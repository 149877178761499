<transport-tw-input-field
  [control]="searchInputControl"
  [label]="label"
  [showErrors]="showErrors"
  [errorsSize]="'xs'"
  [required]="required"
  [errorToMessage]="errorToMessage"
  [size]="'md'">
  <transport-tw-input
    [size]="'md'"
    matAutocompleteOrigin
    #origin="matAutocompleteOrigin"
    [control]="searchInputControl">
    <input
      class="p-0"
      tw-input
      #inputElement
      type="text"
      [formControl]="searchInputControl"
      [placeholder]="inputPlaceholder"
      [matAutocomplete]="auto"
      autocomplete="nope"
      [required]="required"
      [attr.data-test]="dataTest"
      #trigger="matAutocompleteTrigger"
      [matAutocompleteConnectedTo]="origin"
      transportAutocompletePosition="trigger"
      (blur)="onMarkTouch()"
    />
  </transport-tw-input>
</transport-tw-input-field>
<mat-autocomplete
  class="tw-menu-block"
  #auto
  (optionSelected)="optionSelected($event)"
  [displayWith]="bindedValue">
  <mat-option *ngFor="let item of $any(selectData$ | async)" [value]="item">
    <!--      {{ bindedValue(item) }}-->
    <transport-tw-menu-option [variant]="value === item ? 'active' : 'option'" [text]="bindedValue(item)"
                              size="sm"></transport-tw-menu-option>
  </mat-option>

</mat-autocomplete>
