import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { TnAnalyticsService, TnCurrentUserFacade } from '@transport/ui-store';
import { Observable, take } from 'rxjs';

import { ALERT_STATE, ALERT_TITLE_VARIANT, ALERT_VARIANT, TnAlertService } from '@transport/ui-kit';
import { TOAST_DURATION, ORDER_ALLOCATION_TYPE, USER_ROLE, ACCEPTING_PLACE } from '@transport/ui-interfaces';
import { TnDomainService } from '@transport/ui-services';
import { LOCAL_HOST_DOMAIN, SERVER_MARKETPLACE_URL } from '@transport/ui-utils';
import { TnNotificationsFunctionality } from '../../notifications-functionality.service';
import { INotification, NOTIFICATION_TOPIC, getNotificationEntitySearch, getNotificationEntityUrl } from '../../notifications.utils';
import { NotificaionsService } from './notifications.service';

@Component({
  selector: 'notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsComponent implements OnInit {
  public notifications$ = this.notifications.notifications$ as Observable<INotification[]>;

  public isLoading$ = this.notifications.isLoading$;

  public count$ = this.notifications.count$;

  public get currentUserRole() {
    return this.currentUser.currentUserRole || USER_ROLE.CARGO_OWNER;
  }

  public pager: PageEvent = {
    pageIndex: 0,
    pageSize: 25,
    length: 0,
  };

  constructor(
    protected notifications: NotificaionsService,
    private readonly currentUser: TnCurrentUserFacade,
    private notificationFunctionality: TnNotificationsFunctionality,
    private alert: TnAlertService,
    private readonly domainService: TnDomainService,
    private readonly analytics: TnAnalyticsService,
  ) {}

  public ngOnInit() {
    this.setPager(this.pager);
  }

  public trackById = (index, item: INotification) => item.id;

  public setPager(pager) {
    this.notifications.setPage(pager);
  }

  public leavePage() {
    this.notifications.leavePage();
  }

  public markAsRead(notification: INotification) {
    if (!notification?.isRead) {
      this.notifications.markAsRead(notification).subscribe();
    }
  }

  public markAllAsRead() {
    this.notifications.markAllAsRead().subscribe();
  }

  public goToEntity(notification: INotification) {
    this.analytics.track('user goes to notification entity from page');
    if (
      notification.topic === NOTIFICATION_TOPIC.ORDER ||
      notification.topic === NOTIFICATION_TOPIC.AUCTION ||
      notification.topic === NOTIFICATION_TOPIC.BIDDING
    ) {
      this.processEnterInOrderEntity(notification);
      return;
    }
    const urlPath = getNotificationEntityUrl(notification, this.currentUserRole, false);
    const query = getNotificationEntitySearch(notification, this.currentUserRole);
    if (notification.topic === NOTIFICATION_TOPIC.COMPLETED_ORDERS || notification.topic === NOTIFICATION_TOPIC.PRETENSION) {
      this.openNewTabWithSubdomain(notification.payload?.owner_subdomain ?? '', notification.payload?.id ?? '', urlPath);
      return;
    }
    if (notification.topic === NOTIFICATION_TOPIC.COMMON_TENDER) {
      this.openNewTabWithSubdomain(notification.payload?.subdomain ?? '', notification.payload?.id ?? '', urlPath);
      return;
    }

    if (notification.topic === NOTIFICATION_TOPIC.CONTRACT || notification.topic === NOTIFICATION_TOPIC.ACCREDITATION) {
      this.goByPath([urlPath], query);
      return;
    }

    this.notifications.goByPath([urlPath, (notification.payload as { id }).id], query);
  }

  public openNewTabWithSubdomain(subdomain: string, id: string, path: string): void {
    if (subdomain) {
      const localHostPort = this.domainService.domain.includes(LOCAL_HOST_DOMAIN) ? ':4300' : '';
      const hasMarketSubdomain = this.domainService.domain.includes(SERVER_MARKETPLACE_URL);
      const marketSubdomain = hasMarketSubdomain ? '' : `${SERVER_MARKETPLACE_URL}.`;
      const url = `${window.location.protocol}//${subdomain}.${marketSubdomain}${this.domainService.domain}${localHostPort}/${path}${id}`;
      window.open(url, '_blank');
    }
  }

  private processEnterInOrderEntity(notification: INotification) {
    const orderId = (notification.payload as { id }).id;
    this.notificationFunctionality
      .isOrderAvailable(orderId)
      .pipe(take(1))
      .subscribe(isOrderAvailable => {
        if (!isOrderAvailable) {
          const allocation = notification.topic;
          this.alert.openAlert(
            {
              titleVariant: ALERT_TITLE_VARIANT.NONE,
              state: ALERT_STATE.ERROR,
              variant: ALERT_VARIANT.SOLID,
              message: `${
                allocation === 'order' ? 'Заявка' : allocation === 'auction' ? 'Аукцион' : 'Сбор предложений'
              } №${orderId} вам более ${allocation === 'order' ? 'недоступна' : 'недоступен'}.`,
            },
            TOAST_DURATION.SHORT,
          );
          return;
        }

        if (isOrderAvailable?.allocationType === ORDER_ALLOCATION_TYPE.AUCTION_ALLOCATION) notification.topic = NOTIFICATION_TOPIC.AUCTION;
        if (
          isOrderAvailable?.allocationType === ORDER_ALLOCATION_TYPE.SIMPLE_ALLOCATION ||
          isOrderAvailable?.allocationType === ORDER_ALLOCATION_TYPE.PREASSIGNED_ALLOCATION
        ) {
          notification.topic = NOTIFICATION_TOPIC.ORDER;
        }

        const urlPath = getNotificationEntityUrl(notification, this.currentUserRole, false);
        const query = getNotificationEntitySearch(notification, this.currentUserRole);
        this.goByPath([urlPath, String(notification.payload?.id)], { ...query, area: ACCEPTING_PLACE.PARTNERS });
      });
  }

  private goByPath(path: string[], query) {
    if (this.currentUserRole === USER_ROLE.CARGO_OWNER && this.domainService.marketPlaceCargoOwnerSubdomain) {
      const localHostPort = this.domainService.domain.includes('lv4.lv3.lv2.localhost') ? ':4200' : '';
      const url = `${window.location.protocol}//${this.domainService.marketPlaceCargoOwnerSubdomain}.${this.domainService.domain.replace(
        'market.',
        '',
      )}${localHostPort}${path[0]}/${path[1]}`;
      window.open(url, '_blank');
      return;
    }
    this.notifications.goByPath(path, query);
  }
}
