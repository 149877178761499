<ng-template #dialogTemplateRef>
  <transport-settings-container (closeContainer)="closeDialog()" class="transport-settings-container">
    <form [formGroup]="$any(formData)">
      <mat-checkbox transportMatInputDataTest="mat-checkbox-26" *ngFor="let key of columnsKeys" [formControl]="$any($any(formData?.controls)[key])">
        {{ columnsDefs[key].label }}
      </mat-checkbox>
    </form>
  </transport-settings-container>
</ng-template>

<button mat-icon-button #dialogTrigger [disableRipple]="true" (click)="openDialog()">
  <mat-icon>settings</mat-icon>
</button>
