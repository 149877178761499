export enum DRIVER_FORM_CONSTANTS {
  MAX_LICENSE_SERIES_LENGTH = 10,
  MAX_LICENSE_NUMBER_LENGTH = 20,
  MAX_PASSPORT_SERIES_LENGTH = 10,
  MAX_PASSPORT_NUMBER_LENGTH = 20,
  MAX_IIN_LENGTH = 12,
}

export interface IDriverForm {
  birthday: string | null | undefined;
  gender: string | null | undefined;
  inn: string | null | undefined;
  phone: string | null | undefined;
  addPhone: string | null | undefined;
  licenceSeries: string | null | undefined;
  licenceNumber: string | null | undefined;
  licencePlace: string | null | undefined;
  licenceDate: string | null | undefined;
  licenceSurname: string | null | undefined;
  licenceName: string | null | undefined;
  licencePatronymic: string | null | undefined;
  surname: string | null | undefined;
  name: string | null | undefined;
  patronymic: string | null | undefined;
  passportSeries: string | null | undefined;
  passportNumber: string | null | undefined;
  passportPlace: string | null | undefined;
  passportDate: string | null | undefined;
  country: string | null | undefined;
  agentCarrierId?: string | null;
}
