import { PAGE_ACTIONS } from "@transport/ui-interfaces";

export class ViewMode {
  constructor(public mode: PAGE_ACTIONS ) {}

  get isCreate() {
    return this.mode === 'create';
  }
  get isEdit() {
    return this.mode === 'edit';
  }
  get isCopy() {
    return this.mode === 'copy';
  }
  get isView() {
    return this.mode === 'view';
  }
  get isRemove() {
    return this.mode === 'remove';
  }
}
