<ng-container *ngIf="showIcon">
  <mat-icon
    class="angular-material info-icon"
    transportTooltip
    [templateRef]="truckIdentificator"
    [isPopup]="true"
    [context]="{truckIdentificatorType: type}"
    svgIcon="help"
  >
  </mat-icon>
</ng-container>

<ng-template #truckIdentificator let-truckIdentificatorType="truckIdentificatorType">
  <transport-truck-identificator-tooltip [truckIdentificatorType]="truckIdentificatorType"></transport-truck-identificator-tooltip>
</ng-template>
