import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { routerBack, routerForward, routerGo, routerToExternal } from '../actions/router.actions';

/**
 * Router effects.
 */
@Injectable({
  providedIn: 'root',
})
export class TnRouterEffects {
  /**
   * Router `go` effect.
   */

  public navigate$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(routerGo),
        tap(({ path, query: queryParams, extras }) => void this.router.navigate(path, { queryParams, ...extras })),
      ),
    { dispatch: false },
  );

  /**
   * Router `back` effect.
   */
  public navigateBack$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(routerBack),
        tap(() => {
          this.location.back();
        }),
      ),
    { dispatch: false },
  );

  /**
   * Router `forward` effect.
   */
  public navigateForward$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(routerForward),
        tap(() => {
          this.location.forward();
        }),
      ),
    { dispatch: false },
  );

  public navigateToExternalRoute$ = createEffect(
    () =>
      this.action$.pipe(
        ofType(routerToExternal),
        tap(({ url, howOpen }) => {
          window.open(url, howOpen);
        }),
      ),
    { dispatch: false },
  );

  /**
   * Constructor.
   * @param action$ state actions
   * @param router application router
   * @param location location service
   */
  constructor(private readonly action$: Actions, private readonly router: Router, private readonly location: Location) {}
}
