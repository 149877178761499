import { createAction, props } from '@ngrx/store';
import { transport } from '@transport/proto';
import { IUiSettings, IUser, IUserOrganization, USER_ROLE } from '@transport/ui-interfaces';
import INotificationSettingsOverallType = transport.UserProfile.UserSettings.INotificationSettingsOverallType;
import IEditProfilePasswordInput = transport.IEditProfilePasswordInput;

export const restoreUserAction = createAction('[Current user] Restore User');

export const getCurrentUserProfileSuccess = createAction(
  '[Current user] Get User Profile Success',
  props<{
    profile: transport.IUserProfile;
  }>(),
);

export const getCurrentUserUpdateVat = createAction(
  '[Current user] Get User Update Vat',
  props<{
    vat: any;
  }>(),
);

export const getUserOrganizationStart = createAction(
  '[Current user] Get Organization Start',
  props<{
    userId: string;
    userRole: USER_ROLE;
  }>(),
);

export const getUserOrganizationSuccess = createAction(
  '[Current user] Get Organization Success',
  props<{
    organization: IUserOrganization;
    id: string;
  }>(),
);

export const updateUserOrganization = createAction(
  '[Current user] Update User Organization Success',
  props<{
    organization: transport.IAdminOrganization;
  }>(),
);
export const updateUserProfileOrganization = createAction(
  '[Current user] Update User Profile Organization Success',
  props<{
    profile: transport.IUserProfile;
  }>(),
);

export const restoreUserSuccessAction = createAction(
  '[Current user] Restore user success',
  props<{
    user: IUser & { uiSettings: IUiSettings };
  }>(),
);

export const saveUserAction = createAction(
  '[Current user] Save user',
  props<{
    user: Partial<IUser>;
  }>(),
);

export const saveUserSuccessAction = createAction(
  '[Current user] Save user success',
  props<{
    user: IUser & { uiSettings: IUiSettings };
  }>(),
);

export const resetUserAction = createAction('[Current user] Reset user');

export const setUiSettingsAction = createAction(
  '[Current user] Set uiSettings;',
  props<{
    user: Partial<IUser>;
    uiSettings: IUiSettings;
  }>(),
);

export const setUiSettingsSuccessAction = createAction(
  '[Current user] Set uiSettings success',
  props<{
    uiSettings: IUiSettings;
  }>(),
);

export const editUserName = createAction(
  '[Current user] Edit user name',
  props<{
    user: Partial<transport.IUserProfile>;
    userRole: USER_ROLE;
  }>(),
);

export const editUserNameSuccess = createAction(
  '[Current user] Edit user name success',
  props<{ user: Partial<transport.IUserProfile> }>(),
);

export const setDocumentsSettingsSuccess = createAction(
  '[Current user] Edit documents success',
  props<{ facsimileImg: string | null }>(),
);

export const editUserPhone = createAction(
  '[Current user] Edit user phone',
  props<{
    user: Partial<transport.IUserProfile>;
    userRole: USER_ROLE;
  }>(),
);
export const editUserCountry = createAction(
  '[Current user] Edit user Country',
  props<{
    user: Partial<transport.IUserProfile>;
    userRole: USER_ROLE;
  }>(),
);

export const editUserPhoneSuccess = createAction(
  '[Current user] Edit user phone success',
  props<{ user: Partial<transport.IUserProfile> }>(),
);

export const editUserCountrySuccess = createAction(
  '[Current user] Edit user Country success',
  props<{ user: Partial<transport.IUserProfile> }>(),
);

export const setNotificationSettings = createAction(
  '[Current user] Set notificationSettings',
  props<{
    user: Partial<IUser>;
    notificationSettings: INotificationSettingsOverallType;
  }>(),
);

export const setNotificationSettingsSuccess = createAction(
  '[Current user] Set notificationSettings success',
  props<{
    newNotificationSettings: INotificationSettingsOverallType;
  }>(),
);

export const setNotificationSettingsFailure = createAction('[Current user] Set notificationSettings failure');

export const setOldOrderPageDesignSupport = createAction('[Current user] Set Old Order Page Design Support');

export const setOldOrderPageDesignSupportSuccess = createAction('[Current user] Set Old Order Page Design Support Success');

export const resetOldOrderPageDesignSupport = createAction('[Current user] Reset Old Order Page Design Support');

export const resetOldOrderPageDesignSupportSuccess = createAction('[Current user] Reset Old Order Page Design Support Success');
