import { Pipe, PipeTransform } from '@angular/core';
import { transport } from '@transport/proto';
import memo from 'memo-decorator';

/**
 * Pipe for table row styles in dialog window
 */
@Pipe({
  name: 'blockedTrailerByNomenclature',
})
export class TnBlockedTrailerByNomenclaturePipe implements PipeTransform {
  @memo({
    resolver: (...args: any[]): string => JSON.stringify(args),
  })
  public transform(
    blackListInfo?: transport.Vehicle.IBlackListVehicle[] | null,
    orderNomenclature?: string | null,
  ): transport.Vehicle.IBlackListVehicle[] | null {
    if (Boolean(blackListInfo)) {
      const actualBlackListItems = (blackListInfo ?? []).filter(item => {
        const trailerNomenclature = item?.nomenclatureType ?? '';
        const orderNomenclatureArray = (orderNomenclature ?? '').split(',').map(nomenclature => nomenclature.trim());
        if (Boolean(trailerNomenclature) && !Boolean(orderNomenclatureArray?.includes(trailerNomenclature))) {
          return null;
        }
        return item;
      });

      const prohobitedList = actualBlackListItems.filter(item => Boolean(item?.workProhibited));

      return prohobitedList.length ? prohobitedList : actualBlackListItems;
    }
    return null;
  }
}
