import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { FILE_UPLOAD_STATUS, IFile } from '@transport/ui-interfaces';
import { TnFileDownloadService } from '@transport/ui-store';

export enum FILE_DOWNLOAD_LINK_CLICK_HANDLER_TYPE {
  /**обычная ссылка, клик обработается браузером */
  LINK_TARGET_BLANK = 'LINK_TARGET_BLANK',
  /**клик по ссылке обрабоатется и файл скачается get-запросом */
  DOWNLOAD_GET_REQUEST = 'DOWNLOAD_GET_REQUEST',
}

@Component({
  selector: 'transport-files-list',
  templateUrl: './files-list.component.html',
  styleUrls: ['./files-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnFilesListComponent {
  public readonly uploadStatusEnum = FILE_UPLOAD_STATUS;

  @Input() public files: IFile[] = [];

  @Input() public downloadHandlerType: FILE_DOWNLOAD_LINK_CLICK_HANDLER_TYPE = FILE_DOWNLOAD_LINK_CLICK_HANDLER_TYPE.LINK_TARGET_BLANK;

  @Input() public isReadOnly = false;

  @Output() public readonly removeButtonClicked = new EventEmitter<number>();

  @Output() public readonly refreshButtonClicked = new EventEmitter<number>();

  public clickHandlerTypeEnum = FILE_DOWNLOAD_LINK_CLICK_HANDLER_TYPE;

  constructor(private readonly downloadService: TnFileDownloadService) {}

  public onRemoveButtonClick(fileIndex: number): void {
    this.removeButtonClicked.emit(fileIndex);
  }

  public onRefreshButtonClick(indx: number): void {
    this.refreshButtonClicked.emit(indx);
  }

  public download(file: IFile) {
    if (Boolean(file.downloadUrl)) {
      void this.downloadService.download({ url: file.downloadUrl ?? '', fileName: file.fullName }).subscribe();
    }
  }
}
