import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { USER_ROLE } from '@transport/ui-interfaces';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { TnActionsByRoles } from '../../../actions-by-roles/actions-by-roles';
import { TnCurrentUserFacade } from '../../../current-user/current-user.facade';
import { TnAdminApiService } from '../../admin-api.service';
import { allPermissionsActions, allPermissionsApiActions } from '../actions';

@Injectable({
  providedIn: 'root',
})
export class TnAllPermissionsEffects extends TnActionsByRoles {
  public readonly loadAllPermissions = createEffect(() =>
    this.actionsByRoles$.pipe(
      ofType(allPermissionsActions.loadAllPermissions),
      switchMap(() =>
        this.adminService.getAllPermissions().pipe(
          map(allPermissionsApiActions.loadAllPermissionsSuccess),
          catchError(error => of(allPermissionsApiActions.loadAllPermissionsFailure({ error }))),
        ),
      ),
    ),
  );

  constructor(actions$: Actions, userFacade: TnCurrentUserFacade, private readonly adminService: TnAdminApiService) {
    super([USER_ROLE.OWNER], actions$, userFacade);
  }
}
