import { createAction, props } from '@ngrx/store';
import { TToast } from '@transport/ui-interfaces';

export interface IShowToasterPayload {
  message: string;
  type: TToast;
  duration?: number;
}

export interface IShowRedirectToasterPayload extends IShowToasterPayload {
  route: string;
  styles?: string[];
  action?: string | null;
}

export const SHOW_TOASTER_ACTION = '[Toaster] show toaster';

export const showToaster = createAction(SHOW_TOASTER_ACTION, props<{ payload: IShowToasterPayload }>());

export const SHOW_TOASTER_CANCEL_ACTION = '[Toaster] show timer toaster';

export const SHOW_TOASTER_REDIRECT_ACTION = '[Toaster] show redirect timer toaster';

export const showToasterWithCancel = createAction(SHOW_TOASTER_CANCEL_ACTION, props<{ payload: IShowToasterPayload }>());

export const showToasterWithRedirect = createAction(SHOW_TOASTER_REDIRECT_ACTION, props<{ payload: IShowRedirectToasterPayload }>());

export type THideToasterPayload = Record<string, unknown>;

export const HIDE_TOASTER_ACTION = '[Toaster] hide toaster';

export const hideToaster = createAction(HIDE_TOASTER_ACTION, props<{ payload: THideToasterPayload }>());
