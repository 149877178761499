import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ValidatorFn, Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { transport } from '@transport/proto';
import { conditionalValidator, IPlace, IWayBillDocAttributes, IWaybilStoragePoint, waybillFormValidator } from '@transport/ui-interfaces';
import { Observable, of } from 'rxjs';

import { IFileAdditionalAttributesForm } from '../file-additional-attributes-form-abstract';
import IStoragePoint = transport.Order.IStoragePoint;
import { TnLoadingPlaceSelectPipe } from '@transport/ui-pipes';
import { map } from 'rxjs/operators';

export interface IWaybillForm extends IFileAdditionalAttributesForm {
  orderUnloadingPlaces$: Observable<IStoragePoint[]>;
  loadingPlaceTime$: Observable<string>;
  isWaybillRequired$: Observable<boolean>;
}

@UntilDestroy()
@Component({
  selector: 'transport-waybill-form',
  templateUrl: './waybill-form.component.html',
  styleUrls: ['./waybill-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnWaybillFormComponent implements IWaybillForm, OnInit {
  public readonly orderUnloadingPlaces$: Observable<IStoragePoint[]> = of([]);

  public readonly loadingPlaceTime$: Observable<string> = of('');

  public readonly isWaybillRequired$: Observable<boolean> = of(false);

  public storagePoints$: Observable<IWaybilStoragePoint[] | null | undefined> = of(null);

  public isWaybillRequired = false;

  public readonly form: FormGroup<IWayBillDocAttributes> = this.createWayBillFormGroup();

  public get invalid(): boolean {
    return this.form.invalid;
  }

  public get isDateControlsVisible(): boolean {
    return Boolean(this.form.controls.storagePoint.value);
  }

  constructor(private readonly loadingPlacePipe: TnLoadingPlaceSelectPipe, private readonly cdRef: ChangeDetectorRef) {}

  public ngOnInit(): void {
    void this.isWaybillRequired$.pipe(untilDestroyed(this)).subscribe(required => {
      this.isWaybillRequired = Boolean(required);
    });

    void this.loadingPlaceTime$.pipe(untilDestroyed(this)).subscribe(loadingPlaceTime => {
      this.form.setValidators(waybillFormValidator(loadingPlaceTime));
    });

    this.storagePoints$ = this.orderUnloadingPlaces$.pipe(
      map(items =>
        items?.map(item => ({
          id: item.storagePoint?.id ?? null,
          name: this.loadingPlacePipe.transform(item.storagePoint as IPlace),
          arriveDatetimeFrom: item.arriveDatetimeFrom ?? null,
          arriveDatetimeTo: item.arriveDatetimeTo ?? null,
        })),
      ),
    );
  }

  public markAllAsTouched(): void {
    this.form.markAllAsTouched();
    this.cdRef.markForCheck();
  }

  public writeValue(val: IWayBillDocAttributes): void {
    this.form.setValue(val);
    this.cdRef.detectChanges();
  }

  public disable(): void {
    this.form.disable();
  }

  public getValue(): IWayBillDocAttributes {
    return this.form.value;
  }

  private createWayBillFormGroup(): FormGroup<IWayBillDocAttributes> {
    return new FormGroup<IWayBillDocAttributes>({
      storagePoint: new FormControl<IWaybilStoragePoint | null>(null, [
        conditionalValidator(() => this.isWaybillRequired, Validators.required) as ValidatorFn,
      ]),

      dateFrom: new FormControl(null, conditionalValidator(() => this.isWaybillRequired, Validators.required) as ValidatorFn),
      timeFrom: new FormControl('', conditionalValidator(() => this.isWaybillRequired, Validators.required) as ValidatorFn),
      dateTo: new FormControl(null),
      timeTo: new FormControl(''),
    });
  }
}
