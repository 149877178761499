import { createAction, props } from '@ngrx/store';
import { transport } from '@transport/proto';
import { IDriver } from '@transport/ui-interfaces';

export const setDriverId = '[Driver] Set id';
export const getDriverId = '[Driver] Get id';

export const setDriverIdAction = createAction(
  setDriverId,
  props<{
    id: string | null;
  }>(),
);

export const getDriverIdAction = createAction(getDriverId);

export const removeDriver = createAction('Remove Driver from Detail Page', props<{ id: string; name: string }>());
export const removeDriverSuccess = createAction('Remove Driver Success from Detail Page');
export const removeDriverFailure = createAction('Remove Driver Failure from Detail Page', props<{ error: Error }>());

export const setChangedDriverStatus = createAction('Set changed driver status', props<{ changedDriverStatus: Partial<IDriver> }>());

export const searchAgentCarrier = createAction('Search Agent Carrier For Driver', props<{ term: string }>());
export const searchAgentCarrierSuccess = createAction(
  'Search Agent Carrier For Driver Success',
  props<{ agentCarriers: transport.IAgentCarrier[] }>(),
);

export const setSelectedAgentCarrier = createAction(
  'Set Selected Agent Carrier For Driver',
  props<{ agentCarriers: transport.IAgentCarrier[] }>(),
);
