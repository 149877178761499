/**
 * UI colors interface.
 */
export type TColorScheme = 'primary' | 'accent' | 'warn' | null;

/**
 * Grid list tile interface.
 */
export interface IGridListTile<T> {
  color: string;
  cols: number;
  rows: number;
  data: T;
}
