import { createAction, props } from '@ngrx/store';

import { IFormStateValue } from './form.state';

export const formNamespaceKey = 'Form';

export const GET_FORM_DATA = `[${formNamespaceKey}] get form data`;

const getFormData = createAction(GET_FORM_DATA, props<{ payload: { path: string } }>());

export const SET_FORM_DATA = `[${formNamespaceKey}] set form data`;

const setFormData = createAction(SET_FORM_DATA, props<{ payload: { path: string; value: IFormStateValue } }>());

export const RESET_FORM_DATA = `[${formNamespaceKey}] reset form data`;

const resetFormData = createAction(RESET_FORM_DATA, props<{ payload: { path: string } }>());

export const formActions = {
  getFormData,
  setFormData,
  resetFormData,
};
