import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'marketplace-tabs-rounded',
  templateUrl: './tabs-rounded.component.html',
  styleUrls: ['./tabs-rounded.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MpTabsRoundedComponent {
  @Input() public tabs: Array<string> = [];

  @Input() public selectedTabIndex = 0;

  @Output() public readonly tabsSelectionChanged = new EventEmitter<number>();

  public changeTab(selectedIndex: number) {
    this.selectedTabIndex = selectedIndex
    this.tabsSelectionChanged.emit(selectedIndex);
  }

}
