<div [ngClass]="labelClasses ? labelClasses : ['text-gray-700', 'text-'+size]">
  {{label}}
  <span *ngIf="required" class="text-red-500 ml-0.5">*</span>
  <span *ngIf="labelAddition" class="text-xs text-gray-400 font-medium">{{labelAddition}}</span>
</div>

<div class="">
  <ng-content></ng-content>
</div>

<div [ngClass]="captionClasses ? captionClasses : ['text-gray-500 flex-grow', 'text-'+errorsSize]">
  {{ !control || !(control.invalid && control.touched) || !showErrors ? caption : '' }}
  <span *ngIf="control && showErrors && control.invalid && control.touched" class="text-red-500">
    {{errorToMessage(control.errors)}}
  </span>
</div>
