<div class="popup px-3" *ngIf="popupContent.header">
  <h3 class="not-italic mb-3 font-medium text-sm text-gray-700">
    {{ popupContent.header }}
  </h3>
  <div class="mb-2">
    <p class="flex items-center mb-2 text-sm text-gray-700">
      <marketplace-verification-icon
        [iconColor]="popupContent.message.iconColor"
        [tip]="popupContent.message.statusText"
      ></marketplace-verification-icon>
      {{popupContent.message.statusText}}
    </p>
    <p class="text-xs text-gray-500">
      {{popupContent.message.messageText}}
    </p>
  </div>

  <button class="bet__button tw-button-solid-sm w-full m-0" [disabled]="popupContent.button.isDisabled" (click)="startVerification()">
    {{popupContent.button.text}}
    <mat-icon *ngIf="popupContent.button.withIcon">arrow_forward</mat-icon>
  </button>
</div>
