import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { GET_CONSTANTS_CONFIG, getConfig, setConfig } from './constants.actions';
import { TnConstantsApiService } from './constants-api.service';

@Injectable({
  providedIn: 'root',
})
export class TnConstantsEffects {
  constructor(private readonly action$: Actions, private readonly api: TnConstantsApiService) {}

  public readonly getConfig$ = createEffect(() =>
    this.action$.pipe(
      ofType<ReturnType<typeof getConfig>>(GET_CONSTANTS_CONFIG),
      switchMap(() => this.api.config()),
      map(config => setConfig({ payload: config })),
    ),
  );
}
