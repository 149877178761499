<ng-container *ngIf="isShowBlackListInfo">
  <mat-icon
    class="black-list-column__icon"
    [svgIcon]="icon"
    transportTooltip
    [shouldShowByClick]="isTransportChoosingColumnType"
    [templateRef]="iconTooltip"
  >
  </mat-icon>
</ng-container>

<ng-template #iconTooltip>
  <div class="transport-tooltip-title">{{ mainTitle }}</div>
  <ng-container *ngIf="isTransportChoosingColumnType; else directories">
    <ul class="reason-list" *ngIf="reasonList.length > 0">
      <li *ngFor="let item of reasonList">
        {{ item }}
      </li>
    </ul>
    <ng-container *ngIf="reasonCount > showReasonCount">
      <span>{{ reasonCountTitle }}</span>
    </ng-container>
    <a matDialogClose [routerLink]="[linkPage]" [queryParams]="{ returnUrl }">{{ moreDetailsTitle }}</a>
  </ng-container>
</ng-template>

<ng-template #directories>
  <div>{{ partProhibitedTitle }}</div>
  <ng-container *ngIf="type === columnType.TRUCKS">
    <div>{{ prohibitedTrailerTitle }}</div>
    <div>{{ partProhibitedTrailerTitle }}</div>
  </ng-container>
</ng-template>
