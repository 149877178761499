import { ChangeDetectionStrategy, Component, ElementRef, Input, OnInit } from '@angular/core';

type TWarningType = 'success' | 'warning' | 'error' | 'info' | 'gray-info';

@Component({
  selector: 'transport-warning-block',
  templateUrl: './warning-block.component.html',
  styleUrls: ['./warning-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnWarningBlockComponent implements OnInit {
  @Input() public type: TWarningType = 'warning';

  @Input() public tooltip = '';

  @Input() public title = '';

  @Input() public hasIcon = true;

  public get hasTitle() {
    return Boolean(this.title);
  }

  constructor(private readonly element: ElementRef) {}

  public ngOnInit() {
    if (this.type === 'error') {
      (this.element.nativeElement as HTMLElement).scrollIntoView();
    }
  }

  public get getClass(): string {
    let className = 'warning';
    switch (this.type) {
      case 'success':
        className = 'success';
        break;
      case 'error':
        className = 'error';
        break;
      case 'info':
        className = 'info';
        break;
      case 'gray-info':
        className = 'gray-info';
        break;
    }
    return className;
  }
}
