import { Sort } from '@angular/material/sort';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';

import { ITnState } from '../state/index';
import { makeTableActions } from './table-actions';
import { makeTableSelectors } from './table-selectors';
import { REFRESH_TYPE } from './table-state.interface';
import { ITableStateFacade } from './table-state-facade.interface';

export interface ITableStateFacadeFilter {
  type?: string;
  availability?: string;
  typePoint?: string;
}

export class TnTableStateFacade<TFilter = ITableStateFacadeFilter> implements ITableStateFacade<TFilter> {
  public currentPagination$ = this.store.select(this.selectors.getPagination);

  public currentFilter$ = this.store.select(this.selectors.getFilter) as Observable<TFilter>;

  public currentSort$ = this.store.select(this.selectors.getSort);

  public currentSelected$ = this.store.select(this.selectors.getSelectedItems);

  public readonly refresh$ = this.store.select(this.selectors.getRefreshState);

  private readonly currentFilterSubject = new BehaviorSubject<TFilter>({} as TFilter);

  public readonly currentFilterSubject$ = this.currentFilterSubject.asObservable();

  private readonly currentSortSubject = new BehaviorSubject({} as Sort);

  public readonly currentSortSubject$ = this.currentSortSubject.asObservable();

  private readonly currentSelectedSubject = new BehaviorSubject({} as Map<string, boolean>);

  public readonly currentSelectedSubject$ = this.currentSelectedSubject.asObservable();

  constructor(
    public store: Store<ITnState>,
    private readonly actions: ReturnType<typeof makeTableActions>,
    private readonly selectors: ReturnType<typeof makeTableSelectors>,
  ) {
    void this.store.select(selectors.getFilter).subscribe(value => {
      this.currentFilterSubject.next(value);
    });
    void this.store.select(selectors.getSort).subscribe(value => {
      this.currentSortSubject.next(value);
    });

    void this.store.select(selectors.getSelectedItems).subscribe(value => {
      this.currentSelectedSubject.next(value);
    });
  }

  public get currentFilter() {
    return this.currentFilterSubject.value;
  }

  public get currentSort() {
    return this.currentSortSubject.value;
  }

  public get currentSelected(): Map<string, boolean> {
    return this.currentSelectedSubject.value;
  }

  public changePage(pageSize: number, page: number) {
    this.store.dispatch(this.actions.setTablePageAction({ pageSize, page }));
  }

  public saveSelection(selected: Map<string, boolean>) {
    this.store.dispatch(this.actions.saveSelectionItems({ selected }));
  }

  public resetPagination(): void {
    this.store.dispatch(this.actions.resetPaginationAction());
  }

  public setFilterSettings(filterSettings) {
    this.store.dispatch(this.actions.setTableFilterAction({ filterSettings }));
  }

  public setSortSettings(sortSettings) {
    this.store.dispatch(this.actions.setTableSortingAction({ sortSettings }));
  }

  public setRefreshState(refresh: REFRESH_TYPE) {
    this.store.dispatch(this.actions.setRefreshState({ refresh }));
  }
}
