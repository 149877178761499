import { gql } from 'apollo-angular';

export const VERIFICATION_FLOW_QUERIES = {
  createCarrierRiskProfile: gql`
  mutation createCarrierRiskProfile {
      createCarrierRiskProfile {
        profileId
      }
  }`,
  addCarrierRiskProfileDossier: gql`
    mutation addCarrierRiskProfileDossier(
      $docType: String!,
      $docVal: String!,
      $profileId: String!
    ) {
      addCarrierRiskProfileDossier(
        docType: $docType,
        docVal: $docVal,
        profileId: $profileId
      ) {
        dossierId
      }
    }`,
  submitCarrierRiskProfile: gql`
    mutation submitCarrierRiskProfile($profileId: String!) {
      submitCarrierRiskProfile(profileId: $profileId) {
        ok
      }
    }
  `,
};
