import { Directive, ElementRef, HostListener, Optional, Renderer2, Self } from '@angular/core';
import { DefaultValueAccessor, NgControl } from '@angular/forms';

@Directive({
  selector: '[transportUppercase], input[transportUppercase]',
})
export class TnUppercaseTextDirective extends DefaultValueAccessor {
  private readonly elementRef: ElementRef;

  private start = 0;

  private end = 0;

  private oldValue = '';

  constructor(renderer: Renderer2, elementRef: ElementRef, @Optional() @Self() public ngControl: NgControl) {
    super(renderer, elementRef, false);
    this.elementRef = elementRef;
    this.oldValue = (this.elementRef.nativeElement as HTMLElement & { value }).value;
  }

  @HostListener('blur') public blurHandler() {
    this.onTouched();
  }

  @HostListener('input', ['$event']) public onInput(event: InputEvent): void {
    const value = (event.target as HTMLElement & { value }).value;
    const transformed = this.transformValue(value);
    const nativeEl = this.elementRef.nativeElement as HTMLElement & {
      selectionStart;
      selectionEnd;
      value;
      setSelectionRange;
    };
    if (this.oldValue !== transformed) {
      this.start = nativeEl.selectionStart;
      this.end = nativeEl.selectionEnd;
      super.writeValue(transformed);
      (this.ngControl.valueAccessor as DefaultValueAccessor).onChange(transformed);
      this.oldValue = nativeEl.value;
    }
    nativeEl.setSelectionRange(this.start, this.end);
  }

  private transformValue(value) {
    const result = Boolean(value) && typeof value === 'string' ? value.toUpperCase() : value;
    return result;
  }
}
