import { gql } from 'apollo-angular';
export namespace IGSecStaff {
export type Maybe<T> = T | null | undefined;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `DateTime` scalar type represents a DateTime
   * value as specified by
   * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
   */
  DateTime: string;
  /**
   * Allows use of a JSON String for input / output from the GraphQL schema.
   *
   * Use of this type is *not recommended* as you lose the benefits of having a defined, static
   * schema (one of the key benefits of GraphQL).
   */
  JSONString: string;
};



export interface ICloneRiskProfile {
  /** Идентификатор созданного профиля риска */
  newProfileId?: Maybe<Scalars['String']>;
}


/** Debugging information for the current query. */
export interface IDjangoDebug {
  /** Executed SQL queries for this API query. */
  sql?: Maybe<Array<Maybe<IDjangoDebugSql>>>;
}

/** Represents a single database query made to a Django managed DB. */
export interface IDjangoDebugSql {
  /** The Django database alias (e.g. 'default'). */
  alias: Scalars['String'];
  /** Duration of this database query in seconds. */
  duration: Scalars['Float'];
  /** Postgres connection encoding if available. */
  encoding?: Maybe<Scalars['String']>;
  /** Whether this database query was a SELECT. */
  isSelect: Scalars['Boolean'];
  /** Whether this database query took more than 10 seconds. */
  isSlow: Scalars['Boolean'];
  /** Postgres isolation level if available. */
  isoLevel?: Maybe<Scalars['String']>;
  /** JSON encoded database query parameters. */
  params: Scalars['String'];
  /** The raw SQL of this query, without params. */
  rawSql: Scalars['String'];
  /** The actual SQL sent to this database. */
  sql?: Maybe<Scalars['String']>;
  /** Start time of this database query. */
  startTime: Scalars['Float'];
  /** Stop time of this database query. */
  stopTime: Scalars['Float'];
  /** Postgres transaction ID if available. */
  transId?: Maybe<Scalars['String']>;
  /** Postgres transaction status if available. */
  transStatus?: Maybe<Scalars['String']>;
  /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
  vendor: Scalars['String'];
}


export interface IManualTaskType {
  /** Уникальный идентификатор задачи */
  taskId?: Maybe<Scalars['ID']>;
  /** Идентификатор задачи для отображения */
  displayId?: Maybe<Scalars['Int']>;
  /** Список тегов задачи для формирования контекста */
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  /** Аргументы для выполнения задачи либо json для рендеринга формы в ЛК оператора */
  args?: Maybe<Scalars['JSONString']>;
  /** Результат */
  result?: Maybe<Scalars['JSONString']>;
  /** Дата и время создания */
  createdAt?: Maybe<Scalars['DateTime']>;
  /** Дата и время выполнения */
  completedAt?: Maybe<Scalars['DateTime']>;
  /** Статус задачи */
  status?: Maybe<IStatus>;
}

/** Тип объекта */
export enum IObjectType {
  CargoOwner = 'cargo_owner',
  Carrier = 'carrier',
  VehicleAvailability = 'vehicle_availability',
  Vehicle = 'vehicle',
  Driver = 'driver',
  Organization = 'organization'
}

/** GraphQL-мутаторы для оператора платформы */
export interface IPlatformOperatorMutations {
  _debug?: Maybe<IDjangoDebug>;
  /** Завершить задачу */
  completeTask?: Maybe<IManualTaskType>;
}


/** GraphQL-мутаторы для оператора платформы */
export type IPlatformOperatorMutationsCompleteTaskArgs = {
  comment?: Maybe<Scalars['String']>;
  result?: Maybe<Scalars['Boolean']>;
  taskId: Scalars['String'];
};

/** GraphQL-мутаторы для оператора платформы */
export interface IPlatformOperatorQuery {
  _debug?: Maybe<IDjangoDebug>;
  /** Список задач, выполняемых вручную */
  getManualTasks?: Maybe<Array<Maybe<IManualTaskType>>>;
  /** Задача, выполняемая вручную */
  getTask?: Maybe<IManualTaskType>;
  /** Список задач, выполняемых вручную */
  getManualTasksCount?: Maybe<Scalars['Int']>;
}


/** GraphQL-мутаторы для оператора платформы */
export type IPlatformOperatorQueryGetManualTasksArgs = {
  status?: Maybe<IStatus>;
  result?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  searchFilter?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
};


/** GraphQL-мутаторы для оператора платформы */
export type IPlatformOperatorQueryGetTaskArgs = {
  taskId: Scalars['String'];
};


/** GraphQL-мутаторы для оператора платформы */
export type IPlatformOperatorQueryGetManualTasksCountArgs = {
  status?: Maybe<IStatus>;
  result?: Maybe<Scalars['Boolean']>;
  tags?: Maybe<Array<Maybe<Scalars['String']>>>;
  searchFilter?: Maybe<Scalars['String']>;
};

export interface IRisksMutation {
  /** Создать профиль риска для объекта */
  submitRiskProfile?: Maybe<ISubmitRiskProfile>;
  /** Скопировать профиль риска для объекта */
  cloneRiskProfile?: Maybe<ICloneRiskProfile>;
  /** Пометить профиль как неполный */
  setRiskProfileIncomplete?: Maybe<ISetRiskProfileIncomplete>;
  _debug?: Maybe<IDjangoDebug>;
}


export type IRisksMutationSubmitRiskProfileArgs = {
  objectId: Scalars['String'];
  objectType: IObjectType;
};


export type IRisksMutationCloneRiskProfileArgs = {
  profileId: Scalars['String'];
};


export type IRisksMutationSetRiskProfileIncompleteArgs = {
  comment: Scalars['String'];
  profileId: Scalars['String'];
};

export interface IRisksQuery {
  _debug?: Maybe<IDjangoDebug>;
}

export interface ISetRiskProfileIncomplete {
  /** Операция выполнена успешно - ДА или НЕТ */
  ok?: Maybe<Scalars['String']>;
}

/** An enumeration. */
export enum IStatus {
  Pending = 'pending',
  Submitted = 'submitted',
  Done = 'done',
  Errors = 'errors'
}

export interface ISubmitRiskProfile {
  /** Идентификатор созданного профиля риска */
  profileId?: Maybe<Scalars['String']>;
}

}