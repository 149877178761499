<div class="text-wrap"
     *ngIf="!contracts?.length; else contractTemp">
  <div [@hightlight]='hightlight'
       class="text-top ellips"
       [attr.tn-tip]="true">{{textTop}}</div>
  <div class="text-bottom ellips"
       [attr.tn-tip]="true"
       *ngIf="showTextBottom">{{textBottom}}</div>
</div>

<ng-template #contractTemp>
  <div class="text-wrap">
    <div class="text-top">
      № {{contracts[contracts.length - 1].contractNumber}}
      <a *ngIf="contracts[contracts.length - 1].file"
         class="file-link"
         [href]="contracts[contracts.length - 1].file"
         target="_blank"
         (mouseup)="$event.stopPropagation()">
        <mat-icon svgIcon="download-file" class="download-contract"></mat-icon>
      </a>
    </div>
    <div class="text-bottom">ОТ {{contracts[contracts.length - 1].startDate | date: 'dd.MM.yyyy'}}</div>
  </div>
</ng-template>
