import { Directive, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TnDynamicComponentDirective } from '@transport/ui-directives';

@UntilDestroy()
@Directive()
export abstract class TnPageFilter<TComponent extends { activatedFiltersCount: number; openDialog: VoidFunction }, S> {
  @ViewChild(TnDynamicComponentDirective, { static: true })
  protected readonly dynamicComponentDir?: TnDynamicComponentDirective;

  protected filter?: TComponent | null;

  @Input() public set tabType(tabType: string) {
    this.loadFilterComponentByTabType(tabType);
  }

  @Input() public disabled = false;

  @Output() public readonly filterChange = new EventEmitter<S>();

  public get activatedFiltersCount() {
    return this.filter?.activatedFiltersCount ?? 0;
  }

  public openFilterDialog() {
    this.filter?.openDialog();
  }

  protected abstract loadFilterComponentByTabType(tabType: string): void;
}
