import { Component } from '@angular/core';
import { Observable, map, takeUntil } from 'rxjs';
import { VehiclesTableService } from '../table/vehicles-table.service';
import { VehicleService } from '@marketplace/shared';
import { TnCurrentUserService } from '@marketplace/auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MatDialog } from '@angular/material/dialog';
import { IVehicleTable } from '../table/vehicles-table.interface';
import { VehiclesAmountService } from '../filters/filters-toolbar/vehicle-amount.service';
import { VehiclesFilterModalComponent } from '../filters/filter-modal/vehicles-filter-modal.component';
import { VehiclesService } from '../vehicles.service';
import { ActivatedRoute } from '@angular/router';
import { USER_ROLE } from '@transport/ui-interfaces';
import { TnCurrentUserFacade } from '@transport/ui-store';

@UntilDestroy()
@Component({
  selector: 'marketplace-vehicles-table-page',
  templateUrl: './vehicles-table-page.component.html',
  styleUrls: ['./vehicles-table-page.component.scss'],
  providers: [VehicleService],
})
export class VehiclesTablePageComponent {
  public filtersCount$ = this.vehicleTableService.store.filter$.pipe(
    map(filter =>
      Object.values({...filter, availability: undefined})
        .map(v => (v ? 1 : 0))
        .reduce((a: number, b: number) => a + b, 0),
    ),
  );

  public canManage = true;

  public vehicles$: Observable<IVehicleTable[]> = this.vehicleTableService.list.pipe(map(data => data.items));

  constructor(
    private vehicleService: VehicleService,
    private vehiclesPageService: VehiclesService,
    private dialog: MatDialog,
    public vehicleTableService: VehiclesTableService,
    public vehiclesAmountService: VehiclesAmountService,
    public currentUserService: TnCurrentUserService,
    private route: ActivatedRoute,
    public readonly userFacade: TnCurrentUserFacade,
  ) {
    this.route.data.pipe(untilDestroyed(this)).subscribe(res => {
      this.vehiclesPageService.role$.next(res?.roles?.includes(USER_ROLE.CARRIER) ? USER_ROLE.CARRIER : USER_ROLE.CARGO_OWNER);
      this.vehicleTableService.getBodySubtypes();
      this.vehicleTableService.getVehicleMakes();
    })
    this.userFacade.permissions$.subscribe(res => (this.canManage = !!res.generalReference?.manage || !!this.currentUserService.currentUserPermissions.generalReference?.manage));
  }


  public createVehicles(): void {
    this.vehicleService.openModal(this.vehiclesPageService.role$.value as USER_ROLE);
    if (this.vehicleService.dialogRef)
      this.vehicleService.vm?.finish$
        .pipe(takeUntil(this.vehicleService.dialogRef?.afterClosed()))
        .subscribe(finished => {
          if(finished) {
            this.vehicleTableService.fetch();
            this.vehiclesAmountService.updateVehiclesStatusCounters();
          }
        });
  }

  public onFilterModalOpen() {
    const dialogRef = this.dialog.open(VehiclesFilterModalComponent, {
      autoFocus: false,
      maxWidth: '400px',
      maxHeight: '95vh',
    });

    return dialogRef
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(values => {
        if (values) {
          this.vehicleTableService.store.changeFilter(values);
        }
      });
  }
}
