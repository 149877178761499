import { IFiasAddressDto } from '@transport/ui-interfaces';

export interface IFiasData {
  addresses: IFiasAddressDto[];
}

export interface IFiasState {
  default: IFiasData;
  [key: string]: IFiasData;
}

export const fiasInitialState: IFiasState = {
  default: {
    addresses: [],
  },
};
