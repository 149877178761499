import { createNewVehicle, IVehicle } from '../../vehicle/vehicle.interface';
import { TDirectoryEntityPredefinedAction } from '../directory.interface';

/**
 * Router truck page component data model.
 */
export class TnTruckPageComponentData {
  public title = 'directory: truck page';

  public truck: IVehicle = createNewVehicle();

  public predefinedAction: TDirectoryEntityPredefinedAction | null = null;
}
