import { gql } from 'apollo-angular';
export namespace IGCarrier {
  export type Maybe<T> = T | null | undefined;
  export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
  export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
  export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
  /** All built-in and custom scalars, mapped to their actual values */
  export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /**
     * The `Date` scalar type represents a Date
     * value as specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    Date: string;
    /**
     * The `DateTime` scalar type represents a DateTime
     * value as specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    DateTime: string;
    /** The `Decimal` scalar type represents a python Decimal. */
    Decimal: string;
    /**
     * Allows use of a JSON String for input / output from the GraphQL schema.
     *
     * Use of this type is *not recommended* as you lose the benefits of having a defined, static
     * schema (one of the key benefits of GraphQL).
     */
    JSONString: string;
    ModelID: string;
    /**
     * The `Time` scalar type represents a Time value as
     * specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    Time: string;
    /**
     * Create scalar that ignores normal serialization/deserialization, since
     * that will be handled by the multipart request spec
     */
    Upload: string;
  };

  /** An enumeration. */
  export enum IAccreditationStatus {
    New = 'new',
    Pending = 'pending',
    Passed = 'passed',
    NotPassed = 'not_passed',
  }

  export enum IAccreditationStatusEnum {
    New = 'new',
    Pending = 'pending',
    Passed = 'passed',
    NotPassed = 'not_passed',
  }

  /** Перечислитель типов размещения заявок */
  export enum IActualProblemEventEnum {
    /** Все заявки */
    All = 'ALL',
    /** Есть актуальные проблемные события */
    Exists = 'EXISTS',
    /** Нет актуальных проблемных событий */
    NotExists = 'NOT_EXISTS',
  }

  export interface IAddAgentCarrierInput {
    legalForm?: Maybe<Scalars['ID']>;
    /** ИНН */
    inn: Scalars['String'];
    /** КПП */
    kpp?: Maybe<Scalars['String']>;
    /** Почтовый (фактический) адрес. Длина (max): 300 */
    postalAddress?: Maybe<Scalars['String']>;
    /** Ставка НДС для ГП, относящихся к организациям-агентам (ТЭК) */
    vat?: Maybe<Scalars['ID']>;
    /** Название перевозчика. Длина (max): 150 */
    carrierName: Scalars['String'];
  }

  export interface IAddBidOnTenderInput {
    /** id тендера */
    tenderId: Scalars['ID'];
    /** Цена за лот */
    lotPrice: Scalars['Float'];
    /** Комментарий к ставке */
    comment?: Maybe<Scalars['String']>;
  }

  export interface IAddCarrierOrganizationInput {
    /** Код ОПФ */
    legalFormCode?: Maybe<Scalars['String']>;
    /** Название организации. Длина (max): 300 */
    name: Scalars['String'];
    /** Телефон организации. Длина (max): 150 */
    phoneNumber?: Maybe<Scalars['String']>;
    /** Электронная почта. Длина (max): 254 */
    email?: Maybe<Scalars['String']>;
    /** ИНН. Длина (max): 12 */
    inn?: Maybe<Scalars['String']>;
    /** КПП. Длина (max): 9 */
    kpp?: Maybe<Scalars['String']>;
    /** Юридический адрес. Длина (max): 300 */
    legalAddress?: Maybe<Scalars['String']>;
    /** ОГРН/ОГРНИП. Длина (max): 15 */
    registrationNumber?: Maybe<Scalars['String']>;
    /** Почтовый адрес. Длина (max): 300 */
    postalAddress?: Maybe<Scalars['String']>;
  }

  export interface IAddCarrierRiskProfileDossier {
    /** Идентификатор созданного документа досье */
    dossierId?: Maybe<Scalars['String']>;
  }

  export interface IAddCompletedOrdersReportInput {
    /** Данные по акту оказанных услуг */
    reportSettings?: Maybe<IReportSettingsInput>;
    /** Данные по строкам акта оказанных услуг */
    orderRecords?: Maybe<Array<Maybe<IOrderRecordInput>>>;
  }

  /** Вводимая информация при добавлении водителя */
  export interface IAddDriverInput {
    /** ID грузоперевозчика от агента (ТЭК) */
    agentCarrierId?: Maybe<Scalars['ID']>;
    /** Фамилия. Длина (max): 150 */
    surname: Scalars['String'];
    /** Имя. Длина (max): 30 */
    name: Scalars['String'];
    /** Отчество. Длина (max): 150 */
    patronymic?: Maybe<Scalars['String']>;
    /** Код ISO страны водителя (по умолчанию RU). Длина (max): 2 */
    country?: Maybe<Scalars['String']>;
    /** Серия паспорта. Длина (max): 10 */
    passportSeries?: Maybe<Scalars['String']>;
    /** Номер паспорта. Длина (max): 20 */
    passportNumber: Scalars['String'];
    /** Кем выдан паспорт. Длина (max): не ограничена */
    passportPlace: Scalars['String'];
    /** Дата выдачи паспорта */
    passportDate: Scalars['Date'];
    /** Имя в водительском удостоверении */
    licenceName?: Maybe<Scalars['String']>;
    /** Фамилия в водительском удостоверении */
    licenceSurname?: Maybe<Scalars['String']>;
    /** Отчество в водительском удостоверении */
    licencePatronymic?: Maybe<Scalars['String']>;
    /** Серия водительских прав. Длина (max): 10 */
    licenceSeries: Scalars['String'];
    /** Номер водительских прав. Длина (max): 20 */
    licenceNumber: Scalars['String'];
    /** Кем выданы водительские права. Длина (max): не ограничена */
    licencePlace?: Maybe<Scalars['String']>;
    /** Дата выдачи водительских прав */
    licenceDate: Scalars['Date'];
    /** Телефон. Длина (max): 12 */
    phone: Scalars['String'];
    /** Доп. Телефон. Длина (max): 12 */
    addPhone?: Maybe<Scalars['String']>;
    /** Дата рожденья */
    birthday: Scalars['Date'];
    /** Пол */
    gender?: Maybe<IDriverGender>;
    /** ИНН. Длина (max): 12 */
    inn?: Maybe<Scalars['String']>;
  }

  export interface IAddDriverRiskProfileDossier {
    /** Идентификатор созданного документа досье */
    dossierId?: Maybe<Scalars['String']>;
  }

  /** Информация о новом сотруднике организации */
  export interface IAddEmployeeInput {
    /** Имя. Длина (max): 30 */
    firstName: Scalars['String'];
    /** Фамилия. Длина (max): 150 */
    lastName: Scalars['String'];
    /** Отчество. Длина (max): 150 */
    patronymic?: Maybe<Scalars['String']>;
    /** Email. Длина (max): 254 */
    email: Scalars['String'];
    /** Номер телефона. Длина (max): 25 */
    phoneNumber?: Maybe<Scalars['String']>;
    /** Список из ID прав доступа, назначаемых сотруднику */
    permissions: Array<Maybe<Scalars['ID']>>;
    /** Настройки UI */
    uiSettings?: Maybe<Scalars['JSONString']>;
  }

  export interface IAddHeadOfOrganizationInput {
    /** Фамилия. Длина (max): 150 */
    surname?: Maybe<Scalars['String']>;
    /** Имя. Длина (max): 30 */
    name?: Maybe<Scalars['String']>;
    /** Отчество. Длина (max): 150 */
    patronymic?: Maybe<Scalars['String']>;
    /** Серия паспорта. Длина (max): 10 */
    passportSeries?: Maybe<Scalars['String']>;
    /** Номер паспорта. Длина (max): 20 */
    passportNumber?: Maybe<Scalars['String']>;
    /** Дата выдачи паспорта */
    passportDate?: Maybe<Scalars['Date']>;
  }

  export interface IAddInsuranceInput {
    /** ID договора с СК */
    insuranceContract: Scalars['ID'];
    /** Данные для страхования */
    insuranceData: Scalars['JSONString'];
  }

  export interface IAddInsuranceOrderInput {
    /** ID заявки */
    orderId: Scalars['ID'];
    /** Данные для страхования */
    insurance?: Maybe<IAddInsuranceInput>;
  }

  export interface IAddOrderUploadedDocumentInput {
    /** Загруженные файлы */
    uploadedFileIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Метаданные транспортной накладной */
    waybillInfo?: Maybe<IAddWaybillInput>;
    /** Тип документа */
    documentTypeId: Scalars['ModelID'];
    /** Загруженный файл */
    uploadedFileId?: Maybe<Scalars['ModelID']>;
    /** Подписываемый файл */
    signedFileId?: Maybe<Scalars['ModelID']>;
  }

  export interface IAddTrustedOrderPreferenceInput {
    /** Наличие ЭЦП (электронно-цифровой подписи) грузоперевозчика */
    hasDigitalSignature: Scalars['Boolean'];
    /** Наличие собственных ТС у грузоперевозчика */
    hasOwnVehicle: Scalars['Boolean'];
  }

  export interface IAddVehicleAvailabilityRiskProfileDossier {
    /** Идентификатор созданного документа досье */
    dossierId?: Maybe<Scalars['String']>;
  }

  export interface IAddVehicleRiskProfileDossier {
    /** Идентификатор созданного документа досье */
    dossierId?: Maybe<Scalars['String']>;
  }

  export interface IAddWaybillInput {
    /** id места доставки */
    storagePointId?: Maybe<Scalars['ID']>;
    /** Время с */
    dateFrom?: Maybe<Scalars['DateTime']>;
    /** Время по */
    dateTo?: Maybe<Scalars['DateTime']>;
  }

  /** Фильтрация по принадлежности Водителей ГП ТЭКа или самому ТЭКу */
  export enum IAgentCarrierDriverOwnershipEnum {
    /** Все записи */
    All = 'ALL',
    /** Собственные Водители ТЭКа */
    Own = 'OWN',
    /** Водители ГП ТЭКа */
    AgentCarrier = 'AGENT_CARRIER',
  }

  export interface IAgentCarrierInfoInput {
    /** ИНН */
    inn?: Maybe<Scalars['String']>;
    /** КПП */
    kpp?: Maybe<Scalars['String']>;
    /** Наименование */
    name?: Maybe<Scalars['String']>;
    /** Почтовый (фактический) адрес */
    address?: Maybe<Scalars['String']>;
    /** Телефон */
    phone?: Maybe<Scalars['String']>;
  }

  export interface IAgentCarrierInfoType {
    inn?: Maybe<Scalars['String']>;
    kpp?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
  }

  export interface IAgentCarrierType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    /** Название перевозчика */
    carrierName: Scalars['String'];
    /** Почтовый (фактический) адрес */
    postalAddress?: Maybe<Scalars['String']>;
    /** Ставка НДС для ГП, относящихся к организациям-агентам (ТЭК) */
    vat?: Maybe<IVatType>;
    /** ИНН */
    inn?: Maybe<Scalars['String']>;
    /** КПП */
    kpp?: Maybe<Scalars['String']>;
    /** Название организации */
    name?: Maybe<Scalars['String']>;
    /** Юридический адрес */
    legalAddress?: Maybe<Scalars['String']>;
    /** Количество ТС */
    countVehicles?: Maybe<Scalars['Int']>;
    /** Последняя проверка существования организации ГП */
    lastExistenceCheck?: Maybe<IOrganizationExistenceHistoryType>;
    /** Организационно-правовая форма */
    legalForm?: Maybe<ILegalFormType>;
  }

  /** Фильтрация по принадлежности ТС Грузоперевозчику ТЭКа или самому ТЭКу */
  export enum IAgentCarrierVehicleOwnershipEnum {
    /** Все записи */
    All = 'ALL',
    /** Собственные ТС ТЭКа */
    Own = 'OWN',
    /** ТС грузоперевозчика ТЭКа */
    AgentCarrier = 'AGENT_CARRIER',
  }

  /** Класс для выдачи сумм распределения фрахта и агентского вознаграждения из суммы заявки */
  export interface IAgentFeeAndFreightDetails {
    /** Сумма ГП ТЭКа: величина фрахта, включая НДС */
    agentCarrierFreightIncludingVat: Scalars['Decimal'];
    /** Сумма ГП ТЭКа: ставка НДС */
    agentCarrierVatRate: Scalars['String'];
    /** Сумма ГП ТЭКа: величина НДС для фрахта */
    agentCarrierFreightVatAmount: Scalars['Decimal'];
    /** Сумма ТЭКа: НДС за контрагента (за ГП) */
    agentVatAmountPerAgentCarrier: Scalars['Decimal'];
    /** Сумма ТЭКа: агентское вознаграждение, включая НДС */
    agentFeeIncludingVat: Scalars['Decimal'];
    /** Сумма ТЭКа: ставка НДС */
    agentFeeVatRate: Scalars['String'];
    /** Сумма ТЭКа: величина НДС для агентского вознаграждения */
    agentFeeVatAmount: Scalars['Decimal'];
    /** Итого */
    totalPrice: Scalars['Decimal'];
  }

  /** Перечислитель типов размещения заявок */
  export enum IAllocationTypeEnum {
    /** Все заявки */
    All = 'ALL',
    /** Простые заявки */
    Simple = 'SIMPLE',
    /** Аукционные заявки */
    Auction = 'AUCTION',
    /** Торги на повышение */
    Bidding = 'BIDDING',
  }

  export interface IAuctionOrderType {
    /** Уникальный идентификатор заявки */
    id?: Maybe<Scalars['ID']>;
    /** Дата и время создания */
    createdDatetime?: Maybe<Scalars['DateTime']>;
    /** Вес */
    weight?: Maybe<Scalars['Float']>;
    /** Объём */
    volume?: Maybe<Scalars['Float']>;
    /** Стоимость */
    price?: Maybe<IPriceType>;
    /** Комментарий */
    comment?: Maybe<Scalars['String']>;
    /** Статус заявки */
    status?: Maybe<IOrderStatus>;
    /** Информация о водителе */
    driverInfo?: Maybe<Scalars['String']>;
    /** Информация об автомобиле */
    truckInfo?: Maybe<Scalars['String']>;
    /** Информация об автомобиле */
    trailerInfo?: Maybe<Scalars['String']>;
    /** Дата и время указания информации о водителе, автомобиле и прицепе */
    transportReservedDatetime?: Maybe<Scalars['DateTime']>;
    /** Дата и время загрузки скана заявки-договора */
    contractAttachedDatetime?: Maybe<Scalars['DateTime']>;
    /** Дата и время завершения оформления заявки */
    readyToGoDatetime?: Maybe<Scalars['DateTime']>;
    /** Тип размещения заявки */
    allocationType?: Maybe<IOrderAllocationType>;
    /** Цена включает НДС */
    isVatIncluded?: Maybe<Scalars['Boolean']>;
    /** Количество грузовых мест */
    cargoPackagesCount?: Maybe<Scalars['Int']>;
    /** Лот */
    lot?: Maybe<ILotType>;
    /** Лот торгов на повышение */
    biddinglot?: Maybe<IBiddingLotType>;
    /** Номер заявки во внешней системе */
    externalNo?: Maybe<Scalars['String']>;
    /** Признак размещения заявки на рынке */
    isMarket?: Maybe<Scalars['Boolean']>;
    /** Сроки оплаты */
    paymentPeriod?: Maybe<Scalars['String']>;
    /** Дедлайн для обработки назначенной заявки */
    processDeadline?: Maybe<Scalars['DateTime']>;
    /** Дополнительные услуга по заявке */
    extraServices?: Maybe<Array<Maybe<IExtraServicesType>>>;
    /** Организация, указанная в качестве заказчика перевозки */
    customerOrganization?: Maybe<ICargoOwnerOrganizationType>;
    /** Признак того, каким образом происходит подписание заявок */
    signingWay?: Maybe<IOrderSigningWay>;
    /** Температурный режим (градусы Цельсия) */
    temperatureMode?: Maybe<Scalars['Int']>;
    /** Заявка только на просмотр */
    isReadonly?: Maybe<Scalars['Boolean']>;
    /** Время на бронирование свободной заявки, указанная для текущей заявки, в минутах */
    timeOrderReservation?: Maybe<Scalars['Int']>;
    /** Количество участников торговой процедуры */
    tradingParticipantsCount?: Maybe<Scalars['Int']>;
    /** Отгрузка обязательна в согласованную с покупателем дату */
    isShipmentOnAgreedDate?: Maybe<Scalars['Boolean']>;
    /** Виды номенклатуры */
    cargoNomenclatureTypes?: Maybe<Scalars['String']>;
    /** Дополнительное время на обработку назначенной заявки, в секундах */
    gracePeriod?: Maybe<Scalars['Int']>;
    /** Дата и время создания заявки на транспорт в 1С */
    deliveryRequestDateUtc?: Maybe<Scalars['DateTime']>;
    /** Оригинальная заявка */
    sourceOrder?: Maybe<IOrderType>;
    /** Перевозка для заказчиков DIY, например, Leroy Merlin */
    diy?: Maybe<Scalars['Boolean']>;
    /** Признак того, что в заявке не нарушен ни один критерий SLA */
    slaObserved?: Maybe<Scalars['Boolean']>;
    /** Тип груза */
    cargoType?: Maybe<Scalars['String']>;
    /** Наименование типа груза */
    cargoTypeName?: Maybe<Scalars['String']>;
    /** Получение информации о первом месте погрузки в заявке */
    loadingPlace?: Maybe<IStoragePointDataType>;
    /** Дата погрузки */
    loadingDate?: Maybe<Scalars['Date']>;
    /** Контактное лицо на первом месте погрузки в заявке */
    contactPersonAtLoadingPlace?: Maybe<Scalars['String']>;
    /** Получение информации о последнем месте доставки в заявке */
    unloadingPlace?: Maybe<IStoragePointDataType>;
    /** Дата доставки */
    unloadingDate?: Maybe<Scalars['Date']>;
    /** Контактное лицо на последнем месте доставки в заявке */
    contactPersonAtUnloadingPlace?: Maybe<Scalars['String']>;
    /** Список мест погрузки указанной заявки */
    loadingPlaces?: Maybe<Array<Maybe<IOrderStoragePointType>>>;
    /** Список мест доставки указанной заявки */
    unloadingPlaces?: Maybe<Array<Maybe<IOrderStoragePointType>>>;
    /** Получение информации о водителе */
    driver?: Maybe<IDriverDataType>;
    /** Получение информации о сменном водителе */
    coDriver?: Maybe<IDriverType>;
    /** Получение информации о ТС */
    vehicle?: Maybe<IVehicleDataType>;
    /** Информация о прицепе/полуприцепе */
    vehicletrailer?: Maybe<IVehicleTrailerDataType>;
    /** Дополнительная информация */
    extraData?: Maybe<Scalars['JSONString']>;
    /** Получение информации о требованиях к транспортному средству */
    vehicleRequirements?: Maybe<IVehicleRequirementsType>;
    /** Получение информации о требованиях к грузовому месту */
    cargoPlaceRequirements?: Maybe<ICargoPlaceRequirementsType>;
    /** Есть ли страховака */
    hasInsurance?: Maybe<Scalars['Boolean']>;
    /** Информация о менеджере заявки */
    owner?: Maybe<IOrderOwnerType>;
    /** Тип оплаты */
    paymentType?: Maybe<IPaymentTypeType>;
    /** Цена с НДС */
    priceWithVat?: Maybe<IPriceType>;
    /** Цена без НДС */
    priceWithoutVat?: Maybe<IPriceType>;
    /** Цена заявки-договора */
    contractPrice?: Maybe<Scalars['Float']>;
    /** Статус заявки по событиям из МЛ */
    statusOnEvent?: Maybe<Scalars['String']>;
    /**
     * Время завершения в заявке
     * @deprecated Для web и мобильных клиентов этот параметр заменен на viewEndDatetime.
     */
    endDatetime?: Maybe<Scalars['DateTime']>;
    /** Время завершения, которое рассчитывается для web-клиента, в зависимости от таймера в заявке */
    viewEndDatetime?: Maybe<Scalars['DateTime']>;
    /** Дедлайн для обработки назначенной заявки,который рассчитывается для web-клиента, в зависимости от таймера в заявке */
    viewProcessDeadline?: Maybe<Scalars['DateTime']>;
    /** Дедлайн для того чтобы забрать свободную заявку или сделать ставку для аукционной заявки,который рассчитывается для web-клиента, в зависимости от таймера в заявке */
    acceptDeadline?: Maybe<Scalars['DateTime']>;
    /**
     * Время до завершения заявки по времени(в секундах)
     * @deprecated Этот параметр в следующих версиях будет заменен на secondsToLifeTimeExpired.
     */
    secondsToCancel?: Maybe<Scalars['Int']>;
    /** Время до завершения заявки по времени жизни(в секундах) */
    secondsToLifeTimeExpired?: Maybe<Scalars['Int']>;
    /** Время до завершения заявки по времени бронирования(в секундах) */
    secondsToReservationTimeExpired?: Maybe<Scalars['Int']>;
    /** Может ли пользователь редактировать список загруженных документов */
    canModifyUploadedDocuments?: Maybe<Scalars['Boolean']>;
    /** Может ли пользователь отменить резерв транспорта(открепить ТС, водителя) */
    canUnreserveTransport?: Maybe<Scalars['Boolean']>;
    /** Загруженные документы */
    uploadedDocuments?: Maybe<Array<Maybe<IOrderUploadedDocumentType>>>;
    /** Загруженные документы */
    uploadedDocumentsV2?: Maybe<Array<Maybe<IOrderUploadedDocumentType>>>;
    /** Список типов файлов, которые необходимо подписывать */
    settingsESignRequired?: Maybe<Array<Maybe<IOrderDocumentTypeType>>>;
    /** Маршрутный лист по заявке */
    routeSheet?: Maybe<IRouteSheetType>;
    /** Текущий статус документа */
    documentActualStatus?: Maybe<Array<Maybe<IDocumentStatusType>>>;
    /** История статус по документу */
    documentStatusHistory?: Maybe<Array<Maybe<IDocumentStatusType>>>;
    /** Дополнительные настройки заявки */
    orderAttributes?: Maybe<IOrderAttributesType>;
    /** Страховка */
    insurance?: Maybe<IOrderInsuranceType>;
    /** Обязательно ли страховать перевозку */
    isInsuranceRequired?: Maybe<Scalars['Boolean']>;
    /** Контрагент */
    buyer?: Maybe<IBuyerType>;
    /** Договор ГО со страховой компанией */
    ownerInsuranceContract?: Maybe<IInsuranceContractType>;
    /** Фактическая организация-перевозчик (назначается ТЭКом), осуществляющая перевозку */
    actualCarrierOrganization?: Maybe<IAgentCarrierType>;
    /** Для завершения заявки необходимо указать информацию по транспортным накладнымдля всех мест доставки */
    waybillRequired?: Maybe<Scalars['Boolean']>;
    /** Информация для всех транспортных накладных указана */
    hasWaybillInfo?: Maybe<Scalars['Boolean']>;
    /** Перевозка осуществляется собственным транспортным средством ТЭКа */
    agentUsesOwnVehicle?: Maybe<Scalars['Boolean']>;
    /** Расшифровка фрахта и агентского вознаграждения в заявке с НДС и без НДС */
    agentFeeAndFreightDetails?: Maybe<IAgentFeeAndFreightDetails>;
    /** Генеральный договор с грузоотправителем */
    carrierContract?: Maybe<ICarrierContractType>;
    /** Дата и время создания заявки-договора(1С) */
    documentContractCreatedAt1c?: Maybe<Scalars['DateTime']>;
    /** Заявка ранее назначалась на этого ГП */
    isPreviouslyAssigned?: Maybe<Scalars['Boolean']>;
    /** Отгрузка обязательна в согласованную с покупателем дату и статус не завершенный */
    isShipmentOnAgreedDateAndStatusNotCompleted?: Maybe<Scalars['Boolean']>;
    /** Связанный тендер */
    tender?: Maybe<ITenderType>;
    /** Типы погрузки/разгрузки */
    loadingTypes?: Maybe<Scalars['String']>;
    /** Может ли пользователь выбрать другой генеральный договор */
    canChangeContract?: Maybe<Scalars['Boolean']>;
    /** Внешний ID заявки */
    extId?: Maybe<Scalars['String']>;
    /** Может ли ГП отменить заявку */
    canCancelOrder?: Maybe<Scalars['Boolean']>;
    /** Список документов, которые ещё находятся в обработке */
    genDocumentsPending?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** SLA организации */
    sla?: Maybe<ISlaType>;
    /** Статус партнерства перевозчика по отношению к владельцу заказа */
    carrierBusinessRelationshipToOwner?: Maybe<IOrderBusinessRelationShipToCarrier>;
    /** Информация о фактической организации-перевозчике */
    agentCarrierInfo?: Maybe<IAgentCarrierInfoType>;
    /** Информация о грузах */
    cargoInfo?: Maybe<Scalars['JSONString']>;
    /** Список грузов в заявке */
    cargos?: Maybe<Array<Maybe<ICargoType>>>;
    /** Настройки документооборота */
    documentFlowSettings?: Maybe<IDocumentFlowSettingsEnum>;
    /** Статус документооборота */
    edmStatus?: Maybe<Scalars['String']>;
    /** Причины невозможности сделать ставку */
    cantBetReasons?: Maybe<Array<Maybe<Scalars['String']>>>;
  }

  export type IAuctionOrderTypeLoadingPlaceArgs = {
    id?: Maybe<Scalars['ID']>;
  };

  export type IAuctionOrderTypeUnloadingPlaceArgs = {
    id?: Maybe<Scalars['ID']>;
  };

  export type IAuctionOrderTypeCoDriverArgs = {
    id?: Maybe<Scalars['ID']>;
  };

  export type IAuctionOrderTypeStatusOnEventArgs = {
    value?: Maybe<IOrderStatusOnEvent>;
  };

  export type IAuctionOrderTypeDocumentActualStatusArgs = {
    documentType?: Maybe<IDocumentTypeInnerNameEnum>;
  };

  export type IAuctionOrderTypeDocumentStatusHistoryArgs = {
    documentType?: Maybe<IDocumentTypeInnerNameEnum>;
  };

  export interface IAuctionParametersType {
    /** Длительность аукциона, указанная для текущей организации, в минутах */
    duration: Scalars['Int'];
    /** Дострочное завершение торгов */
    earlyFinish: Scalars['Boolean'];
    /** Тип шага цены */
    typeOfPriceStep: IAuctionParametersTypeOfPriceStep;
    /** Минимальный шаг торгов указанный для текущей организации, используется в том случае, если выбран тип фиксированного шага */
    minStepPrice: Scalars['Int'];
    /** Процент от начальной цены для расчета минимального шага торгов, %, используется в том случае, если выбран тип процента от стоимости */
    minPriceStepPercent: Scalars['Float'];
    /** Переторжка для аукционов включена */
    isProlongationAvailable: Scalars['Boolean'];
    /** Время в минутах, на которое продлевается аукцион */
    minutesOfProlongation: Scalars['Int'];
    /** Сколько раз можно продлевать аукцион */
    timesOfProlongation: Scalars['Int'];
    /** Время в минутах до конца аукциона, в которое может быть начата переторжка */
    minutesTillEndToProlong: Scalars['Int'];
  }

  /** An enumeration. */
  export enum IAuctionParametersTypeOfPriceStep {
    /** Фиксированный шаг */
    Fixed = 'FIXED',
    /** Процент от начальной стоимости для расчета шага */
    Percent = 'PERCENT',
  }

  /** Enum доступных записей */
  export enum IAvailabilityEnum {
    /** Все записи */
    All = 'ALL',
    /** Активные записи */
    Active = 'ACTIVE',
    /** Архивные записи */
    Archived = 'ARCHIVED',
  }

  /** Баннер-уведомление */
  export interface IBannerNotificationType {
    /** Текст уведомления */
    text: Scalars['String'];
    /** Дата и время окончания отображения уведомления */
    expireAt?: Maybe<Scalars['DateTime']>;
    /** Уведомление активно */
    isActive: Scalars['Boolean'];
  }

  export interface IBidOnTenderType {
    id: Scalars['ID'];
    /** Предложенная цена */
    price?: Maybe<Scalars['Int']>;
    /** Комментарий к ставке */
    comment?: Maybe<Scalars['String']>;
  }

  export interface IBiddingLotType {
    /** Дата и время начала аукциона */
    startDatetime: Scalars['DateTime'];
    /** Дата и время завершения аукциона */
    endDatetime: Scalars['DateTime'];
    /** Исходная цена */
    startPrice: Scalars['Int'];
    /** Минимальный шаг торгов */
    minStepPrice: Scalars['Int'];
    id?: Maybe<Scalars['ID']>;
    /** Заявка */
    order?: Maybe<IOrderType>;
    biddingStatus?: Maybe<Scalars['String']>;
    /** Время до завершения торгов (в секундах) */
    viewEndDatetime?: Maybe<Scalars['Int']>;
  }

  export interface IBiddingOrderType {
    /** Уникальный идентификатор заявки */
    id?: Maybe<Scalars['ID']>;
    /** Дата и время создания */
    createdDatetime?: Maybe<Scalars['DateTime']>;
    /** Вес */
    weight?: Maybe<Scalars['Float']>;
    /** Объём */
    volume?: Maybe<Scalars['Float']>;
    /** Стоимость */
    price?: Maybe<IPriceType>;
    /** Комментарий */
    comment?: Maybe<Scalars['String']>;
    /** Статус заявки */
    status?: Maybe<IOrderStatus>;
    /** Информация о водителе */
    driverInfo?: Maybe<Scalars['String']>;
    /** Информация об автомобиле */
    truckInfo?: Maybe<Scalars['String']>;
    /** Информация об автомобиле */
    trailerInfo?: Maybe<Scalars['String']>;
    /** Дата и время указания информации о водителе, автомобиле и прицепе */
    transportReservedDatetime?: Maybe<Scalars['DateTime']>;
    /** Дата и время загрузки скана заявки-договора */
    contractAttachedDatetime?: Maybe<Scalars['DateTime']>;
    /** Дата и время завершения оформления заявки */
    readyToGoDatetime?: Maybe<Scalars['DateTime']>;
    /** Тип размещения заявки */
    allocationType?: Maybe<IOrderAllocationType>;
    /** Цена включает НДС */
    isVatIncluded?: Maybe<Scalars['Boolean']>;
    /** Количество грузовых мест */
    cargoPackagesCount?: Maybe<Scalars['Int']>;
    /** Лот */
    lot?: Maybe<ILotType>;
    /** Лот торгов на повышение */
    biddinglot?: Maybe<IBiddingLotType>;
    /** Номер заявки во внешней системе */
    externalNo?: Maybe<Scalars['String']>;
    /** Признак размещения заявки на Рынке */
    isMarket?: Maybe<Scalars['Boolean']>;
    /** Сроки оплаты */
    paymentPeriod?: Maybe<Scalars['String']>;
    /** Дедлайн для обработки назначенной заявки */
    processDeadline?: Maybe<Scalars['DateTime']>;
    /** Дополнительные услуга по заявке */
    extraServices?: Maybe<Array<Maybe<IExtraServicesType>>>;
    /** Организация, указанная в качестве заказчика перевозки */
    customerOrganization?: Maybe<ICargoOwnerOrganizationType>;
    /** Признак того, каким образом происходит подписание заявок */
    signingWay?: Maybe<IOrderSigningWay>;
    /** Температурный режим (градусы Цельсия) */
    temperatureMode?: Maybe<Scalars['Int']>;
    /** Заявка только на просмотр */
    isReadonly?: Maybe<Scalars['Boolean']>;
    /** Время на бронирование свободной заявки, указанная для текущей заявки, в минутах */
    timeOrderReservation?: Maybe<Scalars['Int']>;
    /** Количество участников торговой процедуры */
    tradingParticipantsCount?: Maybe<Scalars['Int']>;
    /** Отгрузка обязательна в согласованную с покупателем дату */
    isShipmentOnAgreedDate?: Maybe<Scalars['Boolean']>;
    /** Виды номенклатуры */
    cargoNomenclatureTypes?: Maybe<Scalars['String']>;
    /** Дополнительное время на обработку назначенной заявки, в секундах */
    gracePeriod?: Maybe<Scalars['Int']>;
    /** Дата и время создания заявки на транспорт в 1С */
    deliveryRequestDateUtc?: Maybe<Scalars['DateTime']>;
    /** Оригинальная заявка */
    sourceOrder?: Maybe<IOrderType>;
    /** Перевозка для заказчиков DIY, например, Leroy Merlin */
    diy?: Maybe<Scalars['Boolean']>;
    /** Признак того, что в заявке не нарушен ни один критерий SLA */
    slaObserved?: Maybe<Scalars['Boolean']>;
    /** Тип груза */
    cargoType?: Maybe<Scalars['String']>;
    /** Наименование типа груза */
    cargoTypeName?: Maybe<Scalars['String']>;
    /** Получение информации о первом месте погрузки в заявке */
    loadingPlace?: Maybe<IStoragePointDataType>;
    /** Дата погрузки */
    loadingDate?: Maybe<Scalars['Date']>;
    /** Контактное лицо на первом месте погрузки в заявке */
    contactPersonAtLoadingPlace?: Maybe<Scalars['String']>;
    /** Получение информации о последнем месте доставки в заявке */
    unloadingPlace?: Maybe<IStoragePointDataType>;
    /** Дата доставки */
    unloadingDate?: Maybe<Scalars['Date']>;
    /** Контактное лицо на последнем месте доставки в заявке */
    contactPersonAtUnloadingPlace?: Maybe<Scalars['String']>;
    /** Список мест погрузки указанной заявки */
    loadingPlaces?: Maybe<Array<Maybe<IOrderStoragePointType>>>;
    /** Список мест доставки указанной заявки */
    unloadingPlaces?: Maybe<Array<Maybe<IOrderStoragePointType>>>;
    /** Получение информации о водителе */
    driver?: Maybe<IDriverDataType>;
    /** Получение информации о сменном водителе */
    coDriver?: Maybe<IDriverType>;
    /** Получение информации о ТС */
    vehicle?: Maybe<IVehicleDataType>;
    /** Информация о прицепе/полуприцепе */
    vehicletrailer?: Maybe<IVehicleTrailerDataType>;
    /** Дополнительная информация */
    extraData?: Maybe<Scalars['JSONString']>;
    /** Получение информации о требованиях к транспортному средству */
    vehicleRequirements?: Maybe<IVehicleRequirementsType>;
    /** Получение информации о требованиях к грузовому месту */
    cargoPlaceRequirements?: Maybe<ICargoPlaceRequirementsType>;
    /** Есть ли страховака */
    hasInsurance?: Maybe<Scalars['Boolean']>;
    /** Информация о менеджере заявки */
    owner?: Maybe<IOrderOwnerType>;
    /** Тип оплаты */
    paymentType?: Maybe<IPaymentTypeType>;
    /** Цена с НДС */
    priceWithVat?: Maybe<IPriceType>;
    /** Цена без НДС */
    priceWithoutVat?: Maybe<IPriceType>;
    /** Цена заявки-договора */
    contractPrice?: Maybe<Scalars['Float']>;
    /** Статус заявки по событиям из МЛ */
    statusOnEvent?: Maybe<Scalars['String']>;
    /**
     * Время завершения в заявке
     * @deprecated Для web и мобильных клиентов этот параметр заменен на viewEndDatetime.
     */
    endDatetime?: Maybe<Scalars['DateTime']>;
    /** Время завершения, которое рассчитывается для web-клиента, в зависимости от таймера в заявке */
    viewEndDatetime?: Maybe<Scalars['DateTime']>;
    /** Дедлайн для обработки назначенной заявки,который рассчитывается для web-клиента, в зависимости от таймера в заявке */
    viewProcessDeadline?: Maybe<Scalars['DateTime']>;
    /** Дедлайн для того чтобы забрать свободную заявку или сделать ставку для аукционной заявки,который рассчитывается для web-клиента, в зависимости от таймера в заявке */
    acceptDeadline?: Maybe<Scalars['DateTime']>;
    /**
     * Время до завершения заявки по времени(в секундах)
     * @deprecated Этот параметр в следующих версиях будет заменен на secondsToLifeTimeExpired.
     */
    secondsToCancel?: Maybe<Scalars['Int']>;
    /** Время до завершения заявки по времени жизни(в секундах) */
    secondsToLifeTimeExpired?: Maybe<Scalars['Int']>;
    /** Время до завершения заявки по времени бронирования(в секундах) */
    secondsToReservationTimeExpired?: Maybe<Scalars['Int']>;
    /** Может ли пользователь редактировать список загруженных документов */
    canModifyUploadedDocuments?: Maybe<Scalars['Boolean']>;
    /** Может ли пользователь отменить резерв транспорта(открепить ТС, водителя) */
    canUnreserveTransport?: Maybe<Scalars['Boolean']>;
    /** Загруженные документы */
    uploadedDocuments?: Maybe<Array<Maybe<IOrderUploadedDocumentType>>>;
    /** Загруженные документы */
    uploadedDocumentsV2?: Maybe<Array<Maybe<IOrderUploadedDocumentType>>>;
    /** Список типов файлов, которые необходимо подписывать */
    settingsESignRequired?: Maybe<Array<Maybe<IOrderDocumentTypeType>>>;
    /** Маршрутный лист по заявке */
    routeSheet?: Maybe<IRouteSheetType>;
    /** Текущий статус документа */
    documentActualStatus?: Maybe<Array<Maybe<IDocumentStatusType>>>;
    /** История статус по документу */
    documentStatusHistory?: Maybe<Array<Maybe<IDocumentStatusType>>>;
    /** Дополнительные настройки заявки */
    orderAttributes?: Maybe<IOrderAttributesType>;
    /** Страховка */
    insurance?: Maybe<IOrderInsuranceType>;
    /** Обязательно ли страховать перевозку */
    isInsuranceRequired?: Maybe<Scalars['Boolean']>;
    /** Контрагент */
    buyer?: Maybe<IBuyerType>;
    /** Договор ГО со страховой компанией */
    ownerInsuranceContract?: Maybe<IInsuranceContractType>;
    /** Фактическая организация-перевозчик (назначается ТЭКом), осуществляющая перевозку */
    actualCarrierOrganization?: Maybe<IAgentCarrierType>;
    /** Для завершения заявки необходимо указать информацию по транспортным накладнымдля всех мест доставки */
    waybillRequired?: Maybe<Scalars['Boolean']>;
    /** Информация для всех транспортных накладных указана */
    hasWaybillInfo?: Maybe<Scalars['Boolean']>;
    /** Перевозка осуществляется собственным транспортным средством ТЭКа */
    agentUsesOwnVehicle?: Maybe<Scalars['Boolean']>;
    /** Расшифровка фрахта и агентского вознаграждения в заявке с НДС и без НДС */
    agentFeeAndFreightDetails?: Maybe<IAgentFeeAndFreightDetails>;
    /** Генеральный договор с грузоотправителем */
    carrierContract?: Maybe<ICarrierContractType>;
    /** Дата и время создания заявки-договора(1С) */
    documentContractCreatedAt1c?: Maybe<Scalars['DateTime']>;
    /** Заявка ранее назначалась на этого ГП */
    isPreviouslyAssigned?: Maybe<Scalars['Boolean']>;
    /** Отгрузка обязательна в согласованную с покупателем дату и статус не завершенный */
    isShipmentOnAgreedDateAndStatusNotCompleted?: Maybe<Scalars['Boolean']>;
    /** Связанный тендер */
    tender?: Maybe<ITenderType>;
    /** Типы погрузки/разгрузки */
    loadingTypes?: Maybe<Scalars['String']>;
    /** Может ли пользователь выбрать другой генеральный договор */
    canChangeContract?: Maybe<Scalars['Boolean']>;
    /** Внешний ID заявки */
    extId?: Maybe<Scalars['String']>;
    /** Может ли ГП отменить заявку */
    canCancelOrder?: Maybe<Scalars['Boolean']>;
    /** Список документов, которые ещё находятся в обработке */
    genDocumentsPending?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** SLA организации */
    sla?: Maybe<ISlaType>;
    /** Статус партнерства перевозчика по отношению к владельцу заказа */
    carrierBusinessRelationshipToOwner?: Maybe<IOrderBusinessRelationShipToCarrier>;
    /** Информация о фактической организации-перевозчике */
    agentCarrierInfo?: Maybe<IAgentCarrierInfoType>;
    /** Информация о грузах */
    cargoInfo?: Maybe<Scalars['JSONString']>;
    /** Список грузов в заявке */
    cargos?: Maybe<Array<Maybe<ICargoType>>>;
    /** Настройки документооборота */
    documentFlowSettings?: Maybe<IDocumentFlowSettingsEnum>;
    /** Статус документооборота */
    edmStatus?: Maybe<Scalars['String']>;
    /** Ставка организации пользователя */
    myOrganizationBet?: Maybe<Scalars['Int']>;
    /** Заявка, назначенная по результатам торгов. */
    assignedOrder?: Maybe<IOrderType>;
    /** Статус торгов для ГП */
    biddingStatusForCarrier?: Maybe<Scalars['String']>;
  }

  export type IBiddingOrderTypeLoadingPlaceArgs = {
    id?: Maybe<Scalars['ID']>;
  };

  export type IBiddingOrderTypeUnloadingPlaceArgs = {
    id?: Maybe<Scalars['ID']>;
  };

  export type IBiddingOrderTypeCoDriverArgs = {
    id?: Maybe<Scalars['ID']>;
  };

  export type IBiddingOrderTypeStatusOnEventArgs = {
    value?: Maybe<IOrderStatusOnEvent>;
  };

  export type IBiddingOrderTypeDocumentActualStatusArgs = {
    documentType?: Maybe<IDocumentTypeInnerNameEnum>;
  };

  export type IBiddingOrderTypeDocumentStatusHistoryArgs = {
    documentType?: Maybe<IDocumentTypeInnerNameEnum>;
  };

  export type IBiddingOrderTypeBiddingStatusForCarrierArgs = {
    value?: Maybe<IBiddingStatusForCarrier>;
  };

  /**
   * PLACE_BET_AND_WON - участвовал и победил
   * PLACE_BET_AND_LOST - участвовал и проиграл
   * PLACE_BET_AND_CANCEL - участвовал и торги отменили
   * PLACE_BET_AND_WAIT - участвовал и ждет решение
   * NOT_PLACE_BET - не участвовал
   */
  export enum IBiddingStatusForCarrier {
    PlaceBetAndWon = 'PLACE_BET_AND_WON',
    PlaceBetAndLost = 'PLACE_BET_AND_LOST',
    PlaceBetAndCancel = 'PLACE_BET_AND_CANCEL',
    PlaceBetAndWait = 'PLACE_BET_AND_WAIT',
    NotPlaceBet = 'NOT_PLACE_BET',
  }

  /** Перечислитель признака рыночной/партнерской заявки */
  export enum IBiddingTypeEnum {
    /** И рыночные и партнерские заявки */
    All = 'ALL',
    /** Только рыночные заявки */
    Market = 'MARKET',
    /** Только партнерские заявки */
    Partner = 'PARTNER',
  }

  export interface IBlackListDriverType {
    id: Scalars['ID'];
    /** Грузоотправитель, к которому относится ЧС */
    cargoOwner: ICarrierOrganizationType;
    /** Дата и время вступление в силу ограничений */
    startDate: Scalars['DateTime'];
    /** Режим работы с объектом: False - предупреждение, True - блокировка */
    workProhibited: Scalars['Boolean'];
    /** Описание проблемы с объектом */
    description: Scalars['String'];
    /** Причина внесения объекта в чёрный список */
    reason: Scalars['String'];
  }

  export interface IBlackListVehicleTrailerType {
    id: Scalars['ID'];
    /** Грузоотправитель, к которому относится ЧС */
    cargoOwner: ICarrierOrganizationType;
    /** Дата и время вступление в силу ограничений */
    startDate: Scalars['DateTime'];
    /** Режим работы с объектом: False - предупреждение, True - блокировка */
    workProhibited: Scalars['Boolean'];
    /** Описание проблемы с объектом */
    description: Scalars['String'];
    /** Причина внесения объекта в чёрный список */
    reason: Scalars['String'];
    /** Виды номенклатуры */
    nomenclatureType?: Maybe<Scalars['String']>;
  }

  export interface IBlackListVehicleType {
    id: Scalars['ID'];
    /** Грузоотправитель, к которому относится ЧС */
    cargoOwner: ICarrierOrganizationType;
    /** Дата и время вступление в силу ограничений */
    startDate: Scalars['DateTime'];
    /** Режим работы с объектом: False - предупреждение, True - блокировка */
    workProhibited: Scalars['Boolean'];
    /** Описание проблемы с объектом */
    description: Scalars['String'];
    /** Причина внесения объекта в чёрный список */
    reason: Scalars['String'];
  }

  /** Информация о подтипе кузова транспортного средства */
  export interface IBodySubtypeType {
    id: Scalars['ID'];
    /** Наименование подтипа кузова ТС */
    name: Scalars['String'];
  }

  /** Тип кузова транспортного средства */
  export interface IBodyTypeType {
    id: Scalars['ID'];
    /** Наименование типа кузова ТС */
    name: Scalars['String'];
    /** Получение списка всех доступных подтипов кузова */
    subtypes?: Maybe<Array<Maybe<IBodySubtypeType>>>;
  }

  export interface IBuyerType {
    id: Scalars['ID'];
    /** ИНН */
    inn: Scalars['String'];
    /** Наименование организации */
    name: Scalars['String'];
    /** Полное наименование организации */
    fullName?: Maybe<Scalars['String']>;
    /** Юридический адрес */
    legalAddress: Scalars['String'];
    /** Вид организации (Юр.лицо, Физ.лицо) */
    juridical: Scalars['Boolean'];
    /** Сокращённое название организационно-правовой формы */
    form?: Maybe<Scalars['String']>;
  }

  export interface ICargoOwnerOrganizationType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    /** Дата и время аккредитации */
    accrAtDt?: Maybe<Scalars['DateTime']>;
    /** Аккредитован - ДА или НЕТ */
    accrValue: Scalars['Boolean'];
    /** Дата и время окончания аккредитации */
    accrExpAt?: Maybe<Scalars['DateTime']>;
    /** Ссылка на объект risk profile */
    accrProfile?: Maybe<Scalars['String']>;
    role: IOrganizationRole;
    /** Тип НДС по умолчанию. */
    vat?: Maybe<IVatType>;
    /** Название организации */
    name: Scalars['String'];
    /** Подписывающее лицо (Фамилия И. О.) */
    signer: Scalars['String'];
    /** Описание организации */
    about?: Maybe<Scalars['String']>;
    /** Телефон организации */
    phoneNumber?: Maybe<Scalars['String']>;
    /** Электронная почта */
    email?: Maybe<Scalars['String']>;
    /** Является группой компаний */
    isCompanyGroup: Scalars['Boolean'];
    /** Головная компания */
    parent?: Maybe<ICarrierOrganizationType>;
    /** Организационно-правовая форма организации */
    legalForm?: Maybe<ILegalFormType>;
    /** ИНН */
    inn?: Maybe<Scalars['String']>;
    /** КПП */
    kpp?: Maybe<Scalars['String']>;
    /** Юридический адрес */
    legalAddress?: Maybe<Scalars['String']>;
    /** Почтовый адрес */
    postalAddress?: Maybe<Scalars['String']>;
    /** ОГРН/ОГРНИП */
    registrationNumber?: Maybe<Scalars['String']>;
    /** Тип оплаты */
    paymentType?: Maybe<IPaymentTypeType>;
    /** Сроки оплаты */
    paymentPeriod?: Maybe<Scalars['String']>;
    /** Показывать номер заявки из внешней системы */
    showOrderExternalNo: Scalars['Boolean'];
    /** Настройки документооборота */
    documentFlowSettings?: Maybe<IOrganizationDocumentFlowSettings>;
    /** Дополнительные условия при добавлении заявки на рынке */
    extraConditionsForMarket?: Maybe<Scalars['String']>;
    /** Организация TN */
    isTn: Scalars['Boolean'];
    /** Включить возможность отслеживания заявок */
    enableDeliveryTracking: Scalars['Boolean'];
    /** Необходима дополнительная аккредитация ГП от ГВ */
    requiresCarrierAccrOnMarket: Scalars['Boolean'];
    /** Параметр, обозначающий pk настроек персонализации */
    personalizationSettings?: Maybe<Scalars['ID']>;
    /** Параметр, отвечающий за поддомен организации */
    subdomain?: Maybe<Scalars['String']>;
    /** Параметр, отвечающий за URL логотипа организации */
    logo?: Maybe<Scalars['String']>;
    /** Общие параметры аукционов организации */
    auctionParameters?: Maybe<IAuctionParametersType>;
    /** Настройки страхования для организации */
    insuranceParameters?: Maybe<IInsuranceParametersType>;
    /** Дополнительные услуги, доступные организации */
    allowedExtraServices?: Maybe<Array<Maybe<IExtraServicesType>>>;
    /** Статус задачи */
    accrStatus?: Maybe<IAccreditationStatusEnum>;
    /** Публичная оферта грузовладельца */
    publicOffer?: Maybe<Scalars['String']>;
  }

  /** An enumeration. */
  export enum ICargoPlaceRequirementsPackagingType {
    /** Паллеты */
    Pallets = 'PALLETS',
    /** Коробки */
    Boxes = 'BOXES',
    /** Пачки */
    Bundles = 'BUNDLES',
    /** Россыпь */
    Placer = 'PLACER',
  }

  export interface ICargoPlaceRequirementsType {
    /** Вид упаковки */
    packagingType?: Maybe<ICargoPlaceRequirementsPackagingType>;
    /** Длина грузового места */
    length?: Maybe<Scalars['Float']>;
    /** Ширина грузового места */
    width?: Maybe<Scalars['Float']>;
    /** Высота грузового места */
    height?: Maybe<Scalars['Float']>;
  }

  export enum ICargoStatus {
    /** Не выполнялся */
    NotStarted = 'not_started',
    /** Загружен */
    Loaded = 'loaded',
    /** Доставлен */
    Delivered = 'delivered',
    /** Не выполнен */
    Cancelled = 'cancelled',
    /** Частично доставлен */
    PartlyDelivered = 'partly_delivered',
  }

  export interface ICargoType {
    /** Уникальный идентификатор груза */
    cargoId?: Maybe<Scalars['String']>;
    /** Статус груза */
    status: ICargoStatus;
    /** Наименование груза */
    name?: Maybe<Scalars['String']>;
    /** Ссылка на точку загрузки */
    loadingPointId?: Maybe<Scalars['Int']>;
    /** Размеры/количество груза */
    size?: Maybe<IDeliverySizeType>;
    /** Единицы доставки */
    deliveryUnits?: Maybe<Array<Maybe<IDeliveryUnit>>>;
  }

  /** An enumeration. */
  export enum ICarrierContractAgentType {
    /** Агент */
    Agent = 'AGENT',
    /** Экспедитор */
    Expeditor = 'EXPEDITOR',
    /** Собственник ТС */
    Owner = 'OWNER',
  }

  /** An enumeration. */
  export enum ICarrierContractContractType {
    /** Агентский */
    Agent = 'AGENT',
    /** С поставщиком */
    Supplier = 'SUPPLIER',
  }

  export interface ICarrierContractType {
    id: Scalars['ID'];
    /** Номер договора */
    contractNumber: Scalars['String'];
    /** Наименование */
    name?: Maybe<Scalars['String']>;
    /** Вид договора */
    contractType?: Maybe<ICarrierContractContractType>;
    /** Тип агента в договоре (если он указан) */
    agentType: ICarrierContractAgentType;
    /** Активность договора (не архивный и не просроченный) */
    isActive?: Maybe<Scalars['Boolean']>;
    /** Организация ГВ */
    cargoOwner?: Maybe<IOrganizationForCargoOwnerType>;
  }

  /** GraphQL-мутаторы для грузоперевозчика */
  export interface ICarrierMutations {
    _debug?: Maybe<IDjangoDebug>;
    /** Добавление нового сотрудника организации */
    addEmployee?: Maybe<IEmployeeType>;
    /** Изменение данных сотрудника организации */
    editEmployee?: Maybe<IEmployeeType>;
    /** Отправка сотрудника организации в архив */
    sendEmployeeToArchive?: Maybe<IEmployeeType>;
    /** Сброс пароля сотрудника организации */
    resetEmployeePassword?: Maybe<IEmployeeType>;
    /** Добавить учредительный документ */
    addConstituentDocument?: Maybe<IConstituentDocumentType>;
    /** Удалить учредительный документ */
    removeConstituentDocument?: Maybe<IConstituentDocumentType>;
    /** Редактирование профиля пользователя */
    editProfile?: Maybe<IProfileType>;
    /** Изменение настроек профиля пользователя */
    editProfileSettings?: Maybe<IProfileType>;
    /**
     * Загрузить файл.
     * Мин. размер 1 б
     * Макс. размер 20 Мб
     * Поддерживаемые типы:
     * * application/msword
     * * application/pdf
     * * application/vnd.ms-excel
     * * application/vnd.oasis.opendocument.spreadsheet
     * * application/vnd.oasis.opendocument.text
     * * application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
     * * application/vnd.openxmlformats-officedocument.wordprocessingml.document
     * * image/jpeg
     * * image/png
     * * image/tiff
     * * image/x-tiff
     */
    uploadFile?: Maybe<IUploadedFileType>;
    /** Пометить уведомление как прочитанное */
    markNotificationAsRead?: Maybe<Scalars['Boolean']>;
    /** Пометить все уведомления пользователя как прочитанные */
    markAllNotificationsAsRead?: Maybe<Scalars['Boolean']>;
    /** Добавление нового ГП для агента (ТЭК) */
    addAgentCarrier?: Maybe<IAgentCarrierType>;
    /** Изменение ГП для агента (ТЭК) */
    editAgentCarrier?: Maybe<IAgentCarrierType>;
    /** Перенос ГП агента (ТЭК) в архив */
    sendAgentCarrierToArchive?: Maybe<IAgentCarrierType>;
    /** Сделать ставку */
    placeBet?: Maybe<ILotType>;
    /** Добавление нового водителя */
    addDriver?: Maybe<IDriverType>;
    /** Изменение информации о водителе */
    editDriver?: Maybe<IDriverType>;
    /** Перенос имеющегося водителя в архив */
    sendDriverToArchive?: Maybe<IDriverType>;
    /** Пригласить водителя установить мобильное приложение */
    inviteDriverToMobile?: Maybe<Scalars['Boolean']>;
    /** Создать профиль аккредитации водителя */
    createDriverRiskProfile?: Maybe<ICreateDriverRiskProfile>;
    /** Добавить досье для аккредитации водителя */
    addDriverRiskProfileDossier?: Maybe<IAddDriverRiskProfileDossier>;
    /** Отправить профиль риска водителя на проверку */
    submitDriverRiskProfile?: Maybe<ISubmitDriverRiskProfile>;
    /** Добавить учредительный документ */
    addOrganizationUploadedDocument?: Maybe<IOrganizationUploadedDocumentType>;
    /** Удалить учредительный документ */
    removeOrganizationUploadedDocument?: Maybe<IOrganizationUploadedDocumentType>;
    /** Добавление страхования к заявке */
    addInsuranceOrder?: Maybe<IOrderType>;
    /** Забронировать заявку */
    bookOrder?: Maybe<IOrderType>;
    /** Отказаться от заявки */
    cancelOrder?: Maybe<IOrderType>;
    /**
     * Устаревший метод. Указать информацию о водителе, автомобиле и прицепе
     * @deprecated Устаревший метод. Не следует использовать
     */
    reserveTransport?: Maybe<IOrderType>;
    /** Указать водителя и ТС из справочника, информацию о прицепе */
    reserveTransportV2?: Maybe<IOrderType>;
    /** Указать сменного водителя */
    setCoDriver?: Maybe<IOrderType>;
    /**
     * Устаревший метод. Прикрепить скан заявки-договора
     * @deprecated Устаревший метод. Не следует использовать
     */
    attachContract?: Maybe<IOrderType>;
    /** Завершить процесс оформления заявки */
    letsGo?: Maybe<IOrderType>;
    /** Завершить процесс оформления заявки (упрощённый вариант для мобильного приложения) */
    letsGoForMobile?: Maybe<IOrderType>;
    /** Завершить заявку */
    completeOrder?: Maybe<IOrderType>;
    /** Сделать ставку в торгах на повышение */
    placeBiddingBet?: Maybe<IBiddingLotType>;
    /** Отменить резерв транспорта */
    unreserveTransport?: Maybe<IOrderType>;
    /** Редактирование загруженных документов в заявке */
    editOrderUploadedDocuments?: Maybe<Array<Maybe<IOrderUploadedDocumentType>>>;
    /** Редактирование организации */
    editOrganization?: Maybe<ICarrierOrganizationType>;
    /** Создать профиль аккредитации ГП */
    createCarrierRiskProfile?: Maybe<ICreateCarrierRiskProfile>;
    /** Добавить досье для аккредитации ГП */
    addCarrierRiskProfileDossier?: Maybe<IAddCarrierRiskProfileDossier>;
    /** Отправить профиль риска ГП на проверку */
    submitCarrierRiskProfile?: Maybe<ISubmitCarrierRiskProfile>;
    /** Ответить на запрос цены */
    respondOnPriceQuotation?: Maybe<IPriceQuotationType>;
    /** Добавление нового акта оказанных услуг */
    addCompletedOrdersReport?: Maybe<ICompletedOrdersReportType>;
    /** Редактирование акта оказанных услуг */
    editCompletedOrdersReport?: Maybe<ICompletedOrdersReportType>;
    /** Добавление нового транспортного средства */
    addVehicleOnTMS?: Maybe<IVehicleType>;
    /** Изменение информации о транспортном средстве */
    editVehicle?: Maybe<IVehicleType>;
    /** Перенос имеющегося водителя в архив */
    sendVehicleToArchive?: Maybe<IVehicleType>;
    /** Создать профиль аккредитации ТС */
    createVehicleRiskProfile?: Maybe<ICreateVehicleRiskProfile>;
    /** Добавить досье для аккредитации ТС */
    addVehicleRiskProfileDossier?: Maybe<IAddVehicleRiskProfileDossier>;
    /** Отправить профиль риска ТС на проверку */
    submitVehicleRiskProfile?: Maybe<ISubmitVehicleRiskProfile>;
    /** Добавление предложения транспортного средства */
    addVehicleAvailability?: Maybe<IVehicleAvailabilityType>;
    /** Создать профиль аккредитации Предложения ТС */
    createVehicleAvailabilityRiskProfile?: Maybe<ICreateVehicleAvailabilityRiskProfile>;
    /** Добавить досье для аккредитации Предложения ТС */
    addVehicleAvailabilityRiskProfileDossier?: Maybe<IAddVehicleAvailabilityRiskProfileDossier>;
    /** Отправить профиль риска Предложения ТС на проверку */
    submitVehicleAvailabilityRiskProfile?: Maybe<ISubmitVehicleAvailabilityRiskProfile>;
    /** Предложить цену за лот (тендер) */
    addBidOnTender?: Maybe<ITenderType>;
  }

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsAddEmployeeArgs = {
    input?: Maybe<IAddEmployeeInput>;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsEditEmployeeArgs = {
    input?: Maybe<IEditEmployeeInput>;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsSendEmployeeToArchiveArgs = {
    employeeId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsResetEmployeePasswordArgs = {
    employeeId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsAddConstituentDocumentArgs = {
    file: Scalars['Upload'];
    name: Scalars['String'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsRemoveConstituentDocumentArgs = {
    docId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsEditProfileArgs = {
    emailInput?: Maybe<IEditProfileEmailInput>;
    passwordInput?: Maybe<IEditProfilePasswordInput>;
    profileInput?: Maybe<IEditProfileInput>;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsEditProfileSettingsArgs = {
    input?: Maybe<IEditProfileSettingsInput>;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsUploadFileArgs = {
    input: IUploadFileInput;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsMarkNotificationAsReadArgs = {
    notificationId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsAddAgentCarrierArgs = {
    input?: Maybe<IAddAgentCarrierInput>;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsEditAgentCarrierArgs = {
    input?: Maybe<IEditAgentCarrierInput>;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsSendAgentCarrierToArchiveArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsPlaceBetArgs = {
    bet: Scalars['Int'];
    orderId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsAddDriverArgs = {
    input?: Maybe<IAddDriverInput>;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsEditDriverArgs = {
    input?: Maybe<IEditDriverInput>;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsSendDriverToArchiveArgs = {
    driverId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsInviteDriverToMobileArgs = {
    driverId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsCreateDriverRiskProfileArgs = {
    driverId: Scalars['Int'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsAddDriverRiskProfileDossierArgs = {
    docType: Scalars['String'];
    docVal: Scalars['String'];
    profileId: Scalars['String'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsSubmitDriverRiskProfileArgs = {
    driverId: Scalars['ID'];
    profileId: Scalars['String'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsAddOrganizationUploadedDocumentArgs = {
    documentTypeId: Scalars['ID'];
    uploadedFileId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsRemoveOrganizationUploadedDocumentArgs = {
    docId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsAddInsuranceOrderArgs = {
    input?: Maybe<IAddInsuranceOrderInput>;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsBookOrderArgs = {
    orderId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsCancelOrderArgs = {
    orderId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsReserveTransportArgs = {
    driverInfo: Scalars['String'];
    orderId: Scalars['ID'];
    trailerInfo: Scalars['String'];
    truckInfo: Scalars['String'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsReserveTransportV2Args = {
    agentCarrierId?: Maybe<Scalars['ID']>;
    agentCarrierInfo?: Maybe<IAgentCarrierInfoInput>;
    carrierContractId?: Maybe<Scalars['ID']>;
    driverId: Scalars['ID'];
    freightAmount?: Maybe<Scalars['Float']>;
    orderId: Scalars['ID'];
    vehicleId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsSetCoDriverArgs = {
    coDriverId?: Maybe<Scalars['ID']>;
    orderId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsAttachContractArgs = {
    file: Scalars['Upload'];
    orderId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsLetsGoArgs = {
    orderId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsLetsGoForMobileArgs = {
    orderId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsCompleteOrderArgs = {
    orderId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsPlaceBiddingBetArgs = {
    bet: Scalars['Int'];
    orderId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsUnreserveTransportArgs = {
    orderId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsEditOrderUploadedDocumentsArgs = {
    input: IEditOrderUploadedDocumentsInput;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsEditOrganizationArgs = {
    input?: Maybe<IEditOrganizationInput>;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsAddCarrierRiskProfileDossierArgs = {
    docType: Scalars['String'];
    docVal: Scalars['String'];
    profileId: Scalars['String'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsSubmitCarrierRiskProfileArgs = {
    profileId: Scalars['String'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsRespondOnPriceQuotationArgs = {
    input?: Maybe<IRespondOnPriceQuotationInput>;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsAddCompletedOrdersReportArgs = {
    input?: Maybe<IAddCompletedOrdersReportInput>;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsEditCompletedOrdersReportArgs = {
    input?: Maybe<IEditCompletedOrdersReportInput>;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsAddVehicleOnTmsArgs = {
    input?: Maybe<IVehicleOnTmsInput>;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsEditVehicleArgs = {
    input?: Maybe<IEditVehicleInput>;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsSendVehicleToArchiveArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsCreateVehicleRiskProfileArgs = {
    vehicleId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsAddVehicleRiskProfileDossierArgs = {
    docType: Scalars['String'];
    docVal: Scalars['String'];
    profileId: Scalars['String'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsSubmitVehicleRiskProfileArgs = {
    profileId: Scalars['String'];
    vehicleId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsAddVehicleAvailabilityArgs = {
    input: IVehicleAvailabilityInput;
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsCreateVehicleAvailabilityRiskProfileArgs = {
    vehicleAvailabilityId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsAddVehicleAvailabilityRiskProfileDossierArgs = {
    docType: Scalars['String'];
    docVal: Scalars['String'];
    profileId: Scalars['String'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsSubmitVehicleAvailabilityRiskProfileArgs = {
    profileId: Scalars['String'];
    vehicleAvailabilityId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоперевозчика */
  export type ICarrierMutationsAddBidOnTenderArgs = {
    input?: Maybe<IAddBidOnTenderInput>;
  };

  export interface ICarrierOrganizationType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    /** Дата и время аккредитации */
    accrAtDt?: Maybe<Scalars['DateTime']>;
    /** Аккредитован - ДА или НЕТ */
    accrValue: Scalars['Boolean'];
    /** Дата и время окончания аккредитации */
    accrExpAt?: Maybe<Scalars['DateTime']>;
    /** Ссылка на объект risk profile */
    accrProfile?: Maybe<Scalars['String']>;
    role: IOrganizationRole;
    /** Тип НДС по умолчанию. */
    vat?: Maybe<IVatType>;
    /** Название организации */
    name: Scalars['String'];
    /** Подписывающее лицо (Фамилия И. О.) */
    signer: Scalars['String'];
    /** Описание организации */
    about?: Maybe<Scalars['String']>;
    /** Телефон организации */
    phoneNumber?: Maybe<Scalars['String']>;
    /** Электронная почта */
    email?: Maybe<Scalars['String']>;
    /** Является группой компаний */
    isCompanyGroup: Scalars['Boolean'];
    /** Организационно-правовая форма организации */
    legalForm?: Maybe<ILegalFormType>;
    /** ИНН */
    inn?: Maybe<Scalars['String']>;
    /** КПП */
    kpp?: Maybe<Scalars['String']>;
    /** Юридический адрес */
    legalAddress?: Maybe<Scalars['String']>;
    /** Почтовый адрес */
    postalAddress?: Maybe<Scalars['String']>;
    /** ОГРН/ОГРНИП */
    registrationNumber?: Maybe<Scalars['String']>;
    /** Тип оплаты */
    paymentType?: Maybe<IPaymentTypeType>;
    /** Сроки оплаты */
    paymentPeriod?: Maybe<Scalars['String']>;
    /** Показывать номер заявки из внешней системы */
    showOrderExternalNo: Scalars['Boolean'];
    /** Дополнительные условия при добавлении заявки на рынке */
    extraConditionsForMarket?: Maybe<Scalars['String']>;
    /** Организация TN */
    isTn: Scalars['Boolean'];
    /** Включить возможность отслеживания заявок */
    enableDeliveryTracking: Scalars['Boolean'];
    /** Дата и время создания */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Комментарий к результату аккредитации */
    accrComment?: Maybe<Scalars['String']>;
    /** Является ли организация существующей (настоящей) */
    isReal: Scalars['Boolean'];
    /** Дата выдачи ОГРН */
    ogrnDate?: Maybe<Scalars['Date']>;
    /** Источник создания организации */
    registrationSource: IOrganizationRegistrationSource;
    /** Представитель организации */
    headOfOrganization?: Maybe<IHeadOfOrganizationType>;
    /** Параметры безопасного пространства */
    trustedOrderPreference?: Maybe<ITrustedOrderPreferenceType>;
    /** Параметры рынка */
    marketAttributes?: Maybe<IOrganizationMarketAttributesType>;
    /** Организация имеет доступ в ТМС */
    hasTmsAccess?: Maybe<Scalars['Boolean']>;
    /** Организация имеет доступ к Рынку */
    hasMarketAccess?: Maybe<Scalars['Boolean']>;
    /** Общие параметры аукционов организации */
    auctionParameters?: Maybe<IAuctionParametersType>;
    /** Настройки страхования для организации */
    insuranceParameters?: Maybe<IInsuranceParametersType>;
    /** Статус задачи */
    accrStatus?: Maybe<IAccreditationStatus>;
  }

  /** GraphQL-запросы для грузоперевозчика */
  export interface ICarrierQuery {
    _debug?: Maybe<IDjangoDebug>;
    /** Получение информации о типе кузова ТС и соответствующих подтипов */
    bodyType?: Maybe<IBodyTypeType>;
    /** Получение справочника типов кузова транспортных средств и соответствующих подтипов */
    bodyTypes?: Maybe<Array<Maybe<IBodyTypeType>>>;
    /** Получение справочника подтипов кузова транспортных средств */
    bodySubtypes?: Maybe<Array<Maybe<IBodySubtypeType>>>;
    /** Список всех сотрудников организации */
    organizationEmployees?: Maybe<Array<Maybe<IEmployeeType>>>;
    /** Информация о сотруднике своей организации */
    organizationEmployee?: Maybe<IEmployeeType>;
    /** Общее количество объектов */
    organizationEmployeesCount?: Maybe<Scalars['Int']>;
    /** Получение списка организационно-правовых форм организаций */
    legalForms?: Maybe<Array<Maybe<ILegalFormType>>>;
    /** Получение информации о типе погрузки/разгрузки */
    loadingType?: Maybe<ILoadingTypeType>;
    /** Получение списка всех типов погрузки/разгрузки */
    loadingTypes?: Maybe<Array<Maybe<ILoadingTypeType>>>;
    /** Типы документов для организации */
    organizationDocumentTypes?: Maybe<Array<Maybe<IDocumentTypeType>>>;
    /** Получение информации о типе оплаты */
    paymentType?: Maybe<IPaymentTypeType>;
    /** Получение списка всех типов оплаты */
    paymentTypes?: Maybe<Array<Maybe<IPaymentTypeType>>>;
    /** Список прав пользователя в системе */
    allPermissions?: Maybe<Array<Maybe<IPermissionType>>>;
    /** Профиль пользователя */
    profile?: Maybe<IProfileType>;
    /** Получение информации о всех НДС */
    vatTypes?: Maybe<Array<Maybe<IVatType>>>;
    /** Получение информации о всех часовых поясах */
    timezones?: Maybe<Array<Maybe<ITimeZoneType>>>;
    /** Получение учредительного документа */
    constituentDocument?: Maybe<IConstituentDocumentType>;
    /** Учредительные документы */
    myConstituentDocuments?: Maybe<Array<Maybe<IConstituentDocumentType>>>;
    /** Получение уведомления по ID */
    notification?: Maybe<INotificationType>;
    /** Получение списка уведомлений */
    notifications?: Maybe<Array<Maybe<INotificationType>>>;
    /** Общее количество объектов */
    notificationsCount?: Maybe<Scalars['Int']>;
    /** Получение баннер-уведомления */
    bannerNotification?: Maybe<IBannerNotificationType>;
    /** Получение информации о ГП агента (ТЭК) */
    agentCarrier?: Maybe<IAgentCarrierType>;
    /** Получение списка всех ГП агента (ТЭК) */
    agentCarriers?: Maybe<Array<Maybe<IAgentCarrierType>>>;
    /** Общее количество объектов */
    agentCarriersCount?: Maybe<Scalars['Int']>;
    /** Получение списка аукционов */
    lots?: Maybe<Array<Maybe<ILotType>>>;
    /** Получение информации об аукционе */
    lot?: Maybe<ILotType>;
    /** Получение списка аукционов, в которых принимал участие ГП */
    lotsWithCarrierBet?: Maybe<Array<Maybe<ILotType>>>;
    listPackagingTypesForCargoType?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Получение списка генеральных договоров с ТехноНиколь */
    carrierContractsWithTn?: Maybe<Array<Maybe<ICarrierContractType>>>;
    /** Получение списка генеральных договоров с ГО */
    carrierContractsWithCargoOwner?: Maybe<Array<Maybe<ICarrierContractType>>>;
    /** Получение состояния деловых отношений между ГП и ГВ */
    stateBusinessRelationship?: Maybe<Scalars['String']>;
    /** Общее количество объектов */
    carrierContractsWithTnCount?: Maybe<Scalars['Int']>;
    /** Общее количество объектов */
    carrierContractsWithCargoOwnerCount?: Maybe<Scalars['Int']>;
    /** Подготовленный профиль пользователя, с данными, пришедшими из ЦП */
    carrierUser?: Maybe<ICarrierUserType>;
    /** Получение документа, прикрепленного к организации */
    organizationDocument?: Maybe<IOrganizationUploadedDocumentType>;
    /** Документы, прикрепленные к организации */
    organizationDocuments?: Maybe<Array<Maybe<IOrganizationUploadedDocumentType>>>;
    /** Получение информации о водителе */
    driver?: Maybe<IDriverType>;
    /** Автомобили */
    driverVehicles?: Maybe<Array<Maybe<IVehicleType>>>;
    /** Получение списка всех водителей */
    drivers?: Maybe<Array<Maybe<IDriverType>>>;
    /** Количество водителей */
    driversCount?: Maybe<Scalars['Int']>;
    /** Список договоров с СК */
    myInsuranceContracts?: Maybe<Array<Maybe<IInsuranceContractType>>>;
    /** Получение информации о договоре со страховой компанией */
    insuranceContract?: Maybe<IInsuranceContractType>;
    /** Информация о данных, необходимых для страхования */
    insuranceInitialInfo?: Maybe<IInsuranceInitialInfoType>;
    /** Предварительный подсчёт стоимости страхования */
    insuranceCost?: Maybe<Scalars['Float']>;
    /** Отмена страхования заявки */
    cancelInsurance?: Maybe<Scalars['Boolean']>;
    /** Список страховых компаний */
    insuranceCompanies?: Maybe<Array<Maybe<IInsuranceCompanyType>>>;
    /** Список доступных классов грузов */
    insuranceCargoClasses?: Maybe<Array<Maybe<IInsuranceReferenceType>>>;
    /** Список доступных типов грузов */
    insuranceCargoKinds?: Maybe<Array<Maybe<IInsuranceReferenceType>>>;
    /** Общее количество объектов */
    myInsuranceContractsCount?: Maybe<Scalars['Int']>;
    order?: Maybe<IOrderType>;
    /** Получение списка всех заявок доступных грузоперевозчику */
    allOrders?: Maybe<Array<Maybe<IOrderType>>>;
    auctionOrder?: Maybe<IAuctionOrderType>;
    /** Получение списка аукционных заявок доступных грузоперевозчику */
    auctionOrders?: Maybe<Array<Maybe<IAuctionOrderType>>>;
    biddingOrder?: Maybe<IBiddingOrderType>;
    /** Получение списка торгов на повышение, доступных грузоперевозчику */
    biddingOrders?: Maybe<Array<Maybe<IBiddingOrderType>>>;
    biddingLot?: Maybe<IBiddingLotType>;
    biddingLots?: Maybe<Array<Maybe<IBiddingLotType>>>;
    /** Получение списка заявок назначенных на грузоперевозчика */
    myOrders?: Maybe<Array<Maybe<IOrderType>>>;
    /** Получение списка заявок, в которых грузоперевозчик делал ставку */
    myAuctionOrders?: Maybe<Array<Maybe<IOrderType>>>;
    /** Информация о контактном лице */
    contactPersonAtStoragePoints?: Maybe<Array<Maybe<IContactPersonAtStoragePointType>>>;
    /** Предварительная расшифровка сумм фрахта и агентского вознаграждения в заявке. */
    agentFeeAndFreightDetails?: Maybe<IAgentFeeAndFreightDetails>;
    /** Получение списка выполненных заявок для ТН */
    completedTnOrders?: Maybe<Array<Maybe<IOrderType>>>;
    /** Счетчики заявок ГП */
    ordersCounters?: Maybe<IOrdersCountersType>;
    /** Общее количество объектов */
    myOrdersCount?: Maybe<Scalars['Int']>;
    /** Общее количество объектов */
    auctionOrdersCount?: Maybe<Scalars['Int']>;
    /** Общее количество объектов */
    myAuctionOrdersCount?: Maybe<Scalars['Int']>;
    /** Общее количество объектов */
    biddingOrdersCount?: Maybe<Scalars['Int']>;
    /** Общее количество объектов */
    completedTnOrdersCount?: Maybe<Scalars['Int']>;
    /** Общее количество объектов */
    allOrdersCount?: Maybe<Scalars['Int']>;
    /** Получение списка обычных и назначенных заявок доступных грузоперевозчику */
    orders?: Maybe<Array<Maybe<IOrderType>>>;
    /** Количество обычных и назначенных заявок доступных грузоперевозчику */
    ordersCount?: Maybe<Scalars['Int']>;
    /** Типы документов для заявки */
    orderDocumentTypes?: Maybe<Array<Maybe<IOrderDocumentTypeType>>>;
    /** Получение информации по профилю организации */
    organization?: Maybe<ICarrierOrganizationType>;
    /** Получение данных организации по ИНН */
    organizationDataByInn?: Maybe<IOrganizationDataType>;
    /** Получение информации об организации-грузовладельце */
    cargoOwnerOrganization?: Maybe<IOrganizationForCargoOwnerType>;
    /** Список всех ГВ, связанных с конкретным ГП деловым отношением, кроме состояния отказано */
    cargoOwnerWithBusinessRelationshipWithCarrier?: Maybe<Array<Maybe<IOrganizationForCargoOwnerType>>>;
    /** Список всех ГВ, связанных с конкретным ГП деловым отношением */
    cargoOwnersDataWithBusinessRelationship?: Maybe<Array<Maybe<IOrganizationForCargoOwnerType>>>;
    /** Получение информации о запросах цен */
    priceQuotations?: Maybe<Array<Maybe<IPriceQuotationType>>>;
    /** Получение информации о запросе цены */
    priceQuotation?: Maybe<IPriceQuotationType>;
    /** Общее количество объектов */
    priceQuotationsCount?: Maybe<Scalars['Int']>;
    /** Получение списка отчетов рейтингов грузоперевозчика */
    carrierRatingReports?: Maybe<Array<Maybe<ICarrierRatingReportType>>>;
    /** Получение списка деталей отчетов рейтингов грузоперевозчика */
    carrierRatingReportDetails?: Maybe<Array<Maybe<ICarrierRatingReportDetailType>>>;
    /** Получение списка заводов отчетов рейтингов грузоперевозчика */
    carrierRatingDepartments?: Maybe<Array<Maybe<ICarrierRatingReportDepartmentType>>>;
    /** Общее количество объектов */
    carrierRatingReportsCount?: Maybe<Scalars['Int']>;
    /** Общее количество объектов */
    carrierRatingReportDetailsCount?: Maybe<Scalars['Int']>;
    /** Получение списка актов оказанных услуг */
    completedOrdersReports?: Maybe<Array<Maybe<ICompletedOrdersReportType>>>;
    /** Получение информации об акте оказанных услуг */
    completedOrdersReport?: Maybe<ICompletedOrdersReportType>;
    /** Статусы актов оказанных услуг (CompletedOrdersReport) */
    completedOrdersReportStatuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Список типов услуг для актов оказанных услуг */
    completedOrdersReportServices?: Maybe<Array<Maybe<ICompletedOrdersReportServiceType>>>;
    /** Общее количество объектов */
    completedOrdersReportsCount?: Maybe<Scalars['Int']>;
    /** Получение информации о маршрутных листах водителя */
    routeSheets?: Maybe<Array<Maybe<IRouteSheetType>>>;
    /** Получение информации о выполняемом маршрутном листе водителя */
    activeRouteSheet?: Maybe<IRouteSheetType>;
    /** Получение информации о событиях на маршрутном листе */
    routeSheetEvents?: Maybe<Array<Maybe<IRouteSheetEventType>>>;
    /** Список мест погрузки/разгрузки */
    storagePoints?: Maybe<Array<Maybe<IStoragePointType>>>;
    /** Общее количество объектов */
    storagePointsCount?: Maybe<Scalars['Int']>;
    /** Получение информации о транспортном средстве */
    vehicle?: Maybe<IVehicleType>;
    /** Получение списка всех транспортных средств */
    vehicles?: Maybe<Array<Maybe<IVehicleType>>>;
    /** Получение списка всех представленных марок транспортных средств */
    vehicleMakes?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Получение списка типов ТС */
    vehicleTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Водители */
    vehicleDrivers?: Maybe<Array<Maybe<IDriverType>>>;
    /** Общее количество объектов */
    vehiclesCount?: Maybe<Scalars['Int']>;
    /** Получение списка предложений ТС */
    vehiclesAvailability?: Maybe<Array<Maybe<IVehicleAvailabilityType>>>;
    /** Количество предложений ТС */
    vehiclesAvailabilityCount?: Maybe<Scalars['Int']>;
    /** Тендер */
    tender?: Maybe<ITenderType>;
    /** Список тендеров */
    tenders?: Maybe<Array<Maybe<ITenderType>>>;
    /** Заводы (для раздела "Тендеры") */
    tenderDepartments?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Города погрузки (для раздела "Тендеры") */
    tenderLoadingSettlements?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Отчет по тендеру */
    tenderReport?: Maybe<ITenderReportType>;
    /** Список отчетов по тендерам */
    tenderReports?: Maybe<Array<Maybe<ITenderReportType>>>;
    /** Общее количество объектов */
    tendersCount?: Maybe<Scalars['Int']>;
    /** Общее количество объектов */
    tenderReportsCount?: Maybe<Scalars['Int']>;
  }

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryBodyTypeArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryOrganizationEmployeesArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    organizationId?: Maybe<Scalars['ID']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryOrganizationEmployeeArgs = {
    employeeId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryOrganizationEmployeesCountArgs = {
    organizationId?: Maybe<Scalars['ID']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryLoadingTypeArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryPaymentTypeArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryConstituentDocumentArgs = {
    docId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryNotificationArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryNotificationsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    isRead?: Maybe<Scalars['Boolean']>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryNotificationsCountArgs = {
    isRead?: Maybe<Scalars['Boolean']>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryAgentCarrierArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryAgentCarriersArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    availability?: Maybe<IAvailabilityEnum>;
    carrierName?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    inn?: Maybe<Scalars['String']>;
    kpp?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryAgentCarriersCountArgs = {
    availability?: Maybe<IAvailabilityEnum>;
    carrierName?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    inn?: Maybe<Scalars['String']>;
    kpp?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryLotsArgs = {
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryLotArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryLotsWithCarrierBetArgs = {
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCarrierContractsWithTnArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCarrierContractsWithCargoOwnerArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    cargoOwnerUserId: Scalars['ID'];
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryStateBusinessRelationshipArgs = {
    inn: Scalars['String'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCarrierContractsWithTnCountArgs = {
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCarrierContractsWithCargoOwnerCountArgs = {
    id?: Maybe<Scalars['ID']>;
    name?: Maybe<Scalars['String']>;
    cargoOwnerUserId: Scalars['ID'];
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCarrierUserArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryOrganizationDocumentArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryOrganizationDocumentsArgs = {
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryDriverArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryDriverVehiclesArgs = {
    driverId: Scalars['ID'];
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryDriversArgs = {
    availability?: Maybe<IAvailabilityEnum>;
    fullName?: Maybe<Scalars['String']>;
    namePatronymic?: Maybe<Scalars['String']>;
    surname?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    agentCarrierId?: Maybe<Scalars['ID']>;
    agentCarrierOwnership?: Maybe<IAgentCarrierDriverOwnershipEnum>;
    withActiveAgentCarrierOnly?: Maybe<Scalars['Boolean']>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryDriversCountArgs = {
    availability?: Maybe<IAvailabilityEnum>;
    fullName?: Maybe<Scalars['String']>;
    namePatronymic?: Maybe<Scalars['String']>;
    surname?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    agentCarrierId?: Maybe<Scalars['ID']>;
    agentCarrierOwnership?: Maybe<IAgentCarrierDriverOwnershipEnum>;
    withActiveAgentCarrierOnly?: Maybe<Scalars['Boolean']>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryMyInsuranceContractsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    availability?: Maybe<IAvailabilityEnum>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryInsuranceContractArgs = {
    insuranceContractId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryInsuranceInitialInfoArgs = {
    orderId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryInsuranceCostArgs = {
    orderId: Scalars['ID'];
    declaredPrice: Scalars['Int'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCancelInsuranceArgs = {
    orderId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryMyInsuranceContractsCountArgs = {
    availability?: Maybe<IAvailabilityEnum>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryOrderArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryAllOrdersArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
    vehicleAvailabilityId?: Maybe<Scalars['ID']>;
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    actualProblemEvent?: Maybe<IActualProblemEventEnum>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    driverDataFullname?: Maybe<Scalars['String']>;
    vehicleDataRegNo?: Maybe<Scalars['String']>;
    excludeBiddingOrder?: Maybe<Scalars['Boolean']>;
    isMarket?: Maybe<Scalars['Boolean']>;
    bodySubtype?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryAuctionOrderArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryAuctionOrdersArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
    vehicleAvailabilityId?: Maybe<Scalars['ID']>;
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    actualProblemEvent?: Maybe<IActualProblemEventEnum>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    driverDataFullname?: Maybe<Scalars['String']>;
    vehicleDataRegNo?: Maybe<Scalars['String']>;
    excludeBiddingOrder?: Maybe<Scalars['Boolean']>;
    isMarket?: Maybe<Scalars['Boolean']>;
    bodySubtype?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryBiddingOrderArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryBiddingOrdersArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
    vehicleAvailabilityId?: Maybe<Scalars['ID']>;
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    actualProblemEvent?: Maybe<IActualProblemEventEnum>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    driverDataFullname?: Maybe<Scalars['String']>;
    vehicleDataRegNo?: Maybe<Scalars['String']>;
    excludeBiddingOrder?: Maybe<Scalars['Boolean']>;
    isMarket?: Maybe<Scalars['Boolean']>;
    bodySubtype?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryBiddingLotArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryBiddingLotsArgs = {
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryMyOrdersArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
    vehicleAvailabilityId?: Maybe<Scalars['ID']>;
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    actualProblemEvent?: Maybe<IActualProblemEventEnum>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    driverDataFullname?: Maybe<Scalars['String']>;
    vehicleDataRegNo?: Maybe<Scalars['String']>;
    excludeBiddingOrder?: Maybe<Scalars['Boolean']>;
    isMarket?: Maybe<Scalars['Boolean']>;
    bodySubtype?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryMyAuctionOrdersArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
    vehicleAvailabilityId?: Maybe<Scalars['ID']>;
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    actualProblemEvent?: Maybe<IActualProblemEventEnum>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    driverDataFullname?: Maybe<Scalars['String']>;
    vehicleDataRegNo?: Maybe<Scalars['String']>;
    excludeBiddingOrder?: Maybe<Scalars['Boolean']>;
    isMarket?: Maybe<Scalars['Boolean']>;
    bodySubtype?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryContactPersonAtStoragePointsArgs = {
    orderId: Scalars['ID'];
    storagePointId?: Maybe<Scalars['ID']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryAgentFeeAndFreightDetailsArgs = {
    orderId: Scalars['ID'];
    agentCarrierId: Scalars['ID'];
    freightAmount?: Maybe<Scalars['Float']>;
    carrierContractId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCompletedTnOrdersArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
    dateFrom?: Maybe<Scalars['Date']>;
    dateTo?: Maybe<Scalars['Date']>;
    loadingPlaces?: Maybe<Array<Maybe<Scalars['ID']>>>;
    carrierContract?: Maybe<Scalars['ID']>;
    actualCarrierOrganizations?: Maybe<Array<Maybe<Scalars['ID']>>>;
    agentUsesOwnVehicle?: Maybe<Scalars['Boolean']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryMyOrdersCountArgs = {
    ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
    vehicleAvailabilityId?: Maybe<Scalars['ID']>;
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    actualProblemEvent?: Maybe<IActualProblemEventEnum>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    driverDataFullname?: Maybe<Scalars['String']>;
    vehicleDataRegNo?: Maybe<Scalars['String']>;
    excludeBiddingOrder?: Maybe<Scalars['Boolean']>;
    isMarket?: Maybe<Scalars['Boolean']>;
    bodySubtype?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryAuctionOrdersCountArgs = {
    ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
    vehicleAvailabilityId?: Maybe<Scalars['ID']>;
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    actualProblemEvent?: Maybe<IActualProblemEventEnum>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    driverDataFullname?: Maybe<Scalars['String']>;
    vehicleDataRegNo?: Maybe<Scalars['String']>;
    excludeBiddingOrder?: Maybe<Scalars['Boolean']>;
    isMarket?: Maybe<Scalars['Boolean']>;
    bodySubtype?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryMyAuctionOrdersCountArgs = {
    ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
    vehicleAvailabilityId?: Maybe<Scalars['ID']>;
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    actualProblemEvent?: Maybe<IActualProblemEventEnum>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    driverDataFullname?: Maybe<Scalars['String']>;
    vehicleDataRegNo?: Maybe<Scalars['String']>;
    excludeBiddingOrder?: Maybe<Scalars['Boolean']>;
    isMarket?: Maybe<Scalars['Boolean']>;
    bodySubtype?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryBiddingOrdersCountArgs = {
    ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
    vehicleAvailabilityId?: Maybe<Scalars['ID']>;
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    actualProblemEvent?: Maybe<IActualProblemEventEnum>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    driverDataFullname?: Maybe<Scalars['String']>;
    vehicleDataRegNo?: Maybe<Scalars['String']>;
    excludeBiddingOrder?: Maybe<Scalars['Boolean']>;
    isMarket?: Maybe<Scalars['Boolean']>;
    bodySubtype?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCompletedTnOrdersCountArgs = {
    ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
    dateFrom?: Maybe<Scalars['Date']>;
    dateTo?: Maybe<Scalars['Date']>;
    loadingPlaces?: Maybe<Array<Maybe<Scalars['ID']>>>;
    carrierContract?: Maybe<Scalars['ID']>;
    actualCarrierOrganizations?: Maybe<Array<Maybe<Scalars['ID']>>>;
    agentUsesOwnVehicle?: Maybe<Scalars['Boolean']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryAllOrdersCountArgs = {
    ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
    vehicleAvailabilityId?: Maybe<Scalars['ID']>;
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    actualProblemEvent?: Maybe<IActualProblemEventEnum>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    driverDataFullname?: Maybe<Scalars['String']>;
    vehicleDataRegNo?: Maybe<Scalars['String']>;
    excludeBiddingOrder?: Maybe<Scalars['Boolean']>;
    isMarket?: Maybe<Scalars['Boolean']>;
    bodySubtype?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryOrdersArgs = {
    ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
    vehicleAvailabilityId?: Maybe<Scalars['ID']>;
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    actualProblemEvent?: Maybe<IActualProblemEventEnum>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    driverDataFullname?: Maybe<Scalars['String']>;
    vehicleDataRegNo?: Maybe<Scalars['String']>;
    excludeBiddingOrder?: Maybe<Scalars['Boolean']>;
    isMarket?: Maybe<Scalars['Boolean']>;
    bodySubtype?: Maybe<Scalars['String']>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryOrdersCountArgs = {
    ids?: Maybe<Array<Maybe<Scalars['ID']>>>;
    vehicleAvailabilityId?: Maybe<Scalars['ID']>;
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceBySettlement?: Maybe<Scalars['String']>;
    unloadingPlaceByFiasCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    actualProblemEvent?: Maybe<IActualProblemEventEnum>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    driverDataFullname?: Maybe<Scalars['String']>;
    vehicleDataRegNo?: Maybe<Scalars['String']>;
    excludeBiddingOrder?: Maybe<Scalars['Boolean']>;
    isMarket?: Maybe<Scalars['Boolean']>;
    bodySubtype?: Maybe<Scalars['String']>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryOrderDocumentTypesArgs = {
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryOrganizationArgs = {
    organizationId?: Maybe<Scalars['ID']>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryOrganizationDataByInnArgs = {
    inn: Scalars['String'];
    legalFormCode: Scalars['String'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCargoOwnerOrganizationArgs = {
    subdomain: Scalars['String'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCargoOwnerWithBusinessRelationshipWithCarrierArgs = {
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCargoOwnersDataWithBusinessRelationshipArgs = {
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryPriceQuotationsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    availability?: Maybe<IPriceQuotationCarrierEnum>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryPriceQuotationArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryPriceQuotationsCountArgs = {
    availability?: Maybe<IPriceQuotationCarrierEnum>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCarrierRatingReportsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    startDate?: Maybe<Scalars['Date']>;
    endDate?: Maybe<Scalars['Date']>;
    indicator?: Maybe<Scalars['String']>;
    department?: Maybe<Scalars['String']>;
    departmentIn?: Maybe<Array<Maybe<Scalars['String']>>>;
    deliveryArea?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCarrierRatingReportDetailsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    carrierRatingId: Scalars['ID'];
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCarrierRatingDepartmentsArgs = {
    startDate?: Maybe<Scalars['Date']>;
    endDate?: Maybe<Scalars['Date']>;
    indicator?: Maybe<Scalars['String']>;
    department?: Maybe<Scalars['String']>;
    departmentIn?: Maybe<Array<Maybe<Scalars['String']>>>;
    deliveryArea?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCarrierRatingReportsCountArgs = {
    startDate?: Maybe<Scalars['Date']>;
    endDate?: Maybe<Scalars['Date']>;
    indicator?: Maybe<Scalars['String']>;
    department?: Maybe<Scalars['String']>;
    departmentIn?: Maybe<Array<Maybe<Scalars['String']>>>;
    deliveryArea?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCarrierRatingReportDetailsCountArgs = {
    carrierRatingId: Scalars['ID'];
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCompletedOrdersReportsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    id?: Maybe<Scalars['ID']>;
    yearAndMonthDate?: Maybe<Scalars['Date']>;
    agentBillNo?: Maybe<Scalars['String']>;
    hasNotes?: Maybe<Scalars['Boolean']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    reportNo?: Maybe<Scalars['String']>;
    carrierContractId?: Maybe<Scalars['ID']>;
    agentUsesOwnVehicle?: Maybe<Scalars['Boolean']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCompletedOrdersReportArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCompletedOrdersReportServicesArgs = {
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryCompletedOrdersReportsCountArgs = {
    id?: Maybe<Scalars['ID']>;
    yearAndMonthDate?: Maybe<Scalars['Date']>;
    agentBillNo?: Maybe<Scalars['String']>;
    hasNotes?: Maybe<Scalars['Boolean']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    reportNo?: Maybe<Scalars['String']>;
    carrierContractId?: Maybe<Scalars['ID']>;
    agentUsesOwnVehicle?: Maybe<Scalars['Boolean']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryRouteSheetsArgs = {
    driverId: Scalars['ID'];
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryActiveRouteSheetArgs = {
    driverId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryRouteSheetEventsArgs = {
    routeSheetId: Scalars['ID'];
    event?: Maybe<IRouteSheetEventEvent>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryStoragePointsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    typePoint?: Maybe<IStoragePointTypePoint>;
    withArchived?: Maybe<Scalars['Boolean']>;
    companyNameOrSettlement?: Maybe<Scalars['String']>;
    pointName?: Maybe<Scalars['String']>;
    organizationName?: Maybe<Scalars['String']>;
    inn?: Maybe<Scalars['ID']>;
    settlement?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryStoragePointsCountArgs = {
    typePoint?: Maybe<IStoragePointTypePoint>;
    withArchived?: Maybe<Scalars['Boolean']>;
    companyNameOrSettlement?: Maybe<Scalars['String']>;
    pointName?: Maybe<Scalars['String']>;
    organizationName?: Maybe<Scalars['String']>;
    inn?: Maybe<Scalars['ID']>;
    settlement?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryVehicleArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryVehiclesArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    availability?: Maybe<IAvailabilityEnum>;
    ownership?: Maybe<IVehicleOwnershipEnum>;
    vehicleType?: Maybe<IVehicleTypeEnum>;
    regNo?: Maybe<Scalars['String']>;
    vehicleBrand?: Maybe<Scalars['String']>;
    trailerRegNo?: Maybe<Scalars['String']>;
    bodyType?: Maybe<Scalars['ID']>;
    bodySubtype?: Maybe<Scalars['ID']>;
    capacity?: Maybe<Scalars['Float']>;
    loadingType?: Maybe<Scalars['ID']>;
    volume?: Maybe<Scalars['Float']>;
    agentCarrierId?: Maybe<Scalars['ID']>;
    agentCarrierOwnership?: Maybe<IAgentCarrierVehicleOwnershipEnum>;
    withActiveAgentCarrierOnly?: Maybe<Scalars['Boolean']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryVehicleDriversArgs = {
    vehicleId: Scalars['ID'];
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryVehiclesCountArgs = {
    availability?: Maybe<IAvailabilityEnum>;
    ownership?: Maybe<IVehicleOwnershipEnum>;
    vehicleType?: Maybe<IVehicleTypeEnum>;
    regNo?: Maybe<Scalars['String']>;
    vehicleBrand?: Maybe<Scalars['String']>;
    trailerRegNo?: Maybe<Scalars['String']>;
    bodyType?: Maybe<Scalars['ID']>;
    bodySubtype?: Maybe<Scalars['ID']>;
    capacity?: Maybe<Scalars['Float']>;
    loadingType?: Maybe<Scalars['ID']>;
    volume?: Maybe<Scalars['Float']>;
    agentCarrierId?: Maybe<Scalars['ID']>;
    agentCarrierOwnership?: Maybe<IAgentCarrierVehicleOwnershipEnum>;
    withActiveAgentCarrierOnly?: Maybe<Scalars['Boolean']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryVehiclesAvailabilityArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    ownership: IVehicleAvailabilityOwnershipEnum;
    fromPublishDate?: Maybe<Scalars['DateTime']>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryVehiclesAvailabilityCountArgs = {
    ownership: IVehicleAvailabilityOwnershipEnum;
    fromPublishDate?: Maybe<Scalars['DateTime']>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryTenderArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryTendersArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    onlyWithMyBids?: Maybe<Scalars['Boolean']>;
    department?: Maybe<Scalars['String']>;
    loadingSettlement?: Maybe<Scalars['String']>;
    ordersQuantityFrom?: Maybe<Scalars['Int']>;
    ordersQuantityTo?: Maybe<Scalars['Int']>;
    startDateFrom?: Maybe<Scalars['Date']>;
    startDateTo?: Maybe<Scalars['Date']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryTenderReportArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryTenderReportsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    startDateFrom?: Maybe<Scalars['Date']>;
    startDateTo?: Maybe<Scalars['Date']>;
    loadingSettlement?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryTendersCountArgs = {
    onlyWithMyBids?: Maybe<Scalars['Boolean']>;
    department?: Maybe<Scalars['String']>;
    loadingSettlement?: Maybe<Scalars['String']>;
    ordersQuantityFrom?: Maybe<Scalars['Int']>;
    ordersQuantityTo?: Maybe<Scalars['Int']>;
    startDateFrom?: Maybe<Scalars['Date']>;
    startDateTo?: Maybe<Scalars['Date']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоперевозчика */
  export type ICarrierQueryTenderReportsCountArgs = {
    startDateFrom?: Maybe<Scalars['Date']>;
    startDateTo?: Maybe<Scalars['Date']>;
    loadingSettlement?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** An enumeration. */
  export enum ICarrierRatingReportCalculatedRating {
    /** Не назначен */
    'ONE' = '_1',
    /** Кандидат на отчисление */
    A_0 = 'A_0',
    /** 1 */
    A_1 = 'A_1',
    /** 2 */
    A_2 = 'A_2',
    /** 3 */
    A_3 = 'A_3',
  }

  /** An enumeration. */
  export enum ICarrierRatingReportCurrentRating {
    /** Не назначен */
    'ONE' = '_1',
    /** Кандидат на отчисление */
    A_0 = 'A_0',
    /** 1 */
    A_1 = 'A_1',
    /** 2 */
    A_2 = 'A_2',
    /** 3 */
    A_3 = 'A_3',
  }

  /** Названия заводов для отчета по рейтингу */
  export interface ICarrierRatingReportDepartmentType {
    /** Название завода для отчета по рейтингу */
    department?: Maybe<Scalars['String']>;
  }

  /** An enumeration. */
  export enum ICarrierRatingReportDetailDeliveryRequestType {
    /** Назначенная */
    Assigned = 'ASSIGNED',
    /** Свободная */
    Vacant = 'VACANT',
    /** Аукционная */
    Auction = 'AUCTION',
  }

  /** Тип детальной записи отчета рейтинга перевозчика */
  export interface ICarrierRatingReportDetailType {
    id: Scalars['ID'];
    /** Номер заявки на транспорт */
    deliveryRequestNumber?: Maybe<Scalars['String']>;
    /**  Дата и время создания заявки на транспорт в 1С */
    deliveryRequestDateUtc?: Maybe<Scalars['DateTime']>;
    /** Номер заявки-договора */
    deliveryContractNumber?: Maybe<Scalars['String']>;
    /** Дата и время создания заявки-договора в 1С */
    deliveryContractDateUtc?: Maybe<Scalars['DateTime']>;
    /** Тип заявки на транспорт */
    deliveryRequestType?: Maybe<ICarrierRatingReportDetailDeliveryRequestType>;
    /** Перевозка выполнена */
    deliveryCompleted?: Maybe<Scalars['Boolean']>;
    /** Отказ от заявки-договора */
    deliveryContractRefused?: Maybe<Scalars['Boolean']>;
    /** Срыв срока подачи ТС на загрузку */
    carrierFaultCanceledShipment?: Maybe<Scalars['Boolean']>;
    /** Опоздание ТС на загрузку */
    carrierFaultDelayedShipment?: Maybe<Scalars['Boolean']>;
  }

  /** Тип отчета рейтинга перевозчика */
  export interface ICarrierRatingReportType {
    id: Scalars['ID'];
    /** Дата начала расчета */
    startDate?: Maybe<Scalars['Date']>;
    /** Дата окончания расчета */
    endDate?: Maybe<Scalars['Date']>;
    /** Показатель */
    indicator?: Maybe<Scalars['String']>;
    /** Завод */
    department?: Maybe<Scalars['String']>;
    /** Направление */
    deliveryArea?: Maybe<Scalars['String']>;
    /** Текущий рейтинг */
    currentRating?: Maybe<ICarrierRatingReportCurrentRating>;
    /** Расчетный рейтинг */
    calculatedRating?: Maybe<ICarrierRatingReportCalculatedRating>;
    /** Выполнено перевозок всего */
    totalDeliveriesCompleted?: Maybe<Scalars['Int']>;
    /** Назначено перевозок */
    assignedDeliveryRequests?: Maybe<Scalars['Int']>;
    /** Выполнено назначенных перевозок */
    assignedDeliveryRequestsCompleted?: Maybe<Scalars['Int']>;
    /** Выполнено перевозок из свободных заявок */
    vacantDeliveryRequestsCompleted?: Maybe<Scalars['Int']>;
    /** Отказы от назначенных перевозок */
    assignedDeliveryRequestsRefused?: Maybe<Scalars['Int']>;
    /** Отказы от заявок-договоров */
    deliveryContractsRefused?: Maybe<Scalars['Int']>;
    /** Срывы срока подачи ТС на загрузку */
    carrierFaultCanceledShipments?: Maybe<Scalars['Int']>;
    /** Среднее время поиска автомобиля, минут */
    vehicleSearchAverageTime?: Maybe<Scalars['Int']>;
    /** Общий процент отказа от заявок */
    refusedRequestsPercentage?: Maybe<Scalars['Float']>;
    /** Процент опозданий ТС на загрузку */
    carrierFaultDelayedShipmentsPercentage?: Maybe<Scalars['Float']>;
    /** Процент выполнения заявок из свободных */
    vacantRequestsDeliveriesPercentage?: Maybe<Scalars['Float']>;
    /** Детальная запись отчета рейтинга перевозчика */
    details?: Maybe<Array<Maybe<ICarrierRatingReportDetailType>>>;
  }

  export interface ICarrierUserType {
    id: Scalars['ID'];
    /** Адрес электронной почты пользователя */
    email: Scalars['String'];
    /** Имя пользователя */
    firstName: Scalars['String'];
    /** Фамилия пользователя */
    lastName: Scalars['String'];
    phoneNumber?: Maybe<Scalars['String']>;
    /** Информация по ответу ГП на запрос цены */
    organization?: Maybe<ICarrierOrganizationType>;
    /** Отчество пользователя */
    patronymic?: Maybe<Scalars['String']>;
    /** Дата и время создания */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Уникальный GUID в ТН */
    tnId?: Maybe<Scalars['String']>;
    /** Источник создания пользователя */
    registrationSource: ICustomUserRegistrationSource;
    /** Регистрация успешно пройдена */
    isRegistered: Scalars['Boolean'];
    /** Пользователь в процессе саморегистрации */
    selfRegistrationInProcess?: Maybe<Scalars['Boolean']>;
  }

  /** Тип услуг для актов оказанных услуг */
  export interface ICompletedOrdersReportServiceType {
    id: Scalars['ID'];
    /** Название услуги */
    name: Scalars['String'];
  }

  /** Тип акта оказанных услуг */
  export interface ICompletedOrdersReportType {
    id: Scalars['ID'];
    /** Статус */
    status: IReportOnCompletedOrdersStatus;
    /** Замечания */
    notes?: Maybe<Scalars['String']>;
    /** Платежный документ */
    paymentDocuments?: Maybe<Scalars['String']>;
    /** Настройки отчета */
    reportSettings?: Maybe<IReportSettingsType>;
    /** Список строк акта */
    orderRecords?: Maybe<Array<Maybe<IOrderRecordFromReportType>>>;
    /** Ссылка на скачивание архива с печатными формами */
    downloadPrintedFormsArchiveUrl?: Maybe<Scalars['String']>;
    /** Сумма счета */
    totalSum?: Maybe<Scalars['Float']>;
    /** Наличие замечаний */
    hasNotes?: Maybe<Scalars['Boolean']>;
    /** Id генерального договора заявок */
    carrierContract?: Maybe<ICarrierContractType>;
    /** Перевозки осуществлялись собственным транспортным средством ТЭКа */
    agentUsesOwnVehicle?: Maybe<Scalars['Boolean']>;
  }

  export interface IConstituentDocumentType {
    id: Scalars['ID'];
    /** Название документа */
    name: Scalars['String'];
    /** ID организации */
    organizationId?: Maybe<Scalars['ID']>;
    /** URL для скачивания */
    url?: Maybe<Scalars['String']>;
  }

  /** Контактное лицо на месте погрузки/разгрузки */
  export interface IContactPersonAtStoragePointType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    fullName: Scalars['String'];
    phoneNumber: Scalars['String'];
    loadingPlace?: Maybe<IStoragePointType>;
  }

  export interface IContractInfoType {
    /** Время создания заявки-договора, согласно 1С */
    createdAt1c?: Maybe<Scalars['DateTime']>;
    /** Номер документа в системе 1С */
    contractNumber1c?: Maybe<Scalars['String']>;
  }

  export interface ICreateCarrierRiskProfile {
    /** Идентификатор созданного профиля риска */
    profileId?: Maybe<Scalars['String']>;
  }

  export interface ICreateDriverRiskProfile {
    /** Идентификатор созданного профиля риска */
    profileId?: Maybe<Scalars['String']>;
  }

  export interface ICreateVehicleAvailabilityRiskProfile {
    /** Идентификатор созданного профиля риска */
    profileId?: Maybe<Scalars['String']>;
  }

  export interface ICreateVehicleRiskProfile {
    /** Идентификатор созданного профиля риска */
    profileId?: Maybe<Scalars['String']>;
  }

  /** An enumeration. */
  export enum ICustomUserEmailStatus {
    /** Подтверждён */
    Confirmed = 'CONFIRMED',
    /** Не подтверждён */
    NotConfirmed = 'NOT_CONFIRMED',
    /** Требуется подтверждение */
    NeedConfirm = 'NEED_CONFIRM',
  }

  /** An enumeration. */
  export enum ICustomUserRegistrationSource {
    /** Саморегистрация */
    SelfRegistration = 'SELF_REGISTRATION',
    /** Ручное создание */
    ManualRegistration = 'MANUAL_REGISTRATION',
  }

  export interface IDeliverySizeType {
    /** Длина груза */
    length?: Maybe<Scalars['Decimal']>;
    /** Ширина груза */
    width?: Maybe<Scalars['Decimal']>;
    /** Высота груза */
    height?: Maybe<Scalars['Decimal']>;
    /** Объём груза */
    volume?: Maybe<Scalars['Decimal']>;
    /** Масса груза */
    weight?: Maybe<Scalars['Decimal']>;
    /** Тип упаковки */
    packageType?: Maybe<Scalars['String']>;
    /** Количество упаковок */
    quantity?: Maybe<Scalars['Int']>;
    /** Количество или объем задаются ТТН */
    setByWaybill?: Maybe<Scalars['Boolean']>;
  }

  export interface IDeliveryUnit {
    /** Уникальный идентификатор единицы разгрузки */
    unitId?: Maybe<Scalars['String']>;
    /** Ссылка на груз */
    cargoId?: Maybe<Scalars['String']>;
    /** Ссылка на точку доставки */
    deliveryPointId?: Maybe<Scalars['Int']>;
    /** Статус выполнения на единице доставки груза */
    status?: Maybe<IDeliveryUnitStatus>;
    /** Размеры/количество разгружаемого груза */
    size?: Maybe<IDeliverySizeType>;
  }

  export enum IDeliveryUnitStatus {
    /** Не выполнялся */
    NotStarted = 'not_started',
    /** Отменена */
    Cancelled = 'cancelled',
    /** Загружен */
    Loaded = 'loaded',
    /** Доставлен */
    Delivered = 'delivered',
  }

  /** Debugging information for the current query. */
  export interface IDjangoDebug {
    /** Executed SQL queries for this API query. */
    sql?: Maybe<Array<Maybe<IDjangoDebugSql>>>;
  }

  /** Represents a single database query made to a Django managed DB. */
  export interface IDjangoDebugSql {
    /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
    vendor: Scalars['String'];
    /** The Django database alias (e.g. 'default'). */
    alias: Scalars['String'];
    /** The actual SQL sent to this database. */
    sql?: Maybe<Scalars['String']>;
    /** Duration of this database query in seconds. */
    duration: Scalars['Float'];
    /** The raw SQL of this query, without params. */
    rawSql: Scalars['String'];
    /** JSON encoded database query parameters. */
    params: Scalars['String'];
    /** Start time of this database query. */
    startTime: Scalars['Float'];
    /** Stop time of this database query. */
    stopTime: Scalars['Float'];
    /** Whether this database query took more than 10 seconds. */
    isSlow: Scalars['Boolean'];
    /** Whether this database query was a SELECT. */
    isSelect: Scalars['Boolean'];
    /** Postgres transaction ID if available. */
    transId?: Maybe<Scalars['String']>;
    /** Postgres transaction status if available. */
    transStatus?: Maybe<Scalars['String']>;
    /** Postgres isolation level if available. */
    isoLevel?: Maybe<Scalars['String']>;
    /** Postgres connection encoding if available. */
    encoding?: Maybe<Scalars['String']>;
  }

  export enum IDocumentFlowSettingsEnum {
    /** Грузовладелец первым подписывает договор */
    CargoOwnerFirst = 'CARGO_OWNER_FIRST',
    /** Договор подписывает только грузоперевозчик */
    CarrierOnly = 'CARRIER_ONLY',
  }

  export interface IDocumentStatusType {
    /** ID документа */
    id?: Maybe<Scalars['ID']>;
    /** Документ */
    document?: Maybe<IOrderUploadedDocumentType>;
    /** Статус */
    status?: Maybe<Scalars['String']>;
    /** Дата создания */
    createdAt?: Maybe<Scalars['String']>;
    /** Тип документа */
    documentType?: Maybe<IOrderDocumentTypeType>;
    /** Внешний ID */
    extDocId?: Maybe<Scalars['Int']>;
  }

  /** Перечислитель по типу документа для получения статуса */
  export enum IDocumentTypeInnerNameEnum {
    /** Все типы */
    All = 'ALL',
    /** Доверенность водителю */
    PoaDriver = 'POA_DRIVER',
    /** Заявка-договор */
    Contract = 'CONTRACT',
    /** Транспортная накладная */
    Waybill = 'WAYBILL',
    /** Электронная подпись */
    DigitSign = 'DIGIT_SIGN',
    /** Форма заявки-договора */
    ContractForm = 'CONTRACT_FORM',
    /** ТОРГ-12 */
    Torg12 = 'TORG12',
    /** Соглашение о расторжении */
    TerminationAgreement = 'TERMINATION_AGREEMENT',
  }

  export interface IDocumentTypeType {
    id: Scalars['ID'];
    /** Название типа документа */
    name: Scalars['String'];
    /** Служебное название документа */
    innerName?: Maybe<IOrganizationDocumentTypeInnerName>;
  }

  /** Указанное в заявке место погрузки/доставки */
  export interface IDraftOrderStoragePointType {
    /** Указанные в заявке дата и предполагаемое время начала периода прибытия транспорта */
    arriveDatetimeFrom?: Maybe<Scalars['DateTime']>;
    /** Указанные в заявке дата и предполагаемое время окончания периода прибытия транспорта */
    arriveDatetimeTo?: Maybe<Scalars['DateTime']>;
    /** Фактическое время прибытия на точку доставки */
    factArriveDatetimeFrom?: Maybe<Scalars['DateTime']>;
    /** Фактическое время оканчания работы на точке доставки */
    factArriveDatetimeTo?: Maybe<Scalars['DateTime']>;
    /** Комментарий к месту погрузки/разгрузки */
    comment?: Maybe<Scalars['String']>;
    /** Порядковый номер погрузки/разгрузки в рамках заявки */
    seqId?: Maybe<Scalars['Int']>;
    storagePoint?: Maybe<IStoragePointType>;
    /** Deprecated! Стоит использовать arrive_datetime_from */
    workDatetime?: Maybe<Scalars['DateTime']>;
    /** Контактное лицо на точке погрузки/доставки */
    contactPersonAtStoragePoint?: Maybe<Scalars['String']>;
    /** Контактный телефон на точке погрузки/доставки */
    contactPhoneAtStoragePoint?: Maybe<Scalars['String']>;
    /** Контактное лицо на точке погрузки/доставки */
    contactPersons?: Maybe<Array<Maybe<IOrderStoragePointContactPerson>>>
    /** Тип погрузки/разгрузки */
    loadingType?: Maybe<ILoadingTypeType>;
    /** Список прикрепленных файлов */
    attachedDocuments?: Maybe<Array<Maybe<IOrderStoragePointAttachedDocumentType>>>;
  }

  /** An enumeration. */
  export enum IDriverDataGender {
    /** Женский */
    Female = 'FEMALE',
    /** Мужской */
    Male = 'MALE',
  }

  /** Данные водителя для заявки */
  export interface IDriverDataType {
    /** День рожденья */
    birthday?: Maybe<Scalars['Date']>;
    /** Пол */
    gender?: Maybe<IDriverDataGender>;
    /** ИНН */
    inn?: Maybe<Scalars['String']>;
    /** Мобильный телефон водителя */
    phone?: Maybe<Scalars['String']>;
    /** Доп. мобильный телефон водителя */
    addPhone?: Maybe<Scalars['String']>;
    /** Серия водительских прав */
    licenceSeries?: Maybe<Scalars['String']>;
    /** Номер водительских прав */
    licenceNumber?: Maybe<Scalars['String']>;
    /** Кем выданы водитеские права */
    licencePlace?: Maybe<Scalars['String']>;
    /** Дата выдачи водительских прав */
    licenceDate?: Maybe<Scalars['Date']>;
    /** Фамилия */
    surname?: Maybe<Scalars['String']>;
    /** Имя */
    name?: Maybe<Scalars['String']>;
    /** Отчество */
    patronymic?: Maybe<Scalars['String']>;
    /** Серия паспорта */
    passportSeries?: Maybe<Scalars['String']>;
    /** Номер паспорта */
    passportNumber?: Maybe<Scalars['String']>;
    /** Кем выдан паспорт */
    passportPlace?: Maybe<Scalars['String']>;
    /** Дата выдачи паспорта */
    passportDate?: Maybe<Scalars['Date']>;
    /** Страна (RU - Россия, любой другой - не Россия) */
    country: Scalars['String'];
    /** id водителя */
    id?: Maybe<Scalars['ID']>;
    /** Грузоперевозчик */
    carrierOrganization?: Maybe<ICarrierOrganizationType>;
    /** Архивный? */
    isArchived?: Maybe<Scalars['Boolean']>;
    /** Статус проверки */
    verificationStatus?: Maybe<Scalars['String']>;
    /** Устанавливал ли водитель мобильное приложение */
    usesDriverMobile?: Maybe<Scalars['Boolean']>;
    /** ГП агента (ТЭК) */
    agentCarrier?: Maybe<IAgentCarrierType>;
    /** Информация о ЧС */
    blackListInfo?: Maybe<Array<Maybe<IBlackListDriverType>>>;
  }

  /** Данные водителя для заявки */
  export type IDriverDataTypeBlackListInfoArgs = {
    orderId?: Maybe<Scalars['ID']>;
  };

  /** An enumeration. */
  export enum IDriverGender {
    /** Женский */
    Female = 'FEMALE',
    /** Мужской */
    Male = 'MALE',
  }

  /** Информация о водителе */
  export interface IDriverType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    /** Статус проверки */
    verificationStatus: IDriverVerificationStatus;
    /** День рожденья */
    birthday?: Maybe<Scalars['Date']>;
    /** Пол */
    gender?: Maybe<IDriverGender>;
    /** ИНН */
    inn?: Maybe<Scalars['String']>;
    /** Мобильный телефон водителя */
    phone?: Maybe<Scalars['String']>;
    /** Доп. мобильный телефон водителя */
    addPhone?: Maybe<Scalars['String']>;
    /** Серия водительских прав */
    licenceSeries?: Maybe<Scalars['String']>;
    /** Номер водительских прав */
    licenceNumber?: Maybe<Scalars['String']>;
    /** Кем выданы водитеские права */
    licencePlace?: Maybe<Scalars['String']>;
    /** Дата выдачи водительских прав */
    licenceDate?: Maybe<Scalars['Date']>;
    /** Фамилия */
    surname?: Maybe<Scalars['String']>;
    /** Имя */
    name?: Maybe<Scalars['String']>;
    /** Отчество */
    patronymic?: Maybe<Scalars['String']>;
    /** Серия паспорта */
    passportSeries?: Maybe<Scalars['String']>;
    /** Номер паспорта */
    passportNumber?: Maybe<Scalars['String']>;
    /** Кем выдан паспорт */
    passportPlace?: Maybe<Scalars['String']>;
    /** Дата выдачи паспорта */
    passportDate?: Maybe<Scalars['Date']>;
    /** Страна (RU - Россия, любой другой - не Россия) */
    country: Scalars['String'];
    /** Грузоперевозчик */
    carrierOrganization?: Maybe<ICarrierOrganizationType>;
    /** Устанавливал ли водитель мобильное приложение */
    usesDriverMobile?: Maybe<Scalars['Boolean']>;
    /** Водитель получил как минимум одно приглашение в МП */
    hasBeenInvitedToDriverMobile?: Maybe<Scalars['Boolean']>;
    /** ГП агента (ТЭК) */
    agentCarrier?: Maybe<IAgentCarrierType>;
    /** Информация о ЧС */
    blackListInfo?: Maybe<Array<Maybe<IBlackListDriverType>>>;
    /** Статус задачи */
    accrStatus?: Maybe<IAccreditationStatus>;
    /** Коментарий к аккредитации */
    accrComment?: Maybe<Scalars['String']>;
  }

  /** Информация о водителе */
  export type IDriverTypeBlackListInfoArgs = {
    orderId?: Maybe<Scalars['ID']>;
  };

  /** An enumeration. */
  export enum IDriverVerificationStatus {
    /** Проверка не проводилась */
    NotChecked = 'NOT_CHECKED',
    /** Принято в работу */
    OnWork = 'ON_WORK',
    /** Одобрено */
    Approved = 'APPROVED',
    /** Отклонено */
    Rejected = 'REJECTED',
  }

  export interface IEditAgentCarrierInput {
    /** Ставка НДС для ГП, относящихся к организациям-агентам (ТЭК) */
    vat?: Maybe<Scalars['ID']>;
    /** ID */
    id: Scalars['ModelID'];
    /** Название перевозчика. Длина (max): 150 */
    carrierName?: Maybe<Scalars['String']>;
    /** Почтовый (фактический) адрес. Длина (max): 300 */
    postalAddress?: Maybe<Scalars['String']>;
  }

  export interface IEditCompletedOrdersReportInput {
    /** id акта оказанных услуг */
    reportId: Scalars['ID'];
    /** Данные по акту оказанных услуг */
    reportSettings?: Maybe<IReportSettingsInput>;
    /** Данные по строкам акта оказанных услуг */
    orderRecords?: Maybe<Array<Maybe<IOrderRecordInput>>>;
  }

  /** Вводимая информация при редактировании водителя */
  export interface IEditDriverInput {
    /** ID грузоперевозчика от агента (ТЭК) */
    agentCarrierId?: Maybe<Scalars['ID']>;
    /** Фамилия. Длина (max): 150 */
    surname?: Maybe<Scalars['String']>;
    /** Имя. Длина (max): 30 */
    name?: Maybe<Scalars['String']>;
    /** Отчество. Длина (max): 150 */
    patronymic?: Maybe<Scalars['String']>;
    /** Код ISO страны водителя (по умолчанию RU). Длина (max): 2 */
    country?: Maybe<Scalars['String']>;
    /** Серия паспорта. Длина (max): 10 */
    passportSeries?: Maybe<Scalars['String']>;
    /** Номер паспорта. Длина (max): 20 */
    passportNumber?: Maybe<Scalars['String']>;
    /** Кем выдан паспорт. Длина (max): не ограничена */
    passportPlace?: Maybe<Scalars['String']>;
    /** Дата выдачи паспорта */
    passportDate?: Maybe<Scalars['Date']>;
    /** Имя в водительском удостоверении */
    licenceName?: Maybe<Scalars['String']>;
    /** Фамилия в водительском удостоверении */
    licenceSurname?: Maybe<Scalars['String']>;
    /** Отчество в водительском удостоверении */
    licencePatronymic?: Maybe<Scalars['String']>;
    /** Серия водительских прав. Длина (max): 10 */
    licenceSeries?: Maybe<Scalars['String']>;
    /** Номер водительских прав. Длина (max): 20 */
    licenceNumber?: Maybe<Scalars['String']>;
    /** Кем выданы водительские права. Длина (max): не ограничена */
    licencePlace?: Maybe<Scalars['String']>;
    /** Дата выдачи водительских прав */
    licenceDate?: Maybe<Scalars['Date']>;
    /** Телефон. Длина (max): 12 */
    phone?: Maybe<Scalars['String']>;
    /** Доп. Телефон. Длина (max): 12 */
    addPhone?: Maybe<Scalars['String']>;
    /** Дата рожденья */
    birthday?: Maybe<Scalars['Date']>;
    /** Пол */
    gender?: Maybe<IDriverGender>;
    /** ИНН. Длина (max): 12 */
    inn?: Maybe<Scalars['String']>;
    id: Scalars['ID'];
  }

  /** Редактирование информации о сотруднике организации */
  export interface IEditEmployeeInput {
    /** Имя. Длина (max): 30 */
    firstName?: Maybe<Scalars['String']>;
    /** Фамилия. Длина (max): 150 */
    lastName?: Maybe<Scalars['String']>;
    /** Отчество. Длина (max): 150 */
    patronymic?: Maybe<Scalars['String']>;
    /** Email. Длина (max): 254 */
    email?: Maybe<Scalars['String']>;
    /** Номер телефона. Длина (max): 25 */
    phoneNumber?: Maybe<Scalars['String']>;
    /** Список из ID прав доступа, назначаемых сотруднику */
    permissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Настройки UI */
    uiSettings?: Maybe<Scalars['JSONString']>;
    /** ID редактируемого сотрудника */
    id: Scalars['ID'];
  }

  export interface IEditOrderUploadedDocumentInput {
    /** Метаданные транспортной накладной */
    waybillInfo?: Maybe<IEditWaybillInput>;
    /** Загруженные файлы */
    uploadedFileIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** ID */
    id: Scalars['ModelID'];
    /** Тип документа */
    documentTypeId?: Maybe<Scalars['ModelID']>;
    /** Загруженный файл */
    uploadedFileId?: Maybe<Scalars['ModelID']>;
  }

  export interface IEditOrderUploadedDocumentsInput {
    /** Документы на добавление */
    documentsToAdd?: Maybe<Array<Maybe<IAddOrderUploadedDocumentInput>>>;
    /** Документы на редактирование */
    documentsToEdit?: Maybe<Array<Maybe<IEditOrderUploadedDocumentInput>>>;
    /** Документы на удаление */
    documentsToDelete?: Maybe<Array<Maybe<Scalars['ModelID']>>>;
    /** Заявка */
    orderId: Scalars['ModelID'];
  }

  export interface IEditOrganizationInput {
    /** Название организации. Длина (max): 300 */
    name?: Maybe<Scalars['String']>;
    /** Подписывающее лицо (Фамилия И. О.). Длина (max): 150 */
    signer?: Maybe<Scalars['String']>;
    /** Описание организации. Длина (max): 300 */
    about?: Maybe<Scalars['String']>;
    /** Телефон организации. Длина (max): 150 */
    phoneNumber?: Maybe<Scalars['String']>;
    /** Электронная почта. Длина (max): 254 */
    email?: Maybe<Scalars['String']>;
    /** КПП. Длина (max): 9 */
    kpp?: Maybe<Scalars['String']>;
    /** Юридический адрес. Длина (max): 300 */
    legalAddress?: Maybe<Scalars['String']>;
    /** Почтовый адрес. Длина (max): 300 */
    postalAddress?: Maybe<Scalars['String']>;
    /** ОГРН/ОГРНИП. Длина (max): 15 */
    registrationNumber?: Maybe<Scalars['String']>;
    /** Идентификатор типа НДС */
    vat?: Maybe<Scalars['ID']>;
    /** Тип оплаты */
    paymentTypeId?: Maybe<Scalars['ID']>;
    /** Сроки оплаты. Длина (max): 5000 */
    paymentPeriod?: Maybe<Scalars['String']>;
    /** ID компании */
    id: Scalars['ID'];
  }

  /** Смена пароля профиля */
  export interface IEditProfileEmailInput {
    /** Текущий пароль */
    currentPassword?: Maybe<Scalars['String']>;
    /** Новый email */
    newEmail?: Maybe<Scalars['String']>;
  }

  /** Редактирование личной информации пользователя */
  export interface IEditProfileInput {
    /** Имя */
    firstName?: Maybe<Scalars['String']>;
    /** Фамилия */
    lastName?: Maybe<Scalars['String']>;
    /** Отчество */
    patronymic?: Maybe<Scalars['String']>;
    /** Номер телефона */
    phoneNumber?: Maybe<Scalars['String']>;
  }

  /** Смена пароля профиля */
  export interface IEditProfilePasswordInput {
    /** Текущий пароль */
    currentPassword?: Maybe<Scalars['String']>;
    /** Новый пароль */
    newPassword?: Maybe<Scalars['String']>;
  }

  /** Настройки профиля пользователя */
  export interface IEditProfileSettingsInput {
    /** Настройки UI */
    uiSettings?: Maybe<Scalars['JSONString']>;
    /** Настройки уведомлений */
    notificationSettings?: Maybe<INotificationSettingsOverallInput>;
    /** Включить/выключить новый дизайн */
    isNewDesign?: Maybe<Scalars['Boolean']>;
  }

  /** Информация о транспортном средстве. В системе может быть 3 типа ТС: грузовик(TRUCK), фура(TRAILER) и автосцепка(COOPLER). В зависимости от выбранного типа ТС варьируется набор обязательных полей: для грузовика необходимо заполнить информацию о кузове (поля VehicleBody...), при этом все поля являются опционными. для фуры необходимо заполнить информацию о прицепе (поля VehicleTrailer...). Обязательно нужно указать тип прицепа(VehicleTrailerType) - полуприцеп(HALF), остальные поля являются опционными.для автосцепки обязательным является заполнение информации и кузове и о прицепе.Обязательно нужно указать тип прицепа(VehicleTrailerType) - прицеп(FULL). */
  export interface IEditVehicleInput {
    /** Устаревший. Используйте vehicleRegNo */
    vehicleId?: Maybe<Scalars['String']>;
    /** Устаревший. Используйте vehicleTrailerRegNo */
    vehicleTrailerTrailerId?: Maybe<Scalars['String']>;
    /** Устаревший. Не используется */
    vehicleBodyType?: Maybe<Scalars['ID']>;
    /** Устаревший. Не используется */
    vehicleTrailerType?: Maybe<Scalars['ID']>;
    /** Код ISO страны ТС (по умолчанию RU). Длина (max): 2 */
    country?: Maybe<Scalars['String']>;
    /** Номер государственной регистрации ТС */
    vehicleRegNo?: Maybe<Scalars['String']>;
    /** Тип ТС */
    vehicleType?: Maybe<IVehicleVehicleType>;
    /** Марка ТС. Длина (max): 100 */
    vehicleMake?: Maybe<Scalars['String']>;
    /** Номер свидетельства о регистрации ТС. Длина (max): 20 */
    vehicleCertificate?: Maybe<Scalars['String']>;
    /** VIN код. Длина (max): 17 */
    vehicleVin?: Maybe<Scalars['String']>;
    /** Водители с правом управления данным ТС */
    vehicleDrivers?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Подтип кузова ТС */
    vehicleBodySubtype?: Maybe<Scalars['ID']>;
    /** Объём кузова ТС в м3 */
    vehicleBodyVolume?: Maybe<Scalars['Float']>;
    /** Грузоподъемность ТС в тоннах */
    vehicleBodyCapacity?: Maybe<Scalars['Float']>;
    vehicleBodyLoadingTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Номер гос. регистрации прицепа/полуприцепа */
    vehicleTrailerRegNo?: Maybe<Scalars['String']>;
    /** Подтип кузова прицепа/полуприцепа */
    vehicleTrailerSubtype?: Maybe<Scalars['ID']>;
    /** Объём кузова прицепа/полуприцепа в м3 */
    vehicleTrailerVolume?: Maybe<Scalars['Float']>;
    /** Грузоподъемность прицепа/полуприцепа в тоннах */
    vehicleTrailerCapacity?: Maybe<Scalars['Float']>;
    vehicleTrailerLoadingTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Марка прицепа/полуприцепа */
    vehicleTrailerMake?: Maybe<Scalars['String']>;
    /** ID грузоперевозчика от агента (ТЭК) */
    agentCarrierId?: Maybe<Scalars['ID']>;
    /** Тип владения ТС */
    ownershipType?: Maybe<IVehicleOwnershipType>;
    /** ID */
    id: Scalars['ID'];
  }

  export interface IEditWaybillInput {
    /** Время с */
    dateFrom?: Maybe<Scalars['DateTime']>;
    /** Время по */
    dateTo?: Maybe<Scalars['DateTime']>;
    /** Файл транспортной накладной */
    uploadedFileId?: Maybe<Scalars['ID']>;
    /** id места доставки */
    storagePointId?: Maybe<Scalars['ID']>;
  }

  /** Сотрудник организации */
  export interface IEmployeeType {
    id: Scalars['ID'];
    /** Адрес электронной почты пользователя */
    email: Scalars['String'];
    /** Имя пользователя */
    firstName: Scalars['String'];
    /** Фамилия пользователя */
    lastName: Scalars['String'];
    phoneNumber?: Maybe<Scalars['String']>;
    lastLogin?: Maybe<Scalars['DateTime']>;
    /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
    isActive: Scalars['Boolean'];
    dateJoined: Scalars['DateTime'];
    /** Организация, к которой относится пользователь */
    organization?: Maybe<ICarrierOrganizationType>;
    /** Отчество пользователя */
    patronymic?: Maybe<Scalars['String']>;
    /** Статус электронной почты */
    emailStatus: ICustomUserEmailStatus;
    /** Роль */
    role?: Maybe<Scalars['String']>;
    /** Название организации, к которой относится пользователь */
    companyName?: Maybe<Scalars['String']>;
    /** Список прав пользователя в системе */
    permissions?: Maybe<Array<Maybe<IPermissionType>>>;
  }

  export interface IEventAttachedDoc {
    /** Наименование прикрепленного документа */
    filename?: Maybe<Scalars['String']>;
    /** URL прикрепленного документа */
    url?: Maybe<Scalars['String']>;
    /** Дата и время, когда был загружен документ */
    datetime?: Maybe<Scalars['String']>;
  }

  export interface IExtraServicesType {
    id: Scalars['ID'];
    /** Название дополнительной услуги */
    name: Scalars['String'];
    /** Стоимость доп. услуги */
    price?: Maybe<Scalars['Float']>;
    /** Сумма НДС */
    vatAmount?: Maybe<Scalars['Float']>;
  }

  export interface IGrapheneLoadingType {
    /** Id типа погрузки */
    id?: Maybe<Scalars['ID']>;
    /** Название типа погрузки */
    name?: Maybe<Scalars['String']>;
  }

  export interface IGrapheneVehicleBodyType {
    /** Тип кузова */
    bodyType?: Maybe<Scalars['String']>;
    /** Подтип кузова */
    bodySubtype?: Maybe<Scalars['String']>;
    /** Грузоподъемность */
    capacity?: Maybe<Scalars['Float']>;
    /** Объем */
    volume?: Maybe<Scalars['Float']>;
    /** Типы погрузки */
    loadingTypes?: Maybe<Array<Maybe<IGrapheneLoadingType>>>;
  }

  export interface IGrapheneVehicleTrailerType {
    /** Тип прицепа/полуприцепа */
    bodyType?: Maybe<Scalars['String']>;
    /** Подтип прицепа/полуприцепа */
    bodySubtype?: Maybe<Scalars['String']>;
    /** Регистрационный номер прицепа/полуприцепа */
    regNo?: Maybe<Scalars['String']>;
    /** Грузоподъемность */
    capacity?: Maybe<Scalars['Float']>;
    /** Объем */
    volume?: Maybe<Scalars['Float']>;
    /** Типы погрузки */
    loadingTypes?: Maybe<Array<Maybe<IGrapheneLoadingType>>>;
  }

  /** Информация о транспортном средстве */
  export interface IGrapheneVehicleType {
    /** ID ТС */
    id?: Maybe<Scalars['ID']>;
    /** Тип ТС */
    vehicleType?: Maybe<Scalars['String']>;
    /** Марка ТС */
    vehicleMake?: Maybe<Scalars['String']>;
    /** Номер свидетельства о регистрации ТС */
    vehicleCertificate?: Maybe<Scalars['String']>;
    /** Номер государственной регистрации ТС */
    regNo?: Maybe<Scalars['String']>;
    /** VIN */
    vehicleVin?: Maybe<Scalars['String']>;
    /** Кузов ТС */
    vehiclebody?: Maybe<IGrapheneVehicleBodyType>;
    /** Прицеп ТС */
    vehicletrailer?: Maybe<IGrapheneVehicleTrailerType>;
    /** Статус верификации ТС */
    verificationStatus?: Maybe<Scalars['String']>;
  }

  export interface IHeadOfOrganizationType {
    id: Scalars['ID'];
    /** Фамилия */
    surname?: Maybe<Scalars['String']>;
    /** Имя */
    name?: Maybe<Scalars['String']>;
    /** Отчество */
    patronymic?: Maybe<Scalars['String']>;
    /** Серия паспорта */
    passportSeries?: Maybe<Scalars['String']>;
    /** Номер паспорта */
    passportNumber?: Maybe<Scalars['String']>;
    /** Дата выдачи паспорта */
    passportDate?: Maybe<Scalars['Date']>;
  }

  export interface IInsuranceCompanyType {
    /** Код страховой компании */
    codename?: Maybe<Scalars['String']>;
    /** Название страховой компании */
    name?: Maybe<Scalars['String']>;
  }

  export interface IInsuranceContractType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    parameters?: Maybe<Scalars['JSONString']>;
    /** Номер генерального договора */
    generalNumber?: Maybe<Scalars['String']>;
    /** Тариф */
    tariff: Scalars['Float'];
    /** Дата и время начала действия договора */
    startDatetime: Scalars['DateTime'];
    /** Дата и время завершения действия договора */
    endDatetime?: Maybe<Scalars['DateTime']>;
    /** Договор по умолчанию */
    isDefault: Scalars['Boolean'];
    /** Страховая компания */
    insuranceCompany?: Maybe<IInsuranceCompanyType>;
  }

  export interface IInsuranceInitialInfoType {
    /** JSON schema для входных данных страхования */
    schema?: Maybe<Scalars['JSONString']>;
    /** Значения (key-value) для полей формы */
    fieldValues?: Maybe<Scalars['JSONString']>;
    /** Значения по умолчанию для полей формы */
    defaultValues?: Maybe<Scalars['JSONString']>;
  }

  export interface IInsuranceParametersType {
    /** Страховать груз по всем заявкам */
    insureByDefault: Scalars['Boolean'];
  }

  export interface IInsuranceReferenceType {
    /** Код записи справочника */
    code?: Maybe<Scalars['String']>;
    /** Наименование записи справочника */
    value?: Maybe<Scalars['String']>;
  }

  /** An enumeration. */
  export enum ILegalFormCode {
    /** ООО */
    A_12300 = 'A_12300',
    /** ПАО */
    A_12247 = 'A_12247',
    /** НАО */
    A_12267 = 'A_12267',
    /** ИП */
    A_50102 = 'A_50102',
    /** Самозанятый */
    A_00000 = 'A_00000',
  }

  /** Организационно-правовая форма */
  export interface ILegalFormType {
    id: Scalars['ID'];
    /** Сокращённое название организационно-правовой формы */
    shortName: Scalars['String'];
    /** Название организационно-правовой формы */
    name: Scalars['String'];
    /** Код общеросийского классификатора организационно-правовой формы */
    code: ILegalFormCode;
  }

  export interface ILoadingTypeType {
    id: Scalars['ID'];
    /** Наименование типа погрузки/разгрузки */
    name: Scalars['String'];
  }

  /** An enumeration. */
  export enum ILotStepPriceType {
    /** Простой */
    Simple = 'SIMPLE',
    /** Расчётный */
    Calculated = 'CALCULATED',
  }

  export interface ILotType {
    /** Дата и время начала аукциона */
    startDatetime: Scalars['DateTime'];
    /** Дата и время завершения аукциона */
    endDatetime: Scalars['DateTime'];
    /** Исходная цена */
    startPrice: Scalars['Int'];
    /** Последняя ставка */
    lastBet?: Maybe<Scalars['Int']>;
    /** Дата и время когда была сделана последняя ставка */
    lastBetDatetime?: Maybe<Scalars['DateTime']>;
    /** Отслеживать ход торгов */
    enableNotifications: Scalars['Boolean'];
    /** Тип шага цены: простой или расчётный */
    stepPriceType: ILotStepPriceType;
    /** Возможность делать ставку равную начальной цене */
    betCanEqualStartPrice: Scalars['Boolean'];
    /** Минимальный шаг торгов */
    minStepPrice: Scalars['Int'];
    /** Дополнительные условия по рыночной заявке */
    extraConditionsForMarket?: Maybe<Scalars['String']>;
    /** Заявка имеет дополнительные условия по рынку для перевозки */
    hasExtraConditionsForMarket: Scalars['Boolean'];
    /** Заявка */
    order?: Maybe<IOrderType>;
    /** ID аукциона (совпадает с ID заявки) */
    id?: Maybe<Scalars['ID']>;
    /** Тип аукциона */
    type?: Maybe<Scalars['String']>;
    /** Последняя ставка сделана мной? */
    isLastBetMine?: Maybe<Scalars['Boolean']>;
    /** Последняя ставка сделана моей организацией? */
    isLastBetByMyOrganization?: Maybe<Scalars['Boolean']>;
    /** Статус аукциона (зависит от статуса заявки) */
    auctionStatus?: Maybe<Scalars['String']>;
    /** Можно ли сейчас продлить аукцион */
    mayBeProlonged?: Maybe<Scalars['Boolean']>;
    /** Время до завершения аукциона */
    viewEndDatetime?: Maybe<Scalars['DateTime']>;
  }

  export interface INonTrackableSlaCriterionType {
    /** Название критерия */
    name?: Maybe<Scalars['String']>;
    /** Описание */
    description?: Maybe<Scalars['String']>;
  }

  /** Настройки уведомлений */
  export interface INotificationSettingsInput {
    /** Уведомление через веб-сокеты */
    web?: Maybe<Scalars['Boolean']>;
    /** Уведомление по электронной почте */
    email?: Maybe<Scalars['Boolean']>;
    /** Уведомление по SMS */
    sms?: Maybe<Scalars['Boolean']>;
    /** Уведомление на мобильное приложение */
    mobilePush?: Maybe<Scalars['Boolean']>;
  }

  /** Настройки уведомлений пользователя */
  export interface INotificationSettingsOverallInput {
    /** Аукционы */
    auctionOrder?: Maybe<INotificationSettingsInput>;
    /** Свободные заявки */
    freeOrder?: Maybe<INotificationSettingsInput>;
    /** Назначенные заявки */
    assignedOrder?: Maybe<INotificationSettingsInput>;
    /** Выполнение заявки */
    completeOrder?: Maybe<INotificationSettingsInput>;
    /** Торги на повышение */
    biddingOrder?: Maybe<INotificationSettingsInput>;
    /** Тендеры */
    tenderOrder?: Maybe<INotificationSettingsInput>;
    /** Отмена заявок */
    cancelOrder?: Maybe<INotificationSettingsInput>;
    /** Запрос цены */
    priceQuotation?: Maybe<INotificationSettingsInput>;
    /** Оформление документов */
    documentProcessing?: Maybe<INotificationSettingsInput>;
  }

  /** Настройки уведомлений пользователя */
  export interface INotificationSettingsOverallType {
    /** Аукционы */
    auctionOrder?: Maybe<INotificationSettingsType>;
    /** Свободные заявки */
    freeOrder?: Maybe<INotificationSettingsType>;
    /** Назначенные заявки */
    assignedOrder?: Maybe<INotificationSettingsType>;
    /** Выполнение заявки */
    completeOrder?: Maybe<INotificationSettingsType>;
    /** Торги на повышение */
    biddingOrder?: Maybe<INotificationSettingsType>;
    /** Тендеры */
    tenderOrder?: Maybe<INotificationSettingsType>;
    /** Отмена заявок */
    cancelOrder?: Maybe<INotificationSettingsType>;
    /** Запрос цены */
    priceQuotation?: Maybe<INotificationSettingsType>;
    /** Оформление документов */
    documentProcessing?: Maybe<INotificationSettingsType>;
    /** Критерии обслуживания */
    slaCriteria?: Maybe<INotificationSettingsType>;
  }

  /** Настройки уведомлений */
  export interface INotificationSettingsType {
    /** Уведомление через веб-сокеты */
    web?: Maybe<Scalars['Boolean']>;
    /** Уведомление по электронной почте */
    email?: Maybe<Scalars['Boolean']>;
    /** Уведомление по SMS */
    sms?: Maybe<Scalars['Boolean']>;
    /** Уведомление на мобильное приложение */
    mobilePush?: Maybe<Scalars['Boolean']>;
  }

  export interface INotificationType {
    id: Scalars['ID'];
    eventDatetime: Scalars['DateTime'];
    title: Scalars['String'];
    text: Scalars['String'];
    topic?: Maybe<Scalars['String']>;
    action?: Maybe<Scalars['String']>;
    payload?: Maybe<Scalars['JSONString']>;
    isRead: Scalars['Boolean'];
  }

  export enum IOrderAllocationType {
    /** Простая заявка */
    SimpleAllocation = 'SIMPLE_ALLOCATION',
    /** Аукционная заявка */
    AuctionAllocation = 'AUCTION_ALLOCATION',
    /** Назначенная */
    PreassignedAllocation = 'PREASSIGNED_ALLOCATION',
    /** Торги на повышение */
    BiddingAllocation = 'BIDDING_ALLOCATION',
  }

  export interface IOrderAttributesType {
    /** Необходимо наличие успешной проверки грузоперевозчика для его работы с заявкой */
    isScored: Scalars['Boolean'];
    /** Необходимо наличие ЭЦП (электронно-цифровой подписи) грузоперевозчика для его работы с заявкой */
    hasDigitalSignature: Scalars['Boolean'];
    /** Необходимо наличие собственных ТС у грузоперевозчика для его работы с заявкой */
    hasOwnVehicle: Scalars['Boolean'];
    /** По заявке начат процесс смены водителя и ТС */
    isDriverAndVehicleChanging: Scalars['Boolean'];
    /** Таймер смены водителя и ТС остановлен */
    isDriverAndVehicleChangeTimerOnPause?: Maybe<Scalars['Boolean']>;
    /** Оставшееся время для смены водителя и ТС(в минутах) */
    driverAndVehicleChangeTimeLeft?: Maybe<Scalars['Int']>;
  }

  export enum IOrderBusinessRelationShipToCarrier {
    /** На аккредитации */
    OnAccreditation = 'ON_ACCREDITATION',
    /** Аккредитован */
    Accredited = 'ACCREDITED',
    /** Отказано в аккредитации */
    NotAccredited = 'NOT_ACCREDITED',
    /** Контракт подписан */
    ContractIsSigned = 'CONTRACT_IS_SIGNED',
  }

  export interface IOrderDocumentTypeType {
    id: Scalars['ID'];
    /** Название типа документа */
    name: Scalars['String'];
    innerName?: Maybe<Scalars['String']>;
    /** Тип виден для graphql */
    isVisible: Scalars['Boolean'];
    /** Документ этого типа может состоять из нескольких файлов */
    canHaveSeveralFiles: Scalars['Boolean'];
  }

  /** An enumeration. */
  export enum IOrderInsuranceStatus {
    /** Ожидание назначения ТС */
    WaitingTransport = 'WAITING_TRANSPORT',
    /** Приготовлено для страхования */
    Prepared = 'PREPARED',
    /** Выполняется */
    InProgress = 'IN_PROGRESS',
    /** Застраховано */
    Insured = 'INSURED',
    /** Отклонено */
    Rejected = 'REJECTED',
    /** Отменён */
    Canceled = 'CANCELED',
    /** Ошибка */
    Error = 'ERROR',
  }

  export interface IOrderInsuranceType {
    /** Договор с СК */
    insuranceContract?: Maybe<IInsuranceContractType>;
    /** Статус */
    status: IOrderInsuranceStatus;
    /** URL страхового сертификата */
    certificateUrl?: Maybe<Scalars['String']>;
    /** URL счета на оплату страхового сертификата */
    invoiceUrl?: Maybe<Scalars['String']>;
    /** Данные для страхования */
    data?: Maybe<Scalars['JSONString']>;
    /** Застраховал ли ГО заявку */
    isOwnerInsurance?: Maybe<Scalars['Boolean']>;
  }

  /** Информация о менеджере заявки */
  export interface IOrderOwnerType {
    id: Scalars['ID'];
    /** Адрес электронной почты пользователя */
    email: Scalars['String'];
    /** Имя пользователя */
    firstName: Scalars['String'];
    /** Фамилия пользователя */
    lastName: Scalars['String'];
    phoneNumber?: Maybe<Scalars['String']>;
    /** Отчество пользователя */
    patronymic?: Maybe<Scalars['String']>;
  }

  export interface IOrderRecordFromReportType {
    /** Номер счета-фактуры */
    invoiceNo?: Maybe<Scalars['String']>;
    /** Дата счета-фактуры */
    invoiceDate?: Maybe<Scalars['Date']>;
    /** Файл счета-фактуры */
    invoiceFile?: Maybe<IUploadedFileType>;
    /** Номер акта */
    actNo?: Maybe<Scalars['String']>;
    /** Дата акта */
    actDate?: Maybe<Scalars['Date']>;
    /** Файл акта */
    actFile?: Maybe<IUploadedFileType>;
    /** Заявка на перевозку */
    order?: Maybe<IOrderType>;
  }

  export interface IOrderRecordInput {
    /** Id заявки на перевозку */
    orderId?: Maybe<Scalars['ID']>;
    /** Id файла счета-фактуры */
    invoiceFileId?: Maybe<Scalars['ID']>;
    /** Id файла акта */
    actFileId?: Maybe<Scalars['ID']>;
    /** Номер счета-фактуры. Длина (max): 50 */
    invoiceNo?: Maybe<Scalars['String']>;
    /** Дата счета-фактуры */
    invoiceDate?: Maybe<Scalars['Date']>;
    /** Номер акта. Длина (max): 50 */
    actNo?: Maybe<Scalars['String']>;
    /** Дата акта */
    actDate?: Maybe<Scalars['Date']>;
  }

  export enum IOrderSigningWay {
    /** Приложение скана заявки */
    OrderScan = 'ORDER_SCAN',
    /** Приложение файла ЭЦП */
    DigitalSignature = 'DIGITAL_SIGNATURE',
    /** (Deprecated!)Подписание через Tn-crypto организациями */
    TnCrypto = 'TN_CRYPTO',
  }

  export enum IOrderStatus {
    /** Доступно */
    Free = 'FREE',
    /** Назначен перевозчик */
    Assigned = 'ASSIGNED',
    /** Завершено */
    Completed = 'COMPLETED',
    /** Отменено */
    Canceled = 'CANCELED',
    /** Транспорт зарезервирован */
    TransportReserved = 'TRANSPORT_RESERVED',
    /** Заявка-договор подписана и загружена */
    ContractAttached = 'CONTRACT_ATTACHED',
    /** Оформление заявки завершено, заказ готов к перевозке */
    ReadyToGo = 'READY_TO_GO',
  }

  /**
   * READY_TO_GO - в исполнении/без МЛ
   * NEW - в исполнении
   * DRIVER_CONFIRMED - водитель подтвержден
   * ON_ROUTE - транспорт выехал на маршрут (по статусу МЛ)
   * DEPARTURE_ON_ROUTE - транспорт выехал на маршрут (по событию из МЛ)
   * DELIVERY_COMPLETED - доставка завершена
   * CANCELED - заявка отменена
   * EMPTY - None
   */
  export enum IOrderStatusOnEvent {
    ReadyToGo = 'READY_TO_GO',
    New = 'NEW',
    DriverConfirmed = 'DRIVER_CONFIRMED',
    OnRoute = 'ON_ROUTE',
    DepartureOnRoute = 'DEPARTURE_ON_ROUTE',
    DeliveryCompleted = 'DELIVERY_COMPLETED',
    Canceled = 'CANCELED',
    Empty = 'EMPTY',
  }

  export interface IOrderStoragePointAttachedDocumentType {
    /** Наименование прикрепленного документа */
    filename?: Maybe<Scalars['String']>;
    /** URL прикрепленного документа */
    url?: Maybe<Scalars['String']>;
  }

  export interface IOrderStoragePointContactPerson {
    fullName?: Maybe<Scalars['String']>;
    phoneNumber?: Maybe<Scalars['String']>;
  }

  /** Указанное в заявке место погрузки/доставки */
  export interface IOrderStoragePointType {
    id: Scalars['ID'];
    /** Указанные в заявке дата и предполагаемое время начала периода прибытия транспорта */
    arriveDatetimeFrom?: Maybe<Scalars['DateTime']>;
    /** Указанные в заявке дата и предполагаемое время окончания периода прибытия транспорта */
    arriveDatetimeTo?: Maybe<Scalars['DateTime']>;
    /** Фактическое время прибытия на точку доставки */
    factArriveDatetimeFrom?: Maybe<Scalars['DateTime']>;
    /** Фактическое время оканчания работы на точке доставки */
    factArriveDatetimeTo?: Maybe<Scalars['DateTime']>;
    /** Комментарий к месту погрузки/разгрузки */
    comment?: Maybe<Scalars['String']>;
    /** Порядковый номер погрузки/разгрузки в рамках заявки */
    seqId?: Maybe<Scalars['Int']>;
    storagePoint?: Maybe<IStoragePointDataType>;
    /** Deprecated! Стоит использовать arrive_datetime_from */
    workDatetime?: Maybe<Scalars['DateTime']>;
    /** Контактное лицо на точке погрузки/доставки */
    contactPersonAtStoragePoint?: Maybe<Scalars['String']>;
    /** Контактный телефон на точке погрузки/доставки */
    contactPhoneAtStoragePoint?: Maybe<Scalars['String']>;
    /** Контактное лицо на точке погрузки/доставки */
    contactPersons?: Maybe<Array<Maybe<IOrderStoragePointContactPerson>>>
    /** Тип погрузки/разгрузки */
    loadingType?: Maybe<ILoadingTypeType>;
    /** Список прикрепленных файлов */
    attachedDocuments?: Maybe<Array<Maybe<IOrderStoragePointAttachedDocumentType>>>;
  }

  export interface IOrderType {
    /** Уникальный идентификатор заявки */
    id?: Maybe<Scalars['ID']>;
    /** Дата и время создания */
    createdDatetime?: Maybe<Scalars['DateTime']>;
    /** Вес */
    weight?: Maybe<Scalars['Float']>;
    /** Объём */
    volume?: Maybe<Scalars['Float']>;
    /** Стоимость */
    price?: Maybe<IPriceType>;
    /** Комментарий */
    comment?: Maybe<Scalars['String']>;
    /** Статус заявки */
    status?: Maybe<IOrderStatus>;
    /** Информация о водителе */
    driverInfo?: Maybe<Scalars['String']>;
    /** Информация об автомобиле */
    truckInfo?: Maybe<Scalars['String']>;
    /** Информация об автомобиле */
    trailerInfo?: Maybe<Scalars['String']>;
    /** Дата и время указания информации о водителе, автомобиле и прицепе */
    transportReservedDatetime?: Maybe<Scalars['DateTime']>;
    /** Дата и время загрузки скана заявки-договора */
    contractAttachedDatetime?: Maybe<Scalars['DateTime']>;
    /** Дата и время завершения оформления заявки */
    readyToGoDatetime?: Maybe<Scalars['DateTime']>;
    /** Тип размещения заявки */
    allocationType?: Maybe<IOrderAllocationType>;
    /** Цена включает НДС */
    isVatIncluded?: Maybe<Scalars['Boolean']>;
    /** Количество грузовых мест */
    cargoPackagesCount?: Maybe<Scalars['Int']>;
    /** Лот */
    lot?: Maybe<ILotType>;
    /** Лот торгов на повышение */
    biddinglot?: Maybe<IBiddingLotType>;
    /** Номер заявки во внешней системе */
    externalNo?: Maybe<Scalars['String']>;
    /** Признак размещения заявки на рынке */
    isMarket?: Maybe<Scalars['Boolean']>;
    /** Сроки оплаты */
    paymentPeriod?: Maybe<Scalars['String']>;
    /** Дедлайн для обработки назначенной заявки */
    processDeadline?: Maybe<Scalars['DateTime']>;
    /** Дополнительные услуга по заявке */
    extraServices?: Maybe<Array<Maybe<IExtraServicesType>>>;
    /** Организация, указанная в качестве заказчика перевозки */
    customerOrganization?: Maybe<ICargoOwnerOrganizationType>;
    /** Признак того, каким образом происходит подписание заявок */
    signingWay?: Maybe<IOrderSigningWay>;
    /** Температурный режим (градусы Цельсия) */
    temperatureMode?: Maybe<Scalars['Int']>;
    /** Заявка только на просмотр */
    isReadonly?: Maybe<Scalars['Boolean']>;
    /** Время на бронирование свободной заявки, указанная для текущей заявки, в минутах */
    timeOrderReservation?: Maybe<Scalars['Int']>;
    /** Количество участников торговой процедуры */
    tradingParticipantsCount?: Maybe<Scalars['Int']>;
    /** Отгрузка обязательна в согласованную с покупателем дату */
    isShipmentOnAgreedDate?: Maybe<Scalars['Boolean']>;
    /** Виды номенклатуры */
    cargoNomenclatureTypes?: Maybe<Scalars['String']>;
    /** Дополнительное время на обработку назначенной заявки, в секундах */
    gracePeriod?: Maybe<Scalars['Int']>;
    /** Дата и время создания заявки на транспорт в 1С */
    deliveryRequestDateUtc?: Maybe<Scalars['DateTime']>;
    /** Оригинальная заявка */
    sourceOrder?: Maybe<IOrderType>;
    /** Перевозка для заказчиков DIY, например, Leroy Merlin */
    diy?: Maybe<Scalars['Boolean']>;
    /** Признак того, что в заявке не нарушен ни один критерий SLA */
    slaObserved?: Maybe<Scalars['Boolean']>;
    /** Тип груза */
    cargoType?: Maybe<Scalars['String']>;
    /** Наименование типа груза */
    cargoTypeName?: Maybe<Scalars['String']>;
    /** Получение информации о первом месте погрузки в заявке */
    loadingPlace?: Maybe<IStoragePointDataType>;
    /** Дата погрузки */
    loadingDate?: Maybe<Scalars['Date']>;
    /** Контактное лицо на первом месте погрузки в заявке */
    contactPersonAtLoadingPlace?: Maybe<Scalars['String']>;
    /** Получение информации о последнем месте доставки в заявке */
    unloadingPlace?: Maybe<IStoragePointDataType>;
    /** Дата доставки */
    unloadingDate?: Maybe<Scalars['Date']>;
    /** Контактное лицо на последнем месте доставки в заявке */
    contactPersonAtUnloadingPlace?: Maybe<Scalars['String']>;
    /** Список мест погрузки указанной заявки */
    loadingPlaces?: Maybe<Array<Maybe<IOrderStoragePointType>>>;
    /** Список мест доставки указанной заявки */
    unloadingPlaces?: Maybe<Array<Maybe<IOrderStoragePointType>>>;
    /** Получение информации о водителе */
    driver?: Maybe<IDriverDataType>;
    /** Получение информации о сменном водителе */
    coDriver?: Maybe<IDriverType>;
    /** Получение информации о ТС */
    vehicle?: Maybe<IVehicleDataType>;
    /** Информация о прицепе/полуприцепе */
    vehicletrailer?: Maybe<IVehicleTrailerDataType>;
    /** Дополнительная информация */
    extraData?: Maybe<Scalars['JSONString']>;
    /** Получение информации о требованиях к транспортному средству */
    vehicleRequirements?: Maybe<IVehicleRequirementsType>;
    /** Получение информации о требованиях к грузовому месту */
    cargoPlaceRequirements?: Maybe<ICargoPlaceRequirementsType>;
    /** Есть ли страховака */
    hasInsurance?: Maybe<Scalars['Boolean']>;
    /** Информация о менеджере заявки */
    owner?: Maybe<IOrderOwnerType>;
    /** Тип оплаты */
    paymentType?: Maybe<IPaymentTypeType>;
    /** Цена с НДС */
    priceWithVat?: Maybe<IPriceType>;
    /** Цена без НДС */
    priceWithoutVat?: Maybe<IPriceType>;
    /** Цена заявки-договора */
    contractPrice?: Maybe<Scalars['Float']>;
    /** Статус заявки по событиям из МЛ */
    statusOnEvent?: Maybe<Scalars['String']>;
    /**
     * Время завершения в заявке
     * @deprecated Для web и мобильных клиентов этот параметр заменен на viewEndDatetime.
     */
    endDatetime?: Maybe<Scalars['DateTime']>;
    /** Время завершения, которое рассчитывается для web-клиента, в зависимости от таймера в заявке */
    viewEndDatetime?: Maybe<Scalars['DateTime']>;
    /** Дедлайн для обработки назначенной заявки,который рассчитывается для web-клиента, в зависимости от таймера в заявке */
    viewProcessDeadline?: Maybe<Scalars['DateTime']>;
    /** Дедлайн для того чтобы забрать свободную заявку или сделать ставку для аукционной заявки,который рассчитывается для web-клиента, в зависимости от таймера в заявке */
    acceptDeadline?: Maybe<Scalars['DateTime']>;
    /**
     * Время до завершения заявки по времени(в секундах)
     * @deprecated Этот параметр в следующих версиях будет заменен на secondsToLifeTimeExpired.
     */
    secondsToCancel?: Maybe<Scalars['Int']>;
    /** Время до завершения заявки по времени жизни(в секундах) */
    secondsToLifeTimeExpired?: Maybe<Scalars['Int']>;
    /** Время до завершения заявки по времени бронирования(в секундах) */
    secondsToReservationTimeExpired?: Maybe<Scalars['Int']>;
    /** Может ли пользователь редактировать список загруженных документов */
    canModifyUploadedDocuments?: Maybe<Scalars['Boolean']>;
    /** Может ли пользователь отменить резерв транспорта(открепить ТС, водителя) */
    canUnreserveTransport?: Maybe<Scalars['Boolean']>;
    /** Загруженные документы */
    uploadedDocuments?: Maybe<Array<Maybe<IOrderUploadedDocumentType>>>;
    /** Загруженные документы */
    uploadedDocumentsV2?: Maybe<Array<Maybe<IOrderUploadedDocumentType>>>;
    /** Список типов файлов, которые необходимо подписывать */
    settingsESignRequired?: Maybe<Array<Maybe<IOrderDocumentTypeType>>>;
    /** Маршрутный лист по заявке */
    routeSheet?: Maybe<IRouteSheetType>;
    /** Текущий статус документа */
    documentActualStatus?: Maybe<Array<Maybe<IDocumentStatusType>>>;
    /** История статус по документу */
    documentStatusHistory?: Maybe<Array<Maybe<IDocumentStatusType>>>;
    /** Дополнительные настройки заявки */
    orderAttributes?: Maybe<IOrderAttributesType>;
    /** Страховка */
    insurance?: Maybe<IOrderInsuranceType>;
    /** Обязательно ли страховать перевозку */
    isInsuranceRequired?: Maybe<Scalars['Boolean']>;
    /** Контрагент */
    buyer?: Maybe<IBuyerType>;
    /** Договор ГО со страховой компанией */
    ownerInsuranceContract?: Maybe<IInsuranceContractType>;
    /** Фактическая организация-перевозчик (назначается ТЭКом), осуществляющая перевозку */
    actualCarrierOrganization?: Maybe<IAgentCarrierType>;
    /** Для завершения заявки необходимо указать информацию по транспортным накладнымдля всех мест доставки */
    waybillRequired?: Maybe<Scalars['Boolean']>;
    /** Информация для всех транспортных накладных указана */
    hasWaybillInfo?: Maybe<Scalars['Boolean']>;
    /** Перевозка осуществляется собственным транспортным средством ТЭКа */
    agentUsesOwnVehicle?: Maybe<Scalars['Boolean']>;
    /** Расшифровка фрахта и агентского вознаграждения в заявке с НДС и без НДС */
    agentFeeAndFreightDetails?: Maybe<IAgentFeeAndFreightDetails>;
    /** Генеральный договор с грузоотправителем */
    carrierContract?: Maybe<ICarrierContractType>;
    /** Дата и время создания заявки-договора(1С) */
    documentContractCreatedAt1c?: Maybe<Scalars['DateTime']>;
    /** Заявка ранее назначалась на этого ГП */
    isPreviouslyAssigned?: Maybe<Scalars['Boolean']>;
    /** Отгрузка обязательна в согласованную с покупателем дату и статус не завершенный */
    isShipmentOnAgreedDateAndStatusNotCompleted?: Maybe<Scalars['Boolean']>;
    /** Связанный тендер */
    tender?: Maybe<ITenderType>;
    /** Типы погрузки/разгрузки */
    loadingTypes?: Maybe<Scalars['String']>;
    /** Может ли пользователь выбрать другой генеральный договор */
    canChangeContract?: Maybe<Scalars['Boolean']>;
    /** Внешний ID заявки */
    extId?: Maybe<Scalars['String']>;
    /** Может ли ГП отменить заявку */
    canCancelOrder?: Maybe<Scalars['Boolean']>;
    /** Список документов, которые ещё находятся в обработке */
    genDocumentsPending?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** SLA организации */
    sla?: Maybe<ISlaType>;
    /** Статус партнерства перевозчика по отношению к владельцу заказа */
    carrierBusinessRelationshipToOwner?: Maybe<IOrderBusinessRelationShipToCarrier>;
    /** Информация о фактической организации-перевозчике */
    agentCarrierInfo?: Maybe<IAgentCarrierInfoType>;
    /** Информация о грузах */
    cargoInfo?: Maybe<Scalars['JSONString']>;
    /** Список грузов в заявке */
    cargos?: Maybe<Array<Maybe<ICargoType>>>;
    /** Настройки документооборота */
    documentFlowSettings?: Maybe<IDocumentFlowSettingsEnum>;
    /** Статус документооборота */
    edmStatus?: Maybe<Scalars['String']>;
  }

  export type IOrderTypeLoadingPlaceArgs = {
    id?: Maybe<Scalars['ID']>;
  };

  export type IOrderTypeUnloadingPlaceArgs = {
    id?: Maybe<Scalars['ID']>;
  };

  export type IOrderTypeCoDriverArgs = {
    id?: Maybe<Scalars['ID']>;
  };

  export type IOrderTypeStatusOnEventArgs = {
    value?: Maybe<IOrderStatusOnEvent>;
  };

  export type IOrderTypeDocumentActualStatusArgs = {
    documentType?: Maybe<IDocumentTypeInnerNameEnum>;
  };

  export type IOrderTypeDocumentStatusHistoryArgs = {
    documentType?: Maybe<IDocumentTypeInnerNameEnum>;
  };

  export interface IOrderUploadedDocumentFileType {
    /** id загруженного файла */
    id?: Maybe<Scalars['ID']>;
    /** Документ */
    document: IOrderUploadedDocumentType;
    /** Загруженный файл */
    uploadedFile: IUploadedFileType;
    /** Дата загрузки файла пользователем */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Название файла */
    name?: Maybe<Scalars['String']>;
    /** Ссылка для скачивания файла */
    url?: Maybe<Scalars['String']>;
  }

  export interface IOrderUploadedDocumentType {
    id: Scalars['ID'];
    /** Организация-владелец */
    organization: ICarrierOrganizationType;
    /** Тип документа */
    documentType: IOrderDocumentTypeType;
    /** Загруженный файл */
    uploadedFile?: Maybe<IUploadedFileType>;
    /** Подписываемый файл */
    signedFile?: Maybe<IOrderUploadedDocumentType>;
    /** Может ли пользователь удалить документ */
    deletable?: Maybe<Scalars['Boolean']>;
    /** Информация по транспортной накладной */
    waybillInfo?: Maybe<IWaybillInfoType>;
    /** Информация по заявке-договору */
    contractInfo?: Maybe<IContractInfoType>;
    /** Загруженные файлы */
    uploadedFiles?: Maybe<Array<Maybe<IOrderUploadedDocumentFileType>>>;
  }

  /** Счетчики заявок ГП */
  export interface IOrdersCountersType {
    /** Количество всех свободных заявок в системе */
    free?: Maybe<Scalars['Int']>;
    /** Количество всех назначенных заявок в системе */
    assigned?: Maybe<Scalars['Int']>;
    /** Количество активных торгов */
    bidding?: Maybe<Scalars['Int']>;
    /** Количество активных аукционов */
    auction?: Maybe<Scalars['Int']>;
  }

  export interface IOrganizationDataType {
    /** Организационно-правовая форма (код классификации) */
    legalFormCode?: Maybe<Scalars['String']>;
    /** Юридический адрес */
    address?: Maybe<Scalars['String']>;
    /** ОГРН */
    ogrn?: Maybe<Scalars['String']>;
    /** КПП */
    kpp?: Maybe<Scalars['String']>;
    /** Наименование компании */
    companyName?: Maybe<Scalars['String']>;
    /** Статус компании */
    status?: Maybe<Scalars['String']>;
  }

  /** An enumeration. */
  export enum IOrganizationDocumentFlowSettings {
    /** Грузовладелец первым подписывает договор */
    CargoOwnerFirst = 'CARGO_OWNER_FIRST',
    /** Договор подписывает только грузоперевозчик */
    CarrierOnly = 'CARRIER_ONLY',
  }

  /** An enumeration. */
  export enum IOrganizationDocumentTypeInnerName {
    /** Документ о собственности/аренде офисного помещения */
    OwnershipDoc = 'OWNERSHIP_DOC',
    /** Открытая часть электронной подписи */
    OpenDigitSign = 'OPEN_DIGIT_SIGN',
  }

  export interface IOrganizationExistenceHistoryType {
    /** Дата и время создания */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Статус существования организации-подрядчика */
    status?: Maybe<Scalars['String']>;
  }

  export type IOrganizationExistenceHistoryTypeStatusArgs = {
    value?: Maybe<IStateStatusEnum>;
  };

  export interface IOrganizationForCargoOwnerType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    /** Название организации */
    name: Scalars['String'];
    /** Описание организации */
    about?: Maybe<Scalars['String']>;
    /** Телефон организации */
    phoneNumber?: Maybe<Scalars['String']>;
    /** Электронная почта */
    email?: Maybe<Scalars['String']>;
    /** ИНН */
    inn?: Maybe<Scalars['String']>;
    /** Юридический адрес */
    legalAddress?: Maybe<Scalars['String']>;
    /** Параметр, обозначающий pk настроек персонализации */
    personalizationSettings?: Maybe<Scalars['ID']>;
    /** Параметр, отвечающий за поддомен организации */
    subdomain?: Maybe<Scalars['String']>;
    /** Параметр, отвечающий за URL логотипа организации */
    logo?: Maybe<Scalars['String']>;
    /** Статус партнерства ГП по отношению к ГВ */
    carrierBusinessRelationshipState?: Maybe<Scalars['String']>;
  }

  /** An enumeration. */
  export enum IOrganizationMarketAttributesDriverCheck {
    /** Проверка не проводилась */
    NotChecked = 'NOT_CHECKED',
    /** Принято в работу */
    OnWork = 'ON_WORK',
    /** Одобрено */
    Approved = 'APPROVED',
    /** Отклонено */
    Rejected = 'REJECTED',
  }

  /** An enumeration. */
  export enum IOrganizationMarketAttributesEsignCheck {
    /** Проверка не проводилась */
    NotChecked = 'NOT_CHECKED',
    /** Принято в работу */
    OnWork = 'ON_WORK',
    /** Одобрено */
    Approved = 'APPROVED',
    /** Отклонено */
    Rejected = 'REJECTED',
  }

  /** An enumeration. */
  export enum IOrganizationMarketAttributesOrganizationCheck {
    /** Проверка не проводилась */
    NotChecked = 'NOT_CHECKED',
    /** Принято в работу */
    OnWork = 'ON_WORK',
    /** Одобрено */
    Approved = 'APPROVED',
    /** Отклонено */
    Rejected = 'REJECTED',
  }

  export interface IOrganizationMarketAttributesType {
    /** Доступ на рынок запрашивался */
    accessRequested: Scalars['Boolean'];
    /** Состояние проверки данных по организации */
    organizationCheck: IOrganizationMarketAttributesOrganizationCheck;
    /** Состояние проверки данных по водителю */
    driverCheck: IOrganizationMarketAttributesDriverCheck;
    /** Состояние проверки данных по ТС */
    vehicleCheck: IOrganizationMarketAttributesVehicleCheck;
    /** Состояние проверки данных по ЭЦП */
    esignCheck: IOrganizationMarketAttributesEsignCheck;
    /** Параметры безопасного пространства */
    trustedOrderPreference?: Maybe<ITrustedOrderPreferenceType>;
  }

  /** An enumeration. */
  export enum IOrganizationMarketAttributesVehicleCheck {
    /** Проверка не проводилась */
    NotChecked = 'NOT_CHECKED',
    /** Принято в работу */
    OnWork = 'ON_WORK',
    /** Одобрено */
    Approved = 'APPROVED',
    /** Отклонено */
    Rejected = 'REJECTED',
  }

  /** An enumeration. */
  export enum IOrganizationRegistrationSource {
    /** Саморегистрация */
    SelfRegistration = 'SELF_REGISTRATION',
    /** Ручное создание */
    ManualRegistration = 'MANUAL_REGISTRATION',
    /** Зарегистрирована агентом (ТЭК) */
    AgentRegistration = 'AGENT_REGISTRATION',
    /** Создана при синхронизации с 1С */
    ReceivedFrom_1C = 'RECEIVED_FROM_1C',
  }

  /** An enumeration. */
  export enum IOrganizationRole {
    /** Грузовладелец */
    CargoOwner = 'CARGO_OWNER',
    /** Грузоперевозчик */
    Carrier = 'CARRIER',
    /** Сотрудник службы безопасности */
    TndlSecurityStaff = 'TNDL_SECURITY_STAFF',
  }

  export interface IOrganizationUploadedDocumentType {
    id: Scalars['ID'];
    /** Организация */
    organization: ICarrierOrganizationType;
    /** Тип документа */
    documentType: IDocumentTypeType;
    /** Загруженный файл */
    uploadedFile: IUploadedFileType;
  }

  export interface IPaymentTypeType {
    id: Scalars['ID'];
    /** Название типа оплаты */
    name: Scalars['String'];
  }

  /** Право доступа пользователя */
  export interface IPermissionType {
    id: Scalars['ID'];
    codename: Scalars['String'];
    /** Описание права доступа */
    description?: Maybe<Scalars['String']>;
  }

  export interface IPriceInput {
    /** Цена */
    amount?: Maybe<Scalars['Decimal']>;
    /** Валюта */
    currency?: Maybe<Scalars['String']>;
  }

  /** Фильтр по запросам цен для организации ГП */
  export enum IPriceQuotationCarrierEnum {
    Active = 'ACTIVE',
    MyResponse = 'MY_RESPONSE',
    Default = 'DEFAULT',
  }

  /** Информация ответа ГП на запрос цены */
  export interface IPriceQuotationResponseType {
    id: Scalars['ID'];
    /** Организация, ответившая на запрос цены */
    organization: ICarrierOrganizationType;
    /** Цена */
    price: Scalars['Int'];
    /** Цена включает НДС */
    isVatIncluded: Scalars['Boolean'];
    /** Комментарий ответа к запросу цены */
    comment: Scalars['String'];
  }

  /** Информация запроса цены от ГО */
  export interface IPriceQuotationType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    /** Дата и время создания */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Организация, от которой был сделан запрос цены */
    organization: ICarrierOrganizationType;
    /** Откуда */
    loadingPlace: Scalars['String'];
    /** Код населенного пункта отправления (ФИАС) */
    loadingPlaceFiasCode: Scalars['String'];
    /** Куда */
    unloadingPlace: Scalars['String'];
    /** Код населенного пункта назначения (ФИАС) */
    unloadingPlaceFiasCode: Scalars['String'];
    /** Требования к ТС: Тип кузова транспортного средства */
    vehicleRequirements?: Maybe<IVehicleRequirementsType>;
    /** Дата и время окончания внесения предложений */
    endDateTime: Scalars['DateTime'];
    /** Шаг цены (кратность) */
    priceStep?: Maybe<Scalars['Int']>;
    /** Комментарий к запросу цены */
    comment?: Maybe<Scalars['String']>;
    /** Завершенный? */
    isFinished: Scalars['Boolean'];
    /** Информация по ответу ГП на запрос цены */
    response?: Maybe<IPriceQuotationResponseType>;
  }

  export interface IPriceType {
    /** Цена */
    amount?: Maybe<Scalars['Decimal']>;
    /** Валюта */
    currency?: Maybe<Scalars['String']>;
  }

  export enum IPriceTypeEnum {
    PerKm = 'PER_KM',
    Total = 'TOTAL',
  }

  /** Профиль пользователя */
  export interface IProfileType {
    id: Scalars['ID'];
    /** Адрес электронной почты пользователя */
    email: Scalars['String'];
    /** Имя пользователя */
    firstName: Scalars['String'];
    /** Фамилия пользователя */
    lastName: Scalars['String'];
    phoneNumber?: Maybe<Scalars['String']>;
    lastLogin?: Maybe<Scalars['DateTime']>;
    /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
    isActive: Scalars['Boolean'];
    dateJoined: Scalars['DateTime'];
    /** Организация, к которой относится пользователь */
    organization?: Maybe<ICarrierOrganizationType>;
    /** Отчество пользователя */
    patronymic?: Maybe<Scalars['String']>;
    uiSettings?: Maybe<Scalars['JSONString']>;
    /** Роль */
    role?: Maybe<Scalars['String']>;
    /** Название организации, к которой относится пользователь */
    companyName?: Maybe<Scalars['String']>;
    /** Список прав пользователя в системе */
    permissions?: Maybe<Array<Maybe<IPermissionType>>>;
    /** Адрес электронной почты техподдержки */
    supportEmail?: Maybe<Scalars['String']>;
    /** Номер телефона техподдержки */
    supportPhoneNumber?: Maybe<Scalars['String']>;
    /** Руководства пользователя */
    userGuides?: Maybe<IUserGuideType>;
    /** Максимальное количество мест погрузки в заявке */
    maxCountLoadingPlace?: Maybe<Scalars['Int']>;
    /** Настройки пользователя */
    userSettings?: Maybe<IUserSettingsType>;
    /** Часовой пояс сервера */
    defaultTimezone?: Maybe<ITimeZoneType>;
  }

  /** An enumeration. */
  export enum IReportOnCompletedOrdersStatus {
    /** Отправлено в ОКС */
    SentToOks = 'SENT_TO_OKS',
    /** Принято в ОКС */
    Draft = 'DRAFT',
    /** Проведен в ОКС */
    AcceptedForAccounting = 'ACCEPTED_FOR_ACCOUNTING',
    /** Оплачен */
    Paid = 'PAID',
  }

  export interface IReportSettingsInput {
    /** Id выбранного типа услуг */
    reportServiceId: Scalars['ID'];
    /** Номер акта. Длина (max): 50 */
    reportNo: Scalars['String'];
    /** Дата документов */
    reportDate: Scalars['Date'];
    /** Номер счета. Длина (max): 50 */
    agentBillNo: Scalars['String'];
    /** Номер счета-фактуры на агентское вознаграждение. Длина (max): 50 */
    agentInvoiceNo?: Maybe<Scalars['String']>;
    /** Номер счета экспедитора. Длина (max): 50 */
    agentCarrierBillNo?: Maybe<Scalars['String']>;
    /** Номер счета-фактуры для фактического перевозчика. Длина (max): 50 */
    actualCarrierInvoiceNo?: Maybe<Scalars['String']>;
    /** Руководитель. Длина (max): 200 */
    headOfOrganization: Scalars['String'];
    /** Главный бухгалтер. Длина (max): 200 */
    chiefAccountant: Scalars['String'];
  }

  export interface IReportSettingsType {
    /** Номер акта */
    reportNo: Scalars['String'];
    /** Дата документов */
    reportDate: Scalars['Date'];
    /** Номер счета */
    agentBillNo: Scalars['String'];
    /** Номер счета-фактуры на агентское вознаграждение */
    agentInvoiceNo?: Maybe<Scalars['String']>;
    /** Номер счета экспедитора */
    agentCarrierBillNo?: Maybe<Scalars['String']>;
    /** Номер счета-фактуры для фактического перевозчика */
    actualCarrierInvoiceNo?: Maybe<Scalars['String']>;
    /** Руководитель */
    headOfOrganization: Scalars['String'];
    /** Главный бухгалтер */
    chiefAccountant: Scalars['String'];
    /** Тип услуги перевозчика */
    service?: Maybe<ICompletedOrdersReportServiceType>;
  }

  export interface IRespondOnPriceQuotationInput {
    /** ID запроса цены */
    priceQuotationId: Scalars['ID'];
    /** Предлагаемая цена */
    price: Scalars['Int'];
    /** НДС включен */
    isVatIncluded: Scalars['Boolean'];
    /** Комментарий */
    comment?: Maybe<Scalars['String']>;
  }

  /** An enumeration. */
  export enum IRouteSheetEventEvent {
    /** Текущее местоположение */
    CurrentLocation = 'CURRENT_LOCATION',
    /** Выезд на маршрут */
    DepartureOnRoute = 'DEPARTURE_ON_ROUTE',
    /** Погрузка завершена */
    CargoLoadingCompleted = 'CARGO_LOADING_COMPLETED',
    /** Приезд на место доставки */
    ArrivedToDeliveryPlace = 'ARRIVED_TO_DELIVERY_PLACE',
    /** Въезд на территорию места доставки */
    EnteredDeliveryPlace = 'ENTERED_DELIVERY_PLACE',
    /** Разгрузка завершена */
    CargoUnloadCompleted = 'CARGO_UNLOAD_COMPLETED',
    /** Задержка по времени */
    TimeLag = 'TIME_LAG',
    /** Водитель не подтвердил МЛ */
    ProblemDriverConfirmed = 'PROBLEM_DRIVER_CONFIRMED',
    /** Водитель не зафиксировал выезд на маршрут */
    ProblemDepartureOnRoute = 'PROBLEM_DEPARTURE_ON_ROUTE',
    /** Водитель не зафиксировал завершение погрузки */
    ProblemCargoLoadingCompleted = 'PROBLEM_CARGO_LOADING_COMPLETED',
    /** Водитель не зафиксировал приезд на разгрузку */
    ProblemArrivedToDeliveryPlace = 'PROBLEM_ARRIVED_TO_DELIVERY_PLACE',
    /** Водитель не зафиксировал въезд на разгрузку */
    ProblemEnteredDeliveryPlace = 'PROBLEM_ENTERED_DELIVERY_PLACE',
    /** Водитель не зафиксировал завершение разгрузки */
    ProblemCargoUnloadCompleted = 'PROBLEM_CARGO_UNLOAD_COMPLETED',
    /** Водитель не зафиксировал завершение доставки */
    ProblemDeliveryCompleted = 'PROBLEM_DELIVERY_COMPLETED',
    /** Приезд на место погрузки */
    ArrivedToLoadingPlace = 'ARRIVED_TO_LOADING_PLACE',
    /** Въезд на территорию места погрузки */
    EnteredLoadingPlace = 'ENTERED_LOADING_PLACE',
    /** Выезд с территории места погрузки */
    DepartureFromLoadingPlace = 'DEPARTURE_FROM_LOADING_PLACE',
  }

  /** An enumeration. */
  export enum IRouteSheetEventIssueTopic {
    /** Задержка по времени */
    TimeLag = 'TIME_LAG',
    /** Координаты точки места погрузки/разгрузки не совпадают с фактическими координатами */
    IncorrectCoordinates = 'INCORRECT_COORDINATES',
  }

  /** An enumeration. */
  export enum IRouteSheetEventSourceInfo {
    /** Интеграция с ТН */
    TnIntegration = 'TN_INTEGRATION',
    /** Мобильное приложение водителя */
    DriverMobile = 'DRIVER_MOBILE',
  }

  /** Информация о событии, произошедшем на маршрутном листе */
  export interface IRouteSheetEventType {
    id: Scalars['ID'];
    /** Статус маршрутного листа */
    event: IRouteSheetEventEvent;
    dateTime?: Maybe<Scalars['DateTime']>;
    /** Долгота места события на маршруте */
    longitude?: Maybe<Scalars['String']>;
    /** Широта места события на маршруте */
    latitude?: Maybe<Scalars['String']>;
    /** Тема возникшей проблемы */
    issueTopic?: Maybe<IRouteSheetEventIssueTopic>;
    /** Описание причины возникновения проблемы */
    issueText?: Maybe<Scalars['String']>;
    /** Источник информации о событии */
    sourceInfo: IRouteSheetEventSourceInfo;
    /** Водитель, для которого сформирован маршрутный лист */
    driver?: Maybe<IDriverType>;
    /**
     * Маршрутный лист, на котором произошло событие
     * @deprecated Меняется направление графа - есть опасность циклического запроса RouteSheet-RouteSheetEvent. Данное поле будет удалено.Для получения событий по МЛ используйте поле events в RouteSheetType.
     */
    routeSheet?: Maybe<IRouteSheetType>;
    /** Точка погрузки/доставки, на которой произошло событие */
    storagePoint?: Maybe<IStoragePointType>;
    /** Документы, прикрепленные водителем на событие */
    documents?: Maybe<Array<Maybe<IEventAttachedDoc>>>;
  }

  /** An enumeration. */
  export enum IRouteSheetStatus {
    /** Новый */
    New = 'NEW',
    /** Водитель подтвержден */
    DriverConfirmed = 'DRIVER_CONFIRMED',
    /** На маршруте */
    OnRoute = 'ON_ROUTE',
    /** Доставка завершена */
    DeliveryCompleted = 'DELIVERY_COMPLETED',
    /** Отменен */
    Cancelled = 'CANCELLED',
  }

  /** Информация о маршрутном листе */
  export interface IRouteSheetType {
    id: Scalars['ID'];
    /** Статус маршрутного листа */
    status: IRouteSheetStatus;
    /**
     * Информация о заявке, по которой составлен маршрутный лист
     * @deprecated Меняется направление графа - есть опасность циклического запроса Order-RouteSheet. Данное поле будет удалено.Для получения МЛ используйте поле routeSheet в OrderType.
     */
    order?: Maybe<IOrderType>;
    /** События маршрутного листа */
    events?: Maybe<Array<Maybe<IRouteSheetEventType>>>;
    /** Документы, прикрепленные к событиям маршрутного листа */
    documents?: Maybe<Array<Maybe<IEventAttachedDoc>>>;
    /** Актуальные проблемные события по заявке */
    actualProblemEvents?: Maybe<Array<Maybe<IRouteSheetEventType>>>;
    /** Есть ли связь у водителя */
    isDriverOnline?: Maybe<Scalars['Boolean']>;
  }

  export interface ISlaType {
    /** ID SLA */
    id?: Maybe<Scalars['Int']>;
    /** Название SLA */
    name?: Maybe<Scalars['String']>;
    /** Описание */
    description?: Maybe<Scalars['String']>;
    /** Пользовательский критерий в SLA */
    nonTrackableCriteria?: Maybe<Array<Maybe<INonTrackableSlaCriterionType>>>;
    /** Критерий в SLA */
    trackableCriteria?: Maybe<Array<Maybe<ITrackableSlaCriterionType>>>;
  }

  /** An enumeration. */
  export enum IStateStatusEnum {
    Active = 'ACTIVE',
    Liquidating = 'LIQUIDATING',
    Liquidated = 'LIQUIDATED',
    Bankrupt = 'BANKRUPT',
    Reorganizing = 'REORGANIZING',
  }

  /** Информация о месте погрузки/доставки */
  export interface IStoragePointDataType {
    /** Грузоотправитель, на которого зарегистрировано место погрузки/доставки */
    cargoOwner: ICarrierOrganizationType;
    /** Название места погрузки/доставки */
    pointName?: Maybe<Scalars['String']>;
    /** Населённый пункт */
    settlement: Scalars['String'];
    /** Название компании, к которой относится место погрузки/доставки */
    companyName: Scalars['String'];
    /** Адрес места погрузки/доставки */
    address: Scalars['String'];
    /** Время начала работы */
    workingHoursFrom: Scalars['Time'];
    /** Время окончания работы */
    workingHoursTo: Scalars['Time'];
    /** Возможные типы погрузки/доставки */
    loadingTypes: Array<ILoadingTypeType>;
    /** Изображение со схемой проезда к месту погрузки/доставки */
    drivingDirectionsImg?: Maybe<Scalars['String']>;
    /** Тип точки */
    typePoint: IStoragePointDataTypePoint;
    /** Широта места погрузки/разгрузки */
    latitude?: Maybe<Scalars['String']>;
    /** Долгота места погрузки/разгрузки */
    longitude?: Maybe<Scalars['String']>;
    /** Код в системе ФИАС, по которому можно получить подробную информацию об адресе */
    fiasCode?: Maybe<Scalars['String']>;
    /** Часовой пояс места погрузки/доставки */
    timezone?: Maybe<ITimeZoneType>;
    /** id места погрузки/доставки (StoragePoint) */
    id?: Maybe<Scalars['ID']>;
    /** Место архивное */
    isArchived?: Maybe<Scalars['Boolean']>;
    /** Название места погрузки/доставки */
    name?: Maybe<Scalars['String']>;
    /** Список контактов */
    contacts?: Maybe<Array<Maybe<IContactPersonAtStoragePointType>>>;
  }

  /** An enumeration. */
  export enum IStoragePointDataTypePoint {
    /** Погрузка */
    Loading = 'LOADING',
    /** Разгрузка */
    Unloading = 'UNLOADING',
  }

  /** Место погрузки/доставки */
  export interface IStoragePointType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    /** Грузоотправитель, на которого зарегистрировано место погрузки/доставки */
    cargoOwner: ICarrierOrganizationType;
    /** Название места погрузки/доставки */
    pointName?: Maybe<Scalars['String']>;
    /** Населённый пункт */
    settlement: Scalars['String'];
    /** Название компании, к которой относится место погрузки/доставки */
    companyName: Scalars['String'];
    /** Адрес места погрузки/доставки */
    address: Scalars['String'];
    /** Время начала работы */
    workingHoursFrom: Scalars['Time'];
    /** Время окончания работы */
    workingHoursTo: Scalars['Time'];
    /** Возможные типы погрузки/доставки */
    loadingTypes: Array<ILoadingTypeType>;
    /** Изображение со схемой проезда к месту погрузки/доставки */
    drivingDirectionsImg?: Maybe<Scalars['String']>;
    /** Тип точки */
    typePoint: IStoragePointTypePoint;
    /** Широта места погрузки/разгрузки */
    latitude?: Maybe<Scalars['String']>;
    /** Долгота места погрузки/разгрузки */
    longitude?: Maybe<Scalars['String']>;
    /** Код в системе ФИАС, по которому можно получить подробную информацию об адресе */
    fiasCode?: Maybe<Scalars['String']>;
    /** Часовой пояс места погрузки/доставки */
    timezone?: Maybe<ITimeZoneType>;
    /** Название места погрузки/доставки */
    name?: Maybe<Scalars['String']>;
    /** Список контактов */
    contacts?: Maybe<Array<Maybe<IContactPersonAtStoragePointType>>>;
  }

  /** An enumeration. */
  export enum IStoragePointTypePoint {
    /** Погрузка */
    Loading = 'LOADING',
    /** Разгрузка */
    Unloading = 'UNLOADING',
  }

  export interface ISubmitCarrierRiskProfile {
    /** Успешно отправлен на проверку - ДА или НЕТ */
    ok?: Maybe<Scalars['Boolean']>;
  }

  export interface ISubmitDriverRiskProfile {
    /** Успешно отправлен на проверку - ДА или НЕТ */
    ok?: Maybe<Scalars['Boolean']>;
  }

  export interface ISubmitVehicleAvailabilityRiskProfile {
    /** Успешно отправлен на проверку - ДА или НЕТ */
    ok?: Maybe<Scalars['Boolean']>;
  }

  export interface ISubmitVehicleRiskProfile {
    /** Успешно отправлен на проверку - ДА или НЕТ */
    ok?: Maybe<Scalars['Boolean']>;
  }

  /** Тип тендера */
  export interface ITenderReportType {
    id: Scalars['ID'];
    /** Номер лота */
    lotNumber?: Maybe<Scalars['String']>;
    /** Организация-заказчик */
    cargoOwnerOrganization?: Maybe<ICarrierOrganizationType>;
    /** Город погрузки */
    loadingSettlement?: Maybe<Scalars['String']>;
    /** Завод */
    department?: Maybe<Scalars['String']>;
    /** Направление */
    course?: Maybe<Scalars['String']>;
    /** Количество перевозок */
    ordersQuantity?: Maybe<Scalars['Int']>;
    /** Цена победителя */
    winnerPrice?: Maybe<Scalars['Int']>;
    /** Статус тендера для отображения */
    displayedStatus?: Maybe<Scalars['String']>;
    /** Количество назначенных заявок */
    ordersAssignedQuantity?: Maybe<Scalars['Int']>;
    /** Количество заявок в работе */
    ordersInWorkQuantity?: Maybe<Scalars['Int']>;
    /** Количество выполненных заявок */
    ordersCompletedQuantity?: Maybe<Scalars['Int']>;
    /** Количество отмененных заявок */
    ordersCanceledQuantity?: Maybe<Scalars['Int']>;
    /** Процент отказа по заявкам */
    ordersCanceledPercentage?: Maybe<Scalars['Int']>;
    /** Заявки по тендеру */
    orders?: Maybe<Array<Maybe<IOrderType>>>;
    /** Тендер ТН */
    isTn?: Maybe<Scalars['Boolean']>;
  }

  /** Тип тендера */
  export interface ITenderType {
    id: Scalars['ID'];
    /** Номер лота */
    lotNumber?: Maybe<Scalars['String']>;
    /** Организация-заказчик */
    cargoOwnerOrganization?: Maybe<ICarrierOrganizationType>;
    /** Дата и время окончания принятия ставок */
    bidsEndDatetime?: Maybe<Scalars['DateTime']>;
    /** Город погрузки */
    loadingSettlement?: Maybe<Scalars['String']>;
    /** Завод */
    department?: Maybe<Scalars['String']>;
    /** Направление */
    course?: Maybe<Scalars['String']>;
    /** Адрес доставки */
    unloadingPlace?: Maybe<Scalars['String']>;
    /** Начало периода действия */
    activeDateFrom?: Maybe<Scalars['Date']>;
    /** Конец периода действия */
    activeDateTo?: Maybe<Scalars['Date']>;
    /** Минимальный вес груза, кг */
    cargoWeightFrom?: Maybe<Scalars['Float']>;
    /** Максимальный вес груза, кг */
    cargoWeightTo?: Maybe<Scalars['Float']>;
    /** Количество перевозок */
    ordersQuantity?: Maybe<Scalars['Int']>;
    /** Максимальная цена за перевозку */
    maxOrderPrice?: Maybe<Scalars['Int']>;
    /** Шаг цены */
    stepPrice?: Maybe<Scalars['Int']>;
    /** Цена победителя */
    winnerPrice?: Maybe<Scalars['Int']>;
    /** Огранивать максимальную сумму ставки */
    limitRate: Scalars['Boolean'];
    /** Комментарий к тендеру */
    comment?: Maybe<Scalars['String']>;
    /** Цена включает НДС */
    isVatIncluded: Scalars['Boolean'];
    /** Статус тендера для отображения */
    displayedStatus?: Maybe<Scalars['String']>;
    /** Последняя сделанная ставка */
    lastBid?: Maybe<IBidOnTenderType>;
    /**
     * Цена последней сделанной ставки
     * @deprecated Следует использовать поле lastBid
     */
    lastBidPrice?: Maybe<Scalars['Float']>;
    /** Требования к ТС */
    vehicleRequirements?: Maybe<Array<Maybe<ITenderVehicleRequirementsType>>>;
    /** Тендер ТН */
    isTn?: Maybe<Scalars['Boolean']>;
  }

  /** Требования к ТС для тендера */
  export interface ITenderVehicleRequirementsType {
    /** Тип кузова ТС */
    bodyType?: Maybe<Scalars['String']>;
    /** Подтип кузова */
    bodySubtype?: Maybe<IBodySubtypeType>;
    /** Грузоподъемность ТС, т. */
    liftingCapacity?: Maybe<Scalars['Float']>;
    /** Объём кузова ТС в м3 */
    volume?: Maybe<Scalars['Float']>;
    /** Длина кузова ТС */
    length?: Maybe<Scalars['Float']>;
    /** Ширина кузова ТС */
    width?: Maybe<Scalars['Float']>;
    /** Высота кузова ТС */
    height?: Maybe<Scalars['Float']>;
    /** Необходимость наличия манипулятора у ТС */
    mustHaveManipulator: Scalars['Boolean'];
  }

  export interface ITimeZoneType {
    id: Scalars['ID'];
    /** Название города часового пояса */
    settlement: Scalars['String'];
    /** Часовой пояс(в формате UTC) */
    timezone: Scalars['String'];
    /** Название часового пояса */
    name: Scalars['String'];
  }

  export interface ITrackableSlaCriterionType {
    /** Пользовательское описание */
    description?: Maybe<Scalars['String']>;
    /** Тип системного критерия */
    systemCriterionType?: Maybe<Scalars['String']>;
    /** Описание системного критерия */
    systemCriterionDescription?: Maybe<Scalars['String']>;
    /** Значение порога отслеживания */
    thresholdValue?: Maybe<Scalars['String']>;
  }

  export interface ITrustedOrderPreferenceType {
    id: Scalars['ID'];
    /** Наличие успешной проверки грузоперевозчика */
    isScored: Scalars['Boolean'];
    /** Наличие ЭЦП (электронно-цифровой подписи) грузоперевозчика */
    hasDigitalSignature: Scalars['Boolean'];
    /** Наличие собственных ТС у грузоперевозчика */
    hasOwnVehicle: Scalars['Boolean'];
  }

  export interface IUploadFileInput {
    /** Файл */
    file: Scalars['Upload'];
    /** Название файла. Длина (max): 255 */
    name: Scalars['String'];
  }

  export interface IUploadedFileType {
    id: Scalars['ID'];
    /** Дата и время создания */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Название файла */
    name: Scalars['String'];
    /** Ссылка для скачивания файла */
    url?: Maybe<Scalars['String']>;
    /** Содержимое файла в base64 */
    content?: Maybe<Scalars['String']>;
  }

  /** Руководства пользователя */
  export interface IUserGuideType {
    /** Руководство по работе с ЭЦП */
    signGuide?: Maybe<IUploadedFileType>;
    /** Руководство для грузоперевозчика */
    carrierGuide?: Maybe<IUploadedFileType>;
    /** Руководство для грузовладельца */
    cargoOwnerGuide?: Maybe<IUploadedFileType>;
  }

  /** Настройки пользователя */
  export interface IUserSettingsType {
    /** Настройки уведомлений пользователя */
    notificationSettings?: Maybe<INotificationSettingsOverallType>;
    /** Включен новый дизайн */
    isNewDesign: Scalars['Boolean'];
  }

  export interface IVatType {
    id: Scalars['ID'];
    /** Название ставки НДС */
    name: Scalars['String'];
    /** Значение ставки НДС, % */
    rate: Scalars['Float'];
  }

  /** Информация о предложении ТС */
  export interface IVehicleAvailabilityInput {
    /** ID ТС */
    vehicleId: Scalars['ID'];
    /** Населённый пункт в котором ГП готов загрузиться */
    loadingPlaceDescription: Scalars['String'];
    /** Начало погрузки */
    loadingTimeFrom: Scalars['DateTime'];
    /** Окончание погрузки */
    loadingTimeTo: Scalars['DateTime'];
    /** ФИАС код */
    loadingPlaceFiasCode?: Maybe<Scalars['String']>;
    /** Часовой пояс места погрузки */
    loadingPlaceTimezone: Scalars['String'];
    /** Населенный пункт места погрузки */
    loadingPlaceSettlement?: Maybe<Scalars['String']>;
    /** Fias-код населенного пункта места погрузки */
    loadingPlaceSettlementFiasCode?: Maybe<Scalars['String']>;
    /** Населённый пункт в который ГП готов отправиться */
    unloadingPlaceDescription: Scalars['String'];
    /** ФИАС код */
    unloadingPlaceFiasCode?: Maybe<Scalars['String']>;
    /** Стоимость */
    price?: Maybe<IPriceInput>;
    /** Тип цены */
    priceType?: Maybe<IPriceTypeEnum>;
    /** Включен ли в цену НДС */
    isVatIncluded?: Maybe<Scalars['Boolean']>;
    /** Комментарий */
    comment?: Maybe<Scalars['String']>;
  }

  /** Фильтрация по принадлежности предложений ТС ГП */
  export enum IVehicleAvailabilityOwnershipEnum {
    /** Принадлежащие ГП предложения ТС */
    Own = 'OWN',
    /** Не принадлежащие ГП предложения ТС */
    NotOwn = 'NOT_OWN',
  }

  export enum IVehicleAvailabilityStatusEnum {
    Created = 'CREATED',
    Published = 'PUBLISHED',
    NotAccredited = 'NOT_ACCREDITED',
    Canceled = 'CANCELED',
    Completed = 'COMPLETED',
  }

  /** Информация о предложении ТС */
  export interface IVehicleAvailabilityType {
    /** ID предложения транспорта */
    id?: Maybe<Scalars['ID']>;
    /** Информация о ТС */
    vehicle?: Maybe<IGrapheneVehicleType>;
    /** Организационно-правовая форма */
    legalForm?: Maybe<Scalars['String']>;
    /** Стартовая точка погрузки */
    loadingPlaceDescription?: Maybe<Scalars['String']>;
    /** Дата и время начала возможной погрузки */
    loadingTimeFrom?: Maybe<Scalars['DateTime']>;
    /** Дата и время завершения возможной погрузки */
    loadingTimeTo?: Maybe<Scalars['DateTime']>;
    /** Населенный пункт места погрузки */
    loadingPlaceSettlement?: Maybe<Scalars['String']>;
    /** Fias-код населенного пункта места погрузки */
    loadingPlaceSettlementFiasCode?: Maybe<Scalars['String']>;
    /** Конечная точка разгрузки */
    unloadingPlaceDescription?: Maybe<Scalars['String']>;
    /** Код ФИАС места погрузки */
    loadingPlaceFiasCode?: Maybe<Scalars['String']>;
    /** Часовой пояс(в формате UTC) */
    loadingPlaceTimezone?: Maybe<Scalars['String']>;
    /** Код ФИАС места разгрузки */
    unloadingPlaceFiasCode?: Maybe<Scalars['String']>;
    /** Включен ли в цену НДС */
    isVatIncluded?: Maybe<Scalars['Boolean']>;
    /** Стоимость перевозки */
    price?: Maybe<IPriceType>;
    /** Тип назначенной цены */
    priceType?: Maybe<IPriceTypeEnum>;
    /** Комментарий к публикации */
    comment?: Maybe<Scalars['String']>;
    /** Дата и время публикации на платформе */
    publishDate?: Maybe<Scalars['DateTime']>;
    /** Дата и время завершения публикации ТС */
    deadline?: Maybe<Scalars['DateTime']>;
    /** Статус публикации */
    status?: Maybe<IVehicleAvailabilityStatusEnum>;
    /** Дата и время создания */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Дата и время аккредитации */
    accrAtDt?: Maybe<Scalars['DateTime']>;
    /** Аккредитован - ДА или НЕТ */
    accrValue?: Maybe<Scalars['Boolean']>;
    /** Дата и время окончания аккредитации */
    accrExpAt?: Maybe<Scalars['DateTime']>;
    /** Ссылка на объект risk profile */
    accrProfile?: Maybe<Scalars['String']>;
    /** Коментарий к аккредитации */
    accrComment?: Maybe<Scalars['String']>;
    /** Количество предложений заявок от ГВ */
    vehicleRequestsCount?: Maybe<Scalars['Int']>;
    /** Количество завершённых заявок ГП */
    completedOrderCount?: Maybe<Scalars['Int']>;
  }

  /** Информация о кузове транспортного средства */
  export interface IVehicleBodyType {
    id: Scalars['ID'];
    /** Транспртное средство */
    vehicle: IVehicleType;
    /** Объём кузова в м3 */
    volume?: Maybe<Scalars['Float']>;
    /** Грузоподъемность в тоннах */
    capacity?: Maybe<Scalars['Float']>;
    /** Информация о типе кузова ТС */
    bodyType?: Maybe<IBodyTypeType>;
    /** Информация о подтипе кузова ТС */
    bodySubtype?: Maybe<IBodySubtypeType>;
    /** Получение списка всех типов погрузки/разгрузки */
    loadingTypes?: Maybe<Array<Maybe<ILoadingTypeType>>>;
  }

  /** An enumeration. */
  export enum IVehicleDataOwnershipType {
    /** Лизинг */
    Leasing = 'LEASING',
    /** Аренда */
    Rent = 'RENT',
    /** В собственности */
    Own = 'OWN',
  }

  /** Информация о транспортном средстве */
  export interface IVehicleDataType {
    /** Типы ТС */
    vehicleType: IVehicleDataVehicleType;
    /** Марка ТС */
    vehicleMake: Scalars['String'];
    /** Номер свидетельства о регистрации ТС */
    vehicleCertificate?: Maybe<Scalars['String']>;
    /** Номер государственной регистрации ТС (латиница) */
    regNo: Scalars['String'];
    /** VIN */
    vehicleVin?: Maybe<Scalars['String']>;
    /** Страна (RU - Россия, любой другой - не Россия) */
    country: Scalars['String'];
    /** ТС находится в собственности у грузоперевозчика */
    ownedByCarrier: Scalars['Boolean'];
    /** Типы владения ТС */
    ownershipType: IVehicleDataOwnershipType;
    /** id транспортного средства */
    id?: Maybe<Scalars['ID']>;
    /** Номер государственной регистрации ТС, (Deprecated! Используйте reg_no) */
    vehicleId?: Maybe<Scalars['String']>;
    /** Грузоперевозчик */
    carrierOrganization?: Maybe<ICarrierOrganizationType>;
    /** Архивный? */
    isArchived?: Maybe<Scalars['Boolean']>;
    vehiclebody?: Maybe<IVehicleBodyType>;
    vehicletrailer?: Maybe<IVehicleTrailerType>;
    /** Статус проверки */
    verificationStatus?: Maybe<Scalars['String']>;
    /** ГП агента (ТЭК) */
    agentCarrier?: Maybe<IAgentCarrierType>;
    /** Информация о ЧС */
    blackListInfo?: Maybe<Array<Maybe<IBlackListVehicleType>>>;
  }

  /** Информация о транспортном средстве */
  export type IVehicleDataTypeBlackListInfoArgs = {
    orderId?: Maybe<Scalars['ID']>;
  };

  /** An enumeration. */
  export enum IVehicleDataVehicleType {
    /** Грузовик */
    Truck = 'TRUCK',
    /** Фура */
    Trailer = 'TRAILER',
    /** Автосцепка */
    Coupler = 'COUPLER',
  }

  /** Информация о транспортном средстве. В системе может быть 3 типа ТС: грузовик(TRUCK), фура(TRAILER) и автосцепка(COOPLER). В зависимости от выбранного типа ТС варьируется набор обязательных полей: для грузовика необходимо заполнить информацию о кузове (поля VehicleBody...), при этом все поля являются опционными. для фуры необходимо заполнить информацию о прицепе (поля VehicleTrailer...). Обязательно нужно указать тип прицепа(VehicleTrailerType) - полуприцеп(HALF), остальные поля являются опционными.для автосцепки обязательным является заполнение информации и кузове и о прицепе.Обязательно нужно указать тип прицепа(VehicleTrailerType) - прицеп(FULL). */
  export interface IVehicleOnMarketInput {
    /** Устаревший. Используйте vehicleRegNo */
    vehicleId?: Maybe<Scalars['String']>;
    /** Устаревший. Используйте vehicleTrailerRegNo */
    vehicleTrailerTrailerId?: Maybe<Scalars['String']>;
    /** Устаревший. Не используется */
    vehicleBodyType?: Maybe<Scalars['ID']>;
    /** Устаревший. Не используется */
    vehicleTrailerType?: Maybe<Scalars['ID']>;
    /** Код ISO страны ТС (по умолчанию RU). Длина (max): 2 */
    country?: Maybe<Scalars['String']>;
    /** Номер государственной регистрации ТС */
    vehicleRegNo?: Maybe<Scalars['String']>;
    /** Тип ТС */
    vehicleType: IVehicleVehicleType;
    /** Марка ТС. Длина (max): 100 */
    vehicleMake: Scalars['String'];
    /** Номер свидетельства о регистрации ТС. Длина (max): 20 */
    vehicleCertificate: Scalars['String'];
    /** VIN код. Длина (max): 17 */
    vehicleVin: Scalars['String'];
    /** Водители с правом управления данным ТС */
    vehicleDrivers?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Подтип кузова ТС */
    vehicleBodySubtype?: Maybe<Scalars['ID']>;
    /** Объём кузова ТС в м3 */
    vehicleBodyVolume?: Maybe<Scalars['Float']>;
    /** Грузоподъемность ТС в тоннах */
    vehicleBodyCapacity?: Maybe<Scalars['Float']>;
    vehicleBodyLoadingTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Номер гос. регистрации прицепа/полуприцепа */
    vehicleTrailerRegNo?: Maybe<Scalars['String']>;
    /** Подтип кузова прицепа/полуприцепа */
    vehicleTrailerSubtype?: Maybe<Scalars['ID']>;
    /** Объём кузова прицепа/полуприцепа в м3 */
    vehicleTrailerVolume?: Maybe<Scalars['Float']>;
    /** Грузоподъемность прицепа/полуприцепа в тоннах */
    vehicleTrailerCapacity?: Maybe<Scalars['Float']>;
    vehicleTrailerLoadingTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Марка прицепа/полуприцепа */
    vehicleTrailerMake?: Maybe<Scalars['String']>;
    /** ID грузоперевозчика от агента (ТЭК) */
    agentCarrierId?: Maybe<Scalars['ID']>;
    /** Тип владения ТС */
    ownershipType?: Maybe<IVehicleOwnershipType>;
    /** Устаревший. Будет удалено. ТС находится в собственности у грузоперевозчика */
    ownedByCarrier?: Maybe<Scalars['Boolean']>;
  }

  /** Информация о транспортном средстве. В системе может быть 3 типа ТС: грузовик(TRUCK), фура(TRAILER) и автосцепка(COOPLER). В зависимости от выбранного типа ТС варьируется набор обязательных полей: для грузовика необходимо заполнить информацию о кузове (поля VehicleBody...), при этом все поля являются опционными. для фуры необходимо заполнить информацию о прицепе (поля VehicleTrailer...). Обязательно нужно указать тип прицепа(VehicleTrailerType) - полуприцеп(HALF), остальные поля являются опционными.для автосцепки обязательным является заполнение информации и кузове и о прицепе.Обязательно нужно указать тип прицепа(VehicleTrailerType) - прицеп(FULL). */
  export interface IVehicleOnTmsInput {
    /** Устаревший. Используйте vehicleRegNo */
    vehicleId?: Maybe<Scalars['String']>;
    /** Устаревший. Используйте vehicleTrailerRegNo */
    vehicleTrailerTrailerId?: Maybe<Scalars['String']>;
    /** Устаревший. Не используется */
    vehicleBodyType?: Maybe<Scalars['ID']>;
    /** Устаревший. Не используется */
    vehicleTrailerType?: Maybe<Scalars['ID']>;
    /** Код ISO страны ТС (по умолчанию RU). Длина (max): 2 */
    country?: Maybe<Scalars['String']>;
    /** Поле станет обязательным. Номер государственной регистрации ТС */
    vehicleRegNo?: Maybe<Scalars['String']>;
    /** Тип ТС */
    vehicleType: IVehicleVehicleType;
    /** Марка ТС. Длина (max): 100 */
    vehicleMake: Scalars['String'];
    /** Номер свидетельства о регистрации ТС. Длина (max): 20 */
    vehicleCertificate?: Maybe<Scalars['String']>;
    /** VIN код. Длина (max): 17 */
    vehicleVin?: Maybe<Scalars['String']>;
    /** Водители с правом управления данным ТС */
    vehicleDrivers?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Подтип кузова ТС */
    vehicleBodySubtype?: Maybe<Scalars['ID']>;
    /** Объём кузова ТС в м3 */
    vehicleBodyVolume?: Maybe<Scalars['Float']>;
    /** Грузоподъемность ТС в тоннах */
    vehicleBodyCapacity?: Maybe<Scalars['Float']>;
    vehicleBodyLoadingTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Номер гос. регистрации прицепа/полуприцепа */
    vehicleTrailerRegNo?: Maybe<Scalars['String']>;
    /** Подтип кузова прицепа/полуприцепа */
    vehicleTrailerSubtype?: Maybe<Scalars['ID']>;
    /** Объём кузова прицепа/полуприцепа в м3 */
    vehicleTrailerVolume?: Maybe<Scalars['Float']>;
    /** Грузоподъемность прицепа/полуприцепа в тоннах */
    vehicleTrailerCapacity?: Maybe<Scalars['Float']>;
    vehicleTrailerLoadingTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Марка прицепа/полуприцепа */
    vehicleTrailerMake?: Maybe<Scalars['String']>;
    /** ID грузоперевозчика от агента (ТЭК) */
    agentCarrierId?: Maybe<Scalars['ID']>;
    /** Тип владения ТС */
    ownershipType?: Maybe<IVehicleOwnershipType>;
  }

  /** Фильтрация по принадлежности ТС Грузоперевозчику */
  export enum IVehicleOwnershipEnum {
    /** Все записи */
    All = 'ALL',
    /** Собственные ТС Грузоперевозчика */
    Own = 'OWN',
    /** Не собсвтенные ТС Грузоперевозчика */
    NotOwn = 'NOT_OWN',
  }

  /** An enumeration. */
  export enum IVehicleOwnershipType {
    /** Лизинг */
    Leasing = 'LEASING',
    /** Аренда */
    Rent = 'RENT',
    /** В собственности */
    Own = 'OWN',
  }

  /** An enumeration. */
  export enum IVehicleRequirementsDeliveryMethod {
    /** Отдельной машиной */
    Ftl = 'FTL',
    /** Возможен догруз */
    Ltl = 'LTL',
  }

  export interface IVehicleRequirementsType {
    /** Требования к ТС: Тип кузова транспортного средства */
    bodySubtype?: Maybe<IBodySubtypeType>;
    /** Требования к ТС: Грузоподъемность в тоннах */
    capacity?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Объём кузова в м3 */
    volume?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Длина кузова */
    length?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Ширина кузова */
    width?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Высота кузова */
    height?: Maybe<Scalars['Float']>;
    /** Способ, которым ТС доставляет груз(FTL/LTL) */
    deliveryMethod: IVehicleRequirementsDeliveryMethod;
    /** Температурный режим (градусы Цельсия) */
    temperatureModeFrom?: Maybe<Scalars['Int']>;
    /** Температурный режим (градусы Цельсия) */
    temperatureModeTo?: Maybe<Scalars['Int']>;
    /** Получение списка всех типов погрузки/разгрузки */
    loadingTypes?: Maybe<Array<Maybe<ILoadingTypeType>>>;
  }

  /** An enumeration. */
  export enum IVehicleTrailerDataTrailerType {
    /** Прицеп */
    Full = 'FULL',
    /** Полуприцеп */
    Half = 'HALF',
  }

  /** Информация о прицепе/полуприцепе транспортного средства */
  export interface IVehicleTrailerDataType {
    /** Объём кузова в м3 */
    volume?: Maybe<Scalars['Float']>;
    /** Грузоподъемность в тоннах */
    capacity?: Maybe<Scalars['Float']>;
    /** Типы прицепа ТС */
    trailerType: IVehicleTrailerDataTrailerType;
    /** Номер государственной регистрации прицепа/полуприцепа (латиница) */
    regNo: Scalars['String'];
    /** Марка прицепа ТС */
    vehicleMake?: Maybe<Scalars['String']>;
    /** id прицепа/полуприцепа */
    id?: Maybe<Scalars['ID']>;
    /** Информация о типе кузова ТС */
    bodyType?: Maybe<IBodyTypeType>;
    /** Информация о подтипе кузова ТС */
    bodySubtype?: Maybe<IBodySubtypeType>;
    /** Получение списка всех типов погрузки/разгрузки */
    loadingTypes?: Maybe<Array<Maybe<ILoadingTypeType>>>;
    /** Номер государственной регистрации прицепа/полуприцепа, (Deprecated! Используйте reg_no) */
    trailerId?: Maybe<Scalars['String']>;
    /** Информация о ЧС */
    blackListInfo?: Maybe<Array<Maybe<IBlackListVehicleTrailerType>>>;
  }

  /** Информация о прицепе/полуприцепе транспортного средства */
  export type IVehicleTrailerDataTypeBlackListInfoArgs = {
    orderId?: Maybe<Scalars['ID']>;
  };

  /** An enumeration. */
  export enum IVehicleTrailerTrailerType {
    /** Прицеп */
    Full = 'FULL',
    /** Полуприцеп */
    Half = 'HALF',
  }

  /** Прицеп/полуприцеп транспортного средства */
  export interface IVehicleTrailerType {
    id: Scalars['ID'];
    /** Объём кузова в м3 */
    volume?: Maybe<Scalars['Float']>;
    /** Грузоподъемность в тоннах */
    capacity?: Maybe<Scalars['Float']>;
    /** Типы прицепа ТС */
    trailerType: IVehicleTrailerTrailerType;
    /** Номер государственной регистрации прицепа/полуприцепа (латиница) */
    regNo: Scalars['String'];
    /** Марка прицепа ТС */
    vehicleMake?: Maybe<Scalars['String']>;
    /** Информация о типе кузова ТС */
    bodyType?: Maybe<IBodyTypeType>;
    /** Информация о подтипе кузова ТС */
    bodySubtype?: Maybe<IBodySubtypeType>;
    /** Получение списка всех типов погрузки/разгрузки */
    loadingTypes?: Maybe<Array<Maybe<ILoadingTypeType>>>;
    /** Номер государственной регистрации прицепа/полуприцепа, (Deprecated! Используйте reg_no) */
    trailerId?: Maybe<Scalars['String']>;
    /** Информация о ЧС */
    blackListInfo?: Maybe<Array<Maybe<IBlackListVehicleTrailerType>>>;
  }

  /** Прицеп/полуприцеп транспортного средства */
  export type IVehicleTrailerTypeBlackListInfoArgs = {
    orderId?: Maybe<Scalars['ID']>;
  };

  /** Информация о транспортном средстве */
  export interface IVehicleType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    /** Статус проверки */
    verificationStatus: IVehicleVerificationStatus;
    /** Типы ТС */
    vehicleType: IVehicleVehicleType;
    /** Марка ТС */
    vehicleMake: Scalars['String'];
    /** Номер свидетельства о регистрации ТС */
    vehicleCertificate?: Maybe<Scalars['String']>;
    /** Номер государственной регистрации ТС (латиница) */
    regNo: Scalars['String'];
    /** VIN */
    vehicleVin?: Maybe<Scalars['String']>;
    /** Страна (RU - Россия, любой другой - не Россия) */
    country: Scalars['String'];
    /** ТС находится в собственности у грузоперевозчика */
    ownedByCarrier: Scalars['Boolean'];
    /** Типы владения ТС */
    ownershipType: IVehicleOwnershipType;
    /** Грузоперевозчик */
    carrierOrganization: ICarrierOrganizationType;
    vehiclebody?: Maybe<IVehicleBodyType>;
    vehicletrailer?: Maybe<IVehicleTrailerType>;
    /** Номер государственной регистрации ТС, (Deprecated! Используйте reg_no) */
    vehicleId?: Maybe<Scalars['String']>;
    /** ГП агента (ТЭК) */
    agentCarrier?: Maybe<IAgentCarrierType>;
    /** Информация о ЧС */
    blackListInfo?: Maybe<Array<Maybe<IBlackListVehicleType>>>;
    /** Водители, прикрепленные к ТС */
    drivers?: Maybe<Array<Maybe<IDriverType>>>;
    /** Собственное ТС */
    ownVehicle?: Maybe<Scalars['Boolean']>;
    /** Статус задачи */
    accrStatus?: Maybe<IAccreditationStatus>;
    /** Коментарий к аккредитации */
    accrComment?: Maybe<Scalars['String']>;
  }

  /** Информация о транспортном средстве */
  export type IVehicleTypeBlackListInfoArgs = {
    orderId?: Maybe<Scalars['ID']>;
  };

  /** Enum типов ТС */
  export enum IVehicleTypeEnum {
    /** Все типы */
    All = 'ALL',
    /** Грузовик */
    Truck = 'TRUCK',
    /** Фура */
    Trailer = 'TRAILER',
    /** Автосцепка */
    Coupler = 'COUPLER',
  }

  /** An enumeration. */
  export enum IVehicleVehicleType {
    /** Грузовик */
    Truck = 'TRUCK',
    /** Фура */
    Trailer = 'TRAILER',
    /** Автосцепка */
    Coupler = 'COUPLER',
  }

  /** An enumeration. */
  export enum IVehicleVerificationStatus {
    /** Проверка не проводилась */
    NotChecked = 'NOT_CHECKED',
    /** Принято в работу */
    OnWork = 'ON_WORK',
    /** Одобрено */
    Approved = 'APPROVED',
    /** Отклонено */
    Rejected = 'REJECTED',
  }

  export interface IWaybillInfoType {
    /** Место погрузки/разгрузки из заявки */
    orderStoragePoint?: Maybe<IDraftOrderStoragePointType>;
    /** Время доставки с */
    dateFrom?: Maybe<Scalars['DateTime']>;
    /** Время доставки по */
    dateTo?: Maybe<Scalars['DateTime']>;
  }
}
