import { Actions } from '@ngrx/effects';
import { filter, switchMap } from 'rxjs/operators';

import { TnConfirmationService } from '../../../../confirmation/confirmation.service';
import { TnCarrierDirectoryApiService } from '../../carrier-directory-api.service';
export class TnTruckBaseEffects {
  public removeTruck(id: string) {
    return this.confirmation.openByPrefix('carrier.directory.vehicle.details.modals.archive').pipe(
      filter(result => Boolean(result)),
      switchMap(() => {
        return this.carrierDirectoryService.sendVehicleToArchive(Number(id));
      }),
    );
  }

  constructor(
    public readonly actions$: Actions,
    public readonly confirmation: TnConfirmationService,
    public readonly carrierDirectoryService: TnCarrierDirectoryApiService,
  ) {}
}
