import { Action, createReducer, on } from '@ngrx/store';

import { setApiBaseUrl, setEnv, setFlags } from './feature-access.actions';
import { featureAccessInitialState, IFeatureAccessState } from './feature-access.state';

const createFeatureAccessReducer = createReducer(
  featureAccessInitialState,
  on(setEnv, (state, { payload }) => ({ ...state, environment: { ...payload } })),
  on(setFlags, (state, { payload }) => ({ ...state, featureFlags: { ...payload } })),
  on(setApiBaseUrl, (state, { payload }) => ({ ...state, apiBaseUrl: payload.apiBaseUrl })),
);

export function featureAccessReducer(state: IFeatureAccessState, action: Action) {
  return createFeatureAccessReducer(state, action);
}
