import { IVehicleListResponse, IVehicleTable } from './vehicles-table.interface';

const vehicleTypes: { [key: string]: string } = {
  TRUCK: 'Одиночное ТС',
  COUPLER: 'Сцепка',
  TRAILER: 'Фура',
};

const ownershipTypes: { [key: string]: string } = {
  LEASING: 'Лизинг',
  RENT: 'Аренда',
  OWN: 'Собственное ТС',
  ATTRACTED: 'Привлеченное ТС',
  NOTOWN: 'Привлеченное ТС',
};

export const mapVehicles = (initialVehicles: IVehicleListResponse[]): IVehicleTable[] => {
  return initialVehicles.map(
    ({
      id,
      country,
      accrStatus,
      vehicleType,
      regNo,
      vehicleMake,
      vehiclebody,
      vehicletrailer,
      ownershipType,
      isArchived,
      accrComment,
      blackListInfo,
      agentCarrier,
    }) => ({
      id: id,
      country: country,
      workProhibited: blackListInfo?.length ? blackListInfo[0].workProhibited || false : false,
      accrStatus: accrStatus || '',
      vehicleType: vehicleTypes[vehicleType] || '',
      vehicleTypeSource: vehicleType,
      licencePlate: regNo || '',
      vehicleBrand: vehicleMake || '',
      trailerRegNo: vehicletrailer?.regNo || '',
      bodySubType: vehiclebody?.bodySubtype?.name || vehicletrailer?.bodySubtype?.name || '',
      capacity: vehicletrailer?.capacity || vehiclebody?.capacity || 0,
      volume: vehicletrailer?.volume || vehiclebody?.volume || 0,
      ownershipType: ownershipTypes[ownershipType] || '',
      btns: true,
      isArchived: isArchived || false,
      accrComment: accrComment || '',
      agentCarrier,
      blackListVehicle: {
        limitationType: !!blackListInfo?.find(item => item.workProhibited),
        message: blackListInfo,
      },
      blackListTrailer: {
        limitationType: !!vehicletrailer?.blackListInfo?.find(item => item.workProhibited),
        message: vehicletrailer?.blackListInfo,
      },
    }),
  );
};
