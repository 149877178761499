import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TnYamapsDispatcherService {
  /*  Yadex map api object */
  public maps: (Record<string, unknown> & { id: string })[] = [];

  /**
   * Register map
   * @param mapObj  Object with map.
   */
  public register(mapObj: Record<string, unknown> & { id: string }): void {
    this.maps.push(mapObj);
  }

  /**
   * Unregister map
   * @param mapId  Id of map.
   */
  public unregister(mapId: string): void {
    this.maps = this.maps.filter((map: { id: string }) => {
      return map.id !== mapId;
    });
  }
}
