import { OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { delay, map, scan } from 'rxjs/operators';

import { TnGlobalProgressBarComponent } from './global-progress-bar/global-progress-bar.component';

@Injectable({
  providedIn: 'root',
})
export class TnProgressService {
  /**
   * Spin subject.
   */
  private readonly spinSubject: Subject<boolean> = new Subject();

  /**
   * TnProgressService constructor.
   * @param spinnerTopRef spinner overlay reference
   */
  constructor(private readonly spinnerTopRef: OverlayRef) {
    void this.spinSubject
      .asObservable()
      .pipe(
        map(val => (val ? 1 : -1)),
        scan((acc, one) => (acc + one >= 0 ? acc + one : 0), 0),
        delay(0),
      )
      .subscribe(res => {
        this.spinnerTopRef.addPanelClass('global-spinner-overlay');
        if (res === 1 && !this.spinnerTopRef.hasAttached()) {
          const comp = new ComponentPortal(TnGlobalProgressBarComponent);
          this.spinnerTopRef.attach(comp);
        } else if (res === 0 && this.spinnerTopRef.hasAttached()) {
          this.spinnerTopRef.detach();
        }
      });
  }

  /**
   * Shows global spinner overlay.
   */
  public show() {
    this.spinSubject.next(true);
  }

  /**
   * Hides global spinner overlay.
   */
  public hide() {
    this.spinSubject.next(false);
  }
}
