export const tenderLocalization = {
  tabTitles: {
    tenders: 'Тендеры',
    reports: 'Отчеты',
  },
  tenders: {
    empty: 'Тендеров не найдено',
    table: {
      columns: {
        id: 'Ид.',
        loadingSettlement: 'Место погрузки',
        course: 'Направление',
        department: 'Завод',
        lotNumber: 'Номер лота',
        bidsEndDatetime: 'Прием ставок до',
        displayedStatus: 'Статус',
        cargoWeightFrom: 'Вес груза от, кг',
        cargoWeightTo: 'Вес груза до, кг',
        vehicleBodyType: 'Вид ТС',
        activeDateFrom: 'Период действия с',
        activeDateTo: 'Период действия по',
        ordersQuantity: 'Кол-во',
        lastBidPrice: 'Ставка, с НДС ₽',
        maxOrderPrice: 'Макс ставка с НДС ₽',
        winnerPrice: 'Лучшая ставка, с НДС ₽',
        bidsQuantity: 'Кол-во ставок',
        volume: 'Объем',
        bodyType: 'Кузов',
        comment: 'Комментарий',
      },
    },
    status: {
      new: 'Новый',
      closed: 'Завершен, победитель выбран',
      bidWon: 'Ставка выиграла',
      bidMade: 'Сделана ставка',
      waitingForResult: 'Выбор победителя',
      closedWithoutBids: 'Окончен без предложений',
      canceled: 'Отменен',
    },
    filter: {
      title: 'Фильтры',
      placeTitle: 'Место погрузки',
      departmentTitle: 'Завод',
      periodTitle: 'Период',
      orderCountTitle: 'Количество перевозок',
      form: {
        onlyWithMyBids: 'Только мои ставки',
        loadingSettlement: 'Место погрузки',
        period: 'Дата погрузки',
      },
    },
    vehicleBodyType: {
      tent: 'тент',
      refrigerator: 'рефрижератор',
      isotherm: 'изотерм',
      open: 'открытый',
      closed: 'закрытый',
    },
    info: {
      placeBetErrorDialog: {
        title: 'Ошибка',
        tenderBidTimeEnd: 'Прием ставок по лоту окончен',
        badTenderCompletion: 'Вы не можете сделать ставку, так как количество отказов в прошлом периоде по одному из лотов превысило 15%',
        tenderBlockedCarrier: 'Вы не можете сделать ставку, так как количество отказов в прошлом периоде по одному из лотов превысило 15%',
        lowOrganizationRating: 'Вы не можете сделать ставку, так как у вас низкий рейтинг',
        action: 'Понятно',
      },
    },
    requirements: {
      capacity: '{{ value }} т',
      volume: '{{ value }} м³',
    },
  },
  tenderReports: {
    empty: 'Сейчас отчетов нет. Они появятся, когда вы поучаствуете в тендере',
    table: {
      columns: {
        id: 'Ид',
        carrierOrganization: 'Перевозчик',
        winnerPrice: 'Цена',
        loadingSettlement: 'Место погрузки',
        course: 'Направление',
        department: 'Завод',
        lotNumber: 'Номер лота',
        ordersQuantity: 'Перевозок по лоту',
        ordersAssignedQuantity: 'Назначено',
        ordersCompletedQuantity: 'Выполнено',
        displayedStatus: 'Статус',
        ordersInWorkQuantity: 'В работе',
        ordersCanceledQuantity: 'Не выполнено',
        ordersCanceledPercentage: 'Процент отказов',
      },
    },
    status: {
      inWork: 'в работе',
      completed: 'завершен',
      canceled: 'отменен',
    },
    filter: {
      title: 'Фильтры',
      form: {
        loadingSettlement: 'Место погрузки',
        period: 'Период',
      },
    },
  },
  tenderReport: {
    empty: 'Сейчас данных о заявках нет. Они появятся, после окончания тендера',
    pageTitle: 'Заявки на транспорт ({{count}})',
    table: {
      columns: {
        id: 'Ид. заявки',
        createdDatetime: 'Дата заявки',
        firstName: 'Перевозчик',
        number: 'Номер заявки на транспорт',
        deliveryRequestDateUtc: 'Дата заявки на транспорт',
        status: 'Статус',
      },
    },
  },
  tenderCreate: {
    title: 'Новый тендер',
    formFieldsTitle: {
      route: 'Маршрут',
      settings: 'Настройка тендера',
      vehicleRequirements: 'Требования к ТС',
      publicationType: 'Способ публикации',
      comments: 'Комментарий',
    },
    settings: {
      headers: {
        conditions: 'Условия перевозок',
        startingCost: 'Стартовая стоимость перевозки',
        end: 'Окончание тендера',
      },
      forms: {
        ordersQuantity: 'Количество перевозок',
        activeDateFrom: 'Начало',
        activeDateTo: 'Окончание',
        bidsEndDate: 'Дата',
        maxOrderPrice: 'Укажите стартовую цену',
        stepPrice: 'Размер шага',
        nds: 'НДС',
        errors: {
          required: {
            ordersQuantity: 'Укажите количество перевозок',
            activeDateFrom: 'Выберите начало перевозок',
            activeDateTo: 'Выберите окончание перевозок',
            maxOrderPrice: 'Укажите стартовую цену',
            stepPrice: 'Выберите размер шага',
            bidsEndDate: 'Выберите дату окончания',
            bidsEndTime: 'Выберите время окончания',
          },
          lessThanNow: 'Дата не может быть в прошлом',
          lessThanNowTime: 'Дата и время не могут быть в прошлом',
          dateToLessThanDateFrom: 'Дата окончания не может быть раньше даты начала',
        },
        vats: {
          withVat: 'с НДС',
          withoutVat: 'без НДС',
        },
      },
    },
    routes: {
      from: 'Откуда',
      to: 'Куда',
      placeholder: 'Введите название населенного пункта',
    },
    publication: {
      carrierGroup: 'Группа перевозчиков',
      carrierGroupWithCount: 'Группа перевозчиков {{ value }}',
      all: 'Все',
      errors: {
        required: 'Выберите группу перевозчиков',
      },
    },
    comment: 'Комментарий',
    filling: 'Заполнение',
    actionCreate: 'Создать',
    messages: {
      error: 'Произошла ошибка при создании тендера',
    },
  },
  tenderCard: {
    createOrder: 'Создать заявку',
    title: 'Тендер',
    subtitle: '№ {{ id }} ({{ number }}) от ',
    statusBarStages: {
      '1': {
        labelA: 'Идет тендер',
        labelB: '',
      },
      '2': {
        labelA: 'Подведение',
        labelB: 'итогов',
      },
      '3': {
        labelA: 'Завершен',
        labelB: '',
      },
    },
    routes: {
      title: 'Маршрут',
      from: 'Место погрузки',
      to: 'Место доставки',
      activeDateFromToValue: 'с {{ fromDate }} по {{ toDate }}',
      activeDateFromTo: 'Период действия',
      course: 'Направление',
      department: 'Завод',
    },
    management: {
      timeUntilEndAction: 'Время до окончания торгов',
      timeUntilEndTender: 'До конца тендера',
      maxOrderPrice: 'Стартовая цена,',
      myBet: 'Моя текущая ставка за 1 перевозку',
      ordersQuantity: 'Количество перевозок, шт',
      activeDateFrom: 'Начало перевозок',
      activeDateTo: 'Окончание перевозок',
      stepSize: 'Размер шага,',
      win: 'Вы победили',
      lost: 'Вы проиграли',
      priceWithVat: '{{value}} ₽ с НДС',
      price: 'Назначить цену за 1 перевозку',
      vatIncluded: 'с НДС',
      withoutVat: 'без НДС',
      error: {
        max: 'Ставка не должна превышать {{ value }}',
        min: 'Ставка не может быть равна 0',
        reachedMinPrice: 'Достигнута минимальная цена',
        default: 'Произошла ошибка',
      },
    },
    chooseWinner: 'Выберите победителя тендера',
    winnerNotFound: 'Победитель не найден',
    vehicleRequirements: {
      cardName: 'Описание',
      requirements: 'Требование к ТС',
      cargoWeight: 'Параметры груза',
      weight: {
        label: 'Вес, т',
        value: 'от {{from}} до {{to}}',
      },
      capacity: {
        label: 'Грузоподъемность',
        value: '{{capacity}} т',
      },
      volume: {
        label: 'Объем',
        value: '{{volume}} м³',
      },
      bodyType: {
        label: 'Тип кузова',
      },
      bodySubtype: {
        label: 'Подтип кузова',
      },
      bodyDimensions: {
        label: 'Внутренний размер кузова (ДхВхШ), м',
        value: '{{length}}x{{height}}x{{width}}',
      },
      vehicleMustHaveManipulator: 'манипулятор - требуется',
    },
    bids: {
      title: 'Ответы',
      select: 'Выбрать',
    },
    cancelConfirmation: {
      title: 'Отменить тендер',
      content: 'Вы уверены, что хотите отменить тендер?',
      confirm: 'Да',
      cancel: 'Нет',
    },
    errors: {
      cancel: 'Произошла ошибка при отмене тенедера',
      setWinner: 'Произошла ошибка при выборе победителя',
    },
    winner: 'Победитель',
  },
};
