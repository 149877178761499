<div class="container p-4 bg-white flex flex-col basis-auto" [ngClass]="{ 'hide-border': !isBorder }">
  <div class="description flex" *ngIf="hasDescription">
    <div class="desc-icon flex justify-center items-center" *ngIf="hasIcon" [ngClass]="iconStyles">
      <mat-icon *ngIf="svgIconName" [svgIcon]="svgIconName"></mat-icon>
      <mat-icon *ngIf="matIconName" class="material-icons">{{ matIconName }}</mat-icon>
    </div>
    <div class="title" [ngClass]="{ active: isActiveTitle }" (click)="clickTitle.emit()">
      <div>{{ title }}</div>
      <div class="subtitle" *ngIf="subtitle">{{ subtitle }}</div>
    </div>
  </div>
  <div class="flex flex-col basis-auto" [ngClass]="{ 'content__margin': hasIcon }">
    <ng-content></ng-content>
  </div>
</div>
