import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { VerificationFlowService } from '@marketplace-app/verification/verification-flow.service';
import { VerificationStatuses } from '@transport/ui-kit';

@Component({
  selector: 'marketplace-accreditation-status-block',
  templateUrl: './accreditation-status-block.component.html',
  styleUrls: ['./accreditation-status-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AccreditationStatusBlockComponent {
  @Input() status: VerificationStatuses | undefined = undefined;;

  constructor(private verificationFlowService: VerificationFlowService) { }

  public get isNotStarted() {
    return this.status === VerificationStatuses.notStarted;
  }

  public get isDenied() {
    return this.status === VerificationStatuses.denied;
  }

  public startVerificationFlow() {
    this.verificationFlowService.openModal();
  }
}
