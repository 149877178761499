import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { transport } from '@transport/proto';
import { IPager } from '@transport/ui-interfaces';
import { BehaviorSubject, Subject, catchError, map, of, switchMap, take, tap } from 'rxjs';
import { Location } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { NotificationsApiService} from '../../notifications-api.service';
import { WebsocketService } from 'libs/websocket/src';
import { PopupNotificaions } from '../notifications-popup/notifications-popup.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class NotificaionsService {
  public filters$ = new BehaviorSubject<any>({
    first: 0,
    offset: 0,
  });

  public showWarningPopup$ = new Subject<boolean>();

  public notifications$ = new BehaviorSubject<transport.INotification[]>([]);

  public isLoading$ = new BehaviorSubject<boolean>(false);

  public count$ = new BehaviorSubject<number>(0);

  public readonly notifier$ = new Subject();

  constructor(
    private router: Router,
    private readonly location: Location,
    private onPopupNotificaions: PopupNotificaions,
    private socket: WebsocketService,
    private notificationsService: NotificationsApiService
  ) {

    this.socket
      .on('notification', ['created', 'marked_as_read'])
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        if (this.location.isCurrentPathEqualTo('/notifications')) {
          this.loadNotifications(false);
        }
      });

    this.socket
      .on('order', ['documents_created', 'violation_of_criteria', 'order_loading_place_time_changed', 'order_data_changed', 'order_price_changed'])
      .pipe(untilDestroyed(this))
      .subscribe(() => this.showWarningPopup$.next(true));

    this.socket
      .on('import', ['import_finished', 'import_error'])
      .pipe(untilDestroyed(this))
      .subscribe(() => this.showWarningPopup$.next(true));
  }

  public goByPath(path: string[], query: Record<string, unknown>) {
    this.router.navigate(path, { queryParams: query });
  }

  public markAsRead(notification: transport.INotification) {
    this.isLoading$.next(true);
    return this.notificationsService.markNotificationAsRead(notification.id ?? '').pipe(
      take(1),
      switchMap(() => this.loadNotifications()),
      tap(() => this.isLoading$.next(false)),
      catchError(error => {
        console.error(error);
        this.isLoading$.next(false);

        return of(error);
      }),
    )
  }

  public markAllAsRead() {
    this.isLoading$.next(true);
    return this.notificationsService.markAllNotificationsAsRead().pipe(
      take(1),
      switchMap(() => this.loadNotifications()),
      tap(() => this.isLoading$.next(false)),
      catchError(error =>{
        console.error(error);
        this.isLoading$.next(false);

        return of(error);
      }),
    );
  }

  public setPage(pager: IPager) {
    this.filters$.next({
      first: pager.pageSize,
      offset: pager.pageIndex * pager.pageSize,
    });

    this.loadNotifications().subscribe();
  }

  public loadNotifications(withSpinner = true) {
    this.isLoading$.next(true);
    this.onPopupNotificaions.isLoading$.next(true);

    return this.notificationsService
      .getNotificationsWithCounts(withSpinner, void 0, this.filters$.value?.first, this.filters$.value?.offset)
      .pipe(
        take(1),
        map(result => {
          this.notifications$.next(result.notifications);
          this.count$.next(result.count ?? 0);
          this.onPopupNotificaions.unreadCount$.next(result.unreadCount ?? 0);
          this.isLoading$.next(false);
          this.onPopupNotificaions.isLoading$.next(false);
        }),
        catchError(error => {
          console.error(error);
          this.isLoading$.next(false);
          this.onPopupNotificaions.isLoading$.next(false);
          return of(null);
        }),
      );
  }

  public leavePage() {
    this.location.back();
  }
}
