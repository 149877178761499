import { marketplaceLocalization } from 'libs/transport-ui-translate/src/lib/translations/marketplace/ru';
import { monitoringLocalization } from '../master-tms/ru-monitoring';
import { sidebarLocalization } from '../master-tms/ru-sidebar';
import { addressInputLocalization } from './ru-address-input';
import { RU_API_BOUND_NAMING } from './ru-api-bound-naming';
import { authLocalization } from './ru-auth';
import { blackListInfoLocalization } from './ru-blacklist-info';
import { customControlsLocalization } from './ru-custom-controls';
import { driverLocalization } from './ru-driver';
import { helpLocalization } from './ru-help';
import { menuLocalization } from './ru-menu';
import { orderLocalization } from './ru-order';
import { passportLocalization } from './ru-passport';
import { userProfileLocalization } from './ru-profile'; // TODO: https://technonikol.atlassian.net/browse/T20S-5059
import { registrationStepperLocalization } from './ru-registration-stepper';
import { staffLocalization } from './ru-staff';
import { supervisorLocalization } from './ru-supervisor';
import { tenderLocalization } from './ru-tender';
import { toolbarLocalization } from './ru-toolbar';

export const RU = {
  shared: {
    trial: {
      ends: 'Пробный период истек',
      expAt: 'Пробный период до',
    },
    currencies: {
      RUB: 'р.',
    },
    date: {
      placeholder: 'дд.мм.гггг',
    },
    time: {
      placeholder: 'чч:мм',
    },
    pipe: {
      cargoParams: {
        weight: '{{value}}т',
        volume: ' / {{value}}м³',
      },
    },
    imageInput: 'Нажмите или перетащите',
    personalization: {
      supportedFiles: 'Допустимы файлы PNG, JPEG, SVG, ICO.',
    },
    facsimileImg: {
      supportedFiles: 'Допустимы файлы PNG, JPEG',
    },
    documentFlow: {
      checkBoxLabel: 'Грузовладелец подписывает заявку/договор первым',
      carrierContractSigning: 'Контрактованный грузоперевозчик подписывает заявку',
      carrierContractRequired: 'Обязательно указание договора при оформлении заявки',
    },
    subdomain: {
      label: 'Субдомен',
      title: 'Выберите контрагента',
      toasterMessage: 'У Вас пока нет доступных контрагентов',
    },
    reg: {
      title: 'Войти или зарегистрироваться',
      titleRegistration: 'Регистрация',
      fio: 'Имя',
      passwordLabel: 'Пароль (от 8 символов)',
      confirmEmail: 'Подтвердите почту',
      alreadyReg: 'Уже зарегистрированы? ',
      enter: 'Войти',
      noticeFirst: 'Нажимая на кнопку «Зарегистрироваться», вы соглашаетесь с условиями ',
      licenseAgreement: 'Пользовательского соглашения',
      noticeMiddle: ' и даете своё согласие на обработку  персональных данных на условиях, определенных ',
      userAgreement: 'Политикой конфиденциальности.',
      sendEmailSuccess:
        'На почту {{value}} отправлено письмо со ссылкой для подтверждения регистрации. Если вы не можете найти письмо, проверьте, пожалуйста, папку Спам.',
      organization: {
        label: 'Организация',
        placeholder: 'Поиск по наименованию или идентификатору организации',
      },
    },
    customControls: customControlsLocalization,
    toolbar: toolbarLocalization,
    menu: menuLocalization,
    addressInput: addressInputLocalization,
    orderPage: orderLocalization,
    withVat: 'С НДС',
    withoutVat: 'БЕЗ НДС',
    counterOfferComment: 'Комментарий к встречному',
    stepBetComment: 'Комментарий к ставке',
    raitingMessage: 'Ранее заявка была вам назначена. Её невыполнение может понизить ваш рейтинг',
    measurements: {
      ton: {
        short: 'т',
      },
      cubedMeters: {
        short: 'м³',
      },
      kilograms: {
        short: 'кг',
      },
      currency: {
        ru: '₽',
      },
    },
    placeBet: {
      auction: {
        label: 'Сделать ставку',
        step: 'Шаг торгов',
      },
      tender: {
        label: 'Сделать ставку за 1 перевозку',
        labelForTn: 'Сделать ставку',
        step: 'Шаг тендера',
      },
      confirmation: {
        title: 'Сделать ставку',
        content: 'Вы уверены, что хотите cделать ставку?',
        confirm: 'Да',
        cancel: 'Нет',
      },
    },
    card: { fillIn: 'Заполните' },
    accordion: {
      showMore: 'Показать еще {{value}}...',
      hide: 'Скрыть',
    },
    fileSelector: {
      chooseFile: 'Выбрать файл',
      choose: 'Выбрать',
      fileNotSelected: 'файл не выбран',
      file: 'пустой файл',
      loading: 'Загрузка...',
      errors: {
        maxSize: 'Файл слишком большой',
        format: 'Неверный формат файла',
      },
    },
    accreditationAutocomplete: {
      needForCheck: 'Требуется проверка',
      sendOnCheck: 'Отправить на проверку',
      checking: 'Идет проверка',
      denied: 'Отказано',
    },
    support: {
      title: 'Тех. поддержка',
      copyright: '© 2022 Все права защищены',
      version: 'Версия',
    },
    notifications: {
      markAllAsRead: 'Отметить все как прочитанные',
      goToEntity: 'Перейти',
      viewAll: 'посмотреть все',
      page: {
        title: 'Ваши уведомления',
        leavePage: 'Назад',
        noNotifications: 'Уведомлений нет',
      },
    },
    balance: {
      title: 'Баланс',
    },
    catalogue: {
      title: 'Каталог',
    },
    cargoOffer: {
      sendSuccess: 'Предложение успешно отправлено',
      offer: 'Предложить заказ',
      sended: 'Отправлено',
      noVariants: 'Нет вариантов',
      create: 'Создать',
      refresh: 'Обновить',
      findVehicle: 'Найдено ТС',
      suggest: 'Отправить',
      organizationTooltip: 'Данные грузоперевозчика будут доступны в случае взятия заявки в работу',
    },
    actions: {
      backToTheList: 'Назад к списку',
      back: 'Назад',
      remove: 'Удалить',
      archive: 'Архивировать',
      copy: 'Копировать',
      save: 'Сохранить',
      create: 'Создать',
      createCargoType: 'Создать тип грузов ',
      change: 'Изменить',
      edit: 'Редактировать',
      cancel: 'Отменить',
      accept: 'Подтвердить',
      details: 'Детали',
      select: 'Выбрать',
      close: 'Закрыть',
      apply: 'Применить',
      publish: 'Опубликовать',
      clearAll: 'Очистить фильтры',
      invite: 'Пригласить',
      add: 'Добавить',
      download: 'Скачать',
      accreditation: 'Аккредитовать',
      approve: 'Одобрить',
      reject: 'Отказать',
      setAccreditation: 'На аккредитацию',
      addTransport: 'Добавить ТС',
      startTyping: 'Начните ввод',
      actions: 'Действия',
      contract: 'Договор',
      carrier: 'Перевозчик',
      upload: 'Загрузить новый',
      addGroup: 'Добавить в группу',
      addBlock: 'В черный список',
      openInModal: 'Открыть в окне',
    },
    status: {
      title: 'Статус',
      archive: 'Архивная',
      active: 'Активная',
      inProgress: 'В исполнении',
      problem: 'Проблема на маршруте',
      delay: 'Задержка',
      noConnection: 'Связь потеряна',
      driverConnection: 'Водитель на связи',
      plannedConnection: 'Планируется отслеживание',
      notes: 'Замечания',
      orderTag: 'Признак заявки',
      free: 'Не назначена',
      assigned: 'Назначена',
      activeAuction: 'Активный',
    },
    biddingStatusForCarrier: {
      placeBetAndWon: 'Вы победили',
      placeBetAndLost: 'Вы проиграли',
      placeBetAndCancel: 'Торги отменены',
      placeBetAndWait: 'Ожидание итогов',
      notPlaceBet: 'Новые',
    },
    gender: {
      title: 'Пол',
      male: 'Мужской',
      female: 'Женский',
    },
    range: {
      from: 'с',
      to: 'по',
    },
    rangeNumber: {
      from: 'от',
      to: 'до',
      errors: {
        fromMax: 'от должно быть меньше {{value}}',
        toMin: 'до должно быть больше {{value}}',
      },
    },
    placeholder: {
      notSelected: 'Не выбран',
      selectCarrier: 'Выберите перевозчика',
      selectCarrierGroup: 'Выберите группу перевозчиков',
    },
    errors: {
      cancel: 'Отмена',
      emailDublicate: 'Пользователь с таким email уже зарегистрирован',
      emailNotRegistered: 'Пользователь с таким email не зарегистрирован',
      unknown: 'Произошла неизвестная ошибка. Проверьте наличие соединения с сетью',
      permissionOperation: 'У вас недостаточно прав на выполнение операции',
      login: 'Проверьте правильность ввода логина/пароля',
      permission: 'У вас недостаточно прав для доступа к странице',
      required: 'Поле обязательно к заполнению',
      email: 'Неверный формат email',
      subdomain: 'Неверный формат субдомена',
      password: 'Введите пароль',
      nologin: 'Введите логин',
      falseCredentionals: 'Неверный логин или пароль',
      phone: 'Неверный формат. Пример верного значения +7(999)123-45-67',
      phoneBY: 'Неверный формат. Пример верного значения +375(99)123-45-67',
      coordinates: 'Неверный формат координат',
      innPhys: 'Допустимый формат 12 цифр',
      innIncorrect: 'Указан некорректный ИНН',
      iinIncorrect: 'Допустимый формат 12 цифр',
      binIncorrect: 'Допустимый формат 12 цифр',
      onlyNumbers: 'Допустимо вводить только цифры',
      innLegal: 'Допустимый формат 10 цифр',
      ogrnIp: 'Допустимый формат 15 цифр',
      ogrnLegal: 'Допустимый формат 13 цифр',
      kppLegal: 'Допустимый формат 9 цифр',
      accIncorrect: 'Допустимый формат 20 цифр',
      bikIncorrect: 'Допустимый формат 9 цифр',
      minZero: 'Значение не может быть меньше 0',
      maxNumber: 'Максимальное значение {{value}}',
      minNumber: 'Минимальное значение {{value}}',
      carNumber: 'Неверный формат государственного номера',
      maxFileSize: 'Размер файла превышает {{value}} Мб',
      maxCommentLength: 'Максимальная длина комментария {{value}} символов',
      maxLength: 'Максимальная длина значения равна {{value}} символам',
      minLength: 'Минимальная длина значения равна {{value}} символам',
      dateStartBounds: 'Дата начала не должна быть больше даты окончания',
      dateEndBounds: 'Дата окончания не должна быть меньше даты начала',
      timeStartBounds: 'Время начала должно быть меньше времени завершения',
      timeEndBounds: 'Время завершения должно быть больше времени начала',
      trimmedString: 'Значение не может состоять только из пробельных символов',
      startEndSpaces: 'Уберите пробелы в начале и в конце строки',
      moreThanNow: 'Нельзя ввести будущую дату',
      lessThanNow: 'Нельзя ввести прошлую дату',
      wrongLifetime: 'Укажите период меньше длительности аукциона',
      checkLoadingUnloadingDates: 'Проверьте даты погрузки/разгрузки',
      summarizingDateError: 'Не может быть меньше даты завершения тендера',
      timeFromMoreTimeTo: 'Нельзя ввести время С больше времени ПО',
      fileUpload: 'Ошибка при загрузке файла',
      timeoutError: 'Превышено время ожидания ответа от сервера',
      formInvalid: 'Проверьте корректность заполнения полей формы',
      somethingWrongCallSupport: 'Что-то пошло не так. Обратитесь в службу поддержки.',
      yamapCommonError: 'Произошла ошибка при загрузке координат',
      authError: 'Ваша сессия устарела. Повторите пожалуйста вход в систему',
      lessThanAdultAge: 'Добавляем людей от 18 лет',
      registryLegalForm: 'Данная ОПФ не зарегистрирована в системе. Для реализации обратитесь в тех. поддержку',
      signer: 'Используются недопустимые символы',
      needConfirmEmail: 'Email не подтвержден. Пожалуйста, подтвердите почту, перейдя по ссылке из почты',
      needInviteLink: 'Регистрация не завершена, пожалуйста, пройдите по ссылке приглашения',
      domainIsNotExist: 'Домен такой компании не существует. Пожалуйста, перейди на главный сайт',
      domainIsExist: 'Такой домен существует',
      extension: 'Допустимы файлы {{value}}',
      incorrectInn: 'Организация с таким ИНН уже зарегистрирована. Обратитесь, пожалуйста, в службу клиентского сервиса +74999590911',
      incorrectInnTonna: 'Организация с таким ИНН уже зарегистрирована. Обратитесь, пожалуйста, в службу клиентского сервиса +74991103911',
      innNotSelected: 'Пожалуйста, выберите организацию из списка',
      dadataHintError: 'Возникла непредвиденная ошибка. Обратитесь в службу клиентского сервиса.',
      organizationsNotFound: 'Зарегистрированных организаций не найдено',
      maybeSelfEmployee: 'Зарегистрированных организаций не найдено. Если Вы самозанятый, то укажите ИНН и продолжайте регистрацию.',
      innInvalid: 'Такой ИНН не существует',
      valueIsNotAnOption: 'Выберите значение из списка',
      cargoTypeError: 'Поле обязательно к заполнению',
      maxUserNameLength: 'Максимальное количество символов 150',
      dateToLessThanDateFrom: 'Дата завершения не может быть раньше даты начала',
      dateToLessThanDateTo: 'Дата начала не может быть позже даты завершения',
      orderPlaceNoTimezone: 'Таймзона не определена',
      orderRemains: 'Не указан пункт разгрузки',
      orderRemainsVolume: 'Не указан пункт разгрузки для {{value}} м³',
      orderRemainsWeight: 'Не указан пункт разгрузки для {{value}} т',
      orderRemainsQuantity: 'Не указан пункт разгрузки для {{value}} шт',
      orderOverUnloadedVolume: 'Превышен лимит на разгрузке для {{value}} м³',
      orderOverUnloadedWeight: 'Превышен лимит на разгрузке для {{value}} т',
      orderOverUnloadedQuantity: 'Превышен лимит на разгрузке для {{value}} шт',
      orderCargoOverallVolume: 'Суммарный объем грузов не может превышать {{value}} м³',
      orderCargoOverallWeight: 'Суммарный вес грузов не может превышать {{value}} т',
    },
    alerts: {
      changesSuccessfullySaved: 'Изменения сохранены',
    },
    filter: {
      title: 'Фильтр',
      all: 'Все',
      active: 'Активные',
      archived: 'Архив',
    },
    publicOrdersList: {
      title: 'Заявки Master-TMS',
      index: {
        title: 'Свободные заявки',
      },
    },
    selectedAutocomplete: 'Выбрано',
    allocationType: {
      SIMPLE_ALLOCATION: 'Срочный',
      AUCTION_ALLOCATION: 'Аукцион',
    },
    orders: {
      list: {
        cancelReason: {
          CARRIER_REFUSED_ORDER: 'Перевозчик отказался от заявки',
          CARGOOWNER_CANCELED_ORDER: 'Поиск перевозчика был отменен',
          TIME_TO_PROCESS_ORDER_EXPIRED: 'Перевозчик не оформил заявку за выделенное время',
          CARRIER_NOT_FOUND: 'Перевозчик не найден',
          CARRIER_NOT_SHOWING_FOR_LOADING: 'Машина не прибыла на погрузку',
        },
        header: {
          order: 'Заявка',
          route: 'Маршрут',
          cargo: 'Груз',
          carrier: 'Перевозчик',
        },
        ordersNotFound: 'Заявки не найдены',
        deleteOrder: {
          title: 'Отмена заявки',
          content: 'Вы действительно хотите отменить заявку №{{ value }}?',
          confirm: 'Да',
          cancel: 'Нет',
          success: 'Заявка №{{value}} успешно отменена',
          error: 'Ошибка отмены заявки',
        },
        orderCardPopup: {
          biddingDetails: 'Открыть детали торгов',
          orderDetails: 'Детали заявки',
          copyOrder: 'Создать копию заявки',
          deleteOrder: 'Удалить заявку',
          archiveOrder: 'Архивировать заявку',
          cancelOrder: 'Отменить заявку',
          toOrderTracking: 'Перейти к отслеживанию',
          changePrice: 'Изменить цену',
          changeStartPrice: 'Изменить стартовую цену',
        },
        status: {
          DRAFT: 'Черновик',
          PENDING: 'Ожидает обработки',
          FREE: 'Опубликована',
          ASSIGNED: 'Назначение ТС и водителя',
          TRANSPORT_RESERVED: 'Подписание документов',
          CONTRACT_ATTACHED: 'Ожидает выполнения',
          READY_TO_GO: 'Выполняется',
          COMPLETED: 'Завершена',
          CANCELED: 'Отменена',
          DELAYED: 'Ожидает публикации',
          CANCELED_BY_OWNER: 'Отменена заказчиком',
          CANCELED_BY_CARRIER: 'Отказ перевозчика',
        },
        publication: {
          everybody: 'Все партнеры',
          group: 'Группа ГП',
          everybodyAndMarket: 'Партнеры и рынок',
          groupAndMarket: 'Группа ГП и рынок',
          onlyMarket: 'Только рынок',
          assigned: 'Назначена',
          nowhere: 'Не опубликована',
        },
        publicationTooltip: {
          everybody: 'Заявка будет видна только вашим партнерам.',
          group: 'Заявка будет видна только одной группе перевозчиков.',
          everybodyAndMarket: 'Заявка будет видна партнерам и опубликована на открытом рынке.',
          groupAndMarket: 'Заявка будет видна одной группе перевозчиков и опубликована на открытом рынке.',
          onlyMarket: 'Заявка будет опубликована и видна только на открытом рынке.',
          assigned: 'Заявка видна только одному перевозчику.',
          nowhere: 'Заявка не видна никому.',
        },
        filter: {
          button: 'Фильтры',
          status: 'Статус',
          statusPlaceholder: 'Выберите статус',
          loadingPlacePlaceholder: 'Наименование, Организация, Адрес/Населенный пункт',
          unloadingPlacePlaceholder: 'Наименование, Организация, Адрес/Населенный пункт',
          dateControlPlaceholder: 'дд.мм.гггг',
          carrierOrganizationsPlaceholder: 'Выберите перевозчика',
          carrierOrganizations: 'Перевозчик',
          onlyMy: 'Только мои заявки',
          loadingPlace: 'Место погрузки',
          unloadingPlace: 'Место доставки',
          loadingDate: {
            from: 'Дата погрузки с',
            to: 'Дата погрузки по',
          },
          unloadingDate: {
            from: 'Дата доставки с',
            to: 'Дата доставки по',
          },
          orderId: 'Системный номер заявки',
          extOrderId: 'Внешний номер заявки',
          buttons: {
            clear: 'Очистить фильтры',
            accept: 'Применить',
          },
        },
        sort: {
          placeholder: 'Сортировать по',
          byId: 'По дате создания',
          byFirstLoadingPointDate: 'По дате отгрузки',
          byOrderNumber: 'По номеру заявки',
          byLastModifiedDatetime: 'По дате последнего обновления',
        },
        search: 'Номер заявки, места погрузки и разгрузки',
        auction: {
          title: 'Аукцион',
          betsCount: 'Ставок',
          lastBet: 'Лучшая ставка',
          price: 'Стартовая цена',
          acceptDeadline: 'Завершится через',
        },
        priceRequest: {
          title: 'Запрос цены',
          offersCount: 'Предложений',
          offersBest: 'Лучшее предложение',
        },
        simpleOrder: {
          title: 'Срочный',
          price: 'Цена',
        },
        bidding: {
          title: 'Сбор предложений',
          price: 'Цена',
        },
        route: {
          pointsOnRoute: 'Точек на маршруте',
        },
      },
      table: {
        filters: {
          title: 'Фильтр',
          clear: 'Очистить',
          place: {
            loading: 'Введите место погрузки',
            unloading: 'Введите место доставки',
          },
          placeTitle: 'Место погрузки / доставки',
          loadingDate: {
            from: 'Дата погрузки с',
            to: 'Дата погрузки по',
          },
          unloadingDate: {
            from: 'Дата доставки с',
            to: 'Дата доставки по',
          },
          number: {
            id: 'По номеру заявки',
            externalNo: 'По внешнему номеру',
            input: 'Укажите номер заявки',
          },
          carrierTitle: 'Перевозчики',
          carrierPlaceholder: 'Название перевозчика',
          dateTitle: 'Дата',
          driverAndVehicle: 'Водитель и транспорт',
          vehicleDataRegNo: 'Госномер автомобиля',
          driverDataFullname: 'ФИО водителя',
          numberTitle: 'Номер заявки',
          onlyMy: 'Только мои заявки',
          onlyMyBets: 'Только мои ставки',
          market: 'Рынок',
          tms: 'Партнёры',
        },
        column: {
          id: 'ID',
          idNumber: 'ID/Вн. Номер',
          icons: '',
          iconsTooltip: {
            sla: 'Нарушение критерия уровня обслуживания.\n {{ value }}',
          },
          externalNo: 'Внеш.№',
          loadingPlace: 'Откуда',
          loadingPlaces: 'Откуда',
          unloadingPlace: 'Куда',
          unloadingPlaces: 'Куда',
          carrier: 'Перевозчик',
          email: 'Эл. почта',
          role: 'Роль',
          name: {
            title: 'Название',
            first: 'Имя',
            last: 'Фамилия',
            company: 'Компания',
          },
          loadingDate: 'Дата',
          unloadingDate: 'Дата',
          date: {
            created: 'Дата создания',
            modified: 'Дата изменения',
          },
          type: {
            cargo: 'Тип груза',
          },
          volume: 'Объём (м³)',
          temperatureMode: 'Температурный режим (\u2103)',
          bodySubType: 'Подтип кузова',
          loadingTypes: 'Типы погрузки / разгрузки',
          status: 'Статус',
          price: 'Цена',
          lastBet: 'Текущая ставка',
          weight: 'Вес (т)',
          comment: 'Комментарий',
          timeleft: 'До конца аукциона, чч:мм',
          lifeleft: 'Время на обработку, чч:мм:cc',
          bidtimeleft: 'До конца торгов, чч:мм',
          actions: 'Действия',
          extraConditionsForMarket: 'Доп. условия перевозки',
          extraConditionsForMarketPlaceholder: 'Доп. условия для заявки-договора',
          isMarket: 'Публикация',
          publishType: {
            hints: {
              isMarket: 'Рынок',
              tms: 'Партнёр',
            },
          },
          customerOrganization: 'Заказчик перевозки',
          owner: 'Ответственный за размещение заявки',
          driver: 'Водитель',
          vehicleRegNo: 'Гос.номер ТС',
          department: 'Завод',
          vehicleType: 'Тип ТС',
          bodyType: 'Тип кузова',
          startPrice: 'Стартовая ставка',
          biddingStatusForCarrier: 'Статус',
          myOrganizationBet: 'Моя ставка',
          loadingDispatcher: 'Диспетчер',
          unloadingDispatcher: 'Диспетчер',
          loadingStatus: 'Статус погрузки',
          unloadingStatus: 'Статус разгрузки',
          onWayStatus: 'Статус в пути',
          onWayProgress: 'Прогресс',
          onWayEstimate: 'Прогноз',
          cargo: 'Груз',
          vehicle: 'ТС',
          allocationType: 'Тип заказа',
          acceptDeadline: 'Таймер',
          notSpecified: 'не указан',
        },
        search: 'Поиск',
      },
      action: {
        create: {
          title: 'Создать',
          titleCargo: 'Груз',
          titleRouting: 'Маршрут',
        },
        import: {
          title: 'Импортировать из ILS',
          action: 'Импортировать',
          project: 'Проект',
          importSuccess: 'Импорт проекта начат успешно',
          importWithError: 'Ошибка импорта. {{value}}',
          projectsLoadingFailed: 'Не удалось загрузить список проектов. {{value}}',
        },
        details: 'Детали заявки',
        copy: 'Скопировать заявку',
        remove: 'Удалить заявку',
        archive: 'Архивировать заявку',
        accept: 'Забрать заявку',
        cancel: 'Отказаться от заявки',
        tracking: 'Отследить',
        carrier: {
          filter: {
            all: 'Все заявки',
            auction: 'Аукцион',
            free: 'Свободные заявки',
            taken: 'Мои заявки',
            bids: 'Торги',
          },
        },
        owner: {
          filter: {
            all: 'Все заявки',
            auction: 'Аукцион',
            free: 'Опубликованные',
            taken: 'Назначенные',
            draft: 'Неопубликованные',
            completed: 'Исполненные',
            canceled: 'Отмененные',
          },
          routes: {
            unclosed: 'Незакрытые',
            onWay: 'В рейсе',
            closed: 'Завершенные',
          },
        },
        goToTracking: 'Перейти к отслеживанию',
        goToOrder: 'Перейти к заявке',
      },
      confirmation: {
        default: {
          title: 'Подтвердить?',
          content: '',
          confirm: 'Да',
          cancel: 'Нет',
          cancelMP: 'Отмена',
        },
      },
      pager: {
        itemsPerPage: 'Элементов на странице',
      },
      stageOfOrder: {
        free: 'Опубликована',
        pending: 'Ожидает обработки',
        assigned: 'Назначение ТС и водителя',
        transportReserved: 'Подписание документов',
        contractAttached: 'Ожидает выполнения',
        readyToGo: 'Выполняется',
        canceled: 'Отменена',
        completed: 'Завершена',
        EDM: {
          signing: 'Подписание документов',
        },
      },
      status: {
        transportReserved: 'Указаны данные',
        contractAttached: 'Отправлена заявка-договор',
        canceled: 'Отменена',
        completed: 'Исполнена',
        cargoOwnerSigning: 'Договор на подписи у грузоотправителя',
        cargoOwnerSigned: 'Договор подписан грузоотправителем',
        contractSigned: 'Договор двусторонне подписан',
        contractSignedError: 'Ошибка подписания.',
        terminationAgreement: 'Соглашение о расторжении заявки–договора отправлено',
        terminationAgreementCargoSign: 'Соглашение о расторжении подписано грузовладельцем и ожидает вашего подписания',
        terminationAgreementFullSign: 'Соглашение о расторжении заявки–договора двусторонне подписано',
      },
      documents: {
        blockTitle: 'Документы',
        uploadBtn: 'Приложить документы',
        sendToCarrier: 'Отправить перевозчику на подписание',
        sendToOwner: 'Отправить грузовладельцу на подписание',
        signRegularSign: 'Подписать',
        edmModal: {
          sendToCarrier: 'Документ успешно отправлен перевозчику',
          uploadSigned: 'Загрузить подписанный файл',
          hint: 'Скачайте, подпишите и загрузите скан документа',
          cargoOwnerSigned: 'Подписан ГВ',
          carrierSigned: 'Подписан ГП',
          change: 'Изменить',
        },
      },
      insurance: {
        blockTitle: 'Страхование',
        actions: {
          insure: 'Застраховать груз',
        },
        certificate: 'Страховой сертификат',
        invoice: 'Счет на оплату',
      },
      lifetime: {
        label: 'Время на оформление заявки ограничено. Осталось ',
        labelStatus: 'Время на оформление',
        expired: 'Время на обработку заявки истекло',
      },
      reservationTimeLimit: 'Эта заявка имеет ограничение на время оформления',
      reservationTimeExpired: 'Заявка отменена, время на оформление истекло',
      dashboard: {
        title: 'На сегодня',
        cards: {
          all: 'Все',
          unclosed: 'Незакрытые',
          loading: 'Погрузки',
          unloading: 'Разгрузки',
          onTheWay: 'Выполняются',
          completed: 'Завершены',
          free: 'На торгах',
          pending: 'В обработке',
          onSign: 'Оформление',
          waitToGo: 'Ожидают выполнения',
          refusal: 'Отказы',
        },
      },
      labels: {
        customer: 'Заказчик перевозки',
        truckAndDriver: 'Транспорт и водитель',
        carrier: 'Исполнитель',
        freight: 'Фрахт',
      },
      carrier: {
        dispatcher: 'Экспедитор',
        actualCarrierOrganizationPlaceholder: 'Перевозчик',
        actualCarrierOrganizationSearchPlaceholder: 'Введите наименование перевозчика',
        contractBetweenCarrierOwner: 'Номер договора',
        typeProperty: 'Чей транспорт',
        changeContractDialog: {
          title: 'Сменить договор',
          content: 'Вы уверены, что хотите сменить договор на {{ value }} ?',
          confirm: 'Сменить',
          cancel: 'Отмена',
        },
        archiveCotractTitle: '• Неактивен',
        contractActiveError: 'Выбран неактивный договор',
      },
      freight: {
        labels: {
          amountCarrier: 'Сумма услуги перевозчику',
          dispatcherCommision: 'Комиссия экспедитора',
          priceWithVat: 'Сумма с НДС',
          priceVat: 'Сумма НДС',
          rateVat: 'Ставка НДС',
          counterPartyVat: 'НДС за контрагента',
          result: 'Итого',
        },
        errors: {
          required: 'Укажите фрахт и агентское вознаграждение для перевозки',
          agentAmountLess100: 'Комиссия экспедитора (с НДС) не может быть меньше 100 руб. Укажите другую сумму.',
          agentAmountMoreFreight: 'Обратите внимание! Сумма вознаграждения экспедитора превышает сумму выплаты перевозчику. Продолжить?',
        },
      },
      loadingPlacesTitle: 'Места погрузки',
      unloadingPlacesTitle: 'Места доставки',
      blackList: {
        transport: {
          blackListInfoOrderCard: 'На момент ввода, объект {{type}} находился в черном списке',
        },
        driver: {
          blackListInfoOrderCard: 'На момент ввода водителя, объект находился в черном списке',
        },
      },
      tender: {
        type: 'Тип заявки',
        typeValue: 'Тендерная',
        number: 'Номер лота',
        timeUntilEndAction: 'Время на обработку',
      },
      startEdmSign: 'Перейти к подписанию заявки',
    },
    sla: {
      table: {
        column: {
          id: 'Ид.',
          name: 'Наименование',
          description: 'Описание',
          nonTrackableCriteriaCount: 'Пользовательских критериев',
          trackableCriteriaCount: 'Отслеживаемых критериев',
          createdAt: 'Создано',
        },
      },
    },
    passport: passportLocalization,
    auth: authLocalization,
    directory: {
      loadingPlacesTable: {
        title: 'Места погрузки/разгрузки',
        config: {
          backToList: 'Назад к списку',
          allPlaces: 'Все места',
          loading: 'Погрузка',
          unloading: 'Разгрузка',
          cancel: 'Отмена',
          save: 'Сохранить',
          delete: 'Архивировать',
          copy: 'Копировать',
          add: 'Добавить',
          edit: 'Редактировать',
          view: 'Посмотреть',
        },
        column: {
          id: 'Ид.',
          pointName: 'Наименование',
          companyName: 'Организация',
          address: 'Адрес',
          typePoint: 'Назначение',
          location: 'Схема',
          coordinates: 'Координаты',
          type: 'Тип',
        },
        inn: 'ИНН',
        pointType: {
          loading: 'Погрузка',
          unloading: 'Разгрузка',
        },
        addressType: {
          warehouse: 'Склад',
          store: 'Торговая точка',
          client: 'Клиент',
          railwaystation: 'ЖД станция',
          port: 'Порт',
          garage: 'Гараж',
        },
        countries: {
          RU: 'Россия',
          KZ: 'Казахстан',
          BY: 'Беларусь',
        },
        noAddresses: 'Адреса не найдены',
        actionResult: {
          deleteSuccess: 'Адрес перемещен в архив',
          deleteError: 'Ошибка при перемещении адреса в архив',
          addSuccess: 'Адрес добавлен',
          addError: 'Ошбика добавления адреса',
          editSuccess: 'Изменения сохранены',
          editError: 'Ошибка сохранения изменений',
        },
      },
      loadingPlacesDetails: {
        title: {
          create: 'Новый адрес',
          loadingPlace: 'Адрес погрузки',
          unloadingPlace: 'Адрес доставки',
        },
        status: {
          title: 'Статус',
          archive: 'Архивный адрес',
          active: 'Активный адрес',
          all: 'Все',
          activeStatus: 'Активные',
        },
        formLabels: {
          country: 'Страна',
          toLoad: 'Загрузить',
          toLoadAlternative: 'Загрузить схему проезда',
          place: 'Это место',
          loadingCheckbox: 'Погрузка',
          deliveryCheckbox: 'Разгрузка',
          pointName: 'Наименование',
          addressType: 'Тип адреса',
          companyName: 'Организация',
          settlement: 'Населённый пункт',
          address: 'Адрес',
          mapPlace: 'Место на карте',
          typesAllowed: 'Разрешённые типы погрузки/разгрузки',
          loadTypesSide: 'Боковая',
          loadTypesBack: 'Задняя',
          loadTypesTop: 'Верхняя',
          workingHours: 'Режим работы:',
          extPointId: 'Внешний id',
          contactPersons: 'Контактные лица',
          interestedPersons: 'Заинтересованые лица',
          firstName: 'Имя',
          lastName: 'Фамилия',
          phoneNumber: 'Телефон',
          addContact: 'Добавить контакт',
          isUnarchived: 'Активна',
          isArchived: 'Неактивна',
          status: 'Статус записи:',
          archieve: 'Архивировать',
          dearchieve: 'Разархивировать',
          searchAddress: 'Поиск адреса',
          timezone: 'Часовой пояс',
          type: 'Назначение',
          latitude: 'Широта',
          longitude: 'Долгота',
        },
        actions: {
          add: 'Добавить',
        },
        validation: {
          noActionSelected: 'Необходимо выбрать хотя бы один тип места',
          noUniqueContact: 'Такой контакт уже существует',
          noTypeLoadUnload: 'Необходимо выбрать один тип',
          noAddressFound: 'Адрес не найден. Вы можете указать точку на карте',
        },
        modals: {
          daDataModal: {
            title: 'Выберите точку на карте',
            emtpy: 'Ничего не найдено',
            confirm: 'Выбрать',
            cancel: 'Закрыть',
          },
          archive: {
            title: 'Архивирование адреса',
            content: 'Вы уверены, что хотите переместить этот адрес в архив?',
            confirm: 'Да, архивировать',
            cancel: 'Нет',
          },
          cancel: {
            title: 'Отмена действия',
            content: 'При переходе на другую страницу данные не сохранятся. Вы уверены, что хотите перейти?',
            confirm: 'Да, отменить',
            cancel: 'Нет',
          },
          titles: {
            loading: 'Добавить адрес погрузки',
            unloading: 'Добавить адрес разгрузки',
          },
        },
        buttons: {
          openModalMap: 'Указать точку на карте',
        },
      },
      cargoTypes: {
        title: 'Типы грузов',
        newCargo: 'Новый груз',
        noCargo: 'Грузы не найдены',
        status: {
          title: 'Статус',
          archive: 'Архивный груз',
          active: 'Активный груз',
          all: 'Все',
          activeStatus: 'Активные',
        },
        table: {
          column: {
            id: 'Ид.',
            cargoOwner: 'Грузоотправитель, на которого зарегистрирован тип груза',
            name: 'Название груза',
            sizes: 'Размеры (м)',
            defaultVolume: 'Объем (м³)',
            temperatureMode: 'Температурный режим (\u2103)',
            defaultWeight: 'Вес (т)',
            lengthCargoPackage: 'Длина грузового места (м)',
            widthCargoPackage: 'Ширина грузового места (м)',
            heightCargoPackage: 'Высота грузового места (м)',
            cargoPackagesCount: 'Мест',
            packagingType: 'Упаковка',
            typeVehicle: 'Тип ТС',
            permittedLoadingTypes: 'Погрузка/Разгрузка',
            isArchived: 'Статус',
            actions: 'Действия',
            cargoKinds: 'Вид груза',
            cargoBodyType: 'Тип кузова',
            cargoBodySubtype: 'Подтип кузова',
          },
        },
        config: {
          cancel: 'Отмена',
          save: 'Сохранить',
          delete: 'Архивировать',
          copy: 'Копировать',
          add: 'Добавить',
          edit: 'Редактировать',
          view: 'Посмотреть',
        },
        actionResult: {
          deleteSuccess: 'Груз перемещен в архив',
          deleteError: 'Ошибка при перемещении груза в архив',
          addSuccess: 'Груз добавлен',
          addError: 'Ошбика добавления груза',
          editSuccess: 'Изменения сохранены',
          editError: 'Ошибка сохранения изменений',
        },
        modals: {
          archive: {
            title: 'Архивирование груза',
            content: 'Вы уверены, что хотите переместить этот груз в архив?',
            confirm: 'Да, архивировать',
            cancel: 'Нет',
          },
          cancel: {
            title: 'Отмена действия',
            content: 'При переходе на другую страницу данные не сохранятся. Вы уверены, что хотите перейти?',
            confirm: 'Да, отменить',
            cancel: 'Нет',
          },
          titles: {
            loading: 'Добавить груз погрузки',
            unloading: 'Добавить груз разгрузки',
          },
        },
      },
      vehicleTemplates: {
        title: 'Шаблоны ТС',
        newTemplate: 'Новый шаблон',
        editTemplate: 'Редактирование шаблона',
        noTemplates: 'Шаблоны не найдены',
        config: {
          cancel: 'Отмена',
          save: 'Сохранить',
          delete: 'Удалить',
          copy: 'Копировать',
          add: 'Добавить',
          edit: 'Редактировать',
          view: 'Посмотреть',
        },
        form: {
          name: 'Наименование шаблона',
        },
        table: {
          column: {
            id: 'Ид.',
            cargoOwner: 'Грузоотправитель, на которого зарегистрирован тип груза',
            name: 'Название шаблона',
            volume: 'Объем (м³)',
            temperatureMode: 'Температурный режим (\u2103)',
            capacity: 'Грузоподъемность (т)',
            loadingTypes: 'Тип погрузки/разгрузки',
            isArchived: 'Статус',
            bodyTypes: 'Тип кузова',
          },
        },
        modals: {
          archive: {
            title: 'Архивирование шаблона',
            content: 'Вы уверены, что хотите переместить этот шаблон в архив?',
            confirm: 'Да, архивировать',
            cancel: 'Нет',
          },
          delete: {
            title: 'Удаление шаблона',
            content: 'Вы уверены, что хотите удалить этот шаблон?',
            confirm: 'Да, удалить',
            cancel: 'Нет',
          },
        },
        actionResult: {
          deleteSuccess: 'Шаблон удален',
          deleteError: 'Ошибка при удалении шаблона',
          addSuccess: 'Шаблон добавлен',
          addError: 'Ошибка добавления шаблона',
          editSuccess: 'Изменения сохранены',
          editError: 'Ошибка сохранения изменений',
        },
      },
      carrierOrganizations: {
        title: 'Контрактованы',
        table: {
          column: {
            id: 'Ид.',
            identifier: 'Идентификатор',
            about: 'Описание организации',
            phoneNumber: 'Телефон организации',
            email: 'Электронная почта',
            status: 'Статус организации',
            name: 'Название',
            inn: 'ИНН',
            kpp: 'КПП',
            registrationNumber: 'ОГРН/ОГРНИП',
            organizationType: 'ОГРН/ИП',
            signer: 'Подписывающее лицо (Фамилия И. О.)',
            address: 'Адрес',
            legalAddress: 'Юридический адрес',
            postalAddress: 'Почтовый адрес',
            owner: 'Руководитель',
            contracts: 'Договор №',
          },
          noCarriers: 'Перевозчики не найдены',
        },
        action: {
          invite: 'Пригласить перевозчика',
        },
      },
      carrierGroups: {
        title: 'Группы',
        titlePage: 'Группа грузоперевозчиков',
        members: 'Перевозчики, входящие в группу',
        moreCarriersMessage: {
          zero: '',
          one: '... и еще {{value}} перевозчик',
          fromTwoToFive: '... и еще {{value}} перевозчика',
          other: '... и еще {{value}} перевозчиков',
        },
        table: {
          column: {
            id: 'Ид.',
            name: 'Название',
            carriersQuantity: 'Кол-во',
            inn: 'ИНН',
            organizationType: 'ОГРН/ИП',
            address: 'Адрес',
            owner: 'Руководитель',
            contracts: 'Договор №',
            menu: '',
          },
          noGroups: 'Перевозчики не найдены',
        },
        config: {
          delete: 'Удалить',
          copy: 'Копировать',
        },
        empty: 'Группа пуста',
        actions: {
          addCarrier: 'Добавить организацию',
          addGroup: 'Создать',
        },
        confirm: {
          removeGroup: {
            title: 'Удаление группы перевозчиков',
            content: 'Вы уверены, что хотите удалить группу?',
            confirm: 'Да, удалить',
            cancel: 'Нет',
          },
          removeItem: {
            title: 'Удаление перевозчика из группы',
            content: 'Вы уверены, что хотите удалить перевозчика из группы?',
            confirm: 'Да, удалить',
            cancel: 'Нет',
          },
        },
        success: 'Группа успешно сохранена',
        errors: {
          save: 'Ошибка при сохранении группы перевозчиков',
        },
        remove: {
          success: 'Группа успешно удалена',
        },
      },
      accreditation: {
        cancelRejectMessage: 'Возвращение на аккредитацию прошло успешно!',
        cancelMessage: 'Отказ в аккредитации прошел успешно!',
        cancelAccreditationMessage: 'Аккредитация прошла успешно!',
        title: 'На аккредитации',
        titlePage: 'На аккредитации',
        modalReject: {
          title: 'Отказать в аккредитации',
          cancel: 'Отменить',
          accept: 'Подтвердить',
          placeholderReject: 'Причина отказа*',
        },
        menu: {
          solution: 'Принять решение',
        },
        solutionModal: {
          title: 'Решение по аккредитации',
          idx: 'Проверить в “IDX”',
          dia: 'Проверить в “Диадок”',
          spa: 'Проверить в “Спасские ворота”',
        },
        table: {
          column: {
            name: 'Название',
            inn: 'Инн',
            organizationType: 'ОГРН/ИП',
            address: 'Адрес',
            owner: 'Руководитель',
            action: '',
            id: 'Ид.',
            signer: 'ФИО администратора',
            fioEmployee: 'ФИО сотрудника',
            email: 'Электронная почта',
            phoneNumber: 'Телефон',
            historyDate: 'Дата',
            reason: 'Причина',
          },
        },
      },
      reject: {
        title: 'Отказано',
        table: {
          column: {
            name: 'Наименование',
            inn: 'ИНН',
            identifier: 'Идентификатор',
            fioAdmin: 'ФИО администратора',
            email: 'Электронная почта',
            phone: 'Телефон',
            date: 'Дата',
            fioEmployee: 'ФИО сотрудника',
            reason: 'Причина',
          },
        },
      },
      drivers: {
        table: {
          column: {
            surname: 'Фамилия',
            namePatronymic: 'Имя и отчество',
            name: 'Имя и отчество',
            phone: 'Телефон',
            application: 'Мобильное приложение',
            actions: 'Действия',
            verificationStatus: 'Статус проверки',
            blacklist: 'Статус блокировки',
          },
          tooltip: {
            application: {
              installed: 'Установлено',
              notInstalled: 'Не установлено',
            },
          },
        },
        filter: {
          allDrivers: 'Все водители',
          activeDrivers: 'Активные',
          archivedDrivers: 'Архивные',
        },
        actions: {
          add: 'Добавить водителя',
        },
        message: {
          success: 'Водитель сохранен',
        },
      },
      trucks: {
        message: {
          success: 'Транспортное средство сохранено',
          duplicate: {
            number: 'ТС с таким госномером уже существует. Попробуйте изменить значение поля',
            sts: 'ТС с таким СТС уже существует. Попробуйте изменить значение поля',
            vin: 'ТС с таким VIN уже существует. Попробуйте изменить значение поля',
          },
          remove: {
            success: 'Транспортное средство добавлено в архив',
          },
        },
      },
      owners: {
        title: 'Доверенные грузоотправители',
      },
      contractingRequired: {
        title: 'Требуется контрактование',
        table: {
          column: {
            name: 'Название',
            inn: 'ИНН',
            organizationType: 'ОГРН/ИП',
            address: 'Адрес',
            owner: 'Руководитель',
            contracts: '',
            menu: '',
            uploadContract: 'Загрузить договор',
          },
        },
        success: 'Договор успешно загружен',
        failed: 'Договор не загружен',
      },
      carriers: {
        title: 'Перевозчики',
        trusted: 'Доверенные',
      },
    },
    tracking: {
      offerInfoTitle: 'Заявка № {{orderId}}',
      orderLink: 'перейти к заявке',
      loadingPlace: 'Погрузка',
      unloadingPlace: 'Разгрузка',
      currentLocation: 'Текущее месторасположение',
      departureInRoute: 'Выезд на маршрут',
      cargoLoadingComplete: 'Этап маршрута завершен',
      arrivedToDeliveryPlace: 'Приезд на место доставки',
      enteredDeliveryPlace: 'Въезд на территорию места доставки',
      cargoType: 'Тип груза',
      distance: 'Расстояние',
      duration: 'Время в пути',
      durationInTraffic: 'Время с пробками',
      buttonIsNotAvailableNotification: 'Отслеживание данной заявки невозможно',
      timeLag: 'Задержка по времени',
      tnIntegration: 'ТН',
      driverMobile: 'Водитель',
      arrivedToLoadingPlace: 'Приезд на место погрузки',
      enteredLoadingPlace: 'Въезд на территорию места погрузки',
      departureFromLoadingPlace: 'Выезд с территории места погрузки',
      arrivedToLoadingPlaceShort: 'Прибыл',
      enteredLoadingPlaceShort: 'Въехал',
      departureFromLoadingPlaceShort: 'Выехал',
      loadingPrefix: 'Погрузка',
      incorrectCoordinates:
        'Координаты точки разгрузки, по которой отправляется событие о прибытии, не совпадают с фактическими координатами пользователя',
      delayRoute: 'Задержка на маршруте',
      noEvents: 'Нет событий',
      pointsRoute: 'Этапы маршрута',
      eventsRoute: 'События на маршруте',
      eventDocuments: 'Прикрепленные документы',
      stage: 'Этап',
      attached: 'Приложен',
    },
    pager: {
      ofString: 'из',
      itemsPerPage: 'Элементов на странице',
      nextPageLabel: 'Следующая страница',
      previousPageLabel: 'Предыдущая страница',
      firstPageLabel: 'Первая страница',
      lastPageLabel: 'Последняя страница',
    },
    search: {
      title: 'Поиск',
      all: 'Все',
    },
    request: {
      error: { ...RU_API_BOUND_NAMING.request.error },
    },
    clientTimeWarning: {
      title: 'Внимание!',
      main: 'Время на вашем компьютере не соответствует мировому времени. Администрация сайта не несет ответственность за результат торгов.',
      recommendations: 'Рекомендации по синхронизации времени можно найти тут:',
      confirm: 'Ок',
    },
    admin: {
      users: {
        action: {
          invite: 'Пригласить',
          inviteUser: 'Пригласить пользователя',
        },
        adminCGPermissionsMessage: 'Нельзя изменять права у администратора ГК',
        table: {
          id: 'Ид.',
          lastName: 'Фамилия',
          firstName: 'Имя и отчество',
          email: 'Email',
          phoneNumber: 'Телефон',
          role: 'Роль',
          permissions: 'Права пользователя',
          status: 'Статус',
          actions: 'Действия',
          user: 'ПОЛЬЗОВАТЕЛЬ',
          rule: 'ПРАВА',
          statuses: '',
          sendInviteAgain: 'Отправить приглашение повторно',
        },
        status: {
          active: 'Активирован',
          inactive: 'Приглашение отправлено',
        },
      },
      user: {
        action: {
          resetPassword: 'Сбросить пароль',
        },
        message: {
          resetPasswordSuccess: 'На {{email}} было выслано письмо с новым паролем',
          resetPasswordError: 'При попытке сброса пароля произошла ошибка.\nОбратитесь к администратору',
          duplicateUser: 'Пользователь с таким email ({{email}}) уже существует',
          saveSuccess: 'Пользователь успешно сохранен',
          saveError: 'Ошибка при сохранении пользователя.\nОбратитесь к администратору',
          inviteSuccess: 'Пользователь успешно приглашен',
          inviteError: 'Ошибка при приглашении пользователя.\nОбратитесь к администратору',
          removeError: 'Ошибка при удалении пользователя.\nОбратитесь к администратору',
          removeSuccess: 'Пользователь успешно удален',
          reset: {
            title: 'Сброс пароля',
            content: 'Вы уверены, что хотите сбросить пароль?',
            confirm: 'Да',
            cancel: 'Нет',
          },
          reInvitation: 'Письмо отправлено повторно',
        },
        confirm: {
          remove: {
            title: 'Удаление сведений о пользователе',
            content: 'Вы уверены, что хотите удалить сведения о пользователе?',
            confirm: 'Да, удалить',
            cancel: 'Нет',
          },
        },
      },
    },
    addDriverDialog: {
      title: 'Добавьте нового водителя',
    },
    chooseDriverDialog: {
      title: 'Выбор водителя',
      emtyDriversList: 'Список водителей пуст',
      createDriver: 'Не нашли нужного водителя? Добавьте его!',
      actions: {
        change: 'Поменять',
        delete: 'Открепить',
      },
    },
    chooseTruckDialog: {
      title: 'Выбор автомобиля',
      emtyTrucksList: 'Список транспортных средств пуст',
      createTruck: 'Не нашли нужного автомобиля? Добавьте его!',
      actions: {
        change: 'Поменять',
        delete: 'Открепить',
      },
    },
    warningChooseTruck: {
      title: 'Смена ТС',
      content:
        'Данное ТС указано в подписанном договоре. Если вы хотите выбрать другое ТС, то потребуется повторное подписание договора. Вы уверены, что хотите сменить ТС?',
      confirm: 'Да, сменить',
      cancel: 'Отмена',
    },
    warningChooseReadyToGoTruck: {
      title: 'Условия смены ТС',
      content: 'Сменить ТС/водителя необходимо в течение {{time}}. Вы уверены, что сможете выполнить всю процедуру за отведенное время?',
      confirm: 'Да, сменить',
      cancel: 'Отмена',
    },
    warningChooseDriver: {
      title: 'Смена Водителя',
      content:
        'Данный Водитель указан в подписанном договоре. Если вы хотите выбрать другого Водителя, то потребуется повторное подписание договора. Вы уверены, что хотите сменить Водителя?',
      confirm: 'Да, сменить',
      cancel: 'Отмена',
    },
    ngSelectLocalize: {
      noItems: 'Выберите из списка',
    },
    vehicleRequirements: {
      title: 'Машина',
      capacity: 'Грузоподъемность',
      capacityAddon: 'тонн',
      bodyType: 'Тип кузова',
      volume: 'Объем',
      volumeAddon: 'м³',
      typeLabel: 'Выберите тип',
      vehicleSample: 'Выбрать шаблон',
      vehicleSampleRoute: 'Выберите ТС на маршрут',
      bodySubtype: 'Подтип кузова',
      length: 'Длина (м)',
      width: 'Ширина (м)',
      height: 'Высота (м)',
      loadingTypes: 'Типы погрузки/разгрузки',
      internalSizes: 'Внутренний размер кузова',
      containerType: 'Тип контейнера',
      containerTypePlaceholder: 'Выберите тип',
      specialRequirements: 'Специальные требования',
      temperatureMode: {
        groupLabel: 'Температурный режим',
        addonFrom: 'от',
        addonTo: 'до',
      },
      orderType: {
        ftlOnly: 'Выделенный транспорт (FTL)',
        ftlOrLtl: 'Возможен догруз (FTL или LTL)',
      },
    },
    cargoPlaceRequirements: {
      length: 'Длина (м)',
      width: 'Ширина (м)',
      height: 'Высота (м)',
      cargoPackagesCount: 'Грузовых мест',
      packagingType: 'Вид упаковки',
      errors: {
        cargoPackagesCount: 'Количество грузовых мест не может быть меньше 1',
      },
    },
    chronologicalOrderConfirmationDialog: {
      title: 'Подтверждение',
      content:
        'Точки маршрута расположены не в хронологическом порядке. При сохранении заявки места погрузки/разгрузки будут отсортированы по дате.',
      confirm: 'Сохранить',
      cancel: 'Продолжить редактирование',
    },
    dirtyFormConfirmationDialog: {
      title: 'Подтверждение',
      content: 'На форме есть несохраненные данные, которые будут потеряны при переходе.',
      confirm: 'Покинуть страницу',
      cancel: 'Продолжить редактирование или сохранить',
    },
    dirtyModalFormConfirmationDialog: {
      title: 'Подтверждение',
      content: 'На форме есть несохраненные данные, которые будут потеряны при переходе.',
      confirm: 'Закрыть',
      cancel: 'Продолжить редактирование',
    },
    auctionSettings: {
      prolongTime: 'Аукцион будет продлен на',
      isProlongationAvailable: 'Продление торгов',
      prolongationCount: 'Максимальное количество продлений',
      minutesTillEndToProlong: 'Если ставка сделана менее чем, за … мин до окончания торгов',
      maxProlongTime: 'Максимальное время продления {{value}} мин',
      earlyFinish: 'Досрочное завершение торгов',
      auctionStep: {
        title: 'Шаг торгов',
        options: {
          fixed: 'руб.',
          percent: {
            label: '%',
            description: '% от стартовой цены',
          },
        },
        errors: {
          shouldBeMoreThan: 'Шаг не может быть менее {{value}}{{currency}}',
          shouldBeLessThan: 'Шаг должен быть более {{value}}{{currency}}',
        },
      },
    },
    mp: marketplaceLocalization,
    masterTms: sidebarLocalization,
    monitoring: monitoringLocalization,
    fileUploadDialog: {
      title: 'Загрузка файлов',
      fileTypeSelectDefault: 'Тип',
      fileTypeSelectOrder: 'Тип документа',
      formWayBill: {
        selectUnloadingPlace: 'Место доставки',
        unloadingDateFrom: 'Фактическая дата прибытия',
        unloadingDateTo: 'Фактическая дата убытия',
        unloadingTime: 'Время',
        tip: 'Фактические данные указаны в разделе 7 Транспортной накладной. В случае их отсутствия указываются плановые дата и время подачи транспортного средства под выгрузку.',
        errors: {
          fromDateLessPlanned: 'Дата и время доставки С не может быть раньше плановой даты доставки С',
          fromDateMaxOffset: 'Дата и время доставки С не может быть указана позднее, чем 72 часа с начала плановой даты доставки',
          dateToLessDateFrom: '«Фактическая дата убытия» не может быть раньше «Фактической даты прибытия»',
          dateFromLessLoadingDate: '«Фактическая дата прибытия» не может быть раньше «Даты погрузки»',
        },
      },
    },
    documents: {
      orderContract: 'Договор',
      terminationAgreement: 'СОГЛАШЕНИЕ О РАСТОРЖЕНИИ ЗАЯВКИ-ДОГОВОРА',
      carrierSigned: 'Подписан ГП',
      ownerSigned: 'Подписан ГВ',
      bothSigned: 'Подписан двусторонне',
      checkingProcess: 'Идет проверка',
      sign: 'подписать',
      date: 'Дата',
    },
    order: {
      type: 'Тип заявки',
    },
    timeRange: {
      from: 'с',
      to: 'по',
    },
    reconciliation: {
      createAct: 'Добавить',
      table: {
        diff: {
          no: 'Нет',
          yes: 'Да',
          none: 'Проверка не проводилась',
          inProgress: 'Ожидайте, идет обработка актов сверки',
          error: 'Ошибка',
        },
        columns: {
          id: 'id',
          createDateTime: 'Дата',
          firstOrganization: 'Контрагент 1',
          secondOrganization: 'Контрагент 2',
          comment: 'Комментарий',
          actDateFrom: 'Период сверки с',
          actDateTo: 'Период сверки по',
          diff: 'Различия',
          creationMethod: 'Способ создания',
        },
      },
      form: {
        periodBlockTitle: 'Период сверки',
        firstOrganizationBlockTitle: 'Контрагент 1',
        secondOrganizationBlockTitle: 'Контрагент 2',
        resultsBlockTitle: 'Результаты сверки',
        commentBlockTitle: 'Комментарий',
        saveButton: {
          label: 'Выполнить',
        },
        organizationName: {
          label: 'Наименование контрагента',
        },
        rule: {
          label: 'Правило сверки',
        },
        comment: {
          label: 'Комментарий',
        },
        file: {
          placeholder: 'Загрузите или перетащите файл',
        },
        submit: {
          success: 'Сравнение запущено',
          error: 'Ошибка',
        },
        errors: {
          actFileRequired: 'Загрузите акт сверки',
          unknown: 'Неизвестная ошибка',
          requiredRuleCarrier:
            'Не настроено правило распознавания акта сверки. Обратитесь к логисту (заказчику перевозки), приложив в письме файл акта сверки',
          parse:
            'Не удалось распознать акт сверки {{value}}. Файл акта сверки пустой, либо неправильно указано Правило сверки. Если файл содержит данные, то обратитесь к логисту (заказчику перевозки), приложив в письме файл акта сверки',
        },
        results: {
          inProgress: 'Ожидайте, идет обработка актов сверки',
          noDiff: 'Различий нет',
        },
      },
      creationMethod: {
        auto: 'Автоматический',
        manual: 'Ручной',
      },
    },
    organizationUser: {
      form: {
        name: 'Имя',
        patronymic: 'Отчество',
        surname: 'Фамилия',
        passportSeries: 'Серия паспорта',
        passportNumber: 'Номер паспорта',
        passportDate: 'Дата выдачи',
      },
      title: 'Первое лицо организации',
    },
    driverInvite: {
      inviteTitle: 'Пригласить водителя в мобильное приложение',
      inviteTitleSuccess: 'Приглашение успешно отправлено!',
      inviteTitleError: 'Произошла ошибка отправки сообщения',
      inviteDescriptionError: 'Попробуйте снова или обратитесь в службу технической поддержки',
      inviteDescription:
        'На основной номер мобильного телефона водителя будет отправлено sms-сообщение со ссылками на Приложение Водителя.',
    },
    verificationStatus: {
      title: 'Статус проверки',
      approved: 'Одобрено',
      rejected: 'Отклонено',
      notChecked: 'Проверка не проводилась',
      onWork: 'Принято в работу',
    },
    table: {
      empty: 'Ничего не найдено',
    },
    blackListInfo: {
      iconTip: {
        blockTitle: 'Заблокировано Технониколь',
        partBlockTitle: 'Частично заблокировано Технониколь',
        reasonMessage: '{{type}}: {{reason}}',
        nomenclatureMessage: '{{nomenclature}}',
      },
      nomenclature: 'Вид номенклатуры: ',
    },
    blackListType: {
      trailer: 'Прицеп',
      truck: 'ТС',
      driver: 'водителя',
    },
    timeTextFormat: {
      timeIsUp: 'время истекло',
      day: 'д',
      hour: 'ч',
      minute: 'м',
    },
    allowedExtraServices: {
      priceOld: ', {{price}} руб ',
      vatAmountOld: '(в т.ч. НДС {{vatAmount}} руб)',
    },
    userAgreement: 'Пользовательское соглашение',
    tender: { ...tenderLocalization },
    quotas: {
      statuses: {
        IN_WORK: 'В работе',
        COMPLETED: 'Завершена',
        CREATED: 'Запланирована',
      },
      deleteConfirmation: {
        title: 'Удаление квоты',
        content: 'Вы уверены, что хотите удалить эту квоту?',
        confirm: 'Да',
        cancel: 'Нет',
      },
    },
    driver: { ...driverLocalization },
    staff: { ...staffLocalization },
    registrationStepper: { ...registrationStepperLocalization },
    blackList: { ...blackListInfoLocalization },
    help: { ...helpLocalization },
    profile: { ...userProfileLocalization },
    supervisor: { ...supervisorLocalization },
    paginatorLabel: 'Элементов на странице',
    warningMessageDetails: 'Подробнее',
    warningMessageTermAgreement: 'У вас есть неподписанные документы по заявке',
    inviteModal: {
      copy: 'Скопировано в буфер обмена',
      linkChanged: 'Ссылка изменена',
      title: 'Персональная ссылка',
      send: 'Отправить',
      invite: 'Пригласить',
      success: 'Приглашение успешно отправлено',
      personalLinkHint: 'Присоединиться может один грузоперевозчик',
      accreditationCheckboxTitle: 'Автоматическая аккредитация',
      accreditationCheckboxHint: 'Перевозчик сразу получит доступ к заявкам',
      publicLinkTitle: 'Публичная ссылка',
      publicLinkTitleHint: 'Любое количество перевозчиков могут присоединиться',
      publicLinkInfo: 'По ссылке может подключиться неограниченное кол-во перевозчиков. Перевозчики попадут на аккредитацию.',
      publicLinkToggle: 'Активировать публичную ссылку',
      publicLinkLabel: 'Общедоступная ссылка для перевозчика',
      inviteMore: 'Пригласить еще',
    },
    inviteUserModal: {
      title: 'Пригласить сотрудника',
      placeholderEmail: 'Электронная почта сотрудника',
      access: 'Права доступа:',
      hint: 'На указанный Email будет отправлена ссылка для получения доступа в систему.',
    },
    detailsUserModal: {
      title: 'Детали сотрудника',
    },
    uploadContract: {
      title: 'Загрузка договора',
      contractId: 'Номер договора',
      dateStart: 'Дата начала',
      dateEnd: 'Дата завершения',
      uploadTooltip: 'Кликните для выбора или перетащите файл (PDF, DOCX, DOC, JPEG)',
      addContract: 'Добавить договор',
    },
    betsCount: 'Ставок: {{value}}',
    integrationApi: {
      title: 'API Коннектор',
      description: 'Универсальный инструмент для интеграции вашего бизнес-приложения с Master TMS.',
      titleKey: 'Ключ доступа API',
      keyUsageDescription: 'Для выполнения запросов к API, формируйте заголовок формата: "authorization: IntegrationToken 716fffb4fbfe..."',
      documentation: 'Документация',
      swaggerDescription: 'Со списком доступных методов и подробной документацией можно ознакомиться',
      invite: 'Приглашение интегратора',
      link: 'по ссылке',
      inviteDescription1: 'Вы можете предоставить доступ к данному разделу вашему сотруднику или внешнему интегратору. Для этого в разделе',
      inviteDescription2: 'пригласите пользователя с правом “Доступ к настройке интеграций”',
      resetTitle: 'Подтвердите сброс ключа',
      empty: 'Отсутствует',
      resetDescription:
        'Вы уверены, что хотите сбросить API ключ? После сброса запросы по старому ключу больше <strong>не</strong> будут возможны.',
      actions: {
        connect: 'Подключить',
        setting: 'Настроить',
        generateToken: 'Сгенерировать',
        resetToken: 'Сбросить ключ',
        reset: 'Сбросить',
        cancel: 'Отменить',
      },
      status: {
        notConnected: 'Не подключен',
        connected: 'Подключен',
      },
    },
    integrationILS: {
      title: 'Коннектор к Interlogis',
      description: 'Интеграция с системой маршрутизации interlogis.ru',
      userAndPass: 'Пользователь и пароль',
      userAndPassDescription: 'Для выполнения запросов к API, укажите пользователя и пароль.',
      user: 'Пользователь',
      login: 'Логин',
      password: 'Пароль',
      connectSuccess: 'Подключение к ILS прошло успешно',
      connectWithError: 'Не удалось подключиться к ILS. {{value}}',
      goToOrders: 'Для импорта проекта перейдите в ',
      actions: {
        connect: 'Подключить',
        setting: 'Настроить',
        cancel: 'Отменить',
      },
      status: {
        notConnected: 'Не подключен',
        expired: 'Не активный',
        connected: 'Подключен',
      },
    },
  },
};
