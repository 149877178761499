import { transport } from '@transport/proto';

import { createNewDriver, IDriver } from '../../driver/driver.interface';
import { TDirectoryEntityPredefinedAction } from '../directory.interface';

/**
 * Router driver page component data model.
 */
export class TnDriverPageComponentData {
  public title = 'directory: driver page';

  public driver: IDriver = createNewDriver();

  public predefinedAction: TDirectoryEntityPredefinedAction | null = null;

  public select: {
    countries: {
      code: string;
      name: string;
    }[];
  } = {
    countries: [],
  };

  public agentCarriers: transport.IAgentCarrier[] = [];
}
