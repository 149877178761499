import { enumHasValue } from '@transport/ui-utils';

import { PROBLEM_EVENTS } from '../filters';

/**
 * Order status enumerator.
 */
export enum ORDER_STATUS {
  FREE = 'FREE',
  ASSIGNED = 'ASSIGNED',
  TRANSPORT_RESERVED = 'TRANSPORT_RESERVED',
  PENDING = 'PENDING',
  CONTRACT_ATTACHED = 'CONTRACT_ATTACHED',
  READY_TO_GO = 'READY_TO_GO',
  COMPLETED = 'COMPLETED',
  CANCELED = 'CANCELED',
}

export enum ORDER_STATUS_ON_EVENT {
  NEW = 'NEW',
  DRIVER_CONFIRMED = 'DRIVER_CONFIRMED',
  ON_ROUTE = 'ON_ROUTE',
  DELIVERY_COMPLETED = 'DELIVERY_COMPLETED',
  CANCELED = 'CANCELED',
}

export enum ACCEPTING_PLACE {
  MARKET = 'MARKET',
  PARTNERS = 'PARTNERS',
}

/**
 * Order statuses.
 */
export const OrderStatuses: ORDER_STATUS[] = Object.keys(ORDER_STATUS).map(key => ORDER_STATUS[key]);

export const orderStatusTranslateDictionary: Record<keyof typeof ORDER_STATUS, string> = {
  [ORDER_STATUS.FREE]: 'free',
  [ORDER_STATUS.ASSIGNED]: 'assigned',
  [ORDER_STATUS.TRANSPORT_RESERVED]: 'transportReserved',
  [ORDER_STATUS.PENDING]: 'pending',
  [ORDER_STATUS.CONTRACT_ATTACHED]: 'contractAttached',
  [ORDER_STATUS.READY_TO_GO]: 'readyToGo',
  [ORDER_STATUS.COMPLETED]: 'completed',
  [ORDER_STATUS.CANCELED]: 'canceled',
};

export enum CARRIER_ORDER_PREDEFINED_ACTION {
  ACCEPT = 'accept',
  CANCEL = 'cancel',
}

/**
 * Orders filter interface.
 */
export interface IOrdersFilterDto {
  // Filter by single status.
  status?: ORDER_STATUS;
  // Filter by multiple statuses.
  statuses?: ORDER_STATUS[];
  // Paging filter: how many elements should be returned after offset.
  first?: number;
  // Paging filter: how many elements should be passed before returning elements equal to 'first' value.
  offset?: number;
  loadingPlaceName?: string;
  unloadingPlaceName?: string;
  onlyMy: boolean;
  isMarket?: boolean;
  totalCount: boolean;
  allocationType?: ORDER_ALLOCATION_TYPE_GROUP;
  biddingType: BIDDING_TYPE;
  actualProblemEvent?: PROBLEM_EVENTS;
  loadingDates?: string[];
  unloadingDates?: string[];
  loadingDateFrom?: string;
  loadingDateTo?: string;
  unloadingDateFrom?: string;
  unloadingDateTo?: string;
  carrierOrganizationIds?: string[];
  id?: string;
  externalNo?: string;
  driverDataFullname?: string;
  vehicleDataRegNo?: string;
}

export interface IPublishTypeFilter {
  biddingType: BIDDING_TYPE;
}

export interface IOrdersFilter {
  type?: ORDERS_FILTER_MODE | string;
  publishType?: IPublishTypeFilter;
  loadingPlaceName?: string;
  unloadingPlaceName?: string;
  onlyMy?: boolean;
  isMarket?: boolean;
  actualProblemEvent?: PROBLEM_EVENTS;
  loadingDates?: string;
  unloadingDates?: string;
  id?: string;
  carriers?: string;
  externalNo?: string;
  driverDataFullname?: string;
  vehicleDataRegNo?: string;
  loadingDateFrom?: string;
  allocationType?: ORDER_ALLOCATION_TYPE;
}

export type TCarrierOrdersFilter = Omit<IOrdersFilter, 'loadingPlaceName' | 'unloadingPlaceName'> & {
  loadingPlaceByFiasCodes?: string[] | string;
  unloadingPlaceByFiasCodes?: string[] | string;
  onlyMy?: boolean;
};

export type TCarrierOrdersFilterDto = Omit<IOrdersFilterDto, 'loadingPlaceName' | 'unloadingPlaceName' | 'onlyMy'> & {
  loadingPlaceByFiasCodes?: string[];
  unloadingPlaceByFiasCodes?: string[];
  onlyMy?: boolean;
};

export enum ORDERS_FILTER_MODE {
  ALL = 'all',
  FREE = 'free',
  TAKEN = 'taken',
  AUCTION = 'auction',
  DRAFT = 'draft',
  BIDS = 'bids',
  READY_TO_GO = 'readyToGo',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
}

export enum ORDERS_FILTER_ROUTES {
  UNCLOSED = 'unclosed',
  ON_WAY = 'onWay',
  CLOSED = 'closed',
}

export enum ORDERS_TABLE_FILTER_MODE {
  DRAFT = 'draft',
  FREE = 'free',
  TAKEN = 'taken',
  ON_WAY = 'onWay',
  COMPLETED = 'completed',
  CANCELED = 'canceled',
}

export enum ORDERS_FILTER_COUNTERS_TYPE {
  ALL = 'all',
  FREE = 'free',
  ASSIGNED = 'assigned',
  AUCTION = 'auction',
  DRAFT = 'draft',
  BIDDING = 'bidding',
  CONTRACTING_REQUIRED = 'contracting-required',
}

export enum ORDER_DETAILS_PAGE_KIND {
  MAIN = 'main',
  AUCTION = 'auction',
  BID = 'bidding',
}

// Используется в теле заявки
export enum ORDER_ALLOCATION_TYPE {
  SIMPLE_ALLOCATION = 'SIMPLE_ALLOCATION',
  AUCTION_ALLOCATION = 'AUCTION_ALLOCATION',
  PREASSIGNED_ALLOCATION = 'PREASSIGNED_ALLOCATION',
  BIDDING_ALLOCATION = 'BIDDING_ALLOCATION',
}

export enum ACK_STATUS {
  NOT_REQUIRED = 'NOT_REQUIRED',
  EXPECTED = 'EXPECTED',
  CONFIRMED = 'CONFIRMED',
}

export enum CARGO_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  LOADED = 'LOADED',
  DELIVERED = 'DELIVERED',
  CANCELLED = 'CANCELLED',
  PARTLY_DELIVERED = 'PARTLY_DELIVERED',
}

export enum ORDER_ALLOCATION_TYPE_GROUP {
  // Все заявки
  ALL = 'ALL',
  // Простые заявки
  SIMPLE = 'SIMPLE',
  // ТМС
  AUCTION = 'AUCTION',
  PREASSIGNED = 'PREASSIGNED',
  BIDDING = 'BIDDING',
}

export enum BIDDING_TYPE {
  ALL = 'ALL',
  MARKET = 'MARKET',
  PARTNER = 'PARTNER',
}

export enum ORDER_LOT_STATUS {
  LOST = 'lostStatus',
  WON = 'wonStatus',
  LOOSING = 'loosingStatus',
  WINNING = 'winningStatus',
}

/**
 * Order publish type.
 */
export enum ORDER_PUBLISH_TYPE {
  GROUP = 'GROUP',
  SINGLE = 'SINGLE',
  EXCHANGE = 'EXCHANGE',
  ALL = 'ALL',
}

export enum ORDER_KIND {
  AUCTION = 'auction',
  SIMPLE = 'simple',
  DRAFT = 'draft',
  BID = 'bidding',
}

export enum ORDER_BIDDING_STATUS {
  PLACE_BET_AND_WON = 'PLACE_BET_AND_WON',
  PLACE_BET_AND_LOST = 'PLACE_BET_AND_LOST',
  PLACE_BET_AND_CANCEL = 'PLACE_BET_AND_CANCEL',
  PLACE_BET_AND_WAIT = 'PLACE_BET_AND_WAIT',
  NOT_PLACE_BET = 'NOT_PLACE_BET',
}

export enum ORDER_SIGNING_WAY {
  ORDER_SCAN = 'ORDER_SCAN',
  DIGITAL_SIGNATURE = 'DIGITAL_SIGNATURE',
  SCAN_OR_DIGITAL_SIGNATURE = 'SCAN_OR_DIGITAL_SIGNATURE',
  TN_CRYPTO = 'TN_CRYPTO',
}

export enum DOCUMENT_STATUS {
  DOCUMENT_CREATING_ERROR = 'DOCUMENT_CREATING_ERROR',
  CARGO_OWNER_SIGNING = 'CARGO_OWNER_SIGNING',
  CARGO_OWNER_SIGNATURE_CHECKING = 'CARGO_OWNER_SIGNATURE_CHECKING',
  CARGO_OWNER_SIGNATURE_OK = 'CARGO_OWNER_SIGNATURE_OK',
  CARGO_OWNER_SIGNATURE_ERROR = 'CARGO_OWNER_SIGNATURE_ERROR',
  CARRIER_SIGNATURE_CHECKING = 'CARRIER_SIGNATURE_CHECKING',
  SIGNED = 'SIGNED',
  CARRIER_SIGNATURE_ERROR = 'CARRIER_SIGNATURE_ERROR',
}

export enum DOCUMENT_TYPE {
  CONTRACT_FORM = 'contract_form', // форма заявки-договора
  REQUEST_FORM = 'request_form', // форма генеральной заявки
  CONTRACT = 'contract', // договор
  TERMINATION_AGREEMENT = 'term_agreement', // соглашение о расторжении
  DIGIT_SIGN = 'digit_sign', // Электронная подпись
  WAYBILL = 'waybill', // Транспортная накладная
  POA_DRIVER = 'poa_driver', // Доверенность водителю
  GOODS_INFO = 'goods_info', // подробная информация по заявке
  INVOICE_SERVICES_ACTUAL_CARRIER = 'invoice_services_actual_carrier', // Файл счета-фактуры на оказанные услуги
  ACT_ACTUAL_CARRIER = 'act_actual_carrier', // Файл акта об оказанных услугах
}

export enum ORDERS_TABLE_COLUMN_NAMES {
  ID = 'idNumber',
  ICONS = 'icons',
  EXTERNAL_NO = 'externalNo',
  TIME_TO_PROCESS_ORDER = 'lifeleft',
  FIRST_LOADING_POINT_ADDRESS = 'loadingPlace',
  LAST_LOADING_POINT_ADDRESS = 'unloadingPlace',
  FIRST_LOADING_POINT_DATE = 'loadingDate',
  LAST_LOADING_POINT_DATE = 'unloadingDate',
  WEIGHT = 'weight',
  VOLUME = 'volume',
  TEMPERATURE_MODE = 'temperatureMode',
  VEHICLE_VEHICLEBODY_SUBTYPE_NAME = 'bodySubType',
  STATUS = 'status',
  PRICE = 'price',
  COMMENT = 'comment',
  CUSTOMER_ORGANIZATION_NAME = 'customerOrganization',
  LOADING_TYPES = 'loadingTypes',
  OWNER = 'owner',
  CARRIER = 'carrier',
  AUCTION_TIME_TO_LEFT = 'timeleft',
  LAST_BET = 'lastBet',
  IS_MARKET = 'isMarket',
  DRIVER = 'driver',
  VEHICLE_REG_NO = 'vehicleRegNo',
  HAS_EXTRA_CONDITIONS_FOR_MARKET = 'hasExtraConditionsForMarket',
  TIME_TO_PROCESS_BID = 'bidtimeleft',
  START_PRICE = 'startPrice',
  MY_ORGANIZATION_BET = 'myOrganizationBet',
  BIDDING_STATUS_FOR_CARRIER = 'biddingStatusForCarrier',
  LOADING_STATUS = 'loadingStatus',
  UNLOADING_STATUS = 'unloadingStatus',
  ON_WAY_STATUS = 'onWayStatus',
  ON_WAY_PROGRESS = 'onWayProgress',
  ON_WAY_ESTIMATE = 'onWayEstimate',
  CARGO = 'cargo',
  VEHICLE = 'vehicle',
  TODAY = 'today',
  ALLOCATION_TYPE = 'allocationType',
  ACCEPT_DEADLINE = 'acceptDeadline',
}

export enum CARGO_TABLE_TYPE {
  AUCTION = 'auction',
  ORDER = 'order',
}

export const isValidPublishTypeFilter = (filter: IPublishTypeFilter): boolean => {
  return enumHasValue(BIDDING_TYPE, filter.biddingType);
};

export const ordersSorterDto = {
  id: 'id',
  externalNo: 'external_no_lower',
  lifeleft: 'process_time_advanced',
  loadingPlace: 'first_loading_point_name',
  unloadingPlace: 'first_unloading_point_name',
  loadingDate: 'first_loading_point_date',
  unloadingDate: 'last_unloading_point_date',
  weight: 'weight',
  volume: 'volume',
  temperatureMode: 'temperature_mode',
  bodySubType: 'vehiclerequirements__body_subtype__name',
  status: 'status_advanced',
  price: 'price',
  comment: 'comment_lower',
  customerOrganization: 'customer_organization_name',
  carrier: 'carrier_organization__name',
  timeleft: 'lot_view_end_datetime',
  lastBet: 'lot__last_bet',
  loadingTypes: 'loading_types',
  owner: 'owner_fullname',
  isMarket: 'is_market',
  driver: 'driver_data_fullname',
  vehicleRegNo: 'vehicle_data__reg_no',
  hasExtraConditionsForMarket: 'lot__has_extra_conditions_for_market',
  contractPrice: 'contract_price',
};

export const biddingOrdersSorterDto = {
  id: 'id',
  externalNo: 'external_no_lower',
  loadingPlace: 'first_loading_point_name',
  unloadingPlace: 'last_unloading_point_name',
  loadingDate: 'first_loading_point_date',
  unloadingDate: 'last_unloading_point_date',
  weight: 'weight',
  volume: 'volume',
  temperatureMode: 'temperature_mode',
  bodySubType: 'vehiclerequirements__body_subtype__name',
  comment: 'comment_lower',
  customerOrganization: 'customer_organization__name',
  myOrganizationBet: 'my_organization_bet',
  biddingStatusForCarrier: 'bidding_status_advanced',
};

export enum DELIVERY_METHODS {
  FTL = 'FTL',
  LTL = 'LTL',
}

export enum ORDER_CANCEL_REASON {
  // перевозчик не найден
  ORDER_EXPIRED = 'ORDER_EXPIRED',
  AUCTION_ENDED_WITHOUT_WINNER = 'AUCTION_ENDED_WITHOUT_WINNER',
  ORDER_ACCEPT_DEADLINE_EXPIRED = 'ORDER_ACCEPT_DEADLINE_EXPIRED',
  // перевозчик отказался
  CARRIER_REFUSED_ORDER = 'CARRIER_REFUSED_ORDER',
  // поиск перевозчика был отменен
  CARGOOWNER_CANCELED_ORDER = 'CARGOOWNER_CANCELED_ORDER',
  // Перевозчик не оформил заявку за выделенное время
  TIME_TO_PROCESS_ORDER_EXPIRED = 'TIME_TO_PROCESS_ORDER_EXPIRED',
  ORDER_RESERVATION_TIME_EXPIRED = 'ORDER_RESERVATION_TIME_EXPIRED',
  // Машина не прибыла на погрузку
  CARRIER_NOT_SHOWING_FOR_LOADING = 'CARRIER_NOT_SHOWING_FOR_LOADING',
}

export enum ORDER_CANCEL_INITIATOR {
  CARRIER_CANCELLING = 'CARRIER_CANCELLING',
  CARGO_OWNER_CANCELLING = 'CARGO_OWNER_CANCELLING',
}
