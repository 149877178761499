import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TnMapPoint } from '@transport/ui-interfaces';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import {
  getCityByAddress,
  getMapPointByAddress,
  getMapPointByAddressFailure,
  getMapPointByAddressSuccess,
  getMapPointsByAddress,
  getMapPointsByCoordinates,
  setDadataData,
} from './dadata.actions';
import { TnDadataApiService } from './dadata-api.service';

@Injectable({
  providedIn: 'root',
})
export class TnDadataEffects {
  constructor(private readonly action$: Actions, private readonly api: TnDadataApiService) {}

  public readonly getMapPointByAddress$ = createEffect(() =>
    this.action$.pipe(
      ofType(getMapPointByAddress),
      switchMap(payload => this.api.getMapPointByAddress(payload)),
      switchMap(data => {
        return of(
          setDadataData({ payload: { suggestions: Boolean(data) ? [data as TnMapPoint] : [] } }),
          getMapPointByAddressSuccess({
            point: data ?? ({} as TnMapPoint),
          }),
        );
      }),
    ),
  );

  public readonly getMapPointsByAddress$ = createEffect(() =>
    this.action$.pipe(
      ofType(getMapPointsByAddress),
      switchMap(({ payload }) =>
        this.api.getMapPointsByAddress(payload, payload.countResults).pipe(
          map(data => setDadataData({ payload: { suggestions: data } })),
          catchError(error => of(getMapPointByAddressFailure(error))),
        ),
      ),
    ),
  );

  public readonly getCityByAddress$ = createEffect(() =>
    this.action$.pipe(
      ofType(getCityByAddress),
      switchMap(({ payload }) =>
        this.api.getCityByAddress(payload).pipe(
          map(data => setDadataData({ payload: { suggestions: data } })),
          catchError(error => of(getMapPointByAddressFailure(error))),
        ),
      ),
    ),
  );

  public readonly getMapPointsByCoordinates$ = createEffect(() =>
    this.action$.pipe(
      ofType(getMapPointsByCoordinates),
      switchMap(({ payload }) => this.api.getMapPointsByCoordinates(payload.lat, payload.lon)),
      map(data => setDadataData({ payload: { suggestions: data } })),
    ),
  );
}
