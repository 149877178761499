import { ChangeDetectionStrategy, Component, HostBinding } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { fadeInOut, slideAndFadeInOut } from '@transport/ui-utils';

/**
 * Passport root component.
 * Root
 * view component for Passport.
 */
@UntilDestroy()
@Component({
  selector: 'transport-marketplace-root',
  templateUrl: './root.component.html',
  styleUrls: ['./root.component.scss'],
  animations: [fadeInOut, slideAndFadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnMarketplaceRootComponent {
  @HostBinding('@.disabled')
  public animationsDisabled = true;

  constructor() {}
}
