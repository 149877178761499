import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TnFileToUpload, TnUploadedFile, USER_ROLE } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';
import { first, map, switchMap } from 'rxjs/operators';

import { getCurrentUser } from '../current-user/current-user.selectors';
import { TnGqlClientSharedService } from '../gql-client-shared/graphql-client-shared.service';

@Injectable({
  providedIn: 'root',
})
export class TnFileUploadService {
  constructor(private readonly gqlClientShared: TnGqlClientSharedService, private readonly store: Store) {}

  public upload(file: TnFileToUpload, withSpinner: boolean, role?: USER_ROLE): Observable<TnUploadedFile> {
    return this.store.select(getCurrentUser).pipe(
      first(),
      switchMap(user => {
        return this.gqlClientShared.uploadFile(role || user.role, file, withSpinner);
      }),
      map(resp => {
        const res = new TnUploadedFile(file.fullName, resp?.uploadFile?.url ?? '', resp?.uploadFile?.id ?? '');
        return res;
      }),
    );
  }
}
