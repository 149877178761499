import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { transport } from '@transport/proto';

import { ICargoType } from '../cargo-type';
import { trimmedStringValidator } from '../form-validators/forms';
import { vehicleRequirementsValidator } from '../form-validators/vehicle-requirements-order-form.validator';

export enum CARGO_TYPE_FORM_CONSTANTS {
  MAX_NAME_LENGTH = 50,
  MIN_DEFAULT_WEIGHT = 0.001,
  MAX_DEFAULT_WEIGHT = 99.99,
  MIN_DEFAULT_VOLUME = 0,
  MAX_DEFAULT_VOLUME = 120.0,
  MIN_CARGO_PACKAGE_DIMENSIONS = 0.01,
  MAX_CARGO_PACKAGE_DIMENSIONS = 99.99,
  MIN_TEMPERATURE = -99,
  MAX_TEMPERATURE = 99,
  MIN_DEFAULT_CAPACITY = 0,
  MAX_DEFAULT_CAPACITY = 99.99,
  MIN_CARGO_COUNT = 1,
  MAX_CARGO_COUNT = 999,
}
export class TnCargoTypeFormData {
  public name = '';

  public defaultWeight: number | null = null;

  public defaultVolume: number | null = null;

  public temperatureMode: number | null = null;

  public vehicleRequirements: transport.Order.IVehicleRequirements;

  public cargoPlaceRequirements: transport.Order.IPlaceRequirements;

  /**
   * TnCargoTypeFormData constructor.
   * @param cargoType cargo type data
   */
  constructor(
    cargoType: ICargoType,
    vehicleRequirements: transport.Order.IVehicleRequirements,
    cargoPlaceRequirements: transport.Order.IPlaceRequirements,
  ) {
    this.name = cargoType.name ?? '';
    this.defaultWeight = cargoType.defaultWeight ?? null;
    this.defaultVolume = cargoType.defaultVolume ?? null;
    this.temperatureMode = cargoType.temperatureMode ?? null;
    this.vehicleRequirements = vehicleRequirements;
    this.cargoPlaceRequirements = cargoPlaceRequirements;
  }
}

/**
 * Cargo type form interface.
 */
export interface ICargoTypeForm extends FormGroup {
  controls: {
    name: AbstractControl;
    defaultWeight: AbstractControl;
    defaultVolume: AbstractControl;
    temperatureMode: AbstractControl;
    vehicleRequirements: AbstractControl;
    cargoPlaceRequirements: AbstractControl;
  };
}

/**
 * New cargo type form constructor type.
 * @param fb form builder
 * @param disabled Flag for disabling controls
 * @param emptyValue Flag for setting empty value for controls
 * @param editableItem editable item
 */
export type TNewCargoTypeFormConstructor = (fb: FormBuilder, emptyValue: boolean, editableItem?: TnCargoTypeFormData) => ICargoTypeForm;

const temperatureValidators: ValidatorFn | null = Validators.compose([
  Validators.min(CARGO_TYPE_FORM_CONSTANTS.MIN_TEMPERATURE),
  Validators.max(CARGO_TYPE_FORM_CONSTANTS.MAX_TEMPERATURE),
]);

/**
 * Cargo type form constructor
 * @param fb form builder
 * @param disabled Flag for disabling controls
 * @param emptyValue Flag for setting empty value for controls
 * @param editableItem editable item
 */
export const newCargoTypeForm: TNewCargoTypeFormConstructor = (
  fb: FormBuilder,
  emptyValue: boolean,
  editableItem?: TnCargoTypeFormData,
): ICargoTypeForm => {
  const form = fb.group({
    name: [
      emptyValue ? '' : editableItem?.name,
      Validators.compose([Validators.required, trimmedStringValidator, Validators.maxLength(CARGO_TYPE_FORM_CONSTANTS.MAX_NAME_LENGTH)]),
    ],
    defaultWeight: [
      emptyValue ? '' : editableItem?.defaultWeight,
      Validators.compose([
        Validators.min(CARGO_TYPE_FORM_CONSTANTS.MIN_DEFAULT_WEIGHT),
        Validators.max(CARGO_TYPE_FORM_CONSTANTS.MAX_DEFAULT_WEIGHT),
      ]),
    ],
    defaultVolume: [
      emptyValue ? '' : editableItem?.defaultVolume,
      Validators.compose([
        Validators.min(CARGO_TYPE_FORM_CONSTANTS.MIN_DEFAULT_VOLUME),
        Validators.max(CARGO_TYPE_FORM_CONSTANTS.MAX_DEFAULT_VOLUME),
      ]),
    ],
    temperatureMode: [emptyValue ? '' : editableItem?.temperatureMode, temperatureValidators],
    vehicleRequirements: [emptyValue ? null : editableItem?.vehicleRequirements, vehicleRequirementsValidator],
    cargoPlaceRequirements: [emptyValue ? '' : editableItem?.cargoPlaceRequirements],
  }) as ICargoTypeForm;
  return form;
};
