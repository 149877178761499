import { OverlayRef } from '@angular/cdk/overlay';
import { HttpClient } from '@angular/common/http';
import { NgZone, Provider } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { transport } from '@transport/proto';
import { APP_ENV, TnAppEnvironment, WS_CONFIG } from '@transport/ui-interfaces';
import { HttpLink } from 'apollo-angular/http';

import { TnDomainService } from '../../../../transport-ui-services/src/lib/domain/domain.service';
import { TnConstantsApiService } from '../constants';
import { TnAuthApiService, TnCurrentUserEffects, TnCurrentUserFacade, TnUserService } from '../current-user';
import { TnAnalyticsService } from '../current-user/services/analytics/analytics.service';
import { TnHttpHandlersService } from '../feature-access';
import { TnFiasApiService } from '../fias';
import { TnGqlClientCarrierService } from '../gql-client-carrier';
import { TnGqlClientSharedService } from '../gql-client-shared';
import { TnPassportFacade, TnPassportService } from '../passport';
import { TnOrganizationService } from '../profile';
import { TnProgressService } from '../progress';
import { ITnState } from '../state';
import { TnClSsoFrameService } from '../tncl-sso-frame/tncl-sso-frame-service.service';
import { TnToasterFacade, TnToasterService } from '../toaster';

export const httpHandlersProviders: Provider[] = [
  {
    provide: APP_ENV,
    useValue: {
      production: false,
      testing: true,
      debugging: false,
      apiDomain: 't12.tntest.xyz',
      appName: 'Master-TMS testing',
      support: {
        phone: '+79155925073',
        email: 'support@transport2.ru',
      },
    },
  },
  {
    provide: TnUserService,
    useFactory: (gql: TnGqlClientSharedService, tnDomainService: TnDomainService) =>
      new TnUserService(new TnAppEnvironment(), gql, tnDomainService, new TnClSsoFrameService()),
    deps: [TnGqlClientSharedService, TnDomainService],
  },
  {
    provide: TnCurrentUserFacade,
    useFactory: (store: Store<ITnState>, actions: Actions) => new TnCurrentUserFacade(store, actions),
    deps: [Store, Actions],
  },
  {
    provide: TnCurrentUserEffects,
    useFactory: (
      actions: Actions,
      user: TnUserService,
      gql: TnGqlClientSharedService,
      gqlCarrier: TnGqlClientCarrierService,
      store: Store<ITnState>,
      toastFacade: TnToasterFacade,
      router: Router,
    ) => new TnCurrentUserEffects(actions, user, gql, gqlCarrier, store, toastFacade, router),
    deps: [Actions, TnUserService, TnGqlClientSharedService, TnGqlClientCarrierService],
  },
  HttpLink,
  {
    provide: WS_CONFIG,
    useValue: {
      url: '',
      reconnectInterval: 1,
      reconnectAttempts: 1,
    } as transport.IWebSocketConfig,
  },
  {
    provide: TnProgressService,
    useFactory: (overlay: OverlayRef) => new TnProgressService(overlay),
    deps: [OverlayRef],
  },
  {
    provide: TnToasterService,
    useFactory: (snackbar, zone, toast, router) => new TnToasterService(snackbar, zone, toast, router),
    deps: [MatSnackBar, NgZone],
  },
  {
    provide: TnHttpHandlersService,
    useFactory: (
      userFacade: TnCurrentUserFacade,
      httpLink: HttpLink,
      store: Store,
      win: Window,
      env: TnAppEnvironment,
      toastFacade: TnToasterFacade,
      ssoService: TnClSsoFrameService,
    ) => new TnHttpHandlersService(userFacade, httpLink, store, env, toastFacade, ssoService),
    deps: [TnCurrentUserFacade, HttpLink, Store, APP_ENV, TnToasterFacade, TnClSsoFrameService],
  },
  {
    provide: TnToasterFacade,
    useFactory: (translate: TranslateService, store: Store) => new TnToasterFacade(translate, store),
    deps: [TranslateService, Store],
  },
  {
    provide: TnAuthApiService,
    useFactory: (http: HttpClient, userFacade: TnCurrentUserFacade, handlers: TnHttpHandlersService) =>
      new TnAuthApiService(http, userFacade, handlers),
    deps: [HttpClient, TnCurrentUserFacade, TnHttpHandlersService],
  },
  {
    provide: TnConstantsApiService,
    useFactory: (http: HttpClient, handlers: TnHttpHandlersService, env: TnAppEnvironment) =>
      new TnConstantsApiService(http, handlers, env),
    deps: [HttpClient, TnHttpHandlersService, APP_ENV],
  },
  {
    provide: TnFiasApiService,
    useFactory: (http: HttpClient, handlers: TnHttpHandlersService) => new TnFiasApiService(http, handlers),
    deps: [HttpClient, TnHttpHandlersService],
  },
  {
    provide: TnPassportFacade,
    useFactory: (store: Store<ITnState>, passportService: TnPassportService, domainService: TnDomainService) =>
      new TnPassportFacade(store, passportService, domainService),
    deps: [Store, TnPassportService, TnDomainService],
  },
  /**
   * @note TnPassportService should not depend directly on TnGqlClientCarrierService
   */
  {
    provide: TnPassportService,
    useFactory: (gql: TnGqlClientCarrierService, organization: TnOrganizationService) =>
      new TnPassportService(gql, organization),
    deps: [TnGqlClientCarrierService, TnOrganizationService],
  },
  {
    provide: TnAnalyticsService,
    useFactory: (authService: TnAuthApiService, handlers: TnHttpHandlersService, router: Router, http: HttpClient, env: TnAppEnvironment) =>
      new TnAnalyticsService(authService, handlers, router, http, env),
    deps: [TnAuthApiService, TnHttpHandlersService, Router, HttpClient, APP_ENV],
  },
];
