import { getTableReducer } from '../../table/table-params.reducer';
import { subOrganizationsTableActions } from './sub-organizations.actions';

const initialState = {
  filter: {
    availability: 'ALL',
  },
};

export const subOrganizationsTableFeatureKey = 'subOrganizations';

export function subOrganizationsTableReducer(state, action) {
  return getTableReducer(subOrganizationsTableActions, initialState)(state, action);
}
