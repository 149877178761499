<div class="verification-card rounded-md flex flex-col bg-white p-4 mx-auto shadow-md gap-2.5">
    <ng-container *ngIf="!isDenied; else isDeniedInfo">
      <p class="text-gray-700 text-xl font-medium">{{'shared.mp.accreditation.cargoBlock.title' | translate}}</p>
      <p class="text-sm text-gray-700 text-center">{{'shared.mp.accreditation.cargoBlock.bodyText' | translate}}</p>
    </ng-container>
    <ng-template #isDeniedInfo>
      <p class="text-gray-700 text-xl font-medium text-center">{{'shared.mp.accreditation.cargoBlock.titleReject' | translate}}</p>
      <p class="text-sm text-gray-700 text-center">{{'shared.mp.accreditation.cargoBlock.bodyTextReject' | translate}}</p>
    </ng-template>
    <button *ngIf="isNotStarted" class="bet__button tw-button-solid-sm w-full m-0" (click)="startVerificationFlow()">
      {{'shared.mp.accreditation.cargoBlock.button' | translate}}
      <mat-icon>arrow_forward</mat-icon>
    </button>
</div>