import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject, throttleTime } from 'rxjs';
import { TIMEOUT } from '@transport/ui-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DriverService } from '@marketplace/shared';

@UntilDestroy()
@Component({
  selector: 'driver-modal',
  templateUrl: './driver-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriverModalComponent implements OnInit {
  public service: DriverService = this.data.service;
  private action$ = new Subject();
  public showLimitations = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { service: DriverService }) {}

  ngOnInit() {
    this.action$.pipe(untilDestroyed(this), throttleTime(TIMEOUT.DEFAULT)).subscribe(() => this.service.vm?.action());
  }

  action() {
    this.action$.next(null);
  }
}
