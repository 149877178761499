import { Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: '[transportScrollTo]',
})
export class TnScrollToDirective {
  constructor(private readonly elementRef: ElementRef) {}

  @Input() public scrollPosition: { x: 0; y: 0 } = { x: 0, y: 0 };

  @Input() public set transportScrollTo(isChanged: boolean) {
    if (isChanged) {
      this.changeScrollPosition();
    }
  }

  public changeScrollPosition() {
    const { x, y } = this.scrollPosition;
    if ((this.elementRef.nativeElement as HTMLElement).scrollTo instanceof Function) {
      (this.elementRef.nativeElement as HTMLElement).scrollTo(x, y);
    }
  }
}
