<div class='flex flex-col justify-between h-full py-4 px-4'>
  <div class='flex flex-col'>
    <div class='flex justify-between modal-header'>
      <span class='text-gray-700 font-normal text-md'>
        {{ 'shared.mp.vehicles.blackListModal.title' | translate }}
      </span>
      <button class="h-6" mat-dialog-close>
        <mat-icon class="close-icon rounded-full text-gray-400 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200">close</mat-icon>
      </button>
    </div>

    <div class='flex flex-col gap-2 mt-2'>

      <div class='flex flex-col text-gray-500 text-sm gap-1' *ngIf='data.vehicle'>
        <span *ngIf='data.vehicle.vehicleType'>
          <span class='text-gray-500'>{{ 'shared.mp.vehicles.blackListModal.vehicleType' | translate }}: </span>
          <span class='text-gray-700'>{{(vehicleType || '') | translate}}</span>
        </span>
        <span>
          <span class='text-gray-500'>{{ 'shared.mp.vehicles.blackListModal.regNumber' | translate }}: </span>
          <span class='text-gray-700'>{{(regNo || '') | translate}}</span>
        </span>
        <span>
          <span class='text-gray-500'>{{ 'shared.mp.vehicles.blackListModal.country' | translate }}: </span>
          <span class='text-gray-700'>{{(country || '') | translate}}</span>
        </span>
      </div>

      <common-select
        *ngIf='!data.vehicle?.country'
        [disabled]='!!data.vehicle'
        label="{{ 'shared.mp.vehicles.vehicle.form.country' | translate }}"
        class="w-full !gap-0"
        size="sm"
        [control]="$any(form.controls.country)"
        [required]="true"
        [dataSource]="countries"
        [allowClear]="false"
        [errorToMessage]="validationErrors.bind(this)"
        dataTest="form-vehicle-country">
      </common-select>

<!--      <common-select-->
<!--        *ngIf='data.vehicle?.vehicleType'-->
<!--        [disabled]='!!data.vehicle'-->
<!--        label="{{ 'shared.mp.vehicles.vehicle.form.type' | translate }}"-->
<!--        size="sm"-->
<!--        [control]="$any(form.controls.vehicleType)"-->
<!--        [required]="true"-->
<!--        [dataSource]="vehicleTypes"-->
<!--        [allowClear]="false"-->
<!--        [errorToMessage]="validationErrors.bind(this)"-->
<!--        dataTest="form-vehicle-type">-->
<!--      </common-select>-->

      <common-input
        *ngIf='!data.vehicle?.regNo'
        [disabled]='!!data.vehicle'
        label="{{ 'shared.mp.vehicles.vehicle.form.regNumber' | translate }}"
        size="sm"
        class="flex-grow basis-[47%]"
        inputClasses="uppercase"
        [errorToMessage]="validationErrors.bind(this)"
        [placeholder]="registrationNumberPlaceholder"
        [required]="true"
        [control]="$any(form.controls.regNo)"
        [mask]="vehiclePassport?.registrationNumber?.mask"
        [patterns]="$any(vehiclePassport?.registrationNumber?.patterns)"
        dataTest="form-vehicle-regNumber"
      ></common-input>

      <common-textarea
        [disabled]='!!data.vehicle'
        class="w-full"
        placeholder="{{ 'shared.mp.vehicles.blackListModal.reason' | translate }}"
        label="{{ 'shared.mp.vehicles.blackListModal.reason' | translate }}"
        dataTest="blacklist-reason"
        [required]='true'
        [errorToMessage]="validationErrors.bind(this)"
        [size]="'sm'"
        [rows]='3'
        [control]="$any(form.controls.reason)"
      >
      </common-textarea>


      <common-textarea
        class="w-full"
        placeholder="{{ 'shared.mp.vehicles.blackListModal.description' | translate }}"
        label="{{ 'shared.mp.vehicles.blackListModal.description' | translate }}"
        dataTest="blacklist-description"
        [size]="'sm'"
        [rows]='3'
        [control]="$any(form.controls.description)"
      >
      </common-textarea>

      <common-select
        dataTest="block-type"
        [required]='true'
        [label]="'shared.mp.vehicles.blackListModal.block' | translate"
        [control]="$any(form.controls.workProhibited)"
        [dataSource]="$any(blockTypes)"
        [allowClear]="false"
        [value]="form.controls.workProhibited.value"
      >
      </common-select>
    </div>
  </div>

  <div mat-dialog-actions class="flex mt-auto gap-3 w-full">
    <button class="tw-button-solid-sm flex-auto text-sm flex-grow" [disabled]='!form.touched'
            (click)="send()">
      {{ 'shared.mp.vehicles.blackListModal.send' | translate }}
    </button>

    <button class="tw-button-outline-sm !border-gray-200 !text-gray-700 text-sm" (click)="cancel()">
      {{ 'shared.directory.loadingPlacesTable.config.cancel' | translate }}
    </button>
  </div>

</div>
