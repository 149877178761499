import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { IReconcilationActDetailsRouteParams, TnReconciliationActDetailsFormValue } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { RouterActions } from '../../ngrx-router';
import { ITnState } from '../../state';
import * as actDetailsActions from '../actions/reconciliation-act.actions';
import * as apiActions from '../actions/reconciliation-act-api.actions';
import * as selectors from '../selectors/reconciliation-act.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnReconciliationActFacade {
  constructor(public store: Store<ITnState>, private readonly action$: Actions) {}

  public organizations$ = this.store.pipe(select(selectors.selectReconciliationOrganizations));

  public rules$ = this.store.pipe(select(selectors.selectReconciliationRules));

  public isFormDirty$ = this.store.pipe(select(selectors.selectIsFormDirty));

  public isUserOwner$ = this.store.pipe(select(selectors.selectIsUserOwner));

  public isUserCarrier$ = this.store.pipe(select(selectors.selectIsUserCarrier));

  public invalidFormSubmitted$ = this.action$.pipe(ofType(actDetailsActions.invalidFormSubmitted));

  public needScrollToResults$ = this.store.pipe(select(selectors.selectNeedScrollToResults));

  public isDisabledFormMode$ = this.store.pipe(select(selectors.selectIsDisabledFormMode));

  public isSaveBtnVisible$ = this.store.pipe(select(selectors.selectIsSaveBtnVisible));

  public isResultsVisible$ = this.store.pipe(select(selectors.selectIsResultsVisible));

  public actDetailsFormValue$: Observable<TnReconciliationActDetailsFormValue> = this.store.pipe(
    select(selectors.selectActFormValue),
    filter(val => val !== null),
  ) as Observable<TnReconciliationActDetailsFormValue>;

  public loadActs(pageNumber: number, countPerPage: number): void {
    this.store.dispatch(apiActions.loadReconciliationActsStart({ pageNumber, countPerPage }));
  }

  public reconciliationActDetailsRouteParamsChanged(params: IReconcilationActDetailsRouteParams): void {
    this.store.dispatch(actDetailsActions.reconciliationActDetailsRouteParamsChanged({ params }));
  }

  public saveActBtnClick(act: TnReconciliationActDetailsFormValue): void {
    this.store.dispatch(actDetailsActions.saveActBtnClick({ act }));
  }

  public actDetailsFormCreated(): void {
    this.store.dispatch(actDetailsActions.actDetailsFormCreated());
  }

  public actDetailsFormScrolledToResults(): void {
    this.store.dispatch(actDetailsActions.reconciliationActDetailsPageScrolledToResults());
  }

  public navigateToAddAct(): void {
    this.store.dispatch(RouterActions.routerGo({ path: ['reconciliation/create'] }));
  }

  public navigateToViewAct(guid: string): void {
    this.store.dispatch(RouterActions.routerGo({ path: ['reconciliation/view', guid] }));
  }

  public backToList(): void {
    this.store.dispatch(RouterActions.routerGo({ path: ['reconciliation'] }));
  }

  public pageDestroyed(): void {
    this.store.dispatch(actDetailsActions.reconciliationActDetailsPageDestroyed());
  }

  public actFormValidStatusChanged(isValid: boolean): void {
    this.store.dispatch(actDetailsActions.reconciliationActDetailsFormValidStatusChanged({ isValid }));
  }

  public actFormDirtyStatusChanged(isDirty: boolean): void {
    this.store.dispatch(actDetailsActions.reconciliationActDetailsFormDirtyStatusChanged({ isDirty }));
  }
}
