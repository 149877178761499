import { createReducer, on } from '@ngrx/store';
import { transport } from '@transport/proto';

import { agentCarrierActions } from '../actions/agent-carrier.actions';

export interface IAgentCarrierState {
  agent: transport.IAgentCarrier | null;
  legalForms: transport.ILegalForm[];
  loading: boolean;
  organizationSearchError: string;
  companyName?: string | null;
}

export const initialState: IAgentCarrierState = {
  agent: null,
  legalForms: [],
  loading: false,
  organizationSearchError: '',
  companyName: '',
};

const agentCarrierReducerFunc = createReducer(
  initialState,
  on(agentCarrierActions.getAgentCarrier, agentCarrierActions.addAgentCarrier, agentCarrierActions.editAgentCarrier, state => ({
    ...state,
    loading: true,
  })),
  on(agentCarrierActions.getAgentCarrierSuccess, (state, { agent }) => {
    return {
      ...state,
      agent,
      companyName: agent?.name,
      loading: false,
    };
  }),
  on(agentCarrierActions.getLegalFormsSuccess, (state, { legalForms }) => ({
    ...state,
    legalForms,
  })),
  on(agentCarrierActions.getOrganizationInfo, state => ({
    ...state,
    organizationSearchError: '',
    loading: true,
  })),
  on(agentCarrierActions.getOrganizationInfoFailure, (state, { organizationSearchError }) => ({
    ...state,
    organizationSearchError,
    companyName: null,
    loading: false,
  })),
  on(agentCarrierActions.getOrganizationInfoSuccess, (state, { company }) => ({
    ...state,
    companyName: company?.companyName ?? '',
    loading: false,
  })),
  on(agentCarrierActions.clearOrganizationError, state => ({
    ...state,
    organizationSearchError: '',
  })),
  on(
    agentCarrierActions.getAgentCarrierFailure,
    agentCarrierActions.addAgentCarrierSuccess,
    agentCarrierActions.addAgentCarrierFailure,
    agentCarrierActions.editAgentCarrierSuccess,
    agentCarrierActions.editAgentCarrierFailure,
    agentCarrierActions.removeAgentCarrierSuccess,
    agentCarrierActions.removeAgentCarrierFailure,
    state => ({ ...state, loading: false }),
  ),
);

export function agentCarrierReducer(state, action) {
  return agentCarrierReducerFunc(state, action);
}
