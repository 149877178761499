import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { VEHICLE_TYPE, VEHICLE_TYPE_OWNERSHIP } from '@transport/ui-interfaces';
import { VehicleFormVm } from '../vehicle.service';
import { BehaviorSubject, combineLatest, debounceTime, map, Observable, startWith, tap } from 'rxjs';
import { OWNERSHIP_TYPES, VEHICLE_TYPES } from './vehicle-form';
import { COUNTER, regExpConfig, TIMEOUT } from '@transport/ui-utils';
import { IMarketplaceDriver } from '../vehicle.interface';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';
import { DriverService } from '../../edit-driver';
import { TnModalAddTransportService } from 'libs/transport-notifications/src/lib/components/modal-add-transport/modal-add-transport.service';
import { TnDadataApiService } from '@transport/ui-store';
import { ICommonSelectData } from 'libs/custom-controls/src/lib/common.interface';
import { setAgentCarrierData, setVehicleMakeData } from '../vehicle.mapper';
import { TnCurrentUserService } from '@marketplace/auth';
import { TnSupportedCountry } from '@transport/ui-pipes';
import { TranslateService } from '@ngx-translate/core';

interface Place {
  name: string;
  settlement: string;
}
@UntilDestroy()
@Component({
  selector: 'vehicle-form[vm]',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.scss'],
  providers: [DriverService],
})
export class VehicleFormComponent implements OnInit, OnDestroy {
  @Input() vm!: VehicleFormVm;

  public vehicleTypes = VEHICLE_TYPES.map(t => ({ id: t.type, label: this.translate.instant(t.name) }));

  public ownershipTypes = OWNERSHIP_TYPES.map(t => ({ id: t.type, label: this.translate.instant(t.name) }));
  public VEHICLE_TYPE_OWNERSHIP = VEHICLE_TYPE_OWNERSHIP;

  public vehicleType = '';

  public ownershipTractorType: VEHICLE_TYPE_OWNERSHIP | string = '';

  public ownershipTrailerType: VEHICLE_TYPE_OWNERSHIP | string = '';

  public regExpConf = regExpConfig;

  public drivers: IMarketplaceDriver[] = [];
  public _drivers$ = new BehaviorSubject<ICommonSelectData[]>([]);

  public organizations: ICommonSelectData[] = [];

  public vehicleMake$ = new BehaviorSubject<ICommonSelectData[]>([]);

  public bodySubtypes$ = new BehaviorSubject<ICommonSelectData[]>([]);

  public drivers$!: Observable<IMarketplaceDriver[]>;

  public isSubDomain: string | null = null;

  public disableOwnershipType = false;

  public isAgentOrExpeditor = Boolean(this.currentUserService.currentUser.isAgentOrExpeditor);

  public agentCarrierInputSubject$ = new BehaviorSubject<string | null>(null);

  public TnSupportedCountry = TnSupportedCountry;

  public registrationNumberPlaceholderConfig = {
    [TnSupportedCountry.RU]: 'A 123 AA 123',
    [TnSupportedCountry.KZ]: '11 YYY 11',
    [TnSupportedCountry.BY]: 'XXXXXX - N',
    [TnSupportedCountry.UZ]: '01 123 ABC',
    [TnSupportedCountry.TJ]: '1234 AB 01',
    [TnSupportedCountry.KG]: '01 123 AB',
  };

  public trailerNumberPlaceholderConfig = {
    [TnSupportedCountry.RU]: 'АА1234 12',
    [TnSupportedCountry.KZ]: '11 YYY 11',
    [TnSupportedCountry.BY]: 'XXXXXX - N',
    [TnSupportedCountry.UZ]: '01 123 ABC',
    [TnSupportedCountry.TJ]: '1234 AB 01',
    [TnSupportedCountry.KG]: '01 123 AB',
  };

  constructor(
    private domainService: TnDomainService,
    private readonly driverService: DriverService,
    private readonly cdr: ChangeDetectorRef,
    private readonly modalAddTransportService: TnModalAddTransportService,
    private readonly dadataService: TnDadataApiService,
    private currentUserService: TnCurrentUserService,
    private translate: TranslateService,
  ) {}

  public ngOnInit() {
    this.isSubDomain = this.domainService.subDomain;
    if (this.isSubDomain) {
      this.vm.form.controls.ownershipTrailerType.clearValidators();
      this.vm.form.controls.ownershipTractorType.clearValidators();
      this.tractorStsForm.controls.firstSide.clearValidators();
      this.tractorStsForm.controls.secondSide.clearValidators();
      this.trailerStsForm.controls.firstSide.clearValidators();
      this.trailerStsForm.controls.secondSide.clearValidators();
      this.tracktorCheckStateAndChangeValidation('');
      this.trailerCheckStateAndChangeValidation('');
    }

    this.vm.form.controls.country.valueChanges.pipe(untilDestroyed(this)).subscribe(({ id }) => {
      switch (id) {
        case TnSupportedCountry.RU:
          this.vm.form.controls.regNumber.clearValidators();
          this.vm.form.controls.regNumber.setValidators([Validators.required, Validators.pattern(/^[авекмнорстухАВЕКМНОРСТУХ][0-9]{3}[авекмнорстухАВЕКМНОРСТУХ]{2}[0-9]{2,3}/)]);
          this.vm.form.controls.regNumber.updateValueAndValidity();
          break;
        case TnSupportedCountry.KZ:
          this.vm.form.controls.regNumber.clearValidators();
          this.vm.form.controls.regNumber.setValidators([Validators.required, Validators.pattern(/^[A-Za-z0-9 ]{4}[A-Za-z0-9 ]{0,4}/)]);
          this.vm.form.controls.regNumber.updateValueAndValidity();
          break;
        case TnSupportedCountry.UZ:
          this.vm.form.controls.regNumber.clearValidators();
          this.vm.form.controls.regNumber.setValidators([Validators.required, Validators.pattern(/^[A-Za-z0-9 ]{8}[A-Za-z0-9 ]{0,1}/)]);
          this.vm.form.controls.regNumber.updateValueAndValidity();
          break;
        case TnSupportedCountry.KG:
          this.vm.form.controls.regNumber.clearValidators();
          this.vm.form.controls.regNumber.setValidators([Validators.required, Validators.pattern(/^[A-Za-z0-9 ]{7}[A-Za-z0-9 ]{0,1}/)]);
          this.vm.form.controls.regNumber.updateValueAndValidity();
          break;
        case TnSupportedCountry.TJ:
          this.vm.form.controls.regNumber.clearValidators();
          this.vm.form.controls.regNumber.setValidators([Validators.required, Validators.pattern(/^[A-Za-z0-9 ]{7}[A-Za-z0-9 ]{0,1}/)]);
          this.vm.form.controls.regNumber.updateValueAndValidity();
          break;
        case TnSupportedCountry.BY:
          this.vm.form.controls.regNumber.clearValidators();
          this.vm.form.controls.regNumber.setValidators([Validators.required, Validators.pattern(/[A-Za-z0-9]{6}[1-7]/)]);
          this.vm.form.controls.regNumber.updateValueAndValidity();
          break;

        default:
          break;
      }
    });

    this.vm.form.controls.type.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.vehicleType = value?.id;
      this.checkStateAndChangeValidation(this.vehicleType);
    });

    this.vm.form.controls.ownershipType.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (!this.isSubDomain && !this.disableOwnershipType) {
        this.vm.form.controls.ownershipTractorType.setValue(value);
        if (this.vehicleType !== 'TRUCK') {
          this.vm.form.controls.ownershipTrailerType.setValue(value);
        }
      }
    });
    this.vm.form.controls.isThirdParty.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.clearValidatorsIfThirdPartyType();
      if (!this.isSubDomain) {
        value ? (this.vm.actionBtnTxt = this.vm.translate.instant('shared.mp.vehicles.vehicle.buttons.save'))
              : (this.vm.actionBtnTxt = this.vm.translate.instant('shared.mp.vehicles.vehicle.buttons.saveAndAccreditate'));
      }
    });

    // this.vm.form.controls.driver.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
    //   if (value?.id) {
    //     this.vm.form.controls.driver.setValue(String(value.id));
    //   }
    // });
    this.vm.form.controls.agentCarrierId.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (value?.id) {
        this.vm.baseVm.getDrivers(value.id);
      }
    });

    this.vm.baseVm.bodySubtypes
      .pipe(
        untilDestroyed(this),
        map(v => v.bodySubtypes?.map(t => ({ id: t.id || '', label: t.name || '' })) || []),
      )
      .subscribe(v => this.bodySubtypes$.next(v));

    this.vm.baseVm.vehicleMakes
      .pipe(
        untilDestroyed(this),
        map(v => v?.vehicleMakes?.map(make => setVehicleMakeData(make) as ICommonSelectData)),
      )
      .subscribe(v => this.vehicleMake$.next(v));

    combineLatest([this.vm.vehicle$, this.bodySubtypes$, this.vehicleMake$])
      .pipe(untilDestroyed(this))
      .subscribe(([v, b, m]) => {
        const ownershipType = this.ownershipTypes?.find(o => o.id === v.ownershipType);
        const type = this.vehicleTypes?.find(o => o.id === v.type);
        const trailerBodyType = b?.find(o => o.id === v.trailerBodyType);

        this.vm.form.patchValue({ ...v, type, ownershipType, trailerBodyType });
      });

    this.vm.baseVm.drivers$.pipe(untilDestroyed(this)).subscribe(v => {
      this._drivers$.next(
        v.items?.map(driver => ({ id: driver.id!, label: `${driver.surname} ${driver.name} ${driver.patronymic ?? ''}` })),
      );
      this.drivers = v.items?.map(driver => {
        return {
          fullName: `${driver.surname} ${driver.name} ${driver.patronymic ?? ''}`,
          id: driver.id,
          accrStatus: driver?.accrStatus,
        };
      });
      if (this.drivers?.length) {
        this.cdr.markForCheck();
        this.checkDriversChanges();
      }
    });

    this.vm.baseVm.organizations.pipe(untilDestroyed(this)).subscribe(v => {
      this.organizations = v?.map(org => setAgentCarrierData(org) as ICommonSelectData) ?? [];
      if (this.organizations?.length) {
        this.cdr.markForCheck();
      }
    });
    if (this.isAgentOrExpeditor) {
      this.agentCarrierInputSubject$
        .pipe(
          debounceTime(TIMEOUT.SHORT),
          tap(searchQuery => this.vm.baseVm.getAgentCarriers(searchQuery ?? '')),
          untilDestroyed(this),
        )
        .subscribe();
    }

    this.vm.form.controls.ownershipTractorType.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.ownershipTractorType = value?.id;
      this.tracktorCheckStateAndChangeValidation(this.isThirdPartyType ? '' : this.ownershipTractorType);

      if (value && value !== this.vm.form.controls.ownershipType.value) {
        this.disableOwnershipType = true;
        this.vm.form.controls.ownershipType.setValue(value);
        this.vm.form.controls.ownershipType.disable();
      }
    });
    this.vm.form.controls.ownershipTrailerType.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.ownershipTrailerType = value?.id;
      this.trailerCheckStateAndChangeValidation(this.isThirdPartyType ? '' : this.ownershipTrailerType);
    });

    this.modalAddTransportService.afterSaveDataDriver.subscribe(data => {
      // this.drivers.push({
      //   fullName: `${data.surname} ${data.name} ${data.patronymic ?? ''}`,
      //   id: data?.id,
      //   accrStatus: data?.accrStatus,
      // });

      const driver = {
        label: `${data.surname} ${data.name} ${data.patronymic ?? ''}`,
        id: data?.id,
      };
      this._drivers$.next([...this._drivers$.value, driver]);
      // Если находимся на субдомене или контракт с ГВ подписан, то после закрытия модального окна создания водителя устанавливаем созданного водителя в инпут
      // В противном случае после закрытия модального окна обновляем список водителей в селекте.
      if (this.isSubDomain) {
        this.vm.form.controls.driver.setValue(driver);
        // this.vm.form.controls.driver.setValue(String(data.id));
      } else {
        this.vm.baseVm.getDrivers();
      }
    });
  }

  searchAgentCarriers(search) {
    this.agentCarrierInputSubject$.next(search);
  }

  public ngOnDestroy() {
    this.vm.dispose();
  }

  public get vehiclePassport() {
    const country = this.vm.form.controls.country.value?.id;
    return country
    ? this.regExpConf.ngxMask.vehiclePassport[country.toLowerCase()]
    : this.regExpConf.ngxMask.vehiclePassport.ru;
  }

  public get registrationNumberPlaceholder() {
    const country = this.vm.form.controls.country.value?.id;
    return country
      ? this.registrationNumberPlaceholderConfig[country]
      : 'A 123 AA 123';
  }

  public get trailerNumberPlaceholder() {
    const country = this.vm.form.controls.country.value?.id;
    return country
      ? this.trailerNumberPlaceholderConfig[country]
      : 'АА1234 12';
  }

  public get loadingTypesForm() {
    return this.vm.form.controls.loadingTypes as FormGroup;
  }

  public get tractorStsForm() {
    return this.vm.form.controls.tractorSts as FormGroup;
  }

  public get trailerStsForm() {
    return this.vm.form.controls.trailerSts as FormGroup;
  }

  public get isThirdPartyType(): boolean {
    return this.vm.form.controls.isThirdParty.value;
  }

  public getTrailerTitle(vehicleType: string): string {
    return vehicleType === VEHICLE_TYPE.TRAILER
      ? 'shared.mp.vehicles.vehicle.title.semiTrailer'
      : 'shared.mp.vehicles.vehicle.title.trailer';
  }

  public resetDriver(): void {
    this.vm.form.controls.driver.reset();
  }

  public getDriverFullName(driver: IMarketplaceDriver): string {
    return driver?.fullName ?? '';
  }

  public sendDriverOnCheck(id: string): void {
    this.driverService.openModal(this.currentUserService.currentUserRole, id);
    this.driverService.dialogRef
      ?.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.vm.form.controls.driver.markAsPristine();
        this.vm.form.controls.driver.markAsUntouched();
        this.vm.baseVm.getDrivers();
      });
  }

  public selectDriver() {
    this.driverService.openModal(this.currentUserService.currentUserRole);
  }

  private checkDriversChanges(): void {
    this.drivers$ = this.vm.form.controls.driver.valueChanges.pipe(
      untilDestroyed(this),
      startWith(this.vm.form.controls.driver.value ?? ''),
      map(value => this.autocompliteDriverFilter(value?.fullName ?? value)),
    );
  }

  private autocompliteDriverFilter(value: string | null): IMarketplaceDriver[] {
    const filterValue = value ? value.toLowerCase() : '';
    return this.drivers?.filter(driver => driver?.fullName?.toLowerCase().includes(filterValue));
  }

  private checkStateAndChangeValidation(type: string): void {
    if (type === VEHICLE_TYPE.TRUCK) {
      this.vm.form.controls.trailerRegNumber.clearValidators();
      this.vm.form.controls.trailerRegNumber.updateValueAndValidity();
      this.vm.form.controls.trailerMake.clearValidators();
      this.vm.form.controls.trailerMake.updateValueAndValidity();

      this.vm.form.controls.ownershipTrailerType.clearValidators();
      this.trailerStsForm.controls.firstSide.clearValidators();
      this.trailerStsForm.controls.secondSide.clearValidators();
      this.trailerCheckStateAndChangeValidation('');
    } else {
      const value = this.vm.form.controls.trailerRegNumber?.value;
      this.vm.form.removeControl('trailerRegNumber');
      this.vm.form.addControl('trailerRegNumber', new FormControl(value, Validators.required));
      this.vm.form.controls.trailerMake.setValidators(Validators.required);

      if (!this.isSubDomain) {
        this.vm.form.controls.ownershipTrailerType.setValidators(Validators.required);
        this.trailerStsForm.controls.firstSide.setValidators(Validators.required);
        this.trailerStsForm.controls.secondSide.setValidators(Validators.required);
        this.trailerCheckStateAndChangeValidation(this.isThirdPartyType ? '' : this.ownershipTrailerType);
      }
    }
  }

  private clearTracktorFileSelectorsValidity() {
    this.vm.form.controls.tracktorLeasingAgreement.clearValidators();
    this.vm.form.controls.tracktorRentContract.clearValidators();
  }

  private clearTrailerFileSelectorsValidity() {
    this.vm.form.controls.trailerLeasingAgreement.clearValidators();
    this.vm.form.controls.trailerRentContract.clearValidators();
  }

  private tracktorCheckStateAndChangeValidation(ownershipType: VEHICLE_TYPE_OWNERSHIP | string): void {
    this.clearTracktorFileSelectorsValidity();
    switch (ownershipType) {
      case VEHICLE_TYPE_OWNERSHIP.LEASING:
        this.vm.form.controls.tracktorLeasingAgreement.setValidators(Validators.required);
        this.vm.form.controls.tracktorLeasingAgreement.updateValueAndValidity();
        break;

      case VEHICLE_TYPE_OWNERSHIP.RENT:
        this.vm.form.controls.tracktorRentContract.setValidators(Validators.required);
        this.vm.form.controls.tracktorRentContract.updateValueAndValidity();
        break;

      default:
        break;
    }
    this.tractorStsForm.controls.firstSide.updateValueAndValidity();
    this.tractorStsForm.controls.secondSide.updateValueAndValidity();
  }

  private trailerCheckStateAndChangeValidation(ownershipType: VEHICLE_TYPE_OWNERSHIP | string): void {
    this.clearTrailerFileSelectorsValidity();
    switch (ownershipType) {
      case VEHICLE_TYPE_OWNERSHIP.LEASING:
        this.vm.form.controls.trailerLeasingAgreement.setValidators(Validators.required);
        this.vm.form.controls.trailerLeasingAgreement.updateValueAndValidity();
        break;

      case VEHICLE_TYPE_OWNERSHIP.RENT:
        this.vm.form.controls.trailerRentContract.setValidators(Validators.required);
        this.vm.form.controls.trailerRentContract.updateValueAndValidity();
        break;

      default:
        break;
    }
    this.trailerStsForm.controls.firstSide.updateValueAndValidity();
    this.trailerStsForm.controls.secondSide.updateValueAndValidity();
  }

  private clearAccreditationValidators(): void {
    this.vm.form.controls.ownershipTrailerType.clearValidators();
    this.vm.form.controls.ownershipTractorType.clearValidators();
    this.tractorStsForm.controls.firstSide.clearValidators();
    this.tractorStsForm.controls.secondSide.clearValidators();
    this.trailerStsForm.controls.firstSide.clearValidators();
    this.trailerStsForm.controls.secondSide.clearValidators();
    this.clearTracktorFileSelectorsValidity();
    this.clearTrailerFileSelectorsValidity();
    this.vm.form.updateValueAndValidity();
  }

  private clearValidatorsIfThirdPartyType(): void {
    if (this.isThirdPartyType) {
      this.clearAccreditationValidators();
    } else {
      this.checkStateAndChangeValidation(this.vehicleType);
      if (!this.isSubDomain) {
        this.tractorStsForm.controls.firstSide.setValidators(Validators.required);
        this.tractorStsForm.controls.secondSide.setValidators(Validators.required);
      }
    }
  }

  public getDaDataPlaceAddress(value): Observable<Place[]> {
    return this.dadataService.getMapPointsByAddress({ address: value ?? '' }, COUNTER.FIVE).pipe(
      untilDestroyed(this),
      map(place => {
        const addresses = place
          ?.filter(el => el.cityWithType || el.settlementWithType)
          .map(el => {
            //фильтр возвращает c DaData массив с теми полями, по которым точно будет фильтрация заявок
            return {
              name: el.fullAddress,
              settlement: el.cityWithType || el.settlementWithType,
              ...el,
            };
          });
        return addresses;
      }),
    );
  }

  public validationErrors(e: ValidationErrors | null) {
    if (e?.required) return this.vm.translate.instant('shared.errors.required');
    if (e?.mask) return this.vm.translate.instant('shared.errors.carNumber');
    if (e?.trimmedString) return this.vm.translate.instant('shared.errors.trimmedString');
    if (e?.lessThanAdultAge) return this.vm.translate.instant('shared.errors.lessThanAdultAge');
    if (e?.moreThanNow) return this.vm.translate.instant('shared.errors.moreThanNow');
    if (Boolean(e?.max)) return this.vm.translate.instant('shared.errors.maxNumber', { value: e?.max?.max });
    if (Boolean(e?.min && !e?.required)) return this.vm.translate.instant('shared.errors.maxNumber', { value: e?.max?.max });
    if (e?.pattern) return this.vm.translate.instant('shared.errors.carNumber');

    return '';
  }
}
