import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

/**
 * Pipe for get comma separated string
 */
@Pipe({
  name: 'fio',
})
export class TnFioPipe implements PipeTransform {
  /**
   * Transform object with name, surname, patronymic to fio string
   * @param person Object
   */
  @memo({
    resolver: (...args: any[]): string => JSON.stringify(args),
  })
  public transform(
    person: {
      surname?: string | null;
      lastName?: string | null;
      name?: string | null;
      firstName?: string | null;
      patronymic?: string | null;
    },
    initials = false,
  ): string {
    if (!Boolean(person)) {
      return '';
    }
    const namePart = (str: string) => {
      return initials && Boolean(str) ? `${str[0]}.` : str;
    };
    return [
      person.surname,
      person.lastName,
      namePart(person.name ?? ''),
      namePart(person.firstName ?? ''),
      namePart(person.patronymic ?? ''),
    ]
      .filter(val => Boolean(val))
      .join(' ');
  }
}
