import { transport } from '@transport/proto';

import { DOCUMENT_TYPE } from './order-common.interface';

export class TnOrderDocumentTypes {
  public signType: transport.IOrderDocumentType;

  public readonly contractType: transport.IOrderDocumentType;

  public readonly otherTypes: transport.IOrderDocumentType[];

  constructor(allTypes: transport.IOrderDocumentType[]) {
    this.otherTypes = allTypes.filter(item => Boolean(item.isVisible));

    this.signType = allTypes.find(item => item?.innerName === DOCUMENT_TYPE.DIGIT_SIGN) ?? new transport.OrderDocumentType();

    this.contractType = allTypes.find(item => item?.innerName === DOCUMENT_TYPE.CONTRACT) ?? new transport.OrderDocumentType();
  }
}
