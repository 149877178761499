<button class="tw-menu-button" *ngIf="variant === 'button'" (click)="action.emit()">
  <ng-container *ngIf="text; else content">
    {{ text }}
  </ng-container>
</button>

<div [ngClass]="[class, variant === 'active' ? ' active' : '', size === null ? '!h-auto' : '']" *ngIf="variant !== 'button'">
  <ng-container *ngIf="text; else content">
    <div class="tw-menu-item-text" *ngIf="!additionalTextRight">
      <div class="tw-menu-item-text text-gray-500" *ngIf="additionalText">{{ additionalText }}</div>
      <div class="tw-menu-item-text">{{ text }}</div>
    </div>
    <div class="tw-menu-item-text flex gap-1" *ngIf="additionalTextRight">
      <span class="tw-menu-item-text">{{ text }}</span>
      <span class="tw-menu-item-text text-gray-500">{{ additionalTextRight }}</span>
    </div>
  </ng-container>
  <mat-icon *ngIf="variant === 'active'" svgIcon="complete2"></mat-icon>
</div>

<ng-template #content>
  <ng-content></ng-content>
</ng-template>
