import { NgModule, Provider } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TnOrderDetailsCarrierEffects } from './effects/order-details-carrier.effects';
import { TnOrderDetailsCarrierDocsEffects } from './effects/order-details-carrier-docs.effects';
import { TnOrderDetailsCarrierFlowEffects } from './effects/order-details-carrier-flow.effects';
import { TnOrderDetailsCarrierTnSignEffects } from './effects/order-details-carrier-tnsign.effects';
import { TnOrderRemoveEffects } from './effects/order-remove.effects';
import { TnOrdersTableEffects } from './effects/orders-table.effects';
import { TnOrderDetailsCarrierFacade } from './facades/order-details-carrier.facade';
import { TnOrdersTableCarrierFacade } from './facades/orders-table-carrier.facade';
import { TnOrdersTableOwnerFacade } from './facades/orders-table-owner.facade';
import { ordersTableFeatureName, reducers } from './orders-table.state';
import { TnCarrierOrdersService } from './services/carrier/carrier-order.service';
import { TnCommonOrdersService } from './services/common/common-order.service';
import { TnSignDocumentService } from './services/common/sign-document.service';
import { TnOwnerOrdersService } from './services/owner/owner-order.service';

export const ORDERS_TABLE_EFFECTS = [
  TnOrderDetailsCarrierEffects,
  TnOrderDetailsCarrierDocsEffects,
  TnOrderDetailsCarrierFlowEffects,
  TnOrderDetailsCarrierTnSignEffects,
  TnOrderRemoveEffects,
  TnOrdersTableEffects,
];

/**
 * @note testing only
 * This providers array should be used for testing only.
 */
export const tnOrdersTableStateModuleProviders: Provider[] = [
  TnOrdersTableCarrierFacade,
  TnOrdersTableOwnerFacade,
  TnOrderDetailsCarrierFacade,
  TnOrderDetailsCarrierEffects,
  TnOrderDetailsCarrierDocsEffects,
  TnOrderDetailsCarrierFlowEffects,
  TnOrderDetailsCarrierTnSignEffects,
  TnOrderRemoveEffects,
  TnOrdersTableEffects,
  TnCarrierOrdersService,
  TnOwnerOrdersService,
  TnCommonOrdersService,
  TnSignDocumentService,
];

@NgModule({
  imports: [StoreModule.forFeature(ordersTableFeatureName, reducers), EffectsModule.forFeature(ORDERS_TABLE_EFFECTS)],
  exports: [StoreModule, EffectsModule],
})
export class TnOrdersTableStateModule {}
