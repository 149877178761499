import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { transport } from '@transport/proto';
import { DOCUMENT_TYPE } from '@transport/ui-interfaces';
import { tap, withLatestFrom } from 'rxjs/operators';

import { ITnState } from '../../state/index';
import * as OrderDetailsCarrierActions from '../actions/order-details-carrier.actions';
import * as selectors from '../selectors/order-details-carrier.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnOrderDetailsCarrierTnSignEffects {
  public signTnCrypto = createEffect(
    () =>
      this.action$.pipe(
        ofType(OrderDetailsCarrierActions.signTnCrypto),
        withLatestFrom(
          this.store.pipe(
            select(selectors.tnCryptoOrderDetails.isTerminationArgeementInProgress, {
              docType: DOCUMENT_TYPE.TERMINATION_AGREEMENT,
            }),
          ),
          this.store.pipe(
            select(selectors.tnCryptoOrderDetails.getActualStatus, {
              docType: DOCUMENT_TYPE.TERMINATION_AGREEMENT,
            }),
          ),
          this.store.pipe(
            select(selectors.tnCryptoOrderDetails.getActualStatus, {
              docType: DOCUMENT_TYPE.CONTRACT,
            }),
          ),
        ),
        tap(([, isTerminationArgeementInProgress, actualStatusTermAgreement, actualStatusContract]) => {
          if (isTerminationArgeementInProgress) {
            window.open((actualStatusTermAgreement as transport.Order.IDocumentStatus)?.url ?? '', '_blank');
          } else {
            window.open((actualStatusContract as transport.Order.IDocumentStatus)?.url ?? '', '_blank');
          }
        }),
      ),
    { dispatch: false },
  );

  constructor(private readonly action$: Actions, private readonly store: Store<ITnState>) {}
}
