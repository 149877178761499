
export const scrollToErrorControl = () => {
  setTimeout(() => {
    const el = document.getElementsByClassName(`ng-invalid ng-touched`)?.[0];
    el?.scrollIntoView({block: 'center', behavior: 'smooth'});
  }, 100)
};

export const scrollToPointControl = (id) => {
  setTimeout(() => {
    const el = document.getElementsByClassName(`point-in-route-${id}`)?.[0];
    el?.scrollIntoView({block: 'center', behavior: 'smooth'});
  }, 100)
};

export function mapTemplateToLabel<T extends Record<string, any>>(name: string, tmpl?: T | null): string { // tmpl?: IVehicleRequirements
  if (!tmpl) return name;
  const bodySubtypes = `(${tmpl.loadingTypes.map(t => t.name).join(', ')})`;
  const capacity = tmpl.capacity ? tmpl?.capacity.toString() + 'т' : '';
  const volume = tmpl.volume ? tmpl.volume.toString() + 'м³' : '';
  return `${name} ${capacity} ${volume} ${tmpl.bodySubtypes.length ? bodySubtypes : ''}`;
};
