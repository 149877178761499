import { createAction, props } from '@ngrx/store';
import { transport } from '@transport/proto';

const loadAllPermissionsSuccess = createAction(
  '[AdminUsers/API] Load All Permissions Success',
  props<{ permissions: transport.User.Permission[] }>(),
);
const loadAllPermissionsFailure = createAction('[AdminUsers/API] Load All Permissions Failure', props<{ error: Error }>());

export const allPermissionsApiActions = {
  loadAllPermissionsSuccess,
  loadAllPermissionsFailure,
};
