import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  HostBinding,
  Inject,
  Input,
  OnInit,
  Optional,
} from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBar } from '@angular/material/snack-bar';

import { ALERT_STATE, ALERT_TITLE_VARIANT, ALERT_VARIANT, IAlertInputs } from './alert.interfaces';

@Component({
  selector: 'transport-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnAlertComponent implements OnInit, AfterViewInit {
  @Input() public titleVariant: 'top' | 'left' | 'none' = ALERT_TITLE_VARIANT.TOP;

  @Input() public title = 'Title';

  @Input() public state: 'info' | 'warning' | 'success' | 'error' = ALERT_STATE.INFO;

  @Input() public variant: 'left-accent' | 'solid' | 'subtle' = ALERT_VARIANT.LEFT_ACCENT;

  @Input() public message = '';

  @HostBinding('class') get classes() {
    return {
      [`state-${this.state}`]: true,
      [`variant-${this.variant}`]: true,
    };
  }

  constructor(
    private readonly _snackBar: MatSnackBar,
    private readonly cdr: ChangeDetectorRef,
    @Optional()
    @Inject(MAT_SNACK_BAR_DATA)
    private readonly snackData?: IAlertInputs | null,
  ) {}

  public ngOnInit() {
    if (this.snackData) {
      this.titleVariant = this.snackData.titleVariant ?? ALERT_TITLE_VARIANT.TOP;
      this.state = this.snackData.state ?? ALERT_STATE.INFO;
      this.variant = this.snackData.variant ?? ALERT_VARIANT.LEFT_ACCENT;
      this.message = this.getErrorFromMessage();
      let titleFromState = '';
      switch (this.state) {
        case ALERT_STATE.INFO:
          titleFromState = 'Информация';
          break;
        case ALERT_STATE.SUCCESS:
          titleFromState = 'Готово';
          break;
        case ALERT_STATE.ERROR:
          titleFromState = 'Ошибка';
          break;
        case ALERT_STATE.WARNING:
          titleFromState = 'Уведомление';
          break;
      }

      this.title = this.snackData.title ?? titleFromState;
    }
  }

  private getErrorFromMessage() {
    // сделать обработчик ошибок
    const message = this.snackData?.message ?? '';
    // if (message.includes('401')) {
    //   return 'Время сессии истекло, авторизуйтесь, пожалуйста, заново'; // перенести в транслейт
    // }
    return message;
  }

  public ngAfterViewInit() {
    this.cdr.markForCheck();
  }

  public onCloseButtonClick() {
    this._snackBar.dismiss();
  }
}
