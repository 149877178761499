import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { TnOwnerDirectoryCarriersApiService } from '../carriers-table/owner-directory-carriers-api.service';
import { loadCarrierFilterList } from './actions/carrier-filter-list.actions';
import { ICarrierFilterListState } from './reducers/carrier-filter-list.reducer';
import { getCarriersList } from './selectors/carrier-filter-list.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnCarrierFilterListFacade {
  constructor(
    protected store: Store<ICarrierFilterListState>,
    private readonly ownerDirectoryCarriersService: TnOwnerDirectoryCarriersApiService,
  ) {}

  public get carriersList$() {
    return this.store.select(getCarriersList);
  }

  public sendCarriers() {
    this.store.dispatch(loadCarrierFilterList());
  }

  public getCarrierOrganizations() {
    return this.ownerDirectoryCarriersService.getCarrierOrganizationsFilterList();
  }
}
