import { AfterViewInit, Directive, ElementRef, EventEmitter, Output, Renderer2 } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive({
  selector: '[transportTableColumnReorder]',
})
export class TnColumnReorderDirective implements AfterViewInit {
  @Output() public readonly reorder = new EventEmitter();

  @Output() public readonly dragColumnStart = new EventEmitter();

  @Output() public readonly dragColumnFinish = new EventEmitter();

  private isDragStarted = false;

  private readonly listeners: VoidFunction[] = [];

  private draggableColumn: HTMLElement | null = null;

  private currentDroppable: Element | null = null;

  private draggingColumnIndex = -1;

  private dropColumnIndex = -1;

  private pageOffset = { x: 0, y: 0 };

  private get tableElement() {
    return this.tableRef.nativeElement;
  }

  private get headerElement() {
    return this.tableElement.querySelector('thead');
  }

  private get bodyElement() {
    return this.tableElement.querySelector('tbody');
  }

  private get rowElements() {
    return Array.from(this.tableElement?.querySelectorAll('tbody tr') ?? []);
  }

  private get firstDraggableColumnIndex() {
    const firstDragAnchorElement = this.dragAnchorElements[0];
    return this.headerColumnElements.findIndex(headerColumn => headerColumn.querySelector('.drag-anchor') === firstDragAnchorElement);
  }

  private get headerColumnElements() {
    return Array.from(this.tableElement?.querySelectorAll('th') ?? []);
  }

  private get dragAnchorElements() {
    return Array.from(this.tableElement?.querySelectorAll('.drag-anchor') ?? []);
  }

  constructor(private readonly renderer: Renderer2, private readonly tableRef: ElementRef<HTMLElement>) {}

  public ngAfterViewInit() {
    setTimeout(() => this.bindListeners());
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList') {
          this.bindListeners();
        }
      });
    });
    const config = { childList: true };
    observer.observe(this.headerElement as Node, config);

    if (this.tableElement !== null) {
      this.setPageOffset(this.tableElement.getBoundingClientRect());

      this.tableElement.closest('div[class=transport-table-wrapper], div[class=scrollable-container]')?.addEventListener('scroll', () => {
        this.setPageOffset(this.tableElement.getBoundingClientRect());
      });
    }
  }

  private setPageOffset(boundingRect) {
    this.pageOffset = boundingRect as { x: number; y: number };
  }

  public bindListeners() {
    if (this.isDragStarted) {
      return;
    }
    this.unbindListeners();
    this.dragAnchorElements.forEach(element => {
      this.listeners.push(this.renderer.listen(element, 'pointerdown', this.onDragStart.bind(this)));
    });
  }

  private unbindListeners() {
    this.listeners.forEach(listener => listener());
    this.listeners.splice(0, this.listeners.length);
  }

  private onDragStart(event: PointerEvent) {
    event.stopPropagation();
    event.preventDefault();
    this.headerColumnElements.forEach(th => (th.style.touchAction = 'none'));
    this.draggingColumnIndex = this.dragAnchorElements.findIndex(element => element === event.target);
    this.dropColumnIndex = this.draggingColumnIndex;
    const firstDraggableColumnIndex = this.firstDraggableColumnIndex;
    const { x, y } = this.getColumnPosition(event);
    this.draggableColumn = this.getCloneOfColumn(this.draggingColumnIndex + firstDraggableColumnIndex, { x, y });
    this.tableElement.parentNode?.appendChild(this.draggableColumn);
    this.hideOriginalColumn(this.draggingColumnIndex + firstDraggableColumnIndex);

    this.dragColumnStart.emit();
    this.isDragStarted = true;

    this.listeners.push(this.renderer.listen('document', 'pointermove', this.onDragging.bind(this)));

    this.listeners.push(this.renderer.listen('document', 'pointerup', this.onDragFinish.bind(this)));
  }

  private onDragging(event: PointerEvent) {
    if (!this.isDragStarted) {
      this.isDragStarted = true;
    } else {
      this.tableElement.classList.add('column-dragging');
      const { x, y } = this.getColumnPosition(event);
      this.styleDraggableColumn(this.draggableColumn as HTMLElement, { x, y });

      const OFFSET = 3;
      const elemBelow = document.elementFromPoint(event.clientX - OFFSET, event.clientY - OFFSET);
      if (!elemBelow) {
        return;
      }

      const droppableBelow = elemBelow.closest('th:not(.draggable)');
      if (this.currentDroppable !== droppableBelow && this.isDragStarted) {
        if (this.currentDroppable) {
          (this.currentDroppable as HTMLElement).style.borderLeft = '';
        }
        this.currentDroppable = droppableBelow;
        if (this.currentDroppable) {
          (droppableBelow as HTMLElement).style.borderLeft = '1px solid #3d4460';
          this.dropColumnIndex =
            this.headerColumnElements.findIndex(headerColumn => headerColumn === droppableBelow) - this.firstDraggableColumnIndex;
        }
      }
    }
  }

  private getColumnPosition(event: PointerEvent): { x: number; y: number } {
    return { x: event.pageX - this.pageOffset.x, y: event.pageY - this.pageOffset.y };
  }

  private onDragFinish(event: PointerEvent) {
    this.isDragStarted = false;
    this.unbindListeners();
    const parentHeaderCell = this.getParent(event.target as HTMLElement, 'TH');
    if (Boolean(parentHeaderCell)) {
      parentHeaderCell.style.borderLeft = '';
      if (this.dropColumnIndex !== this.draggingColumnIndex && this.dropColumnIndex !== -1 && this.draggingColumnIndex !== -1) {
        this.reorder.emit({ from: this.draggingColumnIndex, to: this.dropColumnIndex - 1 });
      } else {
        this.bindListeners();
      }
    }

    this.draggableColumn?.remove();
    this.dragColumnFinish.emit();
    const tableElement = this.tableElement;
    tableElement.classList.remove('column-dragging');
    tableElement.querySelectorAll('.reorder-temp').forEach(item => item.remove());
  }

  private getCloneOfColumn(columnIndex: number, { x, y }: { x: number; y: number }) {
    const tableElement = this.tableElement;

    const cloneOfHeaderElement = this.headerElement?.cloneNode() as HTMLElement;
    const originalHeaderCell = this.headerColumnElements[columnIndex];
    const clonedHeaderCell = originalHeaderCell.cloneNode(true) as HTMLElement;
    clonedHeaderCell.classList.remove('mat-table-sticky');
    clonedHeaderCell.classList.add('draggable', 'dragging');
    cloneOfHeaderElement.appendChild(clonedHeaderCell);

    const cloneOfBodyElement = this.bodyElement?.cloneNode() as HTMLElement;
    this.rowElements.forEach(row => {
      const clonedRow = row.cloneNode();
      const clonedRowCell = row.querySelectorAll('td').item(columnIndex).cloneNode(true);
      (clonedRowCell as HTMLElement).classList.add('dragging');
      clonedRow.appendChild(clonedRowCell);
      cloneOfBodyElement.appendChild(clonedRow);
    });

    const cloneOfColumn = tableElement.cloneNode() as HTMLElement;
    cloneOfColumn.appendChild(cloneOfHeaderElement);
    cloneOfColumn.appendChild(cloneOfBodyElement);
    cloneOfColumn.style.width = `${parseInt(window.getComputedStyle(originalHeaderCell).width, 10)}px`;
    this.styleDraggableColumn(cloneOfColumn, { x, y });
    cloneOfColumn.classList.add('reorder-temp');
    return cloneOfColumn;
  }

  private styleDraggableColumn(column: HTMLElement, { x, y }: { x: number; y: number }) {
    column.style.position = 'absolute';
    column.style.left = `${x}px`;
    column.style.top = `${y}px`;
    column.style.backgroundColor = '#FFF';
    column.style.zIndex = '10000';
    column.style.cursor = 'grabbing';
    column.style.pointerEvents = 'none';
  }

  private getParent(element: HTMLElement, parentNodeName: string) {
    let parentElement: HTMLElement = element;
    while (Boolean(parentElement) && parentElement.nodeName !== parentNodeName) {
      parentElement = parentElement.parentNode as HTMLElement;
    }
    return parentElement;
  }

  private hideOriginalColumn(columnIndex: number) {
    const originalHeaderCell = this.headerColumnElements[columnIndex];
    const { width, height } = originalHeaderCell.getBoundingClientRect();
    let hideElement = document.createElement('div');
    originalHeaderCell.style.position = 'relative';
    this.styleHideElement(hideElement, width - 1, height);
    hideElement.style.borderTopWidth = '1px';
    hideElement.classList.add('reorder-temp');
    originalHeaderCell.appendChild(hideElement);

    let originalRowCellDimensions: DOMRect;
    const rowElements = this.rowElements;
    rowElements.forEach((rowElement, index) => {
      const originalRowCell = rowElement.querySelectorAll('td').item(columnIndex);
      if (index === 0) {
        originalRowCellDimensions = originalRowCell.getBoundingClientRect();
      }
      hideElement = document.createElement('div');
      originalRowCell.style.position = 'relative';
      this.styleHideElement(hideElement, originalRowCellDimensions.width - 1, originalRowCellDimensions.height);
      if (index === rowElements.length - 1) {
        hideElement.style.borderBottomWidth = '1px';
      }
      hideElement.classList.add('reorder-temp');
      originalRowCell.appendChild(hideElement);
    });
  }

  private styleHideElement(hideEl: HTMLElement, width, height) {
    hideEl.style.width = `${width}px`;
    hideEl.style.height = `${height}px`;
    hideEl.style.position = 'absolute';
    hideEl.style.top = '0px';
    hideEl.style.left = '0px';
    hideEl.style.boxSizing = 'border-box';
    hideEl.style.backgroundColor = '#eef5fd';
    hideEl.style.borderStyle = 'dashed';
    hideEl.style.borderColor = '#90CAF9';
    hideEl.style.borderTopWidth = '0px';
    hideEl.style.borderBottomWidth = '0px';
    hideEl.style.borderLeftWidth = '1px';
    hideEl.style.borderRightWidth = '1px';
  }
}
