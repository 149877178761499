import { gql } from 'apollo-angular';

export const FILTERS_TOOLBAR_QUERIES = {
  counter: gql`
    query VehicleStatusCounter {
      vehicleStatusCounters {
        all
        notArchived
        archived
      }
    }
  `
};
