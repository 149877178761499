import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

const MINUTES_IN_HOUR = 60;
const TEN = 10;
@Pipe({
  name: 'timeDuration',
})
export class TnTimeDurationPipe implements PipeTransform {
  /**
   * @param value count minutes
   * @returns time format hh:mm
   */
  @memo()
  public transform(minutes: number): string {
    if (!Boolean(minutes) || minutes < 0) {
      return '';
    }
    const hours = Math.floor(minutes / MINUTES_IN_HOUR);
    const minutesLeft = minutes % MINUTES_IN_HOUR;
    return `${hours < TEN ? '0' : ''}${hours}:${minutesLeft < TEN ? '0' : ''}${minutesLeft}`;
  }
}


export function getTimezoneForMoment(timezone: string = 'UTC+0') {
  let utc = '+0000';
  if (timezone) {
    utc = timezone.replace('UTC+', '');
    utc = utc.length === 1 ? `+0${utc}00` : `+${utc}00`;
  } else {
    console.error('нет таймзоны у мест погрузки разгрузки');
  }
  return utc
}