export interface IConstantConfig {
  default: string;
  description: string;
  value: string;
}

/**
 * @note
 * api weirdness. suddenly the UPPERCASE.
 */
export interface IConstantsApiResponse {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: tech debt
  SUPPORT_EMAIL: IConstantConfig;
  // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: tech debt
  SUPPORT_PHONE_NUMBER: IConstantConfig;
}

export type TStateRelationship = 'NOT_ACCREDITED' | 'ON_ACCREDITATION' | 'ACCREDITED';
export enum STATE_RELATIONSHIP {
  NOT_ACCREDITED = 'NOT_ACCREDITED', //(в случае отказа в аккредитации)
  ON_ACCREDITATION = 'ON_ACCREDITATION', //(в случае отправки на аккредитацию)
  ACCREDITED = 'ACCREDITED', //(в случае подтверждения аккредитации)
  CONTRACT_IS_SIGNED = 'CONTRACT_IS_SIGNED', //(контракт подписан)
}
