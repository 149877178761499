import { createAction, props } from '@ngrx/store';
import { IUser } from '@transport/ui-interfaces';

const loadUserSuccess = createAction('[AdminUser/API] Load User Success', props<{ user: IUser | null }>());

const loadUserFailure = createAction('[AdminUser/API] Load User Failure', props<{ error: Error }>());

const saveUserSuccess = createAction('[AdminUser/API] Save User Success', props<{ user: Partial<IUser> }>());
const saveUserFailure = createAction('[AdminUser/API] Save User Failure', props<{ error: Error }>());

const inviteUserSuccess = createAction('[AdminUser/API] Invite User Success', props<{ user: Partial<IUser> }>());
const inviteUserFailure = createAction('[AdminUser/API] Invite User Failure', props<{ error: Error }>());

const removeUserSuccess = createAction('[AdminUser/API] Remove User Success', props<{ user: IUser }>());
const removeUserFailure = createAction('[AdminUser/API] Remove User Failure', props<{ error: Error }>());

const resetPasswordSuccess = createAction('[AdminUser/API] Reset user password success', props<{ user: Partial<IUser> }>());

const resetPasswordFailure = createAction('[AdminUser/API] Reset user password failure', props<{ error: Error }>());

export const userApiActions = {
  loadUserSuccess,
  loadUserFailure,
  saveUserSuccess,
  saveUserFailure,
  inviteUserSuccess,
  inviteUserFailure,
  removeUserSuccess,
  removeUserFailure,
  resetPasswordSuccess,
  resetPasswordFailure,
};
