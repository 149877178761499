<div class="!flex p-4">
  <div class="flex justify-between gap-2 w-full">
    <strong class="flex text-lg">{{ textData.title | translate }}</strong>
    <button class="h-6" mat-dialog-close data-test="close-button" (click)="cancel()">
      <mat-icon class="close-icon rounded-full text-gray-400 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200">close</mat-icon>
    </button>
  </div>
</div>

<div *ngIf="!textData.markdown" class="mb-0 px-4 pb-4">
  {{ textData.content | translate }}
</div>
<div *ngIf="textData.markdown" class="markdown mb-0 px-4 pb-4">
  <markdown [data]="textData.content"></markdown>
</div>

<div class="!flex !justify-end !items-center px-4 pb-4">
  <button mat-button (click)="cancel()" data-test="comfirm-cancel-btn">
    {{ textData.cancel | translate }}
  </button>
  <button mat-raised-button class="!text-white" color="primary" (click)="confirm()" data-test="comfirm-btn" cdkFocusInitial>
    {{ textData.confirm | translate }}
  </button>
</div>
