import { Injectable } from '@angular/core';
import { map, take } from 'rxjs/operators';

import { TnGqlClientOwnerQueriesService } from '../../../gql-client-owner/graphql-client-owner-queries.service';

@Injectable({
  providedIn: 'root',
})
export class TnOwnerDirectoryLoadingPlacesApiService {
  constructor(private readonly graphQL: TnGqlClientOwnerQueriesService) {}

  /**
   * get all loading types request
   */
  public getLoadingtypes() {
    return this.graphQL.queryLoadingTypesList().pipe(
      take(1),
      map(data => {
        return {
          loadingTypes: data,
        };
      }),
    );
  }
}
