import { transport } from '@transport/proto';

import { IOrder, ORDER_STATUS } from '../order';
import { ROUTE_SHEET_STATUS } from '../route-sheet';

export interface IGqlOrderResponse {
  order: IOrder;
}

export interface IGqlOrderContractPartResponse {
  order: {
    status: ORDER_STATUS;
    uploadedDocuments?: transport.IOrderUploadedDocument[] | null;
    documentActualStatus?: transport.Order.IDocumentStatus[] | null;
  };
}

export interface IGqlOrdersResponse {
  orders: IOrder[];
  totalCount?: number;
}

export interface IMyOrderResponse {
  id: string | null;
  status: ORDER_STATUS;
  loadingDate: string;
  unloadingDate: string;
}

export interface IMyOrdersResponse {
  orders: IMyOrderResponse[];
}

export interface IGqlOwnerOrdersAmount {
  free: number;
  taken: number;
  onWay: number;
  completed: number;
  canceled: number;
  pending: number;
  onSign: number;
  assigned: number;
  reserved: number;
}

export interface IGqlBiddingOrdersResponse {
  biddingOrders: IOrder[];
  totalCount?: number;
}

export interface IGqlCarrierRatingReportDataResponse {
  carrierRatingReports: {
    id: string;
    startDate: string;
    endDate: string;
    indicator: string;
    department: string;
    deliveryArea: string;
    totalDeliveriesCompleted: number;
    assignedDeliveryRequests: number;
    assignedDeliveryRequestsCompleted: number;
    vacantDeliveryRequestsCompleted: number;
    assignedDeliveryRequestsRefused: number;
    deliveryContractsRefused: number;
    carrierFaultCanceledShipments: number;
    vehicleSearchAverageTime: number;
    refusedRequestsPercentage: number;
    carrierFaultDelayedShipmentsPercentage: number;
    vacantRequestsDeliveriesPercentage: number;
    currentRating: string;
    calculatedRating: string;
  }[];
  carrierRatingReportsCount?: number;
}

export interface IGqlCarrierRatingDetailsReportDataResponse {
  carrierRatingReportDetails: {
    id: string;
    deliveryRequestNumber: string;
    deliveryRequestDateUtc: string;
    deliveryContractNumber: string;
    deliveryContractDateUtc: string;
    deliveryRequestType: string;
    deliveryCompleted: boolean;
    deliveryContractRefused: boolean;
    carrierFaultCanceledShipment: boolean;
    carrierFaultDelayedShipment: boolean;
  }[];
  carrierRatingReportDetailsCount?: number;
}

export interface IGqlDraftsResponse {
  drafts: transport.IDraft[];
  totalCount?: number;
}

export interface IGqlDraftResponse {
  draft: transport.IDraft;
}

export interface IGqlMyOrdersResponse {
  myOrders: IOrder[];
  totalCount?: number;
}

export interface IGqlAuctionOrderResponse {
  auctionOrder: IOrder;
}

export interface IGqlBiddingOrderResponse {
  biddingOrder: IOrder;
}

export interface IGqlAuctionOrdersResponse {
  auctionOrders: IOrder[];
  totalCount?: number;
}

export interface IGqlMyCarrierAccreditationResponse {
  carrierOrganizations: transport.Carrier.ICarrierAccreditationServer[];
}

export interface IGqlMyCarrierGroupsResponse {
  myCarrierGroups: transport.Carrier.ICarrierGroup[];
  totalCount?: number;
}

export interface IGqlContactsAtPlacesResponse {
  contacts: transport.IContact[];
}

export interface IGqlDepartmentsResponse {
  carrierRatingDepartments: { department: string }[];
}

export enum BUSINESS_RELATIONSHIP {
  ON_ACCREDITATION = 'ON_ACCREDITATION',
  ACCREDITED = 'ACCREDITED',
  NOT_ACCREDITED = 'NOT_ACCREDITED',
  CONTRACT_IS_SIGNED = 'CONTRACT_IS_SIGNED',
}

export interface IGqlAccrStatusBySubdomainResponse {
  cargoOwnerOrganization: { carrierBusinessRelationshipState: keyof typeof BUSINESS_RELATIONSHIP };
}

export interface IGqlUploadFileResponse {
  uploadFile: transport.IUploadedFile;
}

export interface IGqlGetOrderDocumentTypesResponse {
  orderDocumentTypes: transport.IOrderDocumentType[];
}

export interface IGqlInsuranceContractResponse {
  myInsuranceContracts: transport.Insurance.Contract[];
  totalCount?: number;
}

export interface IGqlEditOrderUploadedDocumentsResponse {
  editOrderUploadedDocuments: transport.IOrderUploadedDocument[];
}

export interface IGqlAddWaybillResponse {
  editOrderStoragePoint: IOrder;
  editOrderUploadedDocuments: transport.IOrderUploadedDocument[];
}

export interface IGqlGetTimezonesResponse {
  timezones: transport.ITimezone[];
}

export interface IGqlRouteSheetEventsResponse {
  routeSheetEvents: transport.IRouteSheetEvent;
}

export interface IGqlOrderRouteSheetResponse {
  orderRouteSheet: {
    id: string;
    status: ROUTE_SHEET_STATUS;
    order: transport.IOrder;
    document: transport.IEventAttachedDoc;
  };
}

export interface IGqlAllOwnerSlaResponse {
  allOrganizationSla: transport.ISLA[];
}

export interface IGqlSlaCriteriaResponse {
  slaCriteria: transport.SLA.ICriterion[];
}

export interface IGqlSlaResponse {
  sla: transport.ISLA;
}
