import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { transport } from '@transport/proto';
import { IUser, VEHICLE_TYPE_PROPERTY } from '@transport/ui-interfaces';

export interface ICarrierSelection {
  dispatcher: string;
  actualCarrierOrganization?: string | null;
  contractBetweenCarrierOwner?: string | null;
  typeProperty?: VEHICLE_TYPE_PROPERTY | null;
}

export function createCarrierSelectionFormGroup(
  companyName: string,
  contractName: string | null,
  canChangeContract: boolean,
): FormGroup<ICarrierSelection> {
  return new FormGroup<ICarrierSelection>({
    dispatcher: new FormControl({
      value: companyName,
      disabled: true,
    }),
    actualCarrierOrganization: new FormControl(null),
    contractBetweenCarrierOwner: new FormControl({ value: contractName, disabled: !canChangeContract }, Validators.required),
    typeProperty: new FormControl({ value: null, disabled: true }),
  });
}

export function getAgentCarrierId(
  currentUserInfo: IUser,
  selectedObject?: { agentCarrier?: transport.IAgentCarrier | null } | null,
  isAgentWorkScheme?: boolean,
) {
  if (Boolean(isAgentWorkScheme) && Boolean(selectedObject)) {
    const { agentCarrier } = selectedObject as { agentCarrier: transport.IAgentCarrier };
    return Boolean(agentCarrier) ? agentCarrier?.id : currentUserInfo.organization?.id ?? null;
  }
  return null;
}
