/* eslint-disable @typescript-eslint/naming-convention */
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TnHttpHandlersService } from '@transport/ui-store';
import { catchError, map, Observable, of } from 'rxjs';

import { IMonitoringSettings, IMonitoringSettingsForRequest, IRoutingSettings, IRoutingSettingsForRequest } from './interfaces';
import { ICommonSelectData } from 'libs/custom-controls/src/lib/common.interface';
import { RECOVERY_CARGO_AFTER_DELETE, OPTIMIZATION_CRITERION_CHOICES } from '@transport/ui-interfaces';

const defaultRoutingSettings = {
  averageSpeed: 50,
  averageLoadingDuration: 0,
  averageUnloadingDuration: 0,
  cargoPriceRequired: false,
  addressWarningLevel: null,
  optimizationCriterion: OPTIMIZATION_CRITERION_CHOICES.ROUTE_DURATION,
  recoveryCargoAfterDelete: RECOVERY_CARGO_AFTER_DELETE.DELETE
};

const defaultMonitoringSettings = {
  coordinatesUpdatePeriod: 180,
  maxDistanceToPoint: 0,
  canChangePointsOrder: false,
  isWaybillRequired: false,
  notifyLocInterval: 0,
  allowedOutOfRadarPeriod: 0,
  trackBeforeFirstPoint: false,
  autoComplete: false,
  radiusForAutoComplete: 50,
  allowedNonActivePeriod: null,
  minPeriodOnPointAuto: 1,
};

@UntilDestroy()
@Injectable({ providedIn: 'root' })
export class TnSettingsService {
  public routingSettings: IRoutingSettings = defaultRoutingSettings;
  public monitoringSettings: IMonitoringSettings = defaultMonitoringSettings;

  public availableModules: string[] = [];

  private readonly endpoints = {
    getSettings: (): string => this.httpHandlerService.getEndpointUrl('p/org/settings/'),
    getModules: (): string => this.httpHandlerService.getEndpointUrl('p/org/settings/modules'),
    getCargoOwnerModules: (): string => this.httpHandlerService.getEndpointUrl('p/org/cargo-owner/settings/modules/'),
    getMonitoringSettings: (): string => this.httpHandlerService.getEndpointUrl('/p/org/settings/monitoring'),
    updateMonitoringSettings: (): string => this.httpHandlerService.getEndpointUrl('/p/org/settings/monitoring'),
    getRoutingSettings: (): string => this.httpHandlerService.getEndpointUrl('p/org/settings/routing'),
    updateRoutingSettings: (): string => this.httpHandlerService.getEndpointUrl('p/org/settings/routing'),
    updateCargoPriceRequiredSetting: (): string => this.httpHandlerService.getEndpointUrl('p/org/settings/routing/cargo_price_required'),
  };

  constructor(private readonly http: HttpClient, private readonly httpHandlerService: TnHttpHandlersService) {}

  public getSettings(): Observable<any> {
    return this.http.get<any>(this.endpoints.getSettings());
  }

  public getRoutingSettings(): Observable<IRoutingSettings> {
    return this.http.get<IRoutingSettingsForRequest | null>(this.endpoints.getRoutingSettings()).pipe(
      map(value => (this.routingSettings = value ? this.mapRoutingSettingsToView(value) : defaultRoutingSettings)),
      catchError(error => of(error)),
    );
  }

  public getMonitoringSettings(): Observable<IMonitoringSettings> {
    return this.http.get<IMonitoringSettingsForRequest | null>(this.endpoints.getMonitoringSettings()).pipe(
      map(value => (this.monitoringSettings = value ? this.mapMonitoringSettingsToView(value) : defaultMonitoringSettings)),
      catchError(error => of(error)),
    );
  }

  public getModules(): Observable<string[]> {
    return this.http.get<string[]>(this.endpoints.getModules()).pipe(
      map(value => (this.availableModules = value)),
      catchError(error => of(error)),
    );
  }

  public getCargoOwnerModules(): Observable<string[]> {
    return this.http.get<string[]>(this.endpoints.getCargoOwnerModules()).pipe(
      map(value => (this.availableModules = value)),
      catchError(error => of(error)),
    );
  }

  public updateRoutingsSetting(value: IRoutingSettings) {
    return this.http.put(this.endpoints.updateRoutingSettings(), this.mapRoutingSettingsToRequest(value));
  }

  public updateMonitoringSettings(value: IMonitoringSettings) {
    return this.http.put(this.endpoints.updateMonitoringSettings(), this.mapMonitoringSettingsToRequest(value));
  }

  public updateCargoPriceRequiredSetting(value: boolean) {
    return this.http.put(this.endpoints.updateCargoPriceRequiredSetting(), value);
  }

  private mapMonitoringSettingsToView(settings: IMonitoringSettingsForRequest): IMonitoringSettings {
    return {
      coordinatesUpdatePeriod: this.numOrNull(settings.coordinates_update_period),
      maxDistanceToPoint: this.numOrNull(settings.max_distance_to_point),
      canChangePointsOrder: settings.can_change_points_order,
      isWaybillRequired: settings.is_waybill_required,
      notifyLocInterval: this.numOrNull(settings.notify_loc_interval),
      allowedOutOfRadarPeriod: this.numOrNull(settings.allowed_out_of_radar_period),
      trackBeforeFirstPoint: settings.track_before_first_point,
      autoComplete: settings.auto_complete,
      radiusForAutoComplete: this.numOrNull(settings.radius_for_auto_complete),
      allowedNonActivePeriod: this.numOrNull(settings.allowed_non_active_period),
      minPeriodOnPointAuto: settings.min_period_on_point_auto ?? 1,
    };
  }

  private mapMonitoringSettingsToRequest(settings: IMonitoringSettings): IMonitoringSettingsForRequest {
    return {
      coordinates_update_period: this.numOrNull(settings.coordinatesUpdatePeriod),
      max_distance_to_point: this.numOrNull(settings.maxDistanceToPoint),
      can_change_points_order: settings.canChangePointsOrder,
      is_waybill_required: settings.isWaybillRequired,
      notify_loc_interval: this.numOrNull(settings.notifyLocInterval),
      allowed_out_of_radar_period: this.numOrNull(settings.allowedOutOfRadarPeriod),
      track_before_first_point: settings.trackBeforeFirstPoint,
      auto_complete: settings.autoComplete,
      radius_for_auto_complete: this.numOrNull(settings.radiusForAutoComplete),
      allowed_non_active_period: this.numOrNull(settings.allowedNonActivePeriod),
      min_period_on_point_auto: settings.minPeriodOnPointAuto ? Number(settings.minPeriodOnPointAuto) : 1,
    };
  }

  private mapRoutingSettingsToView(settings: IRoutingSettingsForRequest) {
    return {
      averageSpeed: settings.avg_speed,
      averageLoadingDuration: settings.avg_loading_duration,
      averageUnloadingDuration: settings.avg_unloading_duration,
      cargoPriceRequired: settings.cargo_price_required,
      addressWarningLevel: settings.address_warning_level,
      optimizationCriterion: settings.optimization_criterion ?? OPTIMIZATION_CRITERION_CHOICES.ROUTE_DURATION,
      recoveryCargoAfterDelete: settings.recovery_cargo_after_delete ? 1 : 0,
    };
  }

  private mapRoutingSettingsToRequest(routingSettings: IRoutingSettings) {
    return {
      avg_speed: routingSettings.averageSpeed,
      avg_loading_duration: routingSettings.averageLoadingDuration,
      avg_unloading_duration: routingSettings.averageUnloadingDuration,
      cargo_price_required: routingSettings.cargoPriceRequired,
      address_warning_level: (routingSettings.addressWarningLevel as ICommonSelectData)?.id,
      optimization_criterion: (routingSettings.optimizationCriterion as ICommonSelectData)?.id,
      recovery_cargo_after_delete: Boolean((routingSettings.recoveryCargoAfterDelete as ICommonSelectData)?.id),
    };
  }

  private numOrNull(value: number | null) {
    return Number(value) && Number(value) > 0 ? value : null;
  }
}
