<ng-container *ngIf="price === null || price === undefined; then noPrice; else withPrice"></ng-container>
<ng-template #noPrice>
  <marketplace-text-column
     title="{{ 'carrier.directory.vehiclePublications.priceColumn.noPrice.header' | translate }}"
     titleClass="font-bold"
     text="{{ 'carrier.directory.vehiclePublications.priceColumn.noPrice.details' | translate }}"
  >
  </marketplace-text-column>
</ng-template>

<ng-template #withPrice>
  <marketplace-text-column
    title="{{
      price | currencyMulti : currency : false : (
        priceForDistance ? ('carrier.directory.vehiclePublications.priceColumn.priceForDistance' | translate) : ''
      )
    }}"
    titleClass="font-bold"
    text="{{ (isVatIncluded ? 'carrier.order.field.withVat' : 'carrier.order.field.withoutVat') | translate }}"
  >
  </marketplace-text-column>
</ng-template>

