<form [formGroup]="$any(form)" class="formWayBill" transportConnectForm="wayBill" [clearFormStateOnDestroy]="true">
  <mat-form-field data-test="unloadingPlace">
    <ng-select
      appendTo="body"
      transportNgSelectMat
      [items]="$any(storagePoints$ | async)"
      [formControl]="$any(form.controls.storagePoint)"
      bindLabel="name"
      [clearable]="false"
      [required]="isWaybillRequired"
      placeholder="{{ 'shared.fileUploadDialog.formWayBill.selectUnloadingPlace' | translate }}"
    >
    </ng-select>
    <mat-error *ngIf="form?.controls?.storagePoint?.errors?.required">{{
      'shared.errors.required' | translate
    }}</mat-error>
  </mat-form-field>

  <ng-container *ngIf="isDateControlsVisible">
    <section class="date-time flex flex-col gap-3">
      <mat-form-field class="date" data-test="unloadingDateFrom">
        <input
          matInput
          [required]="isWaybillRequired"
          [matDatepicker]="pickerFrom"
          (focus)="pickerFrom.open()"
          placeholder="{{ 'shared.fileUploadDialog.formWayBill.unloadingDateFrom' | translate }}"
          [formControl]="$any(form.controls.dateFrom)"
          autocomplete="off"
        />
        <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
        <mat-datepicker #pickerFrom></mat-datepicker>
        <mat-error *ngIf="form?.controls?.dateFrom?.errors?.required">
          {{'shared.errors.required' | translate}}
        </mat-error>
        <mat-error *ngIf="form?.controls?.dateFrom?.errors?.maxOffset">
          {{ 'shared.fileUploadDialog.formWayBill.errors.fromDateMaxOffset' | translate }}
        </mat-error>
        <mat-error *ngIf="form?.controls?.dateFrom?.errors?.lessPlanned">
          {{ 'shared.fileUploadDialog.formWayBill.errors.fromDateLessPlanned' | translate }}
        </mat-error>
        <mat-error *ngIf="form?.controls?.dateFrom?.errors?.dateFromLessLoadingDate">
          {{ 'shared.fileUploadDialog.formWayBill.errors.dateFromLessLoadingDate' | translate }}
        </mat-error>
      </mat-form-field>

      <section class="times flex flex-col">
        <mat-form-field class="point-time" data-test="unloadingTime">
          <mat-label>{{ 'shared.fileUploadDialog.formWayBill.unloadingTime' | translate }}</mat-label>
          <transport-time-input [formControl]="$any(form.controls.timeFrom)" [required]="isWaybillRequired"></transport-time-input>
          <mat-error *ngIf="form?.controls?.timeFrom?.errors?.required">{{
          'shared.errors.required' | translate
          }}</mat-error>
        </mat-form-field>
      </section>
    </section>

    <section class="date-time flex flex-col gap-3">
      <mat-form-field class="date">
        <input
          matInput
          [matDatepicker]="pickerTo"
          (focus)="pickerTo.open()"
          placeholder="{{ 'shared.fileUploadDialog.formWayBill.unloadingDateTo' | translate }}"
          [formControl]="$any(form.controls.dateTo)"
          autocomplete="off"
        />
        <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
        <mat-datepicker #pickerTo></mat-datepicker>
        <mat-error *ngIf="form?.controls?.dateTo?.errors?.dateToLessDateFrom">
          {{ 'shared.fileUploadDialog.formWayBill.errors.dateToLessDateFrom' | translate }}
        </mat-error>
      </mat-form-field>

      <section class="times flex flex-col">
        <mat-form-field class="point-time">
          <mat-label>{{ 'shared.fileUploadDialog.formWayBill.unloadingTime' | translate }}</mat-label>
          <transport-time-input [formControl]="$any(form.controls.timeTo)"></transport-time-input>
        </mat-form-field>
      </section>
    </section>

    <div class="annotation" *ngIf="isWaybillRequired">
      <span class="tip-star">*&nbsp;</span>{{ 'shared.fileUploadDialog.formWayBill.tip' | translate }}
    </div>
  </ng-container>
</form>
