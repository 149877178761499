import { AbstractControl, ValidationErrors } from '@angular/forms';
import moment, { Moment } from 'moment';

export interface IMonth {
  name: string;
  startDate: string;
  endDate: string;
}

export interface IYear {
  name: string;
  dateValue: string;
}

export const DEFAULT_MONTHS_COUNT = 12;

export function rangeValidator(): ValidationErrors {
  return (control: AbstractControl) => {
    const start = (control.value as { toDate: string; fromDate: string })?.fromDate;
    const end = (control.value as { toDate: string; fromDate: string })?.toDate;
    const result = Boolean(start) && end && start > end;

    return (Boolean(result) ? { outOfBounds: true } : null) as ValidationErrors;
  };
}

/**
 * Get dates '2020-10-22,2020-10-23' by form value
 */
export function getDateRangeStringRepresentation(formValue: { fromDate?: string; toDate?: string } | null): string {
  if (Boolean(formValue)) {
    const { fromDate, toDate } = formValue as { fromDate?: string; toDate?: string };
    return Boolean(fromDate) || Boolean(toDate) ? `${getDateFormating(fromDate ?? '')},${getDateFormating(toDate ?? '')}` : '';
  }

  return '';
}

/**
 * Get form value from string '2020-10-22,2020-10-23'
 */
export function getFormDateValue(stringDates: string): { fromDate?: string; toDate?: string } {
  const [fromDate, toDate] = Boolean(stringDates) ? stringDates.split(',') : [];

  return Boolean(fromDate) || Boolean(toDate) ? { fromDate, toDate } : {};
}

/**
 * Get a formatted date
 */
export function getDateFormating(date: string): string {
  return date ? moment(date, 'YYYY-MM-DD').format('YYYY-MM-DD') : '';
}

export const getMonths = (format: string, startDate: Moment, isReversed = false, count = DEFAULT_MONTHS_COUNT): IMonth[] => {
  const res: IMonth[] = [];
  for (let i = 0; i < count; i += 1) {
    const startOfMonth = startDate.clone().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = startDate.clone().endOf('month').format('YYYY-MM-DD');
    const monthName = startDate.format(format);
    res.push({
      startDate: startOfMonth,
      endDate: endOfMonth,
      name: monthName,
    });
    startDate.add(isReversed ? -1 : 1, 'month');
  }
  return res;
};

export const getYears = (beforeCurrent: number, afterCurrent: number): IYear[] => {
  const currentYear = moment().startOf('year');
  const res: IYear[] = [];
  const count = beforeCurrent + afterCurrent + 1;
  const startDate = currentYear.add(-beforeCurrent, 'year');
  for (let i = 0; i < count; i += 1) {
    const name = startDate.format('YYYY');
    const dateValue = startDate.clone().startOf('year').format('YYYY-MM-DD');
    res.push({
      name,
      dateValue,
    });
    startDate.add(1, 'year');
  }
  return res;
};
