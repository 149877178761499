import { transport } from '@transport/proto';
import { TAdminOrganizationForm } from '@transport/ui-interfaces';

export const adminProfileFeatureKey = 'admin';

export interface IAdminProfileState {
  subOrganizations: transport.IAdminOrganization[];
  loading: boolean;
  reactiveFormValue: TAdminOrganizationForm;
}

export const adminProfileInitialState: IAdminProfileState = {
  subOrganizations: [],
  loading: false,
  reactiveFormValue: {} as TAdminOrganizationForm,
};
