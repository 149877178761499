import { createAction, props } from '@ngrx/store';

import { IPassportState, PASSPORT_PATH, passportFeatureName } from './passport.state';

export const setPassportUiState = createAction(`[${passportFeatureName}] set state`, props<{ payload: IPassportState }>());

export const setPassportRoute = createAction(`[${passportFeatureName}] set route`, props<{ route: PASSPORT_PATH | string }>());

export const setAccessRecovery = createAction(
  `[${passportFeatureName}] set access recovery`,
  props<{ token: string; isExpired: boolean; email: string }>(),
);

export const setConfirmEmailData = createAction(
  `[${passportFeatureName}] set confirm email data`,
  props<{ token: string; email: string; newEmail: string; isExpired: boolean }>(),
);

export const goForward = createAction(`[${passportFeatureName}] go forward`);

export const goBack = createAction(`[${passportFeatureName}] go back`);

export const setTitle = createAction(`[${passportFeatureName}] set title`, props<{ title: string }>());

export const setRegistrationTokenExpired = createAction(`[${passportFeatureName}] set registration token expired`);

export const setPasswordForgot = createAction(`[${passportFeatureName}] set password forgot`);

export const resetPasswordForgot = createAction(`[${passportFeatureName}] reset password forgot`);
