export const staffLocalization = {
  actions: {
    invite: 'Пригласить',
    details: 'Детали',
    copy: 'Копировать',
    send: 'Отправить',
    inviteAgain: 'Отправить приглашение повторно',
  },
  table: {
    header: {
      user: 'Пользователь',
      permissions: 'Права',
    },
    status: 'Приглашение отправлено',
  },
  modal: {
    title: 'Пригласить сотрудника',
    detailsTitle: 'Детали сотрудника',
    info: 'Права доступа:',
    alerts: {
      success: 'Пользователь успешно приглашен',
    },
    errors: {
      createEmployee: 'Невозможно пригласить сотрудника',
      empltyPermissions: 'Необходимо выбрать право доступа',
    }
  },
};
