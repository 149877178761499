import { ValidatorFn, Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { transport } from '@transport/proto';
import {
  conditionalValidator,
  hasPhysicalPersonInn,
  isJuridicalPerson,
  LEGAL_FORM_CODES,
  moreThanNowValidator,
  TAdminOrganizationForm,
  trimmedStringValidator,
} from '@transport/ui-interfaces';
import { MAX_EMAIL_LENGTH, regExpConfig } from '@transport/ui-utils';

import { TOrganizationForm } from './profile-form-shared';

export interface IOrganizationUserForm {
  name: string;
  patronymic: string;
  surname: string;
  passportSeries: string;
  passportNumber: string;
  passportDate: string;
}

export enum ORGANIZATION_USER_FORM_CONSTANTS {
  MAX_PASSPORT_SERIES_LENGTH = 10,
  MAX_PASSPORT_NUMBER_LENGTH = 20,
}

export const isJuridicalPersonValidator = (formControl: FormControl) => {
  const legalFormCode = (formControl.parent?.get('legalForm')?.value as transport.ILegalForm)?.code as LEGAL_FORM_CODES;
  return isJuridicalPerson(legalFormCode);
};

export const isPhysicalPersonValidator = (formControl: FormControl) => {
  const legalFormCode = (formControl.parent?.get('legalForm')?.value as transport.ILegalForm)?.code as LEGAL_FORM_CODES;
  return hasPhysicalPersonInn(legalFormCode);
};

export function createOrganizationEditFormGroup(disabled = true) {
  return new FormGroup<Partial<TAdminOrganizationForm>>({
    kpp: new FormControl<string | null>({ value: null, disabled }, Validators.pattern(regExpConfig.kpp.RU)),
    legalAddress: new FormControl<string | null>({ value: null, disabled }, [Validators.required, trimmedStringValidator]),
    postalAddress: new FormControl<string | null>(null, trimmedStringValidator),
    phoneNumber: new FormControl<string | null>(null, Validators.pattern(regExpConfig.phoneNumber.RU)),
    email: new FormControl<string | null>(null, [Validators.pattern(regExpConfig.email.regExp), Validators.maxLength(MAX_EMAIL_LENGTH)]),
    signer: new FormControl<string | null>(null, trimmedStringValidator),
    ...getOrganizationDisabledControls(disabled),
  });
}

export function getOrganizationDisabledControls(disabled: boolean) {
  return {
    legalForm: new FormControl<transport.ILegalForm | null>(
      {
        value: null,
        disabled,
      },
      [Validators.required],
    ),
    ogrn: new FormControl<string | null>(
      {
        value: null,
        disabled,
      },
      [
        Validators.required,
        conditionalValidator(isJuridicalPersonValidator, Validators.pattern(regExpConfig.ogrn.RU)) as ValidatorFn,
        conditionalValidator(isPhysicalPersonValidator, Validators.pattern(regExpConfig.ogrnIp.RU)) as ValidatorFn,
      ],
    ),
    companyName: new FormControl<string | null>(
      {
        value: null,
        disabled,
      },
      [Validators.required, trimmedStringValidator],
    ),
    inn: new FormControl<string | null>(
      {
        value: null,
        disabled,
      },
      [
        Validators.required,
        conditionalValidator(isJuridicalPersonValidator, Validators.pattern(regExpConfig.inn.RU)) as ValidatorFn,
        conditionalValidator(isPhysicalPersonValidator, Validators.pattern(regExpConfig.innPhysOrIp.RU)) as ValidatorFn,
      ],
    ),
  };
}

export function createOrganizationUserFormGroup() {
  return new FormGroup<Partial<IOrganizationUserForm>>({
    name: new FormControl<string>('', [Validators.required]),
    patronymic: new FormControl<string>('', [Validators.required]),
    surname: new FormControl<string>('', [Validators.required]),
    passportSeries: new FormControl<string>('', [
      Validators.required,
      Validators.maxLength(ORGANIZATION_USER_FORM_CONSTANTS.MAX_PASSPORT_SERIES_LENGTH),
    ]),
    passportNumber: new FormControl<string>('', [
      Validators.required,
      Validators.maxLength(ORGANIZATION_USER_FORM_CONSTANTS.MAX_PASSPORT_NUMBER_LENGTH),
    ]),
    passportDate: new FormControl<string>('', Validators.compose([Validators.required, moreThanNowValidator()])),
  });
}

export function mapOrganizationInputValueToInner<T extends TAdminOrganizationForm>(value: T): TOrganizationForm {
  return {
    inn: value.inn ?? '',
    id: value.id ?? '',
    email: value.email ?? '',
    kpp: value.kpp ?? '',
    companyName: value.companyName ?? '',
    legalAddress: value.legalAddress ?? '',
    postalAddress: value.postalAddress ?? '',
    ogrn: value.ogrn ?? '',
    phoneNumber: value.phoneNumber ?? '',
    signer: value.signer ?? '',
    legalForm: value.legalForm ?? null,
  };
}

export function mapOrganizationUserInputValueToInner<T extends IOrganizationUserForm>(value: T): IOrganizationUserForm {
  return {
    name: value.name ?? '',
    patronymic: value.patronymic ?? '',
    surname: value.surname,
    passportSeries: value.passportSeries ?? '',
    passportNumber: value.passportNumber ?? '',
    passportDate: value.passportDate ?? '',
  };
}
