import { ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList } from '@angular/core';

import { TnCardSectionRowComponent } from './card-section-row/card-section-row.component';

export interface ICardSectionMenuItem {
  label: string;
  icon: string;
  action: VoidFunction;
}

@Component({
  selector: 'transport-card-section',
  templateUrl: './card-section.component.html',
  styleUrls: ['./card-section.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- need for parent async refresh handling
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TnCardSectionComponent {
  @ContentChildren(TnCardSectionRowComponent)
  public readonly rows: QueryList<TnCardSectionRowComponent> | null = null;

  @Input() public header = '';

  @Input() public translationPrefix = '';

  @Input() public menuItems: ICardSectionMenuItem[] = [];

  @Input() public hasMenu = false;

  public getLabelWithPrefix(label: string) {
    return `${this.translationPrefix}${label}`;
  }

  public getTipMessageWithPrefix(message: string) {
    return `${this.translationPrefix}${message}`;
  }

  public isRowVisible(row: TnCardSectionRowComponent) {
    return Boolean(row.customRow ?? row.value ?? row.icon);
  }
}
