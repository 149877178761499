import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DEFAULT_MAX_FILE_SIZE, DOC_FILE_ACCEPT_EXTENSIONS_SHORT_ARRAY } from '@transport/ui-utils';

import { TnFileUploadService } from './services/file-upload.service';

const extFilesAccept = DOC_FILE_ACCEPT_EXTENSIONS_SHORT_ARRAY;
const fileMaxSize = DEFAULT_MAX_FILE_SIZE;

@UntilDestroy()
@Component({
  selector: 'transport-file-selector',
  templateUrl: './file-selector.component.html',
  styleUrls: ['./file-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TnFileUploadService],
})
export class TnFileSelectorComponent implements OnInit {
  @Output() public readonly fileSelected: EventEmitter<File> = new EventEmitter<File>();

  @ViewChild('file') private readonly file;

  @Input() public uploadFile = true;

  @Input() public control!: AbstractControl;

  @Input() public label: string | undefined;

  @Input() public labelClasses?: string[];

  @Input() public labelAddition?: string;

  @Input() public required = false;

  @Input() public showErrors = true;

  @Input() public extFilesAccept = extFilesAccept;

  @Input() public fileMaxSize = fileMaxSize;

  @Input() public size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';

  @Input() public errorSize: 'xs' | 'sm' | 'md' | 'lg' = 'xs';

  @Input() public height = 'h-8';

  @Input() public reduceText = false;

  @Input() public accept!: string;

  @Input() public errorToMessage: (errors: ValidationErrors | null) => string = () => 'Error';

  public fileName = '';

  public isLoading = false;

  constructor(public readonly fileUploadService: TnFileUploadService, private readonly cdr: ChangeDetectorRef) {
    this.fileUploadService.fileName$.pipe(untilDestroyed(this)).subscribe(val => {
      this.fileName = val;
    });
  }

  public ngOnInit() {
    if (this.control.value) {
      this.fileName = this.control?.value?.fileName || this.control?.value?.fileUrl;
    }
    this.control.statusChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.cdr.markForCheck();
    });
  }

  public addFile(): void {
    this.file.nativeElement.click();
  }

  public clear() {
    this.control.patchValue(null);
    this.fileName = '';
    this.file.nativeElement.value = '';
    this.cdr.markForCheck();
  }

  public handleFileInput(target: EventTarget | null): void {
    if (!target) {
      return;
    }

    if (this.invalidFile(target['files'].item(0))) {
      this.fileName = '';
      this.control.markAsTouched();
      this.cdr.markForCheck();
      return;
    }

    this.fileSelected.emit(target['files'].item(0));

    if (this.uploadFile) {
      this.isLoading = true;
      this.fileUploadService
        .postFile(target['files'].item(0))
        .pipe(untilDestroyed(this))
        .subscribe({
          next: data => {
            if (Boolean(data)) {
              this.isLoading = false;
              this.control?.patchValue({ fileName: this.fileName, fileUrl: data?.file_url });
              this.fileUploadService.fileUrl$.next(data?.file_url);
              this.cdr.markForCheck();
            }
          },
          error: error => {
            this.isLoading = false;
            this.cdr.markForCheck();
          },
        });
    } else {
      this.control?.patchValue({ fileName: target['files'].item(0).name });
      this.fileName = target['files'].item(0).name;
      this.cdr.markForCheck();
    }
  }

  private invalidFile(file: File): boolean {
    return this.invalidFileSize(file) || this.invalidFileFormat(file);
  }

  private invalidFileFormat(file: File): boolean {
    const nameArr = file.name.split('.');
    const ext = `.${nameArr[nameArr.length - 1]}`.toLowerCase().trim();
    if (this.extFilesAccept.find(el => el === ext)) {
      return false;
    }
    this.control?.setErrors({ invalidFormat: true });
    return true;
  }

  private invalidFileSize(file: File): boolean {
    if (file.size > this.fileMaxSize) {
      this.control?.setErrors({ invalidSize: true });
      return true;
    }
    return false;
  }
}
