import { ActionReducerMap } from '@ngrx/store';

import { allPermissionReducer } from './user/reducers/all-permissions.reducer';
import { adminUserReducer } from './user/reducers/user.reducer';
import { ownerUsersReducer } from './users/reducers/owner-users.reducer';

export interface IAdminState {
  user: ReturnType<typeof adminUserReducer>;
  users: ReturnType<typeof ownerUsersReducer>;
  allPermissions: ReturnType<typeof allPermissionReducer>;
}

export const adminFeatureName = 'admin';

export const adminReducers: ActionReducerMap<IAdminState> = {
  user: adminUserReducer,
  users: ownerUsersReducer,
  allPermissions: allPermissionReducer,
};
