<notifications-toolbar>
  <div class="flex flex-auto justify-between items-center">
    <button data-test="back-button" (click)="leavePage()" class="tw-button-outline-md">
      <mat-icon class="mat-icon-inside" [ngClass]="{'mat-icon-pointer': true, 'left': true}">chevron_left</mat-icon>
      {{'shared.actions.back' | translate}}
    </button>

    <button data-test="read-all-button" [disabled]="$any(isLoading$ | async)" (click)="markAllAsRead()" class="tw-button-solid-md">
      {{'shared.notifications.markAllAsRead' | translate}}
      <mat-icon class="mat-icon-inside" [ngClass]="{'mat-icon-pointer': !$any(isLoading$ | async), 'right': true}">done</mat-icon>
    </button>
  </div>
</notifications-toolbar>

<div class="flex flex-col transport-table-wrapper h-full">
  <div class="spinner-box flex justify-start items-start" *ngIf="isLoading$ | async">
    <mat-progress-bar color="accent" mode="indeterminate" value="13" [bufferValue]="50"></mat-progress-bar>
  </div>
  <ng-container *ngIf="notifications$ | async as notifications">
    <div *ngFor="let notification of notifications; trackBy: trackById" (click)="markAsRead(notification)">
      <div
        class="notification-list-item !flex gap-5 justify-start items-center !overflow-visible"
        data-test="notification-list-item"
        mat-menu-item
      >
        <mat-icon>message</mat-icon>
        <div class="notification-time">
          {{ notification.eventDatetime | date: 'HH:mm [dd.MM.yy]' }}
        </div>
        <div class="notification-title" [ngClass]="{ 'not-read': !notification.isRead }">
          {{ notification.title }}
          <button *ngIf="notification?.payload?.id" class="!border-blue-500 !text-blue-600 tw-button-outline-xs" (click)="goToEntity(notification)" data-test="goto-notification-button">
            {{'shared.notifications.goToEntity' | translate}}
          </button>
        </div>
      </div>
    </div>
    <div *ngIf="!notifications.length && !(isLoading$ | async)" class="flex justify-center items-center w-full h-full">
      <h2 class="text-gray-300 text-xl">{{ 'shared.notifications.page.noNotifications' | translate }}</h2>
    </div>
  </ng-container>
</div>

<mat-paginator
  *ngIf="(notifications$ | async)?.length"
  showFirstLastButtons
  class="flex justify-center items-start"
  [length]="count$ | async"
  [pageSize]="pager.pageSize"
  [pageSizeOptions]="[25, 50, 100]"
  (page)="setPager($event)"
>
</mat-paginator>
