import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { TnCurrentUserService } from '@marketplace/auth';
import { VerificationsService } from '../../services/verifications.service';
import { VerificationStatuses } from '@transport/ui-kit';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { map } from 'rxjs';
import { STATUS, USER_ROLE } from '@transport/ui-interfaces';
import { Router } from '@angular/router';
import { VerificationFlowService } from '@marketplace-app/verification/verification-flow.service';

@UntilDestroy()
@Component({
  selector: 'marketplace-verification-block',
  templateUrl: './verification-block.component.html',
  styleUrls: ['./verification-block.component.scss'],
})
export class VerificationBlockComponent implements OnInit, OnDestroy {
  @Input() collapsed = false;

  public Statuses = STATUS;

  public status?: VerificationStatuses;

  public verificationClosed$ = this.currentUserService.user$.pipe(map(user => Boolean(user.uiSettings?.['verificationClosed'])));
  public isRegistrationCompleted$ = this.verificationsService.isRegistrationCompleted$;

  constructor(
    private verificationsService: VerificationsService,
    private currentUserService: TnCurrentUserService,
    private readonly verificationService: VerificationFlowService,
    private router: Router,
    private cdr: ChangeDetectorRef,
  ) {
  }

  ngOnInit() {
    this.verificationsService.verificationStatus.pipe(untilDestroyed(this))
      .subscribe(accStatus => {
        this.status = accStatus;
        this.cdr.detectChanges();
      })
  }

  ngOnDestroy(): void {
    this.verificationsService.verificationStatus.next(undefined); //Необходим для отображения корректного статуса аккредитации при входе нового пользователя
  }

  markStatusAsRead() {
    this.verificationsService.markStatusAsRead();
  }

  startAccredetation = () => {
    if (this.currentUserService.isUserRole(USER_ROLE.CARRIER)) this.verificationService.openModal(true);
    else this.router.navigate(['cargo-offer', 'accreditation-modal']);
  };
}
