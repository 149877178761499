import { Injectable } from '@angular/core';
import { transport } from '@transport/proto';
import { IGqlMyCarrierAccreditationResponse, TStateRelationship } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnGqlClientOwnerMutationsService } from '../../../gql-client-owner/graphql-client-owner-mutations.service';
import { TnGqlClientOwnerQueriesService } from '../../../gql-client-owner/graphql-client-owner-queries.service';

@Injectable({
  providedIn: 'root',
})
export class TnOwnerDirectoryCarrierAccreditationApiService {
  constructor(private readonly gqlQuery: TnGqlClientOwnerQueriesService, private readonly gqlMutation: TnGqlClientOwnerMutationsService) {}

  public getCarrierAccreditationList(): Observable<{ list: transport.Carrier.ICarrierAccreditation[]; totalCount: number }> {
    return this.gqlQuery.getCarrierAccreditation().pipe(
      map(data => {
        return {
          list: data.carrierOrganizations.map(el => ({
            historyDate: el.businessRelationship?.lastChangeDate,
            ...el,
          })),
          totalCount: data.carrierOrganizations.length,
        };
      }),
    );
  }

  public getCarrierRejectList(): Observable<{ list: transport.Carrier.ICarrierReject[]; totalCount: number }> {
    return this.gqlQuery.getCarrierReject().pipe(
      map((data: IGqlMyCarrierAccreditationResponse) => {
        return {
          list: data.carrierOrganizations.map(el => ({
            historyDate: el.businessRelationship?.lastChangeDate,
            fioEmployee: el.businessRelationship?.lastChangeUser,
            reason: el.businessRelationship?.lastChangeReason,
            ...el,
          })),
          totalCount: data.carrierOrganizations.length,
        };
      }),
    );
  }

  public editBusinessRelationship(carrierOrgId: string, state: TStateRelationship): Observable<{ state: TStateRelationship }> {
    return this.gqlMutation.editBusinessRelationship(carrierOrgId, state);
  }

  public rejectCarrier(carrierOrgId:string, state: TStateRelationship, lastChangeReason: string): Observable<{ state: TStateRelationship }> {
    return this.gqlMutation.rejectCarrier(carrierOrgId, state, lastChangeReason);
  }
}
