import { createSelector } from '@ngrx/store';

import { ITableState } from './table-state.interface';

export function makeTableSelectors(featureSelector) {
  const getFilter = createSelector(featureSelector, (state: ITableState) => {
    return state?.filter;
  });

  const getSort = createSelector(featureSelector, (state: ITableState) => {
    return state?.sort;
  });

  const getPagination = createSelector(featureSelector, (state: ITableState) => {
    return state?.pagination;
  });

  const getSelectedItems = createSelector(featureSelector, (state: ITableState) => {
    return state?.selected;
  });

  const getRefreshState = createSelector(featureSelector, (state: ITableState) => {
    return state?.refresh;
  });

  return {
    getFilter,
    getPagination,
    getSort,
    getSelectedItems,
    getRefreshState,
  };
}
