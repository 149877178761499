import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'transport-table-search-cell',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnTableSearchCellComponent {
  @ContentChild(TemplateRef)
  public template: TemplateRef<never> | null = null;

  @Input()
  public field?: string;

  @Input()
  public type = 'text';

  @Input()
  public options: Record<string, string>[] = [];
}
