import { transport } from '@transport/proto';

import { createNewDriver, IDriver } from '../../driver/driver.interface';
import { TVehicle, VEHICLE_TYPE_OWNERSHIP, VEHICLE_TYPE_PROPERTY } from '../../vehicle';
import { createNewVehicle } from '../../vehicle/vehicle.interface';
import { TDirectoryEntityPredefinedAction } from '../directory.interface';

/**
 * Router vehicles page component data model.
 */
export class TnVehiclePageComponentData {
  public vehicle = createNewVehicle();

  public predefinedAction: TDirectoryEntityPredefinedAction = 'view';

  public driver: IDriver = createNewDriver();

  public select: {
    vehicleTypes: TVehicle[];
    vehicleMakes: transport.Vehicle.IMaker[];
    countries: { code: string; name: string }[];
    bodyTypes: transport.Vehicle.Body.IType[];
    bodySubtypes: Record<string, transport.Vehicle.Body.ISubtype[]>;
    loadingTypes: transport.ILoadingType[];
    typeProperties?: VEHICLE_TYPE_PROPERTY[];
    ownershipTypes?: VEHICLE_TYPE_OWNERSHIP[];
    agentCarriers?: transport.IAgentCarrier[];
  } = {
    vehicleTypes: [],
    vehicleMakes: [],
    countries: [],
    bodyTypes: [],
    bodySubtypes: {},
    loadingTypes: [],
    typeProperties: [],
    ownershipTypes: [],
    agentCarriers: [],
  };
}
