import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IPassportState, PASSPORT_PATH, passportFeatureName } from './passport.state';

export const getPassportState = createFeatureSelector<IPassportState>(passportFeatureName);

export const confirmEmailData = createSelector(getPassportState, (state: IPassportState) => state.confirmEmailData);

export const title = createSelector(getPassportState, (state: IPassportState) => state.title);

export const navigation = createSelector(getPassportState, (state: IPassportState) => state.navigation);

export const step = createSelector(getPassportState, (state: IPassportState) => state.navigation.step);

export const accessRecovery = createSelector(getPassportState, (state: IPassportState) => state.accessRecovery);

export const isSignUpState = createSelector(getPassportState, (state: IPassportState) => state.currentPath === PASSPORT_PATH.SIGNUP);
