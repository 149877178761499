import { Sort } from '@angular/material/sort';
import { IPaginationData } from '@transport/ui-interfaces';

export enum REFRESH_TYPE {
  PERIODICAL = 'periodical',
  FORCED = 'FORCED',
  SLEEP = 'SLEEP',
}

export interface ITableState {
  pagination: IPaginationData;
  sort: Sort;
  selected: Map<string, boolean>;
  refresh: REFRESH_TYPE;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: tech debt
  filter?: any;
}
