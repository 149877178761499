import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { appColors } from '@transport/client-shared-assets';

const LINE_X_OFFSET = 48;
const LINE_Y_OFFSET = 24;
const ICON_SIZE = 24;
const DOUBLE_COUNT = 2;

@UntilDestroy()
@Directive()
export abstract class TnStatusBarDirective implements AfterViewInit {
  @ViewChild('viewPort') public viewPort?: ElementRef<HTMLElement>;

  public stageNumber = 1;

  public disabled = false;

  public lines: { x1: number; x2: number; y1: number; y2: number; stroke: string }[] = [];

  public points: {
    r: number;
    x: number;
    y: number;
    iconTransform: string;
    fill: string;
    label: { a: string; b: string; color: string };
  }[] = [];

  public get iconBackgroundColor() {
    return appColors.textButton;
  }

  protected abstract localization: string;

  protected getPointCount() {
    return 1;
  }

  constructor(public cdr: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    window.setTimeout(this.resize.bind(this));
  }

  @HostListener('window:resize', ['$event'])
  public resize(event?) {
    const { clientWidth, clientHeight } = this.viewPort?.nativeElement ?? { clientWidth: LINE_X_OFFSET, clientHeight: 0 };

    this.lines = this.getLines({ clientWidth, clientHeight });
    this.points = this.getPoints({ clientWidth, clientHeight });

    this.cdr.markForCheck();
  }

  private getLines({ clientWidth, clientHeight }: { clientWidth: number; clientHeight: number }) {
    const POINT_COUNT = this.getPointCount();
    const getLine = () => ({
      x1: 0,
      x2: 0,
      y1: clientHeight / DOUBLE_COUNT - LINE_Y_OFFSET,
      y2: clientHeight / DOUBLE_COUNT - LINE_Y_OFFSET,
      stroke: appColors.black300,
    });

    const getLineX = index => {
      return ((clientWidth - DOUBLE_COUNT * LINE_X_OFFSET) * index) / (POINT_COUNT - 1) + LINE_X_OFFSET;
    };

    return [...Array(POINT_COUNT - 1).keys()].map((key, index) => {
      const line = getLine();
      line.x1 = getLineX(index);
      line.x2 = getLineX(index + 1);
      if (index < this.stageNumber - 1 && !this.disabled) {
        line.stroke = this.iconBackgroundColor;
      }
      return line;
    });
  }

  private getPoints({ clientWidth, clientHeight }: { clientWidth: number; clientHeight: number }) {
    const { black, black300, gray500 } = appColors;
    const POINT_COUNT = this.getPointCount();

    const getPoint = () => ({
      r: 4,
      x: 0,
      y: clientHeight / DOUBLE_COUNT - LINE_Y_OFFSET,
      iconTransform: '',
      fill: black300,
      label: { a: '', b: '', color: gray500 },
    });

    const getPointLabel = (label: string, index: number) => {
      return `${this.localization}${this.disabled && index + 1 === this.stageNumber ? 'disabled' : index + 1}.${label}`;
    };

    return [...Array(POINT_COUNT).keys()].map((item, index) => {
      const point = getPoint();
      point.x = ((clientWidth - DOUBLE_COUNT * LINE_X_OFFSET) * index) / (POINT_COUNT - 1) + LINE_X_OFFSET;
      point.iconTransform = `translate(${point.x - ICON_SIZE / DOUBLE_COUNT},${point.y - ICON_SIZE / DOUBLE_COUNT})`;
      point.label.a = getPointLabel('labelA', index);
      point.label.b = getPointLabel('labelB', index);
      if (index + 1 <= this.stageNumber && !this.disabled) {
        point.fill = this.iconBackgroundColor;
        point.label.color = black;
      }
      return point;
    });
  }
}
