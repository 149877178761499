import { createNewOrder, IOrder } from '../order/index';

/**
 * Router owner order tracking page component data model.
 */
export class TnOwnerOrderTrackingPageComponentData {
  public title = 'tracking: owner order tracking page';

  public order: IOrder = createNewOrder();

  constructor(input?: TnOwnerOrderTrackingPageComponentData) {
    this.title = input?.title ?? this.title;
    this.order = input?.order ?? this.order;
  }
}
