import {
  DEMO_DOMAIN_URL,
  DEMO_MARKETPLACE_URL,
  DEMO_SSO_URL,
  DEV_DOMAIN_URL,
  DEV_MARKETPLACE_URL,
  LOADTEST_MARKETPLACE,
  LOADTEST_MARKETPLACE_URL,
  PROD_DOMAIN_URL,
  PROD_MARKETPLACE_URL,
  PROD_SSO_URL,
  STAGE_DOMAIN_URL,
  STAGE_MARKETPLACE_URL,
  STAGE_SSO_URL,
  TEST_DOMAIN_URL,
  TEST_MARKETPLACE_URL,
} from '@transport/ui-utils';

export const ALLOWED_DOMAIN_LIST = [
  `${DEV_DOMAIN_URL}`,
  `${TEST_DOMAIN_URL}`,
  `${DEMO_DOMAIN_URL}`,
  `${STAGE_DOMAIN_URL}`,
  `${DEV_MARKETPLACE_URL}`,
  `${TEST_MARKETPLACE_URL}`,
  `${DEMO_MARKETPLACE_URL}`,
  `${STAGE_MARKETPLACE_URL}`,
  `${PROD_MARKETPLACE_URL}`,
  `${LOADTEST_MARKETPLACE_URL}`,
  `${LOADTEST_MARKETPLACE}`,
  `${PROD_DOMAIN_URL}`,

  `${DEMO_SSO_URL}`,
  `${STAGE_SSO_URL}`,
  `${PROD_SSO_URL}`,
  'sso.localhost:4400',
  'sso.test-mob-tms.tn-dl.ru',
  'test-mob-tms.tn-dl.ru',
  'market.test-mob-tms.tn-dl.ru',
  'dev1-tms.tn-dl.ru',
  'market.dev1-tms.tn-dl.ru',
  'sso.dev1-tms.tn-dl.ru',
  'dev-2-tms.tn-dl.ru',
  'market.dev-2-tms.tn-dl.ru',
  'sso.dev-2-tms.tn-dl.ru',
  'dev-3-tms.tn-dl.ru',
  'market.dev-3-tms.tn-dl.ru',
  'sso.dev-3-tms.tn-dl.ru',
  'dev-4-tms.tn-dl.ru',
  'market.dev-4-tms.tn-dl.ru',
  'sso.dev-4-tms.tn-dl.ru',

  'lv4.lv3.lv2.localhost:4200',
  'lv4.lv3.lv2.localhost:4300',
];

/**
 * @param domain Для дефолтных`allowedDomains`- без`http(s)://`
 */
export function isAllowedDomain(domain: string, allowedDomains: string[] = ALLOWED_DOMAIN_LIST) {
  return allowedDomains.some((allowedDomain: string) => domain.includes(allowedDomain));
}

const DOMAIN_SLASH_OFFSET = 2;
export function prepareDomain(domain: string) {
  return domain.slice(domain.search('//') + DOMAIN_SLASH_OFFSET);
}
