<div class='select-none' *ngIf="!verificationClosed">
    <div [ngClass]="{collapsed: collapsed}" class="verification-block notStarted rounded-md flex flex-col" *ngIf="status === statuses.notStarted">
        <div class="verification-info flex">
            <mat-icon svgIcon="warning_icon"></mat-icon>
            <p style="margin: 0;" *ngIf="!collapsed">{{'shared.mp.accreditation.sidebarBlock.statuses.notStarted' | translate}}</p>
        </div>
        <button
            class="bet__button tw-button-solid-sm w-full mt-2"
            *ngIf="onStartAccredetation"
            (click)="onStartAccredetation()"
        >
            <span *ngIf="!collapsed">{{'shared.mp.cargo.table.popup.notAccepted.notInitiated.button' | translate}}</span>
            <mat-icon>arrow_forward</mat-icon>
        </button>
    </div>
    <div [ngClass]="{collapsed: collapsed}" class="verification-block checking rounded-md" *ngIf="status === statuses.checking">
        <h2 *ngIf="!collapsed">{{'shared.mp.accreditation.sidebarBlock.title' | translate}}</h2>
        <p>
            <mat-icon>lens</mat-icon>
            <span *ngIf="!collapsed">
                {{'shared.mp.accreditation.sidebarBlock.statuses.checking' | translate}}
            </span>
        </p>
    </div>
    <div [ngClass]="{collapsed: collapsed}" class="verification-block denied rounded-md" *ngIf="status === statuses.denied">
        <div class="close" (click)="onMarkStatusAsRead()">
            <mat-icon>close</mat-icon>
        </div>
        <h2 *ngIf="!collapsed">{{'shared.mp.accreditation.sidebarBlock.title' | translate}}</h2>
        <p>
            <mat-icon>lens</mat-icon>
            <span *ngIf="!collapsed">
                {{'shared.mp.accreditation.sidebarBlock.statuses.denied' | translate}}
            </span>
        </p>
    </div>
    <div [ngClass]="{collapsed: collapsed}" class="verification-block opened rounded-md" *ngIf="status === statuses.opened">
        <div class="close" (click)="onMarkStatusAsRead()">
            <mat-icon>close</mat-icon>
        </div>
        <h2 *ngIf="!collapsed">{{'shared.mp.accreditation.sidebarBlock.title' | translate}}</h2>
        <p>
            <mat-icon>lens</mat-icon>
            <span *ngIf="!collapsed">
                {{'shared.mp.accreditation.sidebarBlock.statuses.opened' | translate}}
            </span>
        </p>
    </div>
</div>
