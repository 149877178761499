import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAX_EMAIL_LENGTH, regExpConfig } from '@transport/ui-utils';

export interface IPasswordForgotFormData {
  email: string;
}

export interface IPasswordForgotForm extends FormGroup {
  controls: {
    email: AbstractControl;
  };
}

export type TNewPasswordForgotFormConstructor = (
  fb: FormBuilder,
  editableItem?: IPasswordForgotFormData,
  disabled?: boolean,
) => IPasswordForgotForm;

export const newPasswordForgotForm: TNewPasswordForgotFormConstructor = (
  fb: FormBuilder,
  editableItem?: IPasswordForgotFormData,
  disabled = false,
): IPasswordForgotForm => {
  return fb.group({
    email: [
      editableItem?.email ?? '',
      {
        validators: Validators.compose([
          Validators.required,
          Validators.pattern(regExpConfig.email.regExp),
          Validators.maxLength(MAX_EMAIL_LENGTH),
        ]),
        disabled,
      },
    ],
  }) as IPasswordForgotForm;
};
