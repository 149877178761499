/**
 * Cargo packaging type values.
 */
export type TCargoPackagingTypeCode = 'PALLETS' | 'BOXES' | 'BUNDLES' | 'PLACER';

/**
 * Cargo types filter interface.
 */
export interface ICargoTypesFilter {
  // How many elements should be returned after offset.
  first?: number;
  // How many elements should be passed before returning elements equal to 'first' value.
  offset?: number;
  // Request for total count of cargo types
  totalCount?: boolean;
}

export interface ICargoPackagingType {
  id: TCargoPackagingTypeCode;
  name: string;
}
