<transport-tw-input-field
  [control]="formControl"
  [errorsSize]="errorsSize"
  [size]="size"
  [showErrors]="showErrors"
  [required]="required"
  [label]="label"
  [errorToMessage]="errorToMessage"
>
  <transport-tw-input
    [control]="formControl"
  >
    <textarea
      class="text-sm rounded-md"
      tw-input
      [formControl]="$any(formControl)"
      [placeholder]="placeholder"
      [required]="required"
      [rows]="rows"
      [attr.data-test]="dataTest"
      (input)="inputValue($event)"
    ></textarea>
  </transport-tw-input>
</transport-tw-input-field>
