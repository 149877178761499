import { createNewCargoOwner, ICargoOwner } from '../../cargo-owner/cargo-owner.interface';
import { TDirectoryEntityPredefinedAction } from '../directory.interface';

/**
 * Router cargo owner page component data model.
 */
export class TnOwnerPageComponentData {
  public title = 'directory: owner page';

  public user: ICargoOwner | null = createNewCargoOwner();

  public predefinedAction: TDirectoryEntityPredefinedAction | null = null;
}
