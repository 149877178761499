/* eslint-disable complexity */
/* eslint-disable no-undefined */
/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */

import moment from 'moment';

export interface ITimeLeftResult {
  time: string;
  className: 'primary' | 'danger' | '';
}

export function timeLeft(date: string | null | undefined, adjustedTime = 0): ITimeLeftResult | undefined {
  if (!date) return undefined;

  const duration = moment.duration(moment(date).diff(moment())).subtract(adjustedTime);

  const years = duration.years();
  duration.subtract(years, 'years');

  const months = duration.months();
  duration.subtract(months, 'months');

  const days = duration.days();
  duration.subtract(days, 'days');

  const hours = duration.hours();
  duration.subtract(hours, 'hours');

  const minutes = duration.minutes();
  duration.subtract(minutes, 'minutes');

  const seconds = duration.seconds();
  duration.subtract(seconds, 'seconds');

  if (months && days && !years) {
    return {
      className: 'primary',
      time: `${months}м ${days}д`,
    };
  }

  if (months || years) {
    return {
      className: 'primary',
      time: years ? `${years}г` : `${months}м`,
    };
  }

  if (days)
    return {
      className: 'primary',
      time: `${days}д ${hours}ч`,
    };

  if (!days && hours)
    return {
      className: 'primary',
      time: moment(`${hours}:${minutes}:${seconds}`, 'HH:mm:ss').format('HH:mm:ss'),
    };

  if (!days && !hours && (minutes || seconds)) {
    return {
      className: (minutes === 3 && !seconds) || minutes <= 2 ? 'danger' : 'primary',
      time: moment(`${minutes}:${seconds}`, 'mm:ss').format('mm:ss'),
    };
  }

  return undefined;
}
