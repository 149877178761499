import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { TToast } from '@transport/ui-interfaces';
import { MonoTypeOperatorFunction, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';

import { showToaster, showToasterWithCancel, showToasterWithRedirect } from './toaster.actions';

export enum TOAST_TYPE {
  ERROR = 'error',
  SUCCESS = 'success',
  ACCENT = 'accent',
}

export interface ITranslateParams {
  code: string;
  params?: {
    [key: string]: string;
  };
  additionalText?: string;
}

@Injectable({
  providedIn: 'root',
})
export class TnToasterFacade {
  constructor(private readonly translate: TranslateService, private readonly store: Store) {}

  public getToastMessage(message: string | ITranslateParams) {
    let toastMessage: string;

    if (typeof message !== 'string') {
      const { code, params, additionalText = '' } = message;
      toastMessage = `${params ? this.translate.instant(code, params) : this.translate.instant(code)}${additionalText}`;
    } else {
      toastMessage = this.translate.instant(message);
    }

    return toastMessage;
  }

  public showMessage(message: string | ITranslateParams, type: TToast = TOAST_TYPE.ERROR, duration?: number): void {
    this.store.dispatch(
      showToaster({
        payload: {
          message: this.getToastMessage(message),
          type,
          duration,
        },
      }),
    );
  }

  public showCancelMessage(message: string | ITranslateParams, type: TToast = TOAST_TYPE.ERROR, duration?: number): void {
    this.store.dispatch(
      showToasterWithCancel({
        payload: {
          message: this.getToastMessage(message),
          type,
          duration,
        },
      }),
    );
  }

  public showRedirectMessage(route: string, message: string | ITranslateParams, type: TToast = TOAST_TYPE.ERROR, duration?: number, styles?: string[], action?: string): void {
    this.store.dispatch(
      showToasterWithRedirect({
        payload: {
          route,
          message: this.getToastMessage(message),
          type,
          duration,
          styles,
          action
        },
      }),
    );
  }

  public notificationHandler = <T>(errorText: string, successText: string): MonoTypeOperatorFunction<T> => {
    return input$ =>
      input$.pipe(
        catchError(err => {
          this.showMessage(errorText);
          return throwError(err);
        }),
        tap(() => {
          this.showMessage(successText, TOAST_TYPE.SUCCESS);
        }),
      );
  };
}
