import { transport } from '@transport/proto';

import { TCarrierRole } from './carrier-common.interface';

/**
 * Carrier interface.
 * This interfaces are needed if protobuf types is not enough,
 * e.g. when entity should have a property with specific string values.
 */
export interface ICarrier extends transport.ICarrier {
  gender: TCarrierRole;
}

export const createNewCarrier = (input?: Partial<ICarrier>) =>
  transport.Carrier.toObject(new transport.Carrier(input), {
    defaults: true,
  }) as ICarrier;
