export interface ISlaFilter {
  [key: string]: string;
}

export enum SLA_TABLE_COLUMN_NAMES {
  ID = 'id',
  NAME = 'name',
  DESCRIPTION = 'description',
  NON_TRACKABLE_CRITERIA_COUNT = 'nonTrackableCriteriaCount',
  TRACKABLE_CRITERIA_COUNT = 'trackableCriteriaCount',
  CREATED_AT = 'createdAt',
}
