import { Provider } from '@angular/core';
import { InMemoryCache } from '@apollo/client/core';
import { TranslateService } from '@ngx-translate/core';
import { Apollo, APOLLO_OPTIONS } from 'apollo-angular';

import { TnCurrentUserFacade } from '../current-user';
import { TnFeatureAccessService, TnHttpHandlersService } from '../feature-access';
import { TnGqlClientCarrierService } from '../gql-client-carrier';
import { TnGqlClientOwnerMutationsService, TnGqlClientOwnerQueriesService } from '../gql-client-owner';
import { TnGqlClientSharedService } from '../gql-client-shared';


export const sharedClientGraphQLProvider: Provider[] = [
  {
    provide: APOLLO_OPTIONS,
    useFactory: () => ({ link: 'test', cache: new InMemoryCache() }),
  },
  {
    provide: TnGqlClientSharedService,
    useFactory: (apollo: Apollo, handlers: TnHttpHandlersService, translate: TranslateService) =>
      new TnGqlClientSharedService(apollo, handlers, translate),
    deps: [Apollo, TnHttpHandlersService, TranslateService],
  },
  {
    provide: TnGqlClientCarrierService,
    useFactory: (shared: TnGqlClientSharedService, userFacade: TnCurrentUserFacade, featureAccess: TnFeatureAccessService) =>
      new TnGqlClientCarrierService(shared, userFacade, featureAccess),
    deps: [TnGqlClientSharedService, TnCurrentUserFacade, TnFeatureAccessService],
  },
  {
    provide: TnGqlClientOwnerQueriesService,
    useFactory: (shared: TnGqlClientSharedService, userFacade: TnCurrentUserFacade, featureAccess: TnFeatureAccessService) =>
      new TnGqlClientOwnerQueriesService(shared, userFacade, featureAccess),
    deps: [TnGqlClientSharedService, TnCurrentUserFacade, TnFeatureAccessService],
  },
  {
    provide: TnGqlClientOwnerMutationsService,
    useFactory: (shared: TnGqlClientSharedService) => new TnGqlClientOwnerMutationsService(shared),
    deps: [TnGqlClientSharedService],
  },
];
