import { CARRIERS_RATING_VALUE } from './carriers-rating-value';

export interface ICarriersRatingReportData {
  id: string;

  /** Дата окончания расчета */
  endDate: string;

  /** Дата начала расчета */
  startDate: string;

  /** Показатель */
  indicator: string;

  /** Направление */
  deliveryArea: string;

  /** Завод */
  department: string;

  /** Выполнено перевозок всего */
  totalDeliveriesCompleted: number;

  /** Назначено перевозок*/
  assignedDeliveryRequests: number;

  /** Выполнено назначенных перевозок*/
  assignedDeliveryRequestsCompleted: number;

  /** Выполнено перевозок из свободных заявок*/
  vacantDeliveryRequestsCompleted: number;

  /** Отказы от назначенных перевозок*/
  assignedDeliveryRequestsRefused: number;

  /** Отказы от заявок-договоров*/
  deliveryContractsRefused: number;

  /** Срывы срока подачи ТС на загрузку*/
  carrierFaultCanceledShipments: number;

  /** Среднее время поиска автомобиля, минут*/
  vehicleSearchAverageTime: number;

  /** Общий процент отказа от заявок*/
  refusedRequestsPercentage: number;

  /** Процент опозданий ТС на загрузку*/
  carrierFaultDelayedShipmentsPercentage: number;

  /** Процент выполнения заявок из свободных*/
  vacantRequestsDeliveriesPercentage: number;

  /** Текущий рейтинг*/
  currentRating: CARRIERS_RATING_VALUE;

  /** Расчетный рейтинг*/
  calculatedRating: CARRIERS_RATING_VALUE;
}
