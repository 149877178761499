import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { DOCUMENT_TYPE, IOrder, IOrdersFilter } from '@transport/ui-interfaces';

import { ITnState } from '../../state/index';
import { TnTableStateFacade } from '../../table/table-state-facade';
import { removeOrder, removeOrderSuccess } from '../actions/orders-remove.actions';
import { tableActions } from '../actions/orders-table-owner.actions';
import { tableSelectors, tnCryptoOrder } from '../selectors/orders-table-owner.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnOrdersTableOwnerFacade extends TnTableStateFacade<IOrdersFilter> {
  public readonly removeOrder$ = this.actions$.pipe(ofType(removeOrderSuccess));

  constructor(public store: Store<ITnState>, public actions$: Actions) {
    super(store, tableActions, tableSelectors);
  }

  public isTnCryptoDocumentOwnerSigning$(order: IOrder, docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(tnCryptoOrder.isTnCryptoDocumentOwnerSigning, { order, docType }));
  }

  public isTnCryptoDocumentSigned$(order: IOrder, docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(tnCryptoOrder.isTnCryptoDocumentSigned, { order, docType }));
  }

  public isTnCryptoDocumentOwnerSigned$(order: IOrder, docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(tnCryptoOrder.isTnCryptoDocumentOwnerSigned, { order, docType }));
  }

  public isTnCryptoDocumentSigningError$(order: IOrder, docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(tnCryptoOrder.isTnCryptoDocumentSigningError, { order, docType }));
  }

  public remove(id: string, isDraft: boolean) {
    this.store.dispatch(removeOrder({ orderId: id, isDraft }));
  }
}
