import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { AUCTION_CRITERION_CHOICES } from '@transport/ui-interfaces';
import { TnSupportedCurrency } from '@transport/ui-pipes';
import { ICommonSelectData } from 'libs/custom-controls/src/lib/common.interface';
import { BehaviorSubject } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'additional-auction-criteria[dateAdditionalCriteriaControl][vatAdditionalCriteriaControl]',
  templateUrl: './additional-auction-criteria.component.html',
  styleUrls: ['./additional-auction-criteria.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AdditionalAuctionCriteriaComponent implements OnInit {

  @Input() public currency = TnSupportedCurrency.RUB;
  @Input() public arriveDatetimeTo = '';
  @Input() public arriveDatetimeFrom = '';
  @Input() public dateAdditionalCriteriaControl!: UntypedFormControl;
  @Input() public vatAdditionalCriteriaControl!: UntypedFormControl;
  @Input() public additionalAuctionCriteria: { type: AUCTION_CRITERION_CHOICES; weight: number }[] = [];

  @Input() public set myOrgAdditionalCriteriaData(data: { type: AUCTION_CRITERION_CHOICES; value: number }[] | null) {
    this._myOrgAdditionalCriteriaData = data;
    this.dateOptions$.next(this.getDateOptions()); //Для случая, когда заявка меняеться чемер нотификации, без дестроя компонента
    this.dateAdditionalCriteriaControl.setValue({
      id: this.myOrgAdditionalCriteriaData?.find(item => item.type === AUCTION_CRITERION_CHOICES.FIRST_LOADING_DATE)?.value ?? 0,
    });
  }

  _myOrgAdditionalCriteriaData: { type: AUCTION_CRITERION_CHOICES; value: number }[] | null = null;
  public get myOrgAdditionalCriteriaData() {
    return this._myOrgAdditionalCriteriaData;
  }

  public get firstDateCriteriaWeight() {
    return this.additionalAuctionCriteria ? this.additionalAuctionCriteria[0]?.weight : 0;
  }
  
  public dateOptions$ = new BehaviorSubject<ICommonSelectData[] | null>(null);
  public AUCTION_CRITERION_CHOICES = AUCTION_CRITERION_CHOICES;

  constructor() { }

  ngOnInit() {
    this.dateOptions$.next(this.getDateOptions());
    this.dateAdditionalCriteriaControl.setValue({
      id: this.myOrgAdditionalCriteriaData?.find(item => item.type === AUCTION_CRITERION_CHOICES.FIRST_LOADING_DATE)?.value ?? 0,
    });
  }

  public get datesDiff() {
    return moment(this.arriveDatetimeTo, ['DD.MM.YYYY', 'YYYY-MM-DD']).diff(
      moment(this.arriveDatetimeFrom, ['DD.MM.YYYY', 'YYYY-MM-DD']),
      'days',
    );
  }

  public getDateOptions() {
    const diff = this.datesDiff;
    const options: ICommonSelectData[] = [];
    for (let i = 0; i <= diff; i++) {
      options.push({
        id: i,
        label: `${moment(this.arriveDatetimeFrom, ['DD.MM.YYYY', 'YYYY-MM-DD']).add(i, 'days').format('DD.MM.YYYY')}`,
      });
    }
    return options;
  }
}
