import { transport } from '@transport/proto';
import { TModify } from '@transport/ui-utils';

import {
  auctionProlongationParametersFromDto,
  createNewAuctionProlongationParameters,
  insuranceParametersFromDto,
} from '../lot/auction-prolongation-parameters.interface';
import { TnSupportedCurrency } from '@transport/ui-pipes';

/**
 * @note TODO: something's wrong with integration here. The dto is weird.
 */
export interface IAdminOrganizationDto extends transport.IAdminOrganization {
  registrationNumber: string;
  auctionParameters: transport.IAuctionProlongationParameters & {
    minutesOfProlongation?: number;
    earlyFinish?: boolean;
    minStepPrice?: number;
    minPriceStepPercent?: number;
    typeOfPriceStep?: 'FIXED' | 'PERCENT';
  };
  paymentTypeId: string | null;
  vat?: transport.IOrganizationVatType | null;
  currency: TnSupportedCurrency | null;
  facsimileImg?: string | null;
}

export interface IAdminOrganization extends transport.IAdminOrganization {
  facsimileImg?: string | null;
}

export type TAdminOrganizationForm = TModify<
  Pick<
    Partial<IAdminOrganization>,
    | 'inn'
    | 'id'
    | 'email'
    | 'kpp'
    | 'companyName'
    | 'legalAddress'
    | 'ogrn'
    | 'phoneNumber'
    | 'signer'
    | 'isCompanyGroup'
    | 'postalAddress'
    | 'showOrderExternalNo'
    | 'paymentPeriod'
    | 'extraConditionsForMarket'
    | 'enableDeliveryTracking'
    | 'subdomain'
    | 'logo'
    | 'documentFlowSettings'
    | 'carrierContractSigning'
    | 'carrierContractRequired'
    | 'currency'
    | 'facsimileImg'
  >,
  {
    legalForm?: transport.ILegalForm | null;
    paymentType: transport.IPaymentType | null;
    auctionProlongationParameters?: transport.IAuctionProlongationParameters | null;
    auctionLotStepParameters?: {
      typeOfPriceStep?: 'FIXED' | 'PERCENT' | null;
      minStepPrice?: number | null;
      minPriceStepPercent?: number | null;
    } | null;
    earlyFinish?: boolean | null;
    insuranceParameters: transport.IInsuranceParameters | null;
    vat: transport.IOrganizationVatType | null | undefined;
    currency: TnSupportedCurrency | null | undefined;
  }
>;

export const createNewAdminOrganization = (input: Partial<IAdminOrganization> = {}) => {
  input.auctionProlongationParameters = !Boolean(input.auctionProlongationParameters)
    ? createNewAuctionProlongationParameters(input.auctionProlongationParameters ?? {})
    : input.auctionProlongationParameters;
  const res = transport.AdminOrganization.toObject(new transport.AdminOrganization(input), {
    defaults: true,
  }) as IAdminOrganization;
  res.facsimileImg = input.facsimileImg;
  return res;
};

/**
 * Temporary method.
 * Map data from api-object.
 */
export const fromDto = (dtoData: IAdminOrganization): IAdminOrganization => {
  const data = dtoData as IAdminOrganizationDto;
  return createNewAdminOrganization({
    companyName: data.name,
    ogrn: data.registrationNumber,
    isCompanyGroup: data.isCompanyGroup,
    legalForm: data.legalForm,
    id: data.id,
    email: data.email,
    postalAddress: data.postalAddress,
    inn: data.inn,
    kpp: data.kpp,
    phoneNumber: data.phoneNumber,
    legalAddress: data.legalAddress,
    signer: data.signer,
    vat: data.vat,
    currency: data.currency,
    auctionProlongationParameters: auctionProlongationParametersFromDto(data.auctionParameters),
    additionalAuctionParameters: {
      minStepPrice: data.auctionParameters?.minStepPrice,
      minPriceStepPercent: data.auctionParameters?.minPriceStepPercent,
      typeOfPriceStep: data.auctionParameters?.typeOfPriceStep,
      earlyFinish: data.auctionParameters?.earlyFinish,
    },
    insuranceParameters: insuranceParametersFromDto(data.insuranceParameters ?? void 0),
    showOrderExternalNo: data.showOrderExternalNo,
    paymentType: data.paymentType,
    paymentPeriod: data.paymentPeriod,
    extraConditionsForMarket: data.extraConditionsForMarket,
    enableDeliveryTracking: data.enableDeliveryTracking,
    isArchived: data.isArchived,
    subdomain: data.subdomain,
    logo: data.logo,
    facsimileImg: data.facsimileImg,
    documentFlowSettings: data.documentFlowSettings,
    carrierContractSigning: data.carrierContractSigning,
    carrierContractRequired: data.carrierContractRequired,
  });
};

/**
 * Temporary method.
 * Map data to api-object.
 */
export const toDto = (data: any): IAdminOrganizationDto => {
  return {
    registrationNumber: data.ogrn ?? '',
    name: data.companyName,
    email: data.email,
    postalAddress: data.postalAddress,
    id: data.id,
    kpp: data.kpp,
    phoneNumber: data.phoneNumber,
    legalAddress: data.legalAddress,
    signer: data.signer,
    vat: data.vat,
    currency: data.currency ?? TnSupportedCurrency.RUB,
    auctionParameters: {
      isProlongationAvailable: data.auctionProlongationParameters?.isProlongationAvailable,
      minutesOfProlongation: data.auctionProlongationParameters?.prolongationTime ?? 0,
      minutesTillEndToProlong: data.auctionProlongationParameters?.minutesTillEndToProlong,
      timesOfProlongation: data.auctionProlongationParameters?.prolongationCount ?? 0,
      typeOfPriceStep: data.auctionLotStepParameters?.typeOfPriceStep ?? void 0,
      minStepPrice: data.auctionLotStepParameters?.minStepPrice ?? 0,
      minPriceStepPercent: data.auctionLotStepParameters?.minPriceStepPercent ?? 0,
      earlyFinish: data.earlyFinish ?? false,
    },
    showOrderExternalNo: data.showOrderExternalNo,
    paymentTypeId: data.paymentType?.id ?? null,
    paymentPeriod: data.paymentPeriod,
    extraConditionsForMarket: data.extraConditionsForMarket,
    insuranceParameters: {
      insureByDefault: data.insuranceParameters?.insureByDefault,
    },
    enableDeliveryTracking: data.enableDeliveryTracking,
    subdomain: data.subdomain,
    logo: data.logo,
    facsimileImg: data.facsimileImg,
    documentFlowSettings: data.documentFlowSettings,
    carrierContractSigning: data.carrierContractSigning,
    carrierContractRequired: data.carrierContractRequired,
  };
};
