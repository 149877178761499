import { DatePipe } from '@angular/common';
import { IAgentCarrier, STATUS } from '@transport/ui-interfaces';
import { IMarketplaceDriver, IMarketplaceDriverInput } from './driver.interface';
import { COUNTRIES, TnSupportedCountry } from '@transport/ui-pipes';

export function readMarketplaceDriver(driver?: any): IMarketplaceDriverInput {
  const country = driver?.country || TnSupportedCountry.RU;

  return {
    id: driver?.id,
    accrStatus: driver?.accrStatus || STATUS.NEW,
    accrComment: driver?.accrComment || '',
    name: driver?.name,
    surname: driver?.surname,
    patronymic: driver?.patronymic,
    birthday: driver?.birthday,
    phone: driver?.phone,
    addPhone: driver?.addPhone,
    country: COUNTRIES.find(c => c.id === country),
    iin: driver?.iin,
    inn: driver?.inn,
    passportSeries: driver?.passportSeries,
    passportNumber: driver?.passportNumber,
    passportPlace: driver?.passportPlace,
    passportDate: driver?.passportDate,
    identityCardNumber: driver?.identityCardNumber,
    identityCardPlace: driver?.identityCardPlace,
    identityCardDate: driver?.identityCardDate,
    licenceSeries: driver?.licenceSeries,
    licenceNumber: driver?.licenceNumber,
    licencePlace: driver?.licencePlace,
    licenceDate: driver?.licenceDate,
    agentCarrierId: setAgentCarrierData(driver?.agentCarrier),
    blackListDriver: {
      limitationType: !!driver?.blackListInfo?.find(item => item.workProhibited),
      message: driver?.blackListInfo,
    },
  };
}

export function driverToServer(driver: IMarketplaceDriverInput, datePipe: DatePipe) {
  const newDriver: IMarketplaceDriver = {
    id: driver?.id,
    birthday: datePipe.transform(driver?.birthday, 'yyyy-MM-dd'),
    country: driver?.country?.id as string,
    phone: driver?.phone ? `${driver?.phone}` : null,
    addPhone: driver?.addPhone ? `${driver?.addPhone}` : null,
    licenceSeries: driver?.licenceSeries,
    licenceNumber: driver?.licenceNumber,
    licencePlace: driver?.licencePlace,
    licenceDate: datePipe.transform(driver?.licenceDate, 'yyyy-MM-dd'),
    surname: driver?.surname,
    name: driver?.name,
    inn: driver?.inn ?? null,
    patronymic: driver?.patronymic,
    passportSeries: driver?.passportSeries || null,
    passportNumber: driver?.passportNumber || null,
    passportPlace: driver?.passportPlace || null,
    passportDate: datePipe.transform(driver?.passportDate, 'yyyy-MM-dd') || null,
    identityCardNumber: driver?.identityCardNumber || null,
    identityCardPlace: driver?.identityCardPlace || null,
    identityCardDate: datePipe.transform(driver?.identityCardDate, 'yyyy-MM-dd') || null,
    agentCarrierId: driver?.agentCarrierId?.id || null,
    iin: null,
  };

  if (driver?.country?.id === TnSupportedCountry.KZ) {
    newDriver.iin = driver?.iin;
  }

  return newDriver;
}

export function setAgentCarrierData(org: IAgentCarrier | null) {
  if(org) {
    return {
      label: `${org.carrierName} (${org.inn || org.bin || org.iin})`,
      id: org.id ?? '',
    };
  }
  return null;
}
