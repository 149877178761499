import { transport } from '@transport/proto';

export const adminProfileSharedFeatureKey = 'shared';

export interface IAdminProfileSharedState {
  legalFormsDict: transport.ILegalForm[];
  vatTypesDict: transport.IOrganizationVatType[];
  paymentTypesDict: transport.IPaymentType[];
  defaultContract: transport.Insurance.IContract;
}

export const adminProfileSharedInitialState: IAdminProfileSharedState = {
  legalFormsDict: [],
  vatTypesDict: [],
  paymentTypesDict: [],
  defaultContract: {},
};
