export const RU_API_BOUND_NAMING = {
  request: {
    error: {
      objectNotFound: 'Данные не найдены',
      invalidObjectStatePosition: 'Недопустимая операция',
      objectAccessForbidden: 'Отсутствует доступ к данным',
      invalidParam: 'Неверные параметры запроса',
      invalidObjectStateTransition: 'Некорректная операция над заявкой',
    },
  },
};
