import { createReducer, on } from '@ngrx/store';

import { subOrganizationProfileActions } from './sub-organization-profile.actions';
import { subOrganizationProfileInitialState } from './sub-organization-profile.state';

const subOrganizationProfileReducer = createReducer(
  subOrganizationProfileInitialState,
  // @ts-ignore
  on(subOrganizationProfileActions.loadSubOrganizationProfileSuccess, (state, { profile }) => {
    return {
      ...state,
      reactiveFormValue: {
        ...profile,
        legalForm: profile.legalForm ?? null,
        paymentType: profile.paymentType ?? {},
        auctionProlongationParameters: profile.auctionProlongationParameters ?? {},
        insuranceParameters: profile.insuranceParameters ?? {},
        vat: profile.vat?.id ?? null,
        currency: profile.currency ?? null,
      },
      loading: false,
      isArchived: Boolean(profile.isArchived),
    };
  }),

  on(subOrganizationProfileActions.loadSubOrganizationProfileStart, state => ({
    ...state,
    loading: true,
  })),
);

export function subOrganizationReducer(state, action) {
  return subOrganizationProfileReducer(state, action);
}
