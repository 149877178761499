import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Inject,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { APP_ENV, TnAppEnvironment } from '@transport/ui-interfaces';
import { interval, Subject, takeUntil } from 'rxjs';

import { isAllowedDomain, prepareDomain } from './is-allowed-domain';
import { ICommandInterface, SSO_ACTION_TYPE } from './models';
import { TnClSsoFrameService } from './tncl-sso-frame-service.service';

@Component({
  selector: 'transport-tncl-sso-frame',
  templateUrl: './tncl-sso-frame.component.html',
  styleUrls: ['./tncl-sso-frame.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnClSsoFrameComponent implements AfterViewInit, OnDestroy {
  public ssoUrl = `https://${this.env.ssoUrl}`;

  constructor(private readonly ssoService: TnClSsoFrameService, @Inject(APP_ENV) public readonly env: TnAppEnvironment) {}

  private readonly destroy$: Subject<boolean> = new Subject<boolean>();

  private frameDocument;

  private readonly loaded$: Subject<boolean> = new Subject<boolean>();

  private readonly stopTimer$: Subject<boolean> = new Subject<boolean>();

  @Output() private readonly ssoLogout = new EventEmitter();

  @ViewChild('iframeId')
  private readonly frame!: ElementRef;

  @HostListener('window:message', ['$event'])
  public onMessage(event: MessageEvent): void {
    const originDomain = prepareDomain(event.origin);
    if (!isAllowedDomain(originDomain)) {
      return;
    }
    if (event.data.action === SSO_ACTION_TYPE.RETURN) {
      this.stopTimer$.next(true);
      this.ssoService.pushToken(event.data.value);
    }
    if (event.data.action === SSO_ACTION_TYPE.LOGOUT) {
      this.ssoLogout.emit();
    }
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
  }

  public ngAfterViewInit(): void {
    this.ssoService.commandStream$.pipe(takeUntil(this.destroy$)).subscribe((command: ICommandInterface) => {
      this.frameDocument?.postMessage({ action: command?.action, message: command?.data }, '*'); //TODO  указать точное целевое происхождение вместо '*'
    });
    this.frameDocument = this.frame.nativeElement.contentWindow;
    this.loaded$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.ssoService.pushCommand({ action: SSO_ACTION_TYPE.GET });
    });
    const delay = 100;
    const timer = interval(delay);
    timer.pipe(takeUntil(this.stopTimer$)).subscribe(() => {
      this.loaded$.next(true);
    });
  }
}
