import { IDriverInResponse, IDriverInTable } from './drivers-table.interface';

export const driversResponseToDomain: (data: IDriverInResponse) => IDriverInTable = data => {
  return {
    id: data.id,
    accrStatus: data?.accrStatus,
    accrComment: data?.accrComment,
    phone: data?.phone,
    surname: mapNullToUndefined(data.surname),
    name: mapNullToUndefined(data.name),
    patronymic: mapNullToUndefined(data.patronymic),
    passportSeries: mapNullToUndefined(data.passportSeries),
    passportNumber: mapNullToUndefined(data.passportNumber),
    isArchived: data.isArchived,
    usesDriverMobile: mapNullToFalse(data.usesDriverMobile),
    hasBeenInvitedToDriverMobile: data.hasBeenInvitedToDriverMobile,
    agentCarrier: data?.agentCarrier,
    blackListDriver: {
      limitationType: !!data.blackListInfo?.find(item => item.workProhibited),
      message: data.blackListInfo,
    },
  };
};

function mapNullToUndefined<T>(data: T | null): T | undefined {
  return data === null ? undefined : data;
}

function mapNullToFalse<T>(data: T | null): T | boolean {
  return data === null ? false : data;
}

