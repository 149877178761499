import { AbstractControl, FormArray, FormControl, FormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';
import {
  AbstractControl as NgNeatAbstractControl,
  FormArray as NgNeatFormArray,
  FormControl as NgNeatFormControl,
  FormGroup as NgNeatFormGroup,
} from '@ngneat/reactive-forms';
import { DEFAULT_MONTHS_COUNT, PLACE_TYPE_POINT } from '@transport/ui-utils';
import moment, { Moment } from 'moment';
import { combineLatest, fromEvent, Observable, of } from 'rxjs';
import { finalize, shareReplay, switchMap } from 'rxjs/operators';

const ADULT_AGE = 18;
/**
 * Triggers form validation.
 * @param control form control
 */
export function triggerFormValidation(
  control: AbstractControl | NgNeatAbstractControl | FormGroup | NgNeatFormGroup | FormArray | NgNeatFormArray,
) {
  if (control instanceof FormGroup || control instanceof NgNeatFormGroup) {
    const group = control;
    const controlKeys = Object.keys(control.controls);
    for (const key of controlKeys) {
      const c = group.controls[key];
      triggerFormValidation(c);
    }
  }
  if (control instanceof FormArray || control instanceof NgNeatFormArray) {
    const group = control;
    const controlKeys = Object.keys(group.controls);
    for (const field of controlKeys) {
      const c = group.controls[field];
      triggerFormValidation(c);
    }
  }

  control.markAsTouched();
  control.updateValueAndValidity({ onlySelf: false });

  // TODO: debug
  // console.warn('value:', control.value, '| valid:', control.valid);
}

/**
 * Time intersection validation
 */
export function timesRangeValidator(): ValidatorFn {
  const parseTime = (value: string) => {
    const parts = value.split(':');
    const MINUTES_IN_HOUR = 60;
    return Number(parts[0]) * MINUTES_IN_HOUR + Number(parts[1]);
  };

  return (formControl: AbstractControl) => {
    const value: { fromTime; toTime } = formControl?.value;
    const fromTime = value?.fromTime;
    const toTime = value?.toTime;
    if (Boolean(fromTime) && Boolean(toTime)) {
      const from = parseTime(fromTime);
      const to = parseTime(toTime);
      if (from > to) {
        return { outOfBounds: true };
      }
    }

    return null;
  };
}

export function timesRangeRequiredValidator(): ValidatorFn {
  return (formControl: AbstractControl) => {
    const value: { fromTime; toTime } = formControl?.value;
    const fromTime = Boolean(value?.fromTime);
    const toTime = Boolean(value?.toTime);
    if (!Boolean(fromTime) || !Boolean(toTime)) {
      return { required: true };
    }

    return null;
  };
}

export function lessThanNowValidator(timeField: string, dateField?: string): ValidatorFn {
  return (control: AbstractControl) => {
    const isTimeControl = Boolean(dateField) && !Boolean(timeField);
    const time: string = Boolean(timeField)
      ? control.parent?.controls[timeField].value ?? ''
      : Boolean(isTimeControl)
      ? control.value
      : '23:59';

    const date = Boolean(dateField)
      ? moment(control.parent?.controls[dateField as string].value).format('YYYY-MM-DD')
      : moment(control.value).format('YYYY-MM-DD');
    if (Boolean(control.value) && Boolean(time)) {
      const now = moment();
      const dateTime = moment(`${date} ${time}`);
      if (now.isAfter(dateTime)) {
        return {
          lessThanNow: true,
        };
      }
    }
    return null;
  };
}

export function multiplicityValidator(stepField: string): ValidatorFn {
  return (control: AbstractControl) => {
    const step: number = control.parent?.controls[stepField].value;
    if (Boolean(control.value)) {
      if (Number(control.value) % (step ?? 0) !== 0) {
        return {
          multiplicity: true,
        };
      }
    }
    return null;
  };
}

export function moreThanNowValidator(): ValidatorFn {
  return formControl => {
    const value: moment.Moment = formControl.value;
    if (Boolean(value)) {
      const now = moment();
      if (now.isBefore(value)) {
        return {
          moreThanNow: true,
        };
      }
    }
    return null;
  };
}

export function lessThanAdultAgeValidator(): ValidatorFn {
  return formControl => {
    const value: moment.Moment = formControl.value;
    const now = moment();
    if (Boolean(value)) {
      const years = now.diff(value, 'months') / DEFAULT_MONTHS_COUNT;
      if (!now.isBefore(value) && years < ADULT_AGE) {
        return {
          lessThanAdultAge: true,
        };
      }
    }
    return null;
  };
}

// eslint-disable-next-line max-lines-per-function
export function timeToMoreThanTimeFromValidator(
  timeFromFields: string,
  timeToFields: string,
  dateFromFields?: string,
  dateToFields?: string,
): ValidatorFn {
  // eslint-disable-next-line complexity
  return (formGroup: AbstractControl) => {
    const dateFromValue = getDateValueFromControl(dateFromFields ? formGroup.get(dateFromFields)?.value : null);
    const dateToValue = getDateValueFromControl(dateToFields ? formGroup.get(dateToFields)?.value : null);

    const timeFromControl = formGroup.get(timeFromFields);
    const timeToControl = formGroup.get(timeToFields);
    const timeFormat = 'HH:mm';
    const errorKey = 'invalidTimeFrom';
    let error: { [errorKey]: boolean } | null = null;

    if (
      Boolean(timeFromControl?.value) &&
      Boolean(timeToControl?.value) &&
      moment(timeFromControl?.value, timeFormat) > moment(timeToControl?.value, timeFormat)
    ) {
      if (!Boolean(dateFromValue) || !Boolean(dateToValue) || dateFromValue === dateToValue) {
        error = { [errorKey]: true };
        timeFromControl?.markAsTouched();
      }
    }

    function resetControlErrors(control?: AbstractControl | null) {
      let controlErrors: ValidationErrors | null = control?.errors ? { ...control.errors } : null;
      if (controlErrors) {
        controlErrors = Object.entries(controlErrors)
          .filter(([key]) => key !== errorKey)
          .reduce((acc, [key, value]) => Object.assign(acc, { [key]: value }), {});
        controlErrors = Object.keys(controlErrors).length ? controlErrors : null;
      }

      const resultErrors = controlErrors || error ? { ...control?.errors, ...error } : null;

      control?.setErrors(resultErrors);
    }

    if (Boolean(timeFromControl?.value)) {
      resetControlErrors(timeFromControl);
    }

    if (Boolean(timeToControl?.value)) {
      resetControlErrors(timeToControl);
    }
    return error;
  };
}

function getDateValueFromControl(date: Moment | string): string | null {
  if (!Boolean(date)) {
    return null;
  }

  const dateFormat = 'YYYY.MM.DD';
  return moment(date).format(dateFormat);
}

export function requiredDurationValidator(checkMode: (g: FormGroup | FormArray) => boolean, format = 'HH:mm'): ValidatorFn {
  return (control: AbstractControl) => {
    const wrongMode = control.parent === null ? false : !Boolean(checkMode) || !checkMode(control.parent);
    const value = moment(control.value, format);
    return Boolean(wrongMode) || !value.isValid() || Boolean(value.minutes()) || Boolean(value.hours())
      ? null
      : {
          emptyDuration: true,
        };
  };
}

export function trimmedStringValidator(control: AbstractControl) {
  if (Boolean(control.value) && control.value.trim().length === 0) {
    return {
      trimmedString: true,
    };
  }
  return null;
}

export function sameFieldValueValidator(fieldName: string, casesensetive = true): ValidatorFn {
  return (control: AbstractControl) => {
    let compariseValue: string = control.parent?.controls[fieldName].value;
    let controlValue: string = control.value;
    compariseValue = casesensetive ? compariseValue : compariseValue?.toLowerCase();
    controlValue = casesensetive ? controlValue : controlValue?.toLowerCase();

    if (compariseValue === controlValue) {
      (control.parent?.controls[fieldName] as NgNeatFormControl).removeError('sameFieldValue');
    }

    return compariseValue !== controlValue ? { sameFieldValue: true } : null;
  };
}

// Conditional apply validator
export function conditionalValidator(predicate, validator) {
  return (formControl: FormControl) => {
    if (formControl.parent === null) {
      return null;
    }
    if (Boolean(predicate(formControl))) {
      const res = validator(formControl);
      return res;
    }
    return null;
  };
}

export const formFillingValidator = (form: FormGroup): ValidationErrors | null => {
  if (form.touched) {
    const controlWithErrors = Object.keys(form.controls).filter(key => {
      const control = form.get(key);
      return Boolean(control?.touched) && Boolean(control?.errors);
    });
    return controlWithErrors.length > 0 ? { fillingErrors: true } : null;
  }
  return null;
};

export function dirtyFormLeavePageCheck() {
  return (source: Observable<boolean>): Observable<boolean> => {
    let isDirty = false;
    const windowUnloadSubscription = fromEvent(window, 'beforeunload').subscribe(event => {
      if (isDirty) {
        event.returnValue = false;
      }
    });
    return source.pipe(
      switchMap(value => {
        isDirty = value;
        return of(isDirty);
      }),
      finalize(() => {
        windowUnloadSubscription.unsubscribe();
      }),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
  };
}

export function dirtyCheckFormHandle(formDirty: Observable<boolean>, ignoreDirty: Observable<boolean>): Observable<boolean> {
  return combineLatest([formDirty, ignoreDirty]).pipe(
    switchMap(([dirty, ignore]) => (ignore ? of(false) : of(dirty))),
    dirtyFormLeavePageCheck(),
  );
}

/**
 * Checks, if form controll contains required value
 * Can be used for conditional validation
 * @param fieldName Form control name
 */
export const checkFormControllValue = (fieldName: string) => value => (group: FormGroup | FormArray) =>
  (group as FormGroup)?.controls[fieldName]?.value === value;

export function requireCheckboxesToBeCheckedValidator(controlNames: string[], minRequired = 1) {
  return (formGroup: AbstractControl | FormGroup): { [key: string]: any } | null => {
    let checked = 0;
    controlNames.forEach(el => {
      const control = formGroup.get(el);
      if (control?.value) {
        checked++;
      }
    });

    if (checked < minRequired) {
      return {
        requireCheckboxesToBeChecked: true,
      };
    }

    return null;
  };
}

export function isValidDate(isRequired): ValidatorFn {
  return (formControl: AbstractControl) => {
    const value: string | moment.Moment = formControl?.value;
    const formats = ['DD.MM.YYYY', 'YYYY-MM-DD'];
    if (Boolean(value) && moment(value, formats).isValid()) {
      return null;
    }
    if (!Boolean(value) && !Boolean(isRequired)) {
      return null;
    }
    return { isDateInvalid: true };
  };
}
export function isValidTime(isRequired, maxSimbols): ValidatorFn {
  return (formControl: AbstractControl) => {
    const value: string | null = formControl?.value;
    // @ts-ignore
    if (Boolean(value) && value?.length < maxSimbols) {
      return { isTimeInvalid: true };
    }
    return null;
  };
}

export function isEmptyList(control: AbstractControl) {
  const value: [] | null = control?.value;
  if (Boolean(value) && !value?.length) {
    return { isEmptyList: true };
  }
  return null;
}

export function temperatureRangeValidator(): ValidatorFn {
  return (formControl: AbstractControl) => {
    const { from, to } = formControl?.value;
    if (from !== null && to !== null && Number(from) > Number(to)) {
      return { temperatureRangeError: true };
    }
    return null;
  };
}

export function innValidator(msg: { innLegal?: boolean; innError?: boolean }, length: number) {
  return (control: AbstractControl): ValidationErrors | null => {
    return control.value && control.value.length !== length ? msg : null;
  };
}
