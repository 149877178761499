import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { ITnState } from '../../../state';
import { TnTableStateFacade } from '../../../table/table-state-facade';
import { removeDriver, removeDriverSuccess } from './actions/driver-remove.actions';
import { tableActions } from './actions/drivers.actions';
import { tableSelectors } from './selectors/drivers.selectors';

export interface IDriversFilter {
  surname?: string;
  namePatronymic?: string;
  fullName?: string;
  phone?: string;
  availability?: string | null;
}

@Injectable({
  providedIn: 'root',
})
export class TnDirectoryDriversFacade extends TnTableStateFacade<IDriversFilter> {
  public removeDriver$ = this.action.pipe(ofType(removeDriverSuccess));

  constructor(public store: Store<ITnState>, public action: Actions) {
    super(store, tableActions, tableSelectors);
  }

  public removeDriver(id: string, name = '') {
    this.store.dispatch(removeDriver({ id, name }));
  }
}
