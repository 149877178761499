import { createReducer, on } from '@ngrx/store';
import { ICarrierOrganization } from '@transport/ui-interfaces';

import * as actions from '../actions/carrier-filter-list.actions';

export interface ICarrierFilterListState {
  carriersList: Partial<ICarrierOrganization>[];
}

export const initialState: ICarrierFilterListState = {
  carriersList: [],
};

const groupReducer = createReducer(
  initialState,
  on(actions.loadCarrierFilterListSuccess, (state, { list }) => {
    return {
      ...initialState,
      carriersList: list,
    };
  }),
);

export function carrierFilterListReducer(state, action) {
  return groupReducer(state, action);
}
