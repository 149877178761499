import { AbstractControl as NgAbstractControl } from '@angular/forms';
import { AbstractControl } from '@ngneat/reactive-forms';
import { ValidationErrors, ValidatorFn } from '@ngneat/reactive-forms/lib/types';
import { TranslateService } from '@ngx-translate/core';

import { ILot } from '../lot/lot.interface';

export const betValidatorConstructor =
  (translate: TranslateService, lot: ILot): ValidatorFn =>
  // eslint-disable-next-line complexity -- TODO: tech debt
  (control: AbstractControl | NgAbstractControl): ValidationErrors | null => {
    const bet = Number(control.value);
    const errors = { valid: { value: true } };

    if (bet <= 0) {
      errors.valid.value = translate.instant('carrier.order.section.auction.field.bet.error.shouldMoreZero');
      return errors;
    }

    const nextMaxBet = Boolean(lot.lastBet) ? (lot.lastBet ?? 0) - (lot.minStepPrice ?? 0) : lot.startPrice;
    if (nextMaxBet === 0) {
      errors.valid.value = translate.instant('carrier.order.section.auction.field.bet.error.minimumBet', { value: nextMaxBet });
      return errors;
    }

    if (bet > (nextMaxBet ?? 0)) {
      errors.valid.value = translate.instant('carrier.order.section.auction.field.bet.error.shouldNotBeMore', { value: nextMaxBet });
      return errors;
    }

    // Price should be the same as startPrice or multiple of minimal bet
    const isMultipleOfStep = (bet - (Boolean(lot.lastBet) ? lot.lastBet ?? 0 : lot.startPrice ?? 0)) % (lot.minStepPrice ?? 1) === 0;
    if (!isMultipleOfStep) {
      errors.valid.value = translate.instant('carrier.order.section.auction.field.bet.error.multipleOfStep', { value: lot.minStepPrice });
      return errors;
    }

    return null;
  };
