import { Action, createReducer, on } from '@ngrx/store';

import { getTimezonesSuccess } from './time-zone.actions';
import { ITimezoneState, timezoneInitialState } from './time-zone.state';

const createTimezoneReducer = createReducer(
  timezoneInitialState,

  on(getTimezonesSuccess, (state, payload) => {
    return {
      timezones: payload.timezones,
    };
  }),
);

export function timezoneReducer(state: ITimezoneState, action: Action) {
  return createTimezoneReducer(state, action);
}
