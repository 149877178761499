import { Inject, Injectable } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { SidebarItem } from '@transport/sidebar';
import { BehaviorSubject, combineLatest, debounceTime, map, take } from 'rxjs';
import { TIMEOUT } from '@transport/ui-utils';
import { NavigationEnd, Router } from '@angular/router';
import { TnAlertService } from '@transport/ui-kit';

@UntilDestroy()
@Injectable()
export class SidebarGuardService {
  allowedItems$ = new BehaviorSubject<SidebarItem[]>([]);
  disabledRoutes$ = this.allowedItems$.pipe(map(items => this.getRoutes(this.config).filter(x => !this.getRoutes(items).includes(x))));

  constructor(@Inject('sidebarConfig') public config: SidebarItem[], public router: Router, private readonly alertService: TnAlertService) {
    this.disabledRoutes$.pipe(debounceTime(TIMEOUT.DEFAULT), take(1)).subscribe(routes => {
      if (routes.includes(this.router.url)) {
        this.router.navigate(['/']);
        this.alertService.shortError('Недостаточно прав для просмотра раздела');
      }
    });

    combineLatest([router.events, this.disabledRoutes$])
      .pipe(untilDestroyed(this), debounceTime(TIMEOUT.SHORT))
      .subscribe(([router, routes]) => {
        if (router instanceof NavigationEnd && routes.includes(router.url)) {
          this.router.navigate(['/']);
          this.alertService.shortError('Недостаточно прав для просмотра раздела');
        }
      });
  }

  public getRoutes(items: SidebarItem[]) {
    const routes = items.map(item => (item.children ? this.getRoutes(item.children) : item.route));
    let result: string[] = [];

    routes.forEach(route => {
      if (typeof route === 'string') result.push(route);
      if (Array.isArray(route)) {
        result = [...result, ...route];
      }
    });

    return result;
  }

  public setAllowedItems(items: SidebarItem[]) {
    this.allowedItems$.next(items);
  }
}
