import { FocusMonitor } from '@angular/cdk/a11y';
import { Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, Optional, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { UntilDestroy } from '@ngneat/until-destroy';

import { TnBaseMatInputDirective } from '../base-mat-input';

@UntilDestroy()
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- TODO: tech debt
@Component({
  selector: 'transport-time-input',
  templateUrl: './time-input.component.html',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TnTimeInputComponent,
    },
  ],
  styleUrls: ['./time-input.component.scss'],
})
/* eslint-disable prettier/prettier -- prettier conflicts with eslint (brace style), eslint takes precedence here */
export class TnTimeInputComponent
  extends TnBaseMatInputDirective
  implements ControlValueAccessor, OnDestroy, DoCheck, MatFormFieldControl<unknown> {
  @Input() dataTest = ''
    /* eslint-enable prettier/prettier -- prettier conflicts with eslint in this case (brace style), eslint takes precedence here */
  public static nextId = 0;

  @HostBinding('attr.id')
  public id = `time-input-${(TnTimeInputComponent.nextId += 1)}`;

  @ViewChild('timeInput')
  public timeInput?: ElementRef<HTMLInputElement>;

  constructor(public fm: FocusMonitor, public elRef: ElementRef<HTMLElement>, @Optional() public ngControl: NgControl) {
    super(fm, elRef, ngControl);
  }

  @HostBinding('class.floating')
  public get shouldLabelFloat() {
    // ALTERNATIVE return this.focused || !this.empty;
    return true;
  }

  public get emptyValue() {
    return this.timeInput?.nativeElement.value === '––:––';
  }

  public onChange(event: string) {
    const LENGTH_VALID_TIME_VALUE = 5;
    if (event.length === LENGTH_VALID_TIME_VALUE) {
      this.value = event;
      this.propagateChange(this.value);
    } else {
      this.value = '';
      this.propagateChange('');
    }
    this.onTouched(); // it needs here, after propagateChanges (for formArray)
  }
}
