export const snakeToCamelCase = (input: string) =>
  input.replace(/[_]([a-z])/g, group => {
    return group.toUpperCase().replace('_', '');
  });

export const camelToSnakeCase = (input: string) =>
  input.replace(/([A-Z])/g, group => {
    return `_${group.toLowerCase()}`;
  });

export const fromSnakeDto = <T1 = Record<string, Record<string, unknown>>, T2 = Record<string, Record<string, unknown>>>(input: T1) => {
  const copyObject = (snakeKeys: string[]) => {
    const result: Record<string, Record<string, unknown>> = {};
    for (const snakeKey of snakeKeys) {
      const camelKey = snakeToCamelCase(snakeKey);
      const dto = input as unknown as Record<string, Record<string, unknown>>;
      if (typeof dto[snakeKey] !== 'object') {
        result[camelKey] = dto[snakeKey];
      } else if (typeof dto[snakeKey] === 'object') {
        result[camelKey] = copyObject(Object.keys(dto[snakeKey]));
      }
    }
    return result;
  };
  const keys = Object.keys(input as Object);
  const result = copyObject(keys);
  return result as unknown as T2;
};

export const toSnakeDto = <T1 = Record<string, Record<string, unknown>>, T2 = Record<string, Record<string, unknown>>>(input: T1) => {
  const copyObject = (camelKeys: string[]) => {
    const result: Record<string, Record<string, unknown>> = {};
    for (const camelKey of camelKeys) {
      const snakeKey = camelToSnakeCase(camelKey);
      const dto = input as unknown as Record<string, Record<string, unknown>>;
      if (typeof dto[camelKey] !== 'object') {
        result[snakeKey] = dto[camelKey];
      } else if (typeof dto[camelKey] === 'object') {
        result[snakeKey] = copyObject(Object.keys(dto[camelKey]));
      }
    }
    return result;
  };
  const keys = Object.keys(input as Object);
  const result = copyObject(keys);
  return result as unknown as T2;
};
