import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TnUiKitModule } from '@transport/ui-kit';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnUiPipesModule } from '@transport/ui-pipes';
import { TnUiTranslateModule } from '@transport/ui-translate';
import { VerificationModule } from 'apps/marketplace/src/app/verification/verification.module';
import { AccreditationStatusBlockComponent } from './components/accreditation-status-block/accreditation-status-block.component';
import { MpTabsRoundedComponent } from './components/tabs-rounded/tabs-rounded.component';
import { PlaceWithDatetimeColumnComponent } from './components/ui-kit-table/table-columns/place-with-datetime/place-with-datetime-column.component';
import { PriceColumnComponent } from './components/ui-kit-table/table-columns/price/price-column.component';
import { MpStatusColumnComponent } from './components/ui-kit-table/table-columns/status-column/status-column.component';
import { TextColumnComponent } from './components/ui-kit-table/table-columns/text/text-column.component';
import { VehiclePublicationPriceComponent } from './components/ui-kit-table/table-columns/vehicle-publication-price/vehicle-publication-price.component';
import { VehicleRequiredColumnComponent } from './components/ui-kit-table/table-columns/vehicle-required/vehicle-required-column.component';
import { VehicleTypeColumnComponent } from './components/ui-kit-table/table-columns/vehicle-type/vehicle-type-column.component';
import { TableTagComponent } from './components/ui-kit-table/table-tag.component';
import { VerificationBlockComponent } from './components/verification-block/verification-block.component';

@NgModule({
  declarations: [
    TableTagComponent,
    TextColumnComponent,
    VehicleRequiredColumnComponent,
    PriceColumnComponent,
    VerificationBlockComponent,
    VehicleTypeColumnComponent,
    VehiclePublicationPriceComponent,
    PlaceWithDatetimeColumnComponent,
    MpTabsRoundedComponent,
    MpStatusColumnComponent,
    AccreditationStatusBlockComponent,
  ],
  imports: [CommonModule, TnUiTranslateModule, TnUiPipesModule, TnUiMaterialModule, TnUiKitModule, VerificationModule],
  exports: [
    TableTagComponent,
    TextColumnComponent,
    VehicleRequiredColumnComponent,
    PriceColumnComponent,
    VerificationBlockComponent,
    VehicleTypeColumnComponent,
    VehiclePublicationPriceComponent,
    PlaceWithDatetimeColumnComponent,
    MpTabsRoundedComponent,
    MpStatusColumnComponent,
    AccreditationStatusBlockComponent,
  ],
})
export class AppSharedModule {}
