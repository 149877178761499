import { Pipe, PipeTransform } from '@angular/core';
import { ICargoType } from '@transport/ui-interfaces';
import memo from 'memo-decorator';

@Pipe({
  name: 'cargoSize',
})
export class TnCargoSizePipe implements PipeTransform {
  @memo({
    resolver: (...args: any[]): string => JSON.stringify(args),
  })
  public transform({ lengthCargoPackage, widthCargoPackage, heightCargoPackage }: ICargoType) {
    const result: (number | string)[] = [];
    result.push(lengthCargoPackage ?? '- ', widthCargoPackage ?? '- ', heightCargoPackage ?? '- ');
    return result.join(', ');
  }
}
