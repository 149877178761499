import { transport } from '@transport/proto';

export const createNewLoadingType = (input?: Partial<transport.ILoadingType>) =>
  transport.LoadingType.toObject(new transport.LoadingType(input), {
    defaults: true,
  }) as transport.ILoadingType;

export const createNewVehicleType = (input?: Partial<transport.IVehicleType>) =>
  transport.VehicleType.toObject(new transport.VehicleType(input), {
    defaults: true,
  }) as transport.IVehicleType;

export const createNewLegalForm = (input?: Partial<transport.ILegalForm>) =>
  transport.LegalForm.toObject(new transport.LegalForm(input), {
    defaults: true,
  }) as transport.ILegalForm;

export enum VERIFICATION_STATUS {
  NOT_CHECKED = 'NOT_CHECKED',
  ON_WORK = 'ON_WORK',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum BLACK_LIST_COLUMN_TYPE {
  TRUCKS = 'trucks',
  DRIVERS = 'drivers',
  CHOOSE_TRUCK = 'chooseTruck',
  CHOOSE_DRIVER = 'chooseDriver',
}

export enum BLACK_LIST_TYPE {
  TRUCK = 'truck',
  DRIVER = 'driver',
  TRAILER = 'trailer',
}

export enum TABLE_FILTER {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  FINISHED = 'FINISHED',
  MY_RESPONSE = 'MY_RESPONSE',
}

export interface IBlackListViewData {
  companyCounts: number;
  prohibited: number;
  partProhibited: number;
  trailer: {
    prohibited: number;
    partProhibited: number;
    trailerList: transport.Vehicle.IBlackListVehicle[];
  } | null;
  reasonList: string[];
}

export enum BLACK_LIST_SECTION {
  PART_PROHIBITED = 'partProhibited',
  PROHIBITED = 'prohibited',
}

export const LEGAL_FORM_CODE_BACKEND_ENUM_PREFIX = '';

export enum LEGAL_FORM_CODES {
  LIMITED_LIABILITY_COMPANY = '12300',
  INDIVIDUAL_ENTREPRENEUR = '50102',
  SELF_EMPLOYED = '00000',
  PUBLIC_JOINT_STOCK_COMPANY = '12247',
  NON_PUBLIC_JOINT_STOCK_COMPANY = '12267',
}

export const JURISTIC_PERSON_CODES = [
  LEGAL_FORM_CODES.LIMITED_LIABILITY_COMPANY,
  LEGAL_FORM_CODES.PUBLIC_JOINT_STOCK_COMPANY,
  LEGAL_FORM_CODES.NON_PUBLIC_JOINT_STOCK_COMPANY,
];

export const PHYSICAL_PERSON_INN_CODES = [LEGAL_FORM_CODES.INDIVIDUAL_ENTREPRENEUR, LEGAL_FORM_CODES.SELF_EMPLOYED];

export const isJuridicalPerson = (code: LEGAL_FORM_CODES) => JURISTIC_PERSON_CODES.some(juristicPersonCode => juristicPersonCode === code);

export const hasPhysicalPersonInn = (code: LEGAL_FORM_CODES) =>
  PHYSICAL_PERSON_INN_CODES.some(physicalPersonInnCode => physicalPersonInnCode === code);
