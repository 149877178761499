import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MENU_STATE } from '@transport/sidebar';
import { USER_ROLE } from '@transport/ui-interfaces';
import { APPLICATION, TnChangeApplicationService, TnCurrentUserFacade } from '@transport/ui-store';
import { TnProfileOrganizationService } from 'libs/transport-profile/src/lib/services';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';
import { BehaviorSubject } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'transport-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnHeaderComponent implements OnInit, OnChanges {
  @Input() public isTonna = false;

  @Input() public hideExchangeNavbar = false;

  // eslint-disable-next-line
  @Input() public menuState$!: BehaviorSubject<MENU_STATE>;

  public application: APPLICATION = APPLICATION.CLOUD_TMS;

  public isSubdomainPage!: boolean;

  public disabledLogo = false;

  constructor(
    private readonly applicationChangeService: TnChangeApplicationService,
    private readonly cdr: ChangeDetectorRef,
    private readonly profileService: TnProfileOrganizationService,
    private readonly router: Router,
    private readonly domainService: TnDomainService,
    private readonly currentUser: TnCurrentUserFacade,
  ) {}

  public ngOnChanges(): void {
    this.isSubdomainPage = Boolean(this.router.url.includes('profile/subdomain'));

    this.profileService
      .getIsSubdomainPage()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.isSubdomainPage = res;
        this.cdr.markForCheck();
      });
  }

  public ngOnInit(): void {
    if (this.currentUser.currentUserRole === USER_ROLE.OWNER) {
      this.disabledLogo = true;
    }

    if (!this.isTonna) {
      this.applicationChangeService
        .getApplicationChanged$()
        .pipe(untilDestroyed(this))
        .subscribe(res => {
          this.application = res;
          this.cdr.markForCheck();
        });
    } else {
      this.application = 1;
    }
  }

  public goToSubdomainTable(): void {
    const localHostPort = this.domainService.domain.includes('lv4.lv3.lv2.localhost') ? ':4200' : '';
    window.location.href = `${window.location.protocol}//${this.domainService.domain}${localHostPort}/profile/subdomain`;
  }
}
