import { AbstractControl, ValidatorFn } from '@angular/forms';

export enum CARGO_DIMENSIONS {
  MAX_WIDTH_CARGO = 99.99,
  MAX_LENGTH_CARGO = 99.99,
  MAX_HEIGHT_CARGO = 99.99,
}

const validateRange = (value: number, min: number, max: number) => {
  return !value || (value >= min && value <= max);
};

export const cargoDimensionsValidator: ValidatorFn = (control: AbstractControl) => {
  if (
    !validateRange(control.value.length, 0, CARGO_DIMENSIONS.MAX_LENGTH_CARGO) ||
    !validateRange(control.value.height, 0, CARGO_DIMENSIONS.MAX_HEIGHT_CARGO) ||
    !validateRange(control.value.width, 0, CARGO_DIMENSIONS.MAX_WIDTH_CARGO)
  ) {
    return { cargoDimensions: true };
  }

  return null;
};

export const cargoDimensionsRequiredValidator: ValidatorFn = (control: AbstractControl) => {
  if (!Boolean(control.value.length) || !Boolean(control.value.height) || !Boolean(control.value.width)) {
    return { required: true };
  }

  return null;
};
