<div class="relative">
  <div [ngClass]="[getBlockClass()]">
    <div [ngClass]="['tw-input-container-'+size, 'rounded-l-md', !rightAddon ? 'rounded-r-md' : '']">
      <ng-content></ng-content>
      <div #selectorShadow></div>
    </div>
    <div *ngIf='rightAddon && !options?.length'
      [ngClass]="[
        'addon',
        'bg-gray-100 rounded-r-md border-l border-solid border-gray-100 text-gray-700 flex items-center ',
        'tw-input-spacing'+classSizeAddon, size ? 'text-'+size : ''
      ]"
    >
      {{ rightAddon }}
    </div>
  </div>
  <button
    #selectorDiv
    *ngIf="options?.length"
    [ngClass]="[
      selectorClass,
      'flex justify-between items-center h-full px-2 text-gray-500 rounded-l-md rounded-r-md',
      'absolute right-0 top-0 flex-grow-0 border border-transparent outline-none',
      formGroup.invalid && formGroup.touched ? 'focus:border-red-500' : 'focus:border-blue-500',
    ]"
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    [disabled]="formGroup.disabled"
  >
    {{ currentSelectTitle }}
    <mat-icon svgIcon="arrow-down"></mat-icon>
  </button>
</div>


<mat-menu #menu="matMenu" xPosition="before" [hasBackdrop]="true" class="tw-menu-block !shadow-base tw-menu-block">
  <transport-tw-menu-option *ngFor="let option of options" (click)="selectOption(option.value)">
    <div>
      <div class="text-md text-gray-700">{{option.title}}</div>
      <div class="text-xs text-gray-500" *ngIf="option.subtitle">{{option.subtitle}}</div>
    </div>
  </transport-tw-menu-option>
</mat-menu>
