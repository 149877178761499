import { Sort } from '@angular/material/sort';
import { IPaginationData } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';

import { REFRESH_TYPE } from './table-state.interface';

export interface ITableStateFacade<TFilter> {
  currentPagination$: Observable<IPaginationData>;
  currentFilter$: Observable<TFilter>;
  currentFilterSubject$: Observable<TFilter>;
  currentSort$: Observable<Sort>;
  currentSortSubject$: Observable<Sort>;
  currentSelected$: Observable<Map<string, boolean>>;
  currentSelectedSubject$: Observable<Map<string, boolean>>;
  refresh$: Observable<REFRESH_TYPE>;
  currentSelected: Map<string, boolean>;
  currentFilter?: TFilter;
  currentSort: Sort;
  changePage(pageSize: number, page: number): void;
  setFilterSettings(filterSettings): void;
  setSortSettings(sortSettings: Sort): void;
  resetPagination(): void;
  saveSelection(selected: Map<string, boolean>): void;
  setRefreshState(refresh: REFRESH_TYPE): void;
}
