import { NgModule, Provider } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { ActionReducerMap, StoreModule } from '@ngrx/store';

import { TnAdminProfileEffects } from './admin/admin-profile.effects';
import { TnAdminProfileFacade } from './admin/admin-profile.facade';
import { adminProfileReducer } from './admin/admin-profile.reducer';
import { adminProfileFeatureKey } from './admin/admin-profile.state';
import { TnAdminProfileSharedEffects } from './shared/admin-profile-shared.effects';
import { adminProfileSharedReducer } from './shared/admin-profile-shared.reducer';
import { adminProfileSharedFeatureKey } from './shared/admin-profile-shared.state';
import { subOrganizationReducer } from './sub-organization/sub-organization.reducer';
import { TnSubOrganizationProfileEffects } from './sub-organization/sub-organization-profile.effects';
import { TnSubOrganizationProfileFacade } from './sub-organization/sub-organization-profile.facade';
import { subOrganizationFeatureKey } from './sub-organization/sub-organization-profile.state';
import { subOrganizationsTableFeatureKey, subOrganizationsTableReducer } from './sub-organizations-table/sub-organizations.reducer';
import { TnSubOrganizationsFacade } from './sub-organizations-table/sub-organizations-state.facade';
import { IProfileState } from './transport-profile.state';

/**
 * @note testing only
 * This providers array should be used for testing only.
 */
export const tnProfileStoreModuleProviders: Provider[] = [TnAdminProfileFacade, TnSubOrganizationProfileFacade, TnSubOrganizationsFacade];

export const profileReducers: ActionReducerMap<IProfileState> = {
  [adminProfileFeatureKey]: adminProfileReducer,
  [subOrganizationFeatureKey]: subOrganizationReducer,
  [subOrganizationsTableFeatureKey]: subOrganizationsTableReducer,
  [adminProfileSharedFeatureKey]: adminProfileSharedReducer,
};

export const PROFILE_EFFECTS = [TnAdminProfileEffects, TnSubOrganizationProfileEffects, TnAdminProfileSharedEffects];

@NgModule({
  imports: [StoreModule.forFeature('profile', profileReducers), EffectsModule.forFeature(PROFILE_EFFECTS)],
  exports: [StoreModule, EffectsModule],
})
export class TnProfileStateModule {}
