import { Directionality } from '@angular/cdk/bidi';
import {
  ConnectedPosition,
  FlexibleConnectedPositionStrategy,
  HorizontalConnectionPos,
  OriginConnectionPosition,
  Overlay,
  OverlayConnectionPosition,
  OverlayRef,
  ScrollDispatcher,
  ScrollStrategy,
  VerticalConnectionPos,
} from '@angular/cdk/overlay';
import { ComponentPortal, ComponentType } from '@angular/cdk/portal';
import { DOCUMENT } from '@angular/common';
import { AfterViewInit, Directive, ElementRef, Inject, Input, NgZone, OnDestroy, TemplateRef, ViewContainerRef } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter, tap } from 'rxjs/operators';

import { TnTooltipComponent } from './tooltip.component';
import {
  DEFAULT_MARGIN_OFFSET,
  DEFAULT_X_OFFSET,
  DEFAULT_Y_OFFSET,
  SCROLL_THROTTLE_MS,
  TRANSPORT_TOOLTIP_SCROLL_STRATEGY,
} from './tooltip.constants';

export function transportTooltipScrollStrategyFactory(overlay: Overlay): () => ScrollStrategy {
  return () => overlay.scrollStrategies.reposition({ scrollThrottle: SCROLL_THROTTLE_MS });
}

const getTextFromElement = (nodes: NodeList, text = '') => {
  let result = text;
  for (const node of Array.from(nodes)) {
    if (node.nodeType === Node.TEXT_NODE) {
      result += node.textContent as string;
    } else if (typeof (node as HTMLElement).getAttribute === 'function') {
      const ariaHiddenAttribute = (node as HTMLElement).getAttribute('aria-hidden');
      if (ariaHiddenAttribute !== 'true') {
        if (Boolean(node.childNodes)) {
          result = getTextFromElement(node.childNodes, result);
        }
      }
    }
  }
  return result;
};

@UntilDestroy()
@Directive({ selector: '[transportTooltip]' })
export class TnTooltipDirective implements AfterViewInit, OnDestroy {
  @Input()
  public templateRef: TemplateRef<unknown> | null = null;

  @Input()
  public context: Record<string, unknown> | null = null;

  @Input()
  public disabled: boolean | null = false;

  @Input()
  public marginOffset = DEFAULT_MARGIN_OFFSET;

  @Input()
  public shouldShowByClick = false;

  @Input()
  public shouldCloseByMouseup = false;

  @Input()
  public isPopup = false;

  @Input()
  public isGlobalMode = false;

  @Input()
  public containerClass = 'transport-tooltip-panel';

  @Input()
  public set tipPosition(value: string) {
    this.position = value;
  }

  private showEventType = 'mouseenter';

  private overlayRef: OverlayRef | null = null;

  private tooltipInstance: TnTooltipComponent | null = null;

  private portal?: ComponentPortal<TnTooltipComponent>;

  private readonly tooltipComponent: ComponentType<TnTooltipComponent> = TnTooltipComponent;

  private readonly scrollStrategy?: () => ScrollStrategy;

  private position = 'below';

  private strategy?: FlexibleConnectedPositionStrategy;

  private readonly document: Document;

  private target: HTMLElement = this.elementRef.nativeElement;

  private readonly showHandler = (event: Event) => this.onShow(event);

  constructor(
    private readonly overlay: Overlay,
    private readonly elementRef: ElementRef<HTMLElement>,
    private readonly scrollDispatcher: ScrollDispatcher,
    private readonly viewContainerRef: ViewContainerRef,
    private readonly dir: Directionality,
    private readonly ngZone: NgZone,
    private readonly router: Router,
    @Inject(TRANSPORT_TOOLTIP_SCROLL_STRATEGY) scrollStrategy: () => ScrollStrategy,
    @Inject(DOCUMENT) document: Document,
  ) {
    this.scrollStrategy = scrollStrategy;
    this.document = document;
  }

  public ngAfterViewInit() {
    if (this.shouldShowByClick) {
      this.showEventType = 'click';
    }

    if (this.isGlobalMode) {
      this.showEventType = 'mouseover';
      void this.router.events
        .pipe(
          untilDestroyed(this),
          filter(event => event instanceof NavigationStart),
          tap(() => this.hide()),
        )
        .subscribe();
    }

    this.elementRef.nativeElement.addEventListener(this.showEventType, this.showHandler);
  }

  public ngOnDestroy() {
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.tooltipInstance = null;
    }
    this.elementRef.nativeElement.removeEventListener(this.showEventType, this.showHandler);
  }

  public onShow(event: Event): void {
    if (this.tooltipInstance?.isVisible() ?? Boolean(this.disabled)) {
      return;
    }
    if (this.isGlobalMode) {
      this.onGlobalModeShow(event);
    } else {
      this.bindHideHandler(this.elementRef.nativeElement);
      this.bindWheelHandler();
      this.show();
    }
  }

  private onGlobalModeShow(event: Event) {
    let target = event.target as HTMLElement;
    const targetTextOverflowWrapper = this.getTargetTextOverflowWrapper(target);
    if (Boolean(targetTextOverflowWrapper)) {
      const targetWithLineClamp =
        targetTextOverflowWrapper?.clientHeight !== targetTextOverflowWrapper?.scrollHeight && target.classList.contains('line-clamp-2');
      if ((targetTextOverflowWrapper?.clientWidth ?? 0) === (targetTextOverflowWrapper?.scrollWidth ?? 0) && !targetWithLineClamp) {
        return;
      }
      if (target.tagName === 'INPUT' && target.getAttribute('disabled') !== null) {
        target = target?.parentElement as HTMLElement;
      }
      this.target = target;
      this.context = { message: getTextFromElement(targetTextOverflowWrapper?.childNodes ?? ({} as NodeList)) };
      this.overlayRef?.dispose();
      this.overlayRef = null;
    } else {
      return;
    }
    this.bindHideHandler(target);
    this.bindWheelHandler();
    this.show();
  }

  private getTargetTextOverflowWrapper(target: HTMLElement) {
    const ngSelectorTarget = target.tagName === 'INPUT' ? target.parentElement?.parentElement?.querySelector('.ng-value') : null;
    if (Boolean(ngSelectorTarget)) {
      return ngSelectorTarget as HTMLElement;
    }
    const matInputTarget = target.classList.contains('mat-input-element')
      ? target.parentElement?.parentElement?.querySelector('.mat-form-field-label')
      : null;
    if (Boolean(matInputTarget)) {
      return matInputTarget as HTMLElement;
    }
    return (typeof target.getAttribute === 'function' && target.getAttribute('tn-tip') === 'true') ||
      target.classList.contains('ng-value-label')
      ? target
      : null;
  }

  private show() {
    const overlayRef = this.createOverlay();
    this.detach();
    this.portal = this.portal ?? new ComponentPortal(this.tooltipComponent, this.viewContainerRef);
    this.tooltipInstance = overlayRef.attach(this.portal).instance;
    void this.tooltipInstance
      .afterHidden()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.detach());

    if (!this.isPopup) {
      this.checkPosition();
    }
    this.updatePosition();
    this.tooltipInstance.position = this.position;
    this.tooltipInstance.isPopup = this.isPopup;
    this.tooltipInstance.show(this.templateRef as TemplateRef<unknown>, this.context);
  }

  private bindHideHandler(element: HTMLElement) {
    const hideHandler = () => this.hide();

    if (this.shouldShowByClick) {
      this.document.addEventListener('click', ({ target }) => {
        if (!this.hasParent(target, element)) {
          hideHandler();
          this.document.removeEventListener('click', hideHandler);
        }
      });
    } else {
      element.addEventListener('mouseleave', () => {
        hideHandler();
        element.removeEventListener('mouseleave', hideHandler);
      });
    }

    if (this.shouldCloseByMouseup) {
      element.addEventListener('mouseup', () => {
        hideHandler();
        element.removeEventListener('mouseup', hideHandler);
      });
    }
  }

  private bindWheelHandler() {
    const wheelHandler = (event: WheelEvent) => this.wheelListener(event);
    this.document.addEventListener('wheel', (event: WheelEvent) => {
      wheelHandler(event);
      this.document.removeEventListener('wheel', wheelHandler);
    });
  }

  private hasParent(element, realParenElement) {
    let parentElement: HTMLElement = element;
    while (Boolean(parentElement) && !(parentElement === realParenElement || parentElement === this.document.body)) {
      parentElement = parentElement.parentElement as HTMLElement;
    }
    return parentElement === realParenElement;
  }

  public hide(): void {
    if (this.tooltipInstance) {
      this.tooltipInstance.hide();
    }
  }

  private createOverlay(): OverlayRef {
    if (this.overlayRef) {
      return this.overlayRef;
    }

    const scrollableAncestors = this.scrollDispatcher.getAncestorScrollContainers(this.target);

    this.strategy = this.overlay
      .position()
      .flexibleConnectedTo(this.target)
      .withTransformOriginOn(`.transport-tooltip`)
      .withFlexibleDimensions(true)
      .withScrollableContainers(scrollableAncestors);

    // Removed for https://tndl.kaiten.ru/space/50858/card/7908969
    // void this.strategy.positionChanges.pipe(untilDestroyed(this)).subscribe(change => {
    //   if (this.tooltipInstance) {
    //     if (change.scrollableViewProperties.isOverlayClipped && this.tooltipInstance.isVisible()) {
    //       this.ngZone.run(() => this.hide());
    //     }
    //   }
    // });

    this.overlayRef = this.overlay.create({
      direction: this.dir,
      positionStrategy: this.strategy,
      panelClass: this.containerClass,
      scrollStrategy: this.scrollStrategy ? this.scrollStrategy() : ({} as ScrollStrategy),
    });

    this.updatePosition();

    void this.overlayRef
      .detachments()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.detach());

    return this.overlayRef;
  }

  public checkPosition() {
    const originRect = this.target.getBoundingClientRect();
    const viewportRect = this.document.body.getBoundingClientRect();
    switch (this.position) {
      case 'below':
      case 'above':
        if (originRect.right + this.marginOffset > viewportRect.right) {
          this.position = 'before';
        } else if (originRect.left - this.marginOffset < viewportRect.left) {
          this.position = 'after';
        }
        break;
      case 'before':
        if (originRect.right + this.marginOffset < viewportRect.right) {
          this.position = 'below';
        }
        break;
      case 'after':
        if (originRect.left - this.marginOffset > viewportRect.left) {
          this.position = 'below';
        }
        break;
    }
  }

  private detach() {
    if (this.overlayRef) {
      if (this.overlayRef.hasAttached()) {
        this.overlayRef.detach();
      }
    }

    this.tooltipInstance = null;
  }

  private updatePosition() {
    this.strategy
      ?.withDefaultOffsetY(this.position === 'below' ? DEFAULT_Y_OFFSET : 0)
      .withDefaultOffsetX(this.position === 'before' ? -DEFAULT_X_OFFSET : this.position === 'after' ? DEFAULT_X_OFFSET : 0);

    this.overlayRef?.updatePositionStrategy(this.strategy as FlexibleConnectedPositionStrategy);
    const position = this.overlayRef?.getConfig().positionStrategy as FlexibleConnectedPositionStrategy;
    const origin = this.getOrigin();
    const overlay = this.getOverlayPosition();

    position.withPositions([
      this.addOffset({ ...origin.main, ...overlay.main }),
      this.addOffset({ ...origin.fallback, ...overlay.fallback }),
    ]);
  }

  private addOffset(position: ConnectedPosition): ConnectedPosition {
    return position;
  }

  private getOrigin(): { main: OriginConnectionPosition; fallback: OriginConnectionPosition } {
    const originPosition = this.getOriginPosition();
    const { x, y } = this.invertPosition(originPosition.originX, originPosition.originY);

    return {
      main: originPosition,
      fallback: { originX: x, originY: y },
    };
  }

  private getOriginPosition(): OriginConnectionPosition {
    const position = this.position;
    if (position === 'above' || position === 'below') {
      return { originX: 'center', originY: position === 'above' ? 'top' : 'bottom' };
    } else if (position === 'before') {
      return { originX: 'start', originY: 'center' };
    } else if (position === 'after') {
      return { originX: 'end', originY: 'center' };
    }
    return {} as OriginConnectionPosition;
  }

  private getOverlayPosition(): { main: OverlayConnectionPosition; fallback: OverlayConnectionPosition } {
    const overlayPosition = this.getOverlayConnectionPosition();
    const { x, y } = this.invertPosition(overlayPosition.overlayX, overlayPosition.overlayY);

    return {
      main: overlayPosition,
      fallback: { overlayX: x, overlayY: y },
    };
  }

  private getOverlayConnectionPosition(): OverlayConnectionPosition {
    const position = this.position;
    if (position === 'above') {
      return { overlayX: 'center', overlayY: 'bottom' };
    } else if (position === 'below') {
      return { overlayX: 'center', overlayY: 'top' };
    } else if (position === 'before') {
      return { overlayX: 'end', overlayY: 'center' };
    } else if (position === 'after') {
      return { overlayX: 'start', overlayY: 'center' };
    }
    return {} as OverlayConnectionPosition;
  }

  private invertPosition(x: HorizontalConnectionPos, y: VerticalConnectionPos) {
    let invertedX = x,
      invertedY = y;
    if (this.position === 'above' || this.position === 'below') {
      if (y === 'top') {
        invertedY = 'bottom';
      } else if (y === 'bottom') {
        invertedY = 'top';
      }
    } else if (x === 'end') {
      invertedX = 'start';
    } else if (x === 'start') {
      invertedX = 'end';
    }

    return { x: invertedX, y: invertedY };
  }

  private wheelListener(event: WheelEvent) {
    if (Boolean(this.tooltipInstance?.isVisible())) {
      const elementUnderPointer = this.document.elementFromPoint(event.clientX, event.clientY);
      const element = this.target;

      if (elementUnderPointer !== element && !element.contains(elementUnderPointer)) {
        this.hide();
      }
    }
  }
}
