import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CARRIER_MUTATION } from '@transport/gql';
import { ACCEPTING_PLACE, USER_ROLE } from '@transport/ui-interfaces';
import { TnAnalyticsService, TnGqlClientSharedService } from '@transport/ui-store';
import { BehaviorSubject, Subject } from 'rxjs';

export enum BiddingPopupViewEnum {
  counterOffer = 'counterOffer',
}

interface IAddOrderCounterOfferInput {
  orderId: number;
  amount: number | null;
  vatIncluded: boolean | null;
  currency: string;
  comment: string | null;
  acceptingPlace?: ACCEPTING_PLACE | null;
}

@Injectable()
export class BiddingPopupService {
  public $closePopupSubject = new Subject();

  public currentView$ = new BehaviorSubject<BiddingPopupViewEnum>(BiddingPopupViewEnum.counterOffer);

  constructor(
    private readonly sharedGraphQlClient: TnGqlClientSharedService,
    public readonly translate: TranslateService,
    private readonly analytics: TnAnalyticsService
  ) {}

  public addOrderCounterOffer(input: IAddOrderCounterOfferInput, acceptingPlace: ACCEPTING_PLACE | null | undefined) {
    this.analytics.track(`user sent offer`, {payload: acceptingPlace ?? ACCEPTING_PLACE.MARKET});
    return this.sharedGraphQlClient.mutate(USER_ROLE.CARRIER, CARRIER_MUTATION.addOrderCounterOffer, { input, acceptingPlace });
  }
}
