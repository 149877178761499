import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, catchError, map, of, switchMap, tap, take } from 'rxjs';
import { WebsocketService } from 'libs/websocket/src';
import { transport } from '@transport/proto';
import { NotificationsApiService } from '../../notifications-api.service';

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class PopupNotificaions {
  public unreadCount$ = new BehaviorSubject<number>(0);

  public notifications$ = new BehaviorSubject<transport.INotification[]>([]);

  public isLoading$ = new BehaviorSubject<boolean>(false);

  public count$ = new BehaviorSubject<number>(0);

  constructor(
    private router: Router,
    private notificationsService: NotificationsApiService,
    private socket: WebsocketService,
  ) {
    this.socket
      .on('notification', ['created', 'marked_as_read'])
      .pipe(
        untilDestroyed(this),
        tap(res => this.loadOnPopupNotifications().subscribe()),
      )
      .subscribe();
  }

  public goByPath(path: string[], query: Record<string, unknown>) {
    this.router.navigate(path, { queryParams: query })
  }

  public markAsRead(notification: transport.INotification) {
    this.isLoading$.next(true);
    return this.notificationsService.markNotificationAsRead(notification.id ?? '').pipe(
      take(1),
      switchMap(() => this.loadOnPopupNotifications()),
      tap(() => this.isLoading$.next(false)),
      catchError(error => {
        console.error(error);
        this.isLoading$.next(false);

        return of(error);
      }),
    )
  }

  public markAllAsRead() {
    this.isLoading$.next(true);
    return this.notificationsService.markAllNotificationsAsRead().pipe(
      take(1),
      switchMap(() => this.loadOnPopupNotifications()),
      tap(() => this.isLoading$.next(false)),
      catchError(error =>{
        console.error(error);
        this.isLoading$.next(false);

        return of(error);
      }),
    )
  }

  public loadOnPopupNotifications() {
    this.isLoading$.next(true);
    const first = 5;
    return this.notificationsService
      .getNotificationsWithCounts(false, void 0, first, 0)
      .pipe(
        take(1),
        map(result => {
          this.count$.next(result.count ?? 0);
          this.unreadCount$.next(result.unreadCount ?? 0);
          this.notifications$.next(result.notifications);
          this.isLoading$.next(false);
        }),
        catchError(error => {
          this.isLoading$.next(false);

          return of(error);
        }),
      );
  }
}
