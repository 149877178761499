import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';

import { ITnState } from '../state/index';
import * as actions from './time-zone.actions';
import * as selectors from './time-zone.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnTimezoneFacade {
  public readonly timezoneData$ = this.store.pipe(select(selectors.timezoneData));

  constructor(private readonly store: Store<ITnState>) {}

  public getTimeZones() {
    this.store.dispatch(actions.getTimezones());
  }
}
