import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_ENV, TnAppEnvironment } from '@transport/ui-interfaces';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';
import { BehaviorSubject, Observable, of } from 'rxjs';

import { IPostFileResponce } from '../interfaces/responce';

@Injectable({
  providedIn: 'root',
})
export class TnFileUploadService {
  private readonly headersConfig: HttpHeaders = new HttpHeaders();

  private readonly fileNameSubject$ = new BehaviorSubject<string>('');

  public fileName$ = this.fileNameSubject$.asObservable();
  public fileUrl$ = new BehaviorSubject<string | undefined>('');

  constructor(
    private readonly httpClient: HttpClient,
    private readonly domainService: TnDomainService,
    @Inject(APP_ENV) public readonly env: TnAppEnvironment,
  ) {}

  public postFile(fileToUpload: File): Observable<IPostFileResponce | null> {
    if (!Boolean(fileToUpload)) {
      return of(null);
    }
    const endpoint = `https://${this.env.apiDomain}/p/files/r/`;
    this.fileNameSubject$.next(fileToUpload.name);
    const formData: FormData = new FormData();
    formData.append('file', fileToUpload, fileToUpload.name);
    return this.httpClient.post<IPostFileResponce>(endpoint, formData);
  }
}
