import { Injectable } from '@angular/core';
import { IPlace, TPlace } from '@transport/ui-interfaces';
import { map } from 'rxjs/operators';

import { TnGqlClientOwnerQueriesService } from '../../gql-client-owner/graphql-client-owner-queries.service';

@Injectable({
  providedIn: 'root',
})
export class TnOwnerDirectoryApiService {
  constructor(private readonly graphQL: TnGqlClientOwnerQueriesService) {}

  /**
   * Request for list of items
   */
  public getStoragePoints(listenX?: number, withSpinner?: boolean, first?: number, offset?: number, options?: Record<string, unknown>) {
    return this.graphQL.queryStoragePoints(listenX, withSpinner, first, offset, options).pipe(
      map(data => {
        return {
          list: data.storagePoints,
          totalCount: data.totalCount ?? 0,
        };
      }),
    );
  }

  /**
   * Get place
   * @param id place
   * @param type place
   * @returns [Observable<LoadingPlace[]>] place
   */
  public getStoragePoint(id: string, typePoint: TPlace) {
    const req = this.graphQL.queryStoragePoint(id, typePoint);
    return req.pipe(map((data: { storagePoint: IPlace }) => data.storagePoint));
  }
}
