import { Directive, Host, Input, OnInit } from '@angular/core';
import { MatInput } from '@angular/material/input';

import { TnCustomErrorStateMatcher } from './custom-error-state-matcher';

@Directive({
  selector: '[transportInputErrorState]',
})
export class TnInputErrorStateMatcherDirective implements OnInit {
  @Input() public transportInputErrorState = false;

  constructor(@Host() private readonly host: MatInput) {}

  public ngOnInit(): void {
    if (Boolean(this.transportInputErrorState)) {
      this.host._defaultErrorStateMatcher = new TnCustomErrorStateMatcher();
    }
  }
}
