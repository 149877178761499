import { createFeatureSelector, createSelector } from '@ngrx/store';

import { makeTableSelectors } from '../../../table/table-selectors';
import { adminFeatureName, IAdminState } from '../../admin.state';

const getAdminState = createFeatureSelector<IAdminState>(adminFeatureName);
const usersStateSelector = createSelector(getAdminState, (state: IAdminState) => {
  return state.users;
});

export const tableSelectors = makeTableSelectors(usersStateSelector);
