import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TnControlStateClassesDirective } from './add-control-state-classes/add-control-state-classes.directive';
import { TnClearInputDirective } from './clear-input/clear-input.directive';
import { TnClickOutsideDirective } from './click-outside/click-outside-directive';
import { TnClickStopPropagationDirective, TnSelfDirective } from './click/click-stop-propagation.directive';
import { TnInputErrorStateMatcherDirective } from './custom-error-state/custom-input-error-state.directive';
import { TnSelectErrorStateMatcherDirective } from './custom-error-state/custom-select-error-state-.directive';
import { TnDynamicComponentDirective } from './dynamic-component/dynamic-component.directive';
import { TnElasticInputDirective } from './elastic-input/elastic-input.directive';
import { TnInnMaxDirective } from './inn-max/inn-max.directive';
import { TnMatCheckboxControlDirective } from './mat-checkbox/ng-select-mat.directive';
import { TnNgSelectFormFieldControlDirective } from './ng-select-mat/ng-select-mat.directive';
import { TnNumberOnlyDirective } from './number-only/tn-number-only.directive';
import { TnPrintDirective } from './print/print.directive';
import { TnRecreateViewDirective } from './recreate-view-when-key-changed/recreate-view-when-key-changed.directive';
import { MatTabScrollToCenterDirective } from './scroll-directive/scroll.directive';
import { TnScrollToDirective } from './scroll-to/scroll-to.directive';
import { TnTrimDirective } from './trim/trim.directive';
import { TnUppercaseTextDirective } from './uppercase/uppercase.directive';
import { TnWholeNumberOnlyDirective } from './whole-numbers-only/tn-number-only.directive';

@NgModule({
  imports: [CommonModule],
  declarations: [
    TnClickStopPropagationDirective,
    TnSelfDirective,
    TnScrollToDirective,
    TnElasticInputDirective,
    TnUppercaseTextDirective,
    TnNgSelectFormFieldControlDirective,
    TnClearInputDirective,
    TnPrintDirective,
    TnInputErrorStateMatcherDirective,
    TnSelectErrorStateMatcherDirective,
    TnDynamicComponentDirective,
    TnTrimDirective,
    TnNumberOnlyDirective,
    TnRecreateViewDirective,
    TnInnMaxDirective,
    TnMatCheckboxControlDirective,
    TnControlStateClassesDirective,
    MatTabScrollToCenterDirective,
    TnWholeNumberOnlyDirective,
    TnClickOutsideDirective,
  ],
  exports: [
    TnClickStopPropagationDirective,
    TnSelfDirective,
    TnScrollToDirective,
    TnElasticInputDirective,
    TnUppercaseTextDirective,
    TnNgSelectFormFieldControlDirective,
    TnClearInputDirective,
    TnPrintDirective,
    TnInputErrorStateMatcherDirective,
    TnSelectErrorStateMatcherDirective,
    TnDynamicComponentDirective,
    TnTrimDirective,
    TnNumberOnlyDirective,
    TnRecreateViewDirective,
    TnInnMaxDirective,
    TnMatCheckboxControlDirective,
    TnControlStateClassesDirective,
    MatTabScrollToCenterDirective,
    TnWholeNumberOnlyDirective,
    TnClickOutsideDirective,
  ],
})
export class TnUiDirectivesModule {}
