import { TnReconcilationActModel } from './reconciliation-act';
import { IReconcilationActOrganizationFormValue } from './reconciliation-act-organization-form-value';
import { IReconcilationActResults } from './reconciliation-act-results';
import { RECONCILIATION_ACT_STATUS } from './reconciliation-act-status';

export class TnReconciliationActDetailsFormValue {
  public firstOrganization: IReconcilationActOrganizationFormValue | null = null;

  public secondOrganization: IReconcilationActOrganizationFormValue | null = null;

  public status: RECONCILIATION_ACT_STATUS | null = null;

  public results: IReconcilationActResults | null = null;

  public comment: string;

  public period: { fromDate?: string; toDate?: string };

  constructor(model: TnReconcilationActModel) {
    this.period = {
      fromDate: model.actDateFrom?.toISOString(),
      toDate: model.actDateTo?.toISOString(),
    };
    this.firstOrganization = {
      organization: model.firstOrganization,
      rule: model.firstRule,
      file: model.firstAct,
    };
    this.secondOrganization = {
      organization: model.secondOrganization,
      rule: model.secondRule,
      file: model.secondAct,
    };
    this.status = model.status;
    this.results = {
      status: model.status,
      diff: model.diff,
      errorType: model.errorType,
      fileFull: model.resultsFileFull,
      fileDiffOnly: model.resultsFileDiffOnly,
    };
    this.comment = model.comment;
  }
}
