import { Provider } from '@angular/core';

import { tnOrdersTableStateModuleProviders } from '../orders-table/orders-table-state.module';
import { tnProfileStoreModuleProviders } from '../profile/transport-profile-state.module';
import { fileUploadProvidersMock } from './file-upload-provider.mock';
import { sharedClientGraphQLProvider } from './shared-graphql-providers.mock';

/**
 * All store library providers.
 */
export const storeLibraryTestingProviders: Provider[] = [
  ...sharedClientGraphQLProvider,
  ...fileUploadProvidersMock,
  ...tnOrdersTableStateModuleProviders,
  ...tnProfileStoreModuleProviders,
];
