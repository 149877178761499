import { Injectable } from '@angular/core';
import { IUiSettings, IUser } from '@transport/ui-interfaces';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

import { ICommandInterface, SSO_ACTION_TYPE } from './models';

@Injectable({
  providedIn: 'root',
})
export class TnClSsoFrameService {
  private readonly commands$: Subject<ICommandInterface> = new Subject();

  public readonly token$: BehaviorSubject<Partial<IUser & { uiSettings: IUiSettings }> | string> = new BehaviorSubject({});

  public get commandStream$(): Observable<ICommandInterface> {
    return this.commands$.asObservable();
  }

  public get tokenStream$() {
    return this.token$.asObservable();
  }

  public pushCommand(command: ICommandInterface) {
    this.commands$.next(command);
  }

  public login(userData: Partial<IUser & { uiSettings: IUiSettings }> | Partial<IUser>) {
    this.commands$.next({
      action: SSO_ACTION_TYPE.SET,
      data: userData,
    });
  }

  public logOut() {
    this.commands$.next({
      action: SSO_ACTION_TYPE.LOGOUT,
    });
  }

  public pushToken(token: Partial<IUser & { uiSettings: IUiSettings }> | string) {
    this.token$.next(token);
  }
}
