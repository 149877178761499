import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
} from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { Coordinate } from 'ol/coordinate';
import Feature, { FeatureLike } from 'ol/Feature.js';
import { Polyline } from 'ol/format.js';
import { Circle, Geometry, LineString } from 'ol/geom';
import Point from 'ol/geom/Point.js';
import { Tile as TileLayer, Vector as VectorLayer } from 'ol/layer.js';
import Map from 'ol/Map.js';
import { fromLonLat } from 'ol/proj';
import { Cluster, OSM, Vector as VectorSource } from 'ol/source.js';
import { Fill, Icon, Stroke, Style } from 'ol/style.js';
import View from 'ol/View.js';

export interface IRoutingMapPoint {
  latitude: number;
  longitude: number;
  style: Style;
  dashedLine?: boolean;
  lineColor?: string;
  primaryPoint?: boolean;
}

export interface IRoutingMapRoute {
  poliline: string;
  color: string;
  routeName: string;
  layer?: VectorLayer<VectorSource<Geometry>>;
  visible?: boolean;
}

const driverIcon = new Icon({
  src: 'assets/icons/driver.svg',
});

const Moscow: IRoutingMapPoint = {
  latitude: 55.738817,
  longitude: 37.611003,
  style: new Style({
    image: driverIcon,
  }),
};

const OSMUrl = 'https://b.tile.geofabrik.de/15173cf79060ee4a66573954f6017ab0/{z}/{x}/{y}.png';

@UntilDestroy()
@Component({
  selector: 'routing-map',
  templateUrl: './routing-map.component.html',
  styleUrls: ['./routing-map.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnRoutingMapComponent implements AfterViewInit {
  @ViewChild('popupContainer') public popupContainer!: ElementRef;

  @Input() public mainPoint!: IRoutingMapPoint | null;

  @Input() public mainPointHistory!: IRoutingMapPoint[] | null;

  @Input() public extraPoints!: IRoutingMapPoint[] | null;

  @Input() public set routes(routes: IRoutingMapRoute[] | null) {
    this.routesOnMap =
      routes?.map(route => ({
        ...route,
        layer: this.getRouteVectorLayer(route),
        visible: false,
      })) ?? null;
  }

  @Output() public readonly closeCargoModal = new EventEmitter();

  public routesOnMap!: IRoutingMapRoute[] | null;

  private map: Map | undefined;

  private readonly extraPlacesVectorLayer: VectorLayer<VectorSource> | undefined;

  public selectedCargos: { name: string; time: string; cargoId: string }[] | undefined;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  public ngAfterViewInit() {
    this.initMap();
    this.createPointsList();
  }

  private initMap() {
    // Создание основного слоя карта
    const raster = new TileLayer({
      source: new OSM({ url: OSMUrl }),
      className: 'tn-osm-map',
    });
    const centerLocation =
      this.mainPoint && Boolean(this.mainPoint?.latitude) && Boolean(this.mainPoint?.longitude)
        ? this.mainPoint
        : this.extraPoints && Boolean(this.extraPoints?.length)
        ? this.extraPoints[0]
        : Moscow;

    this.map = new Map({
      layers: [raster],
      target: 'map',
      view: new View({
        center: fromLonLat([centerLocation.longitude, centerLocation.latitude]),
        zoom: 15,
      }),
    });
  }

  private createPointsList() {
    if (this.mainPoint && Boolean(this.mainPoint?.latitude) && Boolean(this.mainPoint?.longitude)) {
      this.addPointsLayer([this.mainPoint], 20);
      this.drawRadiusForPoint(fromLonLat([this.mainPoint.longitude, this.mainPoint.latitude]));
    }

    if (this.mainPointHistory?.length) {
      this.addPointsLayer(this.mainPointHistory, 15);
    }

    // Тоже самое только если есть дополнительные точки
    if (this.extraPoints && this.extraPoints?.length > 0) {
      this.addPointsLayer(this.extraPoints, 10);

      if (this.extraPoints?.length > 1) {
        let firstCoordinate = fromLonLat([this.extraPoints[0].longitude, this.extraPoints[0].latitude]);
        for (let i = 1; i < this.extraPoints.length; i = i + 1) {
          const secondCoordinate = fromLonLat([this.extraPoints[i].longitude, this.extraPoints[i].latitude]);
          this.drawRouteLines([firstCoordinate, secondCoordinate], this.extraPoints[i].lineColor, this.extraPoints[i].dashedLine);
          firstCoordinate = secondCoordinate;
        }
      }
      this.setMapZoom();
    }
  }

  private addPointsLayer(points: IRoutingMapPoint[], zIndex = 0) {
    // Создание кластера основной точки
    const clusterPlaces = this.createPointsLayer(points);

    // Создание слоя основной точки
    const placesVectorLayer = new VectorLayer({
      source: clusterPlaces,
      style: function (feature: FeatureLike) {
        return feature.get('features').map((val: FeatureLike) => val.getGeometry()?.get('style'))[0];
      },
    });

    placesVectorLayer.setZIndex(zIndex);

    // Добавление основного слоя на карту
    this.map?.addLayer(placesVectorLayer);
  }

  private createPointsLayer(places: IRoutingMapPoint[]) {
    const source = new VectorSource({
      features: this.setFeatures(places),
    });
    const clusterSource = new Cluster({
      source: source,
    });
    return clusterSource;
  }

  private setFeatures(places: IRoutingMapPoint[]) {
    return places.map(place => {
      const point = new Point(fromLonLat([place.longitude, place.latitude]));
      point.set('style', place.style);

      return new Feature(point);
    });
  }

  private setMapZoom() {
    const latitudeArray: number[] = [];
    const longitudeArray: number[] = [];

    if (this.mainPoint && Boolean(this.mainPoint?.latitude) && Boolean(this.mainPoint?.longitude)) {
      latitudeArray.push(this.mainPoint.latitude);
      longitudeArray.push(this.mainPoint.longitude);
    }
    const primariPoint = this.extraPoints?.find(point => point.primaryPoint);
    if (primariPoint) {
      latitudeArray.push(primariPoint.latitude);
      longitudeArray.push(primariPoint.longitude);
    } else {
      this.extraPoints?.forEach(point => {
        latitudeArray.push(point.latitude);
        longitudeArray.push(point.longitude);
      });
    }

    const minLatitude = Math.min(...latitudeArray);
    const maxLatitude = Math.max(...latitudeArray);
    const minLongitude = Math.min(...longitudeArray);
    const maxLongitude = Math.max(...longitudeArray);
    const center = [(minLongitude + maxLongitude) / 2, (minLatitude + maxLatitude) / 2];
    this.map?.getView().setCenter(fromLonLat([center[0], center[1]]));
    // Zoom карты
    const min = fromLonLat([minLongitude, minLatitude]);
    const max = fromLonLat([maxLongitude, maxLatitude]);
    // extent = [min-x, min-y, max-x, max-y]
    const extent = [min[0], min[1], max[0], max[1]];
    this.map?.getView().fit(extent, { duration: 500, padding: [50, 50, 50, 50], size: this.map?.getSize(), maxZoom: 17 });
  }

  private drawRouteLines(points: Coordinate[], color: string | undefined, dashedLine = false) {
    // Линии
    const featureLine = new Feature({
      geometry: new LineString(points),
    });
    const vectorLineLayer = new VectorSource({
      features: [featureLine],
    });
    const styleFunction = function (feature) {
      const styles = [
        // linestring
        new Style({
          stroke: new Stroke({
            color: color ? color : '#808080',
            width: 2,
            lineDash: dashedLine ? [4, 8] : undefined,
          }),
        }),
      ];

      return styles;
    };

    const vector = new VectorLayer({
      source: vectorLineLayer,
      style: styleFunction,
    });

    this.map?.addLayer(vector);
  }

  private drawRadiusForPoint(point: Coordinate) {
    const featureCircle = new Feature({
      geometry: new Circle(point, 100),
    });
    const vectorCircleLayer = new VectorSource({
      features: [featureCircle],
    });
    const styleFunction = function (feature) {
      const styles = [
        new Style({
          fill: new Fill({ color: '#3418E333' }),
        }),
      ];

      return styles;
    };
    const vector = new VectorLayer({
      source: vectorCircleLayer,
      style: styleFunction,
    });

    this.map?.addLayer(vector);
  }

  private getRouteVectorLayer(route: IRoutingMapRoute): VectorLayer<VectorSource<Geometry>> {
    const vectorSource = new VectorSource();

    const styles = {
      route: new Style({
        stroke: new Stroke({
          width: 4,
          color: route.color || '#00bb00',
        }),
      }),
    };

    const poliline = new Polyline({
      factor: 1e5,
    }).readGeometry(route.poliline, {
      dataProjection: 'EPSG:4326',
      featureProjection: 'EPSG:3857',
    });
    const feature = new Feature({
      type: 'route',
      geometry: poliline,
    });
    feature.setStyle(styles.route);
    vectorSource.addFeature(feature);

    return new VectorLayer({
      source: vectorSource,
    });
  }

  public switchRouteVisibility(route: IRoutingMapRoute) {
    if (route.layer) {
      if (route.visible) {
        route.visible = false;
        this.hideRouteOnMap(route.layer);
      } else {
        route.visible = true;
        this.showRouteOnMap(route.layer);
      }
    }

    this.cdr.markForCheck();
  }

  private showRouteOnMap(route: VectorLayer<VectorSource<Geometry>>) {
    this.map?.addLayer(route);
  }

  private hideRouteOnMap(route: VectorLayer<VectorSource<Geometry>>) {
    this.map?.removeLayer(route);
  }
}
