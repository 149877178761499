import { animate, style, transition, trigger } from '@angular/animations';

import { ANIMATION_DURATION } from '../config/animations.config';

/**
 * Slide and fade in out animation.
 */
export const slideAndFadeInOut = trigger('slideAndFadeInOut', [
  transition(':enter', [
    style({
      transform: 'translateX(100%)',
      opacity: 0,
    }),
    animate(
      ANIMATION_DURATION.ENTER,
      style({
        transform: 'translateX(0)',
        opacity: 1,
      }),
    ),
  ]),
  transition('* => void', [
    style({
      transform: 'translateX(100%)',
      opacity: 1,
    }),
    animate(
      ANIMATION_DURATION.VOID,
      style({
        transform: 'translateX(100%)',
        opacity: 0,
      }),
    ),
  ]),
]);
