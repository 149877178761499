import { Component, EventEmitter, Input, Optional, Output } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IFile, TnFileToUpload } from '@transport/ui-interfaces';
import { DEFAULT_MAX_FILE_SIZE } from '@transport/ui-utils';

import { FILE_DOWNLOAD_LINK_CLICK_HANDLER_TYPE } from '@transport/ui-components';

// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- TODO: tech debt
@Component({
  selector: 'transport-files-input',
  templateUrl: './files-input.component.html',
  styleUrls: ['./files-input.component.scss'],
})
export class TnFilesInputComponent implements ControlValueAccessor {
  constructor(@Optional() private readonly ngControl: NgControl) {
    if (typeof this.ngControl !== 'undefined' && this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
  }

  @Input() public maxFileSize = DEFAULT_MAX_FILE_SIZE;

  @Input() public accept = '';

  @Input() public acceptDrag = '';

  @Input() public errorAccept = '';

  @Input() public placeholder = '';

  @Input() public multiple = false;

  @Input() public downloadHandlerType: FILE_DOWNLOAD_LINK_CLICK_HANDLER_TYPE = FILE_DOWNLOAD_LINK_CLICK_HANDLER_TYPE.LINK_TARGET_BLANK;

  @Output() public invalidFileSizeSelected = new EventEmitter();

  public value: IFile | undefined;

  public get isUploadAreaVisible(): boolean {
    return !this.isDisabled && this.isEmpty;
  }

  public get valueAsArray(): IFile[] {
    return this.isEmpty ? [] : [this.value as IFile];
  }

  public get isEmpty(): boolean {
    return !Boolean(this.value);
  }

  public isDisabled = false;

  public onFileChange(files: TnFileToUpload[]): void {
    const file = files[0];
    this.value = file;
    this.onChange(this.value);
    this.onTouched();
  }

  public onRemoveButtonClicked(): void {
    this.value = void 0;

    this.onChange(this.value);
    this.onTouched();
  }

  public writeValue(obj: IFile): void {
    this.value = obj;
  }

  public registerOnChange(fn: (...args) => null): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: (...args) => null): void {
    this.onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  public onChange = (...args) => null;

  public onTouched = () => null;
}
