import { transport } from '@transport/proto';

import { createNewPlace, IPlace } from '../../place/place.interface';
import { TDirectoryEntityPredefinedAction } from '../directory.interface';

/**
 * Router loading place page component data model.
 */
export class TnLoadingPlacePageComponentData {
  public title = 'directory: loading place page';

  public loadingPlace: IPlace | null = createNewPlace();

  public loadingPlaceUrl: string | undefined | null;

  public predefinedAction: TDirectoryEntityPredefinedAction = 'view';

  public select: {
    loadingTypes: transport.ILoadingType[];
  } = {
    loadingTypes: [],
  };
}
