import { createAction, props } from '@ngrx/store';
import { transport } from '@transport/proto';
import { TAdminOrganizationForm, TDirectoryEntityPredefinedAction } from '@transport/ui-interfaces';

const loadSubOrganizationProfileStart = createAction('[SubOrganization Profile] Load SubOrganization Profile Start');

const loadSubOrganizationProfileSuccess = createAction(
  '[SubOrganization Profile] Load SubOrganization Profile Success',
  props<{ profile: transport.IAdminOrganization }>(),
);

const loadSubOrganizationProfileFailure = createAction(
  '[SubOrganization Profile] Load SubOrganization Profile Failure',
  props<{ error }>(),
);

const saveSubOrganizationProfileStart = createAction(
  '[SubOrganization Profile] Save SubOrganization Profile Start',
  props<{ formValue: TAdminOrganizationForm }>(),
);

const saveSubOrganizationProfileSuccess = createAction(
  '[SubOrganization Profile] Save SubOrganization Profile Success',
  props<{ profile: transport.IAdminOrganization }>(),
);

const saveSubOrganizationProfileFailure = createAction(
  '[SubOrganization Profile] Save SubOrganization Profile Failure',
  props<{ error }>(),
);

const navigateToAction = createAction(
  '[SubOrganization Profile] Navigate To Action',
  props<{ action: TDirectoryEntityPredefinedAction }>(),
);

const removeSubOrganizationProfileStart = createAction('[SubOrganization Profile] Remove Profile Start', props<{ orgId?: string }>());

const removeSubOrganizationProfileSuccess = createAction('[SubOrganization Profile] Remove SubOrganization Profile Success');

const removeSubOrganizationProfileFailure = createAction(
  '[SubOrganization Profile] Remove SubOrganization Profile Failure',
  props<{ error }>(),
);

export const subOrganizationProfileActions = {
  loadSubOrganizationProfileStart,
  loadSubOrganizationProfileSuccess,
  loadSubOrganizationProfileFailure,
  saveSubOrganizationProfileStart,
  saveSubOrganizationProfileSuccess,
  saveSubOrganizationProfileFailure,
  navigateToAction,
  removeSubOrganizationProfileStart,
  removeSubOrganizationProfileSuccess,
  removeSubOrganizationProfileFailure,
};
