import { ChangeDetectionStrategy, Component, Inject, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TOAST_DURATION, USER_ROLE } from '@transport/ui-interfaces';
import { SUPPORT_TONNA_PHONE, TnGqlClientSharedService } from '@transport/ui-store';
import { TnAlertService } from '@transport/ui-kit';
import { TranslateService } from '@ngx-translate/core';
import { catchError, throwError } from 'rxjs';

import { PublishVehicleFormComponent } from '../form/publish-vehicle-form.component';
import { PUBLISH_VEHICLE_QUERIES } from '../vehicle-queries';
import { ALERT_STATE, IAlertInputs } from '../../../../../transport-ui-kit/src/lib/alert/alert.interfaces';
import { PublishVehicleModalService } from '../vehicle.service';

@UntilDestroy()
@Component({
  selector: 'publish-vehicle-modal',
  templateUrl: './publish-vehicle-modal.component.html',
  styleUrls: ['./publish-vehicle-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
})
export class PublishVehicleModalComponent {
  @ViewChild('formComponent') formComponent!: PublishVehicleFormComponent;
  public service: PublishVehicleModalService = this.data.service;

  public isLoading = false;

  public supportPhone = SUPPORT_TONNA_PHONE;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { service: PublishVehicleModalService },
    private readonly sharedGraphQlClient: TnGqlClientSharedService,
    private readonly alertService: TnAlertService,
    private readonly translate: TranslateService,
  ) {}

  public onOk() {
    this.formComponent.form.updateValueAndValidity();
    this.formComponent.form.markAllAsTouched();
    if (this.formComponent.form.valid) {
      this.isLoading = true;
      const input = this.formComponent.getRawValue();
      this.sharedGraphQlClient
        .query<{ addVehicleAvailability: { id: string } }>(USER_ROLE.CARRIER, PUBLISH_VEHICLE_QUERIES.publishVehicle, 1, { input })
        .pipe(
          untilDestroyed(this),
          catchError(err => this.showError(err, this.translate.instant('shared.mp.publications.modal.error.publication'))),
        )
        .subscribe(() => {
          this.showSuccess();
          this.service.confirm();
        });
    }
  }

  private showSuccess() {
    this.alertService.openAlert(
      {
        title: this.translate.instant('shared.mp.publications.modal.success'),
        state: ALERT_STATE.SUCCESS,
      } as IAlertInputs,
      TOAST_DURATION.DEFAULT,
    );
  }

  private showError(err, msg) {
    this.alertService.openAlert(
      {
        title: msg,
        state: ALERT_STATE.ERROR,
      } as IAlertInputs,
      TOAST_DURATION.DEFAULT,
    );
    this.isLoading = false;
    return throwError(() => err);
  }
}
