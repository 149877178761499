import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

import { iconsCollection } from './icons-collection';
import { ISvgIconInterface } from './svg-icon.interface';

/**
 * Application material icons registry wrapper.
 */
@Injectable({
  providedIn: 'root',
})
export class TnIconsRegistryService {
  /**
   * Custom SVG icons array.
   * Information stored here is used to register SVG icons in material icons registry.
   */
  private readonly svgIcons: ISvgIconInterface[] = iconsCollection;

  /**
   * Custom icon font class aliases.
   * Information stored here is used to register font class aliases in material icons registry.
   */
  private readonly fontClassAliases: string[] = [];

  /**
   * Constructor.
   * @param matIconRegistry Material icon registry - icons registry for registering icons for usage within mat-icon selector
   * @param domSanitizer DOM sanitizer
   */
  constructor(private readonly matIconRegistry: MatIconRegistry, private readonly domSanitizer: DomSanitizer) {}

  /**
   * Initialize mat icon registry.
   */
  public initialize(): void {
    this.registerFontClassAliases();
    this.addIcons();
  }

  public getMatIconRegistry(): MatIconRegistry {
    return this.matIconRegistry;
  }

  /**
   * Returns registered icons array.
   */
  public getIcons() {
    return this.svgIcons;
  }

  /**
   * Returns registered font classe aliases array.
   */
  public getFontClassAliases() {
    return this.fontClassAliases;
  }

  /**
   * Registers custom svg icons to matIconRegistry.
   */
  private addIcons(): void {
    const icons = this.getIcons();
    for (const icon of icons) {
      this.matIconRegistry.addSvgIcon(icon.name, this.domSanitizer.bypassSecurityTrustResourceUrl(icon.path));
    }
  }

  /**
   * Registers font class aliases for usage with mat-icon by adding directives.
   *
   * For example, if fontawesome 'all' alias is registered, icons from that set can be used with mat-icon like this
   * <mat-icon fontSet="fab" fontIcon="fa-icon"></mat-icon>
   * <mat-icon fontSet="fas" fontIcon="fa-icon"></mat-icon>
   *
   * Notes regarding fontawesome if it is ever used:
   * - free plan includes only fab (font-awesome-brands) and fas (font-awesome-solid) groups
   * - icons reference: https://fontawesome.com/icons/
   */
  private registerFontClassAliases(): void {
    const aliases = this.getFontClassAliases();
    for (const alias of aliases) {
      this.matIconRegistry.registerFontClassAlias(alias);
    }
  }
}
