import { HttpErrorResponse } from '@angular/common/http';

export interface IHttpRestErrorFields {
  [key: string]: string[];
}

export interface IHttpRestError {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: tech debt
  error_fields: IHttpRestErrorFields;
  code: string;
  message: string;
}

export interface IHttpRestErrorResponse extends HttpErrorResponse {
  error: IHttpRestError;
}
