import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { TnFileToUpload } from '@transport/ui-interfaces';
import { BehaviorSubject, mergeMap, Observable, of, startWith, Subject } from 'rxjs';
import { kbToMb } from '@transport/ui-utils';
import { TnToasterFacade, TOAST_TYPE } from '@transport/ui-store';
import { TranslateService } from '@ngx-translate/core';
import { filter, takeUntil } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'common-image-input[control]',
  templateUrl: './common-image-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonImageInputComponent implements OnInit {
  public imageSrc: SafeResourceUrl | null = null;
  public imgBlobs$ = new BehaviorSubject<File[]>([]);

  @ViewChild('imgElement')
  private readonly imgElement?: ElementRef<HTMLImageElement>;

  @Input() public accept = '.png, .jpeg, .svg, .ico, .jpg, .pdf';
  @Input() public acceptHint: string | null = null;

  @Input() public required = false;
  @Input() public disabled = false;

  @Input() public errorToMessage: (errors: ValidationErrors | null) => string = () => 'Error';

  @Input() public formGroup?: FormGroup;
  @Input() public control!: FormControl;
  @Input() public dataTest: string = '';

  constructor(
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService,
    private toastFacade: TnToasterFacade,
  ) {}

  public ngOnInit(): void {
    const completed$ = new Subject();
    setTimeout(() => {
      this.control.patchValue(this.control.value);
      completed$.next(true);
    }, 1000);
    this.control.valueChanges
      .pipe(
        untilDestroyed(this),
        startWith(this.control.value),
        mergeMap(value => (typeof value === 'string' ? this.loadImgOrNull(value) : of(value))),
        filter(value => !!value),
        takeUntil(completed$),
      )
      .subscribe(imgBlob => {
        if (imgBlob !== null) {
          const file = this.blobToFile(imgBlob, 'logo.jpeg');
          this.imgBlobs$.next([file]);
        }
        this.cdr.markForCheck();
      });

    this.imgBlobs$.pipe(untilDestroyed(this)).subscribe((files: File[]) => {
      if (Boolean(FileReader) && Boolean(files?.length)) {
        const filereader = new FileReader();
        filereader.onload = () => {
          if (typeof this.imgElement?.nativeElement?.src !== 'undefined') {
            this.imgElement.nativeElement.src = filereader.result as string;
          }
        };
        filereader.readAsDataURL(files[0]);
      } else {
        if (typeof this.imgElement?.nativeElement?.src !== 'undefined') {
          this.imgElement.nativeElement.src = '';
        }
      }
    });
  }

  private blobToFile(theBlob: Blob, fileName: string): File {
    const blob = theBlob as Blob & { lastModifiedDate: Date; name: string };
    blob.lastModifiedDate = new Date();
    blob.name = fileName;
    return theBlob as File;
  }

  private loadImgOrNull(imgPath: string | null | undefined): Observable<Blob | null> {
    return typeof imgPath !== 'undefined' && imgPath !== null && imgPath !== '' ? this.loadImg(imgPath) : of(null);
  }

  public loadImg(imgUrl: string) {
    return new Observable<Blob>(subscriber => {
      const OK = 200;
      const READY = 4;
      const url = imgUrl;
      const request = new XMLHttpRequest();
      request.responseType = 'blob';
      request.open('GET', url, true);
      request.send();
      request.onreadystatechange = () => {
        if (request.readyState === READY) {
          if (request.status === OK) {
            subscriber.next(request.response);
            subscriber.complete();
          } else {
            subscriber.error(request.response);
          }
        }

        return () => {
          request.abort();
        };
      };
    });
  }

  public onChangeFiles(files: TnFileToUpload[]) {
    this.imgBlobs$.next(files.map(item => item.fileData));
    if (files.length) this.control.patchValue(files[0]);
  }

  public onInvalidFileSizeSelected(err: { maxSize: number; files: TnFileToUpload[] }): void {
    const errorText = this.translate.instant('shared.errors.maxFileSize', {
      value: kbToMb(err.maxSize),
    });
    this.toastFacade.showMessage(errorText, TOAST_TYPE.ERROR);
  }

  public clear() {
    this.imgBlobs$.next([]);
    this.control.patchValue(null);
  }
}
