<div class="container-with-table">
  <transport-drivers-toolbar (changeFilter)="setFilter($event)"></transport-drivers-toolbar>

  <div class="transport-table-wrapper flex flex-col basis-full">
    <mat-progress-bar class="table-sticky-progress-bar" *ngIf="loading$ | async" mode="indeterminate"></mat-progress-bar>
    <transport-table
      [dataSource]="dataSource"
      [tableName]="tableName"
      [columnNames]="columnNames"
      translatePath="shared.directory.drivers.table.column"
      [hasIndicators]="true"
      [hasSettings]="$any(userFacade.permissions$ | async).generalReference?.manage"
      [rowActions]="rowActions"
      (rowClick)="showItemDetails($event, 'view')"
    >
      <transport-table-column field="indicators">
        <ng-template let-driver="rowData">
          <transport-black-list-column
            [blackListInfo]="driver.blackListInfo | blackListViewData"
            [type]="blackListType.DRIVERS"
          ></transport-black-list-column>
        </ng-template>
      </transport-table-column>
      <transport-table-column field="name" [hasTooltip]="true"></transport-table-column>
      <transport-table-column field="surname" [hasTooltip]="true"></transport-table-column>
      <transport-table-column field="phone" [hasTooltip]="true">
        <ng-template let-driver="rowData">
          {{ driver.phone | phoneNumber }}
        </ng-template>
      </transport-table-column>
      <transport-table-column field="verificationStatus">
        <ng-template let-driver="rowData">
          <transport-verification-status class="value" [status]="driver.verificationStatus"></transport-verification-status>
        </ng-template>
      </transport-table-column>
      <transport-table-column field="application">
        <ng-template let-driver="rowData">
          <mat-icon
            class="application-icon"
            transportTooltip
            [context]="{ message: (driverHasMobileAppTipMessage(driver.usesDriverMobile) | translate) }"
            [svgIcon]="driver.usesDriverMobile ? 'application-installed-outlined' : 'application-not-installed-outlined'"
          ></mat-icon>
        </ng-template>
      </transport-table-column>
      <transport-table-column field="actions">
        <ng-template let-driver="rowData">
          <ng-container [ngTemplateOutletContext]="{driver: driver}" [ngTemplateOutlet]="rowActionsMenu"></ng-container>
        </ng-template>
      </transport-table-column>
    </transport-table>

    <transport-empty-list *ngIf="isEmpty$ | async" class="flex justify-center items-center"></transport-empty-list>
    <mat-paginator #paginator showFirstLastButtons class="flex justify-center" (page)="changePage($event)"> </mat-paginator>
  </div>
</div>
<ng-template #rowActionsMenu let-driver="driver">
  <button
    mat-icon-button
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    transportTooltip
    [context]="{ message: 'shared.directory.drivers.table.column.actions' | translate }"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu">
    <transport-table-item-actions-buttons
      *ngIf="$any(userFacade.permissions$ | async) as permissions"
      (view)="showItemDetails(driver, 'view')"
      (copy)="showItemDetails(driver, 'copy')"
      (remove)="remove(driver)"
      (edit)="showItemDetails(driver, 'edit')"
      [isModifyEnabled]="true"
      [isEditEnabled]="!driver.isArchived && permissions.generalReference?.manage"
      [isRemoveEnabled]="!driver.isArchived && permissions.generalReference?.manage"
      [isCopyEnabled]="permissions.generalReference?.manage"
    >
    </transport-table-item-actions-buttons>
  </mat-menu>
</ng-template>
