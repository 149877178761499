import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IDirectoryState } from '../../../directory-state.interface';
import { IAgentCarrierState } from '../reducers/agent-carrier.reducer';

const getDirectoryState = createFeatureSelector<IDirectoryState>('directory');
const agentCarrierStateSelector = createSelector(getDirectoryState, (state: IDirectoryState) => state.agentCarrier);

const agentCarrier = createSelector(agentCarrierStateSelector, (state: IAgentCarrierState) => state.agent);

const legalForms = createSelector(agentCarrierStateSelector, (state: IAgentCarrierState) => state.legalForms);

const loading = createSelector(agentCarrierStateSelector, (state: IAgentCarrierState) => state.loading);

const organizationSearchError = createSelector(agentCarrierStateSelector, (state: IAgentCarrierState) => state.organizationSearchError);

const companyName = createSelector(agentCarrierStateSelector, (state: IAgentCarrierState) => state.companyName);

export const agentCarrierSelectors = {
  agentCarrier,
  legalForms,
  loading,
  organizationSearchError,
  companyName,
};
