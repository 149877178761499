import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { ITnState } from '../../../state';
import { TnTableStateFacade } from '../../../table/table-state-facade';
import { tableActions } from './actions/choose-trucks.actions';
import { chooseTrucksTableSelectors } from './selectors/trucks.selectors';
import { ITrucksFilter } from './trucks-state.facade';

@Injectable({
  providedIn: 'root',
})
export class TnChooseTrucksFacade extends TnTableStateFacade<ITrucksFilter> {
  constructor(public store: Store<ITnState>, public action: Actions) {
    super(store, tableActions, chooseTrucksTableSelectors);
  }
}
