import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { USER_ROLE } from '@transport/ui-interfaces';
import { Observable, of } from 'rxjs';

import { TnCurrentUserFacade } from '../../current-user/current-user.facade';
import { TnToasterFacade } from '../../toaster/toaster.facade';

@Injectable({
  providedIn: 'root',
})
export class TnHelpGuard implements CanActivate {
  constructor(
    private readonly userFacade: TnCurrentUserFacade,
    private readonly router: Router,
    private readonly toastFacade: TnToasterFacade,
  ) {}

  /**
   * Checks if user can activate a help page.
   */
  public canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    if (this.userFacade.isUserRole(USER_ROLE.CARRIER) && !Boolean(this.userFacade.currentUser.isWorkingWithTN)) {
      const userRole: USER_ROLE = this.userFacade.currentUser.role;
      this.toastFacade.showMessage('shared.errors.permission');
      void this.router.navigate([`/orders/${userRole}`]);
    }
    return of(true);
  }
}
