<div class="flex flex-wrap gap-x-3 bg-gray-50 rounded-md border border-gray-100 p-4">
  <div class="flex justify-between items-start w-full">
    <div class="text-lg text-gray-700 font-medium">
      {{ 'carrier.directory.marketplaceDriver.form.accreditation.title' | translate }}
    </div>
    <div [ngClass]="[
      'px-1 rounded-sm text-xs font-bold',
      driverStatus === DRIVER_STATUS.PENDING ? 'bg-orange-100 text-orange-700' : '',
      driverStatus === DRIVER_STATUS.PASSED ? 'bg-green-100 text-green-700' : '',
      driverStatus === DRIVER_STATUS.NEW ? 'bg-gray-500 text-white' : '',
      driverStatus === DRIVER_STATUS.NOT_PASSED ? 'bg-red-100 text-red-700' : '',
      ]"
    > {{ STATUS_TEXT[driverStatus] | translate }}
    </div>
  </div>
  <div class="mb-3 text-gray-500 font-normal text-xs w-full">
    {{ 'carrier.directory.marketplaceDriver.form.accreditation.description' | translate }}
  </div>
  <ng-content></ng-content>
</div>
