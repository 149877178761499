<mat-form-field class="w-full autocomplete" appearance="outline">
  <mat-icon *ngIf="matIcon">{{matIcon}}</mat-icon>
  <input
    type="text"
    matInput
    [ngClass]="{withIcon: matIcon}"
    (blur)="onBlurOrgInput($event)"
    [formControl]="orgControl"
    [matAutocomplete]="auto"
    data-test="organization"
    [placeholder]="'shared.reg.organization.placeholder' | translate"
  >
  <mat-error *ngIf="orgControl?.errors?.isExist" class="text-xs">
    {{ 'shared.errors.incorrectInn' | translate }}
  </mat-error>
  <mat-error *ngIf="orgControl?.errors?.organizationsNotFound" class="text-xs">
    {{ 'shared.errors.organizationsNotFound' | translate }}
  </mat-error>
  <mat-error *ngIf="orgControl?.errors?.notSelected && !orgControl?.errors?.organizationsNotFound" class="text-xs">
    {{ 'shared.errors.innNotSelected' | translate }}
  </mat-error>
  <mat-error *ngIf="orgControl?.errors?.invalid" class="text-xs">
    {{ 'shared.errors.innInvalid' | translate }}
  </mat-error>
  <!-- <mat-error *ngIf="orgControl?.errors?.required" class="text-xs">
    {{ 'shared.errors.required' | translate }}
  </mat-error> -->
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
    <mat-option *ngFor="let option of filteredOptions" [value]="option" class="mat-option">
      <div class="option-title">
        {{option.name}}
      </div>
      {{option.inn}} {{option.address}}
    </mat-option>
  </mat-autocomplete>
</mat-form-field>
