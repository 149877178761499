import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Pipe({
  name: 'cargoParamsFormat',
})
export class TnCargoParams implements PipeTransform {
  // Преобразование веса и объема в 10т / 10м³ или 10т (если объем не указан или равен 0)
  constructor(private readonly translate: TranslateService) {}

  public transform(weight: string | number | undefined | null, volume: string | number | undefined | null): string {
    if (weight) {
      const result = `${this.translate.instant(`shared.pipe.cargoParams.weight`, { value: weight })}${
        Boolean(volume) ? this.translate.instant(`shared.pipe.cargoParams.volume`, { value: volume }) : ''
      }`;
      return result;
    }
    return '';
  }
}
