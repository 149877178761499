import * as sharedFragments from '../shared/graphql-fragments-shared';

export const STORAGE_POINT_FRAGMENT_SHORT = `
  id
  name
`;

export const PAYMENT_TYPE_FRAGMENT = `
  id
  name
`;

export const LOT_FRAGMENT = `
  id
  order {
    id
  }
  startDatetime
  endDatetime
  startPrice
  minStepPrice
  lastBet
  lastBetDatetime
  isLastBetMine
  enableNotifications
  auctionStatus
  isLastBetByMyOrganization
  hasExtraConditionsForMarket
  viewEndDatetime
  betCanEqualStartPrice
`;

export const LOT_FRAGMENT_SHORT = `
  id
  endDatetime
  startPrice
  lastBet
  lastBetDatetime
  isLastBetMine
  auctionStatus
  isLastBetByMyOrganization
  hasExtraConditionsForMarket
  viewEndDatetime
`;

export const BIDDING_LOT_FRAGMENT_SHORT = `
  id
  viewEndDatetime
  startPrice
  biddingStatus
`;

export const BIDDING_LOT_FRAGMENT = `
  id
  endDatetime
  viewEndDatetime
  startPrice
  minStepPrice
  biddingStatus
  myLastBet
`;

export const ORDERS_FRAGMENT = `
  id
  externalNo
  loadingPlaces {
    factArriveDatetimeFrom
    factArriveDatetimeTo
    storagePoint {
      id
      name
      longitude
      latitude
    }
    loadingType {
      id
      name
    }
  }
  unloadingPlaces {
    factArriveDatetimeFrom
    factArriveDatetimeTo
    storagePoint {
      id
      name
      longitude
      latitude
    }
    loadingType {
      id
      name
    }
  }
  volume
  status
  isVatIncluded
  cargoType
  loadingDate
  unloadingDate
  price {
    amount
    currency
  }
  weight
  temperatureMode
  comment
  allocationType
  isMarket
  statusOnEvent
  endDatetime
  viewEndDatetime
  secondsToLifeTimeExpired
  secondsToReservationTimeExpired
  customerOrganization {
    ${sharedFragments.ORGANIZATION_SHORT_FRAGMENT}
  }
  signingWay
  documentActualStatus(documentType: ALL) {
    status
    documentType {
      innerName
    }
  }
  documentStatusHistory(documentType: ALL) {
    status
    documentType {
      innerName
    }
  }
  vehicleRequirements {
    bodySubtype {
      name
    }
  }
  driver {
    surname
    name
    patronymic
  }
  vehicle {
    regNo
  }
  isShipmentOnAgreedDate
  isPreviouslyAssigned
  viewProcessDeadline
  gracePeriod
  sourceOrder {
    id
  }
  tender {
    lotNumber
  }
  diy,
  slaObserved
`;

export const BIDDING_ORDERS_FRAGMENT = `
  id
  externalNo
  loadingPlaces {
    factArriveDatetimeFrom
    factArriveDatetimeTo
    storagePoint {
      id
      name
      longitude
      latitude
    }
    loadingType {
      id
      name
    }
  }
  unloadingPlaces {
    factArriveDatetimeFrom
    factArriveDatetimeTo
    storagePoint {
      id
      name
      longitude
      latitude
    }
    loadingType {
      id
      name
    }
  }
  allocationType
  loadingDate
  unloadingDate
  volume
  weight
  temperatureMode
  comment
  isMarket
  vehicleRequirements {
    bodySubtype {
      name
    }
  }
  customerOrganization {
    ${sharedFragments.ORGANIZATION_SHORT_FRAGMENT}
  }
  myOrganizationBet
  biddingStatusForCarrier
`;

export const ORDER_FRAGMENT = `
  id
  tenderNumber
  edmStatus
  edmStatuses {
    contract
    termAgreement
  }
  waiverRequired
  isFactPointDatetimeRequired
  carrierContractSigning
  documentFlowSettings
  createdDatetime
  acceptDeadline
  carrierAckStatus
  carrierBusinessRelationshipToOwner
  actualContractNumber
  agentCarrierInfo {
    inn
    kpp
    name
    phone
    address
  }
  slaObserved
  sla {
    id
    name
    nonTrackableCriteria {
      name
      description
    }
    trackableCriteria {
      description
      thresholdValue
      isActive
    }
  }
  loadingPlaces {
    action
    contactPersons {
      fullName
      phoneNumber
    }
    storagePoint {
      ${sharedFragments.STORAGE_POINT_FRAGMENT}
    }
    arriveDatetimeFrom
    arriveDatetimeTo
    comment
    factArriveDatetimeFrom
    factArriveDatetimeTo
    loadingType {
      id
      name
    }
    seqId
    attachedDocuments {
      filename
      url
    }
    loadingEvents {
      event
      dateTime
    }
  }
  unloadingPlaces {
    action
    contactPersons {
      fullName
      phoneNumber
    }
    storagePoint {
      ${sharedFragments.STORAGE_POINT_FRAGMENT}
    }
    arriveDatetimeFrom
    arriveDatetimeTo
    comment
    factArriveDatetimeFrom
    factArriveDatetimeTo
    loadingType {
      id
      name
    }
    seqId
    attachedDocuments {
      filename
      url
    }
  }
  createdDatetime
  loadingDate
  unloadingDate
  volume
  externalNo
  genDocumentsPending
  status
  cargoType
  cargoPackagesCount
  cargos {
    name
    price {
      amount
      currency
    }
  }
  isVatIncluded
  price {
    amount
    currency
  }
  weight
  temperatureMode
  comment
  vehicle {
    id
    vehicleMake
    vehicleType
    vehicleId
    regNo
    ownershipType
    isThirdParty
    blackListInfo(orderId: $orderId) {
      cargoOwner {
        id
        name
      }
      startDate
      workProhibited
      reason
      description
    }
    vehicletrailer {
      trailerType
      trailerId
      blackListInfo(orderId: $orderId) {
        cargoOwner {
          id
          name
        }
        startDate
        workProhibited
        reason
        description
        nomenclatureType
      }
    }
    carrierOrganization {
      id
      name
    }
    agentCarrier {
      id
      name
      vat {
        id
        name
      }
    }
  }
  driver {
    ${sharedFragments.ORDER_FRAGMENT_DRIVER}
    blackListInfo(orderId: $orderId) {
      cargoOwner {
        id
        name
      }
      startDate
      workProhibited
      reason
      description
    }
    agentCarrier {
      id
      name
      vat {
        id
        name
      }
    }
  }
  coDriver {
    ${sharedFragments.ORDER_FRAGMENT_DRIVER}
    blackListInfo(orderId: $orderId) {
      id
      cargoOwner {
        id
        name
      }
      startDate
      workProhibited
      reason
      description
    }
    agentCarrier {
      id
      name
      vat {
        id
        name
      }
    }
  }
  allocationType
  containerInfo {
    containerNumber
    containerSeal
  }
  vehicleRequirements {
    containerType
    bodySubtypes {
      id
      name
    }
    capacity
    volume
    length
    width
    height
    loadingTypes {
      id
      name
    }
    deliveryMethod
    temperatureModeFrom
    temperatureModeTo
  }
  cargoPlaceRequirements {
    packagingType
    length
    width
    height
  }
  insurance {
    isOwnerInsurance
    insuranceContract {
      id
    }
    status
    data
    certificateUrl
    invoiceUrl
  }
  owner {
    id
    email
    firstName
    lastName
    phoneNumber
  }
  priceWithVat {
    amount
    currency
  }
  priceWithoutVat {
    amount
    currency
  }
  isMarket
  statusOnEvent
  paymentPeriod
  lot {
    hasExtraConditionsForMarket
  }
  paymentType{
    ${PAYMENT_TYPE_FRAGMENT}
  }
  uploadedDocuments{
    ${sharedFragments.ORDER_UPLOADED_DOCUMENT_FRAGMENT}
  }
  canModifyUploadedDocuments
  endDatetime
  viewEndDatetime
  secondsToLifeTimeExpired
  secondsToReservationTimeExpired
  gracePeriod
  extraServices {
    id
    name
    price
    vatAmount
    currency
  }
  settingsESignRequired {
    id
  }
  customerOrganization {
    ${sharedFragments.ORGANIZATION_SHORT_FRAGMENT}
    inn
  }
  routeSheet {
    isDriverOnline
  }
  signingWay
  documentActualStatus(documentType: ALL) {
    status
    documentType {
      innerName
    }
  }
  documentStatusHistory(documentType: ALL) {
    status
    documentType {
      innerName
    }
  }
  canUnreserveTransport
  isReadonly
  isInsuranceRequired
  ownerInsuranceContract {
    id
    parameters
    generalNumber
    tariff
    startDatetime
    endDatetime
    isDefault
    insuranceCompany {
      codename
      name
    }
  }
  orderAttributes {
    hasOwnVehicle
    isDriverAndVehicleChanging
    isDriverAndVehicleChangeTimerOnPause
    driverAndVehicleChangeTimeLeft
  }
  waybillRequired
  hasWaybillInfo
  actualCarrierOrganization {
    id
    name
  }
  agentUsesOwnVehicle
  agentFeeAndFreightDetails {
    agentCarrierFreightIncludingVat
    agentCarrierVatRate
    agentCarrierFreightVatAmount
    agentVatAmountPerAgentCarrier
    agentFeeIncludingVat
    agentFeeVatRate
    agentFeeVatAmount
    totalPrice
  }
  carrierContract {
    id
    contractNumber
    name
    agentType
    isActive
  }
  cargoNomenclatureTypes
  timeOrderReservation
  viewProcessDeadline
  isPreviouslyAssigned
  tender {
    lotNumber
    bidsEndDatetime
  }
  canChangeContract
  diy
  carriagePrice{
    name
    price
    vatAmount
    currency
  }
  customFields {
    key
    title
    value
  }
  isSnapshot
`;

export const ORDER_REPORT_FRAGMENT = `
  id
  externalNo
  uploadedDocuments {
    documentType {
      id
      innerName
      name
    }
  }
  loadingPlace {
    address
  }
  loadingDate
  unloadingDate
  unloadingPlace {
    address
  }
  unloadingPlaces {
    arriveDatetimeFrom
  }
  vehicle {
    regNo
  }
  price
  driver {
    surname
    name
    patronymic
  }
  actualCarrierOrganization {
    id
    name
    vat {
      name
      rate
    }
  }
  carrierContract {
    id
    contractNumber
    name
  }
  agentUsesOwnVehicle
  contractPrice
`;

export const ORDER_FRAGMENT_ROUTE_SHEET = `
  id
  createdDatetime
  loadingPlaces {
    storagePoint {
      ${sharedFragments.STORAGE_POINT_FRAGMENT}
    }
    arriveDatetimeFrom
    arriveDatetimeTo
    loadingType {
      id
      name
    }
    seqId
  }
  unloadingPlaces {
    storagePoint {
      ${sharedFragments.STORAGE_POINT_FRAGMENT}
    }
    arriveDatetimeFrom
    arriveDatetimeTo
    loadingType {
      id
      name
    }
    seqId
  }
  loadingPlace {
    name
  }
  unloadingPlace {
    name
  }
  status
  routeSheet {
    isDriverOnline
  }
  cargoType
`;

export const ORGANIZATION_FRAGMENT = `
  id
  name
  isTn
  isCompanyGroup
  legalForm {
    ${sharedFragments.LEGAL_FORM_FRAGMENT}
  }
  inn
  kpp
  legalAddress
  postalAddress
  registrationNumber
  vat {
    id
    name
  }
  showOrderExternalNo
`;

export const ORGANIZATION_CARRIER_PROFILE_FRAGMENT = `
  isArchived
  createdAt
  id
  vat {
    id
    name
    rate
  }
  name
  signer
  signerPerson {
    fullName
    phoneNumber
    email
    country
  }
  about
  phoneNumber
  email
  legalForm {
    ${sharedFragments.LEGAL_FORM_FRAGMENT}
  }
  inn
  kpp
  legalAddress
  postalAddress
  registrationNumber
`;
