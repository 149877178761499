import { gql } from 'apollo-angular';

export const PUBLISH_VEHICLE_QUERIES = {
  publishVehicle: gql`
    mutation AddVehicleAvailability($input: VehicleAvailabilityInput!) {
      addVehicleAvailability(input: $input) {
        id           
      }
    }
  `,
  addVehicleAvailabilityRiskProfileDossier: gql`
    mutation addVehicleAvailabilityRiskProfileDossier ($docType: String!, $docVal: String!, $profileId: String!) {
      addVehicleAvailabilityRiskProfileDossier(
        docType: $docType,
        docVal: $docVal,
        profileId: $profileId,
      ) {
        dossierId
      }
    }
  `,
  createVehicleAvailabilityRiskProfile: gql`
   mutation createVehicleAvailabilityRiskProfile($vehicleAvailabilityId: ID!) {  
      createVehicleAvailabilityRiskProfile (vehicleAvailabilityId: $vehicleAvailabilityId){
        profileId
      }
    }
  `,
  submitVehicleAvailabilityRiskProfile: gql`
   mutation submitVehicleAvailabilityRiskProfile($profileId: String!, $vehicleAvailabilityId: ID!) {  
    submitVehicleAvailabilityRiskProfile (
      profileId: $profileId,
      vehicleAvailabilityId: $vehicleAvailabilityId,
    ){
      ok
    }
  }
  `,
};
