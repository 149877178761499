import { createSelector } from '@ngrx/store';

import { ITnState } from '../state/index';
import { ITimezoneState } from './time-zone.state';

export const getTimezoneValue = (state: ITnState) => state.timezone;

export const timezoneData = createSelector(getTimezoneValue, (state: ITimezoneState) => {
  return state.timezones;
});
