import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ITableFilterOption, TnTableFilterDirective } from '@transport/ui-components';
import { TABLE_FILTER } from '@transport/ui-interfaces';
import { IDriversFilter, TnCurrentUserFacade, TnDirectoryDriversFacade } from '@transport/ui-store';
import { tap } from 'rxjs/operators';

@Component({
  selector: 'transport-drivers-toolbar',
  templateUrl: './drivers-toolbar.component.html',
  styleUrls: ['./drivers-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnDriversToolbarComponent extends TnTableFilterDirective<IDriversFilter> implements OnInit {
  constructor(
    public readonly userFacade: TnCurrentUserFacade,
    public readonly router: Router,
    public readonly translate: TranslateService,
    protected readonly driversFacade: TnDirectoryDriversFacade,
  ) {
    super(translate, driversFacade);
  }

  public ngOnInit(): void {
    void this.driversFacade.currentFilter$
      .pipe(
        untilDestroyed(this),
        tap((filterValue?: IDriversFilter) => {
          if (Boolean(filterValue?.availability)) {
            this.selectedTabIndex = this.filterOptions.findIndex(({ value }) => value === filterValue?.availability);
          }
        }),
      )
      .subscribe();
  }

  public onFilterChange(value: ITableFilterOption['value'] | IDriversFilter) {
    this.changeFilter.emit(value as IDriversFilter);
  }

  protected getFilterOptions() {
    return [
      {
        label: 'shared.directory.drivers.filter.allDrivers',
        value: TABLE_FILTER.ALL,
      },
      {
        label: 'shared.directory.drivers.filter.activeDrivers',
        value: TABLE_FILTER.ACTIVE,
      },
      {
        label: 'shared.directory.drivers.filter.archivedDrivers',
        value: TABLE_FILTER.ARCHIVED,
      },
    ];
  }

  public gotoDriverCreationPage(): void {
    void this.router.navigate(['/directory/carrier/drivers/create']);
  }
}
