import { createSelector } from '@ngrx/store';
import { TnReconciliationActDetailsFormValue, USER_ROLE } from '@transport/ui-interfaces';

import { reconciliationActFeatureKey } from '../reducers';
import * as fromFeature from './index';

export const selectReconciliationActState = createSelector(
  fromFeature.selectReconciliationActFeatureState,
  state => state[reconciliationActFeatureKey],
);

export const selectReconciliationOrganizations = createSelector(selectReconciliationActState, state => state.organizations);

export const selectUserOrganization = createSelector(selectReconciliationActState, state => state.userOrganization);

export const selectIsUserOwner = createSelector(selectUserOrganization, userOrganization => userOrganization?.role === USER_ROLE.OWNER);

export const selectIsUserCarrier = createSelector(selectUserOrganization, userOrganization => userOrganization?.role === USER_ROLE.CARRIER);

export const selectNeedScrollToResults = createSelector(selectReconciliationActState, state => state.needScrollToResultsAfterPageInit);

export const selectReconciliationRules = createSelector(selectReconciliationActState, state => state.rules);

export const selectFormMode = createSelector(selectReconciliationActState, state => state.formMode);

export const selectAct = createSelector(selectReconciliationActState, state => state.act);

export const selectActGuid = createSelector(selectAct, act => act?.guid);

export const selectIsFormValid = createSelector(selectReconciliationActState, state => state.isFormValid);
export const selectIsFormDirty = createSelector(selectReconciliationActState, state => state.isFormDirty);

export const selectActFormValue = createSelector(selectAct, state => {
  if (state === null) {
    return null;
  }
  const result = new TnReconciliationActDetailsFormValue(state);
  return result;
});

export const selectIsDisabledFormMode = createSelector(selectFormMode, mode => {
  const result = mode === 'view';
  return result;
});

export const selectIsSaveBtnVisible = createSelector(selectFormMode, mode => {
  const result = mode === 'create';
  return result;
});

export const selectIsResultsVisible = createSelector(selectFormMode, mode => {
  const result = mode !== 'create';
  return result;
});

export const completedActs = createSelector(selectReconciliationActState, state => state.completedActsGuid);
