import { gql } from 'apollo-angular';

export const DRIVER_QUERIES = {
  getDriver: gql`
    query driver($id: ID!) {
      driver(id: $id) {
        id
        accrStatus
        accrComment
        surname
        name
        patronymic
        passportSeries
        passportNumber
        passportPlace
        passportDate
        identityCardNumber
        identityCardPlace
        identityCardDate
        licenceSeries
        licenceNumber
        licencePlace
        licenceDate
        inn
        birthday
        phone
        inn
        iin
        addPhone
        gender
        country
        agentCarrier {
          id
          carrierName
          inn
        }
        blackListInfo {
          cargoOwner {
            id
            name
          }
          workProhibited
          reason
          description
        }
      }
    }
  `,
  editDriver: gql`
    mutation EditDriver($input: EditDriverInput) {
      editDriver(input: $input) {
        id
      }
    }
  `,
  addDriver: gql`
    mutation AddDriver($input: AddDriverInput) {
      addDriver(input: $input) {
        id
      }
    }
  `,
  archiveDriver: gql`
    mutation ArchiveDriver($driverId: ID!) {
      sendDriverToArchive(driverId: $driverId) {
        id
      }
    }
  `,
  restoreDriver: gql`
    mutation restoreDriverFromArchive($driverId: ID!){
      restoreDriverFromArchive(driverId: $driverId){
        id
        isArchived
      }
    }
  `,

  createDriverRiskProfile: gql`
    mutation createDriverRiskProfile($driverId: Int!) {
      createDriverRiskProfile(driverId: $driverId) {
        profileId
      }
    }
  `,

  submitDriverRiskProfile: gql`
    mutation submitDriverRiskProfile($profileId: String!, $driverId: ID!) {
      submitDriverRiskProfile(profileId: $profileId, driverId: $driverId) {
        ok
      }
    }
  `,

  addDriverRiskProfileDossier: gql`
    mutation addDriverRiskProfileDossier(
      $docType: String!,
      $docVal: String!,
      $profileId: String!
    ) {
      addDriverRiskProfileDossier(
        docType: $docType,
        docVal: $docVal,
        profileId: $profileId
      ) {
        dossierId
      }
    }
  `,

  getOrganizations: gql`
  query getAgentCarriers(
    $first: Int
    $offset: Int
    $availability: AvailabilityEnum
    $searchQuery: String
    ) {
    items: agentCarriers(
      availability: $availability
      searchQuery: $searchQuery
      first: $first
      offset: $offset
    ) {
      id
      carrierName
      inn
      country
      iin
      bin
    }
  }
  `,
};
