import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { USER_ROLE } from '@transport/ui-interfaces';
import { map } from 'rxjs/operators';

import { getCurrentUser } from '../../current-user';

/**
 * User role guard.
 */
@Injectable({
  providedIn: 'root',
})
export class TnUserRoleGuard implements CanActivate {
  constructor(private readonly store: Store, private readonly router: Router) {}

  /**
   * Checks if user can activate a particular router path.
   *
   * Redirects user to correct path which corresponds currently logged in user role.
   */
  public canActivate(next?: ActivatedRouteSnapshot, state?: RouterStateSnapshot) {
    const availableRoles = (next?.data.roles as Array<USER_ROLE>) ?? [];
    return this.store.select(getCurrentUser).pipe(
      map(({ role }) => {
        const correctRole = availableRoles.some(availableRole => availableRole === role) || state?.url.toString().includes(role);
        if (!Boolean(correctRole)) {
          void this.router.navigate([`/orders/${role}`]);
        }
        return Boolean(correctRole);
      }),
    );
  }
}
