import { AbstractControl, ValidatorFn } from '@angular/forms';

export function nextBeforePrevValidator(): ValidatorFn {
  return (control: AbstractControl) => {
    if (control.value) {
      const nextDate = control.value;
      const index = control.parent?.value?.seqId ? control.parent?.value?.seqId : 1;
      const prevDate = (control.parent?.parent?.controls as AbstractControl[])
        ?.find(parentControl => parentControl.value.seqId === index - 1)?.get('date')?.value;
      if (nextDate && prevDate && Boolean(nextDate.isBefore(prevDate, 'day'))) {
        return {
          nextBeforePrev: true,
        };
      }
    }
  return null;
  };
}
