import { Actions } from '@ngrx/effects';
import { filter, switchMap } from 'rxjs/operators';

import { TnConfirmationService } from '../../../../confirmation/confirmation.service';
import { TnCarrierDirectoryDriversApiService } from '../../drivers-table/carrier-directory-drivers-api.service';

export class TnDriverBaseEffects {
  public removeDriver(id) {
    return this.confirmation.openByPrefix('carrier.directory.driver.confirm.remove').pipe(
      filter(result => Boolean(result)),
      switchMap(() => {
        return this.driverDirectoryService.removeDriver(id);
      }),
    );
  }

  constructor(
    public readonly actions$: Actions,
    public readonly confirmation: TnConfirmationService,
    public readonly driverDirectoryService: TnCarrierDirectoryDriversApiService,
  ) {}
}
