import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { ITruckForm, trimmedStringValidator, VEHICLE_FORM_CONSTANTS } from '@transport/ui-interfaces';

export function createTruckFormGroup() {
  return new FormGroup<ITruckForm>({
    id: new FormControl<string>(''),
    vehicleMake: new FormControl<string>('', Validators.required),
    vehicleCountry: new FormControl<string>('', Validators.required),
    vehicleId: new FormControl<string>(
      '',
      Validators.compose([Validators.required, Validators.maxLength(VEHICLE_FORM_CONSTANTS.MAX_GOSNUM_LENGTH), trimmedStringValidator]),
    ),
    vehicleCertificate: new FormControl<string>(
      '',
      Validators.compose([Validators.maxLength(VEHICLE_FORM_CONSTANTS.MAX_STSNUM_LENGTH), trimmedStringValidator]),
    ),
    vehicleVin: new FormControl<string>(''),
    isArchived: new FormControl<boolean>(false),
  });
}

export function mapTruckFormInputValueToInner<T extends ITruckForm>(value: T) {
  return {
    id: value.id ?? '',
    vehicleMake: value.vehicleMake ?? '',
    vehicleCountry: value.vehicleCountry ?? '',
    vehicleId: value.vehicleId ?? '',
    vehicleCertificate: value.vehicleCertificate ?? '',
    vehicleVin: value.vehicleVin ?? '',
    isArchived: value.isArchived ?? false,
  };
}
