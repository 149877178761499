<transport-tw-input-field
  [class]="inputFieldClass"
  [control]="formControl"
  [size]="size"
  [errorsSize]="errorsSize"
  [label]="label"
  [labelClasses]="labelClasses"
  [labelAddition]="labelAddition"
  [caption]="caption"
  [captionClasses]="captionClasses"
  [showErrors]="showErrors"
  [required]="required"
  [errorToMessage]="errorToMessage"
>
  <transport-tw-input
    class="relative"
    [ngClass]="{'!h-8': size !== 'md'}"
    [size]="size"
    [control]="formControl"
    [leftAddon]="leftAddon"
    [rightAddon]="rightAddon"
  >
    <ng-content></ng-content>
    <input
      tw-input
      autocomplete="off"
      transportNumberOnly
      [formControl]="$any(formControl)"
      [lengthAfterSeparator]="lengthAfterSeparator"
      [onlyIntegers]="onlyIntegers"
      [value]="displayValue"
      [placeholder]="placeholder"
      [class]="inputClasses ? inputClasses : ''"
      [required]="required"
      [maxlength]="maxlength"
      [allowNegativeNumbers]="allowNegativeNumbers"
      [attr.disabled]="disabled ? true : null"
      [attr.data-test]="dataTest"
      (input)="getDisplayValue()"
    />
  </transport-tw-input>
</transport-tw-input-field>
