import { createReducer, on } from '@ngrx/store';
import { PAGE_ACTIONS } from '@transport/ui-interfaces';

import { changePageMode } from '../actions/cargo-types-remove.actions';

export interface ICargoTypeState {
  mode: PAGE_ACTIONS;
}

export const initialState: ICargoTypeState = {
  mode: PAGE_ACTIONS.VIEW,
};

const cargoTypeReducerFunc = createReducer(
  initialState,
  on(changePageMode, (state, { mode }) => {
    return {
      ...state,
      mode,
    };
  }),
);

export function cargoTypeReducer(state, action) {
  return cargoTypeReducerFunc(state, action);
}
