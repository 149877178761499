import { RECONCILIATION_ACT_ERROR_TYPE } from '../reconciliation-act-error-type';
import { RECONCILIATION_ACT_STATUS } from '../reconciliation-act-status';

export interface IReconcilationActWebsocketMessageDto {
  id: string;
  guid: string;
  diff_results_filename?: string;
  full_results_filename?: string;
  has_diff: boolean;
  status: RECONCILIATION_ACT_STATUS;
  error_type: RECONCILIATION_ACT_ERROR_TYPE;
}
