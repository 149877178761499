import { gql } from 'apollo-angular';

export const GUIDE_QUERIES = {
  getBodyTypes: gql`
    query bodyTypes {
      bodyTypes {
        id
        name
        subtypes {
          id
          name
        }
      }
    }
  `,
  vehicleTemplates: gql`
  query vehicleRequirementsTemplates($first: Int, $offset: Int, $name: String, $withArchived: Boolean, $totalCount: Boolean = false) {
    vehicleRequirementsTemplates(first: $first, offset: $offset, name: $name, withArchived: $withArchived) {
      id
      name
      vehicleRequirements {
        bodySubtypes {
          id
          name
        }
        loadingTypes {
          id
          name
        }
        containerType
        capacity
        volume
        temperatureModeFrom
        temperatureModeTo
        deliveryMethod
        specialRequirements
      }
    }
    totalCount: vehicleRequirementsTemplatesCount(withArchived: $withArchived) @include(if: $totalCount)
  }`,
  getStoragePoints: gql`
    query storagePoints($typePoint: StoragePointTypePoint) {
      storagePoints(typePoint: $typePoint) {
        id
        name
        companyName
        coordinateAccuracy
        pointName
        settlement
        actions
      }
    }
  `,
  getCarrierOrganizations: gql`
    query CarrierOrganizations($state: [String]) {
      carrierOrganizations(stateIn: $state) {
        id
        name
         contracts {
          id
          contractNumber
          startDate
         }
      }
    }
  `,
  getMyCarrierGroups: gql`
    query myCarrierGroups {
      myCarrierGroups {
        id
        name
      }
    }
  `,
  carrierOrganization: gql`
    query carrierOrganization($id: ID!) {
      carrierOrganization(id: $id) {
        contracts(isArchived: false) {
          id
          name: contractNumber
        }
      }
    }
  `,
};
