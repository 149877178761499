import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ICargoType, PAGE_ACTIONS } from '@transport/ui-interfaces';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, tap } from 'rxjs/operators';

import { TnConfirmationService } from '../../../../confirmation/confirmation.service';
import {
  changePage,
  changePageMode,
  removeCargoType,
  removeCargoTypeFailure,
  removeCargoTypeSuccess,
} from '../actions/cargo-types-remove.actions';
import { TnOwnerDirectoryCargoTypesApiService } from '../owner-directory-cargo-types-api.service';
import { TnAlertService } from '@transport/ui-kit';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TnCargoTypesEffects {
  public readonly removeCargoType = createEffect(() =>
    this.actions$.pipe(
      ofType(removeCargoType),
      concatMap(({ cargoType }) =>
        this.confirmation.openByPrefix('owner.cargoType.confirm.remove').pipe(
          filter(result => Boolean(result)),
          switchMap(() => {
            const name = cargoType.name ?? '';
            return this.ownerDirectoryCargoTypesService.removeCargoType(cargoType?.id ?? '').pipe(
              map((cargoTypeResult: ICargoType) => {
                this.alertService.shortSuccess(this.translate.instant('owner.cargoType.success.remove', { name }));
                return removeCargoTypeSuccess({ cargoType: cargoTypeResult });
              }),
              catchError((error: Error) => {
                this.alertService.shortError(this.translate.instant('owner.cargoType.failure.remove', { name }));
                return of(removeCargoTypeFailure({ error }));
              }),
            );
          }),
        ),
      ),
    ),
  );

  public readonly changePageAction = createEffect(() =>
    this.actions$.pipe(
      ofType(changePage),
      tap(({ id, action }) => {
        if (action === PAGE_ACTIONS.CREATE) {
          void this.router.navigate(['/directory/owner/cargo-types/create']);
        } else {
          void this.router.navigate([`/directory/owner/cargo-types/detail/${id ? `${id}/` : ''}${action}`]);
        }
      }),
      map(({ action }) => changePageMode({ mode: action })),
    ),
  );

  constructor(
    public readonly actions$: Actions,
    private readonly confirmation: TnConfirmationService,
    private readonly ownerDirectoryCargoTypesService: TnOwnerDirectoryCargoTypesApiService,
    private alertService: TnAlertService,
    private readonly router: Router,
    private translate: TranslateService,
  ) {}
}
