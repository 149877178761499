export interface ICarriersRatingDetailsReportData {
  id: string;

  /** Номер заявки на транспорт */
  deliveryRequestNumber: string;

  /** Дата и время создания заявки на транспорт в 1С */
  deliveryRequestDateUtc: moment.Moment;

  /** Номер заявки-договора */
  deliveryContractNumber: string;

  /** Дата и время создания заявки-договора в 1С */
  deliveryContractDateUtc: moment.Moment;

  /** Тип заявки на транспорт */
  deliveryRequestType: CARRIER_RATING_REPORT_REQUEST_TYPE;

  /** Перевозка выполнена */
  deliveryCompleted: boolean;

  /** Отказ от заявки-договора */
  deliveryContractRefused: boolean;

  /** Срыв срока подачи ТС на загрузку */
  carrierFaultCanceledShipment: boolean;

  /** Опоздание ТС на загрузку */
  carrierFaultDelayedShipment: boolean;
}

export enum CARRIER_RATING_REPORT_REQUEST_TYPE {
  ASSIGNED = 'ASSIGNED',

  VACANT = 'VACANT',

  AUCTION = 'AUCTION',
}
