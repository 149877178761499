export interface ILSResponse<T> {
  success:boolean;
  data?: T | null;
  error?: ILSTokenErrors | null;
}

export interface ILSTokenResponse {
  id: number;
  created_at: string;
  expired_ap: string;
}


export enum ILSTokenErrors {
  NO_ILS_CONNECTION = 'Пользователь не имеет интеграции с ILS',
  INVALID_CREDENTIALS = 'Переданны некорректные credentials в endpoint аутентификации',
  ILS_AUTHENTICATION_FAILED = 'Неверные логин или пароль',
  INVALID_ILS_TOKEN = 'Некорректный или истекший ILS токен',
  BAD_RESPONSE_FROM_ILS = 'ILS вернул неожиданный ответ'
}

export interface ILSToken {
  connected: boolean;
  expired: boolean;
}
