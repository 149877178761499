<div class="footer flex flex-wrap justify-between px-6 py-3 bg-gray-50 align-middle">
  <a [href]="helpPhone.href" class="help flex items-center not-italic font-medium text-sm">
    <span class="text-gray-500">{{ 'shared.mp.verification.stepper.help' | translate }}:</span>
    <span class="phone text-gray-700 pl-1">{{helpPhone.text}}</span>
  </a>
  <div class="buttons flex gap-3">
    <button class="continue-later tw-button-outline-sm" *ngIf="showContinue" (click)="continueLater()">
      {{ 'shared.mp.verification.buttons.continueLater' | translate }}
    </button>
    <ng-content></ng-content>
  </div>
</div>
