import { TranslateService } from '@ngx-translate/core';
import { IGCarrier } from '@transport/graphql-interface';
import { IAgentCarrier, USER_ROLE, VEHICLE_TYPE, VEHICLE_TYPE_OWNERSHIP } from '@transport/ui-interfaces';
import { TnGqlClientSharedService } from '@transport/ui-store';
import { DocumentNode } from 'graphql';
import { TnModalAddTransportService } from 'libs/transport-notifications/src/lib/components/modal-add-transport/modal-add-transport.service';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';
import { BehaviorSubject, catchError, Observable, of, switchMap } from 'rxjs';
import { VEHICLE_QUERIES } from '../vehicle-queries';
import {
  IMarketplaceBodySubtypesList,
  IMarketplaceDriversList,
  IMarketplaceVehicle,
  IMarketplaceVehicleDocVal,
  IMarketplaceVehicleMake,
  IVehicleFormFields,
} from '../vehicle.interface';
import { COUNTRIES, TnSupportedCountry } from '@transport/ui-pipes';

export class VehicleBaseVm {
  public vehicleMakes = new BehaviorSubject({} as IMarketplaceVehicleMake);
  public bodySubtypes = new BehaviorSubject({} as IMarketplaceBodySubtypesList);
  public drivers$ = new BehaviorSubject({} as IMarketplaceDriversList);
  public organizations = new BehaviorSubject<IAgentCarrier[] | null>(null);

  public readonly countries = COUNTRIES.map(c => c.id === TnSupportedCountry.NO ? {...c, hidden: true} : c);

  constructor(
    private readonly sharedGraphQlClient: TnGqlClientSharedService,
    private readonly translate: TranslateService,
    private readonly domainService: TnDomainService,
    private role: USER_ROLE,
    private readonly modalAddTransportService?: TnModalAddTransportService,
  ) {
    this.getVehicleMakes();
    this.getBodySubtypes();
    this.getDrivers();
  }

  public getVehicleMakes(): void {
    this.sharedGraphQlClient.query<IMarketplaceVehicleMake>(this.role, VEHICLE_QUERIES.getVehicleMakes, 1).subscribe(res => {
      this.vehicleMakes.next(res);
    });
  }

  public getBodySubtypes(): void {
    this.sharedGraphQlClient.query<IMarketplaceBodySubtypesList>(this.role, VEHICLE_QUERIES.getBodySubtypes, 1).subscribe(res => {
      this.bodySubtypes.next(res);
    });
  }

  public getDrivers(agentCarrierId?: string | null): void {
    this.sharedGraphQlClient
      .query<IMarketplaceDriversList>(this.role, VEHICLE_QUERIES.getDrivers, 1, {
        totalCount: true,
        availability: IGCarrier.IAvailabilityEnum.Active,
        agentCarrierId,
        sortBy: ['surname'],
      })
      .subscribe(res => {
        this.drivers$.next(res);
      });
  }

  public getAgentCarriers(searchQuery = ''): void {
    this.sharedGraphQlClient
      .query<{ items: IAgentCarrier[] }>(this.role, VEHICLE_QUERIES.getOrganizations, 1, {
        availability: IGCarrier.IAvailabilityEnum.Active,
        first: 10,
        searchQuery,
      })
      .subscribe(res => {
        this.organizations.next(res.items);
      });
  }

  public getQueryFields(fields: IVehicleFormFields): IMarketplaceVehicle {
    const vehicle: { [key: string]: any } = {
      country: fields.country ?? TnSupportedCountry.RU,
      ownershipType: fields.ownershipType,
      vehicleDrivers: fields.driver ? [fields.driver.id ? fields.driver.id : fields.driver] : [],
      isThirdParty: fields.isThirdParty,
      vehicleMake: fields.make?.id ?? null,
      vehicleRegNo: fields.regNumber,
      vehicleType: fields.type,
      agentCarrierId: fields?.agentCarrierId?.id ?? null,
      baseLocation: fields?.baseLocation
        ? {
            fiasCode: fields.baseLocation?.fiasCode || null,
            fullAddress: fields.baseLocation?.fullAddress || null,
            latitude: fields.baseLocation?.geoLat || fields.baseLocation?.latitude || null,
            longitude: fields.baseLocation?.geoLon || fields.baseLocation?.longitude || null,
            regionWithType: fields.baseLocation?.regionWithType || null,
            settlement: fields.baseLocation?.settlement || null,
            timezone: fields.baseLocation?.timezone || null,
          }
        : null,
    };

    if (fields.id) {
      vehicle.id = fields.id;
    }

    switch (fields.type) {
      case VEHICLE_TYPE.TRUCK:
        return {
          ...vehicle,
          vehicleBodyCapacity: fields.capacity,
          vehicleBodyLoadingTypes: this.setLoadingTypes(fields.loadingTypes),
          vehicleBodySubtype: fields.trailerBodyType,
          vehicleBodyVolume: fields.volume,
        };
      case VEHICLE_TYPE.TRAILER:
        return {
          ...vehicle,
          vehicleTrailerCapacity: fields.capacity,
          vehicleTrailerLoadingTypes: this.setLoadingTypes(fields.loadingTypes),
          vehicleTrailerSubtype: fields.trailerBodyType,
          vehicleTrailerVolume: fields.volume,
          vehicleTrailerRegNo: fields.trailerRegNumber,
          vehicleTrailerMake: fields.trailerMake?.id ?? null,
        };
      case VEHICLE_TYPE.COUPLER:
        return {
          ...vehicle,
          vehicleTrailerCapacity: fields.capacity,
          vehicleTrailerLoadingTypes: this.setLoadingTypes(fields.loadingTypes),
          vehicleTrailerSubtype: fields.trailerBodyType,
          vehicleTrailerVolume: fields.volume,
          vehicleTrailerRegNo: fields.trailerRegNumber,
          vehicleTrailerMake: fields.trailerMake?.id ?? null,
          vehicleBodySubtype: fields.trailerBodyType,
          vehicleBodyLoadingTypes: this.setLoadingTypes(fields.loadingTypes),
        };
    }

    return vehicle;
  }

  public getDocVal(fields: IVehicleFormFields): IMarketplaceVehicleDocVal {
    return {
      ownershipTractorType: fields.ownershipTractorType || null,
      tracktorLeasingAgreement: fields.tracktorLeasingAgreement || null,
      tracktorRentContract: fields.tracktorRentContract || null,
      tractorSts: fields.tractorSts || null,
      ownershipTrailerType: fields.ownershipTrailerType || null,
      trailerLeasingAgreement: fields.trailerLeasingAgreement || null,
      trailerRentContract: fields.trailerRentContract || null,
      trailerSts: fields.trailerSts || null,
      country: (fields.country as TnSupportedCountry) || null,
    };
  }

  private setLoadingTypes(types): string[] {
    const loadingTypes: string[] = [];
    if (types.side) loadingTypes.push('1');
    if (types.back) loadingTypes.push('2');
    if (types.up) loadingTypes.push('3');
    return loadingTypes;
  }

  public createVehicle(fields: any) {
    return this.createOrEditVehicle(VEHICLE_QUERIES.addVehicle, fields);
  }

  public editVehicle(fields: any) {
    return this.createOrEditVehicle(VEHICLE_QUERIES.editVehicle, fields);
  }

  private createOrEditVehicle(
    node: DocumentNode,
    fields: any,
  ): Observable<{ submitVehicleRiskProfile: { ok: boolean } } | { error: string }> {
    const data = {
      input: {
        ...this.getQueryFields(fields),
      },
    };
    let vehicleId;
    let profileId;

    return this.sharedGraphQlClient
      .mutate<{ editVehicle: IMarketplaceVehicle; addVehicleOnTMS: IMarketplaceVehicle }>(this.role, node, data)
      .pipe(
        switchMap(res => {
          if (!res) {
            return of({
              createVehicleRiskProfile: { profileId: '' },
              error: this.translate.instant('shared.mp.vehicles.vehicle.messages.editError') as string,
            });
          }
          vehicleId = res.addVehicleOnTMS?.id || res.editVehicle?.id;
          if (!this.domainService.subDomain && !data.input.isThirdParty) {
            return this.sharedGraphQlClient.mutate<{ createVehicleRiskProfile: { profileId: string } }>(
              this.role,
              VEHICLE_QUERIES.createVehicleRiskProfile,
              {
                vehicleId,
              },
            );
          }

          return of({
            createVehicleRiskProfile: { profileId: '' },
            error: '',
          });
        }),
        switchMap(
          (riskProfile: {
            createVehicleRiskProfile: {
              profileId: string;
            };
          }) => {
            if (riskProfile?.createVehicleRiskProfile?.profileId) {
              profileId = riskProfile.createVehicleRiskProfile?.profileId;
              const docType = 'accr_docs';
              const docVal = JSON.stringify(this.getDocVal(fields));

              return this.sharedGraphQlClient.mutate<{ addVehicleRiskProfileDossier: { dossierId: string } }>(
                this.role,
                VEHICLE_QUERIES.addVehicleRiskProfileDossier,
                {
                  docType,
                  docVal,
                  profileId,
                },
              );
            } else {
              return of({
                addVehicleRiskProfileDossier: {
                  dossierId: '',
                },
                error: '',
              });
            }
          },
        ),
        switchMap(
          (dossier: {
            addVehicleRiskProfileDossier: {
              dossierId: string;
            };
          }) => {
            let result: Observable<{ submitVehicleRiskProfile: { ok: boolean } } | { error: string }>;
            if (dossier.addVehicleRiskProfileDossier.dossierId) {
              result = this.sharedGraphQlClient.mutate<{ submitVehicleRiskProfile: { ok: boolean } }>(
                this.role,
                VEHICLE_QUERIES.submitVehicleRiskProfile,
                {
                  profileId,
                  vehicleId,
                },
              );
            } else {
              result = of({
                error: '',
              });
            }
            if (this.modalAddTransportService) {
              this.modalAddTransportService.afterSaveData.next({ ...this.getQueryFields(fields), id: vehicleId });
            }

            return result;
          },
        ),
        catchError(err => {
          // TODO: Это костыль, нужно исправить!!!!!
          // TODO: Нужно с беком унифицировать подход к обработке ошибок!!!!!
          try {
            const messages: any = JSON.parse(err.message.replaceAll('\'', '"'));
            if (!messages.__all__) throw new Error();

            return of({
              error: messages.__all__.length ? messages.__all__[0] : messages.__all__,
            });
          } catch (error) {
            return of({
              error: this.translate.instant('shared.mp.vehicles.vehicle.messages.createError') as string,
            });
          };
        }),
      );
  }
}
