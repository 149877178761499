import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { DOCUMENT_TYPE, IFileType, IFileUploadDialogFormValue, IFileUploadDialogLabels, IOrderAttachment } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';

import { IWaybillForm } from './components/additional-attributes/waybill-form/waybill-form.component';
import { TnUploadModal } from './components/upload-dialog/upload-dialog.component';
import { TnWaybillViewDialogModal } from './components/waybill-view-dialog/waybill-view-dialog.component';

export interface IUploadModalData {
  fileTypes$: Observable<IFileType[]>;
  waybillForm?: IWaybillForm;
  fileType?: IFileType | null;
  withAttrs?: boolean;
}

export interface ILoadModalData {
  docType: DOCUMENT_TYPE;
  titleText: string;
  closeButtonText?: string;
  fileData: IOrderAttachment;
}

@Injectable({
  providedIn: 'root',
})
export class TnOrderDocumentOperationsDialogService {
  constructor(private readonly dialog: MatDialog) {}

  public open<T = TnUploadModal>(
    component: ComponentType<T>,
    data: IUploadModalData,
    labels?: Partial<IFileUploadDialogLabels>,
    ) {
    const dialogRef = this.dialog.open<T, null, IFileUploadDialogFormValue>(component, { disableClose: true });

    const instance = dialogRef.componentInstance as unknown as TnUploadModal;
    if (typeof labels !== 'undefined') {
      instance.titleText = labels.title ?? instance.titleText;
      instance.fileTypePlaceholderText = labels.fileTypeSelect ?? instance.fileTypePlaceholderText;
    }

    instance.fileTypes$ = data.fileTypes$;
    instance.waybillForm = data.waybillForm;
    if (data.fileType) {
      instance.withAdditionalAttributes = data.withAttrs;
      instance.applyEditFormMode();
      instance.fillForm({fileType: data?.fileType} as IFileUploadDialogFormValue, data.withAttrs);
    }

    return dialogRef.afterClosed();
  }

  public openForEdit<T = TnUploadModal>(
    component: ComponentType<T>,
    data: IUploadModalData,
    formValue: IFileUploadDialogFormValue,
    labels?: Partial<IFileUploadDialogLabels>,
  ) {
    const dialogRef = this.dialog.open<T, null, IFileUploadDialogFormValue>(component);

    const instance = dialogRef.componentInstance as unknown as TnUploadModal;
    if (typeof labels !== 'undefined') {
      instance.titleText = labels.title ?? instance.titleText;
      instance.fileTypePlaceholderText = labels.fileTypeSelect ?? instance.fileTypePlaceholderText;
    }

    instance.fileTypes$ = data.fileTypes$;
    instance.waybillForm = data.waybillForm;
    instance.multipleFiles = false;
    instance.applyEditFormMode();
    instance.fillForm(formValue);
    return dialogRef.afterClosed();
  }

  public view<T = TnWaybillViewDialogModal>(component: ComponentType<T>, data: ILoadModalData) {
    const dialogRef = this.dialog.open<T, null>(component);

    const instance = dialogRef.componentInstance as unknown as TnWaybillViewDialogModal;
    instance.docType = data.docType;
    instance.titleText = data.titleText;
    instance.closeButtonText = data.closeButtonText ?? instance.closeButtonText;
    instance.fileData = data.fileData;

    return dialogRef.afterClosed();
  }
}
