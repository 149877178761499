import { Inject, Injectable } from '@angular/core';
import { DateAdapter } from '@angular/material/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LangChangeEvent, TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { Subject } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ISupportedLanguage, IUiLanguagesInterface, TLangCode } from '../interfaces/ui-languages.interface';
import { IUiTranslations } from '../interfaces/ui-translations.interface';
import { RU as CARRIER_RU } from '../translations/carrier/ru';
import { RU as OWNER_RU } from '../translations/owner/ru';
import { RU as SHARED_RU } from '../translations/shared/ru';

/**
 * Transport v2.0 translation utils service.
 * @note TODO: refactor translation utils service, it has unneeded logic that's is present in the translation library that's used.
 */
@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TnTranslationUtilsService {
  /**
   * Language changes notifier.
   */
  private readonly languageChangesSubject: Subject<LangChangeEvent> = new Subject();

  public readonly languageChanges$ = this.languageChangesSubject.asObservable();

  /**
   * Available UI language codes.
   */
  private readonly langs: IUiLanguagesInterface = {
    ru: 'ru',
    en: 'en',
  };

  /**
   * Supported UI languages.
   */
  private readonly supportedLangs: ISupportedLanguage[] = [
    { key: 'en', name: 'English' },
    { key: 'ru', name: 'Russian' },
  ];

  /**
   * UI dictionaries.
   */
  private readonly translations: IUiTranslations = {
    ru: { ...CARRIER_RU, ...OWNER_RU, ...SHARED_RU },
    en: {}, // Object.assign({}, CARRIER_EN, OWNER_EN, SHARED_EN)
  };

  /**
   * TnTranslationUtilsService constructor.
   * @param translate Translate service
   * @param dateAdapter Datepickers date adapter
   */
  constructor(private readonly translate: TranslateService, private readonly dateAdapter: DateAdapter<Date>) {
    this.languageChangeSubscription();
  }

  /**
   * Initializes Translate service.
   */
  public initialize(): void {
    this.setDatepickersLocale('ru');
    moment.locale('ru');
    this.translate.setDefaultLang(this.langs.ru);
    this.translate.setTranslation(this.langs.ru, this.translations.ru);
    this.translate.setTranslation(this.langs.en, this.translations.en);
    void this.translate.use(this.langs.ru);
  }

  /**
   * Sets UI language, depending on preferences in user browser.
   * For now there are only dictionaries only: English, Russian
   * Russian language is set is user preference does not include one of the supported languages.
   */
  public getUserLanguagePreference(): TLangCode {
    const navLang: string = window.navigator.language;
    const userPreference: TLangCode = Boolean(navLang.match(/(ru-RU|ru)/gi)) || Boolean(navLang[0].match(/(ru)/gi)) ? 'ru' : 'en';
    return userPreference;
  }

  /**
   * Available UI language codes.
   */
  public languages(): IUiLanguagesInterface {
    return this.langs;
  }

  /**
   * Supported UI languages.
   */
  public supportedLanguages(): ISupportedLanguage[] {
    return this.supportedLangs;
  }

  /**
   * UI dictionaries.
   */
  public dictionaries(): IUiTranslations {
    return this.translations;
  }

  /**
   * Uses specific language for UI.
   * @param langCode language code
   */
  public useLanguage(langCode: TLangCode): void {
    if (langCode in this.langs) {
      void this.translate.use(this.langs[langCode]);
    }
  }

  /**
   * Resolves if language is current based on provided language code
   * @param langCode language code
   */
  public isCurrentLanguage(langCode: TLangCode): boolean {
    return this.translate.currentLang === langCode;
  }

  /**
   * Translate service language change subscription.
   */
  private languageChangeSubscription(): void {
    void this.translate.onLangChange
      .pipe(
        untilDestroyed(this),
        tap(event => {
          this.languageChangesSubject.next(event);
          const langCode = event.lang as TLangCode;
          this.setDatepickersLocale(langCode);
          moment.locale(langCode);
        }),
      )
      .subscribe();
  }

  /**
   * Sets datapickers locale:
   * 'ru' if key corresponds Russian language, 'en' in all other cases.
   * @param key language key to be seleted, supported languages: en, ru
   */
  private setDatepickersLocale(key: TLangCode): void {
    this.dateAdapter.setLocale(key);
  }
}
