import { TnUserPermissionsMap } from './user-permission.interface';

/**
 * Carrier user permissions.
 */
export class TnCarrierUserPermissionsMap extends TnUserPermissionsMap {
  public documents? = {
    read: false,
    manage: false,
  };

  public carriersRatingReport? = {
    read: false,
    manage: false,
  };

  public carriersBiddingOrders? = {
    read: false,
    manage: false,
  };

  public users? = {
    read: false,
    manage: false,
  };

  public organizationProfile? = {
    read: false,
    manage: false,
  };
}
