import { Action, createReducer, on } from '@ngrx/store';

import { showProgress } from './progress.actions';
import { IProgressState, progressInitialState } from './progress.state';

const createProgressReducer = createReducer(
  progressInitialState,
  on(showProgress, (state, { payload }) => ({ ...state, ...payload })),
);

export function progressReducer(state: IProgressState, action: Action) {
  return createProgressReducer(state, action);
}
