import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { ICargoType, PAGE_ACTIONS } from '@transport/ui-interfaces';

import { ITnState } from '../../../state';
import { TnTableStateFacade } from '../../../table/table-state-facade';
import { tableActions } from './actions/cargo-types.actions';
import { changePage, changePageMode, removeCargoType, removeCargoTypeSuccess } from './actions/cargo-types-remove.actions';
import { pageMode, tableSelectors } from './selectors/cargo-types.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnDirectoryCargoTypesFacade extends TnTableStateFacade<ICargoType> {
  public removeCargoType$ = this.actions$.pipe(ofType(removeCargoTypeSuccess));

  public pageMode$ = this.store.pipe(select(pageMode));

  constructor(public store: Store<ITnState>, public actions$: Actions) {
    super(store, tableActions, tableSelectors);
  }

  public remove(cargoType: Partial<ICargoType>) {
    this.store.dispatch(removeCargoType({ cargoType }));
  }

  public onChangePage(action: PAGE_ACTIONS, id: string) {
    this.store.dispatch(changePage({ action, id }));
  }

  public setPageMode(mode: PAGE_ACTIONS) {
    this.store.dispatch(changePageMode({ mode }));
  }
}
