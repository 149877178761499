import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { transport } from '@transport/proto';
import { createNewUser, IUiSettings, IUser, IUserOrganization, TOAST_DURATION, USER_ROLE } from '@transport/ui-interfaces';
import { ITnState, TnCurrentUserFacade, TnGqlClientCarrierService, TnGqlClientSharedService, TnUserService } from '@transport/ui-store';
import { getObjectProperty } from '@transport/ui-utils';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { filter, first, map, mergeMap, takeUntil, tap } from 'rxjs/operators';
import { TnCarrierUserPermissionsMap, TnOwnerUserPermissionsMap, TnUserPermissionsMap } from '../../interfaces';
import { MpStaffUserPermissionsMap } from '../../interfaces/user-permission-staff.interface';
import * as currentUserAction from '../../../../../../transport-ui-store/src/lib/current-user/current-user.actions';

@Injectable({
  providedIn: 'root',
})
export class TnCurrentUserService {
  // public readonly profile$ = this.store.pipe(select(selector.getCurrentUserProfile));
  private readonly profileSubject = new BehaviorSubject<transport.IUserProfile>({ userSettings: { notificationSettings: null } });

  private readonly userSubject = new BehaviorSubject(createNewUser({ uiSettings: {} }));

  private readonly isLoggedInSubject = new BehaviorSubject<string>('');

  public readonly isLoggedIn$ = this.isLoggedInSubject.asObservable();

  public readonly user$ = this.userSubject.asObservable();

  public readonly profile$ = this.profileSubject.asObservable();

  private readonly permissionsSubject = new BehaviorSubject(
    new TnUserPermissionsMap() as TnCarrierUserPermissionsMap & TnOwnerUserPermissionsMap & MpStaffUserPermissionsMap,
  );

  public readonly permissions$ = this.permissionsSubject.asObservable();

  private readonly uiSettingsSubject = new BehaviorSubject<IUiSettings>({});

  public readonly uiSettings$ = this.uiSettingsSubject.asObservable();

  private readonly notifier$ = new Subject();

  constructor(
    protected store: Store<ITnState>,
    public readonly tnUserService: TnUserService,
    private readonly gqlCarrier: TnGqlClientCarrierService,
    private readonly gql: TnGqlClientSharedService,
    public readonly tmsCurrentUserFacade: TnCurrentUserFacade,
  ) {
    this.user$.subscribe(user => {
      this.permissionsSubject.next(this.getPermissions(user));
      this.isLoggedInSubject.next(user.token ?? '');
      this.uiSettingsSubject.next((user.uiSettings as IUiSettings | null | undefined) ?? {});
    });
  }

  public get isAccessRequestMarket() {
    const isAccessRequest = this.userSubject.value.organization?.accessRequested;
    return Boolean(isAccessRequest);
  }

  public get isRegistered() {
    return this.userSubject.value.organization?.isRegistered ?? true;
  }

  public get isTmsOrganization() {
    const isTms = this.userSubject.value.organization?.hasTmsAccess;
    return Boolean(isTms);
  }

  public get currentUserRole() {
    return this.userSubject.value.role;
  }

  public get currentUser() {
    return this.userSubject.value;
  }

  public get currentUserPermissions() {
    return this.permissionsSubject.value;
  }

  public get profile() {
    return this.profileSubject.value;
  }

  //TODO: rework
  public restoreProfile() {
    this.tnUserService
      .getProfile(this.currentUser.role, Boolean(this.currentUser.isWorkingWithTN))
      .pipe(
        map(profile => {
          this.profileSubject.next({
            ...this.profileSubject.getValue(),
            ...profile,
            phoneNumber: profile.phoneNumber ?? '',
            firstName: profile.firstName ?? '',
            lastName: profile.lastName ?? '',
            patronymic: profile.patronymic ?? '',
            id: profile?.id,
            organization: profile.organization,
            bankDetails: profile.bankDetails,
          });
          this.tmsCurrentUserFacade.updateUserProfileOrganization(profile);
        }),
      )
      .subscribe();
  }

  // eslint-disable-next-line max-lines-per-function
  public saveUser(newUser: Partial<IUser & { uiSettings: IUiSettings }>) {
    if (Boolean(newUser.token)) {
      newUser.uiSettings = newUser.uiSettings ?? {};
    }

    return this.tnUserService
      .saveUser(newUser)
      .pipe(
        tap(savedUser => this.userSubject.next(savedUser)),
        filter(
          savedUser => Boolean(savedUser?.role) && (!Boolean(savedUser?.organization) || Boolean(savedUser?.organization?.isRegistered)),
        ),
        mergeMap(savedUser => {
          const { role, isWorkingWithTN } = savedUser;
          return this.tnUserService.getProfile(role, Boolean(isWorkingWithTN)).pipe(
            map(profile => {
              this.profileSubject.next({
                ...this.profileSubject.getValue(),
                phoneNumber: profile.phoneNumber ?? '',
                firstName: profile.firstName ?? '',
                lastName: profile.lastName ?? '',
                patronymic: profile.patronymic ?? '',
                id: profile?.id,
                ...profile,
              });
              this.userSubject.next({
                ...this.userSubject.getValue(),
                id: profile?.id,
              });
              if (savedUser.role === USER_ROLE.CARRIER) {
                const carrierUser = this.gqlCarrier.getCarrierUserMarkerAttributes(profile?.id as string).pipe(
                  map(res => {
                    const organization: IUserOrganization = this.assembleOrganization(res.carrierUser);
                    this.userSubject.next({
                      ...this.userSubject.getValue(),
                      id: profile?.id,
                      organization,
                    });
                  }),
                );
              }
            }),
          );
        }),
        takeUntil(this.notifier$),
      )
      .subscribe(() => {
        this.notifier$.next(null);
      });
  }

  private assembleOrganization(carrierUser: transport.ICarrierUser) {
    return {
      isRegistered: !Boolean(carrierUser.selfRegistrationInProcess ?? false),
      hasTmsAccess: Boolean(carrierUser.organization?.hasTmsAccess),
      hasMarketAccess: Boolean(carrierUser.organization?.hasMarketAccess),
      accessRequested: Boolean(carrierUser.organization?.marketAttributes?.accessRequested),
      id: carrierUser.organization?.id as string,
      name: carrierUser.organization?.name,
      vatName: carrierUser.organization?.vat?.name ?? '',
      vatRate: carrierUser.organization?.vat?.rate ?? 0,
    };
  }

  public restoreUserOnInit() {
    return this.tnUserService
      .restoreUser()
      .pipe(
        map(response => {
          if (Boolean(response)) {
            this.userSubject.next(response);
          }
        }),
        takeUntil(this.notifier$),
      )
      .subscribe(() => {
        this.notifier$.next(null);
      });
  }

  public resetUserAuthToken() {
    const email = this.userSubject.value.email;
    this.resetUser();
    this.saveUser({
      token: '',
      ...(Boolean(email) && { email }),
    });
  }

  public resetUser() {
    this.tnUserService.resetUser();
    // this.userSubject.next(createNewUser());
    this.isLoggedInSubject.next('');
    this.permissionsSubject.next(
      new TnUserPermissionsMap() as TnCarrierUserPermissionsMap & TnOwnerUserPermissionsMap & MpStaffUserPermissionsMap,
    );
    this.profileSubject.next({}); // чтобы не сохранять данные об организации при выходе из приложения
  }

  public isUserRole(role: USER_ROLE): boolean {
    return this.currentUserRole === role;
  }

  public editUserUiSettings(settings: IUiSettings<boolean>) {
    this.gql
      .editUiSettings(this.userSubject.value.role, settings)
      .pipe(first())
      .subscribe(newUiSettings => {
        const user = { ...this.userSubject.value, uiSettings: newUiSettings };
        this.userSubject.next(user);
        this.tnUserService.updateCookie(user);
      });
  }

  // permissionName = 'organization.read'
  public hasPermissionStatic(permissionName: string): boolean {
    return Boolean(getObjectProperty(permissionName, this.currentUserPermissions as Record<string, unknown>));
  }

  public hasPermission(permissionName: string): Observable<boolean> {
    return this.permissions$.pipe(map(permissions => Boolean(getObjectProperty(permissionName, permissions as Record<string, unknown>))));
  }

  public getPermissions(user: IUser): TnOwnerUserPermissionsMap & TnCarrierUserPermissionsMap & MpStaffUserPermissionsMap {
    const permissionsMap: Record<string, unknown> = {};
    if (Boolean(user.permissions)) {
      user.permissions?.reduce((acc, cur) => {
        acc[cur.codename ?? ''] = true;
        return acc;
      }, permissionsMap);
    }
    switch (user.role) {
      case USER_ROLE.CARGO_OWNER:
        return this.getOwnerPermissions(permissionsMap);
      case USER_ROLE.TNDL_SECURITY_STAFF:
        return this.getStaffPermissions(permissionsMap);
      default:
        permissionsMap.isWorkingWithTN = user.isWorkingWithTN;
        return this.getCarrierPermissions(permissionsMap);
    }
  }

  private getOwnerPermissions(permissionsMap: Record<string, unknown> = {}): TnOwnerUserPermissionsMap {
    return {
      users: {
        read: Boolean(permissionsMap.can_manage_users_and_their_roles),
        manage: Boolean(permissionsMap.can_manage_users_and_their_roles),
      },
      orders: {
        read: true,
        manage: Boolean(permissionsMap.can_manage_general_reference_and_orders),
        filterOrganization: Boolean(permissionsMap.can_view_organization_orders),
      },
      generalReference: {
        read: false,
        manage: Boolean(permissionsMap.can_manage_general_reference_and_orders),
      },
      companyProfile: {
        read: false,
        manage: Boolean(permissionsMap.can_manage_company_profile),
      },
      carriersPartners: {
        read: false,
        manage: Boolean(permissionsMap.can_manage_carriers_partners),
      },
      subOrganizationProfile: {
        read: false,
        manage: Boolean(permissionsMap.is_company_group_admin),
      },
      reconciliationAct: {
        read: Boolean(permissionsMap.can_use_acts_matcher),
        manage: Boolean(permissionsMap.can_use_acts_matcher),
      },
    };
  }

  private getCarrierPermissions(permissionsMap: Record<string, unknown> = {}): TnCarrierUserPermissionsMap {
    return {
      documents: {
        read: true,
        manage: Boolean(permissionsMap.carrier_can_manage_documents),
      },
      generalReference: {
        read: true,
        manage: Boolean(permissionsMap.carrier_can_manage_general_reference),
      },
      reconciliationAct: {
        read: Boolean(permissionsMap.can_use_acts_matcher),
        manage: Boolean(permissionsMap.can_use_acts_matcher),
      },
      carriersRatingReport: {
        read: Boolean(permissionsMap.isWorkingWithTN),
        manage: false,
      },
      serviceRegistry: {
        read: Boolean(permissionsMap.carrier_has_access_on_transport_services),
      },
      carriersBiddingOrders: {
        read: Boolean(permissionsMap.isWorkingWithTN),
        manage: false,
      },
      users: {
        read: Boolean(permissionsMap.carrier_can_manage_users_and_permissions),
        manage: Boolean(permissionsMap.carrier_can_manage_users_and_permissions),
      },
      organizationProfile: {
        read: true,
        manage: Boolean(permissionsMap.carrier_can_manage_organization_profile),
      },
    };
  }

  private getStaffPermissions(permissionsMap: Record<string, unknown> = {}): MpStaffUserPermissionsMap {
    return {
      organization: {
        read: Boolean(permissionsMap?.se_can_view_organizations),
        manage: Boolean(permissionsMap?.se_can_manage_accreditations),
        admin: Boolean(permissionsMap?.se_accreditations_admin),
      },
    };
  }
}
