import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ngfModule } from 'angular-file';
import { NgxMaskDirective, NgxMaskPipe, provideEnvironmentNgxMask } from 'ngx-mask';

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, ngfModule, NgxMaskDirective, NgxMaskPipe],
  exports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, ngfModule, NgxMaskDirective, NgxMaskPipe],
  providers: [provideEnvironmentNgxMask()]
})
export class TnCoreModule {}
