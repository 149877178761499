import { Injectable } from '@angular/core';
import { getContentTypeHeader } from '@transport/ui-utils';
import fetch from 'node-fetch';
import { BehaviorSubject } from 'rxjs';
import { IReadyobject } from 'yandex-maps';

interface IExtendedYmapsApi {
  ready: (
    successCallback?: () => unknown | IReadyobject,
    errorCallback?: () => unknown,
    context?: Record<string, unknown>,
  ) => Promise<void>;
}

declare const ymaps: IExtendedYmapsApi;

@Injectable({
  providedIn: 'root',
})
export class TnYamapsLoaderService {
  private readonly apiKey = 'f166b876-8b8c-4bf3-8a37-717a094f561b';

  private readonly loadApiSubject = new BehaviorSubject(false);

  public loadApi$ = this.loadApiSubject.asObservable();

  constructor() {
    this.initV2();
  }

  public async getYamapApi(token) {
    const response = await fetch(`https://api-maps.yandex.ru/2.1/?lang=ru_RU&callback=angular2YAMapsAPILoader&&apikey=${token}`, {
      method: 'GET',
      headers: {
        ...getContentTypeHeader('text/javascript;charset=UTF-8: PASS'),
      },
    });
    return response;
  }

  public initV2() {
    void this.getYamapApi(this.apiKey)
      .then(res => res.text())
      .then(body => {
        new Function(body)();
        ymaps
          .ready(() => {
            this.loadApiSubject.next(true);
          })
          .catch(error => {
            // eslint-disable-next-line no-console -- TODO: send to logger
            console.error('yandex-maps init', error);
          });
      });
  }

  /**
   * Remove yandex api script from window.
   */
  public destroy() {
    document.getElementById('YaScript')?.remove();
  }
}
