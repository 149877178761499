export enum RECONCILIATION_ACT_DIFF_TYPE {
  YES = 'Yes',
  NO = 'No',
  /**проверка не проводилась */
  NONE = 'None',
}

export const reconciliationAсtDiffTypeFromDto = (dto: boolean | null): RECONCILIATION_ACT_DIFF_TYPE => {
  if (dto === null) {
    return RECONCILIATION_ACT_DIFF_TYPE.NONE;
  }

  return dto ? RECONCILIATION_ACT_DIFF_TYPE.YES : RECONCILIATION_ACT_DIFF_TYPE.NO;
};
