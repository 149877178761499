import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { IEntityFlowAction, TEntityFlowActionType } from '@transport/ui-interfaces';
import { TTransportButtonType } from '@transport/ui-kit';

export interface IEntityFlowButton {
  type: TTransportButtonType;
  label: string;
  actionType: TEntityFlowActionType;
  icon: string;
}

@Component({
  selector: 'transport-entity-flow-buttons',
  templateUrl: './entity-flow-buttons.component.html',
  styleUrls: ['./entity-flow-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnEntityFlowButtonsComponent implements OnInit, OnChanges {
  @Input() public actions: IEntityFlowAction[] = [];

  @Input() public disabled = false;

  @Input() public dataTest = '';

  // TODO: refactor this, somewhere we need 'delete', somewhere we need 'archive', but component can only show 'delete'
  // Need to use component TnActionButtonsComponent I guess.
  @Input() public archiveInsteadOfRemove = false;

  @Output() public readonly remove = new EventEmitter<void>();

  // eslint-disable-next-line @angular-eslint/no-output-native -- TODO: tech debt
  @Output() public readonly copy = new EventEmitter<void>();

  @Output() public readonly edit = new EventEmitter<void>();

  @Output() public readonly save = new EventEmitter<void>();

  @Output() public readonly cancel = new EventEmitter<void>();

  public flowButtons!: IEntityFlowButton[];

  // ngOnInit needed to avoid the double click bug. TODO: tech debt #5186644 / #5186439
  public ngOnInit(): void {
    this.flowButtons = this.buttons;
  }

  public ngOnChanges(changes: SimpleChanges) {
    // Checking for the previous value is necessary to bypass the double click bug /#5257894 /#5327432
    if (changes.actions && Boolean(changes.actions.previousValue)) {
      if (Boolean(changes.actions.currentValue[0].type !== changes.actions.previousValue[0].type)) {
        this.flowButtons = this.buttons;
      }
    }
  }

  public get buttons(): IEntityFlowButton[] {
    return this.actions.map(({ type, label, icon }) => {
      return {
        actionType: type,
        label: label ?? `shared.actions.${this.archiveInsteadOfRemove ? (type === 'remove' ? 'archive' : type) : type}`,
        icon: icon ?? this.getButtonIcon(type),
        type: type === 'save' ? 'action' : 'action-secondary-flat',
      };
    });
  }

  public onAction(event: Event, type: TEntityFlowActionType) {
    event.preventDefault();
    switch (type) {
      case 'remove':
        this.remove.emit();
        break;
      case 'edit':
        this.edit.emit();
        break;
      case 'copy':
        this.copy.emit();
        break;
      case 'cancel':
        this.cancel.emit();
        break;
      case 'save':
        this.save.emit();
        break;
    }
  }

  private getButtonIcon(type: TEntityFlowActionType) {
    switch (type) {
      case 'edit':
        return 'edit';
      case 'remove':
        return 'delete-outlined';
      case 'copy':
        return 'copy';
      case 'cancel':
        return '';
      case 'save':
        return 'complete2';
    }
  }
}
