<div class="notification flex pl-6 pr-6">
  <mat-icon svgIcon="success-icon-40" class="notification__icon !w-10 !h-10 mr-4"></mat-icon>
  <div class="flex flex-col mb-3">
    <div class="mb-2 text-lg font-medium text-gray-700">{{ 'shared.mp.verification.notification.title' | translate }}</div>
    <div style="max-width: 496px;" class="text-sm font-normal text-gray-500">
      {{ 'shared.mp.verification.notification.info' | translate }}
    </div>
  </div>

  <ng-content></ng-content>
</div>
