import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { TnCurrentUserService } from '@marketplace/auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { combineLatest, map } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { LEGAL_FORMS } from '../../verification-modal/verification-modal.component';

@UntilDestroy()
@Component({
  selector: 'verification-contacts-form[form]',
  templateUrl: './contacts-form.component.html',
  styleUrls: ['./contacts-form.component.scss'],
})
export class TnContactsFormComponent implements OnInit {
  @Input() form!: FormGroup;

  public legalForm = this.currentUserService.profile$.pipe(
    untilDestroyed(this),
    map(profile => profile.organization?.legalForm),
  );

  constructor(public currentUserService: TnCurrentUserService, public translate: TranslateService) {}

  validationErrors(e: ValidationErrors | null) {
    // if (e?.required) return this.translate.instant('shared.errors.required');
    if (e?.trimmedString) return this.translate.instant('shared.errors.trimmedString');
    if (e?.lessThanAdultAge) return this.translate.instant('shared.errors.lessThanAdultAge');
    if (e?.moreThanNow) return this.translate.instant('shared.errors.moreThanNow');
    if (e?.pattern) return this.translate.instant('shared.mp.verification.validationErrors.wrongFormat');
    if (e?.customPattern) return this.translate.instant('shared.errors.startEndSpaces');
    if (e?.maxlength)
      return this.translate.instant('shared.mp.verification.validationErrors.maxLength', { value: e?.maxlength.requiredLength });

    return '';
  }

  ngOnInit() {
    combineLatest([this.currentUserService.user$, this.currentUserService.profile$])
      .pipe(untilDestroyed(this))
      .subscribe(([user, profile]) => {
        this.form.patchValue({
          fullName: this.form.controls.fullName.value || `${user.lastName || ''} ${user.firstName || ''} ${user.patronymic || ''}`,
          phone: this.form.controls.phone.value || profile.phoneNumber,
        });
      });
  }

  public isSelfEmployed(legalForm) {
    return legalForm?.id === LEGAL_FORMS.SELFEMPLOYED;
  }
}
