export enum ROUTE_SHEET_STATUS {
  NEW = 'Новый',
  DRIVER_CONFIRMED = 'Водитель подтвержден',
  ON_ROUTE = 'На маршруте',
  DELIVERY_COMPLETED = 'Доставка завершена',
  CANCELED = 'Отменен',
}

export enum ROUTE_SHEET_EVENT {
  DEPARTURE_ON_ROUTE = 'Выезд на маршрут',
  DELIVERY_COMPLETED = 'Груз доставлен',
  CURRENT_LOCATION = 'Текущее местоположение',
  ROUTE_PHASE_IS_COMPLETE = 'Этап маршрута завершен',
}

export enum ROUTE_SHEETS_CODE {
  DELIVERY_COMPLETED = 'DELIVERY_COMPLETED',
}
