<marketplace-text-column
  title="{{
   ((bodySubtypes || bodySubtype) ?? '---') + ', ' +
   (capacity ? capacity + ('shared.measurements.ton.short' | translate) : '--')  + '/' +
   (volume ? volume + ('shared.measurements.cubedMeters.short' | translate) : '--')
  }}"
  titleClass="font-medium"
  textClass="text-sm"
  text="{{
    (vm.vehicleType ? vm.vehicleType + '. ' : '') +
    (vm.loadingTypes.join(', '))
   }}"
>
</marketplace-text-column>
