import { ElementRef } from '@angular/core';


/**
 * Функция реализующая IRowExtender - это функция:
 * - Которая принимает ссылку на элемент строки таблицы <tr> и табличные данные для этой строки в качестве входных параметров.
 * - Которая внутри себя подписывается на что-либо (Обычно на DOM события элемента <tr>) и затем возвращает функцию отписки. (Будет вызвана когда таблица Дистроится).
 * @param {ElementRef} elRef - ElementRef points <tr> element.
 * @param {Object} rowData - Clients data of a row element.
 * */
export type IRowExtender<T> = (elRef: ElementRef, rowData: T) => () => void;
