import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { APP_ENV, TnAppEnvironment, TOAST_DURATION, USER_ROLE } from '@transport/ui-interfaces';
import { TnAlertService } from '@transport/ui-kit';
import { TnClSsoFrameService, TnToasterFacade, TnUserService, TOAST_TYPE } from '@transport/ui-store';
import { catchError, Subject, switchMap, throwError } from 'rxjs';

import { TnMarketplaceAuthService } from '../../shared/services/marketplace-auth.service';
import { TnCurrentUserService } from '../../shared/services/user/current-user.service';

@UntilDestroy()
@Component({
  selector: 'transport-confirm-email',
  templateUrl: './confirm-email.component.html',
  styleUrls: ['./confirm-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnConfirmEmailComponent implements OnInit {
  private token = '';

  public isSuccess$ = new Subject();

  @ViewChild('invite') public inviteRef?: TemplateRef<never>;

  constructor(
    private readonly route: ActivatedRoute,
    private readonly authService: TnMarketplaceAuthService,
    private readonly tnUserService: TnUserService,
    private readonly router: Router,
    private readonly toastFacade: TnToasterFacade,
    private readonly tnCurrentUserService: TnCurrentUserService,
    private readonly alertService: TnAlertService,
    private readonly ssoService: TnClSsoFrameService,
    private readonly cdr: ChangeDetectorRef,
    @Inject(APP_ENV) public readonly env: TnAppEnvironment,
  ) {}

  public ngOnInit(): void {
    void this.route.paramMap.pipe(switchMap(params => params.getAll('token'))).subscribe((data: string) => {
      this.token = data;
    });
    this.confirmRegistration();
  }

  private confirmRegistration(): void {
    this.authService
      .confirmRegistration(this.token)
      .pipe(
        untilDestroyed(this),
        catchError((err: HttpErrorResponse) => {
          this.isSuccess$.next(false);
          const confirmationErrorStatus = 409;
          if (err.status === confirmationErrorStatus) {
            this.toastFacade.showMessage('shared.passport.confirmEmail.noNeededError', TOAST_TYPE.ERROR, TOAST_DURATION.SHORT);
          }
          void this.tnUserService.restoreUser().subscribe(res => {
            if (res.role === USER_ROLE.NA) void this.router.navigate(['/login']);
            if (res.role === USER_ROLE.CARGO_OWNER) void this.router.navigate(['my-cargo']);
          });
          return throwError(() => err);
        }),
      )
      .subscribe(res => {
        this.isSuccess$.next(true);
        this.tnCurrentUserService.saveUser(res.userLoginData);
        this.ssoService.login(res.userLoginData);
        this.authService.invitationUserStatusWithHeaders().pipe(untilDestroyed(this)).subscribe();
        if (res.userLoginData.role === USER_ROLE.CARGO_OWNER) {
          window.location.href = `${this.env.tmsDomain}`; //Указаны корректные сслыки в окружении, необходимо проверить работу на стендах.
        } else {
          void this.router.navigate(['my-cargo']).then(() => {
            this.inviteRef && this.alertService.openAlertFromTemplate(this.inviteRef, TOAST_DURATION.LONG);
          });
        }
      });
  }
}
