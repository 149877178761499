import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TVehicle, VEHICLE_OWNERSHIPS_FILTERS, VEHICLE_TYPE_OWNERSHIP, VEHICLE_TYPE_PROPERTY } from '@transport/ui-interfaces';
import { isSnakeCase, snakeCaseToCamelCase } from '@transport/ui-utils';
import memo from 'memo-decorator';

/**
 * Pipe for converting truck label to human readable value
 */
@Pipe({
  name: 'truckType',
})
export class TnTruckTypePipe implements PipeTransform {
  constructor(public translate: TranslateService) {}

  /**
   * Pipe for get translated value for truck label
   * @param value Kind of truck
   * @returns String with translate truck type
   */
  @memo({
    resolver: (...args: any[]): string => JSON.stringify(args),
  })
  public transform(value: TVehicle | VEHICLE_TYPE_PROPERTY | VEHICLE_TYPE_OWNERSHIP | VEHICLE_OWNERSHIPS_FILTERS | string): string {
    return Boolean(value) && this.translate.instant(`carrier.directory.vehicle.${this.getLocalizationName(value)}`);
  }

  private getLocalizationName(value: string): string {
    return isSnakeCase(value) ? snakeCaseToCamelCase(value) : value.toLowerCase();
  }
}
