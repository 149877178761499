import { AbstractControl, FormGroup } from '@angular/forms';

export interface IOrderCargoPlaceRequirementsForm extends FormGroup {
  controls: {
    length: AbstractControl;
    width: AbstractControl;
    height: AbstractControl;
    cargoPackagesCount: AbstractControl;
    packagingType: AbstractControl;
  };
}
