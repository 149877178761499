import { TnSupportedCountry } from '@transport/ui-pipes';
import { IDadataSuggestionDto } from '../dadata/dadata.dto';

export interface ITrackingMapObject {
  id: string;
  type: string;
  coordinates: string;
  hintContent: string;
  iconContent: string;
  icon: string;
  iconHover: string;
}

export enum RECOVERY_CARGO_AFTER_DELETE { 
  DELETE = 0,
  ADD_TO_NEW = 1,
}

export enum OPTIMIZATION_CRITERION_CHOICES {
  ROUTE_LENGTH = 'ROUTE_LENGTH',
  ROUTE_DURATION = 'ROUTE_DURATION',
}

export enum QC_GEO {
  CUSTOM = -1,
  EXACT,
  NEAREST_HOUSE,
  STREET,
  LOCALITY,
  CITY,
  UNDEFINED_COORDINATES,
}

export class TnMapPoint {
  public fiasCode = '';

  public fullAddress = '';

  public geoLat = '';

  public geoLon = '';

  public cityWithType = '';

  public settlementWithType = '';

  public timezone = '';

  public cityFiasId = '';

  public settlementFiasId = '';

  public regionWithType = '';

  public coordinateAccuracy = QC_GEO.UNDEFINED_COORDINATES;

  public country: string = TnSupportedCountry.RU;

  public static fromDto(dtoItem?: IDadataSuggestionDto): TnMapPoint {
    return {
      fiasCode: dtoItem?.data?.fias_id ?? '',
      fullAddress: dtoItem?.value ?? '',
      geoLat: dtoItem?.data?.geo_lat ?? '',
      geoLon: dtoItem?.data?.geo_lon ?? '',
      cityWithType: dtoItem?.data?.city_with_type ?? '',
      regionWithType: dtoItem?.data?.city_with_type ?? '',
      settlementWithType: dtoItem?.data?.settlement_with_type ?? '',
      cityFiasId: dtoItem?.data?.city_fias_id ?? '',
      settlementFiasId: dtoItem?.data?.settlement_fias_id ?? '',
      timezone: dtoItem?.data?.timezone ?? '',
      country: dtoItem?.data?.country ?? TnSupportedCountry.RU,
      coordinateAccuracy: Number(dtoItem?.data?.qc_geo || QC_GEO.UNDEFINED_COORDINATES),
    };
  }
}
