<transport-form-block-new
  [class]="'black-list-container flex gap-4 ' + hasDifferentProhibitedTypes ? 'max-w-[50%]' : 'max-w-full'"
  *ngIf="partProhibitedCount > 0"
  [title]="partProhibitedTitle | translate: { count: partProhibitedCount }"
  [iconStyles]="['warning']"
  svgIconName="warning_amber"
>
  <ng-container *ngFor="let company of partProhibitedCompanies; let i=index">
    <div
      class="black-list-company"
      [attr.data-test]="'black-list-part-prohibited-' + i"
    >{{ company }}</div>
  </ng-container>
  <div class="black-list-more" (click)="openBlackListInfoDialog(true)">
    {{ 'shared.blackList.section.more' | translate }}
  </div>
</transport-form-block-new>
<transport-form-block-new
  [class]="'black-list-container flex gap-4 ' + hasDifferentProhibitedTypes ? 'max-w-[50%]' : 'max-w-full'"
  *ngIf="prohibitedCount > 0"
  [title]="prohibitedTitle | translate: { count: prohibitedCount }"
  [iconStyles]="['error']"
  matIconName="block"
>
  <ng-container *ngFor="let company of prohibitedCompanies; let i=index">
    <div
      class="black-list-company"
      [attr.data-test]="'black-list-prohibited-' + i"
    >{{ company }}</div>
  </ng-container>
  <div class="black-list-more" (click)="openBlackListInfoDialog()">
    {{ 'shared.blackList.section.more' | translate }}
  </div>
</transport-form-block-new>
