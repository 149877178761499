import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { tap } from 'rxjs/operators';

import { TnClientTimeService } from '../client-time/client-time.service';

/**
 * Client time interceptor interceptor.
 * Reads date from server, updates client time service.
 */
@Injectable({
  providedIn: 'root',
})
export class TnClientTimeInterceptor implements HttpInterceptor {
  /**
   * TnClientTimeInterceptor constructor.
   * @param clientTime Client time service
   */
  constructor(private readonly clientTime: TnClientTimeService) {}

  /**
   * Intercepts http request, reads date from server, updates client time.
   * @param req Http request
   * @param next Http request handler
   */
  public intercept(req: HttpRequest<unknown>, next: HttpHandler) {
    return next.handle(req).pipe(
      tap((event: HttpEvent<unknown>) => {
        if (event instanceof HttpResponse) {
          const dateStr = event.headers.get('date');
          const contentType = event.headers.get('content-type');
          if (dateStr !== null && contentType === 'application/json') {
            const time = new Date(dateStr);
            this.clientTime.setServerTime(time);
          }
        }
      }),
    );
  }
}
