import { transport } from '@transport/proto';
import {
  ICargoPackagingType,
  ICargoType,
  ICarrier,
  IOrder,
  IOrderAttachment,
  IOrderProposal,
  IPlace,
  ORDER_PUBLISH_TYPE,
  TnOrderDocumentTypes,
} from '@transport/ui-interfaces';

export interface IOwnerOrderPageData {
  order: IOrder;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: tech debt
  insuranceContracts: any;
  select: IOwnerOrderPageDataSelects;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: tech debt
  predefinedAction: any;
  defaultVat: transport.IOrganizationVatType | null;
  defaultPaymentType: transport.IPaymentType | null;
  defaultPaymentPeriod: string | null;
  showOrderExternalNo?: boolean;
  allowedExtraServices: transport.IExtraService[];
  attachments?: IOrderAttachment[];
  customerOrganizations?: transport.IAdminOrganization[];
  tender?: transport.ITender | null;
  proposals?: IOrderProposal<keyof IOrder>[] | null;
}

export interface IOwnerOrderPageDataSelects {
  loadingPlaces: IPlace[];
  unloadingPlaces: IPlace[];
  carriers: ICarrier[];
  carrierGroups: transport.Carrier.ICarrierGroup[];
  cargoTypes: Partial<ICargoType>[];
  cargoBodyTypes: transport.Vehicle.Body.IType[];
  loadingTypes: transport.ILoadingType[];
  packagingTypes: ICargoPackagingType[];
  paymentTypes: transport.IPaymentType[];
  documentTypes: TnOrderDocumentTypes;
}

export interface IPublishType {
  value: ORDER_PUBLISH_TYPE;
  title: string;
}
