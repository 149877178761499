import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { transport } from '@transport/proto';
import { IVehicle } from '@transport/ui-interfaces';
import { COUNTER } from '@transport/ui-utils';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { IDirectoryState } from '../../directory-state.interface';
import { TnDirectoryAgentCarriersFacade } from '../agent-carriers-table/agent-carriers.facade';
import { removeTruck, removeTruckSuccess, searchAgentCarrier, setSelectedAgentCarrier, setVehicle } from './actions/truck.actions';
import { agentCarriers, changedTruckStatus, truck } from './selectors/truck.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnTruckFacade {
  public removeTruck$ = this.actions$.pipe(ofType(removeTruckSuccess));

  public vehicle$ = this.store.pipe(select(truck));

  public changedTruckStatus$ = this.store.pipe(select(changedTruckStatus));

  public agentCarriers$ = this.store.pipe(select(agentCarriers));

  constructor(
    private readonly store: Store<IDirectoryState>,
    private readonly actions$: Actions,
    private readonly agentCarrierFacade: TnDirectoryAgentCarriersFacade,
  ) {}

  public removeTruck(id: string) {
    this.store.dispatch(removeTruck({ id }));
  }

  public setVehicle(vehicle: IVehicle | null) {
    this.store.dispatch(setVehicle({ vehicle }));
  }

  public getAgentCarriers(name: string): Observable<transport.IAgentCarrier[]> {
    if (name.length > COUNTER.TWO) {
      return this.agentCarrierFacade
        .getAgentCarriers(1, false, { name, availability: 'ACTIVE' })
        .pipe(map(result => result.list.map(agentCarrier => this.agentCarrierFacade.changeAgentCarrierNameMapper(agentCarrier))));
    }
    return of([]);
  }

  public searchAgentCarrier(term: string) {
    this.store.dispatch(searchAgentCarrier({ term }));
  }

  public setSelectedAgentCarrier(selectedAgentCarrier: transport.IAgentCarrier[]) {
    this.store.dispatch(setSelectedAgentCarrier({ agentCarriers: selectedAgentCarrier }));
  }
}
