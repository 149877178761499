import { transport } from '@transport/proto';
import { USER_ROLE } from '@transport/ui-interfaces';


export enum NOTIFICATION_TOPIC {
  ORDER = 'order',
  AUCTION = 'auction',
  PRICE = 'price_quotation',
  COMPLETED_ORDERS = 'completed_orders_report',
  TENDER = 'tender',
  COMMON_TENDER = 'common_tender',
  BIDDING = 'bidding',
  CONTRACT = 'contract',
  ACCREDITATION = 'accreditation',
  PRETENSION = 'pretension',
}

export interface INotification extends transport.INotification {
  payload: Record<string, string> | null;
}

export const getNotificationEntityUrl = (notification: INotification, currentUserRole: USER_ROLE, isTms: boolean) => {
  const automaticAccreditation =
    notification.payload?.automatic_accreditation !== undefined ? Boolean(notification.payload?.automatic_accreditation) : null;

  switch (currentUserRole) {
    case USER_ROLE.CARRIER:
      return getEntityUrlForCarrier(isTms, notification.topic ?? '');

    case USER_ROLE.CARGO_OWNER:
      return getEntityUrlForOwner(notification.topic ?? '', automaticAccreditation);

    default:
      break;
  }

  return '';
};

export function getEntityUrlForCarrier(isTms: boolean, topic: string): string {
  let urlPath = `/orders/`;
  if (topic === NOTIFICATION_TOPIC.AUCTION) {
    urlPath += isTms ? 'carrierAuction/' : '';
  }
  if (topic === NOTIFICATION_TOPIC.PRICE) {
    urlPath = 'marketplace/carrier/view';
  }
  if (topic === NOTIFICATION_TOPIC.COMPLETED_ORDERS) {
    urlPath = 'service-registry/report/';
  }
  if (topic === NOTIFICATION_TOPIC.PRETENSION) {
    urlPath = 'pretensions/view/';
  }
  if (topic === NOTIFICATION_TOPIC.TENDER) {
    urlPath = 'my-tenders/';
  }
  if (topic === NOTIFICATION_TOPIC.COMMON_TENDER) {
    urlPath = 'tenders/carrier/view/';
  }
  return urlPath;
}

export function getEntityUrlForOwner(topic: string, isAutomaticAccreditation: boolean | null = null): string {
  let urlPath = '';

  // Hidden because of 7205875 bug.
  /*if (topic === NOTIFICATION_TOPIC.AUCTION) {
    urlPath += '/auction/view';
  } else*/ if (topic === NOTIFICATION_TOPIC.PRICE) {
    urlPath = '/orders/marketplace/owner/view';
  } else if (topic === NOTIFICATION_TOPIC.TENDER) {
    urlPath = '/orders/tenders/tender/';
  } else if (topic === NOTIFICATION_TOPIC.CONTRACT) {
    urlPath = '/carriers/trusted';
  } else if (topic === NOTIFICATION_TOPIC.PRETENSION) {
    urlPath = '/pretensions/view/';
  } else if (topic === NOTIFICATION_TOPIC.COMMON_TENDER) {
    urlPath = '/tenders/owner/view/';
  } else if (topic === NOTIFICATION_TOPIC.ACCREDITATION) {
    if (isAutomaticAccreditation === true) {
      urlPath = '/carriers/contracting-required';
    } else if (isAutomaticAccreditation === false) {
      urlPath = '/carriers/accreditation';
    }
  } else {
    urlPath += '/order/view';
  }
  return urlPath;
}

export const getNotificationEntitySearch = (notification: INotification, currentUserRole: USER_ROLE) => {
  if (currentUserRole === USER_ROLE.CARRIER) {
    if (notification.topic === NOTIFICATION_TOPIC.AUCTION) {
      return { kind: 'auction' };
    }
    if (notification.topic === NOTIFICATION_TOPIC.BIDDING) {
      return { kind: 'bidding' };
    }
  }
  if (currentUserRole === USER_ROLE.OWNER) {
    if (notification.topic === NOTIFICATION_TOPIC.ACCREDITATION || notification.topic === NOTIFICATION_TOPIC.CONTRACT) {
      return { carrierId: notification.payload?.carrier_organization_id || notification?.payload?.id || '' };
    }
  }
  return {};
};
