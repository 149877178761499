import { FormBuilder, ValidatorFn, Validators } from '@angular/forms';
import { conditionalValidator, IOrganizationForm, LEGAL_FORM_CODES, trimmedStringValidator } from '@transport/ui-interfaces';
import { regExpConfig } from '@transport/ui-utils';

import { isJuridicalPersonValidator, isPhysicalPersonValidator } from '../shared/organization-shared';

export interface IOrganizationResultInfo {
  status: ORGANIZATION_SEARCHING_STATUS;
  organization: IOrganizationData | null;
}

export interface IOrganizationData {
  companyName: string;
  legalFormCode: string;
  kpp: string;
  address: string;
  ogrn: string;
  status: string;
}

export enum ORGANIZATION_SEARCHING_STATUS {
  OK = 'ok',
  NOT_ACTIVE = 'notActive',
  NOT_FOUND = 'organizationNotFound',
}

export const getOrganizationForm = (fb: FormBuilder, isEditing = false): IOrganizationForm => {
  return fb.group({
    legalForm: [{ disabled: isEditing, value: null }, [Validators.required]],
    ogrn: [
      {
        value: null,
        disabled: true,
      },
      [
        Validators.required,
        conditionalValidator(isJuridicalPersonValidator, Validators.pattern(regExpConfig.ogrn.RU)) as ValidatorFn,
        conditionalValidator(isPhysicalPersonValidator, Validators.pattern(regExpConfig.ogrnIp.RU)) as ValidatorFn,
      ],
    ],
    companyName: [
      {
        value: null,
        disabled: true,
      },
      [Validators.required, trimmedStringValidator],
    ],
    inn: [
      {
        value: null,
        disabled: isEditing,
      },
      [
        Validators.required,
        conditionalValidator(isJuridicalPersonValidator, Validators.pattern(regExpConfig.inn.RU)) as ValidatorFn,
        conditionalValidator(isPhysicalPersonValidator, Validators.pattern(regExpConfig.innPhysOrIp.RU)) as ValidatorFn,
      ],
    ],
    kpp: [null, [Validators.required, Validators.pattern(regExpConfig.kpp.RU)]],
    legalAddress: [
      {
        value: null,
        disabled: true,
      },
      [Validators.required, trimmedStringValidator],
    ],
    postalAddress: [null, [trimmedStringValidator]],
    sameAddress: [false],
  }) as IOrganizationForm;
};

const setValidatorsForJuridicalAndIndividual = (form: IOrganizationForm) => {
  const { companyName, ogrn, legalAddress } = form.controls;
  companyName.setValidators([Validators.required, trimmedStringValidator]);
  ogrn.setValidators([
    Validators.required,
    conditionalValidator(isJuridicalPersonValidator, Validators.pattern(regExpConfig.ogrn.RU)) as ValidatorFn,
    conditionalValidator(isPhysicalPersonValidator, Validators.pattern(regExpConfig.ogrnIp.RU)) as ValidatorFn,
  ]);
  legalAddress.setValidators([Validators.required, trimmedStringValidator]);
};

export const changeFormValidators = (legalFormCode: LEGAL_FORM_CODES, form: IOrganizationForm) => {
  const { kpp, companyName, ogrn, legalAddress } = form.controls;
  switch (legalFormCode) {
    case LEGAL_FORM_CODES.LIMITED_LIABILITY_COMPANY:
      setValidatorsForJuridicalAndIndividual(form);
      kpp.setValidators([Validators.required, Validators.pattern(regExpConfig.kpp.RU)]);
      break;
    case LEGAL_FORM_CODES.INDIVIDUAL_ENTREPRENEUR:
      setValidatorsForJuridicalAndIndividual(form);
      kpp.clearValidators();
      break;
    case LEGAL_FORM_CODES.SELF_EMPLOYED:
      companyName.clearValidators();
      ogrn.clearValidators();
      legalAddress.clearValidators();
      kpp.clearValidators();
      break;
  }
};
