import { gql } from 'apollo-angular';

export const VEHICLE_QUERIES = {
  getVehicleMakes: gql`
    query vehicleMakes {
      vehicleMakes
    }
  `,

  getBodySubtypes: gql`
    query bodySubtypes {
      bodySubtypes {
        id
        name
      }
    }
  `,

  getDrivers: gql`
    query drivers(
      $first: Int
      $offset: Int
      $availability: AvailabilityEnum
      $fullName: String
      $surname: String
      $agentCarrierId: ID
      $namePatronymic: String)
    {
      items: drivers(
        first: $first
        offset: $offset
        availability: $availability
        fullName: $fullName
        surname: $surname
        agentCarrierId: $agentCarrierId
        namePatronymic: $namePatronymic)
      {
        surname
        name
        accrStatus
        patronymic
        id
      }
    }
  `,

  getOrganizations: gql`
  query getAgentCarriers(
    $first: Int
    $offset: Int
    $availability: AvailabilityEnum
    $searchQuery: String
    ) {
    items: agentCarriers(
      availability: $availability
      searchQuery: $searchQuery
      first: $first
      offset: $offset
    ) {
      id
      carrierName
      inn
      country
      iin
      bin
    }
  }
  `,

  addVehicle: gql`
    mutation addVehicle($input: VehicleOnTMSInput!) {
      addVehicleOnTMS(input: $input) {
        id
      }
    }
  `,

  getVehicle: gql`
    query vehicle($id: ID!) {
      vehicle(id: $id) {
        country
        accrStatus
        accrComment
        vehicleType
        vehicleMake
        vehicleId
        ownershipType
        isThirdParty
        blackListInfo {
          cargoOwner {
            id
            name
          }
          workProhibited
          reason
          description
        }
        agentCarrier {
          id
          carrierName
          inn
        }
        drivers {
          name
          patronymic
          surname
          id
        }
        vehicletrailer {
          regNo
          trailerType
          vehicleMake
          blackListInfo {
            cargoOwner {
              id
              name
            }
            workProhibited
            reason
            description
          }
          bodySubtype {
            id
            name
          }
          loadingTypes {
            id
            name
          }
          capacity
          volume
        }
        vehiclebody {
          bodySubtype {
            id
            name
          }
          loadingTypes {
            id
            name
          }
          capacity
          volume
        }
        baseLocation {
          settlement
          fullAddress
          fiasCode
          timezone
          regionWithType
          latitude
          longitude
        }
      }
    }
  `,

  editVehicle: gql`
    mutation editVehicle($input: EditVehicleInput!) {
      editVehicle(input: $input) {
        id
      }
    }
  `,

  createVehicleRiskProfile: gql`
    mutation createVehicleRiskProfile($vehicleId: ID!) {
      createVehicleRiskProfile ( vehicleId: $vehicleId ) {
        profileId
      }
    }
  `,

  addVehicleRiskProfileDossier: gql`
    mutation addVehicleRiskProfileDossier($docType: String!, $docVal: String!, $profileId: String!) {
      addVehicleRiskProfileDossier (
        docType: $docType,
        docVal: $docVal,
        profileId: $profileId
      ) {
        dossierId
      }
    }
  `,

  submitVehicleRiskProfile: gql`
    mutation submitVehicleRiskProfile($profileId: String!, $vehicleId: ID!) {
      submitVehicleRiskProfile (
        profileId: $profileId,
        vehicleId: $vehicleId
      ) {
        ok
      }
    }
  `,

  sendVechicleToArchive: gql`
    mutation SendVechicleToArchive($id: ID!) {
      sendVehicleToArchive(id: $id) {
        id
        isArchived
      }
    }
  `,

  sendVehicleToBlackList: gql`
    mutation AttachVehicleToBlackList($input: AttachVehicleToBlackListInput) {
      attachVehicleToBlackList(input: $input) {
        id
      }
    }
  `,

  ExcludeVehicleFromBlackList: gql`
    mutation ExcludeVehicleFromBlackList($input: ExcludeVehicleFromBlackListInput) {
      excludeVehicleFromBlackList(input: $input) {
        id
      }
    }
  `,

  restoreVehicle: gql`
    mutation restoreVehicleFromArchive($id: ID!){
      restoreVehicleFromArchive(id: $id){
        id
        isArchived
      }
    }
  `,
};
