import { TnUploadedFile } from '../files';
import { IReconcilationActWebsocketMessageDto } from './dto/reconciliation-act-websocket-message.dto';
import { RECONCILIATION_ACT_DIFF_TYPE, reconciliationAсtDiffTypeFromDto } from './reconciliation-act-diff-type';
import { RECONCILIATION_ACT_ERROR_TYPE } from './reconciliation-act-error-type';
import { TnReconciliationActFilesUrlGeneratorAbstract } from './reconciliation-act-files-url-generator';
import { RECONCILIATION_ACT_STATUS } from './reconciliation-act-status';

export class TnReconcilationActWebsocketMessage {
  public id: string;

  public guid: string;

  public status: RECONCILIATION_ACT_STATUS;

  public errorType: RECONCILIATION_ACT_ERROR_TYPE;

  public resultsFileFull: TnUploadedFile | null = null;

  public resultsFileDiffOnly: TnUploadedFile | null = null;

  public diff: RECONCILIATION_ACT_DIFF_TYPE | null = null;

  constructor(id: string, guid: string, status: RECONCILIATION_ACT_STATUS, errorType: RECONCILIATION_ACT_ERROR_TYPE) {
    this.id = id;
    this.guid = guid;
    this.status = status;
    this.errorType = errorType;
  }

  public static fromDto(
    dto: IReconcilationActWebsocketMessageDto,
    urlGenerator?: TnReconciliationActFilesUrlGeneratorAbstract,
  ): TnReconcilationActWebsocketMessage {
    const result = new TnReconcilationActWebsocketMessage(dto.id, dto.guid, dto.status, dto.error_type);

    result.diff = reconciliationAсtDiffTypeFromDto(dto.has_diff);

    if (result.status === RECONCILIATION_ACT_STATUS.COMPLETED && typeof urlGenerator !== 'undefined') {
      result.resultsFileFull = new TnUploadedFile(
        dto.full_results_filename ?? '',
        urlGenerator.generateDownloadUrlForResults(result.guid, true),
      );

      if (result.diff === RECONCILIATION_ACT_DIFF_TYPE.YES) {
        result.resultsFileDiffOnly = new TnUploadedFile(
          dto.diff_results_filename ?? '',
          urlGenerator.generateDownloadUrlForResults(result.guid, false),
        );
      }
    }

    return result;
  }
}
