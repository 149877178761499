import { Injectable } from '@angular/core';
import { from, Observable, of } from 'rxjs';
import { filter } from 'rxjs/operators';

import { TnYamapsLoaderService } from '../loader/yamaps-loader.service';
import { TnYamapsUtilsService } from '../utils/yamaps-utils.service';

interface IExtendedYmapsApi {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: tech debt
  Map: unknown;
  geocode: (...args) => Promise<{
    geoObjects: {
      get(arg0: number): { geometry: { getCoordinates(): number[] }; properties: { getAll(): { text } } };
    };
  }>;
}

declare const ymaps: IExtendedYmapsApi;

@Injectable({
  providedIn: 'root',
})
export class TnYamapsGeocodeService {
  public ymaps?: IExtendedYmapsApi;

  constructor(private readonly utils: TnYamapsUtilsService, private readonly yamapsLoader: TnYamapsLoaderService) {
    if (typeof this.ymaps === 'undefined') {
      void this.yamapsLoader.loadApi$.pipe(filter(val => Boolean(val))).subscribe(() => {
        this.ymaps = ymaps;
      });
    }
  }

  /**
   * Parse and return points of map center
   * @returns Promise<number[]> Coordinate.
   */
  public getMapCenter(center: string | number[] | PromiseLike<number[]>, points: number[][]) {
    let result: Observable<number | number[] | PromiseLike<number[]>> = of(points[0]);

    if (center === '' && Object.keys(center).length === 0 && points.length === 0) {
      result = of(this.utils.defaultCity);
    } else if (center !== '' && points.length === 0) {
      if (typeof center === 'string') {
        result = from(this.addressToCoordinates(`${center}`));
      }
      const centerLength = 2;
      if (typeof center === 'object' && Object.keys(center).length === centerLength) {
        result = from(center);
      }
      result = of(this.utils.defaultCity);
    }
    if (typeof points[0] === 'string') {
      result = from(this.addressToCoordinates(`${String(points[0])}`));
    }

    return result;
  }

  /**
   * Returns geo points by address
   */
  public async addressToCoordinates(address: string): Promise<number[]> {
    return typeof this.ymaps !== 'undefined'
      ? this.ymaps.geocode(address).then(res => {
          return res.geoObjects.get(0).geometry.getCoordinates();
        })
      : new Promise(resolve => {
          resolve([0, 0]);
        });
  }

  /**
   * Returns address by geo points
   * @param coordinate Coordinate of object.
   * @returns Promise<string> Address.
   */
  public async coordinateToAdress(coordinate): Promise<string> {
    return this.ymaps?.geocode(coordinate).then(res => {
      return res.geoObjects.get(0).properties.getAll().text;
    });
  }

  /**
   * public search method for yandex map.
   * @param val  Search string request.
   * @param callback  Callback function
   */
  public geoSearch(val: string, callback) {
    const time = 100;
    const search = () =>
      setTimeout(() => {
        if (typeof this.ymaps !== 'undefined' && Boolean(this.ymaps.Map)) {
          void this.ymaps.geocode(val).then(res => {
            callback(res);
          });
        } else {
          search();
        }
      }, time);
    search();
  }

  public geolocation(success: { (position): void; (arg0): void }, deny: { (): void; (): void; (): void }) {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        position => {
          success(position);
        },
        error => {
          deny();
        },
      );
    } else {
      // TODO: it this message is needed 'Yamap Module: geolocation IS NOT available', snack bar should be used for its display
      deny();
    }
  }
}
