<ng-container *ngFor="let creteria of additionalAuctionCriteria">
  <div *ngIf="creteria.type === AUCTION_CRITERION_CHOICES.FIRST_LOADING_DATE" class="flex items-center place-content-center mb-1 text-center">
    <common-select
      class="mb-2 w-[200px]"
      [disabled]="false"
      [size]="'sm'"
      [label]="'Дата подачи'"
      [allowClear]="false"
      dataTest="place-step-bet-additional-criteria-date"
      [dataSource]="dateOptions$ | async"
      [control]="dateAdditionalCriteriaControl">
    </common-select>
    <mat-icon class="mat-icon-sm text-gray-500 mt-3 ml-1"
              [matTooltip]="'Увеличение срока подачи на 1 день эквивалентно повышению ставки на ' + (firstDateCriteriaWeight | currencyMulti: currency: false) + ' и учитывается при выборе победителя'"
              matTooltipShowDelay="0"
              matTooltipHideDelay="0">
              info
    </mat-icon>
  </div>
  <div *ngIf="creteria.type === AUCTION_CRITERION_CHOICES.IS_VAT_INCLUDED" class="flex items-center place-content-center mb-1 text-center">
    <common-checkbox
      class="mb-2"
      [size]="'sm'"
      [label]="'НДС включен в цену'"
      dataTest="place-step-bet-additional-criteria-vat"
      [control]="vatAdditionalCriteriaControl">
    </common-checkbox>
  </div>
</ng-container>