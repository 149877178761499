import { ValidationErrors } from '@angular/forms';
import {
  IBodyTrailerForm,
  IDriverForm,
  ILoginFormData,
  ISignupFormData,
  ITruckForm,
  IVehicleTrailerForm,
  IWayBillDocAttributes,
} from '@transport/ui-interfaces';

export interface IFormStateError {
  controlName: string;
  errors: ValidationErrors[];
}

export interface IFormStateValue<
  T = {
    [key: string]: unknown;
  },
> {
  controls: T;
  errors: IFormStateError[];
}

export interface IFormState {
  login: {
    controls: ILoginFormData;
    errors: IFormStateError[];
  };
  signup: {
    controls: ISignupFormData;
    errors: IFormStateError[];
  };
  driver: {
    controls: IDriverForm;
    errors: IFormStateError[];
  };
  wayBill: {
    controls: IWayBillDocAttributes;
    errors: IFormStateError[];
  };
  truck: {
    controls: ITruckForm;
    errors: IFormStateError[];
  };
  trailer: {
    controls: IVehicleTrailerForm;
    errors: IFormStateError[];
  };
  body: {
    controls: IBodyTrailerForm;
    errors: IFormStateError[];
  };
}

const loginFormInitialValue: IFormStateValue<ILoginFormData> = {
  controls: {
    email: '',
    password: '',
  },
  errors: [],
};

const signupFormInitialValue: IFormStateValue<ISignupFormData> = {
  controls: {
    firstName: '',
    lastName: '',
    inn: '',
    companyName: '',
    email: '',
    role: 0,
    phone: '',
    useTs: false,
    useSignature: false,
    personalData: false,
  },
  errors: [],
};

const driverFormInitialValue: IFormStateValue<IDriverForm> = {
  controls: {
    birthday: void 0,
    gender: void 0,
    inn: void 0,
    phone: void 0,
    addPhone: void 0,
    licenceSeries: void 0,
    licenceNumber: void 0,
    licencePlace: void 0,
    licenceDate: void 0,
    licenceSurname: void 0,
    licenceName: void 0,
    licencePatronymic: void 0,
    surname: void 0,
    name: void 0,
    patronymic: void 0,
    passportSeries: void 0,
    passportNumber: void 0,
    passportPlace: void 0,
    passportDate: void 0,
    country: void 0,
  },
  errors: [],
};

const wayBillFormInitialValue: IFormStateValue<IWayBillDocAttributes> = {
  controls: {
    storagePoint: null,
    dateFrom: null,
    timeFrom: '',
    dateTo: null,
    timeTo: '',
  },
  errors: [],
};

const truckFormInitialValue: IFormStateValue<ITruckForm> = {
  controls: {
    id: '',
    vehicleMake: '',
    vehicleCountry: '',
    vehicleId: null,
    vehicleCertificate: null,
    vehicleVin: '',
    isArchived: false,
  },
  errors: [],
};

const trailerFormInitialValue: IFormStateValue<IVehicleTrailerForm> = {
  controls: {
    trailerId: '',
    trailerType: '',
    trailerSubtype: '',
    trailerVolume: 0,
    trailerCapacity: 0,
    trailerLoadingTypes: [],
  },
  errors: [],
};

const bodyFormInitialValue: IFormStateValue<IBodyTrailerForm> = {
  controls: {
    bodyType: '',
    bodySubtype: '',
    bodyVolume: 0,
    bodyCapacity: 0,
    bodyLoadingTypes: [],
  },
  errors: [],
};

export const formInitialState: IFormState = {
  login: loginFormInitialValue,
  signup: signupFormInitialValue,
  driver: driverFormInitialValue,
  wayBill: wayBillFormInitialValue,
  truck: truckFormInitialValue,
  trailer: trailerFormInitialValue,
  body: bodyFormInitialValue,
};
