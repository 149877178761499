import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ITableFilterOption, TnTableList } from '@transport/ui-components';
import { BLACK_LIST_COLUMN_TYPE, IDriver, TDirectoryEntityPredefinedAction } from '@transport/ui-interfaces';
import {
  IDriversFilter,
  TnCarrierDirectoryDriversApiService,
  TnCurrentUserFacade,
  TnDirectoryDriversFacade,
  TnDriverFacade,
} from '@transport/ui-store';
import { filter } from 'rxjs/operators';

/**
 * Drivers table component.
 * Is intended for displaying drivers as a table for user with system role 'cargo carrier'
 */
@UntilDestroy()
@Component({
  selector: 'transport-drivers',
  templateUrl: './drivers.component.html',
  styleUrls: ['./drivers.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnDriversComponent extends TnTableList<IDriver> implements OnInit {
  @HostBinding('class.transport-page-table') public readonly transportPageTable = true;

  public tableName = 'drivers-table';

  public blackListType = BLACK_LIST_COLUMN_TYPE;

  constructor(
    private readonly carrierDirectoryDriversService: TnCarrierDirectoryDriversApiService,
    public readonly translate: TranslateService,
    private readonly driversFacade: TnDirectoryDriversFacade,
    private readonly driverFacade: TnDriverFacade,
    public readonly router: Router,
    public readonly route: ActivatedRoute,
    public readonly userFacade: TnCurrentUserFacade,
    public readonly cdRef: ChangeDetectorRef,
  ) {
    super(router, route, driversFacade, cdRef);
  }

  public getList(first?: number, offset?: number, filterSetting?: Record<string, unknown>) {
    return this.carrierDirectoryDriversService
      .getDrivers(1, false, { first, offset, totalCount: true, ...filterSetting })
      .pipe(untilDestroyed(this));
  }

  /**
   * Lifecycle hook called on component initialization.
   */
  public ngOnInit(): void {
    void this.driversFacade.removeDriver$.pipe(untilDestroyed(this)).subscribe(() => this.setFilter(this.driversFacade.currentFilter));

    void this.driverFacade.changedDriverStatus$
      .pipe(
        filter(driverInfo => Boolean(driverInfo)),
        untilDestroyed(this),
      )
      .subscribe(driverInfo => {
        this.updatePartDataSourceByKey('id', driverInfo as Partial<IDriver>);
        this.cdRef.markForCheck();
      });
  }

  /**
   * Shows driver`s details page
   * @param item Driver type model that should be displayed in details.
   * @param action Predifined action
   */
  public showItemDetails(item: IDriver, action: TDirectoryEntityPredefinedAction): void {
    void this.router.navigate([`/directory/carrier/drivers/${action}/${item.id}`]);
  }

  public setFilter(value: ITableFilterOption['value'] | IDriversFilter, initial = false) {
    this.filter(typeof value === 'string' ? { availability: value } : value, initial);
  }

  public remove(driver: IDriver) {
    const fio = this.setFullNameWithBirthday(driver.surname, driver.name, driver.patronymic);
    void this.driversFacade.removeDriver(driver.id as string, fio);
  }

  public get columnNames() {
    return [
      { name: 'surname', include: true, width: 250 },
      { name: 'name', include: true, width: 250 },
      { name: 'phone', include: true, width: 250 },
      { name: 'application', include: true, width: 250 },
      // Функционал вырезан в соответствии с #5119565
      // { name: 'verificationStatus', include: true, width: 250 },
    ];
  }

  public get rowActions() {
    return {
      archived: (driver: IDriver) => Boolean(driver.isArchived),
    };
  }

  public get driverHasMobileAppTipMessage() {
    return (hasMobileApp: boolean) => `shared.directory.drivers.table.tooltip.application.${hasMobileApp ? 'installed' : 'notInstalled'}`;
  }

  protected parseFilterFromUrlString(url: string): IDriversFilter {
    const result = {} as IDriversFilter;
    url
      .split('|')
      .filter(item => Boolean(item))
      .forEach(item => {
        const name = item.split(':')[0];
        const value = item.split(':')[1];
        result[name] = this.parseBooleanString(value);
      });

    if (!Boolean(result.availability)) {
      result.availability = this.driversFacade.currentFilter?.availability ?? 'ALL';
    }
    return result;
  }

  private setFullNameWithBirthday(
    surname: string | null | undefined,
    name: string | null | undefined,
    patronymic: string | null | undefined
  ): string {
    const patronymicFirstSymbol = `${patronymic?.split('')[0]}.`;
    return `${surname} ${name?.split('')[0]}.${Boolean(patronymic) ? patronymicFirstSymbol : ''}`;
  }
}
