import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { openConfirmation, SET_CONFIRMATION_CHOICE, setConfirmationChoice } from '@transport/ui-store';
import { Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TnModalAddTransportService {
  public dialogRef?: MatDialogRef<any>;

  public readonly afterSaveData = new Subject<any>();
  // вынести в другой сервис
  public readonly afterSaveDataDriver = new Subject<any>();

  constructor(private readonly dialog: MatDialog, private readonly store: Store, private readonly actions$: Actions) {}

  public open() {

  }

  public close(withoutWarning = false) {
    if (!withoutWarning && this.dialogRef?.componentInstance.truckPage.isDirty) {
      this.store.dispatch(openConfirmation({ payload: { openByPrefix: 'shared.dirtyModalFormConfirmationDialog' } }));
      this.actions$
        .pipe(
          ofType<ReturnType<typeof setConfirmationChoice>>(SET_CONFIRMATION_CHOICE),
          first(),
          map(action => action.payload.confirmClosure),
        )
        .subscribe(status => {
          if (status) {
            this.dialogRef?.close();
          }
        });
    } else {
      this.dialogRef?.close();
    }
  }

  public confirmClose(): boolean {
    const close = confirm('Закрыть окно?');
    return close;
  }
}
