import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IFiasAddressDto } from '@transport/ui-interfaces';
import { forkJoin } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';

import { GET_FIAS_DATA, getFiasByCode, getFiasData, setAddressFromFias, setFiasData } from './fias.actions';
import { IFiasData } from './fias.state';
import { TnFiasApiService } from './fias-api.service';

@Injectable({
  providedIn: 'root',
})
export class TnFiasEffects {
  constructor(private readonly action$: Actions, private readonly api: TnFiasApiService) {}

  public readonly getFiasData$ = createEffect(() =>
    this.action$.pipe(
      ofType<ReturnType<typeof getFiasData>>(GET_FIAS_DATA),
      switchMap(({ payload }) =>
        this.api.getFiasCodeCollectionByString(payload.input, payload.minInputLength).pipe(map(data => ({ data, scope: payload.scope }))),
      ),
      map(({ data, scope }) => setFiasData({ payload: data, scope })),
    ),
  );

  public readonly getFiasAddressByCode$ = createEffect(() =>
    this.action$.pipe(
      ofType(getFiasByCode),
      mergeMap(({ codes, scope }) => {
        const codeRequests = codes.map(code => this.api.getFiasStringByCode(code).pipe(map(data => ({ data, scope }))));
        return forkJoin(codeRequests).pipe(
          map((result: { data: IFiasData; scope: string }[]) => {
            const addresses = result.reduce((accumulator: IFiasAddressDto[], { data }) => {
              accumulator.push(data.addresses[0]);
              return accumulator;
            }, []);

            return setAddressFromFias({
              address: {
                addresses,
              },
              scope: result[0].scope,
            });
          }),
        );
      }),
    ),
  );
}
