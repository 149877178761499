import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IAuthenticationCodeRequestFormData,
  IConfirmRegistrationResponse,
  ILoginByCodeRequestFormData,
  IInviteStatusResponse,
  ILoginRequestFormData,
  ILoginResponseData,
  IMarketplaceRegFormData,
  IRegByCodeFormData,
  USER_ROLE,
  ISignUpEmployee,
  IConfirmEmailResponse,
} from '@transport/ui-interfaces';
import { TnHttpHandlersService } from '@transport/ui-store';
import { map, Observable } from 'rxjs';
import { TnCurrentUserService } from 'libs/marketplace-auth/src/lib/shared/services/user/current-user.service';

@Injectable({
  providedIn: 'root',
})
export class TnMarketplaceAuthService {
  private readonly endpoints = {
    signUp: (): string => this.handlers.getEndpointUrl('/auth/sign_up'),
    signUpByCode: (): string => this.handlers.getEndpointUrl('/auth/sign_up_by_code'),
    checkEmail: (): string => this.handlers.getEndpointUrl('/auth/check_email'),
    confirmEmailAgain: (): string => this.handlers.getEndpointUrl('/auth/send_confirm_email_again'),
    confirmRegistration: (): string => this.handlers.getEndpointUrl('/auth/confirm_user_registration'),
    getInviteStatusByToken: (): string => this.handlers.getEndpointUrl('/auth/invitation_user_status'),
    signUpEmployee: (): string => this.handlers.getEndpointUrl('/auth/employee/registration/confirm'),
    login: (): string => this.handlers.getEndpointUrl('/auth/login'),
    loginByCode: (): string => this.handlers.getEndpointUrl('/auth/login_via_code'),
    sendAuthenticationCode: (): string => this.handlers.getEndpointUrl('/auth/send_authentication_code'),
    verifyUserKey: (value: string): string => this.handlers.getEndpointUrl(`/auth/verify-user-key/${value}`),
  };

  public authenticationData: ILoginByCodeRequestFormData | IRegByCodeFormData | undefined;

  public sendCodeAfterOpenForm = false;

  public inviteToken: string | undefined;

  constructor(
    private readonly http: HttpClient,
    private readonly handlers: TnHttpHandlersService,
    public userService: TnCurrentUserService,
  ) {}

  public signUp(data: IMarketplaceRegFormData, token?: string) {
    const endpoint: string = this.endpoints.signUp();
    if (token) data.role = undefined;
    const observable = this.http.post(endpoint, { ...data, invite_token: token });
    return this.handlers.pipeHttpRequest(observable);
  }

  public signUpByCode(data: IRegByCodeFormData): Observable<ILoginResponseData> {
    const endpoint: string = this.endpoints.signUpByCode();
    const observable = this.http.post<ILoginResponseData>(endpoint, data);
    return this.handlers.pipeHttpRequest(observable);
  }

  public checkEmailExist(email: string): Observable<{ emailExist: boolean }> {
    const observable = this.http.post<{ emailExist: boolean }>(`${this.endpoints.checkEmail()}`, { email });
    return this.handlers.pipeHttpRequest<{ emailExist: boolean }>(observable, 1, false, true);
  }

  public confirmEmailAgain(): Observable<void> {
    const observable = this.http.get<void>(`${this.endpoints.confirmEmailAgain()}`);
    return this.handlers.pipeHttpRequest<void>(observable, 1, false, true);
  }

  public confirmRegistration(token: string): Observable<IConfirmRegistrationResponse> {
    const observable = this.http.post<IConfirmRegistrationResponse>(`${this.endpoints.confirmRegistration()}`, { token });
    return this.handlers.pipeHttpRequest<IConfirmRegistrationResponse>(observable, 1, false, false).pipe(
      map(data => {
        const result: IConfirmRegistrationResponse = { ...data };
        if (Boolean(data.userLoginData)) {
          result.userLoginData.role = USER_ROLE[result.userLoginData.role];
        }
        return result;
      }),
    );
  }

  public invitationUserStatusWithHeaders(token?: string): Observable<IInviteStatusResponse> {
    const url = this.endpoints.getInviteStatusByToken();
    const headers = new HttpHeaders().set('Authorization', 'Token ' + (this.userService.currentUser.token ?? ''));
    const options = { headers: headers };

    return this.http.post<IInviteStatusResponse>(url, { token }, { ...options });
  }

  public signUpEmployee(data: ISignUpEmployee): Observable<{email: string}> {
    const endpoint: string = this.endpoints.signUpEmployee();
    const observable = this.http.post<{email: string}>(endpoint, data);
    return this.handlers.pipeHttpRequest<{email: string}>(observable);
  }

  public login(loginData: ILoginRequestFormData) {
    const endpoint: string = this.endpoints.login();
    const observable = this.http.post<ILoginResponseData>(endpoint, loginData);
    return this.handlers.pipeHttpRequest<ILoginResponseData>(observable).pipe(
      map(data => {
        const result: ILoginResponseData = { ...data };
        result.role = USER_ROLE[result.role];
        result.email = loginData.email;
        return result;
      }),
    );
  }

  public loginByCode(loginData: ILoginByCodeRequestFormData) {
    const endpoint: string = this.endpoints.loginByCode();
    const observable = this.http.post<ILoginResponseData>(endpoint, loginData);
    return this.handlers.pipeHttpRequest<ILoginResponseData>(observable).pipe(
      map(data => {
        const result: ILoginResponseData = { ...data };
        result.role = USER_ROLE[result.role];
        result.email = loginData.email;
        return result;
      }),
    );
  }

  public sendAuthenticationCode(authenticationCodeData: IAuthenticationCodeRequestFormData): Observable<Partial<ILoginResponseData>> {
    const endpoint: string = this.endpoints.sendAuthenticationCode();
    const observable = this.http.post<Partial<ILoginResponseData>>(endpoint, authenticationCodeData);
    return this.handlers.pipeHttpRequest<Partial<ILoginResponseData>>(observable);
  }

  public verifyUserKey(key: string): Observable<IConfirmEmailResponse> {
    const observable = this.http.get<IConfirmEmailResponse>(`${this.endpoints.verifyUserKey(key)}`);
    return this.handlers.pipeHttpRequest<IConfirmEmailResponse>(observable, 1, false, false);
  }
}
