import { NgModule } from '@angular/core';
import { CdkTableModule } from '@angular/cdk/table';
import { TableComponent } from './components/table/table.component';
import { CommonModule} from '@angular/common';
import { MarketplaceTableColumnComponent } from './components/table/table-column.component';
import { TableRowExtenderDirective } from "./table-row-extender/table-row-extender.directive";

@NgModule({
  declarations: [TableComponent, MarketplaceTableColumnComponent, TableRowExtenderDirective],
  imports: [CdkTableModule, CommonModule],
  providers: [],
  exports: [TableComponent, MarketplaceTableColumnComponent, TableRowExtenderDirective],
})
export class PlainTableModule {}
