import { Injectable } from '@angular/core';
import { LEGAL_FORM_CODES } from '@transport/ui-interfaces';
import { map } from 'rxjs/operators';

import { TnGqlClientCarrierService } from '../gql-client-carrier';
import { TnOrganizationService } from '../profile/organization/organization.service';

@Injectable({
  providedIn: 'root',
})
export class TnPassportService {
  constructor(
    private readonly graphQL: TnGqlClientCarrierService,
    private readonly organizationService: TnOrganizationService,
  ) {}

  public getProfileData(id: string) {
    return this.graphQL.getCarrierUser(id).pipe(map(result => result.carrierUser));
  }

  public getMarketAttributes(id: string) {
    return this.graphQL.getCarrierUserMarkerAttributes(id).pipe(map(result => result.carrierUser));
  }

  public getOrganizationByInn(inn: string, legalFormCode: LEGAL_FORM_CODES) {
    return this.organizationService.getOrganizationByInn(inn, legalFormCode).pipe(map(value => value.organization));
  }
}
