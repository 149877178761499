import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { TnCoreModule } from '@transport/core';
import { transport } from '@transport/proto';
import { WS_CONFIG } from '@transport/ui-interfaces';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnUiTranslateModule } from '@transport/ui-translate';
import { HttpLink } from 'apollo-angular/http';

import { TnClientTimeWarningComponent } from './client-time/client-time-warning/client-time-warning.component';
import { TnIconsRegistryService } from './icons/icons-registry.service';

const wsConfig: transport.IWebSocketConfig = {
  url: '',
  reconnectAttempts: 1,
  reconnectInterval: 1000,
};

const moduleProviders: () => Provider[] = () => [
  { provide: LocationStrategy, useClass: PathLocationStrategy },
  HttpLink,
  {
    provide: WS_CONFIG,
    useValue: wsConfig,
  },
];

@NgModule({
  imports: [TnCoreModule, StoreModule, TnUiMaterialModule, TnUiTranslateModule],
  declarations: [TnClientTimeWarningComponent],
})
export class TnUiServicesModule {
  constructor(private readonly iconsService: TnIconsRegistryService) {
    this.iconsService.initialize();
  }

  public static forRoot(): ModuleWithProviders<TnUiServicesModule> {
    return {
      ngModule: TnUiServicesModule,
      providers: [...moduleProviders()],
    };
  }
}
