export interface IReportStatisticsForm {
  dates: { fromDate: string; toDate: string };
  numberOrder: string;
  carrier: string | null;
  carrierGroup: string[];
}

export interface IStatisticsReportInput {
  dateFrom?: string | null;
  dateTo?: string | null;
  organizationId?: string;
  groupId?: string;
  orderId?: string;
  reportType: REPORT_TYPE;
}

export enum REPORT_TYPE {
  ORDER_DETAILS = 'orders_details',
  ORDER_ACTIVITY = 'carrier_order_activity',
  ORDERS_TRAIDING = 'orders_trading',
  ORDERS_TRAIDING_DETAILS = 'orders_trading_details',
}
