import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, tap } from 'rxjs';
import { OrderApiTrackQueryService } from './order-query-events.service';

enum HTTP_STATUS {
  SUCCESS = 200,
}

@Injectable({
  providedIn: 'root',
})
export class OrderQueryInterceptorService implements HttpInterceptor {
  constructor(private readonly eventsService: OrderApiTrackQueryService) {}
  private queriesForUpdate = [
    this.eventsService.TRACK_QUERIES.CANCEL_ORDER_TRADING,
    this.eventsService.TRACK_QUERIES.ACCEPT_COUNTER_OFFER,
    this.eventsService.TRACK_QUERIES.ASSIGN_COUNTER_OFFER,
  ];

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      tap(response => {
        if (response instanceof HttpResponse && response.status === HTTP_STATUS.SUCCESS) {
          const withErrors = typeof response.body === 'object' && response.body !== null && 'errors' in response.body;
          const url = response.url;
          const query = this.queriesForUpdate.find(item => url?.includes(item));
          if (!withErrors && query) {
            this.eventsService.events$.next(query);
          }
          if (
            !withErrors &&
            (url?.includes(this.eventsService.TRACK_QUERIES.ORDERS) ||
              url?.includes(this.eventsService.TRACK_QUERIES.AUCTION_BET_HISTORY) ||
              url?.includes(this.eventsService.TRACK_QUERIES.ORDER_SHORT_INFO))
          ) {
            this.eventsService.serverTime$.next(response.headers.get('date') || '');
          }
        }
      }),
    );
  }
}
