import { Action, createReducer, on } from '@ngrx/store';

import { ngrxThrow401, ngrxThrow404 } from '../actions/ngrx-error.actions';

/**
 * NgRx error interface.
 */
export interface INgrxError {
  code: number;
  message?: string;
}

/**
 * NgRx error state.
 */
export interface INgrxErrorState {
  ngrxError: INgrxError;
}

/**
 * NgRx error initial state.
 */
export const ngrxErrorInitialState: INgrxErrorState = {
  ngrxError: {
    code: -1,
  },
};

const createNgrxErrorReducer = createReducer(
  ngrxErrorInitialState,
  on(ngrxThrow401, (state, { payload }) => ({
    ...state,
    ...payload,
  })),
  on(ngrxThrow404, (state, { payload }) => ({
    ...state,
    ...payload,
  })),
);

export function ngrxErrorReducer(state: INgrxErrorState, action: Action) {
  return createNgrxErrorReducer(state, action);
}
