import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';

import { TnHttpHandlersService } from '../feature-access/http-handlers.service';
import { IFiasData } from './fias.state';

const DEFAULT_MIN_LENGTH = 3;

@Injectable({
  providedIn: 'root',
})
export class TnFiasApiService {
  constructor(private readonly http: HttpClient, private readonly handlers: TnHttpHandlersService) {}

  public getFiasCodeCollectionByString(input: string, minInputLength = DEFAULT_MIN_LENGTH): Observable<IFiasData> {
    // It's temporary solution for server error 404.
    if (input.trim().length > minInputLength) {
      const url = `${this.handlers.getEndpointUrl('/fias/suggest/')}`;
      return this.http.get<IFiasData>(url + input);
    }
    return of({ addresses: [] });
  }

  public getFiasStringByCode(code: string): Observable<IFiasData> {
    const url = `${this.handlers.getEndpointUrl('/fias/encode/')}`;
    return this.http.get<IFiasData>(url + code);
  }
}
