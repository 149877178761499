<div mat-dialog-title class="!flex !mt-2">
  <mat-icon class="material-icons dialog-close absolute right-2 top-2" (click)="dialog.close()">clear</mat-icon>
  <span class="font-semibold text-primary text-xxl">{{ 'shared.mp.vehicles.filters.title' | translate }}</span>
</div>

<mat-divider class="!mt-[-12px] !mb-0"></mat-divider>

<div mat-dialog-content>
  <form [formGroup]="form" class="flex flex-col">
    <common-select
      placeholder="{{ 'shared.mp.vehicles.filters.vehicleType' | translate }}"
      label="{{ 'shared.mp.vehicles.filters.vehicleType' | translate }}"
      dataTest="vehicle-car-type"
      [dataSource]="vehicleTypes"
      [control]="$any(form.controls.vehicleType)"
    >
    </common-select>

    <common-select
      placeholder="{{ 'shared.mp.vehicles.filters.ownershipType' | translate }}"
      label="{{ 'shared.mp.vehicles.filters.ownershipType' | translate }}"
      dataTest="vehicle-ownership-type"
      [dataSource]="ownershipTypes"
      [control]="$any(form.controls.ownership)"
    >
    </common-select>

    <common-checkbox
      [control]="$any(form.controls.isThirdParty)"
      [label]="'shared.mp.vehicles.vehicle.form.isThirdParty' | translate"
      dataTest="vehicle-isThirdParty">
    </common-checkbox>

    <common-input
      label="{{ 'shared.mp.vehicles.filters.regNumber' | translate }}"
      dataTest="vehicle-reg-number"
      inputClasses="uppercase"
      [size]="'sm'"
      [labelClasses]='["text-sm", "text-gray-700"]'
      [errorToMessage]="validationErrors.bind(this)"
      [control]="$any(form.controls.regNo)"
    >
    </common-input>

    <common-select
      placeholder="{{ 'shared.mp.vehicles.filters.make' | translate }}"
      label="{{ 'shared.mp.vehicles.filters.make' | translate }}"
      dataTest="vehicle-make"
      [size]="'sm'"
      [dataSource]="vehicleMakes$ | async"
      [labelClasses]='["text-sm", "text-gray-700"]'
      [control]="$any(form.controls.vehicleBrand)"
    >
    </common-select>

    <common-input
      label="{{ 'shared.mp.vehicles.filters.trailerRegNumber' | translate }}"
      dataTest="vehicle-trailer-reg-number"
      inputClasses="uppercase"
      [size]="'sm'"
      [labelClasses]='["text-sm", "text-gray-700"]'
      [errorToMessage]="validationErrors.bind(this)"
      [control]="$any(form.controls.trailerRegNo)"
    >
    </common-input>

    <common-select
      class="w-full"
      placeholder="{{ 'shared.mp.vehicles.filters.trailerBodyType' | translate }}"
      label="{{ 'shared.mp.vehicles.filters.trailerBodyType' | translate }}"
      dataTest="vehicle-trailer-body-type"
      [dataSource]="bodySubtypes$ | async"
      [control]="$any(form.controls.bodyType)"
    >
    </common-select>

    <div class="flex gap-2">
      <common-number-input
        class="w-full"
        placeholder="{{ 'shared.mp.vehicles.filters.capacity' | translate }}"
        label="{{ 'shared.mp.vehicles.filters.capacity' | translate }}"
        dataTest="vehicle-capacity"
        rightAddon="т"
        [size]="'sm'"
        [labelClasses]='["text-sm", "text-gray-700"]'
        [control]="$any(form.controls.capacity)"
      >
      </common-number-input>

      <common-number-input
        class="w-full"
        placeholder="{{ 'shared.mp.vehicles.filters.volume' | translate }}"
        label="{{ 'shared.mp.vehicles.filters.volume' | translate }}"
        dataTest="vehicle-volume"
        rightAddon="м³"
        [size]="'sm'"
        [labelClasses]='["text-sm", "text-gray-700"]'
        [control]="$any(form.controls.volume)"
      >
      </common-number-input>
    </div>

    <common-select
      placeholder="{{ 'shared.mp.vehicles.filters.loadingType' | translate }}"
      label="{{ 'shared.mp.vehicles.filters.loadingType' | translate }}"
      dataTest="vehicle-loading-type"
      [dataSource]="loadingTypes"
      [control]="$any(form.controls.loadingType)"
    >
    </common-select>
  </form>
  <div class="flex gap-2 mt-3 mb-2">
    <button *ngIf="form.dirty" (click)="onClear()" class="mb-6 sm:mb-0 tw-button-outline-sm w-full" data-test="filters-clear-button">
      {{ 'shared.mp.vehicles.filters.clear' | translate }}
    </button>
    <button (click)="onApply()" class="mb-6 sm:mb-0 tw-button-solid-sm w-full" data-test="filters-apply-button">
      {{ 'shared.mp.vehicles.filters.apply' | translate }}
    </button>
  </div>
</div>

