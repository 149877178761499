import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Renderer2,
  Self,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl, ValidationErrors } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TnDadataApiService, TnDadataFacade } from '@transport/ui-store';
import { TIMEOUT } from '@transport/ui-utils';
import { Subject } from 'rxjs';
import { debounceTime, filter, map, takeUntil, tap } from 'rxjs/operators';

import { TnBaseMatInputDirective } from '../base-mat-input';
import { TnMapPoint } from '@transport/ui-interfaces';

interface IAddressAutocompleteItem {
  label: string;
  value: IAddressAutocompleteItemValue;
}

export interface IAddressAutocompleteItemValue {
  fullAddress: string;
  isUserInput: boolean;
}

const trimmedStringInputValidator = (control: AbstractControl) => {
  const value = control.value;
  const canTrimmed = typeof value === 'string';
  if (Boolean(value) && canTrimmed && (value as string).trim().length === 0) {
    return {
      trimmedString: true,
    };
  }
  return null;
};

const NUMBER_OF_ITEMS_IN_AUTOCOMPLETE_LIST = 10;

@UntilDestroy()
@Component({
  selector: 'address-input-kit',
  templateUrl: './address-input-kit.component.html',
  styleUrls: ['./address-input-kit.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: AddressInputComponentKit,
    },
  ],
})
/* eslint-disable prettier/prettier -- prettier conflicts with eslint (brace style), eslint takes precedence here */
export class AddressInputComponentKit
  extends TnBaseMatInputDirective<TnMapPoint & { isUserInput?: boolean }>
  implements ControlValueAccessor, OnDestroy, DoCheck, AfterViewInit, MatFormFieldControl<unknown> {
  /* eslint-enable prettier/prettier -- prettier conflicts with eslint in this case (brace style), eslint takes precedence here */

  @Input() public label? = '';

  @Input() public showErrors = true;

  @Input() public address? = '';

  @Input() public latitude? = '';

  @Input() public longitude? = '';

  @Input() public dataTest? = '';

  @Input() public errorToMessage: (errors: ValidationErrors | null) => string = () => 'Error';

  @HostBinding('attr.id')
  public id = `address-input-1`;

  public searchInputControl = new FormControl('', trimmedStringInputValidator);

  private readonly shouldCompleteSearchInputMonitoringSubject = new Subject<boolean>();

  public selectData$ = this.tnDadataFacade.dadataSuggestions$;

  @ViewChild('inputElement') public inputElementRef?: ElementRef<HTMLInputElement>;

  public bindedValue = (value: IAddressAutocompleteItem) => {
    return value?.label ?? '';
  };

  @HostBinding('class.floating')
  public get shouldLabelFloat() {
    return true;
  }

  constructor(
    public fm: FocusMonitor,
    public elRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl,
    private readonly tnDadataFacade: TnDadataFacade,
    private readonly renderer: Renderer2,
    private readonly cdr: ChangeDetectorRef,
    private readonly dadataApiService: TnDadataApiService,
  ) {
    super(fm, elRef, ngControl);
  }

  ngDoCheck() {
    this.checkTouchFromParentControl();
    this.cdr.detectChanges();
  }

  checkTouchFromParentControl() {
    if (this.ngControl.touched && this.searchInputControl.touched) {
      return
    }
    if (this.ngControl.touched && this.searchInputControl.untouched) {
      this.searchInputControl.markAsTouched()
    }
    if (this.ngControl.untouched && this.searchInputControl.touched) {
      this.searchInputControl.markAsUntouched()
    }
  }
  onMarkTouch () {
    this.searchInputControl.updateValueAndValidity();
    this.searchInputControl.markAsTouched();
    this.ngControl?.control?.updateValueAndValidity();
    this.ngControl?.control?.markAsTouched();
    this.cdr.detectChanges();
  }

  public getDaData = (address: string) =>
    this.tnDadataFacade.getMapPointsByAddress({
      address,
      countResults: NUMBER_OF_ITEMS_IN_AUTOCOMPLETE_LIST,
    });

  public optionSelected(event: MatAutocompleteSelectedEvent) {
    this.searchInputControl.setErrors(null);
    const option: IAddressAutocompleteItem = event.option.value;
    this.dadataApiService.getMapPointByAddress({ address: option.value.fullAddress }).subscribe(res => {
      this.value = res;
      this.propagateChange(this.value);
    });
  }

  public setRequiredFormError(): void {
    this.searchInputControl.setErrors({ required: true });
    this.searchInputControl.markAsTouched();
    this.cdr.markForCheck();
  }

  public ngAfterViewInit() {
    if (Boolean(this.address)) {
      setTimeout(() => this.onUpdateFromExternalSource({ fullAddress: this.address as string, isUserInput: true }));
    } else {
      this.initSearchInputMonitoring();
    }
  }

  private onUpdateFromExternalSource(value: { fullAddress: string; isUserInput: boolean }) {
    this.shouldCompleteSearchInputMonitoringSubject.next(true);
    const val = TnMapPoint.fromDto();
    val.fullAddress = value.fullAddress;
    this.value = { ...val,  isUserInput: value.isUserInput };

    this.propagateChange(this.value);
    this.renderer.setProperty(this.inputElementRef?.nativeElement, 'value', value.fullAddress);
    this.inputElementRef?.nativeElement.dispatchEvent(new Event('input', { bubbles: true }));
    this.initSearchInputMonitoring();
  }

  private initSearchInputMonitoring() {
    void this.searchInputControl.valueChanges
      .pipe(
        map(value => {
          if (typeof value !== 'object' && value !== '') {
            this.searchInputControl.setErrors({ notSelectedAddress: true });
          } else {
            const errors = this.searchInputControl.errors;
            delete errors?.notSelectedAddress;
            this.searchInputControl.setErrors(errors);
          }
          return value
        }),
        debounceTime(TIMEOUT.DEFAULT),
        filter(value => typeof value === 'string'),
        tap((value: string | null) => {
          if (value) {
            this.getDaData(value);
          } else {
            this.inputValue = void 0;
          }
          //
          // const val = TnMapPoint.fromDto();
          // val.fullAddress = value;
          // this.value = { ...val, isUserInput: true };
          // this.propagateChange(this.value);
        }),
        takeUntil(this.shouldCompleteSearchInputMonitoringSubject),
      )
      .subscribe();
  }
}
