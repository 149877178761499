import { createAction, props } from '@ngrx/store';
import { TnCarrierOrderTrackingPageComponentData, TnOwnerOrderTrackingPageComponentData } from '@transport/ui-interfaces';

export const CARRIER_ORDER_TRACKING_PAGE_SET_ROUTE_DATA = '[Carrier Order Tracking] Set Route Data';

export const setCarrierOrderTrackingPageRouteData = createAction(
  CARRIER_ORDER_TRACKING_PAGE_SET_ROUTE_DATA,
  props<{ payload: { carrierOrderTrackingPage: TnCarrierOrderTrackingPageComponentData } }>(),
);

export const OWNER_ORDER_TRACKING_PAGE_SET_ROUTE_DATA = '[Owner Order Tracking] Set Route Data';

export const setOwnerOrderTrackingPageRouteData = createAction(
  OWNER_ORDER_TRACKING_PAGE_SET_ROUTE_DATA,
  props<{ payload: { ownerOrderTrackingPage: TnOwnerOrderTrackingPageComponentData } }>(),
);
