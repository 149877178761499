import { Injectable } from '@angular/core';
import { transport } from '@transport/proto';
import { IUser } from '@transport/ui-interfaces';
import { map } from 'rxjs/operators';

import { TnGqlClientOwnerMutationsService } from '../gql-client-owner/graphql-client-owner-mutations.service';
import { TnGqlClientOwnerQueriesService } from '../gql-client-owner/graphql-client-owner-queries.service';

/**
 * Transport Admin API service.
 * Manage admin fetching and actions.
 * Controls admin events subscribtions.
 */
@Injectable({
  providedIn: 'root',
})
export class TnAdminApiService {
  constructor(private readonly gqlQuery: TnGqlClientOwnerQueriesService, private readonly gqlMutation: TnGqlClientOwnerMutationsService) {}

  public getUsers(listenX?: number, withSpinner?: boolean, first?: number, offset?: number, options?: Record<string, unknown>) {
    return this.gqlQuery.queryUsers(listenX, withSpinner, first, offset, options).pipe(
      map(data => {
        return {
          list: data.employees as IUser[],
          totalCount: data.count ?? 0,
        };
      }),
    );
  }

  public getUser(userId: string, withSpinner = true) {
    return this.gqlQuery.queryUser(userId, withSpinner);
  }

  public saveUser(user: transport.IEditProfileInput & { id: string }, withSpinner = true) {
    return this.gqlMutation.saveUser(user, withSpinner);
  }

  public inviteUser(user: transport.IEditProfileInput, withSpinner = true) {
    return this.gqlMutation.inviteUser(user, withSpinner);
  }

  public inviteUserAgain(userId, withSpinner = true) {
    return this.gqlMutation.inviteUserAgain(userId, withSpinner);
  }

  public removeUser(userId: string, withSpinner = true) {
    return this.gqlMutation.removeUser(userId, withSpinner);
  }

  public getAllPermissions() {
    return this.gqlQuery.getAllPermissions(false);
  }

  public resetPassword(id, withSpinner = true) {
    return this.gqlMutation.resetPassword(id, withSpinner);
  }
}
