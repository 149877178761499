import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { FormBuilder, FormControl } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { transport } from '@transport/proto';
import { TnCustomNgNeatReactiveFormControlDirective } from '@transport/ui-directives';
import { IBodyTrailerForm } from '@transport/ui-interfaces';
import { createBodyInfoFormGroup, mapBodyTruckInputValueToInner } from '@transport/ui-store';
import { of } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'transport-body-info',
  templateUrl: './body-info.component.html',
  styleUrls: ['./body-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnBodyInfoComponent extends TnCustomNgNeatReactiveFormControlDirective<IBodyTrailerForm> implements OnInit, OnDestroy {
  @Input() public isUseMatStep = false;

  @Input() public loadingTypes: transport.Vehicle.Body.IType[] = [];

  @Input() public bodyTypes: transport.Vehicle.Body.IType[] = [];

  @Input() public bodySubtypes: Record<string, transport.Vehicle.Body.ISubtype[]> = {};

  @Input() public isDisabled = false;

  constructor(
    protected readonly fb: FormBuilder,
    @Optional() @Self() public controlDir: NgControl,
    private readonly changeDetector: ChangeDetectorRef,
  ) {
    super(fb);
    if (Boolean(controlDir)) {
      controlDir.valueAccessor = this;
    }
  }

  public ngOnInit() {
    this.controlDir.control?.setValidators([this.validate.bind(this)]);
    this.controlDir.control?.updateValueAndValidity();
    this.changeDetector.detectChanges();

    this.form.disabledWhile(of(this.isDisabled));

    void this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.onChange(value);
    });

    void this.form.controls.bodyType.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.form.controls.bodySubtype.reset();
    });

    if (Boolean(this.controlDir.control)) {
      void (this.controlDir.control as FormControl).touch$.pipe(untilDestroyed(this)).subscribe(touch => {
        if (Boolean(touch)) {
          this.form.markAllAsTouched();
          this.changeDetector.detectChanges();
        }
      });
    }
  }

  public ngOnDestroy() {
    this.controlDir.control?.clearValidators();
    this.controlDir.control?.updateValueAndValidity();
  }

  public validate(): ValidationErrors | null {
    return this.form.invalid ? { bodyInfo: true } : null;
  }

  public writeValue(data: IBodyTrailerForm): void {
    if (Boolean(data)) {
      this.form.patchValue(mapBodyTruckInputValueToInner<IBodyTrailerForm>(data), {
        emitEvent: false,
      });
    }
  }

  public get bodyTypeValue() {
    return this.form.controls.bodyType.value as string;
  }

  protected createFormGroup() {
    return createBodyInfoFormGroup();
  }
}
