import { Action, createReducer, on } from '@ngrx/store';
import { IOrganizationDocument, IRegistrationFileToUpload, IRegistrationState, REGISTRATION_STATUS } from '@transport/ui-interfaces';

import {
  changeStep,
  deleteDocumentSuccess,
  deleteUploadedFile,
  loadDocumentsAndTypesSuccess,
  setAllDocumentTouched,
  setRegistrationStatus,
  uploadDocumentStart,
  uploadDocumentSuccess,
  uploadSignDocumentSucсess,
} from '../actions/registration.actions';

export const registrationInitialState = {
  step: null,
  driverCheck: REGISTRATION_STATUS.NOT_CHECKED,
  organizationCheck: REGISTRATION_STATUS.NOT_CHECKED,
  vehicleCheck: REGISTRATION_STATUS.NOT_CHECKED,
  esignCheck: REGISTRATION_STATUS.NOT_CHECKED,
  documents: [],
  signDocument: null,
} as IRegistrationState;

const createRegistrationReducer = createReducer(
  registrationInitialState,
  on(changeStep, (state, { payload }) => ({ ...state, step: payload })),

  on(setRegistrationStatus, (state, { payload }) => {
    const { driverCheck, organizationCheck, vehicleCheck, esignCheck } = payload;
    return {
      ...state,
      driverCheck,
      organizationCheck,
      vehicleCheck,
      esignCheck,
    };
  }),

  on(uploadDocumentStart, state => ({ ...state, uploadedDocument: null })),

  on(uploadDocumentSuccess, (state, { file }) => ({
    ...state,
    documents: updateDocuments(state.documents, file),
  })),

  on(uploadSignDocumentSucсess, (state, { file }) => ({
    ...state,
    signDocument: { ...file },
  })),

  on(loadDocumentsAndTypesSuccess, (state, { documents }) => {
    const signDocumentIndex = documents.findIndex(document => document.documentType.innerName === 'OPEN_DIGIT_SIGN');
    if (Boolean(signDocumentIndex + 1)) {
      const signDocument = Boolean(signDocumentIndex) ? (documents[signDocumentIndex] as IOrganizationDocument) : null;
      return {
        ...state,
        documents: [...documents.slice(0, signDocumentIndex), ...documents.slice(signDocumentIndex + 1)],
        signDocument,
      };
    }
    return {
      ...state,
      documents: [...documents],
    };
  }),

  on(deleteDocumentSuccess, (state, { index }) => ({
    ...state,
    documents: [
      ...state.documents.slice(0, index),
      { documentType: state.documents[index].documentType, uploadedFile: null },
      ...state.documents.slice(index + 1),
    ],
  })),

  on(deleteUploadedFile, state => ({
    ...state,
    signDocument: { ...(state.signDocument as IOrganizationDocument), uploadedFile: null },
  })),

  on(setAllDocumentTouched, state => ({
    ...state,
    documents: [...state.documents.map(document => ({ ...document, isTouched: true }))],
  })),
);

function updateDocuments(documents: IRegistrationFileToUpload[], file: IRegistrationFileToUpload): IRegistrationFileToUpload[] {
  const documentIndex = documents.findIndex(item => item.documentType.id === file.documentType.id);
  return [...documents.slice(0, documentIndex), { ...file }, ...documents.slice(documentIndex + 1)];
}

export function registrationReducer(state: IRegistrationState, action: Action) {
  return createRegistrationReducer(state, action);
}
