import { Directive, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { IYaMap, IYaMapLib } from '@transport/ui-interfaces';
import { COUNTER } from '@transport/ui-utils';
import { filter } from 'rxjs/operators';

import { TnYamapsLoaderService } from '../../services/loader/yamaps-loader.service';
import { TnYamapsUtilsService } from '../../services/utils/yamaps-utils.service';

@UntilDestroy()
@Directive()
export abstract class TnYamapBaseDirective implements OnDestroy, OnInit {
  /**  Map identificator */
  public mapId = '';

  /**  Map width */
  @Input() public width = '100%';

  /**  Map height */
  @Input() public height = '500px';

  /**  Map zoom */
  @Input() public zoom: number = COUNTER.EIGHT;

  /**  Map controls initialization */
  private readonly controls = false;

  /**  Restrict map mode */
  protected readonly restrict = false;

  /**  Get coordinates by click mode callback */
  @Output() public readonly getCoordinatesByClickChange = new EventEmitter();

  @Output() public readonly yamapReady = new EventEmitter();

  /**  Yandex map lib */
  protected ymaps: IYaMapLib = {} as IYaMapLib;

  /**  Map object */
  protected map?: IYaMap;

  /**
   * Constructor.
   * Gets map id when called.
   */
  constructor(protected readonly yamapsLoader: TnYamapsLoaderService, protected readonly utils: TnYamapsUtilsService) {
    this.mapId = utils.getId();
  }

  public ngOnInit() {
    const time = 100;
    void this.yamapsLoader.loadApi$
      .pipe(
        filter(val => Boolean(val)),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.ymaps = (window as Window & { ymaps }).ymaps;
        this.ymaps.ready(() => {
          this.yamapReady.next(true);
        });
        const create = () =>
          setTimeout(() => {
            if (this.utils.checkContainer(this.mapId)) {
              this.mount();
            } else {
              create();
            }
          }, time);
        create();
      });
  }

  protected abstract mount(): void;

  public redraw(isSmallScreen = false) {
    if (typeof this.map !== 'undefined') {
      const sizeFactor = 0.4;
      const container = document.getElementById(this.mapId);
      let offsetHeight = container?.parentElement?.parentElement?.parentElement?.parentElement?.parentElement?.offsetHeight ?? 0;
      offsetHeight = isSmallScreen ? offsetHeight * sizeFactor : offsetHeight;
      this.map.container.getElement().style.height = offsetHeight;
      if (container !== null) {
        container.style.height = this.height;
      }
      this.map.container.fitToViewport();
    }
  }

  /**
   * Get map coordinates by click
   */
  public getCoordinatesByClickInit() {
    this.map?.events.add('click', (e: { get: (key: string) => { toPrecision: (precision: number) => unknown }[] }) => {
      const coords = e.get('coords');
      this.getCoordinatesByClickChange.emit([coords[0].toPrecision(COUNTER.SIX), coords[1].toPrecision(COUNTER.SIX)]);
    });
  }

  /**
   * Set zoom
   * @param number zoom scale
   */
  public setZoom(number: number) {
    this.map?.setZoom(number);
  }

  public ngOnDestroy(): void {
    if (Boolean(this.map)) {
      this.map?.destroy();
    }
    localStorage.removeItem('breaker');
  }

  public getId() {
    return this.utils.getId();
  }

  /**
   * Set maps controls method.
   * @returns array with controls.
   */
  protected setControls() {
    if (this.utils.checkContainer(this.mapId)) {
      if (this.restrict) {
        return [];
      }
      if (!this.controls) {
        return ['zoomControl'];
      }
      return ['zoomControl'];
    }
    return [];
  }
}
