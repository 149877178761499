import { ActionReducerMap } from '@ngrx/store';

import * as fromFeature from './reconciliation-act.reducer';
import * as fromTable from './reconciliation-act-table.reducer';

export const reconciliationActFeatureKey = 'reconciliationAct';

export interface IReconcilationActState {
  [reconciliationActFeatureKey]: fromFeature.IState;
  [fromTable.reconciliationActTableFeatureKey]: ReturnType<typeof fromTable.reconciliationActTableReducer>;
}

export const reducers: ActionReducerMap<IReconcilationActState> = {
  [reconciliationActFeatureKey]: fromFeature.reducer,
  [fromTable.reconciliationActTableFeatureKey]: fromTable.reconciliationActTableReducer,
};
