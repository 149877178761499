import { createAction, props } from '@ngrx/store';
import {
  TnReconcilationActModel,
  TnReconcilationActOrganization,
  TnReconcilationActRule,
  TnReconcilationActWebsocketMessage,
  TnReconciliationActDetailsFormValue,
} from '@transport/ui-interfaces';

export const loadReconciliationActsStart = createAction(
  '[Reconciliation Act API] Load Reconciliation Acts Start',
  props<{ pageNumber: number; countPerPage: number }>(),
);

//#region Organizations
export const loadReconciliationOrganizationsStart = createAction('[Reconciliation Act API] Load Reconciliation Organizations Start');

export const loadReconciliationOrganizationsSuccess = createAction(
  '[Reconciliation Act API] Load Reconciliation Organizations Success',
  props<{ organizations: TnReconcilationActOrganization[] }>(),
);

export const loadReconciliationOrganizationsFailure = createAction(
  '[Reconciliation Act API] Load Reconciliation Organizations Failure',
  props<{ error: Error }>(),
);
//#endregion Organizations

//#region Rules
export const loadReconciliationRulesStart = createAction('[Reconciliation Act API] Load Reconciliation Rules Start');

export const loadReconciliationRulesSuccess = createAction(
  '[Reconciliation Act API] Load Reconciliation Rules Success',
  props<{ rules: TnReconcilationActRule[] }>(),
);

export const loadReconciliationRulesFailure = createAction(
  '[Reconciliation Act API] Load Reconciliation Rules Failure',
  props<{ error: Error }>(),
);
//#endregion Rules

//#region MyOrganization
export const loadReconciliationMyOrganizationStart = createAction('[Reconciliation Act API] Load Reconciliation My Organization Start');

export const loadReconciliationMyOrganizationSuccess = createAction(
  '[Reconciliation Act API] Load Reconciliation My Organization Success',
  props<{ organization: TnReconcilationActOrganization }>(),
);

export const loadReconciliationMyOrganizationFailure = createAction(
  '[Reconciliation Act API] Load Reconciliation My Organization Failure',
  props<{ error: Error }>(),
);
//#endregion MyOrganization

export const saveReconciliationActStart = createAction(
  '[Reconciliation Act API] Save Reconciliation Act Start',
  props<{ act: TnReconciliationActDetailsFormValue }>(),
);

export const saveReconciliationActSuccess = createAction(
  '[Reconciliation Act API] Save Reconciliation Act Success',
  props<{ act: TnReconcilationActModel }>(),
);

export const saveReconciliationActFailure = createAction(
  '[Reconciliation Act API] Save Reconciliation Act Failure',
  props<{ error: Error }>(),
);

export const loadReconciliationActStart = createAction('[Reconciliation Act API] Load Reconciliation Act Start', props<{ guid: string }>());

export const loadReconciliationActSuccess = createAction(
  '[Reconciliation Act API] Load Reconciliation Act Success',
  props<{ act: TnReconcilationActModel }>(),
);

export const loadReconciliationActFailure = createAction(
  '[Reconciliation Act API] Load Reconciliation Act Failure',
  props<{ error: Error }>(),
);

export const websocketCompleteMessageReceived = createAction(
  '[Reconciliation Act Websocket] Complete Messasge Received',
  props<{ message: TnReconcilationActWebsocketMessage }>(),
);

export const repeatGetAct = createAction('[Reconciliation Act API] Repeat Get Act', props<{ guid: string }>());
