import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { transport } from '@transport/proto';

export interface ICarreirOrderContractHandlingFormValue {
  driver: transport.IDriver | null | undefined;
  truck: transport.IVehicle | null | undefined;
  secondDriver: transport.IDriver | null | undefined;
}

export function createCarrierContractHandlingFormGroup(): FormGroup<ICarreirOrderContractHandlingFormValue> {
  return new FormGroup<ICarreirOrderContractHandlingFormValue>({
    driver: new FormControl(null, Validators.required),
    truck: new FormControl(null, Validators.required),
    secondDriver: new FormControl(),
  });
}
