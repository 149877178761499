import { Pipe, PipeTransform } from '@angular/core';
import { regExpConfig } from '@transport/ui-utils';
import memo from 'memo-decorator';
import { NgxMaskService } from 'ngx-mask';

/**
 * Pipe for currency value
 */
@Pipe({
  name: 'currencyRu',
})
export class TnCurrencyPipe implements PipeTransform {
  constructor(private readonly maskService: NgxMaskService) {}

  /**
   * Transform sts number string to special string format
   * @param value Value string
   * @param decimalDigits Value boolean. Used to show if showing decimal digits is needed.
   * @param suffix Value string. Used to replace Ruble sign in the end with the value.
   */
  @memo({
    resolver: (...args: any[]): string => JSON.stringify(args),
  })
  public transform(value: number | string | null | undefined, decimalDigits = true, suffix = ''): string {
    let val = typeof value === 'string' ? String(parseFloat(value)) : value?.toString();
    if (Boolean(val) && decimalDigits) {
      val += val?.includes('.') ? '00' : ',00';
    }
    this.maskService.thousandSeparator = ' ';
    this.maskService.allowNegativeNumbers = true;
    const result = this.maskService.applyMaskWithPattern(val ?? '', [
      regExpConfig.ngxMask.currency.rur.mask,
      { ...regExpConfig.ngxMask.currency.rur.patterns },
    ]);
    return result !== '' ? (suffix ? `${result} ${suffix}` : `${result} \u20BD`) : '';
  }
}
