import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { USER_ROLE } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnCurrentUserFacade } from '../../current-user/current-user.facade';
import { TnToasterFacade } from '../../toaster/toaster.facade';

@Injectable({
  providedIn: 'root',
})
export class TnPermissionGuard implements CanActivate {
  constructor(
    private readonly userFacade: TnCurrentUserFacade,
    private readonly router: Router,
    private readonly toastFacade: TnToasterFacade,
  ) {}

  /**
   * Checks if user can activate a particular router path.
   */
  public canActivate(next: ActivatedRouteSnapshot): Observable<boolean> {
    const permissionName = next.data.permissionName;
    return this.userFacade.hasPermission(permissionName).pipe(
      map(allowed => {
        const userRole: USER_ROLE = this.userFacade.currentUser.role;
        if (!allowed) {
          this.toastFacade.showMessage('shared.errors.permission');
          void this.router.navigate([`/orders/${userRole}`]);
        }
        return allowed;
      }),
    );
  }
}
