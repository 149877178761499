export const registrationStepperLocalization = {
  title: 'Регистрация',
  firstStep: 'Проверка организации',
  secondStep: 'Добавление и проверка транспортного средства',
  thirdStep: 'Добавление и проверка водителя',
  fourthStep: 'Добавление подписи',
  actions: {
    sendToCheck: 'Отправить на проверку',
    attachSign: 'Прикрепить электронную подпись',
  },
  addTruck: 'Добавление транспортного средства (ТС)',
  ownedByCarrier: 'ТС в собственности у организации',
  addDriver: 'Добавление водителя',
  addSign: 'Добавление электронной подписи',
  sign: 'Электронная подпись',
  confirmationActionOrganization: {
    title: 'Подтверждение',
    content:
      'Отправляя данные об организации и документы, вы подтверждаете их актуальность. Вы не сможете вернуться на этот шаг, внести изменения можно будет только с помощью службы поддержки.',
    confirm: 'Отправить',
    cancel: 'Отменить',
  },
  confirmationActionTruck: {
    title: 'Подтверждение',
    content:
      'Отправляя данные о транспортном средстве, вы подтверждаете их актуальность. Вы не сможете вернуться на этот шаг, внести изменения можно будет только с помощью службы поддержки.',
    confirm: 'Отправить',
    cancel: 'Отменить',
  },
  confirmationActionDriver: {
    title: 'Подтверждение',
    content:
      'Отправляя данные о водителе, вы подтверждаете их актуальность. Вы не сможете вернуться на этот шаг, внести изменения можно будет только с помощью службы поддержки.',
    confirm: 'Отправить',
    cancel: 'Отменить',
  },
  confirmationActionSign: {
    title: 'Подтверждение',
    content:
      'Отправляя данные об электроной подписи, вы подтверждаете их актуальность. Вы не сможете вернуться на этот шаг, внести изменения можно будет только с помощью службы поддержки.',
    confirm: 'Отправить',
    cancel: 'Отменить',
  },
  documentsTitle: 'Документы',
  organizationDoc: {
    attached: 'Приложен',
    title: 'Документы',
    uploadLabel: 'Загрузить',
    required: 'Необходимо загрузить документ',
  },
  statuses: {
    onWork: 'на проверке',
    approved: 'проверено',
    rejected: 'отклонено',
  },
  resultStatus: {
    check: {
      title: 'Идет проверка',
      description:
        'Спасибо, что предоставили нам всю информацию, мы отправили ее на проверку и сразу сообщим Вам, как только проверка будет завершена.',
      action: 'На главную',
    },
    success: {
      title: 'Поздравляем!',
      description: 'Все этапы регистрации в системе пройдены.',
      action: 'Приступить к работе',
    },
  },
  errors: {
    organization: 'Произошла ошибка при сохранении организации',
    truck: 'Произошла ошибка при сохранении транспортного средства',
    loadDocuments: 'Произошла ошибка при получении документов',
    deleteDocument: 'Произошла ошибка при удалении документа',
    loadedDocument: 'Произошла ошибка при загрузке документа',
  },
};
