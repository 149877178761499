import { transport } from '@transport/proto';

import { IUiSettings } from '../user/user.interface';
import { USER_ROLE } from '../user/user-common.interface';
import { IConfirmRegistrationStep } from '../registration/registration.interfaces';
import { TnSupportedCountry } from '@transport/ui-pipes';

export interface ILoginRequestFormData {
  email: string;
  password: string;
}

export interface ILoginByCodeRequestFormData {
  email: string;
  code: string;
}

export interface IAuthenticationCodeRequestFormData {
  email: string;
}

export interface ISignupRequestFormData {
  name: string;
  email: string;
  password: string;
  organization: string;
  role: string;
}

export interface ILoginResponseData {
  token: string;
  email?: string;
  role: USER_ROLE;
  firstName: string;
  lastName: string;
  companyName: string;
  permissions: transport.User.IPermission[];
  uiSettings: IUiSettings;
  isWorkingWithTN: boolean;
  isAgentOrExpeditor: boolean;
  isSupervisor: boolean;
  patronymic: string | null;
  // TODO: https://technonikol.atlassian.net/browse/T20S-6411
  firstLogin: boolean;
  userAgreementIsAccepted: boolean;
  id: string;
  emailStatus: string;
  authCodeExpireAt?: string;
}

export interface ISupervisorInfo {
  token: string;
  tokenExpiresIn: number;
  firstName: string;
  lastName: string;
  patronymic: string | null;
  isSupervisor: boolean;
}

export interface ILoginSupervisorResponse {
  userOnBehalf: ILoginResponseData;
  supervisor: ISupervisorInfo;
}

export interface IConfirmEmailResponse {
  email: string;
  // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: https://technonikol.atlassian.net/browse/T20S-5852
  new_email: string;
}

export interface IRegistrationForbiddenError {
  status: number;
  error: {
    // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: https://technonikol.atlassian.net/browse/T20S-5852
    error_fields: {
      email: string;
    };
  };
}

export interface IInviteStatusResponse {
  inviteStatus: 'REGISTER_USER' | 'CONFIRM_EMAIL' | 'CREATE_ORGANIZATION' | 'DONE' | 'UNEXPECTED' | 'TO_TMS';
}

export interface IInviteToken {
  token: string;
}

export interface IConfirmRegistrationResponse {
  inviteToken: string;
  organizationName: string;
  status: string;
  userLoginData: ILoginResponseData;
}

export interface ISignUpEmployee {
  fullName: string;
  phoneNumber: string;
  country: TnSupportedCountry;
  userKey: string;
}
