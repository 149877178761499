import { transport } from '@transport/proto';
import moment from 'moment';
import ITimezone = transport.ITimezone;
import { TnSupportedCurrency } from '@transport/ui-pipes';
import { ICommonSelectData } from 'libs/custom-controls/src/lib/common.interface';

const MS_IN_HOUR = 3600000;

export interface IOrderStoragePointFormValue {
  seqId?: number | null;
  storagePoint: string | null;
  address: string;
  comment: string | null;
  date: string | null;
  dateTo: string | null;
  arriveTimeFrom: string | null;
  arriveTimeTo: string | null;
  timeFrom?: string | null;
  timeTo?: string | null;
  timezone?: string | null;
  latitude?: string | null;
  contactPersons?: transport.IOrderStoragePointContactPerson[];
  longitude?: string | null;
  drivingDirectionsImg: string | null;
  loadingType: transport.ILoadingType[];
  attachedDocuments?: transport.Order.AttachedDocuments[];
  customFields?: {
        extId: string;
        label: string;
        value: string | null;
        options: {
          displayCargoOwner: boolean;
          displayCarrier: boolean;
          displayDriver: boolean;
          editableByCargoOwner: boolean;
        };
      }[]
    | null;
}

export interface IOrderCargoFormValue {
  id?: number | null;
  extCargoId?: string | null;
  docType?: transport.ICargoDocType | null;
  createdCargoId?: string | null;
  name?: string | null;
  size?: IOrderCargoSizeFormValue | null;
  loadingPlaceId?: number | null;
  price: IOrderCargoPriceFormValue;
}

export interface IOrderCargoPriceFormValue {
  amount: string;
  currency: ICommonSelectData;
}

export interface IOrderDeliveryUnitsFormValue {
  cargoId: number | null;
  deliveryPointId: number | null;
  size: IOrderCargoSizeFormValue;
}

export interface IOrderCargoSizeFormValue {
  weight?: number | null;
  volume?: number | null;
  packageType?: { type: string; name: string; id: string } | null;
  quantity?: number | null;
  setByWaybill?: boolean | null;
  dimensions?: {
    length?: number | null;
    width?: number | null;
    height?: number | null;
    dimensionsType?: string | null;
  };
}

export const setTimezone = (placeTimezone: ITimezone | null | undefined): string => {
  return Boolean(placeTimezone?.id) ? `(${placeTimezone?.timezone}) ${placeTimezone?.settlement}` : ``;
};

export const getTimezone = (timezone?: string): string => {
  return Boolean(timezone) ? timezone?.slice(1, timezone?.indexOf(')')) ?? '' : '';
};

export const addTimezoneOffset = (date: string | null, timezone: string) => {
  const separator = timezone.includes('-') ? '-' : '+';
  const offset = timezone.split(separator)[1] ?? '0';
  if (!Boolean(offset)) {
    throw Error('wrong timezone format!');
  }
  const formattedOffset = offset.length === 1 ? `0${offset}` : offset;
  return date ? date.replace('+00:00', `${separator}${formattedOffset}:00`) : null;
};

export const removeTimezoneOffset = (date: string, timezone: string) => {
  const separator = timezone.includes('-') ? '-' : '+';
  const offset = timezone.split(separator)[1];
  const time = new Date(date).getTime();
  return Boolean(offset)
    ? new Date(time + (separator === '-' ? -Number(offset) : Number(Number(offset))) * MS_IN_HOUR).toISOString()
    : new Date(time).toISOString();
};

const removeTimezoneOffsets = (
  { arriveDatetimeFrom, arriveDatetimeTo, storagePoint }: transport.Order.IStoragePoint,
  shouldUseLocalTime: boolean,
) => {
  const timezone = storagePoint?.timezone ? storagePoint?.timezone.timezone : '';
  if (!shouldUseLocalTime || !Boolean(timezone)) {
    return { arriveDatetimeFrom, arriveDatetimeTo };
  }

  return {
    arriveDatetimeFrom: Boolean(arriveDatetimeFrom) ? removeTimezoneOffset(arriveDatetimeFrom as string, timezone as string) : '',
    arriveDatetimeTo: Boolean(arriveDatetimeTo) ? removeTimezoneOffset(arriveDatetimeTo as string, timezone as string) : '',
  };
};

/**
 * TODO: simplify this function.
 */
// eslint-disable-next-line complexity, max-lines-per-function -- TODO: tech debt
export const createNewOrderStoragePointFormValue = (
  input: Partial<transport.Order.IStoragePoint> = {},
  clearDates = false,
  initDefaultLoadType = false,
  shouldUseLocalTime = false,
) => {
  const timeFormat = 'HH:mm';
  const storagePoint = input?.storagePoint?.id ?? null;
  const address = input?.storagePoint?.address ?? null;
  const comment = input?.comment ?? null;
  const contactPersons = input?.contactPersons ?? [];
  let loadingType = Boolean(input?.loadingType) ? input.loadingType : null;
  const latitude = input?.storagePoint?.latitude ?? null;
  const longitude = input?.storagePoint?.longitude ?? null;
  const { arriveDatetimeFrom, arriveDatetimeTo } = removeTimezoneOffsets(input, shouldUseLocalTime);
  const date = (!clearDates && moment(arriveDatetimeFrom).utc(!shouldUseLocalTime).format('YYYY-MM-DD')) || null;
  const dateTo = (!clearDates && moment(arriveDatetimeTo).utc(!shouldUseLocalTime).format('YYYY-MM-DD')) || null;
  const drivingDirectionsImg = input?.storagePoint?.drivingDirectionsImg ?? null;
  const arriveTimeFrom = (Boolean(arriveDatetimeFrom) && moment(arriveDatetimeFrom).utc(!shouldUseLocalTime).format(timeFormat)) || null;
  const arriveTimeTo = (Boolean(arriveDatetimeTo) && moment(arriveDatetimeTo).utc(!shouldUseLocalTime).format(timeFormat)) || null;
  const attachedDocuments = input?.attachedDocuments ?? [];
  const timezone = setTimezone(input.storagePoint?.timezone);

  if (initDefaultLoadType) {
    loadingType = input.storagePoint?.loadingTypes ? input.storagePoint?.loadingTypes[0] : null;
  }

  /**
   * TODO: fix bad typing
   */
  return {
    storagePoint,
    address,
    comment,
    contactPersons,
    date,
    dateTo,
    arriveTimeFrom,
    arriveTimeTo,
    timezone,
    latitude,
    longitude,
    drivingDirectionsImg,
    loadingType,
    attachedDocuments,
  } as IOrderStoragePointFormValue;
};

// eslint-disable-next-line
export const mapOrderStoragePointFormValueToInputDto = (formValue: IOrderStoragePointFormValue): transport.IOrderStoragePointInput => {
  const timeFrom = moment(`${formValue.date} ${formValue.arriveTimeFrom}`).isValid() ? formValue.arriveTimeFrom : '00:00';
  const timeTo = Boolean(formValue.arriveTimeTo) ? formValue.arriveTimeTo : '23:59';
  const arriveDatetimeFromUTC = formValue.date ? moment(`${formValue.date} ${timeFrom}`).utc(true).format('YYYY-MM-DDTHH:mm:ssZ') : null;
  const arriveDatetimeToUTC = moment(`${formValue.dateTo ?? formValue.date} ${timeTo}`).isValid()
    ? moment(`${formValue.dateTo ?? formValue.date} ${timeTo}`)
        .utc(true)
        .format('YYYY-MM-DDTHH:mm:ssZ')
    : null;
  const timezone = getTimezone(formValue.timezone as string) || '';
  return {
    contactPersons: formValue.contactPersons || [],
    storagePoint: formValue.storagePoint || null,
    arriveDatetimeFrom: addTimezoneOffset(arriveDatetimeFromUTC, timezone),
    // Field arriveTimeTo is optional
    arriveDatetimeTo: arriveDatetimeToUTC ? addTimezoneOffset(arriveDatetimeToUTC, timezone) : null,
    // TODO Отсылать массив
    loadingType: formValue.loadingType[0]?.id || null,
    comment: formValue.comment || null,
    seqId: formValue.seqId ?? null,
    customFields: formValue.customFields,
  };
};

export const mapOrderSizeFormValueToInputDto = (value: IOrderCargoSizeFormValue): transport.IOrderCargoSize => {
  return {
    length: value?.dimensions?.length,
    width: value?.dimensions?.width,
    height: value?.dimensions?.height,
    packageType: value?.packageType?.type ?? value?.packageType?.id,
    quantity: value?.quantity,
    volume: value?.volume || undefined,
    weight: value?.weight,
    setByWaybill: value?.setByWaybill,
    // dimensionsType: value?.dimensions?.dimensionsType,
  };
};

export const mapOrderCargoPriceFromValueToInputDto = (formValue: IOrderCargoPriceFormValue): transport.IOrderCargoPrice => {
  return {
    amount: formValue.amount || null,
    currency: formValue.currency.id as TnSupportedCurrency,
  };
};

export const mapOrderCargosFormValueToInputDto = (formValue: IOrderCargoFormValue): transport.IOrderCargo => {
  return {
    id: formValue.id,
    extCargoId: formValue.extCargoId?.trim() || null,
    docType: Boolean(formValue.docType?.id)
      ? {
          id: formValue.docType?.id,
          code: formValue.docType?.code,
          name: formValue.docType?.name,
        }
      : null,
    name: formValue.name,
    loadingPlaceId: formValue.loadingPlaceId,
    size: mapOrderSizeFormValueToInputDto(formValue?.size as IOrderCargoSizeFormValue),
    price: mapOrderCargoPriceFromValueToInputDto(formValue.price),
  };
};

export const mapOrderDeliveryUnitsFormValueToInputDto = (formValue: IOrderDeliveryUnitsFormValue): transport.IOrderDeliveryUnit => {
  return {
    ...formValue,
    size: mapOrderSizeFormValueToInputDto(formValue?.size),
  };
};

export const copyCargosToDeliveryUnits = (formValue: IOrderCargoFormValue, lastUnloadingPlaceId: number): IOrderDeliveryUnitsFormValue => {
  return {
    cargoId: formValue?.id ?? null,
    deliveryPointId: lastUnloadingPlaceId,
    size: {
      setByWaybill: true,
    },
  };
};
