<transport-tw-input-field
  [class]="inputFieldClass"
  [control]="formControl"
  [size]="size"
  [ngClass]="{'min-w-[104px]': leftAddon || rightAddon}"
  [errorsSize]="errorsSize"
  [label]="label"
  [labelAddition]="labelAddition"
  [caption]="caption"
  [captionClasses]="captionClasses"
  [showErrors]="showErrors"
  [required]="required"
  [errorToMessage]="errorToMessage"
>
  <transport-tw-input
    class="relative"
    [size]="size"
    [control]="formControl"
    [leftAddon]="leftAddon"
    [rightAddon]="rightAddon"
  >
    <ng-content></ng-content>
    <input
      tw-input
      autocomplete="off"
      [formControl]="$any(formControl)"
      [placeholder]="placeholder"
      [required]="required"
      [mask]="mask"
      [leadZeroDateTime]="leadZeroDateTime"
      [dropSpecialCharacters]="dropSpecialCharacters"
      [attr.disabled]="disabled ? true : null"
      [attr.data-test]="dataTest"
    />
  </transport-tw-input>
</transport-tw-input-field>
