import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Output,
} from '@angular/core';
import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { TnFileToUpload, trimmedStringValidator } from '@transport/ui-interfaces';
import { kbToMb, regExpConfig } from '@transport/ui-utils';
import moment from 'moment';

import { IUploadContractForm } from './table-item-upload-contract.interface';
import { TnToasterFacade, TOAST_TYPE } from '@transport/ui-store';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'transport-table-item-upload-contract',
  templateUrl: './table-item-upload-contract.component.html',
  styleUrls: ['./table-item-upload-contract.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnTableItemUploadContractComponent {
  @Output() public submitForm = new EventEmitter<IUploadContractForm>();


  public form = new FormGroup({
    contractId: new FormControl<string>('', Validators.compose([Validators.required, trimmedStringValidator])),
    dateStart: new FormControl<string | moment.Moment>('', Validators.compose([Validators.required, this.lessThanDateValidator({ end: true }).bind(this)])),
    dateEnd: new FormControl<string | moment.Moment>('', Validators.compose([this.lessThanDateValidator({ start: true }).bind(this)])),
    file: new FormControl<string | TnFileToUpload>(''),
  });

  public readonly regExpConf = regExpConfig;

  public readonly toDay = moment()
    .locale((window.navigator as any).userLanguage || window.navigator.language)
    .format('L');

  constructor(private readonly fb: FormBuilder,
              private readonly cdr: ChangeDetectorRef,
              private readonly translate: TranslateService,
              private readonly toasterFacade: TnToasterFacade) {}

  public onSubmit(event: Event) {
    event.preventDefault();
    this.form?.markAllAsTouched();
    this.form?.updateValueAndValidity();
    if (Boolean(this.form?.valid)) {
      this.form.value.contractId = this.form.value.contractId.trim();
      this.submitForm.emit(this.form.value as IUploadContractForm);
    }
  }

  public lessThanDateValidator(minDate: { start?: boolean; end?: boolean }): ValidatorFn {
    return (control: AbstractControl) => {
      if (!this.form || typeof control.value === 'string' ) {
        return null;
      }
      const dateStart = this.form.value.dateStart;
      const dateEnd = this.form.value.dateEnd;

      if (minDate.start && moment(dateStart).isValid() && moment(control.value).isValid()) {
        if (control.value.isBefore(dateStart)) {
          return {
            dateToLessThanDateFrom: true,
          };
        }
      }
      if (minDate.end && moment(dateEnd).isValid() && moment(control.value).isValid()) {
        if (control.value.isAfter(dateEnd)) {
          return {
            dateToLessThanDateTo: true,
          };
        }
      }
      return null;
    };
  }
  onErrorSize(err) {
    const errorText = this.translate.instant('shared.errors.maxFileSize', {
      value: kbToMb(err.maxSize),
    });
    this.toasterFacade.showMessage(errorText, TOAST_TYPE.ERROR);
  }

  public validationErrors(e: ValidationErrors | null) {
    if (e?.required) return this.translate.instant('shared.errors.required');
    if (e?.dateToLessThanDateFrom) return this.translate.instant('shared.errors.dateToLessThanDateFrom');
    return '';
  }
}
