import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

/**
 * Work with geocoder
 */
@Injectable({
  providedIn: 'root',
})
export class TnYamapsGeocodeHttpService {
  /**
   * Class constructor.
   * @param http - HttpClient angular service
   */
  constructor(private readonly http: HttpClient) {}

  /**
   * Request to yandex maps geocode api.
   * @param request - data string
   * @return Observable
   */
  public request(request) {
    const options = {
      params: new HttpParams().set('format', 'json').set('geocode', request),
    };
    return this.http.get('https://geocode-maps.yandex.ru/1.x/', options);
  }

  /**
   * Convert point to string
   * @param request - data string
   * @return Observable
   */
  public getByPoint(request) {
    return this.request(request);
  }

  /**
   * Convert string to point
   * @param request - data string
   * @return Observable
   */
  public getByAddress(request) {
    return this.request(request);
  }
}
