import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { GraphQLError } from 'graphql';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { TnConfirmationService } from '../../confirmation/confirmation.service';
import { TnToasterFacade, TOAST_TYPE } from '../../toaster/toaster.facade';
import { removeOrder, removeOrderFailure, removeOrderSuccess } from '../actions/orders-remove.actions';
import { TnOwnerOrdersService } from '../services/owner/owner-order.service';

@Injectable({
  providedIn: 'root',
})
export class TnOrderRemoveEffects {
  public operationFail = createEffect(() =>
    this.actions$.pipe(
      ofType(removeOrderFailure),
      tap(({ error }) => {
        //retrow error for handling by global error handler
        throw error;
      }),
    ),
  );

  public readonly removeOrder = createEffect(() =>
    this.actions$.pipe(
      ofType(removeOrder),
      switchMap(({ orderId, isDraft }) =>
        this.confirmation.openByPrefix('owner.order.confirm.remove').pipe(
          filter(result => Boolean(result)),
          switchMap(() => {
            return (isDraft ? this.ownerOrderService.deleteDraft(orderId ?? '') : this.ownerOrderService.cancelOrder(orderId ?? '')).pipe(
              map(() => {
                this.toastFacade.showMessage('owner.order.action.removeMsg.success', TOAST_TYPE.SUCCESS);
                return removeOrderSuccess();
              }),
              catchError((error: GraphQLError) => {
                return of(removeOrderFailure({ error }));
              }),
            );
          }),
        ),
      ),
    ),
  );

  constructor(
    public readonly actions$: Actions,
    private readonly confirmation: TnConfirmationService,
    private readonly ownerOrderService: TnOwnerOrdersService,
    private readonly toastFacade: TnToasterFacade,
  ) {}
}
