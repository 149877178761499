export enum FILE_TYPE {
  UNKNOWN = 'UNKNOWN',
  PDF = 'PDF',
  XLS = 'XLS',
  DOC = 'DOC',
}

export enum FILE_UPLOAD_STATUS {
  NOT_UPLOADED = 'NOT_UPLOADED',
  UPLOADING = 'UPLOADING',
  UPLOADED = 'UPLOADED',
  UPLOAD_ERROR = 'UPLOAD_ERROR',
  FILE_SIZE_ERROR = 'FILE_SIZE_ERROR',
}

export enum MIME_TYPE {
  XLXS = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  DOCX = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  DOC = 'application/msword',
}

export interface IFile {
  id?: string;
  extension: string;
  fullName: string;
  nameWithoutExtension: string;
  type: FILE_TYPE;
  downloadUrl?: string;
  fileData?: File;
  status: FILE_UPLOAD_STATUS;
  errorText: string;
  description?: string;
}
