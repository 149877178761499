/**
 * Base user permissions.
 */
export class TnUserPermissionsMap {
  public generalReference? = {
    read: false,
    manage: false,
  };

  public reconciliationAct? = {
    read: false,
    manage: false,
  };

  public serviceRegistry? = {
    read: false,
  };
}
