import { createAction, props } from '@ngrx/store';
import { transport } from '@transport/proto';
import { IVehicle } from '@transport/ui-interfaces';

export const removeTruck = createAction('Remove Truck from Detail Page', props<{ id: string }>());
export const removeTruckSuccess = createAction('Remove Truck from Detail Page Success');
export const removeTruckFailure = createAction('Remove Truck from Detail Page Failure', props<{ error: Error }>());

export const setVehicle = createAction('Set vehicle', props<{ vehicle: IVehicle | null }>());

export const setChangedTruckStatus = createAction('Set changed vehicle status', props<{ changedTruckStatus: Partial<IVehicle> | null }>());

export const searchAgentCarrier = createAction('Search Agent Carrier For Vehicle', props<{ term: string }>());

export const searchAgentCarrierSuccess = createAction(
  'Search Agent Carrier For Vehicle Success',
  props<{ agentCarriers: transport.IAgentCarrier[] }>(),
);

export const setSelectedAgentCarrier = createAction(
  'Set Selected Agent Carrier For Vehicle',
  props<{ agentCarriers: transport.IAgentCarrier[] }>(),
);
