import { Directive, ElementRef, EventEmitter, OnInit, Output, Renderer2 } from '@angular/core';

import { TnCleanerDirective } from './cleaner';

@Directive({
  selector: '[transportClickSelf]',
})
export class TnSelfDirective extends TnCleanerDirective implements OnInit {
  @Output() public readonly transportClickSelf = new EventEmitter();

  constructor(private readonly renderer: Renderer2, private readonly element: ElementRef) {
    super();
  }

  public ngOnInit() {
    this.unsubscribe = this.renderer.listen(this.element.nativeElement, 'click', (event: Event) => {
      TnCleanerDirective.handleEvents(this.eventOptions, event);
      if (event.target === this.element.nativeElement) {
        this.transportClickSelf.emit(event);
      }
    });
  }
}
