import { Injectable } from '@angular/core';
import { TnCurrentUserService } from '@marketplace/auth';
import { transport } from '@transport/proto';
import { BehaviorSubject, Subject, map, takeUntil } from 'rxjs';
import { VerificationStatuses } from '@transport/ui-kit';

@Injectable({
  providedIn: 'root'
})
export class VerificationsService {
  public verificationStatus = new BehaviorSubject<VerificationStatuses | undefined>(undefined);
  private updatedStatus$ = new Subject();

  public isRegistrationCompleted$ = this.currentUserService.profile$.pipe(map(profile => Boolean(profile.isRegistrationCompleted)));

  constructor(private currentUserService: TnCurrentUserService) {
    this.currentUserService.profile$.pipe(takeUntil(this.updatedStatus$)).subscribe(profile => {
      const uiSettings = this.currentUserService.currentUser?.uiSettings;

      if (this.checkIfNotStarted(profile.organization)) {
        this.verificationStatus.next(VerificationStatuses.notStarted);
        if (uiSettings?.['verificationClosed']) {
          this.setVerificationClosedSetting(false);
        }
      }
      if (this.checkIfChecking(profile.organization)) {
        this.verificationStatus.next(VerificationStatuses.checking);
        if (uiSettings?.['verificationClosed']) {
          this.setVerificationClosedSetting(false);
        }
      }
      if (this.checkIfDenied(profile.organization)) {
        this.verificationStatus.next(VerificationStatuses.denied);
        if (uiSettings?.['verificationClosed']) {
          this.setVerificationClosedSetting(false);
        }
      }
      if (this.checkIfOpened(profile.organization)) {
        this.verificationStatus.next(VerificationStatuses.opened);
      }
    });
  }

  markStatusAsRead() {
    this.updatedStatus$.next(null);
    this.setVerificationClosedSetting(true);
  }

  private setVerificationClosedSetting(value: boolean) {
    this.currentUserService.editUserUiSettings({verificationClosed: value});
  }

  private checkIfNotStarted(organization: transport.IAdminOrganization | null | undefined) {
    return organization?.accrStatus === 'new';
  }

  private checkIfChecking(organization: transport.IAdminOrganization | null | undefined) {
    return organization?.accrStatus === 'pending';
  }

  private checkIfDenied(organization: transport.IAdminOrganization | null | undefined) {
    return organization?.accrStatus === 'not_passed';
  }

  private checkIfOpened(organization: transport.IAdminOrganization | null | undefined) {
    return organization?.accrStatus === 'passed';
  }
}
