import { FocusMonitor } from '@angular/cdk/a11y';
import { ChangeDetectionStrategy, Component, DoCheck, ElementRef, forwardRef, HostBinding, Input, OnDestroy, Optional, Self } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { regExpConfig } from '@transport/ui-utils';

import { TnBaseMatInputDirective } from '../base-mat-input';

@Component({
  selector: 'transport-phone-input',
  templateUrl: './phone-input.component.html',
  providers: [
    {
      provide: MatFormFieldControl,
      // eslint-disable-next-line @angular-eslint/no-forward-ref -- TODO: tech debt
      useExisting: forwardRef(() => TnPhoneInputComponent),
    },
  ],
  styleUrls: ['./phone-input.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- TODO: tech debt
  changeDetection: ChangeDetectionStrategy.Default,
})
/* eslint-disable prettier/prettier -- prettier conflicts with eslint (brace style), eslint takes precedence here */
export class TnPhoneInputComponent
  extends TnBaseMatInputDirective
  implements ControlValueAccessor, OnDestroy, DoCheck, MatFormFieldControl<unknown> {
    /* eslint-enable prettier/prettier -- prettier conflicts with eslint in this case (brace style), eslint takes precedence here */
  public static nextId = 0;

  @Input() public dataTest = '';

  @HostBinding('attr.id')
  public id = `phone-number-input-${(TnPhoneInputComponent.nextId += 1)}`;

  public readonly mask = regExpConfig.ngxMask.phone.ruInput.mask;

  public readonly patterns = regExpConfig.ngxMask.phone.ruInput.patterns;

  public controlType = 'phone-number-input';

  private isFirstInput = true;

  constructor(public fm: FocusMonitor, public elRef: ElementRef<HTMLElement>, @Optional() @Self() public ngControl: NgControl) {
    super(fm, elRef, ngControl);
  }

  public onChange(event) {
    this.onTouched();
    this.isFirstInput = !Boolean(event);
    this.value = event === '+7' ? '' : event;
    this.propagateChange(this.value);
  }

  public onBlur(element: { value: string }) {
    if (element.value && this.getUnmaskedValue(element.value) === '+7') {
      element.value = '';
    }

    this.onTouched();
  }

  private getUnmaskedValue(value: string) {
    const regEx = /[(|)|-|_|-]/g;
    return value.replace(regEx, '');
  }

  public doOnChange(element: { value: string; setSelectionRange }) {
    const val = this.getUnmaskedValue(element.value);
    // If first inputed symbol is 7 or 8, we should ignore it
    if (this.isFirstInput) {
      const eightVal = '+78';
      const sevenVal = '+77';
      if (val === eightVal || val === sevenVal) {
        setTimeout(() => {
          const FOCUS_POSITION = 3;
          element.setSelectionRange(FOCUS_POSITION, FOCUS_POSITION);
        }, 0);
        this.onChange('+7');
        element.value = element.value.replace(/\+7\([87]/g, '+7(_');
        return;
      }
    }
    this.onChange(val);
  }
}
