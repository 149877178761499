/**
 * Translate service carrier dictionary: Russian.
 */
export const RU = {
  carrier: {
    orders: {
      table: {
        indicator: {
          certificateTooltip: {
            title: 'Ранее заявка была вам назначена',
            message: 'Ее невыполнение может понизить ваш рейтинг',
          },
          allocationTypeIconTip: {
            preassignedMessage: 'Назначена по рейтингу',
            auctionMessage: 'Аукцион',
            biddingMessage: 'Торги',
          },
          tender: 'Тендер',
        },
        status: {
          processing: 'Оформление',
          onWay: 'В рейсе',
          canceled: 'Отменен',
          canceledByOwner: 'Отменен заказчиком',
          canceledByCarrier: 'Отказ перевозчика',
          completed: 'Завершен',
          pending: 'Ожидает обработки',
          free: 'Опубликована',
          actionRequire: 'Требуется действие',
        },
        tooltip: 'Требуется принять заказ',
      },
    },
    drivers: {
      statuses: {
        new: 'Требуется проверка',
        passed: 'Одобрено',
        pending: 'На проверке',
        notPassed: 'Отказано',
      },
    },
    order: {
      title: {
        simple: 'Заявка',
        auction: 'Аукцион',
        bidding: 'Торги',
        draft: 'Черновик',
      },
      newDesign: 'Новый дизайн',
      errors: {
        vehicleRequired: 'Необходимо выбрать транспорт',
        driverRequired: 'Необходимо выбрать водителя',
        sameDrivers: 'Основной и сменный водители не должны совпадать',
        waybillRequired:
          'Для завершения заявки на перевозку введите данные о доставке. Войдите в блок “Документы“, откройте контекстное меню типа документа “Транспортная накладная“. Нажмите кнопку “Открыть“. Укажите данные Транспортной накладной ',
      },
      confirm: {
        removeDocument: {
          title: 'Удаление документа',
          content: 'Вы уверены, что хотите удалить документ?',
          confirm: 'Да, удалить',
          cancel: 'Нет',
        },
      },
      delayedPublication: {
        modal: {
          title: 'Сохранение черновика заявки',
          close: 'Нет',
          apply: 'Да, продолжить',
          message: 'План по публикации заявки {{delayedDateTime}} будет отменён. Продолжить?',
        },
      },
      action: {
        reservationTime: {
          title: 'Внимание',
          content:
            'Вы собираетесь забронировать заявку с ограниченным временем на оформление ({{time}} ЧЧ:ММ). Вы уверены, что хотите ее забронировать?',
          confirm: 'Да, забронировать',
          cancel: 'Отменить',
        },
        accept: {
          name: 'Забрать заявку',
          confirmation: {
            title: 'Забрать заявку',
            content: 'Вы уверены, что хотите забрать заявку?',
            confirm: 'Да, забрать',
            cancel: 'Нет',
          },
          success: 'Заявка успешно назначена',
          error: 'При назначении заявки произошла ошибка',
        },
        cancel: {
          name: 'Отказаться',
          confirmation: {
            title: 'Отказаться от заказа',
            content: 'Вы уверены, что хотите отказаться от заказа?',
            confirm: 'Да, отказаться',
            cancel: 'Нет',
          },
          success: 'Вы успешно отказались от заявки',
          error: 'При отказе от заявки произошла ошибка',
          confirmationTn: {
            title: 'Отказ от перевозки',
            content:
              'Для того, чтобы отказаться от перевозки на данном этапе, необходимо подписать Соглашение о расторжении заявки-договора.\n\nВы уверены, что хотите отказаться от данной перевозки?',
            confirm: 'Да, отказаться',
            cancel: 'Нет',
          },
        },
        complete: {
          name: 'Завершить заявку',
          confirmation: {
            title: 'Завершить заявку',
            content: 'Вы уверены, что хотите завершить выполнение заказа?',
            confirm: 'Да, завершить',
            cancel: 'Нет',
          },
          success: 'Заявка успешно завершена',
          error: 'При завершении заявки произошла ошибка',
        },
        close: {
          name: 'Закрыть',
        },
        backToTheList: {
          name: 'Назад к списку',
        },
        saveInfoForContract: {
          name: 'Отправить данные для формирования договора',
          success: 'Данные отправлены',
          error: 'При отправке данных произошла ошибка',
        },
        letsGo: {
          name: 'Поехали!',
          confirmation: {
            title: 'Приступить к заказу',
            content: 'Вы уверены, что хотите приступить к заказу?',
            confirm: 'Да, приступить',
            cancel: 'Нет',
          },
          success: 'Заявка в работе',
          error: 'При старте заявки произошла ошибка',
        },
        addSignedContract: {
          name: 'Загрузить подписанный договор',
          invalid: 'Разрешенные форматы:',
        },
        startTnCryptoSigning: 'Подписать TN Crypto',
        addWaybill: {
          success: 'Документ успешно загружен',
          error: 'При загрузке документа произошла ошибка',
        },
        editWaybill: {
          success: 'Данные сохранены',
          error: 'При сохранении произошла ошибка',
        },
      },
      driverCard: {
        application: 'Моб. приложение',
        phone: 'Телефон',
        passport: 'Паспорт',
        passportSeries: 'Серия паспорта',
        passportNumber: 'Номер паспорта',
        passportPlace: 'Кем выдан',
        iin: 'ИИН',
        inn: 'ИНН',
        license: 'Вод.\u00A0удостов.',
        licenseSeries: 'Серия вод. удостоверения',
        licenseNumber: 'Номер вод. удостоверения',
        licensePlace: 'Кем выдан',
        secondDriver: 'Сменный водитель',
      },
      warnings: {
        orderScan: 'Скачайте форму, подпишите и загрузите подписанный договор, чтобы начать работу',
        digitalSignature: 'Приложите электронную подпись к договору, чтобы начать работать',
        tnCryptoCargoOwnerSigningWait: 'Ожидаем подписание договора со стороны грузоотправителя',
        underSigning: 'Отказаться нельзя, идет подписание',
        tnCryptoCarrierSigningError: 'Произошла ошибка, подпишите повторно или обратитесь в службу поддержки',
        tnCryptoOwnerSigningError: 'Ошибка подписи договора у грузоотправителя',
        tnCryptoCarrierSignRequired: 'Грузоотправитель подписал договор. Подпишите договор, чтобы продолжить работу',

        tnCryptoCarrierSignatureChecking: 'Ожидайте, идет проверка подписи',
        tnCryptoContractSigned: 'Договор успешно подписан',

        terminationAgreementOwnerSigningWait: 'Ожидаем подписания Соглашения о расторжении заявки-договора со стороны грузоотправителя',
        terminationAgreementCarrierSignRequired:
          'Грузоотправитель подписал Соглашения о расторжении заявки-договора. Подпишите Соглашения о расторжении заявки-договора, чтобы продолжить работу',
        terminationAgreementSigned: 'Соглашения о расторжении заявки-договора подписано. Заявка отменена',
        terminationAgreementCarrierSigningError: 'Произошла ошибка, подпишите повторно или обратитесь в службу поддержки',
        insuranceRequired: 'Эта перевозка требует страхования',
        driverAndVehicleChangeLimit: 'На смену ТС/водителя осталось',
        driverAndVehicleChangeTimeout: 'Смена не была произведена вовремя. В течение минуты заявка будет отменена',
        shipmentOnAgreedDate: 'Отгрузка нужна обязательно в согласованную дату',
        isPreviouslyAssigned: {
          title: 'Свободная заявка',
          text: 'Ранее заявка была вам назначена. Ее невыполнение может понизить ваш рейтинг',
        },
      },
      field: {
        organization: 'Организация',
        fio: 'Контактное лицо',
        phoneNumber: 'Телефон',
        loadingPlace: 'Откуда',
        loadingPlaceAddress: 'Адрес места погрузки',
        loadingPlaceComment: 'Комментарий к погрузке',
        unloadingPlace: 'Куда',
        unloadingPlaceAddress: 'Адрес места доставки',
        unloadingPlaceComment: 'Комментарий к доставке',
        loadingDate: 'Дата погрузки',
        cargoType: 'Груз',
        weight: 'Вес (т)',
        volume: 'Объем (м³)',
        price: 'Цена, руб',
        total: 'Итого',
        withVat: 'НДС',
        withoutVat: 'без НДС',
        withVatLong: 'В т.ч. НДС',
        error: 'Ошибка',
        comment: 'Комментарий',
        carrier: 'Перевозчик',
        orderDetails: 'Детали заказа',
        truckInfo: 'Данные автомобиля',
        trailerInfo: 'Данные прицепа',
        driverInfo: 'Данные водителя',
        bindingType: {
          title: 'Тип заявки',
          market: 'Рыночная',
          tms: 'Партнёрская',
        },
        hasInsurance:
          'Обратите внимание! По указанию заказчика, грузоперевозка будет застрахована. Процесс страхования начнется после нажатия на кнопку "Поехали!"',
        hasInsuranceShort: 'Обратите внимание! По указанию заказчика, грузоперевозка будет застрахована.',
        consignee: 'Грузополучатель',
        consignor: 'Грузоотправитель',
        insurance: {
          dataPreparedMessage: 'Страховой полис будет проверен и выслан по электронной почте после перевода заявки в статус "В исполнении"',
        },
      },
      section: {
        mainInfo: {
          name: 'Основная информация',
        },
        transport: {
          name: 'Транспорт',
          country: 'Страна принадлежности',
          semiTrailer: 'Полуприцеп',
          trailer: 'Прицеп',
          body: 'Кузов',
          addTrailer: 'Добавить прицеп',
          assignedTruck: 'Назначьте транспорт',
          truckToBlackList: 'Транспортное средство в черном списке',
          truckToBlackListInfo: 'ТС заблокировано этой компанией, выберите другое',
        },
        driver: {
          passportIssued: '{{passportSeries}} {{passportNumber}}, Выдан {{passportPlace}}, {{passportDate}}',
          name: 'Водитель',
          driverToBlackListInfo: 'Водитель заблокирован компанией, выберите другого',
        },
        owner: {
          name: 'Заказчик перевозки',
        },
        lot: {
          vatIncluded: 'включая НДС',
          withoutVat: 'без НДС',
          remainingTimeFormat: 'ЧЧ:ММ',
          remainingTimeFormatWithSeconds: 'ЧЧ:ММ:CC',
          field: {
            startPrice: 'Стартовая цена',
            minStepPrice: 'Шаг торгов',
            currentBet: 'Текущая ставка',
            yourBet: 'Ваша ставка',
            auctionEndDatetime: 'Дата и время окончания аукциона',
            bidEndDatetime: 'Дата и время окончания приема ставок',
            bet: 'Сделать предложение',
          },
          action: {
            makeABet: {
              name: 'Сделать ставку',
              confirmation: {
                title: 'Сделать ставку',
                content: 'Вы уверены, что хотите cделать ставку?',
                confirm: 'Да',
                cancel: 'Нет',
              },
              error: {
                title: 'Ограничение на участие в аукционе',
                confirm: 'Понятно',
              },
            },
          },
        },
        auction: {
          name: 'Аукцион',
          minStep: 'Размер шага',
          steps: 'Ставок',
          firstBetStatus: 'Сделайте первую ставку!',
          winningStatus: 'Вы выигрываете',
          wonStatus: 'Вы выиграли',
          loosingStatus: 'Вы проигрываете',
          lostStatus: 'Вы проиграли',
          field: {
            lastUserBet: {
              name: 'Ваша последняя ставка',
            },
            remainingTime: {
              name: 'Прием ставок завершается через',
            },
            bet: {
              error: {
                multipleOfStep: 'Изменение цены должно быть кратно шагу торгов',
                shouldNotBeMore: 'Ставка не должна превышать {{value}}',
                required: 'Поле обязательно к заполнению',
                shouldMoreZero: 'Ставка должна быть больше 0',
                minimumBet: 'Достигнута минимальная цена аукциона',
              },
            },
          },
        },
        auctionDetails: {
          status: 'Торги завершены!',
          wonStatus: 'Вы победили в торгах!',
          showDetails: 'Показать детали',
        },
        bid: {
          title: 'Торги на повышение',
          makeYourBet: 'Сделайте свою ставку!',
          waitForBidsEnd: 'Ожидайте решения грузовладельца',
          bidsAreFinished: 'Торги завершены',
          betsEndsAfter: 'Прием ставок завершается через',
          betsEnd: 'Прием ставок завершен',
          youWonBids: 'Вы победили в торгах на повышение!',
          noWinners: 'Победителей не выявлено',
          youLostBids: 'Вы проиграли в торгах на повышение',
          shouldMoreZero: 'Ставка должна быть больше 0',
          minimumBet: 'Минимальный размер ставки равен {{value}} ₽',
          expiredToastMessage: 'Прием ставок окончен',
        },
        priceChange: {
          warning:
            'Грузовладелец внёс изменение в стоимость перевозки. Предыдущая стоимость составляла {{amount}} {{currency}} {{withVat}} согласуйте или отклоните данное изменение.',
          successToastMessage: 'Изменение цены отправлено грузоперевозчику',
        },
        commentChange: {
          successToastMessage: 'Комментарий изменен успешно',
        },
        containerChange: {
          successToastMessage: 'Номер контейнера и пломбы изменены успешно',
        },
      },
      problemsFilter: {
        title: 'Тип события',
        list: {
          all: 'Все',
          existProblems: 'Есть проблемные события',
          notProblems: 'Нет проблемных событий',
          placeholder: 'Выберите тип события',
        },
      },
    },
    agentCarrierFilter: {
      title: 'Фильтры по перевозчикам',
      form: {
        carrier: 'Перевозчик',
        name: 'Наименование',
        inn: 'ИНН',
        kpp: 'КПП',
      },
    },
    driverFilter: {
      title: 'Фильтры по водителю',
      form: {
        fio: 'ФИО',
        phone: 'Телефон',
      },
    },
    truckFilter: {
      title: 'Фильтры по автомобилю',
      form: {
        vehicleType: 'Тип автомобиля',
        vehicleId: 'Гос. номер автомобиля',
        vehicleMake: 'Марка автомобиля',
        trailerId: 'Гос. номер прицепа',
        bodyType: 'Тип кузова',
        bodySubtype: 'Подтип кузова',
        bodyCapacity: 'Грузоподъемность (т)',
        bodyVolume: 'Объем (м³)',
        bodyTypes: 'Тип погрузки',
        agentCarrierOwnership: 'Тип собственности',
      },
    },
    directory: {
      vehicle: {
        title: 'Транспорт',
        trailer: 'Фура',
        truck: 'Грузовик',
        coupler: 'Сцепка',
        own: 'Собственное ТС',
        attracted: 'Привлеченное ТС',
        make: 'Марка',
        notOwn: 'Привлеченное ТС',
        leasing: 'Лизинг',
        rent: 'Аренда',
        all: 'Все',
        agentCarrier: 'Привлеченное ТС',
        trailerType: {
          full: 'Прицеп',
          half: 'Полуприцеп',
        },
        table: {
          config: {
            backToList: 'Назад к списку',
            allVehicles: 'Все ТС',
            active: 'Активные',
            archived: 'Архив',
            cancel: 'Отмена',
            save: 'Сохранить',
            delete: 'Удалить',
            copy: 'Копировать',
            add: 'Добавить',
            edit: 'Редактировать',
          },
          column: {
            id: 'Ид.',
            vehicleType: 'Тип ТС',
            vehicleMake: 'Марка ТС',
            bodyType: 'Тип кузова',
            bodySubtype: 'Подтип кузова',
            bodyCapacity: 'Грузоподъемность кузова',
            bodyLoadingTypes: 'Тип погрузки кузова',
            bodyVolume: 'Объем кузова',
            trailer: 'Прицеп',
            trailerId: 'Гос. номер прицепа',
            trailerLoadingTypes: 'Тип погрузки прицепа',
            trailerCapacity: 'Грузоподъемность прицепа',
            trailerVolume: 'Объем прицепа',
            vehicleId: 'Гос. номер',
            stsNum: 'СТС номер',
            vin: 'VIN номер',
            subtype: 'Подтип кузова',
            capacity: 'Грузоподъёмность (т)',
            volume: 'Объём (м³)',
            loadingTypes: 'Тип погрузки',
            verificationStatus: 'Статус проверки',
            isArchived: 'Статус',
            actived: 'Активно',
            archived: 'В архиве',
            blacklist: 'Статус блокировки',
            ownVehicle: 'Тип собственности',
          },
          value: {
            agentCarrierOwnership: {
              own: 'Собственное ТС',
              attracted: 'Привлеченное ТС',
            },
          },
        },
        details: {
          title: 'Справочник транспортных средств',
          formLabels: {
            country: 'Страна',
            isUnarchived: 'Активна',
            isArchived: 'Неактивна',
            status: 'Статус записи:',
            archieve: 'Архивировать',
            dearchieve: 'Разархивировать',
            typeVehicle: 'Тип ТС',
            markVehicle: 'Марка ТС',
            gosNum: 'Госномер',
            stsNum: 'Номер СТС',
            vin: 'Номер VIN',
            bodyId: 'Госномер',
            bodyType: 'Тип кузова',
            bodySubtype: 'Подтип кузова',
            bodyVolume: 'Объём (м³)',
            bodyCapacity: 'Грузоподъёмность (т)',
            bodyLoadingTypes: 'Тип загрузки',
            trailerId: 'Госномер',
            trailerType: 'Тип кузова',
            trailerSubtype: 'Подтип кузова',
            trailerVolume: 'Объём (м³)',
            trailerCapacity: 'Грузоподъёмность (т)',
            trailerLoadingTypes: 'Тип загрузки',
            isOrganizationOwner: 'ТС в собственности у организации',
            typeProperty: 'Тип собственности',
            ownershipType: 'Тип владения',
            agentCarrier: 'Введите название организации',
          },
          validation: {
            notEmpty: 'Значение не может быть пустым',
            notPatternSts: 'Кроме цифр необходимо использовать только заглавные символы',
            notPattern: 'Значение должно соответствовать маске',
            mustLength17:
              'Номер должен состоять из 17-ти разрешённых символов: 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, A, B, C, D, E, F, G, H, J, K, L, M, N, P, R, S, T, U, V, W, X, Y, Z',
            wrongPatternGosNum: 'Гос. номер не соответствует шаблону: А123АА12 или А123АА123',
            wrongPatternTrailerNum: 'Гос. номер не соответствует шаблону: АА123456',
            min1: 'Значение не может быть меньше 1',
            max99: 'Значение не может быть больше 99',
            notEmptyVolumeBody: 'Поле обязательно к заполнению. Поставьте 0, если у кузова нет объёма',
            notEmptyVolumeTrailer: 'Поле обязательно к заполнению. Поставьте 0, если у прицепа нет объёма',
          },
          modals: {
            archive: {
              title: 'Удаление транспортного средства',
              content: 'Вы уверены, что хотите удалить это ТС?',
              confirm: 'Да, удалить',
              cancel: 'Нет',
            },
            cancel: {
              title: 'Отмена действия',
              content: 'При переходе на другую страницу данные не сохранятся. Вы уверены, что хотите перейти?',
              confirm: 'Да, отменить',
              cancel: 'Нет',
            },
          },
          checkVehicleWarning: 'На рынок допускаются только проверенные транспортные средства',
          identificator: {
            title: {
              sts: 'Где искать номер СТС',
              vin: 'Где искать VIN',
              gosNumber: 'Где искать госномер',
              gosTrailerNumber: 'Где искать госномер на прицеп и полуприцеп',
            },
            description: {
              sts: 'Номер СТС написан в самом низу документа красными цифрами.',
              vin: 'В свидетельстве о регистрации найдите поле «Идентификационный номер (VIN)» — это 17-значный код. Так же VIN автомобиля можно найти в паспорте ТС и полисе ОСАГО.',
              gosNumber: 'Госномер вы найдёте в Свидетельстве о регистрации (СТС) в строке «Регистрационный знак».',
              gosTrailerNumber: 'Госномер вы найдёте в Свидетельстве о регистрации (СТС) в строке «Регистрационный знак».',
            },
          },
        },
        errors: {
          scorringInProgress: 'Транспортное средство находится на проверке. Редактирование невозможно',
        },
      },
      marketplaceDriver: {
        title: {
          create: 'Создать нового водителя',
          edit: 'Редактировать водителя',
        },
        messages: {
          deleteSuccess: 'Водитель успешно удален',
          deleteError: 'Ошибка удаления водителя',
          createSuccess: 'Водитель успешно создан',
          createAndSubmitSuccess: 'Водитель успешно создан и отправлен на проверку',
          createError: 'Ошибка создания водителя',
          editSuccess: 'Водитель успешно сохранен',
          editAndSubmitSuccess: 'Водитель успешно сохранен и отправлен на проверку',
          editError: 'Ошибка сохранения водителя',
          fetchError: 'Ошибка получения профиля водителя',
          archiveSuccess: 'Водитель успешно отправлен в архив',
          archiveError: 'Не удалось добавить водителя в архив',
          restoreSuccess: 'Водитель успешно восстановлен',
          restoreError: 'Не удалось восстановить водителя',
          restoreExistDriverError: 'Водитель с такими документами уже есть в списке',
        },
        blockTitle: {
          common: 'Общая информация',
          passport: 'Паспорт',
          licence: 'Водительское удостоверение',
        },
        form: {
          surname: 'Фамилия',
          name: 'Имя',
          patronymic: 'Отчество',
          birthday: 'Дата рождения',
          phone: 'Телефон',
          addPhone: 'Дополнительный телефон',
          passportTitle: 'Паспорт',
          country: 'Гражданство',
          iin: 'ИИН',
          passportSeries: 'Серия',
          passportNumber: 'Номер',
          passportPlace: 'Кем выдан',
          inn: 'ИНН',
          licenceTitle: 'Водительское удостоверение',
          licenceSeries: 'Серия ВУ',
          licenceNumber: 'Номер ВУ',
          licensePlace: 'Кем выдано',
          dateGetting: 'Дата выдачи',
          accreditation: {
            title: 'Аккредитация Рынком грузов и транспорта',
            status: 'Требуется проверка',
            thirdPartyDescription: 'Использование привлеченного водителя невозможно на открытом рынке',
            description: 'Данная проверка требуется для назначения водителя на заявки открытого рынка',
            passportMainPage: 'Разворот паспорта с фотографией',
            passportRegistration: 'Разворот паспорта с пропиской',
          },
        },
        errors: {
          mask: 'Неверный формат. Допустимая длина {{ value }}',
        },
        buttons: {
          save: 'Сохранить',
          saveAndAccredit: 'Сохранить и Аккредитовать',
          cancel: 'Отмена',
          create: 'Создать',
        },
        confirm: {
          title: 'Удаление водителя',
          content: 'Вы действительно хотите удалить водителя?',
          confirm: 'Удалить',
          cancel: 'Отмена',
        },
      },

      marketplaceVehicle: {
        form: {
          accreditation: {
            title: 'Аккредитация Рынком грузов и транспорта',
            description: 'Данная проверка требуется для назначения ТС на заявки открытого рынка',
            thirdPartyDescription: 'Использование привлеченного транспортного средства невозможно на открытом рынке',
            statuses: {
              new: 'Требуется проверка',
              passed: 'Одобрено',
              pending: 'На проверке',
              notPassed: 'Отказано',
            },
            vehicleTypes: {
              truck: 'Одиночное ТС',
              trailer: 'Тягач',
              coupler: 'Кузов',
            },
            ownershipTypes: {
              leasing: 'Договор лизинга',
              rent: 'Договор аренды',
              own: 'Собственное',
              free_use: 'Безвозмездное пользование',
              joint_property_of_spouses: 'Cовместная собственность супругов',
              third_party: 'Привлеченное',
            },
            stsFirstSide: 'СТС: первая сторона',
            stsSecondSide: 'СТС: вторая сторона',
            trailerTypes: {
              semitrailer: 'Полуприцеп',
              trailer: 'Прицеп',
            },
          },
        },
      },

      vehiclePublications: {
        priceColumn: {
          priceForDistance: '\u20BD/км',
          noPrice: {
            header: 'Не указано',
            details: 'Открыт к предложениям',
          },
        },
      },

      driver: {
        title: 'Водитель',
        form: {
          passportData: 'Паспортные данные',
          surname: 'Фамилия в документе',
          name: 'Имя',
          patronymic: 'Отчество',
          passportSeries: 'Серия паспорта',
          passportNumber: 'Номер паспорта',
          placeGetting: 'Кем выдан',
          dateGetting: 'Дата выдачи',
          licenceData: 'Данные водительского удостоверения',
          licenceSeries: 'Серия',
          licenceNumber: 'Номер',
          info: 'Общая информация',
          birthday: 'Дата рождения',
          inn: 'ИНН',
          phone: 'Телефон',
          addPhone: 'Дополнительный телефон',
          application: {
            installed: 'МП УСТАНОВЛЕНО',
            notInstalled: 'МП НЕ УСТАНОВЛЕНО',
          },
          invite: 'Пригласить',
          inviteAgain: 'Пригласить снова',
          agentCarrier: 'Введите название организации',
        },
        errors: {
          inn: 'Неверный формат. Допустимо 12 цифр',
          documentNumber: 'Неверный формат. Допустимо 6 цифр',
          documentSeries: 'Неверный формат. Допустимо 4 знака, буквы и цифры',
          passportSeries: 'Неверный формат. Допустимо 4 цифры',
          surnameMatch: 'Не совпадает с фамилией в водительском удостоверении',
          nameMatch: 'Не совпадает с именем в водительском удостоверении',
          patronymicMatch: 'Не совпадает с отчеством в водительском удостоверении',
          licenceSurnameMatch: 'Не совпадает с фамилией в паспортных данных',
          licenceNameMatch: 'Не совпадает с именем в паспортных данных',
          licencePatronymicMatch: 'Не совпадает с отчеством в паспортных данных',
          scorringInProgress: 'Водитель находится на проверке. Редактирование невозможно',
        },
        confirm: {
          remove: {
            title: 'Удаление сведений о водителе',
            content: 'Вы уверены, что хотите удалить сведения о водителе?',
            confirm: 'Да, удалить',
            cancel: 'Нет',
          },
        },
      },

      agentCarriers: {
        table: {
          config: {
            backToList: 'Назад к списку',
            allVehicles: 'Все перевозчики',
            active: 'Активные',
            archived: 'Архив',
            cancel: 'Отмена',
            save: 'Сохранить',
            delete: 'Удалить',
            copy: 'Копировать',
            add: 'Добавить',
            edit: 'Редактировать',
          },
          column: {
            id: 'Ид.',
            name: 'Наименование',
            carrier: 'Перевозчик',
            inn: 'ИНН',
            kpp: 'КПП',
            vehicleCount: 'Количество ТС',
          },
          tooltip: {
            archivedTitle: 'Перенесено в архив',
          },
        },
        details: {
          title: 'Создать перевозчика',
          form: {
            carrier: 'Перевозчик',
            inn: 'ИНН',
            enterInn: 'Введите ИНН',
            kpp: 'КПП',
            enterKpp: 'Введите КПП',
            legalForm: 'Тип организации',
            companyName: 'Наименование организации',
            legalAddress: 'Юридический адрес',
            postalAddress: 'Фактический адрес',
            rate: 'Ставка НДС',
            sameAddress: 'Фактический адрес такой же, как юридический',
            searchBtn: 'Найти',
            searchOrganizationInfo: 'Введите реквизиты и нажмите “Найти”',
            selfEmployedSuccessVerification: '{{inn}} является плательщиком налога на профессиональный доход',
          },
          errors: {
            getAgentCarrier: 'Произошла ошибка при загрузке перевозчика',
            addAgentCarrier: 'Произошла ошибка при создании перевозчика',
            editAgentCarrier: 'Произошла ошибка при редактировании перевозчика',
            removeAgentCarrier: 'Произошла ошибка при удалении перевозчика',
            kppError: 'Допустимый формат 9 цифр',
            carrierOrganizationEqualsToOwn: 'Вы не можете указать собственную организацию как перевозчика-партнера',
          },
          success: {
            save: 'Перевозчик успешно сохранен',
            remove: 'Перевозчик успешно добавлен в архив',
          },
          modals: {
            archive: {
              title: 'Удаление перевозчика',
              content: 'Вы уверены, что хотите удалить этого перевозчика?',
              confirm: 'Да, удалить',
              cancel: 'Нет',
            },
          },
          tooltip: {
            failedVerification: 'Перевозчик не прошел проверку',
            addedArchive: 'Вы добавили перевозчика в архив',
          },
          agentCarrierName: '{{ value }} (ИНН {{ inn }} )',
        },
        confirm: {
          remove: {
            title: 'Удаление сведений о перевозчике',
            content: 'Вы уверены, что хотите удалить сведения о перевозчике?',
            confirm: 'Да, удалить',
            cancel: 'Нет',
          },
        },
      },
    },
    request: {
      error: {
        takeCanceledAuction: 'Вы не можете взять в работу эту заявку, т.к. отказались от неё во время аукциона',
        notUniqueVin: 'ТС с таким госномером/СТС/VIN уже существует',
      },
    },
    tracking: {
      notifications: {
        trackingNotAvailable:
          'Отслеживание данной заявки невозможно, так как при создании одно или несколько мест погрузки/доставки не имело координат',
        disableTracking: 'Отслеживание  заявок отключено грузоотправителем',
      },
    },
    registration: {
      greeting: 'Добро пожаловать в систему Транспорт 2! Для того, чтобы видеть всю информацию и брать заявки в работу - ',
      greetingLink: 'дополните информацию в профиле',
      clickByOrderWarning: 'Чтобы получить доступ к деталям заявок - дополните информацию в профиле',
    },
    report: {
      auctionControlReports: {
        pageTitle: 'Контроль аукциона',
        pageDescription: {
          paragraph1: 'Отчет формируется за две недели с 28 по 14 число, или с 14 по 28 число',
        },
        table: {
          columns: {
            id: '',
            productionDivisionName: 'Завод',
            routeName: 'Очередь',
            queueName: 'Показатель',
            endDate: 'Дата окончания блокировки',
            auctionWonCount: 'Выигранных аукционов',
            auctionCanceledCount: 'Отказы от перевозки аукционных заявок',
            cancelPercent: '% отказов',
          },
          empty: 'Сейчас отчетов нет. Они появятся, когда вы поучаствуете в аукционах',
        },
      },
      auctionControlReportDetails: {
        pageTitle: 'Аукционы ({{count}})',
        table: {
          columns: {
            orderId: 'Ид.',
            externalNo: 'Внешний номер',
            createdDate: 'Дата заявки на транспорт',
            assignedDate: 'Дата назначения по аукциону',
            canceledDate: 'Дата отказа',
          },
        },
      },
      carriersRating: {
        menuItemTitle: 'Рейтинг ТН',
        pageTitle: 'Рейтинг ТН',
        infoMessageTitle: 'Как формируется отчёт?',
        pageDescription: {
          paragraph1: 'Отчет отображает текущий рейтинг перевозчиков. Перерасчет рейтинга осуществляется каждый месяц с 5ого на 6е число',
          paragraph2:
            'С 1ого по 5е число нового месяца для просмотра текущего расчетного рейтинга в настройке периода отчета необходимо установить предыдущий месяц',
          paragraph3: 'Подробно о расчете рейтинга написано в руководстве пользователя',
        },
        value: {
          a0: 'Кандидат на отчисление',
          a1: '1 категория',
          a2: '2 категория',
          a3: '3 категория',
        },
        table: {
          columns: {
            department: 'Завод',
            deliveryArea: 'Направление',
            indicator: 'Показатель',
            currentRating: 'Текущий рейтинг',
            calculatedRating: 'Расчетный рейтинг',
            totalDeliveriesCompleted: 'Выполнено перевозок всего',
            assignedDeliveryRequests: 'Назначено перевозок',
            assignedDeliveryRequestsCompleted: 'Выполнено назначенных перевозок',
            vacantDeliveryRequestsCompleted: 'Выполнено перевозок из свободных заявок',
            assignedDeliveryRequestsRefused: 'Отказы от назначенных перевозок',
            deliveryContractsRefused: 'Отказы от заявок-договоров',
            carrierFaultCanceledShipments: 'Срыв подачи ТС на загрузку',
            vehicleSearchAverageTime: 'Среднее время поиска автомобиля, чч:мм',
            refusedRequestsPercentage: 'Общий процент отказа от заявок, %',
            carrierFaultDelayedShipmentsPercentage: 'Процент опозданий ТС на загрузку, %',
            vacantRequestsDeliveriesPercentage: 'Процент выполнения заявок из свободных, %',
          },
          filter: {
            title: 'Фильтр',
            form: {
              period: 'Период',
              department: 'Завод (Место погрузки)',
            },
          },
        },
      },
      carriersRatingDetails: {
        title: 'Заявки на транспорт ({{count}})',
        deliveryRequestTypeTipTitle: 'Происхождение заявки',
        requestType: {
          assigned: 'Назначена по рейтингу',
          auction: 'Аукцион',
          vacant: 'Свободная',
        },
        deliveryFaults: {
          deliveryContractRefused: 'Отказ от договора',
          carrierFaultCanceledShipment: 'Срыв срока подачи ТС',
          carrierFaultDelayedShipment: 'Опоздание ТС',
        },
        table: {
          columns: {
            deliveryRequestNumber: '№ заявки на транспорт',
            deliveryRequestDateUtc: 'Дата заявки на транспорт',
            deliveryContractNumber: '№ заявки-договора',
            deliveryContractDateUtc: 'Дата заявки-договора',
            deliveryCompleted: 'Выполнена',
            deliveryFaults: 'Нарушения',
          },
        },
      },
    },
    servicesRegistry: {
      act: {
        title: 'Создать отчет для ОКС',
        subtitle: 'Объединение заявок-договоров в Акт оказанных услуг',
        viewTitle: 'Акт оказанных услуг № {{id}}',
        toolbar: {
          downloadToExcel: 'Выгрузить в XLS',
          downloadToOKS: 'Выгрузить отчет в ОКС',
          downloadEditToOKS: 'Выгрузить изменения в ОКС',
          addCompletedOrders: 'Добавить заявки-договоры',
          downloadDocuments: 'Скачать документы',
        },
        info: {
          title: 'Информация по отчету',
          documentNumbersTitle: 'Номера документов',
          detailsTitle: 'Детали',
          headOfOrganization: 'Руководитель, ФИО',
          chiefAccountant: 'Главный бухгалтер, ФИО',
          serviceName: 'Наименование услуги перевозчика',
          reportDate: 'Дата документов',
          reportNo: 'Номер акта',
          agentCarrierBillNo: 'Номер счета экспедитора',
          actualCarrierInvoiceNo: {
            own: 'Номер счета-фактуры',
            attracted: 'Номер счета-фактуры перевыставленных услуг',
          },
          agentBillNo: 'Номер счета',
          agentInvoiceNo: 'Номер счета-ф на агентское вознаграждение',
          totalSum: 'Сумма акта',
          defaultServiceName: 'Транспортные услуги',
        },
        orders: {
          title: 'Выбранные заявки-договоры',
          table: {
            columns: {
              id: 'ID',
              externalNo: 'Внешний номер',
              createDate: 'Дата',
              unloadingPlace: 'Адрес',
              carrier: 'Перевозчик',
              vat: 'Ставка НДС',
              driver: 'Водитель',
              contractPrice: 'Цена заявки-договора, \u20BD',
              invoiceNumber: 'Номер счета-фактуры',
              invoiceDate: 'Дата счета-фактуры',
              invoiceFile: 'С-Ф',
              actNumber: 'Номер акта',
              actDate: 'Дата акта',
              actFile: 'Акт',
              total: 'Всего:',
            },
          },
        },
        messages: {
          success:
            'Данные по акту отправлены в ОКС. Продолжайте работать в системе. Мы сообщим вам, как будут готовы печатные формы документов',
          commonErrorTitle: 'Обнаружены следующие ошибки проверьте их и повторите попытку',
          documentSuccess: 'Документ успешно загружен',
        },
        warningDialog: {
          title: 'Несовпадение дат',
          reportDateLessUnloading:
            'Дата документов {{date}} раньше плановой даты доставки {{unloadingDate}}. Номер заявки-договора {{orderNo}}\n\nПродолжить?',
          reportMonthMoreUnloading:
            'Месяц даты документов {{date}} не совпадает с месяцем плановой даты доставки {{unloadingDate}}. Номер заявки-договора {{orderNo}}\n\nПродолжить?',
          confirm: 'Продолжить',
          cancel: 'Отмена',
        },
      },
    },
    serviceRegistry: {
      completedOrders: {
        title: 'Заявки-договоры',
        table: {
          column: {
            id: 'Номер',
            externalNo: 'Внешний номер',
            createDate: 'Дата заявки договора',
            loadingDate: 'Дата погрузки',
            contractNumber: 'Номер заявки-договора',
            loadingPlace: 'Место погрузки',
            address: 'Адрес',
            vehicleId: 'ТС',
            driver: 'Водитель',
            contractPrice: 'Цена заявки-договора, \u20BD',
          },
        },
        filters: {
          title: 'Фильтр',
          period: {
            from: 'Период с',
            to: 'Период по',
          },
          storagePoints: 'Места погрузки',
          contract: 'Договор',
          carriers: 'Перевозчики',
          all: 'Все',
        },
        actions: {
          unloadToXls: 'Выгрузить в XLS',
          addToReport: 'Добавить в отчет',
          createReport: 'Создать отчет',
        },
        selected: {
          one: 'выбрана',
          fromTwoToFive: 'выбрано',
          other: 'выбрано',
        },
        countContracts: {
          zero: 'нет заявок-договоров',
          one: '{{value}} заявка-договор',
          fromTwoToFive: '{{value}} заявки-договора',
          other: '{{value}} заявок-договоров',
        },
        addToReport: {
          title: 'Добавить заявки в отчет',
          description: 'Выберите отчет из списка или воспользуйтесь поиском',
          success: 'Заявки-договоры успешно добавлены в отчет',
          empty: 'Отсутствуют акты со статусом Черновик',
        },
        messages: {
          create: {
            type: 'Для создания отчета укажите перевозчиков или Тип собственности ТС (чей транспорт) в Фильтрах',
            contract: 'Для создания отчета укажите Договор в Фильтрах',
          },
          add: {
            type: 'Создание отчета невозможно. Заявки-договора имеют разный Тип собственности ТС',
            contract: 'Создание отчета невозможно. Заявки-договора имеют разный договор',
          },
        },
      },
      completedOrdersReports: {
        title: 'Отчеты об оказанных услугах',
        reportsCounter: {
          zero: 'нет отчетов',
          one: '{{value}} отчет',
          fromTwoToFive: '{{value}} отчета',
          other: '{{value}} отчетов',
        },
        table: {
          column: {
            agentBillNo: 'Номер счета',
            reportDate: 'Дата счета',
            reportNo: 'Номер акта',
            notes: 'Замечания',
            status: 'Статус акта',
            paymentDocuments: 'Платежный документ',
            totalSum: 'Сумма, руб',
            documents: 'Документы',
          },
        },
        filter: {
          month: 'Месяц',
          year: 'Год',
          agentBillNo: 'Номер счета',
          reportNo: 'Номер акта',
          hasNotes: 'Есть замечания',
          statuses: {
            sentToOks: 'Отправлен в ОКС',
            draft: 'Принято в ОКС',
            readyToPay: 'Готов к оплате',
            acceptedForAccounting: 'Проведен в ОКС',
            paid: 'Оплачен',
            placeholder: 'Статус акта',
            all: 'Все',
          },
        },
      },
    },
  },
};
