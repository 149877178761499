import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, OnDestroy, TemplateRef } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'transport-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnTooltipComponent implements OnDestroy {
  @HostBinding('style.zoom') public get getZoom() {
    return this.visibility === 'visible' ? 1 : null;
  }

  @HostBinding('aria-hidden') public get getAriaHidden() {
    return true;
  }

  @HostBinding('class') public get getClass() {
    if (this.isPopup) {
      return 'transport-tooltip transport-tooltip-light';
    }
    const baseClass = 'transport-tooltip transport-tooltip-dark';
    switch (this.position) {
      case 'before':
        return `${baseClass} right-corner`;
      case 'after':
        return `${baseClass} left-corner`;
      case 'below':
        return `${baseClass} top-corner`;
      case 'above':
        return `${baseClass} bottom-corner`;
      default:
        return baseClass;
    }
  }

  private readonly onHideSubject: Subject<void> = new Subject();

  public visibility = 'initial';

  public templateRef: TemplateRef<unknown> | null = null;

  public context: Record<string, unknown> | null = null;

  public position = 'below';

  public isPopup = false;

  constructor(private readonly changeDetectorRef: ChangeDetectorRef) {}

  public show(templateRef: TemplateRef<unknown>, context: Record<string, unknown> | null) {
    this.templateRef = templateRef ?? null;
    this.context = context;
    this.visibility = 'visible';
    this.markForCheck();
  }

  public hide() {
    this.visibility = 'hidden';
    this.markForCheck();
    this.onHideSubject.next();
    this.templateRef = null;
    this.context = null;
  }

  public isVisible() {
    return this.visibility === 'visible';
  }

  public afterHidden(): Observable<void> {
    return this.onHideSubject.asObservable();
  }

  private markForCheck(): void {
    this.changeDetectorRef.markForCheck();
  }

  public ngOnDestroy() {
    this.onHideSubject.complete();
  }
}
