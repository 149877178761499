import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { TranslateService } from '@ngx-translate/core';
import { GRAPHQL_QUERY, OWNER_MUTATIONS, SHARED_MUTATION } from '@transport/gql';
import { transport } from '@transport/proto';
import {
  auctionProlongationParametersFromDto,
  BLACK_LIST_TYPE,
  ICargoType,
  IDefaultProfileParams,
  IInsuranceData,
  IInsuranceDialogData,
  ILoadingPlaceForm,
  insuranceOutputDataFromDto,
  IOrder,
  IOrderProposal,
  IOrdersFilter,
  IPlace,
  ORDER_STATUS,
  PROPOSAL_DECISION,
  STATE_RELATIONSHIP,
  STATUS,
  TnOrderDocumentTypes,
  TOrderAttachmentForSave,
  TPlace,
  USER_ROLE,
} from '@transport/ui-interfaces';
import { TnBlockedTrailerByNomenclaturePipe } from '@transport/ui-pipes';
import { removeEmptyProperties } from '@transport/ui-utils';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { TnFeatureAccessService } from '../../../feature-access/feature-access.service';
import { TnGqlClientOwnerMutationsService } from '../../../gql-client-owner/graphql-client-owner-mutations.service';
import { TnGqlClientOwnerQueriesService } from '../../../gql-client-owner/graphql-client-owner-queries.service';
import { TnGqlClientSharedService } from '../../../gql-client-shared/graphql-client-shared.service';
import { IOwnerOrderPageDataSelects } from '../../interfaces/owner-order-page-data.interface';
import { TnCommonOrdersService } from '../common/common-order.service';
import ITender = transport.ITender;
import { HttpClient } from '@angular/common/http';

import { TnCurrentUserFacade } from '../../../current-user';
import { TnHttpHandlersService } from '../../../feature-access/http-handlers.service';

/**
 * Orders service for cargo owner.
 */
@Injectable({
  providedIn: 'root',
})
export class TnOwnerOrdersService extends TnCommonOrdersService {
  constructor(
    private readonly gqlQuery: TnGqlClientOwnerQueriesService,
    private readonly gqlMutation: TnGqlClientOwnerMutationsService,
    protected graphQLShared: TnGqlClientSharedService,
    protected translateService: TranslateService,
    protected featureAccess: TnFeatureAccessService,
    private readonly blockedTrailerPipe: TnBlockedTrailerByNomenclaturePipe,
    public readonly userFacade: TnCurrentUserFacade,
    private readonly http: HttpClient,
    private readonly handlers: TnHttpHandlersService,
  ) {
    super(translateService, featureAccess, graphQLShared);
  }

  public getOrders(listenX?: number, withSpinner?: boolean, first?: number, offset?: number, filter?: IOrdersFilter, sorter?: Sort) {
    return this.gqlQuery.queryOrders(listenX, withSpinner, first, offset, filter, sorter).pipe(
      map(data => {
        return {
          list: data.orders.map(item => ({
            ...item,
            loadingPlace: (item.loadingPlaces ?? [])[0]?.storagePoint ?? null,
            unloadingPlace: (item.unloadingPlaces ?? [])[0]?.storagePoint ?? null,
            lifeTimeExpiredMs: this.getOrderLifeTimeExpiredMs(item),
            lotTimeExpiredMs: Boolean(item.lot?.viewEndDatetime) ? new Date(item.lot?.viewEndDatetime ?? '').getTime() - Date.now() : 0,
          })),
          totalCount: data.totalCount ?? 0,
        };
      }),
    );
  }

  public myOrders() {
    return this.gqlQuery.queryMyOrdersForDashboard();
  }

  public getOrdersAmount() {
    return this.gqlQuery.queryOrdersAmountForDashboard();
  }

  public getDrafts(
    listenX?: number,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
    options?: Record<string, unknown>,
    sorter?: Sort,
  ) {
    return this.gqlQuery.queryDrafts(listenX, withSpinner, first, offset, options, sorter).pipe(
      map(data => {
        const orders = data.drafts.map(draft => {
          return {
            id: draft.id,
            ...draft.order,
            loadingPlace: (draft.loadingPlaces ?? [])[0]?.storagePoint ?? null,
            unloadingPlace: (draft.unloadingPlaces ?? [])[0]?.storagePoint ?? null,
            loadingDate: draft.loadingDate,
            unloadingDate: draft.unloadingDate,
            loadingTypes: this.getLoadingTypesList(draft.loadingPlaces, draft.unloadingPlaces).join(', '),
            vehicleRequirements: {
              ...draft.vehicleRequirements,
              loadingTypes: this.getLoadingTypesList(draft.loadingPlaces, draft.unloadingPlaces).map(type => ({ name: type })),
            },
          };
        });
        return {
          list: orders as IOrder[],
          totalCount: data.totalCount ?? 0,
        };
      }),
    );
  }

  public getDraft(id: string) {
    return this.gqlQuery.queryDraft(id).pipe(
      map(data => {
        const order = {
          ...data.draft.order,
          loadingPlaces: data.draft.loadingPlaces,
          unloadingPlaces: data.draft.unloadingPlaces,
          cargos: data.draft.cargos,
          vehicleRequirements: {
            ...data.draft.vehicleRequirements,
            loadingTypes: this.getLoadingTypesList(data.draft.loadingPlaces, data.draft.unloadingPlaces, true),
          },
          cargoPlaceRequirements: data.draft.cargoPlaceRequirements,
          extraServices: data.draft.extraServices,
        };
        const parsedOrder = this.parseDraftData(order as IOrder);
        return {
          id: data.draft.id,
          ...parsedOrder,
        };
      }),
    );
  }

  public deleteDraft(draftId) {
    return this.gqlMutation.deleteDraft(draftId);
  }

  /**
   * Get order by id
   */
  public getOrder(id: string, isCopyMode = false) {
    return this.gqlQuery.queryOrder(id, isCopyMode).pipe(map(data => this.parseOrderData(data.order)));
  }

  public contactPersonsAtLoadingPlace(id: string) {
    return this.gqlQuery.contactPersonsAtLoadingPlace(id);
  }

  public contactPersonsAtUnloadingPlace(id: string) {
    return this.gqlQuery.contactPersonsAtUnloadingPlace(id);
  }

  /**
   * Get auction order by id
   */
  public getAuctionOrder(id: string, isCopyMode = false) {
    return this.gqlQuery.queryAuctionOrder(id, isCopyMode).pipe(
      map(data => {
        return this.parseOrderData(data.auctionOrder);
      }),
    );
  }

  public getOwnerPlacesList(typePoint: TPlace, settlementFias?: string | null) {
    return this.gqlQuery
      .queryStoragePoints(1, true, 0, 0, { typePoint, selectMode: true, settlementFias: settlementFias })
      .pipe(map(data => data.storagePoints));
  }

  /**
   * Get loading places list
   */
  public getOwnerLoadingPlacesList(loadingSettlementFias?: string | null) {
    return this.gqlQuery
      .queryStoragePoints(1, true, 0, 0, { typePoint: 'LOADING', selectMode: true, settlementFias: loadingSettlementFias })
      .pipe(map(data => data.storagePoints));
  }

  /**
   * Get place
   * @param id place
   * @param type place
   */
  public getStoragePoint(id: string, typePoint: TPlace, listenX = 1, withSpinner?: boolean) {
    const req = this.gqlQuery.queryStoragePoint(id, typePoint, listenX, withSpinner);
    return req.pipe(map(data => data.storagePoint));
  }

  /**
   * Get unloading places list
   */
  public getOwnerUnloadingPlacesList(courseFias?: string | null) {
    return this.gqlQuery
      .queryStoragePoints(1, true, 0, 0, { typePoint: 'UNLOADING', selectMode: true, settlementFias: courseFias })
      .pipe(map(data => data.storagePoints));
  }

  /**
   * Get carriers list
   */
  public getOwnerCarriersList(state?: STATE_RELATIONSHIP) {
    return this.gqlQuery.queryCarriers(1, true, 0, 0, { selectMode: true, state }).pipe(map(data => data.carrierOrganizations));
  }

  /**
   * Get cargo body types with sublasses
   */
  public getBodyTypes(): Observable<transport.Vehicle.Body.IType[]> {
    return this.gqlQuery.queryBodyTypes(1, true);
  }

  /**
   * Get loading types list
   * @returns [Observable<ILoadingTypeType[]>] list of loading types
   */
  public getOwnerLoadingTypesList(): Observable<transport.ILoadingType[]> {
    return this.gqlQuery.queryLoadingTypesList(1, true);
  }

  /**
   * Get cargo types list
   */
  public getOwnerCargoTypesList() {
    return this.gqlQuery.queryCargoTypes(1, true, 0, 0, { selectMode: true }).pipe(map(data => data.cargoTypes));
  }

  /**
   * Get cargo type by id
   * @param cargoTypeId Id cargo type
   * @returns Observable with cargo type
   */
  public getOwnerCargoType(cargoTypeId: string, withSpinner?: boolean) {
    return this.gqlQuery.queryCargoType(cargoTypeId, 1, withSpinner).pipe(map(data => data.cargoType));
  }

  /**
   * Add order request
   * @returns [Observable<any>] id of new order
   */
  public addOrder(order: transport.IAddOrderInput) {
    const input = removeEmptyProperties({ ...order }) as transport.IAddOrderInput;
    return this.gqlMutation.addOrder(input);
  }

  /**
   * Add auction request
   * @returns [Observable<any>] id of new order
   */
  public addAuction(value: transport.IAddAuctionOrderInput) {
    const input = {
      order: removeEmptyProperties(value.order),
      lot: removeEmptyProperties(value.lot),
      isMarket: value.isMarket,
    } as transport.IAddAuctionOrderInput;
    return this.gqlMutation.addAuction(input);
  }

  /**
   * Assing Carrier request
   * @returns [Observable<any>] status
   */
  public assignCarrier(carrierId: string, orderId: string) {
    return this.gqlMutation.assignCarrier(carrierId, orderId);
  }

  public startOrderTrading(orderId: string, isMarket: boolean, lot) {
    return this.gqlMutation.startOrderTrading(orderId, isMarket, lot);
  }

  public updateOrder(input) {
    return this.gqlMutation.updateOrder(input);
  }

  /**
   * Cancel order request
   * @returns [Observable<any>] status
   */
  public cancelOrder(orderId: string) {
    return this.gqlMutation.cancelOrder(orderId);
  }

  /**
   * get available packaging types list
   */
  public getOwnerPackagingTypesList() {
    return this.gqlQuery.queryPackagingTypes();
  }

  private getSelectDataFromOrder(order: IOrder) {
    const loadingTypes = [
      ...(order.loadingPlaces?.map(item => item.loadingType) as transport.ILoadingType[]),
      ...(order.unloadingPlaces?.map(item => item.loadingType) as transport.ILoadingType[]),
      ...((order.vehicleRequirements?.loadingTypes as transport.ILoadingType[]) || []),
    ] as transport.ILoadingType[];

    return {
      loadingPlaces: order.loadingPlaces?.map(item => item.storagePoint) as IPlace[],
      unloadingPlaces: order.unloadingPlaces?.map(item => item.storagePoint) as IPlace[],
      carrierGroups: (Boolean(order.carrierGroup) ? [order.carrierGroup] : []) as transport.Carrier.ICarrierGroup[],
      cargoTypes: (Boolean(order.cargoType) ? [order.cargoType] : []) as Partial<ICargoType>[],
      loadingTypes,
      paymentTypes: (Boolean(order.paymentType) ? [order.paymentType] : []) as transport.IPaymentType[],
    };
  }

  /**
   * Groups data for order details dialog.
   */
  public groupOrderDetailsDialogData(order: IOrder | null, tender?: ITender | null): Observable<IOwnerOrderPageDataSelects> {
    if (order === null) {
      return forkJoin({
        loadingPlaces: this.getOwnerLoadingPlacesList(tender?.loadingSettlementFias),
        unloadingPlaces: this.getOwnerUnloadingPlacesList(tender?.courseFias),
        carriers: this.getOwnerCarriersList(),
        carrierGroups: this.getCarrierGroups(),
        cargoTypes: this.getOwnerCargoTypesList(),
        cargoBodyTypes: this.getBodyTypes(),
        loadingTypes: this.getOwnerLoadingTypesList(),
        packagingTypes: this.getOwnerPackagingTypesList(),
        paymentTypes: this.getPaymentTypes(),
        documentTypes: this.getOrderDocumentTypes(),
      });
    }

    // we can avoid extra requests, take data for selects from order ir view mode
    // also some data saved in order could be removed form data base, so we tale it from order
    return forkJoin({
      cargoBodyTypes: this.getBodyTypes(),
      packagingTypes: this.getOwnerPackagingTypesList(),
      documentTypes: this.getOrderDocumentTypes(),
      carriers: this.getOwnerCarriersList(),
    }).pipe(
      switchMap(data => {
        return of({
          ...this.getSelectDataFromOrder(order),
          cargoBodyTypes: data.cargoBodyTypes,
          packagingTypes: data.packagingTypes,
          documentTypes: data.documentTypes,
          carriers: data.carriers,
        });
      }),
    );
  }

  private getPaymentTypes(): Observable<transport.IPaymentType[]> {
    return this.gqlQuery.queryPaymentTypes();
  }

  public getIsuranceContracts(withSpinner?: boolean) {
    return this.gqlQuery.queryInsuranceContracts(withSpinner).pipe(
      map(value => {
        return value.myInsuranceContracts;
      }),
    );
  }

  public getInsuranceInfo(contract: string): Observable<{
    cargoClasses: { id: string; name: string }[];
    cargoKinds: { id: string; name: string }[];
  }> {
    return this.gqlQuery.queryInsuranceInfo(contract, false).pipe(map(value => this.getInsuranceInfoMap(value)));
  }

  public getInsuranceCost(insuranceContractId, declaredPrice, withSpinner?: boolean) {
    return this.gqlQuery.queryInsuranceCost(insuranceContractId, declaredPrice, false).pipe(map(value => value.insuranceCost));
  }

  public getUserOrganization(withSpinner?: boolean) {
    return this.gqlQuery.queryOrganization(withSpinner).pipe(map(data => data.profile.organization));
  }

  public isOrderDeletable(order: IOrder) {
    return order.status !== ORDER_STATUS.COMPLETED && order.status !== ORDER_STATUS.CANCELED;
  }

  public getDefaultProfileOrganizationParams(): Observable<IDefaultProfileParams> {
    return this.gqlQuery.queryOrganizationDefaultParams(true).pipe(map(data => data.profile.organization));
  }

  public getOrganizationsServices(): Observable<transport.IExtraService[]> {
    return this.gqlQuery.getOrganizationsServices(true);
  }

  public getCarrierGroups(): Observable<transport.Carrier.ICarrierGroup[]> {
    return this.gqlQuery.getCarrierGroupsShort().pipe(
      map(data => {
        return Boolean(data?.myCarrierGroups) ? data.myCarrierGroups : [];
      }),
    );
  }

  public getDefaultAuctionParameters(): Observable<transport.IAuctionProlongationParameters> {
    return this.gqlQuery.queryOrganizationDefaultAuctionParameters(true).pipe(
      map(data => {
        return auctionProlongationParametersFromDto(data.profile?.organization?.auctionParameters);
      }),
    );
  }

  public getOrderDocumentTypes(): Observable<TnOrderDocumentTypes> {
    return super.getOrderDocumentTypes(USER_ROLE.OWNER);
  }

  public attachDocuments(documents: TOrderAttachmentForSave[], orderId: string): Observable<transport.IOrderUploadedDocument[]> {
    return super.attachDocuments(documents, orderId, USER_ROLE.OWNER);
  }

  public editDocuments(
    documents: transport.IEditOrderUploadedDocumentInput[],
    orderId: string,
  ): Observable<transport.IOrderUploadedDocument[]> {
    return super.editDocuments(documents, orderId, USER_ROLE.OWNER);
  }

  public removeDocument(documentId: string, orderId: string) {
    return super.removeDocument(documentId, orderId, USER_ROLE.OWNER);
  }

  public blackListVehicle(order?: IOrder): { info: transport.Vehicle.IBlackListVehicle[]; textType: BLACK_LIST_TYPE } | null {
    const truckBlackList = order?.vehicle?.blackListInfo ?? [];
    const orderNomenclature = order?.cargoNomenclatureTypes;
    const trailerBlackList = this.blockedTrailerPipe.transform(order?.vehicleTrailer?.blackListInfo ?? [], orderNomenclature);
    const isTruckBlackListExist = Boolean(truckBlackList.length);

    return isTruckBlackListExist || Boolean((trailerBlackList ?? []).length > 0)
      ? {
          textType: this.translate.instant(
            `shared.blackListType.${isTruckBlackListExist ? BLACK_LIST_TYPE.TRUCK : BLACK_LIST_TYPE.TRAILER}`,
          ),
          info: (truckBlackList ?? []).concat(trailerBlackList ?? []),
        }
      : null;
  }

  public getInsuranceData(params: IInsuranceDialogData): Observable<IInsuranceData> {
    const { currentValue, contractId } = params;
    const { cargoTypeId, declaredPrice } = currentValue;

    return forkJoin({
      cargoType: this.getOwnerCargoType(cargoTypeId, false),
      insuranceInfo: this.getInsuranceInfo(contractId),
      userOrganization: this.getUserOrganization(false),
      insurancePrice: Boolean(currentValue.declaredPrice) ? this.getInsuranceCost(contractId, declaredPrice) : of(''),
    }).pipe(
      switchMap(result => {
        const { cargoType, userOrganization, insurancePrice, insuranceInfo } = result;
        const { cargoKinds, cargoClasses } = insuranceInfo;

        return of({
          cargoType,
          userOrganization,
          insurancePrice,
          select: {
            cargoKinds,
            cargoClasses,
          },
        });
      }),
    );
  }

  public getCustomerOrganizations() {
    return this.gqlQuery.queryOrganization().pipe(
      switchMap(data => {
        const subOrgs =
          data.profile.organization.isCompanyGroup ?? false
            ? this.gqlQuery.querySubOrganizations().pipe(map(dto => dto.subOrganizations))
            : of([]);
        return subOrgs.pipe(map(res => [data.profile.organization, ...res]));
      }),
    );
  }

  private parseOrderData(order: IOrder) {
    // TODO: backend returns a string (that should be parsed as a json) instead on an object via api - this is not acceptable;
    let insurance;
    if (Boolean(order.insurance)) {
      insurance = {
        ...order.insurance,
        data: Boolean(order?.insurance?.data) ? insuranceOutputDataFromDto(JSON.parse(order?.insurance?.data ?? '')) : {},
      };
    }

    return {
      ...order,
      insurance,
    };
  }

  private parseDraftData(order: IOrder) {
    // TODO: Workaround for mapping draft data. Must be changed when reworking draft detail page
    if (order.cargos?.length) {
      order.cargoType = {
        id: order.cargos[0].cargoId,
        name: order.cargos[0].name,
      };
      order.volume = Number(order.cargos[0].size?.volume);
      order.weight = Number(order.cargos[0].size?.weight);
    }

    return order;
  }

  public getTender(id: string) {
    return this.gqlQuery
      .query<{ tender: transport.ITender }>(GRAPHQL_QUERY.owner.getTender, 1, { id }, false)
      .pipe(map(result => result.tender));
  }

  public sendOrderDocumentToEdms(docId: string): Observable<any> {
    return this.gqlMutation.mutate(SHARED_MUTATION.sendDocumentToEdms, { docId });
  }

  public getStatusAccreditation(): Observable<STATUS | null> {
    return this.userFacade.profile$.pipe(
      switchMap(profile => {
        if (Boolean(profile.organization?.accrStatus)) {
          return of(profile);
        }
        return this.gqlQuery.getOwnerStatusAccreditation().pipe(
          map(status => ({
            organization: { accrStatus: status },
          })),
        );
      }),
      map(profile => {
        return profile.organization?.accrStatus ?? null;
      }),
    );
  }

  public fetchStatusAccreditation(): Observable<STATUS | null> {
    return this.gqlQuery.getOwnerStatusAccreditation().pipe(map(status => status ?? null));
  }

  public editLoadingUnloadingPlace(input: any): Observable<any> {
    return this.gqlMutation.mutate(OWNER_MUTATIONS.editLoadingUnloadingPlace, { input });
  }

  public createOrderChangeProposal(
    proposal: Pick<IOrderProposal<keyof IOrder>, 'objectId' | 'objectType' | 'changes'>,
  ): Observable<unknown> {
    const headers = this.handlers.getAuthHeaders();
    return this.http.put(this.handlers.getEndpointUrl(`/p/edms/change_proposals`), proposal, { headers });
  }

  public getOrderChangeProposal(orderId: string) {
    const headers = this.handlers.getAuthHeaders();
    return this.http
      .get<{
        items: IOrderProposal<keyof IOrder>[];
        totalCount: number;
      }>(this.handlers.getEndpointUrl(`/p/edms/change_proposals/order/${orderId}`), { headers })
      .pipe(map(result => result.items));
  }

  public decideOrderChangeProposal(proposal: IOrderProposal<keyof IOrder>, decision: PROPOSAL_DECISION): Observable<unknown> {
    const headers = this.handlers.getAuthHeaders();
    const { id, changes } = proposal;
    return this.http.patch(this.handlers.getEndpointUrl(`/p/edms/change_proposals/${id}`), { changes, decision }, { headers });
  }

  public updateContainerInfo(data: { orderId: string; number: string; seal: string }) {
    const headers = this.handlers.getAuthHeaders();
    return this.http.post(
      this.handlers.getEndpointUrl(`p/orders/${data.orderId}:set_container`),
      {
        containerNumber: data.number,
        containerSeal: data.seal,
      },
      { headers },
    );
  }
}
