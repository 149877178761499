import { RouterReducerState } from '@ngrx/router-store';
import { Action, ActionReducer, MetaReducer } from '@ngrx/store';

import { IAdminState } from '../admin/admin.state';
import { IConfirmationState } from '../confirmation/confirmation.state';
import { IConstantsState } from '../constants/constants.state';
import { resetUserAction } from '../current-user/current-user.actions';
import { IDadataState } from '../dadata/dadata.state';
import { IFeatureAccessState } from '../feature-access/feature-access.state';
import { IFiasState } from '../fias/fias.state';
import { IFormState } from '../form/form.state';
import { IPassportState } from '../passport/passport.state';
import { ITimezoneState } from '../time-zone/time-zone.state';
import { ITrackingState } from '../tracking/tracking.state';

/**
 * Clears state except featureAccess on a respective action.
 */
function clearState(reducer: ActionReducer<Partial<ITnState>>) {
  return function (state: Partial<ITnState> | undefined, action: Action) {
    const clearedState = { ...state, featureAccess: state?.featureAccess };

    return action.type === resetUserAction.type ? reducer(clearedState, action) : reducer(state, action);
  };
}

export interface ITnState {
  router: RouterReducerState;
  tracking: ITrackingState;
  passport: IPassportState;
  featureAccess: IFeatureAccessState;
  constants: IConstantsState;
  fias: IFiasState;
  dadata: IDadataState;
  timezone: ITimezoneState;
  confirmation: IConfirmationState;
  admin: IAdminState;
  form: IFormState;
}

export const metaReducers: MetaReducer<Partial<ITnState>>[] = [clearState];
