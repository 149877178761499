import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { transport } from '@transport/proto';
import { TnSupportedCurrency } from '@transport/ui-pipes';

export interface IPaymentSettingsForm {
  vat: transport.IOrganizationVatType | null;
  paymentType: transport.IPaymentType | null;
  paymentPeriod: string | null;
  currency: TnSupportedCurrency | null;
  withoutVat: boolean | null;
}

const maxPaymentPeriodLength = 5000;

export function createPaymentSettingsForm() {
  return new FormGroup<IPaymentSettingsForm>({
    currency: new FormControl<TnSupportedCurrency | null>(null, Validators.required),
    withoutVat: new FormControl<boolean | null>(null),
    vat: new FormControl<transport.IOrganizationVatType | null>(null, Validators.required),
    paymentType: new FormControl<transport.IPaymentType | null>(null),
    paymentPeriod: new FormControl<string | null>(null, Validators.maxLength(maxPaymentPeriodLength)),
  });
}

export function mapPaymentInputValueToInner<T extends IPaymentSettingsForm>(value: Partial<T>) {
  return {
    currency: Boolean(value?.currency) ? value?.currency : null,
    vat: Boolean(value?.vat) ? value?.vat : null,
    paymentType: Boolean(value?.paymentType) ? value?.paymentType : null,
    paymentPeriod: Boolean(value?.paymentPeriod) ? value?.paymentPeriod : null,
  };
}
