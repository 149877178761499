import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TnHttpHandlersService } from '@transport/ui-store';
import { map, Observable, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TnModalInviteService {
  private readonly endpoints = {
    getInviteLink: (): string => this.handlers.getEndpointUrl('/auth/create_invite_url'),
    sendEmailLink: (): string => this.handlers.getEndpointUrl('/auth/send_invite_email'),
    changeAutoAccreditaion: (): string => this.handlers.getEndpointUrl('/auth/change_automatic_accreditation'),
    getPublicInviteLink: (): string => this.handlers.getEndpointUrl('/auth/multiple_invite_url'),
  };

  constructor(private readonly http: HttpClient, private readonly handlers: TnHttpHandlersService) {}

  private getInviteLink(): Observable<{ url: string }> {
    const observable = this.http.get<{ url: string }>(`${this.endpoints.getInviteLink()}`);
    return this.handlers.pipeHttpRequest<{ url: string }>(observable, 1, false, true);
  }

  public setPublicLink(status): Observable<{ url: string; isCompleted: boolean }> {
    const observable = this.http.post<{ url: string; isCompleted: boolean }>(`${this.endpoints.getPublicInviteLink()}`, {
      isCompleted: status,
    });
    return this.handlers.pipeHttpRequest<{ url: string; isCompleted: boolean }>(observable, 1, false, true);
  }

  public sendEmailLink(emails: string[], automaticAccreditation: boolean): Observable<string> {
    const observable = this.http.post<string>(`${this.endpoints.sendEmailLink()}`, {
      emails,
      automaticAccreditation,
    });
    return this.handlers.pipeHttpRequest<string>(observable, 1, false, true);
  }

  private changeAutoAccreditation(url: string, automaticAccreditation: boolean) {
    const observable = this.http.post<void>(`${this.endpoints.changeAutoAccreditaion()}`, {
      url,
      automaticAccreditation,
    });
    return this.handlers.pipeHttpRequest<void>(observable, 1, false, true);
  }

  public createInviteLink(automaticAccreditation: boolean) {
    const observable = this.getInviteLink().pipe(
      switchMap(({ url }) => this.changeAutoAccreditation(url, automaticAccreditation).pipe(map(() => ({ url })))),
    );
    return this.handlers.pipeHttpRequest<{ url: string }>(observable, 1, false, true);
  }
}
