import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { transport } from '@transport/proto';
import { BLACK_LIST_TYPE } from '@transport/ui-interfaces';

@Component({
  selector: 'transport-black-list-card',
  templateUrl: './black-list-card.component.html',
  styleUrls: ['./black-list-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnBlackListCardComponent {
  @Input() public type: BLACK_LIST_TYPE = BLACK_LIST_TYPE.TRUCK;

  @Input() public info?: transport.Driver.IBlackListDriver | transport.Vehicle.IBlackListVehicle | null;

  public blackListType = BLACK_LIST_TYPE;

  public get isProhibited() {
    return Boolean(this.info?.workProhibited);
  }

  public get textType() {
    return this.translate.instant(`shared.blackListType.${this.isTrailerType ? BLACK_LIST_TYPE.TRAILER : this.type}`);
  }

  public get title() {
    return this.isProhibited ? this.fullyBlockedTitle : this.partiallyBlockedTitle;
  }

  public get iconStyles() {
    return this.isProhibited ? ['error'] : ['warning'];
  }

  public get subTitle() {
    return this.isProhibited ? this.fullyBlockedDescription : this.partiallyBlockedDescription;
  }

  public get svgIconName() {
    return this.isProhibited ? '' : 'warning_amber';
  }

  public get matIconName() {
    return this.isProhibited ? 'block' : '';
  }

  public get nomenclature() {
    return (this.info as transport.Vehicle.IBlackListVehicle)?.nomenclatureType ?? '';
  }

  public get companyName() {
    return this.info?.cargoOwner?.name;
  }

  private get isTrailerType() {
    const { nomenclatureType } = this.info as transport.Vehicle.IBlackListVehicle;
    return Boolean(nomenclatureType) || nomenclatureType === null;
  }

  private get fullyBlockedTitle() {
    return this.translate.instant(`${this.localization}.title.full`, { company: this.companyName });
  }

  private get partiallyBlockedTitle() {
    return this.translate.instant(`${this.localization}.title.partial`, { company: this.companyName });
  }

  private get fullyBlockedDescription() {
    return this.translate.instant(`${this.localization}.description.full`, {
      type: this.textType,
      company: this.companyName,
    });
  }

  private get partiallyBlockedDescription() {
    return this.translate.instant(`${this.localization}.description.partial`, {
      type: this.textType,
      company: this.companyName,
    });
  }

  private readonly localization = 'shared.blackList.card';

  constructor(private readonly translate: TranslateService) {}
}
