<mat-dialog-content>
<div class="ui-kit-wrap">
    <div class="content">
        <div class="icon-col">
            <div class="bg-red-100 icon-col__wrap hover:!cursor-pointer" mat-dialog-close>
              <mat-icon class="text-red-700 hover:!cursor-pointer">clear</mat-icon>
            </div>
        </div>
        <div class="content-col">
            <div class="title">
            {{ 'shared.mp.accreditation.modals.rejectTitle' | translate }}
            </div>
            <textarea
                class="w-full h-20 text-md tw-input-block mt-2"
                [formControl]="input"
                [ngClass]="{'tw-input-block-error': input.invalid && input.touched}"
                cdkFocusInitial
                autocomplete="off"
                autofocus
                cols="60"
                [placeholder]="'shared.mp.accreditation.modals.rejectPlaceholder' | translate"
                type="text"></textarea>
        </div>
    </div>
</div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button class="tw-button-outline-sm" mat-dialog-close>
        {{ 'shared.mp.accreditation.actions.cancel' | translate }}
    </button>
    <button class="tw-button-solid-sm ml-3"
            [mat-dialog-close]="input.value"
            [disabled]="input.invalid">
        {{ 'shared.directory.accreditation.modalReject.accept' | translate }}
    </button>
</mat-dialog-actions>
