import { createAction, props } from '@ngrx/store';

import { IFiasData } from './fias.state';

export const fiasNamespaceKey = 'Fias';

export const GET_FIAS_DATA = `[${fiasNamespaceKey}] get fias data`;

export const getFiasData = createAction(GET_FIAS_DATA, props<{ payload: { input: string; minInputLength?: number; scope?: string } }>());

export const SET_FIAS_DATA = `[${fiasNamespaceKey}] set fias data`;

export const setFiasData = createAction(SET_FIAS_DATA, props<{ payload: IFiasData; scope?: string }>());

export const setAddressFromFias = createAction(`${fiasNamespaceKey} set fias address`, props<{ address: IFiasData; scope: string }>());

export const getFiasByCode = createAction(`${fiasNamespaceKey} get address by code`, props<{ codes: string[]; scope: string }>());
