// export * from './choose-dialog-row-classes/choose-dialog-row-classes.pipe';
export * from './currency/currency.pipe';
export * from './currency/multi-currency.pipe';
export * from './fio/fio.pipe';
export * from './loading-place-select/loading-place-select.pipe';
export * from './phone-number/phone-number.pipe';
export * from './sts-number/sts-number.pipe';
export * from './toolbar-counter/toolbar-counter.pipe';
export * from './trucks/black-list-view-data.pipe';
export * from './trucks/blocked-trailer-by-nomenclature.pipe';
export * from './trucks/truck-type.pipe';
