import { ChangeDetectionStrategy, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { transport } from '@transport/proto';
import ISystemBanner = transport.ISystemBanner;


@UntilDestroy()
@Component({
  selector: 'transport-system-banner',
  templateUrl: './system-banner.component.html',
  styleUrls: ['./system-banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnSystemBannerComponent implements OnInit {
  @Output() public readonly activated = new EventEmitter();

  public dataSource?: ISystemBanner;


  public ngOnInit() {
  }
}
