import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TnDadataEffects } from './dadata.effects';
import { dadataReducer } from './dadata.reducers';

const DADATA_EFFECTS = [TnDadataEffects];

@NgModule({
  imports: [StoreModule.forFeature('dadata', dadataReducer), EffectsModule.forFeature(DADATA_EFFECTS)],
  exports: [StoreModule, EffectsModule],
})
export class TnDadataModule {}
