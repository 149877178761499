import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { IVehicleTrailerForm, trimmedStringValidator, VEHICLE_FORM_CONSTANTS } from '@transport/ui-interfaces';

const MAX_VOLUME = 99;
const MAX_CAPACITY = 99;

export function createTrailerInfoFormGroup() {
  return new FormGroup<IVehicleTrailerForm>({
    trailerId: new FormControl<string>(
      '',
      Validators.compose([Validators.required, Validators.maxLength(VEHICLE_FORM_CONSTANTS.MAX_GOSNUM_LENGTH), trimmedStringValidator]),
    ),
    trailerType: new FormControl<string>('', Validators.required),
    trailerSubtype: new FormControl<string>('', Validators.required),
    trailerVolume: new FormControl<number>(0, Validators.compose([Validators.required, Validators.max(MAX_CAPACITY)])),
    trailerCapacity: new FormControl<number>(0, Validators.compose([Validators.required, Validators.min(1), Validators.max(MAX_VOLUME)])),
    trailerLoadingTypes: new FormControl<string[]>([], Validators.required),
  });
}

export function mapTruckInputValueToInner<T extends IVehicleTrailerForm>(value: T) {
  return {
    trailerId: value.trailerId ?? '',
    trailerType: value.trailerType ?? '',
    trailerSubtype: value.trailerSubtype ?? '',
    trailerVolume: value.trailerVolume ?? 0,
    trailerCapacity: value.trailerCapacity ?? 0,
    trailerLoadingTypes: value.trailerLoadingTypes?.map(item => item ?? '') ?? [],
  };
}
