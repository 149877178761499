import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DIRECTORY_EFFECTS, directoryReducers } from './directory-state.interface';

@NgModule({
  imports: [StoreModule.forFeature('directory', directoryReducers), EffectsModule.forFeature(DIRECTORY_EFFECTS)],
  exports: [StoreModule, EffectsModule],
})
// TODO перенести всё в TnDirectoryStoreModule и удалить этот модуль T20S-4385
export class TnDirectoryStateModule {}
