<mat-form-field class="w-full !p-0" appearance="fill">
  <input type="text" [class]="inputClass" (blur)="onBlurOrgInput($event)" matInput [required]="requiredInput"
    [formControl]="orgControl" [matAutocomplete]="auto" [placeholder]="'shared.orderPage.contractCard.contractor.placeholder' | translate ">
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" (optionSelected)="optionSelected($event)">
    <mat-option *ngFor="let option of filteredOptions" [value]="option" class="mat-option">
      <div class="option-title">
        {{option.name}}
      </div>
      {{option.inn}} {{option.address}}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="orgControl?.errors?.notSelected" class="text-xs">
    {{ 'shared.errors.innNotSelected' | translate }}
  </mat-error>
  <mat-error *ngIf="orgControl?.errors?.invalid" class="text-xs">
    {{ 'shared.errors.innInvalid' | translate }}
  </mat-error>
  <mat-error *ngIf="orgControl?.errors?.required" class="text-xs">
    {{ 'shared.errors.required' | translate }}
  </mat-error>
</mat-form-field>
