import { adminProfileReducer } from './admin/admin-profile.reducer';
import { adminProfileFeatureKey } from './admin/admin-profile.state';
import { adminProfileSharedFeatureKey } from './shared';
import { adminProfileSharedReducer } from './shared/admin-profile-shared.reducer';
import { subOrganizationReducer } from './sub-organization/sub-organization.reducer';
import { subOrganizationFeatureKey } from './sub-organization/sub-organization-profile.state';
import { subOrganizationsTableFeatureKey, subOrganizationsTableReducer } from './sub-organizations-table/sub-organizations.reducer';

export interface IProfileState {
  [adminProfileFeatureKey]: ReturnType<typeof adminProfileReducer>;
  [subOrganizationFeatureKey]: ReturnType<typeof subOrganizationReducer>;
  [subOrganizationsTableFeatureKey]: ReturnType<typeof subOrganizationsTableReducer>;
  [adminProfileSharedFeatureKey]: ReturnType<typeof adminProfileSharedReducer>;
}

export const profileFeatureKey = 'profile';
