import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TDirectoryEntityPredefinedAction } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';

import { RouterActions } from '../../ngrx-router/actions/router.actions';
import { ITnState } from '../../state/index';
import { subOrganizationProfileActions } from './sub-organization-profile.actions';
import { subOrganizationProfileSelectors } from './sub-organization-profile.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnSubOrganizationProfileFacade {
  public isLoading$ = this.store.pipe(select(subOrganizationProfileSelectors.getLoading));

  public isArchived$ = this.store.pipe(select(subOrganizationProfileSelectors.getIsArchivedOrganization));

  public legalFormsDict$ = this.store.pipe(select(subOrganizationProfileSelectors.getLegalForms));

  public removeOrganization$ = this.actions$.pipe(ofType(subOrganizationProfileActions.removeSubOrganizationProfileSuccess));

  public defaultInsurance$ = this.store.pipe(select(subOrganizationProfileSelectors.getDefaultInsurance));

  public paymentTypesDict$ = this.store.pipe(select(subOrganizationProfileSelectors.getPaymentTypes));

  public vatTypesDict$ = this.store.pipe(select(subOrganizationProfileSelectors.getVatTypes));

  public editableOrganizationProfileId$: Observable<string> = this.store.pipe(select(subOrganizationProfileSelectors.getEditableProfileId));

  public formMode$: Observable<TDirectoryEntityPredefinedAction> = this.store.pipe(select(subOrganizationProfileSelectors.getFormMode));

  constructor(private readonly store: Store<ITnState>, private readonly actions$: Actions) {}

  public loadProfile(): void {
    this.store.dispatch(subOrganizationProfileActions.loadSubOrganizationProfileStart());
  }

  public saveProfile(formValue): void {
    this.store.dispatch(subOrganizationProfileActions.saveSubOrganizationProfileStart({ formValue }));
  }

  public removeProfile(orgId?: string): void {
    this.store.dispatch(subOrganizationProfileActions.removeSubOrganizationProfileStart({ orgId }));
  }

  public navigateToTable(): void {
    this.store.dispatch(RouterActions.routerGo({ path: ['/profile/owner/sub-organizations'] }));
  }

  public navigateToEdit(): void {
    this.store.dispatch(subOrganizationProfileActions.navigateToAction({ action: 'edit' }));
  }

  public navigateToCopy(): void {
    this.store.dispatch(subOrganizationProfileActions.navigateToAction({ action: 'copy' }));
  }

  public get reactiveFormValue$() {
    return this.store.pipe(select(subOrganizationProfileSelectors.getReactiveFormValue));
  }
}
