import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTP_ERROR_CODE } from '@transport/ui-interfaces';
import { TnClSsoFrameService, TnUserService } from '@transport/ui-store';
import { getAuthorizationHeader } from '@transport/ui-utils';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { TnCurrentUserFacade } from '../../current-user.facade';

/**
 * Authentication token interceptor.
 * Adds authentication token to http requests.
 */
@Injectable({
  providedIn: 'root',
})
export class TnAuthTokenInterceptorService implements HttpInterceptor {
  /**
   * Constructor.
   */
  constructor(
    private readonly userFacade: TnCurrentUserFacade,
    private readonly userService: TnUserService,
    private readonly ssoFrameService: TnClSsoFrameService,
  ) {}

  /**
   * Intercepts http request, adds authorization header to all requests except authorization, and signup requests.
   * @param req Http request
   * @param next Http request handler
   */
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const token =
      this.userFacade.currentUser.token === ''
        ? JSON.parse(`${this.userService.getCookie(this.userService.getCookieName())}`)?.token ?? ''
        : this.userFacade.currentUser.token;

    let request = req;
    if (
      !/(auth\/(employee\/registration\/confirm|get_organization_by_inn|get_list_organizations_by_hint|organization_by_domain|confirm_user_registration|invitation_user_status|sign_up|login|register|supervisor-organizations-list|supervisor-users-on-behalf-list|supervisor-login)|constants|user-data|supervisor-data|verify-user-key|refresh-user-key|confirm-change-email|reset-password|set-password)/.test(
        request.url,
      )
      && !request.url.includes('statusnpd.nalog.ru')
    ) {
      request = request.clone({
        setHeaders: getAuthorizationHeader(token ?? ''),
      });
    }

    return next.handle(request).pipe();
  }
}
