import { FocusMonitor, FocusOrigin } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, DoCheck, ElementRef, HostBinding, Input, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { Subject } from 'rxjs';

// Base class for custom angular material inputs
@Directive({
  selector: '[transportBaseMatInput]',
})
export abstract class TnBaseMatInputDirective<T = string> implements DoCheck, OnDestroy {
  public focused = false;

  public inputDisabled = false;

  public inputPlaceholder = '';

  public inputRequired = false;

  public inputValue?: T | null;

  @HostBinding('attr.aria-describedby')
  public describedBy = '';

  public touched = false;

  public errorState = false;

  private readonly stateChanges$ = new Subject<void>();

  public readonly stateChanges = this.stateChanges$.asObservable();

  constructor(public fm: FocusMonitor, public elRef: ElementRef<HTMLElement>, public ngControl?: NgControl | null) {
    if (typeof this.ngControl !== 'undefined' && this.ngControl !== null) {
      this.ngControl.valueAccessor = this;
    }
    void fm.monitor(elRef.nativeElement, true).subscribe(origin => {
      this.onFocusMonitor(origin);
      this.focused = Boolean(origin);
      this.stateChanges$.next();
    });
  }

  public readonly stateChangesNextValue = () => {
    this.stateChanges$.next();
  };

  @Input()
  public get value() {
    return this.inputValue;
  }

  public set value(value) {
    this.writeValue(value);
    this.stateChanges$.next();
  }

  @Input()
  public get placeholder(): string {
    return this.inputPlaceholder;
  }

  public set placeholder(value: string) {
    this.inputPlaceholder = value;
    this.stateChanges$.next();
  }

  public get empty() {
    return !Boolean(this.value);
  }

  @HostBinding('class.floating')
  public get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  public get required() {
    return this.inputRequired;
  }

  public set required(req) {
    this.inputRequired = coerceBooleanProperty(req);
    this.stateChanges$.next();
  }

  @Input()
  public get disabled(): boolean {
    return this.inputDisabled;
  }

  public set disabled(value: boolean) {
    this.inputDisabled = coerceBooleanProperty(value);
    this.stateChanges$.next();
  }

  public ngDoCheck(): void {
    if (Boolean(this.ngControl)) {
      this.errorState = (this.ngControl?.invalid ?? false) && (this.ngControl?.touched ?? false);
      this.stateChanges$.next();
    }
  }

  public ngOnDestroy() {
    this.stateChanges$.complete();
    this.fm.stopMonitoring(this.elRef.nativeElement);
  }

  // ControlValueAccessor implementing
  public writeValue(value: T | null | undefined): void {
    this.inputValue = value;
  }

  public registerOnChange(fn: (...args) => undefined): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this.onTouched = fn;
  }

  public propagateChange = (...args) => void 0;

  // End ControlValueAccessor implementing
  public onTouched = () => {
    this.touched = true;
  };

  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  public setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  public onContainerClick(event: MouseEvent) {
    if ((event.target as Element).tagName.toLowerCase() !== 'input') {
      this.elRef.nativeElement.querySelector('input')?.focus();
    }
  }

  // Default behavior is doing nothing
  public onFocusMonitor = (origin: FocusOrigin) => void 0;
}
