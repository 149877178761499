import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ACCREDITATION_STATUS } from '@marketplace-app/users/security-staff/pages/common/common.interface';

@Component({
  selector: 'marketplace-status-column',
  template: `
    <div class="whitespace-nowrap overflow-hidden text-ellipsis">
      <span [ngClass]="[
        'py-0.5 px-2 rounded-2xl text-xs font-medium leading-4',
        status === ACCREDITATION_STATUS.ON_ACCRED ?  'bg-orange-100 text-orange-700' : '',
        status === ACCREDITATION_STATUS.ACCRED ? 'bg-green-100 text-green-700' : '',
        status === ACCREDITATION_STATUS.REJECT ? 'bg-red-100 text-red-700' : '',
        status === ACCREDITATION_STATUS.NEW || status === ACCREDITATION_STATUS.DATA_REQUESTED ? 'bg-gray-100 text-gray-700' : '',
        ]"
        style="height: 18px">
        {{ textStatus }}
        <mat-icon 
          transportTooltip
          [context]="{message: reason}"
          *ngIf="(status === ACCREDITATION_STATUS.REJECT || status === ACCREDITATION_STATUS.ACCRED) && reason" 
          [ngClass]="[
            '!h-3.5 !w-3.5 ml-1.5 relative !cursor-pointer',
            status === ACCREDITATION_STATUS.ACCRED ? 'text-green-700' : '',
            status === ACCREDITATION_STATUS.REJECT ? 'text-red-700' : '',
          ]"
          style="font-size: 12px; top: 2px">info</mat-icon>
      </span>
    </div>
    <div class="text-gray-700 text-xs leading-4 font-medium" *ngIf="text">
      <div class="whitespace-nowrap overflow-hidden text-ellipsis" tn-tip="true">{{text}}</div>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MpStatusColumnComponent {
  @Input() status!: ACCREDITATION_STATUS;
  @Input() textStatus: string | null = null;
  @Input() text: string | null = null;
  @Input() reason: string | null = null;

  ACCREDITATION_STATUS = ACCREDITATION_STATUS;
}
