import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { IDadataSuggestionDto, TnMapPoint } from '@transport/ui-interfaces';
import { CountryNames, TnSupportedCountry } from '@transport/ui-pipes';
import { COUNTER } from '@transport/ui-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnHttpHandlersService } from '../feature-access/http-handlers.service';

@Injectable({
  providedIn: 'root',
})
export class TnDadataApiService {
  constructor(private readonly http: HttpClient, private readonly handlers: TnHttpHandlersService) {}

  public getMapPointByAddress(place: { address: string; country?: string }): Observable<TnMapPoint | null> {
    const country =  place.country && (TnSupportedCountry[place.country] === TnSupportedCountry.NO ? null : (TnSupportedCountry[place.country] ?? CountryNames[place.country] ?? TnSupportedCountry.RU));
    return this.handlers.pipeHttpRequest(
      this.http
        .post<{ suggestions: IDadataSuggestionDto[] }>(this.handlers.getEndpointUrl('/dadata/suggest-address/'), {
          query: place.address,
          country,
          count: 1,
        })
        .pipe(
          map(data => {
            return data.suggestions.length ? TnMapPoint.fromDto(data.suggestions[0]) : null;
          }),
        ),
      1,
      false,
    );
  }

  public getMapPointsByAddress(place: { address: string; country?: TnSupportedCountry }, countResults = COUNTER.TEN) {
    const country = place.country === TnSupportedCountry.NO ? null : (place.country ?? TnSupportedCountry.RU);

    return this.handlers.pipeHttpRequest(
      this.http
        .post<{ suggestions: IDadataSuggestionDto[] }>(this.handlers.getEndpointUrl('/dadata/suggest-address/'), {
          query: place.address,
          count: countResults,
          country,
        })
        .pipe(map(data => data.suggestions.map(dtoItem => TnMapPoint.fromDto(dtoItem)))),
      1,
      false,
    );
  }

  public getCityByAddress(place: { address: string; countResults?: number; country?: TnSupportedCountry }) {
    const country = place.country === TnSupportedCountry.NO ? null : place.country ?? TnSupportedCountry.RU;
    return this.handlers.pipeHttpRequest(
      this.http
        .post<{ suggestions: IDadataSuggestionDto[] }>(this.handlers.getEndpointUrl('/dadata/suggest-address/'), {
          query: place.address,
          count: place.countResults ?? COUNTER.TEN,
          country,
          from_bound: { value: 'city' },
          to_bound: { value: 'settlement' },
        })
        .pipe(map(data => data.suggestions.map(dtoItem => TnMapPoint.fromDto(dtoItem)))),
      1,
      false,
    );
  }

  public getMapPointsByCoordinates(lat: number, lon: number) {
    return this.handlers.pipeHttpRequest(
      this.http
        .post<{ suggestions: IDadataSuggestionDto[] }>(this.handlers.getEndpointUrl('/dadata/geolocate-address/'), { lat, lon })
        .pipe(map(data => data.suggestions.map(dtoItem => TnMapPoint.fromDto(dtoItem)))),
      1,
      false,
    );
  }
}
