import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { featureAccessReducer } from './feature-access.reducers';

@NgModule({
  imports: [StoreModule.forFeature('featureAccess', featureAccessReducer)],
  exports: [StoreModule, EffectsModule],
})
export class TnFeatureAccessModule {}
