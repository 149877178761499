/* eslint-disable max-lines-per-function */
import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { NgSelectConfig } from '@ng-select/ng-select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BurgerMenuService, MENU_ANIMATION, MENU_STATE, SidebarItem } from '@transport/sidebar';
import { APP_ENV, TColorScheme, TnAppEnvironment, USER_ROLE } from '@transport/ui-interfaces';
import { TnSettingsService } from '@transport/ui-services';
import { setEnv, TnAnalyticsService, TnClSsoFrameService, TnCurrentUserFacade, TnPassportFacade, TnUserService } from '@transport/ui-store';
import { WebsocketService } from '@transport/websocket';
import { BehaviorSubject, combineLatest, distinctUntilChanged, filter, map, Observable, Subject, tap } from 'rxjs';

import { AuthTokenService } from './auth-token.service';
import { SidebarGuardService } from 'libs/sidebar/src/lib/sidebar-guard.service';
import { masterSidebarItems } from './transport-sidebar/transport-sidebar.config';
import { transport } from '@transport/proto';
import { TnProfileOrganizationService } from 'libs/transport-profile/src/lib/services';

declare const window: any;

/**
 * Transport v2.0 root component.
 */
@UntilDestroy()
@Component({
  selector: 'transport-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    SidebarGuardService,
    {
      provide: 'sidebarConfig',
      useValue: masterSidebarItems,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: MENU_ANIMATION,
})
export class TnAppComponent implements OnInit, OnDestroy {
  /**
   * Toolbar color configuration.
   */
  public readonly color: TColorScheme = 'primary';

  public readonly homePath$: Observable<{ link: string; disabled: boolean }> = combineLatest([
    this.router.events,
    this.userFacade.user$,
  ]).pipe(
    filter(([event]) => event instanceof NavigationEnd),
    map(([event, { role }]) => {
      if (role === USER_ROLE.CARGO_OWNER) {
        const link = '';
        return {
          link,
          disabled: true,
        };
      }
      const link = `/profile/subdomain`;
      return {
        link,
        disabled: (event as NavigationEnd).url.includes(`${link}`),
      };
    }),
  );

  public get isCarrier() {
    return this.userFacade.isUserRole(USER_ROLE.CARRIER);
  }

  public get isOwner() {
    return this.userFacade.isUserRole(USER_ROLE.OWNER);
  }

  public get hasToken() {
    return Boolean(this.userFacade.currentUser.token);
  }

  private readonly higherHeadingSubject = new BehaviorSubject<boolean>(false);

  public higherHeading$ = this.higherHeadingSubject.asObservable();

  public isPassportPage = true;

  public firstLoad = true;

  public isExpired = true;

  public trialExpDate: string | null = null;

  public widgetOpened = window.usedeskMessenger ? true : false;

  public widgetScript = document.querySelector('#widget-script');

  public menuState = MENU_STATE;

  public sidebarItems: SidebarItem[] = masterSidebarItems;

  private readonly destroy$ = new Subject();

  public owners!: transport.OwnerOrganiationContractWithCarrier[];

  public isSubdomainPage!: boolean;

  public isUserRoleCarrier!: boolean;

  public showSelectData = false;

  constructor(
    public readonly userFacade: TnCurrentUserFacade,
    public readonly userService: TnUserService,
    public readonly burgerService: BurgerMenuService,
    public readonly sidebarGuard: SidebarGuardService,
    private readonly title: Title,
    private readonly meta: Meta,
    private readonly translate: TranslateService,
    private readonly ngSelectConfig: NgSelectConfig,
    private readonly passportFacade: TnPassportFacade,
    private readonly store: Store,
    private readonly router: Router,
    private readonly analytics: TnAnalyticsService,
    private readonly cdr: ChangeDetectorRef,
    private readonly settingsService: TnSettingsService,
    private readonly wsService: WebsocketService,
    private readonly token: AuthTokenService,
    private readonly profileService: TnProfileOrganizationService,
    private readonly ssoService: TnClSsoFrameService,
    @Inject(APP_ENV) public readonly env: TnAppEnvironment,
  ) {}
  /**
   * Lifecycle hook called on component initialization.
   * When called does the following:
   * - saves app environment to store;
   * - restores user;
   * - sets ng-select not found text;
   * - sets document title;
   * - sets document description;
   * - sets default page content language;
   * - init amplitude
   * - track navigation events with amplitude
   * - switches page content language when UI language is changed via the translate service;
   */

  public ngOnInit() {
    this.token.isTokenValid$.subscribe(res => {
      if (res) {
        this.analytics.init();
        this.router.events.pipe(filter(e => e instanceof NavigationEnd)).subscribe(e => {
          const event = e as NavigationEnd;
          this.firstLoad = false;
          this.isPassportPage = event.urlAfterRedirects.includes('passport');
          this.cdr.markForCheck();
        });

        this.store.dispatch(setEnv({ payload: this.env }));
        this.userFacade.restoreUserOnInit();
        this.ngSelectConfig.notFoundText = this.translate.instant('shared.ngSelectLocalize.noItems');
        this.title.setTitle(this.env.appName ?? '');
        this.meta.updateTag({ description: 'Freight management system.' });
        this.meta.updateTag({
          'http-equiv': 'content-language',
          content: this.translate.getDefaultLang(),
        });

        this.userFacade.user$
          .pipe(
            untilDestroyed(this),
            distinctUntilChanged((cur, prev) => cur?.id === prev.id),
          )
          .subscribe(user => {
            // Condition for https://tndl.kaiten.ru/space/50858/card/8065382
            // костыль пока тонна и маркет на одном домене
            if (this.hasToken && this.isCarrier) {
              this.passportFacade.redirectToMarket();
            }
            if (this.hasToken)
              this.settingsService
                .getModules()
                .pipe(untilDestroyed(this))
                .subscribe(res => {
                  const displayedItems = this.mapDisplayedItems(this.sidebarItems);
                  this.sidebarGuard.setAllowedItems(displayedItems);
                });
            if (user.token) this.wsService.connect(user.token);
          });

        this.userFacade.profile$
          .pipe(
            untilDestroyed(this),
            map(result => result.organization?.trialPeriodExpAt ?? ''),
            filter(result => Boolean(result) && this.isOwner),
            tap(date => {
              this.isExpired = new Date(date) <= new Date();
              this.trialExpDate = date;
            }),
          )
          .subscribe();

        registerLocaleData(localeRu, 'ru');

        void this.translate.onLangChange
          .pipe(
            untilDestroyed(this),
            tap(event => {
              this.meta.removeTag('http-equiv=content-language');
              this.meta.updateTag({
                'http-equiv': 'content-language',
                content: event.lang,
              });
            }),
          )
          .subscribe();

        this.widgetScript?.addEventListener('load', () => {
          this.widgetOpened = true;
        });
      } else if (!this.router.url.includes('/passport/login')) {
        this.userFacade.resetUser();
        this.userService.resetUser();
        this.analytics.stopSession();
        this.wsService.disconnect();
        this.ssoService.logOut();
        window.location.href = `${window.location.origin}/passport/login`;
        // this.router.navigate(['/passport/login']);
      }
    });

    this.profileService
      .getIsSubdomainPage()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.isSubdomainPage = res;
        this.cdr.markForCheck();
      });
  }

  public get isSupervisor(): boolean {
    return Boolean(this.userFacade.currentUser.isSupervisor);
  }

  public onShowBanner(value: boolean): void {
    void this.higherHeadingSubject.next(value);
  }

  public closeWidget() {
    this.widgetOpened = false;
    window.usedeskMessenger.toggle();
  }

  public logoutBySSO(): void {
    if (!this.router.url.includes('/passport/login')) {
      this.userFacade.resetUser();
      this.userService.resetUser();
      this.analytics.stopSession();
      this.wsService.disconnect();
      // this.router.navigate(['/passport/login']); //TODO
      window.location.href = `${window.location.origin}/passport/login`;
    }
  }

  public fadeOutClick(): void {
    if (this.burgerService.isMobileDevice && this.burgerService.menuState$.value === MENU_STATE.IN) {
      this.burgerService.changeMenuView();
    }
  }

  private mapDisplayedItems(items: SidebarItem[]): SidebarItem[] {
    return items.filter(item => {
      if (item.children?.length) {
        item.children = this.mapDisplayedItems(item.children);
      }
      const isAllowed = item.allowed?.includes(this.userFacade.currentUserRole);
      const isAvailableModule = item.module ? this.settingsService.availableModules?.includes(item.module) : true;
      if (item?.permissions) {
        const hasPerm = item?.permissions.find(permName => this.userFacade.hasPermissionStatic(permName));
        return hasPerm && isAllowed && isAvailableModule;
      }
      return isAllowed && isAvailableModule;
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
  }
}
