<mat-label>
  <span class="text-sm text-gray-700">{{ 'shared.addressInput.label.input' | translate }} <span *ngIf="required" class="text-red-500">*</span></span>
  <a *ngIf="withMap" class="place-link" [routerLink]="" (click)="openAddressInputDialog($event)">{{ 'shared.addressInput.label.link' | translate }}</a>
</mat-label>
<mat-form-field class="autocomplete" appearance="outline">
  <input
    transportClearInput
    #inputElement
    type="text"
    matInput
    [formControl]="searchInputControl"
    [matAutocomplete]="auto"
    autocomplete="nope"
    [required]="required"
    (clearInput)="setRequiredFormError()"
    [attr.data-test]="dataTest"
  />
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)" [displayWith]="bindedValue">
    <mat-option class="horizon-scroll" *ngFor="let item of $any(selectData$ | async)" [value]="item">
      {{ bindedValue(item) }}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="searchInputControl.errors?.required">{{ 'shared.errors.required' | translate }}</mat-error>
  <mat-error *ngIf="searchInputControl.errors?.trimmedString">{{ 'shared.errors.trimmedString' | translate }}</mat-error>
</mat-form-field>
