import { stopPropagationWhenSelectionExtender, composeRowExtenders } from '../../plain-table/table-row-extender';
import { redirectByLinkMouseExtender } from './extenders/redirect-by-link-mouse.extender';
import { redirectByLinkKeyboardExtender } from './extenders/redirect-by-link-keyboard.extender';
import { redirectByLinkExtender } from './extenders/redirect-by-link.extender';

// For more information read DOCUMENTATION.md
export const TableRowExtenders = {
  compose: composeRowExtenders,
  stopPropagationWhenSelectionExtender,
  redirectByLinkExtender,
  redirectByLinkMouseExtender,
  redirectByLinkKeyboardExtender,
};
