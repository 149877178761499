import { ChangeDetectionStrategy, Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { VehicleTypeColumnVm } from './vehicle-type-column-vm';

@Component({
  selector: 'marketplace-vehicle-type-column',
  templateUrl: './vehicle-type-column.component.html',
  styleUrls: ['./vehicle-type-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleTypeColumnComponent implements OnChanges {
  @Input() public bodySubtypes: { name: string, id: string }[] | null = null;
  @Input() public bodySubtype: string | null = null;

  @Input() public vehicleType: string | null = null;

  @Input() public loadingTypes: string | string[] = [];

  @Input() public capacity: string | null = null;

  @Input() public volume: string | null = null;

  public vm = new VehicleTypeColumnVm();

  public ngOnChanges(changes: SimpleChanges) {
    if ('loadingTypes' in changes) {
      this.vm.setLoadingTypes(changes.loadingTypes.currentValue);
    }
    if ('vehicleType' in changes) {
      this.vm.setVehicleType(changes.vehicleType.currentValue);
    }
  }
}
