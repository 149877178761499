import { Pipe, PipeTransform } from '@angular/core';
import { TnSupportedCitizenNumbers } from '../currency/multi-currency.pipe';

const RU_CITIZEN_NUMBER_LENGTH = 2;
const BY_CITIZEN_NUMBER_LENGTH = 4;

export function getPhoneWithCitizenNumber(phone: string | null): { citizenNumber: string; phone: string } | null {
  if (!phone) return null;

  let p = phone;
  if (!phone.startsWith('+') && !phone.startsWith('8')) p = `+${phone}`;

  let splitIndex = 1;
  if (p.startsWith(TnSupportedCitizenNumbers.RU)) {
    splitIndex = RU_CITIZEN_NUMBER_LENGTH
  }
  if (p.startsWith(TnSupportedCitizenNumbers.BY)) {
    splitIndex = BY_CITIZEN_NUMBER_LENGTH
  }
  if (p.startsWith(TnSupportedCitizenNumbers.UZ)) {
    splitIndex = BY_CITIZEN_NUMBER_LENGTH
  }
  if (p.startsWith(TnSupportedCitizenNumbers.KG)) {
    splitIndex = BY_CITIZEN_NUMBER_LENGTH
  }
  if (p.startsWith(TnSupportedCitizenNumbers.TJ)) {
    splitIndex = BY_CITIZEN_NUMBER_LENGTH
  }

  return {
    citizenNumber: p.substring(0, splitIndex),
    phone: p.substring(splitIndex),
  };
}

@Pipe({
  name: 'phoneFormat',
})
export class TnPhonePipe implements PipeTransform {
  // Преобразование из +79995552233 в +7 (999) 555-22-33
  public transform(phoneNumber: string | null | undefined): string {
    const { citizenNumber, phone } = getPhoneWithCitizenNumber(phoneNumber as string) ?? {
      citizenNumber: TnSupportedCitizenNumbers.RU,
      phone: '',
    };

    if (phone) {
      if (Number(phone)) {
        switch (citizenNumber) {
          case TnSupportedCitizenNumbers.RU:
            return `${citizenNumber} (${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 8)}-${phone.slice(8)}`;

          case TnSupportedCitizenNumbers.BY:
          case TnSupportedCitizenNumbers.UZ:
          case TnSupportedCitizenNumbers.KG:
          case TnSupportedCitizenNumbers.TJ:
            return `${citizenNumber} (${phone.slice(0, 2)}) ${phone.slice(2, 5)}-${phone.slice(5, 7)}-${phone.slice(7)}`;

          default:
            return `${citizenNumber} (${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 8)}-${phone.slice(8)}`;
        }
      }

      return phone;
    }

    return '';
  }
}
