import { createSelector } from '@ngrx/store';

import { ITnState } from '../state/index';
import { IDadataState } from './dadata.state';

export const getDadataValue = (state: ITnState) => state.dadata;

export const dadataData = createSelector(getDadataValue, (state: IDadataState) => state);

export const dadataSuggestions = createSelector(getDadataValue, (state: IDadataState) => {
  return state.suggestions.map(item => {
    return { label: item.fullAddress, value: item };
  });
});

export const dadataCitySuggestions = createSelector(getDadataValue, (state: IDadataState) => {
  let suggestions = state.suggestions
    .filter(item => item.settlementWithType || item.cityWithType)
    .map(item => {
      return { label: item.fullAddress, value: item };
    });
  return suggestions;
});
