import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { IUser, TUserPredefinedAction } from '@transport/ui-interfaces';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

import { RouterActions } from '../../ngrx-router/actions/router.actions';
import { ITnState } from '../../state/index';
import { allPermissionsActions, userActions } from './actions';
import * as allPermissionSelector from './selectors/all-permissions.selectors';
import * as selector from './selectors/user.selectors';
import { IUserForm } from './user.interface';

@Injectable({
  providedIn: 'root',
})
export class TnUserFacade {
  public user$ = this.store.pipe(select(selector.getUser));

  public userId$ = this.store.pipe(select(selector.getId));

  public allPermissions$ = this.store.pipe(select(allPermissionSelector.getPermissions));

  public isLoading$ = this.store.pipe(select(selector.getLoading));

  public isPageLoad$ = this.store.pipe(select(selector.getPageLoad));

  public isAdminCG = this.store.pipe(select(selector.isAdminCG));

  public form$ = this.store.pipe(select(selector.getForm), shareReplay({ bufferSize: 1, refCount: true }));

  public mode$ = this.store.pipe(select(selector.getMode));

  public isFormDisabled$ = this.store.pipe(select(selector.getIsFormDisabled));

  public isEmailEnabled$ = this.store.pipe(select(selector.getIsEmailEnabled));

  public isPermissionsDisabled$ = this.store.pipe(select(selector.getIsPermissionsDisabled));

  public get isFormDirty$() {
    return this.store.pipe(select(selector.getIsFormDirty));
  }

  private readonly currentModeSubject = new BehaviorSubject('view');

  constructor(protected store: Store<ITnState>) {
    void this.mode$.subscribe(value => {
      this.currentModeSubject.next(value);
    });
  }

  public get mode() {
    return this.currentModeSubject.value;
  }

  public static readonly backToListAction = () => RouterActions.routerGo({ path: ['admin/owner/users'] });

  public loadUser() {
    this.store.dispatch(userActions.loadUser());
    this.store.dispatch(allPermissionsActions.loadAllPermissions());
  }

  public setIsDirtyState(isDirty: boolean) {
    this.store.dispatch(userActions.setFormDirty({ isDirty }));
  }

  public backToList() {
    this.store.dispatch(TnUserFacade.backToListAction());
  }

  public showUserDetails(user: IUser, mode: TUserPredefinedAction) {
    this.store.dispatch(RouterActions.routerGo({ path: ['admin/owner/users', mode, user.id ?? ''] }));
  }

  public navigateToEdit(user: IUser) {
    this.showUserDetails(user, 'edit');
  }

  public navigateToCopy(user: IUser) {
    this.showUserDetails(user, 'copy');
  }

  public remove(user: IUser) {
    this.store.dispatch(userActions.removeUser({ user }));
  }

  public save(user: IUserForm) {
    this.store.dispatch(userActions.saveUser({ user }));
  }

  public invite(user: IUserForm) {
    this.store.dispatch(userActions.inviteUser({ user }));
  }

  public inviteAgain(id) {
    this.store.dispatch(userActions.inviteUserAgain({ id }));
  }

  public resetPassword(id) {
    this.store.dispatch(userActions.resetPassword({ id }));
  }

  public resetState() {
    this.store.dispatch(userActions.resetState());
  }
}
