import { AfterViewInit, ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';
import { DOCUMENT_TYPE, IOrderAttachment, TFileAdditionalAttributes } from '@transport/ui-interfaces';

import { TnFileAdditionalAttributesContainerComponent } from '../additional-attributes/file-additional-attributes-container/file-additional-attributes-container.component';
import { IWaybillForm } from '../additional-attributes/waybill-form/waybill-form.component';

const defaultLabels = {
  closeBtn: 'shared.actions.close',
};

@UntilDestroy()
@Component({
  selector: 'transport-view-dialog',
  templateUrl: './waybill-view-dialog.component.html',
  styleUrls: ['./waybill-view-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnWaybillViewDialogModal implements AfterViewInit {
  constructor(private readonly dialogRef: MatDialogRef<TnWaybillViewDialogModal>) {}

  @ViewChild(TnFileAdditionalAttributesContainerComponent)
  private readonly additionalAttributesComponent?: TnFileAdditionalAttributesContainerComponent;

  private file?: IOrderAttachment;

  @Input() public docType: DOCUMENT_TYPE = DOCUMENT_TYPE.WAYBILL;

  @Input() public waybillForm?: IWaybillForm;

  @Input() public titleText;

  @Input() public closeButtonText = defaultLabels.closeBtn;

  @Input() public set fileData(value: IOrderAttachment | undefined) {
    this.file = value;
  }

  public get fileData() {
    return this.file;
  }

  private additionalAttributes?: TFileAdditionalAttributes | null = null;

  public ngAfterViewInit(): void {
    this.additionalAttributes = this.fileData?.waybillInfo;
    if (Boolean(this.additionalAttributes)) {
      this.additionalAttributesComponent?.disabledFields();
      this.additionalAttributesComponent?.writeValue(this.additionalAttributes as TFileAdditionalAttributes);
    }
  }

  public close(): void {
    this.dialogRef.close();
  }
}
