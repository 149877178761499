import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { TnCurrentUserService } from '@marketplace/auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ITab } from '@transport/ui-interfaces';
import { IDriversFilterToolbarState, IGqlDriversStatusCounters, TOOLBAR_LS_KEY } from '../filters.interface';
import { toolbarConfig } from './filters-toolbar-tabs';
import { DriversAmountService } from './drivers-amount.service';
import { tap } from 'rxjs';
import { DriversTableService } from '../../table/drivers-table.service';

@UntilDestroy()
@Component({
  selector: 'marketplace-filters-toolbar',
  templateUrl: './filters-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FiltersToolbarComponent implements OnInit {
  public tabs: ITab[] = [];
  public tabSelected = 0;

  constructor(
    private driversTableService: DriversTableService,
    private driversAmountService: DriversAmountService,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    public currentUserService: TnCurrentUserService,
  ) {}

  public ngOnInit(): void {
    this.toolbarReadState();
    this.buildTabs();
    this.initAmountListener();
    this.driversTableService.selectedTab$.pipe(untilDestroyed(this)).subscribe(res => {
      this.tabSelected = res;
      this.toolbarWriteState()
      this.cdr.markForCheck();
    });
    this.driversAmountService.updateDriversStatusCounters();
  }

  private buildTabs(): void {
    this.tabs = toolbarConfig.map(item => ({count: item.tab.count, label: this.translate.instant(item.tab.label!)}));
  }

  private initAmountListener() {
    this.driversAmountService.amount$
      .pipe(
        untilDestroyed(this),
        tap((amount: IGqlDriversStatusCounters | null) => {
          if (amount) {
            this.tabs = toolbarConfig.map(item => {
              return {
                label: this.translate.instant(item.tab.label!),
                count: amount ? amount[item.id] : 0,
              };
            });
            this.cdr.markForCheck();
          }
        }),
      )
      .subscribe();
  }

  private toolbarReadState(): void {
    try {
      const json = localStorage.getItem(TOOLBAR_LS_KEY);
      const state = json ? JSON.parse(json) : null;
      if (state) {
        if (state.tabIndex) {
          const tabStatuses = toolbarConfig[state.tabIndex].id;
          this.driversTableService.store.changeFilter({ availability: tabStatuses }, false);
        }
        this.driversTableService.selectedTab$.next(state.tabIndex || 0);
      }
    } catch {
      console.error('ERROR: FiltersToolbarComponent. Can\'t read the state');
    }
  }

  private toolbarWriteState(): void {
    const state: IDriversFilterToolbarState = {
      tabIndex: this.tabSelected,
      tabStatusesChanged: false,
    };
    localStorage.setItem(TOOLBAR_LS_KEY, JSON.stringify(state));
  }

  public onTabChange(tabIndex: number): void {
    this.tabSelected = tabIndex;
    const tabStatuses = toolbarConfig[this.tabSelected].id;
    this.driversTableService.store.changeFilter({ availability: tabStatuses }, false);
    this.driversTableService.selectedTab$.next(this.tabSelected);
    this.toolbarWriteState();
  }
}
