<div class="flex gap-2 flex-col">
  <common-upload-area
    class="upload-area !cursor-pointer w-full z-10"
    [accept]="accept"
    [multiple]="false"
    [files]="$any(imgBlobs$ | async)"
    (filesChange)="onChangeFiles($any($event))"
    (invalidFileSizeSelected)="onInvalidFileSizeSelected($event)"
    [useDefaultStyle]="false"
    data-test="loading-place-upload-area"
  >
    <img #imgElement class="logo-img" />

    <div *ngIf="!(imgBlobs$ | async)?.length" class="flex flex-col gap-2 p-3 border border-dashed border-gray-500 rounded-md">
      <button class="tw-button-outline-sm w-full">
        <mat-icon svgIcon="upload" class="upload"></mat-icon>{{ 'shared.imageInput' | translate }}
      </button>
      <span *ngIf="acceptHint" class="text-gray-500 text-xs">{{ acceptHint }}</span>
    </div>
  </common-upload-area>
  <button *ngIf="(imgBlobs$ | async)?.length" style="height: inherit" class="tw-button-outline-xs" (click)="clear()">
    <mat-icon class="mat-icon-md text-gray-500 cursor-pointer z-50" svgIcon="delete-outlined"></mat-icon>
  </button>
</div>
