import { Directive, ElementRef, Input, OnDestroy, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { IRowExtender } from './row-extender.interface';

@Directive({
  selector: '[marketplaceRowExtender][rowData]',
})
export class TableRowExtenderDirective<T> implements OnInit, OnChanges, OnDestroy {
  @Input() rowExtender: IRowExtender<T> | null = null;
  @Input() rowData!: T;

  private unsub: () => void = () => void 0;

  constructor(private elementRef: ElementRef) {}

  private launchRowExtender() {
    this.unsub = this.rowExtender ? this.rowExtender(this.elementRef, this.rowData) : () => void 0;
  }

  public ngOnInit() {
    this.launchRowExtender();
  }

  public ngOnChanges(changes: SimpleChanges) {
    if ('rowExtender' in changes && !changes.rowExtender.firstChange) {
      this.unsub();
      this.launchRowExtender();
    }
  }

  public ngOnDestroy() {
    this.unsub();
  }
}
