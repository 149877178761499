import { createAction, props } from '@ngrx/store';
import { transport } from '@transport/proto';

const defaultInsuranceSuccess = createAction(
  '[Admin Profile Shared] Load Default Insurance Success',
  props<{ contract: transport.Insurance.IContract }>(),
);

const loadLegalFormsSuccess = createAction(
  '[Admin Profile Shared] Load Legal Forms Success',
  props<{ legalForms: transport.ILegalForm[] }>(),
);

const loadLegalFormsFailure = createAction('[Admin Profile Shared] Load Legal Forms Failure', props<{ error }>());

const defaultInsuranceFailure = createAction('[Admin Profile Shared] Load Default Insurance Failure', props<{ error }>());

const loadVatTypesSuccess = createAction(
  '[Admin Profile Shared] Load Vat Types Success',
  props<{ vatTypes: transport.IOrganizationVatType[] }>(),
);

const loadPaymentTypesSuccess = createAction(
  '[Admin Profile Shared] Load Payment Types Success',
  props<{ paymentTypes: transport.IPaymentType[] }>(),
);

const loadVatTypesFailure = createAction('[Admin Profile Shared] Load Vat Types Failure', props<{ error }>());

const loadPaymentTypesFailure = createAction('[Admin Profile Shared] Load Payment Types Failure', props<{ error }>());

export const adminProfileSharedActions = {
  defaultInsuranceSuccess,
  loadLegalFormsSuccess,
  loadLegalFormsFailure,
  defaultInsuranceFailure,
  loadVatTypesSuccess,
  loadPaymentTypesSuccess,
  loadVatTypesFailure,
  loadPaymentTypesFailure,
};
