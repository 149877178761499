import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, OnInit, Optional, Output, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { filter } from 'rxjs/operators';

@UntilDestroy()
@Directive({
  selector: '[transportClearInput]',
})
export class TnClearInputDirective implements OnInit, AfterViewInit {
  @Input() public isClear = true;

  @Output() public readonly clearInput = new EventEmitter();

  public icon: HTMLElement | undefined;

  constructor(
    private readonly element: ElementRef,
    private readonly render: Renderer2,
    @Optional() private readonly formControl: NgControl,
  ) {}

  public ngOnInit() {
    if (Boolean(this.formControl) && this.isClear) {
      this.createIcon();
      Boolean(this.formControl?.control?.value) && !this.isFormControlUndefinedOrDisabled ? this.showIcon() : this.hideIcon();
    }
    this.formControl?.control?.valueChanges
      .pipe(
        filter(() => Boolean(this.icon)),
        untilDestroyed(this),
      )
      .subscribe(value => {
        Boolean(value) && !this.isFormControlUndefinedOrDisabled ? this.showIcon() : this.hideIcon();
      });
  }

  public ngAfterViewInit() {
    if (this.icon) {
      this.render.listen(this.icon, 'click', event => {
        (event as Event).stopPropagation();
        this.hideIcon();
        this.formControl?.control?.patchValue('');
        this.clearInput.emit();
      });
    }
  }

  public createIcon() {
    const parent = this.render.parentNode(this.element.nativeElement);
    if (!this.icon) {
      this.icon = this.render.createElement('i');
      this.render.appendChild(parent, this.icon);
      this.render.addClass(this.icon, 'clear-icon');
      this.setStyleForInput();
    }
  }

  public showIcon() {
    this.render.removeClass(this.icon, 'hidden');
  }

  public hideIcon() {
    this.render.addClass(this.icon, 'hidden');
  }

  private get isFormControlUndefinedOrDisabled(): boolean {
    const result = !Boolean(this.formControl) || Boolean(this.formControl?.disabled);
    return result;
  }

  private setStyleForInput() {
    if ((this.element.nativeElement as HTMLElement).className.includes('mat-input-element')) {
      this.render.setStyle(this.element.nativeElement, 'width', '94%');
    }
  }
}
