import { Directive, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[transportInnMax]',
})
export class TnInnMaxDirective {
  constructor(@Optional() private readonly formControl: NgControl) {}

  @HostListener('input', ['$event.target.value']) public onInput(event) {
    const maxInnLength = 12;
    if (typeof event === 'string') {
      const inn = event.substring(0, maxInnLength);
      this.formControl.control?.patchValue(inn);
    } else {
      this.formControl.control?.patchValue(this.formControl.control.value);
    }
  }
}
