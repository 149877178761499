import { transport } from '@transport/proto';

import { createNewVehicleType } from '../common';
import { createNewCarrierOrganization } from '../organization/organization.interface';
import { TCargoPackagingTypeCode } from './cargo-type-common.interface';

export interface ICargoType extends transport.ICargoType {
  packagingType: TCargoPackagingTypeCode;
}

export const createNewCargoType = (input: Partial<ICargoType> = {}) => {
  input.cargoOwner = !Boolean(input.cargoOwner) ? createNewCarrierOrganization() : input.cargoOwner;
  input.typeVehicle = !Boolean(input.typeVehicle) ? createNewVehicleType() : input.typeVehicle;
  return transport.CargoType.toObject(new transport.CargoType(input), {
    defaults: true,
    longs: Number,
  }) as ICargoType;
};

export const getTypeBySubtype = (
  cargoBodyTypes: transport.Vehicle.Body.IType[],
  cargoBodySubtypeId: string,
): transport.Vehicle.Body.IType => {
  let cargoBodyType: transport.Vehicle.Body.IType = {};
  cargoBodyTypes.forEach(type => {
    type.subtypes?.forEach(subType => {
      if (subType.id === cargoBodySubtypeId) {
        cargoBodyType = type;
      }
    });
  });

  return cargoBodyType;
};

export const packageTypes: { id: TCargoPackagingTypeCode; label: string }[] = [
  { id: 'PALLETS', label: 'shared.orderPage.descriptionCard.packagingType.value.pallets' },
  { id: 'BOXES', label: 'shared.orderPage.descriptionCard.packagingType.value.boxes' },
  { id: 'BUNDLES', label: 'shared.orderPage.descriptionCard.packagingType.value.bundles' },
  { id: 'PLACER', label: 'shared.orderPage.descriptionCard.packagingType.value.placer' },
];
