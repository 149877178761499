import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'transport-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnCardComponent {
  @Input() public title = '';

  @Input() public collapsible = false;

  @Input() public hasErrors = false;

  @Input() public hasIndicator = false;

  @Input() public shoudHighlightErrors = false;

  public collapsed = false;

  public toggle() {
    this.collapsed = !this.collapsed;
  }
}
