import { ChangeDetectionStrategy, Component, HostBinding, Input } from '@angular/core';

@Component({
  selector: 'transport-driver-status-label',
  templateUrl: './driver-status-label.component.html',
  styleUrls: ['./driver-status-label.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnDriverStatusLabelComponent {
  @Input() public usesDriverMobile?: boolean | null = false;

  @HostBinding('style.background')
  public get background() {
    return Boolean(this.usesDriverMobile) ? '#DCEDC8' : '#FFF3E0';
  }

  @HostBinding('style.color')
  public get color() {
    return Boolean(this.usesDriverMobile) ? '#7CB342' : '#78909C';
  }

  public get icon() {
    return Boolean(this.usesDriverMobile) ? 'application-installed' : 'application-not-installed';
  }

  public get usesDriverMobileText() {
    return `carrier.directory.driver.form.application.${Boolean(this.usesDriverMobile) ? 'installed' : 'notInstalled'}`;
  }

  constructor() {}
}
