import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import {
  TnReconcilationActModel,
  TnReconcilationActOrganization,
  TnReconcilationActRule,
  TUserPredefinedAction,
  USER_ROLE,
} from '@transport/ui-interfaces';

import * as actDetailsActions from '../actions/reconciliation-act.actions';
import * as apiActions from '../actions/reconciliation-act-api.actions';

export interface IState extends EntityState<TnReconcilationActModel> {
  act: TnReconcilationActModel | null;
  organizations: TnReconcilationActOrganization[];
  userOrganization: TnReconcilationActOrganization | null;
  rules: TnReconcilationActRule[];
  formMode: TUserPredefinedAction | null;
  isFormValid: boolean;
  isFormDirty: boolean;
  needScrollToResultsAfterPageInit: boolean;
  completedActsGuid: string[];
}

export const adapter: EntityAdapter<TnReconcilationActModel> = createEntityAdapter<TnReconcilationActModel>({
  selectId: item => item.guid,
});

export const initialState: IState = adapter.getInitialState({
  organizations: [],
  rules: [],
  formMode: null,
  act: null,
  isFormValid: false,
  isFormDirty: false,
  userOrganization: null,
  needScrollToResultsAfterPageInit: false,
  completedActsGuid: [],
});

export const reducer = createReducer(
  initialState,

  on(actDetailsActions.reconciliationActSetFormMode, (state, { formMode }) => ({
    ...state,
    formMode,
  })),

  on(apiActions.loadReconciliationActSuccess, (state, { act }) => ({ ...state, act })),

  on(actDetailsActions.reconciliationActDetailsPageScrolledToResults, state => ({
    ...state,
    needScrollToResultsAfterPageInit: false,
  })),

  on(apiActions.saveReconciliationActSuccess, state => ({
    ...state,
    isFormDirty: false,
    needScrollToResultsAfterPageInit: true,
  })),

  on(actDetailsActions.reconciliationActDetailsPageDestroyed, state => ({
    ...state,
    act: null,
    isFormDirty: false,
    formMode: null,
  })),

  on(apiActions.websocketCompleteMessageReceived, (state, { message }) => {
    return adapter.updateOne({ id: message.guid, changes: { status: message.status, diff: message.diff } }, state);
  }),

  on(actDetailsActions.addCompletedActsAndUpdateSelectedAct, (state, { message }) => {
    const newState = {
      ...state,
      completedActsGuid: [...state.completedActsGuid, message.guid],
    };

    if (Boolean(state.act) && message.guid === state.act?.guid) {
      const newAct = {
        ...state.act,
        status: message.status,
        errorType: message.errorType,
        diff: message.diff,
        resultsFileFull: message.resultsFileFull,
        resultsFileDiffOnly: message.resultsFileDiffOnly,
      };
      return { ...newState, act: newAct };
    }
    return newState;
  }),

  on(actDetailsActions.reconciliationActSetFormMode, (state, { formMode }) => {
    if (formMode === 'create') {
      const newAct = new TnReconcilationActModel();
      return { ...state, act: newAct };
    }
    return state;
  }),

  on(apiActions.loadReconciliationOrganizationsSuccess, (state, { organizations }) => ({
    ...state,
    organizations,
  })),

  on(apiActions.loadReconciliationMyOrganizationSuccess, (state, { organization }) => {
    const isCarrier = organization.role === USER_ROLE.CARRIER;
    let newAct;
    if (isCarrier) {
      newAct = {
        ...state.act,
        firstOrganization: organization,
        firstRule: organization.defaultRule,
      } as TnReconcilationActModel;
    } else {
      newAct = {
        ...state.act,
        secondOrganization: organization,
        secondRule: organization.defaultRule,
      } as TnReconcilationActModel;
    }

    return { ...state, act: newAct, userOrganization: organization };
  }),

  on(apiActions.loadReconciliationRulesSuccess, (state, { rules }) => ({
    ...state,
    rules,
  })),

  on(actDetailsActions.reconciliationActDetailsFormValidStatusChanged, (state, { isValid }) => ({
    ...state,
    isFormValid: isValid,
  })),

  on(actDetailsActions.reconciliationActDetailsFormDirtyStatusChanged, (state, { isDirty }) => ({
    ...state,
    isFormDirty: isDirty,
  })),
);

export const { selectIds, selectEntities } = adapter.getSelectors();
