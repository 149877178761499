export interface IValueTextType {
  value: number;
  text: string;
}

export interface IRouteDataModel {
  type: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: tech debt
  boundedBy: any[];
  distance: IValueTextType;
  duration: IValueTextType;
  durationInTraffic: IValueTextType;
}
