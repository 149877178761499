export interface IActionButton {
  icon: string;
  name: string | TActionsRowButtons;
  label: string;
  isCustomIcon?: boolean;
}
export type TActionsRowButtons = 'view' |
  'copy' |
  'edit' |
  'action-remove' |
  'download' |
  'contract-remove' |
  'upload' |
  'addGroup' |
  'addBlock';
