export const toolbarLocalization = {
  pageTitle: {
    myReconciliationActs: 'Мои акты сверки',
    cargoTypes: 'Типы грузов',
    usersManagement: 'Управление пользователями',
    ordersProcessingTime: 'Время обработки заявок',
    insuranceManagement: 'Управление страхованием',
    companyProfile: 'Настройки',
    routing: 'Маршрутизация',
  },
};
