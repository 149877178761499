import { TnUploadedFile } from '../files/uploaded-file';
import { RECONCILIATION_ACT_DIFF_TYPE } from './reconciliation-act-diff-type';
import { RECONCILIATION_ACT_ERROR_TYPE } from './reconciliation-act-error-type';
import { RECONCILIATION_ACT_STATUS } from './reconciliation-act-status';

export interface IReconcilationActResults {
  status: RECONCILIATION_ACT_STATUS | null;
  diff: RECONCILIATION_ACT_DIFF_TYPE | null;
  errorType: RECONCILIATION_ACT_ERROR_TYPE | null;
  fileFull: TnUploadedFile | null;
  fileDiffOnly: TnUploadedFile | null;
}
