import { createSelector } from '@ngrx/store';
import { transport } from '@transport/proto';
import { DOCUMENT_STATUS, DOCUMENT_TYPE, IOrder, ORDER_STATUS } from '@transport/ui-interfaces';

// Class to share selectors between orders and detail page states
export abstract class TnCryptoSelectors {
  public abstract getOrder;

  // eslint-disable-next-line max-lines-per-function -- TODO: tech debt
  public selectors() {
    const orderStatus = createSelector(this.getOrder, (order: IOrder, props: { docType: DOCUMENT_TYPE; order?: IOrder }) => {
      return order?.status;
    });

    const getActualStatus = createSelector(this.getOrder, (order: IOrder, props: { docType: DOCUMENT_TYPE; order?: IOrder }) => {
      return order?.documentActualStatus?.find(item => item?.documentType?.innerName === props.docType);
    });

    const getDocumentStatusHistory = createSelector(this.getOrder, (order: IOrder, props: { docType: DOCUMENT_TYPE; order?: IOrder }) => {
      return order?.documentStatusHistory?.filter(item => item?.documentType?.innerName === props.docType);
    });

    const isTnCryptoDocumentSigned = createSelector(
      getActualStatus,
      (actualStatus: transport.Order.IDocumentStatus | undefined, props: { docType: DOCUMENT_TYPE; order?: IOrder }) => {
        return actualStatus?.status === DOCUMENT_STATUS.SIGNED;
      },
    );

    const isTnCryptoDocumentOwnerSigned = createSelector(
      getDocumentStatusHistory,
      (documentStatusHistory: transport.Order.IDocumentStatus[] | undefined, props: { docType: DOCUMENT_TYPE; order?: IOrder }) => {
        return Boolean(documentStatusHistory?.map(item => item.status).find(status => status === DOCUMENT_STATUS.CARGO_OWNER_SIGNATURE_OK));
      },
    );

    const isTnCryptoDocumentCarrierChecking = createSelector(
      getActualStatus,
      (actualStatus: transport.Order.IDocumentStatus | undefined, props: { docType: DOCUMENT_TYPE; order?: IOrder }) => {
        return actualStatus?.status === DOCUMENT_STATUS.CARRIER_SIGNATURE_CHECKING;
      },
    );

    const isDocumentSignAvailable = createSelector(
      isTnCryptoDocumentSigned,
      isTnCryptoDocumentOwnerSigned,
      isTnCryptoDocumentCarrierChecking,
      orderStatus,
      (
        isTnDocumentSigned: boolean,
        isTnDocumentOwnerSigned: boolean,
        isTnDocumentCarrierChecking: boolean,
        currentOrderStatus: ORDER_STATUS,
        props: { docType: DOCUMENT_TYPE; order?: IOrder },
      ) => {
        return (
          currentOrderStatus !== ORDER_STATUS.CANCELED &&
          currentOrderStatus !== ORDER_STATUS.COMPLETED &&
          !isTnDocumentSigned &&
          isTnDocumentOwnerSigned &&
          !isTnDocumentCarrierChecking
        );
      },
    );

    const isTnCryptoDocumentSigningProcess = createSelector(
      getActualStatus,
      (actualStatus: transport.Order.IDocumentStatus | undefined, props: { docType: DOCUMENT_TYPE; order?: IOrder }) => {
        const statuses: (string | null | undefined)[] = [
          DOCUMENT_STATUS.CARGO_OWNER_SIGNING,
          DOCUMENT_STATUS.CARGO_OWNER_SIGNATURE_CHECKING,
          DOCUMENT_STATUS.CARRIER_SIGNATURE_CHECKING,
        ];
        return statuses.includes(actualStatus?.status);
      },
    );

    const tnCryptoCarrierSignRequired = createSelector(
      getActualStatus,
      (actualStatus: transport.Order.IDocumentStatus | undefined, props: { docType: DOCUMENT_TYPE; order?: IOrder }) => {
        return actualStatus?.status === DOCUMENT_STATUS.CARGO_OWNER_SIGNATURE_OK;
      },
    );

    const isTnCryptoDocumentSigningError = createSelector(
      getActualStatus,
      (actualStatus: transport.Order.IDocumentStatus | undefined, props: { docType: DOCUMENT_TYPE; order?: IOrder }) => {
        return (
          actualStatus?.status === DOCUMENT_STATUS.CARGO_OWNER_SIGNATURE_ERROR ||
          actualStatus?.status === DOCUMENT_STATUS.CARRIER_SIGNATURE_ERROR
        );
      },
    );

    const isTnCryptoCarrierSigningError = createSelector(
      getActualStatus,
      (actualStatus: transport.Order.IDocumentStatus | undefined, props: { docType: DOCUMENT_TYPE; order?: IOrder }) => {
        return actualStatus?.status === DOCUMENT_STATUS.CARRIER_SIGNATURE_ERROR;
      },
    );

    const isTnCryptoOwnerSigningError = createSelector(
      getActualStatus,
      (actualStatus: transport.Order.IDocumentStatus | undefined, props: { docType: DOCUMENT_TYPE; order?: IOrder }) => {
        return actualStatus?.status === DOCUMENT_STATUS.CARGO_OWNER_SIGNATURE_ERROR;
      },
    );

    const isTnCryptoOwnerChecking = createSelector(
      getActualStatus,
      (actualStatus: transport.Order.IDocumentStatus | undefined, props: { docType: DOCUMENT_TYPE; order?: IOrder }) => {
        return actualStatus?.status === DOCUMENT_STATUS.CARGO_OWNER_SIGNATURE_CHECKING;
      },
    );

    const isTnCryptoOwnerSigning = createSelector(
      getActualStatus,
      (actualStatus: transport.Order.IDocumentStatus | undefined, props: { docType: DOCUMENT_TYPE; order?: IOrder }) => {
        return actualStatus?.status === DOCUMENT_STATUS.CARGO_OWNER_SIGNING;
      },
    );

    const isTnCryptoDocumentOwnerSigning = createSelector(
      isTnCryptoOwnerChecking,
      isTnCryptoOwnerSigning,
      isTnCryptoOwnerSigningError,
      (
        isOwnerChecking: boolean,
        isOwnerSigning: boolean,
        isOwnerSignningError: boolean,
        props: { docType: DOCUMENT_TYPE; order?: IOrder },
      ) => {
        return isOwnerChecking || isOwnerSigning || isOwnerSignningError;
      },
    );

    const isTerminationArgeementInProgress = createSelector(
      isTnCryptoOwnerSigningError,
      isTnCryptoCarrierSigningError,
      isTnCryptoDocumentSigned,
      getActualStatus,
      (
        isOwnerSigningError: boolean,
        isCarrierSigningError: boolean,
        isDocumentSigned: boolean,
        actualStatus: transport.Order.IDocumentStatus | undefined,
        props: { docType: DOCUMENT_TYPE; order?: IOrder } = {
          docType: DOCUMENT_TYPE.TERMINATION_AGREEMENT,
        },
      ) => {
        return Boolean(actualStatus) && (!isDocumentSigned || isOwnerSigningError || isCarrierSigningError);
      },
    );

    return {
      getActualStatus: getActualStatus,
      getDocumentStatusHistory: getDocumentStatusHistory,
      isTnCryptoDocumentSigned,
      isTnCryptoDocumentOwnerSigned,
      isTnCryptoDocumentCarrierChecking,
      isDocumentSignAvailable,
      isTnCryptoDocumentSigningProcess,
      tnCryptoCarrierSignRequired,
      isTnCryptoDocumentSigningError,
      isTnCryptoCarrierSigningError,
      isTnCryptoOwnerSigningError,
      isTnCryptoOwnerChecking,
      isTnCryptoOwnerSigning,
      isTnCryptoDocumentOwnerSigning,
      isTerminationArgeementInProgress,
    };
  }
}
