import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { transport } from '@transport/proto';
import { TnHttpHandlersService } from '@transport/ui-store';
import { TONNA_MARKETPLACE_LOGO_MINI_URL, TONNA_MARKETPLACE_LOGO_URL } from '@transport/ui-utils';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';
import { EMPTY } from 'rxjs';
import { catchError } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'transport-marketplace-auth-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnAuthHeaderComponent implements OnInit {
  public logoSrc = TONNA_MARKETPLACE_LOGO_URL;

  public logoMiniSrc = TONNA_MARKETPLACE_LOGO_MINI_URL;

  public organizationName;

  public organizationLogo;

  public organizationLoading = false;

  public isInvite = Boolean(this.domainService.subDomain);

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly http: HttpClient,
    private readonly handlers: TnHttpHandlersService,
    private readonly domainService: TnDomainService,
  ) {}

  public ngOnInit() {
    if (Boolean(this.isInvite)) {
      this.organizationLoading = true;
      this.http
        .get<transport.InvitedOwnerOrganization>(this.handlers.getEndpointUrl('/auth/organization_by_domain'))
        .pipe(
          untilDestroyed(this),
          catchError(() => {
            this.isInvite = false;
            this.cdr.markForCheck();
            this.organizationLoading = false;
            return EMPTY;
          }),
        )
        .subscribe((organization: transport.InvitedOwnerOrganization) => {
          if (organization.logo) this.organizationLogo = organization.logo;
          if (organization.name) this.organizationName = organization.name;
          this.organizationLoading = false;
          this.cdr.markForCheck();
        });
    }
  }

}
