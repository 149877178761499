import { Pipe, PipeTransform } from '@angular/core';
import { regExpConfig } from '@transport/ui-utils';
import memo from 'memo-decorator';
import { NgxMaskService } from 'ngx-mask';

/**
 * Pipe for sts number 12 12 123456
 */
@Pipe({
  name: 'stsNumber',
})
export class TnStsNumberPipe implements PipeTransform {
  constructor(private readonly maskService: NgxMaskService) {}

  /**
   * Transform sts number string to special string format
   * @param value Value string
   */
  @memo()
  public transform(value: number | string): string {
    if (value === '' || value === null) {
      return '';
    }
    const val = typeof value === 'string' ? value : value.toString();
    return Boolean(value)
      ? this.maskService.applyMaskWithPattern(val, [
          regExpConfig.ngxMask.vehiclePassport.ru.registrationCertificate.mask,
          regExpConfig.ngxMask.vehiclePassport.ru.registrationCertificate.patterns ?? {},
        ])
      : '';
  }
}
