import { createAction, props } from '@ngrx/store';
import { IPaginationData } from '@transport/ui-interfaces';

import { REFRESH_TYPE } from './table-state.interface';

export function makeTableActions(tableName: string) {
  const setPageActionType = `[Table pagination] Set page for table ${tableName}`;
  const setTablePageAction = createAction(setPageActionType, props<Partial<IPaginationData>>());

  const saveSelectionItems = createAction(`[Table state] save selection items ${tableName}`, props<{ selected: Map<string, boolean> }>());

  const setFilterActionType = `[Table filter] Set filter for table ${tableName}`;
  const setTableFilterAction = createAction(setFilterActionType, props<{ filterSettings: Record<string, string> }>());

  const setSortingActionType = `[Table sorting] Set sort for table ${tableName}`;
  const resetPaginationActionType = `[Table pagination] Reset pagination for table ${tableName}`;
  const resetPaginationAction = createAction(resetPaginationActionType);
  const setTableSortingAction = createAction(setSortingActionType, props<{ sortSettings: Record<string, string> }>());

  const setRefreshState = createAction(`[Table refresh] set refresh state`, props<{ refresh: REFRESH_TYPE }>());

  return {
    setTablePageAction,
    setTableFilterAction,
    setTableSortingAction,
    resetPaginationAction,
    saveSelectionItems,
    setRefreshState,
  };
}
