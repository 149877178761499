import { Directive, Input, OnDestroy } from '@angular/core';

/**
 * Useb by click stop propagation directive.
 */
@Directive()
export class TnCleanerDirective implements OnDestroy {
  @Input() protected eventOptions = {
    preventDefault: null,
    stopProp: null,
  };

  protected unsubscribe?: () => void;

  public static handleEvents({ preventDefault, stopProp }, event: Event) {
    if (Boolean(preventDefault)) {
      event.preventDefault();
    }
    if (Boolean(stopProp)) {
      event.stopPropagation();
    }
  }

  public ngOnDestroy() {
    if (typeof this.unsubscribe !== 'undefined') {
      this.unsubscribe();
    }
  }
}
