import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ContentChild, Input, OnInit, TemplateRef } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';
import { Observable, Subject } from 'rxjs';
import { startWith, switchMap, tap, debounceTime, filter, scan, exhaustMap, takeWhile } from 'rxjs/operators';
import { TIMEOUT } from '@transport/ui-utils';

export interface CommonAutocompleteItem {
  id: number;
  name: string;

  [key: string]: any;
}

@Component({
  selector: 'common-autocomplete[control][getItems]',
  templateUrl: 'common-autocomplete.html',
  styleUrls: ['common-autocomplete.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonAutocompleteComponent implements OnInit, AfterViewInit {
  public filteredLookups$!: Observable<CommonAutocompleteItem[]>;
  public customOptionTemplate = false;
  private nextPage$ = new Subject();

  @ContentChild(TemplateRef) template!: TemplateRef<any>;
  
  @Input() public size: 'sm' | 'md' | 'lg' = 'sm';
  @Input() public errorsSize: 'xs' | 'sm' | 'md' | 'lg' = 'xs';
  @Input() public inputFieldClass = '';
  @Input() public label?: string = '';
  @Input() public labelClasses?: string[];
  @Input() public labelAddition?: string;
  @Input() public caption?: string;
  @Input() public captionClasses?: string[];
  @Input() public showErrors = true;
  @Input() public required = false;
  @Input() public disabled = false;

  @Input() pageSize = 10;
  @Input() placeholder = '';
  @Input() optionClasses = '';
  @Input() control!: FormControl;
  @Input() getItems!: (filter: string, offset: number, limit: number) => Observable<CommonAutocompleteItem[]>;
  @Input() hideOptionCondition: (option: CommonAutocompleteItem) => boolean = () => false;
  @Input() displayWith = (item: CommonAutocompleteItem) =>  item ? item.name : '';

  @Input() public errorToMessage: (errors: ValidationErrors | null) => string = () => 'Error';

  constructor(
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    const filter$ = this.control.valueChanges.pipe(
      startWith(''),
      debounceTime(TIMEOUT.SHORTER),
      filter(q => typeof q === "string")
    );
    this.filteredLookups$ = filter$.pipe(
      switchMap(filter => {
        let currentPage = 1;
        return this.nextPage$.pipe(
          startWith(currentPage),
          exhaustMap(() => this.getItems(filter as string, currentPage, this.pageSize)),
          tap(() => currentPage++),
          
          takeWhile(p => p.length > 0),
          scan((allOptions: CommonAutocompleteItem[], newOptions: CommonAutocompleteItem[]) => allOptions.concat(newOptions), []),
        );
      }));
  }

  ngAfterViewInit() {
    if (this.template) {
      this.customOptionTemplate = true;  
      this.cdr.markForCheck();      
    }
  }

  onScroll() {
    this.nextPage$.next(true);
  }
}