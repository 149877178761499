import { ActionReducerMap } from '@ngrx/store';

import { TnAgentCarrierEffects } from './carrier/agent-carrier-page/effects/agent-carrier.effects';
import { agentCarrierReducer } from './carrier/agent-carrier-page/reducers/agent-carrier.reducer';
import { TnAgentCarriersEffects } from './carrier/agent-carriers-table/effects/agent-carriers.effects';
import { agentCarriersReducer } from './carrier/agent-carriers-table/reducers/agent-carriers.reducer';
import { TnDriverEffects } from './carrier/driver-page/effects/driver.effects';
import { driverReducer } from './carrier/driver-page/reducers/driver.reducer';
import { TnDriversEffects } from './carrier/drivers-table/effects/drivers.effects';
import { chooseDriversReducer } from './carrier/drivers-table/reducers/choose-drivers.reducer';
import { driversReducer } from './carrier/drivers-table/reducers/drivers.reducer';
import { TnTruckEffects } from './carrier/truck-page/effects/truck.effects';
import { truckReducer } from './carrier/truck-page/reducers/truck.reducer';
import { TnTrucksEffects } from './carrier/trucks-table/effects/trucks.effects';
import { chooseTrucksReducer } from './carrier/trucks-table/reducers/choose-trucks.reducer';
import { trucksReducer } from './carrier/trucks-table/reducers/trucks.reducer';
import { TnCargoTypesEffects } from './owner/cargo-types-table/effects/cargo-types.effects';
import { cargoTypeReducer } from './owner/cargo-types-table/reducers/cargo-type.reducer';
import { cargoTypesReducer } from './owner/cargo-types-table/reducers/cargo-types.reducer';
import { TnCarriersFilterListEffects } from './owner/carrier-filter-list/effects/carrier-filter-list.effects';
import { carrierFilterListReducer } from './owner/carrier-filter-list/reducers/carrier-filter-list.reducer';
import { TnCarriersGroupEffects } from './owner/carriers-group-page/effects/carrier-group.effects';
import { carrierGroupReducer } from './owner/carriers-group-page/reducers/carriers-group.reducer';
import { carrierGroupsReducer } from './owner/carriers-groups-table/reducers/carrier-groups.reducer';
import { carriersReducer } from './owner/carriers-table/reducers/carriers.reducer';
import { TnLoadingPlaceEffects } from './owner/loading-places-table/effects/loading-places.effects';
import { loadingPlacesReducer } from './owner/loading-places-table/reducers/loading-places.reducer';

export interface IDirectoryState {
  loadingPlaces: ReturnType<typeof loadingPlacesReducer>;
  drivers: ReturnType<typeof driversReducer>;
  trucks: ReturnType<typeof trucksReducer>;
  chooseTrucks: ReturnType<typeof chooseTrucksReducer>;
  chooseDrivers: ReturnType<typeof chooseDriversReducer>;
  truck: ReturnType<typeof truckReducer>;
  cargoTypes: ReturnType<typeof cargoTypesReducer>;
  cargoType: ReturnType<typeof cargoTypeReducer>;
  carriers: ReturnType<typeof carriersReducer>;
  carrierGroups: ReturnType<typeof carrierGroupsReducer>;
  carrierGroup: ReturnType<typeof carrierGroupReducer>;
  carrierFilterList: ReturnType<typeof carrierFilterListReducer>;
  driver: ReturnType<typeof driverReducer>;
  agentCarriers: ReturnType<typeof agentCarriersReducer>;
  agentCarrier: ReturnType<typeof agentCarrierReducer>;
}

export const directoryReducers: ActionReducerMap<IDirectoryState> = {
  loadingPlaces: loadingPlacesReducer,
  drivers: driversReducer,
  trucks: trucksReducer,
  chooseTrucks: chooseTrucksReducer,
  chooseDrivers: chooseDriversReducer,
  truck: truckReducer,
  cargoTypes: cargoTypesReducer,
  cargoType: cargoTypeReducer,
  carriers: carriersReducer,
  carrierGroups: carrierGroupsReducer,
  carrierGroup: carrierGroupReducer,
  carrierFilterList: carrierFilterListReducer,
  driver: driverReducer,
  agentCarriers: agentCarriersReducer,
  agentCarrier: agentCarrierReducer,
};

export const DIRECTORY_EFFECTS = [
  TnCarriersGroupEffects,
  TnCarriersFilterListEffects,
  TnCargoTypesEffects,
  TnLoadingPlaceEffects,
  TnDriverEffects,
  TnDriversEffects,
  TnTruckEffects,
  TnTrucksEffects,
  TnAgentCarriersEffects,
  TnAgentCarrierEffects,
];
