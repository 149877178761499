import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
  APP_ENV,
  ILoginByCodeRequestFormData,
  IRegByCodeFormData,
  TnAppEnvironment,
  TOAST_DURATION,
  USER_ROLE,
} from '@transport/ui-interfaces';
import { TnAlertService } from '@transport/ui-kit';
import { TnClSsoFrameService, TnGqlClientCarrierService, TnToasterFacade, TnUserService, TOAST_TYPE } from '@transport/ui-store';
import { SEND_CODE_TIMEOUT } from '@transport/ui-utils';
import { CodeInputComponent } from 'angular-code-input';
import { ALERT_STATE } from 'libs/transport-ui-kit/src/lib/alert/alert.interfaces';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';
import { TnAnalyticsService } from 'libs/transport-ui-store/src/lib/current-user/services/analytics/analytics.service';
import moment from 'moment';
import { catchError, interval, startWith, Subject, switchMap, take, tap, throwError, combineLatest, first, concatMap, of } from 'rxjs';

import { TnMarketplaceAuthService } from '../../shared/services/marketplace-auth.service';
import { RedirectAfterLoginService } from '../../shared/services/redirect-after-login.service';
import { TnCurrentUserService } from '../../shared/services/user/current-user.service';

const ONE_SECOND = 1000;

@UntilDestroy()
@Component({
  selector: 'transport-submit-code',
  templateUrl: './submit-code.component.html',
  styleUrls: ['./submit-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnMarketplaceSubmitCodeComponent implements OnInit, OnDestroy {
  public email = '';

  public timer = '';

  private readonly destroySubject$ = new Subject();

  public timer$;

  private token: string | undefined;

  public loginErrorMsg: string | null = null;

  @ViewChild('codeInput') codeInput!: CodeInputComponent;

  @ViewChild('invite') public inviteRef?: TemplateRef<never>;

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly authService: TnMarketplaceAuthService,
    private readonly tnCurrentUserService: TnCurrentUserService,
    private readonly redirectAfterLogin: RedirectAfterLoginService,
    private readonly toastFacade: TnToasterFacade,
    private readonly route: ActivatedRoute,
    private readonly translate: TranslateService,
    private readonly ssoService: TnClSsoFrameService,
    private readonly alertService: TnAlertService,
    private readonly analytics: TnAnalyticsService,
    private readonly carrierGqlService: TnGqlClientCarrierService,
    private readonly domainSerice: TnDomainService,
    private readonly userService: TnUserService,
    @Inject(APP_ENV) public readonly env: TnAppEnvironment,
  ) {}

  public get isRegForm() {
    return this.isRegData(this.authService.authenticationData);
  }

  public ngOnInit(): void {
    if (!this.authService.authenticationData) {
      this.router.navigate(['passport/login']);
    } else {
      this.email = this.authService.authenticationData.email;
      if (this.authService.sendCodeAfterOpenForm) {
        this.authService.sendCodeAfterOpenForm = false;
        this.sendCode();
        return;
      }
      this.token = this.route.snapshot.paramMap.get('token')?.toString();
      this.startTimer();
    }
  }

  private startTimer() {
    let timeLeft = SEND_CODE_TIMEOUT;
    this.timer$ = interval(ONE_SECOND);
    this.timer$.pipe(startWith(SEND_CODE_TIMEOUT),take(timeLeft / ONE_SECOND)).subscribe(() => {
      timeLeft = timeLeft - ONE_SECOND;
      this.timer = timeLeft > 0 ? moment(timeLeft).format('mm:ss') : '';
      this.cdr.markForCheck();
    });
  }

  public ngOnDestroy(): void {
    this.destroySubject$.next(null);
  }

  public onCodeCompleted(code: string) {
    this.authentication(code);
  }

  public sendCode() {
    this.loginErrorMsg = null;
    if (this.authService.authenticationData) {
      this.startTimer();
      const data = { email: this.authService.authenticationData.email };
      this.authService
        .sendAuthenticationCode(data)
        .pipe(
          untilDestroyed(this),
          catchError(error => {
            this.toastFacade.showMessage(error, TOAST_TYPE.ERROR, TOAST_DURATION.SHORT);
            return throwError(() => error);
          }),
        )
        .subscribe();
    }
  }

  public goBack() {
    if (this.isRegData(this.authService.authenticationData)) {
      this.router.navigate(['passport/registration']);
    } else {
      this.router.navigate(['passport/login']);
    }
  }

  private isRegData(data: ILoginByCodeRequestFormData | IRegByCodeFormData | undefined): data is IRegByCodeFormData {
    return Boolean((data as IRegByCodeFormData)?.companyName);
  }

  private authentication(code: string) {
    this.loginErrorMsg = null;
    const formData = {
      email: this.email,
      code: code,
    };
    this.authService
      .loginByCode(formData)
      .pipe(
        untilDestroyed(this),
        catchError(error => {
          this.codeInput.reset();
          this.analytics.track('user entered code with error ', { error });
          this.loginErrorMsg = error;
          this.cdr.markForCheck();
          return throwError(() => error);
        }),
      )
      .subscribe(loginResponse => {
        this.analytics.track('user entered correct code');

        // Для корректного редиректа ГВ на МастерТМС после подтверждения почты
        const isRoleRegistred = localStorage.getItem('isRoleRegistred') ?? '';
        localStorage.removeItem('isRoleRegistred');
        if (loginResponse?.role === USER_ROLE.OWNER && isRoleRegistred === USER_ROLE.OWNER) {
          this.tnCurrentUserService.saveUser({ ...loginResponse, firstLogin: true });
          this.userService.setCookie('firstLogin', true);
          window.location.href = `${this.env.tmsDomain}/`;
        }
        this.tnCurrentUserService.saveUser(loginResponse);
        this.ssoService.login(loginResponse);

        const authData = this.authService.authenticationData as IRegByCodeFormData;
        if (Boolean(this.authService.inviteToken) || Boolean(authData?.token)) {
          this.createBusinessRelationship((this.authService.inviteToken ?? authData?.token) as string);
        }

        if (this.domainSerice.subDomain === null) {
          this.router.navigate([this.redirectAfterLogin.workspacesRoute]);
        } else {
          const currentRoute = this.redirectAfterLogin.popUrlFromLocalStorage();
          if (currentRoute) {
            this.router.navigateByUrl(currentRoute);
          }
          this.router.navigate([this.redirectAfterLogin.defaultRoute]);
        }
      });
  }

  private createBusinessRelationship(token: string): void {
    this.authService
      .invitationUserStatusWithHeaders(token)
      .pipe(
        first(),
        switchMap(() => this.carrierGqlService.queryOrganizationAccrstatusBySubdomain(this.domainSerice.subDomain as string)),
        tap(({ carrierBusinessRelationshipState }) => {
          if (carrierBusinessRelationshipState !== 'ON_ACCREDITATION') return;
          const alertInput = {
            title: this.translate.instant('shared.passport.passportCreateCompany.form.success.invitation'),
            state: ALERT_STATE.SUCCESS,
            message: this.translate.instant('shared.passport.confirmEmail.invitationOrdersAccess'),
          };
          this.alertService.openAlert(alertInput, TOAST_DURATION.LONG);
        }),
      )
      .subscribe(() => (this.authService.inviteToken = undefined));
  }
}
