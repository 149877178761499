import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'transport-string-info',
  templateUrl: './string-info.component.html',
  styleUrls: ['./string-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnStringInfoComponent {
  @Input() public label = '';

  @Input() public body?: string | number | null = '';

  @Input() public delimeter = false;

  @Input() public isBodyNoWrap = false;

  @Input() public isBodyBold = false;
}
