import { AfterViewInit, ChangeDetectorRef, Directive, ViewChild } from '@angular/core';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { Sort, SortDirection } from '@angular/material/sort';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { transport } from '@transport/proto';
import { IPager, IPaginationData, ORDER_ALLOCATION_TYPE } from '@transport/ui-interfaces';
import { ITableStateFacade, REFRESH_TYPE } from '@transport/ui-store';
import { TIMEOUT } from '@transport/ui-utils';
import { BehaviorSubject, combineLatest, Observable, of, Subscription, timer } from 'rxjs';
import { catchError, concatMap, delay, filter, scan, switchMap, tap } from 'rxjs/operators';

import { ITableSelection } from '../table/table.interfaces';

// Параметры таблицы в URL не отслеживаются, из URL берутся параметры таблицы только один раз при инициализации (в AfterViewInit)

@UntilDestroy()
@Directive()
export abstract class TnTableList<T extends { id?: string | null }, T1Filter = unknown> implements AfterViewInit {
  @ViewChild('paginator') public paginator: MatPaginator = {} as MatPaginator;

  public paginationData: PageEvent = new PageEvent();

  public pageSizeOptions: number[] = [];

  public tableName = '';

  public dataSource: T[] = [];

  public selectedRows = new Map<string, T>();

  private readonly loadingSubject = new BehaviorSubject<boolean>(true);

  // Испускает значение перед загрузкой данных и после.
  public readonly loading$ = this.loadingSubject.asObservable();

  private readonly isEmptySubject = new BehaviorSubject<boolean>(false);

  // Испускает значение: Есть ли данные после обновления
  public readonly isEmpty$ = this.isEmptySubject.asObservable();

  // Интервал для испускания события REFRESH_TYPE.PERIODICAL
  protected refreshInterval = TIMEOUT.LONG;

  // Этот параметр переопределять в конструкторе наследника
  public hasNavigation = true;

  private refreshTimerSub?: Subscription;

  protected constructor(
    public readonly router: Router,
    public readonly route: ActivatedRoute,
    public readonly tableStateFacade: ITableStateFacade<T1Filter>,
    public readonly cdRef: ChangeDetectorRef,
  ) {}

  public ngAfterViewInit() {
    if (this.hasNavigation) {
      this.initRouterSubscriptions();
      this.initPageStateFromCurrentRoute();
      this.initFilterSettings();
      this.initSortSettings();
    } else {
      this.initLoadSubscription();
    }
    this.initTableStateParamsSubscription();
    this.refreshTableSubscription();
    this.cdRef.detectChanges();
  }

  public abstract getList(
    pageSize?: number,
    offset?: number,
    filterSetting?: T1Filter,
    sortSetting?: Sort,
  ): Observable<{ list: T[]; totalCount: number }>;

  // Используется для внешних целей. В частности для обработки события (page) у <mat-paginator>
  public changePage(event: IPager) {
    this.page(event.pageSize, event.pageIndex);
  }

  // Используется для внешних целей.
  public reloadPage() {
    const { pageSize, pageIndex } = this.paginator ?? this.paginationData;
    this.tableStateFacade.changePage(pageSize, pageIndex);
  }

  // Используется для внешних целей. Дополняет фильтры новыми параметрами.
  public filter(filterParams?: Partial<T1Filter>, initial = false) {
    if (!initial) {
      this.tableStateFacade.changePage((this.paginator ?? this.paginationData).pageSize, 0); //???
    }
    const preparedFilter = clearObjectFromEmptyParams({
      ...this.tableStateFacade.currentFilter,
      ...filterParams,
    });
    this.tableStateFacade.setFilterSettings(preparedFilter);
  }

  // Используется для внешних целей. Устанавливает параметры сортировки
  public sort(sortParams: Sort) {
    this.tableStateFacade.changePage((this.paginator ?? this.paginationData).pageSize, 0);
    this.tableStateFacade.setSortSettings(sortParams);
  }

  public changeSelection({ isSingleSelection, isSelected, currentRow }: ITableSelection<T>) {
    this.updateSelectedRows(isSingleSelection, isSelected, currentRow);
  }

  // Используется для внешних целей. Утанавливает параметры страницы
  public page(pageSize: number, pageIndex: number) {
    this.tableStateFacade.changePage(pageSize, pageIndex);
  }

  // Используется для внешних целей. Получает массив, возвращает строку: количество элементов в круглых скобках. Должен быть венесен отсюда!
  public getFieldsCount(list?: Record<string, unknown>[] | null): string {
    if (Boolean(list) && (list as Record<string, unknown>[])?.length > 1) {
      return `(${(list as Record<string, unknown>[]).length})`;
    }
    return '';
  }

  // Используется для внешних целей. Находит переданные данные в dataSource по ключу и заменяет ими этот элемент . Должен быть венесен отсюда!
  public updatePartDataSourceByKey(key: string, updatedData: Partial<T>) {
    const searchResultIndex = this.dataSource.findIndex(item => item[key] === updatedData[key]);
    if (Boolean(searchResultIndex + 1)) {
      this.dataSource = [
        ...this.dataSource.slice(0, searchResultIndex),
        { ...this.dataSource[searchResultIndex], ...updatedData },
        ...this.dataSource.slice(searchResultIndex + 1),
      ];
    }
  }

  public clearSelectedRows() {
    this.selectedRows = new Map();
    this.tableStateFacade.saveSelection(new Map());
  }

  // Используется для внешних целей.
  public toggleRefreshing(stopRefreshing: boolean) {
    return stopRefreshing ? this.stopRefreshing() : this.startRefreshing();
  }

  // Используется для внешних целей. Начать эмитить события REFRESH_TYPE.PERIODICAL с интервалом в this.refreshInterval миллисекунд
  public startRefreshing() {
    this.refreshTimerSub = timer(this.refreshInterval, this.refreshInterval)
      .pipe(
        untilDestroyed(this),
        tap(() => this.tableStateFacade.setRefreshState(REFRESH_TYPE.PERIODICAL)),
      )
      .subscribe();
  }

  // Используется для внешних целей. Прекратить эмитить события REFRESH_TYPE.PERIODICAL
  public stopRefreshing() {
    this.refreshTimerSub?.unsubscribe();
  }

  // Запрашиваем фрейм данных у абстрактного метода getList
  private getListData(): Observable<{ list: T[]; totalCount: number }> {
    const { pageSize, pageIndex } = this.paginator ?? this.paginationData;
    return this.getList(
      pageSize,
      pageIndex * pageSize,
      { ...(this.tableStateFacade.currentFilter ?? ({} as T1Filter)) },
      { ...this.tableStateFacade.currentSort },
    );
  }

  // Преобразует объект в строку вида "key1:value1|key2:value2"
  protected filterToUrlString(filterData: T1Filter): string {
    return Object.keys(filterData as Object)
      .map(key => `${key}:${filterData[key]}`)
      .join('|');
  }

  // Парсит строку вида 'key1:value1|key2:value2', возвращая объект {key1: value1, key2: value2}
  protected parseFilterFromUrlString(filterString: string): T1Filter {
    const result = {};
    filterString
      .split('|')
      .filter(item => Boolean(item))
      .forEach(item => {
        const name = item.split(':')[0];
        const value = item.split(':')[1];
        result[name] = this.parseBooleanString(value);
      });
    return result as T1Filter;
  }

  // Превращает строковые boolean значения в boolean, иначе возвращает ту же строку
  protected parseBooleanString(value: string): boolean | string {
    if (value === 'false' || value === 'true') {
      return value === 'true';
    }
    return value;
  }

  protected updateTable(value: { list: T[] | null }) {
    this.filter(this.tableStateFacade.currentFilter ?? {});
    if (Boolean(value.list)) {
      this.tableStateFacade.setRefreshState(REFRESH_TYPE.SLEEP);
    }
  }

  // Подписываемся на навигацию чтобы обновлять данные при их изменении
  private initRouterSubscriptions() {
    // При начале навигации, притворяемся что начали загрузку данных (Реальной загрузки данных не происходит)
    void this.router.events
      .pipe(
        untilDestroyed(this),
        filter(event => event instanceof NavigationStart),
        tap(() => this.startLoading()),
      )
      .subscribe();

    // При окончании навигации запрашиваем данные с текущими настройками таблицы. (Происходит после того как)
    void this.router.events
      .pipe(
        untilDestroyed(this),
        filter(event => event instanceof NavigationEnd),
        switchMap(() => this.getListData()),
        tap(({ list, totalCount }) => this.finishLoading(list, totalCount)),
      )
      .subscribe();
  }

  // При получении оповещения о загрузке, загружаем данные. Вызывается метод только если не нужно зависеть от навигации.
  private initLoadSubscription() {
    void this.loading$
      .pipe(
        untilDestroyed(this),
        filter(value => value),
        switchMap(() => this.getListData()),
        tap(({ list, totalCount }) => this.finishLoading(list, totalCount)),
      )
      .subscribe();
  }

  // При изменении пареметра пагинации, фильтра, либо сортировки, перенаправляет на этот же адрес с новыми параметрами в URL. Либо начинает загрузку, если hasNavigation == false
  private initTableStateParamsSubscription() {
    void combineLatest([this.tableStateFacade.currentPagination$, this.tableStateFacade.currentFilter$, this.tableStateFacade.currentSort$])
      .pipe(
        delay(0),
        tap(([{ pageSize, page, pageSizeOptions }, filterSetting, sortSetting]) => {
          // Обновляем поля у пагинатора
          if (Boolean(this.paginator)) {
            this.paginator.pageIndex = page;
            this.paginator.pageSize = pageSize;
            this.paginator.pageSizeOptions = pageSizeOptions;
          } else {
            this.paginationData.pageIndex = page;
            this.paginationData.pageSize = pageSize;
            this.pageSizeOptions = pageSizeOptions;
          }

          this.hasNavigation ? this.navigate({ pageSize, page, pageSizeOptions }, filterSetting, sortSetting) : this.startLoading();
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  /**
   * Подписываемся на событие рефреша, на каждое REFRESH_TYPE.FORCED и каждое N-ное событие REFRESH_TYPE.PERIODICAL:
   * 1. запрашиваем данные (не используем их - почему - не понятно)
   * 2. Эмитим событие SLEEP.
   * 3. Заставляем таблицу перерисоваться.
   */
  private refreshTableSubscription() {
    const periodicalEventsNeeded = Math.ceil(TIMEOUT.GIANT / this.refreshInterval);

    // Не понятно почему функция так названа.
    const onServerRefresh = () =>
      this.getListData().pipe(
        catchError(() => {
          this.tableStateFacade.setRefreshState(REFRESH_TYPE.SLEEP);
          return of({ list: null });
        }),
      );

    void this.tableStateFacade.refresh$
      .pipe(
        untilDestroyed(this),
        filter(refresh => refresh === REFRESH_TYPE.PERIODICAL),
        scan(accumulator => (accumulator === periodicalEventsNeeded ? 1 : accumulator + 1), 0),
        concatMap(value => {
          if (value !== periodicalEventsNeeded) {
            this.tableStateFacade.setRefreshState(REFRESH_TYPE.SLEEP);
            return of({ list: null });
          }
          return onServerRefresh();
        }),
        tap(value => this.updateTable(value)),
      )
      .subscribe();

    void this.tableStateFacade.refresh$
      .pipe(
        untilDestroyed(this),
        filter(refresh => refresh === REFRESH_TYPE.FORCED),
        concatMap(onServerRefresh),
        tap(value => this.updateTable(value)),
      )
      .subscribe();
  }

  // Оповещаем что начали загрузку, очищаем данные и пагинацию
  private startLoading() {
    this.loadingSubject.next(true);
    this.dataSource = [];
    (this.paginator ?? this.paginationData).length = 0;
  }

  // Перенаправляет на тот же адрес с новыми данными таблицы в URL.
  private navigate({ pageSize, page }: IPaginationData, filterSetting: T1Filter, { active, direction }: Sort) {
    const url = this.router.url.split('?')[0];
    const filterString = this.filterToUrlString(filterSetting);

    void this.router.navigate([url], {
      relativeTo: this.route,
      queryParams: {
        pageSize,
        page,
        filter: filterString,
        sort: direction === 'asc' || direction === 'desc' ? `${active}|${direction}` : '',
      },
      queryParamsHandling: 'merge',
      replaceUrl: true,
    });
  }

  /**
   * Устанавливаем данные, оповещаем о завершении загрузки, оповещаем пусто ли в данных (зачем???)
   */
  private finishLoading(list: T[], totalCount: number) {
    const filterTypeAll = 'filter=type:all'; // Exclusion of displaying auctions in all orders #5275829
    if (this.router.url.includes(filterTypeAll)) {
      const ordersWithoutAuction = list.filter(
        (order: transport.IOrder) => !Boolean(order.allocationType === ORDER_ALLOCATION_TYPE.AUCTION_ALLOCATION),
      );
      this.dataSource = ordersWithoutAuction;
    } else {
      this.dataSource = list;
    }
    (this.paginator ?? this.paginationData).length = totalCount;
    this.loadingSubject.next(false);
    this.isEmptySubject.next(!Boolean(list?.length));
  }

  // При инициализации таблицы нужно задать существующие параметры фильтраци из текущего роута, если они есть.
  private initFilterSettings() {
    // TECH DEPT -- Что это такое? Какого черта это в абстрактном классе?!
    if (this.router.url.includes('orders/owner')) {
      const filterData = this.parseFilterFromUrlString('');
      // @ts-ignore
      if (filterData.type === 'auction') {
        // @ts-ignore
        filterData.type = 'free';
        this.tableStateFacade.setFilterSettings({ ...filterData });
        return;
      }
    }
    if (!Boolean(this.tableStateFacade.currentFilter)) {
      const queryParamMap = this.route.snapshot.queryParamMap;
      const filterData = this.parseFilterFromUrlString(queryParamMap.get('filter') ?? '');
      this.tableStateFacade.setFilterSettings({ ...filterData });
    }
  }

  // При инициализации таблицы нужно задать существующие параметры пагинации из текущего роута, если они есть.
  private initPageStateFromCurrentRoute() {
    const queryParamMap = this.route.snapshot.queryParamMap;
    if (Boolean(queryParamMap.get('pageSize')) && Boolean(queryParamMap.get('page'))) {
      this.page(parseInt(this.route.snapshot.queryParams.pageSize, 10), parseInt(this.route.snapshot.queryParams.page, 10));
    }
  }

  // При инициализации таблицы нужно задать существующие параметры сортировки из текущего роута, если они есть.
  private initSortSettings() {
    const queryParamMap = this.route.snapshot.queryParamMap;
    const sortQuery = queryParamMap.get('sort') ?? '';
    if (Boolean(sortQuery)) {
      const [active, direction] = sortQuery.split('|') as [string, SortDirection];
      if (direction === 'asc' || direction === 'desc' || direction === '') {
        this.tableStateFacade.setSortSettings({ active, direction });
      }
    }
  }

  private updateSelectedRows(isSingleSelection: boolean, isSelected: boolean, currentRow?: T) {
    if (Boolean(isSelected) && Boolean(isSingleSelection)) {
      if (Boolean(currentRow)) {
        this.selectedRows.set(currentRow?.id as string, currentRow as T);
      }
    } else if (Boolean(currentRow)) {
      this.selectedRows.delete(currentRow?.id as string);
    } else {
      this.dataSource.forEach((item: T) => {
        if (!Boolean(isSingleSelection) && Boolean(isSelected)) {
          this.selectedRows.set(item?.id as string, item);
        } else {
          this.selectedRows.delete(item?.id as string);
        }
      });
    }

    this.tableStateFacade.saveSelection(new Map([...this.selectedRows.keys()].map(item => [item, true])));
  }
}

// Оставляет только только поля с булевыми значениями, непустыми строками, числами и объектами
function clearObjectFromEmptyParams(obj: Record<string, unknown>) {
  const result = {};
  Object.keys(obj)
    .filter(key => typeof obj[key] === 'boolean' || Boolean(obj[key]) || obj[key] === 0)
    .forEach(key => (result[key] = obj[key]));
  return result;
}
