import { AfterViewInit, ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'common-number-input',
  templateUrl: './common-number-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonNumberInputComponent implements AfterViewInit {
  @Input() public size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';
  @Input() public errorsSize: 'xs' | 'sm' | 'md' | 'lg' = 'xs';
  @Input() public inputFieldClass = '';
  @Input() public label?: string = '';
  @Input() public labelClasses?: string[];
  @Input() public labelAddition?: string;
  @Input() public caption?: string;
  @Input() public captionClasses?: string[];
  @Input() public showErrors = true;
  @Input() public required = false;
  @Input() public leftAddon = '';
  @Input() public rightAddon = '';
  @Input() public lengthAfterSeparator = '2';
  @Input() public onlyIntegers = false;
  @Input() public inputClasses?: string;
  @Input() public disabled = false;
  @Input() public maxlength?: any;
  @Input() public formGroup?: FormGroup;
  @Input() public control?: FormControl;
  @Input() public controlName = '';
  @Input() public placeholder = '';
  @Input() public dataTest = '';
  @Input() public allowNegativeNumbers = false;
  @Input() public errorToMessage: (errors: ValidationErrors | null) => string = () => 'Error';

  public displayValue = '';

  get formControl(): AbstractControl {
    return (this.formGroup?.get(this.controlName) ?? this.control)!;
  }

  ngAfterViewInit() {
    if (this.formControl.value) {
      this.getDisplayValue();
    }
  }

  public getDisplayValue() {
    if(this.allowNegativeNumbers && !Number(this.formControl.value)) {
      this.displayValue = this.formControl.value;
    } else {
      this.displayValue = this.formControl.value !== '' ? Number(this.formControl.value).toLocaleString('ru-RU').replace(',', '.') : '';
    }
  }
}
