import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TnUiComponentsModule } from '@transport/ui-components';
import { TnUiDirectivesModule } from '@transport/ui-directives';
import { TnUiKitModule } from '@transport/ui-kit';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnUiPipesModule } from '@transport/ui-pipes';
import { PublishVehicleModalComponent } from './vehicle-modal/publish-vehicle-modal.component';
import { PublishVehicleFormComponent } from './form/publish-vehicle-form.component';
import { CustomControlsModule } from '@transport/custom-controls';


@NgModule({
  declarations: [
    PublishVehicleModalComponent,
    PublishVehicleFormComponent
  ],
  exports: [
    PublishVehicleModalComponent
  ],
  imports: [
    CommonModule,
    TnUiComponentsModule,
    TnUiMaterialModule,
    TnUiPipesModule,
    TnUiKitModule,
    TnUiDirectivesModule, 
    CustomControlsModule,
  ],
})
export class PublishVehicleModalModule {
}
