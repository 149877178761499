import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { COUNTER, MAX_EMAIL_LENGTH, regExpConfig } from '@transport/ui-utils';

/**
 * Login form data.
 */
export interface ILoginFormData {
  email: string;
  password: string;
}

/**
 * Login form interface.
 */
export interface ILoginForm extends FormGroup {
  controls: {
    email: AbstractControl;
    password: AbstractControl;
  };
}

/**
 * New login form constructor type.
 */
export type TNewLoginFormConstructor = (fb: FormBuilder, editableItem?: ILoginFormData, disabled?: boolean) => ILoginForm;

/**
 * Login form constructor
 * @param fb form builder
 * @param editableItem editable item
 * @param disabled indicates if for should be initialized with disabled controls
 */
export const newLoginForm: TNewLoginFormConstructor = (fb: FormBuilder, editableItem?: ILoginFormData, disabled = false): ILoginForm => {
  return fb.group({
    email: [
      editableItem?.email ?? '',
      {
        validators: Validators.compose([
          Validators.required,
          Validators.pattern(regExpConfig.email.regExp),
          Validators.maxLength(MAX_EMAIL_LENGTH),
        ]),
        disabled,
      },
    ],
    password: [
      '',
      {
        validators: Validators.compose([Validators.required, Validators.minLength(COUNTER.EIGHT)]),
        disabled,
      },
    ],
  }) as ILoginForm;
};
