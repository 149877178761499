import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter, HostBinding,
  Input,
  OnInit,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ICargoType } from '@transport/ui-interfaces';
import { merge, Observable, startWith, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

interface IOption {
  value: string;
  label?: string;
}

@UntilDestroy()
@Component({
  selector: 'transport-autocomplete-with-addition',
  templateUrl: './autocomplete-with-addition.component.html',
  styleUrls: ['./autocomplete-with-addition.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- Tech dept // We don't know how to track when its outside FromControl gets "touched" status.
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TnAutocompleteWithAdditionComponent implements ControlValueAccessor, OnInit, DoCheck, AfterViewInit {
  public static nextId = 0;

  @HostBinding('attr.id')
  public id = `autocomplete-with-addition-${(TnAutocompleteWithAdditionComponent.nextId += 1)}`;

  public myControl: FormControl = new FormControl('');

  private _options: unknown[] = [];

  private _innerOptions: IOption[] = [];

  private _bindLabel = 'value';

  @Input() public set options(value: unknown[]) {
    this._options = value;
    this.updateInnerOptions();
  }

  public get innerOptions() {
    return this._innerOptions;
  }

  @Input() public required = false;

  @Input() public showErrors = true;

  @Input() public title = 'Untitled field';

  @Input() public bindValue!: string;

  @Input() public set bindLabel(value: string) {
    this._bindLabel = value;
    this.updateInnerOptions();
  }

  public get bindLabel() {
    return this._bindLabel;
  }

  @Input() public createElementCallback?: (label: string) => Observable<unknown> | unknown;

  @Output() public readonly optionAdded: EventEmitter<ICargoType> = new EventEmitter<ICargoType>();

  public readonly filteredOptions$: Observable<IOption[]> = this.myControl.valueChanges.pipe(map(value => this._filter(value)));

  private readonly showAllOptionsSubject = new Subject<void>();

  private readonly showAllOptionsAction$: Observable<IOption[]> = this.showAllOptionsSubject
    .asObservable()
    .pipe(map(() => this.innerOptions));

  public visibleOptions$ = merge(this.filteredOptions$, this.showAllOptionsAction$);

  constructor(
    private readonly cdr: ChangeDetectorRef,
    private readonly translate: TranslateService,
    @Self() private readonly controlDir: NgControl,
  ) {
    controlDir.valueAccessor = this;
  }

  //** Control value accessor section **//
  private _onChange: (value: string) => void = () => void 0;

  private _onTouched: () => void = () => void 0;

  public writeValue(obj: string) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- We don't know the shape of input data
    const option = this._options.find(o => (<any>o)[this.bindValue] === obj);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- We don't know the shape of input data
    if (Boolean(option)) this.myControl.setValue((<any>option)[this.bindLabel]);
    else this.myControl.setValue(obj);
  }

  public registerOnChange(fn: (value: string) => void): void {
    this._onChange = fn;
  }

  public registerOnTouched(fn: () => void): void {
    this._onTouched = fn;
  }

  public setDisabledState(isDisabled: boolean): void {
    if (isDisabled) this.myControl.disable();
    else this.myControl.enable();

    this.cdr.markForCheck();
  }

  public onBlur() {
    this._onTouched();
  }

  //////////////////////

  public ngOnInit(): void {
    this.myControl.setValidators([this.validateValueMustBeAnOption]);
    this.watchOverParentControlErrors();
    this.watchAndPropogateCorrectValueChanges();

    this.controlDir.statusChanges?.pipe(startWith(void 0)).subscribe(v => {
      const errors = this.controlDir.errors;
      if (errors && Object.keys(errors).length) {
        this.myControl.setErrors(errors);
      }
    });
  }

  public ngDoCheck() {
    if (Boolean(this.controlDir.touched) && !this.myControl.touched) this.myControl.markAsTouched();
  }

  public ngAfterViewInit() {
    this.showAllOptionsSubject.next();
  }

  private _filter(value: string): IOption[] {
    const filterValue = value.trim().toLowerCase();
    const result = this.innerOptions.filter(option => option.value.toLowerCase().includes(filterValue));

    if (filterValue && !this.checkIfOption(filterValue))
      result.push({
        value,
        label: `${this.translate.instant('shared.actions.create')} '${value}'`,
      } as IOption);

    return result;
  }

  public onOptionSelected($event: { option: { value: string } }) {
    const value = $event.option.value.trim();
    // Checks if value is already an option. If it's not, it will create the new one.
    if (!this.checkIfOption(value)) {
      if (this.createElementCallback) {
        const applyNewOption = option => {
          this._innerOptions.push(mapOptionToInnerOption(option, this.bindLabel));
          this._options = [...this._options, option];
          this.myControl.updateValueAndValidity();
          this.optionAdded.emit(option);
        };

        const result = this.createElementCallback(value);
        if (result instanceof Observable) {
          result.subscribe(v => {
            applyNewOption(v);
          });
        } else {
          applyNewOption(result);
        }
      } else {
        this._innerOptions.push({ value, label: value });
        this.myControl.updateValueAndValidity();
      }
    }
    this.onBlur();
  }

  //On focus the component is supposed to show all of the options if value it has is an option
  public onFocus() {
    if (this.checkIfOption(Boolean(this.myControl.value) ? this.myControl.value : '')) {
      this.showAllOptionsSubject.next();
    }
  }

  private watchAndPropogateCorrectValueChanges() {
    this.myControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (this.myControl.valid && !this.myControl.pristine) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- We don't know the shape of input data
        const resultOption = this._options.find(o => (<any>o)[this.bindLabel] === value);
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- We don't know the shape of input data
        this._onChange(Boolean(resultOption) ? (<any>resultOption)[this.bindValue] : '');
      }
    });
  }

  private updateInnerOptions() {
    this._innerOptions = this._options.map(o => mapOptionToInnerOption(o, this.bindLabel));
    this.myControl.updateValueAndValidity();
  }

  private watchOverParentControlErrors() {
    this.controlDir.statusChanges
      ?.pipe(
        startWith(void 0),
        map(() => this.controlDir.errors),
        untilDestroyed(this),
      )
      .subscribe((errors: ValidationErrors | null) => {
        if (errors) {
          this.myControl.setErrors(errors);
          this.cdr.markForCheck();
        }
      });
  }

  private readonly validateValueMustBeAnOption: ValidatorFn = control => {
    return this.checkIfOption(control.value ?? '') ? null : { valueMustBeAnOption: true };
  };

  private checkIfOption(valueToCheck: string): boolean {
    const loverCasedValue = valueToCheck.toLowerCase();
    return Boolean(this.innerOptions.find(v => v.value.toLocaleLowerCase() === loverCasedValue));
  }
}

function mapOptionToInnerOption(option: unknown, label?: string): IOption {
  if (typeof label === 'string') {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any -- We don't know the shape of input data
    const value = (<any>option)[label];
    return { value, label: value };
  }
  if (typeof option === 'string') {
    return { value: option, label: option };
  }
  throw new Error('The option is not string value, or label is not defined');
}
