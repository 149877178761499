export interface ITableSelection<T> {
  isSingleSelection: boolean;
  isSelected: boolean;
  currentRow?: T;
}

export type TTableSearchKind = 'text' | 'category';

export interface ITableSearch<S> {
  field: string;
  kind: TTableSearchKind;
  categories: Record<string, S[]>;
}
