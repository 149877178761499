import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

import { IActionButton } from './table-item-action-buttons.interface';

@Component({
  selector: 'transport-table-item-actions-buttons',
  templateUrl: './table-item-actions-buttons.component.html',
  styleUrls: ['./table-item-actions-buttons.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnTableItemActionsButtonsComponent {
  @Input() public isModifyEnabled = false;

  @Input() public isEditEnabled = true;

  @Input() public isCopyEnabled = true;

  @Input() public isRemoveEnabled = true;

  @Input() public customButtons: IActionButton[] = [];

  @Output() public readonly view: EventEmitter<void> = new EventEmitter<void>();

  // eslint-disable-next-line @angular-eslint/no-output-native -- TODO: tech debt
  @Output() public readonly copy: EventEmitter<void> = new EventEmitter<void>();

  @Output() public readonly remove: EventEmitter<void> = new EventEmitter<void>();

  @Output() public readonly edit: EventEmitter<void> = new EventEmitter<void>();

  @Output() public readonly custom: EventEmitter<string> = new EventEmitter<string>();

  public action(name) {
    switch (name) {
      case 'view':
        this.view.emit();
        break;
      case 'remove':
        this.remove.emit();
        break;
      case 'edit':
        this.edit.emit();
        break;
      case 'copy':
        this.copy.emit();
        break;
      default:
        throw new Error('Unknown table item action');
    }
  }

  public customAction(name: string) {
    this.custom.emit(name);
  }
}
