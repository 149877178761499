import { NgModule } from '@angular/core';
import { TnCoreModule } from '@transport/core';
import { TnDirectoryModule } from '@transport/directory';
import { TnUiComponentsModule } from '@transport/ui-components';
import { TnUiDirectivesModule } from '@transport/ui-directives';
import { TnUiKitModule } from '@transport/ui-kit';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnUiPipesModule } from '@transport/ui-pipes';
import { TnUiStoreModule } from '@transport/ui-store';
import { TnUiTranslateModule } from '@transport/ui-translate';
import { NotificationsPopupComponent } from './components/notifications-popup/notifications-popup.component';
import { NotificationsComponent } from './components';
import { NotificationsToolbarComponent } from './components/notifications-toolbar/notifications-toolbar.component';

@NgModule({
  imports: [
    TnCoreModule,
    TnUiComponentsModule,
    TnUiDirectivesModule,
    TnUiMaterialModule,
    TnUiTranslateModule,
    TnUiPipesModule,
    TnUiStoreModule,
    TnUiKitModule,
    TnDirectoryModule,
  ],
  declarations: [
    NotificationsComponent,
    NotificationsPopupComponent,
    NotificationsToolbarComponent,
  ],
  exports: [NotificationsComponent, NotificationsPopupComponent],
})
export class NotificationsModule {}
