import { FILE_UPLOAD_STATUS } from './file.interface';
import { TnFileBase } from './file-base';

export class TnUploadedFile extends TnFileBase {
  public downloadUrl: string;

  constructor(fullFileName: string, downloadUrl: string, id?: string) {
    super(fullFileName);
    this.status = FILE_UPLOAD_STATUS.UPLOADED;
    this.downloadUrl = downloadUrl;
    this.id = id;
  }
}

export interface IFileType {
  id?: string | null;
  name?: string | null;
  innerName?: string | null;
}
