import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { DOCUMENT_TYPE, IOrder, TCarrierOrdersFilter } from '@transport/ui-interfaces';

import { ITnState } from '../../state/index';
import { TnTableStateFacade } from '../../table/table-state-facade';
import { tableActions } from '../actions/orders-table-carrier.actions';
import { tableSelectors, tnCryptoOrder } from '../selectors/orders-table-carrier.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnOrdersTableCarrierFacade extends TnTableStateFacade<TCarrierOrdersFilter> {
  constructor(public store: Store<ITnState>) {
    super(store, tableActions, tableSelectors);
  }

  public isTnCryptoOwnerSigning$(order: IOrder, docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(tnCryptoOrder.isTnCryptoOwnerSigning, { order, docType }));
  }

  public isTnCryptoDocumentSigned$(order: IOrder, docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(tnCryptoOrder.isTnCryptoDocumentSigned, { order, docType }));
  }

  public isTnCryptoDocumentOwnerSigned$(order: IOrder, docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(tnCryptoOrder.isTnCryptoDocumentOwnerSigned, { order, docType }));
  }

  public isTnCryptoDocumentSigningError$(order: IOrder, docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(tnCryptoOrder.isTnCryptoDocumentSigningError, { order, docType }));
  }
}
