/* eslint-disable @typescript-eslint/naming-convention */
import { RECOVERY_CARGO_AFTER_DELETE, OPTIMIZATION_CRITERION_CHOICES, QC_GEO } from '@transport/ui-interfaces';
import { ICommonSelectData } from 'libs/custom-controls/src/lib/common.interface';

export interface IRoutingSettings {
  averageSpeed: number;
  averageLoadingDuration: number;
  averageUnloadingDuration: number;
  cargoPriceRequired: boolean;
  addressWarningLevel: ICommonSelectData | QC_GEO | null;
  optimizationCriterion: ICommonSelectData | OPTIMIZATION_CRITERION_CHOICES | null;
  recoveryCargoAfterDelete: ICommonSelectData | RECOVERY_CARGO_AFTER_DELETE | null;
}

export interface IRoutingSettingsForRequest {
  avg_speed: number;
  avg_loading_duration: number;
  avg_unloading_duration: number;
  cargo_price_required: boolean;
  address_warning_level: QC_GEO | null;
  optimization_criterion: OPTIMIZATION_CRITERION_CHOICES | null;
  recovery_cargo_after_delete: RECOVERY_CARGO_AFTER_DELETE | null;
}

export interface IMonitoringSettings {
  coordinatesUpdatePeriod: number | null;
  maxDistanceToPoint: number | null;
  canChangePointsOrder: boolean;
  isWaybillRequired: boolean;
  notifyLocInterval: number | null;
  allowedOutOfRadarPeriod: number | null;
  trackBeforeFirstPoint: boolean;
  autoComplete: boolean;
  radiusForAutoComplete: number | null;
  allowedNonActivePeriod: number | null;
  minPeriodOnPointAuto: number | null;
}

export interface IMonitoringSettingsForRequest {
  coordinates_update_period: number | null;
  max_distance_to_point: number | null;
  can_change_points_order: boolean;
  is_waybill_required: boolean;
  notify_loc_interval: number | null;
  allowed_out_of_radar_period: number | null;
  track_before_first_point: boolean;
  auto_complete: boolean;
  radius_for_auto_complete: number | null;
  allowed_non_active_period: number | null;
  min_period_on_point_auto: number | null;
}
