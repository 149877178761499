import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import { HIDE_PROGRESS_ACTION, hideProgress, SHOW_PROGRESS_ACTION, showProgress } from './progress.actions';
import { TnProgressService } from './progress.service';

@Injectable({
  providedIn: 'root',
})
export class TnProgressEffects {
  constructor(private readonly action$: Actions, private readonly progress: TnProgressService) {}

  public readonly showProgress$ = createEffect(
    () =>
      this.action$.pipe(
        ofType<ReturnType<typeof showProgress>>(SHOW_PROGRESS_ACTION),
        tap(() => {
          this.progress.show();
        }),
      ),
    { dispatch: false },
  );

  public readonly hideProgress$ = createEffect(
    () =>
      this.action$.pipe(
        ofType<ReturnType<typeof hideProgress>>(HIDE_PROGRESS_ACTION),
        tap(() => {
          this.progress.hide();
        }),
      ),
    { dispatch: false },
  );
}
