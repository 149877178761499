import { transport } from '@transport/proto';

/**
 * Vehicle type interface.
 */
export type TVehicle = 'TRUCK' | 'TRAILER' | 'COUPLER' | 'ALL';

export const containerTypes = [
  { id: '20', label: '20' },
  { id: '40', label: '40' },
  { id: '20 HC', label: '20 HC' },
  { id: '40 HC', label: '40 HC' },
];

/**
 * Vehicles filter mode.
 * Empty string corresponds to all vehicles filter
 */
export type TVehiclesFilterMode = 'ALL' | 'ACTIVE' | 'ARCHIVED';

/**
 * Vehicle types filter interface.
 */
export interface IVehicleTypesFilter {
  // Paging filter: how many elements should be returned after offset.
  first?: number;
  // Paging filter: how many elements should be passed before returning elements equal to 'first' value.
  offset?: number;
  // Request for total count of vehicles
  totalCount?: boolean;
}

// Filter by ownership
export enum VEHICLE_OWNERSHIP {
  ALL = 'ALL',
  OWN = 'OWN',
  NOT_OWN = 'NOT_OWN',
}
export interface IVehicleFilterData {
  vehicleTypes: TVehicle[];
  bodyTypes: transport.Vehicle.Body.IType[];
  bodySubtypes: transport.Vehicle.Body.ISubtype[];
  loadingTypes: transport.ILoadingType[];
  agentCarrierOwnerships: VEHICLE_OWNERSHIPS_FILTERS[];
}

export enum VEHICLE_OWNERSHIPS_FILTERS {
  ALL = 'ALL',
  AGENT_CARRIER = 'AGENT_CARRIER',
  OWN = 'OWN',
}

export enum VEHICLE_TYPE_PROPERTY {
  ATTRACTED = 'ATTRACTED',
  OWN = 'OWN',
  UNKNOWN = 'UNKNOWN',
}

export enum VEHICLE_TYPE_OWNERSHIP {
  LEASING = 'LEASING',
  RENT = 'RENT',
  OWN = 'OWN',
  FREE_USE = 'FREE_USE',
  JOINT_PROPERTY_OF_SPOUSES = 'JOINT_PROPERTY_OF_SPOUSES',
}

export enum VEHICLE_TYPE {
  TRUCK = 'TRUCK',
  TRAILER = 'TRAILER',
  COUPLER = 'COUPLER',
}

export const loadingTypesList = [
  {
    id: '2',
    name: 'Задняя',
    checked: false,
  },
  {
    id: '3',
    name: 'Верхняя',
    checked: false,
  },
  {
    id: '1',
    name: 'Боковая',
    checked: false,
  },
];
