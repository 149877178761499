export interface IAgentCarrier {
  id: string;
  inn: string;
  kpp: string;
  name: string;
  carrier: string;
  vehicleCount: number;
  carrierName?: string;
  bin?: string | null;
  iin?: string | null;
}

export interface IAgentCarrierInput {
  legalForm: string;
  inn: string;
  kpp: string;
  postalAddress: string;
  vat: string;
  carrierName: string;
}

export interface IAgentCarrierEditInput {
  postalAddress: string;
  vat: string;
  id: string;
  carrierName: string;
}

export enum AGENT_TYPE {
  AGENT = 'AGENT',
  OWNER = 'OWNER',
  EXPEDITOR = 'EXPEDITOR',
}
