import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { CARRIER_MUTATION, GRAPHQL_QUERY, SHARED_MUTATION } from '@transport/gql';
import { transport } from '@transport/proto';
import {
  BIDDING_TYPE,
  biddingOrdersSorterDto,
  IAgentCarrierEditInput,
  IAgentCarrierInput,
  ICargoPackagingType,
  ICarriersRatingFilter,
  ICheckOrganizationInput,
  ICheckTruckInput,
  IDriver,
  IDriverForm,
  IFileType,
  IGqlAddWaybillResponse,
  IGqlAuctionOrderResponse,
  IGqlBiddingOrderResponse,
  IGqlBiddingOrdersResponse,
  IGqlCarrierRatingDetailsReportDataResponse,
  IGqlCarrierRatingReportDataResponse,
  IGqlDepartmentsResponse,
  IGqlOrderContractPartResponse,
  IGqlOrderResponse,
  IGqlAccrStatusBySubdomainResponse,
  IGqlOrdersResponse,
  ILot,
  IOrder,
  IOrganizationDocument,
  IPlace,
  IVehicle,
  IVehicleData,
  IVehicleTypesFilter,
  IWayBillDocAttributes,
  LEGAL_FORM_CODE_BACKEND_ENUM_PREFIX,
  LEGAL_FORM_CODES,
  ORDER_ALLOCATION_TYPE_GROUP,
  ORDER_STATUS,
  ORDERS_FILTER_MODE,
  ordersSorterDto,
  TCarrierOrdersFilter,
  TCarrierOrdersFilterDto,
  TOrderAttachmentForSave,
  TVehicle,
  USER_ROLE,
} from '@transport/ui-interfaces';
import { mergeDateAndTime, sorterToDto } from '@transport/ui-utils';
import { DocumentNode, GraphQLError } from 'graphql';
import { CARRIER_QUERY } from 'libs/transport-gql/src/lib/graphql/queries/carrier/graphql-queries-carrier';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';

import { TnCurrentUserFacade } from '../current-user';
import { TnFeatureAccessService } from '../feature-access';
import { TnGqlClientSharedService } from '../gql-client-shared';
import { IOrganizationData } from '../profile';

/**
 * GraphQL client service for carrier.
 */
@Injectable({
  providedIn: 'root',
})
export class TnGqlClientCarrierService {
  /**
   * Service user role.
   */
  protected readonly serviceUserRole: USER_ROLE = USER_ROLE.CARRIER;

  /**
   * Default variables.
   */
  private readonly defaultVariables = {};

  constructor(
    private readonly sharedGraphQlClient: TnGqlClientSharedService,
    private readonly userFacade: TnCurrentUserFacade,
    private readonly featureAccess: TnFeatureAccessService,
  ) {
    this.sharedGraphQlClient.createApolloClientFor(this.serviceUserRole);
    this.isLoggedInSubscription();
  }

  /**
   * query - a read-only fetch.
   * @param query to execute
   * @param listenX number of responses to catch
   * @param variables are arguments for fileds
   * @param withSpinner should request start spinner
   * @returns [Observable<any>] updated value
   */
  public query<T>(query: DocumentNode, listenX: number, variables: Record<string, unknown> = {}, withSpinner?: boolean): Observable<T> {
    return this.sharedGraphQlClient.query(this.serviceUserRole, query, listenX, variables, withSpinner);
  }

  /**
   * mutation - a write followed by a fetch.
   * @param mutation to execute
   * @param variables are arguments for fileds
   * @param withSpinner should request start spinner
   * @param httpTimeout
   * @returns [Observable<any>] updated value
   */
  public mutate<T>(
    mutation: DocumentNode,
    variables: Record<string, unknown> = {},
    withSpinner?: boolean,
    httpTimeout?: number,
  ): Observable<T> {
    return this.sharedGraphQlClient.mutate(this.serviceUserRole, mutation, variables, withSpinner, httpTimeout);
  }

  public queryOrders(
    listenX = 1,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
    filter?: TCarrierOrdersFilter,
    sorter?: Sort,
  ): Observable<IGqlOrdersResponse> {
    const variables: TCarrierOrdersFilterDto = this.ordersFilterToDto(filter ?? {});
    variables.first = first;
    variables.offset = offset;
    const sortBy = sorterToDto(sorter ?? ({} as Sort), ordersSorterDto);
    sortBy.unshift('is_shipment_on_agreed_date_and_status_not_completed');
    if (sortBy.length === 1) {
      sortBy.push('-id');
    }

    return this.query<IGqlOrdersResponse>(
      GRAPHQL_QUERY.carrier.getOrders,
      listenX,
      {
        ...variables,
        sortBy,
      },
      withSpinner,
    );
  }

  public queryBiddingOrders(
    listenX = 1,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
    filter?: TCarrierOrdersFilter,
    sorter?: Sort,
  ): Observable<IGqlBiddingOrdersResponse> {
    const variables: TCarrierOrdersFilterDto = this.biddingOrdersFilterToDto(filter ?? {});
    variables.first = first;
    variables.offset = offset;
    const sortBy = sorterToDto(sorter ?? ({} as Sort), biddingOrdersSorterDto);

    return this.query<IGqlBiddingOrdersResponse>(
      GRAPHQL_QUERY.carrier.getBiddingOrders,
      listenX,
      {
        ...variables,
        sortBy,
      },
      withSpinner,
    );
  }

  public queryCarrierContractsWithTn(
    listenX = 1,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
  ): Observable<{ carrierContractsWithTn: transport.Order.ICarrierContract[] }> {
    return this.query<{ carrierContractsWithTn: transport.Order.ICarrierContract[] }>(
      GRAPHQL_QUERY.carrier.getCarrierContractsWithTn,
      listenX,
      {
        first,
        offset,
        totalCount: false,
      },
      withSpinner,
    );
  }

  public queryCarriersRatingData(
    offset: number,
    countPerPage: number,
    filter?: ICarriersRatingFilter,
    listenX = 1,
    withSpinner?: boolean,
  ): Observable<IGqlCarrierRatingReportDataResponse> {
    return this.query<IGqlCarrierRatingReportDataResponse>(
      GRAPHQL_QUERY.carrier.getCarriersRatingReportData,
      listenX,
      {
        countRecords: countPerPage,
        offset,
        departmentIn: filter?.departments,
        startDate: filter?.period?.startDate,
        endDate: filter?.period?.endDate,
      },
      withSpinner,
    );
  }

  public queryCarriersRatingDepartments(listenX = 1, withSpinner?: boolean): Observable<string[]> {
    return this.query<IGqlDepartmentsResponse>(GRAPHQL_QUERY.carrier.getDepartments, listenX, {}, withSpinner).pipe(
      map(resp => resp.carrierRatingDepartments.map(item => item.department)),
    );
  }

  public queryOrganizationAccrstatusBySubdomain(subdomain: string, listenX = 1, withSpinner?: boolean) {
    return this.query<IGqlAccrStatusBySubdomainResponse>(
      CARRIER_QUERY.getOwnerCompanyBusinessRelationship,
      listenX,
      { subdomain },
      withSpinner,
    ).pipe(map(response => response.cargoOwnerOrganization));
  }

  public queryCarriersRatingDetailsData(
    offset: number,
    countPerPage: number,
    carrierRatingId: number,
    listenX = 1,
    withSpinner?: boolean,
  ): Observable<IGqlCarrierRatingDetailsReportDataResponse> {
    return this.query<IGqlCarrierRatingDetailsReportDataResponse>(
      GRAPHQL_QUERY.carrier.getCarriersRatingDetailsReportData,
      listenX,
      {
        carrierRatingId,
        countRecords: countPerPage,
        offset,
      },
      withSpinner,
    );
  }

  /**
   * Queries GraphQL endpont for order by id.
   * @param orderId order id
   * @param listenX number of responses to catch
   */
  public queryOrder(orderId: string, listenX = 1, withSpinner?: boolean): Observable<IGqlOrderResponse> {
    return this.query<IGqlOrderResponse>(GRAPHQL_QUERY.carrier.getOrder, listenX, { orderId }, withSpinner);
  }

  public queryOrderContractStatus(orderId: string, listenX = 1, withSpinner?: boolean): Observable<IGqlOrderContractPartResponse> {
    return this.query<IGqlOrderContractPartResponse>(GRAPHQL_QUERY.carrier.getOrderContractStatus, listenX, { orderId }, withSpinner);
  }

  /**
   * Queries GraphQL endpont for order extra conditions by id.
   * @param orderId order id
   * @param listenX number of responses to catch
   */
  public queryExtraConditions(orderId: string, listenX = 1, withSpinner?: boolean): Observable<IGqlOrderResponse> {
    return this.query<IGqlOrderResponse>(GRAPHQL_QUERY.carrier.getExtraConditions, listenX, { orderId }, withSpinner);
  }

  public queryOrderDriver(orderId: string): Observable<transport.IOrder> {
    return this.query<{ order: transport.IOrder }>(GRAPHQL_QUERY.carrier.getOrderDriver, 1, { orderId }, false).pipe(
      map(result => result.order),
    );
  }

  /**
   * Make mutation to close order.
   * @param orderId order to close
   * @param withSpinner should request start spinner
   * @returns [{completeOrder: IOrder}] updated value
   */
  public completeOrder(orderId: string, withSpinner?: boolean): Observable<{ completeOrder: IOrder }> {
    return this.mutate<{ completeOrder: IOrder }>(CARRIER_MUTATION.completeOrder, { orderId }, withSpinner);
  }

  public addOrderCounterOffer(input: transport.IAddOrderCounterOfferInput, withSpinner?: boolean) {
    return this.mutate(CARRIER_MUTATION.addOrderCounterOffer, { input }, withSpinner);
  }

  public unreserveTransport(orderId: string, withSpinner?: boolean): Observable<{ unreserveTransport: IOrder }> {
    return this.mutate<{ unreserveTransport: IOrder }>(CARRIER_MUTATION.unreserveTransport, { orderId }, withSpinner);
  }

  public addWaybill(orderId: string, documents: TOrderAttachmentForSave[], withSpinner?: boolean): Observable<IGqlAddWaybillResponse> {
    const waybillInfo = this.prepareWaybillAttributes(documents[0].waybillInfo);

    const documentsToAdd: transport.IAddOrderUploadedDocumentInput[] = documents.map(item => {
      const res: transport.IAddOrderUploadedDocumentInput = {
        documentTypeId: item.documentType?.id,
        uploadedFileId: item.uploadedFile.id,
        waybillInfo,
      };

      return res;
    });

    const mutationVariables: { input: transport.IEditOrderUploadedDocumentsInput } = {
      input: {
        orderId,
        documentsToAdd,
      },
    };

    return this.mutate<IGqlAddWaybillResponse>(SHARED_MUTATION.editOrderUploadedDocuments, mutationVariables, withSpinner);
  }

  public prepareWaybillAttributes(waybillFormValue: IWayBillDocAttributes | null): {
    dateFrom: string | null;
    dateTo: string | null;
    storagePointId: string | null;
  } {
    const { dateFrom, timeFrom, dateTo, timeTo, storagePoint } = waybillFormValue ?? {
      dateFrom: null,
      timeFrom: '',
      dateTo: null,
      timeTo: '',
      storagePoint: null,
    };
    const dateTimeFrom = dateFrom && mergeDateAndTime(dateFrom, timeFrom ?? '00:00').format('YYYY-MM-DDTHH:mm:ssZ');

    const dateTimeTo = dateTo && mergeDateAndTime(dateTo, timeTo ?? '00:00').format('YYYY-MM-DDTHH:mm:ssZ');

    return {
      dateFrom: dateTimeFrom,
      dateTo: dateTimeTo,
      storagePointId: storagePoint?.id ?? null,
    };
  }

  public editWaybill(orderId: string, documents: TOrderAttachmentForSave[], withSpinner?: boolean): Observable<IGqlAddWaybillResponse> {
    const waybillInfo = this.prepareWaybillAttributes(documents[0].waybillInfo);
    const documentsToEdit: transport.IEditOrderUploadedDocumentInput[] = documents.map(item => ({
      documentTypeId: item.documentType?.id,
      id: item.id,
      waybillInfo: {
        ...waybillInfo,
        uploadedFileId: item.uploadedFile.id,
      },
    }));

    const mutationVariables: { input: transport.IEditOrderUploadedDocumentsInput } = {
      input: {
        orderId,
        documentsToEdit,
      },
    };

    return this.mutate<IGqlAddWaybillResponse>(SHARED_MUTATION.editOrderUploadedDocuments, mutationVariables, withSpinner);
  }

  /**
   * Make mutation to reject order.
   * @param orderId order to reject
   * @param withSpinner should request start spinner
   * @returns [{cancelOrder: IOrder}] updated value
   */
  public cancelOrder(orderId: string, isTerminationAgreement = false, withSpinner?: boolean): Observable<IOrder> {
    const mutation = isTerminationAgreement ? CARRIER_MUTATION.cancelOrderTn : CARRIER_MUTATION.cancelOrder;
    return isTerminationAgreement
      ? this.mutate<{ signTerminationAgreementTnCrypto: IOrder }>(mutation, { orderId }, withSpinner).pipe(
          map(result => result.signTerminationAgreementTnCrypto),
        )
      : this.mutate<{ cancelOrder: IOrder }>(mutation, { orderId }, withSpinner).pipe(map(result => result.cancelOrder));
  }

  /**
   * Make mutation to accept order.
   * @param orderId id of order to accept
   * @param withSpinner should request start spinner
   * @returns [{bookOrder: IOrder}] updated value
   */
  public acceptOrder(orderId: string, withSpinner?: boolean): Observable<{ bookOrder: IOrder }> {
    return this.mutate<{ bookOrder: IOrder }>(CARRIER_MUTATION.acceptOrder, { orderId }, withSpinner);
  }

  /**
   * Make mutation to accept order.
   * @param orderId id of order to accept
   * @param withSpinner should request start spinner
   * @returns [{bookOrder: IOrder}] updated value
   */
  public letsGo(orderId: string, withSpinner?: boolean): Observable<{ letsGo: IOrder }> {
    return this.mutate<{ letsGo: IOrder }>(CARRIER_MUTATION.letsGo, { orderId }, withSpinner);
  }

  /**
   * Make mutation to reserve transport.
   * @param orderId id of order to accept
   * @param withSpinner should request start spinner
   * @returns [{bookOrder: IOrder}] updated value
   */
  public reserveTransportV2(
    driverId: string,
    orderId: string,
    vehicleId: string,
    carrierContractId: string | null,
    agentCarrierId: string | null,
    freightAmount?: string | null,
    withSpinner?: boolean,
  ): Observable<{ reserveTransportV2: IOrder }> {
    const agentCarrierParams = Boolean(agentCarrierId) ? { agentCarrierId } : {};
    const carrierContractParams = Boolean(carrierContractId) ? { carrierContractId } : {};
    const freightAmountParams = Boolean(freightAmount) ? { freightAmount } : {};
    return this.mutate<{ reserveTransportV2: IOrder }>(
      Boolean(agentCarrierId) ? CARRIER_MUTATION.reserveTransportV2WithAgent : CARRIER_MUTATION.reserveTransportV2,
      {
        driverId,
        orderId,
        vehicleId,
        carrierContractId,
        ...agentCarrierParams,
        ...carrierContractParams,
        ...freightAmountParams,
      },
      withSpinner,
    );
  }

  public calculationFreightAmount(
    orderId: string,
    agentCarrierId: string,
    carrierContractId: string,
    freightAmount?: number | null,
  ): Observable<transport.IFreightDetails | null | { max: boolean }> {
    const organizationId = this.userFacade.currentUser?.organization?.id ?? '';
    if (agentCarrierId === organizationId) {
      return of(null);
    }

    return this.query<{ agentFeeAndFreightDetails: transport.IFreightDetails }>(
      GRAPHQL_QUERY.carrier.getAgentFeeAndFreightDetails,
      1,
      Boolean(freightAmount) || freightAmount === 0
        ? {
            orderId,
            agentCarrierId,
            carrierContractId,
            freightAmount,
          }
        : {
            orderId,
            agentCarrierId,
            carrierContractId,
          },
      false,
    ).pipe(
      map(result => result.agentFeeAndFreightDetails),
      catchError((error: GraphQLError) => {
        if (Boolean(freightAmount) && error.extensions?.param === 'freightAmount') {
          return of({ max: true });
        }
        return of(null);
      }),
    );
  }

  /**
   * Make mutation to place bet.
   * @param bet sum to place
   * @param orderId id of order to place bet
   * @param withSpinner should request start spinner
   * @returns [{placeBet: ILot}] updated value
   */
  public placeABet(bet: number, orderId: string, withSpinner?: boolean): Observable<{ placeBet: ILot }> {
    return this.mutate<{ placeBet: ILot }>(
      CARRIER_MUTATION.placeBet,
      {
        bet,
        orderId,
      },
      withSpinner,
    );
  }

  public placeBiddingBet(bet: number, orderId: string, withSpinner?: boolean): Observable<{ placeBiddingBet: transport.IBiddingLot }> {
    return this.mutate<{ placeBiddingBet: transport.IBiddingLot }>(
      CARRIER_MUTATION.placeBiddingBet,
      {
        bet,
        orderId,
      },
      withSpinner,
    );
  }

  /**
   * Queries GraphQL endpont for auction order by id.
   * @param orderId order id
   * @param listenX number of responses to catch
   */
  public queryAuctionOrder(orderId: string, withSpinner?: boolean): Observable<IGqlAuctionOrderResponse> {
    return this.query<IGqlAuctionOrderResponse>(GRAPHQL_QUERY.carrier.getAuctionOrder, 1, { orderId }, withSpinner);
  }

  public queryBiddingOrder(orderId: string, withSpinner?: boolean): Observable<IGqlBiddingOrderResponse> {
    return this.query<IGqlBiddingOrderResponse>(GRAPHQL_QUERY.carrier.getBiddingOrder, 1, { orderId }, withSpinner);
  }

  /**
   * Queries GraphQL endpont for auction order by id.
   * @param id lot id
   * @param listenX number of responses to catch
   */
  public queryLot(id: string, listenX = 1, withSpinner?: boolean): Observable<unknown> {
    return this.query(GRAPHQL_QUERY.carrier.getLot, listenX, { id }, withSpinner);
  }

  /**
   * Queries GraphQL endpoint for vehicles.
   * @param listenX number of responses to catch
   * @param withSpinner should request start spinner
   */
  public queryVehicles(
    listenX = 1,
    withSpinner?: boolean,
    options?: Record<string, unknown>,
  ): Observable<{ vehicles: transport.IVehicle[]; totalCount: number | undefined }> {
    const variables: IVehicleTypesFilter = { ...this.defaultVariables, ...options };
    return this.query<{ vehicles: transport.IVehicle[]; totalCount: number | undefined }>(
      GRAPHQL_QUERY.carrier.getVehicles,
      listenX,
      variables as Record<string, unknown>,
      withSpinner,
    );
  }

  public queryVehiclesSelector(
    listenX = 1,
    withSpinner?: boolean,
    options?: Record<string, unknown>,
  ): Observable<{ vehicles: transport.IVehicle[]; totalCount: number | undefined }> {
    const variables: IVehicleTypesFilter = { ...this.defaultVariables, ...options };
    return this.query<{ vehicles: transport.IVehicle[]; totalCount: number | undefined }>(
      GRAPHQL_QUERY.carrier.getVehiclesSelector,
      listenX,
      variables as Record<string, unknown>,
      withSpinner,
    );
  }

  /**
   * Queries GraphQL endpoint for vehicle.
   * @param id vehicle id
   * @param listenX number of responses to catch
   * @param withSpinner should request start spinner
   */
  public queryVehicle(id: string, listenX = 1, withSpinner?: boolean): Observable<{ vehicle: IVehicle }> {
    return this.query<{ vehicle: IVehicle }>(
      GRAPHQL_QUERY.carrier.getVehicle,
      listenX,
      {
        id,
      },
      withSpinner,
    );
  }

  /**
   * Queries GraphQL endpoint for vehicle types.
   */
  public queryVehicleTypes(): Observable<{ vehicleTypes: TVehicle[] }> {
    return this.query<{ vehicleTypes: TVehicle[] }>(GRAPHQL_QUERY.carrier.getVehicleTypes, 1, {}, true);
  }

  /**
   * Queries GraphQL endpoint for body types.
   */
  public queryBodyTypes(): Observable<{ bodyTypes: transport.Vehicle.Body.IType[] }> {
    return this.query<{ bodyTypes: transport.Vehicle.Body.IType[] }>(GRAPHQL_QUERY.carrier.getBodyTypes, 1, {}, true);
  }

  /**
   * Queries GraphQL endpoint for vehicle makes.
   */
  public queryVehicleMakes(): Observable<{ vehicleMakes: transport.Vehicle.IMaker[] }> {
    return this.query<{ vehicleMakes: transport.Vehicle.IMaker[] }>(GRAPHQL_QUERY.carrier.getVehicleMakes, 1, {}, true);
  }

  /**
   * Queries GraphQL endpoint for vehicle makes.
   */
  public queryLoadingTypes(): Observable<{ loadingTypes: transport.ILoadingType[] }> {
    return this.query<{ loadingTypes: transport.ILoadingType[] }>(GRAPHQL_QUERY.owner.getLoadingTypeList, 1, {}, true);
  }

  /**
   * Make mutation to remove vehicle.
   * @param vehicleId vehicle type id
   * @param [withSpinner] should request start spinner
   * @returns updated value
   */
  public sendVehicleToArchive(vehicleId: number, withSpinner?: boolean): Observable<unknown> {
    return this.mutate(CARRIER_MUTATION.archiveVehicle, { id: vehicleId }, withSpinner);
  }

  /**
   * Make mutation to create new vehicle.
   * @param input is vehicleParam data
   * @param [withSpinner] should request start spinner
   * @returns updated value
   */
  public addVehicleOnTms(input: IVehicleData, withSpinner?: boolean): Observable<{ addVehicleOnTMS: IVehicle }> {
    return this.mutate<{ addVehicleOnTMS: IVehicle }>(CARRIER_MUTATION.addVehicleOnTms, { input }, withSpinner);
  }

  public addVehicleOnTMSFromModal(input: IVehicleData, withSpinner?: boolean): Observable<{ addVehicleOnTMS: IVehicle }> {
    return this.mutate<{ addVehicleOnTMS: IVehicle }>(CARRIER_MUTATION.addVehicleOnTMSFromModal, { input }, withSpinner);
  }

  /**
   * Make mutation to edit vehicle.
   * @param input is vehicleParam data
   * @param [withSpinner] should request start spinner
   * @returns updated value
   */
  public editVehicle(input: IVehicleData, withSpinner?: boolean): Observable<IVehicle> {
    return this.mutate<IVehicle>(CARRIER_MUTATION.editVehicle, { input }, withSpinner);
  }

  public createVehicleRiskProfile(vehicleId: number, withSpinner?: boolean): Observable<string> {
    return this.mutate<{ createVehicleRiskProfile: { profileId: string } }>(
      GRAPHQL_QUERY.carrier.createVehicleRiskProfile,
      { vehicleId },
      withSpinner,
    ).pipe(map(data => data?.createVehicleRiskProfile?.profileId));
  }

  public submitVehicleRiskProfile(profileId: string, vehicleId: number, withSpinner?: boolean): Observable<string> {
    return this.mutate<string>(GRAPHQL_QUERY.carrier.submitVehicleRiskProfile, { profileId, vehicleId }, withSpinner);
  }

  public createDriverRiskProfile(driverId: number, withSpinner?: boolean): Observable<string> {
    return this.mutate<{ createDriverRiskProfile: { profileId: string } }>(
      GRAPHQL_QUERY.carrier.createDriverRiskProfile,
      { driverId },
      withSpinner,
    ).pipe(map(data => data?.createDriverRiskProfile?.profileId));
  }

  public submitDriverRiskProfile(profileId: string, driverId: number, withSpinner?: boolean): Observable<string> {
    return this.mutate<string>(GRAPHQL_QUERY.carrier.submitDriverRiskProfile, { profileId, driverId }, withSpinner);
  }

  public queryDriversSelector(
    listenX = 1,
    withSpinner?: boolean,
    options?: Record<string, unknown>,
  ): Observable<{
    drivers: IDriver[];
    totalCount: number;
  }> {
    return this.sharedGraphQlClient.query<{
      drivers: IDriver[];
      totalCount: number;
    }>(this.serviceUserRole, GRAPHQL_QUERY.carrier.getDriversSelector, listenX, options, withSpinner);
  }

  public queryDrivers(
    listenX = 1,
    withSpinner?: boolean,
    options?: Record<string, unknown>,
  ): Observable<{
    drivers: IDriver[];
    totalCount: number;
  }> {
    return this.sharedGraphQlClient.query<{
      drivers: IDriver[];
      totalCount: number;
    }>(this.serviceUserRole, GRAPHQL_QUERY.carrier.getDrivers, listenX, options, withSpinner);
  }

  public vehicleDriver(vehicleId: string, orderId?: string, listenX = 1, withSpinner?: boolean): Observable<{ vehicleDrivers: IDriver[] }> {
    const params = Boolean(orderId) ? { vehicleId, orderId } : { vehicleId };
    return this.query<{ vehicleDrivers: IDriver[] }>(GRAPHQL_QUERY.carrier.getVehicleDrivers, listenX, params, withSpinner);
  }

  public vehicleByDriver(
    driverId: string,
    orderId?: string,
    listenX = 1,
    withSpinner?: boolean,
  ): Observable<{ driverVehicles: IVehicle[] }> {
    const params = Boolean(orderId) ? { driverId, orderId } : { driverId };
    return this.query<{ driverVehicles: IVehicle[] }>(GRAPHQL_QUERY.carrier.getVehicleByDriver, listenX, params, withSpinner);
  }

  /**
   * Queries GraphQL endpont for driver by id.
   * @param id driver id
   * @param listenX number of responses to catch
   * @param [withSpinner] should request start spinner
   */
  public queryDriver(id: string, listenX = 1, withSpinner?: boolean): Observable<{ driver: IDriver }> {
    return this.query<{ driver: IDriver }>(GRAPHQL_QUERY.carrier.getDriver, listenX, { id }, withSpinner);
  }

  /**
   * Make mutation to remove driver.
   * @param driverId cargo type id
   * @param [withSpinner] should request start spinner
   * @returns updated value
   */
  public archiveDriver(driverId: string, withSpinner?: boolean): Observable<unknown> {
    return this.mutate(CARRIER_MUTATION.archiveDriver, { driverId }, withSpinner);
  }

  /**
   * Make mutation to create new driver.
   * @param driverParam driver data
   * @param [withSpinner] should request start spinner
   * @returns updated value
   */
  public addDriver(driverParam: Record<string, unknown>, withSpinner?: boolean): Observable<{ addDriver: IDriver }> {
    return this.mutate<{ addDriver: IDriver }>(CARRIER_MUTATION.addDriver, driverParam, withSpinner);
  }

  /**
   * Make mutation to update driver.
   * @param driverParam driver data
   * @param [withSpinner] should request start spinner
   * @returns updated value
   */
  public editDriver(driverParam: Record<string, unknown>, withSpinner?: boolean): Observable<{ editDriver: IDriver }> {
    return this.mutate<{ editDriver: IDriver }>(CARRIER_MUTATION.editDriver, driverParam, withSpinner);
  }

  public getRouteSheets(driverId: string): Observable<{ routeSheets: { id: string; status; order: IOrder }[] }> {
    return this.query<{ routeSheets: { id: string; status; order: IOrder }[] }>(
      GRAPHQL_QUERY.carrier.getRouteSheets,
      1,
      { driverId },
      false,
    );
  }

  public getActiveRouteSheet(driverId: string, listenX = 1, withSpinner?: boolean): Observable<unknown> {
    return this.query(GRAPHQL_QUERY.carrier.getActiveRouteSheet, listenX, { driverId }, withSpinner);
  }

  public getRouteSheetEvents(routeSheetId: string): Observable<{ routeSheetEvents: transport.RouteSheetEvent[] }> {
    return this.query<{ routeSheetEvents: transport.RouteSheetEvent[] }>(
      GRAPHQL_QUERY.carrier.getRouteSheetEvents,
      1,
      { routeSheetId },
      false,
    );
  }

  private ordersFilterToDto(filter: TCarrierOrdersFilter): TCarrierOrdersFilterDto {
    let res: TCarrierOrdersFilterDto = {
      totalCount: true,
      // TODO: 2142 при запуске "Рынка" убрать проверку hideInProd
      biddingType: this.getBiddingType(filter),
      loadingPlaceByFiasCodes: filter.loadingPlaceByFiasCodes as string[],
      unloadingPlaceByFiasCodes: filter.unloadingPlaceByFiasCodes as string[],
    };
    res = { ...res, ...this.getOptionalFilters(filter) };
    switch (filter.type) {
      case ORDERS_FILTER_MODE.ALL:
        res.allocationType = ORDER_ALLOCATION_TYPE_GROUP.SIMPLE;
        break;

      case ORDERS_FILTER_MODE.AUCTION:
        res.allocationType = ORDER_ALLOCATION_TYPE_GROUP.AUCTION;
        res.status = ORDER_STATUS.FREE;
        break;

      case ORDERS_FILTER_MODE.TAKEN:
        res.statuses = [ORDER_STATUS.ASSIGNED, ORDER_STATUS.TRANSPORT_RESERVED, ORDER_STATUS.CONTRACT_ATTACHED, ORDER_STATUS.READY_TO_GO];
        res.allocationType = ORDER_ALLOCATION_TYPE_GROUP.ALL;
        break;

      case ORDERS_FILTER_MODE.FREE:
        res.status = ORDER_STATUS.FREE;
        break;

      default:
        throw new Error('Неизвестный filter.type');
    }

    return res;
  }

  private biddingOrdersFilterToDto(filter: TCarrierOrdersFilter): TCarrierOrdersFilterDto {
    let res: TCarrierOrdersFilterDto = {
      totalCount: true,
      // TODO: 2142 при запуске "Рынка" убрать проверку hideInProd
      biddingType: this.getBiddingType(filter),
      loadingPlaceByFiasCodes: filter.loadingPlaceByFiasCodes as string[],
      unloadingPlaceByFiasCodes: filter.unloadingPlaceByFiasCodes as string[],
      onlyMy: Boolean(filter.onlyMy),
    };
    res = { ...res, ...this.getOptionalFilters(filter) };
    switch (filter.type) {
      case ORDERS_FILTER_MODE.BIDS:
        res.allocationType = ORDER_ALLOCATION_TYPE_GROUP.BIDDING;
        res.status = ORDER_STATUS.FREE;
        break;
      default:
        throw new Error('Неизвестный filter.type');
    }

    return res;
  }

  private getBiddingType(filter: TCarrierOrdersFilter): BIDDING_TYPE {
    return this.featureAccess.hideInProd ? BIDDING_TYPE.ALL : filter.publishType?.biddingType ?? BIDDING_TYPE.ALL;
  }

  private getOptionalFilters(filter: TCarrierOrdersFilter): Partial<TCarrierOrdersFilterDto> {
    let filterValues = {} as Partial<TCarrierOrdersFilterDto>;
    if (Boolean(filter.actualProblemEvent)) {
      filterValues.actualProblemEvent = filter.actualProblemEvent;
    }
    if (Boolean(filter.id)) {
      filterValues.id = filter.id;
    }
    if (Boolean(filter.externalNo)) {
      filterValues.externalNo = filter.externalNo;
    }
    if (Boolean(filter.driverDataFullname)) {
      filterValues.driverDataFullname = filter.driverDataFullname;
    }
    if (Boolean(filter.vehicleDataRegNo)) {
      filterValues.vehicleDataRegNo = filter.vehicleDataRegNo;
    }
    if (Boolean(filter.loadingDates)) {
      filterValues = {
        ...filterValues,
        ...this.sharedGraphQlClient.getDateInterval(filter.loadingDates, 'loadingDate'),
      };
    }
    if (Boolean(filter.unloadingDates)) {
      filterValues = {
        ...filterValues,
        ...this.sharedGraphQlClient.getDateInterval(filter.unloadingDates, 'unloadingDate'),
      };
    }

    return filterValues;
  }

  /**
   * Is logged in subscription.
   */
  private isLoggedInSubscription(): void {
    void this.userFacade.isLoggedIn$.subscribe((token: string) => {
      if (Boolean(token)) {
        const role: USER_ROLE = this.userFacade.currentUserRole;
        if (role === this.serviceUserRole) {
          this.sharedGraphQlClient.resetApolloClientFor(this.serviceUserRole);
        }
      }
    });
  }

  public queryPackagingTypes(): Observable<ICargoPackagingType[]> {
    return this.query<{ listPackagingTypesForCargoType: string[] }>(GRAPHQL_QUERY.carrier.getPackagingTypes, 1).pipe(
      map((value: { listPackagingTypesForCargoType: string[] }) => this.sharedGraphQlClient.getPackagingTypesListMap(value)),
    );
  }

  public getProfileInfo(): Observable<{ profile: { organization: transport.IAdminOrganization } }> {
    return this.query<{ profile: { organization: transport.IAdminOrganization } }>(GRAPHQL_QUERY.carrier.getProfile, 1, {}, true);
  }

  public queryInsuranceInfo(
    orderId: string,
    withSpinner?: boolean,
  ): Observable<{
    cargoClasses: { id: string; name: string }[];
    cargoKinds: { id: string; name: string }[];
  }> {
    return this.query(
      GRAPHQL_QUERY.carrier.getInsuranceInfo,
      1,
      {
        orderId,
      },
      withSpinner,
    );
  }

  public queryInsuranceCost(orderId: string, declaredPrice: number, withSpinner?: boolean): Observable<{ insuranceCost: number }> {
    return this.query<{ insuranceCost: number }>(GRAPHQL_QUERY.carrier.getInsuranceCost, 1, { orderId, declaredPrice }, withSpinner);
  }

  public addInsuranceContract(
    orderId: string,
    insurance: transport.IInsurance,
    withSpinner?: boolean,
  ): Observable<{ addInsuranceOrder: Partial<transport.IOrder> }> {
    return this.mutate<{ addInsuranceOrder: Partial<transport.IOrder> }>(
      CARRIER_MUTATION.addInsurance,
      { input: { orderId, insurance } },
      withSpinner,
    );
  }

  // registration queries
  public getCarrierUser(id: string, withSpinner?: boolean): Observable<{ carrierUser: transport.ICarrierUser }> {
    return this.query<{ carrierUser: transport.ICarrierUser }>(GRAPHQL_QUERY.carrier.getCarrierUser, 1, { id }, withSpinner);
  }

  public getCarrierUserMarkerAttributes(id: string, withSpinner?: boolean): Observable<{ carrierUser: transport.ICarrierUser }> {
    return this.query<{ carrierUser: transport.ICarrierUser }>(
      GRAPHQL_QUERY.carrier.getCarrierUserMarketAttributes,
      1,
      { id },
      withSpinner,
    );
  }

  public addDriverOnRegistration(params: IDriverForm, withSpinner?: boolean): Observable<transport.IDriver> {
    return this.mutate(CARRIER_MUTATION.addDriver, { input: params }, withSpinner);
  }

  public addDocumentForOrganization(
    { documentTypeId, uploadedFileId },
    withSpinner?: boolean,
  ): Observable<{ addOrganizationUploadedDocument: IOrganizationDocument }> {
    return this.mutate<{ addOrganizationUploadedDocument: IOrganizationDocument }>(
      CARRIER_MUTATION.addDocumentForOrganization,
      { documentTypeId, uploadedFileId },
      withSpinner,
    );
  }

  public deleteOrganizationDocument(
    docId: string,
    withSpinner?: boolean,
  ): Observable<{ removeOrganizationUploadedDocument: IOrganizationDocument }> {
    return this.mutate<{ removeOrganizationUploadedDocument: IOrganizationDocument }>(
      CARRIER_MUTATION.removeOrganizationDocument,
      { docId },
      withSpinner,
    );
  }

  public getOrganizationDocumentsType(withSpinner?: boolean): Observable<{ organizationDocumentTypes: IFileType[] }> {
    return this.query<{ organizationDocumentTypes: IFileType[] }>(GRAPHQL_QUERY.carrier.getOrganizationDocumentsType, 1, {}, withSpinner);
  }

  public getOrganizationDocuments(withSpinner?: boolean): Observable<{ organizationDocuments: IOrganizationDocument[] }> {
    return this.query<{ organizationDocuments: IOrganizationDocument[] }>(
      GRAPHQL_QUERY.carrier.getOrganizationDocuments,
      1,
      {},
      withSpinner,
    );
  }

  public getOrganizationByInn(
    inn: string,
    legalFormCode: LEGAL_FORM_CODES,
    withSpinner?: boolean,
  ): Observable<{ organizationDataByInn: IOrganizationData }> {
    return this.query<{ organizationDataByInn: IOrganizationData }>(
      GRAPHQL_QUERY.carrier.getOrganizationDataByInn,
      1,
      { inn, legalFormCode: (legalFormCode as string).slice(LEGAL_FORM_CODE_BACKEND_ENUM_PREFIX.length) },
      withSpinner,
    );
  }

  public queryLegalForms(): Observable<{ legalForms: transport.ILegalForm[] }> {
    return this.query<{ legalForms: transport.ILegalForm[] }>(GRAPHQL_QUERY.shared.getLegalForms, 1);
  }

  public inviteDriver(driverId: string, withSpinner?: boolean): Observable<{ inviteDriverToMobile: boolean }> {
    return this.mutate<{ inviteDriverToMobile: boolean }>(CARRIER_MUTATION.inviteDriver, { driverId }, withSpinner);
  }

  public getAgentCarriers(
    listenX = 1,
    withSpinner?: boolean,
    options?: Record<string, unknown>,
  ): Observable<{ agentCarriers: transport.IAgentCarrier[]; totalCount: number }> {
    return this.query<{ agentCarriers: transport.IAgentCarrier[]; totalCount: number }>(
      GRAPHQL_QUERY.carrier.getAgentCarriers,
      listenX,
      options,
      withSpinner,
    );
  }

  public getAgentCarrier(id: string): Observable<{ agentCarrier: transport.IAgentCarrier }> {
    return this.query<{ agentCarrier: transport.IAgentCarrier }>(GRAPHQL_QUERY.carrier.getAgentCarrier, 1, { id });
  }

  public addAgentCarrier(agentCarrierData: IAgentCarrierInput): Observable<{ addAgentCarrier: transport.IAgentCarrier }> {
    return this.query<{ addAgentCarrier: transport.IAgentCarrier }>(CARRIER_MUTATION.addAgentCarrier, 1, { input: agentCarrierData });
  }

  public editAgentCarrier(agentCarrierData: IAgentCarrierEditInput): Observable<{ editAgentCarrier: transport.IAgentCarrier }> {
    return this.query<{ editAgentCarrier: transport.IAgentCarrier }>(CARRIER_MUTATION.editAgentCarrier, 1, { input: agentCarrierData });
  }

  public sendAgentCarrierToArchive(id: string): Observable<{ sendAgentCarrierToArchive: transport.IAgentCarrier }> {
    return this.query<{ sendAgentCarrierToArchive: transport.IAgentCarrier }>(CARRIER_MUTATION.sendAgentCarrierToArchive, 1, { id });
  }

  public setCoDriver(coDriverId: string | null, orderId: string): Observable<IOrder> {
    return this.mutate<{ setCoDriver: IOrder }>(CARRIER_MUTATION.setCoDriver, { coDriverId, orderId }, false).pipe(
      map(result => result.setCoDriver),
    );
  }

  public queryStoragePoints(
    listenX = 1,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
    options?: Record<string, unknown>,
  ): Observable<{
    storagePoints: IPlace[];
    totalCount: number | undefined;
  }> {
    const variables = { ...(options ?? { first: 0, offset: 0 }) };
    if ((first ?? 0) > 0 && (offset ?? 0) >= 0) {
      variables.first = first;
      variables.offset = offset;
    }
    return this.query<{
      storagePoints: IPlace[];
      totalCount: number | undefined;
    }>(GRAPHQL_QUERY.shared.getStoragePoints, listenX, variables, withSpinner);
  }

  public getCarrierContractsWithCargoOwner(
    cargoOwnerUserId: string,
  ): Observable<{ carrierContractsWithCargoOwner: transport.Order.ICarrierContract[] }> {
    return this.query<{
      carrierContractsWithCargoOwner: transport.Order.ICarrierContract[];
    }>(GRAPHQL_QUERY.carrier.getCarrierContractsWithCargoOwner, 1, { cargoOwnerUserId });
  }

  public getCargoOwnerWithBusinessRelationshipWithCarrier(): Observable<transport.cargoOwnerWithContractWithCarrier> {
    return this.query<transport.cargoOwnerWithContractWithCarrier>(
      GRAPHQL_QUERY.carrier.getCargoOwnerWithBusinessRelationshipWithCarrier,
      1,
    );
  }

  public editOrganization(organization: transport.IEditOrganizationInput): Observable<{ editOrganization: transport.IAdminOrganization }> {
    return this.mutate<{ editOrganization: transport.IAdminOrganization }>(CARRIER_MUTATION.saveOrganizationProfile, {
      input: { ...organization },
    });
  }

  public getCargoOwnversWithBusinessRelationshipData(): Observable<transport.CargoOwnersDataWithBusinessRelationship> {
    return this.query<transport.CargoOwnersDataWithBusinessRelationship>(GRAPHQL_QUERY.carrier.getOwnersDataWithBusinessRelationship, 1);
  }

  public getAcreditationStatus(ownerINN: string): Observable<transport.IAcreditationStatus> {
    return this.query<transport.IAcreditationStatus>(GRAPHQL_QUERY.carrier.getAcreditationStatus, 1, { inn: ownerINN });
  }

  public getCompanyINN(subdomain: string | null): Observable<transport.IOwnerOrganizationINN> {
    const queryString = GRAPHQL_QUERY.carrier.getOwnerCompanyINN;
    return this.query<transport.IOwnerOrganizationINN>(queryString, 1, {
      subdomain: subdomain,
    });
  }
}
