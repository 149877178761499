import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { STEPS_LIST, TnVerificationModalComponent } from 'apps/marketplace/src/app/verification/verification-modal/verification-modal.component';
import { TnGqlClientSharedService } from '@transport/ui-store';
import { USER_ROLE } from '@transport/ui-interfaces';
import { VERIFICATION_FLOW_QUERIES } from 'apps/marketplace/src/app/verification/verification-modal/verification-flow.queruies';
import { of, switchMap } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TnAnalyticsService } from 'libs/transport-ui-store/src/lib/current-user/services/analytics/analytics.service';

@UntilDestroy()
@Injectable()
export class VerificationFlowService {
  public dialogRef?: MatDialogRef<any>;

  constructor(
    private readonly dialog: MatDialog,
    private readonly sharedGraphQlClient: TnGqlClientSharedService, 
    private readonly analytics: TnAnalyticsService
  ) {}

  public openModal(isOpenFromSidebar?: boolean): void {
    this.analytics.track(`user clicked get accreditation on ${isOpenFromSidebar ? 'sidebar' : 'market'}`);
    this.dialogRef = this.dialog.open(TnVerificationModalComponent, {
      disableClose: true,
      maxHeight: '90vh',
      maxWidth: '608px',
      minWidth: '320px',
      panelClass: 'verification-modal',
    });
  }

  save(profile: any) {
    return this.sharedGraphQlClient.mutate(USER_ROLE.CARRIER, VERIFICATION_FLOW_QUERIES.createCarrierRiskProfile, {}).pipe(
      untilDestroyed(this),
      catchError(error => of(undefined)),
      switchMap((value: any) => {
        return of(value?.createCarrierRiskProfile?.profileId);
      }),
      switchMap((id: string) => {
        return this.sharedGraphQlClient
          .mutate(USER_ROLE.CARRIER, VERIFICATION_FLOW_QUERIES.addCarrierRiskProfileDossier, {
            docType: 'INPUT',
            docVal: JSON.stringify(profile),
            profileId: id,
          })
          .pipe(map(res => id));
      }),
      switchMap((id: string) => {
        return this.sharedGraphQlClient.mutate(USER_ROLE.CARRIER, VERIFICATION_FLOW_QUERIES.submitCarrierRiskProfile, {
          profileId: id,
        });
      }),
      catchError(error => of(undefined)),
    );
  }

  public closeModal(stepIndex: number | null): void {
    if (stepIndex !== null) {
      this.analytics.track(`user closed verification form on ${STEPS_LIST[stepIndex]} step`);
    }
    this.dialogRef?.close();
  }

  public changeStep(prevStepIndex: number, selectedStepIndex: number, buttonName?: string) {
    if (buttonName) {
      this.analytics.track(`user clicked on ${buttonName} button on ${STEPS_LIST[prevStepIndex]} step`);
    } else {
      this.analytics.track(`user changed step from ${STEPS_LIST[prevStepIndex]} to ${STEPS_LIST[selectedStepIndex]}`);
    }
  }
}
