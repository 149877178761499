export const supervisorLocalization = {
  title: 'Супервизор',
  description: {
    main: 'Вы увидите ситуацию глазами грузоперевозчика, но не сможете управлять аккаунтом и редактировать заявки',
    user: 'Выберите пользователя, с которым начнёте сеанс супервизора',
  },
  form: {
    login: 'Ваш логин',
    password: 'Пароль',
    selectAgentCarrier: 'Выбрать перевозчика',
    organization: 'Организация',
    fio: 'ФИО пользователя',
    userLogin: 'Логин пользователя',
    startSeans: 'Начать сеанс',
    errors: {
      notSupervisor: 'Данный пользователь не является супервизором',
    },
  },
  supervisorTimeExpiredModal: {
    title: 'Время вышло',
    content: 'Сеанс закончен. Чтобы продолжить работу, повторно авторизуйтесь в статусе супервизора.',
    confirm: 'Закончить сеанс',
  },
  tabs: {
    organization: 'По организации',
    login: 'По логину',
  },
};
