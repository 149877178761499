<div class="container flex justify-start items-center" [ngClass]="getClass">
  <ng-container *ngIf="!hasTitle && hasIcon">
    <div
      *ngIf="tooltip"
      class="icon-container flex justify-center items-center"
      [ngClass]="getClass"
      transportTooltip
      [context]="{message: tooltip}"
    >
      <ng-container *ngTemplateOutlet="icons"></ng-container>
    </div>
    <div *ngIf="!tooltip" class="icon-container flex justify-center items-center" [ngClass]="getClass">
      <ng-container *ngTemplateOutlet="icons"></ng-container>
    </div>
  </ng-container>
  <div *ngIf="hasTitle && hasIcon" class="title-container">{{ title }}</div>
  <div class="message">
    <ng-content></ng-content>
  </div>
</div>

<ng-template #icons>
  <mat-icon *ngIf="type === 'warning'" svgIcon="warning"></mat-icon>
  <mat-icon *ngIf="type === 'success'">check_material</mat-icon>
  <mat-icon *ngIf="type === 'error'">error</mat-icon>
  <mat-icon *ngIf="type === 'info' || type === 'gray-info'" svgIcon="info"></mat-icon>
</ng-template>
