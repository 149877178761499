import { Pipe, PipeTransform } from '@angular/core';
import { regExpConfig } from '@transport/ui-utils';
import { ICommonSelectData } from 'libs/custom-controls/src/lib/common.interface';
import memo from 'memo-decorator';
import { NgxMaskService } from 'ngx-mask';

export enum TnSupportedCurrency {
  RUB = 'RUB',
  // EUR = 'EUR',
  USD = 'USD',
  KZT = 'KZT',
  CNY = 'CNY',
}

export enum TnSupportedCountry {
  RU = 'RU',
  KZ = 'KZ',
  BY = 'BY',
  NO = 'NO',
  UZ = 'UZ',
  TJ = 'TJ',
  KG = 'KG',
}

export enum TnSupportedCountryName {
  RU = 'Россия',
  KZ = 'Казахстан',
  BY = 'Беларусь',
  NO = 'Другое',
  UZ = 'Узбекистан',
  TJ = 'Таджикистан',
  KG = 'Киргизия',
}

export enum TnSupportedCitizenNumbers {
  RU = '+7',
  BY = '+375',
  UZ = '+998',
  KG = '+996',
  TJ = '+992',
}

export const CITIZEN_NUMBERS: ICommonSelectData<TnSupportedCountry>[] = [
  { id: TnSupportedCountry.RU, label: TnSupportedCitizenNumbers.RU },
  { id: TnSupportedCountry.KZ, label: TnSupportedCitizenNumbers.RU, hidden: true },
  { id: TnSupportedCountry.BY, label: TnSupportedCitizenNumbers.BY },
  { id: TnSupportedCountry.UZ, label: TnSupportedCitizenNumbers.UZ },
  { id: TnSupportedCountry.KG, label: TnSupportedCitizenNumbers.KG },
  { id: TnSupportedCountry.TJ, label: TnSupportedCitizenNumbers.TJ },
  { id: TnSupportedCountry.NO, label: TnSupportedCitizenNumbers.RU, hidden: true },
];

export const COUNTRIES: ICommonSelectData[] = [
  { id: TnSupportedCountry.RU, label: TnSupportedCountryName.RU },
  { id: TnSupportedCountry.KZ, label: TnSupportedCountryName.KZ },
  { id: TnSupportedCountry.BY, label: TnSupportedCountryName.BY },
  { id: TnSupportedCountry.UZ, label: TnSupportedCountryName.UZ },
  { id: TnSupportedCountry.KG, label: TnSupportedCountryName.KG },
  { id: TnSupportedCountry.TJ, label: TnSupportedCountryName.TJ },
  { id: TnSupportedCountry.NO, label: TnSupportedCountryName.NO },
];

export const CountryNames = {
  [TnSupportedCountryName.RU]: TnSupportedCountry.RU,
  [TnSupportedCountryName.KZ]: TnSupportedCountry.KZ,
  [TnSupportedCountryName.BY]: TnSupportedCountry.BY,
  [TnSupportedCountryName.TJ]: TnSupportedCountry.TJ,
  [TnSupportedCountryName.KG]: TnSupportedCountry.KG,
  [TnSupportedCountryName.UZ]: TnSupportedCountry.UZ,
  [TnSupportedCountryName.NO]: TnSupportedCountry.NO,
};

export const CurrencySigns = {
  [TnSupportedCurrency.KZT]: '₸',
  [TnSupportedCurrency.USD]: '$',
  [TnSupportedCurrency.CNY]: '¥',
  [TnSupportedCurrency.RUB]: '₽',
};

export const CurrencyBet = {
  [TnSupportedCurrency.KZT]: 112,
  [TnSupportedCurrency.RUB]: 120,
  [TnSupportedCurrency.CNY]: 120,
  [TnSupportedCurrency.USD]: 120,
};

export const CurrenciesSelect = [
  { id: TnSupportedCurrency.RUB, label: CurrencySigns[TnSupportedCurrency.RUB] },
  { id: TnSupportedCurrency.KZT, label: CurrencySigns[TnSupportedCurrency.KZT] },
  { id: TnSupportedCurrency.USD, label: CurrencySigns[TnSupportedCurrency.USD] },
  { id: TnSupportedCurrency.CNY, label: CurrencySigns[TnSupportedCurrency.CNY] },
];

/**
 * Pipe for currency value
 */
@Pipe({
  name: 'currencyMulti',
})
export class TnMultiCurrencyPipe implements PipeTransform {
  constructor(private readonly maskService: NgxMaskService) {}

  /**
   * Transform sts number string to special string format
   * @param value Value string
   * @param currency Value string
   * @param decimalDigits Value boolean. Used to show if showing decimal digits is needed.
   * @param suffix Value string. Used to replace Ruble sign in the end with the value.
   */
  @memo({
    resolver: (...args: any[]): string => JSON.stringify(args),
  })
  public transform(
    value: number | string | null | undefined,
    currency?: TnSupportedCurrency | null,
    decimalDigits = true,
    suffix = '',
  ): string {
    let val = typeof value === 'string' ? String(parseFloat(value)) : value?.toString();
    if (Boolean(val) && decimalDigits) {
      val += val?.includes('.') ? '00' : ',00';
    }
    this.maskService.thousandSeparator = ' ';
    this.maskService.allowNegativeNumbers = true;
    const result = this.maskService.applyMaskWithPattern(val ?? '', [
      regExpConfig.ngxMask.currency.rur.mask,
      { ...regExpConfig.ngxMask.currency.rur.patterns },
    ]);

    const curr = (currency && CurrencySigns[currency]) || '\u20BD';

    return result !== '' ? (suffix ? `${result} ${suffix}` : `${result} ${curr}`) : '';
  }
}
