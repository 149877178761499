/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

const $util = $protobuf.util;

const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const transport = $root.transport = (() => {

    const transport = {};

    transport.AddAuctionOrderInput = (function() {

        function AddAuctionOrderInput(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        AddAuctionOrderInput.prototype.order = null;
        AddAuctionOrderInput.prototype.lot = null;
        AddAuctionOrderInput.prototype.isMarket = false;

        AddAuctionOrderInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.AddAuctionOrderInput)
                return d;
            var m = new $root.transport.AddAuctionOrderInput();
            if (d.order != null) {
                if (typeof d.order !== "object")
                    throw TypeError(".transport.AddAuctionOrderInput.order: object expected");
                m.order = $root.transport.AddOrderInput.fromObject(d.order);
            }
            if (d.lot != null) {
                if (typeof d.lot !== "object")
                    throw TypeError(".transport.AddAuctionOrderInput.lot: object expected");
                m.lot = $root.transport.LotInput.fromObject(d.lot);
            }
            if (d.isMarket != null) {
                m.isMarket = Boolean(d.isMarket);
            }
            return m;
        };

        AddAuctionOrderInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.order = null;
                d.lot = null;
                d.isMarket = false;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = $root.transport.AddOrderInput.toObject(m.order, o);
            }
            if (m.lot != null && m.hasOwnProperty("lot")) {
                d.lot = $root.transport.LotInput.toObject(m.lot, o);
            }
            if (m.isMarket != null && m.hasOwnProperty("isMarket")) {
                d.isMarket = m.isMarket;
            }
            return d;
        };

        AddAuctionOrderInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddAuctionOrderInput;
    })();

    transport.AddOrderInput = (function() {

        function AddOrderInput(p) {
            this.loadingPlaces = [];
            this.unloadingPlaces = [];
            this.extraServices = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        AddOrderInput.prototype.loadingPlaces = $util.emptyArray;
        AddOrderInput.prototype.unloadingPlaces = $util.emptyArray;
        AddOrderInput.prototype.cargoType = "";
        AddOrderInput.prototype.weight = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        AddOrderInput.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        AddOrderInput.prototype.comment = "";
        AddOrderInput.prototype.cargoPackagesCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        AddOrderInput.prototype.price = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        AddOrderInput.prototype.carrier = "";
        AddOrderInput.prototype.isVatIncluded = false;
        AddOrderInput.prototype.vehicleRequirements = null;
        AddOrderInput.prototype.cargoPlaceRequirements = null;
        AddOrderInput.prototype.insurance = null;
        AddOrderInput.prototype.carrierGroupId = "";
        AddOrderInput.prototype.draftId = "";
        AddOrderInput.prototype.externalNo = "";
        AddOrderInput.prototype.paymentPeriod = "";
        AddOrderInput.prototype.paymentTypeId = "";
        AddOrderInput.prototype.extraServices = $util.emptyArray;
        AddOrderInput.prototype.copyOfOrderId = "";
        AddOrderInput.prototype.customerOrganization = "";
        AddOrderInput.prototype.temperatureMode = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        AddOrderInput.prototype.tenderId = "";

        AddOrderInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.AddOrderInput)
                return d;
            var m = new $root.transport.AddOrderInput();
            if (d.loadingPlaces) {
                if (!Array.isArray(d.loadingPlaces))
                    throw TypeError(".transport.AddOrderInput.loadingPlaces: array expected");
                m.loadingPlaces = [];
                for (var i = 0; i < d.loadingPlaces.length; ++i) {
                    if (typeof d.loadingPlaces[i] !== "object")
                        throw TypeError(".transport.AddOrderInput.loadingPlaces: object expected");
                    m.loadingPlaces[i] = $root.transport.OrderStoragePointInput.fromObject(d.loadingPlaces[i]);
                }
            }
            if (d.unloadingPlaces) {
                if (!Array.isArray(d.unloadingPlaces))
                    throw TypeError(".transport.AddOrderInput.unloadingPlaces: array expected");
                m.unloadingPlaces = [];
                for (var i = 0; i < d.unloadingPlaces.length; ++i) {
                    if (typeof d.unloadingPlaces[i] !== "object")
                        throw TypeError(".transport.AddOrderInput.unloadingPlaces: object expected");
                    m.unloadingPlaces[i] = $root.transport.OrderStoragePointInput.fromObject(d.unloadingPlaces[i]);
                }
            }
            if (d.cargoType != null) {
                m.cargoType = String(d.cargoType);
            }
            if (d.weight != null) {
                if ($util.Long)
                    (m.weight = $util.Long.fromValue(d.weight)).unsigned = false;
                else if (typeof d.weight === "string")
                    m.weight = parseInt(d.weight, 10);
                else if (typeof d.weight === "number")
                    m.weight = d.weight;
                else if (typeof d.weight === "object")
                    m.weight = new $util.LongBits(d.weight.low >>> 0, d.weight.high >>> 0).toNumber();
            }
            if (d.volume != null) {
                if ($util.Long)
                    (m.volume = $util.Long.fromValue(d.volume)).unsigned = false;
                else if (typeof d.volume === "string")
                    m.volume = parseInt(d.volume, 10);
                else if (typeof d.volume === "number")
                    m.volume = d.volume;
                else if (typeof d.volume === "object")
                    m.volume = new $util.LongBits(d.volume.low >>> 0, d.volume.high >>> 0).toNumber();
            }
            if (d.comment != null) {
                m.comment = String(d.comment);
            }
            if (d.cargoPackagesCount != null) {
                if ($util.Long)
                    (m.cargoPackagesCount = $util.Long.fromValue(d.cargoPackagesCount)).unsigned = false;
                else if (typeof d.cargoPackagesCount === "string")
                    m.cargoPackagesCount = parseInt(d.cargoPackagesCount, 10);
                else if (typeof d.cargoPackagesCount === "number")
                    m.cargoPackagesCount = d.cargoPackagesCount;
                else if (typeof d.cargoPackagesCount === "object")
                    m.cargoPackagesCount = new $util.LongBits(d.cargoPackagesCount.low >>> 0, d.cargoPackagesCount.high >>> 0).toNumber();
            }
            if (d.price != null) {
                if ($util.Long)
                    (m.price = $util.Long.fromValue(d.price)).unsigned = false;
                else if (typeof d.price === "string")
                    m.price = parseInt(d.price, 10);
                else if (typeof d.price === "number")
                    m.price = d.price;
                else if (typeof d.price === "object")
                    m.price = new $util.LongBits(d.price.low >>> 0, d.price.high >>> 0).toNumber();
            }
            if (d.carrier != null) {
                m.carrier = String(d.carrier);
            }
            if (d.isVatIncluded != null) {
                m.isVatIncluded = Boolean(d.isVatIncluded);
            }
            if (d.vehicleRequirements != null) {
                if (typeof d.vehicleRequirements !== "object")
                    throw TypeError(".transport.AddOrderInput.vehicleRequirements: object expected");
                m.vehicleRequirements = $root.transport.VehicleRequirementsInput.fromObject(d.vehicleRequirements);
            }
            if (d.cargoPlaceRequirements != null) {
                if (typeof d.cargoPlaceRequirements !== "object")
                    throw TypeError(".transport.AddOrderInput.cargoPlaceRequirements: object expected");
                m.cargoPlaceRequirements = $root.transport.CargoPlaceRequirementsInput.fromObject(d.cargoPlaceRequirements);
            }
            if (d.insurance != null) {
                if (typeof d.insurance !== "object")
                    throw TypeError(".transport.AddOrderInput.insurance: object expected");
                m.insurance = $root.transport.AddInsuranceInput.fromObject(d.insurance);
            }
            if (d.carrierGroupId != null) {
                m.carrierGroupId = String(d.carrierGroupId);
            }
            if (d.draftId != null) {
                m.draftId = String(d.draftId);
            }
            if (d.externalNo != null) {
                m.externalNo = String(d.externalNo);
            }
            if (d.paymentPeriod != null) {
                m.paymentPeriod = String(d.paymentPeriod);
            }
            if (d.paymentTypeId != null) {
                m.paymentTypeId = String(d.paymentTypeId);
            }
            if (d.extraServices) {
                if (!Array.isArray(d.extraServices))
                    throw TypeError(".transport.AddOrderInput.extraServices: array expected");
                m.extraServices = [];
                for (var i = 0; i < d.extraServices.length; ++i) {
                    m.extraServices[i] = String(d.extraServices[i]);
                }
            }
            if (d.copyOfOrderId != null) {
                m.copyOfOrderId = String(d.copyOfOrderId);
            }
            if (d.customerOrganization != null) {
                m.customerOrganization = String(d.customerOrganization);
            }
            if (d.temperatureMode != null) {
                if ($util.Long)
                    (m.temperatureMode = $util.Long.fromValue(d.temperatureMode)).unsigned = false;
                else if (typeof d.temperatureMode === "string")
                    m.temperatureMode = parseInt(d.temperatureMode, 10);
                else if (typeof d.temperatureMode === "number")
                    m.temperatureMode = d.temperatureMode;
                else if (typeof d.temperatureMode === "object")
                    m.temperatureMode = new $util.LongBits(d.temperatureMode.low >>> 0, d.temperatureMode.high >>> 0).toNumber();
            }
            if (d.tenderId != null) {
                m.tenderId = String(d.tenderId);
            }
            return m;
        };

        AddOrderInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.loadingPlaces = [];
                d.unloadingPlaces = [];
                d.extraServices = [];
            }
            if (o.defaults) {
                d.cargoType = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.weight = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.weight = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.volume = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.volume = o.longs === String ? "0" : 0;
                d.comment = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.cargoPackagesCount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.cargoPackagesCount = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.price = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.price = o.longs === String ? "0" : 0;
                d.carrier = "";
                d.isVatIncluded = false;
                d.vehicleRequirements = null;
                d.cargoPlaceRequirements = null;
                d.insurance = null;
                d.carrierGroupId = "";
                d.draftId = "";
                d.externalNo = "";
                d.paymentPeriod = "";
                d.paymentTypeId = "";
                d.copyOfOrderId = "";
                d.customerOrganization = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.temperatureMode = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.temperatureMode = o.longs === String ? "0" : 0;
                d.tenderId = "";
            }
            if (m.loadingPlaces && m.loadingPlaces.length) {
                d.loadingPlaces = [];
                for (var j = 0; j < m.loadingPlaces.length; ++j) {
                    d.loadingPlaces[j] = $root.transport.OrderStoragePointInput.toObject(m.loadingPlaces[j], o);
                }
            }
            if (m.unloadingPlaces && m.unloadingPlaces.length) {
                d.unloadingPlaces = [];
                for (var j = 0; j < m.unloadingPlaces.length; ++j) {
                    d.unloadingPlaces[j] = $root.transport.OrderStoragePointInput.toObject(m.unloadingPlaces[j], o);
                }
            }
            if (m.cargoType != null && m.hasOwnProperty("cargoType")) {
                d.cargoType = m.cargoType;
            }
            if (m.weight != null && m.hasOwnProperty("weight")) {
                if (typeof m.weight === "number")
                    d.weight = o.longs === String ? String(m.weight) : m.weight;
                else
                    d.weight = o.longs === String ? $util.Long.prototype.toString.call(m.weight) : o.longs === Number ? new $util.LongBits(m.weight.low >>> 0, m.weight.high >>> 0).toNumber() : m.weight;
            }
            if (m.volume != null && m.hasOwnProperty("volume")) {
                if (typeof m.volume === "number")
                    d.volume = o.longs === String ? String(m.volume) : m.volume;
                else
                    d.volume = o.longs === String ? $util.Long.prototype.toString.call(m.volume) : o.longs === Number ? new $util.LongBits(m.volume.low >>> 0, m.volume.high >>> 0).toNumber() : m.volume;
            }
            if (m.comment != null && m.hasOwnProperty("comment")) {
                d.comment = m.comment;
            }
            if (m.cargoPackagesCount != null && m.hasOwnProperty("cargoPackagesCount")) {
                if (typeof m.cargoPackagesCount === "number")
                    d.cargoPackagesCount = o.longs === String ? String(m.cargoPackagesCount) : m.cargoPackagesCount;
                else
                    d.cargoPackagesCount = o.longs === String ? $util.Long.prototype.toString.call(m.cargoPackagesCount) : o.longs === Number ? new $util.LongBits(m.cargoPackagesCount.low >>> 0, m.cargoPackagesCount.high >>> 0).toNumber() : m.cargoPackagesCount;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                if (typeof m.price === "number")
                    d.price = o.longs === String ? String(m.price) : m.price;
                else
                    d.price = o.longs === String ? $util.Long.prototype.toString.call(m.price) : o.longs === Number ? new $util.LongBits(m.price.low >>> 0, m.price.high >>> 0).toNumber() : m.price;
            }
            if (m.carrier != null && m.hasOwnProperty("carrier")) {
                d.carrier = m.carrier;
            }
            if (m.isVatIncluded != null && m.hasOwnProperty("isVatIncluded")) {
                d.isVatIncluded = m.isVatIncluded;
            }
            if (m.vehicleRequirements != null && m.hasOwnProperty("vehicleRequirements")) {
                d.vehicleRequirements = $root.transport.VehicleRequirementsInput.toObject(m.vehicleRequirements, o);
            }
            if (m.cargoPlaceRequirements != null && m.hasOwnProperty("cargoPlaceRequirements")) {
                d.cargoPlaceRequirements = $root.transport.CargoPlaceRequirementsInput.toObject(m.cargoPlaceRequirements, o);
            }
            if (m.insurance != null && m.hasOwnProperty("insurance")) {
                d.insurance = $root.transport.AddInsuranceInput.toObject(m.insurance, o);
            }
            if (m.carrierGroupId != null && m.hasOwnProperty("carrierGroupId")) {
                d.carrierGroupId = m.carrierGroupId;
            }
            if (m.draftId != null && m.hasOwnProperty("draftId")) {
                d.draftId = m.draftId;
            }
            if (m.externalNo != null && m.hasOwnProperty("externalNo")) {
                d.externalNo = m.externalNo;
            }
            if (m.paymentPeriod != null && m.hasOwnProperty("paymentPeriod")) {
                d.paymentPeriod = m.paymentPeriod;
            }
            if (m.paymentTypeId != null && m.hasOwnProperty("paymentTypeId")) {
                d.paymentTypeId = m.paymentTypeId;
            }
            if (m.extraServices && m.extraServices.length) {
                d.extraServices = [];
                for (var j = 0; j < m.extraServices.length; ++j) {
                    d.extraServices[j] = m.extraServices[j];
                }
            }
            if (m.copyOfOrderId != null && m.hasOwnProperty("copyOfOrderId")) {
                d.copyOfOrderId = m.copyOfOrderId;
            }
            if (m.customerOrganization != null && m.hasOwnProperty("customerOrganization")) {
                d.customerOrganization = m.customerOrganization;
            }
            if (m.temperatureMode != null && m.hasOwnProperty("temperatureMode")) {
                if (typeof m.temperatureMode === "number")
                    d.temperatureMode = o.longs === String ? String(m.temperatureMode) : m.temperatureMode;
                else
                    d.temperatureMode = o.longs === String ? $util.Long.prototype.toString.call(m.temperatureMode) : o.longs === Number ? new $util.LongBits(m.temperatureMode.low >>> 0, m.temperatureMode.high >>> 0).toNumber() : m.temperatureMode;
            }
            if (m.tenderId != null && m.hasOwnProperty("tenderId")) {
                d.tenderId = m.tenderId;
            }
            return d;
        };

        AddOrderInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddOrderInput;
    })();

    transport.OrderStoragePointInput = (function() {

        function OrderStoragePointInput(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        OrderStoragePointInput.prototype.storagePoint = "";
        OrderStoragePointInput.prototype.arriveDatetimeFrom = "";
        OrderStoragePointInput.prototype.arriveDatetimeTo = "";
        OrderStoragePointInput.prototype.loadingType = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        OrderStoragePointInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.OrderStoragePointInput)
                return d;
            var m = new $root.transport.OrderStoragePointInput();
            if (d.storagePoint != null) {
                m.storagePoint = String(d.storagePoint);
            }
            if (d.arriveDatetimeFrom != null) {
                m.arriveDatetimeFrom = String(d.arriveDatetimeFrom);
            }
            if (d.arriveDatetimeTo != null) {
                m.arriveDatetimeTo = String(d.arriveDatetimeTo);
            }
            if (d.loadingType != null) {
                if ($util.Long)
                    (m.loadingType = $util.Long.fromValue(d.loadingType)).unsigned = false;
                else if (typeof d.loadingType === "string")
                    m.loadingType = parseInt(d.loadingType, 10);
                else if (typeof d.loadingType === "number")
                    m.loadingType = d.loadingType;
                else if (typeof d.loadingType === "object")
                    m.loadingType = new $util.LongBits(d.loadingType.low >>> 0, d.loadingType.high >>> 0).toNumber();
            }
            return m;
        };

        OrderStoragePointInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.storagePoint = "";
                d.arriveDatetimeFrom = "";
                d.arriveDatetimeTo = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.loadingType = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.loadingType = o.longs === String ? "0" : 0;
            }
            if (m.storagePoint != null && m.hasOwnProperty("storagePoint")) {
                d.storagePoint = m.storagePoint;
            }
            if (m.arriveDatetimeFrom != null && m.hasOwnProperty("arriveDatetimeFrom")) {
                d.arriveDatetimeFrom = m.arriveDatetimeFrom;
            }
            if (m.arriveDatetimeTo != null && m.hasOwnProperty("arriveDatetimeTo")) {
                d.arriveDatetimeTo = m.arriveDatetimeTo;
            }
            if (m.loadingType != null && m.hasOwnProperty("loadingType")) {
                if (typeof m.loadingType === "number")
                    d.loadingType = o.longs === String ? String(m.loadingType) : m.loadingType;
                else
                    d.loadingType = o.longs === String ? $util.Long.prototype.toString.call(m.loadingType) : o.longs === Number ? new $util.LongBits(m.loadingType.low >>> 0, m.loadingType.high >>> 0).toNumber() : m.loadingType;
            }
            return d;
        };

        OrderStoragePointInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OrderStoragePointInput;
    })();

    transport.VehicleRequirementsInput = (function() {

        function VehicleRequirementsInput(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        VehicleRequirementsInput.prototype.bodySubtype = "";
        VehicleRequirementsInput.prototype.capacity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        VehicleRequirementsInput.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        VehicleRequirementsInput.prototype.length = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        VehicleRequirementsInput.prototype.width = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        VehicleRequirementsInput.prototype.height = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        VehicleRequirementsInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.VehicleRequirementsInput)
                return d;
            var m = new $root.transport.VehicleRequirementsInput();
            if (d.bodySubtype != null) {
                m.bodySubtype = String(d.bodySubtype);
            }
            if (d.capacity != null) {
                if ($util.Long)
                    (m.capacity = $util.Long.fromValue(d.capacity)).unsigned = false;
                else if (typeof d.capacity === "string")
                    m.capacity = parseInt(d.capacity, 10);
                else if (typeof d.capacity === "number")
                    m.capacity = d.capacity;
                else if (typeof d.capacity === "object")
                    m.capacity = new $util.LongBits(d.capacity.low >>> 0, d.capacity.high >>> 0).toNumber();
            }
            if (d.volume != null) {
                if ($util.Long)
                    (m.volume = $util.Long.fromValue(d.volume)).unsigned = false;
                else if (typeof d.volume === "string")
                    m.volume = parseInt(d.volume, 10);
                else if (typeof d.volume === "number")
                    m.volume = d.volume;
                else if (typeof d.volume === "object")
                    m.volume = new $util.LongBits(d.volume.low >>> 0, d.volume.high >>> 0).toNumber();
            }
            if (d.length != null) {
                if ($util.Long)
                    (m.length = $util.Long.fromValue(d.length)).unsigned = false;
                else if (typeof d.length === "string")
                    m.length = parseInt(d.length, 10);
                else if (typeof d.length === "number")
                    m.length = d.length;
                else if (typeof d.length === "object")
                    m.length = new $util.LongBits(d.length.low >>> 0, d.length.high >>> 0).toNumber();
            }
            if (d.width != null) {
                if ($util.Long)
                    (m.width = $util.Long.fromValue(d.width)).unsigned = false;
                else if (typeof d.width === "string")
                    m.width = parseInt(d.width, 10);
                else if (typeof d.width === "number")
                    m.width = d.width;
                else if (typeof d.width === "object")
                    m.width = new $util.LongBits(d.width.low >>> 0, d.width.high >>> 0).toNumber();
            }
            if (d.height != null) {
                if ($util.Long)
                    (m.height = $util.Long.fromValue(d.height)).unsigned = false;
                else if (typeof d.height === "string")
                    m.height = parseInt(d.height, 10);
                else if (typeof d.height === "number")
                    m.height = d.height;
                else if (typeof d.height === "object")
                    m.height = new $util.LongBits(d.height.low >>> 0, d.height.high >>> 0).toNumber();
            }
            return m;
        };

        VehicleRequirementsInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.bodySubtype = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.capacity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.capacity = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.volume = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.volume = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.length = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.length = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.width = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.width = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.height = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.height = o.longs === String ? "0" : 0;
            }
            if (m.bodySubtype != null && m.hasOwnProperty("bodySubtype")) {
                d.bodySubtype = m.bodySubtype;
            }
            if (m.capacity != null && m.hasOwnProperty("capacity")) {
                if (typeof m.capacity === "number")
                    d.capacity = o.longs === String ? String(m.capacity) : m.capacity;
                else
                    d.capacity = o.longs === String ? $util.Long.prototype.toString.call(m.capacity) : o.longs === Number ? new $util.LongBits(m.capacity.low >>> 0, m.capacity.high >>> 0).toNumber() : m.capacity;
            }
            if (m.volume != null && m.hasOwnProperty("volume")) {
                if (typeof m.volume === "number")
                    d.volume = o.longs === String ? String(m.volume) : m.volume;
                else
                    d.volume = o.longs === String ? $util.Long.prototype.toString.call(m.volume) : o.longs === Number ? new $util.LongBits(m.volume.low >>> 0, m.volume.high >>> 0).toNumber() : m.volume;
            }
            if (m.length != null && m.hasOwnProperty("length")) {
                if (typeof m.length === "number")
                    d.length = o.longs === String ? String(m.length) : m.length;
                else
                    d.length = o.longs === String ? $util.Long.prototype.toString.call(m.length) : o.longs === Number ? new $util.LongBits(m.length.low >>> 0, m.length.high >>> 0).toNumber() : m.length;
            }
            if (m.width != null && m.hasOwnProperty("width")) {
                if (typeof m.width === "number")
                    d.width = o.longs === String ? String(m.width) : m.width;
                else
                    d.width = o.longs === String ? $util.Long.prototype.toString.call(m.width) : o.longs === Number ? new $util.LongBits(m.width.low >>> 0, m.width.high >>> 0).toNumber() : m.width;
            }
            if (m.height != null && m.hasOwnProperty("height")) {
                if (typeof m.height === "number")
                    d.height = o.longs === String ? String(m.height) : m.height;
                else
                    d.height = o.longs === String ? $util.Long.prototype.toString.call(m.height) : o.longs === Number ? new $util.LongBits(m.height.low >>> 0, m.height.high >>> 0).toNumber() : m.height;
            }
            return d;
        };

        VehicleRequirementsInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VehicleRequirementsInput;
    })();

    transport.CargoPlaceRequirementsInput = (function() {

        function CargoPlaceRequirementsInput(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        CargoPlaceRequirementsInput.prototype.length = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        CargoPlaceRequirementsInput.prototype.width = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        CargoPlaceRequirementsInput.prototype.height = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        CargoPlaceRequirementsInput.prototype.packagingType = "";

        CargoPlaceRequirementsInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.CargoPlaceRequirementsInput)
                return d;
            var m = new $root.transport.CargoPlaceRequirementsInput();
            if (d.length != null) {
                if ($util.Long)
                    (m.length = $util.Long.fromValue(d.length)).unsigned = false;
                else if (typeof d.length === "string")
                    m.length = parseInt(d.length, 10);
                else if (typeof d.length === "number")
                    m.length = d.length;
                else if (typeof d.length === "object")
                    m.length = new $util.LongBits(d.length.low >>> 0, d.length.high >>> 0).toNumber();
            }
            if (d.width != null) {
                if ($util.Long)
                    (m.width = $util.Long.fromValue(d.width)).unsigned = false;
                else if (typeof d.width === "string")
                    m.width = parseInt(d.width, 10);
                else if (typeof d.width === "number")
                    m.width = d.width;
                else if (typeof d.width === "object")
                    m.width = new $util.LongBits(d.width.low >>> 0, d.width.high >>> 0).toNumber();
            }
            if (d.height != null) {
                if ($util.Long)
                    (m.height = $util.Long.fromValue(d.height)).unsigned = false;
                else if (typeof d.height === "string")
                    m.height = parseInt(d.height, 10);
                else if (typeof d.height === "number")
                    m.height = d.height;
                else if (typeof d.height === "object")
                    m.height = new $util.LongBits(d.height.low >>> 0, d.height.high >>> 0).toNumber();
            }
            if (d.packagingType != null) {
                m.packagingType = String(d.packagingType);
            }
            return m;
        };

        CargoPlaceRequirementsInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.length = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.length = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.width = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.width = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.height = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.height = o.longs === String ? "0" : 0;
                d.packagingType = "";
            }
            if (m.length != null && m.hasOwnProperty("length")) {
                if (typeof m.length === "number")
                    d.length = o.longs === String ? String(m.length) : m.length;
                else
                    d.length = o.longs === String ? $util.Long.prototype.toString.call(m.length) : o.longs === Number ? new $util.LongBits(m.length.low >>> 0, m.length.high >>> 0).toNumber() : m.length;
            }
            if (m.width != null && m.hasOwnProperty("width")) {
                if (typeof m.width === "number")
                    d.width = o.longs === String ? String(m.width) : m.width;
                else
                    d.width = o.longs === String ? $util.Long.prototype.toString.call(m.width) : o.longs === Number ? new $util.LongBits(m.width.low >>> 0, m.width.high >>> 0).toNumber() : m.width;
            }
            if (m.height != null && m.hasOwnProperty("height")) {
                if (typeof m.height === "number")
                    d.height = o.longs === String ? String(m.height) : m.height;
                else
                    d.height = o.longs === String ? $util.Long.prototype.toString.call(m.height) : o.longs === Number ? new $util.LongBits(m.height.low >>> 0, m.height.high >>> 0).toNumber() : m.height;
            }
            if (m.packagingType != null && m.hasOwnProperty("packagingType")) {
                d.packagingType = m.packagingType;
            }
            return d;
        };

        CargoPlaceRequirementsInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CargoPlaceRequirementsInput;
    })();

    transport.AddInsuranceInput = (function() {

        function AddInsuranceInput(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        AddInsuranceInput.prototype.insuranceContract = "";
        AddInsuranceInput.prototype.insuranceData = "";

        AddInsuranceInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.AddInsuranceInput)
                return d;
            var m = new $root.transport.AddInsuranceInput();
            if (d.insuranceContract != null) {
                m.insuranceContract = String(d.insuranceContract);
            }
            if (d.insuranceData != null) {
                m.insuranceData = String(d.insuranceData);
            }
            return m;
        };

        AddInsuranceInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.insuranceContract = "";
                d.insuranceData = "";
            }
            if (m.insuranceContract != null && m.hasOwnProperty("insuranceContract")) {
                d.insuranceContract = m.insuranceContract;
            }
            if (m.insuranceData != null && m.hasOwnProperty("insuranceData")) {
                d.insuranceData = m.insuranceData;
            }
            return d;
        };

        AddInsuranceInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddInsuranceInput;
    })();

    transport.AgentCarrier = (function() {

        function AgentCarrier(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        AgentCarrier.prototype.id = "";
        AgentCarrier.prototype.carrierName = "";
        AgentCarrier.prototype.vat = null;
        AgentCarrier.prototype.postalAddress = "";
        AgentCarrier.prototype.inn = "";
        AgentCarrier.prototype.kpp = "";
        AgentCarrier.prototype.name = "";
        AgentCarrier.prototype.legalAddress = "";
        AgentCarrier.prototype.countVehicles = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        AgentCarrier.prototype.isArchived = false;
        AgentCarrier.prototype.lastExistenceCheck = null;
        AgentCarrier.prototype.legalForm = null;

        AgentCarrier.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.AgentCarrier)
                return d;
            var m = new $root.transport.AgentCarrier();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.carrierName != null) {
                m.carrierName = String(d.carrierName);
            }
            if (d.vat != null) {
                if (typeof d.vat !== "object")
                    throw TypeError(".transport.AgentCarrier.vat: object expected");
                m.vat = $root.transport.OrganizationVatType.fromObject(d.vat);
            }
            if (d.postalAddress != null) {
                m.postalAddress = String(d.postalAddress);
            }
            if (d.inn != null) {
                m.inn = String(d.inn);
            }
            if (d.kpp != null) {
                m.kpp = String(d.kpp);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.legalAddress != null) {
                m.legalAddress = String(d.legalAddress);
            }
            if (d.countVehicles != null) {
                if ($util.Long)
                    (m.countVehicles = $util.Long.fromValue(d.countVehicles)).unsigned = false;
                else if (typeof d.countVehicles === "string")
                    m.countVehicles = parseInt(d.countVehicles, 10);
                else if (typeof d.countVehicles === "number")
                    m.countVehicles = d.countVehicles;
                else if (typeof d.countVehicles === "object")
                    m.countVehicles = new $util.LongBits(d.countVehicles.low >>> 0, d.countVehicles.high >>> 0).toNumber();
            }
            if (d.isArchived != null) {
                m.isArchived = Boolean(d.isArchived);
            }
            if (d.lastExistenceCheck != null) {
                if (typeof d.lastExistenceCheck !== "object")
                    throw TypeError(".transport.AgentCarrier.lastExistenceCheck: object expected");
                m.lastExistenceCheck = $root.transport.LastExistenceCheck.fromObject(d.lastExistenceCheck);
            }
            if (d.legalForm != null) {
                if (typeof d.legalForm !== "object")
                    throw TypeError(".transport.AgentCarrier.legalForm: object expected");
                m.legalForm = $root.transport.LegalForm.fromObject(d.legalForm);
            }
            return m;
        };

        AgentCarrier.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.carrierName = "";
                d.vat = null;
                d.postalAddress = "";
                d.inn = "";
                d.kpp = "";
                d.name = "";
                d.legalAddress = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.countVehicles = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.countVehicles = o.longs === String ? "0" : 0;
                d.isArchived = false;
                d.lastExistenceCheck = null;
                d.legalForm = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.carrierName != null && m.hasOwnProperty("carrierName")) {
                d.carrierName = m.carrierName;
            }
            if (m.vat != null && m.hasOwnProperty("vat")) {
                d.vat = $root.transport.OrganizationVatType.toObject(m.vat, o);
            }
            if (m.postalAddress != null && m.hasOwnProperty("postalAddress")) {
                d.postalAddress = m.postalAddress;
            }
            if (m.inn != null && m.hasOwnProperty("inn")) {
                d.inn = m.inn;
            }
            if (m.kpp != null && m.hasOwnProperty("kpp")) {
                d.kpp = m.kpp;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.legalAddress != null && m.hasOwnProperty("legalAddress")) {
                d.legalAddress = m.legalAddress;
            }
            if (m.countVehicles != null && m.hasOwnProperty("countVehicles")) {
                if (typeof m.countVehicles === "number")
                    d.countVehicles = o.longs === String ? String(m.countVehicles) : m.countVehicles;
                else
                    d.countVehicles = o.longs === String ? $util.Long.prototype.toString.call(m.countVehicles) : o.longs === Number ? new $util.LongBits(m.countVehicles.low >>> 0, m.countVehicles.high >>> 0).toNumber() : m.countVehicles;
            }
            if (m.isArchived != null && m.hasOwnProperty("isArchived")) {
                d.isArchived = m.isArchived;
            }
            if (m.lastExistenceCheck != null && m.hasOwnProperty("lastExistenceCheck")) {
                d.lastExistenceCheck = $root.transport.LastExistenceCheck.toObject(m.lastExistenceCheck, o);
            }
            if (m.legalForm != null && m.hasOwnProperty("legalForm")) {
                d.legalForm = $root.transport.LegalForm.toObject(m.legalForm, o);
            }
            return d;
        };

        AgentCarrier.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AgentCarrier;
    })();

    transport.LastExistenceCheck = (function() {

        function LastExistenceCheck(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        LastExistenceCheck.prototype.createdAt = "";
        LastExistenceCheck.prototype.status = "";

        LastExistenceCheck.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.LastExistenceCheck)
                return d;
            var m = new $root.transport.LastExistenceCheck();
            if (d.createdAt != null) {
                m.createdAt = String(d.createdAt);
            }
            if (d.status != null) {
                m.status = String(d.status);
            }
            return m;
        };

        LastExistenceCheck.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.createdAt = "";
                d.status = "";
            }
            if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                d.createdAt = m.createdAt;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            return d;
        };

        LastExistenceCheck.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LastExistenceCheck;
    })();

    transport.AuctionProlongationParameters = (function() {

        function AuctionProlongationParameters(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        AuctionProlongationParameters.prototype.isProlongationAvailable = false;
        AuctionProlongationParameters.prototype.prolongationTime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        AuctionProlongationParameters.prototype.prolongationCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        AuctionProlongationParameters.prototype.minutesTillEndToProlong = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        AuctionProlongationParameters.prototype.minutesOfProlongation = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        AuctionProlongationParameters.prototype.timesOfProlongation = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        AuctionProlongationParameters.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.AuctionProlongationParameters)
                return d;
            var m = new $root.transport.AuctionProlongationParameters();
            if (d.isProlongationAvailable != null) {
                m.isProlongationAvailable = Boolean(d.isProlongationAvailable);
            }
            if (d.prolongationTime != null) {
                if ($util.Long)
                    (m.prolongationTime = $util.Long.fromValue(d.prolongationTime)).unsigned = false;
                else if (typeof d.prolongationTime === "string")
                    m.prolongationTime = parseInt(d.prolongationTime, 10);
                else if (typeof d.prolongationTime === "number")
                    m.prolongationTime = d.prolongationTime;
                else if (typeof d.prolongationTime === "object")
                    m.prolongationTime = new $util.LongBits(d.prolongationTime.low >>> 0, d.prolongationTime.high >>> 0).toNumber();
            }
            if (d.prolongationCount != null) {
                if ($util.Long)
                    (m.prolongationCount = $util.Long.fromValue(d.prolongationCount)).unsigned = false;
                else if (typeof d.prolongationCount === "string")
                    m.prolongationCount = parseInt(d.prolongationCount, 10);
                else if (typeof d.prolongationCount === "number")
                    m.prolongationCount = d.prolongationCount;
                else if (typeof d.prolongationCount === "object")
                    m.prolongationCount = new $util.LongBits(d.prolongationCount.low >>> 0, d.prolongationCount.high >>> 0).toNumber();
            }
            if (d.minutesTillEndToProlong != null) {
                if ($util.Long)
                    (m.minutesTillEndToProlong = $util.Long.fromValue(d.minutesTillEndToProlong)).unsigned = false;
                else if (typeof d.minutesTillEndToProlong === "string")
                    m.minutesTillEndToProlong = parseInt(d.minutesTillEndToProlong, 10);
                else if (typeof d.minutesTillEndToProlong === "number")
                    m.minutesTillEndToProlong = d.minutesTillEndToProlong;
                else if (typeof d.minutesTillEndToProlong === "object")
                    m.minutesTillEndToProlong = new $util.LongBits(d.minutesTillEndToProlong.low >>> 0, d.minutesTillEndToProlong.high >>> 0).toNumber();
            }
            if (d.minutesOfProlongation != null) {
                if ($util.Long)
                    (m.minutesOfProlongation = $util.Long.fromValue(d.minutesOfProlongation)).unsigned = false;
                else if (typeof d.minutesOfProlongation === "string")
                    m.minutesOfProlongation = parseInt(d.minutesOfProlongation, 10);
                else if (typeof d.minutesOfProlongation === "number")
                    m.minutesOfProlongation = d.minutesOfProlongation;
                else if (typeof d.minutesOfProlongation === "object")
                    m.minutesOfProlongation = new $util.LongBits(d.minutesOfProlongation.low >>> 0, d.minutesOfProlongation.high >>> 0).toNumber();
            }
            if (d.timesOfProlongation != null) {
                if ($util.Long)
                    (m.timesOfProlongation = $util.Long.fromValue(d.timesOfProlongation)).unsigned = false;
                else if (typeof d.timesOfProlongation === "string")
                    m.timesOfProlongation = parseInt(d.timesOfProlongation, 10);
                else if (typeof d.timesOfProlongation === "number")
                    m.timesOfProlongation = d.timesOfProlongation;
                else if (typeof d.timesOfProlongation === "object")
                    m.timesOfProlongation = new $util.LongBits(d.timesOfProlongation.low >>> 0, d.timesOfProlongation.high >>> 0).toNumber();
            }
            return m;
        };

        AuctionProlongationParameters.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.isProlongationAvailable = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.prolongationTime = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.prolongationTime = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.prolongationCount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.prolongationCount = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.minutesTillEndToProlong = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.minutesTillEndToProlong = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.minutesOfProlongation = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.minutesOfProlongation = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.timesOfProlongation = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timesOfProlongation = o.longs === String ? "0" : 0;
            }
            if (m.isProlongationAvailable != null && m.hasOwnProperty("isProlongationAvailable")) {
                d.isProlongationAvailable = m.isProlongationAvailable;
            }
            if (m.prolongationTime != null && m.hasOwnProperty("prolongationTime")) {
                if (typeof m.prolongationTime === "number")
                    d.prolongationTime = o.longs === String ? String(m.prolongationTime) : m.prolongationTime;
                else
                    d.prolongationTime = o.longs === String ? $util.Long.prototype.toString.call(m.prolongationTime) : o.longs === Number ? new $util.LongBits(m.prolongationTime.low >>> 0, m.prolongationTime.high >>> 0).toNumber() : m.prolongationTime;
            }
            if (m.prolongationCount != null && m.hasOwnProperty("prolongationCount")) {
                if (typeof m.prolongationCount === "number")
                    d.prolongationCount = o.longs === String ? String(m.prolongationCount) : m.prolongationCount;
                else
                    d.prolongationCount = o.longs === String ? $util.Long.prototype.toString.call(m.prolongationCount) : o.longs === Number ? new $util.LongBits(m.prolongationCount.low >>> 0, m.prolongationCount.high >>> 0).toNumber() : m.prolongationCount;
            }
            if (m.minutesTillEndToProlong != null && m.hasOwnProperty("minutesTillEndToProlong")) {
                if (typeof m.minutesTillEndToProlong === "number")
                    d.minutesTillEndToProlong = o.longs === String ? String(m.minutesTillEndToProlong) : m.minutesTillEndToProlong;
                else
                    d.minutesTillEndToProlong = o.longs === String ? $util.Long.prototype.toString.call(m.minutesTillEndToProlong) : o.longs === Number ? new $util.LongBits(m.minutesTillEndToProlong.low >>> 0, m.minutesTillEndToProlong.high >>> 0).toNumber() : m.minutesTillEndToProlong;
            }
            if (m.minutesOfProlongation != null && m.hasOwnProperty("minutesOfProlongation")) {
                if (typeof m.minutesOfProlongation === "number")
                    d.minutesOfProlongation = o.longs === String ? String(m.minutesOfProlongation) : m.minutesOfProlongation;
                else
                    d.minutesOfProlongation = o.longs === String ? $util.Long.prototype.toString.call(m.minutesOfProlongation) : o.longs === Number ? new $util.LongBits(m.minutesOfProlongation.low >>> 0, m.minutesOfProlongation.high >>> 0).toNumber() : m.minutesOfProlongation;
            }
            if (m.timesOfProlongation != null && m.hasOwnProperty("timesOfProlongation")) {
                if (typeof m.timesOfProlongation === "number")
                    d.timesOfProlongation = o.longs === String ? String(m.timesOfProlongation) : m.timesOfProlongation;
                else
                    d.timesOfProlongation = o.longs === String ? $util.Long.prototype.toString.call(m.timesOfProlongation) : o.longs === Number ? new $util.LongBits(m.timesOfProlongation.low >>> 0, m.timesOfProlongation.high >>> 0).toNumber() : m.timesOfProlongation;
            }
            return d;
        };

        AuctionProlongationParameters.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AuctionProlongationParameters;
    })();

    transport.BiddingLot = (function() {

        function BiddingLot(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        BiddingLot.prototype.id = "";
        BiddingLot.prototype.order = null;
        BiddingLot.prototype.startDatetime = "";
        BiddingLot.prototype.endDatetime = "";
        BiddingLot.prototype.startPrice = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        BiddingLot.prototype.minStepPrice = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        BiddingLot.prototype.biddingStatus = "";
        BiddingLot.prototype.viewEndDatetime = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        BiddingLot.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.BiddingLot)
                return d;
            var m = new $root.transport.BiddingLot();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.order != null) {
                if (typeof d.order !== "object")
                    throw TypeError(".transport.BiddingLot.order: object expected");
                m.order = $root.transport.Order.fromObject(d.order);
            }
            if (d.startDatetime != null) {
                m.startDatetime = String(d.startDatetime);
            }
            if (d.endDatetime != null) {
                m.endDatetime = String(d.endDatetime);
            }
            if (d.startPrice != null) {
                if ($util.Long)
                    (m.startPrice = $util.Long.fromValue(d.startPrice)).unsigned = false;
                else if (typeof d.startPrice === "string")
                    m.startPrice = parseInt(d.startPrice, 10);
                else if (typeof d.startPrice === "number")
                    m.startPrice = d.startPrice;
                else if (typeof d.startPrice === "object")
                    m.startPrice = new $util.LongBits(d.startPrice.low >>> 0, d.startPrice.high >>> 0).toNumber();
            }
            if (d.minStepPrice != null) {
                if ($util.Long)
                    (m.minStepPrice = $util.Long.fromValue(d.minStepPrice)).unsigned = false;
                else if (typeof d.minStepPrice === "string")
                    m.minStepPrice = parseInt(d.minStepPrice, 10);
                else if (typeof d.minStepPrice === "number")
                    m.minStepPrice = d.minStepPrice;
                else if (typeof d.minStepPrice === "object")
                    m.minStepPrice = new $util.LongBits(d.minStepPrice.low >>> 0, d.minStepPrice.high >>> 0).toNumber();
            }
            if (d.biddingStatus != null) {
                m.biddingStatus = String(d.biddingStatus);
            }
            if (d.viewEndDatetime != null) {
                if ($util.Long)
                    (m.viewEndDatetime = $util.Long.fromValue(d.viewEndDatetime)).unsigned = false;
                else if (typeof d.viewEndDatetime === "string")
                    m.viewEndDatetime = parseInt(d.viewEndDatetime, 10);
                else if (typeof d.viewEndDatetime === "number")
                    m.viewEndDatetime = d.viewEndDatetime;
                else if (typeof d.viewEndDatetime === "object")
                    m.viewEndDatetime = new $util.LongBits(d.viewEndDatetime.low >>> 0, d.viewEndDatetime.high >>> 0).toNumber();
            }
            return m;
        };

        BiddingLot.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.order = null;
                d.startDatetime = "";
                d.endDatetime = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startPrice = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startPrice = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.minStepPrice = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.minStepPrice = o.longs === String ? "0" : 0;
                d.biddingStatus = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.viewEndDatetime = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.viewEndDatetime = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = $root.transport.Order.toObject(m.order, o);
            }
            if (m.startDatetime != null && m.hasOwnProperty("startDatetime")) {
                d.startDatetime = m.startDatetime;
            }
            if (m.endDatetime != null && m.hasOwnProperty("endDatetime")) {
                d.endDatetime = m.endDatetime;
            }
            if (m.startPrice != null && m.hasOwnProperty("startPrice")) {
                if (typeof m.startPrice === "number")
                    d.startPrice = o.longs === String ? String(m.startPrice) : m.startPrice;
                else
                    d.startPrice = o.longs === String ? $util.Long.prototype.toString.call(m.startPrice) : o.longs === Number ? new $util.LongBits(m.startPrice.low >>> 0, m.startPrice.high >>> 0).toNumber() : m.startPrice;
            }
            if (m.minStepPrice != null && m.hasOwnProperty("minStepPrice")) {
                if (typeof m.minStepPrice === "number")
                    d.minStepPrice = o.longs === String ? String(m.minStepPrice) : m.minStepPrice;
                else
                    d.minStepPrice = o.longs === String ? $util.Long.prototype.toString.call(m.minStepPrice) : o.longs === Number ? new $util.LongBits(m.minStepPrice.low >>> 0, m.minStepPrice.high >>> 0).toNumber() : m.minStepPrice;
            }
            if (m.biddingStatus != null && m.hasOwnProperty("biddingStatus")) {
                d.biddingStatus = m.biddingStatus;
            }
            if (m.viewEndDatetime != null && m.hasOwnProperty("viewEndDatetime")) {
                if (typeof m.viewEndDatetime === "number")
                    d.viewEndDatetime = o.longs === String ? String(m.viewEndDatetime) : m.viewEndDatetime;
                else
                    d.viewEndDatetime = o.longs === String ? $util.Long.prototype.toString.call(m.viewEndDatetime) : o.longs === Number ? new $util.LongBits(m.viewEndDatetime.low >>> 0, m.viewEndDatetime.high >>> 0).toNumber() : m.viewEndDatetime;
            }
            return d;
        };

        BiddingLot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return BiddingLot;
    })();

    transport.CargoOwner = (function() {

        function CargoOwner(p) {
            this.contracts = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        CargoOwner.prototype.id = "";
        CargoOwner.prototype.name = "";
        CargoOwner.prototype.about = "";
        CargoOwner.prototype.phoneNumber = "";
        CargoOwner.prototype.email = "";
        CargoOwner.prototype.status = "";
        CargoOwner.prototype.contracts = $util.emptyArray;

        CargoOwner.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.CargoOwner)
                return d;
            var m = new $root.transport.CargoOwner();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.about != null) {
                m.about = String(d.about);
            }
            if (d.phoneNumber != null) {
                m.phoneNumber = String(d.phoneNumber);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            if (d.status != null) {
                m.status = String(d.status);
            }
            if (d.contracts) {
                if (!Array.isArray(d.contracts))
                    throw TypeError(".transport.CargoOwner.contracts: array expected");
                m.contracts = [];
                for (var i = 0; i < d.contracts.length; ++i) {
                    if (typeof d.contracts[i] !== "object")
                        throw TypeError(".transport.CargoOwner.contracts: object expected");
                    m.contracts[i] = $root.google.protobuf.Any.fromObject(d.contracts[i]);
                }
            }
            return m;
        };

        CargoOwner.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.contracts = [];
            }
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.about = "";
                d.phoneNumber = "";
                d.email = "";
                d.status = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.about != null && m.hasOwnProperty("about")) {
                d.about = m.about;
            }
            if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                d.phoneNumber = m.phoneNumber;
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.contracts && m.contracts.length) {
                d.contracts = [];
                for (var j = 0; j < m.contracts.length; ++j) {
                    d.contracts[j] = $root.google.protobuf.Any.toObject(m.contracts[j], o);
                }
            }
            return d;
        };

        CargoOwner.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CargoOwner;
    })();

    transport.CargoType = (function() {

        function CargoType(p) {
            this.permittedLoadingTypes = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        CargoType.prototype.id = "";
        CargoType.prototype.name = "";
        CargoType.prototype.cargoOwner = null;
        CargoType.prototype.defaultWeight = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        CargoType.prototype.defaultVolume = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        CargoType.prototype.typeVehicle = null;
        CargoType.prototype.packagingType = "";
        CargoType.prototype.cargoBodyType = "";
        CargoType.prototype.cargoBodySubtype = "";
        CargoType.prototype.cargoPackagesCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        CargoType.prototype.lengthCargoPackage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        CargoType.prototype.widthCargoPackage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        CargoType.prototype.heightCargoPackage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        CargoType.prototype.permittedLoadingTypes = $util.emptyArray;
        CargoType.prototype.vehicleCapacity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        CargoType.prototype.vehicleVolume = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        CargoType.prototype.vehicleLength = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        CargoType.prototype.vehicleWidth = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        CargoType.prototype.vehicleHeight = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        CargoType.prototype.isArchived = false;
        CargoType.prototype.temperatureMode = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        CargoType.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.CargoType)
                return d;
            var m = new $root.transport.CargoType();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.cargoOwner != null) {
                if (typeof d.cargoOwner !== "object")
                    throw TypeError(".transport.CargoType.cargoOwner: object expected");
                m.cargoOwner = $root.transport.Carrier.Organization.fromObject(d.cargoOwner);
            }
            if (d.defaultWeight != null) {
                if ($util.Long)
                    (m.defaultWeight = $util.Long.fromValue(d.defaultWeight)).unsigned = false;
                else if (typeof d.defaultWeight === "string")
                    m.defaultWeight = parseInt(d.defaultWeight, 10);
                else if (typeof d.defaultWeight === "number")
                    m.defaultWeight = d.defaultWeight;
                else if (typeof d.defaultWeight === "object")
                    m.defaultWeight = new $util.LongBits(d.defaultWeight.low >>> 0, d.defaultWeight.high >>> 0).toNumber();
            }
            if (d.defaultVolume != null) {
                if ($util.Long)
                    (m.defaultVolume = $util.Long.fromValue(d.defaultVolume)).unsigned = false;
                else if (typeof d.defaultVolume === "string")
                    m.defaultVolume = parseInt(d.defaultVolume, 10);
                else if (typeof d.defaultVolume === "number")
                    m.defaultVolume = d.defaultVolume;
                else if (typeof d.defaultVolume === "object")
                    m.defaultVolume = new $util.LongBits(d.defaultVolume.low >>> 0, d.defaultVolume.high >>> 0).toNumber();
            }
            if (d.typeVehicle != null) {
                if (typeof d.typeVehicle !== "object")
                    throw TypeError(".transport.CargoType.typeVehicle: object expected");
                m.typeVehicle = $root.transport.VehicleType.fromObject(d.typeVehicle);
            }
            if (d.packagingType != null) {
                m.packagingType = String(d.packagingType);
            }
            if (d.cargoBodyType != null) {
                m.cargoBodyType = String(d.cargoBodyType);
            }
            if (d.cargoBodySubtype != null) {
                m.cargoBodySubtype = String(d.cargoBodySubtype);
            }
            if (d.cargoPackagesCount != null) {
                if ($util.Long)
                    (m.cargoPackagesCount = $util.Long.fromValue(d.cargoPackagesCount)).unsigned = false;
                else if (typeof d.cargoPackagesCount === "string")
                    m.cargoPackagesCount = parseInt(d.cargoPackagesCount, 10);
                else if (typeof d.cargoPackagesCount === "number")
                    m.cargoPackagesCount = d.cargoPackagesCount;
                else if (typeof d.cargoPackagesCount === "object")
                    m.cargoPackagesCount = new $util.LongBits(d.cargoPackagesCount.low >>> 0, d.cargoPackagesCount.high >>> 0).toNumber();
            }
            if (d.lengthCargoPackage != null) {
                if ($util.Long)
                    (m.lengthCargoPackage = $util.Long.fromValue(d.lengthCargoPackage)).unsigned = false;
                else if (typeof d.lengthCargoPackage === "string")
                    m.lengthCargoPackage = parseInt(d.lengthCargoPackage, 10);
                else if (typeof d.lengthCargoPackage === "number")
                    m.lengthCargoPackage = d.lengthCargoPackage;
                else if (typeof d.lengthCargoPackage === "object")
                    m.lengthCargoPackage = new $util.LongBits(d.lengthCargoPackage.low >>> 0, d.lengthCargoPackage.high >>> 0).toNumber();
            }
            if (d.widthCargoPackage != null) {
                if ($util.Long)
                    (m.widthCargoPackage = $util.Long.fromValue(d.widthCargoPackage)).unsigned = false;
                else if (typeof d.widthCargoPackage === "string")
                    m.widthCargoPackage = parseInt(d.widthCargoPackage, 10);
                else if (typeof d.widthCargoPackage === "number")
                    m.widthCargoPackage = d.widthCargoPackage;
                else if (typeof d.widthCargoPackage === "object")
                    m.widthCargoPackage = new $util.LongBits(d.widthCargoPackage.low >>> 0, d.widthCargoPackage.high >>> 0).toNumber();
            }
            if (d.heightCargoPackage != null) {
                if ($util.Long)
                    (m.heightCargoPackage = $util.Long.fromValue(d.heightCargoPackage)).unsigned = false;
                else if (typeof d.heightCargoPackage === "string")
                    m.heightCargoPackage = parseInt(d.heightCargoPackage, 10);
                else if (typeof d.heightCargoPackage === "number")
                    m.heightCargoPackage = d.heightCargoPackage;
                else if (typeof d.heightCargoPackage === "object")
                    m.heightCargoPackage = new $util.LongBits(d.heightCargoPackage.low >>> 0, d.heightCargoPackage.high >>> 0).toNumber();
            }
            if (d.permittedLoadingTypes) {
                if (!Array.isArray(d.permittedLoadingTypes))
                    throw TypeError(".transport.CargoType.permittedLoadingTypes: array expected");
                m.permittedLoadingTypes = [];
                for (var i = 0; i < d.permittedLoadingTypes.length; ++i) {
                    if (typeof d.permittedLoadingTypes[i] !== "object")
                        throw TypeError(".transport.CargoType.permittedLoadingTypes: object expected");
                    m.permittedLoadingTypes[i] = $root.transport.LoadingType.fromObject(d.permittedLoadingTypes[i]);
                }
            }
            if (d.vehicleCapacity != null) {
                if ($util.Long)
                    (m.vehicleCapacity = $util.Long.fromValue(d.vehicleCapacity)).unsigned = false;
                else if (typeof d.vehicleCapacity === "string")
                    m.vehicleCapacity = parseInt(d.vehicleCapacity, 10);
                else if (typeof d.vehicleCapacity === "number")
                    m.vehicleCapacity = d.vehicleCapacity;
                else if (typeof d.vehicleCapacity === "object")
                    m.vehicleCapacity = new $util.LongBits(d.vehicleCapacity.low >>> 0, d.vehicleCapacity.high >>> 0).toNumber();
            }
            if (d.vehicleVolume != null) {
                if ($util.Long)
                    (m.vehicleVolume = $util.Long.fromValue(d.vehicleVolume)).unsigned = false;
                else if (typeof d.vehicleVolume === "string")
                    m.vehicleVolume = parseInt(d.vehicleVolume, 10);
                else if (typeof d.vehicleVolume === "number")
                    m.vehicleVolume = d.vehicleVolume;
                else if (typeof d.vehicleVolume === "object")
                    m.vehicleVolume = new $util.LongBits(d.vehicleVolume.low >>> 0, d.vehicleVolume.high >>> 0).toNumber();
            }
            if (d.vehicleLength != null) {
                if ($util.Long)
                    (m.vehicleLength = $util.Long.fromValue(d.vehicleLength)).unsigned = false;
                else if (typeof d.vehicleLength === "string")
                    m.vehicleLength = parseInt(d.vehicleLength, 10);
                else if (typeof d.vehicleLength === "number")
                    m.vehicleLength = d.vehicleLength;
                else if (typeof d.vehicleLength === "object")
                    m.vehicleLength = new $util.LongBits(d.vehicleLength.low >>> 0, d.vehicleLength.high >>> 0).toNumber();
            }
            if (d.vehicleWidth != null) {
                if ($util.Long)
                    (m.vehicleWidth = $util.Long.fromValue(d.vehicleWidth)).unsigned = false;
                else if (typeof d.vehicleWidth === "string")
                    m.vehicleWidth = parseInt(d.vehicleWidth, 10);
                else if (typeof d.vehicleWidth === "number")
                    m.vehicleWidth = d.vehicleWidth;
                else if (typeof d.vehicleWidth === "object")
                    m.vehicleWidth = new $util.LongBits(d.vehicleWidth.low >>> 0, d.vehicleWidth.high >>> 0).toNumber();
            }
            if (d.vehicleHeight != null) {
                if ($util.Long)
                    (m.vehicleHeight = $util.Long.fromValue(d.vehicleHeight)).unsigned = false;
                else if (typeof d.vehicleHeight === "string")
                    m.vehicleHeight = parseInt(d.vehicleHeight, 10);
                else if (typeof d.vehicleHeight === "number")
                    m.vehicleHeight = d.vehicleHeight;
                else if (typeof d.vehicleHeight === "object")
                    m.vehicleHeight = new $util.LongBits(d.vehicleHeight.low >>> 0, d.vehicleHeight.high >>> 0).toNumber();
            }
            if (d.isArchived != null) {
                m.isArchived = Boolean(d.isArchived);
            }
            if (d.temperatureMode != null) {
                if ($util.Long)
                    (m.temperatureMode = $util.Long.fromValue(d.temperatureMode)).unsigned = false;
                else if (typeof d.temperatureMode === "string")
                    m.temperatureMode = parseInt(d.temperatureMode, 10);
                else if (typeof d.temperatureMode === "number")
                    m.temperatureMode = d.temperatureMode;
                else if (typeof d.temperatureMode === "object")
                    m.temperatureMode = new $util.LongBits(d.temperatureMode.low >>> 0, d.temperatureMode.high >>> 0).toNumber();
            }
            return m;
        };

        CargoType.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.permittedLoadingTypes = [];
            }
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.cargoOwner = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.defaultWeight = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.defaultWeight = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.defaultVolume = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.defaultVolume = o.longs === String ? "0" : 0;
                d.typeVehicle = null;
                d.packagingType = "";
                d.cargoBodyType = "";
                d.cargoBodySubtype = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.cargoPackagesCount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.cargoPackagesCount = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.lengthCargoPackage = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lengthCargoPackage = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.widthCargoPackage = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.widthCargoPackage = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.heightCargoPackage = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.heightCargoPackage = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.vehicleCapacity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.vehicleCapacity = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.vehicleVolume = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.vehicleVolume = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.vehicleLength = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.vehicleLength = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.vehicleWidth = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.vehicleWidth = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.vehicleHeight = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.vehicleHeight = o.longs === String ? "0" : 0;
                d.isArchived = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.temperatureMode = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.temperatureMode = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.cargoOwner != null && m.hasOwnProperty("cargoOwner")) {
                d.cargoOwner = $root.transport.Carrier.Organization.toObject(m.cargoOwner, o);
            }
            if (m.defaultWeight != null && m.hasOwnProperty("defaultWeight")) {
                if (typeof m.defaultWeight === "number")
                    d.defaultWeight = o.longs === String ? String(m.defaultWeight) : m.defaultWeight;
                else
                    d.defaultWeight = o.longs === String ? $util.Long.prototype.toString.call(m.defaultWeight) : o.longs === Number ? new $util.LongBits(m.defaultWeight.low >>> 0, m.defaultWeight.high >>> 0).toNumber() : m.defaultWeight;
            }
            if (m.defaultVolume != null && m.hasOwnProperty("defaultVolume")) {
                if (typeof m.defaultVolume === "number")
                    d.defaultVolume = o.longs === String ? String(m.defaultVolume) : m.defaultVolume;
                else
                    d.defaultVolume = o.longs === String ? $util.Long.prototype.toString.call(m.defaultVolume) : o.longs === Number ? new $util.LongBits(m.defaultVolume.low >>> 0, m.defaultVolume.high >>> 0).toNumber() : m.defaultVolume;
            }
            if (m.typeVehicle != null && m.hasOwnProperty("typeVehicle")) {
                d.typeVehicle = $root.transport.VehicleType.toObject(m.typeVehicle, o);
            }
            if (m.packagingType != null && m.hasOwnProperty("packagingType")) {
                d.packagingType = m.packagingType;
            }
            if (m.cargoBodyType != null && m.hasOwnProperty("cargoBodyType")) {
                d.cargoBodyType = m.cargoBodyType;
            }
            if (m.cargoBodySubtype != null && m.hasOwnProperty("cargoBodySubtype")) {
                d.cargoBodySubtype = m.cargoBodySubtype;
            }
            if (m.cargoPackagesCount != null && m.hasOwnProperty("cargoPackagesCount")) {
                if (typeof m.cargoPackagesCount === "number")
                    d.cargoPackagesCount = o.longs === String ? String(m.cargoPackagesCount) : m.cargoPackagesCount;
                else
                    d.cargoPackagesCount = o.longs === String ? $util.Long.prototype.toString.call(m.cargoPackagesCount) : o.longs === Number ? new $util.LongBits(m.cargoPackagesCount.low >>> 0, m.cargoPackagesCount.high >>> 0).toNumber() : m.cargoPackagesCount;
            }
            if (m.lengthCargoPackage != null && m.hasOwnProperty("lengthCargoPackage")) {
                if (typeof m.lengthCargoPackage === "number")
                    d.lengthCargoPackage = o.longs === String ? String(m.lengthCargoPackage) : m.lengthCargoPackage;
                else
                    d.lengthCargoPackage = o.longs === String ? $util.Long.prototype.toString.call(m.lengthCargoPackage) : o.longs === Number ? new $util.LongBits(m.lengthCargoPackage.low >>> 0, m.lengthCargoPackage.high >>> 0).toNumber() : m.lengthCargoPackage;
            }
            if (m.widthCargoPackage != null && m.hasOwnProperty("widthCargoPackage")) {
                if (typeof m.widthCargoPackage === "number")
                    d.widthCargoPackage = o.longs === String ? String(m.widthCargoPackage) : m.widthCargoPackage;
                else
                    d.widthCargoPackage = o.longs === String ? $util.Long.prototype.toString.call(m.widthCargoPackage) : o.longs === Number ? new $util.LongBits(m.widthCargoPackage.low >>> 0, m.widthCargoPackage.high >>> 0).toNumber() : m.widthCargoPackage;
            }
            if (m.heightCargoPackage != null && m.hasOwnProperty("heightCargoPackage")) {
                if (typeof m.heightCargoPackage === "number")
                    d.heightCargoPackage = o.longs === String ? String(m.heightCargoPackage) : m.heightCargoPackage;
                else
                    d.heightCargoPackage = o.longs === String ? $util.Long.prototype.toString.call(m.heightCargoPackage) : o.longs === Number ? new $util.LongBits(m.heightCargoPackage.low >>> 0, m.heightCargoPackage.high >>> 0).toNumber() : m.heightCargoPackage;
            }
            if (m.permittedLoadingTypes && m.permittedLoadingTypes.length) {
                d.permittedLoadingTypes = [];
                for (var j = 0; j < m.permittedLoadingTypes.length; ++j) {
                    d.permittedLoadingTypes[j] = $root.transport.LoadingType.toObject(m.permittedLoadingTypes[j], o);
                }
            }
            if (m.vehicleCapacity != null && m.hasOwnProperty("vehicleCapacity")) {
                if (typeof m.vehicleCapacity === "number")
                    d.vehicleCapacity = o.longs === String ? String(m.vehicleCapacity) : m.vehicleCapacity;
                else
                    d.vehicleCapacity = o.longs === String ? $util.Long.prototype.toString.call(m.vehicleCapacity) : o.longs === Number ? new $util.LongBits(m.vehicleCapacity.low >>> 0, m.vehicleCapacity.high >>> 0).toNumber() : m.vehicleCapacity;
            }
            if (m.vehicleVolume != null && m.hasOwnProperty("vehicleVolume")) {
                if (typeof m.vehicleVolume === "number")
                    d.vehicleVolume = o.longs === String ? String(m.vehicleVolume) : m.vehicleVolume;
                else
                    d.vehicleVolume = o.longs === String ? $util.Long.prototype.toString.call(m.vehicleVolume) : o.longs === Number ? new $util.LongBits(m.vehicleVolume.low >>> 0, m.vehicleVolume.high >>> 0).toNumber() : m.vehicleVolume;
            }
            if (m.vehicleLength != null && m.hasOwnProperty("vehicleLength")) {
                if (typeof m.vehicleLength === "number")
                    d.vehicleLength = o.longs === String ? String(m.vehicleLength) : m.vehicleLength;
                else
                    d.vehicleLength = o.longs === String ? $util.Long.prototype.toString.call(m.vehicleLength) : o.longs === Number ? new $util.LongBits(m.vehicleLength.low >>> 0, m.vehicleLength.high >>> 0).toNumber() : m.vehicleLength;
            }
            if (m.vehicleWidth != null && m.hasOwnProperty("vehicleWidth")) {
                if (typeof m.vehicleWidth === "number")
                    d.vehicleWidth = o.longs === String ? String(m.vehicleWidth) : m.vehicleWidth;
                else
                    d.vehicleWidth = o.longs === String ? $util.Long.prototype.toString.call(m.vehicleWidth) : o.longs === Number ? new $util.LongBits(m.vehicleWidth.low >>> 0, m.vehicleWidth.high >>> 0).toNumber() : m.vehicleWidth;
            }
            if (m.vehicleHeight != null && m.hasOwnProperty("vehicleHeight")) {
                if (typeof m.vehicleHeight === "number")
                    d.vehicleHeight = o.longs === String ? String(m.vehicleHeight) : m.vehicleHeight;
                else
                    d.vehicleHeight = o.longs === String ? $util.Long.prototype.toString.call(m.vehicleHeight) : o.longs === Number ? new $util.LongBits(m.vehicleHeight.low >>> 0, m.vehicleHeight.high >>> 0).toNumber() : m.vehicleHeight;
            }
            if (m.isArchived != null && m.hasOwnProperty("isArchived")) {
                d.isArchived = m.isArchived;
            }
            if (m.temperatureMode != null && m.hasOwnProperty("temperatureMode")) {
                if (typeof m.temperatureMode === "number")
                    d.temperatureMode = o.longs === String ? String(m.temperatureMode) : m.temperatureMode;
                else
                    d.temperatureMode = o.longs === String ? $util.Long.prototype.toString.call(m.temperatureMode) : o.longs === Number ? new $util.LongBits(m.temperatureMode.low >>> 0, m.temperatureMode.high >>> 0).toNumber() : m.temperatureMode;
            }
            return d;
        };

        CargoType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CargoType;
    })();

    transport.Carrier = (function() {

        function Carrier(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Carrier.prototype.id = "";
        Carrier.prototype.email = "";
        Carrier.prototype.role = "";
        Carrier.prototype.lastName = "";
        Carrier.prototype.firstName = "";
        Carrier.prototype.companyName = "";
        Carrier.prototype.phoneNumber = "";

        Carrier.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.Carrier)
                return d;
            var m = new $root.transport.Carrier();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            if (d.role != null) {
                m.role = String(d.role);
            }
            if (d.lastName != null) {
                m.lastName = String(d.lastName);
            }
            if (d.firstName != null) {
                m.firstName = String(d.firstName);
            }
            if (d.companyName != null) {
                m.companyName = String(d.companyName);
            }
            if (d.phoneNumber != null) {
                m.phoneNumber = String(d.phoneNumber);
            }
            return m;
        };

        Carrier.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.email = "";
                d.role = "";
                d.lastName = "";
                d.firstName = "";
                d.companyName = "";
                d.phoneNumber = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
            }
            if (m.role != null && m.hasOwnProperty("role")) {
                d.role = m.role;
            }
            if (m.lastName != null && m.hasOwnProperty("lastName")) {
                d.lastName = m.lastName;
            }
            if (m.firstName != null && m.hasOwnProperty("firstName")) {
                d.firstName = m.firstName;
            }
            if (m.companyName != null && m.hasOwnProperty("companyName")) {
                d.companyName = m.companyName;
            }
            if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                d.phoneNumber = m.phoneNumber;
            }
            return d;
        };

        Carrier.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Carrier.Organization = (function() {

            function Organization(p) {
                this.contracts = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Organization.prototype.id = "";
            Organization.prototype.name = "";
            Organization.prototype.about = "";
            Organization.prototype.phoneNumber = "";
            Organization.prototype.email = "";
            Organization.prototype.status = "";
            Organization.prototype.contracts = $util.emptyArray;
            Organization.prototype.isArchived = false;

            Organization.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Carrier.Organization)
                    return d;
                var m = new $root.transport.Carrier.Organization();
                if (d.id != null) {
                    m.id = String(d.id);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.about != null) {
                    m.about = String(d.about);
                }
                if (d.phoneNumber != null) {
                    m.phoneNumber = String(d.phoneNumber);
                }
                if (d.email != null) {
                    m.email = String(d.email);
                }
                if (d.status != null) {
                    m.status = String(d.status);
                }
                if (d.contracts) {
                    if (!Array.isArray(d.contracts))
                        throw TypeError(".transport.Carrier.Organization.contracts: array expected");
                    m.contracts = [];
                    for (var i = 0; i < d.contracts.length; ++i) {
                        if (typeof d.contracts[i] !== "object")
                            throw TypeError(".transport.Carrier.Organization.contracts: object expected");
                        m.contracts[i] = $root.transport.Carrier.Contract.fromObject(d.contracts[i]);
                    }
                }
                if (d.isArchived != null) {
                    m.isArchived = Boolean(d.isArchived);
                }
                return m;
            };

            Organization.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.contracts = [];
                }
                if (o.defaults) {
                    d.id = "";
                    d.name = "";
                    d.about = "";
                    d.phoneNumber = "";
                    d.email = "";
                    d.status = "";
                    d.isArchived = false;
                }
                if (m.id != null && m.hasOwnProperty("id")) {
                    d.id = m.id;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.about != null && m.hasOwnProperty("about")) {
                    d.about = m.about;
                }
                if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                    d.phoneNumber = m.phoneNumber;
                }
                if (m.email != null && m.hasOwnProperty("email")) {
                    d.email = m.email;
                }
                if (m.status != null && m.hasOwnProperty("status")) {
                    d.status = m.status;
                }
                if (m.contracts && m.contracts.length) {
                    d.contracts = [];
                    for (var j = 0; j < m.contracts.length; ++j) {
                        d.contracts[j] = $root.transport.Carrier.Contract.toObject(m.contracts[j], o);
                    }
                }
                if (m.isArchived != null && m.hasOwnProperty("isArchived")) {
                    d.isArchived = m.isArchived;
                }
                return d;
            };

            Organization.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Organization;
        })();

        Carrier.Contract = (function() {

            function Contract(p) {
                this.loadingPlaces = [];
                this.unloadingPlaces = [];
                this.cargoTypes = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Contract.prototype.id = "";
            Contract.prototype.carrierOrganization = null;
            Contract.prototype.contractNumber = "";
            Contract.prototype.startDate = "";
            Contract.prototype.endDate = "";
            Contract.prototype.cargoQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            Contract.prototype.loadingPlaces = $util.emptyArray;
            Contract.prototype.unloadingPlaces = $util.emptyArray;
            Contract.prototype.cargoTypes = $util.emptyArray;
            Contract.prototype.isArchived = false;

            Contract.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Carrier.Contract)
                    return d;
                var m = new $root.transport.Carrier.Contract();
                if (d.id != null) {
                    m.id = String(d.id);
                }
                if (d.carrierOrganization != null) {
                    if (typeof d.carrierOrganization !== "object")
                        throw TypeError(".transport.Carrier.Contract.carrierOrganization: object expected");
                    m.carrierOrganization = $root.transport.Carrier.Organization.fromObject(d.carrierOrganization);
                }
                if (d.contractNumber != null) {
                    m.contractNumber = String(d.contractNumber);
                }
                if (d.startDate != null) {
                    m.startDate = String(d.startDate);
                }
                if (d.endDate != null) {
                    m.endDate = String(d.endDate);
                }
                if (d.cargoQuantity != null) {
                    if ($util.Long)
                        (m.cargoQuantity = $util.Long.fromValue(d.cargoQuantity)).unsigned = false;
                    else if (typeof d.cargoQuantity === "string")
                        m.cargoQuantity = parseInt(d.cargoQuantity, 10);
                    else if (typeof d.cargoQuantity === "number")
                        m.cargoQuantity = d.cargoQuantity;
                    else if (typeof d.cargoQuantity === "object")
                        m.cargoQuantity = new $util.LongBits(d.cargoQuantity.low >>> 0, d.cargoQuantity.high >>> 0).toNumber();
                }
                if (d.loadingPlaces) {
                    if (!Array.isArray(d.loadingPlaces))
                        throw TypeError(".transport.Carrier.Contract.loadingPlaces: array expected");
                    m.loadingPlaces = [];
                    for (var i = 0; i < d.loadingPlaces.length; ++i) {
                        if (typeof d.loadingPlaces[i] !== "object")
                            throw TypeError(".transport.Carrier.Contract.loadingPlaces: object expected");
                        m.loadingPlaces[i] = $root.transport.Place.fromObject(d.loadingPlaces[i]);
                    }
                }
                if (d.unloadingPlaces) {
                    if (!Array.isArray(d.unloadingPlaces))
                        throw TypeError(".transport.Carrier.Contract.unloadingPlaces: array expected");
                    m.unloadingPlaces = [];
                    for (var i = 0; i < d.unloadingPlaces.length; ++i) {
                        if (typeof d.unloadingPlaces[i] !== "object")
                            throw TypeError(".transport.Carrier.Contract.unloadingPlaces: object expected");
                        m.unloadingPlaces[i] = $root.transport.Place.fromObject(d.unloadingPlaces[i]);
                    }
                }
                if (d.cargoTypes) {
                    if (!Array.isArray(d.cargoTypes))
                        throw TypeError(".transport.Carrier.Contract.cargoTypes: array expected");
                    m.cargoTypes = [];
                    for (var i = 0; i < d.cargoTypes.length; ++i) {
                        if (typeof d.cargoTypes[i] !== "object")
                            throw TypeError(".transport.Carrier.Contract.cargoTypes: object expected");
                        m.cargoTypes[i] = $root.transport.CargoType.fromObject(d.cargoTypes[i]);
                    }
                }
                if (d.isArchived != null) {
                    m.isArchived = Boolean(d.isArchived);
                }
                return m;
            };

            Contract.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.loadingPlaces = [];
                    d.unloadingPlaces = [];
                    d.cargoTypes = [];
                }
                if (o.defaults) {
                    d.id = "";
                    d.carrierOrganization = null;
                    d.contractNumber = "";
                    d.startDate = "";
                    d.endDate = "";
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.cargoQuantity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.cargoQuantity = o.longs === String ? "0" : 0;
                    d.isArchived = false;
                }
                if (m.id != null && m.hasOwnProperty("id")) {
                    d.id = m.id;
                }
                if (m.carrierOrganization != null && m.hasOwnProperty("carrierOrganization")) {
                    d.carrierOrganization = $root.transport.Carrier.Organization.toObject(m.carrierOrganization, o);
                }
                if (m.contractNumber != null && m.hasOwnProperty("contractNumber")) {
                    d.contractNumber = m.contractNumber;
                }
                if (m.startDate != null && m.hasOwnProperty("startDate")) {
                    d.startDate = m.startDate;
                }
                if (m.endDate != null && m.hasOwnProperty("endDate")) {
                    d.endDate = m.endDate;
                }
                if (m.cargoQuantity != null && m.hasOwnProperty("cargoQuantity")) {
                    if (typeof m.cargoQuantity === "number")
                        d.cargoQuantity = o.longs === String ? String(m.cargoQuantity) : m.cargoQuantity;
                    else
                        d.cargoQuantity = o.longs === String ? $util.Long.prototype.toString.call(m.cargoQuantity) : o.longs === Number ? new $util.LongBits(m.cargoQuantity.low >>> 0, m.cargoQuantity.high >>> 0).toNumber() : m.cargoQuantity;
                }
                if (m.loadingPlaces && m.loadingPlaces.length) {
                    d.loadingPlaces = [];
                    for (var j = 0; j < m.loadingPlaces.length; ++j) {
                        d.loadingPlaces[j] = $root.transport.Place.toObject(m.loadingPlaces[j], o);
                    }
                }
                if (m.unloadingPlaces && m.unloadingPlaces.length) {
                    d.unloadingPlaces = [];
                    for (var j = 0; j < m.unloadingPlaces.length; ++j) {
                        d.unloadingPlaces[j] = $root.transport.Place.toObject(m.unloadingPlaces[j], o);
                    }
                }
                if (m.cargoTypes && m.cargoTypes.length) {
                    d.cargoTypes = [];
                    for (var j = 0; j < m.cargoTypes.length; ++j) {
                        d.cargoTypes[j] = $root.transport.CargoType.toObject(m.cargoTypes[j], o);
                    }
                }
                if (m.isArchived != null && m.hasOwnProperty("isArchived")) {
                    d.isArchived = m.isArchived;
                }
                return d;
            };

            Contract.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Contract;
        })();

        Carrier.CarrierGroup = (function() {

            function CarrierGroup(p) {
                this.carriers = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            CarrierGroup.prototype.id = "";
            CarrierGroup.prototype.name = "";
            CarrierGroup.prototype.isArchived = false;
            CarrierGroup.prototype.carriers = $util.emptyArray;
            CarrierGroup.prototype.carriersQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

            CarrierGroup.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Carrier.CarrierGroup)
                    return d;
                var m = new $root.transport.Carrier.CarrierGroup();
                if (d.id != null) {
                    m.id = String(d.id);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.isArchived != null) {
                    m.isArchived = Boolean(d.isArchived);
                }
                if (d.carriers) {
                    if (!Array.isArray(d.carriers))
                        throw TypeError(".transport.Carrier.CarrierGroup.carriers: array expected");
                    m.carriers = [];
                    for (var i = 0; i < d.carriers.length; ++i) {
                        if (typeof d.carriers[i] !== "object")
                            throw TypeError(".transport.Carrier.CarrierGroup.carriers: object expected");
                        m.carriers[i] = $root.transport.Carrier.Organization.fromObject(d.carriers[i]);
                    }
                }
                if (d.carriersQuantity != null) {
                    if ($util.Long)
                        (m.carriersQuantity = $util.Long.fromValue(d.carriersQuantity)).unsigned = false;
                    else if (typeof d.carriersQuantity === "string")
                        m.carriersQuantity = parseInt(d.carriersQuantity, 10);
                    else if (typeof d.carriersQuantity === "number")
                        m.carriersQuantity = d.carriersQuantity;
                    else if (typeof d.carriersQuantity === "object")
                        m.carriersQuantity = new $util.LongBits(d.carriersQuantity.low >>> 0, d.carriersQuantity.high >>> 0).toNumber();
                }
                return m;
            };

            CarrierGroup.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.carriers = [];
                }
                if (o.defaults) {
                    d.id = "";
                    d.name = "";
                    d.isArchived = false;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.carriersQuantity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.carriersQuantity = o.longs === String ? "0" : 0;
                }
                if (m.id != null && m.hasOwnProperty("id")) {
                    d.id = m.id;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.isArchived != null && m.hasOwnProperty("isArchived")) {
                    d.isArchived = m.isArchived;
                }
                if (m.carriers && m.carriers.length) {
                    d.carriers = [];
                    for (var j = 0; j < m.carriers.length; ++j) {
                        d.carriers[j] = $root.transport.Carrier.Organization.toObject(m.carriers[j], o);
                    }
                }
                if (m.carriersQuantity != null && m.hasOwnProperty("carriersQuantity")) {
                    if (typeof m.carriersQuantity === "number")
                        d.carriersQuantity = o.longs === String ? String(m.carriersQuantity) : m.carriersQuantity;
                    else
                        d.carriersQuantity = o.longs === String ? $util.Long.prototype.toString.call(m.carriersQuantity) : o.longs === Number ? new $util.LongBits(m.carriersQuantity.low >>> 0, m.carriersQuantity.high >>> 0).toNumber() : m.carriersQuantity;
                }
                return d;
            };

            CarrierGroup.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CarrierGroup;
        })();

        return Carrier;
    })();

    transport.CarrierUser = (function() {

        function CarrierUser(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        CarrierUser.prototype.organization = null;
        CarrierUser.prototype.email = "";
        CarrierUser.prototype.firstName = "";
        CarrierUser.prototype.lastName = "";
        CarrierUser.prototype.phoneNumber = "";
        CarrierUser.prototype.createdAt = "";
        CarrierUser.prototype.tnId = "";
        CarrierUser.prototype.registrationSource = "SELF_REGISTRATION";
        CarrierUser.prototype.isRegistered = false;
        CarrierUser.prototype.selfRegistrationInProcess = false;

        CarrierUser.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.CarrierUser)
                return d;
            var m = new $root.transport.CarrierUser();
            if (d.organization != null) {
                if (typeof d.organization !== "object")
                    throw TypeError(".transport.CarrierUser.organization: object expected");
                m.organization = $root.transport.CarrierOrganization.fromObject(d.organization);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            if (d.firstName != null) {
                m.firstName = String(d.firstName);
            }
            if (d.lastName != null) {
                m.lastName = String(d.lastName);
            }
            if (d.phoneNumber != null) {
                m.phoneNumber = String(d.phoneNumber);
            }
            if (d.createdAt != null) {
                m.createdAt = String(d.createdAt);
            }
            if (d.tnId != null) {
                m.tnId = String(d.tnId);
            }
            if (d.registrationSource != null) {
                m.registrationSource = String(d.registrationSource);
            }
            if (d.isRegistered != null) {
                m.isRegistered = Boolean(d.isRegistered);
            }
            if (d.selfRegistrationInProcess != null) {
                m.selfRegistrationInProcess = Boolean(d.selfRegistrationInProcess);
            }
            return m;
        };

        CarrierUser.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.organization = null;
                d.email = "";
                d.firstName = "";
                d.lastName = "";
                d.phoneNumber = "";
                d.createdAt = "";
                d.tnId = "";
                d.registrationSource = "SELF_REGISTRATION";
                d.isRegistered = false;
                d.selfRegistrationInProcess = false;
            }
            if (m.organization != null && m.hasOwnProperty("organization")) {
                d.organization = $root.transport.CarrierOrganization.toObject(m.organization, o);
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
            }
            if (m.firstName != null && m.hasOwnProperty("firstName")) {
                d.firstName = m.firstName;
            }
            if (m.lastName != null && m.hasOwnProperty("lastName")) {
                d.lastName = m.lastName;
            }
            if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                d.phoneNumber = m.phoneNumber;
            }
            if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                d.createdAt = m.createdAt;
            }
            if (m.tnId != null && m.hasOwnProperty("tnId")) {
                d.tnId = m.tnId;
            }
            if (m.registrationSource != null && m.hasOwnProperty("registrationSource")) {
                d.registrationSource = m.registrationSource;
            }
            if (m.isRegistered != null && m.hasOwnProperty("isRegistered")) {
                d.isRegistered = m.isRegistered;
            }
            if (m.selfRegistrationInProcess != null && m.hasOwnProperty("selfRegistrationInProcess")) {
                d.selfRegistrationInProcess = m.selfRegistrationInProcess;
            }
            return d;
        };

        CarrierUser.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CarrierUser;
    })();

    transport.CarrierOrganization = (function() {

        function CarrierOrganization(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        CarrierOrganization.prototype.isArchived = false;
        CarrierOrganization.prototype.createdAt = "";
        CarrierOrganization.prototype.id = "";
        CarrierOrganization.prototype.name = "";
        CarrierOrganization.prototype.signer = "";
        CarrierOrganization.prototype.phoneNumber = "";
        CarrierOrganization.prototype.email = "";
        CarrierOrganization.prototype.legalForm = null;
        CarrierOrganization.prototype.inn = "";
        CarrierOrganization.prototype.kpp = "";
        CarrierOrganization.prototype.legalAddress = "";
        CarrierOrganization.prototype.registrationNumber = "";
        CarrierOrganization.prototype.registrationSource = "SELF_REGISTRATION";
        CarrierOrganization.prototype.trustedOrderPreference = null;
        CarrierOrganization.prototype.legalFormCode = "";
        CarrierOrganization.prototype.companyName = "";
        CarrierOrganization.prototype.marketAttributes = null;
        CarrierOrganization.prototype.headOfOrganization = null;
        CarrierOrganization.prototype.hasTmsAccess = false;
        CarrierOrganization.prototype.hasMarketAccess = false;
        CarrierOrganization.prototype.vat = null;

        CarrierOrganization.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.CarrierOrganization)
                return d;
            var m = new $root.transport.CarrierOrganization();
            if (d.isArchived != null) {
                m.isArchived = Boolean(d.isArchived);
            }
            if (d.createdAt != null) {
                m.createdAt = String(d.createdAt);
            }
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.signer != null) {
                m.signer = String(d.signer);
            }
            if (d.phoneNumber != null) {
                m.phoneNumber = String(d.phoneNumber);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            if (d.legalForm != null) {
                if (typeof d.legalForm !== "object")
                    throw TypeError(".transport.CarrierOrganization.legalForm: object expected");
                m.legalForm = $root.transport.LegalForm.fromObject(d.legalForm);
            }
            if (d.inn != null) {
                m.inn = String(d.inn);
            }
            if (d.kpp != null) {
                m.kpp = String(d.kpp);
            }
            if (d.legalAddress != null) {
                m.legalAddress = String(d.legalAddress);
            }
            if (d.registrationNumber != null) {
                m.registrationNumber = String(d.registrationNumber);
            }
            if (d.registrationSource != null) {
                m.registrationSource = String(d.registrationSource);
            }
            if (d.trustedOrderPreference != null) {
                if (typeof d.trustedOrderPreference !== "object")
                    throw TypeError(".transport.CarrierOrganization.trustedOrderPreference: object expected");
                m.trustedOrderPreference = $root.transport.TrustedOrderPreference.fromObject(d.trustedOrderPreference);
            }
            if (d.legalFormCode != null) {
                m.legalFormCode = String(d.legalFormCode);
            }
            if (d.companyName != null) {
                m.companyName = String(d.companyName);
            }
            if (d.marketAttributes != null) {
                if (typeof d.marketAttributes !== "object")
                    throw TypeError(".transport.CarrierOrganization.marketAttributes: object expected");
                m.marketAttributes = $root.transport.OrganizationMarketAttributesType.fromObject(d.marketAttributes);
            }
            if (d.headOfOrganization != null) {
                if (typeof d.headOfOrganization !== "object")
                    throw TypeError(".transport.CarrierOrganization.headOfOrganization: object expected");
                m.headOfOrganization = $root.transport.HeadOfOrganizationType.fromObject(d.headOfOrganization);
            }
            if (d.hasTmsAccess != null) {
                m.hasTmsAccess = Boolean(d.hasTmsAccess);
            }
            if (d.hasMarketAccess != null) {
                m.hasMarketAccess = Boolean(d.hasMarketAccess);
            }
            if (d.vat != null) {
                if (typeof d.vat !== "object")
                    throw TypeError(".transport.CarrierOrganization.vat: object expected");
                m.vat = $root.transport.VatType.fromObject(d.vat);
            }
            return m;
        };

        CarrierOrganization.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.isArchived = false;
                d.createdAt = "";
                d.id = "";
                d.name = "";
                d.signer = "";
                d.phoneNumber = "";
                d.email = "";
                d.legalForm = null;
                d.inn = "";
                d.kpp = "";
                d.legalAddress = "";
                d.registrationNumber = "";
                d.registrationSource = "SELF_REGISTRATION";
                d.trustedOrderPreference = null;
                d.legalFormCode = "";
                d.companyName = "";
                d.marketAttributes = null;
                d.headOfOrganization = null;
                d.hasTmsAccess = false;
                d.hasMarketAccess = false;
                d.vat = null;
            }
            if (m.isArchived != null && m.hasOwnProperty("isArchived")) {
                d.isArchived = m.isArchived;
            }
            if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                d.createdAt = m.createdAt;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.signer != null && m.hasOwnProperty("signer")) {
                d.signer = m.signer;
            }
            if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                d.phoneNumber = m.phoneNumber;
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
            }
            if (m.legalForm != null && m.hasOwnProperty("legalForm")) {
                d.legalForm = $root.transport.LegalForm.toObject(m.legalForm, o);
            }
            if (m.inn != null && m.hasOwnProperty("inn")) {
                d.inn = m.inn;
            }
            if (m.kpp != null && m.hasOwnProperty("kpp")) {
                d.kpp = m.kpp;
            }
            if (m.legalAddress != null && m.hasOwnProperty("legalAddress")) {
                d.legalAddress = m.legalAddress;
            }
            if (m.registrationNumber != null && m.hasOwnProperty("registrationNumber")) {
                d.registrationNumber = m.registrationNumber;
            }
            if (m.registrationSource != null && m.hasOwnProperty("registrationSource")) {
                d.registrationSource = m.registrationSource;
            }
            if (m.trustedOrderPreference != null && m.hasOwnProperty("trustedOrderPreference")) {
                d.trustedOrderPreference = $root.transport.TrustedOrderPreference.toObject(m.trustedOrderPreference, o);
            }
            if (m.legalFormCode != null && m.hasOwnProperty("legalFormCode")) {
                d.legalFormCode = m.legalFormCode;
            }
            if (m.companyName != null && m.hasOwnProperty("companyName")) {
                d.companyName = m.companyName;
            }
            if (m.marketAttributes != null && m.hasOwnProperty("marketAttributes")) {
                d.marketAttributes = $root.transport.OrganizationMarketAttributesType.toObject(m.marketAttributes, o);
            }
            if (m.headOfOrganization != null && m.hasOwnProperty("headOfOrganization")) {
                d.headOfOrganization = $root.transport.HeadOfOrganizationType.toObject(m.headOfOrganization, o);
            }
            if (m.hasTmsAccess != null && m.hasOwnProperty("hasTmsAccess")) {
                d.hasTmsAccess = m.hasTmsAccess;
            }
            if (m.hasMarketAccess != null && m.hasOwnProperty("hasMarketAccess")) {
                d.hasMarketAccess = m.hasMarketAccess;
            }
            if (m.vat != null && m.hasOwnProperty("vat")) {
                d.vat = $root.transport.VatType.toObject(m.vat, o);
            }
            return d;
        };

        CarrierOrganization.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CarrierOrganization;
    })();

    transport.TrustedOrderPreference = (function() {

        function TrustedOrderPreference(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TrustedOrderPreference.prototype.isScored = false;
        TrustedOrderPreference.prototype.hasDigitalSignature = false;
        TrustedOrderPreference.prototype.hasOwnVehicle = false;

        TrustedOrderPreference.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.TrustedOrderPreference)
                return d;
            var m = new $root.transport.TrustedOrderPreference();
            if (d.isScored != null) {
                m.isScored = Boolean(d.isScored);
            }
            if (d.hasDigitalSignature != null) {
                m.hasDigitalSignature = Boolean(d.hasDigitalSignature);
            }
            if (d.hasOwnVehicle != null) {
                m.hasOwnVehicle = Boolean(d.hasOwnVehicle);
            }
            return m;
        };

        TrustedOrderPreference.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.isScored = false;
                d.hasDigitalSignature = false;
                d.hasOwnVehicle = false;
            }
            if (m.isScored != null && m.hasOwnProperty("isScored")) {
                d.isScored = m.isScored;
            }
            if (m.hasDigitalSignature != null && m.hasOwnProperty("hasDigitalSignature")) {
                d.hasDigitalSignature = m.hasDigitalSignature;
            }
            if (m.hasOwnVehicle != null && m.hasOwnProperty("hasOwnVehicle")) {
                d.hasOwnVehicle = m.hasOwnVehicle;
            }
            return d;
        };

        TrustedOrderPreference.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TrustedOrderPreference;
    })();

    transport.OrganizationMarketAttributesType = (function() {

        function OrganizationMarketAttributesType(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        OrganizationMarketAttributesType.prototype.organizationCheck = "NOT_CHECKED";
        OrganizationMarketAttributesType.prototype.driverCheck = "NOT_CHECKED";
        OrganizationMarketAttributesType.prototype.vehicleCheck = "NOT_CHECKED";
        OrganizationMarketAttributesType.prototype.trustedOrderPreference = null;
        OrganizationMarketAttributesType.prototype.accessRequested = false;
        OrganizationMarketAttributesType.prototype.esignCheck = "NOT_CHECKED";

        OrganizationMarketAttributesType.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.OrganizationMarketAttributesType)
                return d;
            var m = new $root.transport.OrganizationMarketAttributesType();
            if (d.organizationCheck != null) {
                m.organizationCheck = String(d.organizationCheck);
            }
            if (d.driverCheck != null) {
                m.driverCheck = String(d.driverCheck);
            }
            if (d.vehicleCheck != null) {
                m.vehicleCheck = String(d.vehicleCheck);
            }
            if (d.trustedOrderPreference != null) {
                if (typeof d.trustedOrderPreference !== "object")
                    throw TypeError(".transport.OrganizationMarketAttributesType.trustedOrderPreference: object expected");
                m.trustedOrderPreference = $root.transport.TrustedOrderPreference.fromObject(d.trustedOrderPreference);
            }
            if (d.accessRequested != null) {
                m.accessRequested = Boolean(d.accessRequested);
            }
            if (d.esignCheck != null) {
                m.esignCheck = String(d.esignCheck);
            }
            return m;
        };

        OrganizationMarketAttributesType.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.organizationCheck = "NOT_CHECKED";
                d.driverCheck = "NOT_CHECKED";
                d.vehicleCheck = "NOT_CHECKED";
                d.trustedOrderPreference = null;
                d.accessRequested = false;
                d.esignCheck = "NOT_CHECKED";
            }
            if (m.organizationCheck != null && m.hasOwnProperty("organizationCheck")) {
                d.organizationCheck = m.organizationCheck;
            }
            if (m.driverCheck != null && m.hasOwnProperty("driverCheck")) {
                d.driverCheck = m.driverCheck;
            }
            if (m.vehicleCheck != null && m.hasOwnProperty("vehicleCheck")) {
                d.vehicleCheck = m.vehicleCheck;
            }
            if (m.trustedOrderPreference != null && m.hasOwnProperty("trustedOrderPreference")) {
                d.trustedOrderPreference = $root.transport.TrustedOrderPreference.toObject(m.trustedOrderPreference, o);
            }
            if (m.accessRequested != null && m.hasOwnProperty("accessRequested")) {
                d.accessRequested = m.accessRequested;
            }
            if (m.esignCheck != null && m.hasOwnProperty("esignCheck")) {
                d.esignCheck = m.esignCheck;
            }
            return d;
        };

        OrganizationMarketAttributesType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OrganizationMarketAttributesType;
    })();

    transport.HeadOfOrganizationType = (function() {

        function HeadOfOrganizationType(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        HeadOfOrganizationType.prototype.id = "";
        HeadOfOrganizationType.prototype.surname = "";
        HeadOfOrganizationType.prototype.name = "";
        HeadOfOrganizationType.prototype.patronymic = "";
        HeadOfOrganizationType.prototype.passportSeries = "";
        HeadOfOrganizationType.prototype.passportNumber = "";
        HeadOfOrganizationType.prototype.passportDate = "";

        HeadOfOrganizationType.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.HeadOfOrganizationType)
                return d;
            var m = new $root.transport.HeadOfOrganizationType();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.surname != null) {
                m.surname = String(d.surname);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.patronymic != null) {
                m.patronymic = String(d.patronymic);
            }
            if (d.passportSeries != null) {
                m.passportSeries = String(d.passportSeries);
            }
            if (d.passportNumber != null) {
                m.passportNumber = String(d.passportNumber);
            }
            if (d.passportDate != null) {
                m.passportDate = String(d.passportDate);
            }
            return m;
        };

        HeadOfOrganizationType.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.surname = "";
                d.name = "";
                d.patronymic = "";
                d.passportSeries = "";
                d.passportNumber = "";
                d.passportDate = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.surname != null && m.hasOwnProperty("surname")) {
                d.surname = m.surname;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.patronymic != null && m.hasOwnProperty("patronymic")) {
                d.patronymic = m.patronymic;
            }
            if (m.passportSeries != null && m.hasOwnProperty("passportSeries")) {
                d.passportSeries = m.passportSeries;
            }
            if (m.passportNumber != null && m.hasOwnProperty("passportNumber")) {
                d.passportNumber = m.passportNumber;
            }
            if (m.passportDate != null && m.hasOwnProperty("passportDate")) {
                d.passportDate = m.passportDate;
            }
            return d;
        };

        HeadOfOrganizationType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return HeadOfOrganizationType;
    })();

    transport.VatType = (function() {

        function VatType(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        VatType.prototype.name = "";
        VatType.prototype.rate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        VatType.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.VatType)
                return d;
            var m = new $root.transport.VatType();
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.rate != null) {
                if ($util.Long)
                    (m.rate = $util.Long.fromValue(d.rate)).unsigned = false;
                else if (typeof d.rate === "string")
                    m.rate = parseInt(d.rate, 10);
                else if (typeof d.rate === "number")
                    m.rate = d.rate;
                else if (typeof d.rate === "object")
                    m.rate = new $util.LongBits(d.rate.low >>> 0, d.rate.high >>> 0).toNumber();
            }
            return m;
        };

        VatType.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.name = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.rate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.rate = o.longs === String ? "0" : 0;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.rate != null && m.hasOwnProperty("rate")) {
                if (typeof m.rate === "number")
                    d.rate = o.longs === String ? String(m.rate) : m.rate;
                else
                    d.rate = o.longs === String ? $util.Long.prototype.toString.call(m.rate) : o.longs === Number ? new $util.LongBits(m.rate.low >>> 0, m.rate.high >>> 0).toNumber() : m.rate;
            }
            return d;
        };

        VatType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VatType;
    })();

    transport.LoadingType = (function() {

        function LoadingType(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        LoadingType.prototype.id = "";
        LoadingType.prototype.name = "";

        LoadingType.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.LoadingType)
                return d;
            var m = new $root.transport.LoadingType();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            return m;
        };

        LoadingType.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            return d;
        };

        LoadingType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LoadingType;
    })();

    transport.VehicleType = (function() {

        function VehicleType(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        VehicleType.prototype.id = "";
        VehicleType.prototype.name = "";

        VehicleType.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.VehicleType)
                return d;
            var m = new $root.transport.VehicleType();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            return m;
        };

        VehicleType.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            return d;
        };

        VehicleType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VehicleType;
    })();

    transport.Timezone = (function() {

        function Timezone(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Timezone.prototype.id = "";
        Timezone.prototype.settlement = "";
        Timezone.prototype.timezone = "";

        Timezone.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.Timezone)
                return d;
            var m = new $root.transport.Timezone();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.settlement != null) {
                m.settlement = String(d.settlement);
            }
            if (d.timezone != null) {
                m.timezone = String(d.timezone);
            }
            return m;
        };

        Timezone.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.settlement = "";
                d.timezone = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.settlement != null && m.hasOwnProperty("settlement")) {
                d.settlement = m.settlement;
            }
            if (m.timezone != null && m.hasOwnProperty("timezone")) {
                d.timezone = m.timezone;
            }
            return d;
        };

        Timezone.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Timezone;
    })();

    transport.LegalForm = (function() {

        function LegalForm(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        LegalForm.prototype.id = "";
        LegalForm.prototype.shortName = "";
        LegalForm.prototype.name = "";
        LegalForm.prototype.code = "";

        LegalForm.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.LegalForm)
                return d;
            var m = new $root.transport.LegalForm();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.shortName != null) {
                m.shortName = String(d.shortName);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.code != null) {
                m.code = String(d.code);
            }
            return m;
        };

        LegalForm.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.shortName = "";
                d.name = "";
                d.code = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.shortName != null && m.hasOwnProperty("shortName")) {
                d.shortName = m.shortName;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.code != null && m.hasOwnProperty("code")) {
                d.code = m.code;
            }
            return d;
        };

        LegalForm.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LegalForm;
    })();

    transport.SampleServiceRequest = (function() {

        function SampleServiceRequest(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        SampleServiceRequest.prototype.input1 = "";
        SampleServiceRequest.prototype.input2 = "";

        SampleServiceRequest.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.SampleServiceRequest)
                return d;
            var m = new $root.transport.SampleServiceRequest();
            if (d.input1 != null) {
                m.input1 = String(d.input1);
            }
            if (d.input2 != null) {
                m.input2 = String(d.input2);
            }
            return m;
        };

        SampleServiceRequest.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.input1 = "";
                d.input2 = "";
            }
            if (m.input1 != null && m.hasOwnProperty("input1")) {
                d.input1 = m.input1;
            }
            if (m.input2 != null && m.hasOwnProperty("input2")) {
                d.input2 = m.input2;
            }
            return d;
        };

        SampleServiceRequest.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SampleServiceRequest;
    })();

    transport.SampleServiceResponse = (function() {

        function SampleServiceResponse(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        SampleServiceResponse.prototype.status = "";
        SampleServiceResponse.prototype.data = null;

        SampleServiceResponse.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.SampleServiceResponse)
                return d;
            var m = new $root.transport.SampleServiceResponse();
            if (d.status != null) {
                m.status = String(d.status);
            }
            if (d.data != null) {
                if (typeof d.data !== "object")
                    throw TypeError(".transport.SampleServiceResponse.data: object expected");
                m.data = $root.transport.SampleServiceResponse.ResponseData.fromObject(d.data);
            }
            return m;
        };

        SampleServiceResponse.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.status = "";
                d.data = null;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.data != null && m.hasOwnProperty("data")) {
                d.data = $root.transport.SampleServiceResponse.ResponseData.toObject(m.data, o);
            }
            return d;
        };

        SampleServiceResponse.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        SampleServiceResponse.ResponseData = (function() {

            function ResponseData(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            ResponseData.prototype.stringValue = "";
            ResponseData.prototype.intValue = 0;

            ResponseData.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.SampleServiceResponse.ResponseData)
                    return d;
                var m = new $root.transport.SampleServiceResponse.ResponseData();
                if (d.stringValue != null) {
                    m.stringValue = String(d.stringValue);
                }
                if (d.intValue != null) {
                    m.intValue = d.intValue | 0;
                }
                return m;
            };

            ResponseData.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.stringValue = "";
                    d.intValue = 0;
                }
                if (m.stringValue != null && m.hasOwnProperty("stringValue")) {
                    d.stringValue = m.stringValue;
                }
                if (m.intValue != null && m.hasOwnProperty("intValue")) {
                    d.intValue = m.intValue;
                }
                return d;
            };

            ResponseData.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return ResponseData;
        })();

        return SampleServiceResponse;
    })();

    transport.OrdersCounters = (function() {

        function OrdersCounters(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        OrdersCounters.prototype.free = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        OrdersCounters.prototype.assigned = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        OrdersCounters.prototype.bidding = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        OrdersCounters.prototype.auction = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        OrdersCounters.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.OrdersCounters)
                return d;
            var m = new $root.transport.OrdersCounters();
            if (d.free != null) {
                if ($util.Long)
                    (m.free = $util.Long.fromValue(d.free)).unsigned = false;
                else if (typeof d.free === "string")
                    m.free = parseInt(d.free, 10);
                else if (typeof d.free === "number")
                    m.free = d.free;
                else if (typeof d.free === "object")
                    m.free = new $util.LongBits(d.free.low >>> 0, d.free.high >>> 0).toNumber();
            }
            if (d.assigned != null) {
                if ($util.Long)
                    (m.assigned = $util.Long.fromValue(d.assigned)).unsigned = false;
                else if (typeof d.assigned === "string")
                    m.assigned = parseInt(d.assigned, 10);
                else if (typeof d.assigned === "number")
                    m.assigned = d.assigned;
                else if (typeof d.assigned === "object")
                    m.assigned = new $util.LongBits(d.assigned.low >>> 0, d.assigned.high >>> 0).toNumber();
            }
            if (d.bidding != null) {
                if ($util.Long)
                    (m.bidding = $util.Long.fromValue(d.bidding)).unsigned = false;
                else if (typeof d.bidding === "string")
                    m.bidding = parseInt(d.bidding, 10);
                else if (typeof d.bidding === "number")
                    m.bidding = d.bidding;
                else if (typeof d.bidding === "object")
                    m.bidding = new $util.LongBits(d.bidding.low >>> 0, d.bidding.high >>> 0).toNumber();
            }
            if (d.auction != null) {
                if ($util.Long)
                    (m.auction = $util.Long.fromValue(d.auction)).unsigned = false;
                else if (typeof d.auction === "string")
                    m.auction = parseInt(d.auction, 10);
                else if (typeof d.auction === "number")
                    m.auction = d.auction;
                else if (typeof d.auction === "object")
                    m.auction = new $util.LongBits(d.auction.low >>> 0, d.auction.high >>> 0).toNumber();
            }
            return m;
        };

        OrdersCounters.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.free = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.free = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.assigned = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.assigned = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.bidding = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.bidding = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.auction = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.auction = o.longs === String ? "0" : 0;
            }
            if (m.free != null && m.hasOwnProperty("free")) {
                if (typeof m.free === "number")
                    d.free = o.longs === String ? String(m.free) : m.free;
                else
                    d.free = o.longs === String ? $util.Long.prototype.toString.call(m.free) : o.longs === Number ? new $util.LongBits(m.free.low >>> 0, m.free.high >>> 0).toNumber() : m.free;
            }
            if (m.assigned != null && m.hasOwnProperty("assigned")) {
                if (typeof m.assigned === "number")
                    d.assigned = o.longs === String ? String(m.assigned) : m.assigned;
                else
                    d.assigned = o.longs === String ? $util.Long.prototype.toString.call(m.assigned) : o.longs === Number ? new $util.LongBits(m.assigned.low >>> 0, m.assigned.high >>> 0).toNumber() : m.assigned;
            }
            if (m.bidding != null && m.hasOwnProperty("bidding")) {
                if (typeof m.bidding === "number")
                    d.bidding = o.longs === String ? String(m.bidding) : m.bidding;
                else
                    d.bidding = o.longs === String ? $util.Long.prototype.toString.call(m.bidding) : o.longs === Number ? new $util.LongBits(m.bidding.low >>> 0, m.bidding.high >>> 0).toNumber() : m.bidding;
            }
            if (m.auction != null && m.hasOwnProperty("auction")) {
                if (typeof m.auction === "number")
                    d.auction = o.longs === String ? String(m.auction) : m.auction;
                else
                    d.auction = o.longs === String ? $util.Long.prototype.toString.call(m.auction) : o.longs === Number ? new $util.LongBits(m.auction.low >>> 0, m.auction.high >>> 0).toNumber() : m.auction;
            }
            return d;
        };

        OrdersCounters.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OrdersCounters;
    })();

    transport.CompletedOrdersReportServices = (function() {

        function CompletedOrdersReportServices(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        CompletedOrdersReportServices.prototype.id = "";
        CompletedOrdersReportServices.prototype.name = "";

        CompletedOrdersReportServices.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.CompletedOrdersReportServices)
                return d;
            var m = new $root.transport.CompletedOrdersReportServices();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            return m;
        };

        CompletedOrdersReportServices.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            return d;
        };

        CompletedOrdersReportServices.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return CompletedOrdersReportServices;
    })();

    transport.Contact = (function() {

        function Contact(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Contact.prototype.id = "";
        Contact.prototype.firstName = "";
        Contact.prototype.lastName = "";
        Contact.prototype.fullName = "";
        Contact.prototype.phoneNumber = "";
        Contact.prototype.loadingPlace = null;

        Contact.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.Contact)
                return d;
            var m = new $root.transport.Contact();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.firstName != null) {
                m.firstName = String(d.firstName);
            }
            if (d.fullName != null) {
                m.fullName = String(d.fullName);
            }
            if (d.lastName != null) {
                m.lastName = String(d.lastName);
            }
            if (d.phoneNumber != null) {
                m.phoneNumber = String(d.phoneNumber);
            }
            if (d.loadingPlace != null) {
                if (typeof d.loadingPlace !== "object")
                    throw TypeError(".transport.Contact.loadingPlace: object expected");
                m.loadingPlace = $root.transport.PlaceId.fromObject(d.loadingPlace);
            }
            return m;
        };

        Contact.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.firstName = "";
                d.fullName = "";
                d.lastName = "";
                d.phoneNumber = "";
                d.loadingPlace = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.firstName != null && m.hasOwnProperty("firstName")) {
                d.firstName = m.firstName;
            }
            if (m.fullName != null && m.hasOwnProperty("fullName")) {
                d.fullName = m.fullName;
            }
            if (m.lastName != null && m.hasOwnProperty("lastName")) {
                d.lastName = m.lastName;
            }
            if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                d.phoneNumber = m.phoneNumber;
            }
            if (m.loadingPlace != null && m.hasOwnProperty("loadingPlace")) {
                d.loadingPlace = $root.transport.PlaceId.toObject(m.loadingPlace, o);
            }
            return d;
        };

        Contact.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Contact;
    })();

    transport.Draft = (function() {

        function Draft(p) {
            this.loadingPlaces = [];
            this.unloadingPlaces = [];
            this.loadingTypes = [];
            this.extraServices = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Draft.prototype.id = "";
        Draft.prototype.createdAt = "";
        Draft.prototype.order = null;
        Draft.prototype.cargoPlaceRequirements = null;
        Draft.prototype.loadingPlaces = $util.emptyArray;
        Draft.prototype.unloadingPlaces = $util.emptyArray;
        Draft.prototype.vehicleRequirements = null;
        Draft.prototype.loadingTypes = $util.emptyArray;
        Draft.prototype.loadingPlace = null;
        Draft.prototype.unloadingPlace = null;
        Draft.prototype.loadingDate = "";
        Draft.prototype.unloadingDate = "";
        Draft.prototype.extraServices = $util.emptyArray;

        Draft.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.Draft)
                return d;
            var m = new $root.transport.Draft();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.createdAt != null) {
                m.createdAt = String(d.createdAt);
            }
            if (d.order != null) {
                if (typeof d.order !== "object")
                    throw TypeError(".transport.Draft.order: object expected");
                m.order = $root.transport.Order.fromObject(d.order);
            }
            if (d.cargoPlaceRequirements != null) {
                if (typeof d.cargoPlaceRequirements !== "object")
                    throw TypeError(".transport.Draft.cargoPlaceRequirements: object expected");
                m.cargoPlaceRequirements = $root.transport.Order.PlaceRequirements.fromObject(d.cargoPlaceRequirements);
            }
            if (d.loadingPlaces) {
                if (!Array.isArray(d.loadingPlaces))
                    throw TypeError(".transport.Draft.loadingPlaces: array expected");
                m.loadingPlaces = [];
                for (var i = 0; i < d.loadingPlaces.length; ++i) {
                    if (typeof d.loadingPlaces[i] !== "object")
                        throw TypeError(".transport.Draft.loadingPlaces: object expected");
                    m.loadingPlaces[i] = $root.transport.Order.StoragePoint.fromObject(d.loadingPlaces[i]);
                }
            }
            if (d.unloadingPlaces) {
                if (!Array.isArray(d.unloadingPlaces))
                    throw TypeError(".transport.Draft.unloadingPlaces: array expected");
                m.unloadingPlaces = [];
                for (var i = 0; i < d.unloadingPlaces.length; ++i) {
                    if (typeof d.unloadingPlaces[i] !== "object")
                        throw TypeError(".transport.Draft.unloadingPlaces: object expected");
                    m.unloadingPlaces[i] = $root.transport.Order.StoragePoint.fromObject(d.unloadingPlaces[i]);
                }
            }
            if (d.vehicleRequirements != null) {
                if (typeof d.vehicleRequirements !== "object")
                    throw TypeError(".transport.Draft.vehicleRequirements: object expected");
                m.vehicleRequirements = $root.transport.Order.VehicleRequirements.fromObject(d.vehicleRequirements);
            }
            if (d.loadingTypes) {
                if (!Array.isArray(d.loadingTypes))
                    throw TypeError(".transport.Draft.loadingTypes: array expected");
                m.loadingTypes = [];
                for (var i = 0; i < d.loadingTypes.length; ++i) {
                    if (typeof d.loadingTypes[i] !== "object")
                        throw TypeError(".transport.Draft.loadingTypes: object expected");
                    m.loadingTypes[i] = $root.transport.LoadingType.fromObject(d.loadingTypes[i]);
                }
            }
            if (d.loadingPlace != null) {
                if (typeof d.loadingPlace !== "object")
                    throw TypeError(".transport.Draft.loadingPlace: object expected");
                m.loadingPlace = $root.transport.Order.StoragePoint.fromObject(d.loadingPlace);
            }
            if (d.unloadingPlace != null) {
                if (typeof d.unloadingPlace !== "object")
                    throw TypeError(".transport.Draft.unloadingPlace: object expected");
                m.unloadingPlace = $root.transport.Order.StoragePoint.fromObject(d.unloadingPlace);
            }
            if (d.loadingDate != null) {
                m.loadingDate = String(d.loadingDate);
            }
            if (d.unloadingDate != null) {
                m.unloadingDate = String(d.unloadingDate);
            }
            if (d.extraServices) {
                if (!Array.isArray(d.extraServices))
                    throw TypeError(".transport.Draft.extraServices: array expected");
                m.extraServices = [];
                for (var i = 0; i < d.extraServices.length; ++i) {
                    if (typeof d.extraServices[i] !== "object")
                        throw TypeError(".transport.Draft.extraServices: object expected");
                    m.extraServices[i] = $root.transport.ExtraService.fromObject(d.extraServices[i]);
                }
            }
            return m;
        };

        Draft.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.loadingPlaces = [];
                d.unloadingPlaces = [];
                d.loadingTypes = [];
                d.extraServices = [];
            }
            if (o.defaults) {
                d.id = "";
                d.createdAt = "";
                d.order = null;
                d.cargoPlaceRequirements = null;
                d.vehicleRequirements = null;
                d.loadingPlace = null;
                d.unloadingPlace = null;
                d.loadingDate = "";
                d.unloadingDate = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                d.createdAt = m.createdAt;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = $root.transport.Order.toObject(m.order, o);
            }
            if (m.cargoPlaceRequirements != null && m.hasOwnProperty("cargoPlaceRequirements")) {
                d.cargoPlaceRequirements = $root.transport.Order.PlaceRequirements.toObject(m.cargoPlaceRequirements, o);
            }
            if (m.loadingPlaces && m.loadingPlaces.length) {
                d.loadingPlaces = [];
                for (var j = 0; j < m.loadingPlaces.length; ++j) {
                    d.loadingPlaces[j] = $root.transport.Order.StoragePoint.toObject(m.loadingPlaces[j], o);
                }
            }
            if (m.unloadingPlaces && m.unloadingPlaces.length) {
                d.unloadingPlaces = [];
                for (var j = 0; j < m.unloadingPlaces.length; ++j) {
                    d.unloadingPlaces[j] = $root.transport.Order.StoragePoint.toObject(m.unloadingPlaces[j], o);
                }
            }
            if (m.vehicleRequirements != null && m.hasOwnProperty("vehicleRequirements")) {
                d.vehicleRequirements = $root.transport.Order.VehicleRequirements.toObject(m.vehicleRequirements, o);
            }
            if (m.loadingTypes && m.loadingTypes.length) {
                d.loadingTypes = [];
                for (var j = 0; j < m.loadingTypes.length; ++j) {
                    d.loadingTypes[j] = $root.transport.LoadingType.toObject(m.loadingTypes[j], o);
                }
            }
            if (m.loadingPlace != null && m.hasOwnProperty("loadingPlace")) {
                d.loadingPlace = $root.transport.Order.StoragePoint.toObject(m.loadingPlace, o);
            }
            if (m.unloadingPlace != null && m.hasOwnProperty("unloadingPlace")) {
                d.unloadingPlace = $root.transport.Order.StoragePoint.toObject(m.unloadingPlace, o);
            }
            if (m.loadingDate != null && m.hasOwnProperty("loadingDate")) {
                d.loadingDate = m.loadingDate;
            }
            if (m.unloadingDate != null && m.hasOwnProperty("unloadingDate")) {
                d.unloadingDate = m.unloadingDate;
            }
            if (m.extraServices && m.extraServices.length) {
                d.extraServices = [];
                for (var j = 0; j < m.extraServices.length; ++j) {
                    d.extraServices[j] = $root.transport.ExtraService.toObject(m.extraServices[j], o);
                }
            }
            return d;
        };

        Draft.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Draft;
    })();

    transport.Driver = (function() {

        function Driver(p) {
            this.blackListInfo = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Driver.prototype.id = "";
        Driver.prototype.carrierOrganization = null;
        Driver.prototype.birthday = "";
        Driver.prototype.gender = "MALE";
        Driver.prototype.inn = "";
        Driver.prototype.phone = "";
        Driver.prototype.addPhone = "";
        Driver.prototype.licenceSeries = "";
        Driver.prototype.licenceNumber = "";
        Driver.prototype.licencePlace = "";
        Driver.prototype.licenceDate = "";
        Driver.prototype.surname = "";
        Driver.prototype.name = "";
        Driver.prototype.patronymic = "";
        Driver.prototype.passportSeries = "";
        Driver.prototype.passportNumber = "";
        Driver.prototype.passportPlace = "";
        Driver.prototype.passportDate = "";
        Driver.prototype.isArchived = false;
        Driver.prototype.country = "RU";
        Driver.prototype.usesDriverMobile = false;
        Driver.prototype.hasBeenInvitedToDriverMobile = false;
        Driver.prototype.verificationStatus = "";
        Driver.prototype.agentCarrier = null;
        Driver.prototype.blackListInfo = $util.emptyArray;

        Driver.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.Driver)
                return d;
            var m = new $root.transport.Driver();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.carrierOrganization != null) {
                if (typeof d.carrierOrganization !== "object")
                    throw TypeError(".transport.Driver.carrierOrganization: object expected");
                m.carrierOrganization = $root.transport.Carrier.Organization.fromObject(d.carrierOrganization);
            }
            if (d.birthday != null) {
                m.birthday = String(d.birthday);
            }
            if (d.gender != null) {
                m.gender = String(d.gender);
            }
            if (d.inn != null) {
                m.inn = String(d.inn);
            }
            if (d.phone != null) {
                m.phone = String(d.phone);
            }
            if (d.addPhone != null) {
                m.addPhone = String(d.addPhone);
            }
            if (d.licenceSeries != null) {
                m.licenceSeries = String(d.licenceSeries);
            }
            if (d.licenceNumber != null) {
                m.licenceNumber = String(d.licenceNumber);
            }
            if (d.licencePlace != null) {
                m.licencePlace = String(d.licencePlace);
            }
            if (d.licenceDate != null) {
                m.licenceDate = String(d.licenceDate);
            }
            if (d.surname != null) {
                m.surname = String(d.surname);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.patronymic != null) {
                m.patronymic = String(d.patronymic);
            }
            if (d.passportSeries != null) {
                m.passportSeries = String(d.passportSeries);
            }
            if (d.passportNumber != null) {
                m.passportNumber = String(d.passportNumber);
            }
            if (d.passportPlace != null) {
                m.passportPlace = String(d.passportPlace);
            }
            if (d.passportDate != null) {
                m.passportDate = String(d.passportDate);
            }
            if (d.isArchived != null) {
                m.isArchived = Boolean(d.isArchived);
            }
            if (d.country != null) {
                m.country = String(d.country);
            }
            if (d.usesDriverMobile != null) {
                m.usesDriverMobile = Boolean(d.usesDriverMobile);
            }
            if (d.hasBeenInvitedToDriverMobile != null) {
                m.hasBeenInvitedToDriverMobile = Boolean(d.hasBeenInvitedToDriverMobile);
            }
            if (d.verificationStatus != null) {
                m.verificationStatus = String(d.verificationStatus);
            }
            if (d.agentCarrier != null) {
                if (typeof d.agentCarrier !== "object")
                    throw TypeError(".transport.Driver.agentCarrier: object expected");
                m.agentCarrier = $root.transport.AgentCarrier.fromObject(d.agentCarrier);
            }
            if (d.blackListInfo) {
                if (!Array.isArray(d.blackListInfo))
                    throw TypeError(".transport.Driver.blackListInfo: array expected");
                m.blackListInfo = [];
                for (var i = 0; i < d.blackListInfo.length; ++i) {
                    if (typeof d.blackListInfo[i] !== "object")
                        throw TypeError(".transport.Driver.blackListInfo: object expected");
                    m.blackListInfo[i] = $root.transport.Driver.BlackListDriver.fromObject(d.blackListInfo[i]);
                }
            }
            return m;
        };

        Driver.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.blackListInfo = [];
            }
            if (o.defaults) {
                d.id = "";
                d.carrierOrganization = null;
                d.birthday = "";
                d.gender = "MALE";
                d.inn = "";
                d.phone = "";
                d.addPhone = "";
                d.licenceSeries = "";
                d.licenceNumber = "";
                d.licencePlace = "";
                d.licenceDate = "";
                d.surname = "";
                d.name = "";
                d.patronymic = "";
                d.passportSeries = "";
                d.passportNumber = "";
                d.passportPlace = "";
                d.passportDate = "";
                d.isArchived = false;
                d.country = "RU";
                d.usesDriverMobile = false;
                d.hasBeenInvitedToDriverMobile = false;
                d.verificationStatus = "";
                d.agentCarrier = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.carrierOrganization != null && m.hasOwnProperty("carrierOrganization")) {
                d.carrierOrganization = $root.transport.Carrier.Organization.toObject(m.carrierOrganization, o);
            }
            if (m.birthday != null && m.hasOwnProperty("birthday")) {
                d.birthday = m.birthday;
            }
            if (m.gender != null && m.hasOwnProperty("gender")) {
                d.gender = m.gender;
            }
            if (m.inn != null && m.hasOwnProperty("inn")) {
                d.inn = m.inn;
            }
            if (m.phone != null && m.hasOwnProperty("phone")) {
                d.phone = m.phone;
            }
            if (m.addPhone != null && m.hasOwnProperty("addPhone")) {
                d.addPhone = m.addPhone;
            }
            if (m.licenceSeries != null && m.hasOwnProperty("licenceSeries")) {
                d.licenceSeries = m.licenceSeries;
            }
            if (m.licenceNumber != null && m.hasOwnProperty("licenceNumber")) {
                d.licenceNumber = m.licenceNumber;
            }
            if (m.licencePlace != null && m.hasOwnProperty("licencePlace")) {
                d.licencePlace = m.licencePlace;
            }
            if (m.licenceDate != null && m.hasOwnProperty("licenceDate")) {
                d.licenceDate = m.licenceDate;
            }
            if (m.surname != null && m.hasOwnProperty("surname")) {
                d.surname = m.surname;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.patronymic != null && m.hasOwnProperty("patronymic")) {
                d.patronymic = m.patronymic;
            }
            if (m.passportSeries != null && m.hasOwnProperty("passportSeries")) {
                d.passportSeries = m.passportSeries;
            }
            if (m.passportNumber != null && m.hasOwnProperty("passportNumber")) {
                d.passportNumber = m.passportNumber;
            }
            if (m.passportPlace != null && m.hasOwnProperty("passportPlace")) {
                d.passportPlace = m.passportPlace;
            }
            if (m.passportDate != null && m.hasOwnProperty("passportDate")) {
                d.passportDate = m.passportDate;
            }
            if (m.isArchived != null && m.hasOwnProperty("isArchived")) {
                d.isArchived = m.isArchived;
            }
            if (m.country != null && m.hasOwnProperty("country")) {
                d.country = m.country;
            }
            if (m.usesDriverMobile != null && m.hasOwnProperty("usesDriverMobile")) {
                d.usesDriverMobile = m.usesDriverMobile;
            }
            if (m.hasBeenInvitedToDriverMobile != null && m.hasOwnProperty("hasBeenInvitedToDriverMobile")) {
                d.hasBeenInvitedToDriverMobile = m.hasBeenInvitedToDriverMobile;
            }
            if (m.verificationStatus != null && m.hasOwnProperty("verificationStatus")) {
                d.verificationStatus = m.verificationStatus;
            }
            if (m.agentCarrier != null && m.hasOwnProperty("agentCarrier")) {
                d.agentCarrier = $root.transport.AgentCarrier.toObject(m.agentCarrier, o);
            }
            if (m.blackListInfo && m.blackListInfo.length) {
                d.blackListInfo = [];
                for (var j = 0; j < m.blackListInfo.length; ++j) {
                    d.blackListInfo[j] = $root.transport.Driver.BlackListDriver.toObject(m.blackListInfo[j], o);
                }
            }
            return d;
        };

        Driver.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Driver.BlackListDriver = (function() {

            function BlackListDriver(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            BlackListDriver.prototype.id = "";
            BlackListDriver.prototype.startDate = "";
            BlackListDriver.prototype.workProhibited = false;
            BlackListDriver.prototype.description = "";
            BlackListDriver.prototype.reason = "";
            BlackListDriver.prototype.cargoOwner = null;

            BlackListDriver.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Driver.BlackListDriver)
                    return d;
                var m = new $root.transport.Driver.BlackListDriver();
                if (d.id != null) {
                    m.id = String(d.id);
                }
                if (d.startDate != null) {
                    m.startDate = String(d.startDate);
                }
                if (d.workProhibited != null) {
                    m.workProhibited = Boolean(d.workProhibited);
                }
                if (d.description != null) {
                    m.description = String(d.description);
                }
                if (d.reason != null) {
                    m.reason = String(d.reason);
                }
                if (d.cargoOwner != null) {
                    if (typeof d.cargoOwner !== "object")
                        throw TypeError(".transport.Driver.BlackListDriver.cargoOwner: object expected");
                    m.cargoOwner = $root.transport.CargoOwner.fromObject(d.cargoOwner);
                }
                return m;
            };

            BlackListDriver.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.id = "";
                    d.startDate = "";
                    d.workProhibited = false;
                    d.description = "";
                    d.reason = "";
                    d.cargoOwner = null;
                }
                if (m.id != null && m.hasOwnProperty("id")) {
                    d.id = m.id;
                }
                if (m.startDate != null && m.hasOwnProperty("startDate")) {
                    d.startDate = m.startDate;
                }
                if (m.workProhibited != null && m.hasOwnProperty("workProhibited")) {
                    d.workProhibited = m.workProhibited;
                }
                if (m.description != null && m.hasOwnProperty("description")) {
                    d.description = m.description;
                }
                if (m.reason != null && m.hasOwnProperty("reason")) {
                    d.reason = m.reason;
                }
                if (m.cargoOwner != null && m.hasOwnProperty("cargoOwner")) {
                    d.cargoOwner = $root.transport.CargoOwner.toObject(m.cargoOwner, o);
                }
                return d;
            };

            BlackListDriver.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return BlackListDriver;
        })();

        return Driver;
    })();

    transport.Error = (function() {

        function Error(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Error.prototype.message = "";
        Error.prototype.extensions = null;

        Error.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.Error)
                return d;
            var m = new $root.transport.Error();
            if (d.message != null) {
                m.message = String(d.message);
            }
            if (d.extensions != null) {
                if (typeof d.extensions !== "object")
                    throw TypeError(".transport.Error.extensions: object expected");
                m.extensions = $root.transport.Error.Extensions.fromObject(d.extensions);
            }
            return m;
        };

        Error.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.message = "";
                d.extensions = null;
            }
            if (m.message != null && m.hasOwnProperty("message")) {
                d.message = m.message;
            }
            if (m.extensions != null && m.hasOwnProperty("extensions")) {
                d.extensions = $root.transport.Error.Extensions.toObject(m.extensions, o);
            }
            return d;
        };

        Error.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Error.Extensions = (function() {

            function Extensions(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Extensions.prototype.category = "";
            Extensions.prototype.code = "";
            Extensions.prototype.param = "";

            Extensions.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Error.Extensions)
                    return d;
                var m = new $root.transport.Error.Extensions();
                if (d.category != null) {
                    m.category = String(d.category);
                }
                if (d.code != null) {
                    m.code = String(d.code);
                }
                if (d.param != null) {
                    m.param = String(d.param);
                }
                return m;
            };

            Extensions.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.category = "";
                    d.code = "";
                    d.param = "";
                }
                if (m.category != null && m.hasOwnProperty("category")) {
                    d.category = m.category;
                }
                if (m.code != null && m.hasOwnProperty("code")) {
                    d.code = m.code;
                }
                if (m.param != null && m.hasOwnProperty("param")) {
                    d.param = m.param;
                }
                return d;
            };

            Extensions.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Extensions;
        })();

        return Error;
    })();

    transport.FreightDetails = (function() {

        function FreightDetails(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        FreightDetails.prototype.agentCarrierFreightIncludingVat = "";
        FreightDetails.prototype.agentCarrierFreightVatAmount = "";
        FreightDetails.prototype.agentCarrierVatRate = "";
        FreightDetails.prototype.agentFeeIncludingVat = "";
        FreightDetails.prototype.agentFeeVatAmount = "";
        FreightDetails.prototype.agentFeeVatRate = "";
        FreightDetails.prototype.agentVatAmountPerAgentCarrier = "";
        FreightDetails.prototype.totalPrice = "";

        FreightDetails.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.FreightDetails)
                return d;
            var m = new $root.transport.FreightDetails();
            if (d.agentCarrierFreightIncludingVat != null) {
                m.agentCarrierFreightIncludingVat = String(d.agentCarrierFreightIncludingVat);
            }
            if (d.agentCarrierFreightVatAmount != null) {
                m.agentCarrierFreightVatAmount = String(d.agentCarrierFreightVatAmount);
            }
            if (d.agentCarrierVatRate != null) {
                m.agentCarrierVatRate = String(d.agentCarrierVatRate);
            }
            if (d.agentFeeIncludingVat != null) {
                m.agentFeeIncludingVat = String(d.agentFeeIncludingVat);
            }
            if (d.agentFeeVatAmount != null) {
                m.agentFeeVatAmount = String(d.agentFeeVatAmount);
            }
            if (d.agentFeeVatRate != null) {
                m.agentFeeVatRate = String(d.agentFeeVatRate);
            }
            if (d.agentVatAmountPerAgentCarrier != null) {
                m.agentVatAmountPerAgentCarrier = String(d.agentVatAmountPerAgentCarrier);
            }
            if (d.totalPrice != null) {
                m.totalPrice = String(d.totalPrice);
            }
            return m;
        };

        FreightDetails.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.agentCarrierFreightIncludingVat = "";
                d.agentCarrierFreightVatAmount = "";
                d.agentCarrierVatRate = "";
                d.agentFeeIncludingVat = "";
                d.agentFeeVatAmount = "";
                d.agentFeeVatRate = "";
                d.agentVatAmountPerAgentCarrier = "";
                d.totalPrice = "";
            }
            if (m.agentCarrierFreightIncludingVat != null && m.hasOwnProperty("agentCarrierFreightIncludingVat")) {
                d.agentCarrierFreightIncludingVat = m.agentCarrierFreightIncludingVat;
            }
            if (m.agentCarrierFreightVatAmount != null && m.hasOwnProperty("agentCarrierFreightVatAmount")) {
                d.agentCarrierFreightVatAmount = m.agentCarrierFreightVatAmount;
            }
            if (m.agentCarrierVatRate != null && m.hasOwnProperty("agentCarrierVatRate")) {
                d.agentCarrierVatRate = m.agentCarrierVatRate;
            }
            if (m.agentFeeIncludingVat != null && m.hasOwnProperty("agentFeeIncludingVat")) {
                d.agentFeeIncludingVat = m.agentFeeIncludingVat;
            }
            if (m.agentFeeVatAmount != null && m.hasOwnProperty("agentFeeVatAmount")) {
                d.agentFeeVatAmount = m.agentFeeVatAmount;
            }
            if (m.agentFeeVatRate != null && m.hasOwnProperty("agentFeeVatRate")) {
                d.agentFeeVatRate = m.agentFeeVatRate;
            }
            if (m.agentVatAmountPerAgentCarrier != null && m.hasOwnProperty("agentVatAmountPerAgentCarrier")) {
                d.agentVatAmountPerAgentCarrier = m.agentVatAmountPerAgentCarrier;
            }
            if (m.totalPrice != null && m.hasOwnProperty("totalPrice")) {
                d.totalPrice = m.totalPrice;
            }
            return d;
        };

        FreightDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return FreightDetails;
    })();

    transport.InsuranceParameters = (function() {

        function InsuranceParameters(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        InsuranceParameters.prototype.insureByDefault = false;

        InsuranceParameters.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.InsuranceParameters)
                return d;
            var m = new $root.transport.InsuranceParameters();
            if (d.insureByDefault != null) {
                m.insureByDefault = Boolean(d.insureByDefault);
            }
            return m;
        };

        InsuranceParameters.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.insureByDefault = false;
            }
            if (m.insureByDefault != null && m.hasOwnProperty("insureByDefault")) {
                d.insureByDefault = m.insureByDefault;
            }
            return d;
        };

        InsuranceParameters.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return InsuranceParameters;
    })();

    transport.Insurance = (function() {

        function Insurance(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Insurance.prototype.insuranceContract = null;
        Insurance.prototype.status = "";
        Insurance.prototype.data = "";
        Insurance.prototype.isOwnerInsurance = false;
        Insurance.prototype.certificateUrl = "";
        Insurance.prototype.invoiceUrl = "";

        Insurance.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.Insurance)
                return d;
            var m = new $root.transport.Insurance();
            if (d.insuranceContract != null) {
                if (typeof d.insuranceContract !== "object")
                    throw TypeError(".transport.Insurance.insuranceContract: object expected");
                m.insuranceContract = $root.transport.Insurance.Contract.fromObject(d.insuranceContract);
            }
            if (d.status != null) {
                m.status = String(d.status);
            }
            if (d.data != null) {
                m.data = String(d.data);
            }
            if (d.isOwnerInsurance != null) {
                m.isOwnerInsurance = Boolean(d.isOwnerInsurance);
            }
            if (d.certificateUrl != null) {
                m.certificateUrl = String(d.certificateUrl);
            }
            if (d.invoiceUrl != null) {
                m.invoiceUrl = String(d.invoiceUrl);
            }
            return m;
        };

        Insurance.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.insuranceContract = null;
                d.status = "";
                d.data = "";
                d.isOwnerInsurance = false;
                d.certificateUrl = "";
                d.invoiceUrl = "";
            }
            if (m.insuranceContract != null && m.hasOwnProperty("insuranceContract")) {
                d.insuranceContract = $root.transport.Insurance.Contract.toObject(m.insuranceContract, o);
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.data != null && m.hasOwnProperty("data")) {
                d.data = m.data;
            }
            if (m.isOwnerInsurance != null && m.hasOwnProperty("isOwnerInsurance")) {
                d.isOwnerInsurance = m.isOwnerInsurance;
            }
            if (m.certificateUrl != null && m.hasOwnProperty("certificateUrl")) {
                d.certificateUrl = m.certificateUrl;
            }
            if (m.invoiceUrl != null && m.hasOwnProperty("invoiceUrl")) {
                d.invoiceUrl = m.invoiceUrl;
            }
            return d;
        };

        Insurance.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Insurance.Contract = (function() {

            function Contract(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Contract.prototype.id = "";
            Contract.prototype.insuranceCompany = null;
            Contract.prototype.isDefault = false;
            Contract.prototype.endDatetime = null;
            Contract.prototype.startDatetime = "";
            Contract.prototype.generalNumber = "";
            Contract.prototype.parameters = "";
            Contract.prototype.tariff = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            Contract.prototype.isArchived = false;

            let $oneOfFields;

            Object.defineProperty(Contract.prototype, "_insuranceCompany", {
                get: $util.oneOfGetter($oneOfFields = ["insuranceCompany"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            Object.defineProperty(Contract.prototype, "_endDatetime", {
                get: $util.oneOfGetter($oneOfFields = ["endDatetime"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            Contract.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Insurance.Contract)
                    return d;
                var m = new $root.transport.Insurance.Contract();
                if (d.id != null) {
                    m.id = String(d.id);
                }
                if (d.insuranceCompany != null) {
                    if (typeof d.insuranceCompany !== "object")
                        throw TypeError(".transport.Insurance.Contract.insuranceCompany: object expected");
                    m.insuranceCompany = $root.transport.Insurance.Company.fromObject(d.insuranceCompany);
                }
                if (d.isDefault != null) {
                    m.isDefault = Boolean(d.isDefault);
                }
                if (d.endDatetime != null) {
                    m.endDatetime = String(d.endDatetime);
                }
                if (d.startDatetime != null) {
                    m.startDatetime = String(d.startDatetime);
                }
                if (d.generalNumber != null) {
                    m.generalNumber = String(d.generalNumber);
                }
                if (d.parameters != null) {
                    m.parameters = String(d.parameters);
                }
                if (d.tariff != null) {
                    if ($util.Long)
                        (m.tariff = $util.Long.fromValue(d.tariff)).unsigned = false;
                    else if (typeof d.tariff === "string")
                        m.tariff = parseInt(d.tariff, 10);
                    else if (typeof d.tariff === "number")
                        m.tariff = d.tariff;
                    else if (typeof d.tariff === "object")
                        m.tariff = new $util.LongBits(d.tariff.low >>> 0, d.tariff.high >>> 0).toNumber();
                }
                if (d.isArchived != null) {
                    m.isArchived = Boolean(d.isArchived);
                }
                return m;
            };

            Contract.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.id = "";
                    d.isDefault = false;
                    d.startDatetime = "";
                    d.generalNumber = "";
                    d.parameters = "";
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.tariff = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.tariff = o.longs === String ? "0" : 0;
                    d.isArchived = false;
                }
                if (m.id != null && m.hasOwnProperty("id")) {
                    d.id = m.id;
                }
                if (m.insuranceCompany != null && m.hasOwnProperty("insuranceCompany")) {
                    d.insuranceCompany = $root.transport.Insurance.Company.toObject(m.insuranceCompany, o);
                    if (o.oneofs)
                        d._insuranceCompany = "insuranceCompany";
                }
                if (m.isDefault != null && m.hasOwnProperty("isDefault")) {
                    d.isDefault = m.isDefault;
                }
                if (m.endDatetime != null && m.hasOwnProperty("endDatetime")) {
                    d.endDatetime = m.endDatetime;
                    if (o.oneofs)
                        d._endDatetime = "endDatetime";
                }
                if (m.startDatetime != null && m.hasOwnProperty("startDatetime")) {
                    d.startDatetime = m.startDatetime;
                }
                if (m.generalNumber != null && m.hasOwnProperty("generalNumber")) {
                    d.generalNumber = m.generalNumber;
                }
                if (m.parameters != null && m.hasOwnProperty("parameters")) {
                    d.parameters = m.parameters;
                }
                if (m.tariff != null && m.hasOwnProperty("tariff")) {
                    if (typeof m.tariff === "number")
                        d.tariff = o.longs === String ? String(m.tariff) : m.tariff;
                    else
                        d.tariff = o.longs === String ? $util.Long.prototype.toString.call(m.tariff) : o.longs === Number ? new $util.LongBits(m.tariff.low >>> 0, m.tariff.high >>> 0).toNumber() : m.tariff;
                }
                if (m.isArchived != null && m.hasOwnProperty("isArchived")) {
                    d.isArchived = m.isArchived;
                }
                return d;
            };

            Contract.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Contract;
        })();

        Insurance.Company = (function() {

            function Company(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Company.prototype.codename = "";
            Company.prototype.name = "";

            Company.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Insurance.Company)
                    return d;
                var m = new $root.transport.Insurance.Company();
                if (d.codename != null) {
                    m.codename = String(d.codename);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                return m;
            };

            Company.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.codename = "";
                    d.name = "";
                }
                if (m.codename != null && m.hasOwnProperty("codename")) {
                    d.codename = m.codename;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                return d;
            };

            Company.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Company;
        })();

        return Insurance;
    })();

    transport.LotInput = (function() {

        function LotInput(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        LotInput.prototype.startDatetime = "";
        LotInput.prototype.endDatetime = "";
        LotInput.prototype.startPrice = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        LotInput.prototype.enableNotifications = false;
        LotInput.prototype.minStepPrice = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        LotInput.prototype.isProlongationAvailable = false;
        LotInput.prototype.minutesOfProlongation = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        LotInput.prototype.timesOfProlongation = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        LotInput.prototype.minutesTillEndToProlong = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        LotInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.LotInput)
                return d;
            var m = new $root.transport.LotInput();
            if (d.startDatetime != null) {
                m.startDatetime = String(d.startDatetime);
            }
            if (d.endDatetime != null) {
                m.endDatetime = String(d.endDatetime);
            }
            if (d.startPrice != null) {
                if ($util.Long)
                    (m.startPrice = $util.Long.fromValue(d.startPrice)).unsigned = false;
                else if (typeof d.startPrice === "string")
                    m.startPrice = parseInt(d.startPrice, 10);
                else if (typeof d.startPrice === "number")
                    m.startPrice = d.startPrice;
                else if (typeof d.startPrice === "object")
                    m.startPrice = new $util.LongBits(d.startPrice.low >>> 0, d.startPrice.high >>> 0).toNumber();
            }
            if (d.enableNotifications != null) {
                m.enableNotifications = Boolean(d.enableNotifications);
            }
            if (d.minStepPrice != null) {
                if ($util.Long)
                    (m.minStepPrice = $util.Long.fromValue(d.minStepPrice)).unsigned = false;
                else if (typeof d.minStepPrice === "string")
                    m.minStepPrice = parseInt(d.minStepPrice, 10);
                else if (typeof d.minStepPrice === "number")
                    m.minStepPrice = d.minStepPrice;
                else if (typeof d.minStepPrice === "object")
                    m.minStepPrice = new $util.LongBits(d.minStepPrice.low >>> 0, d.minStepPrice.high >>> 0).toNumber();
            }
            if (d.isProlongationAvailable != null) {
                m.isProlongationAvailable = Boolean(d.isProlongationAvailable);
            }
            if (d.minutesOfProlongation != null) {
                if ($util.Long)
                    (m.minutesOfProlongation = $util.Long.fromValue(d.minutesOfProlongation)).unsigned = false;
                else if (typeof d.minutesOfProlongation === "string")
                    m.minutesOfProlongation = parseInt(d.minutesOfProlongation, 10);
                else if (typeof d.minutesOfProlongation === "number")
                    m.minutesOfProlongation = d.minutesOfProlongation;
                else if (typeof d.minutesOfProlongation === "object")
                    m.minutesOfProlongation = new $util.LongBits(d.minutesOfProlongation.low >>> 0, d.minutesOfProlongation.high >>> 0).toNumber();
            }
            if (d.timesOfProlongation != null) {
                if ($util.Long)
                    (m.timesOfProlongation = $util.Long.fromValue(d.timesOfProlongation)).unsigned = false;
                else if (typeof d.timesOfProlongation === "string")
                    m.timesOfProlongation = parseInt(d.timesOfProlongation, 10);
                else if (typeof d.timesOfProlongation === "number")
                    m.timesOfProlongation = d.timesOfProlongation;
                else if (typeof d.timesOfProlongation === "object")
                    m.timesOfProlongation = new $util.LongBits(d.timesOfProlongation.low >>> 0, d.timesOfProlongation.high >>> 0).toNumber();
            }
            if (d.minutesTillEndToProlong != null) {
                if ($util.Long)
                    (m.minutesTillEndToProlong = $util.Long.fromValue(d.minutesTillEndToProlong)).unsigned = false;
                else if (typeof d.minutesTillEndToProlong === "string")
                    m.minutesTillEndToProlong = parseInt(d.minutesTillEndToProlong, 10);
                else if (typeof d.minutesTillEndToProlong === "number")
                    m.minutesTillEndToProlong = d.minutesTillEndToProlong;
                else if (typeof d.minutesTillEndToProlong === "object")
                    m.minutesTillEndToProlong = new $util.LongBits(d.minutesTillEndToProlong.low >>> 0, d.minutesTillEndToProlong.high >>> 0).toNumber();
            }
            return m;
        };

        LotInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.startDatetime = "";
                d.endDatetime = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startPrice = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startPrice = o.longs === String ? "0" : 0;
                d.enableNotifications = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.minStepPrice = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.minStepPrice = o.longs === String ? "0" : 0;
                d.isProlongationAvailable = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.minutesOfProlongation = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.minutesOfProlongation = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.timesOfProlongation = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timesOfProlongation = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.minutesTillEndToProlong = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.minutesTillEndToProlong = o.longs === String ? "0" : 0;
            }
            if (m.startDatetime != null && m.hasOwnProperty("startDatetime")) {
                d.startDatetime = m.startDatetime;
            }
            if (m.endDatetime != null && m.hasOwnProperty("endDatetime")) {
                d.endDatetime = m.endDatetime;
            }
            if (m.startPrice != null && m.hasOwnProperty("startPrice")) {
                if (typeof m.startPrice === "number")
                    d.startPrice = o.longs === String ? String(m.startPrice) : m.startPrice;
                else
                    d.startPrice = o.longs === String ? $util.Long.prototype.toString.call(m.startPrice) : o.longs === Number ? new $util.LongBits(m.startPrice.low >>> 0, m.startPrice.high >>> 0).toNumber() : m.startPrice;
            }
            if (m.enableNotifications != null && m.hasOwnProperty("enableNotifications")) {
                d.enableNotifications = m.enableNotifications;
            }
            if (m.minStepPrice != null && m.hasOwnProperty("minStepPrice")) {
                if (typeof m.minStepPrice === "number")
                    d.minStepPrice = o.longs === String ? String(m.minStepPrice) : m.minStepPrice;
                else
                    d.minStepPrice = o.longs === String ? $util.Long.prototype.toString.call(m.minStepPrice) : o.longs === Number ? new $util.LongBits(m.minStepPrice.low >>> 0, m.minStepPrice.high >>> 0).toNumber() : m.minStepPrice;
            }
            if (m.isProlongationAvailable != null && m.hasOwnProperty("isProlongationAvailable")) {
                d.isProlongationAvailable = m.isProlongationAvailable;
            }
            if (m.minutesOfProlongation != null && m.hasOwnProperty("minutesOfProlongation")) {
                if (typeof m.minutesOfProlongation === "number")
                    d.minutesOfProlongation = o.longs === String ? String(m.minutesOfProlongation) : m.minutesOfProlongation;
                else
                    d.minutesOfProlongation = o.longs === String ? $util.Long.prototype.toString.call(m.minutesOfProlongation) : o.longs === Number ? new $util.LongBits(m.minutesOfProlongation.low >>> 0, m.minutesOfProlongation.high >>> 0).toNumber() : m.minutesOfProlongation;
            }
            if (m.timesOfProlongation != null && m.hasOwnProperty("timesOfProlongation")) {
                if (typeof m.timesOfProlongation === "number")
                    d.timesOfProlongation = o.longs === String ? String(m.timesOfProlongation) : m.timesOfProlongation;
                else
                    d.timesOfProlongation = o.longs === String ? $util.Long.prototype.toString.call(m.timesOfProlongation) : o.longs === Number ? new $util.LongBits(m.timesOfProlongation.low >>> 0, m.timesOfProlongation.high >>> 0).toNumber() : m.timesOfProlongation;
            }
            if (m.minutesTillEndToProlong != null && m.hasOwnProperty("minutesTillEndToProlong")) {
                if (typeof m.minutesTillEndToProlong === "number")
                    d.minutesTillEndToProlong = o.longs === String ? String(m.minutesTillEndToProlong) : m.minutesTillEndToProlong;
                else
                    d.minutesTillEndToProlong = o.longs === String ? $util.Long.prototype.toString.call(m.minutesTillEndToProlong) : o.longs === Number ? new $util.LongBits(m.minutesTillEndToProlong.low >>> 0, m.minutesTillEndToProlong.high >>> 0).toNumber() : m.minutesTillEndToProlong;
            }
            return d;
        };

        LotInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return LotInput;
    })();

    transport.Lot = (function() {

        function Lot(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Lot.prototype.id = "";
        Lot.prototype.order = null;
        Lot.prototype.startDatetime = "";
        Lot.prototype.endDatetime = "";
        Lot.prototype.startPrice = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Lot.prototype.minStepPrice = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Lot.prototype.lastBet = null;
        Lot.prototype.lastBetCarrier = null;
        Lot.prototype.isLastBetMine = null;
        Lot.prototype.lastBetDatetime = null;
        Lot.prototype.enableNotifications = false;
        Lot.prototype.auctionStatus = "";
        Lot.prototype.isLastBetByMyOrganization = false;
        Lot.prototype.isProlongationAvailable = false;
        Lot.prototype.minutesTillEndToProlong = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Lot.prototype.minutesOfProlongation = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Lot.prototype.timesOfProlongation = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Lot.prototype.mayBeProlonged = false;
        Lot.prototype.quantityOfProlongations = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Lot.prototype.hasExtraConditionsForMarket = null;
        Lot.prototype.extraConditionsForMarket = null;
        Lot.prototype.viewEndDatetime = "";
        Lot.prototype.betCanEqualStartPrice = false;

        let $oneOfFields;

        Object.defineProperty(Lot.prototype, "_lastBet", {
            get: $util.oneOfGetter($oneOfFields = ["lastBet"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Lot.prototype, "_lastBetCarrier", {
            get: $util.oneOfGetter($oneOfFields = ["lastBetCarrier"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Lot.prototype, "_isLastBetMine", {
            get: $util.oneOfGetter($oneOfFields = ["isLastBetMine"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Lot.prototype, "_lastBetDatetime", {
            get: $util.oneOfGetter($oneOfFields = ["lastBetDatetime"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Lot.prototype, "_hasExtraConditionsForMarket", {
            get: $util.oneOfGetter($oneOfFields = ["hasExtraConditionsForMarket"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Lot.prototype, "_extraConditionsForMarket", {
            get: $util.oneOfGetter($oneOfFields = ["extraConditionsForMarket"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Lot.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.Lot)
                return d;
            var m = new $root.transport.Lot();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.order != null) {
                if (typeof d.order !== "object")
                    throw TypeError(".transport.Lot.order: object expected");
                m.order = $root.transport.Order.fromObject(d.order);
            }
            if (d.startDatetime != null) {
                m.startDatetime = String(d.startDatetime);
            }
            if (d.endDatetime != null) {
                m.endDatetime = String(d.endDatetime);
            }
            if (d.startPrice != null) {
                if ($util.Long)
                    (m.startPrice = $util.Long.fromValue(d.startPrice)).unsigned = false;
                else if (typeof d.startPrice === "string")
                    m.startPrice = parseInt(d.startPrice, 10);
                else if (typeof d.startPrice === "number")
                    m.startPrice = d.startPrice;
                else if (typeof d.startPrice === "object")
                    m.startPrice = new $util.LongBits(d.startPrice.low >>> 0, d.startPrice.high >>> 0).toNumber();
            }
            if (d.minStepPrice != null) {
                if ($util.Long)
                    (m.minStepPrice = $util.Long.fromValue(d.minStepPrice)).unsigned = false;
                else if (typeof d.minStepPrice === "string")
                    m.minStepPrice = parseInt(d.minStepPrice, 10);
                else if (typeof d.minStepPrice === "number")
                    m.minStepPrice = d.minStepPrice;
                else if (typeof d.minStepPrice === "object")
                    m.minStepPrice = new $util.LongBits(d.minStepPrice.low >>> 0, d.minStepPrice.high >>> 0).toNumber();
            }
            if (d.lastBet != null) {
                if ($util.Long)
                    (m.lastBet = $util.Long.fromValue(d.lastBet)).unsigned = false;
                else if (typeof d.lastBet === "string")
                    m.lastBet = parseInt(d.lastBet, 10);
                else if (typeof d.lastBet === "number")
                    m.lastBet = d.lastBet;
                else if (typeof d.lastBet === "object")
                    m.lastBet = new $util.LongBits(d.lastBet.low >>> 0, d.lastBet.high >>> 0).toNumber();
            }
            if (d.lastBetCarrier != null) {
                if (typeof d.lastBetCarrier !== "object")
                    throw TypeError(".transport.Lot.lastBetCarrier: object expected");
                m.lastBetCarrier = $root.transport.Lot.LastBetCarrier.fromObject(d.lastBetCarrier);
            }
            if (d.isLastBetMine != null) {
                m.isLastBetMine = Boolean(d.isLastBetMine);
            }
            if (d.lastBetDatetime != null) {
                m.lastBetDatetime = String(d.lastBetDatetime);
            }
            if (d.enableNotifications != null) {
                m.enableNotifications = Boolean(d.enableNotifications);
            }
            if (d.auctionStatus != null) {
                m.auctionStatus = String(d.auctionStatus);
            }
            if (d.isLastBetByMyOrganization != null) {
                m.isLastBetByMyOrganization = Boolean(d.isLastBetByMyOrganization);
            }
            if (d.isProlongationAvailable != null) {
                m.isProlongationAvailable = Boolean(d.isProlongationAvailable);
            }
            if (d.minutesTillEndToProlong != null) {
                if ($util.Long)
                    (m.minutesTillEndToProlong = $util.Long.fromValue(d.minutesTillEndToProlong)).unsigned = false;
                else if (typeof d.minutesTillEndToProlong === "string")
                    m.minutesTillEndToProlong = parseInt(d.minutesTillEndToProlong, 10);
                else if (typeof d.minutesTillEndToProlong === "number")
                    m.minutesTillEndToProlong = d.minutesTillEndToProlong;
                else if (typeof d.minutesTillEndToProlong === "object")
                    m.minutesTillEndToProlong = new $util.LongBits(d.minutesTillEndToProlong.low >>> 0, d.minutesTillEndToProlong.high >>> 0).toNumber();
            }
            if (d.minutesOfProlongation != null) {
                if ($util.Long)
                    (m.minutesOfProlongation = $util.Long.fromValue(d.minutesOfProlongation)).unsigned = false;
                else if (typeof d.minutesOfProlongation === "string")
                    m.minutesOfProlongation = parseInt(d.minutesOfProlongation, 10);
                else if (typeof d.minutesOfProlongation === "number")
                    m.minutesOfProlongation = d.minutesOfProlongation;
                else if (typeof d.minutesOfProlongation === "object")
                    m.minutesOfProlongation = new $util.LongBits(d.minutesOfProlongation.low >>> 0, d.minutesOfProlongation.high >>> 0).toNumber();
            }
            if (d.timesOfProlongation != null) {
                if ($util.Long)
                    (m.timesOfProlongation = $util.Long.fromValue(d.timesOfProlongation)).unsigned = false;
                else if (typeof d.timesOfProlongation === "string")
                    m.timesOfProlongation = parseInt(d.timesOfProlongation, 10);
                else if (typeof d.timesOfProlongation === "number")
                    m.timesOfProlongation = d.timesOfProlongation;
                else if (typeof d.timesOfProlongation === "object")
                    m.timesOfProlongation = new $util.LongBits(d.timesOfProlongation.low >>> 0, d.timesOfProlongation.high >>> 0).toNumber();
            }
            if (d.mayBeProlonged != null) {
                m.mayBeProlonged = Boolean(d.mayBeProlonged);
            }
            if (d.quantityOfProlongations != null) {
                if ($util.Long)
                    (m.quantityOfProlongations = $util.Long.fromValue(d.quantityOfProlongations)).unsigned = false;
                else if (typeof d.quantityOfProlongations === "string")
                    m.quantityOfProlongations = parseInt(d.quantityOfProlongations, 10);
                else if (typeof d.quantityOfProlongations === "number")
                    m.quantityOfProlongations = d.quantityOfProlongations;
                else if (typeof d.quantityOfProlongations === "object")
                    m.quantityOfProlongations = new $util.LongBits(d.quantityOfProlongations.low >>> 0, d.quantityOfProlongations.high >>> 0).toNumber();
            }
            if (d.hasExtraConditionsForMarket != null) {
                m.hasExtraConditionsForMarket = Boolean(d.hasExtraConditionsForMarket);
            }
            if (d.extraConditionsForMarket != null) {
                m.extraConditionsForMarket = String(d.extraConditionsForMarket);
            }
            if (d.viewEndDatetime != null) {
                m.viewEndDatetime = String(d.viewEndDatetime);
            }
            if (d.betCanEqualStartPrice != null) {
                m.betCanEqualStartPrice = Boolean(d.betCanEqualStartPrice);
            }
            return m;
        };

        Lot.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.order = null;
                d.startDatetime = "";
                d.endDatetime = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.startPrice = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.startPrice = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.minStepPrice = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.minStepPrice = o.longs === String ? "0" : 0;
                d.enableNotifications = false;
                d.auctionStatus = "";
                d.isLastBetByMyOrganization = false;
                d.isProlongationAvailable = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.minutesTillEndToProlong = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.minutesTillEndToProlong = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.minutesOfProlongation = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.minutesOfProlongation = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.timesOfProlongation = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timesOfProlongation = o.longs === String ? "0" : 0;
                d.mayBeProlonged = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.quantityOfProlongations = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.quantityOfProlongations = o.longs === String ? "0" : 0;
                d.viewEndDatetime = "";
                d.betCanEqualStartPrice = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.order != null && m.hasOwnProperty("order")) {
                d.order = $root.transport.Order.toObject(m.order, o);
            }
            if (m.startDatetime != null && m.hasOwnProperty("startDatetime")) {
                d.startDatetime = m.startDatetime;
            }
            if (m.endDatetime != null && m.hasOwnProperty("endDatetime")) {
                d.endDatetime = m.endDatetime;
            }
            if (m.startPrice != null && m.hasOwnProperty("startPrice")) {
                if (typeof m.startPrice === "number")
                    d.startPrice = o.longs === String ? String(m.startPrice) : m.startPrice;
                else
                    d.startPrice = o.longs === String ? $util.Long.prototype.toString.call(m.startPrice) : o.longs === Number ? new $util.LongBits(m.startPrice.low >>> 0, m.startPrice.high >>> 0).toNumber() : m.startPrice;
            }
            if (m.minStepPrice != null && m.hasOwnProperty("minStepPrice")) {
                if (typeof m.minStepPrice === "number")
                    d.minStepPrice = o.longs === String ? String(m.minStepPrice) : m.minStepPrice;
                else
                    d.minStepPrice = o.longs === String ? $util.Long.prototype.toString.call(m.minStepPrice) : o.longs === Number ? new $util.LongBits(m.minStepPrice.low >>> 0, m.minStepPrice.high >>> 0).toNumber() : m.minStepPrice;
            }
            if (m.lastBet != null && m.hasOwnProperty("lastBet")) {
                if (typeof m.lastBet === "number")
                    d.lastBet = o.longs === String ? String(m.lastBet) : m.lastBet;
                else
                    d.lastBet = o.longs === String ? $util.Long.prototype.toString.call(m.lastBet) : o.longs === Number ? new $util.LongBits(m.lastBet.low >>> 0, m.lastBet.high >>> 0).toNumber() : m.lastBet;
                if (o.oneofs)
                    d._lastBet = "lastBet";
            }
            if (m.lastBetCarrier != null && m.hasOwnProperty("lastBetCarrier")) {
                d.lastBetCarrier = $root.transport.Lot.LastBetCarrier.toObject(m.lastBetCarrier, o);
                if (o.oneofs)
                    d._lastBetCarrier = "lastBetCarrier";
            }
            if (m.isLastBetMine != null && m.hasOwnProperty("isLastBetMine")) {
                d.isLastBetMine = m.isLastBetMine;
                if (o.oneofs)
                    d._isLastBetMine = "isLastBetMine";
            }
            if (m.lastBetDatetime != null && m.hasOwnProperty("lastBetDatetime")) {
                d.lastBetDatetime = m.lastBetDatetime;
                if (o.oneofs)
                    d._lastBetDatetime = "lastBetDatetime";
            }
            if (m.enableNotifications != null && m.hasOwnProperty("enableNotifications")) {
                d.enableNotifications = m.enableNotifications;
            }
            if (m.auctionStatus != null && m.hasOwnProperty("auctionStatus")) {
                d.auctionStatus = m.auctionStatus;
            }
            if (m.isLastBetByMyOrganization != null && m.hasOwnProperty("isLastBetByMyOrganization")) {
                d.isLastBetByMyOrganization = m.isLastBetByMyOrganization;
            }
            if (m.isProlongationAvailable != null && m.hasOwnProperty("isProlongationAvailable")) {
                d.isProlongationAvailable = m.isProlongationAvailable;
            }
            if (m.minutesTillEndToProlong != null && m.hasOwnProperty("minutesTillEndToProlong")) {
                if (typeof m.minutesTillEndToProlong === "number")
                    d.minutesTillEndToProlong = o.longs === String ? String(m.minutesTillEndToProlong) : m.minutesTillEndToProlong;
                else
                    d.minutesTillEndToProlong = o.longs === String ? $util.Long.prototype.toString.call(m.minutesTillEndToProlong) : o.longs === Number ? new $util.LongBits(m.minutesTillEndToProlong.low >>> 0, m.minutesTillEndToProlong.high >>> 0).toNumber() : m.minutesTillEndToProlong;
            }
            if (m.minutesOfProlongation != null && m.hasOwnProperty("minutesOfProlongation")) {
                if (typeof m.minutesOfProlongation === "number")
                    d.minutesOfProlongation = o.longs === String ? String(m.minutesOfProlongation) : m.minutesOfProlongation;
                else
                    d.minutesOfProlongation = o.longs === String ? $util.Long.prototype.toString.call(m.minutesOfProlongation) : o.longs === Number ? new $util.LongBits(m.minutesOfProlongation.low >>> 0, m.minutesOfProlongation.high >>> 0).toNumber() : m.minutesOfProlongation;
            }
            if (m.timesOfProlongation != null && m.hasOwnProperty("timesOfProlongation")) {
                if (typeof m.timesOfProlongation === "number")
                    d.timesOfProlongation = o.longs === String ? String(m.timesOfProlongation) : m.timesOfProlongation;
                else
                    d.timesOfProlongation = o.longs === String ? $util.Long.prototype.toString.call(m.timesOfProlongation) : o.longs === Number ? new $util.LongBits(m.timesOfProlongation.low >>> 0, m.timesOfProlongation.high >>> 0).toNumber() : m.timesOfProlongation;
            }
            if (m.mayBeProlonged != null && m.hasOwnProperty("mayBeProlonged")) {
                d.mayBeProlonged = m.mayBeProlonged;
            }
            if (m.quantityOfProlongations != null && m.hasOwnProperty("quantityOfProlongations")) {
                if (typeof m.quantityOfProlongations === "number")
                    d.quantityOfProlongations = o.longs === String ? String(m.quantityOfProlongations) : m.quantityOfProlongations;
                else
                    d.quantityOfProlongations = o.longs === String ? $util.Long.prototype.toString.call(m.quantityOfProlongations) : o.longs === Number ? new $util.LongBits(m.quantityOfProlongations.low >>> 0, m.quantityOfProlongations.high >>> 0).toNumber() : m.quantityOfProlongations;
            }
            if (m.hasExtraConditionsForMarket != null && m.hasOwnProperty("hasExtraConditionsForMarket")) {
                d.hasExtraConditionsForMarket = m.hasExtraConditionsForMarket;
                if (o.oneofs)
                    d._hasExtraConditionsForMarket = "hasExtraConditionsForMarket";
            }
            if (m.extraConditionsForMarket != null && m.hasOwnProperty("extraConditionsForMarket")) {
                d.extraConditionsForMarket = m.extraConditionsForMarket;
                if (o.oneofs)
                    d._extraConditionsForMarket = "extraConditionsForMarket";
            }
            if (m.viewEndDatetime != null && m.hasOwnProperty("viewEndDatetime")) {
                d.viewEndDatetime = m.viewEndDatetime;
            }
            if (m.betCanEqualStartPrice != null && m.hasOwnProperty("betCanEqualStartPrice")) {
                d.betCanEqualStartPrice = m.betCanEqualStartPrice;
            }
            return d;
        };

        Lot.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Lot.LastBetCarrier = (function() {

            function LastBetCarrier(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            LastBetCarrier.prototype.id = "";
            LastBetCarrier.prototype.companyName = "";
            LastBetCarrier.prototype.email = "";
            LastBetCarrier.prototype.firstName = "";
            LastBetCarrier.prototype.lastName = "";

            LastBetCarrier.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Lot.LastBetCarrier)
                    return d;
                var m = new $root.transport.Lot.LastBetCarrier();
                if (d.id != null) {
                    m.id = String(d.id);
                }
                if (d.companyName != null) {
                    m.companyName = String(d.companyName);
                }
                if (d.email != null) {
                    m.email = String(d.email);
                }
                if (d.firstName != null) {
                    m.firstName = String(d.firstName);
                }
                if (d.lastName != null) {
                    m.lastName = String(d.lastName);
                }
                return m;
            };

            LastBetCarrier.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.id = "";
                    d.companyName = "";
                    d.email = "";
                    d.firstName = "";
                    d.lastName = "";
                }
                if (m.id != null && m.hasOwnProperty("id")) {
                    d.id = m.id;
                }
                if (m.companyName != null && m.hasOwnProperty("companyName")) {
                    d.companyName = m.companyName;
                }
                if (m.email != null && m.hasOwnProperty("email")) {
                    d.email = m.email;
                }
                if (m.firstName != null && m.hasOwnProperty("firstName")) {
                    d.firstName = m.firstName;
                }
                if (m.lastName != null && m.hasOwnProperty("lastName")) {
                    d.lastName = m.lastName;
                }
                return d;
            };

            LastBetCarrier.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return LastBetCarrier;
        })();

        return Lot;
    })();

    transport.Notification = (function() {

        function Notification(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Notification.prototype.id = "";
        Notification.prototype.title = "";
        Notification.prototype.topic = "";
        Notification.prototype.action = "";
        Notification.prototype.text = "";
        Notification.prototype.payload = null;
        Notification.prototype.isRead = false;
        Notification.prototype.eventDatetime = "";

        Notification.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.Notification)
                return d;
            var m = new $root.transport.Notification();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.title != null) {
                m.title = String(d.title);
            }
            if (d.topic != null) {
                m.topic = String(d.topic);
            }
            if (d.action != null) {
                m.action = String(d.action);
            }
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.payload != null) {
                if (typeof d.payload !== "object")
                    throw TypeError(".transport.Notification.payload: object expected");
                m.payload = $root.google.protobuf.Any.fromObject(d.payload);
            }
            if (d.isRead != null) {
                m.isRead = Boolean(d.isRead);
            }
            if (d.eventDatetime != null) {
                m.eventDatetime = String(d.eventDatetime);
            }
            return m;
        };

        Notification.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.title = "";
                d.topic = "";
                d.action = "";
                d.text = "";
                d.payload = null;
                d.isRead = false;
                d.eventDatetime = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.title != null && m.hasOwnProperty("title")) {
                d.title = m.title;
            }
            if (m.topic != null && m.hasOwnProperty("topic")) {
                d.topic = m.topic;
            }
            if (m.action != null && m.hasOwnProperty("action")) {
                d.action = m.action;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.payload != null && m.hasOwnProperty("payload")) {
                d.payload = $root.google.protobuf.Any.toObject(m.payload, o);
            }
            if (m.isRead != null && m.hasOwnProperty("isRead")) {
                d.isRead = m.isRead;
            }
            if (m.eventDatetime != null && m.hasOwnProperty("eventDatetime")) {
                d.eventDatetime = m.eventDatetime;
            }
            return d;
        };

        Notification.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Notification;
    })();

    transport.NotificationsWithCounters = (function() {

        function NotificationsWithCounters(p) {
            this.notifications = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        NotificationsWithCounters.prototype.count = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        NotificationsWithCounters.prototype.unreadCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        NotificationsWithCounters.prototype.notifications = $util.emptyArray;

        NotificationsWithCounters.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.NotificationsWithCounters)
                return d;
            var m = new $root.transport.NotificationsWithCounters();
            if (d.count != null) {
                if ($util.Long)
                    (m.count = $util.Long.fromValue(d.count)).unsigned = false;
                else if (typeof d.count === "string")
                    m.count = parseInt(d.count, 10);
                else if (typeof d.count === "number")
                    m.count = d.count;
                else if (typeof d.count === "object")
                    m.count = new $util.LongBits(d.count.low >>> 0, d.count.high >>> 0).toNumber();
            }
            if (d.unreadCount != null) {
                if ($util.Long)
                    (m.unreadCount = $util.Long.fromValue(d.unreadCount)).unsigned = false;
                else if (typeof d.unreadCount === "string")
                    m.unreadCount = parseInt(d.unreadCount, 10);
                else if (typeof d.unreadCount === "number")
                    m.unreadCount = d.unreadCount;
                else if (typeof d.unreadCount === "object")
                    m.unreadCount = new $util.LongBits(d.unreadCount.low >>> 0, d.unreadCount.high >>> 0).toNumber();
            }
            if (d.notifications) {
                if (!Array.isArray(d.notifications))
                    throw TypeError(".transport.NotificationsWithCounters.notifications: array expected");
                m.notifications = [];
                for (var i = 0; i < d.notifications.length; ++i) {
                    if (typeof d.notifications[i] !== "object")
                        throw TypeError(".transport.NotificationsWithCounters.notifications: object expected");
                    m.notifications[i] = $root.transport.Notification.fromObject(d.notifications[i]);
                }
            }
            return m;
        };

        NotificationsWithCounters.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.notifications = [];
            }
            if (o.defaults) {
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.count = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.count = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.unreadCount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.unreadCount = o.longs === String ? "0" : 0;
            }
            if (m.count != null && m.hasOwnProperty("count")) {
                if (typeof m.count === "number")
                    d.count = o.longs === String ? String(m.count) : m.count;
                else
                    d.count = o.longs === String ? $util.Long.prototype.toString.call(m.count) : o.longs === Number ? new $util.LongBits(m.count.low >>> 0, m.count.high >>> 0).toNumber() : m.count;
            }
            if (m.unreadCount != null && m.hasOwnProperty("unreadCount")) {
                if (typeof m.unreadCount === "number")
                    d.unreadCount = o.longs === String ? String(m.unreadCount) : m.unreadCount;
                else
                    d.unreadCount = o.longs === String ? $util.Long.prototype.toString.call(m.unreadCount) : o.longs === Number ? new $util.LongBits(m.unreadCount.low >>> 0, m.unreadCount.high >>> 0).toNumber() : m.unreadCount;
            }
            if (m.notifications && m.notifications.length) {
                d.notifications = [];
                for (var j = 0; j < m.notifications.length; ++j) {
                    d.notifications[j] = $root.transport.Notification.toObject(m.notifications[j], o);
                }
            }
            return d;
        };

        NotificationsWithCounters.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return NotificationsWithCounters;
    })();

    transport.SystemBanner = (function() {

        function SystemBanner(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        SystemBanner.prototype.text = "";
        SystemBanner.prototype.expireAt = "";
        SystemBanner.prototype.isActive = false;

        SystemBanner.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.SystemBanner)
                return d;
            var m = new $root.transport.SystemBanner();
            if (d.text != null) {
                m.text = String(d.text);
            }
            if (d.expireAt != null) {
                m.expireAt = String(d.expireAt);
            }
            if (d.isActive != null) {
                m.isActive = Boolean(d.isActive);
            }
            return m;
        };

        SystemBanner.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.text = "";
                d.expireAt = "";
                d.isActive = false;
            }
            if (m.text != null && m.hasOwnProperty("text")) {
                d.text = m.text;
            }
            if (m.expireAt != null && m.hasOwnProperty("expireAt")) {
                d.expireAt = m.expireAt;
            }
            if (m.isActive != null && m.hasOwnProperty("isActive")) {
                d.isActive = m.isActive;
            }
            return d;
        };

        SystemBanner.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return SystemBanner;
    })();

    transport.EditOrderUploadedDocumentsInput = (function() {

        function EditOrderUploadedDocumentsInput(p) {
            this.documentsToAdd = [];
            this.documentsToEdit = [];
            this.documentsToDelete = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        EditOrderUploadedDocumentsInput.prototype.documentsToAdd = $util.emptyArray;
        EditOrderUploadedDocumentsInput.prototype.documentsToEdit = $util.emptyArray;
        EditOrderUploadedDocumentsInput.prototype.documentsToDelete = $util.emptyArray;
        EditOrderUploadedDocumentsInput.prototype.orderId = "";

        EditOrderUploadedDocumentsInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.EditOrderUploadedDocumentsInput)
                return d;
            var m = new $root.transport.EditOrderUploadedDocumentsInput();
            if (d.documentsToAdd) {
                if (!Array.isArray(d.documentsToAdd))
                    throw TypeError(".transport.EditOrderUploadedDocumentsInput.documentsToAdd: array expected");
                m.documentsToAdd = [];
                for (var i = 0; i < d.documentsToAdd.length; ++i) {
                    if (typeof d.documentsToAdd[i] !== "object")
                        throw TypeError(".transport.EditOrderUploadedDocumentsInput.documentsToAdd: object expected");
                    m.documentsToAdd[i] = $root.transport.AddOrderUploadedDocumentInput.fromObject(d.documentsToAdd[i]);
                }
            }
            if (d.documentsToEdit) {
                if (!Array.isArray(d.documentsToEdit))
                    throw TypeError(".transport.EditOrderUploadedDocumentsInput.documentsToEdit: array expected");
                m.documentsToEdit = [];
                for (var i = 0; i < d.documentsToEdit.length; ++i) {
                    if (typeof d.documentsToEdit[i] !== "object")
                        throw TypeError(".transport.EditOrderUploadedDocumentsInput.documentsToEdit: object expected");
                    m.documentsToEdit[i] = $root.transport.EditOrderUploadedDocumentInput.fromObject(d.documentsToEdit[i]);
                }
            }
            if (d.documentsToDelete) {
                if (!Array.isArray(d.documentsToDelete))
                    throw TypeError(".transport.EditOrderUploadedDocumentsInput.documentsToDelete: array expected");
                m.documentsToDelete = [];
                for (var i = 0; i < d.documentsToDelete.length; ++i) {
                    m.documentsToDelete[i] = String(d.documentsToDelete[i]);
                }
            }
            if (d.orderId != null) {
                m.orderId = String(d.orderId);
            }
            return m;
        };

        EditOrderUploadedDocumentsInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.documentsToAdd = [];
                d.documentsToEdit = [];
                d.documentsToDelete = [];
            }
            if (o.defaults) {
                d.orderId = "";
            }
            if (m.documentsToAdd && m.documentsToAdd.length) {
                d.documentsToAdd = [];
                for (var j = 0; j < m.documentsToAdd.length; ++j) {
                    d.documentsToAdd[j] = $root.transport.AddOrderUploadedDocumentInput.toObject(m.documentsToAdd[j], o);
                }
            }
            if (m.documentsToEdit && m.documentsToEdit.length) {
                d.documentsToEdit = [];
                for (var j = 0; j < m.documentsToEdit.length; ++j) {
                    d.documentsToEdit[j] = $root.transport.EditOrderUploadedDocumentInput.toObject(m.documentsToEdit[j], o);
                }
            }
            if (m.documentsToDelete && m.documentsToDelete.length) {
                d.documentsToDelete = [];
                for (var j = 0; j < m.documentsToDelete.length; ++j) {
                    d.documentsToDelete[j] = m.documentsToDelete[j];
                }
            }
            if (m.orderId != null && m.hasOwnProperty("orderId")) {
                d.orderId = m.orderId;
            }
            return d;
        };

        EditOrderUploadedDocumentsInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EditOrderUploadedDocumentsInput;
    })();

    transport.AddOrderUploadedDocumentInput = (function() {

        function AddOrderUploadedDocumentInput(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        AddOrderUploadedDocumentInput.prototype.documentTypeId = "";
        AddOrderUploadedDocumentInput.prototype.uploadedFileId = "";
        AddOrderUploadedDocumentInput.prototype.signedFileId = "";
        AddOrderUploadedDocumentInput.prototype.waybillInfo = null;

        AddOrderUploadedDocumentInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.AddOrderUploadedDocumentInput)
                return d;
            var m = new $root.transport.AddOrderUploadedDocumentInput();
            if (d.documentTypeId != null) {
                m.documentTypeId = String(d.documentTypeId);
            }
            if (d.uploadedFileId != null) {
                m.uploadedFileId = String(d.uploadedFileId);
            }
            if (d.signedFileId != null) {
                m.signedFileId = String(d.signedFileId);
            }
            if (d.waybillInfo != null) {
                if (typeof d.waybillInfo !== "object")
                    throw TypeError(".transport.AddOrderUploadedDocumentInput.waybillInfo: object expected");
                m.waybillInfo = $root.transport.AddWaybillInput.fromObject(d.waybillInfo);
            }
            return m;
        };

        AddOrderUploadedDocumentInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.documentTypeId = "";
                d.uploadedFileId = "";
                d.signedFileId = "";
                d.waybillInfo = null;
            }
            if (m.documentTypeId != null && m.hasOwnProperty("documentTypeId")) {
                d.documentTypeId = m.documentTypeId;
            }
            if (m.uploadedFileId != null && m.hasOwnProperty("uploadedFileId")) {
                d.uploadedFileId = m.uploadedFileId;
            }
            if (m.signedFileId != null && m.hasOwnProperty("signedFileId")) {
                d.signedFileId = m.signedFileId;
            }
            if (m.waybillInfo != null && m.hasOwnProperty("waybillInfo")) {
                d.waybillInfo = $root.transport.AddWaybillInput.toObject(m.waybillInfo, o);
            }
            return d;
        };

        AddOrderUploadedDocumentInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddOrderUploadedDocumentInput;
    })();

    transport.EditOrderUploadedDocumentInput = (function() {

        function EditOrderUploadedDocumentInput(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        EditOrderUploadedDocumentInput.prototype.id = "";
        EditOrderUploadedDocumentInput.prototype.documentTypeId = "";
        EditOrderUploadedDocumentInput.prototype.waybillInfo = null;

        EditOrderUploadedDocumentInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.EditOrderUploadedDocumentInput)
                return d;
            var m = new $root.transport.EditOrderUploadedDocumentInput();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.documentTypeId != null) {
                m.documentTypeId = String(d.documentTypeId);
            }
            if (d.waybillInfo != null) {
                if (typeof d.waybillInfo !== "object")
                    throw TypeError(".transport.EditOrderUploadedDocumentInput.waybillInfo: object expected");
                m.waybillInfo = $root.transport.EditWaybillInput.fromObject(d.waybillInfo);
            }
            return m;
        };

        EditOrderUploadedDocumentInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.documentTypeId = "";
                d.waybillInfo = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.documentTypeId != null && m.hasOwnProperty("documentTypeId")) {
                d.documentTypeId = m.documentTypeId;
            }
            if (m.waybillInfo != null && m.hasOwnProperty("waybillInfo")) {
                d.waybillInfo = $root.transport.EditWaybillInput.toObject(m.waybillInfo, o);
            }
            return d;
        };

        EditOrderUploadedDocumentInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EditOrderUploadedDocumentInput;
    })();

    transport.WaybillInfoType = (function() {

        function WaybillInfoType(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        WaybillInfoType.prototype.dateFrom = "";
        WaybillInfoType.prototype.dateTo = "";
        WaybillInfoType.prototype.orderStoragePoint = null;

        WaybillInfoType.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.WaybillInfoType)
                return d;
            var m = new $root.transport.WaybillInfoType();
            if (d.dateFrom != null) {
                m.dateFrom = String(d.dateFrom);
            }
            if (d.dateTo != null) {
                m.dateTo = String(d.dateTo);
            }
            if (d.orderStoragePoint != null) {
                if (typeof d.orderStoragePoint !== "object")
                    throw TypeError(".transport.WaybillInfoType.orderStoragePoint: object expected");
                m.orderStoragePoint = $root.transport.Order.StoragePoint.fromObject(d.orderStoragePoint);
            }
            return m;
        };

        WaybillInfoType.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.dateFrom = "";
                d.dateTo = "";
                d.orderStoragePoint = null;
            }
            if (m.dateFrom != null && m.hasOwnProperty("dateFrom")) {
                d.dateFrom = m.dateFrom;
            }
            if (m.dateTo != null && m.hasOwnProperty("dateTo")) {
                d.dateTo = m.dateTo;
            }
            if (m.orderStoragePoint != null && m.hasOwnProperty("orderStoragePoint")) {
                d.orderStoragePoint = $root.transport.Order.StoragePoint.toObject(m.orderStoragePoint, o);
            }
            return d;
        };

        WaybillInfoType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WaybillInfoType;
    })();

    transport.AddWaybillInput = (function() {

        function AddWaybillInput(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        AddWaybillInput.prototype.dateFrom = "";
        AddWaybillInput.prototype.dateTo = "";
        AddWaybillInput.prototype.storagePointId = "";

        AddWaybillInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.AddWaybillInput)
                return d;
            var m = new $root.transport.AddWaybillInput();
            if (d.dateFrom != null) {
                m.dateFrom = String(d.dateFrom);
            }
            if (d.dateTo != null) {
                m.dateTo = String(d.dateTo);
            }
            if (d.storagePointId != null) {
                m.storagePointId = String(d.storagePointId);
            }
            return m;
        };

        AddWaybillInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.dateFrom = "";
                d.dateTo = "";
                d.storagePointId = "";
            }
            if (m.dateFrom != null && m.hasOwnProperty("dateFrom")) {
                d.dateFrom = m.dateFrom;
            }
            if (m.dateTo != null && m.hasOwnProperty("dateTo")) {
                d.dateTo = m.dateTo;
            }
            if (m.storagePointId != null && m.hasOwnProperty("storagePointId")) {
                d.storagePointId = m.storagePointId;
            }
            return d;
        };

        AddWaybillInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddWaybillInput;
    })();

    transport.EditWaybillInput = (function() {

        function EditWaybillInput(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        EditWaybillInput.prototype.dateFrom = "";
        EditWaybillInput.prototype.dateTo = "";
        EditWaybillInput.prototype.storagePointId = "";
        EditWaybillInput.prototype.uploadedFileId = "";

        EditWaybillInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.EditWaybillInput)
                return d;
            var m = new $root.transport.EditWaybillInput();
            if (d.dateFrom != null) {
                m.dateFrom = String(d.dateFrom);
            }
            if (d.dateTo != null) {
                m.dateTo = String(d.dateTo);
            }
            if (d.storagePointId != null) {
                m.storagePointId = String(d.storagePointId);
            }
            if (d.uploadedFileId != null) {
                m.uploadedFileId = String(d.uploadedFileId);
            }
            return m;
        };

        EditWaybillInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.dateFrom = "";
                d.dateTo = "";
                d.storagePointId = "";
                d.uploadedFileId = "";
            }
            if (m.dateFrom != null && m.hasOwnProperty("dateFrom")) {
                d.dateFrom = m.dateFrom;
            }
            if (m.dateTo != null && m.hasOwnProperty("dateTo")) {
                d.dateTo = m.dateTo;
            }
            if (m.storagePointId != null && m.hasOwnProperty("storagePointId")) {
                d.storagePointId = m.storagePointId;
            }
            if (m.uploadedFileId != null && m.hasOwnProperty("uploadedFileId")) {
                d.uploadedFileId = m.uploadedFileId;
            }
            return d;
        };

        EditWaybillInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EditWaybillInput;
    })();

    transport.OrderDocumentType = (function() {

        function OrderDocumentType(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        OrderDocumentType.prototype.id = "";
        OrderDocumentType.prototype.name = "";
        OrderDocumentType.prototype.isVisible = false;
        OrderDocumentType.prototype.innerName = "";

        OrderDocumentType.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.OrderDocumentType)
                return d;
            var m = new $root.transport.OrderDocumentType();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.isVisible != null) {
                m.isVisible = Boolean(d.isVisible);
            }
            if (d.innerName != null) {
                m.innerName = String(d.innerName);
            }
            return m;
        };

        OrderDocumentType.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.isVisible = false;
                d.innerName = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.isVisible != null && m.hasOwnProperty("isVisible")) {
                d.isVisible = m.isVisible;
            }
            if (m.innerName != null && m.hasOwnProperty("innerName")) {
                d.innerName = m.innerName;
            }
            return d;
        };

        OrderDocumentType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OrderDocumentType;
    })();

    transport.ContractInfo = (function() {

        function ContractInfo(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        ContractInfo.prototype.createdAt1c = "";
        ContractInfo.prototype.contractNumber1c = "";

        ContractInfo.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.ContractInfo)
                return d;
            var m = new $root.transport.ContractInfo();
            if (d.createdAt1c != null) {
                m.createdAt1c = String(d.createdAt1c);
            }
            if (d.contractNumber1c != null) {
                m.contractNumber1c = String(d.contractNumber1c);
            }
            return m;
        };

        ContractInfo.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.createdAt1c = "";
                d.contractNumber1c = "";
            }
            if (m.createdAt1c != null && m.hasOwnProperty("createdAt1c")) {
                d.createdAt1c = m.createdAt1c;
            }
            if (m.contractNumber1c != null && m.hasOwnProperty("contractNumber1c")) {
                d.contractNumber1c = m.contractNumber1c;
            }
            return d;
        };

        ContractInfo.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ContractInfo;
    })();

    transport.OrderUploadedDocument = (function() {

        function OrderUploadedDocument(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        OrderUploadedDocument.prototype.id = "";
        OrderUploadedDocument.prototype.documentType = null;
        OrderUploadedDocument.prototype.uploadedFile = null;
        OrderUploadedDocument.prototype.signedFile = null;
        OrderUploadedDocument.prototype.organization = null;
        OrderUploadedDocument.prototype.deletable = false;
        OrderUploadedDocument.prototype.waybillInfo = null;
        OrderUploadedDocument.prototype.contractInfo = null;

        OrderUploadedDocument.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.OrderUploadedDocument)
                return d;
            var m = new $root.transport.OrderUploadedDocument();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.documentType != null) {
                if (typeof d.documentType !== "object")
                    throw TypeError(".transport.OrderUploadedDocument.documentType: object expected");
                m.documentType = $root.transport.OrderDocumentType.fromObject(d.documentType);
            }
            if (d.uploadedFile != null) {
                if (typeof d.uploadedFile !== "object")
                    throw TypeError(".transport.OrderUploadedDocument.uploadedFile: object expected");
                m.uploadedFile = $root.transport.UploadedFile.fromObject(d.uploadedFile);
            }
            if (d.signedFile != null) {
                if (typeof d.signedFile !== "object")
                    throw TypeError(".transport.OrderUploadedDocument.signedFile: object expected");
                m.signedFile = $root.transport.OrderUploadedDocument.fromObject(d.signedFile);
            }
            if (d.organization != null) {
                if (typeof d.organization !== "object")
                    throw TypeError(".transport.OrderUploadedDocument.organization: object expected");
                m.organization = $root.transport.OrderUploadedDocument.Organization.fromObject(d.organization);
            }
            if (d.deletable != null) {
                m.deletable = Boolean(d.deletable);
            }
            if (d.waybillInfo != null) {
                if (typeof d.waybillInfo !== "object")
                    throw TypeError(".transport.OrderUploadedDocument.waybillInfo: object expected");
                m.waybillInfo = $root.transport.WaybillInfoType.fromObject(d.waybillInfo);
            }
            if (d.contractInfo != null) {
                if (typeof d.contractInfo !== "object")
                    throw TypeError(".transport.OrderUploadedDocument.contractInfo: object expected");
                m.contractInfo = $root.transport.ContractInfo.fromObject(d.contractInfo);
            }
            return m;
        };

        OrderUploadedDocument.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.documentType = null;
                d.uploadedFile = null;
                d.signedFile = null;
                d.organization = null;
                d.deletable = false;
                d.waybillInfo = null;
                d.contractInfo = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.documentType != null && m.hasOwnProperty("documentType")) {
                d.documentType = $root.transport.OrderDocumentType.toObject(m.documentType, o);
            }
            if (m.uploadedFile != null && m.hasOwnProperty("uploadedFile")) {
                d.uploadedFile = $root.transport.UploadedFile.toObject(m.uploadedFile, o);
            }
            if (m.signedFile != null && m.hasOwnProperty("signedFile")) {
                d.signedFile = $root.transport.OrderUploadedDocument.toObject(m.signedFile, o);
            }
            if (m.organization != null && m.hasOwnProperty("organization")) {
                d.organization = $root.transport.OrderUploadedDocument.Organization.toObject(m.organization, o);
            }
            if (m.deletable != null && m.hasOwnProperty("deletable")) {
                d.deletable = m.deletable;
            }
            if (m.waybillInfo != null && m.hasOwnProperty("waybillInfo")) {
                d.waybillInfo = $root.transport.WaybillInfoType.toObject(m.waybillInfo, o);
            }
            if (m.contractInfo != null && m.hasOwnProperty("contractInfo")) {
                d.contractInfo = $root.transport.ContractInfo.toObject(m.contractInfo, o);
            }
            return d;
        };

        OrderUploadedDocument.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        OrderUploadedDocument.Organization = (function() {

            function Organization(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Organization.prototype.role = "";

            Organization.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.OrderUploadedDocument.Organization)
                    return d;
                var m = new $root.transport.OrderUploadedDocument.Organization();
                if (d.role != null) {
                    m.role = String(d.role);
                }
                return m;
            };

            Organization.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.role = "";
                }
                if (m.role != null && m.hasOwnProperty("role")) {
                    d.role = m.role;
                }
                return d;
            };

            Organization.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Organization;
        })();

        return OrderUploadedDocument;
    })();

    transport.Order = (function() {

        function Order(p) {
            this.loadingPlaces = [];
            this.unloadingPlaces = [];
            this.uploadedDocuments = [];
            this.extraServices = [];
            this.settingsESignRequired = [];
            this.documentActualStatus = [];
            this.documentStatusHistory = [];
            this.cantBetReasons = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Order.prototype.id = "";
        Order.prototype.loadingPlace = null;
        Order.prototype.unloadingPlace = null;
        Order.prototype.loadingPlaces = $util.emptyArray;
        Order.prototype.unloadingPlaces = $util.emptyArray;
        Order.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Order.prototype.cargoPackagesCount = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Order.prototype.status = "FREE";
        Order.prototype.carrier = null;
        Order.prototype.cargoType = null;
        Order.prototype.loadingDate = "";
        Order.prototype.unloadingDate = "";
        Order.prototype.price = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Order.prototype.weight = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Order.prototype.comment = "";
        Order.prototype.fio = "";
        Order.prototype.email = "";
        Order.prototype.phoneNumber = "";
        Order.prototype.createdDatetime = null;
        Order.prototype.lastModifiedDatetime = null;
        Order.prototype.truckInfo = null;
        Order.prototype.trailerInfo = null;
        Order.prototype.driverInfo = null;
        Order.prototype.driver = null;
        Order.prototype.vehicle = null;
        Order.prototype.allocationType = "SIMPLE_ALLOCATION";
        Order.prototype.lot = null;
        Order.prototype.isVatIncluded = false;
        Order.prototype.vehicleRequirements = null;
        Order.prototype.cargoPlaceRequirements = null;
        Order.prototype.insurance = null;
        Order.prototype.hasInsurance = false;
        Order.prototype.owner = null;
        Order.prototype.priceWithVat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Order.prototype.priceWithoutVat = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Order.prototype.carrierGroup = null;
        Order.prototype.isMarket = false;
        Order.prototype.externalNo = "";
        Order.prototype.statusOnEvent = "";
        Order.prototype.paymentPeriod = "";
        Order.prototype.paymentType = null;
        Order.prototype.uploadedDocuments = $util.emptyArray;
        Order.prototype.canModifyUploadedDocuments = false;
        Order.prototype.endDatetime = "";
        Order.prototype.secondsToLifeTimeExpired = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Order.prototype.extraServices = $util.emptyArray;
        Order.prototype.settingsESignRequired = $util.emptyArray;
        Order.prototype.contractAttachedDatetime = null;
        Order.prototype.customerOrganization = null;
        Order.prototype.routeSheet = null;
        Order.prototype.signingWay = "";
        Order.prototype.documentActualStatus = $util.emptyArray;
        Order.prototype.documentStatusHistory = $util.emptyArray;
        Order.prototype.canUnreserveTransport = false;
        Order.prototype.temperatureMode = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Order.prototype.isReadonly = false;
        Order.prototype.isInsuranceRequired = false;
        Order.prototype.ownerInsuranceContract = null;
        Order.prototype.orderAttributes = null;
        Order.prototype.coDriver = null;
        Order.prototype.secondsToReservationTimeExpired = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Order.prototype.vehicleTrailer = null;
        Order.prototype.hasWaybillInfo = false;
        Order.prototype.waybillRequired = false;
        Order.prototype.actualCarrierOrganization = null;
        Order.prototype.agentUsesOwnVehicle = false;
        Order.prototype.agentFeeAndFreightDetails = null;
        Order.prototype.viewEndDatetime = "";
        Order.prototype.carrierContract = null;
        Order.prototype.isShipmentOnAgreedDate = false;
        Order.prototype.cargoNomenclatureTypes = "";
        Order.prototype.timeOrderReservation = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Order.prototype.viewProcessDeadline = "";
        Order.prototype.isPreviouslyAssigned = false;
        Order.prototype.gracePeriod = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Order.prototype.biddinglot = null;
        Order.prototype.myOrganizationBet = null;
        Order.prototype.assignedOrder = null;
        Order.prototype.biddingStatusForCarrier = null;
        Order.prototype.tender = null;
        Order.prototype.sourceOrder = null;
        Order.prototype.canChangeContract = false;
        Order.prototype.contractPrice = "";
        Order.prototype.cantBetReasons = $util.emptyArray;
        Order.prototype.diy = false;

        let $oneOfFields;

        Object.defineProperty(Order.prototype, "_carrier", {
            get: $util.oneOfGetter($oneOfFields = ["carrier"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Order.prototype, "_createdDatetime", {
            get: $util.oneOfGetter($oneOfFields = ["createdDatetime"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Order.prototype, "_lastModifiedDatetime", {
            get: $util.oneOfGetter($oneOfFields = ["lastModifiedDatetime"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Order.prototype, "_truckInfo", {
            get: $util.oneOfGetter($oneOfFields = ["truckInfo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Order.prototype, "_trailerInfo", {
            get: $util.oneOfGetter($oneOfFields = ["trailerInfo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Order.prototype, "_driverInfo", {
            get: $util.oneOfGetter($oneOfFields = ["driverInfo"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Order.prototype, "_lot", {
            get: $util.oneOfGetter($oneOfFields = ["lot"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Order.prototype, "_insurance", {
            get: $util.oneOfGetter($oneOfFields = ["insurance"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Order.prototype, "_carrierGroup", {
            get: $util.oneOfGetter($oneOfFields = ["carrierGroup"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Order.prototype, "_contractAttachedDatetime", {
            get: $util.oneOfGetter($oneOfFields = ["contractAttachedDatetime"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Order.prototype, "_customerOrganization", {
            get: $util.oneOfGetter($oneOfFields = ["customerOrganization"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Order.prototype, "_routeSheet", {
            get: $util.oneOfGetter($oneOfFields = ["routeSheet"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Order.prototype, "_biddinglot", {
            get: $util.oneOfGetter($oneOfFields = ["biddinglot"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Order.prototype, "_myOrganizationBet", {
            get: $util.oneOfGetter($oneOfFields = ["myOrganizationBet"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Order.prototype, "_assignedOrder", {
            get: $util.oneOfGetter($oneOfFields = ["assignedOrder"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Order.prototype, "_biddingStatusForCarrier", {
            get: $util.oneOfGetter($oneOfFields = ["biddingStatusForCarrier"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Order.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.Order)
                return d;
            var m = new $root.transport.Order();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.loadingPlace != null) {
                if (typeof d.loadingPlace !== "object")
                    throw TypeError(".transport.Order.loadingPlace: object expected");
                m.loadingPlace = $root.transport.Place.fromObject(d.loadingPlace);
            }
            if (d.unloadingPlace != null) {
                if (typeof d.unloadingPlace !== "object")
                    throw TypeError(".transport.Order.unloadingPlace: object expected");
                m.unloadingPlace = $root.transport.Place.fromObject(d.unloadingPlace);
            }
            if (d.loadingPlaces) {
                if (!Array.isArray(d.loadingPlaces))
                    throw TypeError(".transport.Order.loadingPlaces: array expected");
                m.loadingPlaces = [];
                for (var i = 0; i < d.loadingPlaces.length; ++i) {
                    if (typeof d.loadingPlaces[i] !== "object")
                        throw TypeError(".transport.Order.loadingPlaces: object expected");
                    m.loadingPlaces[i] = $root.transport.Order.StoragePoint.fromObject(d.loadingPlaces[i]);
                }
            }
            if (d.unloadingPlaces) {
                if (!Array.isArray(d.unloadingPlaces))
                    throw TypeError(".transport.Order.unloadingPlaces: array expected");
                m.unloadingPlaces = [];
                for (var i = 0; i < d.unloadingPlaces.length; ++i) {
                    if (typeof d.unloadingPlaces[i] !== "object")
                        throw TypeError(".transport.Order.unloadingPlaces: object expected");
                    m.unloadingPlaces[i] = $root.transport.Order.StoragePoint.fromObject(d.unloadingPlaces[i]);
                }
            }
            if (d.volume != null) {
                if ($util.Long)
                    (m.volume = $util.Long.fromValue(d.volume)).unsigned = false;
                else if (typeof d.volume === "string")
                    m.volume = parseInt(d.volume, 10);
                else if (typeof d.volume === "number")
                    m.volume = d.volume;
                else if (typeof d.volume === "object")
                    m.volume = new $util.LongBits(d.volume.low >>> 0, d.volume.high >>> 0).toNumber();
            }
            if (d.cargoPackagesCount != null) {
                if ($util.Long)
                    (m.cargoPackagesCount = $util.Long.fromValue(d.cargoPackagesCount)).unsigned = false;
                else if (typeof d.cargoPackagesCount === "string")
                    m.cargoPackagesCount = parseInt(d.cargoPackagesCount, 10);
                else if (typeof d.cargoPackagesCount === "number")
                    m.cargoPackagesCount = d.cargoPackagesCount;
                else if (typeof d.cargoPackagesCount === "object")
                    m.cargoPackagesCount = new $util.LongBits(d.cargoPackagesCount.low >>> 0, d.cargoPackagesCount.high >>> 0).toNumber();
            }
            if (d.status != null) {
                m.status = String(d.status);
            }
            if (d.carrier != null) {
                if (typeof d.carrier !== "object")
                    throw TypeError(".transport.Order.carrier: object expected");
                m.carrier = $root.transport.Carrier.fromObject(d.carrier);
            }
            if (d.cargoType != null) {
                if (typeof d.cargoType !== "object")
                    throw TypeError(".transport.Order.cargoType: object expected");
                m.cargoType = $root.transport.CargoType.fromObject(d.cargoType);
            }
            if (d.loadingDate != null) {
                m.loadingDate = String(d.loadingDate);
            }
            if (d.unloadingDate != null) {
                m.unloadingDate = String(d.unloadingDate);
            }
            if (d.price != null) {
                if ($util.Long)
                    (m.price = $util.Long.fromValue(d.price)).unsigned = false;
                else if (typeof d.price === "string")
                    m.price = parseInt(d.price, 10);
                else if (typeof d.price === "number")
                    m.price = d.price;
                else if (typeof d.price === "object")
                    m.price = new $util.LongBits(d.price.low >>> 0, d.price.high >>> 0).toNumber();
            }
            if (d.weight != null) {
                if ($util.Long)
                    (m.weight = $util.Long.fromValue(d.weight)).unsigned = false;
                else if (typeof d.weight === "string")
                    m.weight = parseInt(d.weight, 10);
                else if (typeof d.weight === "number")
                    m.weight = d.weight;
                else if (typeof d.weight === "object")
                    m.weight = new $util.LongBits(d.weight.low >>> 0, d.weight.high >>> 0).toNumber();
            }
            if (d.comment != null) {
                m.comment = String(d.comment);
            }
            if (d.fio != null) {
                m.fio = String(d.fio);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            if (d.phoneNumber != null) {
                m.phoneNumber = String(d.phoneNumber);
            }
            if (d.createdDatetime != null) {
                m.createdDatetime = String(d.createdDatetime);
            }
            if (d.lastModifiedDatetime != null) {
                m.lastModifiedDatetime = String(d.lastModifiedDatetime);
            }
            if (d.truckInfo != null) {
                m.truckInfo = String(d.truckInfo);
            }
            if (d.trailerInfo != null) {
                m.trailerInfo = String(d.trailerInfo);
            }
            if (d.driverInfo != null) {
                m.driverInfo = String(d.driverInfo);
            }
            if (d.driver != null) {
                if (typeof d.driver !== "object")
                    throw TypeError(".transport.Order.driver: object expected");
                m.driver = $root.transport.Driver.fromObject(d.driver);
            }
            if (d.vehicle != null) {
                if (typeof d.vehicle !== "object")
                    throw TypeError(".transport.Order.vehicle: object expected");
                m.vehicle = $root.transport.Vehicle.fromObject(d.vehicle);
            }
            if (d.allocationType != null) {
                m.allocationType = String(d.allocationType);
            }
            if (d.lot != null) {
                if (typeof d.lot !== "object")
                    throw TypeError(".transport.Order.lot: object expected");
                m.lot = $root.transport.Lot.fromObject(d.lot);
            }
            if (d.isVatIncluded != null) {
                m.isVatIncluded = Boolean(d.isVatIncluded);
            }
            if (d.vehicleRequirements != null) {
                if (typeof d.vehicleRequirements !== "object")
                    throw TypeError(".transport.Order.vehicleRequirements: object expected");
                m.vehicleRequirements = $root.transport.Order.VehicleRequirements.fromObject(d.vehicleRequirements);
            }
            if (d.cargoPlaceRequirements != null) {
                if (typeof d.cargoPlaceRequirements !== "object")
                    throw TypeError(".transport.Order.cargoPlaceRequirements: object expected");
                m.cargoPlaceRequirements = $root.transport.Order.PlaceRequirements.fromObject(d.cargoPlaceRequirements);
            }
            if (d.insurance != null) {
                if (typeof d.insurance !== "object")
                    throw TypeError(".transport.Order.insurance: object expected");
                m.insurance = $root.transport.Insurance.fromObject(d.insurance);
            }
            if (d.hasInsurance != null) {
                m.hasInsurance = Boolean(d.hasInsurance);
            }
            if (d.owner != null) {
                if (typeof d.owner !== "object")
                    throw TypeError(".transport.Order.owner: object expected");
                m.owner = $root.transport.Order.Owner.fromObject(d.owner);
            }
            if (d.priceWithVat != null) {
                if ($util.Long)
                    (m.priceWithVat = $util.Long.fromValue(d.priceWithVat)).unsigned = false;
                else if (typeof d.priceWithVat === "string")
                    m.priceWithVat = parseInt(d.priceWithVat, 10);
                else if (typeof d.priceWithVat === "number")
                    m.priceWithVat = d.priceWithVat;
                else if (typeof d.priceWithVat === "object")
                    m.priceWithVat = new $util.LongBits(d.priceWithVat.low >>> 0, d.priceWithVat.high >>> 0).toNumber();
            }
            if (d.priceWithoutVat != null) {
                if ($util.Long)
                    (m.priceWithoutVat = $util.Long.fromValue(d.priceWithoutVat)).unsigned = false;
                else if (typeof d.priceWithoutVat === "string")
                    m.priceWithoutVat = parseInt(d.priceWithoutVat, 10);
                else if (typeof d.priceWithoutVat === "number")
                    m.priceWithoutVat = d.priceWithoutVat;
                else if (typeof d.priceWithoutVat === "object")
                    m.priceWithoutVat = new $util.LongBits(d.priceWithoutVat.low >>> 0, d.priceWithoutVat.high >>> 0).toNumber();
            }
            if (d.carrierGroup != null) {
                if (typeof d.carrierGroup !== "object")
                    throw TypeError(".transport.Order.carrierGroup: object expected");
                m.carrierGroup = $root.transport.Carrier.CarrierGroup.fromObject(d.carrierGroup);
            }
            if (d.isMarket != null) {
                m.isMarket = Boolean(d.isMarket);
            }
            if (d.externalNo != null) {
                m.externalNo = String(d.externalNo);
            }
            if (d.statusOnEvent != null) {
                m.statusOnEvent = String(d.statusOnEvent);
            }
            if (d.paymentPeriod != null) {
                m.paymentPeriod = String(d.paymentPeriod);
            }
            if (d.paymentType != null) {
                if (typeof d.paymentType !== "object")
                    throw TypeError(".transport.Order.paymentType: object expected");
                m.paymentType = $root.transport.PaymentType.fromObject(d.paymentType);
            }
            if (d.uploadedDocuments) {
                if (!Array.isArray(d.uploadedDocuments))
                    throw TypeError(".transport.Order.uploadedDocuments: array expected");
                m.uploadedDocuments = [];
                for (var i = 0; i < d.uploadedDocuments.length; ++i) {
                    if (typeof d.uploadedDocuments[i] !== "object")
                        throw TypeError(".transport.Order.uploadedDocuments: object expected");
                    m.uploadedDocuments[i] = $root.transport.OrderUploadedDocument.fromObject(d.uploadedDocuments[i]);
                }
            }
            if (d.canModifyUploadedDocuments != null) {
                m.canModifyUploadedDocuments = Boolean(d.canModifyUploadedDocuments);
            }
            if (d.endDatetime != null) {
                m.endDatetime = String(d.endDatetime);
            }
            if (d.secondsToLifeTimeExpired != null) {
                if ($util.Long)
                    (m.secondsToLifeTimeExpired = $util.Long.fromValue(d.secondsToLifeTimeExpired)).unsigned = false;
                else if (typeof d.secondsToLifeTimeExpired === "string")
                    m.secondsToLifeTimeExpired = parseInt(d.secondsToLifeTimeExpired, 10);
                else if (typeof d.secondsToLifeTimeExpired === "number")
                    m.secondsToLifeTimeExpired = d.secondsToLifeTimeExpired;
                else if (typeof d.secondsToLifeTimeExpired === "object")
                    m.secondsToLifeTimeExpired = new $util.LongBits(d.secondsToLifeTimeExpired.low >>> 0, d.secondsToLifeTimeExpired.high >>> 0).toNumber();
            }
            if (d.extraServices) {
                if (!Array.isArray(d.extraServices))
                    throw TypeError(".transport.Order.extraServices: array expected");
                m.extraServices = [];
                for (var i = 0; i < d.extraServices.length; ++i) {
                    if (typeof d.extraServices[i] !== "object")
                        throw TypeError(".transport.Order.extraServices: object expected");
                    m.extraServices[i] = $root.transport.ExtraService.fromObject(d.extraServices[i]);
                }
            }
            if (d.settingsESignRequired) {
                if (!Array.isArray(d.settingsESignRequired))
                    throw TypeError(".transport.Order.settingsESignRequired: array expected");
                m.settingsESignRequired = [];
                for (var i = 0; i < d.settingsESignRequired.length; ++i) {
                    if (typeof d.settingsESignRequired[i] !== "object")
                        throw TypeError(".transport.Order.settingsESignRequired: object expected");
                    m.settingsESignRequired[i] = $root.transport.OrderDocumentType.fromObject(d.settingsESignRequired[i]);
                }
            }
            if (d.contractAttachedDatetime != null) {
                m.contractAttachedDatetime = String(d.contractAttachedDatetime);
            }
            if (d.customerOrganization != null) {
                if (typeof d.customerOrganization !== "object")
                    throw TypeError(".transport.Order.customerOrganization: object expected");
                m.customerOrganization = $root.transport.AdminOrganization.fromObject(d.customerOrganization);
            }
            if (d.routeSheet != null) {
                if (typeof d.routeSheet !== "object")
                    throw TypeError(".transport.Order.routeSheet: object expected");
                m.routeSheet = $root.transport.RouteSheet.fromObject(d.routeSheet);
            }
            if (d.signingWay != null) {
                m.signingWay = String(d.signingWay);
            }
            if (d.documentActualStatus) {
                if (!Array.isArray(d.documentActualStatus))
                    throw TypeError(".transport.Order.documentActualStatus: array expected");
                m.documentActualStatus = [];
                for (var i = 0; i < d.documentActualStatus.length; ++i) {
                    if (typeof d.documentActualStatus[i] !== "object")
                        throw TypeError(".transport.Order.documentActualStatus: object expected");
                    m.documentActualStatus[i] = $root.transport.Order.DocumentStatus.fromObject(d.documentActualStatus[i]);
                }
            }
            if (d.documentStatusHistory) {
                if (!Array.isArray(d.documentStatusHistory))
                    throw TypeError(".transport.Order.documentStatusHistory: array expected");
                m.documentStatusHistory = [];
                for (var i = 0; i < d.documentStatusHistory.length; ++i) {
                    if (typeof d.documentStatusHistory[i] !== "object")
                        throw TypeError(".transport.Order.documentStatusHistory: object expected");
                    m.documentStatusHistory[i] = $root.transport.Order.DocumentStatus.fromObject(d.documentStatusHistory[i]);
                }
            }
            if (d.canUnreserveTransport != null) {
                m.canUnreserveTransport = Boolean(d.canUnreserveTransport);
            }
            if (d.temperatureMode != null) {
                if ($util.Long)
                    (m.temperatureMode = $util.Long.fromValue(d.temperatureMode)).unsigned = false;
                else if (typeof d.temperatureMode === "string")
                    m.temperatureMode = parseInt(d.temperatureMode, 10);
                else if (typeof d.temperatureMode === "number")
                    m.temperatureMode = d.temperatureMode;
                else if (typeof d.temperatureMode === "object")
                    m.temperatureMode = new $util.LongBits(d.temperatureMode.low >>> 0, d.temperatureMode.high >>> 0).toNumber();
            }
            if (d.isReadonly != null) {
                m.isReadonly = Boolean(d.isReadonly);
            }
            if (d.isInsuranceRequired != null) {
                m.isInsuranceRequired = Boolean(d.isInsuranceRequired);
            }
            if (d.ownerInsuranceContract != null) {
                if (typeof d.ownerInsuranceContract !== "object")
                    throw TypeError(".transport.Order.ownerInsuranceContract: object expected");
                m.ownerInsuranceContract = $root.transport.Order.InsuranceContract.fromObject(d.ownerInsuranceContract);
            }
            if (d.orderAttributes != null) {
                if (typeof d.orderAttributes !== "object")
                    throw TypeError(".transport.Order.orderAttributes: object expected");
                m.orderAttributes = $root.transport.Order.OrderAttributesType.fromObject(d.orderAttributes);
            }
            if (d.coDriver != null) {
                if (typeof d.coDriver !== "object")
                    throw TypeError(".transport.Order.coDriver: object expected");
                m.coDriver = $root.transport.Driver.fromObject(d.coDriver);
            }
            if (d.secondsToReservationTimeExpired != null) {
                if ($util.Long)
                    (m.secondsToReservationTimeExpired = $util.Long.fromValue(d.secondsToReservationTimeExpired)).unsigned = false;
                else if (typeof d.secondsToReservationTimeExpired === "string")
                    m.secondsToReservationTimeExpired = parseInt(d.secondsToReservationTimeExpired, 10);
                else if (typeof d.secondsToReservationTimeExpired === "number")
                    m.secondsToReservationTimeExpired = d.secondsToReservationTimeExpired;
                else if (typeof d.secondsToReservationTimeExpired === "object")
                    m.secondsToReservationTimeExpired = new $util.LongBits(d.secondsToReservationTimeExpired.low >>> 0, d.secondsToReservationTimeExpired.high >>> 0).toNumber();
            }
            if (d.vehicleTrailer != null) {
                if (typeof d.vehicleTrailer !== "object")
                    throw TypeError(".transport.Order.vehicleTrailer: object expected");
                m.vehicleTrailer = $root.transport.VehicleTrailer.fromObject(d.vehicleTrailer);
            }
            if (d.hasWaybillInfo != null) {
                m.hasWaybillInfo = Boolean(d.hasWaybillInfo);
            }
            if (d.waybillRequired != null) {
                m.waybillRequired = Boolean(d.waybillRequired);
            }
            if (d.actualCarrierOrganization != null) {
                if (typeof d.actualCarrierOrganization !== "object")
                    throw TypeError(".transport.Order.actualCarrierOrganization: object expected");
                m.actualCarrierOrganization = $root.transport.AgentCarrier.fromObject(d.actualCarrierOrganization);
            }
            if (d.agentUsesOwnVehicle != null) {
                m.agentUsesOwnVehicle = Boolean(d.agentUsesOwnVehicle);
            }
            if (d.agentFeeAndFreightDetails != null) {
                if (typeof d.agentFeeAndFreightDetails !== "object")
                    throw TypeError(".transport.Order.agentFeeAndFreightDetails: object expected");
                m.agentFeeAndFreightDetails = $root.transport.FreightDetails.fromObject(d.agentFeeAndFreightDetails);
            }
            if (d.viewEndDatetime != null) {
                m.viewEndDatetime = String(d.viewEndDatetime);
            }
            if (d.carrierContract != null) {
                if (typeof d.carrierContract !== "object")
                    throw TypeError(".transport.Order.carrierContract: object expected");
                m.carrierContract = $root.transport.Order.CarrierContract.fromObject(d.carrierContract);
            }
            if (d.isShipmentOnAgreedDate != null) {
                m.isShipmentOnAgreedDate = Boolean(d.isShipmentOnAgreedDate);
            }
            if (d.cargoNomenclatureTypes != null) {
                m.cargoNomenclatureTypes = String(d.cargoNomenclatureTypes);
            }
            if (d.timeOrderReservation != null) {
                if ($util.Long)
                    (m.timeOrderReservation = $util.Long.fromValue(d.timeOrderReservation)).unsigned = false;
                else if (typeof d.timeOrderReservation === "string")
                    m.timeOrderReservation = parseInt(d.timeOrderReservation, 10);
                else if (typeof d.timeOrderReservation === "number")
                    m.timeOrderReservation = d.timeOrderReservation;
                else if (typeof d.timeOrderReservation === "object")
                    m.timeOrderReservation = new $util.LongBits(d.timeOrderReservation.low >>> 0, d.timeOrderReservation.high >>> 0).toNumber();
            }
            if (d.viewProcessDeadline != null) {
                m.viewProcessDeadline = String(d.viewProcessDeadline);
            }
            if (d.isPreviouslyAssigned != null) {
                m.isPreviouslyAssigned = Boolean(d.isPreviouslyAssigned);
            }
            if (d.gracePeriod != null) {
                if ($util.Long)
                    (m.gracePeriod = $util.Long.fromValue(d.gracePeriod)).unsigned = false;
                else if (typeof d.gracePeriod === "string")
                    m.gracePeriod = parseInt(d.gracePeriod, 10);
                else if (typeof d.gracePeriod === "number")
                    m.gracePeriod = d.gracePeriod;
                else if (typeof d.gracePeriod === "object")
                    m.gracePeriod = new $util.LongBits(d.gracePeriod.low >>> 0, d.gracePeriod.high >>> 0).toNumber();
            }
            if (d.biddinglot != null) {
                if (typeof d.biddinglot !== "object")
                    throw TypeError(".transport.Order.biddinglot: object expected");
                m.biddinglot = $root.transport.BiddingLot.fromObject(d.biddinglot);
            }
            if (d.myOrganizationBet != null) {
                if ($util.Long)
                    (m.myOrganizationBet = $util.Long.fromValue(d.myOrganizationBet)).unsigned = false;
                else if (typeof d.myOrganizationBet === "string")
                    m.myOrganizationBet = parseInt(d.myOrganizationBet, 10);
                else if (typeof d.myOrganizationBet === "number")
                    m.myOrganizationBet = d.myOrganizationBet;
                else if (typeof d.myOrganizationBet === "object")
                    m.myOrganizationBet = new $util.LongBits(d.myOrganizationBet.low >>> 0, d.myOrganizationBet.high >>> 0).toNumber();
            }
            if (d.assignedOrder != null) {
                if (typeof d.assignedOrder !== "object")
                    throw TypeError(".transport.Order.assignedOrder: object expected");
                m.assignedOrder = $root.transport.Order.fromObject(d.assignedOrder);
            }
            if (d.biddingStatusForCarrier != null) {
                m.biddingStatusForCarrier = String(d.biddingStatusForCarrier);
            }
            if (d.tender != null) {
                if (typeof d.tender !== "object")
                    throw TypeError(".transport.Order.tender: object expected");
                m.tender = $root.transport.Tender.fromObject(d.tender);
            }
            if (d.sourceOrder != null) {
                if (typeof d.sourceOrder !== "object")
                    throw TypeError(".transport.Order.sourceOrder: object expected");
                m.sourceOrder = $root.transport.BiddingLot.fromObject(d.sourceOrder);
            }
            if (d.canChangeContract != null) {
                m.canChangeContract = Boolean(d.canChangeContract);
            }
            if (d.contractPrice != null) {
                m.contractPrice = String(d.contractPrice);
            }
            if (d.cantBetReasons) {
                if (!Array.isArray(d.cantBetReasons))
                    throw TypeError(".transport.Order.cantBetReasons: array expected");
                m.cantBetReasons = [];
                for (var i = 0; i < d.cantBetReasons.length; ++i) {
                    m.cantBetReasons[i] = String(d.cantBetReasons[i]);
                }
            }
            if (d.diy != null) {
                m.diy = Boolean(d.diy);
            }
            return m;
        };

        Order.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.loadingPlaces = [];
                d.unloadingPlaces = [];
                d.uploadedDocuments = [];
                d.extraServices = [];
                d.settingsESignRequired = [];
                d.documentActualStatus = [];
                d.documentStatusHistory = [];
                d.cantBetReasons = [];
            }
            if (o.defaults) {
                d.id = "";
                d.loadingPlace = null;
                d.unloadingPlace = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.volume = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.volume = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.cargoPackagesCount = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.cargoPackagesCount = o.longs === String ? "0" : 0;
                d.status = "FREE";
                d.cargoType = null;
                d.loadingDate = "";
                d.unloadingDate = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.price = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.price = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.weight = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.weight = o.longs === String ? "0" : 0;
                d.comment = "";
                d.fio = "";
                d.email = "";
                d.phoneNumber = "";
                d.driver = null;
                d.vehicle = null;
                d.allocationType = "SIMPLE_ALLOCATION";
                d.isVatIncluded = false;
                d.vehicleRequirements = null;
                d.cargoPlaceRequirements = null;
                d.hasInsurance = false;
                d.owner = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.priceWithVat = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.priceWithVat = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.priceWithoutVat = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.priceWithoutVat = o.longs === String ? "0" : 0;
                d.isMarket = false;
                d.externalNo = "";
                d.statusOnEvent = "";
                d.paymentPeriod = "";
                d.paymentType = null;
                d.canModifyUploadedDocuments = false;
                d.endDatetime = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.secondsToLifeTimeExpired = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.secondsToLifeTimeExpired = o.longs === String ? "0" : 0;
                d.signingWay = "";
                d.canUnreserveTransport = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.temperatureMode = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.temperatureMode = o.longs === String ? "0" : 0;
                d.isReadonly = false;
                d.isInsuranceRequired = false;
                d.ownerInsuranceContract = null;
                d.orderAttributes = null;
                d.coDriver = null;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.secondsToReservationTimeExpired = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.secondsToReservationTimeExpired = o.longs === String ? "0" : 0;
                d.vehicleTrailer = null;
                d.hasWaybillInfo = false;
                d.waybillRequired = false;
                d.actualCarrierOrganization = null;
                d.agentUsesOwnVehicle = false;
                d.agentFeeAndFreightDetails = null;
                d.viewEndDatetime = "";
                d.carrierContract = null;
                d.isShipmentOnAgreedDate = false;
                d.cargoNomenclatureTypes = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.timeOrderReservation = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.timeOrderReservation = o.longs === String ? "0" : 0;
                d.viewProcessDeadline = "";
                d.isPreviouslyAssigned = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.gracePeriod = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.gracePeriod = o.longs === String ? "0" : 0;
                d.tender = null;
                d.sourceOrder = null;
                d.canChangeContract = false;
                d.contractPrice = "";
                d.diy = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.loadingPlace != null && m.hasOwnProperty("loadingPlace")) {
                d.loadingPlace = $root.transport.Place.toObject(m.loadingPlace, o);
            }
            if (m.unloadingPlace != null && m.hasOwnProperty("unloadingPlace")) {
                d.unloadingPlace = $root.transport.Place.toObject(m.unloadingPlace, o);
            }
            if (m.loadingPlaces && m.loadingPlaces.length) {
                d.loadingPlaces = [];
                for (var j = 0; j < m.loadingPlaces.length; ++j) {
                    d.loadingPlaces[j] = $root.transport.Order.StoragePoint.toObject(m.loadingPlaces[j], o);
                }
            }
            if (m.unloadingPlaces && m.unloadingPlaces.length) {
                d.unloadingPlaces = [];
                for (var j = 0; j < m.unloadingPlaces.length; ++j) {
                    d.unloadingPlaces[j] = $root.transport.Order.StoragePoint.toObject(m.unloadingPlaces[j], o);
                }
            }
            if (m.volume != null && m.hasOwnProperty("volume")) {
                if (typeof m.volume === "number")
                    d.volume = o.longs === String ? String(m.volume) : m.volume;
                else
                    d.volume = o.longs === String ? $util.Long.prototype.toString.call(m.volume) : o.longs === Number ? new $util.LongBits(m.volume.low >>> 0, m.volume.high >>> 0).toNumber() : m.volume;
            }
            if (m.cargoPackagesCount != null && m.hasOwnProperty("cargoPackagesCount")) {
                if (typeof m.cargoPackagesCount === "number")
                    d.cargoPackagesCount = o.longs === String ? String(m.cargoPackagesCount) : m.cargoPackagesCount;
                else
                    d.cargoPackagesCount = o.longs === String ? $util.Long.prototype.toString.call(m.cargoPackagesCount) : o.longs === Number ? new $util.LongBits(m.cargoPackagesCount.low >>> 0, m.cargoPackagesCount.high >>> 0).toNumber() : m.cargoPackagesCount;
            }
            if (m.status != null && m.hasOwnProperty("status")) {
                d.status = m.status;
            }
            if (m.carrier != null && m.hasOwnProperty("carrier")) {
                d.carrier = $root.transport.Carrier.toObject(m.carrier, o);
                if (o.oneofs)
                    d._carrier = "carrier";
            }
            if (m.cargoType != null && m.hasOwnProperty("cargoType")) {
                d.cargoType = $root.transport.CargoType.toObject(m.cargoType, o);
            }
            if (m.loadingDate != null && m.hasOwnProperty("loadingDate")) {
                d.loadingDate = m.loadingDate;
            }
            if (m.unloadingDate != null && m.hasOwnProperty("unloadingDate")) {
                d.unloadingDate = m.unloadingDate;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                if (typeof m.price === "number")
                    d.price = o.longs === String ? String(m.price) : m.price;
                else
                    d.price = o.longs === String ? $util.Long.prototype.toString.call(m.price) : o.longs === Number ? new $util.LongBits(m.price.low >>> 0, m.price.high >>> 0).toNumber() : m.price;
            }
            if (m.weight != null && m.hasOwnProperty("weight")) {
                if (typeof m.weight === "number")
                    d.weight = o.longs === String ? String(m.weight) : m.weight;
                else
                    d.weight = o.longs === String ? $util.Long.prototype.toString.call(m.weight) : o.longs === Number ? new $util.LongBits(m.weight.low >>> 0, m.weight.high >>> 0).toNumber() : m.weight;
            }
            if (m.comment != null && m.hasOwnProperty("comment")) {
                d.comment = m.comment;
            }
            if (m.fio != null && m.hasOwnProperty("fio")) {
                d.fio = m.fio;
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
            }
            if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                d.phoneNumber = m.phoneNumber;
            }
            if (m.createdDatetime != null && m.hasOwnProperty("createdDatetime")) {
                d.createdDatetime = m.createdDatetime;
                if (o.oneofs)
                    d._createdDatetime = "createdDatetime";
            }
            if (m.lastModifiedDatetime != null && m.hasOwnProperty("lastModifiedDatetime")) {
                d.lastModifiedDatetime = m.lastModifiedDatetime;
                if (o.oneofs)
                    d._lastModifiedDatetime = "lastModifiedDatetime";
            }
            if (m.truckInfo != null && m.hasOwnProperty("truckInfo")) {
                d.truckInfo = m.truckInfo;
                if (o.oneofs)
                    d._truckInfo = "truckInfo";
            }
            if (m.trailerInfo != null && m.hasOwnProperty("trailerInfo")) {
                d.trailerInfo = m.trailerInfo;
                if (o.oneofs)
                    d._trailerInfo = "trailerInfo";
            }
            if (m.driverInfo != null && m.hasOwnProperty("driverInfo")) {
                d.driverInfo = m.driverInfo;
                if (o.oneofs)
                    d._driverInfo = "driverInfo";
            }
            if (m.driver != null && m.hasOwnProperty("driver")) {
                d.driver = $root.transport.Driver.toObject(m.driver, o);
            }
            if (m.vehicle != null && m.hasOwnProperty("vehicle")) {
                d.vehicle = $root.transport.Vehicle.toObject(m.vehicle, o);
            }
            if (m.allocationType != null && m.hasOwnProperty("allocationType")) {
                d.allocationType = m.allocationType;
            }
            if (m.lot != null && m.hasOwnProperty("lot")) {
                d.lot = $root.transport.Lot.toObject(m.lot, o);
                if (o.oneofs)
                    d._lot = "lot";
            }
            if (m.isVatIncluded != null && m.hasOwnProperty("isVatIncluded")) {
                d.isVatIncluded = m.isVatIncluded;
            }
            if (m.vehicleRequirements != null && m.hasOwnProperty("vehicleRequirements")) {
                d.vehicleRequirements = $root.transport.Order.VehicleRequirements.toObject(m.vehicleRequirements, o);
            }
            if (m.cargoPlaceRequirements != null && m.hasOwnProperty("cargoPlaceRequirements")) {
                d.cargoPlaceRequirements = $root.transport.Order.PlaceRequirements.toObject(m.cargoPlaceRequirements, o);
            }
            if (m.insurance != null && m.hasOwnProperty("insurance")) {
                d.insurance = $root.transport.Insurance.toObject(m.insurance, o);
                if (o.oneofs)
                    d._insurance = "insurance";
            }
            if (m.hasInsurance != null && m.hasOwnProperty("hasInsurance")) {
                d.hasInsurance = m.hasInsurance;
            }
            if (m.owner != null && m.hasOwnProperty("owner")) {
                d.owner = $root.transport.Order.Owner.toObject(m.owner, o);
            }
            if (m.priceWithVat != null && m.hasOwnProperty("priceWithVat")) {
                if (typeof m.priceWithVat === "number")
                    d.priceWithVat = o.longs === String ? String(m.priceWithVat) : m.priceWithVat;
                else
                    d.priceWithVat = o.longs === String ? $util.Long.prototype.toString.call(m.priceWithVat) : o.longs === Number ? new $util.LongBits(m.priceWithVat.low >>> 0, m.priceWithVat.high >>> 0).toNumber() : m.priceWithVat;
            }
            if (m.priceWithoutVat != null && m.hasOwnProperty("priceWithoutVat")) {
                if (typeof m.priceWithoutVat === "number")
                    d.priceWithoutVat = o.longs === String ? String(m.priceWithoutVat) : m.priceWithoutVat;
                else
                    d.priceWithoutVat = o.longs === String ? $util.Long.prototype.toString.call(m.priceWithoutVat) : o.longs === Number ? new $util.LongBits(m.priceWithoutVat.low >>> 0, m.priceWithoutVat.high >>> 0).toNumber() : m.priceWithoutVat;
            }
            if (m.carrierGroup != null && m.hasOwnProperty("carrierGroup")) {
                d.carrierGroup = $root.transport.Carrier.CarrierGroup.toObject(m.carrierGroup, o);
                if (o.oneofs)
                    d._carrierGroup = "carrierGroup";
            }
            if (m.isMarket != null && m.hasOwnProperty("isMarket")) {
                d.isMarket = m.isMarket;
            }
            if (m.externalNo != null && m.hasOwnProperty("externalNo")) {
                d.externalNo = m.externalNo;
            }
            if (m.statusOnEvent != null && m.hasOwnProperty("statusOnEvent")) {
                d.statusOnEvent = m.statusOnEvent;
            }
            if (m.paymentPeriod != null && m.hasOwnProperty("paymentPeriod")) {
                d.paymentPeriod = m.paymentPeriod;
            }
            if (m.paymentType != null && m.hasOwnProperty("paymentType")) {
                d.paymentType = $root.transport.PaymentType.toObject(m.paymentType, o);
            }
            if (m.uploadedDocuments && m.uploadedDocuments.length) {
                d.uploadedDocuments = [];
                for (var j = 0; j < m.uploadedDocuments.length; ++j) {
                    d.uploadedDocuments[j] = $root.transport.OrderUploadedDocument.toObject(m.uploadedDocuments[j], o);
                }
            }
            if (m.canModifyUploadedDocuments != null && m.hasOwnProperty("canModifyUploadedDocuments")) {
                d.canModifyUploadedDocuments = m.canModifyUploadedDocuments;
            }
            if (m.endDatetime != null && m.hasOwnProperty("endDatetime")) {
                d.endDatetime = m.endDatetime;
            }
            if (m.secondsToLifeTimeExpired != null && m.hasOwnProperty("secondsToLifeTimeExpired")) {
                if (typeof m.secondsToLifeTimeExpired === "number")
                    d.secondsToLifeTimeExpired = o.longs === String ? String(m.secondsToLifeTimeExpired) : m.secondsToLifeTimeExpired;
                else
                    d.secondsToLifeTimeExpired = o.longs === String ? $util.Long.prototype.toString.call(m.secondsToLifeTimeExpired) : o.longs === Number ? new $util.LongBits(m.secondsToLifeTimeExpired.low >>> 0, m.secondsToLifeTimeExpired.high >>> 0).toNumber() : m.secondsToLifeTimeExpired;
            }
            if (m.extraServices && m.extraServices.length) {
                d.extraServices = [];
                for (var j = 0; j < m.extraServices.length; ++j) {
                    d.extraServices[j] = $root.transport.ExtraService.toObject(m.extraServices[j], o);
                }
            }
            if (m.settingsESignRequired && m.settingsESignRequired.length) {
                d.settingsESignRequired = [];
                for (var j = 0; j < m.settingsESignRequired.length; ++j) {
                    d.settingsESignRequired[j] = $root.transport.OrderDocumentType.toObject(m.settingsESignRequired[j], o);
                }
            }
            if (m.contractAttachedDatetime != null && m.hasOwnProperty("contractAttachedDatetime")) {
                d.contractAttachedDatetime = m.contractAttachedDatetime;
                if (o.oneofs)
                    d._contractAttachedDatetime = "contractAttachedDatetime";
            }
            if (m.customerOrganization != null && m.hasOwnProperty("customerOrganization")) {
                d.customerOrganization = $root.transport.AdminOrganization.toObject(m.customerOrganization, o);
                if (o.oneofs)
                    d._customerOrganization = "customerOrganization";
            }
            if (m.routeSheet != null && m.hasOwnProperty("routeSheet")) {
                d.routeSheet = $root.transport.RouteSheet.toObject(m.routeSheet, o);
                if (o.oneofs)
                    d._routeSheet = "routeSheet";
            }
            if (m.signingWay != null && m.hasOwnProperty("signingWay")) {
                d.signingWay = m.signingWay;
            }
            if (m.documentActualStatus && m.documentActualStatus.length) {
                d.documentActualStatus = [];
                for (var j = 0; j < m.documentActualStatus.length; ++j) {
                    d.documentActualStatus[j] = $root.transport.Order.DocumentStatus.toObject(m.documentActualStatus[j], o);
                }
            }
            if (m.documentStatusHistory && m.documentStatusHistory.length) {
                d.documentStatusHistory = [];
                for (var j = 0; j < m.documentStatusHistory.length; ++j) {
                    d.documentStatusHistory[j] = $root.transport.Order.DocumentStatus.toObject(m.documentStatusHistory[j], o);
                }
            }
            if (m.canUnreserveTransport != null && m.hasOwnProperty("canUnreserveTransport")) {
                d.canUnreserveTransport = m.canUnreserveTransport;
            }
            if (m.temperatureMode != null && m.hasOwnProperty("temperatureMode")) {
                if (typeof m.temperatureMode === "number")
                    d.temperatureMode = o.longs === String ? String(m.temperatureMode) : m.temperatureMode;
                else
                    d.temperatureMode = o.longs === String ? $util.Long.prototype.toString.call(m.temperatureMode) : o.longs === Number ? new $util.LongBits(m.temperatureMode.low >>> 0, m.temperatureMode.high >>> 0).toNumber() : m.temperatureMode;
            }
            if (m.isReadonly != null && m.hasOwnProperty("isReadonly")) {
                d.isReadonly = m.isReadonly;
            }
            if (m.isInsuranceRequired != null && m.hasOwnProperty("isInsuranceRequired")) {
                d.isInsuranceRequired = m.isInsuranceRequired;
            }
            if (m.ownerInsuranceContract != null && m.hasOwnProperty("ownerInsuranceContract")) {
                d.ownerInsuranceContract = $root.transport.Order.InsuranceContract.toObject(m.ownerInsuranceContract, o);
            }
            if (m.orderAttributes != null && m.hasOwnProperty("orderAttributes")) {
                d.orderAttributes = $root.transport.Order.OrderAttributesType.toObject(m.orderAttributes, o);
            }
            if (m.coDriver != null && m.hasOwnProperty("coDriver")) {
                d.coDriver = $root.transport.Driver.toObject(m.coDriver, o);
            }
            if (m.secondsToReservationTimeExpired != null && m.hasOwnProperty("secondsToReservationTimeExpired")) {
                if (typeof m.secondsToReservationTimeExpired === "number")
                    d.secondsToReservationTimeExpired = o.longs === String ? String(m.secondsToReservationTimeExpired) : m.secondsToReservationTimeExpired;
                else
                    d.secondsToReservationTimeExpired = o.longs === String ? $util.Long.prototype.toString.call(m.secondsToReservationTimeExpired) : o.longs === Number ? new $util.LongBits(m.secondsToReservationTimeExpired.low >>> 0, m.secondsToReservationTimeExpired.high >>> 0).toNumber() : m.secondsToReservationTimeExpired;
            }
            if (m.vehicleTrailer != null && m.hasOwnProperty("vehicleTrailer")) {
                d.vehicleTrailer = $root.transport.VehicleTrailer.toObject(m.vehicleTrailer, o);
            }
            if (m.hasWaybillInfo != null && m.hasOwnProperty("hasWaybillInfo")) {
                d.hasWaybillInfo = m.hasWaybillInfo;
            }
            if (m.waybillRequired != null && m.hasOwnProperty("waybillRequired")) {
                d.waybillRequired = m.waybillRequired;
            }
            if (m.actualCarrierOrganization != null && m.hasOwnProperty("actualCarrierOrganization")) {
                d.actualCarrierOrganization = $root.transport.AgentCarrier.toObject(m.actualCarrierOrganization, o);
            }
            if (m.agentUsesOwnVehicle != null && m.hasOwnProperty("agentUsesOwnVehicle")) {
                d.agentUsesOwnVehicle = m.agentUsesOwnVehicle;
            }
            if (m.agentFeeAndFreightDetails != null && m.hasOwnProperty("agentFeeAndFreightDetails")) {
                d.agentFeeAndFreightDetails = $root.transport.FreightDetails.toObject(m.agentFeeAndFreightDetails, o);
            }
            if (m.viewEndDatetime != null && m.hasOwnProperty("viewEndDatetime")) {
                d.viewEndDatetime = m.viewEndDatetime;
            }
            if (m.carrierContract != null && m.hasOwnProperty("carrierContract")) {
                d.carrierContract = $root.transport.Order.CarrierContract.toObject(m.carrierContract, o);
            }
            if (m.isShipmentOnAgreedDate != null && m.hasOwnProperty("isShipmentOnAgreedDate")) {
                d.isShipmentOnAgreedDate = m.isShipmentOnAgreedDate;
            }
            if (m.cargoNomenclatureTypes != null && m.hasOwnProperty("cargoNomenclatureTypes")) {
                d.cargoNomenclatureTypes = m.cargoNomenclatureTypes;
            }
            if (m.timeOrderReservation != null && m.hasOwnProperty("timeOrderReservation")) {
                if (typeof m.timeOrderReservation === "number")
                    d.timeOrderReservation = o.longs === String ? String(m.timeOrderReservation) : m.timeOrderReservation;
                else
                    d.timeOrderReservation = o.longs === String ? $util.Long.prototype.toString.call(m.timeOrderReservation) : o.longs === Number ? new $util.LongBits(m.timeOrderReservation.low >>> 0, m.timeOrderReservation.high >>> 0).toNumber() : m.timeOrderReservation;
            }
            if (m.viewProcessDeadline != null && m.hasOwnProperty("viewProcessDeadline")) {
                d.viewProcessDeadline = m.viewProcessDeadline;
            }
            if (m.isPreviouslyAssigned != null && m.hasOwnProperty("isPreviouslyAssigned")) {
                d.isPreviouslyAssigned = m.isPreviouslyAssigned;
            }
            if (m.gracePeriod != null && m.hasOwnProperty("gracePeriod")) {
                if (typeof m.gracePeriod === "number")
                    d.gracePeriod = o.longs === String ? String(m.gracePeriod) : m.gracePeriod;
                else
                    d.gracePeriod = o.longs === String ? $util.Long.prototype.toString.call(m.gracePeriod) : o.longs === Number ? new $util.LongBits(m.gracePeriod.low >>> 0, m.gracePeriod.high >>> 0).toNumber() : m.gracePeriod;
            }
            if (m.biddinglot != null && m.hasOwnProperty("biddinglot")) {
                d.biddinglot = $root.transport.BiddingLot.toObject(m.biddinglot, o);
                if (o.oneofs)
                    d._biddinglot = "biddinglot";
            }
            if (m.myOrganizationBet != null && m.hasOwnProperty("myOrganizationBet")) {
                if (typeof m.myOrganizationBet === "number")
                    d.myOrganizationBet = o.longs === String ? String(m.myOrganizationBet) : m.myOrganizationBet;
                else
                    d.myOrganizationBet = o.longs === String ? $util.Long.prototype.toString.call(m.myOrganizationBet) : o.longs === Number ? new $util.LongBits(m.myOrganizationBet.low >>> 0, m.myOrganizationBet.high >>> 0).toNumber() : m.myOrganizationBet;
                if (o.oneofs)
                    d._myOrganizationBet = "myOrganizationBet";
            }
            if (m.assignedOrder != null && m.hasOwnProperty("assignedOrder")) {
                d.assignedOrder = $root.transport.Order.toObject(m.assignedOrder, o);
                if (o.oneofs)
                    d._assignedOrder = "assignedOrder";
            }
            if (m.biddingStatusForCarrier != null && m.hasOwnProperty("biddingStatusForCarrier")) {
                d.biddingStatusForCarrier = m.biddingStatusForCarrier;
                if (o.oneofs)
                    d._biddingStatusForCarrier = "biddingStatusForCarrier";
            }
            if (m.tender != null && m.hasOwnProperty("tender")) {
                d.tender = $root.transport.Tender.toObject(m.tender, o);
            }
            if (m.sourceOrder != null && m.hasOwnProperty("sourceOrder")) {
                d.sourceOrder = $root.transport.BiddingLot.toObject(m.sourceOrder, o);
            }
            if (m.canChangeContract != null && m.hasOwnProperty("canChangeContract")) {
                d.canChangeContract = m.canChangeContract;
            }
            if (m.contractPrice != null && m.hasOwnProperty("contractPrice")) {
                d.contractPrice = m.contractPrice;
            }
            if (m.cantBetReasons && m.cantBetReasons.length) {
                d.cantBetReasons = [];
                for (var j = 0; j < m.cantBetReasons.length; ++j) {
                    d.cantBetReasons[j] = m.cantBetReasons[j];
                }
            }
            if (m.diy != null && m.hasOwnProperty("diy")) {
                d.diy = m.diy;
            }
            return d;
        };

        Order.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Order.VehicleRequirements = (function() {

            function VehicleRequirements(p) {
                this.loadingTypes = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            VehicleRequirements.prototype.length = 0;
            VehicleRequirements.prototype.width = 0;
            VehicleRequirements.prototype.height = 0;
            VehicleRequirements.prototype.bodySubtype = null;
            VehicleRequirements.prototype.capacity = 0;
            VehicleRequirements.prototype.volume = 0;
            VehicleRequirements.prototype.loadingTypes = $util.emptyArray;

            VehicleRequirements.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Order.VehicleRequirements)
                    return d;
                var m = new $root.transport.Order.VehicleRequirements();
                if (d.length != null) {
                    m.length = d.length | 0;
                }
                if (d.width != null) {
                    m.width = d.width | 0;
                }
                if (d.height != null) {
                    m.height = d.height | 0;
                }
                if (d.bodySubtype != null) {
                    if (typeof d.bodySubtype !== "object")
                        throw TypeError(".transport.Order.VehicleRequirements.bodySubtype: object expected");
                    m.bodySubtype = $root.transport.Vehicle.Body.Subtype.fromObject(d.bodySubtype);
                }
                if (d.capacity != null) {
                    m.capacity = d.capacity | 0;
                }
                if (d.volume != null) {
                    m.volume = d.volume | 0;
                }
                if (d.loadingTypes) {
                    if (!Array.isArray(d.loadingTypes))
                        throw TypeError(".transport.Order.VehicleRequirements.loadingTypes: array expected");
                    m.loadingTypes = [];
                    for (var i = 0; i < d.loadingTypes.length; ++i) {
                        if (typeof d.loadingTypes[i] !== "object")
                            throw TypeError(".transport.Order.VehicleRequirements.loadingTypes: object expected");
                        m.loadingTypes[i] = $root.transport.LoadingType.fromObject(d.loadingTypes[i]);
                    }
                }
                return m;
            };

            VehicleRequirements.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.loadingTypes = [];
                }
                if (o.defaults) {
                    d.length = 0;
                    d.width = 0;
                    d.height = 0;
                    d.bodySubtype = null;
                    d.capacity = 0;
                    d.volume = 0;
                }
                if (m.length != null && m.hasOwnProperty("length")) {
                    d.length = m.length;
                }
                if (m.width != null && m.hasOwnProperty("width")) {
                    d.width = m.width;
                }
                if (m.height != null && m.hasOwnProperty("height")) {
                    d.height = m.height;
                }
                if (m.bodySubtype != null && m.hasOwnProperty("bodySubtype")) {
                    d.bodySubtype = $root.transport.Vehicle.Body.Subtype.toObject(m.bodySubtype, o);
                }
                if (m.capacity != null && m.hasOwnProperty("capacity")) {
                    d.capacity = m.capacity;
                }
                if (m.volume != null && m.hasOwnProperty("volume")) {
                    d.volume = m.volume;
                }
                if (m.loadingTypes && m.loadingTypes.length) {
                    d.loadingTypes = [];
                    for (var j = 0; j < m.loadingTypes.length; ++j) {
                        d.loadingTypes[j] = $root.transport.LoadingType.toObject(m.loadingTypes[j], o);
                    }
                }
                return d;
            };

            VehicleRequirements.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return VehicleRequirements;
        })();

        Order.PlaceRequirements = (function() {

            function PlaceRequirements(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            PlaceRequirements.prototype.length = 0;
            PlaceRequirements.prototype.width = 0;
            PlaceRequirements.prototype.height = 0;
            PlaceRequirements.prototype.cargoPackagesCount = 0;
            PlaceRequirements.prototype.packagingType = "";

            PlaceRequirements.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Order.PlaceRequirements)
                    return d;
                var m = new $root.transport.Order.PlaceRequirements();
                if (d.length != null) {
                    m.length = d.length | 0;
                }
                if (d.width != null) {
                    m.width = d.width | 0;
                }
                if (d.height != null) {
                    m.height = d.height | 0;
                }
                if (d.cargoPackagesCount != null) {
                    m.cargoPackagesCount = d.cargoPackagesCount | 0;
                }
                if (d.packagingType != null) {
                    m.packagingType = String(d.packagingType);
                }
                return m;
            };

            PlaceRequirements.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.length = 0;
                    d.width = 0;
                    d.height = 0;
                    d.cargoPackagesCount = 0;
                    d.packagingType = "";
                }
                if (m.length != null && m.hasOwnProperty("length")) {
                    d.length = m.length;
                }
                if (m.width != null && m.hasOwnProperty("width")) {
                    d.width = m.width;
                }
                if (m.height != null && m.hasOwnProperty("height")) {
                    d.height = m.height;
                }
                if (m.cargoPackagesCount != null && m.hasOwnProperty("cargoPackagesCount")) {
                    d.cargoPackagesCount = m.cargoPackagesCount;
                }
                if (m.packagingType != null && m.hasOwnProperty("packagingType")) {
                    d.packagingType = m.packagingType;
                }
                return d;
            };

            PlaceRequirements.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return PlaceRequirements;
        })();

        Order.StoragePoint = (function() {

            function StoragePoint(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            StoragePoint.prototype.storagePoint = null;
            StoragePoint.prototype.arriveDatetimeFrom = "";
            StoragePoint.prototype.arriveDatetimeTo = "";
            StoragePoint.prototype.loadingType = null;
            StoragePoint.prototype.attachedDocuments = null;
            StoragePoint.prototype.address = "";
            StoragePoint.prototype.name = "";
            StoragePoint.prototype.comment = "";
            StoragePoint.prototype.timezone = null;

            StoragePoint.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Order.StoragePoint)
                    return d;
                var m = new $root.transport.Order.StoragePoint();
                if (d.storagePoint != null) {
                    if (typeof d.storagePoint !== "object")
                        throw TypeError(".transport.Order.StoragePoint.storagePoint: object expected");
                    m.storagePoint = $root.transport.Place.fromObject(d.storagePoint);
                }
                if (d.arriveDatetimeFrom != null) {
                    m.arriveDatetimeFrom = String(d.arriveDatetimeFrom);
                }
                if (d.arriveDatetimeTo != null) {
                    m.arriveDatetimeTo = String(d.arriveDatetimeTo);
                }
                if (d.loadingType != null) {
                    if (typeof d.loadingType !== "object")
                        throw TypeError(".transport.Order.StoragePoint.loadingType: object expected");
                    m.loadingType = $root.transport.LoadingType.fromObject(d.loadingType);
                }
                if (d.attachedDocuments != null) {
                    if (typeof d.attachedDocuments !== "object")
                        throw TypeError(".transport.Order.StoragePoint.attachedDocuments: object expected");
                    m.attachedDocuments = $root.transport.Order.AttachedDocuments.fromObject(d.attachedDocuments);
                }
                if (d.address != null) {
                    m.address = String(d.address);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.comment != null) {
                    m.comment = String(d.comment);
                }
                if (d.timezone != null) {
                    if (typeof d.timezone !== "object")
                        throw TypeError(".transport.Order.StoragePoint.timezone: object expected");
                    m.timezone = $root.transport.Timezone.fromObject(d.timezone);
                }
                return m;
            };

            StoragePoint.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.storagePoint = null;
                    d.arriveDatetimeFrom = "";
                    d.arriveDatetimeTo = "";
                    d.loadingType = null;
                    d.attachedDocuments = null;
                    d.address = "";
                    d.name = "";
                    d.comment = "";
                    d.timezone = null;
                }
                if (m.storagePoint != null && m.hasOwnProperty("storagePoint")) {
                    d.storagePoint = $root.transport.Place.toObject(m.storagePoint, o);
                }
                if (m.arriveDatetimeFrom != null && m.hasOwnProperty("arriveDatetimeFrom")) {
                    d.arriveDatetimeFrom = m.arriveDatetimeFrom;
                }
                if (m.arriveDatetimeTo != null && m.hasOwnProperty("arriveDatetimeTo")) {
                    d.arriveDatetimeTo = m.arriveDatetimeTo;
                }
                if (m.loadingType != null && m.hasOwnProperty("loadingType")) {
                    d.loadingType = $root.transport.LoadingType.toObject(m.loadingType, o);
                }
                if (m.attachedDocuments != null && m.hasOwnProperty("attachedDocuments")) {
                    d.attachedDocuments = $root.transport.Order.AttachedDocuments.toObject(m.attachedDocuments, o);
                }
                if (m.address != null && m.hasOwnProperty("address")) {
                    d.address = m.address;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.comment != null && m.hasOwnProperty("comment")) {
                    d.comment = m.comment;
                }
                if (m.timezone != null && m.hasOwnProperty("timezone")) {
                    d.timezone = $root.transport.Timezone.toObject(m.timezone, o);
                }
                return d;
            };

            StoragePoint.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return StoragePoint;
        })();

        Order.AttachedDocuments = (function() {

            function AttachedDocuments(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            AttachedDocuments.prototype.filename = "";
            AttachedDocuments.prototype.url = "";

            AttachedDocuments.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Order.AttachedDocuments)
                    return d;
                var m = new $root.transport.Order.AttachedDocuments();
                if (d.filename != null) {
                    m.filename = String(d.filename);
                }
                if (d.url != null) {
                    m.url = String(d.url);
                }
                return m;
            };

            AttachedDocuments.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.filename = "";
                    d.url = "";
                }
                if (m.filename != null && m.hasOwnProperty("filename")) {
                    d.filename = m.filename;
                }
                if (m.url != null && m.hasOwnProperty("url")) {
                    d.url = m.url;
                }
                return d;
            };

            AttachedDocuments.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return AttachedDocuments;
        })();

        Order.Owner = (function() {

            function Owner(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Owner.prototype.email = "";
            Owner.prototype.firstName = "";
            Owner.prototype.lastName = "";
            Owner.prototype.phoneNumber = "";
            Owner.prototype.id = "";

            Owner.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Order.Owner)
                    return d;
                var m = new $root.transport.Order.Owner();
                if (d.email != null) {
                    m.email = String(d.email);
                }
                if (d.firstName != null) {
                    m.firstName = String(d.firstName);
                }
                if (d.lastName != null) {
                    m.lastName = String(d.lastName);
                }
                if (d.phoneNumber != null) {
                    m.phoneNumber = String(d.phoneNumber);
                }
                if (d.id != null) {
                    m.id = String(d.id);
                }
                return m;
            };

            Owner.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.email = "";
                    d.firstName = "";
                    d.lastName = "";
                    d.phoneNumber = "";
                    d.id = "";
                }
                if (m.email != null && m.hasOwnProperty("email")) {
                    d.email = m.email;
                }
                if (m.firstName != null && m.hasOwnProperty("firstName")) {
                    d.firstName = m.firstName;
                }
                if (m.lastName != null && m.hasOwnProperty("lastName")) {
                    d.lastName = m.lastName;
                }
                if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                    d.phoneNumber = m.phoneNumber;
                }
                if (m.id != null && m.hasOwnProperty("id")) {
                    d.id = m.id;
                }
                return d;
            };

            Owner.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Owner;
        })();

        Order.DocumentStatus = (function() {

            function DocumentStatus(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            DocumentStatus.prototype.createdAt = null;
            DocumentStatus.prototype.id = "";
            DocumentStatus.prototype.status = "";
            DocumentStatus.prototype.document = null;
            DocumentStatus.prototype.documentType = null;
            DocumentStatus.prototype.extId = null;
            DocumentStatus.prototype.url = null;

            let $oneOfFields;

            Object.defineProperty(DocumentStatus.prototype, "_createdAt", {
                get: $util.oneOfGetter($oneOfFields = ["createdAt"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            Object.defineProperty(DocumentStatus.prototype, "_document", {
                get: $util.oneOfGetter($oneOfFields = ["document"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            Object.defineProperty(DocumentStatus.prototype, "_extId", {
                get: $util.oneOfGetter($oneOfFields = ["extId"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            Object.defineProperty(DocumentStatus.prototype, "_url", {
                get: $util.oneOfGetter($oneOfFields = ["url"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            DocumentStatus.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Order.DocumentStatus)
                    return d;
                var m = new $root.transport.Order.DocumentStatus();
                if (d.createdAt != null) {
                    m.createdAt = String(d.createdAt);
                }
                if (d.id != null) {
                    m.id = String(d.id);
                }
                if (d.status != null) {
                    m.status = String(d.status);
                }
                if (d.document != null) {
                    if (typeof d.document !== "object")
                        throw TypeError(".transport.Order.DocumentStatus.document: object expected");
                    m.document = $root.transport.OrderUploadedDocument.fromObject(d.document);
                }
                if (d.documentType != null) {
                    if (typeof d.documentType !== "object")
                        throw TypeError(".transport.Order.DocumentStatus.documentType: object expected");
                    m.documentType = $root.transport.OrderDocumentType.fromObject(d.documentType);
                }
                if (d.extId != null) {
                    m.extId = String(d.extId);
                }
                if (d.url != null) {
                    m.url = String(d.url);
                }
                return m;
            };

            DocumentStatus.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.id = "";
                    d.status = "";
                    d.documentType = null;
                }
                if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                    d.createdAt = m.createdAt;
                    if (o.oneofs)
                        d._createdAt = "createdAt";
                }
                if (m.id != null && m.hasOwnProperty("id")) {
                    d.id = m.id;
                }
                if (m.status != null && m.hasOwnProperty("status")) {
                    d.status = m.status;
                }
                if (m.document != null && m.hasOwnProperty("document")) {
                    d.document = $root.transport.OrderUploadedDocument.toObject(m.document, o);
                    if (o.oneofs)
                        d._document = "document";
                }
                if (m.documentType != null && m.hasOwnProperty("documentType")) {
                    d.documentType = $root.transport.OrderDocumentType.toObject(m.documentType, o);
                }
                if (m.extId != null && m.hasOwnProperty("extId")) {
                    d.extId = m.extId;
                    if (o.oneofs)
                        d._extId = "extId";
                }
                if (m.url != null && m.hasOwnProperty("url")) {
                    d.url = m.url;
                    if (o.oneofs)
                        d._url = "url";
                }
                return d;
            };

            DocumentStatus.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return DocumentStatus;
        })();

        Order.InsuranceContract = (function() {

            function InsuranceContract(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            InsuranceContract.prototype.id = "";
            InsuranceContract.prototype.parameters = "";
            InsuranceContract.prototype.generalNumber = "";
            InsuranceContract.prototype.tariff = 0;
            InsuranceContract.prototype.startDatetime = "";
            InsuranceContract.prototype.endDatetime = "";
            InsuranceContract.prototype.isDefault = false;
            InsuranceContract.prototype.insuranceCompany = null;

            InsuranceContract.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Order.InsuranceContract)
                    return d;
                var m = new $root.transport.Order.InsuranceContract();
                if (d.id != null) {
                    m.id = String(d.id);
                }
                if (d.parameters != null) {
                    m.parameters = String(d.parameters);
                }
                if (d.generalNumber != null) {
                    m.generalNumber = String(d.generalNumber);
                }
                if (d.tariff != null) {
                    m.tariff = d.tariff | 0;
                }
                if (d.startDatetime != null) {
                    m.startDatetime = String(d.startDatetime);
                }
                if (d.endDatetime != null) {
                    m.endDatetime = String(d.endDatetime);
                }
                if (d.isDefault != null) {
                    m.isDefault = Boolean(d.isDefault);
                }
                if (d.insuranceCompany != null) {
                    if (typeof d.insuranceCompany !== "object")
                        throw TypeError(".transport.Order.InsuranceContract.insuranceCompany: object expected");
                    m.insuranceCompany = $root.transport.Order.InsuranceCompany.fromObject(d.insuranceCompany);
                }
                return m;
            };

            InsuranceContract.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.id = "";
                    d.parameters = "";
                    d.generalNumber = "";
                    d.tariff = 0;
                    d.startDatetime = "";
                    d.endDatetime = "";
                    d.isDefault = false;
                    d.insuranceCompany = null;
                }
                if (m.id != null && m.hasOwnProperty("id")) {
                    d.id = m.id;
                }
                if (m.parameters != null && m.hasOwnProperty("parameters")) {
                    d.parameters = m.parameters;
                }
                if (m.generalNumber != null && m.hasOwnProperty("generalNumber")) {
                    d.generalNumber = m.generalNumber;
                }
                if (m.tariff != null && m.hasOwnProperty("tariff")) {
                    d.tariff = m.tariff;
                }
                if (m.startDatetime != null && m.hasOwnProperty("startDatetime")) {
                    d.startDatetime = m.startDatetime;
                }
                if (m.endDatetime != null && m.hasOwnProperty("endDatetime")) {
                    d.endDatetime = m.endDatetime;
                }
                if (m.isDefault != null && m.hasOwnProperty("isDefault")) {
                    d.isDefault = m.isDefault;
                }
                if (m.insuranceCompany != null && m.hasOwnProperty("insuranceCompany")) {
                    d.insuranceCompany = $root.transport.Order.InsuranceCompany.toObject(m.insuranceCompany, o);
                }
                return d;
            };

            InsuranceContract.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return InsuranceContract;
        })();

        Order.InsuranceCompany = (function() {

            function InsuranceCompany(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            InsuranceCompany.prototype.codename = "";
            InsuranceCompany.prototype.name = "";

            InsuranceCompany.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Order.InsuranceCompany)
                    return d;
                var m = new $root.transport.Order.InsuranceCompany();
                if (d.codename != null) {
                    m.codename = String(d.codename);
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                return m;
            };

            InsuranceCompany.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.codename = "";
                    d.name = "";
                }
                if (m.codename != null && m.hasOwnProperty("codename")) {
                    d.codename = m.codename;
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                return d;
            };

            InsuranceCompany.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return InsuranceCompany;
        })();

        Order.OrderAttributesType = (function() {

            function OrderAttributesType(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            OrderAttributesType.prototype.isScored = false;
            OrderAttributesType.prototype.hasDigitalSignature = false;
            OrderAttributesType.prototype.hasOwnVehicle = false;
            OrderAttributesType.prototype.isDriverAndVehicleChanging = false;
            OrderAttributesType.prototype.isDriverAndVehicleChangeTimerOnPause = null;
            OrderAttributesType.prototype.driverAndVehicleChangeTimeLeft = null;

            let $oneOfFields;

            Object.defineProperty(OrderAttributesType.prototype, "_isDriverAndVehicleChangeTimerOnPause", {
                get: $util.oneOfGetter($oneOfFields = ["isDriverAndVehicleChangeTimerOnPause"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            Object.defineProperty(OrderAttributesType.prototype, "_driverAndVehicleChangeTimeLeft", {
                get: $util.oneOfGetter($oneOfFields = ["driverAndVehicleChangeTimeLeft"]),
                set: $util.oneOfSetter($oneOfFields)
            });

            OrderAttributesType.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Order.OrderAttributesType)
                    return d;
                var m = new $root.transport.Order.OrderAttributesType();
                if (d.isScored != null) {
                    m.isScored = Boolean(d.isScored);
                }
                if (d.hasDigitalSignature != null) {
                    m.hasDigitalSignature = Boolean(d.hasDigitalSignature);
                }
                if (d.hasOwnVehicle != null) {
                    m.hasOwnVehicle = Boolean(d.hasOwnVehicle);
                }
                if (d.isDriverAndVehicleChanging != null) {
                    m.isDriverAndVehicleChanging = Boolean(d.isDriverAndVehicleChanging);
                }
                if (d.isDriverAndVehicleChangeTimerOnPause != null) {
                    m.isDriverAndVehicleChangeTimerOnPause = Boolean(d.isDriverAndVehicleChangeTimerOnPause);
                }
                if (d.driverAndVehicleChangeTimeLeft != null) {
                    m.driverAndVehicleChangeTimeLeft = d.driverAndVehicleChangeTimeLeft | 0;
                }
                return m;
            };

            OrderAttributesType.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.isScored = false;
                    d.hasDigitalSignature = false;
                    d.hasOwnVehicle = false;
                    d.isDriverAndVehicleChanging = false;
                }
                if (m.isScored != null && m.hasOwnProperty("isScored")) {
                    d.isScored = m.isScored;
                }
                if (m.hasDigitalSignature != null && m.hasOwnProperty("hasDigitalSignature")) {
                    d.hasDigitalSignature = m.hasDigitalSignature;
                }
                if (m.hasOwnVehicle != null && m.hasOwnProperty("hasOwnVehicle")) {
                    d.hasOwnVehicle = m.hasOwnVehicle;
                }
                if (m.isDriverAndVehicleChanging != null && m.hasOwnProperty("isDriverAndVehicleChanging")) {
                    d.isDriverAndVehicleChanging = m.isDriverAndVehicleChanging;
                }
                if (m.isDriverAndVehicleChangeTimerOnPause != null && m.hasOwnProperty("isDriverAndVehicleChangeTimerOnPause")) {
                    d.isDriverAndVehicleChangeTimerOnPause = m.isDriverAndVehicleChangeTimerOnPause;
                    if (o.oneofs)
                        d._isDriverAndVehicleChangeTimerOnPause = "isDriverAndVehicleChangeTimerOnPause";
                }
                if (m.driverAndVehicleChangeTimeLeft != null && m.hasOwnProperty("driverAndVehicleChangeTimeLeft")) {
                    d.driverAndVehicleChangeTimeLeft = m.driverAndVehicleChangeTimeLeft;
                    if (o.oneofs)
                        d._driverAndVehicleChangeTimeLeft = "driverAndVehicleChangeTimeLeft";
                }
                return d;
            };

            OrderAttributesType.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return OrderAttributesType;
        })();

        Order.CarrierContract = (function() {

            function CarrierContract(p) {
                this.cargoTypes = [];
                this.loadingPlaces = [];
                this.unloadingPlaces = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            CarrierContract.prototype.isArchived = false;
            CarrierContract.prototype.id = "";
            CarrierContract.prototype.contractNumber = "";
            CarrierContract.prototype.startDate = "";
            CarrierContract.prototype.endDate = "";
            CarrierContract.prototype.cargoQuantity = 0;
            CarrierContract.prototype.cargoTypes = $util.emptyArray;
            CarrierContract.prototype.name = "";
            CarrierContract.prototype.loadingPlaces = $util.emptyArray;
            CarrierContract.prototype.unloadingPlaces = $util.emptyArray;
            CarrierContract.prototype.contractType = "";
            CarrierContract.prototype.agentType = "";
            CarrierContract.prototype.cargoOwner = null;
            CarrierContract.prototype.isActive = false;

            CarrierContract.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Order.CarrierContract)
                    return d;
                var m = new $root.transport.Order.CarrierContract();
                if (d.isArchived != null) {
                    m.isArchived = Boolean(d.isArchived);
                }
                if (d.id != null) {
                    m.id = String(d.id);
                }
                if (d.contractNumber != null) {
                    m.contractNumber = String(d.contractNumber);
                }
                if (d.startDate != null) {
                    m.startDate = String(d.startDate);
                }
                if (d.endDate != null) {
                    m.endDate = String(d.endDate);
                }
                if (d.cargoQuantity != null) {
                    m.cargoQuantity = d.cargoQuantity | 0;
                }
                if (d.cargoTypes) {
                    if (!Array.isArray(d.cargoTypes))
                        throw TypeError(".transport.Order.CarrierContract.cargoTypes: array expected");
                    m.cargoTypes = [];
                    for (var i = 0; i < d.cargoTypes.length; ++i) {
                        if (typeof d.cargoTypes[i] !== "object")
                            throw TypeError(".transport.Order.CarrierContract.cargoTypes: object expected");
                        m.cargoTypes[i] = $root.transport.CargoType.fromObject(d.cargoTypes[i]);
                    }
                }
                if (d.name != null) {
                    m.name = String(d.name);
                }
                if (d.loadingPlaces) {
                    if (!Array.isArray(d.loadingPlaces))
                        throw TypeError(".transport.Order.CarrierContract.loadingPlaces: array expected");
                    m.loadingPlaces = [];
                    for (var i = 0; i < d.loadingPlaces.length; ++i) {
                        if (typeof d.loadingPlaces[i] !== "object")
                            throw TypeError(".transport.Order.CarrierContract.loadingPlaces: object expected");
                        m.loadingPlaces[i] = $root.transport.Order.StoragePoint.fromObject(d.loadingPlaces[i]);
                    }
                }
                if (d.unloadingPlaces) {
                    if (!Array.isArray(d.unloadingPlaces))
                        throw TypeError(".transport.Order.CarrierContract.unloadingPlaces: array expected");
                    m.unloadingPlaces = [];
                    for (var i = 0; i < d.unloadingPlaces.length; ++i) {
                        if (typeof d.unloadingPlaces[i] !== "object")
                            throw TypeError(".transport.Order.CarrierContract.unloadingPlaces: object expected");
                        m.unloadingPlaces[i] = $root.transport.Order.StoragePoint.fromObject(d.unloadingPlaces[i]);
                    }
                }
                if (d.contractType != null) {
                    m.contractType = String(d.contractType);
                }
                if (d.agentType != null) {
                    m.agentType = String(d.agentType);
                }
                if (d.cargoOwner != null) {
                    if (typeof d.cargoOwner !== "object")
                        throw TypeError(".transport.Order.CarrierContract.cargoOwner: object expected");
                    m.cargoOwner = $root.transport.CargoOwner.fromObject(d.cargoOwner);
                }
                if (d.isActive != null) {
                    m.isActive = Boolean(d.isActive);
                }
                return m;
            };

            CarrierContract.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.cargoTypes = [];
                    d.loadingPlaces = [];
                    d.unloadingPlaces = [];
                }
                if (o.defaults) {
                    d.isArchived = false;
                    d.id = "";
                    d.contractNumber = "";
                    d.startDate = "";
                    d.endDate = "";
                    d.cargoQuantity = 0;
                    d.name = "";
                    d.contractType = "";
                    d.agentType = "";
                    d.cargoOwner = null;
                    d.isActive = false;
                }
                if (m.isArchived != null && m.hasOwnProperty("isArchived")) {
                    d.isArchived = m.isArchived;
                }
                if (m.id != null && m.hasOwnProperty("id")) {
                    d.id = m.id;
                }
                if (m.contractNumber != null && m.hasOwnProperty("contractNumber")) {
                    d.contractNumber = m.contractNumber;
                }
                if (m.startDate != null && m.hasOwnProperty("startDate")) {
                    d.startDate = m.startDate;
                }
                if (m.endDate != null && m.hasOwnProperty("endDate")) {
                    d.endDate = m.endDate;
                }
                if (m.cargoQuantity != null && m.hasOwnProperty("cargoQuantity")) {
                    d.cargoQuantity = m.cargoQuantity;
                }
                if (m.cargoTypes && m.cargoTypes.length) {
                    d.cargoTypes = [];
                    for (var j = 0; j < m.cargoTypes.length; ++j) {
                        d.cargoTypes[j] = $root.transport.CargoType.toObject(m.cargoTypes[j], o);
                    }
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                if (m.loadingPlaces && m.loadingPlaces.length) {
                    d.loadingPlaces = [];
                    for (var j = 0; j < m.loadingPlaces.length; ++j) {
                        d.loadingPlaces[j] = $root.transport.Order.StoragePoint.toObject(m.loadingPlaces[j], o);
                    }
                }
                if (m.unloadingPlaces && m.unloadingPlaces.length) {
                    d.unloadingPlaces = [];
                    for (var j = 0; j < m.unloadingPlaces.length; ++j) {
                        d.unloadingPlaces[j] = $root.transport.Order.StoragePoint.toObject(m.unloadingPlaces[j], o);
                    }
                }
                if (m.contractType != null && m.hasOwnProperty("contractType")) {
                    d.contractType = m.contractType;
                }
                if (m.agentType != null && m.hasOwnProperty("agentType")) {
                    d.agentType = m.agentType;
                }
                if (m.cargoOwner != null && m.hasOwnProperty("cargoOwner")) {
                    d.cargoOwner = $root.transport.CargoOwner.toObject(m.cargoOwner, o);
                }
                if (m.isActive != null && m.hasOwnProperty("isActive")) {
                    d.isActive = m.isActive;
                }
                return d;
            };

            CarrierContract.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return CarrierContract;
        })();

        return Order;
    })();

    transport.AdminOrganization = (function() {

        function AdminOrganization(p) {
            this.allowedExtraServices = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        AdminOrganization.prototype.id = "";
        AdminOrganization.prototype.email = "";
        AdminOrganization.prototype.companyName = "";
        AdminOrganization.prototype.phoneNumber = "";
        AdminOrganization.prototype.inn = "";
        AdminOrganization.prototype.kpp = "";
        AdminOrganization.prototype.legalAddress = "";
        AdminOrganization.prototype.postalAddress = "";
        AdminOrganization.prototype.ogrn = "";
        AdminOrganization.prototype.legalForm = null;
        AdminOrganization.prototype.signer = "";
        AdminOrganization.prototype.isCompanyGroup = false;
        AdminOrganization.prototype.vat = null;
        AdminOrganization.prototype.auctionProlongationParameters = null;
        AdminOrganization.prototype.showOrderExternalNo = false;
        AdminOrganization.prototype.paymentType = null;
        AdminOrganization.prototype.paymentPeriod = "";
        AdminOrganization.prototype.extraConditionsForMarket = "";
        AdminOrganization.prototype.insuranceParameters = null;
        AdminOrganization.prototype.allowedExtraServices = $util.emptyArray;
        AdminOrganization.prototype.isTn = false;
        AdminOrganization.prototype.name = "";
        AdminOrganization.prototype.enableDeliveryTracking = false;
        AdminOrganization.prototype.isArchived = false;
        AdminOrganization.prototype.registrationNumber = "";
        AdminOrganization.prototype.subdomain = null;
        AdminOrganization.prototype.logo = null;
        AdminOrganization.prototype.documentFlowSettings = null;
        AdminOrganization.prototype.carrierContractSigning = null;
        AdminOrganization.prototype.carrierContractRequired = null;
        AdminOrganization.prototype.currency = null;

        AdminOrganization.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.AdminOrganization)
                return d;
            var m = new $root.transport.AdminOrganization();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            if (d.companyName != null) {
                m.companyName = String(d.companyName);
            }
            if (d.phoneNumber != null) {
                m.phoneNumber = String(d.phoneNumber);
            }
            if (d.inn != null) {
                m.inn = String(d.inn);
            }
            if (d.kpp != null) {
                m.kpp = String(d.kpp);
            }
            if (d.legalAddress != null) {
                m.legalAddress = String(d.legalAddress);
            }
            if (d.postalAddress != null) {
                m.postalAddress = String(d.postalAddress);
            }
            if (d.ogrn != null) {
                m.ogrn = String(d.ogrn);
            }
            if (d.legalForm != null) {
                if (typeof d.legalForm !== "object")
                    throw TypeError(".transport.AdminOrganization.legalForm: object expected");
                m.legalForm = $root.transport.LegalForm.fromObject(d.legalForm);
            }
            if (d.signer != null) {
                m.signer = String(d.signer);
            }
            if (d.isCompanyGroup != null) {
                m.isCompanyGroup = Boolean(d.isCompanyGroup);
            }
            if (d.vat != null) {
                if (typeof d.vat !== "object")
                    throw TypeError(".transport.AdminOrganization.vat: object expected");
                m.vat = $root.transport.OrganizationVatType.fromObject(d.vat);
            }
            if (d.auctionProlongationParameters != null) {
                if (typeof d.auctionProlongationParameters !== "object")
                    throw TypeError(".transport.AdminOrganization.auctionProlongationParameters: object expected");
                m.auctionProlongationParameters = $root.transport.AuctionProlongationParameters.fromObject(d.auctionProlongationParameters);
            }
            if (d.additionalAuctionParameters != null) {
                if (typeof d.auctionProlongationParameters !== 'object')
                    throw TypeError('.transport.AdminOrganization.additionalAuctionParameters: object expected');
                m.additionalAuctionParameters = {
                    typeOfPriceStep: d.additionalAuctionParameters.typeOfPriceStep ?? null,
                    earlyFinish: d.additionalAuctionParameters.earlyFinish ?? null,
                    minStepPrice: d.additionalAuctionParameters.minStepPrice ?? null,
                    minPriceStepPercent: d.additionalAuctionParameters.minPriceStepPercent ?? null,
                };
            }
            if (d.showOrderExternalNo != null) {
                m.showOrderExternalNo = Boolean(d.showOrderExternalNo);
            }
            if (d.paymentType != null) {
                if (typeof d.paymentType !== "object")
                    throw TypeError(".transport.AdminOrganization.paymentType: object expected");
                m.paymentType = $root.transport.PaymentType.fromObject(d.paymentType);
            }
            if (d.paymentPeriod != null) {
                m.paymentPeriod = String(d.paymentPeriod);
            }
            if (d.extraConditionsForMarket != null) {
                m.extraConditionsForMarket = String(d.extraConditionsForMarket);
            }
            if (d.insuranceParameters != null) {
                if (typeof d.insuranceParameters !== "object")
                    throw TypeError(".transport.AdminOrganization.insuranceParameters: object expected");
                m.insuranceParameters = $root.transport.InsuranceParameters.fromObject(d.insuranceParameters);
            }
            if (d.allowedExtraServices) {
                if (!Array.isArray(d.allowedExtraServices))
                    throw TypeError(".transport.AdminOrganization.allowedExtraServices: array expected");
                m.allowedExtraServices = [];
                for (var i = 0; i < d.allowedExtraServices.length; ++i) {
                    if (typeof d.allowedExtraServices[i] !== "object")
                        throw TypeError(".transport.AdminOrganization.allowedExtraServices: object expected");
                    m.allowedExtraServices[i] = $root.transport.ExtraService.fromObject(d.allowedExtraServices[i]);
                }
            }
            if (d.isTn != null) {
                m.isTn = Boolean(d.isTn);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.enableDeliveryTracking != null) {
                m.enableDeliveryTracking = Boolean(d.enableDeliveryTracking);
            }
            if (d.isArchived != null) {
                m.isArchived = Boolean(d.isArchived);
            }
            if (d.registrationNumber != null) {
                m.registrationNumber = String(d.registrationNumber);
            }

            m.subdomain = d.subdomain;
            m.logo = d.logo;
            m.documentFlowSettings = d.documentFlowSettings;
            m.carrierContractSigning = d.carrierContractSigning;
            m.carrierContractRequired = d.carrierContractRequired;
            m.currency = d.currency;
            return m;
        };

        AdminOrganization.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.allowedExtraServices = [];
            }
            if (o.defaults) {
                d.id = "";
                d.email = "";
                d.companyName = "";
                d.phoneNumber = "";
                d.inn = "";
                d.kpp = "";
                d.legalAddress = "";
                d.postalAddress = "";
                d.ogrn = "";
                d.legalForm = null;
                d.signer = "";
                d.isCompanyGroup = false;
                d.vat = null;
                d.auctionProlongationParameters = null;
                d.additionalAuctionParameters = null;
                d.showOrderExternalNo = false;
                d.paymentType = null;
                d.paymentPeriod = "";
                d.extraConditionsForMarket = "";
                d.insuranceParameters = null;
                d.isTn = false;
                d.name = "";
                d.enableDeliveryTracking = false;
                d.isArchived = false;
                d.registrationNumber = "";
                d.subdomain = null;
                d.logo = null;
                d.documentFlowSettings = null;
                d.carrierContractSigning = null;
                d.carrierContractRequired = null;
                d.currency = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
            }
            if (m.companyName != null && m.hasOwnProperty("companyName")) {
                d.companyName = m.companyName;
            }
            if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                d.phoneNumber = m.phoneNumber;
            }
            if (m.inn != null && m.hasOwnProperty("inn")) {
                d.inn = m.inn;
            }
            if (m.kpp != null && m.hasOwnProperty("kpp")) {
                d.kpp = m.kpp;
            }
            if (m.legalAddress != null && m.hasOwnProperty("legalAddress")) {
                d.legalAddress = m.legalAddress;
            }
            if (m.postalAddress != null && m.hasOwnProperty("postalAddress")) {
                d.postalAddress = m.postalAddress;
            }
            if (m.ogrn != null && m.hasOwnProperty("ogrn")) {
                d.ogrn = m.ogrn;
            }
            if (m.legalForm != null && m.hasOwnProperty("legalForm")) {
                d.legalForm = $root.transport.LegalForm.toObject(m.legalForm, o);
            }
            if (m.signer != null && m.hasOwnProperty("signer")) {
                d.signer = m.signer;
            }
            if (m.isCompanyGroup != null && m.hasOwnProperty("isCompanyGroup")) {
                d.isCompanyGroup = m.isCompanyGroup;
            }
            if (m.vat != null && m.hasOwnProperty("vat")) {
                d.vat = $root.transport.OrganizationVatType.toObject(m.vat, o);
            }
            if (m.auctionProlongationParameters != null && m.hasOwnProperty("auctionProlongationParameters")) {
                d.auctionProlongationParameters = $root.transport.AuctionProlongationParameters.toObject(m.auctionProlongationParameters, o);
            }
            if (m.additionalAuctionParameters != null && m.hasOwnProperty('additionalAuctionParameters')) {
                d.additionalAuctionParameters = m.additionalAuctionParameters;
            }
            if (m.showOrderExternalNo != null && m.hasOwnProperty("showOrderExternalNo")) {
                d.showOrderExternalNo = m.showOrderExternalNo;
            }
            if (m.paymentType != null && m.hasOwnProperty("paymentType")) {
                d.paymentType = $root.transport.PaymentType.toObject(m.paymentType, o);
            }
            if (m.paymentPeriod != null && m.hasOwnProperty("paymentPeriod")) {
                d.paymentPeriod = m.paymentPeriod;
            }
            if (m.extraConditionsForMarket != null && m.hasOwnProperty("extraConditionsForMarket")) {
                d.extraConditionsForMarket = m.extraConditionsForMarket;
            }
            if (m.insuranceParameters != null && m.hasOwnProperty("insuranceParameters")) {
                d.insuranceParameters = $root.transport.InsuranceParameters.toObject(m.insuranceParameters, o);
            }
            if (m.allowedExtraServices && m.allowedExtraServices.length) {
                d.allowedExtraServices = [];
                for (var j = 0; j < m.allowedExtraServices.length; ++j) {
                    d.allowedExtraServices[j] = $root.transport.ExtraService.toObject(m.allowedExtraServices[j], o);
                }
            }
            if (m.isTn != null && m.hasOwnProperty("isTn")) {
                d.isTn = m.isTn;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.enableDeliveryTracking != null && m.hasOwnProperty("enableDeliveryTracking")) {
                d.enableDeliveryTracking = m.enableDeliveryTracking;
            }
            if (m.isArchived != null && m.hasOwnProperty("isArchived")) {
                d.isArchived = m.isArchived;
            }
            if (m.registrationNumber != null && m.hasOwnProperty("registrationNumber")) {
                d.registrationNumber = m.registrationNumber;
            }

            d.subdomain = m.subdomain;
            d.logo = m.logo;
            d.documentFlowSettings = m.documentFlowSettings;
            d.carrierContractSigning = m.carrierContractSigning;
            d.carrierContractRequired = m.carrierContractRequired;
            d.currency = m.currency;

            return d;
        };

        AdminOrganization.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AdminOrganization;
    })();

    transport.OrganizationVatType = (function() {

        function OrganizationVatType(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        OrganizationVatType.prototype.id = "";
        OrganizationVatType.prototype.name = "";
        OrganizationVatType.prototype.rate = $util.Long ? $util.Long.fromBits(0,0,false) : 0;

        OrganizationVatType.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.OrganizationVatType)
                return d;
            var m = new $root.transport.OrganizationVatType();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.rate != null) {
                if ($util.Long)
                    (m.rate = $util.Long.fromValue(d.rate)).unsigned = false;
                else if (typeof d.rate === "string")
                    m.rate = parseInt(d.rate, 10);
                else if (typeof d.rate === "number")
                    m.rate = d.rate;
                else if (typeof d.rate === "object")
                    m.rate = new $util.LongBits(d.rate.low >>> 0, d.rate.high >>> 0).toNumber();
            }
            return m;
        };

        OrganizationVatType.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.rate = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.rate = o.longs === String ? "0" : 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.rate != null && m.hasOwnProperty("rate")) {
                if (typeof m.rate === "number")
                    d.rate = o.longs === String ? String(m.rate) : m.rate;
                else
                    d.rate = o.longs === String ? $util.Long.prototype.toString.call(m.rate) : o.longs === Number ? new $util.LongBits(m.rate.low >>> 0, m.rate.high >>> 0).toNumber() : m.rate;
            }
            return d;
        };

        OrganizationVatType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return OrganizationVatType;
    })();

    transport.ExtraService = (function() {

        function ExtraService(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        ExtraService.prototype.id = "";
        ExtraService.prototype.name = "";
        ExtraService.prototype.price = "";
        ExtraService.prototype.vatAmount = "";

        ExtraService.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.ExtraService)
                return d;
            var m = new $root.transport.ExtraService();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.price != null) {
                m.price = String(d.price);
            }
            if (d.vatAmount != null) {
                m.vatAmount = String(d.vatAmount);
            }
            return m;
        };

        ExtraService.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.price = "";
                d.vatAmount = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                d.price = m.price;
            }
            if (m.vatAmount != null && m.hasOwnProperty("vatAmount")) {
                d.vatAmount = m.vatAmount;
            }
            return d;
        };

        ExtraService.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return ExtraService;
    })();

    transport.EditOrganizationInput = (function() {

        function EditOrganizationInput(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        EditOrganizationInput.prototype.registrationNumber = "";
        EditOrganizationInput.prototype.name = "";
        EditOrganizationInput.prototype.legalForm = "";
        EditOrganizationInput.prototype.email = "";
        EditOrganizationInput.prototype.postalAddress = "";
        EditOrganizationInput.prototype.id = "";
        EditOrganizationInput.prototype.inn = "";
        EditOrganizationInput.prototype.kpp = "";
        EditOrganizationInput.prototype.phoneNumber = "";
        EditOrganizationInput.prototype.legalAddress = "";
        EditOrganizationInput.prototype.signer = "";
        EditOrganizationInput.prototype.vat = "";

        EditOrganizationInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.EditOrganizationInput)
                return d;
            var m = new $root.transport.EditOrganizationInput();
            if (d.registrationNumber != null) {
                m.registrationNumber = String(d.registrationNumber);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.legalForm != null) {
                m.legalForm = String(d.legalForm);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            if (d.postalAddress != null) {
                m.postalAddress = String(d.postalAddress);
            }
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.inn != null) {
                m.inn = String(d.inn);
            }
            if (d.kpp != null) {
                m.kpp = String(d.kpp);
            }
            if (d.phoneNumber != null) {
                m.phoneNumber = String(d.phoneNumber);
            }
            if (d.legalAddress != null) {
                m.legalAddress = String(d.legalAddress);
            }
            if (d.signer != null) {
                m.signer = String(d.signer);
            }
            if (d.vat != null) {
                m.vat = String(d.vat);
            }
            return m;
        };

        EditOrganizationInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.registrationNumber = "";
                d.name = "";
                d.legalForm = "";
                d.email = "";
                d.postalAddress = "";
                d.id = "";
                d.inn = "";
                d.kpp = "";
                d.phoneNumber = "";
                d.legalAddress = "";
                d.signer = "";
                d.vat = "";
            }
            if (m.registrationNumber != null && m.hasOwnProperty("registrationNumber")) {
                d.registrationNumber = m.registrationNumber;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.legalForm != null && m.hasOwnProperty("legalForm")) {
                d.legalForm = m.legalForm;
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
            }
            if (m.postalAddress != null && m.hasOwnProperty("postalAddress")) {
                d.postalAddress = m.postalAddress;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.inn != null && m.hasOwnProperty("inn")) {
                d.inn = m.inn;
            }
            if (m.kpp != null && m.hasOwnProperty("kpp")) {
                d.kpp = m.kpp;
            }
            if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                d.phoneNumber = m.phoneNumber;
            }
            if (m.legalAddress != null && m.hasOwnProperty("legalAddress")) {
                d.legalAddress = m.legalAddress;
            }
            if (m.signer != null && m.hasOwnProperty("signer")) {
                d.signer = m.signer;
            }
            if (m.vat != null && m.hasOwnProperty("vat")) {
                d.vat = m.vat;
            }
            return d;
        };

        EditOrganizationInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EditOrganizationInput;
    })();

    transport.PaymentType = (function() {

        function PaymentType(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        PaymentType.prototype.id = "";
        PaymentType.prototype.name = "";

        PaymentType.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.PaymentType)
                return d;
            var m = new $root.transport.PaymentType();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            return m;
        };

        PaymentType.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            return d;
        };

        PaymentType.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PaymentType;
    })();

    transport.Place = (function() {

        function Place(p) {
            this.loadingTypes = [];
            this.contacts = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Place.prototype.id = "";
        Place.prototype.cargoOwner = null;
        Place.prototype.pointName = "";
        Place.prototype.settlement = "";
        Place.prototype.companyName = "";
        Place.prototype.address = "";
        Place.prototype.workingHoursFrom = "";
        Place.prototype.workingHoursTo = "";
        Place.prototype.loadingTypes = $util.emptyArray;
        Place.prototype.drivingDirectionsImg = "";
        Place.prototype.typePoint = "";
        Place.prototype.isArchived = false;
        Place.prototype.name = "";
        Place.prototype.fiasCode = "";
        Place.prototype.latitude = "";
        Place.prototype.longitude = "";
        Place.prototype.contacts = $util.emptyArray;
        Place.prototype.comment = "";
        Place.prototype.timezone = null;

        Place.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.Place)
                return d;
            var m = new $root.transport.Place();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.cargoOwner != null) {
                if (typeof d.cargoOwner !== "object")
                    throw TypeError(".transport.Place.cargoOwner: object expected");
                m.cargoOwner = $root.transport.CargoOwner.fromObject(d.cargoOwner);
            }
            if (d.pointName != null) {
                m.pointName = String(d.pointName);
            }
            if (d.settlement != null) {
                m.settlement = String(d.settlement);
            }
            if (d.companyName != null) {
                m.companyName = String(d.companyName);
            }
            if (d.address != null) {
                m.address = String(d.address);
            }
            if (d.workingHoursFrom != null) {
                m.workingHoursFrom = String(d.workingHoursFrom);
            }
            if (d.workingHoursTo != null) {
                m.workingHoursTo = String(d.workingHoursTo);
            }
            if (d.loadingTypes) {
                if (!Array.isArray(d.loadingTypes))
                    throw TypeError(".transport.Place.loadingTypes: array expected");
                m.loadingTypes = [];
                for (var i = 0; i < d.loadingTypes.length; ++i) {
                    if (typeof d.loadingTypes[i] !== "object")
                        throw TypeError(".transport.Place.loadingTypes: object expected");
                    m.loadingTypes[i] = $root.transport.LoadingType.fromObject(d.loadingTypes[i]);
                }
            }
            if (d.drivingDirectionsImg != null) {
                m.drivingDirectionsImg = String(d.drivingDirectionsImg);
            }
            if (d.typePoint != null) {
                m.typePoint = String(d.typePoint);
            }
            if (d.isArchived != null) {
                m.isArchived = Boolean(d.isArchived);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.fiasCode != null) {
                m.fiasCode = String(d.fiasCode);
            }
            if (d.latitude != null) {
                m.latitude = String(d.latitude);
            }
            if (d.longitude != null) {
                m.longitude = String(d.longitude);
            }
            if (d.contacts) {
                if (!Array.isArray(d.contacts))
                    throw TypeError(".transport.Place.contacts: array expected");
                m.contacts = [];
                for (var i = 0; i < d.contacts.length; ++i) {
                    if (typeof d.contacts[i] !== "object")
                        throw TypeError(".transport.Place.contacts: object expected");
                    m.contacts[i] = $root.transport.Contact.fromObject(d.contacts[i]);
                }
            }
            if (d.comment != null) {
                m.comment = String(d.comment);
            }
            if (d.timezone != null) {
                if (typeof d.timezone !== "object")
                    throw TypeError(".transport.Place.timezone: object expected");
                m.timezone = $root.transport.Timezone.fromObject(d.timezone);
            }
            return m;
        };

        Place.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.loadingTypes = [];
                d.contacts = [];
            }
            if (o.defaults) {
                d.id = "";
                d.cargoOwner = null;
                d.pointName = "";
                d.settlement = "";
                d.companyName = "";
                d.address = "";
                d.workingHoursFrom = "";
                d.workingHoursTo = "";
                d.drivingDirectionsImg = "";
                d.typePoint = "";
                d.isArchived = false;
                d.name = "";
                d.fiasCode = "";
                d.latitude = "";
                d.longitude = "";
                d.comment = "";
                d.timezone = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.cargoOwner != null && m.hasOwnProperty("cargoOwner")) {
                d.cargoOwner = $root.transport.CargoOwner.toObject(m.cargoOwner, o);
            }
            if (m.pointName != null && m.hasOwnProperty("pointName")) {
                d.pointName = m.pointName;
            }
            if (m.settlement != null && m.hasOwnProperty("settlement")) {
                d.settlement = m.settlement;
            }
            if (m.companyName != null && m.hasOwnProperty("companyName")) {
                d.companyName = m.companyName;
            }
            if (m.address != null && m.hasOwnProperty("address")) {
                d.address = m.address;
            }
            if (m.workingHoursFrom != null && m.hasOwnProperty("workingHoursFrom")) {
                d.workingHoursFrom = m.workingHoursFrom;
            }
            if (m.workingHoursTo != null && m.hasOwnProperty("workingHoursTo")) {
                d.workingHoursTo = m.workingHoursTo;
            }
            if (m.loadingTypes && m.loadingTypes.length) {
                d.loadingTypes = [];
                for (var j = 0; j < m.loadingTypes.length; ++j) {
                    d.loadingTypes[j] = $root.transport.LoadingType.toObject(m.loadingTypes[j], o);
                }
            }
            if (m.drivingDirectionsImg != null && m.hasOwnProperty("drivingDirectionsImg")) {
                d.drivingDirectionsImg = m.drivingDirectionsImg;
            }
            if (m.typePoint != null && m.hasOwnProperty("typePoint")) {
                d.typePoint = m.typePoint;
            }
            if (m.isArchived != null && m.hasOwnProperty("isArchived")) {
                d.isArchived = m.isArchived;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.fiasCode != null && m.hasOwnProperty("fiasCode")) {
                d.fiasCode = m.fiasCode;
            }
            if (m.latitude != null && m.hasOwnProperty("latitude")) {
                d.latitude = m.latitude;
            }
            if (m.longitude != null && m.hasOwnProperty("longitude")) {
                d.longitude = m.longitude;
            }
            if (m.contacts && m.contacts.length) {
                d.contacts = [];
                for (var j = 0; j < m.contacts.length; ++j) {
                    d.contacts[j] = $root.transport.Contact.toObject(m.contacts[j], o);
                }
            }
            if (m.comment != null && m.hasOwnProperty("comment")) {
                d.comment = m.comment;
            }
            if (m.timezone != null && m.hasOwnProperty("timezone")) {
                d.timezone = $root.transport.Timezone.toObject(m.timezone, o);
            }
            return d;
        };

        Place.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Place;
    })();

    transport.PlaceId = (function() {

        function PlaceId(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        PlaceId.prototype.id = "";

        PlaceId.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.PlaceId)
                return d;
            var m = new $root.transport.PlaceId();
            if (d.id != null) {
                m.id = String(d.id);
            }
            return m;
        };

        PlaceId.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            return d;
        };

        PlaceId.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return PlaceId;
    })();

    transport.PriceQuote = (function() {

        function PriceQuote(p) {
            this.responses = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        PriceQuote.prototype.id = "";
        PriceQuote.prototype.comment = null;
        PriceQuote.prototype.loadingPlace = "";
        PriceQuote.prototype.loadingPlaceFiasCode = "";
        PriceQuote.prototype.unloadingPlace = "";
        PriceQuote.prototype.unloadingPlaceFiasCode = "";
        PriceQuote.prototype.vehicleRequirements = null;
        PriceQuote.prototype.endDateTime = "";
        PriceQuote.prototype.priceStep = null;
        PriceQuote.prototype.cargoOwner = null;
        PriceQuote.prototype.carrierGroup = null;
        PriceQuote.prototype.isArchived = false;
        PriceQuote.prototype.createdAt = "";
        PriceQuote.prototype.organization = null;
        PriceQuote.prototype.responses = $util.emptyArray;
        PriceQuote.prototype.response = null;
        PriceQuote.prototype.myPrice = null;
        PriceQuote.prototype.myComment = null;
        PriceQuote.prototype.withTax = null;

        let $oneOfFields;

        Object.defineProperty(PriceQuote.prototype, "_comment", {
            get: $util.oneOfGetter($oneOfFields = ["comment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(PriceQuote.prototype, "_priceStep", {
            get: $util.oneOfGetter($oneOfFields = ["priceStep"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(PriceQuote.prototype, "_carrierGroup", {
            get: $util.oneOfGetter($oneOfFields = ["carrierGroup"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(PriceQuote.prototype, "_response", {
            get: $util.oneOfGetter($oneOfFields = ["response"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(PriceQuote.prototype, "_myPrice", {
            get: $util.oneOfGetter($oneOfFields = ["myPrice"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(PriceQuote.prototype, "_myComment", {
            get: $util.oneOfGetter($oneOfFields = ["myComment"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(PriceQuote.prototype, "_withTax", {
            get: $util.oneOfGetter($oneOfFields = ["withTax"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        PriceQuote.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.PriceQuote)
                return d;
            var m = new $root.transport.PriceQuote();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.comment != null) {
                m.comment = String(d.comment);
            }
            if (d.loadingPlace != null) {
                m.loadingPlace = String(d.loadingPlace);
            }
            if (d.loadingPlaceFiasCode != null) {
                m.loadingPlaceFiasCode = String(d.loadingPlaceFiasCode);
            }
            if (d.unloadingPlace != null) {
                m.unloadingPlace = String(d.unloadingPlace);
            }
            if (d.unloadingPlaceFiasCode != null) {
                m.unloadingPlaceFiasCode = String(d.unloadingPlaceFiasCode);
            }
            if (d.vehicleRequirements != null) {
                if (typeof d.vehicleRequirements !== "object")
                    throw TypeError(".transport.PriceQuote.vehicleRequirements: object expected");
                m.vehicleRequirements = $root.transport.Order.VehicleRequirements.fromObject(d.vehicleRequirements);
            }
            if (d.endDateTime != null) {
                m.endDateTime = String(d.endDateTime);
            }
            if (d.priceStep != null) {
                if ($util.Long)
                    (m.priceStep = $util.Long.fromValue(d.priceStep)).unsigned = false;
                else if (typeof d.priceStep === "string")
                    m.priceStep = parseInt(d.priceStep, 10);
                else if (typeof d.priceStep === "number")
                    m.priceStep = d.priceStep;
                else if (typeof d.priceStep === "object")
                    m.priceStep = new $util.LongBits(d.priceStep.low >>> 0, d.priceStep.high >>> 0).toNumber();
            }
            if (d.cargoOwner != null) {
                if (typeof d.cargoOwner !== "object")
                    throw TypeError(".transport.PriceQuote.cargoOwner: object expected");
                m.cargoOwner = $root.transport.CargoOwner.fromObject(d.cargoOwner);
            }
            if (d.carrierGroup != null) {
                if (typeof d.carrierGroup !== "object")
                    throw TypeError(".transport.PriceQuote.carrierGroup: object expected");
                m.carrierGroup = $root.transport.Carrier.CarrierGroup.fromObject(d.carrierGroup);
            }
            if (d.isArchived != null) {
                m.isArchived = Boolean(d.isArchived);
            }
            if (d.createdAt != null) {
                m.createdAt = String(d.createdAt);
            }
            if (d.organization != null) {
                if (typeof d.organization !== "object")
                    throw TypeError(".transport.PriceQuote.organization: object expected");
                m.organization = $root.transport.User.fromObject(d.organization);
            }
            if (d.responses) {
                if (!Array.isArray(d.responses))
                    throw TypeError(".transport.PriceQuote.responses: array expected");
                m.responses = [];
                for (var i = 0; i < d.responses.length; ++i) {
                    if (typeof d.responses[i] !== "object")
                        throw TypeError(".transport.PriceQuote.responses: object expected");
                    m.responses[i] = $root.transport.PriceQuote.Response.fromObject(d.responses[i]);
                }
            }
            if (d.response != null) {
                if (typeof d.response !== "object")
                    throw TypeError(".transport.PriceQuote.response: object expected");
                m.response = $root.transport.PriceQuote.Response.fromObject(d.response);
            }
            if (d.myPrice != null) {
                m.myPrice = d.myPrice | 0;
            }
            if (d.myComment != null) {
                m.myComment = String(d.myComment);
            }
            if (d.withTax != null) {
                m.withTax = Boolean(d.withTax);
            }
            return m;
        };

        PriceQuote.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.responses = [];
            }
            if (o.defaults) {
                d.id = "";
                d.loadingPlace = "";
                d.loadingPlaceFiasCode = "";
                d.unloadingPlace = "";
                d.unloadingPlaceFiasCode = "";
                d.vehicleRequirements = null;
                d.endDateTime = "";
                d.cargoOwner = null;
                d.isArchived = false;
                d.createdAt = "";
                d.organization = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.comment != null && m.hasOwnProperty("comment")) {
                d.comment = m.comment;
                if (o.oneofs)
                    d._comment = "comment";
            }
            if (m.loadingPlace != null && m.hasOwnProperty("loadingPlace")) {
                d.loadingPlace = m.loadingPlace;
            }
            if (m.loadingPlaceFiasCode != null && m.hasOwnProperty("loadingPlaceFiasCode")) {
                d.loadingPlaceFiasCode = m.loadingPlaceFiasCode;
            }
            if (m.unloadingPlace != null && m.hasOwnProperty("unloadingPlace")) {
                d.unloadingPlace = m.unloadingPlace;
            }
            if (m.unloadingPlaceFiasCode != null && m.hasOwnProperty("unloadingPlaceFiasCode")) {
                d.unloadingPlaceFiasCode = m.unloadingPlaceFiasCode;
            }
            if (m.vehicleRequirements != null && m.hasOwnProperty("vehicleRequirements")) {
                d.vehicleRequirements = $root.transport.Order.VehicleRequirements.toObject(m.vehicleRequirements, o);
            }
            if (m.endDateTime != null && m.hasOwnProperty("endDateTime")) {
                d.endDateTime = m.endDateTime;
            }
            if (m.priceStep != null && m.hasOwnProperty("priceStep")) {
                if (typeof m.priceStep === "number")
                    d.priceStep = o.longs === String ? String(m.priceStep) : m.priceStep;
                else
                    d.priceStep = o.longs === String ? $util.Long.prototype.toString.call(m.priceStep) : o.longs === Number ? new $util.LongBits(m.priceStep.low >>> 0, m.priceStep.high >>> 0).toNumber() : m.priceStep;
                if (o.oneofs)
                    d._priceStep = "priceStep";
            }
            if (m.cargoOwner != null && m.hasOwnProperty("cargoOwner")) {
                d.cargoOwner = $root.transport.CargoOwner.toObject(m.cargoOwner, o);
            }
            if (m.carrierGroup != null && m.hasOwnProperty("carrierGroup")) {
                d.carrierGroup = $root.transport.Carrier.CarrierGroup.toObject(m.carrierGroup, o);
                if (o.oneofs)
                    d._carrierGroup = "carrierGroup";
            }
            if (m.isArchived != null && m.hasOwnProperty("isArchived")) {
                d.isArchived = m.isArchived;
            }
            if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                d.createdAt = m.createdAt;
            }
            if (m.organization != null && m.hasOwnProperty("organization")) {
                d.organization = $root.transport.User.toObject(m.organization, o);
            }
            if (m.responses && m.responses.length) {
                d.responses = [];
                for (var j = 0; j < m.responses.length; ++j) {
                    d.responses[j] = $root.transport.PriceQuote.Response.toObject(m.responses[j], o);
                }
            }
            if (m.response != null && m.hasOwnProperty("response")) {
                d.response = $root.transport.PriceQuote.Response.toObject(m.response, o);
                if (o.oneofs)
                    d._response = "response";
            }
            if (m.myPrice != null && m.hasOwnProperty("myPrice")) {
                d.myPrice = m.myPrice;
                if (o.oneofs)
                    d._myPrice = "myPrice";
            }
            if (m.myComment != null && m.hasOwnProperty("myComment")) {
                d.myComment = m.myComment;
                if (o.oneofs)
                    d._myComment = "myComment";
            }
            if (m.withTax != null && m.hasOwnProperty("withTax")) {
                d.withTax = m.withTax;
                if (o.oneofs)
                    d._withTax = "withTax";
            }
            return d;
        };

        PriceQuote.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        PriceQuote.Response = (function() {

            function Response(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Response.prototype.id = "";
            Response.prototype.createdAt = "";
            Response.prototype.organization = null;
            Response.prototype.price = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            Response.prototype.isVatIncluded = false;
            Response.prototype.comment = "";

            Response.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.PriceQuote.Response)
                    return d;
                var m = new $root.transport.PriceQuote.Response();
                if (d.id != null) {
                    m.id = String(d.id);
                }
                if (d.createdAt != null) {
                    m.createdAt = String(d.createdAt);
                }
                if (d.organization != null) {
                    if (typeof d.organization !== "object")
                        throw TypeError(".transport.PriceQuote.Response.organization: object expected");
                    m.organization = $root.transport.User.fromObject(d.organization);
                }
                if (d.price != null) {
                    if ($util.Long)
                        (m.price = $util.Long.fromValue(d.price)).unsigned = false;
                    else if (typeof d.price === "string")
                        m.price = parseInt(d.price, 10);
                    else if (typeof d.price === "number")
                        m.price = d.price;
                    else if (typeof d.price === "object")
                        m.price = new $util.LongBits(d.price.low >>> 0, d.price.high >>> 0).toNumber();
                }
                if (d.isVatIncluded != null) {
                    m.isVatIncluded = Boolean(d.isVatIncluded);
                }
                if (d.comment != null) {
                    m.comment = String(d.comment);
                }
                return m;
            };

            Response.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.id = "";
                    d.createdAt = "";
                    d.organization = null;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.price = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.price = o.longs === String ? "0" : 0;
                    d.isVatIncluded = false;
                    d.comment = "";
                }
                if (m.id != null && m.hasOwnProperty("id")) {
                    d.id = m.id;
                }
                if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                    d.createdAt = m.createdAt;
                }
                if (m.organization != null && m.hasOwnProperty("organization")) {
                    d.organization = $root.transport.User.toObject(m.organization, o);
                }
                if (m.price != null && m.hasOwnProperty("price")) {
                    if (typeof m.price === "number")
                        d.price = o.longs === String ? String(m.price) : m.price;
                    else
                        d.price = o.longs === String ? $util.Long.prototype.toString.call(m.price) : o.longs === Number ? new $util.LongBits(m.price.low >>> 0, m.price.high >>> 0).toNumber() : m.price;
                }
                if (m.isVatIncluded != null && m.hasOwnProperty("isVatIncluded")) {
                    d.isVatIncluded = m.isVatIncluded;
                }
                if (m.comment != null && m.hasOwnProperty("comment")) {
                    d.comment = m.comment;
                }
                return d;
            };

            Response.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Response;
        })();

        PriceQuote.RespondOnPriceQuotationInput = (function() {

            function RespondOnPriceQuotationInput(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            RespondOnPriceQuotationInput.prototype.priceQuotationId = "";
            RespondOnPriceQuotationInput.prototype.price = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            RespondOnPriceQuotationInput.prototype.isVatIncluded = false;
            RespondOnPriceQuotationInput.prototype.comment = "";

            RespondOnPriceQuotationInput.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.PriceQuote.RespondOnPriceQuotationInput)
                    return d;
                var m = new $root.transport.PriceQuote.RespondOnPriceQuotationInput();
                if (d.priceQuotationId != null) {
                    m.priceQuotationId = String(d.priceQuotationId);
                }
                if (d.price != null) {
                    if ($util.Long)
                        (m.price = $util.Long.fromValue(d.price)).unsigned = false;
                    else if (typeof d.price === "string")
                        m.price = parseInt(d.price, 10);
                    else if (typeof d.price === "number")
                        m.price = d.price;
                    else if (typeof d.price === "object")
                        m.price = new $util.LongBits(d.price.low >>> 0, d.price.high >>> 0).toNumber();
                }
                if (d.isVatIncluded != null) {
                    m.isVatIncluded = Boolean(d.isVatIncluded);
                }
                if (d.comment != null) {
                    m.comment = String(d.comment);
                }
                return m;
            };

            RespondOnPriceQuotationInput.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.priceQuotationId = "";
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.price = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.price = o.longs === String ? "0" : 0;
                    d.isVatIncluded = false;
                    d.comment = "";
                }
                if (m.priceQuotationId != null && m.hasOwnProperty("priceQuotationId")) {
                    d.priceQuotationId = m.priceQuotationId;
                }
                if (m.price != null && m.hasOwnProperty("price")) {
                    if (typeof m.price === "number")
                        d.price = o.longs === String ? String(m.price) : m.price;
                    else
                        d.price = o.longs === String ? $util.Long.prototype.toString.call(m.price) : o.longs === Number ? new $util.LongBits(m.price.low >>> 0, m.price.high >>> 0).toNumber() : m.price;
                }
                if (m.isVatIncluded != null && m.hasOwnProperty("isVatIncluded")) {
                    d.isVatIncluded = m.isVatIncluded;
                }
                if (m.comment != null && m.hasOwnProperty("comment")) {
                    d.comment = m.comment;
                }
                return d;
            };

            RespondOnPriceQuotationInput.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return RespondOnPriceQuotationInput;
        })();

        return PriceQuote;
    })();

    transport.AuctionControlReport = (function() {

        function AuctionControlReport(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        AuctionControlReport.prototype.id = "";
        AuctionControlReport.prototype.productionDivisionName = "";
        AuctionControlReport.prototype.queueName = "";
        AuctionControlReport.prototype.routeName = "";
        AuctionControlReport.prototype.endDate = "";
        AuctionControlReport.prototype.auctionWonCount = 0;
        AuctionControlReport.prototype.auctionCanceledCount = 0;
        AuctionControlReport.prototype.cancelPercent = 0;

        AuctionControlReport.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.AuctionControlReport)
                return d;
            var m = new $root.transport.AuctionControlReport();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.productionDivisionName != null) {
                m.productionDivisionName = String(d.productionDivisionName);
            }
            if (d.queueName != null) {
                m.queueName = String(d.queueName);
            }
            if (d.routeName != null) {
                m.routeName = String(d.routeName);
            }
            if (d.endDate != null) {
                m.endDate = String(d.endDate);
            }
            if (d.auctionWonCount != null) {
                m.auctionWonCount = d.auctionWonCount | 0;
            }
            if (d.auctionCanceledCount != null) {
                m.auctionCanceledCount = d.auctionCanceledCount | 0;
            }
            if (d.cancelPercent != null) {
                m.cancelPercent = d.cancelPercent | 0;
            }
            return m;
        };

        AuctionControlReport.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.productionDivisionName = "";
                d.queueName = "";
                d.routeName = "";
                d.endDate = "";
                d.auctionWonCount = 0;
                d.auctionCanceledCount = 0;
                d.cancelPercent = 0;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.productionDivisionName != null && m.hasOwnProperty("productionDivisionName")) {
                d.productionDivisionName = m.productionDivisionName;
            }
            if (m.queueName != null && m.hasOwnProperty("queueName")) {
                d.queueName = m.queueName;
            }
            if (m.routeName != null && m.hasOwnProperty("routeName")) {
                d.routeName = m.routeName;
            }
            if (m.endDate != null && m.hasOwnProperty("endDate")) {
                d.endDate = m.endDate;
            }
            if (m.auctionWonCount != null && m.hasOwnProperty("auctionWonCount")) {
                d.auctionWonCount = m.auctionWonCount;
            }
            if (m.auctionCanceledCount != null && m.hasOwnProperty("auctionCanceledCount")) {
                d.auctionCanceledCount = m.auctionCanceledCount;
            }
            if (m.cancelPercent != null && m.hasOwnProperty("cancelPercent")) {
                d.cancelPercent = m.cancelPercent;
            }
            return d;
        };

        AuctionControlReport.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AuctionControlReport;
    })();

    transport.AuctionControlReportDetails = (function() {

        function AuctionControlReportDetails(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        AuctionControlReportDetails.prototype.externalNo = "";
        AuctionControlReportDetails.prototype.orderId = "";
        AuctionControlReportDetails.prototype.createdDate = "";
        AuctionControlReportDetails.prototype.assignedDate = "";
        AuctionControlReportDetails.prototype.canceledDate = "";
        AuctionControlReportDetails.prototype.id = "";

        AuctionControlReportDetails.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.AuctionControlReportDetails)
                return d;
            var m = new $root.transport.AuctionControlReportDetails();
            if (d.externalNo != null) {
                m.externalNo = String(d.externalNo);
            }
            if (d.orderId != null) {
                m.orderId = String(d.orderId);
            }
            if (d.createdDate != null) {
                m.createdDate = String(d.createdDate);
            }
            if (d.assignedDate != null) {
                m.assignedDate = String(d.assignedDate);
            }
            if (d.canceledDate != null) {
                m.canceledDate = String(d.canceledDate);
            }
            if (d.id != null) {
                m.id = String(d.id);
            }
            return m;
        };

        AuctionControlReportDetails.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.externalNo = "";
                d.orderId = "";
                d.createdDate = "";
                d.assignedDate = "";
                d.canceledDate = "";
                d.id = "";
            }
            if (m.externalNo != null && m.hasOwnProperty("externalNo")) {
                d.externalNo = m.externalNo;
            }
            if (m.orderId != null && m.hasOwnProperty("orderId")) {
                d.orderId = m.orderId;
            }
            if (m.createdDate != null && m.hasOwnProperty("createdDate")) {
                d.createdDate = m.createdDate;
            }
            if (m.assignedDate != null && m.hasOwnProperty("assignedDate")) {
                d.assignedDate = m.assignedDate;
            }
            if (m.canceledDate != null && m.hasOwnProperty("canceledDate")) {
                d.canceledDate = m.canceledDate;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            return d;
        };

        AuctionControlReportDetails.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AuctionControlReportDetails;
    })();

    transport.SampleService = (function() {

        function SampleService(rpcImpl, requestDelimited, responseDelimited) {
            $protobuf.rpc.Service.call(this, rpcImpl, requestDelimited, responseDelimited);
        }

        (SampleService.prototype = Object.create($protobuf.rpc.Service.prototype)).constructor = SampleService;


        Object.defineProperty(SampleService.prototype.sampleServiceMethod = function sampleServiceMethod(request, callback) {
            return this.rpcCall(sampleServiceMethod, $root.transport.SampleServiceRequest, $root.transport.SampleServiceResponse, request, callback);
        }, "name", { value: "SampleServiceMethod" });

        return SampleService;
    })();

    transport.RouteSheet = (function() {

        function RouteSheet(p) {
            this.actualProblemEvents = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        RouteSheet.prototype.id = "";
        RouteSheet.prototype.actualProblemEvents = $util.emptyArray;
        RouteSheet.prototype.isDriverOnline = false;

        RouteSheet.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.RouteSheet)
                return d;
            var m = new $root.transport.RouteSheet();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.actualProblemEvents) {
                if (!Array.isArray(d.actualProblemEvents))
                    throw TypeError(".transport.RouteSheet.actualProblemEvents: array expected");
                m.actualProblemEvents = [];
                for (var i = 0; i < d.actualProblemEvents.length; ++i) {
                    if (typeof d.actualProblemEvents[i] !== "object")
                        throw TypeError(".transport.RouteSheet.actualProblemEvents: object expected");
                    m.actualProblemEvents[i] = $root.transport.RouteSheetEvent.fromObject(d.actualProblemEvents[i]);
                }
            }
            if (d.isDriverOnline != null) {
                m.isDriverOnline = Boolean(d.isDriverOnline);
            }
            return m;
        };

        RouteSheet.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.actualProblemEvents = [];
            }
            if (o.defaults) {
                d.id = "";
                d.isDriverOnline = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.actualProblemEvents && m.actualProblemEvents.length) {
                d.actualProblemEvents = [];
                for (var j = 0; j < m.actualProblemEvents.length; ++j) {
                    d.actualProblemEvents[j] = $root.transport.RouteSheetEvent.toObject(m.actualProblemEvents[j], o);
                }
            }
            if (m.isDriverOnline != null && m.hasOwnProperty("isDriverOnline")) {
                d.isDriverOnline = m.isDriverOnline;
            }
            return d;
        };

        RouteSheet.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RouteSheet;
    })();

    transport.RouteSheetEvent = (function() {

        function RouteSheetEvent(p) {
            this.documents = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        RouteSheetEvent.prototype.id = "";
        RouteSheetEvent.prototype.event = "";
        RouteSheetEvent.prototype.dateTime = "";
        RouteSheetEvent.prototype.longitude = "";
        RouteSheetEvent.prototype.latitude = "";
        RouteSheetEvent.prototype.issueTopic = "";
        RouteSheetEvent.prototype.issueText = "";
        RouteSheetEvent.prototype.driver = null;
        RouteSheetEvent.prototype.storagePoint = null;
        RouteSheetEvent.prototype.documents = $util.emptyArray;

        RouteSheetEvent.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.RouteSheetEvent)
                return d;
            var m = new $root.transport.RouteSheetEvent();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.event != null) {
                m.event = String(d.event);
            }
            if (d.dateTime != null) {
                m.dateTime = String(d.dateTime);
            }
            if (d.longitude != null) {
                m.longitude = String(d.longitude);
            }
            if (d.latitude != null) {
                m.latitude = String(d.latitude);
            }
            if (d.issueTopic != null) {
                m.issueTopic = String(d.issueTopic);
            }
            if (d.issueText != null) {
                m.issueText = String(d.issueText);
            }
            if (d.driver != null) {
                if (typeof d.driver !== "object")
                    throw TypeError(".transport.RouteSheetEvent.driver: object expected");
                m.driver = $root.transport.Driver.fromObject(d.driver);
            }
            if (d.storagePoint != null) {
                if (typeof d.storagePoint !== "object")
                    throw TypeError(".transport.RouteSheetEvent.storagePoint: object expected");
                m.storagePoint = $root.transport.Order.StoragePoint.fromObject(d.storagePoint);
            }
            if (d.documents) {
                if (!Array.isArray(d.documents))
                    throw TypeError(".transport.RouteSheetEvent.documents: array expected");
                m.documents = [];
                for (var i = 0; i < d.documents.length; ++i) {
                    if (typeof d.documents[i] !== "object")
                        throw TypeError(".transport.RouteSheetEvent.documents: object expected");
                    m.documents[i] = $root.transport.EventAttachedDoc.fromObject(d.documents[i]);
                }
            }
            return m;
        };

        RouteSheetEvent.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.documents = [];
            }
            if (o.defaults) {
                d.id = "";
                d.event = "";
                d.dateTime = "";
                d.longitude = "";
                d.latitude = "";
                d.issueTopic = "";
                d.issueText = "";
                d.driver = null;
                d.storagePoint = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.event != null && m.hasOwnProperty("event")) {
                d.event = m.event;
            }
            if (m.dateTime != null && m.hasOwnProperty("dateTime")) {
                d.dateTime = m.dateTime;
            }
            if (m.longitude != null && m.hasOwnProperty("longitude")) {
                d.longitude = m.longitude;
            }
            if (m.latitude != null && m.hasOwnProperty("latitude")) {
                d.latitude = m.latitude;
            }
            if (m.issueTopic != null && m.hasOwnProperty("issueTopic")) {
                d.issueTopic = m.issueTopic;
            }
            if (m.issueText != null && m.hasOwnProperty("issueText")) {
                d.issueText = m.issueText;
            }
            if (m.driver != null && m.hasOwnProperty("driver")) {
                d.driver = $root.transport.Driver.toObject(m.driver, o);
            }
            if (m.storagePoint != null && m.hasOwnProperty("storagePoint")) {
                d.storagePoint = $root.transport.Order.StoragePoint.toObject(m.storagePoint, o);
            }
            if (m.documents && m.documents.length) {
                d.documents = [];
                for (var j = 0; j < m.documents.length; ++j) {
                    d.documents[j] = $root.transport.EventAttachedDoc.toObject(m.documents[j], o);
                }
            }
            return d;
        };

        RouteSheetEvent.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return RouteSheetEvent;
    })();

    transport.EventAttachedDoc = (function() {

        function EventAttachedDoc(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        EventAttachedDoc.prototype.filename = "";
        EventAttachedDoc.prototype.url = "";
        EventAttachedDoc.prototype.datetime = "";

        EventAttachedDoc.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.EventAttachedDoc)
                return d;
            var m = new $root.transport.EventAttachedDoc();
            if (d.filename != null) {
                m.filename = String(d.filename);
            }
            if (d.url != null) {
                m.url = String(d.url);
            }
            if (d.datetime != null) {
                m.datetime = String(d.datetime);
            }
            return m;
        };

        EventAttachedDoc.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.filename = "";
                d.url = "";
                d.datetime = "";
            }
            if (m.filename != null && m.hasOwnProperty("filename")) {
                d.filename = m.filename;
            }
            if (m.url != null && m.hasOwnProperty("url")) {
                d.url = m.url;
            }
            if (m.datetime != null && m.hasOwnProperty("datetime")) {
                d.datetime = m.datetime;
            }
            return d;
        };

        EventAttachedDoc.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EventAttachedDoc;
    })();

    transport.Tender = (function() {

        function Tender(p) {
            this.vehicleRequirements = [];
            this.bids = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Tender.prototype.id = "";
        Tender.prototype.lotNumber = "";
        Tender.prototype.bidsEndDatetime = "";
        Tender.prototype.loadingSettlement = "";
        Tender.prototype.department = "";
        Tender.prototype.course = "";
        Tender.prototype.unloadingPlace = "";
        Tender.prototype.activeDateFrom = "";
        Tender.prototype.activeDateTo = "";
        Tender.prototype.cargoWeightFrom = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Tender.prototype.cargoWeightTo = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Tender.prototype.vehicleRequirements = $util.emptyArray;
        Tender.prototype.ordersQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Tender.prototype.maxOrderPrice = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Tender.prototype.winnerPrice = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Tender.prototype.displayedStatus = "";
        Tender.prototype.lastBidPrice = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Tender.prototype.limitRate = false;
        Tender.prototype.courseFias = "";
        Tender.prototype.loadingSettlementFias = "";
        Tender.prototype.stepPrice = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Tender.prototype.bidsQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Tender.prototype.bids = $util.emptyArray;
        Tender.prototype.isVatIncluded = false;
        Tender.prototype.carrierGroup = null;
        Tender.prototype.comment = "";
        Tender.prototype.carrierOrganization = null;
        Tender.prototype.isTn = false;
        Tender.prototype.lastBid = null;

        Tender.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.Tender)
                return d;
            var m = new $root.transport.Tender();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.lotNumber != null) {
                m.lotNumber = String(d.lotNumber);
            }
            if (d.bidsEndDatetime != null) {
                m.bidsEndDatetime = String(d.bidsEndDatetime);
            }
            if (d.loadingSettlement != null) {
                m.loadingSettlement = String(d.loadingSettlement);
            }
            if (d.department != null) {
                m.department = String(d.department);
            }
            if (d.course != null) {
                m.course = String(d.course);
            }
            if (d.unloadingPlace != null) {
                m.unloadingPlace = String(d.unloadingPlace);
            }
            if (d.activeDateFrom != null) {
                m.activeDateFrom = String(d.activeDateFrom);
            }
            if (d.activeDateTo != null) {
                m.activeDateTo = String(d.activeDateTo);
            }
            if (d.cargoWeightFrom != null) {
                if ($util.Long)
                    (m.cargoWeightFrom = $util.Long.fromValue(d.cargoWeightFrom)).unsigned = false;
                else if (typeof d.cargoWeightFrom === "string")
                    m.cargoWeightFrom = parseInt(d.cargoWeightFrom, 10);
                else if (typeof d.cargoWeightFrom === "number")
                    m.cargoWeightFrom = d.cargoWeightFrom;
                else if (typeof d.cargoWeightFrom === "object")
                    m.cargoWeightFrom = new $util.LongBits(d.cargoWeightFrom.low >>> 0, d.cargoWeightFrom.high >>> 0).toNumber();
            }
            if (d.cargoWeightTo != null) {
                if ($util.Long)
                    (m.cargoWeightTo = $util.Long.fromValue(d.cargoWeightTo)).unsigned = false;
                else if (typeof d.cargoWeightTo === "string")
                    m.cargoWeightTo = parseInt(d.cargoWeightTo, 10);
                else if (typeof d.cargoWeightTo === "number")
                    m.cargoWeightTo = d.cargoWeightTo;
                else if (typeof d.cargoWeightTo === "object")
                    m.cargoWeightTo = new $util.LongBits(d.cargoWeightTo.low >>> 0, d.cargoWeightTo.high >>> 0).toNumber();
            }
            if (d.vehicleRequirements) {
                if (!Array.isArray(d.vehicleRequirements))
                    throw TypeError(".transport.Tender.vehicleRequirements: array expected");
                m.vehicleRequirements = [];
                for (var i = 0; i < d.vehicleRequirements.length; ++i) {
                    if (typeof d.vehicleRequirements[i] !== "object")
                        throw TypeError(".transport.Tender.vehicleRequirements: object expected");
                    m.vehicleRequirements[i] = $root.transport.VehicleRequirements.fromObject(d.vehicleRequirements[i]);
                }
            }
            if (d.ordersQuantity != null) {
                if ($util.Long)
                    (m.ordersQuantity = $util.Long.fromValue(d.ordersQuantity)).unsigned = false;
                else if (typeof d.ordersQuantity === "string")
                    m.ordersQuantity = parseInt(d.ordersQuantity, 10);
                else if (typeof d.ordersQuantity === "number")
                    m.ordersQuantity = d.ordersQuantity;
                else if (typeof d.ordersQuantity === "object")
                    m.ordersQuantity = new $util.LongBits(d.ordersQuantity.low >>> 0, d.ordersQuantity.high >>> 0).toNumber();
            }
            if (d.maxOrderPrice != null) {
                if ($util.Long)
                    (m.maxOrderPrice = $util.Long.fromValue(d.maxOrderPrice)).unsigned = false;
                else if (typeof d.maxOrderPrice === "string")
                    m.maxOrderPrice = parseInt(d.maxOrderPrice, 10);
                else if (typeof d.maxOrderPrice === "number")
                    m.maxOrderPrice = d.maxOrderPrice;
                else if (typeof d.maxOrderPrice === "object")
                    m.maxOrderPrice = new $util.LongBits(d.maxOrderPrice.low >>> 0, d.maxOrderPrice.high >>> 0).toNumber();
            }
            if (d.winnerPrice != null) {
                if ($util.Long)
                    (m.winnerPrice = $util.Long.fromValue(d.winnerPrice)).unsigned = false;
                else if (typeof d.winnerPrice === "string")
                    m.winnerPrice = parseInt(d.winnerPrice, 10);
                else if (typeof d.winnerPrice === "number")
                    m.winnerPrice = d.winnerPrice;
                else if (typeof d.winnerPrice === "object")
                    m.winnerPrice = new $util.LongBits(d.winnerPrice.low >>> 0, d.winnerPrice.high >>> 0).toNumber();
            }
            if (d.displayedStatus != null) {
                m.displayedStatus = String(d.displayedStatus);
            }
            if (d.lastBidPrice != null) {
                if ($util.Long)
                    (m.lastBidPrice = $util.Long.fromValue(d.lastBidPrice)).unsigned = false;
                else if (typeof d.lastBidPrice === "string")
                    m.lastBidPrice = parseInt(d.lastBidPrice, 10);
                else if (typeof d.lastBidPrice === "number")
                    m.lastBidPrice = d.lastBidPrice;
                else if (typeof d.lastBidPrice === "object")
                    m.lastBidPrice = new $util.LongBits(d.lastBidPrice.low >>> 0, d.lastBidPrice.high >>> 0).toNumber();
            }
            if (d.limitRate != null) {
                m.limitRate = Boolean(d.limitRate);
            }
            if (d.courseFias != null) {
                m.courseFias = String(d.courseFias);
            }
            if (d.loadingSettlementFias != null) {
                m.loadingSettlementFias = String(d.loadingSettlementFias);
            }
            if (d.stepPrice != null) {
                if ($util.Long)
                    (m.stepPrice = $util.Long.fromValue(d.stepPrice)).unsigned = false;
                else if (typeof d.stepPrice === "string")
                    m.stepPrice = parseInt(d.stepPrice, 10);
                else if (typeof d.stepPrice === "number")
                    m.stepPrice = d.stepPrice;
                else if (typeof d.stepPrice === "object")
                    m.stepPrice = new $util.LongBits(d.stepPrice.low >>> 0, d.stepPrice.high >>> 0).toNumber();
            }
            if (d.bidsQuantity != null) {
                if ($util.Long)
                    (m.bidsQuantity = $util.Long.fromValue(d.bidsQuantity)).unsigned = false;
                else if (typeof d.bidsQuantity === "string")
                    m.bidsQuantity = parseInt(d.bidsQuantity, 10);
                else if (typeof d.bidsQuantity === "number")
                    m.bidsQuantity = d.bidsQuantity;
                else if (typeof d.bidsQuantity === "object")
                    m.bidsQuantity = new $util.LongBits(d.bidsQuantity.low >>> 0, d.bidsQuantity.high >>> 0).toNumber();
            }
            if (d.bids) {
                if (!Array.isArray(d.bids))
                    throw TypeError(".transport.Tender.bids: array expected");
                m.bids = [];
                for (var i = 0; i < d.bids.length; ++i) {
                    if (typeof d.bids[i] !== "object")
                        throw TypeError(".transport.Tender.bids: object expected");
                    m.bids[i] = $root.transport.Bids.fromObject(d.bids[i]);
                }
            }
            if (d.isVatIncluded != null) {
                m.isVatIncluded = Boolean(d.isVatIncluded);
            }
            if (d.carrierGroup != null) {
                if (typeof d.carrierGroup !== "object")
                    throw TypeError(".transport.Tender.carrierGroup: object expected");
                m.carrierGroup = $root.transport.Carrier.CarrierGroup.fromObject(d.carrierGroup);
            }
            if (d.comment != null) {
                m.comment = String(d.comment);
            }
            if (d.carrierOrganization != null) {
                if (typeof d.carrierOrganization !== "object")
                    throw TypeError(".transport.Tender.carrierOrganization: object expected");
                m.carrierOrganization = $root.transport.Carrier.Organization.fromObject(d.carrierOrganization);
            }
            if (d.isTn != null) {
                m.isTn = Boolean(d.isTn);
            }
            if (d.lastBid != null) {
                if (typeof d.lastBid !== "object")
                    throw TypeError(".transport.Tender.lastBid: object expected");
                m.lastBid = $root.transport.Bids.fromObject(d.lastBid);
            }
            return m;
        };

        Tender.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.vehicleRequirements = [];
                d.bids = [];
            }
            if (o.defaults) {
                d.id = "";
                d.lotNumber = "";
                d.bidsEndDatetime = "";
                d.loadingSettlement = "";
                d.department = "";
                d.course = "";
                d.unloadingPlace = "";
                d.activeDateFrom = "";
                d.activeDateTo = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.cargoWeightFrom = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.cargoWeightFrom = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.cargoWeightTo = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.cargoWeightTo = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.ordersQuantity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.ordersQuantity = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.maxOrderPrice = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.maxOrderPrice = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.winnerPrice = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.winnerPrice = o.longs === String ? "0" : 0;
                d.displayedStatus = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.lastBidPrice = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.lastBidPrice = o.longs === String ? "0" : 0;
                d.limitRate = false;
                d.courseFias = "";
                d.loadingSettlementFias = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.stepPrice = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.stepPrice = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.bidsQuantity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.bidsQuantity = o.longs === String ? "0" : 0;
                d.isVatIncluded = false;
                d.carrierGroup = null;
                d.comment = "";
                d.carrierOrganization = null;
                d.isTn = false;
                d.lastBid = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.lotNumber != null && m.hasOwnProperty("lotNumber")) {
                d.lotNumber = m.lotNumber;
            }
            if (m.bidsEndDatetime != null && m.hasOwnProperty("bidsEndDatetime")) {
                d.bidsEndDatetime = m.bidsEndDatetime;
            }
            if (m.loadingSettlement != null && m.hasOwnProperty("loadingSettlement")) {
                d.loadingSettlement = m.loadingSettlement;
            }
            if (m.department != null && m.hasOwnProperty("department")) {
                d.department = m.department;
            }
            if (m.course != null && m.hasOwnProperty("course")) {
                d.course = m.course;
            }
            if (m.unloadingPlace != null && m.hasOwnProperty("unloadingPlace")) {
                d.unloadingPlace = m.unloadingPlace;
            }
            if (m.activeDateFrom != null && m.hasOwnProperty("activeDateFrom")) {
                d.activeDateFrom = m.activeDateFrom;
            }
            if (m.activeDateTo != null && m.hasOwnProperty("activeDateTo")) {
                d.activeDateTo = m.activeDateTo;
            }
            if (m.cargoWeightFrom != null && m.hasOwnProperty("cargoWeightFrom")) {
                if (typeof m.cargoWeightFrom === "number")
                    d.cargoWeightFrom = o.longs === String ? String(m.cargoWeightFrom) : m.cargoWeightFrom;
                else
                    d.cargoWeightFrom = o.longs === String ? $util.Long.prototype.toString.call(m.cargoWeightFrom) : o.longs === Number ? new $util.LongBits(m.cargoWeightFrom.low >>> 0, m.cargoWeightFrom.high >>> 0).toNumber() : m.cargoWeightFrom;
            }
            if (m.cargoWeightTo != null && m.hasOwnProperty("cargoWeightTo")) {
                if (typeof m.cargoWeightTo === "number")
                    d.cargoWeightTo = o.longs === String ? String(m.cargoWeightTo) : m.cargoWeightTo;
                else
                    d.cargoWeightTo = o.longs === String ? $util.Long.prototype.toString.call(m.cargoWeightTo) : o.longs === Number ? new $util.LongBits(m.cargoWeightTo.low >>> 0, m.cargoWeightTo.high >>> 0).toNumber() : m.cargoWeightTo;
            }
            if (m.vehicleRequirements && m.vehicleRequirements.length) {
                d.vehicleRequirements = [];
                for (var j = 0; j < m.vehicleRequirements.length; ++j) {
                    d.vehicleRequirements[j] = $root.transport.VehicleRequirements.toObject(m.vehicleRequirements[j], o);
                }
            }
            if (m.ordersQuantity != null && m.hasOwnProperty("ordersQuantity")) {
                if (typeof m.ordersQuantity === "number")
                    d.ordersQuantity = o.longs === String ? String(m.ordersQuantity) : m.ordersQuantity;
                else
                    d.ordersQuantity = o.longs === String ? $util.Long.prototype.toString.call(m.ordersQuantity) : o.longs === Number ? new $util.LongBits(m.ordersQuantity.low >>> 0, m.ordersQuantity.high >>> 0).toNumber() : m.ordersQuantity;
            }
            if (m.maxOrderPrice != null && m.hasOwnProperty("maxOrderPrice")) {
                if (typeof m.maxOrderPrice === "number")
                    d.maxOrderPrice = o.longs === String ? String(m.maxOrderPrice) : m.maxOrderPrice;
                else
                    d.maxOrderPrice = o.longs === String ? $util.Long.prototype.toString.call(m.maxOrderPrice) : o.longs === Number ? new $util.LongBits(m.maxOrderPrice.low >>> 0, m.maxOrderPrice.high >>> 0).toNumber() : m.maxOrderPrice;
            }
            if (m.winnerPrice != null && m.hasOwnProperty("winnerPrice")) {
                if (typeof m.winnerPrice === "number")
                    d.winnerPrice = o.longs === String ? String(m.winnerPrice) : m.winnerPrice;
                else
                    d.winnerPrice = o.longs === String ? $util.Long.prototype.toString.call(m.winnerPrice) : o.longs === Number ? new $util.LongBits(m.winnerPrice.low >>> 0, m.winnerPrice.high >>> 0).toNumber() : m.winnerPrice;
            }
            if (m.displayedStatus != null && m.hasOwnProperty("displayedStatus")) {
                d.displayedStatus = m.displayedStatus;
            }
            if (m.lastBidPrice != null && m.hasOwnProperty("lastBidPrice")) {
                if (typeof m.lastBidPrice === "number")
                    d.lastBidPrice = o.longs === String ? String(m.lastBidPrice) : m.lastBidPrice;
                else
                    d.lastBidPrice = o.longs === String ? $util.Long.prototype.toString.call(m.lastBidPrice) : o.longs === Number ? new $util.LongBits(m.lastBidPrice.low >>> 0, m.lastBidPrice.high >>> 0).toNumber() : m.lastBidPrice;
            }
            if (m.limitRate != null && m.hasOwnProperty("limitRate")) {
                d.limitRate = m.limitRate;
            }
            if (m.courseFias != null && m.hasOwnProperty("courseFias")) {
                d.courseFias = m.courseFias;
            }
            if (m.loadingSettlementFias != null && m.hasOwnProperty("loadingSettlementFias")) {
                d.loadingSettlementFias = m.loadingSettlementFias;
            }
            if (m.stepPrice != null && m.hasOwnProperty("stepPrice")) {
                if (typeof m.stepPrice === "number")
                    d.stepPrice = o.longs === String ? String(m.stepPrice) : m.stepPrice;
                else
                    d.stepPrice = o.longs === String ? $util.Long.prototype.toString.call(m.stepPrice) : o.longs === Number ? new $util.LongBits(m.stepPrice.low >>> 0, m.stepPrice.high >>> 0).toNumber() : m.stepPrice;
            }
            if (m.bidsQuantity != null && m.hasOwnProperty("bidsQuantity")) {
                if (typeof m.bidsQuantity === "number")
                    d.bidsQuantity = o.longs === String ? String(m.bidsQuantity) : m.bidsQuantity;
                else
                    d.bidsQuantity = o.longs === String ? $util.Long.prototype.toString.call(m.bidsQuantity) : o.longs === Number ? new $util.LongBits(m.bidsQuantity.low >>> 0, m.bidsQuantity.high >>> 0).toNumber() : m.bidsQuantity;
            }
            if (m.bids && m.bids.length) {
                d.bids = [];
                for (var j = 0; j < m.bids.length; ++j) {
                    d.bids[j] = $root.transport.Bids.toObject(m.bids[j], o);
                }
            }
            if (m.isVatIncluded != null && m.hasOwnProperty("isVatIncluded")) {
                d.isVatIncluded = m.isVatIncluded;
            }
            if (m.carrierGroup != null && m.hasOwnProperty("carrierGroup")) {
                d.carrierGroup = $root.transport.Carrier.CarrierGroup.toObject(m.carrierGroup, o);
            }
            if (m.comment != null && m.hasOwnProperty("comment")) {
                d.comment = m.comment;
            }
            if (m.carrierOrganization != null && m.hasOwnProperty("carrierOrganization")) {
                d.carrierOrganization = $root.transport.Carrier.Organization.toObject(m.carrierOrganization, o);
            }
            if (m.isTn != null && m.hasOwnProperty("isTn")) {
                d.isTn = m.isTn;
            }
            if (m.lastBid != null && m.hasOwnProperty("lastBid")) {
                d.lastBid = $root.transport.Bids.toObject(m.lastBid, o);
            }
            return d;
        };

        Tender.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Tender;
    })();

    transport.Bids = (function() {

        function Bids(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Bids.prototype.id = "";
        Bids.prototype.price = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        Bids.prototype.comment = "";
        Bids.prototype.carrierOrganization = null;

        Bids.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.Bids)
                return d;
            var m = new $root.transport.Bids();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.price != null) {
                if ($util.Long)
                    (m.price = $util.Long.fromValue(d.price)).unsigned = false;
                else if (typeof d.price === "string")
                    m.price = parseInt(d.price, 10);
                else if (typeof d.price === "number")
                    m.price = d.price;
                else if (typeof d.price === "object")
                    m.price = new $util.LongBits(d.price.low >>> 0, d.price.high >>> 0).toNumber();
            }
            if (d.comment != null) {
                m.comment = String(d.comment);
            }
            if (d.carrierOrganization != null) {
                if (typeof d.carrierOrganization !== "object")
                    throw TypeError(".transport.Bids.carrierOrganization: object expected");
                m.carrierOrganization = $root.transport.Carrier.Organization.fromObject(d.carrierOrganization);
            }
            return m;
        };

        Bids.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.price = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.price = o.longs === String ? "0" : 0;
                d.comment = "";
                d.carrierOrganization = null;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.price != null && m.hasOwnProperty("price")) {
                if (typeof m.price === "number")
                    d.price = o.longs === String ? String(m.price) : m.price;
                else
                    d.price = o.longs === String ? $util.Long.prototype.toString.call(m.price) : o.longs === Number ? new $util.LongBits(m.price.low >>> 0, m.price.high >>> 0).toNumber() : m.price;
            }
            if (m.comment != null && m.hasOwnProperty("comment")) {
                d.comment = m.comment;
            }
            if (m.carrierOrganization != null && m.hasOwnProperty("carrierOrganization")) {
                d.carrierOrganization = $root.transport.Carrier.Organization.toObject(m.carrierOrganization, o);
            }
            return d;
        };

        Bids.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Bids;
    })();

    transport.VehicleRequirements = (function() {

        function VehicleRequirements(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        VehicleRequirements.prototype.bodyType = "";
        VehicleRequirements.prototype.liftingCapacity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        VehicleRequirements.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        VehicleRequirements.prototype.length = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        VehicleRequirements.prototype.width = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        VehicleRequirements.prototype.height = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        VehicleRequirements.prototype.mustHaveManipulator = false;
        VehicleRequirements.prototype.bodySubtype = null;

        VehicleRequirements.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.VehicleRequirements)
                return d;
            var m = new $root.transport.VehicleRequirements();
            if (d.bodyType != null) {
                m.bodyType = String(d.bodyType);
            }
            if (d.liftingCapacity != null) {
                if ($util.Long)
                    (m.liftingCapacity = $util.Long.fromValue(d.liftingCapacity)).unsigned = false;
                else if (typeof d.liftingCapacity === "string")
                    m.liftingCapacity = parseInt(d.liftingCapacity, 10);
                else if (typeof d.liftingCapacity === "number")
                    m.liftingCapacity = d.liftingCapacity;
                else if (typeof d.liftingCapacity === "object")
                    m.liftingCapacity = new $util.LongBits(d.liftingCapacity.low >>> 0, d.liftingCapacity.high >>> 0).toNumber();
            }
            if (d.volume != null) {
                if ($util.Long)
                    (m.volume = $util.Long.fromValue(d.volume)).unsigned = false;
                else if (typeof d.volume === "string")
                    m.volume = parseInt(d.volume, 10);
                else if (typeof d.volume === "number")
                    m.volume = d.volume;
                else if (typeof d.volume === "object")
                    m.volume = new $util.LongBits(d.volume.low >>> 0, d.volume.high >>> 0).toNumber();
            }
            if (d.length != null) {
                if ($util.Long)
                    (m.length = $util.Long.fromValue(d.length)).unsigned = false;
                else if (typeof d.length === "string")
                    m.length = parseInt(d.length, 10);
                else if (typeof d.length === "number")
                    m.length = d.length;
                else if (typeof d.length === "object")
                    m.length = new $util.LongBits(d.length.low >>> 0, d.length.high >>> 0).toNumber();
            }
            if (d.width != null) {
                if ($util.Long)
                    (m.width = $util.Long.fromValue(d.width)).unsigned = false;
                else if (typeof d.width === "string")
                    m.width = parseInt(d.width, 10);
                else if (typeof d.width === "number")
                    m.width = d.width;
                else if (typeof d.width === "object")
                    m.width = new $util.LongBits(d.width.low >>> 0, d.width.high >>> 0).toNumber();
            }
            if (d.height != null) {
                if ($util.Long)
                    (m.height = $util.Long.fromValue(d.height)).unsigned = false;
                else if (typeof d.height === "string")
                    m.height = parseInt(d.height, 10);
                else if (typeof d.height === "number")
                    m.height = d.height;
                else if (typeof d.height === "object")
                    m.height = new $util.LongBits(d.height.low >>> 0, d.height.high >>> 0).toNumber();
            }
            if (d.mustHaveManipulator != null) {
                m.mustHaveManipulator = Boolean(d.mustHaveManipulator);
            }
            if (d.bodySubtype != null) {
                if (typeof d.bodySubtype !== "object")
                    throw TypeError(".transport.VehicleRequirements.bodySubtype: object expected");
                m.bodySubtype = $root.transport.Vehicle.Body.Subtype.fromObject(d.bodySubtype);
            }
            return m;
        };

        VehicleRequirements.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.bodyType = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.liftingCapacity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.liftingCapacity = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.volume = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.volume = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.length = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.length = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.width = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.width = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.height = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.height = o.longs === String ? "0" : 0;
                d.mustHaveManipulator = false;
                d.bodySubtype = null;
            }
            if (m.bodyType != null && m.hasOwnProperty("bodyType")) {
                d.bodyType = m.bodyType;
            }
            if (m.liftingCapacity != null && m.hasOwnProperty("liftingCapacity")) {
                if (typeof m.liftingCapacity === "number")
                    d.liftingCapacity = o.longs === String ? String(m.liftingCapacity) : m.liftingCapacity;
                else
                    d.liftingCapacity = o.longs === String ? $util.Long.prototype.toString.call(m.liftingCapacity) : o.longs === Number ? new $util.LongBits(m.liftingCapacity.low >>> 0, m.liftingCapacity.high >>> 0).toNumber() : m.liftingCapacity;
            }
            if (m.volume != null && m.hasOwnProperty("volume")) {
                if (typeof m.volume === "number")
                    d.volume = o.longs === String ? String(m.volume) : m.volume;
                else
                    d.volume = o.longs === String ? $util.Long.prototype.toString.call(m.volume) : o.longs === Number ? new $util.LongBits(m.volume.low >>> 0, m.volume.high >>> 0).toNumber() : m.volume;
            }
            if (m.length != null && m.hasOwnProperty("length")) {
                if (typeof m.length === "number")
                    d.length = o.longs === String ? String(m.length) : m.length;
                else
                    d.length = o.longs === String ? $util.Long.prototype.toString.call(m.length) : o.longs === Number ? new $util.LongBits(m.length.low >>> 0, m.length.high >>> 0).toNumber() : m.length;
            }
            if (m.width != null && m.hasOwnProperty("width")) {
                if (typeof m.width === "number")
                    d.width = o.longs === String ? String(m.width) : m.width;
                else
                    d.width = o.longs === String ? $util.Long.prototype.toString.call(m.width) : o.longs === Number ? new $util.LongBits(m.width.low >>> 0, m.width.high >>> 0).toNumber() : m.width;
            }
            if (m.height != null && m.hasOwnProperty("height")) {
                if (typeof m.height === "number")
                    d.height = o.longs === String ? String(m.height) : m.height;
                else
                    d.height = o.longs === String ? $util.Long.prototype.toString.call(m.height) : o.longs === Number ? new $util.LongBits(m.height.low >>> 0, m.height.high >>> 0).toNumber() : m.height;
            }
            if (m.mustHaveManipulator != null && m.hasOwnProperty("mustHaveManipulator")) {
                d.mustHaveManipulator = m.mustHaveManipulator;
            }
            if (m.bodySubtype != null && m.hasOwnProperty("bodySubtype")) {
                d.bodySubtype = $root.transport.Vehicle.Body.Subtype.toObject(m.bodySubtype, o);
            }
            return d;
        };

        VehicleRequirements.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VehicleRequirements;
    })();

    transport.AddTenderInput = (function() {

        function AddTenderInput(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        AddTenderInput.prototype.vehicleRequirements = null;
        AddTenderInput.prototype.loadingSettlement = "";
        AddTenderInput.prototype.loadingSettlementFias = "";
        AddTenderInput.prototype.course = "";
        AddTenderInput.prototype.courseFias = "";
        AddTenderInput.prototype.ordersQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        AddTenderInput.prototype.activeDateFrom = "";
        AddTenderInput.prototype.activeDateTo = "";
        AddTenderInput.prototype.maxOrderPrice = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        AddTenderInput.prototype.isVatIncluded = false;
        AddTenderInput.prototype.stepPrice = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        AddTenderInput.prototype.bidsEndDatetime = "";
        AddTenderInput.prototype.carrierGroupId = "";
        AddTenderInput.prototype.comment = "";

        AddTenderInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.AddTenderInput)
                return d;
            var m = new $root.transport.AddTenderInput();
            if (d.vehicleRequirements != null) {
                if (typeof d.vehicleRequirements !== "object")
                    throw TypeError(".transport.AddTenderInput.vehicleRequirements: object expected");
                m.vehicleRequirements = $root.transport.VehicleRequirements.fromObject(d.vehicleRequirements);
            }
            if (d.loadingSettlement != null) {
                m.loadingSettlement = String(d.loadingSettlement);
            }
            if (d.loadingSettlementFias != null) {
                m.loadingSettlementFias = String(d.loadingSettlementFias);
            }
            if (d.course != null) {
                m.course = String(d.course);
            }
            if (d.courseFias != null) {
                m.courseFias = String(d.courseFias);
            }
            if (d.ordersQuantity != null) {
                if ($util.Long)
                    (m.ordersQuantity = $util.Long.fromValue(d.ordersQuantity)).unsigned = false;
                else if (typeof d.ordersQuantity === "string")
                    m.ordersQuantity = parseInt(d.ordersQuantity, 10);
                else if (typeof d.ordersQuantity === "number")
                    m.ordersQuantity = d.ordersQuantity;
                else if (typeof d.ordersQuantity === "object")
                    m.ordersQuantity = new $util.LongBits(d.ordersQuantity.low >>> 0, d.ordersQuantity.high >>> 0).toNumber();
            }
            if (d.activeDateFrom != null) {
                m.activeDateFrom = String(d.activeDateFrom);
            }
            if (d.activeDateTo != null) {
                m.activeDateTo = String(d.activeDateTo);
            }
            if (d.maxOrderPrice != null) {
                if ($util.Long)
                    (m.maxOrderPrice = $util.Long.fromValue(d.maxOrderPrice)).unsigned = false;
                else if (typeof d.maxOrderPrice === "string")
                    m.maxOrderPrice = parseInt(d.maxOrderPrice, 10);
                else if (typeof d.maxOrderPrice === "number")
                    m.maxOrderPrice = d.maxOrderPrice;
                else if (typeof d.maxOrderPrice === "object")
                    m.maxOrderPrice = new $util.LongBits(d.maxOrderPrice.low >>> 0, d.maxOrderPrice.high >>> 0).toNumber();
            }
            if (d.isVatIncluded != null) {
                m.isVatIncluded = Boolean(d.isVatIncluded);
            }
            if (d.stepPrice != null) {
                if ($util.Long)
                    (m.stepPrice = $util.Long.fromValue(d.stepPrice)).unsigned = false;
                else if (typeof d.stepPrice === "string")
                    m.stepPrice = parseInt(d.stepPrice, 10);
                else if (typeof d.stepPrice === "number")
                    m.stepPrice = d.stepPrice;
                else if (typeof d.stepPrice === "object")
                    m.stepPrice = new $util.LongBits(d.stepPrice.low >>> 0, d.stepPrice.high >>> 0).toNumber();
            }
            if (d.bidsEndDatetime != null) {
                m.bidsEndDatetime = String(d.bidsEndDatetime);
            }
            if (d.carrierGroupId != null) {
                m.carrierGroupId = String(d.carrierGroupId);
            }
            if (d.comment != null) {
                m.comment = String(d.comment);
            }
            return m;
        };

        AddTenderInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.vehicleRequirements = null;
                d.loadingSettlement = "";
                d.loadingSettlementFias = "";
                d.course = "";
                d.courseFias = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.ordersQuantity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.ordersQuantity = o.longs === String ? "0" : 0;
                d.activeDateFrom = "";
                d.activeDateTo = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.maxOrderPrice = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.maxOrderPrice = o.longs === String ? "0" : 0;
                d.isVatIncluded = false;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.stepPrice = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.stepPrice = o.longs === String ? "0" : 0;
                d.bidsEndDatetime = "";
                d.carrierGroupId = "";
                d.comment = "";
            }
            if (m.vehicleRequirements != null && m.hasOwnProperty("vehicleRequirements")) {
                d.vehicleRequirements = $root.transport.VehicleRequirements.toObject(m.vehicleRequirements, o);
            }
            if (m.loadingSettlement != null && m.hasOwnProperty("loadingSettlement")) {
                d.loadingSettlement = m.loadingSettlement;
            }
            if (m.loadingSettlementFias != null && m.hasOwnProperty("loadingSettlementFias")) {
                d.loadingSettlementFias = m.loadingSettlementFias;
            }
            if (m.course != null && m.hasOwnProperty("course")) {
                d.course = m.course;
            }
            if (m.courseFias != null && m.hasOwnProperty("courseFias")) {
                d.courseFias = m.courseFias;
            }
            if (m.ordersQuantity != null && m.hasOwnProperty("ordersQuantity")) {
                if (typeof m.ordersQuantity === "number")
                    d.ordersQuantity = o.longs === String ? String(m.ordersQuantity) : m.ordersQuantity;
                else
                    d.ordersQuantity = o.longs === String ? $util.Long.prototype.toString.call(m.ordersQuantity) : o.longs === Number ? new $util.LongBits(m.ordersQuantity.low >>> 0, m.ordersQuantity.high >>> 0).toNumber() : m.ordersQuantity;
            }
            if (m.activeDateFrom != null && m.hasOwnProperty("activeDateFrom")) {
                d.activeDateFrom = m.activeDateFrom;
            }
            if (m.activeDateTo != null && m.hasOwnProperty("activeDateTo")) {
                d.activeDateTo = m.activeDateTo;
            }
            if (m.maxOrderPrice != null && m.hasOwnProperty("maxOrderPrice")) {
                if (typeof m.maxOrderPrice === "number")
                    d.maxOrderPrice = o.longs === String ? String(m.maxOrderPrice) : m.maxOrderPrice;
                else
                    d.maxOrderPrice = o.longs === String ? $util.Long.prototype.toString.call(m.maxOrderPrice) : o.longs === Number ? new $util.LongBits(m.maxOrderPrice.low >>> 0, m.maxOrderPrice.high >>> 0).toNumber() : m.maxOrderPrice;
            }
            if (m.isVatIncluded != null && m.hasOwnProperty("isVatIncluded")) {
                d.isVatIncluded = m.isVatIncluded;
            }
            if (m.stepPrice != null && m.hasOwnProperty("stepPrice")) {
                if (typeof m.stepPrice === "number")
                    d.stepPrice = o.longs === String ? String(m.stepPrice) : m.stepPrice;
                else
                    d.stepPrice = o.longs === String ? $util.Long.prototype.toString.call(m.stepPrice) : o.longs === Number ? new $util.LongBits(m.stepPrice.low >>> 0, m.stepPrice.high >>> 0).toNumber() : m.stepPrice;
            }
            if (m.bidsEndDatetime != null && m.hasOwnProperty("bidsEndDatetime")) {
                d.bidsEndDatetime = m.bidsEndDatetime;
            }
            if (m.carrierGroupId != null && m.hasOwnProperty("carrierGroupId")) {
                d.carrierGroupId = m.carrierGroupId;
            }
            if (m.comment != null && m.hasOwnProperty("comment")) {
                d.comment = m.comment;
            }
            return d;
        };

        AddTenderInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return AddTenderInput;
    })();

    transport.TenderReport = (function() {

        function TenderReport(p) {
            this.orders = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TenderReport.prototype.id = "";
        TenderReport.prototype.lotNumber = "";
        TenderReport.prototype.loadingSettlement = "";
        TenderReport.prototype.department = "";
        TenderReport.prototype.course = "";
        TenderReport.prototype.ordersQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        TenderReport.prototype.displayedStatus = "";
        TenderReport.prototype.ordersAssignedQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        TenderReport.prototype.ordersInWorkQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        TenderReport.prototype.ordersCompletedQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        TenderReport.prototype.ordersCanceledQuantity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        TenderReport.prototype.ordersCanceledPercentage = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
        TenderReport.prototype.orders = $util.emptyArray;
        TenderReport.prototype.cargoOwnerOrganization = null;
        TenderReport.prototype.carrierOrganization = null;
        TenderReport.prototype.isTn = false;

        TenderReport.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.TenderReport)
                return d;
            var m = new $root.transport.TenderReport();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.lotNumber != null) {
                m.lotNumber = String(d.lotNumber);
            }
            if (d.loadingSettlement != null) {
                m.loadingSettlement = String(d.loadingSettlement);
            }
            if (d.department != null) {
                m.department = String(d.department);
            }
            if (d.course != null) {
                m.course = String(d.course);
            }
            if (d.ordersQuantity != null) {
                if ($util.Long)
                    (m.ordersQuantity = $util.Long.fromValue(d.ordersQuantity)).unsigned = false;
                else if (typeof d.ordersQuantity === "string")
                    m.ordersQuantity = parseInt(d.ordersQuantity, 10);
                else if (typeof d.ordersQuantity === "number")
                    m.ordersQuantity = d.ordersQuantity;
                else if (typeof d.ordersQuantity === "object")
                    m.ordersQuantity = new $util.LongBits(d.ordersQuantity.low >>> 0, d.ordersQuantity.high >>> 0).toNumber();
            }
            if (d.displayedStatus != null) {
                m.displayedStatus = String(d.displayedStatus);
            }
            if (d.ordersAssignedQuantity != null) {
                if ($util.Long)
                    (m.ordersAssignedQuantity = $util.Long.fromValue(d.ordersAssignedQuantity)).unsigned = false;
                else if (typeof d.ordersAssignedQuantity === "string")
                    m.ordersAssignedQuantity = parseInt(d.ordersAssignedQuantity, 10);
                else if (typeof d.ordersAssignedQuantity === "number")
                    m.ordersAssignedQuantity = d.ordersAssignedQuantity;
                else if (typeof d.ordersAssignedQuantity === "object")
                    m.ordersAssignedQuantity = new $util.LongBits(d.ordersAssignedQuantity.low >>> 0, d.ordersAssignedQuantity.high >>> 0).toNumber();
            }
            if (d.ordersInWorkQuantity != null) {
                if ($util.Long)
                    (m.ordersInWorkQuantity = $util.Long.fromValue(d.ordersInWorkQuantity)).unsigned = false;
                else if (typeof d.ordersInWorkQuantity === "string")
                    m.ordersInWorkQuantity = parseInt(d.ordersInWorkQuantity, 10);
                else if (typeof d.ordersInWorkQuantity === "number")
                    m.ordersInWorkQuantity = d.ordersInWorkQuantity;
                else if (typeof d.ordersInWorkQuantity === "object")
                    m.ordersInWorkQuantity = new $util.LongBits(d.ordersInWorkQuantity.low >>> 0, d.ordersInWorkQuantity.high >>> 0).toNumber();
            }
            if (d.ordersCompletedQuantity != null) {
                if ($util.Long)
                    (m.ordersCompletedQuantity = $util.Long.fromValue(d.ordersCompletedQuantity)).unsigned = false;
                else if (typeof d.ordersCompletedQuantity === "string")
                    m.ordersCompletedQuantity = parseInt(d.ordersCompletedQuantity, 10);
                else if (typeof d.ordersCompletedQuantity === "number")
                    m.ordersCompletedQuantity = d.ordersCompletedQuantity;
                else if (typeof d.ordersCompletedQuantity === "object")
                    m.ordersCompletedQuantity = new $util.LongBits(d.ordersCompletedQuantity.low >>> 0, d.ordersCompletedQuantity.high >>> 0).toNumber();
            }
            if (d.ordersCanceledQuantity != null) {
                if ($util.Long)
                    (m.ordersCanceledQuantity = $util.Long.fromValue(d.ordersCanceledQuantity)).unsigned = false;
                else if (typeof d.ordersCanceledQuantity === "string")
                    m.ordersCanceledQuantity = parseInt(d.ordersCanceledQuantity, 10);
                else if (typeof d.ordersCanceledQuantity === "number")
                    m.ordersCanceledQuantity = d.ordersCanceledQuantity;
                else if (typeof d.ordersCanceledQuantity === "object")
                    m.ordersCanceledQuantity = new $util.LongBits(d.ordersCanceledQuantity.low >>> 0, d.ordersCanceledQuantity.high >>> 0).toNumber();
            }
            if (d.ordersCanceledPercentage != null) {
                if ($util.Long)
                    (m.ordersCanceledPercentage = $util.Long.fromValue(d.ordersCanceledPercentage)).unsigned = false;
                else if (typeof d.ordersCanceledPercentage === "string")
                    m.ordersCanceledPercentage = parseInt(d.ordersCanceledPercentage, 10);
                else if (typeof d.ordersCanceledPercentage === "number")
                    m.ordersCanceledPercentage = d.ordersCanceledPercentage;
                else if (typeof d.ordersCanceledPercentage === "object")
                    m.ordersCanceledPercentage = new $util.LongBits(d.ordersCanceledPercentage.low >>> 0, d.ordersCanceledPercentage.high >>> 0).toNumber();
            }
            if (d.orders) {
                if (!Array.isArray(d.orders))
                    throw TypeError(".transport.TenderReport.orders: array expected");
                m.orders = [];
                for (var i = 0; i < d.orders.length; ++i) {
                    if (typeof d.orders[i] !== "object")
                        throw TypeError(".transport.TenderReport.orders: object expected");
                    m.orders[i] = $root.transport.Order.fromObject(d.orders[i]);
                }
            }
            if (d.cargoOwnerOrganization != null) {
                if (typeof d.cargoOwnerOrganization !== "object")
                    throw TypeError(".transport.TenderReport.cargoOwnerOrganization: object expected");
                m.cargoOwnerOrganization = $root.transport.CargoOwner.fromObject(d.cargoOwnerOrganization);
            }
            if (d.carrierOrganization != null) {
                if (typeof d.carrierOrganization !== "object")
                    throw TypeError(".transport.TenderReport.carrierOrganization: object expected");
                m.carrierOrganization = $root.transport.Carrier.Organization.fromObject(d.carrierOrganization);
            }
            if (d.isTn != null) {
                m.isTn = Boolean(d.isTn);
            }
            return m;
        };

        TenderReport.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.orders = [];
            }
            if (o.defaults) {
                d.id = "";
                d.lotNumber = "";
                d.loadingSettlement = "";
                d.department = "";
                d.course = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.ordersQuantity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.ordersQuantity = o.longs === String ? "0" : 0;
                d.displayedStatus = "";
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.ordersAssignedQuantity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.ordersAssignedQuantity = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.ordersInWorkQuantity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.ordersInWorkQuantity = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.ordersCompletedQuantity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.ordersCompletedQuantity = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.ordersCanceledQuantity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.ordersCanceledQuantity = o.longs === String ? "0" : 0;
                if ($util.Long) {
                    var n = new $util.Long(0, 0, false);
                    d.ordersCanceledPercentage = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                } else
                    d.ordersCanceledPercentage = o.longs === String ? "0" : 0;
                d.cargoOwnerOrganization = null;
                d.carrierOrganization = null;
                d.isTn = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.lotNumber != null && m.hasOwnProperty("lotNumber")) {
                d.lotNumber = m.lotNumber;
            }
            if (m.loadingSettlement != null && m.hasOwnProperty("loadingSettlement")) {
                d.loadingSettlement = m.loadingSettlement;
            }
            if (m.department != null && m.hasOwnProperty("department")) {
                d.department = m.department;
            }
            if (m.course != null && m.hasOwnProperty("course")) {
                d.course = m.course;
            }
            if (m.ordersQuantity != null && m.hasOwnProperty("ordersQuantity")) {
                if (typeof m.ordersQuantity === "number")
                    d.ordersQuantity = o.longs === String ? String(m.ordersQuantity) : m.ordersQuantity;
                else
                    d.ordersQuantity = o.longs === String ? $util.Long.prototype.toString.call(m.ordersQuantity) : o.longs === Number ? new $util.LongBits(m.ordersQuantity.low >>> 0, m.ordersQuantity.high >>> 0).toNumber() : m.ordersQuantity;
            }
            if (m.displayedStatus != null && m.hasOwnProperty("displayedStatus")) {
                d.displayedStatus = m.displayedStatus;
            }
            if (m.ordersAssignedQuantity != null && m.hasOwnProperty("ordersAssignedQuantity")) {
                if (typeof m.ordersAssignedQuantity === "number")
                    d.ordersAssignedQuantity = o.longs === String ? String(m.ordersAssignedQuantity) : m.ordersAssignedQuantity;
                else
                    d.ordersAssignedQuantity = o.longs === String ? $util.Long.prototype.toString.call(m.ordersAssignedQuantity) : o.longs === Number ? new $util.LongBits(m.ordersAssignedQuantity.low >>> 0, m.ordersAssignedQuantity.high >>> 0).toNumber() : m.ordersAssignedQuantity;
            }
            if (m.ordersInWorkQuantity != null && m.hasOwnProperty("ordersInWorkQuantity")) {
                if (typeof m.ordersInWorkQuantity === "number")
                    d.ordersInWorkQuantity = o.longs === String ? String(m.ordersInWorkQuantity) : m.ordersInWorkQuantity;
                else
                    d.ordersInWorkQuantity = o.longs === String ? $util.Long.prototype.toString.call(m.ordersInWorkQuantity) : o.longs === Number ? new $util.LongBits(m.ordersInWorkQuantity.low >>> 0, m.ordersInWorkQuantity.high >>> 0).toNumber() : m.ordersInWorkQuantity;
            }
            if (m.ordersCompletedQuantity != null && m.hasOwnProperty("ordersCompletedQuantity")) {
                if (typeof m.ordersCompletedQuantity === "number")
                    d.ordersCompletedQuantity = o.longs === String ? String(m.ordersCompletedQuantity) : m.ordersCompletedQuantity;
                else
                    d.ordersCompletedQuantity = o.longs === String ? $util.Long.prototype.toString.call(m.ordersCompletedQuantity) : o.longs === Number ? new $util.LongBits(m.ordersCompletedQuantity.low >>> 0, m.ordersCompletedQuantity.high >>> 0).toNumber() : m.ordersCompletedQuantity;
            }
            if (m.ordersCanceledQuantity != null && m.hasOwnProperty("ordersCanceledQuantity")) {
                if (typeof m.ordersCanceledQuantity === "number")
                    d.ordersCanceledQuantity = o.longs === String ? String(m.ordersCanceledQuantity) : m.ordersCanceledQuantity;
                else
                    d.ordersCanceledQuantity = o.longs === String ? $util.Long.prototype.toString.call(m.ordersCanceledQuantity) : o.longs === Number ? new $util.LongBits(m.ordersCanceledQuantity.low >>> 0, m.ordersCanceledQuantity.high >>> 0).toNumber() : m.ordersCanceledQuantity;
            }
            if (m.ordersCanceledPercentage != null && m.hasOwnProperty("ordersCanceledPercentage")) {
                if (typeof m.ordersCanceledPercentage === "number")
                    d.ordersCanceledPercentage = o.longs === String ? String(m.ordersCanceledPercentage) : m.ordersCanceledPercentage;
                else
                    d.ordersCanceledPercentage = o.longs === String ? $util.Long.prototype.toString.call(m.ordersCanceledPercentage) : o.longs === Number ? new $util.LongBits(m.ordersCanceledPercentage.low >>> 0, m.ordersCanceledPercentage.high >>> 0).toNumber() : m.ordersCanceledPercentage;
            }
            if (m.orders && m.orders.length) {
                d.orders = [];
                for (var j = 0; j < m.orders.length; ++j) {
                    d.orders[j] = $root.transport.Order.toObject(m.orders[j], o);
                }
            }
            if (m.cargoOwnerOrganization != null && m.hasOwnProperty("cargoOwnerOrganization")) {
                d.cargoOwnerOrganization = $root.transport.CargoOwner.toObject(m.cargoOwnerOrganization, o);
            }
            if (m.carrierOrganization != null && m.hasOwnProperty("carrierOrganization")) {
                d.carrierOrganization = $root.transport.Carrier.Organization.toObject(m.carrierOrganization, o);
            }
            if (m.isTn != null && m.hasOwnProperty("isTn")) {
                d.isTn = m.isTn;
            }
            return d;
        };

        TenderReport.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TenderReport;
    })();

    transport.UploadedFile = (function() {

        function UploadedFile(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        UploadedFile.prototype.id = "";
        UploadedFile.prototype.name = "";
        UploadedFile.prototype.createdAt = "";
        UploadedFile.prototype.url = "";

        UploadedFile.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.UploadedFile)
                return d;
            var m = new $root.transport.UploadedFile();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.name != null) {
                m.name = String(d.name);
            }
            if (d.createdAt != null) {
                m.createdAt = String(d.createdAt);
            }
            if (d.url != null) {
                m.url = String(d.url);
            }
            return m;
        };

        UploadedFile.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.name = "";
                d.createdAt = "";
                d.url = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.name != null && m.hasOwnProperty("name")) {
                d.name = m.name;
            }
            if (m.createdAt != null && m.hasOwnProperty("createdAt")) {
                d.createdAt = m.createdAt;
            }
            if (m.url != null && m.hasOwnProperty("url")) {
                d.url = m.url;
            }
            return d;
        };

        UploadedFile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return UploadedFile;
    })();

    transport.User = (function() {

        function User(p) {
            this.permissions = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        User.prototype.id = "";
        User.prototype.email = "";
        User.prototype.phoneNumber = "";
        User.prototype.firstName = "";
        User.prototype.lastName = "";
        User.prototype.companyName = "";
        User.prototype.role = "";
        User.prototype.token = "";
        User.prototype.isActive = true;
        User.prototype.permissions = $util.emptyArray;
        User.prototype.uiSettings = null;
        User.prototype.isAgentOrExpeditor = false;
        User.prototype.isWorkingWithTN = false;
        User.prototype.patronymic = "";
        User.prototype.userAgreementIsAccepted = false;
        User.prototype.firstLogin = false;

        User.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.User)
                return d;
            var m = new $root.transport.User();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            if (d.phoneNumber != null) {
                m.phoneNumber = String(d.phoneNumber);
            }
            if (d.firstName != null) {
                m.firstName = String(d.firstName);
            }
            if (d.lastName != null) {
                m.lastName = String(d.lastName);
            }
            if (d.companyName != null) {
                m.companyName = String(d.companyName);
            }
            if (d.role != null) {
                m.role = String(d.role);
            }
            if (d.token != null) {
                m.token = String(d.token);
            }
            if (d.isActive != null) {
                m.isActive = Boolean(d.isActive);
            }
            if (d.permissions) {
                if (!Array.isArray(d.permissions))
                    throw TypeError(".transport.User.permissions: array expected");
                m.permissions = [];
                for (var i = 0; i < d.permissions.length; ++i) {
                    if (typeof d.permissions[i] !== "object")
                        throw TypeError(".transport.User.permissions: object expected");
                    m.permissions[i] = $root.transport.User.Permission.fromObject(d.permissions[i]);
                }
            }
            if (d.uiSettings != null) {
                if (typeof d.uiSettings !== "object")
                    throw TypeError(".transport.User.uiSettings: object expected");
                m.uiSettings = $root.google.protobuf.Any.fromObject(d.uiSettings);
            }
            if (d.isAgentOrExpeditor != null) {
                m.isAgentOrExpeditor = Boolean(d.isAgentOrExpeditor);
            }
            if (d.isWorkingWithTN != null) {
                m.isWorkingWithTN = Boolean(d.isWorkingWithTN);
            }
            if (d.patronymic != null) {
                m.patronymic = String(d.patronymic);
            }
            if (d.userAgreementIsAccepted != null) {
                m.userAgreementIsAccepted = Boolean(d.userAgreementIsAccepted);
            }
            if (d.firstLogin != null) {
                m.firstLogin = Boolean(d.firstLogin);
            }
            return m;
        };

        User.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.permissions = [];
            }
            if (o.defaults) {
                d.id = "";
                d.email = "";
                d.phoneNumber = "";
                d.firstName = "";
                d.lastName = "";
                d.companyName = "";
                d.role = "";
                d.token = "";
                d.isActive = true;
                d.uiSettings = null;
                d.isAgentOrExpeditor = false;
                d.isWorkingWithTN = false;
                d.patronymic = "";
                d.userAgreementIsAccepted = false;
                d.firstLogin = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
            }
            if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                d.phoneNumber = m.phoneNumber;
            }
            if (m.firstName != null && m.hasOwnProperty("firstName")) {
                d.firstName = m.firstName;
            }
            if (m.lastName != null && m.hasOwnProperty("lastName")) {
                d.lastName = m.lastName;
            }
            if (m.companyName != null && m.hasOwnProperty("companyName")) {
                d.companyName = m.companyName;
            }
            if (m.role != null && m.hasOwnProperty("role")) {
                d.role = m.role;
            }
            if (m.token != null && m.hasOwnProperty("token")) {
                d.token = m.token;
            }
            if (m.isActive != null && m.hasOwnProperty("isActive")) {
                d.isActive = m.isActive;
            }
            if (m.permissions && m.permissions.length) {
                d.permissions = [];
                for (var j = 0; j < m.permissions.length; ++j) {
                    d.permissions[j] = $root.transport.User.Permission.toObject(m.permissions[j], o);
                }
            }
            if (m.uiSettings != null && m.hasOwnProperty("uiSettings")) {
                d.uiSettings = $root.google.protobuf.Any.toObject(m.uiSettings, o);
            }
            if (m.isAgentOrExpeditor != null && m.hasOwnProperty("isAgentOrExpeditor")) {
                d.isAgentOrExpeditor = m.isAgentOrExpeditor;
            }
            if (m.isWorkingWithTN != null && m.hasOwnProperty("isWorkingWithTN")) {
                d.isWorkingWithTN = m.isWorkingWithTN;
            }
            if (m.patronymic != null && m.hasOwnProperty("patronymic")) {
                d.patronymic = m.patronymic;
            }
            if (m.userAgreementIsAccepted != null && m.hasOwnProperty("userAgreementIsAccepted")) {
                d.userAgreementIsAccepted = m.userAgreementIsAccepted;
            }
            if (m.firstLogin != null && m.hasOwnProperty("firstLogin")) {
                d.firstLogin = m.firstLogin;
            }
            return d;
        };

        User.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        User.Permission = (function() {

            function Permission(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Permission.prototype.id = "";
            Permission.prototype.codename = "";
            Permission.prototype.description = "";

            Permission.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.User.Permission)
                    return d;
                var m = new $root.transport.User.Permission();
                if (d.id != null) {
                    m.id = String(d.id);
                }
                if (d.codename != null) {
                    m.codename = String(d.codename);
                }
                if (d.description != null) {
                    m.description = String(d.description);
                }
                return m;
            };

            Permission.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.id = "";
                    d.codename = "";
                    d.description = "";
                }
                if (m.id != null && m.hasOwnProperty("id")) {
                    d.id = m.id;
                }
                if (m.codename != null && m.hasOwnProperty("codename")) {
                    d.codename = m.codename;
                }
                if (m.description != null && m.hasOwnProperty("description")) {
                    d.description = m.description;
                }
                return d;
            };

            Permission.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Permission;
        })();

        User.Profile = (function() {

            function Profile(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Profile.prototype.email = "";
            Profile.prototype.firstName = "";
            Profile.prototype.lastName = "";
            Profile.prototype.companyName = "";
            Profile.prototype.role = "";
            Profile.prototype.admin = false;

            Profile.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.User.Profile)
                    return d;
                var m = new $root.transport.User.Profile();
                if (d.email != null) {
                    m.email = String(d.email);
                }
                if (d.firstName != null) {
                    m.firstName = String(d.firstName);
                }
                if (d.lastName != null) {
                    m.lastName = String(d.lastName);
                }
                if (d.companyName != null) {
                    m.companyName = String(d.companyName);
                }
                if (d.role != null) {
                    m.role = String(d.role);
                }
                if (d.admin != null) {
                    m.admin = Boolean(d.admin);
                }
                return m;
            };

            Profile.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.email = "";
                    d.firstName = "";
                    d.lastName = "";
                    d.companyName = "";
                    d.role = "";
                    d.admin = false;
                }
                if (m.email != null && m.hasOwnProperty("email")) {
                    d.email = m.email;
                }
                if (m.firstName != null && m.hasOwnProperty("firstName")) {
                    d.firstName = m.firstName;
                }
                if (m.lastName != null && m.hasOwnProperty("lastName")) {
                    d.lastName = m.lastName;
                }
                if (m.companyName != null && m.hasOwnProperty("companyName")) {
                    d.companyName = m.companyName;
                }
                if (m.role != null && m.hasOwnProperty("role")) {
                    d.role = m.role;
                }
                if (m.admin != null && m.hasOwnProperty("admin")) {
                    d.admin = m.admin;
                }
                return d;
            };

            Profile.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Profile;
        })();

        return User;
    })();

    transport.EditProfileInput = (function() {

        function EditProfileInput(p) {
            this.permissions = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        EditProfileInput.prototype.id = "";
        EditProfileInput.prototype.firstName = null;
        EditProfileInput.prototype.lastName = null;
        EditProfileInput.prototype.email = "";
        EditProfileInput.prototype.permissions = $util.emptyArray;
        EditProfileInput.prototype.phoneNumber = null;

        let $oneOfFields;

        Object.defineProperty(EditProfileInput.prototype, "_firstName", {
            get: $util.oneOfGetter($oneOfFields = ["firstName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(EditProfileInput.prototype, "_lastName", {
            get: $util.oneOfGetter($oneOfFields = ["lastName"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(EditProfileInput.prototype, "_phoneNumber", {
            get: $util.oneOfGetter($oneOfFields = ["phoneNumber"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        EditProfileInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.EditProfileInput)
                return d;
            var m = new $root.transport.EditProfileInput();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.firstName != null) {
                m.firstName = String(d.firstName);
            }
            if (d.lastName != null) {
                m.lastName = String(d.lastName);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            if (d.permissions) {
                if (!Array.isArray(d.permissions))
                    throw TypeError(".transport.EditProfileInput.permissions: array expected");
                m.permissions = [];
                for (var i = 0; i < d.permissions.length; ++i) {
                    m.permissions[i] = String(d.permissions[i]);
                }
            }
            if (d.phoneNumber != null) {
                m.phoneNumber = String(d.phoneNumber);
            }
            return m;
        };

        EditProfileInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.permissions = [];
            }
            if (o.defaults) {
                d.id = "";
                d.email = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.firstName != null && m.hasOwnProperty("firstName")) {
                d.firstName = m.firstName;
                if (o.oneofs)
                    d._firstName = "firstName";
            }
            if (m.lastName != null && m.hasOwnProperty("lastName")) {
                d.lastName = m.lastName;
                if (o.oneofs)
                    d._lastName = "lastName";
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
            }
            if (m.permissions && m.permissions.length) {
                d.permissions = [];
                for (var j = 0; j < m.permissions.length; ++j) {
                    d.permissions[j] = m.permissions[j];
                }
            }
            if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                d.phoneNumber = m.phoneNumber;
                if (o.oneofs)
                    d._phoneNumber = "phoneNumber";
            }
            return d;
        };

        EditProfileInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EditProfileInput;
    })();

    transport.EditProfilePasswordInput = (function() {

        function EditProfilePasswordInput(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        EditProfilePasswordInput.prototype.currentPassword = "";
        EditProfilePasswordInput.prototype.newPassword = "";

        EditProfilePasswordInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.EditProfilePasswordInput)
                return d;
            var m = new $root.transport.EditProfilePasswordInput();
            if (d.currentPassword != null) {
                m.currentPassword = String(d.currentPassword);
            }
            if (d.newPassword != null) {
                m.newPassword = String(d.newPassword);
            }
            return m;
        };

        EditProfilePasswordInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.currentPassword = "";
                d.newPassword = "";
            }
            if (m.currentPassword != null && m.hasOwnProperty("currentPassword")) {
                d.currentPassword = m.currentPassword;
            }
            if (m.newPassword != null && m.hasOwnProperty("newPassword")) {
                d.newPassword = m.newPassword;
            }
            return d;
        };

        EditProfilePasswordInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EditProfilePasswordInput;
    })();

    transport.UserProfile = (function() {

        function UserProfile(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        UserProfile.prototype.id = "";
        UserProfile.prototype.supportEmail = "";
        UserProfile.prototype.supportPhoneNumber = "";
        UserProfile.prototype.userSettings = null;
        UserProfile.prototype.userGuides = null;
        UserProfile.prototype.email = "";
        UserProfile.prototype.phoneNumber = "";
        UserProfile.prototype.firstName = "";
        UserProfile.prototype.lastName = "";
        UserProfile.prototype.patronymic = "";
        UserProfile.prototype.editProfilePasswordInput = null;
        UserProfile.prototype.organization = null;
        UserProfile.prototype.hasDigitalProfile = false;

        UserProfile.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.UserProfile)
                return d;
            var m = new $root.transport.UserProfile();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.supportEmail != null) {
                m.supportEmail = String(d.supportEmail);
            }
            if (d.supportPhoneNumber != null) {
                m.supportPhoneNumber = String(d.supportPhoneNumber);
            }
            if (d.userSettings != null) {
                if (typeof d.userSettings !== "object")
                    throw TypeError(".transport.UserProfile.userSettings: object expected");
                m.userSettings = $root.transport.UserProfile.UserSettings.fromObject(d.userSettings);
            }
            if (d.userGuides != null) {
                if (typeof d.userGuides !== "object")
                    throw TypeError(".transport.UserProfile.userGuides: object expected");
                m.userGuides = $root.transport.UserProfile.UserGuides.fromObject(d.userGuides);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            if (d.phoneNumber != null) {
                m.phoneNumber = String(d.phoneNumber);
            }
            if (d.firstName != null) {
                m.firstName = String(d.firstName);
            }
            if (d.lastName != null) {
                m.lastName = String(d.lastName);
            }
            if (d.patronymic != null) {
                m.patronymic = String(d.patronymic);
            }
            if (d.editProfilePasswordInput != null) {
                if (typeof d.editProfilePasswordInput !== "object")
                    throw TypeError(".transport.UserProfile.editProfilePasswordInput: object expected");
                m.editProfilePasswordInput = $root.transport.EditProfilePasswordInput.fromObject(d.editProfilePasswordInput);
            }
            if (d.organization != null) {
                if (typeof d.organization !== "object")
                    throw TypeError(".transport.UserProfile.organization: object expected");
                m.organization = $root.transport.AdminOrganization.fromObject(d.organization);
            }
            if (d.hasDigitalProfile != null) {
                m.hasDigitalProfile = Boolean(d.hasDigitalProfile);
            }
            return m;
        };

        UserProfile.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.id = "";
                d.supportEmail = "";
                d.supportPhoneNumber = "";
                d.userSettings = null;
                d.userGuides = null;
                d.email = "";
                d.phoneNumber = "";
                d.firstName = "";
                d.lastName = "";
                d.patronymic = "";
                d.editProfilePasswordInput = null;
                d.organization = null;
                d.hasDigitalProfile = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.supportEmail != null && m.hasOwnProperty("supportEmail")) {
                d.supportEmail = m.supportEmail;
            }
            if (m.supportPhoneNumber != null && m.hasOwnProperty("supportPhoneNumber")) {
                d.supportPhoneNumber = m.supportPhoneNumber;
            }
            if (m.userSettings != null && m.hasOwnProperty("userSettings")) {
                d.userSettings = $root.transport.UserProfile.UserSettings.toObject(m.userSettings, o);
            }
            if (m.userGuides != null && m.hasOwnProperty("userGuides")) {
                d.userGuides = $root.transport.UserProfile.UserGuides.toObject(m.userGuides, o);
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
            }
            if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                d.phoneNumber = m.phoneNumber;
            }
            if (m.firstName != null && m.hasOwnProperty("firstName")) {
                d.firstName = m.firstName;
            }
            if (m.lastName != null && m.hasOwnProperty("lastName")) {
                d.lastName = m.lastName;
            }
            if (m.patronymic != null && m.hasOwnProperty("patronymic")) {
                d.patronymic = m.patronymic;
            }
            if (m.editProfilePasswordInput != null && m.hasOwnProperty("editProfilePasswordInput")) {
                d.editProfilePasswordInput = $root.transport.EditProfilePasswordInput.toObject(m.editProfilePasswordInput, o);
            }
            if (m.organization != null && m.hasOwnProperty("organization")) {
                d.organization = $root.transport.AdminOrganization.toObject(m.organization, o);
            }
            if (m.hasDigitalProfile != null && m.hasOwnProperty("hasDigitalProfile")) {
                d.hasDigitalProfile = m.hasDigitalProfile;
            }
            return d;
        };

        UserProfile.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        UserProfile.UserSettings = (function() {

            function UserSettings(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            UserSettings.prototype.notificationSettings = null;
            UserSettings.prototype.isNewDesign = false;

            UserSettings.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.UserProfile.UserSettings)
                    return d;
                var m = new $root.transport.UserProfile.UserSettings();
                if (d.notificationSettings != null) {
                    if (typeof d.notificationSettings !== "object")
                        throw TypeError(".transport.UserProfile.UserSettings.notificationSettings: object expected");
                    m.notificationSettings = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.fromObject(d.notificationSettings);
                }
                if (d.isNewDesign != null) {
                    m.isNewDesign = Boolean(d.isNewDesign);
                }
                return m;
            };

            UserSettings.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.notificationSettings = null;
                    d.isNewDesign = false;
                }
                if (m.notificationSettings != null && m.hasOwnProperty("notificationSettings")) {
                    d.notificationSettings = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.toObject(m.notificationSettings, o);
                }
                if (m.isNewDesign != null && m.hasOwnProperty("isNewDesign")) {
                    d.isNewDesign = m.isNewDesign;
                }
                return d;
            };

            UserSettings.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            UserSettings.NotificationSettingsOverallType = (function() {

                function NotificationSettingsOverallType(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                NotificationSettingsOverallType.prototype.auctionOrder = null;
                NotificationSettingsOverallType.prototype.freeOrder = null;
                NotificationSettingsOverallType.prototype.assignedOrder = null;
                NotificationSettingsOverallType.prototype.completeOrder = null;
                NotificationSettingsOverallType.prototype.biddingOrder = null;
                NotificationSettingsOverallType.prototype.tenderOrder = null;
                NotificationSettingsOverallType.prototype.cancelOrder = null;
                NotificationSettingsOverallType.prototype.priceQuotation = null;
                NotificationSettingsOverallType.prototype.documentProcessing = null;

                NotificationSettingsOverallType.fromObject = function fromObject(d) {
                    if (d instanceof $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType)
                        return d;
                    var m = new $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType();
                    if (d.auctionOrder != null) {
                        if (typeof d.auctionOrder !== "object")
                            throw TypeError(".transport.UserProfile.UserSettings.NotificationSettingsOverallType.auctionOrder: object expected");
                        m.auctionOrder = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.fromObject(d.auctionOrder);
                    }
                    if (d.freeOrder != null) {
                        if (typeof d.freeOrder !== "object")
                            throw TypeError(".transport.UserProfile.UserSettings.NotificationSettingsOverallType.freeOrder: object expected");
                        m.freeOrder = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.fromObject(d.freeOrder);
                    }
                    if (d.assignedOrder != null) {
                        if (typeof d.assignedOrder !== "object")
                            throw TypeError(".transport.UserProfile.UserSettings.NotificationSettingsOverallType.assignedOrder: object expected");
                        m.assignedOrder = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.fromObject(d.assignedOrder);
                    }
                    if (d.completeOrder != null) {
                        if (typeof d.completeOrder !== "object")
                            throw TypeError(".transport.UserProfile.UserSettings.NotificationSettingsOverallType.completeOrder: object expected");
                        m.completeOrder = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.fromObject(d.completeOrder);
                    }
                    if (d.biddingOrder != null) {
                        if (typeof d.biddingOrder !== "object")
                            throw TypeError(".transport.UserProfile.UserSettings.NotificationSettingsOverallType.biddingOrder: object expected");
                        m.biddingOrder = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.fromObject(d.biddingOrder);
                    }
                    if (d.tenderOrder != null) {
                        if (typeof d.tenderOrder !== "object")
                            throw TypeError(".transport.UserProfile.UserSettings.NotificationSettingsOverallType.tenderOrder: object expected");
                        m.tenderOrder = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.fromObject(d.tenderOrder);
                    }
                    if (d.cancelOrder != null) {
                        if (typeof d.cancelOrder !== "object")
                            throw TypeError(".transport.UserProfile.UserSettings.NotificationSettingsOverallType.cancelOrder: object expected");
                        m.cancelOrder = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.fromObject(d.cancelOrder);
                    }
                    if (d.priceQuotation != null) {
                        if (typeof d.priceQuotation !== "object")
                            throw TypeError(".transport.UserProfile.UserSettings.NotificationSettingsOverallType.priceQuotation: object expected");
                        m.priceQuotation = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.fromObject(d.priceQuotation);
                    }
                    if (d.documentProcessing != null) {
                        if (typeof d.documentProcessing !== "object")
                            throw TypeError(".transport.UserProfile.UserSettings.NotificationSettingsOverallType.documentProcessing: object expected");
                        m.documentProcessing = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.fromObject(d.documentProcessing);
                    }
                    return m;
                };

                NotificationSettingsOverallType.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.auctionOrder = null;
                        d.freeOrder = null;
                        d.assignedOrder = null;
                        d.completeOrder = null;
                        d.biddingOrder = null;
                        d.tenderOrder = null;
                        d.cancelOrder = null;
                        d.priceQuotation = null;
                        d.documentProcessing = null;
                    }
                    if (m.auctionOrder != null && m.hasOwnProperty("auctionOrder")) {
                        d.auctionOrder = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.toObject(m.auctionOrder, o);
                    }
                    if (m.freeOrder != null && m.hasOwnProperty("freeOrder")) {
                        d.freeOrder = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.toObject(m.freeOrder, o);
                    }
                    if (m.assignedOrder != null && m.hasOwnProperty("assignedOrder")) {
                        d.assignedOrder = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.toObject(m.assignedOrder, o);
                    }
                    if (m.completeOrder != null && m.hasOwnProperty("completeOrder")) {
                        d.completeOrder = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.toObject(m.completeOrder, o);
                    }
                    if (m.biddingOrder != null && m.hasOwnProperty("biddingOrder")) {
                        d.biddingOrder = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.toObject(m.biddingOrder, o);
                    }
                    if (m.tenderOrder != null && m.hasOwnProperty("tenderOrder")) {
                        d.tenderOrder = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.toObject(m.tenderOrder, o);
                    }
                    if (m.cancelOrder != null && m.hasOwnProperty("cancelOrder")) {
                        d.cancelOrder = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.toObject(m.cancelOrder, o);
                    }
                    if (m.priceQuotation != null && m.hasOwnProperty("priceQuotation")) {
                        d.priceQuotation = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.toObject(m.priceQuotation, o);
                    }
                    if (m.documentProcessing != null && m.hasOwnProperty("documentProcessing")) {
                        d.documentProcessing = $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType.toObject(m.documentProcessing, o);
                    }
                    return d;
                };

                NotificationSettingsOverallType.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                NotificationSettingsOverallType.NotificationSettingsType = (function() {

                    function NotificationSettingsType(p) {
                        if (p)
                            for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                                if (p[ks[i]] != null)
                                    this[ks[i]] = p[ks[i]];
                    }

                    NotificationSettingsType.prototype.web = false;
                    NotificationSettingsType.prototype.email = false;

                    NotificationSettingsType.fromObject = function fromObject(d) {
                        if (d instanceof $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType)
                            return d;
                        var m = new $root.transport.UserProfile.UserSettings.NotificationSettingsOverallType.NotificationSettingsType();
                        if (d.web != null) {
                            m.web = Boolean(d.web);
                        }
                        if (d.email != null) {
                            m.email = Boolean(d.email);
                        }
                        return m;
                    };

                    NotificationSettingsType.toObject = function toObject(m, o) {
                        if (!o)
                            o = {};
                        var d = {};
                        if (o.defaults) {
                            d.web = false;
                            d.email = false;
                        }
                        if (m.web != null && m.hasOwnProperty("web")) {
                            d.web = m.web;
                        }
                        if (m.email != null && m.hasOwnProperty("email")) {
                            d.email = m.email;
                        }
                        return d;
                    };

                    NotificationSettingsType.prototype.toJSON = function toJSON() {
                        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                    };

                    return NotificationSettingsType;
                })();

                return NotificationSettingsOverallType;
            })();

            return UserSettings;
        })();

        UserProfile.UserGuides = (function() {

            function UserGuides(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            UserGuides.prototype.signGuide = null;
            UserGuides.prototype.carrierGuide = null;
            UserGuides.prototype.cargoOwnerGuide = null;

            UserGuides.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.UserProfile.UserGuides)
                    return d;
                var m = new $root.transport.UserProfile.UserGuides();
                if (d.signGuide != null) {
                    if (typeof d.signGuide !== "object")
                        throw TypeError(".transport.UserProfile.UserGuides.signGuide: object expected");
                    m.signGuide = $root.transport.UserProfile.UserGuides.UserGuide.fromObject(d.signGuide);
                }
                if (d.carrierGuide != null) {
                    if (typeof d.carrierGuide !== "object")
                        throw TypeError(".transport.UserProfile.UserGuides.carrierGuide: object expected");
                    m.carrierGuide = $root.transport.UserProfile.UserGuides.UserGuide.fromObject(d.carrierGuide);
                }
                if (d.cargoOwnerGuide != null) {
                    if (typeof d.cargoOwnerGuide !== "object")
                        throw TypeError(".transport.UserProfile.UserGuides.cargoOwnerGuide: object expected");
                    m.cargoOwnerGuide = $root.transport.UserProfile.UserGuides.UserGuide.fromObject(d.cargoOwnerGuide);
                }
                return m;
            };

            UserGuides.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.signGuide = null;
                    d.carrierGuide = null;
                    d.cargoOwnerGuide = null;
                }
                if (m.signGuide != null && m.hasOwnProperty("signGuide")) {
                    d.signGuide = $root.transport.UserProfile.UserGuides.UserGuide.toObject(m.signGuide, o);
                }
                if (m.carrierGuide != null && m.hasOwnProperty("carrierGuide")) {
                    d.carrierGuide = $root.transport.UserProfile.UserGuides.UserGuide.toObject(m.carrierGuide, o);
                }
                if (m.cargoOwnerGuide != null && m.hasOwnProperty("cargoOwnerGuide")) {
                    d.cargoOwnerGuide = $root.transport.UserProfile.UserGuides.UserGuide.toObject(m.cargoOwnerGuide, o);
                }
                return d;
            };

            UserGuides.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            UserGuides.UserGuide = (function() {

                function UserGuide(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                UserGuide.prototype.url = "";

                UserGuide.fromObject = function fromObject(d) {
                    if (d instanceof $root.transport.UserProfile.UserGuides.UserGuide)
                        return d;
                    var m = new $root.transport.UserProfile.UserGuides.UserGuide();
                    if (d.url != null) {
                        m.url = String(d.url);
                    }
                    return m;
                };

                UserGuide.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.url = "";
                    }
                    if (m.url != null && m.hasOwnProperty("url")) {
                        d.url = m.url;
                    }
                    return d;
                };

                UserGuide.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return UserGuide;
            })();

            return UserGuides;
        })();

        return UserProfile;
    })();

    transport.EditProfileEmailInput = (function() {

        function EditProfileEmailInput(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        EditProfileEmailInput.prototype.newEmail = "";
        EditProfileEmailInput.prototype.currentPassword = "";

        EditProfileEmailInput.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.EditProfileEmailInput)
                return d;
            var m = new $root.transport.EditProfileEmailInput();
            if (d.newEmail != null) {
                m.newEmail = String(d.newEmail);
            }
            if (d.currentPassword != null) {
                m.currentPassword = String(d.currentPassword);
            }
            return m;
        };

        EditProfileEmailInput.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.newEmail = "";
                d.currentPassword = "";
            }
            if (m.newEmail != null && m.hasOwnProperty("newEmail")) {
                d.newEmail = m.newEmail;
            }
            if (m.currentPassword != null && m.hasOwnProperty("currentPassword")) {
                d.currentPassword = m.currentPassword;
            }
            return d;
        };

        EditProfileEmailInput.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return EditProfileEmailInput;
    })();

    transport.Employee = (function() {

        function Employee(p) {
            this.permissions = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Employee.prototype.id = "";
        Employee.prototype.email = "";
        Employee.prototype.phoneNumber = "";
        Employee.prototype.firstName = "";
        Employee.prototype.lastName = "";
        Employee.prototype.patronymic = "";
        Employee.prototype.isActive = false;
        Employee.prototype.role = "";
        Employee.prototype.permissions = $util.emptyArray;
        Employee.prototype.hasDigitalProfile = false;
        Employee.prototype.emailStatus = "";
        Employee.prototype.canSendInvitation = false;

        Employee.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.Employee)
                return d;
            var m = new $root.transport.Employee();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.email != null) {
                m.email = String(d.email);
            }
            if (d.phoneNumber != null) {
                m.phoneNumber = String(d.phoneNumber);
            }
            if (d.firstName != null) {
                m.firstName = String(d.firstName);
            }
            if (d.lastName != null) {
                m.lastName = String(d.lastName);
            }
            if (d.patronymic != null) {
                m.patronymic = String(d.patronymic);
            }
            if (d.isActive != null) {
                m.isActive = Boolean(d.isActive);
            }
            if (d.role != null) {
                m.role = String(d.role);
            }
            if (d.permissions) {
                if (!Array.isArray(d.permissions))
                    throw TypeError(".transport.Employee.permissions: array expected");
                m.permissions = [];
                for (var i = 0; i < d.permissions.length; ++i) {
                    if (typeof d.permissions[i] !== "object")
                        throw TypeError(".transport.Employee.permissions: object expected");
                    m.permissions[i] = $root.transport.User.Permission.fromObject(d.permissions[i]);
                }
            }
            if (d.hasDigitalProfile != null) {
                m.hasDigitalProfile = Boolean(d.hasDigitalProfile);
            }
            if (d.emailStatus != null) {
                m.emailStatus = String(d.emailStatus);
            }
            if (d.canSendInvitation != null) {
                m.canSendInvitation = Boolean(d.canSendInvitation);
            }
            return m;
        };

        Employee.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.permissions = [];
            }
            if (o.defaults) {
                d.id = "";
                d.email = "";
                d.phoneNumber = "";
                d.firstName = "";
                d.lastName = "";
                d.patronymic = "";
                d.isActive = false;
                d.role = "";
                d.hasDigitalProfile = false;
                d.emailStatus = "";
                d.canSendInvitation = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.email != null && m.hasOwnProperty("email")) {
                d.email = m.email;
            }
            if (m.phoneNumber != null && m.hasOwnProperty("phoneNumber")) {
                d.phoneNumber = m.phoneNumber;
            }
            if (m.firstName != null && m.hasOwnProperty("firstName")) {
                d.firstName = m.firstName;
            }
            if (m.lastName != null && m.hasOwnProperty("lastName")) {
                d.lastName = m.lastName;
            }
            if (m.patronymic != null && m.hasOwnProperty("patronymic")) {
                d.patronymic = m.patronymic;
            }
            if (m.isActive != null && m.hasOwnProperty("isActive")) {
                d.isActive = m.isActive;
            }
            if (m.role != null && m.hasOwnProperty("role")) {
                d.role = m.role;
            }
            if (m.permissions && m.permissions.length) {
                d.permissions = [];
                for (var j = 0; j < m.permissions.length; ++j) {
                    d.permissions[j] = $root.transport.User.Permission.toObject(m.permissions[j], o);
                }
            }
            if (m.hasDigitalProfile != null && m.hasOwnProperty("hasDigitalProfile")) {
                d.hasDigitalProfile = m.hasDigitalProfile;
            }
            if (m.emailStatus != null && m.hasOwnProperty("emailStatus")) {
                d.emailStatus = m.emailStatus;
            }
            if (m.canSendInvitation != null && m.hasOwnProperty("canSendInvitation")) {
                d.canSendInvitation = m.canSendInvitation;
            }
            return d;
        };

        Employee.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return Employee;
    })();

    transport.Vehicle = (function() {

        function Vehicle(p) {
            this.blackListInfo = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        Vehicle.prototype.id = null;
        Vehicle.prototype.vehicleType = "";
        Vehicle.prototype.vehicleMake = "";
        Vehicle.prototype.vehicleCertificate = "";
        Vehicle.prototype.vehicleId = "";
        Vehicle.prototype.vehicleVin = "";
        Vehicle.prototype.isArchived = null;
        Vehicle.prototype.vehiclebody = null;
        Vehicle.prototype.vehicletrailer = null;
        Vehicle.prototype.country = "RU";
        Vehicle.prototype.verificationStatus = "";
        Vehicle.prototype.agentCarrier = null;
        Vehicle.prototype.regNo = "";
        Vehicle.prototype.blackListInfo = $util.emptyArray;
        Vehicle.prototype.ownershipType = "";
        Vehicle.prototype.ownVehicle = false;

        let $oneOfFields;

        Object.defineProperty(Vehicle.prototype, "_id", {
            get: $util.oneOfGetter($oneOfFields = ["id"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Object.defineProperty(Vehicle.prototype, "_isArchived", {
            get: $util.oneOfGetter($oneOfFields = ["isArchived"]),
            set: $util.oneOfSetter($oneOfFields)
        });

        Vehicle.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.Vehicle)
                return d;
            var m = new $root.transport.Vehicle();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.vehicleType != null) {
                m.vehicleType = String(d.vehicleType);
            }
            if (d.vehicleMake != null) {
                m.vehicleMake = String(d.vehicleMake);
            }
            if (d.vehicleCertificate != null) {
                m.vehicleCertificate = String(d.vehicleCertificate);
            }
            if (d.vehicleId != null) {
                m.vehicleId = String(d.vehicleId);
            }
            if (d.vehicleVin != null) {
                m.vehicleVin = String(d.vehicleVin);
            }
            if (d.isArchived != null) {
                m.isArchived = Boolean(d.isArchived);
            }
            if (d.vehiclebody != null) {
                if (typeof d.vehiclebody !== "object")
                    throw TypeError(".transport.Vehicle.vehiclebody: object expected");
                m.vehiclebody = $root.transport.Vehicle.Body.fromObject(d.vehiclebody);
            }
            if (d.vehicletrailer != null) {
                if (typeof d.vehicletrailer !== "object")
                    throw TypeError(".transport.Vehicle.vehicletrailer: object expected");
                m.vehicletrailer = $root.transport.Vehicle.Trailer.fromObject(d.vehicletrailer);
            }
            if (d.country != null) {
                m.country = String(d.country);
            }
            if (d.verificationStatus != null) {
                m.verificationStatus = String(d.verificationStatus);
            }
            if (d.agentCarrier != null) {
                if (typeof d.agentCarrier !== "object")
                    throw TypeError(".transport.Vehicle.agentCarrier: object expected");
                m.agentCarrier = $root.transport.AgentCarrier.fromObject(d.agentCarrier);
            }
            if (d.regNo != null) {
                m.regNo = String(d.regNo);
            }
            if (d.blackListInfo) {
                if (!Array.isArray(d.blackListInfo))
                    throw TypeError(".transport.Vehicle.blackListInfo: array expected");
                m.blackListInfo = [];
                for (var i = 0; i < d.blackListInfo.length; ++i) {
                    if (typeof d.blackListInfo[i] !== "object")
                        throw TypeError(".transport.Vehicle.blackListInfo: object expected");
                    m.blackListInfo[i] = $root.transport.Vehicle.BlackListVehicle.fromObject(d.blackListInfo[i]);
                }
            }
            if (d.ownershipType != null) {
                m.ownershipType = String(d.ownershipType);
            }
            if (d.ownVehicle != null) {
                m.ownVehicle = Boolean(d.ownVehicle);
            }
            return m;
        };

        Vehicle.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.blackListInfo = [];
            }
            if (o.defaults) {
                d.vehicleType = "";
                d.vehicleMake = "";
                d.vehicleCertificate = "";
                d.vehicleId = "";
                d.vehicleVin = "";
                d.vehiclebody = null;
                d.vehicletrailer = null;
                d.country = "RU";
                d.verificationStatus = "";
                d.agentCarrier = null;
                d.regNo = "";
                d.ownershipType = "";
                d.ownVehicle = false;
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
                if (o.oneofs)
                    d._id = "id";
            }
            if (m.vehicleType != null && m.hasOwnProperty("vehicleType")) {
                d.vehicleType = m.vehicleType;
            }
            if (m.vehicleMake != null && m.hasOwnProperty("vehicleMake")) {
                d.vehicleMake = m.vehicleMake;
            }
            if (m.vehicleCertificate != null && m.hasOwnProperty("vehicleCertificate")) {
                d.vehicleCertificate = m.vehicleCertificate;
            }
            if (m.vehicleId != null && m.hasOwnProperty("vehicleId")) {
                d.vehicleId = m.vehicleId;
            }
            if (m.vehicleVin != null && m.hasOwnProperty("vehicleVin")) {
                d.vehicleVin = m.vehicleVin;
            }
            if (m.isArchived != null && m.hasOwnProperty("isArchived")) {
                d.isArchived = m.isArchived;
                if (o.oneofs)
                    d._isArchived = "isArchived";
            }
            if (m.vehiclebody != null && m.hasOwnProperty("vehiclebody")) {
                d.vehiclebody = $root.transport.Vehicle.Body.toObject(m.vehiclebody, o);
            }
            if (m.vehicletrailer != null && m.hasOwnProperty("vehicletrailer")) {
                d.vehicletrailer = $root.transport.Vehicle.Trailer.toObject(m.vehicletrailer, o);
            }
            if (m.country != null && m.hasOwnProperty("country")) {
                d.country = m.country;
            }
            if (m.verificationStatus != null && m.hasOwnProperty("verificationStatus")) {
                d.verificationStatus = m.verificationStatus;
            }
            if (m.agentCarrier != null && m.hasOwnProperty("agentCarrier")) {
                d.agentCarrier = $root.transport.AgentCarrier.toObject(m.agentCarrier, o);
            }
            if (m.regNo != null && m.hasOwnProperty("regNo")) {
                d.regNo = m.regNo;
            }
            if (m.blackListInfo && m.blackListInfo.length) {
                d.blackListInfo = [];
                for (var j = 0; j < m.blackListInfo.length; ++j) {
                    d.blackListInfo[j] = $root.transport.Vehicle.BlackListVehicle.toObject(m.blackListInfo[j], o);
                }
            }
            if (m.ownershipType != null && m.hasOwnProperty("ownershipType")) {
                d.ownershipType = m.ownershipType;
            }
            if (m.ownVehicle != null && m.hasOwnProperty("ownVehicle")) {
                d.ownVehicle = m.ownVehicle;
            }
            return d;
        };

        Vehicle.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        Vehicle.Body = (function() {

            function Body(p) {
                this.loadingTypes = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Body.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            Body.prototype.capacity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            Body.prototype.bodyType = null;
            Body.prototype.bodySubtype = null;
            Body.prototype.loadingTypes = $util.emptyArray;

            Body.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Vehicle.Body)
                    return d;
                var m = new $root.transport.Vehicle.Body();
                if (d.volume != null) {
                    if ($util.Long)
                        (m.volume = $util.Long.fromValue(d.volume)).unsigned = false;
                    else if (typeof d.volume === "string")
                        m.volume = parseInt(d.volume, 10);
                    else if (typeof d.volume === "number")
                        m.volume = d.volume;
                    else if (typeof d.volume === "object")
                        m.volume = new $util.LongBits(d.volume.low >>> 0, d.volume.high >>> 0).toNumber();
                }
                if (d.capacity != null) {
                    if ($util.Long)
                        (m.capacity = $util.Long.fromValue(d.capacity)).unsigned = false;
                    else if (typeof d.capacity === "string")
                        m.capacity = parseInt(d.capacity, 10);
                    else if (typeof d.capacity === "number")
                        m.capacity = d.capacity;
                    else if (typeof d.capacity === "object")
                        m.capacity = new $util.LongBits(d.capacity.low >>> 0, d.capacity.high >>> 0).toNumber();
                }
                if (d.bodyType != null) {
                    if (typeof d.bodyType !== "object")
                        throw TypeError(".transport.Vehicle.Body.bodyType: object expected");
                    m.bodyType = $root.transport.Vehicle.Body.Type.fromObject(d.bodyType);
                }
                if (d.bodySubtype != null) {
                    if (typeof d.bodySubtype !== "object")
                        throw TypeError(".transport.Vehicle.Body.bodySubtype: object expected");
                    m.bodySubtype = $root.transport.Vehicle.Body.Subtype.fromObject(d.bodySubtype);
                }
                if (d.loadingTypes) {
                    if (!Array.isArray(d.loadingTypes))
                        throw TypeError(".transport.Vehicle.Body.loadingTypes: array expected");
                    m.loadingTypes = [];
                    for (var i = 0; i < d.loadingTypes.length; ++i) {
                        if (typeof d.loadingTypes[i] !== "object")
                            throw TypeError(".transport.Vehicle.Body.loadingTypes: object expected");
                        m.loadingTypes[i] = $root.transport.LoadingType.fromObject(d.loadingTypes[i]);
                    }
                }
                return m;
            };

            Body.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.loadingTypes = [];
                }
                if (o.defaults) {
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.volume = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.volume = o.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.capacity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.capacity = o.longs === String ? "0" : 0;
                    d.bodyType = null;
                    d.bodySubtype = null;
                }
                if (m.volume != null && m.hasOwnProperty("volume")) {
                    if (typeof m.volume === "number")
                        d.volume = o.longs === String ? String(m.volume) : m.volume;
                    else
                        d.volume = o.longs === String ? $util.Long.prototype.toString.call(m.volume) : o.longs === Number ? new $util.LongBits(m.volume.low >>> 0, m.volume.high >>> 0).toNumber() : m.volume;
                }
                if (m.capacity != null && m.hasOwnProperty("capacity")) {
                    if (typeof m.capacity === "number")
                        d.capacity = o.longs === String ? String(m.capacity) : m.capacity;
                    else
                        d.capacity = o.longs === String ? $util.Long.prototype.toString.call(m.capacity) : o.longs === Number ? new $util.LongBits(m.capacity.low >>> 0, m.capacity.high >>> 0).toNumber() : m.capacity;
                }
                if (m.bodyType != null && m.hasOwnProperty("bodyType")) {
                    d.bodyType = $root.transport.Vehicle.Body.Type.toObject(m.bodyType, o);
                }
                if (m.bodySubtype != null && m.hasOwnProperty("bodySubtype")) {
                    d.bodySubtype = $root.transport.Vehicle.Body.Subtype.toObject(m.bodySubtype, o);
                }
                if (m.loadingTypes && m.loadingTypes.length) {
                    d.loadingTypes = [];
                    for (var j = 0; j < m.loadingTypes.length; ++j) {
                        d.loadingTypes[j] = $root.transport.LoadingType.toObject(m.loadingTypes[j], o);
                    }
                }
                return d;
            };

            Body.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            Body.Type = (function() {

                function Type(p) {
                    this.subtypes = [];
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Type.prototype.id = "";
                Type.prototype.name = "";
                Type.prototype.subtypes = $util.emptyArray;

                Type.fromObject = function fromObject(d) {
                    if (d instanceof $root.transport.Vehicle.Body.Type)
                        return d;
                    var m = new $root.transport.Vehicle.Body.Type();
                    if (d.id != null) {
                        m.id = String(d.id);
                    }
                    if (d.name != null) {
                        m.name = String(d.name);
                    }
                    if (d.subtypes) {
                        if (!Array.isArray(d.subtypes))
                            throw TypeError(".transport.Vehicle.Body.Type.subtypes: array expected");
                        m.subtypes = [];
                        for (var i = 0; i < d.subtypes.length; ++i) {
                            if (typeof d.subtypes[i] !== "object")
                                throw TypeError(".transport.Vehicle.Body.Type.subtypes: object expected");
                            m.subtypes[i] = $root.transport.Vehicle.Body.Subtype.fromObject(d.subtypes[i]);
                        }
                    }
                    return m;
                };

                Type.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.arrays || o.defaults) {
                        d.subtypes = [];
                    }
                    if (o.defaults) {
                        d.id = "";
                        d.name = "";
                    }
                    if (m.id != null && m.hasOwnProperty("id")) {
                        d.id = m.id;
                    }
                    if (m.name != null && m.hasOwnProperty("name")) {
                        d.name = m.name;
                    }
                    if (m.subtypes && m.subtypes.length) {
                        d.subtypes = [];
                        for (var j = 0; j < m.subtypes.length; ++j) {
                            d.subtypes[j] = $root.transport.Vehicle.Body.Subtype.toObject(m.subtypes[j], o);
                        }
                    }
                    return d;
                };

                Type.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Type;
            })();

            Body.Subtype = (function() {

                function Subtype(p) {
                    if (p)
                        for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                            if (p[ks[i]] != null)
                                this[ks[i]] = p[ks[i]];
                }

                Subtype.prototype.id = "";
                Subtype.prototype.name = "";

                Subtype.fromObject = function fromObject(d) {
                    if (d instanceof $root.transport.Vehicle.Body.Subtype)
                        return d;
                    var m = new $root.transport.Vehicle.Body.Subtype();
                    if (d.id != null) {
                        m.id = String(d.id);
                    }
                    if (d.name != null) {
                        m.name = String(d.name);
                    }
                    return m;
                };

                Subtype.toObject = function toObject(m, o) {
                    if (!o)
                        o = {};
                    var d = {};
                    if (o.defaults) {
                        d.id = "";
                        d.name = "";
                    }
                    if (m.id != null && m.hasOwnProperty("id")) {
                        d.id = m.id;
                    }
                    if (m.name != null && m.hasOwnProperty("name")) {
                        d.name = m.name;
                    }
                    return d;
                };

                Subtype.prototype.toJSON = function toJSON() {
                    return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
                };

                return Subtype;
            })();

            return Body;
        })();

        Vehicle.Trailer = (function() {

            function Trailer(p) {
                this.loadingTypes = [];
                this.blackListInfo = [];
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Trailer.prototype.trailerId = "";
            Trailer.prototype.volume = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            Trailer.prototype.capacity = $util.Long ? $util.Long.fromBits(0,0,false) : 0;
            Trailer.prototype.bodyType = null;
            Trailer.prototype.trailerType = "";
            Trailer.prototype.bodySubtype = null;
            Trailer.prototype.loadingTypes = $util.emptyArray;
            Trailer.prototype.blackListInfo = $util.emptyArray;

            Trailer.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Vehicle.Trailer)
                    return d;
                var m = new $root.transport.Vehicle.Trailer();
                if (d.trailerId != null) {
                    m.trailerId = String(d.trailerId);
                }
                if (d.volume != null) {
                    if ($util.Long)
                        (m.volume = $util.Long.fromValue(d.volume)).unsigned = false;
                    else if (typeof d.volume === "string")
                        m.volume = parseInt(d.volume, 10);
                    else if (typeof d.volume === "number")
                        m.volume = d.volume;
                    else if (typeof d.volume === "object")
                        m.volume = new $util.LongBits(d.volume.low >>> 0, d.volume.high >>> 0).toNumber();
                }
                if (d.capacity != null) {
                    if ($util.Long)
                        (m.capacity = $util.Long.fromValue(d.capacity)).unsigned = false;
                    else if (typeof d.capacity === "string")
                        m.capacity = parseInt(d.capacity, 10);
                    else if (typeof d.capacity === "number")
                        m.capacity = d.capacity;
                    else if (typeof d.capacity === "object")
                        m.capacity = new $util.LongBits(d.capacity.low >>> 0, d.capacity.high >>> 0).toNumber();
                }
                if (d.bodyType != null) {
                    if (typeof d.bodyType !== "object")
                        throw TypeError(".transport.Vehicle.Trailer.bodyType: object expected");
                    m.bodyType = $root.transport.Vehicle.Body.Type.fromObject(d.bodyType);
                }
                if (d.trailerType != null) {
                    m.trailerType = String(d.trailerType);
                }
                if (d.bodySubtype != null) {
                    if (typeof d.bodySubtype !== "object")
                        throw TypeError(".transport.Vehicle.Trailer.bodySubtype: object expected");
                    m.bodySubtype = $root.transport.Vehicle.Body.Subtype.fromObject(d.bodySubtype);
                }
                if (d.loadingTypes) {
                    if (!Array.isArray(d.loadingTypes))
                        throw TypeError(".transport.Vehicle.Trailer.loadingTypes: array expected");
                    m.loadingTypes = [];
                    for (var i = 0; i < d.loadingTypes.length; ++i) {
                        if (typeof d.loadingTypes[i] !== "object")
                            throw TypeError(".transport.Vehicle.Trailer.loadingTypes: object expected");
                        m.loadingTypes[i] = $root.transport.LoadingType.fromObject(d.loadingTypes[i]);
                    }
                }
                if (d.blackListInfo) {
                    if (!Array.isArray(d.blackListInfo))
                        throw TypeError(".transport.Vehicle.Trailer.blackListInfo: array expected");
                    m.blackListInfo = [];
                    for (var i = 0; i < d.blackListInfo.length; ++i) {
                        if (typeof d.blackListInfo[i] !== "object")
                            throw TypeError(".transport.Vehicle.Trailer.blackListInfo: object expected");
                        m.blackListInfo[i] = $root.transport.Vehicle.BlackListVehicle.fromObject(d.blackListInfo[i]);
                    }
                }
                return m;
            };

            Trailer.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.arrays || o.defaults) {
                    d.loadingTypes = [];
                    d.blackListInfo = [];
                }
                if (o.defaults) {
                    d.trailerId = "";
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.volume = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.volume = o.longs === String ? "0" : 0;
                    if ($util.Long) {
                        var n = new $util.Long(0, 0, false);
                        d.capacity = o.longs === String ? n.toString() : o.longs === Number ? n.toNumber() : n;
                    } else
                        d.capacity = o.longs === String ? "0" : 0;
                    d.bodyType = null;
                    d.trailerType = "";
                    d.bodySubtype = null;
                }
                if (m.trailerId != null && m.hasOwnProperty("trailerId")) {
                    d.trailerId = m.trailerId;
                }
                if (m.volume != null && m.hasOwnProperty("volume")) {
                    if (typeof m.volume === "number")
                        d.volume = o.longs === String ? String(m.volume) : m.volume;
                    else
                        d.volume = o.longs === String ? $util.Long.prototype.toString.call(m.volume) : o.longs === Number ? new $util.LongBits(m.volume.low >>> 0, m.volume.high >>> 0).toNumber() : m.volume;
                }
                if (m.capacity != null && m.hasOwnProperty("capacity")) {
                    if (typeof m.capacity === "number")
                        d.capacity = o.longs === String ? String(m.capacity) : m.capacity;
                    else
                        d.capacity = o.longs === String ? $util.Long.prototype.toString.call(m.capacity) : o.longs === Number ? new $util.LongBits(m.capacity.low >>> 0, m.capacity.high >>> 0).toNumber() : m.capacity;
                }
                if (m.bodyType != null && m.hasOwnProperty("bodyType")) {
                    d.bodyType = $root.transport.Vehicle.Body.Type.toObject(m.bodyType, o);
                }
                if (m.trailerType != null && m.hasOwnProperty("trailerType")) {
                    d.trailerType = m.trailerType;
                }
                if (m.bodySubtype != null && m.hasOwnProperty("bodySubtype")) {
                    d.bodySubtype = $root.transport.Vehicle.Body.Subtype.toObject(m.bodySubtype, o);
                }
                if (m.loadingTypes && m.loadingTypes.length) {
                    d.loadingTypes = [];
                    for (var j = 0; j < m.loadingTypes.length; ++j) {
                        d.loadingTypes[j] = $root.transport.LoadingType.toObject(m.loadingTypes[j], o);
                    }
                }
                if (m.blackListInfo && m.blackListInfo.length) {
                    d.blackListInfo = [];
                    for (var j = 0; j < m.blackListInfo.length; ++j) {
                        d.blackListInfo[j] = $root.transport.Vehicle.BlackListVehicle.toObject(m.blackListInfo[j], o);
                    }
                }
                return d;
            };

            Trailer.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Trailer;
        })();

        Vehicle.Maker = (function() {

            function Maker(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Maker.prototype.name = "";

            Maker.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Vehicle.Maker)
                    return d;
                var m = new $root.transport.Vehicle.Maker();
                if (d.name != null) {
                    m.name = String(d.name);
                }
                return m;
            };

            Maker.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.name = "";
                }
                if (m.name != null && m.hasOwnProperty("name")) {
                    d.name = m.name;
                }
                return d;
            };

            Maker.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Maker;
        })();

        Vehicle.BlackListVehicle = (function() {

            function BlackListVehicle(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            BlackListVehicle.prototype.id = "";
            BlackListVehicle.prototype.startDate = "";
            BlackListVehicle.prototype.workProhibited = false;
            BlackListVehicle.prototype.description = "";
            BlackListVehicle.prototype.reason = "";
            BlackListVehicle.prototype.nomenclatureType = "";
            BlackListVehicle.prototype.cargoOwner = null;

            BlackListVehicle.fromObject = function fromObject(d) {
                if (d instanceof $root.transport.Vehicle.BlackListVehicle)
                    return d;
                var m = new $root.transport.Vehicle.BlackListVehicle();
                if (d.id != null) {
                    m.id = String(d.id);
                }
                if (d.startDate != null) {
                    m.startDate = String(d.startDate);
                }
                if (d.workProhibited != null) {
                    m.workProhibited = Boolean(d.workProhibited);
                }
                if (d.description != null) {
                    m.description = String(d.description);
                }
                if (d.reason != null) {
                    m.reason = String(d.reason);
                }
                if (d.nomenclatureType != null) {
                    m.nomenclatureType = String(d.nomenclatureType);
                }
                if (d.cargoOwner != null) {
                    if (typeof d.cargoOwner !== "object")
                        throw TypeError(".transport.Vehicle.BlackListVehicle.cargoOwner: object expected");
                    m.cargoOwner = $root.transport.CargoOwner.fromObject(d.cargoOwner);
                }
                return m;
            };

            BlackListVehicle.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.id = "";
                    d.startDate = "";
                    d.workProhibited = false;
                    d.description = "";
                    d.reason = "";
                    d.nomenclatureType = "";
                    d.cargoOwner = null;
                }
                if (m.id != null && m.hasOwnProperty("id")) {
                    d.id = m.id;
                }
                if (m.startDate != null && m.hasOwnProperty("startDate")) {
                    d.startDate = m.startDate;
                }
                if (m.workProhibited != null && m.hasOwnProperty("workProhibited")) {
                    d.workProhibited = m.workProhibited;
                }
                if (m.description != null && m.hasOwnProperty("description")) {
                    d.description = m.description;
                }
                if (m.reason != null && m.hasOwnProperty("reason")) {
                    d.reason = m.reason;
                }
                if (m.nomenclatureType != null && m.hasOwnProperty("nomenclatureType")) {
                    d.nomenclatureType = m.nomenclatureType;
                }
                if (m.cargoOwner != null && m.hasOwnProperty("cargoOwner")) {
                    d.cargoOwner = $root.transport.CargoOwner.toObject(m.cargoOwner, o);
                }
                return d;
            };

            BlackListVehicle.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return BlackListVehicle;
        })();

        return Vehicle;
    })();

    transport.VehicleTrailer = (function() {

        function VehicleTrailer(p) {
            this.blackListInfo = [];
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        VehicleTrailer.prototype.id = "";
        VehicleTrailer.prototype.trailerId = "";
        VehicleTrailer.prototype.trailerType = "";
        VehicleTrailer.prototype.regNo = "";
        VehicleTrailer.prototype.blackListInfo = $util.emptyArray;

        VehicleTrailer.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.VehicleTrailer)
                return d;
            var m = new $root.transport.VehicleTrailer();
            if (d.id != null) {
                m.id = String(d.id);
            }
            if (d.trailerId != null) {
                m.trailerId = String(d.trailerId);
            }
            if (d.trailerType != null) {
                m.trailerType = String(d.trailerType);
            }
            if (d.regNo != null) {
                m.regNo = String(d.regNo);
            }
            if (d.blackListInfo) {
                if (!Array.isArray(d.blackListInfo))
                    throw TypeError(".transport.VehicleTrailer.blackListInfo: array expected");
                m.blackListInfo = [];
                for (var i = 0; i < d.blackListInfo.length; ++i) {
                    if (typeof d.blackListInfo[i] !== "object")
                        throw TypeError(".transport.VehicleTrailer.blackListInfo: object expected");
                    m.blackListInfo[i] = $root.transport.Vehicle.BlackListVehicle.fromObject(d.blackListInfo[i]);
                }
            }
            return m;
        };

        VehicleTrailer.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.arrays || o.defaults) {
                d.blackListInfo = [];
            }
            if (o.defaults) {
                d.id = "";
                d.trailerId = "";
                d.trailerType = "";
                d.regNo = "";
            }
            if (m.id != null && m.hasOwnProperty("id")) {
                d.id = m.id;
            }
            if (m.trailerId != null && m.hasOwnProperty("trailerId")) {
                d.trailerId = m.trailerId;
            }
            if (m.trailerType != null && m.hasOwnProperty("trailerType")) {
                d.trailerType = m.trailerType;
            }
            if (m.regNo != null && m.hasOwnProperty("regNo")) {
                d.regNo = m.regNo;
            }
            if (m.blackListInfo && m.blackListInfo.length) {
                d.blackListInfo = [];
                for (var j = 0; j < m.blackListInfo.length; ++j) {
                    d.blackListInfo[j] = $root.transport.Vehicle.BlackListVehicle.toObject(m.blackListInfo[j], o);
                }
            }
            return d;
        };

        VehicleTrailer.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return VehicleTrailer;
    })();

    transport.TnWebsocketEventData = (function() {

        function TnWebsocketEventData(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        TnWebsocketEventData.prototype.topic = "";
        TnWebsocketEventData.prototype.action = "";
        TnWebsocketEventData.prototype.payload = null;
        TnWebsocketEventData.prototype.data = null;

        TnWebsocketEventData.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.TnWebsocketEventData)
                return d;
            var m = new $root.transport.TnWebsocketEventData();
            if (d.topic != null) {
                m.topic = String(d.topic);
            }
            if (d.action != null) {
                m.action = String(d.action);
            }
            if (d.payload != null) {
                if (typeof d.payload !== "object")
                    throw TypeError(".transport.TnWebsocketEventData.payload: object expected");
                m.payload = $root.google.protobuf.Any.fromObject(d.payload);
            }
            if (d.data != null) {
                if (typeof d.data !== "object")
                    throw TypeError(".transport.TnWebsocketEventData.data: object expected");
                m.data = $root.google.protobuf.Any.fromObject(d.data);
            }
            return m;
        };

        TnWebsocketEventData.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.topic = "";
                d.action = "";
                d.payload = null;
                d.data = null;
            }
            if (m.topic != null && m.hasOwnProperty("topic")) {
                d.topic = m.topic;
            }
            if (m.action != null && m.hasOwnProperty("action")) {
                d.action = m.action;
            }
            if (m.payload != null && m.hasOwnProperty("payload")) {
                d.payload = $root.google.protobuf.Any.toObject(m.payload, o);
            }
            if (m.data != null && m.hasOwnProperty("data")) {
                d.data = $root.google.protobuf.Any.toObject(m.data, o);
            }
            return d;
        };

        TnWebsocketEventData.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return TnWebsocketEventData;
    })();

    transport.WebSocketConfig = (function() {

        function WebSocketConfig(p) {
            if (p)
                for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                    if (p[ks[i]] != null)
                        this[ks[i]] = p[ks[i]];
        }

        WebSocketConfig.prototype.url = "";
        WebSocketConfig.prototype.reconnectInterval = 0;
        WebSocketConfig.prototype.reconnectAttempts = 0;

        WebSocketConfig.fromObject = function fromObject(d) {
            if (d instanceof $root.transport.WebSocketConfig)
                return d;
            var m = new $root.transport.WebSocketConfig();
            if (d.url != null) {
                m.url = String(d.url);
            }
            if (d.reconnectInterval != null) {
                m.reconnectInterval = d.reconnectInterval | 0;
            }
            if (d.reconnectAttempts != null) {
                m.reconnectAttempts = d.reconnectAttempts | 0;
            }
            return m;
        };

        WebSocketConfig.toObject = function toObject(m, o) {
            if (!o)
                o = {};
            var d = {};
            if (o.defaults) {
                d.url = "";
                d.reconnectInterval = 0;
                d.reconnectAttempts = 0;
            }
            if (m.url != null && m.hasOwnProperty("url")) {
                d.url = m.url;
            }
            if (m.reconnectInterval != null && m.hasOwnProperty("reconnectInterval")) {
                d.reconnectInterval = m.reconnectInterval;
            }
            if (m.reconnectAttempts != null && m.hasOwnProperty("reconnectAttempts")) {
                d.reconnectAttempts = m.reconnectAttempts;
            }
            return d;
        };

        WebSocketConfig.prototype.toJSON = function toJSON() {
            return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
        };

        return WebSocketConfig;
    })();

    return transport;
})();

export const google = $root.google = (() => {

    const google = {};

    google.protobuf = (function() {

        const protobuf = {};

        protobuf.Any = (function() {

            function Any(p) {
                if (p)
                    for (var ks = Object.keys(p), i = 0; i < ks.length; ++i)
                        if (p[ks[i]] != null)
                            this[ks[i]] = p[ks[i]];
            }

            Any.prototype.type_url = "";
            Any.prototype.value = $util.newBuffer([]);

            Any.fromObject = function fromObject(d) {
                if (d instanceof $root.google.protobuf.Any)
                    return d;
                var m = new $root.google.protobuf.Any();
                if (d.type_url != null) {
                    m.type_url = String(d.type_url);
                }
                if (d.value != null) {
                    if (typeof d.value === "string")
                        $util.base64.decode(d.value, m.value = $util.newBuffer($util.base64.length(d.value)), 0);
                    else if (d.value.length)
                        m.value = d.value;
                }
                return m;
            };

            Any.toObject = function toObject(m, o) {
                if (!o)
                    o = {};
                var d = {};
                if (o.defaults) {
                    d.type_url = "";
                    if (o.bytes === String)
                        d.value = "";
                    else {
                        d.value = [];
                        if (o.bytes !== Array)
                            d.value = $util.newBuffer(d.value);
                    }
                }
                if (m.type_url != null && m.hasOwnProperty("type_url")) {
                    d.type_url = m.type_url;
                }
                if (m.value != null && m.hasOwnProperty("value")) {
                    d.value = o.bytes === String ? $util.base64.encode(m.value, 0, m.value.length) : o.bytes === Array ? Array.prototype.slice.call(m.value) : m.value;
                }
                return d;
            };

            Any.prototype.toJSON = function toJSON() {
                return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
            };

            return Any;
        })();

        return protobuf;
    })();

    return google;
})();

export { $root as default };
