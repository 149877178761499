/* eslint-disable @typescript-eslint/naming-convention */
export const monitoringLocalization = {
  requestsTitle: 'Заявки',
  cargosTitle: 'Грузы',
  actions: {
    downloadReport: 'Выгрузка отчета',
  },
  filters: {
    search: {
      placeholder: 'Поиск',
    },
    tabs: {
      active: 'Текущие',
      pending: 'Плановые',
      problem: 'Проблемы',
      closed: 'Архивные',
    },
  },
  events: {
    WENT_ON_ROUTE: {
      message: 'Выехал на маршрут',
      description: 'В {{time}}, {{date}} водитель выехал на {{point}} точку. Всего точек: {{pointAmount}}',
    },
    ARRIVED_AT_POINT: {
      message: 'Приехал на {{pointNumber}} точку', // 3
    },
    CARGO_LOADED: {
      message: 'Погрузка завершена',
    },
    CARGO_DELIVERED: {
      message: 'Разгрузка завершена',
    },
    DRIVER_CONFIRMED_ORDER: {
      message: 'Водитель подтвердил заказ',
    },
    ORDER_DELIVERED: {
      message: 'Заявка завершена',
    },
    DRIVER_LOCATION: {
      message: 'Местоположение водителя',
    },
    DRIVER_NOT_CONNECTED: {
      message: 'Нет связи с водителем',
      descriptions: 'Мы не можем дозвониться до водителя, попробуйте сами',
    },
    ORDER_CANCELLED: {
      message: 'Заявка отменена',
    },
    FAILED_TO_CONTACT_VIA_PHONE: {
      message: 'Не удалось дозвониться',
    },
    PROBLEM_ON_ROAD: {
      message: 'Проигнорирован запрос на передачу локации',
    },
    LOCATION_REQUEST_FAILED: {
      message: 'Проблема на маршруте',
    },
    errors: {
      unknownProblem: 'Не известная проблема',
    },
  },
  statuses: {
    ON_THE_WAY: {
      message: 'В пути на точку',
    },
    PENDING: {
      message: 'Ожидается принятие заявки от водителя',
    },
  },
  details: {
    blocks: {
      info: {
        title: 'Статус по маршруту',
        noEvents: 'Нет событий',
        phone: 'Номер телефона',
        driver: 'Водитель',
        status: 'Текущий статус',
      },
      graph: {
        title: 'График прогресса',
        passed: 'Пройдено: {{value}} км',
        left: 'Осталось: {{value}} км',
      },
      progress: {
        title: 'Прогресс',
        lastStatus: 'Последний статус по точке',
        statuses: 'Статусы по точке',
        loadingAddress: 'Адрес погрузки',
        plannedTime: 'Плановое время',
        unloadingAddress: 'Адрес грузополучателя',
        noEvents: 'Нет событий',
        onTheWay: 'В пути на точку',
      },
      location: {
        title: 'Локация на карте',
        updatedIn: 'Геолокация от {{data}}',
        voiceRobot: 'Голосовой робот',
        planRoute: 'Плановый маршрут',
        factRoute: 'Фактический маршрут',
      },
    },
    timeResults: {
      faster: 'Быстрее на {{time}}',
      slower: 'Опоздал на {{time}}',
    },
    tabs: {
      points: 'Точки на маршруте',
      cargos: 'Уникальные грузы',
      events: 'Лог событий',
    },
    actions: {
      orderActions: 'Действия по заявке',
      stopMonitor: 'Остановить обзвон',
      startMonitor: 'Возобновить обзвон',
    },
  },
  prefixes: {
    day: 'д',
    hour: 'ч',
    minute: 'мин',
  },
  dialogs: {
    notificationSettings: {
      title: 'Настройка уведомлений',
      noEmails: 'Вы не добавили ни одной почты для рассылки',
      applySettings: 'Применить настройки',
      addEmail: 'Добавьте Email получателя уведомлений',
      addEmailPlaceholder: 'example1@email.ru, example2@email.ru, ...',
      selectEmail: 'Выберите получателя для настройки уведомлений',
      settinstTitle: 'Установите параметры уведомлений',
      add: 'Добавить',
      carrierLogistician: 'Логист грузоперевозчика',
      emailsVisibility: {
        hide: 'Свернуть список получателей',
        show: 'Развернуть список получателей',
      },
      settings: {
        common: {
          title: 'Общие',
          DRIVER_CONFIRMED_ORDER: 'Водитель подтвердил заявку',
          WENT_ON_ROUTE: 'Началось исполнение заявки',
          ORDER_DELIVERED: 'Завершилось исполнение заявки',
          ORDER_CANCELLED: 'Заявка отменена',
        },
        cargo: {
          title: 'Груз',
          CARGO_LOADED: 'Погружен',
          CARGO_DELIVERED: 'Разгружен',
        },
        location: {
          title: 'Местоположение',
          DRIVER_LOCATION: 'Получено текущее местоположение',
          ARRIVED_AT_POINT: 'Приезд на место погрузки/разгрузки',
        },
        problems: {
          title: 'Проблемы',
          FAILED_TO_CONTACT_VIA_PHONE: 'Не удалось дозвониться',
          PROBLEM_ON_ROAD: 'Проблема на маршруте',
          LOCATION_REQUEST_FAILED: 'Проигнорирован запрос на передачу локации',
        },
      },
      errors: {
        emailMaxLength: 'Длинна строки не должна привышать {{length}} символов',
      },
    },
    planFactReport: {
      title: 'Выгрузка план/факт отчёта',
      rangeTitle: 'Выберите интервал',
      downloadReport: 'Выгрузить отчёт',
      success: 'Отчет успешно сформирован',
      error: 'Ошибка выгрузки отчета',
    },
  },
  cargos: {
    statuses: {
      not_started: 'Ожидает',
      loaded: 'Загружен',
      delivered: 'Доставлен',
      partly_delivered: 'Частично доставлен',
      cancelled: 'Отменён',
    },
    packagingType: {
      PALLETS: 'Паллеты',
      BOXES: 'Коробки',
      BUNDLES: 'Пачки',
      PLACER: 'Россыпь',
    },
  },
  robotMonitor: {
    startMonitor: {
      success: 'Обзвон роботом успешно включен',
      errors: {
        unknown_error: 'Ошибка начала обзвона роботом',
        route_not_found: 'Заявка не найдена',
        mon_not_found: 'Ошибка включения несуществующего мониторинга',
        no_next_mon_command: 'Робот уже выполнил весь план по обзвону',
        incorrect_route_state: 'Включение робота для маршрута в некорректном статусе. Маршурт отменен или завершен.',
      },
    },
    stopMonitor: {
      success: 'Обзвон роботом успешно выключен',
      errors: {
        unknown_error: 'Ошибка отключения обзвона роботом',
        route_not_found: 'Заявка не найдена',
        mon_not_found: 'Ошибка выключения несуществующего мониторинга',
        no_next_mon_command: 'Робот уже выполнил весь план по обзвону',
        incorrect_route_state: 'Выключение робота для маршрута в некорректном статусе. Маршурт отменен или завершен.',
      },
    },
  },
};
