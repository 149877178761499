import { Injectable } from '@angular/core';
import { transport } from '@transport/proto';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnGqlClientCarrierService } from '../../../gql-client-carrier';

@Injectable({
  providedIn: 'root',
})
export class TnAgentCarriersService {
  constructor(private readonly graphQL: TnGqlClientCarrierService) {}

  public getAgentCarriers(
    listenX?: number,
    withSpinner?: boolean,
    options?: Record<string, unknown>,
  ): Observable<{ list: transport.IAgentCarrier[]; totalCount: number }> {
    return this.graphQL
      .getAgentCarriers(listenX, withSpinner, options)
      .pipe(map(result => ({ list: result.agentCarriers, totalCount: result.totalCount })));
  }
}
