import { Injectable } from '@angular/core';
import { filter } from 'rxjs/operators';

import { TnYamapsLoaderService } from '../loader/yamaps-loader.service';
import { TnYamapsUtilsService } from '../utils/yamaps-utils.service';

interface IExtendedYmapsApi {
  // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: tech debt
  GeoObject: {
    (...args): void;
    new (
      arg0: {
        geometry: { type: string; coordinates: unknown };
        properties: { iconContent: unknown; hintContent: unknown };
      },
      arg1: { preset: unknown },
    ): unknown;
  };
  // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: tech debt
  Placemark: {
    (...args): void;
    new (
      arg0: number[],
      arg1: { hintContent: unknown; balloonContent: unknown; iconContent: unknown },
      arg2: {
        iconLayout: string;
        iconImageHref: unknown;
        iconImageSize: unknown;
        iconImageOffset: unknown;
        iconContentOffset: unknown;
        iconContentLayout: unknown;
      },
    ): unknown;
  };
  templateLayoutFactory: { createClass };
}

declare const ymaps: IExtendedYmapsApi;

@Injectable({
  providedIn: 'root',
})
export class TnYamapsBalloonsService {
  public readonly defaultLatitude = 55.76;

  public readonly defaultLongitude = 37.64;

  public ymaps?: IExtendedYmapsApi;

  constructor(private readonly utils: TnYamapsUtilsService, private readonly yamapsLoader: TnYamapsLoaderService) {
    if (typeof this.ymaps === 'undefined') {
      void this.yamapsLoader.loadApi$.pipe(filter(val => Boolean(val))).subscribe(() => {
        this.ymaps = (window as Window & { ymaps }).ymaps as IExtendedYmapsApi;
      });
    }
  }

  private geoObject(geoObjectConf, config: { draggable?; preset?; dragCallback? }) {
    const geoObj = this.ymaps?.GeoObject ?? (() => void 0);
    const geo = new geoObj(geoObjectConf, {
      draggable: config.draggable,
      preset: Boolean(config.preset) ? config.preset : 'islands#icon',
    });
    geo.events.add(
      'dragend',
      // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: tech debt
      (res: { originalEvent: { target: { geometry: { _coordinates } } } }) => {
        config.dragCallback(res.originalEvent.target.geometry._coordinates);
      },
    );
    return geo;
  }

  private placeMark(config: {
    iconContentLayoutConfig?: { color; fontWeight };
    hintContent?;
    balloonContent?;
    iconContent?;
    iconImageHref?;
    iconImageSize?;
    iconImageOffset?;
    iconContentOffset?;
  }) {
    const MyIconContentLayout = this.ymaps?.templateLayoutFactory.createClass(
      `<div style="color:${config.iconContentLayoutConfig?.color};
          font-weight: '${config.iconContentLayoutConfig?.fontWeight};">
          $[properties.iconContent]</div>`,
    );
    const iconImageSize = 48;
    const iconImageOffset = -24;
    const iconContentOffset = 15;
    const placeMark = this.ymaps?.Placemark ?? (() => void 0);
    return new placeMark(
      this.utils.defaultCity,
      {
        hintContent: Boolean(config.hintContent) ? config.hintContent : '',
        balloonContent: Boolean(config.balloonContent) ? config.balloonContent : '',
        iconContent: Boolean(config.iconContent) ? config.iconContent : '',
      },
      {
        iconLayout: 'default#imageWithContent',
        iconImageHref: Boolean(config.iconImageHref)
          ? config.iconImageHref
          : 'https://avatars.mds.yandex.net/get-entity_search/69916/76932575/S120x120Face',
        iconImageSize: Boolean(config.iconImageSize) ? config.iconImageSize : [iconImageSize, iconImageSize],
        iconImageOffset: Boolean(config.iconImageOffset) ? config.iconImageOffset : [iconImageOffset, iconImageOffset],
        iconContentOffset: Boolean(config.iconContentOffset) ? config.iconContentOffset : [iconContentOffset, iconContentOffset],
        iconContentLayout: MyIconContentLayout,
      },
    );
  }

  /**
   * Create custom balloons
   * @param config Config for balloons
   * @return object balloon
   */
  public getBalloon(config: {
    type;
    coordinates;
    draggable?;
    dragCallback?;
    hintContent?;
    baloonContent?;
    iconContent?;
    iconContentLayoutConfig?;
    balloonContent?;
    iconImageHref?;
    iconImageSize?;
    iconImageOffset?;
    iconContentOffset?;
    preset?;
  }) {
    const geoObjectConf = {
      geometry: {
        type: 'Point',
        coordinates: Boolean(config.coordinates) ? config.coordinates : [this.defaultLatitude, this.defaultLongitude],
      },
      properties: {
        iconContent: Boolean(config.iconContent) ? config.iconContent : '',
        hintContent: Boolean(config.hintContent) ? config.hintContent : '',
      },
    };
    switch (config.type) {
      case 'simple':
        return new ymaps.GeoObject(geoObjectConf, {
          preset: Boolean(config.preset) ? config.preset : 'islands#blackStretchyIcon',
        });
      case 'point':
        return this.geoObject(geoObjectConf, config);
      case 'image-content':
        return this.placeMark(config);
      default:
        break;
    }
  }
}
