import { createSelector } from '@ngrx/store';
import {
  connectDocumentsWithSign,
  groupOrderAttachments,
  IOrderAttachment,
  IOrderDocumentsListItem,
  ORDER_SIGNING_WAY,
  TnOrderAttachmentSign,
  USER_ROLE,
} from '@transport/ui-interfaces';

import { getCurrentUserRole } from '../../current-user/current-user.selectors';
import { IOrderDetailsCarrierState } from '../reducers/order-details-carrier.reducer';
import { isReadonlyOrderSelector, orderDetailsCarrierStateSelector } from './order-details-carrier.selectors';

export const canEditDocumentSelector = createSelector(
  orderDetailsCarrierStateSelector,
  isReadonlyOrderSelector,
  (state: IOrderDetailsCarrierState, isReadonlyOrder) => Boolean(state.order?.canModifyUploadedDocuments) && !isReadonlyOrder,
);

export const hasDocumentsSelector = createSelector(orderDetailsCarrierStateSelector, (state: IOrderDetailsCarrierState) => {
  return state.displayedDocuments.length > 0;
});

const displayedDocumentsSelector = createSelector(orderDetailsCarrierStateSelector, (state: IOrderDetailsCarrierState) => {
  const result = state.displayedDocuments.filter(
    item =>
      state.order?.signingWay === ORDER_SIGNING_WAY.DIGITAL_SIGNATURE ||
      (item.documentType?.innerName !== 'contract_form' &&
        item.documentType.innerName !== 'contract' &&
        item.documentType.innerName !== 'term_agreement'),
  );
  return result;
});

const signFilesSelector = createSelector(displayedDocumentsSelector, docs => {
  return docs.filter(item => Boolean(item.signedFile)).map(item => new TnOrderAttachmentSign(item));
});

const onlyDocsSelector = createSelector(displayedDocumentsSelector, docs => {
  return docs.filter(item => !Boolean(item.signedFile));
});

const documentsWithSignSelector = createSelector(
  orderDetailsCarrierStateSelector,
  signFilesSelector,
  onlyDocsSelector,
  getCurrentUserRole,
  (state: IOrderDetailsCarrierState, signFiles: TnOrderAttachmentSign[], docs: IOrderAttachment[], currentUserRole: USER_ROLE) => {
    return connectDocumentsWithSign(state.order, signFiles, docs, currentUserRole);
  },
);

export const documentsGroupsSelector = createSelector(documentsWithSignSelector, (documents: IOrderDocumentsListItem[]) => {
  return groupOrderAttachments(documents);
});
