import * as sharedFragments from '../shared/graphql-fragments-shared';

export const STORAGE_POINT_FRAGMENT_SHORT = `
  id
  name
  settlement
  companyName
`;

export const STORAGE_POINT_FRAGMENT = `
  id
  name
  country
  address
  longitude
  latitude
  pointName
  settlement
  companyName
  workingHoursFrom
  workingHoursTo
  loadingTypes {
    id
    name
  }
  typePoint
  actions
  drivingDirectionsImg
  isArchived
  fiasCode
  contacts {
    id
    country
    fullName
    firstName
    lastName
    phoneNumber
    contactInfo
  }
  timezone {
    id
    settlement
    timezone
  }
  type
  extPointId
`;

export const ORDERS_FRAGMENT = `
  id
  externalNo
  loadingPlaces {
    loadingType {
      id
      name
    }
    storagePoint {
      ${STORAGE_POINT_FRAGMENT_SHORT}
    }
  }
  unloadingPlaces {
    loadingType {
      id
      name
    }
    storagePoint {
      ${STORAGE_POINT_FRAGMENT_SHORT}
    }
  }
  loadingDate
  unloadingDate
  createdDatetime
  lastModifiedDatetime
  cargoType {
    name
    packagingType
  }
  weight
  volume
  cargoPackagesCount
  price {
    amount
    currency
  }
  priceWithVat {
    amount
    currency
  }
  priceWithoutVat {
    amount
    currency
  }
  comment
  status
  isVatIncluded
  temperatureMode
  carrier {
    id
    email
    lastName
    firstName
    companyName
  }
  driver {
    surname
    name
    phone
  }
  isMarket
  statusOnEvent
  endDatetime
  viewEndDatetime
  secondsToLifeTimeExpired
  secondsToReservationTimeExpired
  viewProcessDeadline
  gracePeriod
  customerOrganization {
    ${sharedFragments.ORGANIZATION_SHORT_FRAGMENT}
  }
  signingWay
  documentActualStatus(documentType: ALL) {
    status
    documentType {
      innerName
    }
  }
  documentStatusHistory(documentType: ALL) {
    status
    documentType {
      innerName
    }
  }
  vehicleRequirements {
    bodySubtype {
      name
    }
    capacity
    volume
    loadingTypes {
      name
    }
  }
  loadingTypes
`;

export const DRAFTS_FRAGMENT = `
  id
  order {
    externalNo
    loadingDate
    unloadingDate
    weight
    volume
    price {
      amount
      currency
    }
    comment
    status
    isVatIncluded
    temperatureMode
    cargoType {
      name
      packagingType
    }
    cargoPackagesCount
  }
  loadingPlaces {
    loadingType {
      id
      name
    }
    storagePoint {
      ${STORAGE_POINT_FRAGMENT_SHORT}
    }
  }
  unloadingPlaces {
    loadingType {
      id
      name
    }
    storagePoint {
      ${STORAGE_POINT_FRAGMENT_SHORT}
    }
  }
  loadingDate,
  unloadingDate,
  vehicleRequirements {
    bodySubtype {
      name
    }
    volume
    capacity
  }
`;

export const ORDER_FRAGMENT = `
  edmStatus
  documentFlowSettings
  carrierContractSigning
  id @skip(if: $isDraft)
  owner @skip(if: $isDraft) {
    firstName
    lastName
    phoneNumber
    email
    id
  }
  loadingPlaces @skip(if: $isDraft) {
    seqId
    contactPersons {
      fullName
      phoneNumber
    }
    storagePoint {
     ${STORAGE_POINT_FRAGMENT}
    }
    arriveDatetimeFrom
    arriveDatetimeTo
    comment
    loadingType {
      id
      name
    }
  }
  unloadingPlaces @skip(if: $isDraft) {
    seqId
    contactPersons {
      fullName
      phoneNumber
    }
    storagePoint {
      ${STORAGE_POINT_FRAGMENT}
    }
    arriveDatetimeFrom
    arriveDatetimeTo
    comment
    loadingType {
      id
      name
    }
    attachedDocuments {
      filename
      url
    }
  }
  createdDatetime @skip(if: $isDraft)
  lastModifiedDatetime @skip(if: $isDraft)
  carrierAssignedDatetime
  cargoType {
    id
    name
  }
  weight
  volume
  temperatureMode
  price {
    amount
    currency
  }
  comment
  status
  isVatIncluded
  allowCounterOffer
  carrier @skip(if: $isDraft) {
    id
    email
    lastName
    firstName
    companyName
    phoneNumber
  }
  carrierGroup @skip(if: $isDraft) {
    ${sharedFragments.getMyCarrierGroupFragment()}
  }
  actualContractNumber
  transportReservedDatetime
  contractAttachedDatetime
  readyToGoDatetime
  cargoPackagesCount
  allocationType
  containerInfo {
    containerNumber
    containerSeal
  }
  vehicleRequirements @skip(if: $isDraft) {
    ${sharedFragments.VEHICLE_REQUIREMENTS_FRAGMENT}
  }
  ${sharedFragments.CARGOS_FRAGMENT}
  cargoPlaceRequirements @skip(if: $isDraft) {
    packagingType
    length
    width
    height
  }
  insurance @skip(if: $isDraft) {
    insuranceContract {
      id
    }
    status
    data
    certificateUrl
  }
  priceWithVat {
    amount
    currency
  }
  priceWithoutVat {
    amount
    currency
  }
  isMarket
  externalNo
  statusOnEvent @skip(if: $isDraft)
  paymentPeriod
  paymentType{
    ${sharedFragments.PAYMENT_TYPE_FRAGMENT}
  }
  endDatetime
  viewEndDatetime
  viewProcessDeadline
  secondsToLifeTimeExpired
  secondsToReservationTimeExpired
  uploadedDocuments @skip(if: $isCopyMode) {
    ${sharedFragments.ORDER_UPLOADED_DOCUMENT_FRAGMENT}
  }
  extraServices @skip(if: $isDraft) {
    id
    name
    price
    vatAmount
    isVatIncluded
  }
  settingsESignRequired @skip(if: $isDraft) {
    id
  }
  customerOrganization {
    ${sharedFragments.ORGANIZATION_SHORT_FRAGMENT}
  }
  routeSheet {
    isDriverOnline
  }
  signingWay
  documentActualStatus (documentType: ALL) @skip(if: $isCopyMode) {
    status
    documentType {
      innerName
    }
  }
  documentStatusHistory (documentType: ALL) @skip(if: $isCopyMode) {
    status
    documentType {
      innerName
    }
  }
  actualCarrierOrganization
  agentUsesOwnVehicle
  agentFeeAndFreightDetails {
    agentCarrierFreightIncludingVat
    agentCarrierVatRate
    agentCarrierFreightVatAmount
    agentVatAmountPerAgentCarrier
    agentFeeIncludingVat
    agentFeeVatRate
    agentFeeVatAmount
    totalPrice
  }
  carrierContract {
    id
    contractNumber
    name
    agentType
  }
`;

// Some fields duplicate order`s fields. For draft it is normal and expectable
export const DRAFT_FRAGMENT = `
  id
  order {
    ${ORDER_FRAGMENT}
  }
  loadingPlaces {
    seqId
    contactPersons {
      fullName
      phoneNumber
    }
    storagePoint {
     ${STORAGE_POINT_FRAGMENT}
    }
    arriveDatetimeFrom
    arriveDatetimeTo
    comment
    loadingType {
      id
      name
    }
  }
  unloadingPlaces {
    seqId
    contactPersons {
      fullName
      phoneNumber
    }
    storagePoint {
      ${STORAGE_POINT_FRAGMENT}
    }
    arriveDatetimeFrom
    arriveDatetimeTo
    comment
    loadingType {
      id
      name
    }
  }
  vehicleRequirements {
    bodySubtype {
      id
      name
    }
    capacity
    volume
    length
    width
    height
  }
  cargoPlaceRequirements {
    packagingType
    length
    width
    height
  }
  extraServices {
    id
    name
    price
    vatAmount
  }
  cargos {
    cargoId
    name
    status
    loadingPointId
    size {
      length
      width
      height
      weight
      volume
      packageType
      quantity
      setByWaybill
    }
    deliveryUnits {
      unitId
      cargoId
      deliveryPointId
      status
      size {
        length
        width
        height
        volume
        weight
        packageType
        quantity
        setByWaybill
      }
    }
  }
`;

export const LOT_FRAGMENT_SHORT = `
  id
  startDatetime
  endDatetime
  startPrice
  lastBet
  lastBetDatetime
  auctionStatus
  hasExtraConditionsForMarket
  viewEndDatetime
  minStepPrice
  participantOrganizationsCount
`;

export const LOT_FRAGMENT = `
    id
    order {
      id
    }
    startDatetime
    endDatetime
    startPrice
    minStepPrice
    lastBet
    lastBetCarrier {
      id
      email
      companyName
      firstName
      lastName
    }
    lastBetDatetime
    enableNotifications
    auctionStatus
    quantityOfProlongations
    isProlongationAvailable
    minutesOfProlongation
    timesOfProlongation
    minutesTillEndToProlong
    mayBeProlonged
    viewEndDatetime
  `;

export const INSURANCE_CONTRACT = `
  id
  insuranceCompany {
    codename
    name
  }
  isArchived
  parameters
  generalNumber
  tariff
  startDatetime
  endDatetime
  isDefault
`;

export const TENDER_FRAGMENT = `
  id
  lotNumber
  bidsEndDatetime
  loadingSettlement
  loadingSettlementFias
  course
  courseFias
  unloadingPlace
  activeDateFrom
  activeDateTo
  ordersQuantity
  maxOrderPrice
  stepPrice
  winnerPrice
  displayedStatus
  bidsQuantity
  vehicleRequirements {
    bodyType
    bodySubtype {
      id
      name
    }
    liftingCapacity
    volume
    length
    width
    height
    mustHaveManipulator
  }
  bids {
    id
    price
    comment
    carrierOrganization {
      name
    }
  }
  carrierOrganization {
    id
    name
  }
  isVatIncluded
`;
