import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { TypedAction } from '@ngrx/store/src/models';
import { transport } from '@transport/proto';
import { FREIGHT_ERROR, IDriver, IOrder, VEHICLE_TYPE_PROPERTY } from '@transport/ui-interfaces';
import { GraphQLError } from 'graphql';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { RouterActions } from '../../ngrx-router/actions/router.actions';
import { ITnState } from '../../state/index';
import { TnToasterFacade, TOAST_TYPE } from '../../toaster/toaster.facade';
import * as OrderDetailsCarrierActions from '../actions/order-details-carrier.actions';
import * as selectors from '../selectors/order-details-carrier.selectors';
import { TnCarrierOrdersService } from '../services/carrier/carrier-order.service';

@Injectable({
  providedIn: 'root',
})
export class TnOrderDetailsCarrierFlowEffects {
  public operationFail = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.saveInfoForContractFailure),
      tap(({ error }) => {
        //retrow error for handling by global error handler
        throw error;
      }),
    ),
  );

  public acceptOrder = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.acceptOrder),
      switchMap(({ orderId }) => {
        return this.carrierOrdersService.acceptOrder(orderId).pipe(
          map((order: IOrder) => {
            this.toastFacade.showMessage('carrier.order.action.accept.success', TOAST_TYPE.SUCCESS);
            return OrderDetailsCarrierActions.setOrder({ order });
          }),
          catchError((error: GraphQLError) => {
            if (error.extensions?.category === 'CARRIER_REFUSED_AUCTION_ORDER_BEFORE') {
              this.toastFacade.showMessage('carrier.request.error.takeCanceledAuction', TOAST_TYPE.ERROR);
            }
            return of(OrderDetailsCarrierActions.acceptOrderFailure({ error }));
          }),
        );
      }),
    ),
  );

  public cancelOrder = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.cancelOrder),
      switchMap(({ orderId, isTerminationAgreement }) => {
        return this.carrierOrdersService.cancelOrder(orderId, isTerminationAgreement).pipe(
          switchMap((order: IOrder) => {
            const resultActions: TypedAction<string>[] = [
              OrderDetailsCarrierActions.setOrder({ order }),
              OrderDetailsCarrierActions.selectAttractedTruck({ attracted: null }),
            ];
            this.toastFacade.showMessage('carrier.order.action.cancel.success', TOAST_TYPE.SUCCESS);
            return of(...resultActions);
          }),
        );
      }),
    ),
  );

  public completeOrder = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.completeOrder),
      switchMap(({ orderId }) => {
        return this.carrierOrdersService.completeOrder(orderId).pipe(
          map((order: IOrder) => {
            this.toastFacade.showMessage('carrier.order.action.complete.success', TOAST_TYPE.SUCCESS);
            return OrderDetailsCarrierActions.setOrder({ order });
          }),
        );
      }),
    ),
  );

  public letsGo = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.letsGo),
      switchMap(({ orderId }) => {
        return this.carrierOrdersService.letsGo(orderId).pipe(
          map((order: IOrder) => {
            this.toastFacade.showMessage('carrier.order.action.letsGo.success', TOAST_TYPE.SUCCESS);
            return OrderDetailsCarrierActions.setOrder({ order });
          }),
        );
      }),
    ),
  );

  // eslint-disable-next-line max-lines-per-function -- sematically unseparated
  public saveInfoForContract = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.saveInfoForContractStart),
      switchMap(action =>
        of(action).pipe(
          withLatestFrom(
            this.store.pipe(select(selectors.orderDetailsCarrierStateSelector)),
            this.store.pipe(select(selectors.isSelectedVehicleAndDriverNotEmpty)),
            this.store.pipe(select(selectors.attractedSelector)),
            this.store.pipe(select(selectors.isAttractedSelectorAndFreightNotEmpty)),
            this.store.pipe(select(selectors.freightErrorSelector)),
            this.store.pipe(select(selectors.freightSelector)),
          ),
        ),
      ),
      switchMap(([, state, hasData, attracted, hasFreight, freightError, freightState]) => {
        const isFreightForExpeditorState = this.isFreightForExpeditorState(hasFreight, state.order?.carrierContract);
        const isFreightError = this.isFreightError(freightError);
        if (!hasData || !isFreightForExpeditorState || Boolean(isFreightError)) {
          this.toastFacade.showMessage('shared.errors.formInvalid');
          return of(OrderDetailsCarrierActions.validateContractForm());
        }

        return this.carrierOrdersService
          .reserveTransportV2(
            state.selectedDriver?.id ?? '',
            state.order?.id ?? '',
            state.selectedVehicle?.id ?? '',
            state.order?.carrierContract?.id ?? null,
            attracted === VEHICLE_TYPE_PROPERTY.ATTRACTED ? state.selectedAgentCarrier?.id ?? '' : null,
            freightState,
          )
          .pipe(
            switchMap(order => {
              this.toastFacade.showMessage('carrier.order.action.saveInfoForContract.success', TOAST_TYPE.SUCCESS);
              const resultActions: TypedAction<string>[] = [
                OrderDetailsCarrierActions.saveInfoForContractSuccess({ order }),
                OrderDetailsCarrierActions.setFormDirty({ isDirty: false }),
              ];

              if (Boolean(state.selectedSecondDriver)) {
                resultActions.push(
                  OrderDetailsCarrierActions.selectSecondDriverStart({
                    driver: state.selectedSecondDriver as IDriver,
                  }),
                );
              }

              return of(...resultActions);
            }),

            catchError(error => {
              return of(OrderDetailsCarrierActions.saveInfoForContractFailure({ error }));
            }),
          );
      }),
    ),
  );

  public handleExpiredOrder = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.expiredOrder),
      withLatestFrom(this.store.pipe(select(selectors.orderDetailsCarrierStateSelector))),
      switchMap(([, state]) => {
        this.toastFacade.showMessage('shared.orders.lifetime.expired');
        if (Boolean(state.order) && this.carrierOrdersService.isFree(state.order ?? ({} as IOrder))) {
          return [OrderDetailsCarrierActions.pageDestroy(), RouterActions.routerGo({ path: ['/orders/carrier/'] })];
        }
        return [OrderDetailsCarrierActions.pageDestroy(), OrderDetailsCarrierActions.markOrderAsCancelled()];
      }),
    ),
  );

  private isFreightError(freightError: string): boolean {
    return Boolean(freightError) && freightError !== FREIGHT_ERROR.AGENT_AMONT_MORE_FREIGHT;
  }

  private isFreightForExpeditorState(hasFreight: boolean, carrierContract?: transport.Order.ICarrierContract | null): boolean {
    return Boolean(carrierContract?.agentType === 'EXPEDITOR') || hasFreight;
  }

  constructor(
    private readonly carrierOrdersService: TnCarrierOrdersService,
    private readonly action$: Actions,
    private readonly store: Store<ITnState>,
    private readonly toastFacade: TnToasterFacade,
  ) {}
}
