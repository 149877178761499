import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TnCoreModule } from '@transport/core';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnUiTranslateModule } from '@transport/ui-translate';
import { MarkdownModule } from 'ngx-markdown';

import { TnConfirmationEffects } from './confirmation.effects';
import { confirmationReducer } from './confirmation.reducers';
import { TnConfirmationComponent } from './dialog/confirmation.component';

const CONFIRMATION_EFFECTS = [TnConfirmationEffects];

@NgModule({
  imports: [
    StoreModule.forFeature('confirmation', confirmationReducer),
    EffectsModule.forFeature(CONFIRMATION_EFFECTS),
    TnCoreModule,
    TnUiTranslateModule,
    TnUiMaterialModule,
    MarkdownModule.forRoot(),
  ],
  declarations: [TnConfirmationComponent],
  exports: [StoreModule, EffectsModule],
})
export class TnConfirmationModule {}
