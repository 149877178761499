import { ActionReducerMap } from '@ngrx/store';

import { orderDetailsCarrierFeatureName, orderDetailsCarrierReducer } from './reducers/order-details-carrier.reducer';
import { ordersCarrierReducer, ordersTableCarrierFeatureName } from './reducers/orders-table-carrier.reducer';
import { ordersOwnerReducer, ordersTableOwnerFeatureName } from './reducers/orders-table-owner.reducer';

export const ordersTableFeatureName = 'orders';

export interface IOrdersState {
  [ordersTableCarrierFeatureName]: ReturnType<typeof ordersCarrierReducer>;
  [ordersTableOwnerFeatureName]: ReturnType<typeof ordersOwnerReducer>;
  [orderDetailsCarrierFeatureName]: ReturnType<typeof orderDetailsCarrierReducer>;
}

export const reducers: ActionReducerMap<IOrdersState> = {
  [ordersTableCarrierFeatureName]: ordersCarrierReducer,
  [ordersTableOwnerFeatureName]: ordersOwnerReducer,
  [orderDetailsCarrierFeatureName]: orderDetailsCarrierReducer,
};
