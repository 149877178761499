/**
 * OWNER and CARGO_OWNER have same values.
 * CARGO_OWNER is needed for auth only, server returns this value for owner role.
 * OWNER should be used everywhere across the app.
 */
export enum USER_ROLE {
  NA = '',
  CARRIER = 'carrier',
  OWNER = 'owner',
  CARGO_OWNER = 'owner',
  TNDL_SECURITY_STAFF = 'TNDL_SECURITY_STAFF',
  TASKS = 'tasks', // for apollo
  RISKS = 'risks', // for apollo
}

export enum USER_PERMISSION_CODE {
  ADMIN_CG = 'is_company_group_admin',
}
