import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import {
  IReconcilationActWebsocketMessageDto,
  RECONCILIATION_ACT_STATUS,
  TnReconcilationActWebsocketMessage,
} from '@transport/ui-interfaces';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { routerGo } from '../../ngrx-router/actions/router.actions';
import { TnToasterFacade } from '../../toaster/toaster.facade';
import * as ReconciliationActActions from '../actions/reconciliation-act.actions';
import * as ReconciliationActApiActions from '../actions/reconciliation-act-api.actions';
import * as ReconciliationActselectors from '../selectors/reconciliation-act.selectors';
import { TnReconcilationActApiService } from '../services/reconcilation-act-api.service';
import { TnReconcilationActUrlService } from '../services/reconcilation-act-url.service';

@Injectable({ providedIn: 'root' })
export class TnReconciliationActEffects {
  public loadReconciliationAct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReconciliationActApiActions.loadReconciliationActStart),
      switchMap(({ guid }) =>
        this.reconcilationActApiService.getAct(guid).pipe(
          switchMap(act => of(act).pipe(withLatestFrom(this.store.pipe(select(ReconciliationActselectors.completedActs))))),
          map(([act, completedActs]) => {
            if (act.status === RECONCILIATION_ACT_STATUS.PROCESS && completedActs.includes(act.guid)) {
              // T20S-3962 - повторное получение акта сравнения добавлено для обработки ситуации,
              // когда данные сокета обрабатываются раньше запроса getAct
              return ReconciliationActApiActions.repeatGetAct({ guid });
            }
            return ReconciliationActApiActions.loadReconciliationActSuccess({ act });
          }),
          catchError(error => of(ReconciliationActApiActions.loadReconciliationActFailure({ error }))),
        ),
      ),
    );
  });

  public repeatGetAct$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReconciliationActApiActions.repeatGetAct),
      switchMap(({ guid }) =>
        this.reconcilationActApiService.getAct(guid).pipe(
          map(act => ReconciliationActApiActions.loadReconciliationActSuccess({ act })),
          catchError(error => of(ReconciliationActApiActions.loadReconciliationActFailure({ error }))),
        ),
      ),
    );
  });

  public loadReconciliationOrganizations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReconciliationActApiActions.loadReconciliationOrganizationsStart),
      switchMap(() =>
        this.reconcilationActApiService.getOrganizations().pipe(
          map(organizations => ReconciliationActApiActions.loadReconciliationOrganizationsSuccess({ organizations })),
          catchError(error => of(ReconciliationActApiActions.loadReconciliationOrganizationsFailure({ error }))),
        ),
      ),
    );
  });

  public loadReconciliationMyOrganization$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReconciliationActApiActions.loadReconciliationMyOrganizationStart),
      switchMap(() =>
        this.reconcilationActApiService.getMyOrganization().pipe(
          map(organization => ReconciliationActApiActions.loadReconciliationMyOrganizationSuccess({ organization })),
          catchError(error => of(ReconciliationActApiActions.loadReconciliationMyOrganizationFailure({ error }))),
        ),
      ),
    );
  });

  public loadReconciliationRules$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReconciliationActApiActions.loadReconciliationRulesStart),
      switchMap(() =>
        this.reconcilationActApiService.getRules().pipe(
          map(rules => ReconciliationActApiActions.loadReconciliationRulesSuccess({ rules })),
          catchError(error => of(ReconciliationActApiActions.loadReconciliationRulesFailure({ error }))),
        ),
      ),
    );
  });

  public saveReconciliationact$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ReconciliationActApiActions.saveReconciliationActStart),
      concatMap(({ act }) =>
        this.reconcilationActApiService.saveAct(act).pipe(
          this.toastFacade.notificationHandler('shared.reconciliation.form.submit.error', 'shared.reconciliation.form.submit.success'),
          map(savedAct => ReconciliationActApiActions.saveReconciliationActSuccess({ act: savedAct })),
          catchError(error => of(ReconciliationActApiActions.saveReconciliationActFailure({ error }))),
        ),
      ),
    );
  });

  public onSaveContractSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(ReconciliationActApiActions.saveReconciliationActSuccess),
      map(({ act }) => routerGo({ path: ['reconciliation/view', act.guid] })),
    ),
  );

  public trySaveAct = createEffect(() =>
    this.actions$.pipe(
      ofType(ReconciliationActActions.saveActBtnClick),
      switchMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(ReconciliationActselectors.selectIsFormValid))))),
      map(([action, isFormValid]) => {
        if (isFormValid) {
          return ReconciliationActApiActions.saveReconciliationActStart({ act: action.act });
        }
        return ReconciliationActActions.invalidFormSubmitted();
      }),
    ),
  );

  public invalidFormSubmitted = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ReconciliationActActions.invalidFormSubmitted),

        map(() => {
          this.toastFacade.showMessage({ code: 'shared.errors.formInvalid' });
        }),
      ),
    { dispatch: false },
  );

  public watchRouteParamsGuid = createEffect(() =>
    this.actions$.pipe(
      ofType(ReconciliationActActions.reconciliationActDetailsRouteParamsChanged),
      map(action => action.params.guid),
      switchMap(guid => of(guid).pipe(withLatestFrom(this.store.pipe(select(ReconciliationActselectors.selectActGuid))))),
      filter(([routeGuid, storeGuid]) => Boolean(routeGuid) && routeGuid !== storeGuid),
      map(([routeGuid, storeGuid]) =>
        ReconciliationActApiActions.loadReconciliationActStart({
          guid: routeGuid as string,
        }),
      ),
    ),
  );

  public watchRouteParamsMode = createEffect(() =>
    this.actions$.pipe(
      ofType(ReconciliationActActions.reconciliationActDetailsRouteParamsChanged),
      map(action => ReconciliationActActions.reconciliationActSetFormMode({ formMode: action.params.formMode })),
    ),
  );

  public actDetailsFormCreated = createEffect(() =>
    this.actions$.pipe(
      ofType(ReconciliationActActions.actDetailsFormCreated),
      switchMap(action => of(action).pipe(withLatestFrom(this.store.pipe(select(ReconciliationActselectors.selectIsDisabledFormMode))))),
      filter(([, isDisabledFormMode]) => !isDisabledFormMode),
      switchMap(() =>
        of(
          ReconciliationActApiActions.loadReconciliationRulesStart(),
          ReconciliationActApiActions.loadReconciliationOrganizationsStart(),
          ReconciliationActApiActions.loadReconciliationMyOrganizationStart(),
        ),
      ),
    ),
  );

  // public watchWebsocketActions = createEffect(() =>
  //   this.actions$.pipe(
  //     ofWebsocketTopicAndAction('acts_comparison', 'acts_comparison_completed'),
  //     map(action =>
  //       ReconciliationActApiActions.websocketCompleteMessageReceived({
  //         message: TnReconcilationActWebsocketMessage.fromDto(
  //           action.eventData.payload as IReconcilationActWebsocketMessageDto,
  //           this.urlService,
  //         ),
  //       }),
  //     ),
  //   ),
  // );

  public watchWebsocketCompleteForCurrentAct = createEffect(() =>
    this.actions$.pipe(
      ofType(ReconciliationActApiActions.websocketCompleteMessageReceived),
      map(action =>
        ReconciliationActActions.addCompletedActsAndUpdateSelectedAct({
          message: action.message,
        }),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly reconcilationActApiService: TnReconcilationActApiService,
    private readonly toastFacade: TnToasterFacade,
    private readonly store: Store,
    private readonly urlService: TnReconcilationActUrlService,
  ) {}
}
