import { createReducer, on } from '@ngrx/store';
import { transport } from '@transport/proto';
import { createNewGroup, TCarrierGroupForm } from '@transport/ui-interfaces';

import { carrierGroupActions } from '../actions/carriers-group.actions';

const newGroup = createNewGroup();

const initialFormState: TCarrierGroupForm = {
  name: newGroup.name,
  carriers: [],
};

export interface ICarrierGroupState {
  loading: boolean;
  group: transport.Carrier.ICarrierGroup;
  formValue: TCarrierGroupForm;
  isFormDisabled: boolean;
  isFormValid: boolean;
  isFormDirty: boolean;
}

export const initialState: ICarrierGroupState = {
  loading: false,
  group: {},
  formValue: initialFormState,
  isFormDisabled: false,
  isFormValid: false,
  isFormDirty: false,
};

const groupReducer = createReducer(
  initialState,
  on(carrierGroupActions.loadGroup, state => {
    return {
      ...initialState,
      loading: true,
    };
  }),
  on(carrierGroupActions.loadGroupSuccess, (state, { group }) => {
    const { name, carriers } = group;
    const formValue: TCarrierGroupForm = {
      name,
      carriers: carriers ?? [],
    };

    const newState = {
      ...state,
      group,
      formValue: formValue,
      loading: false,
    };
    return newState;
  }),
  on(carrierGroupActions.loadGroupFailure, () => {
    return {
      ...initialState,
    };
  }),
  on(carrierGroupActions.setFormMode, (state, action) => {
    const { mode } = action;
    const isFormDisabled = !['edit', 'create', 'copy'].some(v => v === mode);

    const newState = {
      ...state,
      isFormDisabled,
    };

    return newState;
  }),
  on(carrierGroupActions.formValidStatusChanged, (state, { isValid }) => ({
    ...state,
    isFormValid: isValid,
  })),
  on(carrierGroupActions.formDirtyStatusChanged, (state, { isDirty }) => ({
    ...state,
    isFormDirty: isDirty,
  })),
  on(carrierGroupActions.saveGroupSuccess, state => ({ ...state, isFormDirty: false })),
);

export function carrierGroupReducer(state, action) {
  return groupReducer(state, action);
}
