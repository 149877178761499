import { Directive } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup, UntypedFormGroup } from '@angular/forms';

@Directive()
export abstract class TnFormControlDirective implements ControlValueAccessor {
  public form: UntypedFormGroup = this.createFormGroup();

  public constructor(protected readonly fb: FormBuilder) {}

  public registerOnChange(fn: (...args) => null): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: (...args) => null): void {
    this.onTouched = fn;
  }

  public writeValue(obj): void {
    this.form.patchValue(Boolean(obj) ? obj : {}, { emitEvent: false });
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable({ emitEvent: false }) : this.form.enable({ emitEvent: false });
  }

  protected abstract createFormGroup(): FormGroup;

  protected onChange = (...args) => null;

  protected onTouched = () => null;
}
