export interface IVehicleData {
  vehicleType: string;
  vehicleMake: string;
  country: string;
  vehicleCertificate: string;
  vehicleId?: string; // TODO deprecated
  vehicleVin: string;
  vehicleDrivers?: string[];
  vehicleRegNo?: string;

  vehicleTrailerTrailerId?: string; // TODO deprecated
  vehicleTrailerType?: string;
  vehicleTrailerSubtype?: string;
  vehicleTrailerVolume?: number;
  vehicleTrailerCapacity?: number;
  vehicleTrailerLoadingTypes?: string | string[];
  vehicleTrailerRegNo?: string;

  vehicleBodyType?: string;
  vehicleBodySubtype?: string;
  vehicleBodyVolume?: number;
  vehicleBodyCapacity?: number;
  vehicleBodyLoadingTypes?: string | string[];

  agentCarrierId?: string | null;
  ownershipType?: string;
}
