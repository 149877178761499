import { VehicleListFilter } from "../../table/vehicles-table.service";

export function readVehiclesFilterInput(filter?: VehicleListFilter) {
  return {
    vehicleType: filter?.vehicleType?.id,
    isThirdParty: filter?.isThirdParty,
    ownership: filter?.ownership?.id,
    regNo: filter?.regNo?.length ? filter.regNo : null,
    vehicleBrand: filter?.vehicleBrand?.id,
    trailerRegNo: filter?.trailerRegNo?.length ? filter.trailerRegNo : null,
    bodySubtype: filter?.bodyType?.id,
    capacity: filter?.capacity?.length ? filter.capacity : null,
    volume: filter?.volume?.length ? filter.volume : null,
    loadingType: filter?.loadingType?.id,
    availability: filter?.availability,
  };
}
