import { Injectable } from "@angular/core";
import { USER_ROLE } from "@transport/ui-interfaces";
import { BehaviorSubject } from "rxjs";

@Injectable(
  {providedIn: 'root'}
)
export class DriversService {
  public role$ = new BehaviorSubject<USER_ROLE | null>(null);
}
