<transport-tw-input-field
  [size]="size"
  label="{{ !labelInside ? placeholder : '' }}"
  [control]="control"
  [errorsSize]="errorsSize"
  [required]="!labelInside ? required : false "
  [showErrors]="showErrors"
  [errorToMessage]="errorToMessage"
>
  <transport-tw-input [size]="size" matAutocompleteOrigin #origin="matAutocompleteOrigin" [control]="control">
    <div *ngIf="labelInside && !searchRef.value && !inputFocused" (click)="clickAtInsideLabel()" class="text-black opacity-[0.42] text-sm">
      {{placeholder}}
      <span *ngIf="required" class="text-red-500">*</span>
    </div>
    <input
      aria-label="Input"
      [required]="required"
      tw-input
      placeholder="{{ labelInside && !inputFocused ? '' : ('shared.actions.startTyping' | translate) }}"
      [matAutocomplete]="auto"
      (input)="searching($event)"
      [disabled]="control.disabled"
      [attr.data-test]="dataTest"
      (blur)="onBlur()"
      (focus)="inputFocused = true"
      #searchRef
      #trigger="matAutocompleteTrigger"
      [matAutocompleteConnectedTo]="origin"
      transportAutocompletePosition="trigger"
    />
    <div *ngIf="allowClear" class="flex items-center justify-center cursor-pointer w-5 h-5 mx-1">
      <mat-icon class="!w-[11px] !h-[11px] text-gray-400" svgIcon="close" (click)="clearSelect($event)"></mat-icon>
    </div>
    <mat-icon *ngIf="!control.disabled" matSuffix class="arrow !cursor-pointer" (click)="onClickArrow()">
      {{inputFocused ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
    </mat-icon>
  </transport-tw-input>
</transport-tw-input-field>

<mat-autocomplete class="tw-menu-block" #auto [displayWith]="displayFn.bind(this)" (optionSelected)="onItemSelected($event)">
  <mat-option *ngIf="showAddBtn" class="!p-0" (click)="addItem()" [attr.data-test]="dataTest + '-add-button'">
    <transport-tw-menu-option variant="button" size="sm">+ {{'shared.actions.add' | translate }}</transport-tw-menu-option>
  </mat-option>
  <mat-option *ngIf="visibleData?.length === 0 && showEmptyMessage" class="!p-0" disabled="true">
    <transport-tw-menu-option size="sm" variant="center">{{ 'owner.order.publishTypeNew.noOptions' | translate }}</transport-tw-menu-option>
  </mat-option>
  <mat-option *ngFor="let item of visibleData" class="!p-0" [value]="getValue(item)">
    <transport-tw-menu-option
      [variant]="isSelected(item) ? 'active' : 'option'"
      [text]="getLabel(item)"
      [additionalText]="bindAdditionalLabel && getAdditionalLabel(item)"
    >
    </transport-tw-menu-option>
  </mat-option>
</mat-autocomplete>
