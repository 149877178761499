import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TIMEOUT } from '@transport/ui-utils';
import { Subject, throttleTime } from 'rxjs';
import { VehicleService } from '../vehicle.service';

@UntilDestroy()
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: 'marketplace-vehicle-modal',
  templateUrl: './vehicle-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,

})
export class VehicleModalComponent implements OnInit {
  public service: VehicleService = this.data.service;
  private action$ = new Subject();
  public showLimitations = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: { service: VehicleService }) {}

  public ngOnInit() {
    this.action$.pipe(untilDestroyed(this), throttleTime(TIMEOUT.DEFAULT)).subscribe(() => this.service.vm?.action());
  }

  public action() {
    this.action$.next(null);
  }
}
