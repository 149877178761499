import { createAction, props } from '@ngrx/store';
import { transport } from '@transport/proto';
import {
  FREIGHT_ERROR,
  ICargoPackagingType,
  IOrder,
  IVehicle,
  ORDER_DETAILS_PAGE_KIND,
  VEHICLE_TYPE_PROPERTY,
} from '@transport/ui-interfaces';
import { GraphQLError } from 'graphql';

const actionPrefix = '[Order Details Carrier]';

export const pageDestroy = createAction(`${actionPrefix} Order detail page destroed`);

export const markOrderAsCancelled = createAction(`${actionPrefix} Mark order as canceled`);

export const pereodicalUpdateOrder = createAction(`${actionPrefix} Load pereodically auction order`, props<{ orderId: string }>());

export const expiredOrder = createAction(`${actionPrefix} Expired Order`);

export const loadOrderDetailsStart = createAction(
  `${actionPrefix} Load  Order Details Start`,
  props<{
    orderId: string;
    orderKind?: ORDER_DETAILS_PAGE_KIND;
  }>(),
);

export const loadOrderSuccess = createAction(`${actionPrefix} Load  Order Success`, props<{ order: IOrder }>());

export const loadOrderFailure = createAction(`${actionPrefix} Load  Order Failure`, props<{ error: GraphQLError }>());

export const clearDetailState = createAction(`${actionPrefix} Clear Detail State`);

export const loadOrderDetailsSuccess = createAction(
  `${actionPrefix} Load  Order Details Success`,
  props<{ order: IOrder; cargoBodyTypes: transport.Vehicle.Body.IType[]; packagingTypes: ICargoPackagingType[] }>(),
);

export const refreshOrderStart = createAction(
  `${actionPrefix} Refresh  Order Start`,
  props<{
    orderId: string;
    orderKind?: ORDER_DETAILS_PAGE_KIND;
  }>(),
);

export const updateOrderSuccess = createAction(`${actionPrefix} Update  Order Success`, props<{ order: IOrder }>());

export const unreserveTransportStart = createAction(
  `${actionPrefix} Unreserve transport start`,
  props<{ orderId: string; newVehicle?: IVehicle }>(),
);

export const unreserveTransportSuccess = createAction(`${actionPrefix} Unreserve transport success`, props<{ order: IOrder }>());

export const unreserveTransportFailure = createAction(`${actionPrefix} Unreserve transport failure`, props<{ error: GraphQLError }>());

export const selectDriverStart = createAction(`${actionPrefix} Select driver start`, props<{ driver: transport.IDriver }>());

export const selectDriver = createAction(`${actionPrefix} Select driver`, props<{ driver: transport.IDriver }>());

export const selectSecondDriverStart = createAction(`${actionPrefix} Select second driver start`, props<{ driver: transport.IDriver }>());

export const selectSecondDriver = createAction(`${actionPrefix} Select second driver`, props<{ driver: transport.IDriver }>());

export const sameDrivers = createAction(`${actionPrefix} Driver and second driver is same`);

export const clearDriver = createAction(`${actionPrefix} Clear driver`);

export const selectVehicle = createAction(`${actionPrefix} Select vehicle`, props<{ vehicle: transport.IVehicle }>());

export const clearVehicle = createAction(`${actionPrefix} Clear vehicle`);

export const selectAgentCarrier = createAction(
  `${actionPrefix} Select agent carrier`,
  props<{ agentCarrier?: transport.IAgentCarrier | null }>(),
);

export const selectAttractedTruck = createAction(
  `${actionPrefix} Select attracted truck`,
  props<{ attracted: VEHICLE_TYPE_PROPERTY | null }>(),
);

export const setFreight = createAction(`${actionPrefix} Set freight`, props<{ freight: string | null }>());

export const setFreightError = createAction(`${actionPrefix} Set freight error`, props<{ error: string | FREIGHT_ERROR }>());

export const setOrder = createAction(`${actionPrefix} Set order`, props<{ order: IOrder }>());

export const updateContractStatus = createAction(`${actionPrefix} update contract status`, props<{ orderPart: Partial<IOrder> }>());

export const acceptOrder = createAction(`${actionPrefix} accept order`, props<{ orderId: string }>());

export const acceptOrderFailure = createAction(`${actionPrefix} Accept Order Failure`, props<{ error: GraphQLError }>());

export const cancelOrder = createAction(`${actionPrefix} cancel order`, props<{ orderId: string; isTerminationAgreement: boolean }>());

export const completeOrder = createAction(`${actionPrefix} complete order`, props<{ orderId: string }>());

export const letsGo = createAction(`${actionPrefix} letsGo order`, props<{ orderId: string }>());

export const saveInfoForContractStart = createAction(`${actionPrefix} Save Info For Contract Start`);

export const saveInfoForContractSuccess = createAction(`${actionPrefix} Save Info For Contract Success`, props<{ order: IOrder }>());

export const saveInfoForContractFailure = createAction(`${actionPrefix} Save Info For Contract Failure`, props<{ error: GraphQLError }>());

export const validateContractForm = createAction(`${actionPrefix} Validate Contract Form`);

export const addInsuranceForOrderStart = createAction(
  `${actionPrefix} Add Insurance For Order Start`,
  props<{ orderId: string; insurance: transport.IInsurance }>(),
);

export const addInsuranceForOrderSuccess = createAction(
  `${actionPrefix} Add Insurance For Order Success`,
  props<{ orderId: string; insurance: transport.IInsurance }>(),
);

export const addInsuranceForOrderFailure = createAction(
  `${actionPrefix} Add Insurance For Order Failure`,
  props<{ error: GraphQLError }>(),
);

export const setFormDirty = createAction(`${actionPrefix} Set Form Dirty`, props<{ isDirty: boolean }>());

export const setBlackListForDriver = createAction(
  `${actionPrefix} Set Black List For Driver`,
  props<{ list: transport.Driver.IBlackListDriver[] | null }>(),
);

export const setBlackListForVehicle = createAction(
  `${actionPrefix} Set Black List For Vehicle`,
  props<{ list: transport.Vehicle.IBlackListVehicle[] | null }>(),
);

export const signTnCrypto = createAction(`${actionPrefix} Sign Tn Crypto`);

export const changeOrderContract = createAction(
  `${actionPrefix} Change Order Contract`,
  props<{ contract: transport.Order.ICarrierContract }>(),
);

export const getCarrierContracts = createAction(`${actionPrefix} Get Carrier Contracts`, props<{ id: string }>());

export const getCarrierContractsSuccess = createAction(
  `${actionPrefix} Get Carrier Contracts Success`,
  props<{ contracts: transport.Order.ICarrierContract[] }>(),
);

export const getCarrierContractsFailure = createAction(`${actionPrefix} Get Carrier Contracts Failure`, props<{ error: GraphQLError }>());
