import { AbstractControl, ValidationErrors } from '@angular/forms';
import { transport } from '@transport/proto';
import { MAX_INTEGER } from '@transport/ui-utils';

// eslint-disable-next-line complexity -- TODO: tech debt
function getErrors(value: transport.IAuctionProlongationParameters | null): ValidationErrors | null {
  const result: ValidationErrors | null =
    (value?.isProlongationAvailable ?? false) &&
    ((value?.prolongationCount ?? 0) < 1 ||
      (value?.prolongationTime ?? 0) < 1 ||
      (value?.minutesTillEndToProlong ?? 0) < 1 ||
      (value?.prolongationCount ?? 0) > MAX_INTEGER ||
      (value?.prolongationTime ?? 0) > MAX_INTEGER ||
      (value?.minutesTillEndToProlong ?? 0) > MAX_INTEGER)
      ? { auctionProlongationParametersError: true }
      : null;

  return result;
}

export function auctionProlongationSettingsValidator(control: AbstractControl) {
  return getErrors(control.value);
}
