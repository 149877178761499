import { transport } from '@transport/proto';

import { createNewCargoOwner } from '../cargo-owner/cargo-owner.interface';
import { TPlace } from './place-common.interface';

/**
 * Place interface.
 * This interfaces are needed if protobuf types is not enough,
 * e.g. when entity should have a property with specific string values.
 */

export type ADDRESS_TYPE = 'WAREHOUSE' | 'STORE' | 'CLIENT' | 'RAILWAYSTATION' | 'PORT' | 'GARAGE';

export const ADDRESS_TYPES: ADDRESS_TYPE[] = ['WAREHOUSE', 'STORE', 'CLIENT', 'RAILWAYSTATION', 'PORT', 'GARAGE'];

export interface IPlace extends transport.IPlace {
  typePoint: TPlace;
  type?: ADDRESS_TYPE | string;
  extPointId?: string;
  actions?: TPlace[];
  interestedPersons?: {email: string}[] | null;
}

export const createNewPlace = (input: Partial<IPlace> = {}) => {
  input.cargoOwner = !Boolean(input.cargoOwner) ? createNewCargoOwner() : input.cargoOwner;
  return transport.Place.toObject(new transport.Place(input), {
    defaults: true,
  }) as IPlace;
};

export const createNewPlaces = (input: Partial<transport.Order.IStoragePoint[]> = []) => {
  return input.map(
    item =>
      transport.Order.StoragePoint.toObject(new transport.Order.StoragePoint(item), {
        defaults: true,
      }) as transport.Order.IStoragePoint,
  );
};
