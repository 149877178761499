import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { transport } from '@transport/proto';
import {
  AUCTION_STATUS,
  EDM_STATUS,
  getOrderDeadline,
  IOrder,
  ORDER_ALLOCATION_TYPE,
  ORDER_LOT_STATUS,
  ORDER_STATUS,
  orderStatusTranslateDictionary,
  POINT_TYPES_ORDER_LIST,
  ROUTE_SHEETS_CODE,
  TnOrderDocumentTypes,
  TOrderAttachmentForSave,
  USER_ROLE,
} from '@transport/ui-interfaces';
import { isNumber } from '@transport/ui-utils';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnFeatureAccessService } from '../../../feature-access/feature-access.service';
import { TnGqlClientSharedService } from '../../../gql-client-shared/graphql-client-shared.service';
import { CARRIER_CONTRACT_SIGNING } from '../../../profile';

@Injectable({
  providedIn: 'root',
})
export class TnCommonOrdersService {
  constructor(
    protected translate: TranslateService,
    protected featureAccess: TnFeatureAccessService,
    protected graphQLShared: TnGqlClientSharedService,
  ) {}

  public isAuctionAllocation(order: IOrder) {
    return Boolean(order) && order.allocationType === ORDER_ALLOCATION_TYPE.AUCTION_ALLOCATION;
  }

  public isAuctionActive(order: IOrder) {
    return Boolean(order) && order.lot?.auctionStatus === 'ACTIVE';
  }

  public isAuctionFinished(order: IOrder) {
    return Boolean(order?.lot) && order.lot?.auctionStatus === AUCTION_STATUS.FINISHED;
  }

  public isFree(order: IOrder) {
    return Boolean(order) && order.status === ORDER_STATUS.FREE;
  }

  public isBidding(order: IOrder) {
    return Boolean(order) && order.allocationType === ORDER_ALLOCATION_TYPE.BIDDING_ALLOCATION;
  }

  public isCanceled(order: IOrder) {
    return Boolean(order) && order.status === ORDER_STATUS.CANCELED;
  }

  public isAssigned(order: IOrder) {
    return Boolean(order) && order.status === ORDER_STATUS.ASSIGNED;
  }

  public isTransportReserved(status: ORDER_STATUS) {
    return status === ORDER_STATUS.TRANSPORT_RESERVED;
  }

  public isContractAttached(order: IOrder) {
    return Boolean(order) && (order.status === ORDER_STATUS.CONTRACT_ATTACHED || order.contractAttachedDatetime);
  }

  public isReadyToGo(order: IOrder) {
    return Boolean(order) && order.status === ORDER_STATUS.READY_TO_GO;
  }

  public isCompleted(order: IOrder) {
    return Boolean(order) && order.status === ORDER_STATUS.COMPLETED;
  }

  public isOrderHaveProblems(order: IOrder) {
    return Boolean(order.routeSheet?.actualProblemEvents?.length);
  }

  public isOrderHaveTimeLagProblems(order: IOrder): boolean {
    return (
      this.isOrderHaveProblems(order) &&
      Boolean(
        order.routeSheet?.actualProblemEvents
          ?.map((result: transport.IRouteSheetEvent) => result.event)
          .includes(POINT_TYPES_ORDER_LIST.TIME_LAG),
      )
    );
  }

  public isOrderRouteSheet(order: IOrder) {
    return Boolean(order.routeSheet) && Boolean(order.statusOnEvent) && order.statusOnEvent !== ROUTE_SHEETS_CODE.DELIVERY_COMPLETED;
  }

  public isOrderDriverPlanned(order: IOrder) {
    return this.isOrderRouteSheet(order) && order.routeSheet?.isDriverOnline === null;
  }

  public isOrderDriverOnline(order: IOrder) {
    return this.isOrderRouteSheet(order) && Boolean(order.routeSheet?.isDriverOnline);
  }

  private isOrderLifeTimeSuitable(order: IOrder) {
    return !this.isCanceled(order) && !this.isCompleted(order) && !this.isReadyToGo(order);
  }

  public isExpired(order: IOrder) {
    const isLifeTimeExpired =
      this.isOrderLifeTimeSuitable(order) && isNumber(order.secondsToLifeTimeExpired) && order?.secondsToLifeTimeExpired === 0;
    if (!isLifeTimeExpired && this.isReservationTimeExpired(order)) {
      const orderDeadline = getOrderDeadline(order);
      if (Boolean(orderDeadline)) {
        const orderDeadlineTime = moment(new Date(orderDeadline).getTime());
        return moment(orderDeadlineTime).isBefore(moment());
      }
    }
    return isLifeTimeExpired || this.isReservationTimeExpired(order);
  }

  public hasLifetime(order: IOrder) {
    const result = Boolean(order) && this.isOrderLifeTimeSuitable(order) && (order?.secondsToLifeTimeExpired ?? 0) > 0;
    return result;
  }

  public hasReservationTime(order: IOrder) {
    const result = Boolean(order) && this.isOrderLifeTimeSuitable(order) && (order?.secondsToReservationTimeExpired ?? 0) > 0;
    return result;
  }

  public isReservationTimeExpired(order: IOrder) {
    return order?.secondsToReservationTimeExpired === 0;
  }

  public getAuctionLotStatus(order: Partial<IOrder>) {
    let result;
    const now = new Date().getTime();
    const auctionEnd = new Date(order.lot?.endDatetime ?? '').getTime();
    const auctionEnded = now >= auctionEnd;
    const lastBet = order.lot?.isLastBetByMyOrganization ?? false;
    if (auctionEnded && !lastBet) {
      result = ORDER_LOT_STATUS.LOST;
    } else if (auctionEnded && lastBet) {
      result = ORDER_LOT_STATUS.WON;
    } else if (!auctionEnded && !lastBet) {
      result = ORDER_LOT_STATUS.LOOSING;
    } else if (!auctionEnded && lastBet) {
      result = ORDER_LOT_STATUS.WINNING;
    }
    return result;
  }

  public isAuctionLost(order: Partial<IOrder>) {
    return this.getAuctionLotStatus(order) === ORDER_LOT_STATUS.LOST;
  }

  public getType(order: Partial<IOrder>) {
    // TODO: 2142 при запуске "Рынка" убрать проверку hideInProd
    if (!this.featureAccess.hideInProd) {
      if (order.isMarket ?? false) {
        return this.translate.instant('carrier.order.field.bindingType.market');
      }
      return this.translate.instant('carrier.order.field.bindingType.tms');
    }
  }

  public getOrderDocumentTypes(role: USER_ROLE): Observable<TnOrderDocumentTypes> {
    return this.graphQLShared.getOrderDocumentTypes(role).pipe(map(data => new TnOrderDocumentTypes(data.orderDocumentTypes)));
  }

  public attachDocuments(
    documents: TOrderAttachmentForSave[],
    orderId: string,
    role: USER_ROLE,
  ): Observable<transport.IOrderUploadedDocument[]> {
    return this.graphQLShared.attachDocuments(documents, orderId, role).pipe(
      map(data => {
        return data.editOrderUploadedDocuments;
      }),
    );
  }

  public editDocuments(
    documents: transport.IEditOrderUploadedDocumentInput[],
    orderId: string,
    role: USER_ROLE,
  ): Observable<transport.IOrderUploadedDocument[]> {
    return this.graphQLShared.editDocuments(documents, orderId, role).pipe(
      map(data => {
        return data.editOrderUploadedDocuments;
      }),
    );
  }

  public removeDocument(documentId: string, orderId: string, role: USER_ROLE) {
    return this.graphQLShared.removeDocument(documentId, orderId, role);
  }

  public getInsuranceInfoMap(value) {
    const fieldValues = JSON.parse(value.insuranceInitialInfo.fieldValues);
    return {
      cargoClasses: Object.getOwnPropertyNames(fieldValues.cargo.class).map(key => {
        return { id: key, name: fieldValues.cargo.class[key] as string };
      }),
      cargoKinds: Object.getOwnPropertyNames(fieldValues.cargo.kind).map(key => {
        return { id: key, name: fieldValues.cargo.kind[key] as string };
      }),
    };
  }

  public getLoadingTypesList(
    loadingPlaces?: transport.Order.IStoragePoint[] | null,
    unloadingPlaces?: transport.Order.IStoragePoint[] | null,
    isDraft = false,
  ) {
    const places = (loadingPlaces ?? []).concat(unloadingPlaces ?? []);
    if (isDraft) {
      const mapPlaces = new Map();

      places
        .filter(place => place.loadingType?.id)
        .forEach(place => {
          const id = place.loadingType?.id;
          const name = place.loadingType?.name;
          mapPlaces.set(id, { id, name });
        });

      return mapPlaces.size ? [...mapPlaces].map(el => el[1]).sort() : [];
    }
    return [
      ...new Set(
        places
          .filter(place => place.loadingType?.id)
          .map(place => ` ${place.loadingType?.name}`)
          .sort(),
      ),
    ];
  }

  public getPlacesTitle(field: string, places?: transport.Order.StoragePoint[] | null) {
    if (Boolean(places)) {
      return (places as transport.Order.StoragePoint[]).length > 1
        ? `${this.translate.instant(`shared.orders.table.column.${field}`)}
      ${places?.map(place => place?.storagePoint?.name).join('\n')}`
        : (places as transport.Order.StoragePoint[])[0]?.storagePoint?.name;
    }
    return '';
  }

  public getOrderLifeTimeExpiredMs(order: IOrder): number {
    const orderDeadline = getOrderDeadline(order);
    return Boolean(orderDeadline) ? new Date(orderDeadline).getTime() - Date.now() : 0;
  }
}
