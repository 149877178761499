import { Injectable, OnDestroy } from '@angular/core';
import { TnGqlClientSharedService } from '@transport/ui-store';
import { IPager, USER_ROLE } from '@transport/ui-interfaces';
import { ListStore } from '@marketplace/shared/modules/table/app-oriented-stuff/list.store';
import { DRIVERS_QUERIES } from './drivers-table-queries';
import { IDriverInResponse, IDriverInTable } from './drivers-table.interface';
import { BehaviorSubject, map } from 'rxjs';
import { driversResponseToDomain } from './drivers-table.mappers';
import { FILTERS_TABS } from '../filters/filters.interface';
import { DriversService } from '../drivers.service';


export interface DriverListFilter {
  phone?: string;
  fullName?: string;
  availability?: FILTERS_TABS;
}

export type DriversListStore = ListStore<IDriverInTable, keyof IDriverInTable, DriverListFilter>;

@Injectable()
export class DriversTableService implements OnDestroy {
  private readonly defaultFilter: DriverListFilter = {
    availability: FILTERS_TABS.ALL,
  };

  private listStore: DriversListStore = new ListStore(this.defaultFilter, (filter, sort, pageInfo) => {
    this.listStore.loading$.next(true);
    return this.sharedGraphQlClient
      .query<{items: IDriverInResponse[], totalCount: number}>(this.driversPageService.role$.value as USER_ROLE, DRIVERS_QUERIES.list, 1, {
        ...filter,
        first: pageInfo.pageSize,
        offset: pageInfo.pageIndex * pageInfo.pageSize,
        totalCount: true,
      })
      .pipe(map(data => ({...data, items: data.items.map(item => driversResponseToDomain(item))})));
  });

  public selectedTab$ = new BehaviorSubject<number>(0);

  public store = this.listStore.store;

  get list() {
    return this.listStore.list$;
  }

  public loading$ = this.listStore.loading$.asObservable();


  public fetch() {
    this.listStore.fetch(false, false);
  }

  public changePage(page: IPager) {
    const { pageIndex, pageSize } = page;
    this.listStore.pageInfo$.next({
      pageIndex,
      pageSize,
    });
  }

  constructor(private readonly sharedGraphQlClient: TnGqlClientSharedService, private driversPageService: DriversService) {}

  ngOnDestroy() {
    this.listStore.dispose();
  }
}
