import { IRowExtender } from '../../../plain-table/table-row-extender/row-extender.interface';
import {Router} from "@angular/router";
import {
  getStandardNavigateFunction
} from "./helpers/standart-navigate-function";

interface ITreatLikeALinkKeyboardExtenderConfig<T> {
  router: Router;
  linkBuilder: (rowData: T) => string;
}

/**
 * Redirects user implementing opening new tabs. Supports keyboard navigation only. (No mouse click)
 * The extender subscribes on `keydown` event of row element, and sets `tabindex` attribute on the row element.
 * @param config - Config object
 * @param {Function} config.navigateFunction - function fires when event appears.
 */
export function redirectByLinkKeyboardExtender<T>(config: ITreatLikeALinkKeyboardExtenderConfig<T>) {
  const { router, linkBuilder } = config;
  const navigateFunction = getStandardNavigateFunction(router, linkBuilder);

  const extender: IRowExtender<T> = (elRef, rowData) => {
    const element = elRef.nativeElement;
    if (!element.hasAttribute('tabindex')) {
      element.setAttribute('tabindex', '0');
    }

    const listener = event => {
      const keyboardEvent = event as KeyboardEvent;
      if (keyboardEvent.code !== 'Enter' || keyboardEvent.target !== element) return;
      if (keyboardEvent.ctrlKey) {
        navigateFunction(event, rowData, true);
        return;
      }
      navigateFunction(event, rowData, false);
    };

    element.addEventListener('keydown', listener);
    return () => {
      element.removeEventListener('keydown', listener);
    };
  };

  return extender;
}
