import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { transport } from '@transport/proto';
import { BLACK_LIST_COLUMN_TYPE, IDriver, VEHICLE_OWNERSHIP } from '@transport/ui-interfaces';
import {
  IDriversFilter,
  PAGE_SIZE,
  TnCarrierDirectoryDriversApiService,
  TnChooseDriversFacade,
  TnCurrentUserFacade,
} from '@transport/ui-store';
import { tap } from 'rxjs/operators';

import { TnTableList } from '../../table-list/table-list';

@UntilDestroy()
@Component({
  selector: 'transport-choose-driver-dialog',
  templateUrl: './choose-driver-dialog.component.html',
  styleUrls: ['./choose-driver-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnChooseDriverModal extends TnTableList<IDriver> implements OnInit {
  @Input() public returnUrl?: string;

  public selectedRow?: IDriver | null;

  public tableName = 'choose-driver-table';

  public filterColumnsList = ['header-filter-blacklist', 'header-filter-surname', 'header-filter-namePatronymic', 'header-filter-phone'];

  public blackListType = BLACK_LIST_COLUMN_TYPE;

  constructor(
    public dialogRef: MatDialogRef<TnChooseDriverModal>,
    public readonly router: Router,
    public readonly translate: TranslateService,
    private readonly carrierDirectoryDriversService: TnCarrierDirectoryDriversApiService,
    public readonly driversFacade: TnChooseDriversFacade,
    public readonly route: ActivatedRoute,
    public readonly userFacade: TnCurrentUserFacade,
    public readonly cdRef: ChangeDetectorRef,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      agentCarrierId: string | null;
      onlyOwnership: boolean;
      isAgent: boolean;
      orderId?: string;
      isChooseAny?: boolean;
      selectedDriverId?: string;
    },
  ) {
    super(router, route, driversFacade, cdRef);
    super.hasNavigation = false;
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  public select(): void {
    this.dialogRef.close(this.selectedRow);
  }

  public ngOnInit() {
    this.initFilterState();
    this.page(PAGE_SIZE.FIVE, 0);

    void this.loading$
      .pipe(
        untilDestroyed(this),
        tap(value => {
          if (!value) {
            this.selectedRow = this.dataSource.find(driver => driver.id === this.data.selectedDriverId);
          }
        }),
      )
      .subscribe();
  }

  public initFilterState() {
    let params = {};
    if (this.data?.onlyOwnership) {
      params = { agentCarrierOwnership: VEHICLE_OWNERSHIP.OWN };
    } else if (Boolean(this.data?.agentCarrierId)) {
      params = { agentCarrierId: this.data.agentCarrierId, withActiveAgentCarrierOnly: true };
    } else if (this.data.isAgent && !Boolean(this.data?.agentCarrierId)) {
      params = { withActiveAgentCarrierOnly: true };
    }
    this.driversFacade.setFilterSettings({
      availability: 'ACTIVE',
      ...params,
    });
  }

  public setFilter(filter: IDriversFilter, initial = false) {
    this.filter(filter, initial);
  }

  public getList(first?: number, offset?: number, filterSetting?: Record<string, unknown>) {
    return this.carrierDirectoryDriversService
      .getDrivers(1, false, {
        first,
        offset,
        totalCount: true,
        ...filterSetting,
        orderId: this.data?.orderId,
      })
      .pipe(untilDestroyed(this));
  }

  public transformValue(value: { surname: string; name: string; phone: string }): IDriversFilter {
    return {
      surname: `${value.surname}`.trim(),
      namePatronymic: `${value.name}`.trim(),
      phone: value.phone,
    };
  }

  public selectRow(row: IDriver) {
    const blackListInfo = ((row.blackListInfo as transport.Driver.IBlackListDriver[]) ?? []).filter(item => item.workProhibited);
    this.selectedRow = Boolean(blackListInfo?.length === 0) || Boolean(this.data?.isChooseAny) ? row : null;
  }

  public get columnNames() {
    return [
      { name: 'surname', include: true, width: 200 },
      { name: 'name', include: true, width: 200 },
      { name: 'phone', include: true, width: 200 },
      { name: 'application', include: true, width: 200 },
    ];
  }

  public get rowActions() {
    return {
      archived: (driver: IDriver) => Boolean(driver.isArchived),
      selectedRow: (driver: IDriver) => driver === this.selectedRow,
      blocked: (driver: IDriver) => {
        const driverProhibitedBlackList = (driver.blackListInfo ?? []).filter(item => item?.workProhibited);
        return !Boolean(this.data.isChooseAny) && Boolean(driverProhibitedBlackList?.length > 0);
      },
    };
  }

  public get driverHasMobileAppTipMessage() {
    return (hasMobileApp: boolean) => `shared.directory.drivers.table.tooltip.application.${hasMobileApp ? 'installed' : 'notInstalled'}`;
  }

  public selectDriver(row: IDriver) {
    this.selectRow(row);
    if (Boolean(this.selectedRow)) {
      this.select();
    }
  }
}
