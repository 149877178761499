import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { MIME_TYPE, TnFileToUpload } from '@transport/ui-interfaces';
import { TnToasterFacade, TOAST_TYPE } from '@transport/ui-store';
import { DEFAULT_MAX_FILE_SIZE, DOC_FILE_ACCEPT_EXTENSIONS_SHORT, fadeInOut } from '@transport/ui-utils';
import { InvalidFileItem } from 'angular-file/file-upload/fileTools';
import { dragMeta } from 'angular-file/file-upload/ngf.directive';

const defaultMaxSize = DEFAULT_MAX_FILE_SIZE;

@Component({
  selector: 'common-upload-area',
  templateUrl: './upload-area.component.html',
  styleUrls: ['./upload-area.component.scss'],
  animations: [fadeInOut],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonUploadAreaComponent {
  @Input() public files: File[] = [];

  // указываются extensions '.doc, docx', проверяется после выбора файла через окно или после события drag
  @Input() public accept: string = DOC_FILE_ACCEPT_EXTENSIONS_SHORT;

  @Input() public errorAccept = '';

  // указываются MIME Type 'image/jpeg, image/png', проверяется во время drag над формой загрузки
  // если тип совпадает, то поле подствечивается зеленым, если нет, то красным, если тип  не указан, то серым
  // после идет валидация по extensions из значения accept
  @Input() public acceptDrag = '';

  @Input() public maxSize = defaultMaxSize;

  @Input() public useDefaultStyle = true;

  @Input() public multiple = true;

  @Input() public isHorizontalCenter = true;

  @Output() public readonly filesChange = new EventEmitter<TnFileToUpload[]>();

  @Output() public readonly invalidFileSizeSelected = new EventEmitter<{
    maxSize: number;
    files: TnFileToUpload[];
  }>();

  private validComboDragValue?: boolean | null;

  constructor(private readonly toastFacade: TnToasterFacade, private readonly cdr: ChangeDetectorRef) {}

  // хранится MIME Type когда файл над формой загрузки
  public dragMeta: dragMeta[] = [];

  /**
   * Valid combo drag.
   */
  public set validComboDrag(value: boolean | null | undefined) {
    if (value !== undefined && !this.multiple && Boolean(this.dragMeta)) {
      if (!Boolean(this.dragMeta[0].type)) {
        this.validComboDragValue = null;
        return;
      }
      if (this.getTypeFilesAccept) {
        this.validComboDragValue = Boolean(this.getTypeFilesAccept.find(el => el === this.dragMeta?.[0]?.type));
        return;
      }
    }
    this.validComboDragValue = value;
  }

  public get validComboDrag(): boolean | null | undefined {
    return this.validComboDragValue;
  }

  public onChangeFiles(files: File[]) {
    if (!this.multiple) {
      if (this.validFile(files[0])) {
        const model = files.map(item => new TnFileToUpload(item));
        this.filesChange.emit([model[model.length - 1]]);
        return;
      }
      this.files = [];
      this.toastFacade.showMessage('Допустимые расширения файлов для загрузки ' + this.errorAccept.toUpperCase(), TOAST_TYPE.ERROR);
      return;
    }
    const model = files.map(item => this.swapIfDocExt(new TnFileToUpload(item)));
    this.filesChange.emit(model);
  }

  public swapIfDocExt(file: TnFileToUpload) {
    const blob = file.fileData.slice(0, file.fileData.size, file.extension);
    if (file.extension === 'doc' || file.extension === 'docx')
      file.fileData = new File([blob], file.fileData.name, {
        type: MIME_TYPE.DOCX,
      });
    return file;
  }

  public onDragFilesChange(files: any) {
    this.dragMeta = files;
  }

  public onInvalidChangeFiles(errors: InvalidFileItem[]) {
    if (!Boolean(errors)) {
      return;
    }
    if (!Boolean(errors?.[0].file.type)) {
      if (this.validFile(errors?.[0].file)) {
        const model = errors.map(item => new TnFileToUpload(item.file));
        model.map(item => {
          const blob = item.fileData.slice(0, item.fileData.size, item.extension);
          item.fileData = new File([blob], item.fileData.name, {
            type: item.extension === 'doc' ? 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : item.extension,
          });
          return item;
        });
        this.filesChange.emit(model);
        return;
      }
    }
    if (Boolean(errors?.[0]?.type === 'accept') && Boolean(this.errorAccept)) {
      this.toastFacade.showMessage('Допустимые расширения файлов для загрузки ' + this.errorAccept.toUpperCase(), TOAST_TYPE.ERROR);
    }
    if (Boolean(errors?.[0]?.type === 'fileSize')) {
      this.invalidFileSizeSelected.emit({
        maxSize: this.maxSize,
        files: errors.map(item => new TnFileToUpload(item.file)),
      });
    }
  }

  public get getExtFilesAccept(): string[] {
    const list: string[] = [];
    this.accept.split(',').forEach((el: string) => {
      const ex = el.split('.');
      if (ex.length === 2) {
        list.push(ex[1].toLowerCase().trim());
      }
    });
    return list;
  }

  // Проверяется MIME Type во время нахождения файла над формой сброса файла
  public get getTypeFilesAccept(): string[] | null {
    if (!Boolean(this.acceptDrag)) {
      return null;
    }
    const list: string[] = [];
    this.acceptDrag.split(',').forEach((el: string) => {
      const ex = el.toLowerCase().trim();
      list.push(ex);
    });
    return list;
  }

  validFile(file: File) {
    const nameArr = file.name.split('.');
    const ext = nameArr[nameArr.length - 1].toLowerCase().trim();
    if (this.getExtFilesAccept.find(el => el === ext)) {
      return true;
    }
    return false;
  }
}
