<div id="map" class="w-full h-full"></div>
<ng-container *ngIf="routesOnMap?.length">
  <div class="flex gap-2 absolute top-2 left-2">
    <button
      *ngFor="let route of routesOnMap"
      [style]="'background-color: ' + (route.visible ? route.color : '')"
      [ngClass]="{'tw-button-solid-sm': route.visible, 'tw-button-outline-sm': !route.visible}"
      (click)="switchRouteVisibility(route)"
    >{{route.routeName}}</button>
  </div>
</ng-container>