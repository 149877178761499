import { Injectable, TemplateRef } from '@angular/core';
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarRef, MatSnackBarVerticalPosition } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { TOAST_DURATION } from '@transport/ui-interfaces';

import { TnAlertComponent } from './alert.component';
import { ALERT_STATE, IAlertInputs } from './alert.interfaces';

@Injectable()
export class TnAlertService {
  constructor(private readonly _snackBar: MatSnackBar, private readonly translate: TranslateService) {}

  private readonly horizontalPosition: MatSnackBarHorizontalPosition = 'end';

  private readonly verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  public openAlertFromTemplate(template: TemplateRef<unknown>, duration?: number, $implicit?: unknown): MatSnackBarRef<unknown> {
    return this._snackBar.openFromTemplate(template, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'alert-container',
      data: $implicit,
      duration: duration,
    });
  }

  public openAlert(inputs: IAlertInputs, duration?: number) {
    this._snackBar.openFromComponent(TnAlertComponent, {
      horizontalPosition: this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'alert-container',
      data: inputs,
      duration: duration,
    });
  }

  public openBasicInfoAlert(titlePath?: string, messagePath?: string, duration?: TOAST_DURATION) {
    this.openAlert(
      {
        state: ALERT_STATE.INFO,
        title: titlePath ? this.translate.instant(titlePath) : '',
        message: messagePath ? this.translate.instant(messagePath) : '',
      } as IAlertInputs,
      duration || TOAST_DURATION.SHORT,
    );
  }

  public openBasicSuccessAlert(titlePath?: string, messagePath?: string, duration?: TOAST_DURATION) {
    this.openAlert(
      {
        state: ALERT_STATE.SUCCESS,
        title: titlePath ? this.translate.instant(titlePath) : '',
        message: messagePath ? this.translate.instant(messagePath) : '',
      } as IAlertInputs,
      duration || TOAST_DURATION.SHORT,
    );
  }

  public openBasicErrorAlert(titlePath?: string, messagePath?: string, duration?: TOAST_DURATION, errorMsg?: string) {
    this.openAlert(
      {
        state: ALERT_STATE.ERROR,
        title: titlePath ? this.translate.instant(titlePath) : '',
        message: messagePath ? this.translate.instant(messagePath) : errorMsg ?? '',
      } as IAlertInputs,
      duration || TOAST_DURATION.SHORT,
    );
  }

  public shortSuccess(title?: string, message?: string, horizontalPosition?: MatSnackBarHorizontalPosition) {
    this._snackBar.openFromComponent(TnAlertComponent, {
      horizontalPosition: horizontalPosition || this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'alert-container',
      data: {
        title: title,
        message: message,
        state: ALERT_STATE.SUCCESS,
      },
      duration: TOAST_DURATION.SHORT,
    });
  }

  public shortError(title?: string, message?: string, horizontalPosition?: MatSnackBarHorizontalPosition) {
    this._snackBar.openFromComponent(TnAlertComponent, {
      horizontalPosition: horizontalPosition || this.horizontalPosition,
      verticalPosition: this.verticalPosition,
      panelClass: 'alert-container',
      data: {
        title: title,
        message: message,
        state: ALERT_STATE.ERROR,
      },
      duration: TOAST_DURATION.SHORT,
    });
  }
}
