import { gql } from 'apollo-angular';

/**
 * GraphQL carrier directory queries
 */
export const CARRIER_DIRECTORY_MUTATION = {
  /**
   * archive vehicle.
   * @param vehicle data
   * @param [withSpinner] should request start spinner
   * @returns updated value
   */
  archiveVehicle: gql`
    mutation sendVehicleToArchive($id: ID!) {
      sendVehicleToArchive(id: $id) {
        id
      }
    }
  `,

  /**
   * add vehicle.
   * @param vehicle data
   * @returns updated value
   */
  addVehicleOnTms: gql`
    mutation addVehicleOnTMS($input: VehicleOnTMSInput!) {
      addVehicleOnTMS(input: $input) {
        id
        vehicleId
      }
    }
  `,


  addVehicleOnTMSFromModal: gql`
    mutation addVehicleOnTMSFromModal($input: VehicleOnTMSInput!) {
      addVehicleOnTMS(input: $input) {
        id
        vehicleType
        vehicleMake
        vehicleCertificate
        isArchived
        vehicleId
        vehicleVin
        verificationStatus
        ownVehicle
        vehicletrailer {
          id
          trailerId
          trailerType
          bodyType {
            id
            name
          }
          bodySubtype {
            id
            name
          }
          loadingTypes {
            id
            name
          }
          capacity
          volume
        }
        vehiclebody {
          id
          vehicle {
            id
          }
          bodyType {
            id
            name
          }
          bodySubtype {
            id
            name
          }
          loadingTypes {
            id
            name
          }
          capacity
          volume
        }
        country
        agentCarrier {
          id
          name
          vat {
            id
            name
          }
        }
        ownershipType
      }
    }
  `,

  /**
   * edit vehicle.
   * @param vehicle form
   * @returns updated value
   */
  editVehicle: gql`
    mutation editVehicle($input: EditVehicleInput!) {
      editVehicle(input: $input) {
        id
      }
    }
  `,
  archiveDriver: gql`
    mutation ArchiveDriver($driverId: ID!) {
      sendDriverToArchive(driverId: $driverId) {
        id
      }
    }
  `,
  addDriver: gql`
    mutation AddDriver($input: AddDriverInput) {
      addDriver(input: $input) {
        id
      }
    }
  `,
  editDriver: gql`
    mutation EditDriver($input: EditDriverInput) {
      editDriver(input: $input) {
        id
      }
    }
  `,
};
