import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'marketplace-vehicle-required-column',
  templateUrl: './vehicle-required-column.component.html',
  styleUrls: ['./vehicle-required-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleRequiredColumnComponent {
  @Input() public bodySubtypes: { name: string, id: string }[] | null = null;
  @Input() public bodySubtype: string | null = null;
  @Input() public wrapperClass: string | null = null;

  private _loadingTypes: string | string[] = [];

  @Input()
  public set loadingTypes(value: string | string[]) {
    this._loadingTypes = value;
    this.setLoadingTypesViewReady(value instanceof Array ? value : value.split(', '));
  }

  public get loadingTypes() {
    return this._loadingTypes;
  }

  public get bodySubtypesAsString(): string {
    return this.bodySubtypes?.map(item => item.name)?.join(', ') ?? '---';
  }

  public get orderBodySubtypesShort(): { firstType: string, additionalCount: number } | null {
    if (!this.bodySubtypes?.length) return null;
    const additionalCount = this.bodySubtypes?.length - 1;
    const firstType = this.bodySubtypes[0]?.name ?? '';
    return {additionalCount, firstType};
  }

  public arrayOfLoadingTypes: string[] = [];

  private setLoadingTypesViewReady(types: string[]) {
    const result: string[] = [];
    types.forEach(type => {
      let short = type;
      switch (type) {
        case 'Боковая':
          short = 'Бок';
          break;
        case 'Задняя':
          short = 'Задн';
          break;
        case 'Верхняя':
          short = 'Верх';
          break;
      }
      result.push(short);
    });
    this.arrayOfLoadingTypes = result;
  }
}
