import { Injectable } from '@angular/core';
import { IVehicle, IVehicleData } from '@transport/ui-interfaces';
import { TnStsNumberPipe, TnSupportedCountry } from '@transport/ui-pipes';
import { removeEmptyProperties, vehicleNumberPrepare } from '@transport/ui-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnGqlClientCarrierService } from '../../gql-client-carrier/graphql-client-carrier.service';

@Injectable({
  providedIn: 'root',
})
export class TnCarrierDirectoryApiService {
  constructor(private readonly graphQL: TnGqlClientCarrierService, private readonly stsNumberPipe: TnStsNumberPipe) {}

  /**
   * Request for list of items
   */
  public getVehiclesSelector(listenX?: number, withSpinner?: boolean, options?: Record<string, unknown>) {
    return this.graphQL.queryVehiclesSelector(listenX, withSpinner, options).pipe(
      map(data => {
        return {
          list: (data.vehicles as IVehicle[]).map(v => {
            if (v?.vehicleId) {
              v.vehicleId = vehicleNumberPrepare(v?.vehicleId, v.country);
            }

            return v;
          }),
          totalCount: data.totalCount ?? 0,
        };
      }),
    );
  }

  public getVehicles(listenX?: number, withSpinner?: boolean, options?: Record<string, unknown>) {
    return this.graphQL.queryVehicles(listenX, withSpinner, options).pipe(
      map(data => {
        return {
          list: data.vehicles as IVehicle[],
          totalCount: data.totalCount ?? 0,
        };
      }),
    );
  }

  /**
   * Get vehicle
   * @param id vehicle
   * @returns [Observable<IVehicle>]
   */
  public getVehicle(id: string) {
    const req = this.graphQL.queryVehicle(id);
    return req.pipe(
      map(data => {
        const result: IVehicle = {
          ...data.vehicle,
          vehicleCertificate:
            data.vehicle.country === 'NO'
              ? data.vehicle.vehicleCertificate
              : this.stsNumberPipe.transform(data.vehicle.vehicleCertificate ?? ''),
        };
        return result;
      }),
    );
  }

  /**
   * Get vehicle types
   */
  public getVehicleTypes() {
    const req = this.graphQL.queryVehicleTypes();
    return req.pipe(map(data => data.vehicleTypes));
  }

  /**
   * Get body types
   */
  public getBodyTypes() {
    const req = this.graphQL.queryBodyTypes();
    return req.pipe(map(data => data.bodyTypes));
  }

  /**
   * Get body types
   */
  public getVehicleMakes() {
    const req = this.graphQL.queryVehicleMakes();
    return req.pipe(map(data => data.vehicleMakes));
  }

  /**
   * Get loading types
   */
  public getLoadingTypes() {
    const req = this.graphQL.queryLoadingTypes();
    return req.pipe(map(data => data.loadingTypes));
  }

  // Get list of countries list
  public getCountriesCodeList() {
    return [
      {
        code: 'RU',
        name: 'Российская Федерация',
      },
      {
        code: 'NO',
        name: 'Другое',
      },
    ];
  }

  /**
   * Get driver
   */
  public getVehicleDriver(vehicleId: string, orderId?: string) {
    const req = this.graphQL.vehicleDriver(vehicleId, orderId);
    return req.pipe(map(data => data.vehicleDrivers[0]));
  }

  /**
   * archive vehicle type request
   * @param vehicleParam vehicle type data
   * @returns Observable with status
   */
  public sendVehicleToArchive(vehicleId: number) {
    return this.graphQL.sendVehicleToArchive(vehicleId);
  }

  /**
   * Create new vehicle request
   * @param vehicleParam vehicle form data
   * @returns Observable with status
   */
  public addVehicleOnTms(vehicleForm: IVehicleData): Observable<IVehicle> {
    const input = removeEmptyProperties({ ...vehicleForm });
    const inputWithAgentCarrier = this.addAgentCarrierValue(input);
    return this.graphQL.addVehicleOnTms(inputWithAgentCarrier).pipe(map(result => result.addVehicleOnTMS));
  }

  public addVehicleOnTmsFromModal(vehicleForm: IVehicleData): Observable<IVehicle> {
    const input = removeEmptyProperties({ ...vehicleForm });
    const inputWithAgentCarrier = this.addAgentCarrierValue(input);
    return this.graphQL.addVehicleOnTMSFromModal(inputWithAgentCarrier).pipe(map(result => result.addVehicleOnTMS));
  }

  /**
   * edit new vehicle request
   * @param vehicleParam vehicle form value
   * @returns Observable with status
   */
  public editVehicle(vehicleForm: IVehicleData & { id?: string | null }) {
    const input = removeEmptyProperties({ ...vehicleForm });
    const inputWithAgentCarrier = this.addAgentCarrierValue(input);
    return this.graphQL.editVehicle(inputWithAgentCarrier);
  }

  /**
   * Add empty agent carrier field.
   * @param vehicleForm values
   * @returns vehicleForm values
   */
  public addAgentCarrierValue(input: IVehicleData): IVehicleData {
    if (!Boolean(input.agentCarrierId)) {
      input.agentCarrierId = null;
    }
    return input;
  }
}
