import { BehaviorSubject, catchError, map, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { TnGqlClientSharedService } from '@transport/ui-store';
import { IAgentCarrier, IGCarrier, USER_ROLE } from '@transport/ui-interfaces';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { IMarketplaceDriver, IMarketplaceDriverInput } from '../driver.interface';
import { driverToServer, setAgentCarrierData } from '../driver.mapper';
import { DriverFormVm } from '../driver.service';
import { getDriverForm } from './driver-form';
import { DRIVER_QUERIES } from '../driver-queries';
import { TnModalAddTransportService } from 'libs/transport-notifications/src/lib/components/modal-add-transport/modal-add-transport.service';
import { TnDomainService } from '../../../../../transport-ui-services/src/lib/domain/domain.service';
import { ALERT_STATE } from '@transport/ui-kit';
import { ICommonSelectData } from 'libs/custom-controls/src/lib/common.interface';
import { CITIZEN_NUMBERS } from '@transport/ui-pipes';

export class DriverCreateVm implements DriverFormVm {
  public title = this.translate.instant('carrier.directory.marketplaceDriver.title.create');
  public isSubdomainPage = Boolean(this.domainService.subDomain);
  public actionBtnTxt = this.translate.instant(this.getActionBtnTxt());
  public driver$ = new BehaviorSubject<IMarketplaceDriverInput>({} as IMarketplaceDriverInput);
  public loading$ = new BehaviorSubject<boolean>(false);
  public finish$ = new Subject<{ close: boolean; message: string; alertState: ALERT_STATE }>();
  public disposed$ = new Subject<void>();
  public organizations$ = new BehaviorSubject<ICommonSelectData[] | null>(null);
  public hasAgentCarrier$ = new BehaviorSubject<boolean>(false);

  public form: FormGroup = getDriverForm();
  constructor(
    private readonly sharedGraphQlClient: TnGqlClientSharedService,
    public readonly translate: TranslateService,
    private readonly datePipe: DatePipe,
    private readonly modalAddTransportService: TnModalAddTransportService,
    private readonly domainService: TnDomainService,
    private role: USER_ROLE
  ) {
    this.form.controls?.country.valueChanges.pipe(takeUntil(this.disposed$)).subscribe(country => {
      this.form.controls.phone.setValue(CITIZEN_NUMBERS.find(c => c.id === country?.id)?.label);
      // this.form.controls.addPhone.setValue(CITIZEN_NUMBERS.find(c => c.id === country?.id)?.label);

      this.form.updateValueAndValidity();
    });
  }

  getAgentCarriers(searchQuery) {
    this.sharedGraphQlClient.query<{ items: IAgentCarrier[] }>(this.role, DRIVER_QUERIES.getOrganizations, 1, {
      availability: IGCarrier.IAvailabilityEnum.Active,
      first: 10,
      searchQuery,
    })
    .pipe(
      take(1),
      map(response => response.items.map(item => setAgentCarrierData(item) as ICommonSelectData)),
      catchError(() => of(null)),
    )
    .subscribe(res => this.organizations$.next(res || null));
  }

  action() {
    const fields = this.form.getRawValue();
    this.form.markAllAsTouched();
    if (this.form.invalid) return;

    this.loading$.next(true);

    if (this.isSubdomainPage || this.hasAgentCarrier$?.value) {
      this.sharedGraphQlClient
        .mutate<{ addDriver: IMarketplaceDriver }>(this.role, DRIVER_QUERIES.addDriver, {
          input: driverToServer(fields, this.datePipe),
        })
        .pipe(map(response => response.addDriver))
        .pipe(
          takeUntil(this.disposed$),
          catchError(err => {
            let message = '';
            if (err?.message) {
              message = err.message;
            } else {
              message = this.translate.instant('carrier.directory.marketplaceDriver.messages.createError');
            }
            this.finish$.next({
              close: false,
              message,
              alertState: ALERT_STATE.ERROR,
            });

            return of(undefined);
          }),
        )
        .subscribe(res => {
          if (res) {
            this.modalAddTransportService.afterSaveDataDriver.next({...driverToServer(fields, this.datePipe) ,id: res?.id, })
            this.finish$.next({
              close: true,
              message: this.translate.instant('carrier.directory.marketplaceDriver.messages.createSuccess'),
              alertState: ALERT_STATE.SUCCESS,
            });
          }
          this.loading$.next(false);
        });
    } else {
      this.createAndSubmitRiskProfile(fields);
    }
  }

  public getActionBtnTxt(): string {
    return this.isSubdomainPage || this.hasAgentCarrier$?.value ?
      this.translate.instant('carrier.directory.marketplaceDriver.buttons.save') :
      this.translate.instant('carrier.directory.marketplaceDriver.buttons.saveAndAccredit')
  }

  private createAndSubmitRiskProfile(fields): void {
    let driverId;
    let driverProfileId;

    this.sharedGraphQlClient
      .mutate<{ addDriver: IMarketplaceDriver }>(this.role, DRIVER_QUERIES.addDriver, {
        input: driverToServer(fields, this.datePipe),
      })
      .pipe(map(response => response.addDriver))
      .pipe(
        switchMap((value: any) => {
          driverId = value?.id;
          return this.sharedGraphQlClient.mutate(this.role, DRIVER_QUERIES.createDriverRiskProfile, {driverId: value?.id,})
        }),
        switchMap((value: any) => {
          driverProfileId = value?.createDriverRiskProfile?.profileId;
          return this.sharedGraphQlClient
            .mutate(this.role, DRIVER_QUERIES.addDriverRiskProfileDossier, {
              docType: 'text',
              docVal: JSON.stringify(fields?.passportPhoto),
              profileId: value?.createDriverRiskProfile?.profileId,
            })
        }),
        switchMap((value: any) => {
          return this.sharedGraphQlClient
            .mutate(this.role, DRIVER_QUERIES.submitDriverRiskProfile, {profileId: driverProfileId, driverId: driverId})
        }),
        takeUntil(this.disposed$),
        catchError(err => {
          let message = '';
          if (err?.message) {
            message = err.message;
          } else {
            message = this.translate.instant('carrier.directory.marketplaceDriver.messages.createError');
          }
          this.finish$.next({
            close: false,
            message,
            alertState: ALERT_STATE.ERROR,
          });

          return of(undefined);
        }),
      )
      .subscribe(res => {
        if (res) {
          this.modalAddTransportService.afterSaveDataDriver.next({...driverToServer(fields, this.datePipe) ,id: driverId, })
          this.finish$.next({
            close: true,
            message: this.translate.instant('carrier.directory.marketplaceDriver.messages.createAndSubmitSuccess'),
            alertState: ALERT_STATE.SUCCESS,
          });
        }
        this.loading$.next(false);
      });
  }

  dispose() {
    this.disposed$.next();
    this.disposed$.complete();
    this.driver$.complete();
    this.loading$.complete();
    this.finish$.complete();
  }
}
