import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TnYamapsControlsService {
  /**  Route edit mode */
  public routeEditMode = false;

  /**
   * Class constructor.
   * @param window - Browser window
   */
  constructor() {}

  /**
   * @param multiRouteView  Search string request.
   * @return object Router mode control button
   */
  public getRouterModeBtn(multiRouteView: { editor: { start; stop } }): Record<string, unknown> {
    const buttonEditor: { events: { add } } = new (
      window as Window & {
        // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: tech debt
        ymaps: { control: { Button } };
      }
    ).ymaps.control.Button({
      data: {
        image: `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIyNiIgaGVpZ2h0PSIyNiI+PG
        cgZmlsbD0iIzZCNkI2QiI+PHBhdGggZD0iTTEwIDE0aDQuNWEzLjUgMy41IDAgMCAwIDAtN0gxMHYyaDQuNWExLjUgMS41IDAgMSAxIDAgM0gxMHYyem0wIDAiLz48cGF0aCBkPSJNMTUgMTJ
        oLTQuNWEzLjUgMy
        41IDAgMCAwIDAgN0gxNXYtMmgtNC41YTEuNSAxLjUgMCAxIDEgMC0zSDE1di0yem0wIDBNMTkgMjBhMiAyIDAgMSAwIDAtNCAyIDIgMCAwIDAgMCA0em0wLTFhMSAxIDAgMSAwIDAtMiAxID
        EgMCAwIDAgMCAyem0wIDBNOSAxMGEyIDIgMCAxIDAgMC00IDIgMiAwIDAgMCAwIDR6bTAtMWExIDEgMCAxIDAgMC0yIDEgMSAwIDAgMCAwIDJ6bTAgMCIvPjxwYXRoIGQ9Ik0xMy41NyAyMC4
        4bDIuODMtMi44Mi0uNzEtLjctMi44MyAyLjgyLjcuN3ptMS40MS0yLjgybC43LS43LTIuMTEtMi4xMy0uNy43IDIuMTEgMi4xM3ptMCAwIi8+PC9nPjwvc3ZnPg==`,
      },
    });
    buttonEditor.events.add('select', () => {
      this.routeEditMode = true;
      multiRouteView.editor.start({
        addWayPoints: true,
        removeWayPoints: true,
        drawOver: false,
      });
    });
    buttonEditor.events.add('deselect', () => {
      this.routeEditMode = false;
      multiRouteView.editor.stop();
    });
    return buttonEditor;
  }

  /**
   * @param callback  Callback for reset button.
   * @return object Router reset button
   */
  public getRouterResetBtn(callback): Record<string, unknown> {
    const buttonEditor: { events: { add } } = new (
      window as Window & {
        // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: tech debt
        ymaps: { control: { Button } };
      }
    ).ymaps.control.Button({
      data: {
        content: 'Reset',
      },
      options: {
        selectOnClick: false,
      },
    });
    buttonEditor.events.add('click', callback);
    return buttonEditor;
  }
}
