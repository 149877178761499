export type TEntityFlowActionType = 'edit' | 'remove' | 'save' | 'copy' | 'cancel';

export interface IEntityFlowAction {
  type: TEntityFlowActionType;
  label?: string;
  icon?: string;
}
export type TDirectoryEntityPredefinedAction = 'create' | 'edit' | 'copy' | 'view' | 'remove';

export const getEntityFlowActions = (action: TDirectoryEntityPredefinedAction, isEntityDeactivated = false): IEntityFlowAction[] => {
  return action === 'view'
    ? isEntityDeactivated
      ? [{ type: 'copy' }]
      : [{ type: 'remove' }, { type: 'edit' }, { type: 'copy' }]
    : action === 'remove'
    ? [{ type: 'remove' }]
    : [{ type: 'save' }];
};

export type TCreatePlaceTypePoint = 'LOADING' | 'UNLOADING';
