import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { TnToasterFacade, TnUserService } from '@transport/ui-store';

import { RedirectAfterLoginService } from '../../shared/services/redirect-after-login.service';

/**
 * Authenticated guard.
 */
@Injectable({
  providedIn: 'root',
})
export class TnMarketplaceAuthenticatedGuard implements CanActivate {
  public marketplace = ['my-cargo'];

  constructor(
    public readonly toastFacade: TnToasterFacade,
    public readonly tnUserService: TnUserService,
    private readonly router: Router,
    private readonly redirectAfterLoginService: RedirectAfterLoginService,
  ) {}

  /**
   * Redirects user to passport/login path if user is not logged in.
   */
  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const token = JSON.parse(this.tnUserService.getCookie(this.tnUserService.getCookieName()) ?? '{"token":""}')?.token;

    if (!Boolean(token) || state.url.includes('/passport/reg/')) {
      return true;
    }

    return this.router.navigate(this.marketplace);
  }
}
