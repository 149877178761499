import { Directive, DoCheck, ElementRef, Input } from '@angular/core';
import { AbstractControl } from '@angular/forms';

@Directive({
  selector: '[transportAddControlStateClasses]',
})
export class TnControlStateClassesDirective implements DoCheck {
  @Input() public prefix = 'tw-input-block-';

  @Input('transportAddControlStateClasses') public control?: AbstractControl;

  constructor(private readonly el: ElementRef) {}

  public ngDoCheck() {
    const errorClass = `${this.prefix}error`;
    const manipulateErrorStateFunc = this.error ? this.el.nativeElement.classList.add : this.el.nativeElement.classList.remove;
    manipulateErrorStateFunc.call(this.el.nativeElement.classList, errorClass);

    const disabledClass = `${this.prefix}disabled`;
    const manipulateDisabledStateFunc = this.disabled ? this.el.nativeElement.classList.add : this.el.nativeElement.classList.remove;
    manipulateDisabledStateFunc.call(this.el.nativeElement.classList, disabledClass);
  }

  private get error() {
    return this.control ? this.control.invalid && this.control.touched : false;
  }

  private get disabled() {
    return this.control ? this.control.disabled : false;
  }
}
