import { Directive, HostListener, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

const DEFAULT_MAX_NUMBER_LENGTH = 99;
const DEFAULT_ALLOW_NEGATIVE_NUMBERS = false;

@Directive({
  selector: '[wholeNumberOnly]',
})
export class TnWholeNumberOnlyDirective {
  @Input() public maxNumberLength = DEFAULT_MAX_NUMBER_LENGTH;

  @Input() public allowNegativeNumbers = DEFAULT_ALLOW_NEGATIVE_NUMBERS;

  constructor(@Optional() private readonly formControl: NgControl) {}

  // Разрешает ввод только чисел без плавающей точки. Положительных или отрицательных в зависимости от allowNegativeNumbers.
  // По умолчанию разрешен ввод только положительных чисел без плавающей точки.
  // Длина строки регулируется maxNumberLength. По умолчанию 99 символов.

  @HostListener('input', ['$event.target.value']) public onInput(event) {
    if (typeof event === 'string') {
      const req = this.allowNegativeNumbers
        ? new RegExp(`-?[0-9]{0,${this.maxNumberLength}}`) // Необязательный минус перед числом, если используется - необходима валидация значения "-" без числа
        : new RegExp(`[0-9]{1,${this.maxNumberLength}}`); // Ввод только положительных чисел.
      const value = event.match(req)?.toString();
      this.formControl.control?.patchValue(value);
    } else {
      this.formControl.control?.patchValue(this.formControl.control.value);
    }
  }
}
