import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TRUCK_IDENTIFICATOR_TYPE } from '@transport/ui-interfaces';

@Component({
  selector: 'transport-truck-identificator-tooltip',
  templateUrl: './truck-identificator-tooltip.component.html',
  styleUrls: ['./truck-identificator-tooltip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnTruckIdentificatorTooltipComponent {
  @Input() public truckIdentificatorType = TRUCK_IDENTIFICATOR_TYPE.GOS_NUMBER;

  private readonly localization = 'carrier.directory.vehicle.details.identificator';

  public get title() {
    return `${this.localization}.title.${this.truckIdentificatorType}`;
  }

  public get description() {
    return `${this.localization}.description.${this.truckIdentificatorType}`;
  }

  public get svgIcon() {
    return `${this.truckIdentificatorType}-example`;
  }
}
