<div class="flex gap-3.5">
  <div class="state-icon flex items-center">
    <mat-icon *ngIf='state === "info"' svgIcon="info"></mat-icon>
    <mat-icon *ngIf='state === "error" || state === "warning"' svgIcon="warning-outline"></mat-icon>
    <mat-icon *ngIf='state === "success"' svgIcon="check"></mat-icon>
  </div>
  <div [ngClass]="['flex-grow', titleVariant === 'left' ? 'flex gap-2' : '']">
    <div class="text-md font-bold" data-test="toast-title" *ngIf="titleVariant !== 'none'">{{ title }}</div>
    <div class="text-md font-normal" data-test="toast-message">
      <ng-container *ngIf="message; else content">
        {{ message }}
      </ng-container>
      <ng-template #content><ng-content></ng-content></ng-template>
    </div>
  </div>
  <div class="">
    <button class='w-6 h-6 flex items-center justify-center' (click)="onCloseButtonClick()"><mat-icon class='mat-icon-14' svgIcon="close"></mat-icon></button>
  </div>
</div>
