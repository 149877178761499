import { ORDER_STATUS } from './order-common.interface';

/**
 * Order status filter all type.
 */
export type TOrderStatusFilterAll = '';

/**
 * Order status filter model.
 */
export class TnOrderStatusFilterValue {
  /**
   * Free orders filter value.
   */
  public free: Partial<ORDER_STATUS>[] = [ORDER_STATUS.FREE];

  /**
   * Taken orders filter value.
   */
  public taken: Partial<ORDER_STATUS>[] = [
    ORDER_STATUS.ASSIGNED,
    ORDER_STATUS.TRANSPORT_RESERVED,
    ORDER_STATUS.CONTRACT_ATTACHED,
    ORDER_STATUS.READY_TO_GO,
  ];

  /**
   * All orders filter value.
   */
  public all: TOrderStatusFilterAll[] = [''];
}

/**
 * Order status filter value.
 */
export const OrderStatusFilterValue: TnOrderStatusFilterValue = new TnOrderStatusFilterValue();

/**
 * Cancellable order statuses.
 */
export const CancellableOrderStatuses: ORDER_STATUS[] = [ORDER_STATUS.FREE, ...OrderStatusFilterValue.taken];
