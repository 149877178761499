<form [formGroup]="form">
  <h2>{{ 'shared.mp.verification.form.vehicle.title' | translate }}</h2>
  <div class="flex flex-col">
    <p class="mb-2 flex flex-wrap gap-x-6">
      <span>{{ 'shared.mp.verification.form.vehicle.ownershipText' | translate }}</span>
      <mat-radio-group formControlName="ownership" aria-labelledby="gender-label" (change)="changeOwnershiState()">
        <mat-radio-button value="YES">
          {{ 'shared.mp.verification.form.vehicle.yes' | translate }}
        </mat-radio-button>
        <mat-radio-button value="NO">
          {{ 'shared.mp.verification.form.vehicle.no' | translate }}
        </mat-radio-button>
      </mat-radio-group>
    </p>
    <div [hidden]="ownership !== 'YES'">
      <ng-container formArrayName="vehicles">
        <div class="titles flex flex-row items-center">
          <p class="w-28 mr-3">{{ 'shared.mp.verification.form.vehicle.vehicleId' | translate }}</p>
          <p class="w-48 mr-3">{{ 'shared.mp.verification.form.vehicle.photoFirst' | translate }}</p>
          <p>{{ 'shared.mp.verification.form.vehicle.photoSecond' | translate }}</p>
        </div>
        <ng-container *ngFor="let vehicle of vehicles; let i = index;">
          <div class="controls flex items-start" [formGroup]="vehicle">
            <transport-tw-input-field
              [errorToMessage]="validationErrors.bind(this)"
              [labelClasses]="['label md:hidden']"
              label="{{ 'shared.mp.verification.form.vehicle.vehicleId' | translate }}"
              size="sm"
              errorsSize="xs"
              class="w-28 mr-3 mt-0"
              [control]="$any(vehicle.controls.regNumber)"
            >
              <transport-tw-input size="sm" [control]="$any(vehicle.controls.regNumber)">
                <input
                  [mask]="regExpConf.ngxMask.vehiclePassport.ru.registrationNumber.mask"
                  [patterns]="$any(regExpConf.ngxMask.vehiclePassport.ru.registrationNumber.patterns)"
                  placeholder="А 123 АА 123"
                  spellcheck="false"
                  tw-input
                  class="text-sm"
                  [formControl]="$any(vehicle.controls.regNumber)"
                  class="uppercase"
                />
              </transport-tw-input>
            </transport-tw-input-field>

            <transport-file-selector
              class="md:!w-48 mr-3"
              [reduceText]="true"
              [labelClasses]="['label md:hidden']"
              label="{{ 'shared.mp.verification.form.vehicle.photoFirst' | translate }}"
              [errorToMessage]="validationErrors.bind(this)"
              [control]="vehicle.controls.photoFirst"
            ></transport-file-selector>

            <transport-file-selector
              class="md:!w-48"
              [reduceText]="true"
              [labelClasses]="['label md:hidden']"
              label="{{ 'shared.mp.verification.form.vehicle.photoSecond' | translate }}"
              [errorToMessage]="validationErrors.bind(this)"
              [control]="vehicle.controls.photoSecond"
            ></transport-file-selector>

            <button class="remove-btn tw-button-outline-sm self-end mt-2" (click)="remove(i)">
              <mat-icon>cancel</mat-icon>
              {{ 'shared.mp.verification.form.vehicle.remove' | translate }}
            </button>

            <mat-icon *ngIf="vehicles.length > 1" class="remove mt-1.5 ml-3" (click)="remove(i)">cancel</mat-icon>
          </div>
        </ng-container>
      </ng-container>

      <button class="tw-button-ghost-sm" (click)="addVehicle()">
        <mat-icon>add</mat-icon>
        {{ 'shared.mp.verification.form.vehicle.add' | translate }}
      </button>
    </div>
  </div>
</form>
