import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { APP_ENV, IConstantsApiResponse, TnAppEnvironment } from '@transport/ui-interfaces';
import { map } from 'rxjs/operators';

import { TnHttpHandlersService } from '../feature-access/http-handlers.service';

/**
 * Constants http service.
 * Returns tech support information.
 */
@Injectable({
  providedIn: 'root',
})
export class TnConstantsApiService {
  /**
   * Service endpoints.
   */
  private readonly endpoints = {
    constants: (): string => this.handlers.getEndpointUrl('/constants/'),
  };

  constructor(
    private readonly http: HttpClient,
    private readonly handlers: TnHttpHandlersService,
    @Inject(APP_ENV) private readonly env: TnAppEnvironment,
  ) {}

  /**
   * Get constants config from the backend.
   */
  public config() {
    const endpoint: string = this.endpoints.constants();
    const observable = this.http.get<IConstantsApiResponse>(endpoint);
    return this.handlers.pipeHttpRequest<IConstantsApiResponse>(observable).pipe(
      map(data => {
        const phone =
          Boolean(data.SUPPORT_PHONE_NUMBER) && Boolean(data.SUPPORT_PHONE_NUMBER.value)
            ? data.SUPPORT_PHONE_NUMBER.value
            : this.env.support?.phone;
        const email = Boolean(data.SUPPORT_EMAIL) && Boolean(data.SUPPORT_EMAIL.value) ? data.SUPPORT_EMAIL.value : this.env.support?.email;
        const timestamp = new Date().getTime();
        return { support: { phone, email }, timestamp };
      }),
    );
  }
}
