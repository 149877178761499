import { ChangeDetectionStrategy, Component, Inject, OnDestroy, ViewChild, ViewContainerRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TnYamapComponent, TnYamapsGeocodeService } from '@transport/client-yamaps-core';
import { TnMapPoint } from '@transport/ui-interfaces';
import { TnDadataFacade } from '@transport/ui-store';
import { BehaviorSubject } from 'rxjs';
import { map, take } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'transport-address-input-dialog',
  templateUrl: './address-input-dialog.component.html',
  styleUrls: ['./address-input-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnAddressInputDialogComponent implements OnDestroy {
  /**
   * Import embedded yamaps component.
   */
  @ViewChild(TnYamapComponent) public yamap?: TnYamapComponent;

  /**
   * Object for addresses variants that we put from dadata service.
   */
  public addressSuggestions$ = this.tnDadataFacade.dadataData$.pipe(map(data => data.suggestions));

  /**
   * Ouput data for parent component
   */
  private data?: TnMapPoint;

  /**
   * Default zoom for yamaps after finding something
   */
  private readonly daDataYamapsDefaultZoom = 17;

  private readonly isLoadingSubject = new BehaviorSubject<boolean>(true);

  public isLoading$ = this.isLoadingSubject.asObservable();

  /**
   * Class constructor
   */
  constructor(
    public dialogRef: MatDialogRef<TnAddressInputDialogComponent>,
    private readonly container: ViewContainerRef,
    private readonly geocode: TnYamapsGeocodeService,
    private readonly tnDadataFacade: TnDadataFacade,
    @Inject(MAT_DIALOG_DATA) private readonly dialogData: Partial<TnMapPoint>,
  ) {}

  public mapReady() {
    if (Boolean(this.dialogData?.fullAddress)) {
      void this.tnDadataFacade.getMapPointByAddressSuccess$
        .pipe(untilDestroyed(this))
        .subscribe((res: { point?: { geoLat: string; geoLon: string } }) => {
          this.isLoadingSubject.next(false);
          if (Boolean(res.point?.geoLat) && Boolean(res.point?.geoLon)) {
            this.positionOnMap(Number(res.point?.geoLat), Number(res.point?.geoLon));
          }
        });
      this.tnDadataFacade.getMapPointByAddress({ address: this.dialogData?.fullAddress ?? '', country: this.dialogData?.country });
    } else {
      this.isLoadingSubject.next(false);
    }
  }

  public get isSelectButtonDisabled(): boolean {
    return !Boolean(this.data);
  }

  private positionOnMap(lat: number, lon: number) {
    this.yamap?.removeAllGeoObjects();
    this.yamap?.update([lat, lon]);
    this.yamap?.setZoom(this.daDataYamapsDefaultZoom);
  }

  /**
   * Event called after click on map
   */
  public getCoordinatesByClickChange(e: string[]) {
    this.data = void 0;
    const lat = Number(e[0]);
    const lon = Number(e[1]);
    this.positionOnMap(lat, lon);
    this.tnDadataFacade.getMapPointsByCoordinates({ lat, lon });
  }

  public ngOnDestroy(): void {
    this.tnDadataFacade.clearSuggestions();
  }

  /**
   * Event called after selection of address variant
   */
  public selectAddress(data: TnMapPoint, index): void {
    const el = (this.container.element.nativeElement as HTMLElement).querySelector(`[data-address-index="${index}"]`) as Element;
    const allEl = el.parentElement?.querySelectorAll('mat-card') as NodeListOf<Element>;
    allEl.forEach(element => {
      element.classList.remove('active');
    });
    el.classList.add('active');
    this.yamap?.removeAllGeoObjects();
    void this.geocode.addressToCoordinates(data.fullAddress).then(e => {
      this.yamap?.update(e);
      this.yamap?.setZoom(this.daDataYamapsDefaultZoom);
    });
    this.data = data;
  }

  /**
   * Event called after click on "Select" button
   */
  public select(): void {
    void this.tnDadataFacade.getMapPointByAddressSuccess$.pipe(take(1), untilDestroyed(this)).subscribe(value => {
      this.dialogRef.close(value.point);
    });
    this.tnDadataFacade.getMapPointByAddress({ address: this.data?.fullAddress ?? '', country: this.data?.country || this.dialogData?.country });
  }
}
