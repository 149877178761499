import { google, transport } from '@transport/proto';

export interface WebSocketConfig {
  url: string;
  reconnectInterval?: number;
  reconnectAttempts?: number;
}

export interface IWsMessage<T> {
  event: string;
  data: T;
}

export type TMarketplaceWsTopic = 'order' | 'notification' | 'banner_notification' | 'auction' | 'bidding' | 'import' | 'termination_agreement' | 'common_tender' | 'pretension';
export type TMarketplaceWsTopicAction =
  | 'status.assigned'
  | 'auction_prolonged'
  | 'finished_without_winner'
  | 'updated.price'
  | 'status.cancelled'
  | 'status.freed'
  | 'updated_price_and_change_bets_count'
  | 'reserve_transport_documents_created'
  | 'signing_contract_by_carrier'
  | 'order_price_changed'
  | 'violation_of_criteria'
  | 'order_loading_place_time_changed'
  | 'order_data_changed'
  | 'auction_assigned_to_another_carrier'
  | 'tn_sign_contract_signed_by_carrier'
  | 'signed_term_agreement'
  | 'signing'
  | 'ready_to_go'
  | 'order_price_updated' // Order actions
  | 'created'
  | 'marked_as_read'
  | 'banner_notification_changed'
  | 'documents_created'
  | 'finished_with_winner'
  | 'import_finished'
  | 'import_error'
  | 'counter_offer_placed'
  | 'order_list_changed'
  | 'accept_deadline_changed'
  | 'trading_participants_count_changed'
  | 'signing_contract_by_cargo_owner'
  | 'canceled' // Notification actions
  | 'pending' // tender actions
  | 'pretension_status_changed'
  | 'pretension_chat_message_received'
  | 'completed';

export class MarketplaceWebsocketEventData<T = Record<string, unknown>> implements transport.ITnWebsocketEventData {
  public data?: google.protobuf.IAny | null;

  public topic?: TMarketplaceWsTopic;

  public action?: TMarketplaceWsTopicAction;

  public payload?;

  constructor(event?: Partial<transport.ITnWebsocketEventData>) {
    const newValues = { payload: null, ...event };
    for (const key of Object.keys(newValues)) {
      this[key] = newValues[key];
    }
    if (typeof this.payload === 'string') {
      this.data = JSON.parse(this.payload);
    }
  }
}
