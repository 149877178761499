import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { UntilDestroy } from '@ngneat/until-destroy';

enum TRACK_QUERIES {
  CANCEL_ORDER_TRADING = 'CancelOrderTrading',
  ASSIGN_COUNTER_OFFER = 'acceptCounterOffer',
  ACCEPT_COUNTER_OFFER = 'acceptCounterOfferAsBet',
  ORDERS = 'getOrdersList',
  AUCTION_BET_HISTORY = 'orderAuctionBetHistory',
  ORDER_SHORT_INFO = 'orderShortInfo',
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class OrderApiTrackQueryService {
  public events$ = new Subject<TRACK_QUERIES>();
  public serverTime$ = new BehaviorSubject<string | null>(null);
  public TRACK_QUERIES = TRACK_QUERIES;
}
