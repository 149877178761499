import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TnConstantsEffects } from './constants.effects';
import { constantsReducer } from './constants.reducers';

const CONSTANTS_EFFECTS = [TnConstantsEffects];

@NgModule({
  imports: [StoreModule.forFeature('constants', constantsReducer), EffectsModule.forFeature(CONSTANTS_EFFECTS)],
  exports: [StoreModule, EffectsModule],
})
export class TnConstantsModule {}
