import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store/src/models';
import { createNewUser, IUser, TUserPredefinedAction, USER_PERMISSION_CODE } from '@transport/ui-interfaces';
import { GraphQLError } from 'graphql';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, take, tap, withLatestFrom } from 'rxjs/operators';

import { TnConfirmationService } from '../../../confirmation/confirmation.service';
import { TnCurrentUserFacade } from '../../../current-user/current-user.facade';
import { TnGlobalErrorHandlerService } from '../../../toaster/global-error-handler.service';
import { TnAdminApiService } from '../../admin-api.service';
import { allPermissionsActions, userActions, userApiActions } from '../actions';
import { TnUserFacade } from '../user.facade';
import { TnAlertService } from '@transport/ui-kit';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TnOwnerUserEffects {
  public readonly loadUserFormDictionaries = createEffect(() => {
    return this.facade.mode$.pipe(
      filter(mode => Boolean(mode)),
      take(1),
      map(allPermissionsActions.loadAllPermissions),
    );
  });

  public readonly loadUser = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.loadUser),
      withLatestFrom(this.facade.userId$, this.facade.mode$),
      switchMap(([, userId, mode]: [Action, string, TUserPredefinedAction]) => {
        if (!Boolean(userId)) {
          return [userApiActions.loadUserSuccess({ user: createNewUser() }), userActions.setFormMode({ mode })];
        }

        return this.adminService.getUser(userId).pipe(
          switchMap(user => {
            if (mode === 'copy') {
              user.user.permissions = user.user.permissions?.filter(item => item.codename !== USER_PERMISSION_CODE.ADMIN_CG);
            }
            return [userApiActions.loadUserSuccess(user), userActions.setFormMode({ mode })];
          }),
        );
      }),
    ),
  );

  public readonly saveUser = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.saveUser),
      switchMap(action => of(action).pipe(withLatestFrom(this.facade.userId$))),
      switchMap(([action, id]) => {
        return this.adminService.saveUser({ id: id ?? '', ...action.user });
      }),
      switchMap(value => {
        return [userApiActions.saveUserSuccess(value)];
      }),
      catchError(error => {
        this.alertService.shortError(this.translate.instant('shared.admin.user.message.saveError'));
        return of(userApiActions.saveUserFailure({ error }));
      }),
      tap(() => this.alertService.shortSuccess(this.translate.instant('shared.admin.user.message.saveSuccess'))),
    ),
  );

  public readonly inviteUser = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.inviteUser),
      switchMap(action => {
        return this.adminService.inviteUser(action.user ?? {}).pipe(
          tap(() => this.alertService.shortSuccess(this.translate.instant('shared.admin.user.message.inviteSuccess'))),
          map(userApiActions.inviteUserSuccess),
          catchError((error: GraphQLError) => {
            let message: string;
            if (error.extensions?.category === 'DUPLICATE' && error.extensions?.param === 'email') {
              message = this.translate.instant('shared.admin.user.message.duplicateUser', {
                email: (action.user as Partial<IUser>).email ?? '',
              });
            } else {
              message = this.globalErrorHandler.extractGqlErrorMessage(error);
            }
            this.alertService.shortError(message);
            return of(userApiActions.inviteUserFailure({ error }));
          }),
        );
      }),
    ),
  );

  public readonly inviteUserAgain = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.inviteUserAgain),
      switchMap(action => {
        return this.adminService.inviteUserAgain(action.id ?? {}).pipe(
          tap(() => this.alertService.shortSuccess(this.translate.instant('shared.admin.user.message.inviteSuccess'))),
          map(userApiActions.inviteUserSuccess),
          catchError((error: GraphQLError) => {
            const message = this.globalErrorHandler.extractGqlErrorMessage(error);
            this.alertService.shortError(message);

            return of(userApiActions.inviteUserFailure({ error }));
          }),
        );
      }),
    ),
  );

  public readonly removeUser = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.removeUser),
      switchMap(({ user }) =>
        this.confirmation.openByPrefix('shared.admin.user.confirm.remove').pipe(
          filter(result => Boolean(result)),
          switchMap(() =>
            this.adminService.removeUser(user.id ?? '').pipe(
              map(userApiActions.removeUserSuccess),
              catchError(error => {
                this.alertService.shortError(this.translate.instant('shared.admin.user.message.removeError'));
                return of(userApiActions.removeUserFailure({ error }));
              }),
              tap(() => {
                this.alertService.shortSuccess(this.translate.instant('shared.admin.user.message.removeSuccess'));
              }),
            ),
          ),
        ),
      ),
    ),
  );

  public readonly backToListOnSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(userApiActions.removeUserSuccess, userApiActions.saveUserSuccess, userApiActions.inviteUserSuccess),
      map(() => TnUserFacade.backToListAction()),
    ),
  );

  public readonly resetPassword = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.resetPassword),
      switchMap(({ id }) =>
        this.adminService.resetPassword(id).pipe(
          map(response => {
            this.alertService.shortSuccess(
              this.translate.instant('shared.admin.user.message.resetPasswordSuccess', { email: response.email ?? '' }),
            );
            return userApiActions.resetPasswordSuccess({ user: response });
          }),
          catchError(error => {
            this.alertService.shortError(this.translate.instant('shared.admin.user.message.resetPasswordError'));
            return of(userApiActions.resetPasswordFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    public readonly actions$: Actions,
    public readonly userFacade: TnCurrentUserFacade,
    private readonly adminService: TnAdminApiService,
    private readonly facade: TnUserFacade,
    private readonly confirmation: TnConfirmationService,
    private readonly alertService: TnAlertService,
    private readonly translate: TranslateService,
    private readonly globalErrorHandler: TnGlobalErrorHandlerService,
  ) {}
}
