import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject } from 'rxjs';
import { ICommonSelectData } from '../../common.interface';

@UntilDestroy()
@Component({
  selector: 'common-select',
  templateUrl: './common-select.component.html',
  styleUrls: ['./common-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonSelectComponent implements OnInit {
  _dataSource: ICommonSelectData[] | null = [];
  @Input() public set dataSource(data: ICommonSelectData[] | null) {
    this._dataSource = data;
    this.value = data?.find(i => i.id == (this.value?.id || this.formControl?.value?.id));
    this.options.next(this.dataSource);
  }
  public get dataSource() {
    return this._dataSource;
  }

  public options = new BehaviorSubject<ICommonSelectData[] | null>([]);

  @ViewChild('commonSelect') commonSelect!: MatSelect;

  @Input() public size: 'sm' | 'md' | 'lg' = 'sm';
  @Input() public value?: ICommonSelectData | null;
  @Input() public errorsSize: 'xs' | 'sm' | 'md' | 'lg' = 'xs';
  @Input() public inputFieldClass = '';
  @Input() public panelClass = '';
  @Input() public label?: string = '';
  @Input() public labelClasses?: string[];
  @Input() public labelAddition?: string;
  @Input() public caption?: string;
  @Input() public captionClasses?: string[];
  @Input() public showErrors = true;
  @Input() public required = false;
  @Input() public disabled = false;
  @Input() public asyncSearch = false;
  @Input() public errorToMessage: (errors: ValidationErrors | null) => string = () => 'Error';
  @Input() public allowClear = true;
  @Input() public enableSearch = false;
  @Input() public isOptionWithTooltip = false;

  @Input() public onAdd: (() => void) | undefined = undefined;

  _control: FormControl | undefined;
  @Input() public set control(ctrl: FormControl | undefined) {
    this._control = ctrl;
    if (ctrl && !this.value) this.value = this.options.value?.find(opt => opt?.id === ctrl?.value?.id);
  }
  public get control(): FormControl | undefined {
    return this._control;
  }

  @Input() public formGroup?: FormGroup;
  @Input() public controlName: string = '';
  @Input() public placeholder: string = '';
  @Input() public dataTest: string = '';

  @Output() public selectionChanged = new EventEmitter();

  @Output() public search = new EventEmitter();

  @Output() public clear = new EventEmitter();

  public isOpen = false;

  public filterCtrl: FormControl = new FormControl();

  public hideSelectedValue = false;

  constructor(private cdr: ChangeDetectorRef) {}

  get formControl(): AbstractControl | null {
    if (this.control) return this.control;
    if (this.formGroup && this.controlName) return this.formGroup.get(this.controlName);

    return null;
  }

  public get isEmpty(): boolean {
    return Boolean(!this.value) && !Boolean(this.formControl?.value);
  }

  public ngOnInit() {
    this.filterCtrl.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.filterOptions();
    });
    this.control?.valueChanges.subscribe(res => {
      this.value = this.options.value?.find(opt => opt?.id === res?.id);
      this.cdr.markForCheck();
    });
  }

  public touch(withSearch) {
    if (this.enableSearch) {
      withSearch && this.formControl?.markAsTouched();
    } else {
      this.formControl?.markAsTouched();
    }
  }

  private filterOptions() {
    let search = this.filterCtrl.value?.toLowerCase();

    if (!this.asyncSearch) {
      this.syncFilter(search);
    } else {
      this.asyncFilter(search);
    }
  }

  private syncFilter(search) {
    if (!this.dataSource) {
      return;
    }
    if (!search) {
      this.options.next(this.dataSource);
      this.hideSelectedValue = false;
      return;
    }
    const filteredOptions = this.dataSource.filter(option => option.label.toLowerCase().includes(search));

    this.hideSelectedValue = Boolean(this.value);

    this.options.next(filteredOptions);
  }

  private asyncFilter(search) {
    this.search.emit(search);
  }

  public selectionChange(event): void {
    this.value = event.value;
    this.formControl?.setValue(event.value);
    this.selectionChanged.emit({
      id: event.value.id,
      label: event.value.label,
    });
  }

  public clearSelect(e): void {
    e.stopPropagation();
    this.value = undefined;
    this.formControl?.setValue(null);
    this.clear.emit(null);
    this.selectionChanged.emit(null);
  }
}
