import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map } from 'rxjs/operators';

import { formActions, GET_FORM_DATA } from './form.actions';
import { formInitialState } from './form.state';

@Injectable({
  providedIn: 'root',
})
export class TnFormEffects {
  constructor(private readonly action$: Actions) {}

  public readonly resetFormData$ = createEffect(() =>
    this.action$.pipe(
      ofType<ReturnType<typeof formActions.resetFormData>>(GET_FORM_DATA),
      map(action =>
        formActions.setFormData({
          payload: { path: action.payload.path, value: formInitialState[action.payload.path] },
        }),
      ),
    ),
  );
}
