import { createAction, props } from '@ngrx/store';
import { transport } from '@transport/proto';
import { IAdminOrganization, TAdminOrganizationForm, TDirectoryEntityPredefinedAction } from '@transport/ui-interfaces';

const loadAdminProfileStart = createAction('[Admin Profile] Load Admin Profile Start');

const loadAdminProfileSuccess = createAction('[Admin Profile] Load Admin Profile Success', props<{ profile: IAdminOrganization }>());

const loadAdminProfileFailure = createAction('[Admin Profile] Load Admin Profile Failure', props<{ error }>());

const loadAdminProfileSubOrganizationsSuccess = createAction(
  '[Admin Profile] Load Admin Profile Sub Organizations Success',
  props<{ subOrganizations: transport.IAdminOrganization[] }>(),
);

const loadAdminProfileSubOrganizationsFailure = createAction(
  '[Admin Profile] Load Admin Profile Sub Organizations Failure',
  props<{ error }>(),
);

const saveAdminProfileStart = createAction('[Admin Profile] Save Admin Profile Start', props<{ formValue: TAdminOrganizationForm }>());

const saveAdminProfileSuccess = createAction(
  '[Admin Profile] Save Admin Profile Success',
  props<{ profile: transport.IAdminOrganization }>(),
);

const saveAdminProfileFailure = createAction('[Admin Profile] Save Admin Profile Failure', props<{ error }>());

const navigateToAction = createAction('[Admin Profile] Navigate To Action', props<{ action: TDirectoryEntityPredefinedAction }>());

export const adminProfileActions = {
  loadAdminProfileStart,
  loadAdminProfileSuccess,
  loadAdminProfileFailure,
  loadAdminProfileSubOrganizationsSuccess,
  loadAdminProfileSubOrganizationsFailure,
  saveAdminProfileStart,
  saveAdminProfileSuccess,
  saveAdminProfileFailure,
  navigateToAction,
};
