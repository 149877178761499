import { Action, createReducer, on } from '@ngrx/store';

import { openConfirmation, setConfirmationChoice } from './confirmation.actions';
import { confirmationInitialState, IConfirmationState } from './confirmation.state';

const createConfirmationReducer = createReducer(
  confirmationInitialState,
  on(openConfirmation, (state, { payload }) => ({ ...confirmationInitialState, ...payload })),
  on(setConfirmationChoice, (state, { payload }) => ({ ...state, ...payload })),
);

export function confirmationReducer(state: IConfirmationState, action: Action) {
  return createConfirmationReducer(state, action);
}
