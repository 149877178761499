import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TOAST_DURATION } from '@transport/ui-interfaces';
import { map } from 'rxjs/operators';

import { TnCurrentUserFacade } from '../../current-user/current-user.facade';
import { RouterActions } from '../../ngrx-router/actions/router.actions';
import { TnToasterFacade, TOAST_TYPE } from '../../toaster/toaster.facade';
import { ngrxThrow401, ngrxThrow404 } from '../actions/ngrx-error.actions';

/**
 * NgRx error effects.
 */
@Injectable({
  providedIn: 'root',
})
export class TnNgrxErrorEffects {
  public error404 = createEffect(() => this.actions.pipe(
    ofType(ngrxThrow404),
    map(() => RouterActions.routerGo({ path: ['/passport'] })),
  ));

  public error401 = createEffect(() => this.actions.pipe(
    ofType(ngrxThrow401),
    map(() => {
      this.toastFacade.showMessage('shared.errors.authError', TOAST_TYPE.ERROR, TOAST_DURATION.LONG);
      const isSupervisor = this.userFacade.currentUser.isSupervisor;
      return RouterActions.routerGo({
        path: Boolean(isSupervisor) || this.router.url.includes('supervisor') ? ['/supervisor/login'] : ['/'],
      });
    }),
  ));

  constructor(
    private readonly actions: Actions,
    private readonly toastFacade: TnToasterFacade,
    private readonly userFacade: TnCurrentUserFacade,
    private readonly router: Router,
  ) {}
}
