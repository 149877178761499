import { createReducer, on } from '@ngrx/store';
import { transport } from '@transport/proto';
import { IVehicle } from '@transport/ui-interfaces';

import { searchAgentCarrierSuccess, setChangedTruckStatus, setSelectedAgentCarrier, setVehicle } from '../actions/truck.actions';

export interface ITruckState {
  id: string | null;
  vehicle: IVehicle | null;
  changedTruckStatus: Partial<IVehicle> | null;
  agentCarriers: transport.IAgentCarrier[];
}

export const initialState: ITruckState = {
  id: null,
  vehicle: null,
  changedTruckStatus: null,
  agentCarriers: [],
};

const truckReducerFunc = createReducer(
  initialState,
  on(setVehicle, (state, action) => {
    return {
      ...state,
      vehicle: action.vehicle,
    };
  }),

  on(setChangedTruckStatus, (state, action) => {
    return {
      ...state,
      changedTruckStatus: action.changedTruckStatus,
    };
  }),

  on(searchAgentCarrierSuccess, setSelectedAgentCarrier, (state, { agentCarriers }) => {
    return {
      ...state,
      agentCarriers: [...agentCarriers],
    };
  }),
);

export function truckReducer(state, action) {
  return truckReducerFunc(state, action);
}
