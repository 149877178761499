import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TnToasterActionService {
  constructor() {}

  private readonly toasterAction$ = new Subject();

  public getToasterAction$() {
    return this.toasterAction$;
  }
}
