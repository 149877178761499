import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectRouteData } from '../../ngrx-router/selectors/router.selectors';
import { IProfileState, profileFeatureKey } from '../transport-profile.state';

const getProfileState = createFeatureSelector<IProfileState>(profileFeatureKey);
const getAdminProfileState = createSelector(getProfileState, state => state.admin);
const getProfileSharedState = createSelector(getProfileState, state => state.shared);

export const getReactiveForm = createSelector(getAdminProfileState, state => state.reactiveFormValue);
export const getFormMode = selectRouteData('action');
export const getLegalForms = createSelector(getProfileSharedState, state => state.legalFormsDict);
export const getDefaultInsurance = createSelector(getProfileSharedState, state => state.defaultContract);
export const getVatTypes = createSelector(getProfileSharedState, state => state.vatTypesDict);
export const getPaymentTypes = createSelector(getProfileSharedState, state => state.paymentTypesDict);
export const getIsCompanyGroup = createSelector(getReactiveForm, state => state.isCompanyGroup);
export const getSubOrganizations = createSelector(getAdminProfileState, state => state.subOrganizations);
export const getLoading = createSelector(getAdminProfileState, state => state.loading);
