import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { transport } from '@transport/proto';
import { IDriverForm } from '@transport/ui-interfaces';
import { COUNTER } from '@transport/ui-utils';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnCurrentUserFacade } from '../../../current-user/current-user.facade';
import { IDirectoryState } from '../../directory-state.interface';
import { TnDirectoryAgentCarriersFacade } from '../agent-carriers-table/agent-carriers.facade';
import { TnCarrierDirectoryDriversApiService } from '../drivers-table/carrier-directory-drivers-api.service';
import {
  removeDriver,
  removeDriverSuccess,
  searchAgentCarrier,
  setDriverIdAction,
  setSelectedAgentCarrier,
} from './actions/driver.actions';
import * as selectors from './selectors/driver.selectors';
import { agentCarriers } from './selectors/driver.selectors';
@Injectable({
  providedIn: 'root',
})
export class TnDriverFacade {
  public removeDriver$ = this.actions$.pipe(ofType(removeDriverSuccess));

  public agentCarriers$ = this.store.pipe(select(agentCarriers));

  public driverId$ = this.store.pipe(select(selectors.driverId));

  public changedDriverStatus$ = this.store.pipe(select(selectors.changedDriverStatus));

  constructor(
    private readonly driverDirectoryService: TnCarrierDirectoryDriversApiService,
    private readonly agentCarrierFacade: TnDirectoryAgentCarriersFacade,
    private readonly userFacade: TnCurrentUserFacade,
    private readonly store: Store<IDirectoryState>,
    private readonly actions$: Actions,
  ) {}

  public getDriver(id: string) {
    return this.driverDirectoryService.getDriver(id);
  }

  public addDriver(data: IDriverForm) {
    return this.driverDirectoryService.addDriver(data);
  }

  public editDriver(data: IDriverForm & { id: string }) {
    return this.driverDirectoryService.editDriver(data);
  }

  public removeDriver(id: string, name = '') {
    this.store.dispatch(removeDriver({ id, name }));
  }

  public setDriverId(id: string | null) {
    this.store.dispatch(setDriverIdAction({ id }));
  }

  public getAgentCarriersByName(organizationName: string): Observable<transport.IAgentCarrier[]> {
    const companyName = this.userFacade.currentUser.companyName;
    const arrayWithCurrentOrganization =
      organizationName.length > 0 && Boolean(companyName?.toLowerCase().includes(organizationName.toLowerCase()))
        ? [
            {
              id: this.userFacade.currentUser.organization?.id,
              name: companyName,
            },
          ]
        : [];

    if (organizationName.length > COUNTER.TWO && organizationName !== companyName) {
      return this.agentCarrierFacade
        .getAgentCarriers(1, false, { name: organizationName, availability: 'ACTIVE' })
        .pipe(
          map(result => [
            ...arrayWithCurrentOrganization,
            ...result.list.map(agentCarrier => this.agentCarrierFacade.changeAgentCarrierNameMapper(agentCarrier)),
          ]),
        );
    }
    return of(arrayWithCurrentOrganization);
  }

  public searchAgentCarrier(term: string) {
    this.store.dispatch(searchAgentCarrier({ term }));
  }

  public setSelectedAgentCarrier(selectedAgentCarrier: transport.IAgentCarrier[]) {
    this.store.dispatch(setSelectedAgentCarrier({ agentCarriers: selectedAgentCarrier }));
  }
}
