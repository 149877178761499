import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { STATE_RELATIONSHIP, TCarrierGroupForm, TUserPredefinedAction } from '@transport/ui-interfaces';
import { map } from 'rxjs/operators';

import { ITnState } from '../../../state/index';
import { TnOwnerDirectoryCarriersApiService } from '../carriers-table/owner-directory-carriers-api.service';
import { carrierGroupActions } from './actions/carriers-group.actions';
import { carrierGroupSelectors } from './selectors/carrier-groups.selector';

@Injectable({
  providedIn: 'root',
})
export class TnCarrierGroupsFacade {
  public removeGroup$ = this.action$.pipe(ofType(carrierGroupActions.removeGroupSuccess));

  constructor(
    protected store: Store<ITnState>,
    private readonly action$: Actions,
    private readonly ownerDirectoryCarriersService: TnOwnerDirectoryCarriersApiService,
  ) {}

  public get groupId$() {
    return this.store.pipe(select(carrierGroupSelectors.getGroupId));
  }

  public validateGroupForm$ = this.action$.pipe(ofType(carrierGroupActions.validateGroupForm));

  public groupSaveSuccess$ = this.action$.pipe(ofType(carrierGroupActions.saveGroupSuccess));

  public get group$() {
    return this.store.pipe(select(carrierGroupSelectors.getGroup));
  }

  public get formValue$() {
    return this.store.pipe(select(carrierGroupSelectors.getFormValue));
  }

  public get mode$() {
    return this.store.pipe(select(carrierGroupSelectors.getGroupMode));
  }

  public get isFormValid$() {
    return this.store.pipe(select(carrierGroupSelectors.getIsFormValid));
  }

  public get isFormDirty$() {
    return this.store.pipe(select(carrierGroupSelectors.getIsFormDirty));
  }

  public get isFormDisabled$() {
    return this.store.pipe(select(carrierGroupSelectors.getIsFormDisabled));
  }

  public get isLoading$() {
    return this.store.pipe(select(carrierGroupSelectors.getLoading));
  }

  public get organizations$() {
    return this.ownerDirectoryCarriersService
      .getCarrierOrganizationsList(false, false, [STATE_RELATIONSHIP.ACCREDITED, STATE_RELATIONSHIP.CONTRACT_IS_SIGNED])
      .pipe(map(res => res.list));
  }

  public backToList() {
    this.store.dispatch(carrierGroupActions.navigateToGroups());
  }

  public save(group: TCarrierGroupForm) {
    this.store.dispatch(carrierGroupActions.saveGroup({ group }));
  }

  public remove(id?: string) {
    this.store.dispatch(carrierGroupActions.removeGroup({ id }));
  }

  public showGroupDetails(mode: TUserPredefinedAction) {
    this.store.dispatch(carrierGroupActions.showGroupDetails({ mode }));
  }

  public navigateToEdit() {
    this.showGroupDetails('edit');
  }

  public navigateToCopy() {
    this.showGroupDetails('copy');
  }

  public navigateToRemove() {
    this.showGroupDetails('remove');
  }

  public formValidStatusChanged(isValid: boolean): void {
    this.store.dispatch(carrierGroupActions.formValidStatusChanged({ isValid }));
  }

  public formDirtyStatusChanged(isDirty: boolean): void {
    this.store.dispatch(carrierGroupActions.formDirtyStatusChanged({ isDirty }));
  }
}
