import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'marketplace-verification-icon',
  templateUrl: './verification-icon.component.html',
  styleUrls: ['./verification-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerificationIconComponent {
  @Input() iconColor!: string;
  @Input() tip: string = '';
}
