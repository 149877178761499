import { Component, OnInit, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VerificationFlowService } from '../verification-flow.service';
import { regExpConfig } from '@transport/ui-utils';
import { TranslateService } from '@ngx-translate/core';
import { MatStepper } from '@angular/material/stepper';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { TnAlertService, VerificationStatuses } from '@transport/ui-kit';
import { VerificationsService } from '@marketplace/shared/modules/shared/services/verifications.service';

export enum LEGAL_FORMS {
  INDIVIDUAL_INTERPRENEUR = '1',
  SELFEMPLOYED = '4',
}

export enum STEPS_LIST {
  contacts,
  organization,
  vehicle,
  sending,
}
@Component({
  templateUrl: './verification-modal.component.html',
  styleUrls: ['./verification-modal.component.scss', './step-overrides.component.scss'],
})
export class TnVerificationModalComponent implements OnInit {
  @ViewChild('stepper')
  stepper!: MatStepper;

  contactsFG = this._formBuilder.group({
    fullName: ['', [Validators.required, Validators.maxLength(150)]],
    post: ['', [Validators.maxLength(150)]],
    phone: ['', [Validators.required, Validators.pattern(regExpConfig.phoneNumber.RU)]],
    addPhone: ['', Validators.pattern(regExpConfig.phoneNumber.RU)],
    attorney: [''],
  });

  organizationFG = this._formBuilder.group({
    fullName: ['', Validators.required],
    regulation: ['', Validators.required],
    registrationCertificate: ['', Validators.required],
    ogrnDate: [''],
    appointmentOrder: ['', Validators.required],
    passportPhoto: ['', Validators.required],
    directorFullName: ['', [Validators.maxLength(150)]],
    passportRegistrationPhoto: ['', Validators.required],
    selfEmployedReferrence: [''],
    // directorInn: new FormControl<string>('', [Validators.pattern(regExpConfig.innPhysOrLegal.RU)]),
  });

  vehicleFG = this._formBuilder.group({
    ownership: ['NO'],
    vehicles: this._formBuilder.array([]),
  });

  sendingFG = this._formBuilder.group({
    contactsFG: this.contactsFG,
    organizationFG: this.organizationFG,
    vehicleFG: this.vehicleFG,
  });

  public isSend!: boolean;
  public infoStepWasWatched: boolean = !!localStorage.getItem('VerificationModal');

  private previousStep = 0;
  private selectedStep = 0;
  constructor(
    private _formBuilder: FormBuilder,
    private flowService: VerificationFlowService,
    private alertService: TnAlertService,
    private translate: TranslateService,
    public verificationsService: VerificationsService,
  ) {}

  public ngOnInit() {
    const stored = localStorage.getItem('VerificationModal');
    if (stored) {
      const parsed = JSON.parse(stored);
      if (parsed.contacts) {
        this.contactsFG.patchValue(parsed.contacts);
      }
      if (parsed.organization) {
        this.organizationFG.patchValue(this.mapOrganizationFiles(parsed.organization));
      }
      if (parsed.vehicle) {
        this.vehicleFG.patchValue({
          ownership: parsed.vehicle.ownership,
        });
        const vehicles = (this.vehicleFG.controls['vehicles'] as FormArray).controls as FormGroup[];
        parsed.vehicle.vehicles
          .filter(vehicle => vehicle.photoFirst || vehicle.photoSecond || vehicle.regNumber)
          .map(vehicle => {
            const group = this._formBuilder.group({
              regNumber: [vehicle.regNumber],
              photoFirst: [vehicle.photoFirst],
              photoSecond: [vehicle.photoSecond],
            });
            vehicles.push(group);
          });
      }
    }
  }

  public onStepChange(event: StepperSelectionEvent) {
    this.previousStep = event.previouslySelectedIndex;
    this.selectedStep = event.selectedIndex;
    setTimeout(() => this.flowService.changeStep(this.previousStep, this.selectedStep));
  }

  public stepperNext(form: FormGroup) {
    form.markAllAsTouched();
    this.flowService.changeStep(this.previousStep, this.selectedStep, 'next');
  }

  public stepperPrevious() {
    this.flowService.changeStep(this.previousStep, this.selectedStep, 'previous');
  }

  public close(): void {
    if (this.infoStepWasWatched) {
      const stored = localStorage.getItem('VerificationModal');
      const parsed = stored ? JSON.parse(stored) : {};
      const vehicles = (this.vehicleFG.controls['vehicles'] as FormArray).controls as FormGroup[];
      localStorage.setItem(
        'VerificationModal',
        JSON.stringify({
          contacts: this.contactsFG.getRawValue(),
          organization: this.organizationFG.getRawValue(),
          vehicle: this.vehicleFG.valid && vehicles.every(control => control.valid) ? this.vehicleFG.getRawValue() : parsed?.vehicle,
        }),
      );
    }
    this.flowService.closeModal(this.stepper?.selectedIndex || null);
  }

  save() {
    this.organizationFG.patchValue(this.mapOrganizationFiles(this.organizationFG.value));
    this.sendingFG.markAllAsTouched();
    if (this.contactsFG.valid && this.organizationFG.valid && this.vehicleFG.valid) {
      localStorage.removeItem('VerificationModal');
      const profile = {
        contacts: this.contactsFG.getRawValue(),
        organization: this.organizationFG.getRawValue(),
        vehicle: this.vehicleFG.getRawValue(),
      };
      this.flowService.save(profile).subscribe((id: any) => {
        if (!id) {
          this.alertService.shortError(this.translate.instant('shared.mp.verification.stepper.apiError'));
        } else {
          this.isSend = true;
          // TODO: Для обновления статуса аккердитации в сайдбаре, необходимо решить проблему обновления статуса через websocket в VerificationBlockComponent
          this.verificationsService.verificationStatus.next(VerificationStatuses.checking);
        }
      });
    } else {
      this.alertService.shortError(this.translate.instant('shared.mp.verification.stepper.requiredError', 'center'));
    }
  }

  private mapOrganizationFiles(organization) {
    return {
      ...organization,
      appointmentOrder: organization.appointmentOrder?.fileUrl ? organization.appointmentOrder : '',
      passportPhoto: organization.passportPhoto?.fileUrl ? organization.passportPhoto : '',
      registrationCertificate: organization.registrationCertificate?.fileUrl ? organization.registrationCertificate : '',
      regulation: organization.regulation?.fileUrl ? organization.regulation : '',
      passportRegistrationPhoto: organization.passportRegistrationPhoto?.fileUrl ? organization.passportRegistrationPhoto : '',
    };
  }

  public infoWasWatched() {
    this.infoStepWasWatched = true;
    localStorage.setItem('VerificationModal', '');
  }
}
