import { Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'common-checkbox',
  templateUrl: './common-checkbox.component.html',
  styleUrls: ['./common-checkbox.component.scss'],
})
export class CommonCheckboxComponent {
  @Input() public size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';
  @Input() public errorsSize: 'xs' | 'sm' | 'md' | 'lg' = 'xs';
  @Input() public inputFieldClass = '';
  @Input() public label?: string = '';
  @Input() public labelClasses?: string[];
  @Input() public labelAddition?: string;
  @Input() public caption?: string;
  @Input() public captionClasses?: string[];
  @Input() public showErrors = true;
  @Input() public required = false;
  @Input() public errorToMessage: (errors: ValidationErrors | null) => string = () => 'Error';

  @Input() public formGroup!: FormGroup;
  @Input() public controlName: string = '';
  @Input() public control?: FormControl;
  @Input() public placeholder: string = '';
  @Input() public dataTest: string = '';

  @Input() public direction: 'row' | 'column' = 'column';

  constructor() {}

  get formControl(): AbstractControl {
    return (this.formGroup?.get(this.controlName) ?? this.control)!;
  }
}
