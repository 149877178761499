import { Injectable } from '@angular/core';
import { transport } from '@transport/proto';
import { IAgentCarrierEditInput, IAgentCarrierInput, LEGAL_FORM_CODES, USER_ROLE } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnGqlClientCarrierService } from '../../../gql-client-carrier';
import { TnGqlClientSharedService } from '../../../gql-client-shared';
import { TnOrganizationService } from '../../../profile/organization/organization.service';
@Injectable({
  providedIn: 'root',
})
export class TnAgentCarrierService {
  constructor(
    private readonly graphQL: TnGqlClientCarrierService,
    private readonly graphQLShared: TnGqlClientSharedService,
    private readonly organizationService: TnOrganizationService,
  ) {}

  public getOrganizationByInn(inn: string, legalFormCode: LEGAL_FORM_CODES) {
    return this.organizationService.getOrganizationByInn(inn, legalFormCode).pipe(map(result => result.organization));
  }

  public getLegalForms() {
    return this.organizationService.getLegalForms();
  }

  public getVatTypes(): Observable<transport.IOrganizationVatType[]> {
    return this.graphQLShared.queryOrganizationVatTypes(USER_ROLE.CARRIER);
  }

  public getAgentCarrier(id: string): Observable<transport.IAgentCarrier> {
    return this.graphQL.getAgentCarrier(id).pipe(map(result => result.agentCarrier));
  }

  public addAgentCarrier(agentCarrier: IAgentCarrierInput): Observable<transport.IAgentCarrier> {
    return this.graphQL.addAgentCarrier(agentCarrier).pipe(map(result => result.addAgentCarrier));
  }

  public editAgentCarrier(agentCarrier: IAgentCarrierEditInput): Observable<transport.IAgentCarrier> {
    return this.graphQL.editAgentCarrier(agentCarrier).pipe(map(result => result.editAgentCarrier));
  }

  public sendAgentCarrierToArchive(id: string): Observable<transport.IAgentCarrier> {
    return this.graphQL.sendAgentCarrierToArchive(id).pipe(map(result => result.sendAgentCarrierToArchive));
  }
}
