import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IAuthenticationCodeRequestFormData,
  IConfirmEmailResponse,
  IConfirmRegistrationResponse,
  IInviteStatusResponse,
  ILoginByCodeRequestFormData,
  ILoginRequestFormData,
  ILoginResponseData,
  ILoginSupervisorResponse,
  IRegByCodeFormData,
  ISignUpEmployee,
  ISignupRequestFormData,
  USER_ROLE,
} from '@transport/ui-interfaces';
import { TnCurrentUserFacade } from '@transport/ui-store';
import { getAuthorizationHeader } from '@transport/ui-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnHttpHandlersService } from '../../../feature-access';
@Injectable({
  providedIn: 'root',
})
export class TnAuthApiService {
  /**
   * Endpoints object for making requests to the API.
   */
  public readonly endpoints = {
    login: (): string => this.handlers.getEndpointUrl('/auth/login'),
    loginByCode: (): string => this.handlers.getEndpointUrl('/auth/login_via_code'),
    logout: (): string => this.handlers.getEndpointUrl('/auth/logout'),
    logoutSupervisor: (): string => this.handlers.getEndpointUrl('/auth/supervisor-logout'),
    signup: (): string => this.handlers.getEndpointUrl('/auth/register'),
    signUpByCode: (): string => this.handlers.getEndpointUrl('/auth/sign_up_by_code'),
    signUpEmployee: (): string => this.handlers.getEndpointUrl('/auth/employee/registration/confirm'),
    signinSupervisor: (): string => this.handlers.getEndpointUrl('auth/supervisor-data/'),
    signinDigitalProfile: (): string => this.handlers.getEndpointUrl('auth/user-data/'),
    verifyUserKey: (value: string): string => this.handlers.getEndpointUrl(`/auth/verify-user-key/${value}`),
    refreshUserKey: (value: string): string => this.handlers.getEndpointUrl(`/auth/refresh-user-key/${value}`),
    setPassword: (): string => this.handlers.getEndpointUrl(`/auth/set-password`),
    resetPassword: (): string => this.handlers.getEndpointUrl(`/auth/reset-password`),
    confirmEmailChange: (value: string): string => this.handlers.getEndpointUrl(`/auth/confirm-change-email/${value}`),
    getDigitalProfile: (): string => this.handlers.getEndpointUrl(`/digital-profile/registration-url`),
    getUserAgreement: (): string => this.handlers.getEndpointUrl('/auth/user-agreement'),
    acceptUserAgreement: (): string => this.handlers.getEndpointUrl('/auth/accept-user-agreement'),
    checkEmail: (): string => this.handlers.getEndpointUrl('/auth/check_email'),
    signupByLink: (): string => this.handlers.getEndpointUrl('/auth/sign_up'),
    invitationUserStatus: (): string => this.handlers.getEndpointUrl('/auth/invitation_user_status'),
    confirmEmailAgain: (): string => this.handlers.getEndpointUrl('/auth/send_confirm_email_again'),
    getInviteStatusByToken: (): string => this.handlers.getEndpointUrl('/auth/invitation_user_status'),
    confirmUserRegistration: (): string => this.handlers.getEndpointUrl('/auth/confirm_user_registration'),
    sendAuthenticationCode: (): string => this.handlers.getEndpointUrl('/auth/send_authentication_code'),
  };

  public authenticationData: ILoginByCodeRequestFormData | IRegByCodeFormData | undefined;

  public sendCodeAfterOpenForm = false;

  /**
   * Constructor.
   */
  constructor(
    private readonly http: HttpClient,
    private readonly userFacade: TnCurrentUserFacade,
    private readonly handlers: TnHttpHandlersService,
  ) {}

  /**
   * Loggs user in using email + password.
   */
  public login(email: string, password: string) {
    const endpoint: string = this.endpoints.login();
    const formData: ILoginRequestFormData = {
      email,
      password,
    };
    const observable = this.http.post<ILoginResponseData>(endpoint, formData);
    return this.handlers.pipeHttpRequest<ILoginResponseData>(observable).pipe(
      map(data => {
        const result: ILoginResponseData = { ...data };
        result.role = USER_ROLE[result.role];
        result.email = email;
        return result;
      }),
    );
  }

  public loginByCode(loginData: ILoginByCodeRequestFormData) {
    const endpoint: string = this.endpoints.loginByCode();
    const observable = this.http.post<ILoginResponseData>(endpoint, loginData);
    return this.handlers.pipeHttpRequest<ILoginResponseData>(observable).pipe(
      map(data => {
        const result: ILoginResponseData = { ...data };
        result.role = USER_ROLE[result.role];
        result.email = loginData.email;
        return result;
      }),
    );
  }

  /**
   * Loggs user out using user token.
   */
  public logout(isSupervisor = false) {
    const endpoint: string = isSupervisor ? this.endpoints.logoutSupervisor() : this.endpoints.logout();
    const observable = this.http.post<void>(endpoint, {});
    return this.handlers.pipeHttpRequest<void>(observable);
  }

  /**
   * Signs user up.
   */
  public signup(email: string, password: string, organization: string, role: string, name: string) {
    const endpoint: string = this.endpoints.signup();
    const formData: ISignupRequestFormData = {
      name,
      email,
      password,
      organization,
      role,
    };
    const observable = this.http.post(endpoint, formData); // TODO: add response type
    return this.handlers.pipeHttpRequest(observable); // TODO: add response type
  }

  public signUpByCode(data: IRegByCodeFormData): Observable<ILoginResponseData> {
    const endpoint: string = this.endpoints.signUpByCode();
    const observable = this.http.post<ILoginResponseData>(endpoint, data);
    return this.handlers.pipeHttpRequest(observable);
  }

  public signUpEmployee(data: ISignUpEmployee): Observable<{email: string}> {
    const endpoint: string = this.endpoints.signUpEmployee();
    const observable = this.http.post<{email: string}>(endpoint, data);
    return this.handlers.pipeHttpRequest<{email: string}>(observable);
  }

  public redirectLogoutForSupervisor(url: string) {
    window.open(`${window.location.protocol}//${url}`, '_self');
  }

  public verifyUserKey(key: string): Observable<IConfirmEmailResponse> {
    const observable = this.http.get<IConfirmEmailResponse>(`${this.endpoints.verifyUserKey(key)}`);
    return this.handlers.pipeHttpRequest<IConfirmEmailResponse>(observable, 1, false, false);
  }

  public setPassword(password: string, userKey: string): Observable<void> {
    // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: https://technonikol.atlassian.net/browse/T20S-5852
    const observable = this.http.post<void>(`${this.endpoints.setPassword()}`, { password, user_key: userKey });
    return this.handlers.pipeHttpRequest<void>(observable, 1, false, false);
  }

  public refreshUserKey(key: string): Observable<void> {
    const observable = this.http.get<void>(`${this.endpoints.refreshUserKey(key)}`);
    return this.handlers.pipeHttpRequest<void>(observable, 1, false, false);
  }

  public resetPassword(email: string): Observable<void> {
    const observable = this.http.post<void>(`${this.endpoints.resetPassword()}`, { email });
    return this.handlers.pipeHttpRequest<void>(observable, 1, false, false);
  }

  public confirmEmailChange(key: string): Observable<IConfirmEmailResponse> {
    const observable = this.http.get<IConfirmEmailResponse>(`${this.endpoints.confirmEmailChange(key)}`);
    return this.handlers.pipeHttpRequest<IConfirmEmailResponse>(observable, 1, false, false);
  }

  public getDigitalProfileUrl() {
    const observable = this.http.get<{ url: string }>(`${this.endpoints.getDigitalProfile()}`);
    return this.handlers.pipeHttpRequest<{ url: string }>(observable, 1, false, false);
  }

  public signinForSupervisor(token: string): Observable<ILoginSupervisorResponse> {
    const observable = this.http.get<ILoginSupervisorResponse>(`${this.endpoints.signinSupervisor()}${token}`);
    return this.handlers.pipeHttpRequest<ILoginSupervisorResponse>(observable);
  }

  public signinDigitalProfile(token: string): Observable<ILoginSupervisorResponse> {
    const headers = new HttpHeaders(getAuthorizationHeader(token));
    const observable = this.http.get<ILoginSupervisorResponse>(`${this.endpoints.signinDigitalProfile()}${token}`, { headers });
    return this.handlers.pipeHttpRequest<ILoginSupervisorResponse>(observable);
  }

  public acceptUserAgreement(id: string): Observable<{ id: string }> {
    const observable = this.http.post<{ id: string }>(`${this.endpoints.acceptUserAgreement()}`, { id });
    return this.handlers.pipeHttpRequest<{ id: string }>(observable, 1, false, false);
  }

  public checkEmailExist(email: string): Observable<{ emailExist: boolean }> {
    const observable = this.http.post<{ emailExist: boolean }>(`${this.endpoints.checkEmail()}`, { email });
    return this.handlers.pipeHttpRequest<{ emailExist: boolean }>(observable, 1, false, true);
  }

  public invitationUserStatus(token?: string): Observable<IInviteStatusResponse> {
    const observable = this.http.post<IInviteStatusResponse>(`${this.endpoints.invitationUserStatus()}`, { token });
    return this.handlers.pipeHttpRequest<IInviteStatusResponse>(observable, 1, false, true);
  }

  public invitationUserStatusWithHeaders(token?: string): Observable<IInviteStatusResponse> {
    const url = this.endpoints.getInviteStatusByToken();
    const headers = new HttpHeaders().set('Authorization', 'Token ' + (this.userFacade.currentUser.token ?? ''));
    const options = { headers: headers };

    return this.http.post<IInviteStatusResponse>(url, { token }, { ...options });
  }

  public confirmUserRegistration(token: string): Observable<IConfirmRegistrationResponse> {
    const observable = this.http.post<IConfirmRegistrationResponse>(`${this.endpoints.confirmUserRegistration()}`, { token });
    return this.handlers.pipeHttpRequest<IConfirmRegistrationResponse>(observable, 1, false, false).pipe(
      map(data => {
        const result: IConfirmRegistrationResponse = { ...data };
        if (Boolean(data.userLoginData)) {
          result.userLoginData.role = USER_ROLE[result.userLoginData.role];
        }
        return result;
      }),
    );
  }

  public signupByLink(
    email: string,
    password: string,
    full_name: string,
    phone_number: string,
    inn: string,
    invite_token: string,
  ): Observable<ILoginResponseData> {
    const observable = this.http.post<ILoginResponseData>(`${this.endpoints.signupByLink()}`, {
      email,
      password,
      full_name,
      phone_number,
      inn,
      invite_token,
    });
    return this.handlers.pipeHttpRequest<ILoginResponseData>(observable, 1, false, true);
  }

  public confirmEmailAgain(): Observable<void> {
    const observable = this.http.get<void>(`${this.endpoints.confirmEmailAgain()}`);
    return this.handlers.pipeHttpRequest<void>(observable, 1, false, true);
  }

  public sendAuthenticationCode(authenticationCodeData: IAuthenticationCodeRequestFormData): Observable<ILoginResponseData> {
    const endpoint: string = this.endpoints.sendAuthenticationCode();
    const observable = this.http.post<ILoginResponseData>(endpoint, authenticationCodeData);
    return this.handlers.pipeHttpRequest<ILoginResponseData>(observable);
  }
}
