import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  Component,
  DoCheck,
  ElementRef,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TnFiasFacade } from '@transport/ui-store';
import { debounceTime, filter, map, tap } from 'rxjs/operators';

import { TnBaseMatInputDirective } from '../base-mat-input';
import { IFiasInputDto } from './fias-autocomplete.dto';

const MIN_SEARCH_LENGTH = 2;

@UntilDestroy()
@Component({
  selector: 'transport-fias-autocomplete-input',
  templateUrl: './fias-autocomplete-input.component.html',
  styleUrls: ['./fias-autocomplete-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TnFiasAutocompleteInputComponent,
    },
  ],
})
/* eslint-disable prettier/prettier -- prettier conflicts with eslint (brace style), eslint takes precedence here */
export class TnFiasAutocompleteInputComponent
  extends TnBaseMatInputDirective<IFiasInputDto>
  implements ControlValueAccessor, OnDestroy, DoCheck, MatFormFieldControl<unknown> {
    /* eslint-enable prettier/prettier -- prettier conflicts with eslint in this case (brace style), eslint takes precedence here */
  public static nextId = 0;

  @Input() public scope = '';

  @Input() public tipText = '';

  @Input() public clearInputAfterSelect = false;

  @Output() public readonly loading = new EventEmitter();

  @HostBinding('attr.id')
  public id = `phone-number-input-${(TnFiasAutocompleteInputComponent.nextId += 1)}`;

  public searchInputControl = new FormControl();

  public isLoading = false;

  public selectData$ = this.tnFiasFacade.fiasData$.pipe(
    map(val => {
      const addresses = val[this.scope].addresses;
      if (addresses.length > 0) {
        this.isLoading = false;
        this.loading.emit(this.isLoading);
      }
      return addresses;
    }),
  );

  public readonly options = {
    keydownDelay: 750,
    countSuggestResults: 10,
  };

  public readonly filteredItems$ = this.searchInputControl.valueChanges.pipe(
    debounceTime(this.options.keydownDelay),
    filter(data => typeof data === 'string'),
    tap((value: string) => {
      this.inputValue = void 0;
      if (Boolean(value)) {
        this.isLoading = true;
        this.loading.emit(this.isLoading);
        this.getFiasData(value);
      } else {
        this.value = void 0;
        this.isLoading = false;
        this.loading.emit(this.isLoading);
      }
    }),
  );

  public bindedValue = (value: IFiasInputDto) => {
    return value?.full_address ?? '';
  };

  @HostBinding('class.floating')
  public get shouldLabelFloat() {
    return Boolean(this.tipText) || this.focused || !this.empty;
  }

  public get empty() {
    return !Boolean(this.value) && !Boolean(this.searchInputControl.value);
  }

  constructor(
    public fm: FocusMonitor,
    public elRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl,
    private readonly tnFiasFacade: TnFiasFacade,
  ) {
    super(fm, elRef, ngControl);
  }

  public getFiasData(input: string) {
    this.tnFiasFacade.setFiasData({ addresses: [] }, this.scope);
    this.tnFiasFacade.getFiasData(input, MIN_SEARCH_LENGTH, this.scope);
  }

  public optionSelected(event: MatAutocompleteSelectedEvent) {
    if (!this.clearInputAfterSelect) {
      this.value = event.option.value;
    }
    this.propagateChange(event.option.value);
  }

  public writeValue(value): void {
    this.inputValue = value;
    this.searchInputControl.setValue(value);
  }
}
