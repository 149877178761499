import { createAction, props } from '@ngrx/store';
import { TnMapPoint } from '@transport/ui-interfaces';
import { TnSupportedCountry } from '@transport/ui-pipes';
import { GraphQLError } from 'graphql';

import { IDadataState } from './dadata.state';

export const dadataNamespaceKey = 'Dadata';

export interface IMapPointByAddressPayload {
  address: string;
  country?: TnSupportedCountry;
}

export const getMapPointByAddress = createAction(
  `[${dadataNamespaceKey}] get map point by address`,
  props<{ address: string; country?: string }>(),
);

export const getMapPointByAddressSuccess = createAction(
  `[${dadataNamespaceKey}] get map point by address success`,
  props<{ point: TnMapPoint }>(),
);

export const getMapPointByAddressFailure = createAction(
  `[${dadataNamespaceKey}] get map point by address failure`,
  props<{ error: GraphQLError }>(),
);

export interface IMapPointsByAddressPayload extends IMapPointByAddressPayload {
  countResults?: number;
}

export const getMapPointsByAddress = createAction(
  `[${dadataNamespaceKey}] get map points by address`,
  props<{ payload: IMapPointsByAddressPayload }>(),
);

export const getCityByAddress = createAction(
  `[${dadataNamespaceKey}] get city by address`,
  props<{ payload: IMapPointsByAddressPayload }>(),
);

export interface IMapPointsByCoordinatesPayload {
  lat: number;
  lon: number;
}

export const getMapPointsByCoordinates = createAction(
  `[${dadataNamespaceKey}] get map points by coordinates`,
  props<{ payload: IMapPointsByCoordinatesPayload }>(),
);

export const setDadataData = createAction(`[${dadataNamespaceKey}] set dadata data`, props<{ payload: IDadataState }>());
