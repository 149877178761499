import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { transport } from '@transport/proto';
import { LEGAL_FORM_CODES } from '@transport/ui-interfaces';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';
import { Observable, of } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ITnState } from '../state';
import * as action from './passport.actions';
import * as selector from './passport.selectors';
import { TnPassportService } from './passport.service';
import { IPassportState, PASSPORT_PATH } from './passport.state';

@Injectable({
  providedIn: 'root',
})
export class TnPassportFacade {
  public readonly passport$ = this.store.pipe(select(selector.getPassportState));

  public readonly confirmEmailData$ = this.store.pipe(select(selector.confirmEmailData));

  public readonly title$ = this.store.pipe(select(selector.title));

  public readonly navigation$ = this.store.pipe(select(selector.navigation));

  public readonly step$ = this.store.pipe(select(selector.step));

  public readonly isSignUpState$ = this.store.pipe(select(selector.isSignUpState));

  constructor(
    protected store: Store<ITnState>,
    private readonly passportService: TnPassportService,
    private readonly domainService: TnDomainService,
  ) {}

  public updateState(update: Partial<IPassportState>) {
    return this.store.select(selector.getPassportState).pipe(
      take(1),
      map(state => {
        const payload = { ...state, ...update };
        this.store.dispatch(action.setPassportUiState({ payload }));
        return payload;
      }),
    );
  }


  public setRote(route: PASSPORT_PATH | string) {
    this.store.dispatch(action.setPassportRoute({ route }));
  }

  public getDigitalProfile(id: string | null): Observable<transport.ICarrierUser | null> {
    if (Boolean(id)) {
      return this.passportService.getProfileData(id as string);
    }

    return of(null);
  }

  public getOrganizationByInn(inn: string, legalFormCode: LEGAL_FORM_CODES) {
    return this.passportService.getOrganizationByInn(inn, legalFormCode);
  }

  public allowIndexViewOnly() {
    return this.updateState({
      pathsRestriction: {
        index: false,
        login: true,
        signup: true,
      },
    });
  }

  public allowLoginViewOnly() {
    return this.updateState({
      pathsRestriction: {
        index: true,
        login: false,
        signup: true,
      },
    });
  }

  public allowSignupViewOnly() {
    return this.updateState({
      pathsRestriction: {
        index: true,
        login: true,
        signup: false,
      },
    });
  }

  public setRedirectUrl(url: string) {
    return this.updateState({
      redirectUrlAfterLogin: url,
    });
  }

  public redirectToMarket() {
    // if (this.domainService.domain.includes('lv4.lv3.lv2.localhost')) return;
    // Костыль, надо тянуть из environment
    const localHostPort = this.domainService.domain.includes('lv4.lv3.lv2.localhost') ? ':4300/workspaces' : '/workspaces';
    window.location.href = `${window.location.protocol}//${this.domainService.marketDomain}${localHostPort}`;
  }

  public setAccessRecovery(token, isExpired, email) {
    this.store.dispatch(action.setAccessRecovery({ token, isExpired, email }));
  }

  public setConfirmEmailData(isExpired: boolean, token = '', email = '', newEmail = '') {
    this.store.dispatch(action.setConfirmEmailData({ token, email, newEmail, isExpired }));
  }

  public goForward() {
    this.store.dispatch(action.goForward());
  }

  public goBack() {
    this.store.dispatch(action.goBack());
  }

  public setTitle(title: string) {
    this.store.dispatch(action.setTitle({ title }));
  }

  public setRegistrationTokenExpired() {
    this.store.dispatch(action.setRegistrationTokenExpired());
  }
}
