<div class="body">
  <form [formGroup]="form">
    <div class="flex flex-col gap-2">
      <div class="flex sm:flex-row flex-col gap-2">
        <div class="component-with-label">
          <transport-accreditation-autocomplete
            size="md"
            [control]="$any(form.controls.vehicleId)"
            [placeholder]="'shared.mp.publications.modal.form.vehicle.label' | translate"
            [data]="vehicleData"
            [bindValue]="'id'"
            [bindLabel]="'label'"
            (addClicked)="onOpenModalNewVehicle()"
            (sendOnCheck)="sendVehicleOnCheck($event)"
            [showErrors]="true"
            [required]="true"
            [showEmptyMessage]="true"
            [showAddBtn]="canManageTransportList"
            [loading$]="loading$"
            dataTest="order-transport-vehicle-choose-accr"
          >
          </transport-accreditation-autocomplete>
        </div>
      </div>

      <div class="!mb-0">
        <address-input-kit
          [required]="true"
          class="w-full"
          [placeholder]="'shared.mp.publications.modal.form.placeFrom.placeholder' | translate"
          [label]="'shared.mp.publications.modal.form.placeFrom.label' | translate"
          formControlName="placeFrom"
          [errorToMessage]="validationErrors.bind(this)"
        >
        </address-input-kit>
      </div>
      <!--      Date and time-->
      <div class="flex sm:flex-row flex-col gap-2">
        <common-date-picker
          [formGroup]="form"
          controlName="date"
          class="w-full flex basis-full min-w-full max-w-full sm:basis-[150px] sm:min-w-[150px] sm:max-w-[150px]"
          size="md"
          label=""
          [required]="true"
          [errorToMessage]="validationErrors.bind(this)"
          [placeholder]="'shared.mp.publications.modal.form.placeFrom.date' | translate"
          [dataTest]="'publish-date-time'"
          [allowClear]="true"
          >
        </common-date-picker>
        <div class="flex gap-3 basis-0 sm:basis-full">
          <common-time-input
            [formGroup]="form"
            controlName="timeFrom"
            size="md"
            [placeholder]="'shared.mp.publications.modal.form.placeFrom.timeFrom' | translate"
            [dataTest]="'time-from'"
            [errorToMessage]="validationErrors.bind(this)"
          ></common-time-input>
          <common-time-input
            [formGroup]="form"
            controlName="timeTo"
            class="flex basis-[50%] min-w-[50%] max-w-[50%]"
            size="md"
            [placeholder]="'shared.mp.publications.modal.form.placeFrom.timeTo' | translate"
            [dataTest]="'time-To'"
            [errorToMessage]="validationErrors.bind(this)"
          >
          </common-time-input>
        </div>
      </div>
      <!--      placeTo -->
      <div class="flex sm:flex-row flex-col gap-3">
        <address-input-kit
          [required]="false"
          class="w-full placeholder-black"
          [placeholder]="'shared.mp.publications.modal.form.placeTo.placeholder' | translate"
          [label]="'shared.mp.publications.modal.form.placeTo.label' | translate"
          [showErrors]="false"
          formControlName="placeTo"
        >
        </address-input-kit>
      </div>
      <!--      Цена с ндс-->
      <div class="flex gap-3">
        <transport-tw-input-field
          [control]="$any(form.get('priceForm.price'))"
          [label]="'shared.mp.publications.modal.form.price.label' | translate"
          class="w-full price"
          [showErrors]="true"
          [errorToMessage]="validationErrors.bind(this)"
          [caption]="$any(form.controls.isVatIncluded.value) ? ('owner.order.publishTypeNew.vatHint' | translate: ({value: getVat(), currency: currencySign})) : ''"
        >
          <transport-tw-input-with-select
            [selectControl]="$any(form.get('priceForm.priceType'))"
            [formGroup]="$any(form.controls.priceForm)"
            [options]="options"
            selectorClass="w-20"
          >
            <input
              tw-input
              mask="separator.0"
              thousandSeparator=" "
              [placeholder]="'shared.mp.publications.modal.form.price.placeholder' | translate"
              [formControl]="$any(form.get('priceForm.price'))"
            />
          </transport-tw-input-with-select>
        </transport-tw-input-field>

        <mat-checkbox class="mt-9 min-w-[75px]" formControlName="isVatIncluded" [attr.data-test]="'is-vat-included-12'">
          {{'shared.mp.publications.modal.form.price.withVat' | translate}}
        </mat-checkbox>
      </div>
      <!--      Комментарий-->
      <div class="flex flex-col gap-3">
        <div class="component-with-label gap-1">
          <div class="label">
            {{'shared.mp.publications.modal.form.comment.label' | translate}}
          </div>
          <textarea
            class="w-full h-20 text-md tw-input-block pt-2 pb-2 pl-4 pr-4"
            formControlName="unloadingPlaceDescription"
            [placeholder]="'shared.mp.publications.modal.form.comment.placeholder' | translate"
          ></textarea>
          <div class="font-normal text-xs text-gray-500">{{'shared.mp.publications.modal.form.comment.warning' | translate}}</div>
        </div>
      </div>
      <div class="flex flex-col gap-3 font-normal text-xs text-gray-500">
        <div>{{'shared.mp.publications.modal.form.comment.info' | translate}}</div>
      </div>
    </div>
  </form>
</div>
