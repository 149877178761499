import { transport } from '@transport/proto';

import { createNewCarrierOrganization } from '../organization/organization.interface';
import { DRIVER_GENDER } from './driver-common.interface';

/**
 * Driver interface.
 * This interfaces are needed if protobuf types is not enough,
 * e.g. when entity should have a property with specific string values.
 */
export interface IDriver extends transport.IDriver {
  gender: DRIVER_GENDER;
  namePatronymic?: string;
}

export const createNewDriver = (input: Partial<IDriver> = {}) => {
  input.carrierOrganization = !Boolean(input.carrierOrganization) ? createNewCarrierOrganization() : input.carrierOrganization;
  return transport.Driver.toObject(new transport.Driver(input), {
    defaults: true,
  }) as IDriver;
};
