import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TnAppEnvironment } from '@transport/ui-interfaces';
import { firstValueFrom } from 'rxjs';

@Injectable()
export class TransportConfigService {
  public async loadConfig(env: any, path = 'assets/environment/environment.json') {

    const config = await firstValueFrom(this.http.get<TnAppEnvironment>(path));
    for (const key in config) {
      if (Object.prototype.hasOwnProperty.call(config, key)) {
        env[key] = config[key];
      }
    }

    return config;
  }

  constructor(
    private http: HttpClient
  ) {}
}
