import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { DRIVER_FORM_CONSTANTS, lessThanAdultAgeValidator, moreThanNowValidator, trimmedStringValidator } from '@transport/ui-interfaces';
import { CITIZEN_NUMBERS, TnSupportedCountry } from '@transport/ui-pipes';
import { INN_PHYS_LENGTH, isValidINN, regExpConfig } from '@transport/ui-utils';


export function getDriverForm() {
  return new FormGroup({
    name: new FormControl(null, [Validators.required, trimmedStringValidator]),
    surname: new FormControl(null, [Validators.required, trimmedStringValidator]),
    patronymic: new FormControl(null, []),
    birthday: new FormControl(null, [Validators.required, moreThanNowValidator(), lessThanAdultAgeValidator()]),
    phone: new FormControl(null, [Validators.required, trimmedStringValidator, Validators.pattern(regExpConfig.phoneNumber.LESS_RU)]),
    addPhone: new FormControl(null, [trimmedStringValidator, Validators.pattern(regExpConfig.phoneNumber.LESS_RU)]),
    country: new FormControl({id: TnSupportedCountry.RU}, [Validators.required]),
    iin: new FormControl(null, []),
    inn: new FormControl(null, [innValidator()]),
    agentCarrierId: new FormControl(null),
    passportSeries: new FormControl(
      null,
      Validators.compose([
        Validators.required,
        Validators.maxLength(DRIVER_FORM_CONSTANTS.MAX_PASSPORT_SERIES_LENGTH),
        trimmedStringValidator,
      ]),
    ),
    passportNumber: new FormControl(
      null,
      Validators.compose([
        Validators.required,
        Validators.maxLength(DRIVER_FORM_CONSTANTS.MAX_PASSPORT_NUMBER_LENGTH),
        trimmedStringValidator,
      ]),
    ),
    passportDate: new FormControl(null, [Validators.required, moreThanNowValidator()]),
    passportPlace: new FormControl(null, [trimmedStringValidator]),
    identityCardDate: new FormControl(null, [Validators.required, moreThanNowValidator()]),
    identityCardNumber: new FormControl(
      null,
      Validators.compose([
        Validators.required,
        Validators.maxLength(DRIVER_FORM_CONSTANTS.MAX_PASSPORT_NUMBER_LENGTH),
        trimmedStringValidator,
      ]),
    ),
    identityCardPlace: new FormControl(null, [Validators.required, trimmedStringValidator]),
    licenceDate: new FormControl(null, [Validators.required, moreThanNowValidator()]),
    licencePlace: new FormControl(null, [trimmedStringValidator]),
    licenceNumber: new FormControl(
      null,
      Validators.compose([
        Validators.required,
        Validators.maxLength(DRIVER_FORM_CONSTANTS.MAX_LICENSE_NUMBER_LENGTH),
        trimmedStringValidator,
      ]),
    ),
    licenceSeries: new FormControl(
      null,
      Validators.compose([
        Validators.required,
        Validators.maxLength(DRIVER_FORM_CONSTANTS.MAX_LICENSE_SERIES_LENGTH),
        trimmedStringValidator,
      ]),
    ),
    passportPhoto: new FormGroup({
      mainPage: new FormControl(null),
      registration: new FormControl(null),
    }),
    personalDocTypes: new FormControl(null, [Validators.required]),
  });
}

export function innValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const isValid = validationINN(control?.value);
    if (isValid !== null && !isValid) {
     return { innIncorrectError: true };
    }
    return control?.value && control?.value?.length !== INN_PHYS_LENGTH ? { innError: true } : null
  };
}

export function validationINN(value): boolean | null {
  if (
    Boolean(Number(value)) &&
    // @ts-ignore
    (value.length === INN_PHYS_LENGTH)
  ) {
    return isValidINN(value);
  }
  return null;
}
