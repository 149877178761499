<input
  matInput
  [disabled]="disabled"
  (blur)="onBlur($any($event.target))"
  (input)="doOnChange($any($event.target))"
  [mask]="!!inputValue || !disabled ? mask : ''"
  [patterns]="$any(patterns)"
  [showMaskTyped]="true"
  prefix="+7"
  [dropSpecialCharacters]="false"
  [ngModel]="inputValue"
  [attr.data-test]="dataTest"
/>
