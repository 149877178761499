import { transport } from '@transport/proto';

/**
 * Contact interface.
 * This interfaces are needed if protobuf types is not enough,
 * e.g. when entity should have a property with specific string values.
 */
export const createNewContact = (input?: Partial<transport.IContact>) =>
  transport.Contact.toObject(new transport.Contact(input), {
    defaults: true,
  }) as transport.IContact;

export interface IOrderContact {
  fio: string;
  email?: string | null;
  phoneNumber?: string | null;
}

export type TOrderContact = (transport.IContact & { email: string }) | transport.Order.IOwner | transport.ICarrier | undefined | null;
