import * as fromRouter from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { ITnState } from '../../state/index';
import { IRouterStateUrl, routerFeatureKey } from '../interfaces/router.interfaces';

export const selectRouter = createFeatureSelector<ITnState, fromRouter.RouterReducerState<IRouterStateUrl>>(routerFeatureKey);

const selectRouterState = createSelector(selectRouter, router => router.state);

const selectQueryData = createSelector(selectRouterState, route => route.data);

export const selectQueryParams = createSelector(selectRouterState, route => route.queryParams);
export const selectQueryParam = (param: string) => createSelector(selectQueryParams, params => params[param]);
export const selectRouteParams = createSelector(selectRouterState, route => route.params);
export const selectRouteParam = <T = string>(param: string) => createSelector(selectRouteParams, params => params[param] as T);
export const selectRouteData = (dataPropertyName: string) => createSelector(selectQueryData, data => data[dataPropertyName]);

export const selectUrl = createSelector(selectRouterState, routerState => routerState.url);
