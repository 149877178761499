import { Action, createReducer, on } from '@ngrx/store';

import { formActions } from './form.actions';
import { formInitialState, IFormState } from './form.state';

const createFormReducer = createReducer(
  formInitialState,
  on(formActions.setFormData, (state, { payload }) => ({
    ...state,
    [payload.path]: { ...payload.value },
  })),
);

export function formReducer(state: IFormState, action: Action) {
  return createFormReducer(state, action);
}
