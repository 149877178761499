import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { ACCEPTING_PLACE, ORDER_STATUS, TOAST_DURATION, USER_ROLE } from '@transport/ui-interfaces';
import { TnAnalyticsService, TnConfirmationComponent, TnConfirmationService, TnGqlClientSharedService } from '@transport/ui-store';
import { TranslateService } from '@ngx-translate/core';
import { ACCEPT_ORDER_POPUP_QUERIES } from '@marketplace/shared/modules/take-order-popups/popups/accept-order-popup/accept-order-popup-queries';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, catchError, finalize, of, switchMap, take, tap } from 'rxjs';
import { TnAlertService } from '@transport/ui-kit';
import { IAcceptPopupContext } from '../popup-container/popup.interface';
import { ALERT_STATE, IAlertInputs } from 'libs/transport-ui-kit/src/lib/alert/alert.interfaces';
import { ActivatedRoute, Router } from '@angular/router';
import { AuctionPopupService, AuctionPopupViewEnum } from '../auction-popup/auction-popup.service';
import { MatDialog } from '@angular/material/dialog';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { MAX_LENGTH_200 } from '@transport/ui-utils';
import { CurrencySigns, TnSupportedCurrency } from '@transport/ui-pipes';
import { OwnerPublicOfferService } from 'libs/marketplace-shared/src/lib/services/owner-public-offer.service';
import { WarningTerminationAgreementSignComponent } from '@transport/ui-components';

@UntilDestroy()
@Component({
  selector: 'marketplace-accept-order-popup',
  templateUrl: './accept-order-popup.component.html',
})
export class AcceptOrderPopupComponent implements OnInit, OnDestroy {
  @Input() popupContext!: IAcceptPopupContext;

  @Output() completed = new EventEmitter();

  @ViewChild('counterOfferSuccess') public counterOfferSuccessRef?: TemplateRef<never>;
  @ViewChild('orderSuccess') public orderSuccessRef?: TemplateRef<never>;
  @ViewChild('orderError') public orderErrorRef?: TemplateRef<never>;

  public currentView$ = this.auctionPopup.currentView$.asObservable();

  public disabled = false;

  public loading = new BehaviorSubject(false);

  public queryParams: any = {};
  public popupView = AuctionPopupViewEnum;

  public counterOfferPriceControl = new FormControl(0, [Validators.required, Validators.min(1)]);
  public counterOfferVatControl = new FormControl(true);
  public counterOfferComment = new FormControl(null, Validators.maxLength(MAX_LENGTH_200));

  constructor(
    private readonly sharedGraphQlClient: TnGqlClientSharedService,
    public alertService: TnAlertService,
    public translate: TranslateService,
    public router: Router,
    private activatedRoute: ActivatedRoute,
    private ownerPublicOfferService: OwnerPublicOfferService,
    public auctionPopup: AuctionPopupService,
    public cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    public readonly confirmation: TnConfirmationService,
    private readonly analytics: TnAnalyticsService,
  ) {}

  ngOnInit(): void {
    this.popupContext.status$.pipe(untilDestroyed(this)).subscribe(v => {
      this.cdr.markForCheck();
    });
    this.activatedRoute.queryParams.pipe(untilDestroyed(this)).subscribe(queryParams => (this.queryParams = queryParams));
  }

  ngOnDestroy() {
    this.auctionPopup.currentView$.next(AuctionPopupViewEnum.bet);
  }

  get isDisabled() {
    return this.popupContext.status$.value !== ORDER_STATUS.FREE || this.disabled || this.popupContext.bookingAttemptsLeft === 0;
  }

  get isTaken() {
    return this.popupContext.status$.value === ORDER_STATUS.ASSIGNED;
  }

  get isCancelled() {
    return this.popupContext.status$.value === ORDER_STATUS.CANCELED;
  }

  takeOrder(orderId: string) {
    this.loading.next(true);
    this.analytics.track(`user accepted order`, {payload: this.popupContext.acceptingPlace ?? ACCEPTING_PLACE.MARKET});
    this.sharedGraphQlClient
      .mutate(USER_ROLE.CARRIER, ACCEPT_ORDER_POPUP_QUERIES.acceptOrder, { orderId, acceptingPlace: this.popupContext.acceptingPlace })
      .pipe(
        tap(response => {
          if (response) {
            this.orderSuccessRef &&
              this.alertService.openAlertFromTemplate(this.orderSuccessRef, TOAST_DURATION.LONG, this.popupContext.id);
          }
        }),
        untilDestroyed(this),
        catchError((err: Error) => {
          this.openAlertFailure(err);
          return of(undefined);
        }),
      )
      .subscribe(() => {
        this.loading.next(false);
        this.disabled = true;
        this.completed.emit();
      });
  }

  private reservationTimeDialog(timeToReservationMinutes: number) {
    const days = Math.floor(timeToReservationMinutes / 1440);
    const hour = Math.floor((timeToReservationMinutes % 1440) / 60);
    const min = timeToReservationMinutes % 60;
    const changeTime = `${days || '00'}д, ${hour ? hour : '00'}ч, ${min ? min : '00'}м`;
    const reservationLocalization = `shared.orderPage.managementCard.action.accept.reservationTime.`;
    const dialogRef = this.dialog.open<TnConfirmationComponent>(TnConfirmationComponent, {
      width: '400px',
      data: {
        title: `${reservationLocalization}title`,
        content: this.translate.instant(`${reservationLocalization}content`, {
          time: `${changeTime}`,
        }),
        confirm: `${reservationLocalization}confirm`,
        cancel: `${reservationLocalization}cancel`,
      },
    });
    return dialogRef.afterClosed();
  }

  public accept(orderId: string) {
    const SECONDS_IN_MINUTE = 60;
    const lifeTimeExpiredMinutes = Boolean(this.popupContext.secondsToLifeTimeExpired)
      ? (Number(this.popupContext.secondsToLifeTimeExpired) ?? 0) / SECONDS_IN_MINUTE
      : 0;
    const orderReservationTimeExpiredMinutes = Number(this.popupContext.timeOrderReservation) ?? 0;
    let firstExpiredInterval = 0;
    if (lifeTimeExpiredMinutes > 0 && orderReservationTimeExpiredMinutes > 0) {
      firstExpiredInterval = Math.min(lifeTimeExpiredMinutes, orderReservationTimeExpiredMinutes);
    } else if (lifeTimeExpiredMinutes > 0) {
      firstExpiredInterval = lifeTimeExpiredMinutes;
    } else if (orderReservationTimeExpiredMinutes > 0) {
      firstExpiredInterval = orderReservationTimeExpiredMinutes;
    }

    if (this.popupContext.unsignedTermAgreement) {
      this.dialog.open<WarningTerminationAgreementSignComponent>(WarningTerminationAgreementSignComponent, {
        width: '400px',
        data: this.popupContext.unsignedTermAgreement,
      });
      return;
    }

    if (firstExpiredInterval > 0) {
      this.reservationTimeDialog(firstExpiredInterval)
        .pipe(
          take(1),
          switchMap(value => {
            if (!value) return of(false);
            return this.ownerPublicOfferService.openOwnerPublicOfferDialog(this.popupContext.publicOffer);
          }),
        )
        .subscribe(value => {
          if (value) {
            this.takeOrder(orderId);
          }
        });
    } else {
      this.ownerPublicOfferService
        .openOwnerPublicOfferDialog(this.popupContext.publicOffer)
        .pipe(take(1))
        .subscribe(value => {
          if (value) {
            this.takeOrder(orderId);
          }
        });
    }
  }

  public sendCounterOffer(): void {
    if (this.counterOfferPriceControl.valid && this.counterOfferComment.valid) {
      this.loading.next(true);
      this.ownerPublicOfferService
        .openOwnerPublicOfferDialog(this.popupContext.publicOffer)
        .pipe(
          take(1),
          switchMap(value => {
            if (value) {
              return this.auctionPopup.addOrderCounterOffer(
                {
                  amount: this.counterOfferPriceControl.value,
                  currency: this.popupContext.currency ?? TnSupportedCurrency.RUB,
                  vatIncluded: this.counterOfferVatControl.value,
                  orderId: this.popupContext.id as unknown as number,
                  comment: this.counterOfferComment.value,
                },
                this.popupContext.acceptingPlace,
              );
            }

            return of(null);
          }),
          tap(response => {
            if (response) {
              this.counterOfferSuccessRef &&
                this.alertService.openAlertFromTemplate(this.counterOfferSuccessRef, TOAST_DURATION.LONG, {
                  title: this.translate.instant('shared.mp.counterOfferPopup.counterOfferSuccess'),
                });
            }
          }),
          catchError(err => {
            this.openAlertFailure(err);
            return of(undefined);
          }),
          finalize(() => {
            this.loading.next(false);
            this.auctionPopup.currentView$.next(AuctionPopupViewEnum.bet);
          }),
        )
        .subscribe();
    }
  }

  public goToOrder() {
    this.auctionPopup.$closePopupSubject.next(null);
    this.router.navigate([`/orders/${this.popupContext.id}`], { queryParams: this.queryParams });
  }

  private openAlertFailure(err: Error) {
    this.alertService.openAlert(
      {
        title: this.translate.instant('shared.mp.cargo.table.popup.take.takenErrorTitle'),
        state: ALERT_STATE.ERROR,
        message: err.message,
      } as IAlertInputs,
      TOAST_DURATION.SHORT,
    );
  }

  public get currencySign() {
    return CurrencySigns[this.popupContext.currency ?? TnSupportedCurrency.RUB];
  }

  public priceControlErrorToMessage: (errors: ValidationErrors | null) => string = errors => {
    if (typeof errors?.required !== 'undefined') return this.translate.instant('owner.order.errors.required');
    if (typeof errors?.min !== 'undefined')
      return this.translate.instant('owner.order.errors.lessThanStep', { value: 1, currency: this.currencySign });
    return '';
  };

  public validationErrors(e: ValidationErrors | null) {
    if (e?.maxlength) {
      return this.translate.instant('shared.errors.maxCommentLength', { value: 200 });
    }
    return '';
  }
}
