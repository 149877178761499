import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { passportReducers } from './passport.reducers';

export const PASSPORT_EFFECTS = [];

@NgModule({
  imports: [StoreModule.forFeature('passport', passportReducers), EffectsModule.forFeature(PASSPORT_EFFECTS)],
  exports: [StoreModule, EffectsModule],
})
export class TnPassportStateModule {}
