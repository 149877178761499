import { BehaviorSubject, Subject, takeUntil } from 'rxjs';
import { TnGqlClientSharedService } from '@transport/ui-store';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { VehicleFormVm } from '../vehicle.service';
import { IVehicleFormFields } from '../vehicle.interface';
import { getVehicleForm } from './vehicle-form';
import { readMarketplaceVehicle } from '../vehicle.mapper';
import { VehicleBaseVm } from './vehicle-base-vm';
import { ALERT_STATE, ALERT_TITLE_VARIANT, IAlertInputs } from 'libs/transport-ui-kit/src/lib/alert/alert.interfaces';
import { TnModalAddTransportService } from '../../../../../transport-notifications/src/lib/components/modal-add-transport/modal-add-transport.service';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';
import { USER_ROLE } from '@transport/ui-interfaces';

export class VehicleCreateVm implements VehicleFormVm {
  public baseVm = new VehicleBaseVm(this.sharedGraphQlClient, this.translate, this.domainService, this.role, this.modalAddTransportService);

  public title = this.translate.instant('shared.mp.vehicles.vehicle.title.create');
  public actionBtnTxt = this.translate.instant('shared.mp.vehicles.vehicle.buttons.saveAndAccreditate');
  public vehicle$ = new BehaviorSubject<IVehicleFormFields>(readMarketplaceVehicle(this.baseVm.countries));
  public loading$ = new BehaviorSubject<boolean>(false);
  public finish$ = new Subject<{ close: boolean; alert: IAlertInputs }>();
  public disposed$ = new Subject<void>();

  public form: FormGroup = getVehicleForm();

  constructor(
    private readonly sharedGraphQlClient: TnGqlClientSharedService,
    public readonly translate: TranslateService,
    private readonly modalAddTransportService: TnModalAddTransportService,
    private readonly domainService: TnDomainService,
    public role: USER_ROLE,
  ) {
    if (this.domainService?.subDomain) {
      this.actionBtnTxt = this.translate.instant('shared.mp.vehicles.vehicle.buttons.create');
    }
    this.vehicle$.next(readMarketplaceVehicle(this.baseVm.countries));
  }

  action() {
    this.form.markAllAsTouched();
    this.updateValueAndValidityFileSelectors();
    if (this.form.invalid) return;

    const fields = this.form.getRawValue();
    fields.country = fields.country?.id;
    fields.trailerBodyType = fields.trailerBodyType.id;
    fields.type = fields.type.id;
    fields.ownershipType = fields.ownershipType.id;
    this.loading$.next(true);

    this.baseVm
      .createVehicle(fields)
      .pipe(takeUntil(this.disposed$))
      .subscribe(res => {
        if ((res as { error: string }).error) {
          this.finish$.next({
            close: false,
            alert: {
              message: (res as { error: string }).error,
              state: ALERT_STATE.ERROR,
            },
          });
        } else {
          const message = (res as { submitVehicleRiskProfile: { ok: boolean } }).submitVehicleRiskProfile
            ? this.translate.instant('shared.mp.vehicles.vehicle.messages.createAndAccrSuccess')
            : this.translate.instant('shared.mp.vehicles.vehicle.messages.createSuccess');
          this.finish$.next({
            close: true,
            alert: {
              titleVariant: ALERT_TITLE_VARIANT.NONE,
              message: message,
              state: ALERT_STATE.SUCCESS,
            },
          });
        }
        this.loading$.next(false);
      });
  }

  private updateValueAndValidityFileSelectors() {
    this.form.controls.ownershipTractorType.updateValueAndValidity();
    this.form.controls.tracktorLeasingAgreement.updateValueAndValidity();
    this.form.controls.tracktorRentContract.updateValueAndValidity();
    (this.form.controls.tractorSts as FormGroup).controls.firstSide.updateValueAndValidity();
    (this.form.controls.tractorSts as FormGroup).controls.secondSide.updateValueAndValidity();
    this.form.controls.ownershipTrailerType.updateValueAndValidity();
    this.form.controls.trailerLeasingAgreement.updateValueAndValidity();
    this.form.controls.trailerRentContract.updateValueAndValidity();
    (this.form.controls.trailerSts as FormGroup).controls.firstSide.updateValueAndValidity();
    (this.form.controls.trailerSts as FormGroup).controls.secondSide.updateValueAndValidity();
  }

  dispose() {
    this.disposed$.next();
    this.disposed$.complete();
    this.vehicle$.complete();
    this.loading$.complete();
    this.finish$.complete();
  }
}
