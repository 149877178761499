import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

@Component({
  selector: 'transport-card-section-row',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnCardSectionRowComponent {
  @Input() public label = '';

  @Input() public value?: string | null;

  @Input() public icon?: string;

  @Input() public iconTip?: string;

  @Input() public customRow: TemplateRef<HTMLDivElement> | null = null;

  @Input() public isMultiLine?: boolean;
}
