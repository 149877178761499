<div class="relative">
  <mat-progress-bar *ngIf="vehiclesService.loading$ | async" mode="indeterminate" class="!absolute"></mat-progress-bar>
</div>
<ng-container *ngIf="!(vehiclesService.loading$ | async)">
  <ng-container *ngIf="(vehiclesTableData$ | async)?.length; else noVehicles">
    <marketplace-table
      class="ui-kit vehicles-table"
      [columnConfigs]="columnConfigs"
      (rowClick)="onRowClick($event)"
      [dataSource]="vehiclesTableData$"
      [rowClasses]="rowClasses"
    >
      <marketplace-table-column columnName="tractor">
        <ng-template let-row>
          <div class="flex flex-col">
            <span class="text-gray-700 font-bold text-sm leading-5 flex items-center">{{row.licencePlate}}
              <mat-icon *ngIf="row.blackListVehicle?.message?.length"
                        transportTooltip
                        class="ml-3 !w-5 !h-5 desktop:!w-6 desktop:!h-6"
                        svgIcon="info-outline"
                        [ngClass]="{'!fill-orange-500': !row.blackListVehicle.limitationType, '!fill-red-700': row.blackListVehicle.limitationType}"
                        [containerClass]="'black-list-tooltip'"
                        [context]="{list: row.blackListVehicle.message}"
                        [templateRef]="blackListMsg">
              </mat-icon>
            </span>
            <span class="text-gray-500 font-normal text-sm leading-5">{{row.vehicleBrand}}</span>
          </div>
        </ng-template>
      </marketplace-table-column>

      <marketplace-table-column *ngIf="isAgentOrExpeditor" columnName="agentCarrier">
        <ng-template let-row>
          <div *ngIf="row.agentCarrier"
               class="text-gray-700 text-sm whitespace-nowrap overflow-hidden text-ellipsis"
               transportTooltip
               [context]="{ message: row.agentCarrier?.carrierName + ' (' + (row.agentCarrier?.inn || row.agentCarrier?.bin || row.agentCarrier?.iin) + ')'}">
               {{row.agentCarrier?.carrierName}}({{row.agentCarrier?.inn || row.agentCarrier?.bin || row.agentCarrier?.iin}})
          </div>
        </ng-template>
      </marketplace-table-column>

      <marketplace-table-column columnName="trailer">
        <ng-template let-row>
          <span class="text-gray-700 font-normal text-sm flex items-center">{{row.trailerRegNo}}
            <mat-icon *ngIf="row.blackListTrailer?.message?.length"
                      transportTooltip
                      class="ml-3 !w-5 !h-5 desktop:!w-6 desktop:!h-6"
                      svgIcon="info-outline"
                      [ngClass]="{'!fill-orange-500': !row.blackListTrailer.limitationType, '!fill-red-700': row.blackListTrailer.limitationType}"
                      [containerClass]="'black-list-tooltip'"
                      [templateRef]="blackListMsg"
                      [context]="{list: row.blackListTrailer.message}">
            </mat-icon>
          </span>
        </ng-template>
      </marketplace-table-column>

      <marketplace-table-column columnName="body">
        <ng-template let-row>
          <div class="flex flex-col">
            <span class="text-gray-700 font-normal text-sm leading-5">{{row.bodySubType}}</span>
            <span class="text-gray-500 font-normal text-xs leading-4">{{row.capacity}} т | {{row.volume}} м³</span>
          </div>
        </ng-template>
      </marketplace-table-column>

      <marketplace-table-column columnName="accreditation">
        <ng-template let-row>
          <div *ngIf="!row.isArchived">
            <div class="w-fit h-[18px] px-2 rounded-xl bg-gray-100 flex" *ngIf="row.accrStatus === 'new'">
              <span class="m-auto text-gray-700 font-medium text-xs leading-[14px] whitespace-nowrap">{{'shared.mp.vehicles.table.accreditation.notChecked' | translate}}</span>
            </div>

            <div class="w-fit h-[18px] px-2 rounded-xl bg-orange-100 flex" *ngIf="row.accrStatus === 'pending'">
              <span class="m-auto text-orange-700 font-medium text-xs leading-[14px] whitespace-nowrap">{{'shared.mp.vehicles.table.accreditation.onWork' | translate}}</span>
            </div>

            <div class="flex flex-col" *ngIf="row.accrStatus === 'passed'">
              <div class="w-fit h-[18px] px-2 rounded-xl bg-green-100 flex">
                <span class="m-auto text-green-700 font-medium text-xs leading-[14px] whitespace-nowrap">{{'shared.mp.vehicles.table.accreditation.approved' | translate}}</span>
              </div>
              <!-- <span class="text-gray-700 font-medium text-xs leading-4">Дата: 20.03.2020 13:30</span> -->
            </div>

            <div class="flex flex-col" *ngIf="row.accrStatus === 'not_passed'">
              <div class="w-fit h-[18px] px-2 rounded-xl bg-red-100 flex">
                <span class="m-auto text-red-700 font-medium text-xs leading-[14px] whitespace-nowrap">{{'shared.mp.vehicles.table.accreditation.redjected' | translate}}</span>
                <mat-icon transportTooltip
                          [context]="{ message: row.accrComment }"
                          class="text-red-700 !h-[14px] !w-[14px] !text-[14px] m-auto ml-1">
                          info
                </mat-icon>
              </div>
              <!-- <span class="text-gray-700 font-medium text-xs leading-4 whitespace-nowrap">Дата: 20.03.2020 13:30</span> -->
            </div>
          </div>
        </ng-template>
      </marketplace-table-column>

      <marketplace-table-column columnName="actions">
        <ng-template let-row>
          <div class="flex justify-end">
            <ng-container
              *ngIf="!row.isArchived"
              [ngTemplateOutlet]="rowActionsMenu"
              [ngTemplateOutletContext]="{ $implicit: row }"
            ></ng-container>
            <ng-container
              *ngIf="row.isArchived"
              [ngTemplateOutlet]="rowArchivedActionsMenu"
              [ngTemplateOutletContext]="{ $implicit: row }"
            ></ng-container>
          </div>
        </ng-template>
      </marketplace-table-column>
    </marketplace-table>
    <mat-paginator
      [length]="vehiclesService.total$ | async"
      class="!border-t-0 vehicles-table-paginator"
      fxLayout="row"
      fxLayoutAlign="center"
      pageSize="25"
      [pageIndex]="(vehiclesService.store.pageInfo$ | async)?.pageIndex"
      [pageSizeOptions]="[25, 50, 100]"
      (page)="vehiclesService.changePage($event)"
    ></mat-paginator>
  </ng-container>
</ng-container>

<ng-template #rowActionsMenu let-vehicle>
  <button
    class="!mr-4"
    mat-icon-button
    #menuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="menu"
    (mouseup)="$event.stopPropagation()"
  >
    <mat-icon class="!cursor-pointer text-gray-500 hover:bg-gray-200">more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu" [hasBackdrop]="true" class="vehicles-table !shadow-base">
    <div class="flex flex-col">
      <button
        class="px-3 h-8 flex items-center gap-3 wb-white text-sm text-gray-700 hover:bg-gray-100 active:bg-gray-200"
        (click)="onEdit(vehicle)"
      >
        <mat-icon class="mat-icon-md text-gray-500">edit</mat-icon>
        {{ 'shared.mp.vehicles.table.editButton' | translate }}
      </button>
      <button
        class="px-3 h-8 flex items-center gap-3 wb-white text-sm text-gray-700 hover:bg-gray-100 active:bg-gray-200"
        (click)="sendVechicleToArchive(vehicle)"
      >
        <mat-icon class="mat-icon-md text-gray-500" svgIcon="delete-outlined"></mat-icon>
        {{ 'shared.mp.vehicles.table.deleteButton' | translate }}
      </button>
<!--      <button-->
<!--        *ngIf='isOwner && isNotBlacklisted(vehicle)'-->
<!--        class="px-3 h-8 flex items-center gap-3 wb-white text-sm text-gray-700 hover:bg-gray-100 active:bg-gray-200"-->
<!--        (click)="vehicleToBlackList(vehicle)"-->
<!--      >-->
<!--        <mat-icon class="mat-icon-md text-gray-500" svgIcon="list"></mat-icon>-->
<!--        {{ 'shared.mp.vehicles.table.toBlackListButton' | translate }}-->
<!--      </button>-->
    </div>
  </mat-menu>
</ng-template>

<ng-template #rowArchivedActionsMenu let-vehicle>
  <button
    class="!mr-4"
    mat-icon-button
    #archivedMenuTrigger="matMenuTrigger"
    [matMenuTriggerFor]="archivedMenu"
    (mouseup)="$event.stopPropagation()"
  >
    <mat-icon class="!cursor-pointer text-gray-500 hover:bg-gray-200">more_vert</mat-icon>
  </button>
  <mat-menu #archivedMenu="matMenu" [hasBackdrop]="true" class="vehicles-table !shadow-base">
    <div class="flex flex-col">
      <button
        class="px-3 h-8 flex items-center gap-3 wb-white text-sm text-gray-700 hover:bg-gray-100 active:bg-gray-200"
        (click)="onRestore(vehicle)"
      >
      <mat-icon class="mat-icon-md text-gray-500">restore</mat-icon>
        {{ 'shared.mp.vehicles.table.restoreButton' | translate }}
      </button>
    </div>
  </mat-menu>
</ng-template>

<ng-template #blackListMsg let-list="list">
  <p *ngFor="let item of list; let i = index" class="text-xs">{{i+1}}. {{item?.cargoOwner?.name}}: {{(item?.workProhibited ? 'shared.mp.drivers.blackList.inBlackList' : 'shared.mp.drivers.blackList.hasWarning') | translate}}. {{item?.reason}}</p>
</ng-template>

<ng-template #noVehicles>
  <div class="flex justify-center items-center absolute w-full h-[calc(100vh-120px)]">
    <h2 class="text-gray-300 text-xl">{{ 'shared.mp.vehicles.table.noVehicles' | translate }}</h2>
  </div>
</ng-template>
