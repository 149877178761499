<transport-tw-input-field
  class="items-start w-min"
  [label]="'owner.order.cargoForm.form.dimensions' | translate"
  [control]="$any(formGroup)"
  [required]="required"
  [showErrors]="showErrors"
  [errorToMessage]="errorToMessage"
  [size]="size"
  [errorsSize]="errorsSize"
>
  <transport-tw-input-with-select
    size="sm"
    [selectControl]="$any(dimensionsTypeControl)"
    [formGroup]="$any(formGroup)"
    [rightAddon]="'owner.order.cargoForm.form.dimensionsType' | translate"
  >
    <div class="flex">
      <input
        tw-input
        transportNumberOnly
        autocomplete="off"
        [formControl]="$any(lengthControl)"
        [placeholder]="'owner.order.cargoForm.form.dimensionsLengthlaceholder' | translate"
        [class]="'!w-10 text-center !border-white text-sm tw-input-element-sm'"
        [required]="required"
        [maxlength]="maxlength"
        [attr.data-test]="'dimensions-length-' + dataTest"
      />
      <span class="text-xs font-bold mx-[2px]">x</span>
      <input
        tw-input
        transportNumberOnly
        autocomplete="off"
        [formControl]="$any(widthControl)"
        [placeholder]="'owner.order.cargoForm.form.dimensionsWidthPlaceholder' | translate"
        [class]="'!w-10 text-center !border-white text-sm tw-input-element-sm'"
        [required]="required"
        [maxlength]="maxlength"
        [attr.dataTest]="'dimensions-width-' + dataTest"
      />
      <span class="text-xs font-bold mx-[2px]">x</span>
      <input
        tw-input
        transportNumberOnly
        autocomplete="off"
        [formControl]="$any(heightControl)"
        [placeholder]="'owner.order.cargoForm.form.dimensionsHeightPlaceholder' | translate"
        [class]="'!w-10 text-center !border-white text-sm tw-input-element-sm'"
        [required]="required"
        [maxlength]="maxlength"
        [attr.dataTest]="'dimensions-height-' + dataTest"
      />
    </div>
  </transport-tw-input-with-select>
</transport-tw-input-field>
