<div class="flex flex-col gap-3">
  <div class="flex basis-full justify-between items-center">
    <h5>{{ translationPrefix + header | translate }}</h5>
    <ng-container *ngIf="hasMenu">
      <mat-icon class="card-section-menu__trigger-icon" svgIcon="more-horiz" [matMenuTriggerFor]="menu"></mat-icon>
      <mat-menu #menu="matMenu">
        <div *ngFor="let item of menuItems" mat-menu-item class="card-section-menu__item" (click)="item.action()">
          <mat-icon [svgIcon]="item.icon"></mat-icon>
          <span>{{ item.label | translate }}</span>
        </div>
      </mat-menu>
    </ng-container>
  </div>
  <ng-content select="[message]"></ng-content>
  <div class="flex flex-col gap-1">
    <ng-container *ngFor="let row of rows">
      <ng-container
        *ngIf="isRowVisible(row)"
        [ngTemplateOutlet]="row.customRow !== null ? row.customRow : defaultRow"
        [ngTemplateOutletContext]="{row: row}"
      ></ng-container>
    </ng-container>
  </div>
</div>

<ng-template #defaultRow let-row="row">
  <div class="flex sm:flex-row flex-col basis-full justify-center items-start sm:justify-start">
    <div class="flex basis-1/2 sm:max-w-[50%]">
      <span class="card-section-row__label" [attr.data-test]="'label-'+row.label">{{ getLabelWithPrefix(row.label ?? '') | translate }}</span>
    </div>
    <div class="overflow-hidden text-ellipsis flex basis-1/2 sm:max-w-[50%]" [ngClass]="{'whitespace-nowrap': !row?.isMultiLine}">
      <ng-container *ngIf="row?.icon; then icon; else value"></ng-container>
    </div>
  </div>

  <ng-template #icon>
    <mat-icon
      [svgIcon]="row?.icon ?? ''"
      transportTooltip
      [context]="{ message: getTipMessageWithPrefix(row?.iconTip) | translate }"
    ></mat-icon>
  </ng-template>

  <ng-template #value>
    <span class="card-section-row__value" [attr.data-test]="'value-'+row.label" [title]="row?.value ?? ''">{{ row?.value }}</span>
  </ng-template>
</ng-template>
