import { createFeatureSelector, createSelector } from '@ngrx/store';

import { makeTableSelectors } from '../../table/table-selectors';
import { IProfileState, profileFeatureKey } from '../transport-profile.state';

const getProfileState = createFeatureSelector<IProfileState>(profileFeatureKey);
const subOrganizationsStateSelector = createSelector(getProfileState, (state: IProfileState) => {
  return state.subOrganizations;
});

export const subOrganizationsTableSelectors = makeTableSelectors(subOrganizationsStateSelector);
