import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';

@UntilDestroy()
@Component({
  selector: 'transport-add-select',
  templateUrl: './add-select.component.html',
  styleUrls: ['./add-select.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnAddSelectComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public data: any[] = [];

  @Input() public placeholder = '';

  @Input() public size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';

  @Input() public bindLabel = '';

  @Input() public bindAdditionalLabel = '';

  @Input() public diffrentInputValue = '';

  @Input() public bindValue = '';

  @Input() public dataTest = '';

  @Input() public required = false;

  @Input() public labelInside = false;

  @Input() public showErrors = true;

  @Input() public showEmptyMessage = false;

  @Input() public errorsSize: 'xs' | 'sm' | 'md' | 'lg' = 'sm';

  @Input() public allowClear: boolean = false;

  @Input() public control!: FormControl;

  @Input() public showAddBtn = true;

  @Output() public readonly addClicked = new EventEmitter();

  public visibleData: any[] = [];

  @ViewChild('searchRef', { static: false }) searchRef;

  @ViewChild(MatAutocompleteTrigger) trigger!: MatAutocompleteTrigger;

  constructor(private translate: TranslateService, private zone: NgZone) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.visibleData = this.data;
      if (Boolean(this.control.value)) {
        this.setValueInput(this.control.value);
        this.lastSelectedValue = this.control.value;
      }
    }
  }

  public ngOnInit(): void {
    this.visibleData = this.data;
    this.control.valueChanges.subscribe(id => {
      if (id) {
        this.setValueInput(id);
        this.control.markAsDirty();
        this.control.markAsTouched();
      }
    });
  }

  public ngAfterViewInit() {
    if (Boolean(this.control.value)) {
      this.setValueInput(this.control.value);
    }
  }

  public inputFocused = false;

  public setValueInput(value) {
    const bindValue = Boolean(value[this.bindValue]) ? value[this.bindValue] : value;
    const selectedObj = this.data?.find(el => el[this.bindValue] === bindValue);
    if (Boolean(selectedObj) && this.searchRef?.nativeElement) {
      this.searchRef.nativeElement.value = this.diffrentInputValue ? selectedObj[this.diffrentInputValue] : selectedObj[this.bindLabel];
      this.searchRef.nativeElement.blur();
    }
  }

  public clearSelect(e): void {
    e.stopPropagation();
    this.control.setValue(null);
    this.searchRef.nativeElement.value = null;
  }

  public displayFn(value?: number) {
    return Boolean(value) ? this.getLabel(this.data.find(elem => this.getValue(elem) === value)) : '';
  }

  public addItem() {
    this.trigger.closePanel();
    this.searchRef.nativeElement.blur();
    this.addClicked.emit();
  }

  public getLabel(item) {
    return item[this.bindLabel];
  }

  public getAdditionalLabel(item) {
    return item[this.bindAdditionalLabel];
  }

  public getValue(item) {
    return item[this.bindValue];
  }

  private lastSelectedValue?: string;

  public onItemSelected(event) {
    this.lastSelectedValue = event.option.value;
    this.control.setValue(event.option.value);
  }

  public isSelected(item) {
    return this.getValue(item) === this.lastSelectedValue;
  }

  public onBlur() {
    this.control.markAsTouched();
    this.inputFocused = false;
    if (this.lastSelectedValue) {
      const option = this.data.find(d => d[this.bindValue] === this.lastSelectedValue);
      if (option) {
        this.searchRef.nativeElement.value = this.diffrentInputValue ? option[this.diffrentInputValue] : option[this.bindLabel];
      }
    }
  }

  public onClickArrow() {
    this.inputFocused ? this.searchRef.nativeElement.blur() : this.searchRef.nativeElement.focus();
  }

  public searching(event) {
    const value = event.target.value.toLowerCase();
    this.visibleData = this.data.filter(
      elem => this.getLabel(elem)?.toLowerCase()?.includes(value) || this.getAdditionalLabel(elem)?.toLowerCase()?.includes(value),
    );
  }

  public clickAtInsideLabel() {
    this.searchRef.nativeElement.focus();
    // It doesn't want to open autocomplete panel at the same time with focus event.
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.trigger.openPanel();
      }, 0);
    });
  }

  public errorToMessage = (errors: ValidationErrors | null) => {
    if (errors?.required) {
      return this.translate.instant('shared.errors.required');
    }
    if (errors?.noTimezone) {
      return this.translate.instant('shared.errors.orderPlaceNoTimezone');
    }
    return '';
  };
}
