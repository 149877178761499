import { gql } from 'apollo-angular';
import { transport } from '@transport/proto';

export const AUCTION_POPUP_QUERIES = {
  placeBet: gql`
    mutation PlaceBet($bet: Int!, $orderId: ID!, $comment: String, $acceptingPlace: OrderAcceptingPlaceEnum, $additionalCriteriaData: [AuctionCriteriaInput])
    {
      placeBet(bet: $bet, orderId: $orderId, comment: $comment, acceptingPlace: $acceptingPlace, additionalCriteriaData: $additionalCriteriaData) {
        id
        order {
          status
          price {
            amount
            currency
          }
          lot {
            auctionStatus
            startPrice
            lastBet
            minStepPrice
            endDatetime
            viewEndDatetime
            isLastBetMine
            isLastBetByMyOrganization
          }
        }
      }
    }
  `,
};

interface IAddBet {
  bet: number;
  id: string;
}

export interface IAuctionPopupResponse {
  lot: transport.ILot | null;
}
