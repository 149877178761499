import { Pipe, PipeTransform } from '@angular/core';

export enum TABLE_CELL_PIPE_TYPE {
  HAS_RESIZER = 'has-resizer',
  HEADER_CELL_CLASS = 'header-cell-class',
}

@Pipe({
  name: 'cell',
})
export class TnTableCellPipe implements PipeTransform {
  public transform(field: string, { type }: { type: TABLE_CELL_PIPE_TYPE }): boolean | string {
    switch (type) {
      case TABLE_CELL_PIPE_TYPE.HAS_RESIZER:
        return field !== 'actions' && field !== 'selectors';
      case TABLE_CELL_PIPE_TYPE.HEADER_CELL_CLASS:
        return field === 'actions' ? 'table-column-settings' : field === 'selectors' ? 'table-column-header-selectors' : '';
    }
  }
}
