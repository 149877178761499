<div class="navbar-container" *ngIf="(menuState$ | async) as menuState">
  <div
    class="navbar"
    [ngClass]="{'!w-12': menuState === 'out'}"
  >
    <transport-company-logo
      class="transport-logo-wrap flex items-center justify-start"
      [ngClass]="{'disabled': disabledLogo, '!m-0': menuState === 'out'}"
      (click)="goToSubdomainTable()"
      [application]="application"
      [isTonna]="isTonna"
      [menuState$]="menuState$"
    >
    </transport-company-logo>
  </div>
</div>

<ng-content select="[banner]"></ng-content>
