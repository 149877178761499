import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';

import { setConfirmationChoice } from '../confirmation.actions';
import { IConfirmationTextData } from '../confirmation.interface';

@Component({
  selector: 'transport-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnConfirmationComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<TnConfirmationComponent, boolean>,
    private readonly translate: TranslateService,
    private readonly store: Store,
    @Inject(MAT_DIALOG_DATA) public textData: IConfirmationTextData,
  ) {
    this.setTextData();
  }

  /**
   * Confirm action
   */
  public confirm(): void {
    const confirmClosure = true;
    this.store.dispatch(setConfirmationChoice({ payload: { confirmClosure } }));
    this.dialogRef.close(confirmClosure);
  }

  /**
   * Cancel action
   */
  public cancel(): void {
    const confirmClosure = false;
    this.store.dispatch(setConfirmationChoice({ payload: { confirmClosure } }));
    this.dialogRef.close(confirmClosure);
  }

  /**
   * Sets text data.
   */
  private setTextData(): void {
    const pref = 'shared.orders.confirmation.default.';
    const defaultValues: IConfirmationTextData = {
      title: this.translate.instant(`${pref}title`),
      content: this.translate.instant(`${pref}content`),
      confirm: this.translate.instant(`${pref}confirm`),
      cancel: this.translate.instant(`${pref}cancel`),
      contentParams: '',
      markdown: this.textData.markdown ?? false,
    };
    const { contentParams, markdown, ...textData } = this.textData;
    this.textData = !Boolean(textData)
      ? defaultValues
      : Object.keys(textData).reduce((acc, key) => {
          const translateParams = this.getContentParams(key, contentParams);
          const translation = this.translate.instant(textData[key], translateParams) ?? textData[key];
          const value = !Boolean(translation) ? defaultValues[key] : translation;

          acc[key] = value;
          return acc;
        }, defaultValues);
  }

  private getContentParams(key: string, contentParams?: string) {
    if (Boolean(contentParams) && key === 'content') {
      return { value: contentParams };
    }
    return contentParams;
  }
}
