import { TnAppEnvironment } from '@transport/ui-interfaces';

export interface IFeatureAccessState {
  environment: TnAppEnvironment;
  apiBaseUrl: string;
  featureFlags: Record<string, boolean | undefined>;
}

export const featureAccessInitialState: IFeatureAccessState = {
  environment: new TnAppEnvironment(),
  apiBaseUrl: '',
  featureFlags: {},
};
