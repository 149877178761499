import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { adminFeatureName, adminReducers } from './admin.state';
import { TnAllPermissionsEffects } from './user/effects/all-permissions.effects';
import { TnOwnerUserEffects } from './user/effects/owner-user.effects';

export const ADMIN_EFFECTS = [TnOwnerUserEffects, TnAllPermissionsEffects];

@NgModule({
  imports: [StoreModule.forFeature(adminFeatureName, adminReducers), EffectsModule.forFeature(ADMIN_EFFECTS)],
  exports: [StoreModule, EffectsModule],
})
export class TnAdminStoreModule {}
