// eslint-disable-next-line eslint-comments/disable-enable-pair -- Shut up ESLint!
/* eslint-disable @typescript-eslint/naming-convention -- There it's more convenient */

import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { AbstractControl } from '@angular/forms';

import { TnTwInputDirective } from './tw-input.directive';

@Component({
  selector: 'transport-tw-input',
  templateUrl: './tw-input.component.html',
  styleUrls: ['./tw-input.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- Cuz no ways to detect [control] touched and validity status.
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TnTwInputComponent implements AfterContentInit {
  @HostBinding('class.tw-input-block') public get _tw_input_block_no_size() {
    return !this.size;
  }

  @HostBinding('class.tw-input-block-sm') public get _tw_input_block_sm_size() {
    return this.size === 'sm';
  }

  @HostBinding('class.tw-input-block-md') public get _tw_input_block_md_size() {
    return this.size === 'md';
  }

  @HostBinding('class.tw-input-block-lg') public get _tw_input_block_lg_size() {
    return this.size === 'lg';
  }

  @HostBinding('class.tw-input-block-error') public get error() {
    return this.control ? this.control.invalid && this.control.touched : false;
  }

  @HostBinding('class.tw-input-block-disabled') public get disabled() {
    return this.control ? this.control.disabled : false;
  }

  @ContentChild(TnTwInputDirective) public input?: TnTwInputDirective;

  @Input() public control?: AbstractControl;

  @Input() public styleInput = true;

  @Input() public size?: 'xs' | 'sm' | 'md' | 'lg';

  @Input() public leftAddon = '';

  @Input() public rightAddon = '';

  @Input() public inputType = 'text';

  @Input() public showPassword = false;

  @Output() public readonly showPasswordClick = new EventEmitter();

  public get classSizeAddon() {
    return this.size ? '-' + this.size : '';
  }

  public ngAfterContentInit() {
    if (this.styleInput) {
      this.input?.el.nativeElement.classList.add(`tw-input-element${this.classSizeAddon}`);
    }
  }

  public clickShowPassword(event) {
    this.showPasswordClick.emit(event);
  }

  public isPasswordType() {
    return this.inputType === 'password';
  }
}
