import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { transport } from '@transport/proto';
import { TIMEOUT } from '@transport/ui-utils';
import { of } from 'rxjs';
import { catchError, debounceTime, map, switchMap, tap } from 'rxjs/operators';

import { TnConfirmationService } from '../../../../confirmation/confirmation.service';
import { TnToasterFacade, TOAST_TYPE } from '../../../../toaster';
import { TnCarrierDirectoryDriversApiService } from '../../drivers-table/carrier-directory-drivers-api.service';
import {
  removeDriver,
  removeDriverFailure,
  removeDriverSuccess,
  searchAgentCarrier,
  searchAgentCarrierSuccess,
  setChangedDriverStatus,
} from '../actions/driver.actions';
import { TnDriverFacade } from '../driver-state.facade';
import { TnDriverBaseEffects } from './driver-base.effects';

@Injectable({
  providedIn: 'root',
})
export class TnDriverEffects extends TnDriverBaseEffects {
  public operationFail = createEffect(() =>
    this.actions$.pipe(
      ofType(removeDriverFailure),
      tap(({ error }) => {
        //retrow error for handling by global error handler
        throw error;
      }),
    ),
  );

  public readonly detailRemoveDriver = createEffect(() =>
    this.actions$.pipe(
      ofType(removeDriver),
      switchMap(({ id, name }) =>
        super.removeDriver(id).pipe(
          map(() => {
            this.toastFacade.showMessage({ code: 'shared.driver.toasts.remove.success', params: { name } }, TOAST_TYPE.SUCCESS);
            return removeDriverSuccess();
          }),
          catchError((error: Error) => {
            this.toastFacade.showMessage({ code: 'shared.driver.toasts.remove.error', params: { name } }, TOAST_TYPE.ERROR);
            return of(removeDriverFailure({ error }));
          }),
        ),
      ),
    ),
  );

  // public readonly changeVerificationStatus = createEffect(() =>
  //   this.actions$.pipe(
  //     ofWebsocketTopicAndAction('driver', ['verification_status_changed']),
  //     switchMap(storeAction => {
  //       // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: tech debt
  //       const { id, verification_status } = storeAction.eventData.payload;
  //       return of(
  //         setChangedDriverStatus({
  //           changedDriverStatus: {
  //             id,
  //             verificationStatus: verification_status,
  //           },
  //         }),
  //       );
  //     }),
  //   ),
  // );

  public readonly searchAgentCarrier = createEffect(() =>
    this.actions$.pipe(
      ofType(searchAgentCarrier),
      debounceTime(TIMEOUT.SHORT),
      switchMap(({ term }) => {
        return this.driverFacade
          .getAgentCarriersByName(term)
          .pipe(map((agentCarriers: transport.IAgentCarrier[]) => searchAgentCarrierSuccess({ agentCarriers })));
      }),
    ),
  );

  constructor(
    public readonly actions$: Actions,
    public readonly confirmation: TnConfirmationService,
    public readonly driverDirectoryService: TnCarrierDirectoryDriversApiService,
    private readonly toastFacade: TnToasterFacade,
    private readonly driverFacade: TnDriverFacade,
  ) {
    super(actions$, confirmation, driverDirectoryService);
  }
}
