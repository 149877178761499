import { createSelector } from '@ngrx/store';

import { ITnState } from '../state/index';
import { formInitialState, IFormState } from './form.state';

const getFormValue = (state: ITnState) => state.form;

const formData = createSelector(getFormValue, (state: IFormState) => state);

const formState = (path: keyof typeof formInitialState) => createSelector(getFormValue, (state: IFormState) => state[path]);

export const formSelectors = {
  formData,
  formState,
};
