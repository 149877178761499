import { createFeatureSelector, createSelector } from '@ngrx/store';
import {
  DOCUMENT_STATUS,
  DOCUMENT_TYPE,
  IOrderAttachment,
  ORDER_SIGNING_WAY,
  ORDER_STATUS,
  TnOrderAttachmentGroup,
  VEHICLE_TYPE_PROPERTY,
} from '@transport/ui-interfaces';
import moment from 'moment';

import { IOrdersState, ordersTableFeatureName } from '../orders-table.state';
import { IOrderDetailsCarrierState } from '../reducers/order-details-carrier.reducer';
import { TnCryptoSelectors } from './tn-crypto-document.selectors';

const getOrdersState = createFeatureSelector<IOrdersState>(ordersTableFeatureName);

export const orderDetailsCarrierStateSelector = createSelector(getOrdersState, (state: IOrdersState) => state.orderDetailsCarrier);

export const orderSelector = createSelector(orderDetailsCarrierStateSelector, (state: IOrderDetailsCarrierState) => state.order);

export const orderNomenclature = createSelector(orderSelector, order => order?.cargoNomenclatureTypes);

export const orderStatus = createSelector(orderSelector, order => order?.status);

export const orderOwner = createSelector(orderSelector, order => order?.owner);

export const cargoBodyTypesSelector = createSelector(
  orderDetailsCarrierStateSelector,
  (state: IOrderDetailsCarrierState) => state.cargoBodyTypes,
);

export const orderContractSelector = createSelector(orderSelector, order => order?.carrierContract);

export const carrierContractsSelector = createSelector(
  orderDetailsCarrierStateSelector,
  (state: IOrderDetailsCarrierState) => state.carrierContracts,
);

export const packagingTypesSelector = createSelector(
  orderDetailsCarrierStateSelector,
  (state: IOrderDetailsCarrierState) => state.packagingTypes,
);

const isCompletedOrderSelector = createSelector(orderSelector, order => order?.status === ORDER_STATUS.COMPLETED);

export const allDocumentTypesSelector = createSelector(
  orderDetailsCarrierStateSelector,
  isCompletedOrderSelector,
  (state: IOrderDetailsCarrierState, isCompletedOrder: boolean) =>
    state.allDocumentTypes.filter(item => !isCompletedOrder || item?.innerName !== DOCUMENT_TYPE.WAYBILL),
);

export const isFormDirtySelector = createSelector(
  orderDetailsCarrierStateSelector,
  (state: IOrderDetailsCarrierState) => state.isFormDirty,
);

export const isDriverAndVehicleChangeTimeUp = createSelector(
  orderSelector,
  order =>
    // time to change VEHICLE/Driver is up, but order status is not updated on server
    order?.orderAttributes?.driverAndVehicleChangeTimeLeft !== null &&
    order?.orderAttributes?.driverAndVehicleChangeTimeLeft !== void 0 &&
    order?.orderAttributes?.driverAndVehicleChangeTimeLeft === 0,
);

export const isDriverAndVehicleChanging = createSelector(
  orderSelector,
  order =>
    Boolean(order?.orderAttributes?.isDriverAndVehicleChanging) && !Boolean(order?.orderAttributes?.isDriverAndVehicleChangeTimerOnPause),
);

export const isReadonlyOrderSelector = createSelector(
  orderSelector,
  isDriverAndVehicleChanging,
  isDriverAndVehicleChangeTimeUp,
  (order, isDVChanging, isDVChangeTimeUp) =>
    Boolean(order?.isReadonly) ||
    // time to change VEHICLE/Driver is up, but order status is not updated on server
    (isDVChanging && isDVChangeTimeUp),
);

export const contractBlankSelector = createSelector(orderDetailsCarrierStateSelector, (state: IOrderDetailsCarrierState) => {
  const files = state.displayedDocuments
    .filter(item => item.documentType?.innerName === 'contract_form')
    .map(item => ({
      ...item,
      signRequired: false,
    }));
  const res: TnOrderAttachmentGroup = {
    attachments: files,
    documentType: files[0]?.documentType,
  };
  return [res];
});

export const driverAndVehicleChangeDateTime = createSelector(
  orderDetailsCarrierStateSelector,
  (state: IOrderDetailsCarrierState): string => {
    if (Boolean(state.order?.orderAttributes?.driverAndVehicleChangeTimeLeft)) {
      return moment().add(state.order?.orderAttributes?.driverAndVehicleChangeTimeLeft, 'minutes').toDate().toString();
    }
    return '';
  },
);

function isDocumentSignChecking(
  signingWay: ORDER_SIGNING_WAY | undefined,
  documentActualStatus: string,
  item: IOrderAttachment,
  docInnerName: string,
): boolean {
  const res =
    signingWay === ORDER_SIGNING_WAY.TN_CRYPTO &&
    documentActualStatus === DOCUMENT_STATUS.CARGO_OWNER_SIGNATURE_CHECKING &&
    item.documentType.innerName === docInnerName;
  return res;
}

export const contractDocumentSelector = createSelector(orderDetailsCarrierStateSelector, (state: IOrderDetailsCarrierState) => {
  const result = state.displayedDocuments.find(item => {
    const documentActualStatus = state.order?.documentActualStatus?.find(status => status.documentType?.innerName === 'contract');
    return (
      item.documentType?.innerName === 'contract' &&
      !isDocumentSignChecking(state.order?.signingWay, documentActualStatus?.status ?? '', item, 'contract')
    );
  });
  return result;
});

export const terminationAgreementDocumentSelector = createSelector(orderDetailsCarrierStateSelector, (state: IOrderDetailsCarrierState) => {
  const result = state.displayedDocuments.find(item => {
    const documentActualStatus = state.order?.documentActualStatus?.find(status => status.documentType?.innerName === 'term_agreement');
    return (
      item.documentType?.innerName === 'term_agreement' &&
      !isDocumentSignChecking(state.order?.signingWay, documentActualStatus?.status ?? '', item, 'term_agreement')
    );
  });
  return result;
});

export const selectedDriverSelector = createSelector(
  orderDetailsCarrierStateSelector,
  (state: IOrderDetailsCarrierState) => state.selectedDriver,
);

export const selectedSecondDriverSelector = createSelector(
  orderDetailsCarrierStateSelector,
  (state: IOrderDetailsCarrierState) => state.selectedSecondDriver,
);

export const selectedVehicleSelector = createSelector(
  orderDetailsCarrierStateSelector,
  (state: IOrderDetailsCarrierState) => state.selectedVehicle,
);

export const selectedAgentCarrierSelector = createSelector(
  orderDetailsCarrierStateSelector,
  (state: IOrderDetailsCarrierState) => state.selectedAgentCarrier,
);

export const blackListDriver = createSelector(
  orderDetailsCarrierStateSelector,
  (state: IOrderDetailsCarrierState) => state.blackListDriver,
);

export const blackListVehicle = createSelector(
  orderDetailsCarrierStateSelector,
  (state: IOrderDetailsCarrierState) => state.blackListVehicle,
);

export const freightSelector = createSelector(orderDetailsCarrierStateSelector, (state: IOrderDetailsCarrierState) => state.freight);

export const freightErrorSelector = createSelector(
  orderDetailsCarrierStateSelector,
  (state: IOrderDetailsCarrierState) => state.freightError,
);

export const attractedSelector = createSelector(
  orderDetailsCarrierStateSelector,
  (state: IOrderDetailsCarrierState) => state.attractedTruck,
);

export const isAttractedSelectorAndFreightNotEmpty = createSelector(attractedSelector, freightSelector, (attracted, freight) => {
  return attracted === VEHICLE_TYPE_PROPERTY.ATTRACTED ? Boolean(Number(freight)) : true;
});

export const isSelectedVehicleAndDriverNotEmpty = createSelector(
  selectedDriverSelector,
  selectedVehicleSelector,
  (driver, vehicle) => driver && vehicle,
);

export const isTnCryptoTypeSigningSelector = createSelector(orderSelector, order => order?.signingWay === ORDER_SIGNING_WAY.TN_CRYPTO);

export const orderUnloadingPlacesSelector = createSelector(orderSelector, order => order?.unloadingPlaces);

export const orderLoadingPlacesSelector = createSelector(orderSelector, order => order?.loadingPlaces);

export const isLoadingSelector = createSelector(orderDetailsCarrierStateSelector, (state: IOrderDetailsCarrierState) => state.isLoading);

export const isFirstLoadingSelector = createSelector(
  orderDetailsCarrierStateSelector,
  (state: IOrderDetailsCarrierState) => state.isFirstLoading,
);

export const isWaybillRequired = createSelector(
  orderDetailsCarrierStateSelector,
  (state: IOrderDetailsCarrierState) => state.order?.waybillRequired,
);

class TnCryptoOrderDetailsSelectors extends TnCryptoSelectors {
  public getOrder = orderSelector;
}

export const tnCryptoOrderDetails = new TnCryptoOrderDetailsSelectors().selectors();
