import { AUCTION_CRITERION_CHOICES } from '@transport/ui-interfaces';
import moment from 'moment';

export interface IPublishOrderFormValue {
  minStepPrice: number;
  publishType: ORDER_PUBLISH_TYPE_NEW;
  dateTime: string | null | undefined;
  price: number | null | undefined;
  currency: string | null | undefined;
  isVatIncluded: boolean | null | undefined;
  allowCounterOffer: boolean | null | undefined;
  carrierGroupId: string | null | undefined;
  carrier: string | null | undefined;
  carrierAllAccredited: boolean | undefined;
  isMarket: boolean;
  fromFirstBet?: boolean | null | undefined;
  procedureDuration?: number | null;
  lifetimeAfterFirstBet?: number | null;
  additionalAuctionCriteria?: {type: AUCTION_CRITERION_CHOICES | null, weight: number | null}[] | null;
}

export enum ORDER_PUBLISH_TYPE_NEW {
  AUCTION = 'AUCTION',
  QUICK_ORDER = 'QUICK_ORDER',
  SET_CARRIER = 'SET_CARRIER',
}
