import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { transport } from '@transport/proto';
import { createNewUser, IUiSettings, IUser, REGISTRATION_STATUS, USER_ROLE } from '@transport/ui-interfaces';
import { getObjectProperty } from '@transport/ui-utils';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { selectUrl } from '../ngrx-router/selectors/router.selectors';
import { ITnState } from '../state/index';
import * as currentUserAction from './current-user.actions';
import * as selector from './current-user.selectors';
import { TnUserPermissionsMap } from './user-permission.interface';
import { TnCarrierUserPermissionsMap } from './user-permission-carrier.interface';
import { TnOwnerUserPermissionsMap } from './user-permission-owner.interface';
import INotificationSettingsOverallType = transport.UserProfile.UserSettings.INotificationSettingsOverallType;
import IUserProfile = transport.IUserProfile;
import IEditProfilePasswordInput = transport.IEditProfilePasswordInput;
import ITimezone = transport.ITimezone;

@Injectable({
  providedIn: 'root',
})
export class TnCurrentUserFacade {
  public readonly loading$ = this.store.pipe(select(selector.getLoadingState));

  public readonly hasServerError$ = this.store.pipe(select(selector.getServerErrorState));

  public readonly permissions$ = this.store.pipe(select(selector.getPermissions));

  public readonly profile$ = this.store.pipe(select(selector.getCurrentUserProfile));

  public readonly warningTextBanner$ = this.store.pipe(select(selector.warningTextBanner));

  public readonly notificationSettings$ = this.store.pipe(select(selector.getCurrentUserNotificationSettings));

  private readonly userSubject = new BehaviorSubject(createNewUser());

  public readonly user$ = this.userSubject.asObservable();

  private readonly isLoggedInSubject = new BehaviorSubject<string>('');

  public readonly isLoggedIn$ = this.isLoggedInSubject.asObservable();

  private readonly permissionsSubject = new BehaviorSubject(
    new TnUserPermissionsMap() as TnCarrierUserPermissionsMap & TnOwnerUserPermissionsMap,
  );

  public readonly permissionsSubject$ = this.permissionsSubject.asObservable();

  private readonly uiSettingsSubject = new BehaviorSubject<IUiSettings>({});

  public readonly uiSettings$ = this.uiSettingsSubject.asObservable();

  public currentRoute$ = this.store.select(selectUrl);


  public canFinishAuction$ = new BehaviorSubject(false);

  constructor(protected store: Store<ITnState>, private readonly actions$: Actions) {
    void this.store.select(selector.getCurrentUserAuctionParameters).subscribe(value => {
      this.canFinishAuction$.next(value?.earlyFinish || false);
    });
    void this.store.select(selector.getCurrentUser).subscribe(value => {
      this.userSubject.next(value);
    });
    void this.store.select(selector.isLoggedIn).subscribe(value => {
      this.isLoggedInSubject.next(value ?? '');
    });
    void this.store.select(selector.getPermissions).subscribe(value => {
      this.permissionsSubject.next(value as TnCarrierUserPermissionsMap & TnOwnerUserPermissionsMap);
    });
    void this.store.select(selector.uiSettings).subscribe(value => {
      this.uiSettingsSubject.next(value ?? {});
    });
  }

  public get isRegistered() {
    return this.userSubject.value.organization?.isRegistered ?? true;
  }

  public isAllowedMarket(organization: transport.ICarrierOrganization | null | undefined) {
    const { marketAttributes } = organization ?? {};
    const { organizationCheck, vehicleCheck, driverCheck, esignCheck } = marketAttributes ?? {};
    return (
      Boolean(organizationCheck === REGISTRATION_STATUS.APPROVED) &&
      Boolean(vehicleCheck === REGISTRATION_STATUS.APPROVED) &&
      Boolean(driverCheck === REGISTRATION_STATUS.APPROVED) &&
      Boolean(esignCheck === REGISTRATION_STATUS.APPROVED)
    );
  }

  public get isAccessRequestMarket() {
    const isAccessRequest = this.userSubject.value.organization?.accessRequested;
    return Boolean(isAccessRequest);
  }

  public get isTmsOrganization() {
    const isTms = this.userSubject.value.organization?.hasTmsAccess;
    return Boolean(isTms);
  }

  public get hasOnlyMarketAccess() {
    return !Boolean(this.userSubject.value.organization?.hasTmsAccess) && Boolean(this.userSubject.value.organization?.hasMarketAccess);
  }

  public get currentUserRole() {
    return this.userSubject.value.role;
  }

  public get currentUser() {
    return this.userSubject.value;
  }

  // permissionName = 'organization.read'
  public hasPermissionStatic(permissionName: string): boolean {
    return Boolean(getObjectProperty(permissionName, this.currentUserPermissions as Record<string, unknown>));
  }

  public get currentUserPermissions() {
    return this.permissionsSubject.value;
  }

  public get currentUserUiSettings() {
    return this.uiSettingsSubject.value;
  }

  public get isOldOrderPageDesignSupported() {
    return JSON.parse(window.localStorage.getItem('isOldOrderPageDesignSupported') ?? 'false');
  }

  public toggleOrderPageDesignSupport() {
    Boolean(this.isOldOrderPageDesignSupported)
      ? this.store.dispatch(currentUserAction.resetOldOrderPageDesignSupport())
      : this.store.dispatch(currentUserAction.setOldOrderPageDesignSupport());
  }

  public restoreUserOnInit() {
    this.store.dispatch(currentUserAction.restoreUserAction());
  }

  public updateUserOrganization(organization: transport.IAdminOrganization) {
    this.store.dispatch(currentUserAction.updateUserOrganization({ organization }));
  }
  public updateUserProfileOrganization(profile: transport.IUserProfile) {
    this.store.dispatch(currentUserAction.updateUserProfileOrganization({ profile }));
  }

  public saveUser(newValues: Partial<IUser & { uiSettings: IUiSettings }>) {
    if (Boolean(newValues.token)) {
      newValues.uiSettings = newValues.uiSettings ?? {};
    }
    this.store.dispatch(currentUserAction.saveUserAction({ user: newValues }));
  }

  public resetUserAuthToken() {
    this.resetUser();
    // this.saveUser({});
  }

  public resetUser() {
    this.store.dispatch(currentUserAction.resetUserAction());
    // this.userSubject.next(createNewUser());
    this.isLoggedInSubject.next('');
    this.permissionsSubject.next(new TnUserPermissionsMap() as TnCarrierUserPermissionsMap & TnOwnerUserPermissionsMap);
  }

  public isUserRole(role: USER_ROLE): boolean {
    return this.currentUserRole === role;
  }

  public hasPermission(permissionName: string): Observable<boolean> {
    return this.permissions$.pipe(map(permissions => Boolean(getObjectProperty(permissionName, permissions as Record<string, unknown>))));
  }

  public setUiSettings(key: string, settings) {
    const newSettings = { ...this.uiSettingsSubject.value };
    newSettings[key] = settings;
    this.store.dispatch(
      currentUserAction.setUiSettingsAction({
        user: this.userSubject.value,
        uiSettings: newSettings,
      }),
    );
  }

  public editUserName(user: IUserProfile): void {
    this.store.dispatch(currentUserAction.editUserName({ user, userRole: this.currentUserRole }));
  }

  public editUserPhone(user: IUserProfile): void {
    this.store.dispatch(currentUserAction.editUserPhone({ user, userRole: this.currentUserRole }));
  }
  public editUserCountry(user: IUserProfile): void {
    this.store.dispatch(currentUserAction.editUserCountry({ user, userRole: this.currentUserRole }));
  }

  public setDocumentsSettings(facsimileImg: string | null): void {
    this.store.dispatch(currentUserAction.setDocumentsSettingsSuccess({ facsimileImg }));
  }

  public updateNotificationSettings(notificationSettings: INotificationSettingsOverallType) {
    this.store.dispatch(
      currentUserAction.setNotificationSettings({
        user: this.userSubject.value,
        notificationSettings,
      }),
    );
  }
}
