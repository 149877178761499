<div class="drivers-page-container bg-white m-4 rounded-lg shadow-md">
  <div class="flex py-3 px-4 justify-between align-middle">
    <marketplace-filters-toolbar data-test="filters-toolbar"></marketplace-filters-toolbar>
    <button class="tw-button-solid-sm" *ngIf="canManage" (click)="this.createDriver()">
      <mat-icon svgIcon="plus"></mat-icon>
      {{ 'shared.orders.action.create.title' | translate }}
    </button>
  </div>
  <div>
    <drivers-table [canManage]="canManage"></drivers-table>
  </div>
</div>
