<div class="verification-modal__wrapper">
  <ng-container *ngIf="!isSend; else notification">
    <div class="verification-modal__header hidden px-6 pb-3 border-b-2 border-b-gray-200 ">
      <h2 class="not-italic font-medium text-lg text-gray-700">{{ 'shared.mp.verification.infostep.header' | translate }}</h2>
      <button class="h-6" (click)="close()">
        <mat-icon class="rounded-full text-gray-400 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200">close</mat-icon>
      </button>
    </div>
    <mat-stepper #stepper linear="false" (selectionChange)="onStepChange($event)" *ngIf="infoStepWasWatched; else infoStep">
      <mat-step class="verification-modal__step" [stepControl]="contactsFG">
        <ng-template matStepLabel>
          <div [className]="contactsFG.valid && contactsFG.touched ? 'label-container active' : 'label-container'">
            <div class="line"></div>
            <div class="title">
              <span class="step">
                {{'shared.mp.verification.stepper.step' | translate: ({value: 1})}}
              </span>
              <span class="sub-title">
                {{ 'shared.mp.verification.stepper.contacts' | translate }}
              </span>
            </div>
          </div>
        </ng-template>
        <div class="content__wrapper">
          <div class="content">
            <verification-contacts-form [form]="contactsFG"></verification-contacts-form>
          </div>

          <verification-modal-footer class="verification-modal-footer" (close)="close()">
            <button class="button-begin tw-button-solid-sm" (click)="stepperNext(contactsFG)" matStepperNext>
              {{ 'shared.mp.verification.buttons.next' | translate }}
            </button>
          </verification-modal-footer>
        </div>
      </mat-step>

      <mat-step [stepControl]="organizationFG">
        <ng-template matStepLabel>
          <div [className]="organizationFG.valid && organizationFG.touched ? 'label-container active' : 'label-container'">
            <div class="line"></div>
            <div class="title">
              <span class="step">
                {{'shared.mp.verification.stepper.step' | translate: ({value: 2})}}
              </span>
              <span class="sub-title">
                {{ 'shared.mp.verification.stepper.organization' | translate }}
              </span>
            </div>
          </div>
        </ng-template>
        <div class="content__wrapper">
          <div class="content">
            <verification-organization-form [form]="organizationFG"></verification-organization-form>
          </div>

          <verification-modal-footer class="verification-modal-footer" (close)="close()">
            <button class="back-button tw-button-outline-sm" (click)="stepperPrevious()" matStepperPrevious>
              <mat-icon>arrow_back</mat-icon>
              <span>{{ 'shared.mp.verification.buttons.back' | translate }}</span>
            </button>
            <button class="button-begin tw-button-solid-sm" (click)="stepperNext(organizationFG)" matStepperNext>
              {{ 'shared.mp.verification.buttons.next' | translate }}
            </button>
          </verification-modal-footer>
        </div>
      </mat-step>

      <mat-step [stepControl]="vehicleFG">
        <ng-template matStepLabel>
          <div [className]="vehicleFG.valid && vehicleFG.touched ? 'label-container active' : 'label-container'">
            <div class="line"></div>
            <div class="title">
              <span class="step"> {{'shared.mp.verification.stepper.step' | translate: ({value: 3})}} </span>
              <span class="sub-title">
                {{ 'shared.mp.verification.stepper.vehicle' | translate }}
              </span>
            </div>
          </div>
        </ng-template>
        <div class="content__wrapper">
          <div class="content">
            <verification-vehicle-form [form]="vehicleFG"></verification-vehicle-form>
          </div>

          <verification-modal-footer class="verification-modal-footer" (close)="close()">
            <button class="back-button tw-button-outline-sm" (click)="stepperPrevious()" matStepperPrevious>
              <mat-icon>arrow_back</mat-icon>
              <span>{{ 'shared.mp.verification.buttons.back' | translate }}</span>
            </button>
            <button class="button-begin tw-button-solid-sm" (click)="stepperNext(vehicleFG)" matStepperNext>
              {{ 'shared.mp.verification.buttons.next' | translate }}
            </button>
          </verification-modal-footer>
        </div>
      </mat-step>

      <mat-step>
        <ng-template matStepLabel>
          <div class="label-container">
            <div class="line"></div>
            <div class="title">
              <span class="step"> {{'shared.mp.verification.stepper.step' | translate: ({value: 4})}} </span>
              <span class="sub-title">
                {{ 'shared.mp.verification.stepper.sending' | translate }}
              </span>
            </div>
          </div>
        </ng-template>
        <div class="content__wrapper">
          <div class="content">
            <verification-sending-form [form]="sendingFG"></verification-sending-form>
          </div>

          <verification-modal-footer class="verification-modal-footer" (close)="close()">
            <button class="back-button tw-button-outline-sm" (click)="stepperPrevious()" matStepperPrevious>
              <mat-icon>arrow_back</mat-icon>
              <span>{{ 'shared.mp.verification.buttons.back' | translate }}</span>
            </button>
            <button class="button-begin tw-button-solid-sm" (click)="save()">
              {{ 'shared.mp.verification.buttons.send' | translate }}
            </button>
          </verification-modal-footer>
        </div>
      </mat-step>
    </mat-stepper>
  </ng-container>

  <ng-template #notification>
    <div class="content__wrapper">
      <marketplace-notification></marketplace-notification>

      <verification-modal-footer [showContinue]="false">
        <button class="button-begin tw-button-outline-sm" (click)="close()">
          {{ 'shared.mp.verification.buttons.close' | translate }}
        </button>
      </verification-modal-footer>
    </div>
  </ng-template>

  <ng-template #infoStep>
    <div class="content__wrapper info-step__wrapper">
      <marketplace-info-step>
        <button class="h-6" (click)="close()">
          <mat-icon class="rounded-full text-gray-400 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200">close</mat-icon>
        </button>
      </marketplace-info-step>
      <verification-modal-footer [showContinue]="false">
        <button class="button-begin tw-button-solid-sm" (click)="infoWasWatched()">
          {{ 'shared.mp.verification.buttons.begin' | translate }}
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </verification-modal-footer>
    </div>
  </ng-template>
</div>
