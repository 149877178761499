<ng-template #dialogRef>
  <transport-filter-dialog [hasChanges]="hasChanges" (cancel)="cancel()" (clearAll)="clearAll()" (apply)="apply()">
    <span title>{{ 'carrier.driverFilter.title' | translate }}</span>
    <form content [formGroup]="filterForm" class="flex flex-col">
      <mat-form-field data-test="driver-fio">
        <input
          transportClearInput
          matInput
          type="text"
          placeholder="{{ 'carrier.driverFilter.form.fio' | translate }}"
          formControlName="fio"
        />
      </mat-form-field>

      <mat-form-field data-test="driver-phone">
        <input
          transportClearInput
          matInput
          type="text"
          placeholder="{{ 'carrier.driverFilter.form.phone' | translate }}"
          formControlName="phoneNumber"
          [mask]="mask"
          [patterns]="$any(patterns)"
          [validation]="false"
        />
      </mat-form-field>
    </form>
  </transport-filter-dialog>
</ng-template>

<transport-button
  label="{{ 'shared.orders.table.filters.title' | translate }}"
  icon="filters"
  type="action-flat"
  [counter]="activatedFiltersCount"
  (action)="openDialog()"
></transport-button>
