import { NavigationExtras } from '@angular/router';
import { createAction, props } from '@ngrx/store';

import { routerFeatureKey } from '../interfaces/router.interfaces';

export const routerGo = createAction(
  `[${routerFeatureKey}] Go`,
  props<{
    path: (string | number | boolean)[];
    query?: Record<string, unknown>;
    extras?: NavigationExtras;
  }>(),
);

export const routerBack = createAction(`[${routerFeatureKey}] Back`);

export const routerForward = createAction(`[${routerFeatureKey}] Forward`);

export const routerToExternal = createAction(`[${routerFeatureKey}] External`, props<{ url: string; howOpen: string }>());

export const RouterActions = {
  routerBack,
  routerForward,
  routerGo,
  routerToExternal,
};
