import { Injectable } from '@angular/core';
import { Actions, createEffect } from '@ngrx/effects';
import { USER_ROLE } from '@transport/ui-interfaces';
import { tap } from 'rxjs/operators';

import { TnCurrentUserFacade } from '../../current-user/current-user.facade';
import { REFRESH_TYPE } from '../../table/table-state.interface';
import { TnOrdersTableCarrierFacade } from '../facades/orders-table-carrier.facade';
import { TnOrdersTableOwnerFacade } from '../facades/orders-table-owner.facade';

@Injectable({
  providedIn: 'root',
})
export class TnOrdersTableEffects {
  // public readonly updateOrdersTable = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofWebsocketTopicAndAction('order', ['order_list_changed']),
  //       tap(() => {
  //         if (this.currentUserFacade.isUserRole(USER_ROLE.CARRIER)) {
  //           this.ordersCarrierFacade.setRefreshState(REFRESH_TYPE.FORCED);
  //         } else {
  //           this.ordersOwnerFacade.setRefreshState(REFRESH_TYPE.FORCED);
  //         }
  //       }),
  //     ),
  //   { dispatch: false },
  // );

  // public readonly updateDraftOrdersTable = createEffect(
  //   () =>
  //     this.actions$.pipe(
  //       ofWebsocketTopicAndAction('draft_order', ['draft_order_list_changed']),
  //       tap(() => this.ordersOwnerFacade.setRefreshState(REFRESH_TYPE.FORCED)),
  //     ),
  //   { dispatch: false },
  // );

  constructor(
    public readonly actions$: Actions,
    private readonly ordersCarrierFacade: TnOrdersTableCarrierFacade,
    private readonly ordersOwnerFacade: TnOrdersTableOwnerFacade,
    private readonly currentUserFacade: TnCurrentUserFacade,
  ) {}
}
