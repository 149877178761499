import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { FormBuilder, FormControl } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TnCustomNgNeatReactiveFormControlDirective } from '@transport/ui-directives';
import { DRIVER_FORM_CONSTANTS, IDriverForm, TVehicle } from '@transport/ui-interfaces';
import { TnSupportedCountry } from '@transport/ui-pipes';
import { createDriverFormGroup } from '@transport/ui-store';
import { getTranslateParams, regExpConfig } from '@transport/ui-utils';
import { of } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'transport-driver-form',
  templateUrl: './driver-form.component.html',
  styleUrls: ['./driver-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnDriverFormComponent extends TnCustomNgNeatReactiveFormControlDirective<IDriverForm> implements OnInit {
  @Input() public isDisabled = false;

  @Input() public isUseMatStep = false;

  @Input() public mode = 'create';

  @Input() public countries?: { code: string; name: string }[] = [];

  @Input() public vehicleTypes: TVehicle[] = [];

  @Input() public isBorder = true;

  @Input() public usesDriverMobile?: boolean | null;

  @Input() public isShowInvite = false;

  @Input() public dataTest = '';

  @Input() public hasBeenInvitedToDriverMobile?: boolean | null;

  @Output() public readonly inviteDriver = new EventEmitter();

  public readonly regExpConf = regExpConfig;

  public limitations = getTranslateParams<typeof DRIVER_FORM_CONSTANTS>(DRIVER_FORM_CONSTANTS);

  public get isDriverCountryRussia() {
    return this.form?.controls.country.value === TnSupportedCountry.RU;
  }

  public get inviteDriverText() {
    return `carrier.directory.driver.form.${Boolean(this.hasBeenInvitedToDriverMobile) ? 'inviteAgain' : 'invite'}`;
  }

  constructor(
    protected readonly fb: FormBuilder,
    @Optional() @Self() public controlDir: NgControl,
    private readonly changeDetector: ChangeDetectorRef,
  ) {
    super(fb);
    if (Boolean(controlDir)) {
      controlDir.valueAccessor = this;
    }
  }

  public ngOnInit() {
    this.controlDir.control?.setValidators([this.validate.bind(this)]);
    this.controlDir.control?.updateValueAndValidity();
    this.changeDetector.detectChanges();

    this.form.disabledWhile(of(this.isDisabled));

    void this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.onChange(value);
    });

    if (Boolean(this.controlDir.control)) {
      void (this.controlDir.control as FormControl).touch$.pipe(untilDestroyed(this)).subscribe(touch => {
        if (Boolean(touch)) {
          this.form.markAllAsTouched();
          this.changeDetector.detectChanges();
        }
      });
    }
  }

  public clearVehicleId() {
    this.form.patchValue(
      {
        vehicleId: null,
      },
      { emitEvent: false },
    );
  }

  public validate(): ValidationErrors | null {
    return this.form.invalid ? { driverInfo: true } : null;
  }

  public writeValue(data: IDriverForm): void {
    if (Boolean(data)) {
      this.form.patchValue(data, {
        emitEvent: false,
      });
    }
  }

  public invite() {
    this.inviteDriver.emit();
  }

  protected createFormGroup() {
    return createDriverFormGroup(this.fb);
  }

  public onBlurInput(controlName: string) {
    const name = 'licence' + controlName.charAt(0).toUpperCase() + controlName.slice(1);
    const value = this.form.controls[controlName].value;
    this.form.controls[name].setValue(value);
  }

}
