import { Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroupDirective } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Store } from '@ngrx/store';
import { debounceTime, take, tap } from 'rxjs/operators';

import { ITnState } from '../../../state';
import { formActions } from '../../form.actions';
import { IFormStateError } from '../../form.state';

const defaults = {
  formDebounce: 50,
};

@UntilDestroy()
@Directive({
  selector: '[transportConnectForm]',
})
export class TnConnectFormDirective implements OnInit, OnDestroy {
  @Input() public transportConnectForm?: string;

  @Input() public debounce = defaults.formDebounce;

  @Input() public clearFormStateOnDestroy = false;

  constructor(private readonly formGroupDirective: FormGroupDirective, private readonly store: Store<ITnState>) {}

  private getFormErrorsArray(): IFormStateError[] {
    const result: IFormStateError[] = [];
    const keys = Object.keys(this.formGroupDirective.form.controls);
    for (const key of keys) {
      const control: AbstractControl = this.formGroupDirective.form.controls[key];
      const errorsObj: IFormStateError = {
        controlName: key,
        errors: control.errors ? [control.errors] : [],
      };
      if (errorsObj.errors.length > 0) {
        result.push(errorsObj);
      }
    }
    return result;
  }

  public ngOnInit(): void {
    void this.store
      .select(state => state.form[this.transportConnectForm ?? ''])
      .pipe(
        take(1),
        tap(formValue => {
          this.formGroupDirective.form.patchValue(formValue);
        }),
      )
      .subscribe();

    void this.formGroupDirective.form.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(this.debounce),
        tap(changes => {
          const errors = this.getFormErrorsArray();
          const value = { controls: { ...changes }, errors };
          this.store.dispatch(
            formActions.setFormData({
              payload: { path: this.transportConnectForm ?? '', value },
            }),
          );
        }),
      )
      .subscribe();
  }

  public ngOnDestroy(): void {
    if (this.clearFormStateOnDestroy) {
      this.store.dispatch(
        formActions.resetFormData({
          payload: { path: this.transportConnectForm ?? '' },
        }),
      );
    }
  }
}
