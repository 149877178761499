import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { IConfirmationTextData } from './confirmation.interface';
import { TnConfirmationComponent } from './dialog/confirmation.component';

/**
 * Confirmation service.
 */
@Injectable({
  providedIn: 'root',
})
export class TnConfirmationService {
  constructor(private readonly dialog: MatDialog) {}

  /**
   * Opens confirmation dialog while forming textual data using provided prefix.
   * @param prefixForDialogStrings prefix for dialog strings
   */
  public openByPrefix(prefixForDialogStrings: string, width?: string, contentParams?: string) {
    const pref = `${prefixForDialogStrings}.`;

    const textData: IConfirmationTextData = {
      title: `${pref}title`,
      content: `${pref}content`,
      confirm: `${pref}confirm`,
      cancel: `${pref}cancel`,
      contentParams: contentParams ?? '',
      markdown: false,
    };

    return this.open(textData, width);
  }

  /**
   * Opens confirmation dialog.
   * @param data textual data
   */
  public open(data?: IConfirmationTextData, width?: string, contentParams?: string) {
    return this.dialog
      .open<TnConfirmationComponent, IConfirmationTextData, boolean>(TnConfirmationComponent, {
        width,
        data,
      })
      .afterClosed();
  }
}
