import { createAction, props } from '@ngrx/store';
import { ICarrierOrganization } from '@transport/ui-interfaces';

export const loadCarrierFilterList = createAction('[CarrierFilterList] Load Carrier List');

export const loadCarrierFilterListSuccess = createAction(
  '[CarrierFilterList] Load Carrier List Success',
  props<{ list: Partial<ICarrierOrganization>[] }>(),
);

export const loadCarrierFilterListFailure = createAction('[CarrierFilterList] Load Carrier List Failure', props<{ error: Error }>());
