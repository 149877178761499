import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Directive, DoCheck, forwardRef, Host, HostBinding, Input, OnInit, Optional, Self } from '@angular/core';
import { FormControl, FormGroupDirective, NgControl, NgForm } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatFormFieldControl } from '@angular/material/form-field';
import { NgSelectComponent } from '@ng-select/ng-select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Observable, Subject } from 'rxjs';
import { debounceTime, pluck, switchMap, tap } from 'rxjs/operators';

import { TnNgSelectErrorStateMatcher } from './ng-select-error-state-matcher';

const ASYNC_INPUT_DEBOUNCE = 500;

@UntilDestroy()
@Directive({
  selector: '[transportNgSelectMat], ng-select[transportNgSelectMat]',
  providers: [
    {
      provide: MatFormFieldControl,
      // eslint-disable-next-line @angular-eslint/no-forward-ref -- TODO: tech debt
      useExisting: forwardRef(() => TnNgSelectFormFieldControlDirective),
    },
  ],
})
export class TnNgSelectFormFieldControlDirective implements MatFormFieldControl<unknown>, DoCheck, OnInit {
  public static nextId = 0;

  @HostBinding('attr.id')
  public id = `ng-select-mat-${(TnNgSelectFormFieldControlDirective.nextId += 1)}`;

  public controlType = 'ng-select-mat';

  public focused = false;

  public inputDisabled = false;

  public inputPlaceholder = '';

  public inputRequired = false;

  @HostBinding('attr.aria-describedby')
  public describedBy = '';

  public touched = false;

  public errorState = false;

  private readonly stateChanges$ = new Subject<void>();

  public readonly stateChanges = this.stateChanges$.asObservable();

  private readonly defaultErrorStateMatcher: ErrorStateMatcher = new TnNgSelectErrorStateMatcher(this);

  private inputValue?: string | Record<string, unknown>;

  @Input() public errorStateMatcher?: ErrorStateMatcher;

  @Input() public asyncItemsFn?: (input: string) => Observable<string | Record<string, unknown>[]>;

  constructor(
    @Host() @Optional() private readonly host: NgSelectComponent,
    @Optional() @Self() public ngControl: NgControl | null,
    @Optional() private readonly parentForm: NgForm,
    @Optional() private readonly parentFormGroup: FormGroupDirective,
  ) {
    void host.focusEvent
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.focused = true;
        this.stateChanges$.next();
      });
    void host.blurEvent
      .asObservable()
      .pipe(untilDestroyed(this))
      .subscribe(() => {
        this.focused = false;
        this.stateChanges$.next();
      });
  }

  @Input()
  public get value() {
    return this.inputValue;
  }

  public set value(value) {
    this.inputValue = value;
    this.stateChanges$.next();
  }

  @Input()
  public get placeholder(): string {
    return this.inputPlaceholder;
  }

  public set placeholder(value: string) {
    this.inputPlaceholder = value;
    this.stateChanges$.next();
  }

  public get empty(): boolean {
    return (
      !this.host.hasValue &&
      (typeof this.inputValue === 'undefined' ||
        this.inputValue === null ||
        (Array.isArray(this.inputValue) && this.inputValue.length === 0))
    );
  }

  public get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input()
  public get required(): boolean {
    return this.inputRequired;
  }

  public set required(value: boolean) {
    this.inputRequired = coerceBooleanProperty(value);
    this.stateChanges$.next();
  }

  @Input()
  public get disabled(): boolean {
    return Boolean(this.ngControl) ? this.inputDisabled : this.host.disabled;
  }

  public set disabled(value: boolean) {
    this.inputDisabled = coerceBooleanProperty(value);
    this.stateChanges$.next();
  }

  /**
   * Checks if asyncItemsFn is provided, and takes action.
   */
  private useAsyncItemsFunction() {
    /**
     * If asyncItemsFn is provided we use it as source of items
     */
    if (typeof this.asyncItemsFn !== 'undefined') {
      const asyncItemsFn = this.asyncItemsFn;
      const setItems = items => {
        this.host.itemsList.setItems((this.host.items = items));
      };

      const setLoading = (value: boolean) => {
        this.host.loading = value;
        this.host.detectChanges();
        return value;
      };

      void this.host.searchEvent
        .pipe(
          tap(() => {
            setLoading(true);
            setItems([]);
          }),
          untilDestroyed(this),
          debounceTime(ASYNC_INPUT_DEBOUNCE),
          pluck('term'),
          switchMap(term => asyncItemsFn(term)),
        )
        .subscribe(
          res => {
            setItems(res);
            setLoading(false);
          },
          () => setLoading(false),
        );

      void this.host.closeEvent.pipe(untilDestroyed(this)).subscribe(() => {
        setItems([]);
        setLoading(false);
      });
    }
  }

  public ngOnInit() {
    this.useAsyncItemsFunction();

    if (this.ngControl !== null) {
      this.inputValue = this.ngControl.value;
      this.inputDisabled = this.ngControl.disabled ?? false;
      if (this.ngControl.statusChanges !== null) {
        void this.ngControl.statusChanges.pipe(untilDestroyed(this)).subscribe(state => {
          const disabled = state === 'DISABLED';
          if (disabled !== this.inputDisabled) {
            this.disabled = disabled;
          }
        });
      }
      if (this.ngControl.valueChanges !== null) {
        void this.ngControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
          this.inputValue = value;
          this.host.detectChanges();
          this.stateChanges$.next();
        });
      }
    } else {
      void this.host.changeEvent
        .asObservable()
        .pipe(untilDestroyed(this))
        .subscribe(value => {
          this.inputValue = value;
          this.host.detectChanges();
          this.stateChanges$.next();
        });
    }
  }

  public ngDoCheck() {
    // We need to re-evaluate this on every change detection cycle, because there are some
    // error triggers that we can't subscribe to (e.g. parent form submissions). This means
    // that whatever logic is in here has to be super lean or we risk destroying the performance.
    this.updateErrorState();
    // T20S-1299 Workaround for apply disabled state when form recreated and new form value assigned to formGroup.
    // Without this code setDisabledState does not fired.
    // Example: view order detail page -> copy detail page -> back to view detail page
    if (this.ngControl !== null) {
      if (this.ngControl.valueAccessor?.setDisabledState) {
        this.ngControl.valueAccessor.setDisabledState(this.ngControl.disabled ?? false);
      }
      this.disabled = this.ngControl.disabled ?? false;
    }
  }

  public updateErrorState() {
    const oldState = this.errorState;
    const parent = this.parentFormGroup ?? this.parentForm;
    const matcher = this.errorStateMatcher ?? this.defaultErrorStateMatcher;
    const control =
      this.ngControl === null
        ? null
        : this.ngControl.control !== null && typeof this.ngControl?.control !== 'undefined'
        ? (this.ngControl.control as FormControl)
        : null;
    const newState = control !== null ? matcher?.isErrorState(control, parent) : false;
    if (newState !== oldState) {
      this.errorState = newState ?? false;
      this.stateChanges$.next();
    }
  }

  public setDescribedByIds(ids: string[]): void {
    this.describedBy = ids.join(' ');
  }

  public onContainerClick(event: MouseEvent): void {
    const target = event.target as HTMLElement;
    if (target.classList.contains('mat-form-field-infix')) {
      this.host.focus();
      this.host.open();
    }
  }
}
