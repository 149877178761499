export interface IAlertInputs {
  titleVariant?: ALERT_TITLE_VARIANT;
  title?: string;
  state?: ALERT_STATE;
  variant?: ALERT_VARIANT;
  message?: string;
}

export enum ALERT_TITLE_VARIANT {
  TOP = 'top',
  LEFT = 'left',
  NONE = 'none',
}

export enum ALERT_STATE {
  INFO = 'info',
  WARNING = 'warning',
  SUCCESS = 'success',
  ERROR = 'error',
}

export enum ALERT_VARIANT {
  LEFT_ACCENT = 'left-accent',
  SOLID = 'solid',
  SUBTLE = 'subtle',
}
