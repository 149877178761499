import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable } from 'rxjs';
import { IStoragePointGuide } from 'libs/order/order-shared/src/lib/interfaces/guides.interfaces';
import { POINT_TYPE, TnGqlClientSharedService } from '@transport/ui-store';
import { readStoragePoint } from 'libs/order/order-shared/src/lib/mappers/guide.mappers';
import { USER_ROLE } from '@transport/ui-interfaces';
import { GUIDE_QUERIES } from 'libs/order/owner-order/src/lib/queries/guide.queries';

@UntilDestroy()
@Injectable()
export class StoragePointService {
  private storagePoints$ = new BehaviorSubject<IStoragePointGuide[]>([]);

  loadingSP$: Observable<IStoragePointGuide[]> = this.storagePoints$.pipe(
    map(points => points.filter(p => p.actions.includes(POINT_TYPE.LOADING)).map(readStoragePoint)),
  );
  unloadingSP$: Observable<IStoragePointGuide[]> = this.storagePoints$.pipe(
    map(points => points.filter(p => p.actions.includes(POINT_TYPE.UNLOADING)).map(readStoragePoint)),
  );

  constructor(private gql: TnGqlClientSharedService) {
    this.fetchStoragePoints();
  }

  public fetchStoragePoints() {
    this.gql
      .query(USER_ROLE.OWNER, GUIDE_QUERIES.getStoragePoints, 1)
      .pipe(untilDestroyed(this))
      .subscribe((data: any) => {
        const storagePoints = data?.storagePoints?.map(readStoragePoint) || [];
        this.storagePoints$.next(storagePoints);
      });
  }

  public addStoragePoint(point: IStoragePointGuide) {
    this.storagePoints$.next([...this.storagePoints$.value, point]);
  }
}
