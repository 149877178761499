import { transport } from '@transport/proto';

import { USER_PERMISSION_CODE } from './user-common.interface';

/**
 * User permission interface.
 * This interfaces are needed if protobuf types is not enough,
 * e.g. when entity should have a property with specific string values.
 */
export interface IUserPermission extends transport.User.IPermission {
  codename: USER_PERMISSION_CODE;
}

export const createUserPermission = (input?: Partial<IUserPermission>) =>
  transport.User.Permission.toObject(new transport.User.Permission(input), {
    defaults: true,
  }) as IUserPermission;
