import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { PartnersService } from '@marketplace-app/market-sidebar/partners/partners.service';
import { BUSINESS_RELATIONSHIP } from '@marketplace-app/cargo/table/cargo-table.interfaces';
import {
  initialPartnersCheckPopup,
  IPartnersCheckPopupContent,
  IPartnersCheckPopupContext,
  STATUS_ICON_COLORS,
} from '../popup-container/popup.interface';

@Component({
  selector: 'marketplace-partner-check-popup',
  templateUrl: './partner-check-popup.component.html',
  styleUrls: ['./partner-check-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [PartnersService],
})
export class PartnerCheckPopupComponent implements AfterViewInit {
  @Input() popupContext!: IPartnersCheckPopupContext;

  public popupContent: IPartnersCheckPopupContent = initialPartnersCheckPopup;

  private readonly notifier$ = new Subject();

  constructor(
    private readonly translateService: TranslateService,
    private readonly cdr: ChangeDetectorRef,
    private readonly partnerService: PartnersService,
  ) {}

  ngAfterViewInit(): void {
    this.popupContent = {
      header: this.getHeader(),
      message: this.getMessage(),
      button: this.getButton(),
      cargoOwner: this.popupContext.cargoOwner,
    };
    this.cdr.detectChanges();
  }

  private getMessage() {
    switch (this.popupContext.businessRelationship) {
      case '':
        return this.partnerCheckNotStarted;
      case BUSINESS_RELATIONSHIP.ON_ACCREDITATION:
        return this.partnerCheckInProgress;
      case BUSINESS_RELATIONSHIP.NOT_ACCREDITED:
        return this.partnerCheckFailed;
      default:
        return this.partnerCheckFailed;
    }
  }

  get partnerCheckNotStarted() {
    return {
      statusText: this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.status'),
      tooltip: this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.tip.notStarted'),
      messageText: this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.message.notStarted', {
        value: this.popupContext.cargoOwner.name,
      }),
      iconColor: STATUS_ICON_COLORS.BLUE,
    };
  }
  get partnerCheckInProgress() {
    return {
      statusText: this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.status'),
      tooltip: this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.tip.inProgress'),
      messageText: this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.message.inProgress'),
      iconColor: STATUS_ICON_COLORS.ORANGE,
    };
  }
  get partnerCheckFailed() {
    return {
      statusText: this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.status'),
      tooltip: this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.tip.denied'),
      messageText: this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.message.denied'),
      iconColor: STATUS_ICON_COLORS.RED,
    };
  }

  private getHeader(): string {
    return this.popupContext.isAuction ? this.auctionHeader : this.acceptHeader;
  }
  private get auctionHeader() {
    return this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.header.auctionHeader');
  }
  private get acceptHeader() {
    return this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.header.takeHeader');
  }

  private getButton() {
    if (!this.popupContext.businessRelationship) {
      return this.notStartedButton;
    }
    return this.popupContext.isAuction ? this.auctionButton : this.takeButton;
  }

  private get notStartedButton() {
    return {
      text: this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.button.startVerification'),
      isDisabled: false,
      withIcon: true,
    };
  }
  private get auctionButton() {
    return {
      text: this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.button.auction'),
      isDisabled: true,
      withIcon: false,
    };
  }
  private get takeButton() {
    return {
      text: this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.button.take'),
      isDisabled: true,
      withIcon: false,
    };
  }

  public startPartnersCheck() {
    this.partnerService
      .startPartnersCheck(this.popupContext.cargoOwner.id)
      .pipe(takeUntil(this.notifier$))
      .subscribe(() => {
        if (this.popupContext.onAccreditationRequestSent) this.popupContext.onAccreditationRequestSent();
        this.notifier$.next(null);
      });
  }
}
