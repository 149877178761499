import { Directive, ElementRef, OnDestroy } from '@angular/core';
import { fromEvent, Subscription } from 'rxjs';

@Directive({
  selector: '[scrollToCenter]',
})
export class MatTabScrollToCenterDirective implements OnDestroy {
  private subs = new Subscription();

  constructor(private element: ElementRef) {
    this.subs.add(
      fromEvent(this.element.nativeElement, 'click').subscribe(_ => {
        const scrollContainer = this.element.nativeElement.querySelector('.mat-tab-list');

        scrollContainer?.scroll({
          left: scrollContainer?.scrollLeft,
          behavior: 'smooth',
        });
      }),
    );
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }
}
