import { IReconcilationActRuleDto } from './dto/reconciliation-act-rule.dto';

export class TnReconcilationActRule {
  public id: number;

  public name: string;

  constructor(id: number, name: string) {
    this.id = id;
    this.name = name;
  }

  public static fromDto(dto: IReconcilationActRuleDto): TnReconcilationActRule {
    return new TnReconcilationActRule(dto.id, dto.name);
  }

  public static stringify(rule: TnReconcilationActRule | null): string {
    return rule ? rule.name : '';
  }
}
