import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAX_EMAIL_LENGTH, MAX_LENGTH_200, regExpConfig } from '@transport/ui-utils';
import { trimmedStringValidator } from '../form-validators';
import { TnSupportedCountry } from '@transport/ui-pipes';

const MAX_PHONE_NUMBER_LENGTH = 12;
const MAX_FIO_LENGTH = 150;
/**
 * Reg form data.
 */
export interface IRegByCodeFormData {
  role?: string;
  inn?: string;
  fullName: string;
  phoneNumber: string;
  email: string;
  token?: string;
  selfEmployed?: boolean;
  companyName?: string;
  country?: TnSupportedCountry | null;
}

/**
 * Reg form interface.
 */
export interface IRegByCodeForm extends FormGroup {
  controls: {
    role: AbstractControl;
    inn?: AbstractControl;
    fullName: AbstractControl;
    phoneNumber: AbstractControl;
    email: AbstractControl;
    companyName: AbstractControl;
    country: AbstractControl;
  };
}

/**
 * New login form constructor type.
 */
export type TNewRegByCodeFormConstructor = (fb: FormBuilder, editableItem?: IRegByCodeFormData, disabled?: boolean) => IRegByCodeForm;

/**
 * Reg form constructor
 * @param fb form builder
 * @param editableItem editable item
 * @param disabled indicates if for should be initialized with disabled controls
 */
export const newRegByCodeForm: TNewRegByCodeFormConstructor = (
  fb: FormBuilder,
  editableItem?: IRegByCodeFormData,
  disabled = false,
): IRegByCodeForm => {
  return fb.group({
    role: [
      'CARRIER',
      {
        disabled,
      },
    ],
    inn: [
      '',
    ],
    fullName: [
      '',
      {
        validators: Validators.compose([Validators.required, trimmedStringValidator, Validators.maxLength(MAX_FIO_LENGTH)]),
        disabled,
      },
    ],
    country: [
      null,
      {
        validators: Validators.compose([Validators.required]),
      },
    ],
    phoneNumber: [
      '',
      {
        validators: Validators.compose([
          Validators.required,
        ]),
        disabled,
      },
    ],
    email: [
      editableItem?.email ?? '',
      {
        validators: Validators.compose([
          Validators.required,
          Validators.pattern(regExpConfig.email.regExp),
          Validators.maxLength(MAX_EMAIL_LENGTH),
        ]),
        disabled,
      },
    ],
    companyName: [
      editableItem?.companyName ?? '',
      {
        validators: Validators.compose([
          Validators.required,
          Validators.maxLength(300),
        ]),
        disabled,
      },
    ],
  }) as IRegByCodeForm;
};
