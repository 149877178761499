import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'transport-tw-input-field',
  templateUrl: './tw-input-field.component.html',
  styleUrls: ['./tw-input-field.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- Cuz no ways to detect [control] touched and validity status.
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TnTwInputFieldComponent {
  @Input() public control?: AbstractControl;

  @Input() public size: 'xs' | 'sm' | 'md' | 'lg' = 'md';

  @Input() public errorsSize: 'xs' | 'sm' | 'md' | 'lg' = 'sm';

  @Input() public label?: string = '';

  @Input() public labelClasses?: string[];

  @Input() public labelAddition?: string;

  @Input() public caption?: string;

  @Input() public captionClasses?: string[];

  @Input() public showErrors = true;

  @Input() public required = false;

  @Input() public errorToMessage: (errors: ValidationErrors | null) => string = () => 'Error';
}
