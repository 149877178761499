<transport-tw-input-field
  [size]="size"
  label="{{ !labelInside ? placeholder : '' }}"
  [control]="control"
  [required]="!labelInside ? required : false "
  [showErrors]="showErrors"
  [errorsSize]="errorsSize"
  [errorToMessage]="errorToMessage"
>
  <transport-tw-input [size]="size" matAutocompleteOrigin #origin="matAutocompleteOrigin" [control]="control">
    <div *ngIf="labelInside && !searchRef.value && !inputFocused" (click)="clickAtInsideLabel()">
      {{placeholder}}
      <span *ngIf="required" class="text-red-500">*</span>
    </div>
    <input
      aria-label="Input"
      [required]="required"
      tw-input
      placeholder="{{ labelInside && !inputFocused ? '' : ('shared.actions.startTyping' | translate) }}"
      [matAutocomplete]="auto"
      (input)="searching($event)"
      [disabled]="control.disabled"
      [attr.data-test]="dataTest"
      (blur)="onBlur()"
      (focus)="inputFocused = true"
      #searchRef
      #trigger="matAutocompleteTrigger"
      [matAutocompleteConnectedTo]="origin"
      transportAutocompletePosition="trigger"
    />
    <span class="flex items-center">
      <div *ngIf="allowClear" class="flex items-center justify-center cursor-pointer w-5 h-5 mx-1">
        <mat-icon class="!w-[11px] !h-[11px] text-gray-400" svgIcon="close" (click)="clearSelect($event)"></mat-icon>
      </div>
      <mat-icon *ngIf="!control.disabled" matSuffix class="arrow !cursor-pointer" (click)="onClickArrow()">
        {{inputFocused ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}}
      </mat-icon>
    </span>
  </transport-tw-input>
</transport-tw-input-field>

<mat-autocomplete class="tw-menu-block" #auto [displayWith]="displayFn.bind(this)" (optionSelected)="onItemSelected($event)">
  <mat-option *ngIf="showAddBtn" (click)="addItem()" [attr.data-test]="dataTest + '-add-button'">
    <transport-tw-menu-option variant="button" size="sm">+ {{'shared.actions.add' | translate }}</transport-tw-menu-option>
  </mat-option>

  <mat-spinner *ngIf="loading$ | async" [diameter]="20" [strokeWidth]="2" class="ml-10 mb-1"></mat-spinner>

  <ng-container *ngIf="!(loading$ | async)">
    <mat-option *ngIf="visibleData?.length === 0 && showEmptyMessage" disabled="true">
      <transport-tw-menu-option
        size="sm"
        variant="center"
        >{{ 'owner.order.publishTypeNew.noOptions' | translate }}</transport-tw-menu-option
      >
    </mat-option>
    <mat-option
      *ngFor="let item of visibleData; let i = index"
      [value]="getValue(item)"
      [disabled]="disabledField(item.accrStatus)"
      class="disabled:cursor-default"
    >
      <div *ngIf="i === separator" class="pl-3 text-xs font-semibold text-gray-500 !bg-white cursor-default">
        {{'shared.accreditationAutocomplete.needForCheck' | translate}}
      </div>
      <div class="flex justify-between hover:bg-gray-100">
        <transport-tw-menu-option
          [variant]="isSelected(item) ? 'active' : 'option'"
          size="sm"
          [ngClass]="{'text-gray-700': item.accrStatus === 'passed'}"
          [text]="getLabel(item)"
        ></transport-tw-menu-option>
        <div
          *ngIf="i >= separator && item.accrStatus === 'new'"
          class="flex items-center pr-3 text-xs font-semibold text-blue-500 cursor-pointer"
          (click)="onCheck(item)"
        >
          {{'shared.accreditationAutocomplete.sendOnCheck' | translate}}
        </div>
        <div
          *ngIf="i >= separator && item.accrStatus === 'pending'"
          class="flex items-center pr-3 cursor-default text-xs  font-semibold opacity-50 text-gray-700"
        >
          {{'shared.accreditationAutocomplete.checking' | translate}}
        </div>
        <div
          *ngIf="i >= separator && item.accrStatus === 'not_passed'"
          class="flex items-center pr-3 cursor-default text-xs font-semibold text-red-500"
        >
          {{'shared.accreditationAutocomplete.denied' | translate}}
        </div>
      </div>
    </mat-option>
  </ng-container>
</mat-autocomplete>
