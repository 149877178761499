import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TnCurrentUserService } from '@marketplace/auth';
import { BurgerMenuService, MENU_STATE, SidebarItem } from '@transport/sidebar';
import { USER_ROLE } from '@transport/ui-interfaces';
import { TnDomainService } from '@transport/ui-services';
import {
  SUPPORT_TMS_EMAIL,
  SUPPORT_TMS_PHONE,
  SUPPORT_TONNA_EMAIL,
  SUPPORT_TONNA_PHONE,
  TnAnalyticsService,
  TnAuthApiService,
  TnClSsoFrameService,
  TnCurrentUserFacade,
  TnUserService,
} from '@transport/ui-store';
import { LOCAL_HOST_DOMAIN, abbreviation } from '@transport/ui-utils';
import { WebsocketService } from '@transport/websocket';
import { iconsCollection } from 'libs/transport-ui-services/src/lib/icons/icons-collection';
import { EMPTY, finalize, map, of } from 'rxjs';

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SidebarComponent implements OnInit {
  MENU_STATE = MENU_STATE;

  @Input() items: SidebarItem[] = [];
  @Input() mainSidebarComponent = true;
  @Input() isMarket = false;

  public isRegCompleted$: any = of(null);
  public get hasToken() {
    return Boolean(this.isMarket ? this.currentUserService.currentUser.token : this.currentUserFacade.currentUser.token);
  }
  public get isSupervisor() {
    return Boolean(this.isMarket ? this.currentUserService.currentUser.isSupervisor : this.currentUserFacade.currentUser.isSupervisor);
  }

  public get supportEmail() {
    return this.isMarket ? SUPPORT_TONNA_EMAIL : SUPPORT_TMS_EMAIL;
  }
  public get supportPhone() {
    return this.isMarket ? SUPPORT_TONNA_PHONE : SUPPORT_TMS_PHONE;
  }

  public user$ = (this.isMarket ? this.currentUserService : this.currentUserFacade).profile$.pipe(
    map(res => {
      return {
        shortName: `${res?.lastName} ${abbreviation(res?.firstName ?? '')} ${abbreviation(res?.patronymic ?? '')}`.trim(),
        email: res?.email,
        companyName: res?.organization?.name,
      };
    }),
  );

  constructor(
    public readonly currentUserService: TnCurrentUserService,
    public readonly currentUserFacade: TnCurrentUserFacade,
    public readonly burgerService: BurgerMenuService,
    private readonly ssoService: TnClSsoFrameService,
    private readonly authService: TnAuthApiService,
    private readonly analytics: TnAnalyticsService,
    private readonly wsService: WebsocketService,
    private readonly userService: TnUserService,
    private readonly cdr: ChangeDetectorRef,
    private readonly router: Router,
    private readonly domainService: TnDomainService,
  ) {}
  ngOnInit(): void {
    this.isRegCompleted$ = (this.isMarket ? this.currentUserService : this.currentUserFacade).profile$.pipe(
      map(profile => profile.isRegistrationCompleted),
    );
  }

  public burgerMenuClick(): void {
    this.burgerService.changeMenuView();
    this.cdr.markForCheck();
  }

  public getItemClasses(item: SidebarItem) {
    return item?.classes ? item?.classes : '';
  }

  public getIconSrc(item: SidebarItem) {
    const { path } = iconsCollection.find(({ name }) => name === item.icon) ?? {};
    return path ?? `assets/icons/${item.icon}.svg`;
  }

  public completeWork(): void {
    void this.router.navigate([`supervisor/login`]);
  }

  public logout(initiator = false): void {
    const logout$ = initiator ? this.authService.logout(this.isSupervisor) : EMPTY;
    void logout$
      .pipe(
        finalize(() => {
          localStorage.clear();
          this.analytics.stopSession();
          this.isMarket ? this.currentUserService.resetUser() : this.currentUserFacade.resetUser();
          this.userService.resetUser();
          this.wsService.disconnect();
          this.ssoService.logOut();
          // this.router.navigate(['passport/login']);
          // const localHostPort = this.domainService.domain.includes('lv4.lv3.lv2.localhost') ? ':4300' : '';  // Без субдоменов нет смысла это использовать
          window.location.href = `${window.location.origin}/passport/login`;
        }),
      )
      .subscribe();
  }

  goToProfile() {
    const isOwner = (this.isMarket ? this.currentUserService : this.currentUserFacade).isUserRole(USER_ROLE.CARGO_OWNER);
    if (this.isMarket) {
      if (isOwner) {
        const localHostPort = this.domainService.domain.includes(LOCAL_HOST_DOMAIN) ? ':4200' : '';
        const url = `${window.location.protocol}//${this.domainService.domain.replace('market.', '')}${localHostPort}/owner-profile`;
        window.open(url, '_blank');
      } else {
        this.router.navigate(['my-settings']);
      }
    } else {
      this.router.navigate(['owner-profile']);
    }
  }
}
