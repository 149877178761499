import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { TnUiMaterialModule } from '@transport/ui-material';

import { TnTranslationUtilsService } from './services/translate.service';

/**
 * Transport v2.0 Translate Module providers.
 */
export const tnUiTranslateModuleProviders: Provider[] = [TranslateService, TnTranslationUtilsService];

/**
 * Transport UI translation module.
 */
@NgModule({
  imports: [TranslateModule.forChild(), TnUiMaterialModule],
  exports: [TranslateModule],
  providers: [...tnUiTranslateModuleProviders],
})
export class TnUiTranslateModule {
  constructor(utils: TnTranslationUtilsService) {
    utils.initialize();
  }

  public static forRoot(): ModuleWithProviders<TnUiTranslateModule> {
    return {
      ngModule: TnUiTranslateModule,
      providers: [...tnUiTranslateModuleProviders],
    };
  }
}
