import { Injectable } from '@angular/core';
import { CARRIERS_RATING_VALUE, ICarriersRatingFilter, ICarriersRatingReportData } from '@transport/ui-interfaces';
import { getMonths, IMonth } from '@transport/ui-utils';
import moment from 'moment';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnGqlClientCarrierService } from '../gql-client-carrier/graphql-client-carrier.service';

@Injectable({
  providedIn: 'root',
})
export class TnCarrierRatingService {
  constructor(private readonly graphQL: TnGqlClientCarrierService) {}

  public getRatingData(
    offset: number,
    countPerPage: number,
    filter?: ICarriersRatingFilter,
  ): Observable<{ items: ICarriersRatingReportData[]; totalCount: number }> {
    return this.graphQL.queryCarriersRatingData(offset, countPerPage, filter).pipe(
      map(resp => {
        return {
          totalCount: resp.carrierRatingReportsCount ?? 0,
          items: resp.carrierRatingReports.map(dtoItem => ({
            id: dtoItem.id,
            startDate: dtoItem.startDate,
            endDate: dtoItem.endDate,
            indicator: dtoItem.indicator,
            department: dtoItem.department,
            deliveryArea: dtoItem.deliveryArea,
            totalDeliveriesCompleted: dtoItem.totalDeliveriesCompleted,
            assignedDeliveryRequests: dtoItem.assignedDeliveryRequests,
            assignedDeliveryRequestsCompleted: dtoItem.assignedDeliveryRequestsCompleted,
            vacantDeliveryRequestsCompleted: dtoItem.vacantDeliveryRequestsCompleted,
            assignedDeliveryRequestsRefused: dtoItem.assignedDeliveryRequestsRefused,
            deliveryContractsRefused: dtoItem.deliveryContractsRefused,
            carrierFaultCanceledShipments: dtoItem.carrierFaultCanceledShipments,
            vehicleSearchAverageTime: dtoItem.vehicleSearchAverageTime,
            refusedRequestsPercentage: dtoItem.refusedRequestsPercentage,
            carrierFaultDelayedShipmentsPercentage: dtoItem.carrierFaultDelayedShipmentsPercentage,
            vacantRequestsDeliveriesPercentage: dtoItem.vacantRequestsDeliveriesPercentage,
            currentRating: dtoItem.currentRating as CARRIERS_RATING_VALUE,
            calculatedRating: dtoItem.calculatedRating as CARRIERS_RATING_VALUE,
          })),
        };
      }),
    );
  }

  public getMonthsForFilter(): Observable<IMonth[]> {
    return of(getMonths('MMMM yyyy', moment(), true));
  }

  public getDepartmentsForFilter(): Observable<string[]> {
    return this.graphQL.queryCarriersRatingDepartments();
  }
}
