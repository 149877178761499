<div class="container">
  <transport-upload-area
    *ngIf="isUploadAreaVisible"
    (filesChange)="onFileChange($event)"
    [maxSize]="maxFileSize"
    [accept]="accept"
    [multiple]="multiple"
    [errorAccept]="errorAccept"
    [acceptDrag]="acceptDrag"
    (invalidFileSizeSelected)="invalidFileSizeSelected.emit($event)">
    <div class="placeholder" bottom>{{ placeholder }}</div>
  </transport-upload-area>
  <transport-files-list
    *ngIf="!isEmpty"
    [files]="valueAsArray"
    [isReadOnly]="isDisabled"
    (removeButtonClicked)="onRemoveButtonClicked()"
    [downloadHandlerType]="downloadHandlerType"
  >
  </transport-files-list>
</div>
