import { transport } from '@transport/proto';

export const createNewAuctionProlongationParameters = (input?: Partial<transport.IAuctionProlongationParameters>) => {
  const res = transport.AuctionProlongationParameters.toObject(new transport.AuctionProlongationParameters(input), {
    defaults: true,
  }) as transport.IAuctionProlongationParameters;
  res.prolongationCount = input?.prolongationCount ?? null;
  res.prolongationTime = input?.prolongationTime ?? null;
  res.minutesTillEndToProlong = input?.minutesTillEndToProlong ?? null;
  return res;
};

/**
 * @note TODO: fix dtoData type
 */
export const auctionProlongationParametersFromDto = (
  dtoData?: transport.IAuctionProlongationParameters,
): transport.IAuctionProlongationParameters => {
  return {
    isProlongationAvailable: dtoData?.isProlongationAvailable,
    prolongationTime: dtoData?.minutesOfProlongation,
    prolongationCount: dtoData?.timesOfProlongation,
    minutesTillEndToProlong: dtoData?.minutesTillEndToProlong,
  };
};

export const insuranceParametersFromDto = (dtoData?: transport.IInsuranceParameters): transport.IInsuranceParameters => {
  return {
    insureByDefault: dtoData?.insureByDefault,
  };
};
