import { Injectable, OnDestroy } from '@angular/core';
import { TnGqlClientSharedService } from '@transport/ui-store';
import { IPager, USER_ROLE, VEHICLE_TYPE, VEHICLE_TYPE_OWNERSHIP } from '@transport/ui-interfaces';
import { ListStore } from '@marketplace/shared/modules/table';
import { VEHICLES_QUERIES } from './vehicles-table-queries';
import { BehaviorSubject, map } from 'rxjs';
import { mapVehicles } from './vehicles-table.mappers';
import {
  IMarketplaceBodySubtypes,
  IMarketplaceBodySubtypesList,
  IMarketplaceVehicleMake,
} from 'libs/marketplace-shared/src/lib/edit-vehicle/vehicle.interface';
import { VEHICLE_QUERIES } from 'libs/marketplace-shared/src/lib/edit-vehicle/vehicle-queries';
import { ICommonSelectData } from 'libs/custom-controls/src/lib/common.interface';
import { UntilDestroy } from '@ngneat/until-destroy';
import { IVehicleListResponse } from './vehicles-table.interface';
import { FILTERS_TABS } from '../filters/filters.interface';
import { readVehiclesFilterInput } from '../filters/filter-modal/filter.mappers';
import { VehiclesService } from '../vehicles.service';

export interface VehicleListFilter {
  vehicleType?: ICommonSelectData<VEHICLE_TYPE>;
  ownership?: ICommonSelectData<VEHICLE_TYPE_OWNERSHIP>;
  isThirdParty?: boolean | null;
  regNo?: string;
  vehicleBrand?: ICommonSelectData;
  trailerRegNo?: string;
  bodyType?: ICommonSelectData;
  capacity?: string;
  volume?: string;
  loadingType?: ICommonSelectData<'1' | '2' | '3'>;
  availability?: FILTERS_TABS
  // trail?erBodySubtype: string:
}

export type VehiclesListStore = ListStore<VehiclesTableService, keyof VehiclesTableService, VehicleListFilter>;

@UntilDestroy()
@Injectable()
export class VehiclesTableService implements OnDestroy {
  private listStore = new ListStore({}, (filter, sort, pageInfo) => {
    this.listStore.loading$.next(true);
    return this.sharedGraphQlClient
      .query<{ vehicles: IVehicleListResponse[]; totalCount: number }>(this.vehiclesPageService.role$.value as USER_ROLE, VEHICLES_QUERIES.list, 1, {
        ...readVehiclesFilterInput(filter),
        first: pageInfo.pageSize,
        offset: pageInfo.pageSize * pageInfo.pageIndex,
        totalCount: true,
      })
      .pipe(
        map(data => {
          return {
            items: mapVehicles(data.vehicles),
            totalCount: data.totalCount,
          };
        }),
      );
  }).store.storeToLocalStorage('my-vehicles');

  public selectedTab$ = new BehaviorSubject<number>(0);

  public bodySubtypes$ = new BehaviorSubject<IMarketplaceBodySubtypes[]>([]);
  public vehicleMakes$ = new BehaviorSubject<string[]>([]);

  public store = this.listStore.store;

  public total$ = this.listStore.list$.pipe(map(data => data.totalCount));

  get list() {
    return this.listStore.list$;
  }

  public loading$ = this.listStore.loading$.asObservable();

  constructor(private readonly sharedGraphQlClient: TnGqlClientSharedService, private vehiclesPageService: VehiclesService) {}

  public fetch(): void {
    this.listStore.fetch(false, false);
  }

  public changePage(page: IPager): void {
    const { pageIndex, pageSize } = page;
    this.listStore.pageInfo$.next({
      pageIndex,
      pageSize,
    });
  }

  public getBodySubtypes(): void {
    this.sharedGraphQlClient.query<IMarketplaceBodySubtypesList>(this.vehiclesPageService.role$.value as USER_ROLE, VEHICLE_QUERIES.getBodySubtypes, 1).subscribe(res => {
      this.bodySubtypes$.next(res?.bodySubtypes);
    });
  }

  public getVehicleMakes(): void {
    this.sharedGraphQlClient.query<IMarketplaceVehicleMake>(this.vehiclesPageService.role$.value as USER_ROLE, VEHICLE_QUERIES.getVehicleMakes, 1).subscribe(res => {
      this.vehicleMakes$.next(res?.vehicleMakes);
    });
  }

  ngOnDestroy(): void {
    this.listStore.dispose();
  }
}
