import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'transport-back-to-list-button',
  templateUrl: './back-to-list-button.component.html',
  styleUrls: ['./back-to-list-button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnBackToListButtonComponent {
  @Input() public disabled?: boolean;

  @Input() public title = 'backToTheList';

  @Input() public dataTest = '';

  @Output() public readonly goBack = new EventEmitter();

  public get buttonTitle() {
    return `shared.actions.${this.title}`;
  }

  public back() {
    this.goBack.emit();
  }
}
