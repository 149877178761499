import { createReducer, on } from '@ngrx/store';
import { transport } from '@transport/proto';
import { USER_PERMISSION_CODE } from '@transport/ui-interfaces';

import { userActions } from '../actions/user.actions';
import { userApiActions } from '../actions/user-api.actions';
import { IUserForm, IUserState } from '../user.interface';

const initialState: IUserState = {
  loading: false,
  isPageLoad: false,
  user: null,
  form: null,
  isFormDirty: false,
  isFormDisabled: false,
  isEmailEnabled: true,
  isPermissionsDisabled: false,
};

const mapUserToFormValue = (user: transport.IUser): IUserForm => {
  const formValue: IUserForm = {
    firstName: user.firstName ?? '',
    lastName: user.lastName ?? '',
    phoneNumber: user.phoneNumber ?? '',
    email: user.email ?? '',
    permissions: user?.permissions?.map(p => p.id ?? '') ?? [],
  };

  return formValue;
};

const adminUserReducerCreator = createReducer(
  initialState,

  on(userActions.loadUser, state => ({
    ...state,
    isPageLoad: true,
    loading: true,
  })),

  on(userApiActions.loadUserSuccess, (state, { user }) => {
    const newState = {
      ...state,
      user,
      form: Boolean(user) ? mapUserToFormValue(user ?? {}) : null,
      isPageLoad: false,
      loading: false,
    };
    return newState;
  }),

  on(userActions.setFormDirty, (state, { isDirty }) => ({
    ...state,
    isFormDirty: isDirty,
  })),

  on(userActions.setFormMode, (state, action) => {
    const { mode } = action;

    const isFormDisabled = !['edit', 'create', 'copy'].some(v => v === mode);
    const isEmailEnabled = mode === 'create' || mode === 'copy';

    const isAdminCG = Boolean(state.user?.permissions?.some(permission => (permission.codename ?? '') === USER_PERMISSION_CODE.ADMIN_CG));

    const newState = {
      ...state,
      isFormDisabled,
      isEmailEnabled,
      isPermissionsDisabled: isFormDisabled || isAdminCG,
    };

    return newState;
  }),

  on(userActions.inviteUser, userActions.saveUser, userActions.removeUser, state => {
    const newState = {
      ...state,
      isFormDirty: false,
    };
    return newState;
  }),

  on(userActions.inviteUser, userActions.saveUser, userActions.removeUser, userActions.resetPassword, state => {
    const newState = {
      ...state,
      loading: true,
    };
    return newState;
  }),

  on(
    userApiActions.inviteUserSuccess,
    userApiActions.inviteUserFailure,
    userApiActions.saveUserSuccess,
    userApiActions.saveUserFailure,
    userApiActions.removeUserSuccess,
    userApiActions.removeUserFailure,
    userApiActions.resetPasswordSuccess,
    userApiActions.resetPasswordFailure,
    state => {
      const newState = {
        ...state,
        loading: false,
      };
      return newState;
    },
  ),

  on(userActions.resetState, state => {
    return { ...initialState };
  }),
);

export function adminUserReducer(state, action) {
  return adminUserReducerCreator(state, action);
}
