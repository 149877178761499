<button
  *ngIf="(icon || matIcon) && isSmallScreenEnabled"
  [disabled]="disabled"
  mat-button
  class="small-screen btn"
  [ngClass]="getClass()"
  transportTooltip
  [context]="{ message: label }"
  [marginOffset]="0"
  (click)="onClick($event)"
  [attr.data-test]="dataTest + '-small-screen-btn'"
>
  <span class="mat-button-wrapper">
    <mat-icon  *ngIf="icon" [svgIcon]="icon"></mat-icon>
        <mat-icon
            class="mat-icon-inside"
            *ngIf="matIcon"
            [ngClass]="{
        'mat-icon-pointer': !disabled,
         'left': iconPosition === 'left',
         'right': iconPosition === 'right'}">{{matIcon}}</mat-icon>
    <div *ngIf="counter && counter > 0" class="ml-2 flex justify-center items-center w-4 h-4 rounded-[50%] bg-blue-500 text-white">
      <div class="counter text-xs h-4">{{ counter }}</div>
    </div>
  </span>
</button>

<button
  [disabled]="disabled"
  mat-button
  class="btn btn-text"
  [class]="getClass()"
  [ngClass]="{'wide-screen': isSmallScreenEnabled, 'full-width': hasFullWidth}"
  (click)="onClick($event)"
  [attr.data-test]="dataTest + '-text-btn'"
>
  <span class="mat-button-wrapper">
    <span *ngIf="iconPosition === 'right'">{{ label }}</span>
    <mat-icon
        class="mat-icon-inside"
        *ngIf="matIcon"
        [ngClass]="{
        'mat-icon-pointer': !disabled,
         'left': iconPosition === 'left',
         'right': iconPosition === 'right'}">{{matIcon}}</mat-icon>
    <mat-icon *ngIf="icon" [svgIcon]="icon" [ngClass]="iconPosition"></mat-icon>
    <span *ngIf="iconPosition === 'left'">{{ label }}</span>
    <div *ngIf="counter && counter > 0" class="ml-2 flex justify-center items-center w-4 h-4 rounded-[50%] bg-blue-500 text-white">
      <div class="counter text-xs h-4">{{ counter }}</div>
    </div>
  </span>
</button>
