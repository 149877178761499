export enum DRIVER_GENDER {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export enum STATUS {
  NEW = 'new',
  PENDING = 'pending',
  PASSED = 'passed',
  NOT_PASSED = 'not_passed',
}
