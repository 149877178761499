import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDividerModule } from '@angular/material/divider';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';
import { SidebarComponent } from './sidebar/sidebar.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { TnUiKitModule } from '@transport/ui-kit';
import { NotificationsModule } from 'libs/notifications/src/lib/notifications.module';
import { TnUiLayoutModule } from '@transport/transport-ui-layout';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  declarations: [SidebarComponent],
  imports: [
    CommonModule,
    MatDividerModule,
    MatIconModule,
    MatMenuModule,
    MatTooltipModule,
    TranslateModule,
    RouterModule,
    TnUiKitModule,
    NotificationsModule,
    TnUiLayoutModule,
  ],
  exports: [SidebarComponent],
})
export class SidebarModule {}
