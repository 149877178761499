/* eslint-disable max-lines */
import { gql } from 'apollo-angular';

import * as sharedFragments from '../shared/graphql-fragments-shared';
import * as fragments from './graphql-fragments-owner';
/**
 * GraphQL carrier queries
 */

export const OWNER_QUERY = {
  /**
   * GraphQL query: orders
   */
  getOrdersDefaultSchema: gql`
    query ordersOwner(
      $status: String
      $statuses: [String]
      $loadingPlaceName: String
      $unloadingPlaceName: String
      $first: Int
      $offset: Int
      $onlyMy: Boolean
      $totalCount: Boolean = false
      $allocationType: AllocationTypeEnum
      $biddingType: BiddingTypeEnum
      $loadingDateFrom: Date
      $loadingDateTo: Date
      $unloadingDateFrom: Date
      $unloadingDateTo: Date
      $externalNo: ID
      $carrierOrganizationIds: [ID]
      $id: ID
      $sortBy: [String]
    ) {
      orders(
        status: $status
        statuses: $statuses
        loadingPlaceName: $loadingPlaceName
        unloadingPlaceName: $unloadingPlaceName
        first: $first
        offset: $offset
        onlyMy: $onlyMy
        allocationType: $allocationType
        biddingType: $biddingType
        loadingDateFrom: $loadingDateFrom
        loadingDateTo: $loadingDateTo
        unloadingDateFrom: $unloadingDateFrom
        unloadingDateTo: $unloadingDateTo
        externalNo: $externalNo
        carrierOrganizationIds: $carrierOrganizationIds
        id: $id
        sortBy: $sortBy
      ) {
        ${fragments.ORDERS_FRAGMENT}
        allocationType
        lot {
          ${fragments.LOT_FRAGMENT_SHORT}
        }
        routeSheet {
          isDriverOnline
        }
        owner {
          firstName
          lastName
        }
      }

      totalCount: ordersCount(
        status: $status
        statuses: $statuses
        loadingPlaceName: $loadingPlaceName
        unloadingPlaceName: $unloadingPlaceName
        onlyMy: $onlyMy
        allocationType: $allocationType
        biddingType: $biddingType
        loadingDateFrom: $loadingDateFrom
        loadingDateTo: $loadingDateTo
        unloadingDateFrom: $unloadingDateFrom
        unloadingDateTo: $unloadingDateTo
        externalNo: $externalNo
        id: $id
        sortBy: $sortBy
        carrierOrganizationIds: $carrierOrganizationIds
      ) @include(if: $totalCount)
    }
  `,

  getOrdersShortSchema: gql`
  query ordersCarrierShort(
      $status: String
      $statuses: [String]
      $loadingPlaceName: String
      $unloadingPlaceName: String
      $first: Int
      $offset: Int
      $onlyMy: Boolean
      $isMarket: Boolean
      $totalCount: Boolean = false
      $allocationType: AllocationTypeEnum
      $biddingType: BiddingTypeEnum
      $loadingDateFrom: Date
      $loadingDateTo: Date
      $unloadingDateFrom: Date
      $unloadingDateTo: Date
      $externalNo: ID
      $carrierOrganizationIds: [ID]
      $id: ID
      $sortBy: [String]
    ) {
      orders(
        status: $status
        statuses: $statuses
        loadingPlaceName: $loadingPlaceName
        unloadingPlaceName: $unloadingPlaceName
        first: $first
        offset: $offset
        onlyMy: $onlyMy
        isMarket: $isMarket
        allocationType: $allocationType
        biddingType: $biddingType
        loadingDateFrom: $loadingDateFrom
        loadingDateTo: $loadingDateTo
        unloadingDateFrom: $unloadingDateFrom
        unloadingDateTo: $unloadingDateTo
        externalNo: $externalNo
        carrierOrganizationIds: $carrierOrganizationIds
        id: $id
        sortBy: $sortBy
      )
      {
        id
        documentFlowSettings
        viewEndDatetime
        loadingPlaces {
          storagePoint {
            settlement
            companyName
          }
        }
        unloadingPlaces {
          storagePoint {
            settlement
            companyName
          }
        }
        cargos {
          name
          size {
            weight
            volume
            packageType
            quantity
          }
        }
        edmStatus
        loadingDate
        unloadingDate
        cargoType {
          name
          packagingType
        }
        weight
        volume
        cargoPackagesCount
        price {
          amount
          currency
        }
        priceWithVat {
          amount
          currency
        }
        priceWithoutVat {
          amount
          currency
        }
        status
        isVatIncluded
        allowCounterOffer
        carrier {
          companyName
        }
        driver {
          surname
          name
          phone
        }
        vehicleRequirements {
          bodySubtypes {
            name
          }
          capacity
          volume
          loadingTypes {
            name
          }
        }
        loadingTypes
        slaObserved
        lot{
          lastBet
        }
        betsCount
        allocationType
        acceptDeadline
        lot {
          lastBet
        }
        externalNo
      }
      totalCount: ordersCount(
        status: $status
        statuses: $statuses
        loadingPlaceName: $loadingPlaceName
        unloadingPlaceName: $unloadingPlaceName
        onlyMy: $onlyMy
        isMarket: $isMarket
        allocationType: $allocationType
        biddingType: $biddingType
        loadingDateFrom: $loadingDateFrom
        loadingDateTo: $loadingDateTo
        unloadingDateFrom: $unloadingDateFrom
        unloadingDateTo: $unloadingDateTo
        externalNo: $externalNo
        id: $id
        sortBy: $sortBy
        carrierOrganizationIds: $carrierOrganizationIds
      ) @include(if: $totalCount)
    }`,

  getOrdersAuctionSchema: gql`
  query ordersCarrierAuction(
      $status: String
      $statuses: [String]
      $loadingPlaceName: String
      $unloadingPlaceName: String
      $first: Int
      $offset: Int
      $onlyMy: Boolean
      $totalCount: Boolean = false
      $allocationType: AllocationTypeEnum
      $biddingType: BiddingTypeEnum
      $loadingDateFrom: Date
      $loadingDateTo: Date
      $unloadingDateFrom: Date
      $unloadingDateTo: Date
      $externalNo: ID
      $carrierOrganizationIds: [ID]
      $id: ID
      $sortBy: [String]
    ) {
      orders(
        status: $status
        statuses: $statuses
        loadingPlaceName: $loadingPlaceName
        unloadingPlaceName: $unloadingPlaceName
        first: $first
        offset: $offset
        onlyMy: $onlyMy
        allocationType: $allocationType
        biddingType: $biddingType
        loadingDateFrom: $loadingDateFrom
        loadingDateTo: $loadingDateTo
        unloadingDateFrom: $unloadingDateFrom
        unloadingDateTo: $unloadingDateTo
        externalNo: $externalNo
        carrierOrganizationIds: $carrierOrganizationIds
        id: $id
        sortBy: $sortBy
      ) {
        id
        externalNo
        loadingPlaces {
          storagePoint {
            name
            settlement
          }
        }
        unloadingPlaces {
          storagePoint {
            name
            settlement
          }
        }
        loadingDate
        unloadingDate
        weight
        volume
        price {
          amount
          currency
        }
        priceWithVat {
          amount
          currency
        }
        priceWithoutVat {
          amount
          currency
        }
        comment
        status
        isVatIncluded
        temperatureMode
        carrier {
          companyName
        }
        isMarket
        statusOnEvent
        viewEndDatetime
        viewProcessDeadline
        gracePeriod
        customerOrganization {
          name
        }
        vehicleRequirements {
          bodySubtype {
            name
          }
        }
        loadingTypes
        allocationType
        lot {
          endDatetime
          lastBet
          auctionStatus
          viewEndDatetime
          startPrice
        }
        owner {
          firstName
          lastName
        }
      }

      totalCount: ordersCount(
        status: $status
        statuses: $statuses
        loadingPlaceName: $loadingPlaceName
        unloadingPlaceName: $unloadingPlaceName
        onlyMy: $onlyMy
        allocationType: $allocationType
        biddingType: $biddingType
        loadingDateFrom: $loadingDateFrom
        loadingDateTo: $loadingDateTo
        unloadingDateFrom: $unloadingDateFrom
        unloadingDateTo: $unloadingDateTo
        externalNo: $externalNo
        id: $id
        sortBy: $sortBy
        carrierOrganizationIds: $carrierOrganizationIds
      ) @include(if: $totalCount)
    }
  `,

  myOrders: gql`
    query myOrders {
      orders {
        id
        status
        loadingDate
        unloadingDate
      }
    }
  `,

  getOrdersAmountForDashboard: gql`
    query ordersAmount(
      $allocationType: AllocationTypeEnum,
      $biddingType: BiddingTypeEnum,
    ) {
      free: ordersCount(
        statuses: ["FREE"]
        allocationType: $allocationType
        biddingType: $biddingType
      )
      pending: ordersCount(
        statuses: ["PENDING"]
        allocationType: $allocationType
        biddingType: $biddingType
      )
      taken: ordersCount(
        statuses: ["CONTRACT_ATTACHED"]
        allocationType: $allocationType
        biddingType: $biddingType
      )
      assigned: ordersCount(
        statuses: ["ASSIGNED"]
        allocationType: $allocationType
        biddingType: $biddingType
      )
      reserved: ordersCount(
        statuses: ["TRANSPORT_RESERVED"]
        allocationType: $allocationType
        biddingType: $biddingType
      )
      onSign: ordersCount(
        statuses: ["ASSIGNED", "TRANSPORT_RESERVED"]
        allocationType: $allocationType
        biddingType: $biddingType
      )
      onWay: ordersCount(
        statuses: ["READY_TO_GO"]
        allocationType: $allocationType
        biddingType: $biddingType
      )
      completed: ordersCount(
        statuses: ["COMPLETED"]
        allocationType: $allocationType
        biddingType: $biddingType
      )
      canceled: ordersCount(
        status: "CANCELED"
        allocationType: $allocationType
        biddingType: $biddingType
      )
    }
  `,

  getDrafts: gql`
    query drafts(
      $first: Int
      $offset: Int
      $sortBy: [String]
      $totalCount: Boolean = false
    ) {
      drafts(
        first: $first
        offset: $offset
        sortBy: $sortBy
      ) {
        ${fragments.DRAFTS_FRAGMENT}
      }

      totalCount: draftsCount(
        sortBy: $sortBy
      ) @include(if: $totalCount)
    }
  `,

  getDraft: gql`
    query draft($draftId: ID!, $isDraft: Boolean = true, $isCopyMode: Boolean = false) {
      draft(draftId: $draftId) {
        ${fragments.DRAFT_FRAGMENT}
      }
    }
  `,

  /**
   * GraphQL query: order
   */
  getOrder: gql`
    query getFullOrder($orderId: ID!, $isDraft: Boolean = false, $isCopyMode: Boolean = false) {
      order(id: $orderId) {
        initialData {
          isVatIncluded
          price {
            amount
            currency
          }
        }
        loadingPlaces @skip(if: $isDraft) {
          id
        }
        unloadingPlaces @skip(if: $isDraft) {
          id
        }
        ${fragments.ORDER_FRAGMENT}
        lot {
          ${fragments.LOT_FRAGMENT_SHORT}
        }
        acceptDeadline
        agentCarrierInfo {
          name
          inn
          kpp
          phone
          address
        }
        vehicle {
          id
          vehicleMake
          vehicleType
          regNo
          ownershipType
          blackListInfo(orderId: $orderId) {
            cargoOwner {
              id
              name
            }
            startDate
            workProhibited
            reason
            description
          }
        }
        vehicleTrailer {
          regNo
          trailerType
          blackListInfo(orderId: $orderId) {
            cargoOwner {
              id
              name
            }
            startDate
            workProhibited
            reason
            description
            nomenclatureType
          }
        }
        driver {
          ${sharedFragments.ORDER_FRAGMENT_DRIVER}
          blackListInfo(orderId: $orderId) {
            cargoOwner {
              id
              name
            }
            startDate
            workProhibited
            reason
            description
          }
        }
        coDriver {
          ${sharedFragments.ORDER_FRAGMENT_DRIVER}
          blackListInfo(orderId: $orderId) {
            id
            reason
          }
        }
        cargoNomenclatureTypes
        sla {
          id
          organizationId
          name
          description
          nonTrackableCriteria {
            name
            description
          }
          trackableCriteria {
            id
            description
            isActive

            thresholdValue
            createdAt
          }
          isActive
          isArchived
          createdAt
        }
        slaObserved
      }
    }
  `,

  getContactPersonsAtLoadingPlace: gql`
    query contactPersonsAtLoadingPlace($loadingPlaceId: ID!) {
      contacts: contactPersonsAtLoadingPlace(loadingPlaceId: $loadingPlaceId) {
        id
        firstName
        lastName
        phoneNumber
        loadingPlace {
          id
        }
      }
    }
  `,

  getContactPersonsAtUnloadingPlace: gql`
    query contactPersonsAtUnloadingPlace($unloadingPlaceId: ID!) {
      contacts: contactPersonsAtUnloadingPlace(unloadingPlaceId: $unloadingPlaceId) {
        id
        firstName
        lastName
        phoneNumber
        loadingPlace {
          id
        }
      }
    }
  `,
  getOrderWithTradeInfo: gql`
  query GetOrderWithTradeInfo($orderId: ID!){
    order(id: $orderId) {
      id
      externalNo
      status
      lot {
          startPrice
      }
      tradeOrder {
          tradeOrderId
          orderId
          allocationType
          acceptDeadline
          carrierGroup
          isVatIncluded
          price
      }
    }
  }`,

  getAuctionBetHistory: gql`
    query orderAuctionBetHistory($orderId: ID!) {
      order(id: $orderId) {
        id
        initialData {
          isVatIncluded
          price {
            amount
            currency
          }
        }
        loadingPlaces {
          arriveDatetimeTo
          arriveDatetimeFrom
        }
        carrierAllAccredited
        externalNo
        isVatIncluded
        allocationType
        createdDatetime
        acceptDeadline
        endDatetime
        betsCount
        status
        isMarket
        carrierGroup {
          name
          id
        }
        price {
          amount
          currency
        }
        priceHistory  {
          amount
          currency
          vatIncluded
          updatedAt
        }
        lot {
          id
          participantOrganizationsCount
          lastBet
          startPrice
          auctionStatus
          viewEndDatetime
          additionalAuctionCriteria {
            type
            weight
          }
          history {
            added
            bet
            acceptingPlace
            comment
            carrier {
              id
              organizationId
              companyName
            }
            betEquivalent
            additionalCriteriaData {
              type
              value
            }
          }
        }
        counterOffers {
          counterOfferId
          createdAt
          acceptingPlace
          carrierOrganizationInfo
          comment
          status
          ownerComment
          price {
            amount
            currency
            vatIncluded
            vat
          }
          additionalCriteriaData{
            type
            value
          }
          counterOfferEquivalent
        }
      }
    }
  `,

  getOrderShortInfo: gql`
    query orderShortInfo($orderId: ID!) {
      order(id: $orderId) {
        id
        isVatIncluded
        allocationType
        createdDatetime
        acceptDeadline
        betsCount
        status
        isMarket
        carrierGroup {
          name
        }
        price {
          amount
          currency
        }
        lot {
          id
          participantOrganizationsCount
          lastBet
          startPrice
          auctionStatus
          viewEndDatetime
        }
        counterOffers {
          comment
          counterOfferId
          createdAt
          acceptingPlace
          carrierOrganizationInfo
          price {
            amount
            currency
            vatIncluded
            vat
          }
        }
      }
    }
  `,

  /**
   * GraphQL query: auction order
   */
  getAuctionOrder: gql`
    query auctionOrder($orderId: ID!, $isDraft: Boolean = false, $isCopyMode: Boolean = false) {
      auctionOrder(id: $orderId) {
        ${fragments.ORDER_FRAGMENT}
        vehicle {
          id
          vehicleMake
          vehicleType
          regNo
          ownershipType
          blackListInfo(orderId: $orderId) {
            id
            reason
          }
        }
        vehicleTrailer {
          regNo
          trailerType
          blackListInfo(orderId: $orderId) {
            id
            reason
          }
        }
        driver {
          ${sharedFragments.ORDER_FRAGMENT_DRIVER}
          blackListInfo(orderId: $orderId) {
            id
            reason
          }
        }
        coDriver {
          ${sharedFragments.ORDER_FRAGMENT_DRIVER}
          blackListInfo(orderId: $orderId) {
            id
            reason
          }
        }
        allocationType
        lot {
          ${fragments.LOT_FRAGMENT}
        }
        cargoNomenclatureTypes
      }
    }
  `,

  /**
   * GraphQL query: cargo_types
   */
  getCargoTypes: gql`
    query cargoTypes($first: Int, $offset: Int, $withArchived: Boolean, $totalCount: Boolean = false, $selectMode: Boolean = false) {
      cargoTypes(first: $first, offset: $offset, withArchived: $withArchived) {
        id
        name
        cargoOwner @skip(if: $selectMode) {
          id
          name
        }
        defaultWeight @skip(if: $selectMode)
        defaultVolume @skip(if: $selectMode)
        typeVehicle @skip(if: $selectMode) {
          id
          name
        }
        packagingType @skip(if: $selectMode)
        cargoPackagesCount @skip(if: $selectMode)
        lengthCargoPackage @skip(if: $selectMode)
        widthCargoPackage @skip(if: $selectMode)
        heightCargoPackage @skip(if: $selectMode)
        permittedLoadingTypes @skip(if: $selectMode) {
          id
          name
        }
        isArchived @skip(if: $selectMode)
        temperatureMode
      }

      totalCount: cargoTypesCount(withArchived: $withArchived) @include(if: $totalCount)
    }
  `,

  /**
   * GraphQL query: cargo_types
   */
  getCargoType: gql`
    query cargoType($cargoTypeId: ID!) {
      cargoType(id: $cargoTypeId) {
        id
        name
        cargoOwner {
          id
          name
        }
        defaultWeight
        defaultVolume
        typeVehicle {
          id
          name
        }
        packagingType
        cargoPackagesCount
        lengthCargoPackage
        widthCargoPackage
        heightCargoPackage
        permittedLoadingTypes {
          id
          name
        }
        isArchived
        vehicleCapacity
        vehicleVolume
        vehicleLength
        vehicleWidth
        vehicleHeight
        temperatureMode
      }
    }
  `,

  /**
   * GraphQL query: VEHICLE_TYPE
   */
  getVehicleTypeList: gql`
    {
      vehicleTypes {
        id
        name
      }
    }
  `,

  /**
   * GraphQL query: List of carrier organizations
   */
  getCarrierOrganizationList: gql`
    query CarrierOrganizations($anyContract: Boolean, $state: [String], $selectMode: Boolean = false) {
      carrierOrganizations(anyContract: $anyContract, stateIn: $state) {
        id
        name
        about @skip(if: $selectMode)
        phoneNumber @skip(if: $selectMode)
        email @skip(if: $selectMode)
        status @skip(if: $selectMode)
      }
    }
  `,

  getTrustedCarrierOrganizationList: gql`
    query trustedCarrierOrganizations($first: Int, $offset: Int, $anyContract: Boolean, $totalCount: Boolean = false, $withContracts: Boolean = true) {
      trustedCarrierOrganizations(first: $first, offset: $offset anyContract: $anyContract, withContracts: $withContracts) {
        id
        name
        signer
        about
        phoneNumber
        email
        isCompanyGroup
        country
        inn
        iin
        bin
        unp
        kpp
        legalAddress
        registrationNumber
        legalForm{
          id
          name
          shortName
          code
        }
        contracts {
          contractNumber
          startDate
          endDate
          name
          file
        }
      ogrnDate
      headOfOrganization {
        id
        name
        surname
        patronymic
        inn
      }
      }
      totalCount: trustedCarrierOrganizationsCount(anyContract: $anyContract, withContracts: $withContracts)
        @include(if: $totalCount)
    }
  `,
  /**
   * GraphQL query: List of carrier organizations
   */
  getCarrierOrganizationFilterList: gql`
    query orderCarrierOrganizations($anyContract: Boolean) {
      carrierOrganizations(anyContract: $anyContract) {
        id
        name
      }
    }
  `,

  /**
   * GraphQL query: Carrier organization by id
   */
  getCarrierOrganization: gql`
    query CarrierOrganization($id: ID!) {
      carrierOrganization(id: $id) {
        id
        inn
        kpp
        bin
        iin
        unp
        country
        name
        about
        phoneNumber
        email
        status
        registrationNumber
        signer
        legalAddress
        postalAddress
        legalForm {
          id
          name
          shortName
          code
        }
        businessRelationship {
          state
          carrierFacsimileImg
        }
        contracts {
          id
          contractNumber
          startDate
          file
          endDate
          isArchived
          cargoQuantity
          loadingPlaces {
            id
            pointName
            name
          }
          unloadingPlaces {
            id
            pointName
            name
          }
          cargoTypes {
            id
            name
          }
        }
      }
    }
  `,

  getLoadingTypeList: gql`
    {
      loadingTypes {
        id
        name
      }
    }
  `,

  /**
   * GraphQL query: get cargo body types
   */
  getBodyTypes: gql`
    {
      bodyTypes {
        id
        name
        subtypes {
          id
          name
        }
      }
    }
  `,

  /**
   * GraphQL query: notifications count
   */
  getNotificationsCount: gql`
    query notificationsCount($isRead: Boolean, $first: Int, $offset: Int) {
      notificationsCount(isRead: $isRead, first: $first, offset: $offset)
    }
  `,

  /**
   * GraphQL query: loading|unloading place
   */
  getStoragePoint: gql`
    query storagePoint($id: ID!, $typePoint: StoragePointTypePoint) {
      storagePoint(id: $id, typePoint: $typePoint) {
        ${fragments.STORAGE_POINT_FRAGMENT}
        coordinateAccuracy
        interestedPersons {
          email
          notifications
        }
      }
    }
  `,
  /**
   * GraphQL query: notifications count
   */
  getNotificationsWithCount: gql`
    query notificationsWithCount($isRead: Boolean, $first: Int, $offset: Int) {
      notifications(isRead: $isRead, first: $first, offset: $offset) {
        id
        eventDatetime
        title
        text
        topic
        action
        payload
        isRead
      }

      count: notificationsCount(isRead: $isRead)
    }
  `,

  /**
   * GraphQL query: organization profile
   */
  getCurrentUserOrganizationProfile: gql`
    query getCurrentUserOrganizationProfile {
      profile {
        id
        organization {
          ${sharedFragments.OWNER_ORGANIZATION_FRAGMENT}
          logo
          subdomain
          documentFlowSettings
          currency
        }
        isRegistrationCompleted
        bankDetails {
          name
          rcbic
          corrAcc
          acc
        }
      }
    }
  `,

  /**
   * GraphQL query: default params profile
   */
  getCurrentUserDefaultParamsProfile: gql`
    query getCurrentUserDefaultParamsProfile {
      profile {
        id
        organization {
          vat {
            id
            name
            rate
          }
          showOrderExternalNo
          paymentPeriod
          paymentType {
            ${sharedFragments.PAYMENT_TYPE_FRAGMENT}
          }
        }
      }
    }
  `,

  /**
   * GraphQL query: default auction params of profile
   */
  getCurrentUserDefaultActionParameters: gql`
    query organizationAuctionParameters {
      profile {
        organization {
          auctionParameters {
            ${sharedFragments.AUCTION_PARAMETERS_FRAGMENT}
          }
        }
      }
    }
  `,

  /**
   * GraphQL query: organization profile
   */
  getCurrentUserSuborganizations: gql`
    query subOrganizations(
      $first: Int
      $offset: Int
      $availability: AvailabilityEnum
      $totalCount: Boolean = false) {
      subOrganizations(first: $first, offset: $offset, availability: $availability) {
        ${sharedFragments.OWNER_ORGANIZATION_FRAGMENT}
      }
      totalCount: subOrganizationsCount(availability: $availability)
        @include(if: $totalCount)
    }
  `,

  getPaymentTypes: gql`
    query GetPaymentTypes {
      paymentTypes {
        ${sharedFragments.PAYMENT_TYPE_FRAGMENT}
      }
    }
  `,

  /**
   * GraphQL query: listPackagingTypesForCargoType
   */
  getPackagingTypes: gql`
    query listPackagingTypesForCargoType {
      listPackagingTypesForCargoType
    }
  `,

  /**
   * GraphQL query: routeSheetEvents
   */
  getRouteSheetEvents: gql`
    query routeSheetEvents($routeSheetId: ID!, $event: RouteSheetEventEvent) {
      routeSheetEvents(routeSheetId: $routeSheetId, event: $event) {
        id
        event
        longitude
        latitude
        dateTime
        issueTopic
        issueText
        storagePoint {
          id
          latitude
          longitude
          pointName
          address
          timezone {
            id
            settlement
            timezone
          }
        }
        documents {
          filename
          url
          datetime
        }
      }
    }
  `,

  /**
   * GraphQL query: orderRouteSheet
   */
  getOrderRouteSheet: gql`
    query orderRouteSheet($orderId: ID!, $isDraft: Boolean = false, $isCopyMode: Boolean = false) {
      orderRouteSheet(orderId: $orderId) {
        id
        status
        order {
          ${fragments.ORDER_FRAGMENT}
        }
        documents {
          filename
          url
          datetime
        }
      }
    }
  `,

  getInsuranceContract: gql`
  query InsuranceContract($contractId: ID!) {
    contract:insuranceContract(insuranceContractId: $contractId) {
      ${fragments.INSURANCE_CONTRACT}
    }
  }
  `,

  getInsuranceContracts: gql`
    query myInsuranceContracts(
      $availability: AvailabilityEnum
      $first: Int
      $offset: Int
      $totalCount: Boolean = false
    ) {
      myInsuranceContracts(first: $first, offset: $offset, availability: $availability) {
        ${fragments.INSURANCE_CONTRACT}
      }
       totalCount: myInsuranceContractsCount @include(if: $totalCount)
    }
  `,

  getInsuranceInfo: gql`
    query insuranceInitialInfo($insuranceContractId: ID!) {
      insuranceInitialInfo(insuranceContractId: $insuranceContractId) {
        schema
        fieldValues
        defaultValues
      }
    }
  `,

  getInsuranceCost: gql`
    query insuranceCost($insuranceContractId: ID!, $declaredPrice: Int!) {
      insuranceCost(insuranceContractId: $insuranceContractId, declaredPrice: $declaredPrice)
    }
  `,

  /**
   * GraphQL query: sub org
   */
  getSubOrganizationById: gql`
    query organization($organizationId: ID!) {
      organization(organizationId: $organizationId) {
        ${sharedFragments.OWNER_ORGANIZATION_FRAGMENT}
      }
    }
  `,

  getMyCarrierAccreditation: gql`
    query myCarrierAccreditation($state: [String] = ["ON_ACCREDITATION"],$sortBy: [String] = "-last_change_date") {
      carrierOrganizations(stateIn: $state, sortBy: $sortBy) {
        id
        name
        signer
        about
        phoneNumber
        email
        isCompanyGroup
        inn
        iin
        bin
        unp
        kpp
        country
        legalAddress
        registrationNumber
        ogrnDate
        legalForm {
          id
          name
          shortName
          code
        }
        headOfOrganization {
          id
          name
          surname
          patronymic
          inn
        }
        businessRelationship{
          lastChangeDate
          lastChangeReason
          lastChangeUser
       }
      }
    }
  `,

  getMyCarrierReject: () => gql`
    query myCarrierRejectList($state: [String] = ["NOT_ACCREDITED"]) {
      carrierOrganizations(stateIn: $state) {
        id
        name
        signer
        about
        phoneNumber
        email
        isCompanyGroup
        inn
        iin
        bin
        unp
        kpp
        country
        legalAddress
        registrationNumber
        ogrnDate
        legalForm {
          id
          name
          shortName
          code
        }
        headOfOrganization {
          id
          name
          surname
          patronymic
          inn
        }
        businessRelationship{
          lastChangeDate
          lastChangeReason
          lastChangeUser
       }
      }
    }
  `,

  getMyCarrierContractingRequired: () => gql`
    query myCarrierContractingRequired($state: [String] = ["ACCREDITED"]) {
      carrierOrganizations(stateIn: $state) {
       id
       name
       inn
       iin
       bin
       unp
       country
       signer
       email
       phoneNumber
       kpp
       legalAddress
       registrationNumber
       legalForm {
        id
        name
        shortName
        code
       }
       contracts {
          contractNumber
          startDate
          endDate
          name
          file
        }
       businessRelationship{
        lastChangeDate
        lastChangeReason
        lastChangeUser
       }
      ogrnDate
      headOfOrganization {
        id
        name
        surname
        patronymic
        inn
      }
      }
    }
  `,

  /**
   * GraphQL query: orderRouteSheet
   */
  getMyCarrierGroups: (short = false) => gql`
    query myCarrierGroups(
      $first: Int
      $offset: Int
      $availability: AvailabilityEnum
      $totalCount: Boolean = false
    ) {
      myCarrierGroups(first: $first, offset: $offset, availability: $availability) {
        id
        name
        carriers {
            id
            name
            signer
            about
            phoneNumber
            email
            isCompanyGroup
            inn
            kpp
            legalAddress
            registrationNumber
            contracts {
                contractNumber
                startDate
                endDate
                name
                file
            }
            ogrnDate
            headOfOrganization {
                id
                name
                surname
                patronymic
                inn
            }
        }
        carriersQuantity
    }
      totalCount: myCarrierGroupsCount(availability: $availability) @include(if: $totalCount)
    }
  `,

  getCarrierGroup: gql`
    query carrierGroup($groupId: ID!) {
      group:carrierGroup(carrierGroupId: $groupId) {
        ${sharedFragments.getMyCarrierGroupFragment()}
      }
    }
  `,

  getSpasClasses: gql`
    query InsuranceCargoClasses {
      classes: insuranceCargoClasses {
        ${sharedFragments.INSURANCE_REFERENCE}
      }
    }
  `,
  getSpasKinds: gql`
    query InsuranceCargoKinds {
      kinds: insuranceCargoKinds {
        ${sharedFragments.INSURANCE_REFERENCE}
      }
    }
  `,

  getInsuranceCompanies: gql`
    query InsuranceCompanies {
      companies: insuranceCompanies {
        codename
        name
      }
    }
  `,

  getOrganizationServices: gql`
    query OrganizationServices {
      organization {
        ${sharedFragments.ALLOWED_EXTRA_SERVICES}
      }
    }
  `,

  getTimezones: gql`
     query GetTimeZones {
      timezones {
        id
        settlement
        timezone
      }
    }
  `,

  getTenders: gql`
    query tenders(
      $first: Int
      $offset: Int
      $sortBy: [String]
    ) {
      tenders(
        first: $first,
        offset: $offset,
        sortBy: $sortBy,
      ) {
        ${fragments.TENDER_FRAGMENT}
        comment
      }
      tendersCount: tendersCount(
        sortBy: $sortBy
      )
    }
  `,

  getTenderReports: gql`
      query tenderReports(
        $first: Int
        $offset: Int
        $sortBy: [String]
      ) {
        tenderReports(
          first: $first,
          offset: $offset,
          sortBy: $sortBy,
        ) {
          id
          loadingSettlement
          department
          course
          winnerPrice
          ordersQuantity
          displayedStatus
          ordersAssignedQuantity
          ordersInWorkQuantity
          ordersCompletedQuantity
          ordersCanceledQuantity
          ordersCanceledPercentage
          carrierOrganization {
            name
          }
        }
        tenderReportsCount: tenderReportsCount(
          sortBy: $sortBy,
        )
      }
    `,

  getTenderReport: gql`
    query tenderReport(
      $id: ID!
    ) {
      tenderReport(
        id: $id
      ) {
        orders {
          id
          createdDatetime
          status
          carrier {
            firstName
          }
        }
      }
    }
  `,

  getTender: gql`
    query tender($id: ID!) {
      tender(id: $id) {
        ${fragments.TENDER_FRAGMENT}
        carrierOrganization {
          id
        }
      }
    }
  `,

  getSlaCriteria: gql`
    query SlaCriteria {
      slaCriteria {
        id
        name
        description
        thresholdName
      }
    }
  `,

  getSla: gql`
    query Sla($slaId: ID!) {
      sla(slaId: $slaId) {
        id
        name
        description
        nonTrackableCriteria {
          name
          description
        }
        trackableCriteria {
          id
          description
          isActive
          thresholdValue
          criterion {
            id
            name
            description
            thresholdName
            isArchived
          }
        }
        isActive
      }
    }
  `,

  allSla: gql`
    query AllSla {
      allOrganizationSla {
        id
        name
        description
        nonTrackableCriteria {
          name
        }
        trackableCriteria {
          id
          isActive
        }
        isActive
        createdAt
      }
    }
  `,

  getAllOrganizationSla: gql`
    query allOrganizationSla {
      allOrganizationSla {
        id
        organizationId
        name
        description
        nonTrackableCriteria {
          name
          description
        }
        trackableCriteria {
          id
          description
          isActive

          thresholdValue
          createdAt
        }
        isActive
        isArchived
        createdAt
      }
    }
  `,
};
