import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { transport } from '@transport/proto';
import { IUiSettings, IUser, IUserOrganization, USER_ROLE } from '@transport/ui-interfaces';
import { of, throwError } from 'rxjs';
import { catchError, concatMap, filter, map, switchMap } from 'rxjs/operators';

import { TnGqlClientCarrierService } from '../gql-client-carrier';
import { TnGqlClientSharedService } from '../gql-client-shared/graphql-client-shared.service';
import * as currentUserAction from './current-user.actions';
import { TnUserService } from './services/user/user.service';
import INotificationSettingsOverallType = transport.UserProfile.UserSettings.INotificationSettingsOverallType;
import { Store } from '@ngrx/store';

import { ITnState } from '../state/index';
import IUserProfile = transport.IUserProfile;

import { TnToasterFacade } from '../toaster';
import { Router } from '@angular/router';

import { routerGo } from '../ngrx-router/actions/router.actions';

@Injectable({
  providedIn: 'root',
})
export class TnCurrentUserEffects {
  public restoreCurrentUser = createEffect(() =>
    this.action$.pipe(
      ofType(currentUserAction.restoreUserAction),
      switchMap(() => {
        return this.user.restoreUser().pipe(
          map(response => {
            return currentUserAction.restoreUserSuccessAction({
              user: response as IUser & { uiSettings: IUiSettings },
            });
          }),
        );
      }),
    ),
  );

  public getCurrentUserId = createEffect(() =>
    this.action$.pipe(
      ofType(currentUserAction.saveUserSuccessAction, currentUserAction.restoreUserSuccessAction),
      filter(
        action => Boolean(action.user.role) && (!Boolean(action.user.organization) || Boolean(action.user.organization?.isRegistered)),
      ),
      switchMap(({ user }) => {
        const { role, isWorkingWithTN } = user;
        return this.user.getProfile(role, Boolean(isWorkingWithTN)).pipe(
          switchMap(profile => {
            this.user.isOldOrderPageDesignSupported = !Boolean(profile.userSettings?.isNewDesign);
            return [
              currentUserAction.getCurrentUserProfileSuccess({ profile }),
              currentUserAction.getUserOrganizationStart({ userId: profile?.id as string, userRole: user.role }),
            ];
          }),
        );
      }),
    ),
  );

  public getUserOrganization = createEffect(() =>
    this.action$.pipe(
      ofType(currentUserAction.getUserOrganizationStart),
      filter(action => action.userRole === USER_ROLE.CARRIER),
      switchMap(({ userId }) => {
        return this.gqlCarrier.getCarrierUserMarkerAttributes(userId).pipe(
          map(res => {
            const organization: IUserOrganization = {
              isRegistered: !Boolean(res.carrierUser.selfRegistrationInProcess ?? false),
              hasTmsAccess: Boolean(res.carrierUser.organization?.hasTmsAccess),
              hasMarketAccess: Boolean(res.carrierUser.organization?.hasMarketAccess),
              accessRequested: Boolean(res.carrierUser.organization?.marketAttributes?.accessRequested),
              id: res.carrierUser.organization?.id as string,
              name: res.carrierUser.organization?.name,
              vatName: res.carrierUser.organization?.vat?.name ?? '',
              vatRate: res.carrierUser.organization?.vat?.rate ?? 0,
            };
            return currentUserAction.getUserOrganizationSuccess({
              organization,
              id: userId,
            });
          }),
        );
      }),
    ),
  );

  public saveCurrentUser = createEffect(() =>
    this.action$.pipe(
      ofType(currentUserAction.saveUserAction),
      switchMap((value: { user: Partial<IUser> }) => {
        return this.user.saveUser(value.user).pipe(
          map(response => {
            return currentUserAction.saveUserSuccessAction({
              user: response as IUser & { uiSettings: IUiSettings },
            });
          }),
        );
      }),
    ),
  );

  public resetCurrentUser = createEffect(() =>
    this.action$.pipe(
      ofType(currentUserAction.resetUserAction),
      switchMap(() => {
        this.user.resetUser();
        return of(currentUserAction.restoreUserAction());
      }),
    ),
  );

  public setUiSettings = createEffect(() =>
    this.action$.pipe(
      ofType(currentUserAction.setUiSettingsAction),
      switchMap((value: { user: Partial<IUser>; uiSettings: IUiSettings }) => {
        return this.gql.editUiSettings(value.user.role ?? USER_ROLE.CARRIER, value.uiSettings).pipe(
          map((newUiSettings: IUiSettings) => {
            const user = { ...value.user, uiSettings: newUiSettings } as IUser & {
              uiSettings: IUiSettings;
            };
            return currentUserAction.saveUserAction({ user });
          }),
        );
      }),
    ),
  );

  public editUserName = createEffect(() =>
    this.action$.pipe(
      ofType(currentUserAction.editUserName),
      concatMap(({ userRole, user }: { userRole: USER_ROLE; user: Partial<IUserProfile> }) => {
        return this.gql.editUserName(userRole, user).pipe(map(response => currentUserAction.editUserNameSuccess({ user })));
      }),
    ),
  );

  public editUserPhone = createEffect(() =>
    this.action$.pipe(
      ofType(currentUserAction.editUserPhone),
      concatMap(({ userRole, user }: { userRole: USER_ROLE; user: Partial<IUserProfile> }) => {
        return this.gql.editUserPhone(userRole, user).pipe(map(response => currentUserAction.editUserPhoneSuccess({ user })));
      }),
    ),
  );

  public editUserCountry = createEffect(() =>
    this.action$.pipe(
      ofType(currentUserAction.editUserCountry),
      concatMap(({ userRole, user }: { userRole: USER_ROLE; user: Partial<IUserProfile> }) => {
        return this.gql.editUserCountry(userRole, user).pipe(map(response => currentUserAction.editUserCountrySuccess({ user })));
      }),
    ),
  );

  public setNotificationSettings = createEffect(() =>
    this.action$.pipe(
      ofType(currentUserAction.setNotificationSettings),
      concatMap((value: { user: Partial<IUser>; notificationSettings: INotificationSettingsOverallType }) => {
        return this.gql.editNotificationSettings(value.user.role ?? USER_ROLE.CARRIER, value.notificationSettings).pipe(
          map((newNotificationSettings: INotificationSettingsOverallType) => {
            return currentUserAction.setNotificationSettingsSuccess({ newNotificationSettings });
          }),
          catchError(error => {
            this.store.dispatch(currentUserAction.setNotificationSettingsFailure());
            return throwError(() => error);
          }),
        );
      }),
    ),
  );

  public setOldOrderDesignSupport = createEffect(() =>
    this.action$.pipe(
      ofType(currentUserAction.setOldOrderPageDesignSupport),
      switchMap(() => {
        return this.gql.editIsNewDesign(false).pipe(map(() => currentUserAction.setOldOrderPageDesignSupportSuccess()));
      }),
    ),
  );

  public setOldOrderDesignSupportSuccess = createEffect(() =>
    this.action$.pipe(
      ofType(currentUserAction.setOldOrderPageDesignSupportSuccess),
      map(() => {
        this.user.isOldOrderPageDesignSupported = true;
        const { snapshot } = this.router.routerState;
        const { root, queryParams } = this.router.parseUrl(snapshot.url);
        const id = root.children.primary.segments[1].path;
        const kind = Boolean(queryParams.kind) ? `${queryParams.kind}/` : '';
        return routerGo({ path: [`orders/old-design/${kind}${id}`] });
      }),
    ),
  );

  public resetOldOrderDesignSupport = createEffect(() =>
    this.action$.pipe(
      ofType(currentUserAction.resetOldOrderPageDesignSupport),
      switchMap(() => {
        return this.gql.editIsNewDesign(true).pipe(map(() => currentUserAction.resetOldOrderPageDesignSupportSuccess()));
      }),
    ),
  );

  public resetOldOrderDesignSupportSuccess = createEffect(() =>
    this.action$.pipe(
      ofType(currentUserAction.resetOldOrderPageDesignSupportSuccess),
      map(() => {
        this.user.isOldOrderPageDesignSupported = false;
        const { snapshot } = this.router.routerState;
        const { root } = this.router.parseUrl(snapshot.url);
        const { segments } = root.children.primary;
        const id = segments[segments.length - 1].path;
        const kindIndex = 2;
        const kind = segments[segments.length - kindIndex].path;
        return routerGo({ path: [`orders/${id}`], query: kind !== '_old-design' ? { kind } : {} });
      }),
    ),
  );

  constructor(
    private readonly action$: Actions,
    private readonly user: TnUserService,
    private readonly gql: TnGqlClientSharedService,
    private readonly gqlCarrier: TnGqlClientCarrierService,
    private readonly store: Store<ITnState>,
    private readonly toastFacade: TnToasterFacade,
    private readonly router: Router,
  ) {}
}
