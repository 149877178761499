import { Validators } from '@angular/forms';
import { FormControl } from '@ngneat/reactive-forms';
import { transport } from '@transport/proto';
import { auctionProlongationSettingsValidator, DOCUMENT_FLOW_SETTINGS, TAdminOrganizationForm } from '@transport/ui-interfaces';
import { IRoutingSettings } from '@transport/ui-services';
import { TModify } from '@transport/ui-utils';

import { TnAuctionLotStepSettingsComponent } from '../../../../../transport-ui-components/src/lib/auction-lot-step-settings/auction-lot-step-settings.component';
import { IPaymentSettingsForm } from './payment-settings-shared';

export type TOrganizationForm = TModify<
  Pick<
    Partial<transport.IAdminOrganization>,
    | 'inn'
    | 'id'
    | 'kpp'
    | 'companyName'
    | 'legalAddress'
    | 'postalAddress'
    | 'ogrn'
    | 'country'
    | 'iin'
    | 'bin'
    | 'signerPerson'
    //TODO: decide together with the back which of these three fields is unnecessary
    | 'signer'
    | 'email'
    | 'phoneNumber'
  >,
  {
    legalForm?: transport.ILegalForm | null;
  }
>;

export interface IDocumentFlowForm {
  docFlow: boolean;
}

export interface ICarrierContractRequiredForm {
  carrierContractRequired: boolean;
}

export interface ICarrierContractSigningForm {
  carrierContractSigning: boolean;
}

export enum CARRIER_CONTRACT_SIGNING {
  CARRIER_REQUIRED_ATTACH = 'REQUIRED_CARRIER_CONTRACT_ATTACH',
  OPTIONAL_CARRIER_ATTACH = 'OPTIONAL_CARRIER_CONTRACT_ATTACH',
}

export interface IProfileEditForm {
  organization: TOrganizationForm;
  payment: IPaymentSettingsForm;
  auctionProlongationParameters: transport.IAuctionProlongationParameters | null;
  auctionLotStepParameters: TAdminOrganizationForm['auctionLotStepParameters'] | null;
  earlyFinish: boolean | null;
  insuranceParameters: transport.IInsuranceParameters | null;
  showOrderExternalNo: boolean;
  extraConditionsForMarket: string | null;
  enableDeliveryTracking: boolean;
  personalization: transport.IPersonalizationParameters;
  documentFlow: DOCUMENT_FLOW_SETTINGS;
  carrierContractSigning: CARRIER_CONTRACT_SIGNING;
  carrierContractRequired: boolean;
  routings: IRoutingSettings;
  facsimileImg: string;
}

const extraConditionsForMarketLength = 10000;
export function getAdditionalControls() {
  return {
    auctionProlongationParameters: new FormControl<transport.IAuctionProlongationParameters | null>(
      {},
      auctionProlongationSettingsValidator,
    ),
    auctionLotStepParameters: new FormControl<TAdminOrganizationForm['auctionLotStepParameters'] | null>(
      {},
      TnAuctionLotStepSettingsComponent.getValidator(),
    ),
    earlyFinish: new FormControl<boolean | null>(null),
    insuranceParameters: new FormControl<transport.IInsuranceParameters | null>({}),
    showOrderExternalNo: new FormControl<boolean>(false),
    extraConditionsForMarket: new FormControl<string | null | undefined>(null, Validators.maxLength(extraConditionsForMarketLength)),
    enableDeliveryTracking: new FormControl<boolean>(),
    personalization: new FormControl<transport.IPersonalizationParameters>({}),
    documentFlow: new FormControl<DOCUMENT_FLOW_SETTINGS>(),
    carrierContractSigning: new FormControl<CARRIER_CONTRACT_SIGNING>(),
    carrierContractRequired: new FormControl<boolean>(),
    facsimileImg: new FormControl<string>(),
  };
}

export function entityComparator(a: { id }, b: { id }) {
  return Boolean(a) && Boolean(b) ? a.id === b.id : a === b;
}

export function getAdditionalValue(formValue: IProfileEditForm) {
  const {
    auctionProlongationParameters,
    auctionLotStepParameters,
    earlyFinish,
    insuranceParameters,
    showOrderExternalNo,
    extraConditionsForMarket,
    enableDeliveryTracking,
    personalization,
    documentFlow,
    carrierContractSigning,
    carrierContractRequired,
    facsimileImg,
  } = formValue;
  const obj = {
    auctionProlongationParameters,
    auctionLotStepParameters,
    earlyFinish,
    insuranceParameters,
    showOrderExternalNo,
    extraConditionsForMarket,
    enableDeliveryTracking,
    subdomain: personalization.subdomain,
    documentFlowSettings: documentFlow,
    carrierContractSigning,
    carrierContractRequired,
    facsimileImg,
  };
  if (typeof personalization.logo !== 'string') {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- TODO: tech debt
    // @ts-ignore
    obj.logo = personalization.logo;
  }
  return obj;
}
