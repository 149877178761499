import { transport } from '@transport/proto';

import { TCargoOwnerStatus } from './cargo-owner-common.interface';

/**
 * Cargo Owner interface.
 * This interfaces are needed if protobuf types is not enough,
 * e.g. when entity should have a property with specific string values.
 */
export interface ICargoOwner extends transport.ICargoOwner {
  status: TCargoOwnerStatus;
}

export const createNewCargoOwner = (input?: Partial<ICargoOwner>) =>
  transport.CargoOwner.toObject(new transport.CargoOwner(input), {
    defaults: true,
  }) as ICargoOwner;
