import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  IFileType,
  IOrganiationUploadedFile,
  IOrganizationDocument,
  IRegistrationFileToUpload,
  TnUploadedFile,
} from '@transport/ui-interfaces';
import { GraphQLError } from 'graphql';
import { forkJoin, of } from 'rxjs';
import { catchError, first, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { TnCurrentUserFacade } from '../../current-user/current-user.facade';
import { TnFileUploadService } from '../../file-upload/file-upload.service';
import { TnToasterFacade } from '../../toaster/toaster.facade';
import * as actions from '../actions/registration.actions';
import { TnRegistrationFacade } from '../facades/registration-facade';

function mergeDocumentsAndTypes(documents: IOrganizationDocument[], types: IFileType[]): IRegistrationFileToUpload[] {
  types.forEach(type => {
    const document = documents.find(item => item.documentType.id === type.id);
    if (!Boolean(document)) {
      documents.push({ documentType: type, uploadedFile: null });
    }
  });

  return documents.map(document => {
    return { ...document, isTouched: false };
  });
}

@Injectable({ providedIn: 'root' })
export class TnRegistrationEffects {
  // public watchWebsocketActions = createEffect(() =>
  //   this.actions$.pipe(
  //     ofWebsocketTopicAndAction('organization_market_attrs'),
  //     switchMap(() => {
  //       return this.registrationFacade.getRegistrationStatus(this.currentUserFacade.currentUser.id as string);
  //     }),
  //     map(result => {
  //       const { organization } = result ?? {};
  //       const { marketAttributes } = organization ?? {};
  //       const { driverCheck, vehicleCheck, organizationCheck, esignCheck } = marketAttributes ?? {};

  //       return actions.setRegistrationStatus({
  //         payload: { driverCheck, vehicleCheck, organizationCheck, esignCheck },
  //       });
  //     }),
  //   ),
  // );


  public addDriver = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.addDriver),
      switchMap(({ payload: { entity, value } }) => {
        return this.registrationFacade.addDriverOnRegistration(value).pipe(
          map(() => actions.changeStep({ payload: entity })),
          catchError((error: GraphQLError) => {
            this.toastFacade.showMessage(error.message ?? 'shared.registrationStepper.errors.driver');
            return of(actions.addDriverFailure({ error }));
          }),
        );
      }),
    ),
  );

  public addSignForOrganization = createEffect(
    () =>
      this.actions$.pipe(
        ofType(actions.addSignForOrganization),
        switchMap(action => of(action).pipe(withLatestFrom(this.registrationFacade.signDocument$))),
        switchMap(([, document]) => {
          return this.registrationFacade
            .addDocumentForOrganization(document?.uploadedFile?.id as string, document?.documentType.id as string)
            .pipe(
              catchError((error: GraphQLError) => {
                this.toastFacade.showMessage(error.message ?? 'shared.registrationStepper.errors.loadedDocument');
                return of(actions.addSignForOrganizationFailure({ error }));
              }),
            );
        }),
      ),
    { dispatch: false },
  );

  public loadDocumentsAndTypes = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadDocumentsAndTypes),
      switchMap(() => {
        return forkJoin({
          types: this.registrationFacade.getOrganizationDocumentsType(),
          documents: this.registrationFacade.getOrganizationDocuments(),
        }).pipe(
          switchMap(({ documents, types }) => {
            const documentsWithTypes = mergeDocumentsAndTypes(documents, types);
            return of(actions.loadDocumentsAndTypesSuccess({ documents: documentsWithTypes }));
          }),
        );
      }),
      catchError((error: GraphQLError) => {
        this.toastFacade.showMessage(error.message ?? 'shared.registrationStepper.errors.loadDocuments');
        return of(actions.loadDocumentsFailure({ error }));
      }),
    ),
  );

  public uploadDocument = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.uploadDocumentStart),
      switchMap(({ file, documentType }) => {
        return this.fileUploadService.upload(file, true).pipe(
          first(),
          switchMap(result => of({ file: result, documentType })),
        );
      }),
      switchMap(({ file, documentType }: { file: TnUploadedFile; documentType: IFileType }) => {
        if (documentType.innerName === 'OPEN_DIGIT_SIGN') {
          return of(
            actions.uploadSignDocumentSucсess({
              file: {
                documentType: documentType,
                uploadedFile: { ...(file as IOrganiationUploadedFile) },
              },
            }),
          );
        }
        return this.registrationFacade.addDocumentForOrganization(file.id as string, documentType.id as string).pipe(
          switchMap((result: IOrganizationDocument) => {
            return of(
              actions.uploadDocumentSuccess({
                file: {
                  ...result,
                  uploadedFile: {
                    ...file,
                    ...(result.uploadedFile as IOrganiationUploadedFile),
                  },
                },
              }),
            );
          }),
          catchError((error: GraphQLError) => {
            this.toastFacade.showMessage(error.message ?? 'shared.registrationStepper.errors.loadedDocument');
            return of(actions.uploadDocumentFailure({ error }));
          }),
        );
      }),
    ),
  );

  public deleteDocument = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.deleteDocumentStart),
      switchMap(({ docId, index }) => {
        return this.registrationFacade
          .deleteOrganizationDocument(docId)
          .pipe(switchMap(() => of(actions.deleteDocumentSuccess({ index }))));
      }),
      catchError((error: GraphQLError) => {
        this.toastFacade.showMessage(error.message ?? 'shared.registrationStepper.errors.deleteDocument');
        return of(actions.deleteDocumentFailure({ error }));
      }),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly registrationFacade: TnRegistrationFacade,
    private readonly currentUserFacade: TnCurrentUserFacade,
    private readonly toastFacade: TnToasterFacade,
    private readonly fileUploadService: TnFileUploadService,
  ) {}
}
