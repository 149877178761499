import { Component, Input } from '@angular/core';

@Component({
  selector: 'transport-table-column-badge',
  templateUrl: './table-column-badge.component.html',
  styleUrls: ['./table-column-badge.component.scss'],
})
export class TnTableColumnBadgeComponent {
  @Input() isCount = false;
}
