import { transport } from '@transport/proto';

import { VERIFICATION_STATUS } from '../common';
import { TVehicle } from './vehicle-common.interface';

/**
 * Vehicle interface.
 * This interfaces are needed if protobuf types is not enough,
 * e.g. when entity should have a property with specific string values.
 */
export interface IVehicle extends transport.IVehicle {
  vehicleType: TVehicle;
  verificationStatus: VERIFICATION_STATUS;
}

export const createNewVehicleBodySubtype = (input: Partial<transport.Vehicle.Body.ISubtype> = {}) => {
  return transport.Vehicle.Body.Subtype.toObject(new transport.Vehicle.Body.Subtype(input), {
    defaults: true,
  }) as transport.Vehicle.Body.ISubtype;
};

export const createNewVehicleBodyType = (input: Partial<transport.Vehicle.Body.IType> = {}) => {
  return transport.Vehicle.Body.Type.toObject(new transport.Vehicle.Body.Type(input), {
    defaults: true,
  }) as transport.Vehicle.Body.IType;
};

export const createNewVehicleBody = (input: Partial<transport.Vehicle.IBody> = {}) => {
  input.bodyType = !Boolean(input.bodyType) ? createNewVehicleBodyType() : input.bodyType;
  input.bodySubtype = !Boolean(input.bodySubtype) ? createNewVehicleBodySubtype() : input.bodySubtype;
  const result = transport.Vehicle.Body.toObject(new transport.Vehicle.Body(input), {
    defaults: true,
  }) as transport.Vehicle.IBody;
  result.capacity = null;
  result.volume = null;
  return result;
};

export const createNewVehicleTrailer = (input: Partial<transport.Vehicle.ITrailer> = {}) => {
  input.bodyType = !Boolean(input.bodyType) ? createNewVehicleBodyType() : input.bodyType;
  input.bodySubtype = !Boolean(input.bodySubtype) ? createNewVehicleBodySubtype() : input.bodySubtype;
  const result = transport.Vehicle.Trailer.toObject(new transport.Vehicle.Trailer(input), {
    defaults: true,
  }) as transport.Vehicle.ITrailer;
  result.capacity = null;
  result.volume = null;
  return result;
};

export const createNewVehicleMaker = (input?: Partial<transport.Vehicle.IMaker>) =>
  transport.Vehicle.Maker.toObject(new transport.Vehicle.Maker(input), {
    defaults: true,
  }) as transport.Vehicle.IMaker;

export const createNewVehicle = (input: Partial<IVehicle> = {}) => {
  input.vehiclebody = !Boolean(input.vehiclebody) ? createNewVehicleBody() : input.vehiclebody;
  input.vehicletrailer = !Boolean(input.vehicletrailer) ? createNewVehicleTrailer() : input.vehicletrailer;
  const result = transport.Vehicle.toObject(new transport.Vehicle(input), {
    defaults: true,
  }) as IVehicle;
  if (result.vehiclebody) {
    result.vehiclebody.volume = null;
    result.vehiclebody.capacity = null;
  }
  if (result.vehicletrailer) {
    result.vehicletrailer.volume = null;
    result.vehicletrailer.capacity = null;
  }
  return result;
};
