import { Injectable } from '@angular/core';
import { TnCurrentUserFacade, TnGqlClientSharedService } from '@transport/ui-store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { INotification } from './notifications.utils';


@Injectable({ providedIn: 'root' })
export class NotificationsApiService {
  constructor(private readonly notificationsGraphQL: TnGqlClientSharedService, private readonly currenUser: TnCurrentUserFacade) {}

  public getNotifications(withSpinner = false, first?: number, offset?: number): Observable<INotification[]> {
    return this.notificationsGraphQL.queryNotificationsWithCounts(this.currenUser.currentUserRole, withSpinner, void 0, first, offset).pipe(
      map(
        response =>
          response.notifications?.map(notification => {
            const payload = JSON.parse(notification.payload as string);
            return { ...notification, payload };
          }) ?? ([] as INotification[]),
      ),
    );
  }

  public getNotificationsWithCounts(withSpinner = false, isRead: boolean | undefined = void 0, first?: number, offset?: number) {
    return this.notificationsGraphQL.queryNotificationsWithCounts(this.currenUser.currentUserRole, withSpinner, isRead, first, offset).pipe(
      map(response => ({
        ...response,
        notifications:
          response.notifications?.map(notification => {
            const payload = JSON.parse(notification.payload as string);
            return { ...notification, payload };
          }) ?? ([] as INotification[]),
      })),
    );
  }

  public getUnreadNotificationsCount(withSpinner = false, first?: number, offset?: number) {
    return this.notificationsGraphQL
      .queryNotificationsCount(this.currenUser.currentUserRole, withSpinner, false, first, offset)
      .pipe(map(({ count }) => count));
  }

  public markNotificationAsRead(notificationId: string, withSpinner = false) {
    return this.notificationsGraphQL
      .markNotificationAsReadById(this.currenUser.currentUserRole, notificationId, withSpinner)
      .pipe(map(response => response.markNotificationAsRead));
  }

  public markAllNotificationsAsRead(withSpinner = false) {
    return this.notificationsGraphQL
      .markAllNotificationsAsRead(this.currenUser.currentUserRole, withSpinner)
      .pipe(map(response => response.markAllNotificationsAsRead));
  }

  public getSystemBanner() {
    return this.notificationsGraphQL.getSystemBanner(this.currenUser.currentUserRole);
  }
}
