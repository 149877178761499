import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { IAlertInputs, TnAlertService } from '@transport/ui-kit';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

import { TnConfirmationService } from '../../../../confirmation/confirmation.service';
import { TnToasterFacade, TOAST_TYPE } from '../../../../toaster';
import { TnOwnerDirectoryPlaceTypesApiService } from '../../owner-directory-place-types-api.service';
import { removeLoadingPlace, removeLoadingPlaceFailure, removeLoadingPlaceSuccess } from '../actions/loading-places-remove.actions';

@Injectable({
  providedIn: 'root',
})
export class TnLoadingPlaceEffects {
  public readonly removeLoadingPlace = createEffect(() =>
    this.actions$.pipe(
      ofType(removeLoadingPlace),
      switchMap(({ loadingPlace }) =>
        this.confirmation.openByPrefix('shared.directory.loadingPlacesDetails.modals.archive').pipe(
          filter(result => Boolean(result)),
          switchMap(() => {
            const name = loadingPlace.pointName ?? '';
            return this.ownerDirectoryPlaceTypesService.sendLoadingUnloadingPlaceToArchive(parseInt(loadingPlace?.id ?? '', 10)).pipe(
              map(() => {
                this.alertService.openBasicSuccessAlert('owner.place.success.remove');
                return removeLoadingPlaceSuccess();
              }),
              catchError((error: Error) => {
                this.alertService.openBasicErrorAlert('owner.place.errors.remove');
                return of(removeLoadingPlaceFailure({ error }));
              }),
            );
          }),
        ),
      ),
    ),
  );

  constructor(
    public readonly actions$: Actions,
    private readonly confirmation: TnConfirmationService,
    private readonly ownerDirectoryPlaceTypesService: TnOwnerDirectoryPlaceTypesApiService,
    private readonly toastFacade: TnToasterFacade,
    private readonly alertService: TnAlertService,
  ) {}
}
