import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { IAgentCarrierEditInput, IAgentCarrierInput, LEGAL_FORM_CODES } from '@transport/ui-interfaces';

import { IOrganizationData } from '../../../profile/organization/organization.form';
import { IDirectoryState } from '../../directory-state.interface';
import { agentCarrierActions } from './actions/agent-carrier.actions';
import { agentCarrierSelectors } from './selectors/agent-carrier.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnAgentCarrierFacade {
  public agentCarrier$ = this.store.pipe(select(agentCarrierSelectors.agentCarrier));

  public legalForms$ = this.store.pipe(select(agentCarrierSelectors.legalForms));

  public loading$ = this.store.pipe(select(agentCarrierSelectors.loading));

  public organizationSearchError$ = this.store.pipe(select(agentCarrierSelectors.organizationSearchError));

  public companyName$ = this.store.pipe(select(agentCarrierSelectors.companyName));

  public getCompany$ = this.actions$.pipe(ofType(agentCarrierActions.getOrganizationInfoSuccess));

  public changeAgentCarrier$ = this.actions$.pipe(
    ofType(
      agentCarrierActions.addAgentCarrierSuccess,
      agentCarrierActions.editAgentCarrierSuccess,
      agentCarrierActions.removeAgentCarrierSuccess,
    ),
  );

  constructor(private readonly store: Store<IDirectoryState>, private readonly actions$: Actions) {}

  public getAgentCarrier(id?: string) {
    this.store.dispatch(agentCarrierActions.getAgentCarrier({ id }));
  }

  public addAgentCarrier(agentCarrier: IAgentCarrierInput) {
    this.store.dispatch(agentCarrierActions.addAgentCarrier({ agentCarrier }));
  }

  public editAgentCarrier(agentCarrier: IAgentCarrierEditInput) {
    this.store.dispatch(agentCarrierActions.editAgentCarrier({ agentCarrier }));
  }

  public removeAgentCarrier(id: string) {
    this.store.dispatch(agentCarrierActions.removeAgentCarrier({ id }));
  }

  public getLegalForms() {
    this.store.dispatch(agentCarrierActions.getLegalForms());
  }

  public getOrganizationInfo(query: string, legalFormCode: LEGAL_FORM_CODES, carrierName: string) {
    this.store.dispatch(agentCarrierActions.getOrganizationInfo({ query, legalFormCode, carrierName }));
  }

  public clearOrganizationError() {
    this.store.dispatch(agentCarrierActions.clearOrganizationError());
  }

  public clearOrganization() {
    this.store.dispatch(agentCarrierActions.getOrganizationInfoSuccess({ company: {} as IOrganizationData }));
  }
}
