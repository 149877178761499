import { Component, Input, OnInit } from '@angular/core';
import { TnCurrentUserService } from '@marketplace/auth';
import { FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LEGAL_FORMS } from '../../verification-modal/verification-modal.component';

@UntilDestroy()
@Component({
  selector: 'verification-organization-form',
  templateUrl: 'organization-form.component.html',
  styleUrls: ['organization-form.component.scss'],
})
export class TnOrganizationFormComponent implements OnInit {
  @Input() form!: FormGroup;

  legalForm = this.currentUserService.profile$.pipe(map(profile => profile.organization?.legalForm));

  constructor(public currentUserService: TnCurrentUserService, public translate: TranslateService) {}

  ngOnInit() {
    this.legalForm.pipe(untilDestroyed(this)).subscribe(legalForm => {
      //Если тип организации - ИП
      if (this.isIndividualInterpreneur(legalForm) || this.isSelfEmployed(legalForm)) {
        this.form.controls.appointmentOrder.clearValidators();
        this.form.controls.regulation.clearValidators();
        if (this.isSelfEmployed(legalForm)) {
          this.form.controls.registrationCertificate.clearValidators();
          this.form.controls.selfEmployedReferrence.setValidators(Validators.required);
        }
      }
    });
  }

  validationErrors = (e: ValidationErrors | null) => {
    // if (e?.required) return this.translate.instant('shared.errors.required');
    if (e?.trimmedString) return this.translate.instant('shared.errors.trimmedString');
    if (e?.lessThanAdultAge) return this.translate.instant('shared.errors.lessThanAdultAge');
    if (e?.moreThanNow) return this.translate.instant('shared.errors.moreThanNow');
    if (e?.pattern) return this.translate.instant('shared.passport.passportCreateCompany.form.errors.innPhysOrLegal');
    if (e?.customPattern) return this.translate.instant('shared.errors.startEndSpaces');
    if (e?.maxlength)
      return this.translate.instant('shared.mp.verification.validationErrors.maxLength', { value: e?.maxlength.requiredLength });

    return '';
  }

  public isIndividualInterpreneur(legalForm) {
    return legalForm?.id == LEGAL_FORMS.INDIVIDUAL_INTERPRENEUR;
  }
  public isSelfEmployed(legalForm) {
    return legalForm?.id == LEGAL_FORMS.SELFEMPLOYED;
  }
}
