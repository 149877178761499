<div class="wrapper" [ngClass]="{'info-title': isInfoTitle}">
  <div class="page-title__text">
    <h2 *ngIf="!isInfoTitle">{{ title }}</h2>
    <span class="info-title__text" *ngIf="isInfoTitle">{{ title }}</span>
  </div>
  <ng-content></ng-content>
  <mat-icon
    class="page-title__icon"
    *ngIf="hasDescription"
    svgIcon="info"
    transportTooltip
    [templateRef]="popup"
    [context]="{ paragraphs: descriptionParagraphs }"
    [shouldShowByClick]="true"
    [isPopup]="true"
    tipPosition="after"
    containerClass="page-title-info-popup"
  ></mat-icon>
</div>

<ng-template #popup let-paragraphs="paragraphs">
  <div class="title-info-popup">
    <div *ngFor="let paragraph of paragraphs" class="title-info-popup__paragraph">
      {{ paragraph }}
    </div>
  </div>
</ng-template>
