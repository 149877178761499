<h2>{{ isRegForm ? ('shared.auth.registration.form.title' | translate) : ('shared.auth.login.form.title' | translate)}}</h2>
<form>
  <div class="back" (click)="goBack()">
    <mat-icon>arrow_back</mat-icon>
    <span>{{ 'shared.auth.submitCode.back' | translate }}</span>
  </div>
  <h3>{{ 'shared.auth.submitCode.enterCode' | translate }}</h3>
  <p>
    {{ 'shared.auth.submitCode.sentToMail' | translate }} <br />
    {{email}}
  </p>
  <div class="code-input">
    <code-input #codeInput [codeLength]="4" [initialFocusField]="0" (codeCompleted)="onCodeCompleted($event)"></code-input>
  </div>
  <div *ngIf="loginErrorMsg" class="text-center">
    <span class="text-red-500 text-sm">{{loginErrorMsg}}</span>
  </div>
  <p *ngIf="timer">
    {{ 'shared.auth.submitCode.sendAgain' | translate }}:
    <span class="text-blue-500">{{timer}}</span>
  </p>
  <p *ngIf="!timer">
    <span class="text-blue-500 cursor-pointer" (click)="sendCode()">
      {{ 'shared.auth.submitCode.sendAgain' | translate }}
    </span>
  </p>
</form>

<ng-template #invite>
  <transport-alert title="{{'shared.passport.confirmEmail.invitationActivated' | translate}}" titleVariant="top" state="success">
    {{'shared.passport.confirmEmail.invitationOrdersAccess' | translate}}.
  </transport-alert>
</ng-template>
