import { ChangeDetectionStrategy, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';
import { TnAnalyticsService, TnToasterFacade } from '@transport/ui-store';

import { ACCEPTING_PLACE, ORDER_ALLOCATION_TYPE, TOAST_DURATION, TToast, USER_ROLE } from '@transport/ui-interfaces';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { transport } from '@transport/proto';

import { combineLatest, take, tap } from 'rxjs';
import { ALERT_STATE, ALERT_TITLE_VARIANT, ALERT_VARIANT, TnAlertService } from '@transport/ui-kit';
import { TnCurrentUserService } from 'libs/marketplace-auth/src/lib/shared/services/user/current-user.service';
import { TnDomainService } from '@transport/ui-services';
import { LOCAL_HOST_DOMAIN, SERVER_MARKETPLACE_URL } from '@transport/ui-utils';
import { TnNotificationsFunctionality } from '../../notifications-functionality.service';
import { PopupNotificaions } from './notifications-popup.service';
import { NotificaionsService } from '../notifications/notifications.service';
import { INotification, NOTIFICATION_TOPIC, getNotificationEntitySearch, getNotificationEntityUrl } from '../../notifications.utils';

@UntilDestroy()
@Component({
  selector: 'notifications-popup',
  templateUrl: './notifications-popup.component.html',
  styleUrls: ['./notifications-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationsPopupComponent implements OnInit {
  @Input() withTitle = false;
  @Input() isMarketplace = false;
  public notifications$ = this.popupNotifications.notifications$;

  public isLoading = false;

  public count$ = this.popupNotifications.count$;

  public unreadCount$ = this.popupNotifications.unreadCount$;

  public readonly unreadCountMax = 99;

  public userRole = this.currentUser.currentUserRole || USER_ROLE.CARGO_OWNER;

  @ViewChild(MatMenuTrigger)
  public matMenuTrigger?: MatMenuTrigger;

  constructor(
    private readonly domainService: TnDomainService,
    public popupNotifications: PopupNotificaions,
    protected notificationsFacade: NotificaionsService,
    private readonly currentUser: TnCurrentUserService, // сервис только для маркетплейса
    private readonly toaster: TnToasterFacade,
    private readonly router: Router,
    private readonly notificationFunctionality: TnNotificationsFunctionality,
    private readonly translate: TranslateService,
    private readonly alert: TnAlertService,
    private readonly analytics: TnAnalyticsService,
  ) {}

  public ngOnInit() {
    this.popupNotifications.loadOnPopupNotifications().subscribe();
    combineLatest([this.notificationsFacade.showWarningPopup$, this.notifications$])
      .pipe(untilDestroyed(this))
      .subscribe(([isShow, notifications]) => {
        if (isShow) {
          const filtered = notifications.filter(n => !n.isRead);
          if (filtered[0]) {
            this.orderToast(filtered[0] as INotification);
          }
        }
      });
    this.popupNotifications.isLoading$.pipe(untilDestroyed(this)).subscribe(isLoading => {
      this.isLoading = isLoading;
    });
  }

  public orderToast(notification: INotification) {
    const content = this.getToastContent(notification);

    if (!content) return;
    this.notificationsFacade.showWarningPopup$.next(false);
    const route = this.userRole === USER_ROLE.OWNER ? `/order/view/${notification.payload?.id}` : `/orders/${notification.payload?.id}`;
    if (content?.alwaysShow || route !== this.router.url) {
      this.toaster.showRedirectMessage(
        route,
        this.translate.instant(`shared.orderPage.documentsCard.notification.${content.translate}`, {
          value: notification.payload?.external_no || notification.payload?.id || notification.title,
          data: notification.payload?.changed_data || '',
        }),
        content.type,
        content.duration ?? TOAST_DURATION.SHORT,
        ['mp-custom-snackbar'],
        !(notification.payload?.external_no || notification.payload?.id)
          ? ''
          : this.translate.instant('shared.orderPage.documentsCard.notification.redirect'),
      );
    }
  }

  public getToastContent(notification: transport.INotification): {
    translate: string;
    type: TToast;
    duration?: TOAST_DURATION;
    alwaysShow?: boolean;
  } | null {
    switch (notification.action) {
      case 'documents_created':
        return {
          translate: 'documentsReady',
          type: 'accent',
        };
      case 'violation_of_criteria':
        return {
          translate: 'violationOfCriteria',
          type: 'warning',
        };
      case 'order_loading_place_time_changed':
        return {
          translate: 'orderLoadingPlaceTimeChanged',
          type: 'warning',
          duration: TOAST_DURATION.DEFAULT,
          alwaysShow: true,
        };
      case 'import_finished':
      case 'import_error':
        return {
          translate: 'importFinished',
          type: 'accent',
          alwaysShow: true,
        };
      case 'order_data_changed':
      case 'order_price_changed':
        return {
          translate: 'orderDataChanged',
          type: 'warning',
          duration: TOAST_DURATION.DEFAULT,
          alwaysShow: true,
        };
      default:
        return null;
    }
  }

  public markAsRead(notification: INotification, event: Event) {
    if (!notification?.isRead) {
      this.popupNotifications.markAsRead(notification).subscribe();
    }
    event.stopPropagation();
  }

  public viewAll() {
    this.popupNotifications.goByPath(['/notifications'], {});
    this.matMenuTrigger?.closeMenu();
  }

  public markAllAsRead() {
    this.popupNotifications.markAllAsRead().subscribe();
  }

  public pullNotifications() {
    this.analytics.track('user open notifications popup ');
    this.popupNotifications.loadOnPopupNotifications().subscribe();
  }

  public goToEntity(notification: INotification, event: Event) {
    //this.markAsRead(notification, event);
    this.analytics.track('user goes to notification entity from popup ');
    const urlPath = getNotificationEntityUrl(notification, this.userRole, false);
    const query = getNotificationEntitySearch(notification, this.userRole);

    if (
      notification.topic === NOTIFICATION_TOPIC.ORDER ||
      notification.topic === NOTIFICATION_TOPIC.AUCTION ||
      notification.topic === NOTIFICATION_TOPIC.BIDDING
    ) {
      this.processEnterInOrderEntity(notification);
    } else if (notification.topic === NOTIFICATION_TOPIC.COMPLETED_ORDERS || notification.topic === NOTIFICATION_TOPIC.PRETENSION) {
      this.userRole === USER_ROLE.OWNER
      ? this.goByPath([urlPath, String(notification.payload?.id)], query)
      : this.openNewTabWithSubdomain(notification.payload?.owner_subdomain ?? '', notification.payload?.id ?? '', urlPath);
      return;
    } else if (notification.topic === NOTIFICATION_TOPIC.COMMON_TENDER) {
      notification.payload?.subdomain
        ? this.openNewTabWithSubdomain(notification.payload?.subdomain, notification.payload?.id ?? '', urlPath)
        : this.goByPath([urlPath, String(notification.payload?.id)], query);
      return;
    } else if (notification.topic === NOTIFICATION_TOPIC.CONTRACT) {
      this.goByPath([urlPath], query);
    } else if (notification.topic === NOTIFICATION_TOPIC.ACCREDITATION) {
      this.goByPath([urlPath], query);
    } else {
      this.goByPath([urlPath, String(notification.payload?.id)], query);
    }

    this.matMenuTrigger?.closeMenu();
  }

  public openNewTabWithSubdomain(subdomain: string, id: string, path: string): void {
    if (subdomain) {
      const localHostPort = this.domainService.domain.includes(LOCAL_HOST_DOMAIN) ? ':4300' : '';
      const hasMarketSubdomain = this.domainService.domain.includes(SERVER_MARKETPLACE_URL);
      const marketSubdomain = hasMarketSubdomain ? '' : `${SERVER_MARKETPLACE_URL}.`;
      const url = `${window.location.protocol}//${subdomain}.${marketSubdomain}${this.domainService.domain}${localHostPort}/${path}${id}`;
      window.open(url, '_blank');
    }
  }

  private processEnterInOrderEntity(notification: INotification) {
    const orderId = (notification.payload as { id }).id;
    this.notificationFunctionality
      .isOrderAvailable(orderId)
      .pipe(take(1))
      .subscribe(isOrderAvailable => {
        if (!isOrderAvailable) {
          const allocation = notification.topic;
          this.alert.openAlert(
            {
              titleVariant: ALERT_TITLE_VARIANT.NONE,
              state: ALERT_STATE.ERROR,
              variant: ALERT_VARIANT.SOLID,
              message: `${
                allocation === 'order' ? 'Заявка' : allocation === 'auction' ? 'Аукцион' : 'Сбор предложений'
              } №${orderId} вам более ${allocation === 'order' ? 'недоступна' : 'недоступен'}.`,
            },
            TOAST_DURATION.SHORT,
          );
          return;
        }
        if (isOrderAvailable?.allocationType === ORDER_ALLOCATION_TYPE.AUCTION_ALLOCATION) notification.topic = NOTIFICATION_TOPIC.AUCTION;
        if (
          isOrderAvailable?.allocationType === ORDER_ALLOCATION_TYPE.SIMPLE_ALLOCATION ||
          isOrderAvailable?.allocationType === ORDER_ALLOCATION_TYPE.PREASSIGNED_ALLOCATION
        ) {
          notification.topic = NOTIFICATION_TOPIC.ORDER;
        }

        const urlPath = getNotificationEntityUrl(notification, this.userRole, false);
        const query = getNotificationEntitySearch(notification, this.userRole);
        this.goByPath([urlPath, (notification.payload as { id }).id], { ...query, area: ACCEPTING_PLACE.PARTNERS });
      });
  }

  private goByPath(path: string[], query: Record<string, unknown>) {
    if (this.userRole === USER_ROLE.CARGO_OWNER && this.isMarketplace) {
      const localHostPort = this.domainService.domain.includes('lv4.lv3.lv2.localhost') ? ':4200' : '';
      const url = `${window.location.protocol}//${this.domainService.marketPlaceCargoOwnerSubdomain}.${this.domainService.domain.replace(
        'market.',
        '',
      )}${localHostPort}${path[0]}/${path[1]}`;
      window.open(url, '_blank');
      return;
    }
    this.notificationsFacade.goByPath(path, query);
  }
}
