export const STORAGE_POINT_FRAGMENT = `
  id
  pointName
  settlement
  companyName
  address
  workingHoursFrom
  workingHoursTo
  drivingDirectionsImg
  isArchived
  name
  longitude
  latitude
  fiasCode
  typePoint
  contacts {
    id
    firstName
    lastName
    phoneNumber
  }
  timezone {
    id
    settlement
    timezone
  }
`;

export const AUCTION_PARAMETERS_FRAGMENT = `
  duration
  earlyFinish
  typeOfPriceStep
  minStepPrice
  minPriceStepPercent
  isProlongationAvailable
  minutesOfProlongation
  timesOfProlongation
  minutesTillEndToProlong
  earlyFinish
`;

export const LEGAL_FORM_FRAGMENT = `
  id
  shortName
  name
  code
`;

export const PAYMENT_TYPE_FRAGMENT = `
  id
  name
`;

export const ORGANIZATION_SHORT_FRAGMENT = `
  documentFlowSettings
  requiresCarrierAccrOnMarket
  id
  name
  isTn
`;

export const ORGANIZATION_GROUP_FRAGMENT = `
     id
    name
    signer
    about
    phoneNumber
    email
    isCompanyGroup
    inn
    kpp
    legalAddress
    registrationNumber
    contracts {
        contractNumber
        startDate
        endDate
        name
        file
    }
    ogrnDate
    headOfOrganization {
        id
        name
        surname
        patronymic
        inn
    }

`;

export const CARRIER_ORGANIZATION_FRAGMENT = `
  id
  name
  isTn
  isArchived
  role
  signer
  signerPerson {
    fullName
    phoneNumber
    email
    country
  }
  about
  phoneNumber
  email
  isCompanyGroup
  accrAtDt
  accrValue
  accrExpAt
  accrProfile
  accrStatus
  legalForm {
    ${LEGAL_FORM_FRAGMENT}
  }
  country
  bin
  iin
  inn
  kpp
  legalAddress
  postalAddress
  registrationNumber
  ogrnDate
  vat {
    id
    name
  }
  auctionParameters {
    ${AUCTION_PARAMETERS_FRAGMENT}
  }
  insuranceParameters {
    insureByDefault
  }
  showOrderExternalNo
  paymentPeriod
  paymentType {
    ${PAYMENT_TYPE_FRAGMENT}
  }
  extraConditionsForMarket
  enableDeliveryTracking
`;

export const OWNER_ORGANIZATION_FRAGMENT = `
  id
  name
  documentFlowSettings
  carrierContractRequired
  addressWarningLevel
  isTn
  isArchived
  role
  signer
  email
  phoneNumber
  cargoRequired
  driverRequiredFields
  signerPerson {
    fullName
    phoneNumber
    email
    country
  }
  about
  isCompanyGroup
  legalForm {
    ${LEGAL_FORM_FRAGMENT}
  }
  country
  bin
  iin
  inn
  kpp
  legalAddress
  postalAddress
  registrationNumber
  vat {
    id
    name
  }
  currency
  auctionParameters {
    ${AUCTION_PARAMETERS_FRAGMENT}
    additionalAuctionCriteriaRequired
  }
  insuranceParameters {
    insureByDefault
  }
  showOrderExternalNo
  paymentPeriod
  paymentType {
    ${PAYMENT_TYPE_FRAGMENT}
  }
  extraConditionsForMarket
  enableDeliveryTracking
  accrStatus
  trialPeriodExpAt
  subdomain
  facsimileImg
`;

export const ALLOWED_EXTRA_SERVICES = `
  allowedExtraServices {
    id
    name
    price
    vatAmount
  }
`;

export const PRICE_QUOTATION_RESPONSE = `
  id
  organization {
    ${OWNER_ORGANIZATION_FRAGMENT}
  }
  price
  isVatIncluded
  comment
`;

export const VEHICLE_REQUIREMENTS_FRAGMENT = `
  containerType
  bodySubtypes {
    id
    name
  }
  capacity
  volume
  length
  width
  height
  loadingTypes {
    id
    name
  }
  deliveryMethod
  temperatureModeFrom
  temperatureModeTo
  specialRequirements
`;
export const CARGOS_FRAGMENT = `
  cargoInfo
  cargos {
    cargoId
    name
    status
    loadingPointId
    size {
      length
      width
      height
      weight
      volume
      packageType
      quantity
      setByWaybill
    }
    deliveryUnits {
      unitId
      cargoId
      deliveryPointId
      status
      size {
        length
        width
        height
        volume
        weight
        packageType
        quantity
        setByWaybill
      }
    }
  }
`;

const PRICE_QUOTATION_FRAGMENT_COMMON = `
  id
  organization {
    ${OWNER_ORGANIZATION_FRAGMENT}
  }
  loadingPlace
  loadingPlaceFiasCode
  unloadingPlace
  unloadingPlaceFiasCode
  vehicleRequirements {
    ${VEHICLE_REQUIREMENTS_FRAGMENT}
  }
  endDateTime
  priceStep
  comment
`;

export const getMyCarrierGroupFragment = (short = false) => `
  id
  name
  ${
    !short
      ? `isArchived
        carriers {
        ${ORGANIZATION_GROUP_FRAGMENT}
        }
        carriersQuantity`
      : ''
  }
`;

export const PRICE_QUOTATION_FRAGMENT_OWNER = `
  ${PRICE_QUOTATION_FRAGMENT_COMMON}
  responses {
    ${PRICE_QUOTATION_RESPONSE}
  }
  cargoOwner {
    id
    companyName
    email
    firstName
    lastName
  }
  carrierGroup {
    ${getMyCarrierGroupFragment(true)}
  }
`;

export const ORDER_DOCUMENT_TYPE_FRAGMENT = `
  id
  name
  innerName
  isVisible
  innerName
`;

export const WAYBILL_INFO_FRAGMENT = `
  dateFrom
  dateTo
  orderStoragePoint{
    storagePoint {
      ${STORAGE_POINT_FRAGMENT}
    }
    arriveDatetimeFrom
    arriveDatetimeTo
    factArriveDatetimeFrom
    factArriveDatetimeTo
    loadingType {
      id
      name
    }
    attachedDocuments {
      filename
      url
    }
  }
`;

export const UPLOADED_FILE_FRAGMENT = `
  id
  createdAt
  name
  url
`;

export const ORDER_UPLOADED_DOCUMENT_FRAGMENT = `
  id
  organization {
    role
  }
  documentType{
    ${ORDER_DOCUMENT_TYPE_FRAGMENT}
  }
  uploadedFile{
    ${UPLOADED_FILE_FRAGMENT}
  }
  signedFile {
    id
    documentType{
      ${ORDER_DOCUMENT_TYPE_FRAGMENT}
    }
    uploadedFile{
      ${UPLOADED_FILE_FRAGMENT}
    }
  }
  deletable
  waybillInfo {
    ${WAYBILL_INFO_FRAGMENT}
  }
`;

export const PRICE_QUOTATION_FRAGMENT_CARRIER = `
  ${PRICE_QUOTATION_FRAGMENT_COMMON}
  response {
    ${PRICE_QUOTATION_RESPONSE}
  }
`;

export const INSURANCE_REFERENCE = `
  code
  value
`;

export const ROUTE_SHEET_EVENT = `
  id
  event
  longitude
  latitude
  dateTime
  issueTopic
  issueText
  sourceInfo
  storagePoint {
    id
    latitude
    longitude
    pointName
    address
    timezone {
      id
      settlement
      timezone
    }
  }
  documents {
    filename
    url
    datetime
  }
`;

export const ROUTE_SHEET = `
  id
  status
  events {
    ${ROUTE_SHEET_EVENT}
  }
  documents {
    filename
    url
    datetime
  }
`;

export const ORDER_FRAGMENT_DRIVER = `
  id
  surname
  name
  patronymic
  phone
  iin
  passportSeries
  passportNumber
  passportPlace
  passportDate
  licenceSeries
  licenceNumber
  licenceDate
  licencePlace
  usesDriverMobile`;

export const STORAGE_POINTS_QUERY_TYPE_FRAGMENT = `
  $first: Int
  $offset: Int
  $withArchived: Boolean
  $typePoint: StoragePointTypePoint
  $pointName: String
  $organizationName: String
  $inn: ID
  $settlement: String
  $address: String
  $totalCount: Boolean = false
  $selectMode: Boolean = false`;

export const STORAGE_POINTS_QUERY_BODY_FRAGMENT = `
  first: $first
  offset: $offset
  typePoint: $typePoint
  withArchived: $withArchived
  pointName: $pointName
  organizationName: $organizationName
  inn: $inn
  settlement: $settlement
  address: $address`;

export const STORAGE_POINTS_QUERY_RESPONSE_FRAGMENT = {
  main: `
    id
    pointName
    companyName
    address
    drivingDirectionsImg @skip(if: $selectMode)
    isArchived @skip(if: $selectMode)
    drivingDirectionsImg
    name
    settlement
    fiasCode
    latitude
    longitude
    contacts {
      id
      fullName
      firstName
      lastName
      phoneNumber
    }
    timezone {
      id
      settlement
      timezone
    }
    actions
    type
    extPointId`,
  totalCount: `
    typePoint: $typePoint
    withArchived: $withArchived
    pointName: $pointName
    organizationName: $organizationName
    inn: $inn
    settlement: $settlement
    address: $address
    ) @include(if: $totalCount)`,
};
