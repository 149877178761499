import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { ITnState } from '../../state/index';
import { TnTableStateFacade } from '../../table/table-state-facade';
import { tableActions } from './actions/owner-users.actions';
import { tableSelectors } from './selectors/owner-users.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnAdminOwnerUsersFacade extends TnTableStateFacade {
  constructor(public store: Store<ITnState>) {
    super(store, tableActions, tableSelectors);
  }
}
