<div [class]="wrapperClass">
  <div class="flex items-center text-sm leading-5 font-normal"
       [matTooltip]="bodySubtypesAsString"
       matTooltipShowDelay="0"
       matTooltipHideDelay="0">
    <div class="text-gray-700 whitespace-nowrap overflow-hidden text-ellipsis mr-1">
      {{ orderBodySubtypesShort?.firstType }}
    </div>
    <div *ngIf="orderBodySubtypesShort?.additionalCount" class="text-blue-500 whitespace-nowrap">+{{ orderBodySubtypesShort?.additionalCount }}</div>
  </div>
  <div class="flex gap-1 flex-wrap">
    <marketplace-table-tag *ngFor="let type of arrayOfLoadingTypes" [text]="type"></marketplace-table-tag>
  </div>
</div>

