import { Injectable } from '@angular/core';
import {
  DEMO_DOMAIN_URL,
  DEV_DOMAIN_URL,
  DEV1_DOMAIN_URL,
  DEV_MARKETPLACE_URL,
  DEV1_MARKETPLACE_URL,
  TEST_MARKETPLACE_URL,
  DEMO_MARKETPLACE_URL,
  STAGE_MARKETPLACE_URL,
  LOADTEST_MARKETPLACE_URL,
  PROD_DOMAIN_URL,
  STAGE_DOMAIN_URL,
  STAGE_DEMO_DOMAIN_URL,
  STAGING_DOMAIN_URL,
  LOADTEST_DOMAIN_URL,
  DEVOPS_DOMAIN_URL,
  TEST_DOMAIN_URL,
  LOCAL_HOST_DOMAIN,
  DEL01_DOMAIN_URL,
  DEL02_DOMAIN_URL,
  DEL_DOMAIN_URL,
  PROD_MARKETPLACE_URL,
  SERVER_MARKETPLACE_URL,
} from '@transport/ui-utils';

@Injectable({
  providedIn: 'root',
})
export class TnDomainService {
  private readonly domains = [
    DEV_MARKETPLACE_URL,
    DEV_DOMAIN_URL,
    DEV1_DOMAIN_URL,
    PROD_MARKETPLACE_URL,
    PROD_DOMAIN_URL,
    TEST_DOMAIN_URL,
    DEMO_DOMAIN_URL,
    STAGING_DOMAIN_URL,
    STAGE_DOMAIN_URL,
    STAGE_DEMO_DOMAIN_URL,
    LOADTEST_DOMAIN_URL,
    DEVOPS_DOMAIN_URL,
    DEV_MARKETPLACE_URL,
    DEV1_MARKETPLACE_URL,
    TEST_MARKETPLACE_URL,
    DEMO_MARKETPLACE_URL,
    STAGE_MARKETPLACE_URL,
    LOADTEST_MARKETPLACE_URL,
    LOCAL_HOST_DOMAIN,
    DEL01_DOMAIN_URL,
    DEL02_DOMAIN_URL,
    DEL_DOMAIN_URL,
    'test-mob-tms.tn-dl.ru',
    'market.test-mob-tms.tn-dl.ru',
    // Lines for new env domains, dont delete!
    // NEW_ENV_DOMAINS
    "redos-tms.tn-dl.ru",
    "market.redos-tms.tn-dl.ru",
    "astra-tms.tn-dl.ru",
    "market.astra-tms.tn-dl.ru",
    "dev-4-tms.tn-dl.ru",
    "market.dev-4-tms.tn-dl.ru",
    "dev-3-tms.tn-dl.ru",
    "market.dev-3-tms.tn-dl.ru",
    "dev-2-tms.tn-dl.ru",
    "market.dev-2-tms.tn-dl.ru",
  ];

  // Для корретктного перехода из marketplace в MasterTMS с сохранением пути и параметров
  public marketPlaceCargoOwnerSubdomain: string = '';


  public get domain(): string {
    const hostname = window.location.hostname;
    const index = this.getMainDomainIndexStart(hostname);
    if (index > 0) {
      return hostname.slice(index);
    }
    return hostname;
  }

  //Using only for Master TMS
  public get marketDomain(): string {
    const hostname = window.location.hostname;
    const index = this.getMainDomainIndexStart(hostname);

    if (index > 0) {
      return `${hostname.substring(0, index)}${SERVER_MARKETPLACE_URL}.${hostname.substring(index)}`;
    }
    return `${SERVER_MARKETPLACE_URL}.${hostname}`;
  }

  public get subDomain(): string | null {
    const mainDomainIndexStart = window.location.hostname.search(this.domain);
    if (mainDomainIndexStart !== -1 && mainDomainIndexStart !== 0) {
      const subdomain = window.location.hostname.slice(0, mainDomainIndexStart - 1);
      if (subdomain === SERVER_MARKETPLACE_URL || subdomain === 'www') return null;
      const partial = subdomain.split('.');
      if (partial.length) return partial[0];
      return subdomain;
    }
    return null;
  }

  private getMainDomainIndexStart(hostname: string): number {
    let mainDomainIndexStart = -1;
    this.domains.sort().some((dm: string) => {
      const index = hostname.search(dm);
      if (index !== -1) {
        if (index === 0) {
          // без сабдомена
          mainDomainIndexStart = index;
          return true;
        }
        if (index !== 0 && hostname[index - 1] === '.' && !hostname.startsWith(SERVER_MARKETPLACE_URL)) {
          mainDomainIndexStart = index;
          return true;
        }
        return false;
      }
      return false;
    });
    return mainDomainIndexStart;
  }
}
