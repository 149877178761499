import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export type TWarningMessageType = 'warning' | 'alert' | 'info' | 'note';

export interface IWarningMessage {
  type: TWarningMessageType;
  text: string;
  icon?: string;
  hasDetailsAction?: boolean;
}

@Component({
  selector: 'transport-warning-message',
  templateUrl: './warning-message.component.html',
  styleUrls: ['./warning-message.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnWarningMessageComponent {
  @Input() public dataSource?: IWarningMessage;

  @Output() public readonly showDetails = new EventEmitter<void>();
}
