import { createSelector } from '@ngrx/store';

import { makeTableSelectors } from '../../table/table-selectors';
import * as fromFeature from './index';

const selectReconciliationActTableFeatureState = createSelector(
  fromFeature.selectReconciliationActFeatureState,
  state => state.reconciliationActTable,
);

export const selectors = makeTableSelectors(selectReconciliationActTableFeatureState);
