import { Component } from '@angular/core';
import { TnCurrentUserService } from '@marketplace/auth';
import { takeUntil } from 'rxjs';
import { DriversAmountService } from '../filters/filters-toolbar/drivers-amount.service';
import { DriversTableService } from '../table/drivers-table.service';
import { TnCurrentUserFacade } from '@transport/ui-store';
import { DriversService } from '../drivers.service';
import { USER_ROLE } from '@transport/ui-interfaces';
import { DriverService } from 'libs/marketplace-shared/src/lib/edit-driver/driver.service';
import { ActivatedRoute } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'drivers-table-page',
  templateUrl: './drivers-table-page.component.html',
  providers: [DriverService, DriversTableService]
})
export class DriversTablePageComponent {

  public canManage = true;
  constructor(
    public driverTableService: DriversTableService,
    public driverService: DriverService,
    private driversAmountService: DriversAmountService,
    public currentUserService: TnCurrentUserService,
    public userFacade: TnCurrentUserFacade,
    private driversPageService: DriversService,
    private route: ActivatedRoute
  ) {
    this.route.data.pipe(untilDestroyed(this)).subscribe(res => {
      this.driversPageService.role$.next(res?.roles?.includes(USER_ROLE.CARRIER) ? USER_ROLE.CARRIER : USER_ROLE.CARGO_OWNER);
    });
    this.userFacade.permissions$.subscribe(res => (this.canManage = !!res.generalReference?.manage || !!this.currentUserService.currentUserPermissions.generalReference?.manage));
  }


  createDriver() {
    this.driverService.openModal(this.driversPageService.role$.value as USER_ROLE);
    if (this.driverService.dialogRef)
      this.driverService.vm?.finish$
        .pipe(takeUntil(this.driverService.dialogRef?.afterClosed()))
        .subscribe(finished => {
          if (finished) {
            this.driverTableService.fetch();
            this.driversAmountService.updateDriversStatusCounters();
          }
        });
  }
}
