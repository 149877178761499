import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { transport } from '@transport/proto';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { RouterActions } from '../../ngrx-router';
import { TnGlobalErrorHandlerService } from '../../toaster';
import { adminProfileActions } from './admin-profile.actions';
import { TnAdminProfileFacade } from './admin-profile.facade';
import { TnAdminProfileApiService } from './services/admin-profile-api.service';
import { getCurrentUserUpdateVat, ITnState, TnCurrentUserFacade } from '@transport/ui-store';
import { Store } from '@ngrx/store';
import { TnAlertService } from '@transport/ui-kit';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TnAdminProfileEffects {
  public loadAdminProfile = createEffect(() =>
    this.action$.pipe(
      ofType(adminProfileActions.loadAdminProfileStart),
      switchMap(() =>
        this.adminProfileService.getOrganization().pipe(
          map(result => {
            return adminProfileActions.loadAdminProfileSuccess({
              profile: result,
            });
          }),
          catchError(error => {
            const message = this.globalErrorHandler.extractGqlErrorMessage(error);
            this.alertService.shortError(error.message);
            return of(adminProfileActions.loadAdminProfileFailure({ error }));
          }),
        ),
      ),
    ),
  );

  public loadAdminProfileSubOrganizations = createEffect(() =>
    this.action$.pipe(
      ofType(adminProfileActions.loadAdminProfileSuccess),
      filter(item => item.profile.isCompanyGroup ?? false),
      switchMap(() =>
        this.adminProfileService.getSubOrganizations().pipe(
          map(result =>
            adminProfileActions.loadAdminProfileSubOrganizationsSuccess({
              subOrganizations: result,
            }),
          ),
          catchError(error => {
            const message = this.globalErrorHandler.extractGqlErrorMessage(error);
            this.alertService.shortError(error.message);
            return of(adminProfileActions.loadAdminProfileSubOrganizationsFailure({ error }));
          }),
        ),
      ),
    ),
  );

  public saveAdminProfile = createEffect(() =>
    this.action$.pipe(
      ofType(adminProfileActions.saveAdminProfileStart),
      switchMap(action => of(action).pipe(withLatestFrom(this.facade.reactiveFormValue$))),
      switchMap(([{ formValue }, form]) => {
        // @ts-ignore
        let customForm = { ...form, ...formValue, facsimileImg: formValue.facsimileImg };
        if (typeof customForm.logo === 'string') {
          delete customForm.logo;
        } else if (!Boolean(customForm.logo)) {
          customForm.logo = null;
        } else if (typeof customForm.logo === 'object') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- TODO: tech debt
          // @ts-ignore
          customForm.logo = customForm.logo?.fileData;
        }

        if (typeof customForm.facsimileImg === 'string') {
          delete customForm.facsimileImg;
        } else if (!Boolean(customForm.facsimileImg)) {
          customForm.facsimileImg = null;
        } else if (typeof customForm.facsimileImg === 'object') {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment -- TODO: tech debt
          // @ts-ignore
          customForm.facsimileImg = customForm.facsimileImg?.fileData;
        }
        return this.adminProfileService.saveOrganization(customForm).pipe(
          map(result => {
            const vat = (result as any).editOrganization.vat;
            this.store.dispatch(getCurrentUserUpdateVat({ vat }));
            if (Boolean(formValue.subdomain) && form.subdomain !== formValue.subdomain) {
              window.location.reload();
            }
            this.alertService.shortSuccess(this.translate.instant('owner.profile.message.saveProfileSuccess'));

            return adminProfileActions.saveAdminProfileSuccess({
              profile: result,
            });
          }),
          catchError((error: transport.Error) => {
            if (error.extensions?.param === 'orderDuration') {
              this.alertService.shortError(this.translate.instant('owner.profile.error.orderDuration'));
            } else if (error.message) {
              this.alertService.shortError(error.message);
            }
            return of(adminProfileActions.saveAdminProfileFailure({ error }));
          }),
        );
      }),
    ),
  );

  public saveAdminProfileSuccess = createEffect(() =>
    this.action$.pipe(
      ofType(adminProfileActions.saveAdminProfileSuccess),
      tap(() => void this.userFacade.restoreUserOnInit()),
      map(() => {
        return adminProfileActions.navigateToAction({ action: 'view' });
      }),
    ),
  );

  public navigateTo = createEffect(() =>
    this.action$.pipe(
      ofType(adminProfileActions.navigateToAction),
      map(({ action }) => RouterActions.routerGo({ path: ['/profile/owner', action] })),
    ),
  );

  constructor(
    private readonly adminProfileService: TnAdminProfileApiService,
    private readonly action$: Actions,
    private readonly facade: TnAdminProfileFacade,
    private readonly globalErrorHandler: TnGlobalErrorHandlerService,
    private readonly store: Store<ITnState>,
    private readonly userFacade: TnCurrentUserFacade,
    private alertService: TnAlertService,
    private translate: TranslateService,
  ) {}
}
