import { Component, Input, OnInit } from '@angular/core';
import { DriversTableService } from './drivers-table.service';
import { BehaviorSubject, filter, map, Observable, switchMap, take, takeUntil } from 'rxjs';
import { IColumnConfig } from '@marketplace/shared/modules/table';
import { IDriverInTable } from './drivers-table.interface';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { PaginatedArray } from '@marketplace/shared/modules/table';
import { DriverService } from '@marketplace/shared';
import { TranslateService } from '@ngx-translate/core';
import { TnConfirmationService } from '@transport/ui-store';
import { IDriver, USER_ROLE } from '@transport/ui-interfaces';
import { TnDriverInviteModal } from '@transport/ui-components';
import { DIALOG_WIDTH_DEFAULT } from '@transport/ui-utils';
import { MatDialog } from '@angular/material/dialog';
import { TnCurrentUserService } from '@marketplace/auth';
import { DriversAmountService } from '../filters/filters-toolbar/drivers-amount.service';
import { TnDomainService } from '@transport/ui-services';
import { DriversService } from '../drivers.service';

@UntilDestroy()
@Component({
  selector: 'drivers-table',
  templateUrl: './drivers-table.component.html',
  styleUrls: ['./drivers-table.component.scss'],
})
export class DriversTableComponent implements OnInit {
  public columnConfigs: IColumnConfig[] = [
    { name: 'driver', header: this.translate.instant('shared.mp.drivers.table.header.driver'), minWidth: 150, width: 150 },
    { name: 'agentCarrier', header: this.translate.instant('shared.mp.drivers.table.header.agentCarrier'), minWidth: 200, width: 200},
    { name: 'mobile_app', header: this.translate.instant('shared.mp.drivers.table.header.mobileApp'), minWidth: 110, width: 110 },
    { name: 'accrStatus', header: this.translate.instant('shared.mp.drivers.table.header.accrStatus'), width: 120 },
  ];

  public isAgentOrExpeditor= Boolean(this.currentUserService.currentUser.isAgentOrExpeditor);

  _canManage = false;

  @Input() public set canManage(canManage: boolean) {
    if (canManage) {
      this.columnConfigs = [...this.columnConfigs, { name: 'btns', width: 50 }];
    } else {
      this.columnConfigs = this.columnConfigs.filter(column => column.name !== 'btns');
    }
    this._canManage = canManage;
  }

  public get canManage() {
    return this._canManage;
  }

  public drivers$!: BehaviorSubject<PaginatedArray<IDriverInTable>>;
  public driversDataSource$!: Observable<IDriverInTable[]>;

  public ngOnInit() {
    this.drivers$ = this.driverTableService.list;
    this.driversDataSource$ = this.drivers$.pipe(map(data => data.items));
    this.columnConfigs = this.columnConfigs.filter(item => {
      if (!this.isAgentOrExpeditor) {
        return item.name !== 'agentCarrier';
      } else return true;
    })
  }

  public rowClasses = (driver: IDriverInTable) => {
    const result: string[] = [];
    if (driver.isArchived) {
      result.push('disabled');
    }
    return result;
  };

  constructor(
    private readonly translate: TranslateService,
    private readonly domainService: TnDomainService,
    private readonly confirmation: TnConfirmationService,
    private readonly driversAmountService: DriversAmountService,
    public dialog: MatDialog,
    private currentUserService: TnCurrentUserService,
    private driversPageService: DriversService,
    public driverTableService: DriversTableService,
    public driverService: DriverService,
  ) {
    if (this.domainService.subDomain) {
      this.columnConfigs = this.columnConfigs.filter(column => column.name !== 'accrStatus');
    }
  }

  public onDelete = ($event: MouseEvent, driver: IDriverInTable) => {
    $event.stopPropagation();
    this.confirmation.openByPrefix('shared.mp.drivers.confirmDelete')
      .pipe(
        filter(result => Boolean(result)),
        switchMap(() => this.driverService.sendDriverToArchive(this.driversPageService.role$.value as USER_ROLE, driver.id)),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.driverTableService.fetch();
        this.driversAmountService.updateDriversStatusCounters();
      });
  };

  public onRowClick = (click: { rowData: IDriverInTable; event: Event }) => {
    if (click.rowData.isArchived) return;
    if (!this.canManage) return;

    this.driverService.openModal(this.driversPageService.role$.value as USER_ROLE, click.rowData.id);
    this.fetchOnModalDestroy();
  };

  public onEdit = ($event: MouseEvent, driver: IDriverInTable) => {
    this.driverService.openModal(this.driversPageService.role$.value as USER_ROLE, driver.id);
    this.fetchOnModalDestroy();
  };

  public inviteDriver(driver: IDriver | null) {
    const { hasBeenInvitedToDriverMobile, id } = driver as IDriver;
    this.dialog.open(TnDriverInviteModal, {
      width: `${DIALOG_WIDTH_DEFAULT}px`,
      data: { hasBeenInvitedToDriverMobile, driverId: id, role: this.driversPageService.role$.value as USER_ROLE},
    }).afterClosed()
      .pipe(untilDestroyed(this)).subscribe(() => {
        this.driverTableService.fetch();
        this.driversAmountService.updateDriversStatusCounters();
      });
  }

  public restoreDriver(driver: IDriverInTable) {
    this.driverService.restoreDriver(this.driversPageService.role$.value as USER_ROLE, driver.id)
      .pipe(take(1))
      .subscribe(() => {
        this.driverTableService.fetch();
        this.driversAmountService.updateDriversStatusCounters();
      });
  }

  private fetchOnModalDestroy() {
    if (this.driverService.dialogRef)
      this.driverService.vm?.finish$
        .pipe(takeUntil(this.driverService.dialogRef.afterClosed()))
        .subscribe(finished => finished && this.driverTableService.fetch());
  }
}
