import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TnFiasEffects } from './fias.effects';
import { fiasReducer } from './fias.reducers';

const FIAS_EFFECTS = [TnFiasEffects];

@NgModule({
  imports: [StoreModule.forFeature('fias', fiasReducer), EffectsModule.forFeature(FIAS_EFFECTS)],
  exports: [StoreModule, EffectsModule],
})
export class TnFiasModule {}
