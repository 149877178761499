<button class="flex items-center"[matMenuTriggerFor]="notificationsPopup" (click)="pullNotifications()" data-test="show-notifications-popup-button">
  <div
    class="notification-btn"
    [matTooltip]="!withTitle ? ('shared.masterTms.sidebar.items.title.notifications' | translate) : ''"
    matTooltipShowDelay="0"
    matTooltipHideDelay="0"
  >
    <mat-icon svgIcon="sidebar-notifications" class="icon-notifications"></mat-icon>
    <span class="unread-count" data-test="unread-notifications-counter" *ngIf="unreadCount$ | async as unreadCount">{{
      unreadCount > unreadCountMax ? unreadCountMax : unreadCount
    }}</span>
  </div>
  <span *ngIf="withTitle" class="ml-2 text-sm">{{'shared.masterTms.sidebar.items.title.notifications' | translate}}</span>
</button>

<mat-menu #notificationsPopup="matMenu" xPosition="before" class="notifications-popup" data-test="notifications-popup">
  <div class="spinner-box flex justify-start items-start" *ngIf="isLoading">
    <mat-progress-bar color="accent" mode="indeterminate" value="13" [bufferValue]="50"></mat-progress-bar>
  </div>
  <div class="menu-items-box">
    <div *ngIf="notifications$ | async as notifications" class="menu-items-box-scroll">
      <div
        mat-menu-item
        *ngFor="let notification of $any(notifications)"
        data-test="notifications-popup-row"
        (click)="markAsRead(notification, $event)"
      >
        <div class="flex justify-between py-2">
          <div class="notification-list-item flex gap-[10px] justify-start">
            <mat-icon class="min-w-[24px]">message</mat-icon>

            <div class="notification-time">
              {{ notification.eventDatetime | date: 'HH:mm  [dd.MM.yy]' }}
            </div>

            <div class="notification-title" data-test="notification-title" [ngClass]="{ 'not-read': !notification.isRead }">
              {{ notification.title }}
            </div>
          </div>
          <button *ngIf="notification?.payload?.id" class="!border-blue-500 !text-blue-600 tw-button-outline-xs" (click)="goToEntity(notification, $event)">
            {{'shared.notifications.goToEntity' | translate}}
          </button>
        </div>
      </div>
      <div *ngIf="!notifications.length && !isLoading" class="flex justify-center items-center w-full h-full">
        <h2 class="text-gray-300 text-xl">{{ 'shared.notifications.page.noNotifications' | translate }}</h2>
      </div>
    </div>
  </div>

  <div class="actions-box flex justify-center gap-[10px]" transportClickStop>
    <button
      color="primary"
      [disabled]="isLoading"
      mat-button
      data-test="mark-all-notifications-as-read-button"
      (click)="markAllAsRead()"
    >
      {{ 'shared.notifications.markAllAsRead' | translate }}
    </button>

    <button color="accent" [disabled]="isLoading" mat-stroked-button (click)="viewAll()" data-test="viewAllNotifications">
      {{ 'shared.notifications.viewAll' | translate | uppercase }}
    </button>
  </div>
</mat-menu>
