import { ACCEPTING_PLACE, ORDER_STATUS, AUCTION_CRITERION_CHOICES } from '@transport/ui-interfaces';
import { BehaviorSubject } from 'rxjs';
import { BUSINESS_RELATIONSHIP } from '@marketplace-app/cargo/table/cargo-table.interfaces';
import { VerificationStatuses } from '@transport/ui-kit';
import { TnSupportedCurrency } from 'libs/transport-ui-pipes/src/lib/currency/multi-currency.pipe';

export interface INotAcceptedPopupContext {
  riskProfileStatus: VerificationStatuses;
  isAuction: boolean;
  businessRelationship: BUSINESS_RELATIONSHIP | null;
}

export interface INotAcceptedPopup {
  header: string;
  button: {
    text: string;
    isDisabled: boolean;
    withIcon: boolean;
  };
  message: {
    statusText: string;
    messageText: string;
    iconColor: string;
  };
}

export const initialNotAcceptedPopup: INotAcceptedPopup = {
  header: '',
  button: {
    text: '',
    isDisabled: false,
    withIcon: false,
  },
  message: {
    statusText: '',
    messageText: '',
    iconColor: '',
  },
};

export interface IAuctionPopupContext {
  lotId: string;
  isVat: boolean;
  lotStep: number;
  status$: BehaviorSubject<ORDER_STATUS>;
  acceptingPlace?: ACCEPTING_PLACE | null;
  hasMyCounterOffer: boolean;
  allowCounterOffer: boolean;
  isLastBetMine: boolean;
  expired$: BehaviorSubject<boolean>;
  price$: BehaviorSubject<number>;
  currency: TnSupportedCurrency;
  startPrice: number;
  publicOffer: string | null;
  firstLoadingPlace: {
    loadingDate: string;
    loadingDateTo: string;
  };
  additionalAuctionCriteria: {type: AUCTION_CRITERION_CHOICES, weight: number}[] | null;
  lastBetEquivalent: number | null;
  myOrgAdditionalCriteriaData: {type: AUCTION_CRITERION_CHOICES, value: number}[] | null;
}

export interface IAcceptPopupContext {
  id: string;
  status$: BehaviorSubject<ORDER_STATUS>;
  acceptingPlace?: ACCEPTING_PLACE | null;
  price: number;
  bookingAttemptsLeft: number | null;
  currency: TnSupportedCurrency | null;
  isVatIncluded: boolean;
  isOrderBelongsToUser: boolean;
  secondsToLifeTimeExpired: string | null;
  timeOrderReservation: string | null;
  allowCounterOffer: boolean;
  publicOffer: string | null;
  unsignedTermAgreement: { orderId: string; orderExtId: string }[] | null;
}
export interface IBiddingPopupContext {
  lotId: string;
  isVat: boolean;
  lotStep: number;
  status$: BehaviorSubject<ORDER_STATUS>;
  acceptingPlace?: ACCEPTING_PLACE | null;
  hasMyCounterOffer: boolean;
  allowCounterOffer: boolean;
  isLastBetMine: boolean;
  expired$: BehaviorSubject<boolean>;
  price$: BehaviorSubject<number>;
  currency: TnSupportedCurrency | null;
  startPrice: number;
  publicOffer: string | null;
}

export interface IPartnersCheckPopupContext {
  cargoOwner: {
    name: string;
    id: string;
  };
  isAuction: boolean;
  businessRelationship: BUSINESS_RELATIONSHIP | '';
  onAccreditationRequestSent?: () => void;
}

export interface IPartnersCheckPopupContent {
  header: string;
  message: {
    statusText: string;
    tooltip: string;
    messageText: string;
    iconColor: string;
  };
  button: {
    text: string;
    withIcon: boolean;
    isDisabled: boolean;
  };
  cargoOwner: {
    name: string;
  };
}

export const initialPartnersCheckPopup = {
  header: '',
  message: {
    statusText: '',
    tooltip: '',
    messageText: '',
    iconColor: '',
  },
  button: {
    text: '',
    withIcon: false,
    isDisabled: false,
  },
  cargoOwner: {
    name: '',
    inn: '',
  },
};

export enum STATUS_ICON_COLORS {
  BLUE = 'text-blue-500',
  ORANGE = 'text-orange-500',
  RED = 'text-red-500',
  GREEN = 'text-green-500',
}
