import { transport } from '@transport/proto';

import { REGISTRATION_STATUS } from '../registration/registration.interfaces';
import { USER_ROLE } from './user-common.interface';
import { QC_GEO } from 'libs/transport-ui-interfaces/src/lib/map-point';

export interface IUiSettings<T = unknown> {
  [key: string]:
    | {
        columns: T;
      }
    | T;
}

export interface IUiTableColumnSetting {
  name: string;
  visible: boolean;
}

export interface IUiTableColumnDisplay {
  name: string;
  include: boolean;
  label?: string;
  width?: number;
  hasSorting?: boolean;
  hasSearch?: boolean;
  hasFooterValue?: boolean;
}

/**
 * User interface.
 * This interfaces are needed if protobuf types is not enough,
 * e.g. when entity should have a property with specific string values.
 */
export interface IUser extends transport.IUser {
  role: USER_ROLE;
  organization?: IUserOrganization;
  isSupervisor?: boolean;
  tokenExpiresDate?: string;
  firstLogIn?: boolean;
}

export interface IUserOrganization {
  isRegistered?: boolean; // признак допуска на рынок
  hasTmsAccess: boolean;
  hasMarketAccess: boolean;
  accessRequested: boolean;
  isCompanyGroup?: boolean;
  id: string;
  vatName?: string | null;
  vatRate?: number;
  name?: string | null;
  addressWarningLevel?: QC_GEO | null;
}

export const createNewUser = (input: Partial<IUser> = {}) => {
  return transport.User.toObject(new transport.User(input), {
    defaults: true,
  }) as IUser;
};

export const isAllowedMarket = (organization: transport.ICarrierOrganization | null | undefined) => {
  const { marketAttributes } = organization ?? {};
  const { organizationCheck, vehicleCheck, driverCheck } = marketAttributes ?? {};
  return (
    Boolean(organizationCheck === REGISTRATION_STATUS.APPROVED) &&
    Boolean(vehicleCheck === REGISTRATION_STATUS.APPROVED) &&
    Boolean(driverCheck === REGISTRATION_STATUS.APPROVED)
  );
};
