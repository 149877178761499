import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MAX_EMAIL_LENGTH, MIN_PASSWORD_LENGTH, regExpConfig } from '@transport/ui-utils';
import { trimmedStringValidator } from '../form-validators';

export const MAX_PHONE_NUMBER_LENGTH = 12;
export const MAX_FIO_LENGTH = 150;
/**
 * Reg form data.
 */
export interface IMarketplaceRegFormData {
  role?: string;
  inn: string;
  fullName: string;
  phone_number: string;
  email: string;
  password: string;
}

/**
 * Reg form interface.
 */
export interface IMarketplaceRegForm extends FormGroup {
  controls: {
    role: AbstractControl;
    inn: AbstractControl;
    fullName: AbstractControl;
    phone_number: AbstractControl;
    email: AbstractControl;
    password: AbstractControl;
  };
}

/**
 * New login form constructor type.
 */
export type TNewMarketplaceRegFormConstructor = (
  fb: FormBuilder,
  editableItem?: IMarketplaceRegFormData,
  disabled?: boolean,
) => IMarketplaceRegForm;

/**
 * Reg form constructor
 * @param fb form builder
 * @param editableItem editable item
 * @param disabled indicates if for should be initialized with disabled controls
 */
export const newMarketplaceRegForm: TNewMarketplaceRegFormConstructor = (
  fb: FormBuilder,
  editableItem?: IMarketplaceRegFormData,
  disabled = false,
): IMarketplaceRegForm => {
  return fb.group({
    role: [
      'CARRIER',
      {
        disabled,
      },
    ],
    inn: [
      '',
      {
        validators: Validators.compose([Validators.required]),
        disabled,
      },
    ],
    fullName: [
      '',
      {
        validators: Validators.compose([
          Validators.required,
          trimmedStringValidator,
          Validators.maxLength(MAX_FIO_LENGTH),
        ]),
        disabled,
      },
    ],
    phone_number: [
      '',
      {
        validators: Validators.compose([
          Validators.required,
          Validators.maxLength(MAX_PHONE_NUMBER_LENGTH),
          Validators.pattern(regExpConfig.phoneNumber.RU),
        ]),
        disabled,
      },
    ],
    email: [
      editableItem?.email ?? '',
      {
        validators: Validators.compose([
          Validators.required,
          Validators.pattern(regExpConfig.email.regExp),
          Validators.maxLength(MAX_EMAIL_LENGTH),
        ]),
        disabled,
      },
    ],
    password: [
      '',
      {
        validators: Validators.compose([Validators.required, Validators.minLength(MIN_PASSWORD_LENGTH)]),
        disabled,
      },
    ],
  }) as IMarketplaceRegForm;
};
