import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TnReconciliationActEffects } from './effects/reconciliation-act.effects';
import * as fromReconciliationAct from './reducers/';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(fromReconciliationAct.reconciliationActFeatureKey, fromReconciliationAct.reducers),

    EffectsModule.forFeature([TnReconciliationActEffects]),
  ],
})
export class TnReconciliationActStateModule {}
