import { ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { take, tap } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'transport-table-settings',
  templateUrl: './table-settings.component.html',
  styleUrls: ['./table-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnTableSettingsComponent implements OnInit {
  @ViewChild('dialogTemplateRef') public dialogTemplateRef?: TemplateRef<TnTableSettingsComponent>;

  @ViewChild('dialogTrigger', { read: ElementRef }) public dialogTriggerRef?: ElementRef;

  @Input() public columnsDefs: Record<string, { label: string; visible: boolean }> = {};

  @Output() public readonly changeSettings = new EventEmitter<Record<string, boolean>>();

  public dialogRef?: MatDialogRef<TnTableSettingsComponent>;

  public get columnsKeys() {
    return Object.keys(this.columnsDefs);
  }

  public formData?: FormGroup;

  public currentFormValue?: Record<string, boolean>;

  constructor(private readonly fb: FormBuilder, private readonly dialog: MatDialog) {}

  public setFormAvailable() {
    const value: Record<string, boolean> = this.formData?.getRawValue();
    const checkedItems = Object.keys(value).filter(key => {
      return value[key];
    });
    if (checkedItems.length === 1) {
      this.formData?.controls[checkedItems[0]].disable({ emitEvent: false });
    } else {
      this.formData?.enable({ emitEvent: false });
    }
  }

  public ngOnInit() {
    if (Boolean(this.columnsDefs)) {
      this.formData = this.createForm();
      this.currentFormValue = this.formData.getRawValue();
      this.setFormAvailable();
      void this.formData.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
        this.setFormAvailable();
      });
    }
  }

  public openDialog() {
    const matDialogConfig: MatDialogConfig = new MatDialogConfig();

    if (typeof this.dialogTriggerRef !== 'undefined' && typeof this.dialogTemplateRef !== 'undefined') {
      const rect = (this.dialogTriggerRef.nativeElement as HTMLElement).getBoundingClientRect();
      matDialogConfig.backdropClass = 'table-settings-backdrop';
      matDialogConfig.panelClass = 'table-settings-content';

      this.dialogRef = this.dialog.open(this.dialogTemplateRef, matDialogConfig);

      const matDialogContainerElement = document.querySelector('transport-settings-container');

      if (matDialogContainerElement !== null && typeof this.dialogRef !== 'undefined') {
        this.dialogRef.updatePosition({
          left: `${rect.left - matDialogContainerElement.clientWidth + Number(rect.width)}px`,
          top: `${rect.bottom}px`,
        });

        void this.dialogRef
          .afterClosed()
          .pipe(
            untilDestroyed(this),
            take(1),
            tap(result => {
              if (!Boolean(result)) {
                this.patchFormControlsValue();
              }
            }),
          )
          .subscribe();
      }
    }
  }

  public closeDialog() {
    this.changeSettings.emit(this.formData?.getRawValue());
    this.dialogRef?.close();
  }

  private createForm() {
    const formGroup = this.fb.group({});
    Object.keys(this.columnsDefs).forEach(key => {
      const control = this.fb.control(this.columnsDefs[key].visible);
      formGroup.addControl(key, control);
    });
    return formGroup;
  }

  private patchFormControlsValue() {
    Object.keys(this.currentFormValue as Record<string, boolean>).forEach(key => {
      this.formData?.patchValue({ [key]: (this.currentFormValue as Record<string, boolean>)[key] });
    });
  }
}
