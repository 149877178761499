import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TRUCK_IDENTIFICATOR_TYPE } from '@transport/ui-interfaces';

@Component({
  selector: 'transport-truck-help-icon',
  templateUrl: './truck-help-icon.component.html',
  styleUrls: ['./truck-help-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnTruckHelpIconComponent {
  @Input() public showIcon = true;

  @Input() public type: TRUCK_IDENTIFICATOR_TYPE = TRUCK_IDENTIFICATOR_TYPE.VIN;
}
