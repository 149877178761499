import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { appColors } from '@transport/client-shared-assets';
import { first } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'transport-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnUserInfoComponent {
  @HostBinding('class.warn-mode') public get warnMode() {
    return this.isWarnMode;
  }

  @ViewChild('timeExpired') public timeExpiredTemplate?: TemplateRef<never>;

  @Input() public username = '';

  @Input() public isWarnMode = false;

  @Input() public endSessionTime = '';

  @Output() public readonly completeWork = new EventEmitter();

  public dialogRef?: MatDialogRef<never>;

  public timerColor = appColors.contrast;

  constructor(private readonly dialog: MatDialog) {}

  public showTimeExpiredDialog() {
    if (!this.dialogRef && typeof this.timeExpiredTemplate !== 'undefined') {
      this.dialogRef = this.dialog.open(this.timeExpiredTemplate, { width: '425px' });

      void this.dialogRef
        .afterClosed()
        .pipe(first(), untilDestroyed(this))
        .subscribe(() => this.completeWork.emit());
    }
  }
}
