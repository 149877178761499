import { Directive, EventEmitter, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { ITab, ORDERS_FILTER_COUNTERS_TYPE } from '@transport/ui-interfaces';
import { ITableStateFacade } from '@transport/ui-store';
import { Subject } from 'rxjs';

export interface ITableFilterOption<T = string> {
  label: string;
  value: T;
  counterType?: ORDERS_FILTER_COUNTERS_TYPE;
}

@UntilDestroy()
@Directive()
export abstract class TnTableFilterDirective<TFilter> {
  @Output() public readonly changeFilter = new EventEmitter<TFilter>();

  public filterOptions: ITableFilterOption[] = this.getFilterOptions();

  public tabs: string[] | ITab[] = this.getTabs();

  private readonly selectedTabIndexSubject = new Subject<number>();

  public selectedTabIndex$ = this.selectedTabIndexSubject.asObservable();

  public set selectedTabIndex(value: number) {
    window.setTimeout(() => this.selectedTabIndexSubject.next(value));
  }

  public get currentFilter() {
    return this.tableStateFacade?.currentFilter ?? ({} as TFilter);
  }

  public get selectedValue() {
    return (selectedIndex: number) => this.filterOptions[selectedIndex].value;
  }

  constructor(protected readonly translate: TranslateService, public readonly tableStateFacade?: ITableStateFacade<TFilter>) {}

  protected abstract getFilterOptions();

  public abstract onFilterChange(value: ITableFilterOption['value']);

  protected getTabs() {
    return this.filterOptions.map(({ label }) => this.translate.instant(label));
  }

  public onTabChange(selectedIndex: number) {
    this.selectedTabIndexSubject.next(selectedIndex);
    this.onFilterChange(this.selectedValue(selectedIndex));
  }
}
