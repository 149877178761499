import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'transport-page-title',
  templateUrl: './page-title.component.html',
  styleUrls: ['./page-title.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnPageTitleComponent {
  @Input() public title = '';

  @Input() public descriptionParagraphs: string[] = [];

  @Input() public isInfoTitle = false;

  public get hasDescription() {
    return Boolean(this.descriptionParagraphs.length);
  }
}
