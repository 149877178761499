import { Action, createReducer, on } from '@ngrx/store';

import { getMapPointByAddressFailure, setDadataData } from './dadata.actions';
import { dadataInitialState, IDadataState } from './dadata.state';

const createDadataReducer = createReducer(
  dadataInitialState,
  on(setDadataData, (state, { payload }) => ({ ...payload })),
  on(getMapPointByAddressFailure, state => ({ ...state, suggestions: [] })),
);

export function dadataReducer(state: IDadataState, action: Action) {
  return createDadataReducer(state, action);
}
