<div class="flex justify-between" mat-dialog-title>
  <h2>{{ 'shared.addDriverDialog.title' | translate }}</h2>
  <mat-icon class="dialog-close icon_small" svgIcon="close" (click)="closeDialog()"></mat-icon>
</div>

<div mat-dialog-content transportTooltip [isGlobalMode]="true" class="has-tall-footer table-container">
  <div class="scrollable-container">
    <form class="form" *ngIf="!(isLoading$ | async)" [formGroup]="$any(formData)">

      <transport-driver-form
        formControlName="driver"
        [countries]="pageData.select.countries"
        [usesDriverMobile]="pageData.driver.usesDriverMobile"
        [hasBeenInvitedToDriverMobile]="pageData.driver.hasBeenInvitedToDriverMobile"
        [isShowInvite]="
          !pageData.driver.usesDriverMobile && !pageData.driver.isArchived && mode === 'view'
        "
        [mode]="mode"
        (inviteDriver)="inviteDriver()"
      >
        <ng-container *ngIf="isAgentWorkScheme">
          <mat-form-field class="flex max-w-auto basis-auto">
            <ng-select
              transportNgSelectMat
              formControlName="agentCarrier"
              placeholder="{{ 'carrier.directory.driver.form.agentCarrier' | translate }}"
              bindLabel="name"
              bindValue="id"
              [items]="$any(agentCarriers$ | async)"
              (search)="searchAgentCarrier($event)"
              (clear)="clearAgentCarrier()"
              [required]="true"
            >
              <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
                <span transportTooltip [context]="{ message: item.name }">{{ item.name }}</span>
              </ng-template>
            </ng-select>
            <mat-error *ngIf="formData?.controls?.agentCarrier?.errors?.required">
              {{ 'shared.errors.required' | translate }}
            </mat-error>
          </mat-form-field>
        </ng-container>
      </transport-driver-form>
    </form>
  </div>
</div>

<div class="actions">
  <transport-button
    label="{{'shared.actions.add' | translate}}"
    type="action-confirm"
    (action)="save()"
  ></transport-button>
</div>
