import { Action, createReducer, on } from '@ngrx/store';

import { setConfig } from './constants.actions';
import { constantsInitialState, IConstantsState } from './constants.state';

const createConstantsReducer = createReducer(
  constantsInitialState,
  on(setConfig, (state, { payload }) => ({ ...state, ...payload })),
);

export function constantsReducer(state: IConstantsState, action: Action) {
  return createConstantsReducer(state, action);
}
