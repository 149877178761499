import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { TnAuthenticatedGuard, TnDirtyFormCanDeactivateGuard, TnPermissionGuard, TnUserRoleGuard } from '@transport/ui-store';

/**
 * Directory Routing Module.
 */
export const DIRECTORY_ROUTES: Route[] = [
  {
    path: '',
    canActivate: [TnAuthenticatedGuard, TnUserRoleGuard],
    children: [
      {
        path: 'owner',
        canActivate: [TnAuthenticatedGuard, TnUserRoleGuard],
        children: [
          {
            path: 'addresses',
            children: [
              {
                path: '',
                loadChildren: () => import('@transport/addresses').then(mod => mod.AddressesDirectoryModule),
                canActivate: [TnPermissionGuard],
                data: {
                  title: 'loading place',
                  permissionName: 'generalReference.manage'
                },
              },
            ],
          },
          {
            path: 'cargo-types',
            children: [
              {
                path: '',
                loadChildren: () => import('@transport/cargo-types').then(mod => mod.CargoTypesDirectoryModule),
                canActivate: [TnPermissionGuard],
                data: {
                  title: 'cargo types',
                  permissionName: 'generalReference.manage'
                },
              },
            ],
          },
          {
            path: 'vehicle-templates',
            children: [
              {
                path: '',
                loadChildren: () => import('@transport/vehicle-templates').then(mod => mod.VehicleTemplatesDirectoryModule),
                canActivate: [TnPermissionGuard],
                data: {
                  permissionName: 'generalReference.manage'
                },
              },
            ],
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(DIRECTORY_ROUTES)],
  exports: [RouterModule],
})
export class TnDirectoryRoutingModule {}
