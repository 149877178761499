import { NgModule } from '@angular/core';
import { TnMpPdfImageViewerComponent } from './pdf-image-viewer.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [
    TnMpPdfImageViewerComponent,
  ],
  imports: [NgxExtendedPdfViewerModule, CommonModule],
  exports: [TnMpPdfImageViewerComponent]
})
export class TnMpPdfImageViewerModule {}
