import { gql } from 'apollo-angular';

/**
 * GraphQL carrier directory queries
 */
export const CARRIER_DIRECTORY_QUERY = {
  getDriversSelector: gql`
    query getDriversSelector(
      $agentCarrierId:ID
      $withActiveAgentCarrierOnly:Boolean
      $agentCarrierOwnership: AgentCarrierDriverOwnershipEnum
      $orderId: ID
    ) {
      drivers(
        agentCarrierId: $agentCarrierId
        withActiveAgentCarrierOnly: $withActiveAgentCarrierOnly
        agentCarrierOwnership: $agentCarrierOwnership
      ) {
        id
        accrStatus
        birthday
        phone
        addPhone
        licenceSeries
        licenceNumber
        surname
        name
        patronymic
        passportSeries
        passportNumber
        usesDriverMobile
        hasBeenInvitedToDriverMobile
        agentCarrier {
          name
          id
          inn
        }
        blackListInfo(orderId: $orderId) {
          cargoOwner {
            id
            name
          }
          startDate
          description
          workProhibited
          reason
        }
      }
    }
  `,

  createVehicleRiskProfile: gql`
    mutation createVehicleRiskProfile($vehicleId: ID!) {
      createVehicleRiskProfile(vehicleId: $vehicleId) {
        profileId
      }
    }
  `,

  submitVehicleRiskProfile: gql`
    mutation submitVehicleRiskProfile($profileId: String!, $vehicleId: ID!) {
      submitVehicleRiskProfile(profileId: $profileId, vehicleId: $vehicleId) {
        ok
      }
    }
  `,

  createDriverRiskProfile: gql`
    mutation createDriverRiskProfile($driverId: Int!) {
      createDriverRiskProfile(driverId: $driverId) {
        profileId
      }
    }
  `,

  submitDriverRiskProfile: gql`
    mutation submitDriverRiskProfile($profileId: String!, $driverId: ID!) {
      submitDriverRiskProfile(profileId: $profileId, driverId: $driverId) {
        ok
      }
    }
  `,

  getDrivers: gql`
    query drivers(
      $first: Int
      $offset: Int
      $availability: AvailabilityEnum
      $fullName: String
      $surname: String
      $namePatronymic: String
      $phone: String
      $agentCarrierId: ID
      $agentCarrierOwnership: AgentCarrierDriverOwnershipEnum
      $withActiveAgentCarrierOnly: Boolean
      $orderId: ID
      $totalCount: Boolean = false
    ) {
      drivers(
        first: $first
        offset: $offset
        availability: $availability
        fullName: $fullName
        surname: $surname
        namePatronymic: $namePatronymic
        phone: $phone
        agentCarrierId: $agentCarrierId
        agentCarrierOwnership: $agentCarrierOwnership
        withActiveAgentCarrierOnly: $withActiveAgentCarrierOnly
      ) {
        id
        carrierOrganization {
          id
          name
          about
          phoneNumber
          email
        }
        birthday
        gender
        inn
        phone
        addPhone
        licenceSeries
        licenceNumber
        licencePlace
        licenceDate
        surname
        name
        patronymic
        passportSeries
        passportNumber
        passportPlace
        passportDate
        isArchived
        usesDriverMobile
        hasBeenInvitedToDriverMobile
        verificationStatus
        agentCarrier {
          id
          name
          vat {
            id
            name
          }
        }
        blackListInfo(orderId: $orderId) {
          cargoOwner {
            id
            name
          }
          startDate
          description
          workProhibited
          reason
        }
      }

      totalCount: driversCount(
        availability: $availability
        phone: $phone
        fullName: $fullName
        surname: $surname
        namePatronymic: $namePatronymic
        agentCarrierId: $agentCarrierId
        agentCarrierOwnership: $agentCarrierOwnership
        withActiveAgentCarrierOnly: $withActiveAgentCarrierOnly
      ) @include(if: $totalCount)
    }
  `,

  getDriver: gql`
    query driver($id: ID!) {
      driver(id: $id) {
        id
        surname
        name
        patronymic
        passportSeries
        passportNumber
        passportPlace
        passportDate
        licenceSeries
        licenceNumber
        licencePlace
        licenceDate
        birthday
        phone
        inn
        addPhone
        gender
        country
        isArchived
        usesDriverMobile
        hasBeenInvitedToDriverMobile
        verificationStatus
        agentCarrier {
          id
          name
        }
        blackListInfo {
          cargoOwner {
            id
            name
          }
          startDate
          workProhibited
          reason
          description
        }
      }
    }
  `,

  /**
   * GraphQL query: vehicles list
   */
  getVehicles: gql`
    query vehicles(
      $first: Int
      $offset: Int
      $availability: AvailabilityEnum
      $ownership: VehicleOwnershipEnum
      $vehicleType: VehicleTypeEnum
      $regNo: String
      $vehicleBrand: String
      $trailerRegNo: String
      $bodyType: ID
      $bodySubtype: ID
      $capacity: Float
      $loadingType: ID
      $volume: Float
      $agentCarrierId: ID
      $orderId: ID
      $agentCarrierOwnership: AgentCarrierVehicleOwnershipEnum
      $sortBy: [String]
      $withActiveAgentCarrierOnly: Boolean
      $totalCount: Boolean = false
    ) {
      vehicles(
        first: $first
        offset: $offset
        availability: $availability
        ownership: $ownership
        vehicleType: $vehicleType
        regNo: $regNo
        vehicleBrand: $vehicleBrand
        trailerRegNo: $trailerRegNo
        bodyType: $bodyType
        bodySubtype: $bodySubtype
        capacity: $capacity
        loadingType: $loadingType
        volume: $volume
        agentCarrierId: $agentCarrierId
        agentCarrierOwnership: $agentCarrierOwnership
        withActiveAgentCarrierOnly: $withActiveAgentCarrierOnly
      ) {
        id
        vehicleType
        vehicleMake
        vehicleCertificate
        isArchived
        vehicleId
        vehicleVin
        verificationStatus
        ownVehicle
        vehicletrailer {
          id
          trailerId
          trailerType
          bodyType {
            id
            name
          }
          bodySubtype {
            id
            name
          }
          loadingTypes {
            id
            name
          }
          capacity
          volume
          blackListInfo(orderId: $orderId) {
            cargoOwner {
              id
              name
            }
            nomenclatureType
            workProhibited
            reason
          }
        }
        vehiclebody {
          id
          vehicle {
            id
          }
          bodyType {
            id
            name
          }
          bodySubtype {
            id
            name
          }
          loadingTypes {
            id
            name
          }
          capacity
          volume
        }
        country
        agentCarrier {
          id
          name
          vat {
            id
            name
          }
        }
        blackListInfo(orderId: $orderId) {
          cargoOwner {
            id
            name
          }
          startDate
          workProhibited
          reason
          description
        }
        ownershipType
      }
      totalCount: vehiclesCount(
        sortBy: $sortBy
        availability: $availability
        ownership: $ownership
        vehicleType: $vehicleType
        regNo: $regNo
        vehicleBrand: $vehicleBrand
        trailerRegNo: $trailerRegNo
        bodyType: $bodyType
        bodySubtype: $bodySubtype
        capacity: $capacity
        loadingType: $loadingType
        volume: $volume
        agentCarrierId: $agentCarrierId
        agentCarrierOwnership: $agentCarrierOwnership
        withActiveAgentCarrierOnly: $withActiveAgentCarrierOnly
      ) @include(if: $totalCount)
    }
  `,

  getVehiclesSelector: gql`
    query vehiclesSelector(
      $agentCarrierId:ID
      $withActiveAgentCarrierOnly:Boolean
      $agentCarrierOwnership: AgentCarrierVehicleOwnershipEnum
      $orderId: ID
    ) {
      vehicles(
        agentCarrierId: $agentCarrierId
        withActiveAgentCarrierOnly: $withActiveAgentCarrierOnly
        agentCarrierOwnership: $agentCarrierOwnership
      ){
        id
        country
        accrStatus
        vehicleType
        vehicleMake
        isArchived
        vehicleId
        ownershipType
        vehicletrailer {
          trailerId
          regNo
          blackListInfo(orderId: $orderId) {
            cargoOwner {
              id
              name
            }
            startDate
            description
            workProhibited
            reason
          }
        }
        agentCarrier {
          name
          id
          inn
        }
        blackListInfo(orderId: $orderId) {
          cargoOwner {
            id
            name
          }
          startDate
          description
          workProhibited
          reason
        }
      }
    }
  `,

  /**
   * GraphQL query: vehicle entity
   */
  getVehicle: gql`
    query vehicle($id: ID!) {
      vehicle(id: $id) {
        id
        vehicleType
        vehicleMake
        country
        vehicleCertificate
        isArchived
        vehicleId
        vehicleVin
        verificationStatus
        vehicletrailer {
          trailerId
          trailerType
          bodyType {
            id
            name
          }
          bodySubtype {
            id
            name
          }
          loadingTypes {
            id
            name
          }
          capacity
          volume
          blackListInfo {
            cargoOwner {
              id
              name
            }
            startDate
            workProhibited
            reason
            description
            nomenclatureType
          }
        }
        vehiclebody {
          vehicle {
            id
          }
          bodyType {
            id
            name
          }
          bodySubtype {
            id
            name
          }
          loadingTypes {
            id
            name
          }
          capacity
          volume
        }
        isArchived
        agentCarrier {
          id
          name
          vat {
            id
            name
          }
        }
        blackListInfo {
          cargoOwner {
            id
            name
          }
          startDate
          workProhibited
          reason
          description
        }
        ownershipType
      }
    }
  `,

  /**
   * GraphQL query: vehicle types constants
   */
  getVehicleTypes: gql`
    query vehicleTypes {
      vehicleTypes
    }
  `,

  /**
   * GraphQL query: body types constants
   */
  getBodyTypes: gql`
    query bodyTypes {
      bodyTypes {
        id
        name
        subtypes {
          id
          name
        }
      }
    }
  `,

  /**
   * GraphQL query: vehicle makes constants
   */
  getVehicleMakes: gql`
    query vehicleMakes {
      vehicleMakes
    }
  `,

  getLoadingTypeList: gql`
    {
      loadingTypes {
        id
        name
      }
    }
  `,

  /**
   * GraphQL query: get driver by vehicle ID
   */
  getVehicleDrivers: gql`
    query vehicleDrivers($vehicleId: ID!, $orderId: ID) {
      vehicleDrivers(vehicleId: $vehicleId) {
        id
        carrierOrganization {
          id
          name
          about
          phoneNumber
          email
        }
        birthday
        gender
        inn
        phone
        addPhone
        licenceSeries
        licenceNumber
        licencePlace
        licenceDate
        surname
        name
        patronymic
        passportSeries
        passportNumber
        passportPlace
        passportDate
        isArchived
        agentCarrier {
          id
          name
          vat {
            id
            name
          }
        }
        blackListInfo(orderId: $orderId) {
          cargoOwner {
            id
            name
          }
          startDate
          workProhibited
          reason
          description
        }
      }
    }
  `,

  getVehicleByDriver: gql`
    query driverVehicles($driverId: ID!, $orderId: ID) {
      driverVehicles(driverId: $driverId) {
        id
        vehicleType
        vehicleMake
        vehicleCertificate
        isArchived
        vehicleId
        vehicleVin
        verificationStatus
        agentCarrier {
          id
          name
          vat {
            id
            name
          }
        }
        vehicletrailer {
          id
          trailerId
          trailerType
          bodyType {
            id
            name
          }
          bodySubtype {
            id
            name
          }
          loadingTypes {
            id
            name
          }
          capacity
          volume
          blackListInfo(orderId: $orderId) {
            nomenclatureType
            cargoOwner {
              id
              name
            }
            startDate
            workProhibited
            reason
            description
          }
        }
        vehiclebody {
          id
          vehicle {
            id
          }
          bodyType {
            id
            name
          }
          bodySubtype {
            id
            name
          }
          loadingTypes {
            id
            name
          }
          capacity
          volume
        }
        country
        blackListInfo(orderId: $orderId) {
          cargoOwner {
            id
            name
          }
          startDate
          workProhibited
          reason
          description
        }
        ownershipType
      }
    }
  `,
};
