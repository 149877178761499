import { Directive, EventEmitter, HostListener, Output } from '@angular/core';

/**
 * Directive to stop propagation click
 */
@Directive({
  selector: '[transportClickStopPropagation],[transportClickStop]',
})
export class TnClickStopPropagationDirective {
  @Output() public readonly transportClickStop = new EventEmitter();

  @HostListener('click', ['$event'])
  public onClick(event: Event): void {
    event.stopPropagation();
    event.preventDefault();
    this.transportClickStop.emit(event);
  }
}

export * from './cleaner';
export * from './self.directive';
