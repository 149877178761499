import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TAdminOrganizationForm, TDirectoryEntityPredefinedAction } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';

import { ITnState } from '../../state/index';
import { adminProfileActions } from './admin-profile.actions';
import * as selector from './admin-profile.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnAdminProfileFacade {
  public reactiveFormValue$ = this.store.pipe(select(selector.getReactiveForm));

  public legalFormsDict$ = this.store.pipe(select(selector.getLegalForms));

  public defaultInsurance$ = this.store.pipe(select(selector.getDefaultInsurance));

  public vatTypesDict$ = this.store.pipe(select(selector.getVatTypes));

  public paymentTypesDict$ = this.store.pipe(select(selector.getPaymentTypes));

  public subOrganizations$ = this.store.pipe(select(selector.getSubOrganizations));

  public isCompanyGroup$ = this.store.pipe(select(selector.getIsCompanyGroup));

  public isSubOrganizationsVisible$ = this.isCompanyGroup$;

  public isLoading$ = this.store.pipe(select(selector.getLoading));

  public formMode$: Observable<TDirectoryEntityPredefinedAction> = this.store.pipe(select(selector.getFormMode));

  constructor(private readonly store: Store<ITnState>) {}

  public loadProfile(): void {
    this.store.dispatch(adminProfileActions.loadAdminProfileStart());
  }

  public saveProfile(formValue: TAdminOrganizationForm): void {
    this.store.dispatch(adminProfileActions.saveAdminProfileStart({ formValue }));
  }

  public navigateToEdit(): void {
    this.store.dispatch(adminProfileActions.navigateToAction({ action: 'edit' }));
  }
}
