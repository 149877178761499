<div class="organization-form">
  <h2 class="form__header hidden pb-3 font-semibold text-sm text-gray-700">{{ 'shared.mp.verification.stepper.organization' | translate }}</h2>
  <form [formGroup]="form">
    <individual-businessman [form]="form"></individual-businessman>

    <ng-container *ngIf="!isIndividualInterpreneur(legalForm | async) && !isSelfEmployed(legalForm | async)">
      <div class="gen-dir__header-wrapper pt-6">
        <div class="gen-dir__header not-italic font-medium text-base text-gray-800">
          {{ 'shared.mp.verification.form.organization.genDir' | translate }}
        </div>
      </div>

      <div class="gen-dir  gap-3 divide-y divide-gray-200">
        <div class="row gap-3">
          <transport-tw-input-field
            class="gen-dir__input w-6/12"
            [errorToMessage]="validationErrors.bind(this)"
            label="{{ 'shared.mp.verification.form.organization.directorFullName' | translate }}"
            size="sm"
            [control]="$any(form.controls.directorFullName)"
          >
            <transport-tw-input size="md" [control]="form.controls.directorFullName">
              <input tw-input class="text-sm" [formControl]="$any(form.controls.directorFullName)" />
            </transport-tw-input>
          </transport-tw-input-field>
          <!--          <transport-tw-input-field-->
          <!--            class="w-full"-->
          <!--            [errorToMessage]="validationErrors.bind(this)"-->
          <!--            label="{{ 'shared.mp.verification.form.organization.directorInn' | translate }}"-->
          <!--            size="sm"-->
          <!--            [control]="$any(form.controls.directorInn)"-->
          <!--          >-->
          <!--            <transport-tw-input size="md" [control]="form.controls.directorInn">-->
          <!--              <input transportNumberOnly transportTrim tw-input class="text-sm" [formControl]="$any(form.controls.directorInn)" />-->
          <!--            </transport-tw-input>-->
          <!--          </transport-tw-input-field>-->
        </div>
        <div class="row">
          <span class="flex items-center">{{ 'shared.mp.verification.form.organization.appointmentOrder' | translate }}</span>
          <transport-file-selector
            [errorToMessage]="validationErrors.bind(this)"
            [control]="form.controls.appointmentOrder"
          ></transport-file-selector>
        </div>
        <div class="row">
          <span class="flex items-center">{{ 'shared.mp.verification.form.organization.passportPhoto' | translate }}</span>
          <transport-file-selector
            [errorToMessage]="validationErrors.bind(this)"
            [control]="form.controls.passportPhoto"
          ></transport-file-selector>
        </div>
        <div class="row">
          <span class="flex items-center">{{ 'shared.mp.verification.form.organization.passportRegistrationPhoto' | translate }}</span>
          <transport-file-selector
            [errorToMessage]="validationErrors.bind(this)"
            [control]="form.controls.passportRegistrationPhoto"
          ></transport-file-selector>
        </div>
      </div>
    </ng-container>
  </form>
</div>


