<form *ngIf="form" [formGroup]="form" class="flex-wrap flex-grow justify-between gap-2 flex">
  <common-date-picker
    [formGroup]="form"
    [size]="size"
    [placeholder]="placeholder"
    [label]="fromLabel"
    [required]="fromDateRequired"
    [errorToMessage]="fromValidationErrors.bind(this)"
    [allowClear]="allowClear"
    controlName="fromDate"
    class="flex-grow min-w-[140px]"
    data-test="from-date"
  ></common-date-picker>
  <common-date-picker
    [formGroup]="form"
    [size]="size"
    [placeholder]="placeholder"
    [label]="toLabel"
    [required]="fromDateRequired"
    [errorToMessage]="toValidationErrors.bind(this)"
    [allowClear]="allowClear"
    controlName="toDate"
    class="flex-grow min-w-[140px]"
    data-test="to-date"
  ></common-date-picker>
</form>
