const YAMAPS_TRACKING = {
  placeType: {
    future: 'future',
    delay: 'delay',
    past: 'past',
    driver: 'driver',
    transit: 'transit',
  },
  mapStatus: {
    start: 'start',
    drive: 'drive',
    finish: 'finish',
  },
};
const defaultLatutude = 55.75399399999374;
const defaultLongitude = 37.62209300000001;
const YAMAPS_DEFAULT_MAP_CENTER = [defaultLatutude, defaultLongitude];

export { YAMAPS_DEFAULT_MAP_CENTER, YAMAPS_TRACKING };
