import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TIMEOUT } from '@transport/ui-utils';
import { debounceTime, Observable, of } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'transport-tw-autocomplete',
  templateUrl: './tw-autocomplete.component.html',
  styleUrls: ['./tw-autocomplete.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- Cuz no ways to detect [control] touched and validity status.
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TnTwAutocompleteComponent implements AfterViewInit {
  @ViewChild('inputField') public inputField!: ElementRef;

  @Input() public label = '';

  @Input() public placeholder = '';

  @Input() public size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';

  @Input() public errorsSize: 'xs' | 'sm' | 'md' | 'lg' = 'xs';

  @Input() public labelSize: 'xs' | 'sm' | 'md' | 'lg' = 'sm';

  @Input() public required = false;

  @Input() public showErrors = true;

  @Input() public control = new FormControl();

  @Input() public options: any[] = [];

  @Input() public panelWidth = '';

  @Input() public onlySelectedValue = true;

  @Input() public inputValue?: string;

  public inputChange = this.debounce(() => {
    if (!this.onlySelectedValue) {
      this.control.markAsTouched();
      this.control.setValue((this.inputField.nativeElement as HTMLInputElement).value);
    }
  });

  @Input() public fetchFn: (v: any) => Observable<any[]> = () => of(this.options);

  constructor(private readonly cdr: ChangeDetectorRef) {}

  public ngAfterViewInit(): void {
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(res => {
      if (res === null) {
        (this.inputField.nativeElement as HTMLInputElement).value = '';
      }
    });
  }

  public getOptionName(option?): string {
    return option?.name ?? null;
  }

  public filter(event: any) {
    const filterValue = event.target.value.toLowerCase();
    this.fetchFn(filterValue)
      .pipe(untilDestroyed(this), debounceTime(TIMEOUT.SHORT))
      .subscribe(res => {
        this.options = res;
        this.cdr.markForCheck();
      });
  }

  public onBlur(e: any) {
    if (this.onlySelectedValue) {
      if (!Boolean(e.target.value)) this.selected();
    }
  }

  public selected(e?: MatAutocompleteSelectedEvent) {
    this.control.markAsTouched();
    this.control.setValue(e?.option?.value);
  }

  private debounce(func, timeout = TIMEOUT.SHORT) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }
}
