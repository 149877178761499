import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { IOrder } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { ITnState } from '../../state/index';
import { ITrackingState } from '../../tracking/tracking.state';

/**
 * Carrier order tracking page order resolver.
 */
@Injectable({
  providedIn: 'root',
})
export class TnCarrierOrderTrackingPageOrderResolver implements Resolve<Observable<IOrder>> {
  /**
   * Router data state.
   */
  private readonly routerDataState$: Observable<ITrackingState>;

  /**
   * Constructor.
   * @param store application state store
   */
  constructor(private readonly store: Store<ITnState>) {
    this.routerDataState$ = this.store.pipe(select('tracking'));
  }

  public resolve(): Observable<IOrder> {
    return this.routerDataState$.pipe(
      take(1),
      map((routerDataState: ITrackingState) => routerDataState.carrierOrderTrackingPage.order),
    );
  }
}
