import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  IReconcilationActDto,
  IReconcilationActOrganizationDto,
  IReconcilationActRuleDto,
  IReconcilationActsListDto,
  TnReconcilationActModel,
  TnReconcilationActOrganization,
  TnReconcilationActRule,
  TnReconciliationActDetailsFormValue,
} from '@transport/ui-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnHttpHandlersService } from '../../feature-access';
import { TnReconcilationActUrlService } from './reconcilation-act-url.service';

@Injectable({
  providedIn: 'root',
})
export class TnReconcilationActApiService {
  constructor(
    private readonly http: HttpClient,
    private readonly handlers: TnHttpHandlersService,
    private readonly urlService: TnReconcilationActUrlService,
  ) {}

  public getActs(pageNumber: number, countPerPage: number): Observable<{ list: TnReconcilationActModel[]; totalCount: number }> {
    return this.handlers.pipeHttpRequest(
      this.http
        .get<IReconcilationActsListDto>(this.urlService.generateActsListUrl(pageNumber, countPerPage), this.getDefaultRequestParams())
        .pipe(
          map(data => ({
            list: data.results.map(dtoItem => TnReconcilationActModel.fromDto(dtoItem)),
            totalCount: data.count ?? 0,
          })),
        ),
      1,
      false,
    );
  }

  public getOrganizations(): Observable<TnReconcilationActOrganization[]> {
    return this.handlers.pipeHttpRequest(
      this.http
        .get<IReconcilationActOrganizationDto[]>(this.urlService.organizationsListUrl, this.getDefaultRequestParams())
        .pipe(map(data => data.map(dtoItem => TnReconcilationActOrganization.fromDto(dtoItem)))),
      1,
      true,
    );
  }

  public getMyOrganization(): Observable<TnReconcilationActOrganization> {
    return this.handlers.pipeHttpRequest(
      this.http
        .get<IReconcilationActOrganizationDto>(this.urlService.myOrganizationUrl, this.getDefaultRequestParams())
        .pipe(map(dtoItem => TnReconcilationActOrganization.fromDto(dtoItem))),
      1,
      true,
    );
  }

  public getRules(): Observable<TnReconcilationActRule[]> {
    return this.handlers.pipeHttpRequest(
      this.http
        .get<IReconcilationActRuleDto[]>(this.urlService.rulesListUrl, this.getDefaultRequestParams())
        .pipe(map(data => data.map(dtoItem => TnReconcilationActRule.fromDto(dtoItem)).sort((a, b) => a.name.localeCompare(b.name)))),
      1,
      true,
    );
  }

  public getAct(guid: string): Observable<TnReconcilationActModel> {
    return this.handlers.pipeHttpRequest(
      this.http
        .get<IReconcilationActDto>(this.urlService.generateActDetailsUrl(guid), this.getDefaultRequestParams())
        .pipe(map(dtoItem => this.mapActFromDto(dtoItem))),
      1,
      true,
    );
  }

  public saveAct(act: TnReconciliationActDetailsFormValue): Observable<TnReconcilationActModel> {
    const formData = new FormData();
    formData.append('act_1', act.firstOrganization?.file?.fileData as Blob, act.firstOrganization?.file?.fullName);
    formData.append('act_2', act.secondOrganization?.file?.fileData as Blob, act.secondOrganization?.file?.fullName);
    formData.append('organization_1', String(act.firstOrganization?.organization?.id ?? ''));
    formData.append('organization_1_rule', String(act.firstOrganization?.rule?.id ?? ''));
    formData.append('organization_2', String(act.secondOrganization?.organization?.id ?? ''));
    formData.append('organization_2_rule', String(act.secondOrganization?.rule?.id ?? ''));
    formData.append('act_date_from', String(act.period.fromDate ?? ''));
    formData.append('act_date_to', String(act.period.toDate ?? ''));

    formData.append('comment', String(act.comment ?? ''));
    return this.handlers.pipeHttpRequest(
      this.http
        .post<IReconcilationActDto>(this.urlService.saveActUrl, formData, this.getDefaultRequestParams())
        .pipe(map(dtoItem => this.mapActFromDto(dtoItem))),
      1,
      true,
    );
  }

  private mapActFromDto(dto: IReconcilationActDto): TnReconcilationActModel {
    return TnReconcilationActModel.fromDto(dto, this.urlService);
  }

  private getDefaultRequestParams() {
    return {
      params: new HttpParams().set('format', 'json'),
    };
  }
}
