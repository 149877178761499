import { FocusMonitor } from '@angular/cdk/a11y';
import { AfterViewInit, Component, ElementRef, HostBinding, Input, OnChanges, OnInit, Optional, Self, SimpleChanges } from '@angular/core';
import { FormControl, NgControl, Validators } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { UntilDestroy } from '@ngneat/until-destroy';
import { transport } from '@transport/proto';
import { TnBaseMatInputDirective } from '@transport/ui-components';

@UntilDestroy()
@Component({
  selector: 'transport-group-select',
  templateUrl: './group-select.component.html',
  styleUrls: ['./group-select.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TnGroupSelectComponent,
    },
  ],
})
export class TnGroupSelectComponent extends TnBaseMatInputDirective implements OnInit, OnChanges, AfterViewInit {
  @Input() public data: transport.Carrier.ICarrierGroup[] | null = [];

  public selectedValue = null;

  public static nextId = 0;

  @HostBinding('attr.id')
  public id = `transport-group-select-${(TnGroupSelectComponent.nextId += 1)}`;

  constructor(public fm: FocusMonitor, public elRef: ElementRef<HTMLElement>, @Optional() @Self() public ngControl: NgControl) {
    super(fm, elRef, ngControl);
  }

  public ngOnChanges(changes: SimpleChanges): void {}

  public ngOnInit(): void {
    this.selectedValue = this.ngControl.control?.value;
  }

  public ngAfterViewInit() {
    // this.
  }

  public onChangeSelect(event) {
    this.onTouched();
    this.propagateChange(this.selectedValue);
  }
}
