import { transport } from '@transport/proto';
import { TModify } from '@transport/ui-utils';

export interface IInsuranceReference {
  code: string;
  value: string;
}

export type TInsurance<T> = TModify<transport.IInsurance, { data: string & T }>;

export const createNewInsuranceCompany = (input?: Partial<transport.Insurance.ICompany>) =>
  transport.Insurance.Company.toObject(new transport.Insurance.Company(input), {
    defaults: true,
  }) as transport.Insurance.ICompany;

export const createNewInsuranceContract = (input: Partial<transport.Insurance.IContract> = {}) => {
  input.insuranceCompany = !Boolean(input.insuranceCompany) ? createNewInsuranceCompany() : input.insuranceCompany;
  return transport.Insurance.Contract.toObject(new transport.Insurance.Contract(input), {
    defaults: true,
  }) as transport.Insurance.IContract;
};

export const createNewInsurance = (input: Partial<transport.IInsurance> = {}) => {
  input.insuranceContract = !Boolean(input.insuranceContract) ? createNewInsuranceContract() : input.insuranceContract;
  return transport.Insurance.toObject(new transport.Insurance(input), {
    defaults: true,
  }) as TInsurance<IInsuranceOutputData>;
};

export const createNewOwnerInsuranceContract = (input: Partial<transport.Order.InsuranceContract> = {}) => {
  input.insuranceCompany = !Boolean(input.insuranceCompany) ? createNewInsuranceCompany() : input.insuranceCompany;
  return transport.Order.InsuranceContract.toObject(new transport.Order.InsuranceContract(input), {
    defaults: true,
  }) as transport.Order.InsuranceContract;
};

export interface IInsuranceOutputData {
  cargoPacking: string;
  cargoWeight: number;
  cargoCount: number;
  declaredPrice: number;
  cargoClass: string;
  cargoKind: string;
}

export const insuranceOutputDataFromDto = (dtoData): IInsuranceOutputData => {
  return {
    cargoPacking: dtoData.cargo_packing,
    cargoClass: dtoData.cargo_class,
    cargoWeight: dtoData.cargo_weight,
    cargoCount: dtoData.cargo_count,
    declaredPrice: dtoData.declared_price,
    cargoKind: dtoData.cargo_kind,
  };
};
