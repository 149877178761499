import { ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ValidationErrors, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { getTranslateParams, regExpConfig, TIMEOUT } from '@transport/ui-utils';
import { DRIVER_FORM_CONSTANTS, moreThanNowValidator, trimmedStringValidator } from '@transport/ui-interfaces';
import { DriverFormVm } from '@marketplace/shared';
import { FormGroup } from '@ngneat/reactive-forms';
import { BehaviorSubject, debounceTime, tap } from 'rxjs';
import { TnCurrentUserService } from '@marketplace/auth';
import { COUNTRIES, TnSupportedCountry } from '@transport/ui-pipes';
import { PERSONAL_DOC_TYPES } from '../driver.interface';
import { ICommonSelectData } from 'libs/custom-controls/src/lib/common.interface';

@UntilDestroy()
@Component({
  selector: 'driver-form[vm]',
  templateUrl: './driver-form.component.html',
})
export class DriverFormComponent implements OnInit, OnDestroy {
  @Input() vm!: DriverFormVm;

  public isAgentOrExpeditor = Boolean(this.currentUserService.currentUser.isAgentOrExpeditor);

  public agentCarrierInputSubject$ = new BehaviorSubject<string | null>(null);

  public countries = COUNTRIES;

  public readonly regExpConf = regExpConfig;

  public limitations = getTranslateParams<typeof DRIVER_FORM_CONSTANTS>(DRIVER_FORM_CONSTANTS);

  public docTypesOptions: ICommonSelectData[] = [
    {
      id: PERSONAL_DOC_TYPES.PASSPORT,
      label: 'Паспорт',
    }
  ];


  constructor(private currentUserService: TnCurrentUserService, private cdr: ChangeDetectorRef) {}

  public get isDriverCountryRussia() {
    return this.vm.form?.controls.country.value?.id === TnSupportedCountry.RU;
  }

  public get isDriverCountryKz() {
    return this.vm.form?.controls.country.value?.id === TnSupportedCountry.KZ;
  }

  public get isDriverCountryBy() {
    return this.vm.form?.controls.country.value?.id === TnSupportedCountry.BY;
  }

  public get isDriverCountryUz() {
    return this.vm.form?.controls.country.value?.id === TnSupportedCountry.UZ;
  }

  public get isDriverCountryKg() {
    return this.vm.form?.controls.country.value?.id === TnSupportedCountry.KG;
  }

  public get isDriverCountryTj() {
    return this.vm.form?.controls.country.value?.id === TnSupportedCountry.TJ;
  }

  public get isDriverPassportFieldsRequired() {
    return !this.isDriverCountryKz;
  }

  public get passportFormGroup() {
    return this.vm.form.controls.passportPhoto as FormGroup;
  }

  public get enableIdCard() {
    return [TnSupportedCountry.TJ, TnSupportedCountry.KZ, TnSupportedCountry.UZ, TnSupportedCountry.KG].includes(this.vm.form?.controls?.country.value?.id);
  }

  public get showPassport() {
    return !!this.vm.form.controls?.personalDocTypes.value?.find((item) => item.id === PERSONAL_DOC_TYPES.PASSPORT);
  }

  public get showIdCard() {
    return !!this.vm.form.controls?.personalDocTypes.value?.find((item) => item.id === PERSONAL_DOC_TYPES.IDENTITY_CARD);
  }

  public ngOnInit() {

    this.vm.form.controls?.personalDocTypes.valueChanges.pipe(untilDestroyed(this)).subscribe((res) => {
      this.clearPasportAndIdCardValidators();
      if(this.showPassport) {
        this.setPassportValidators();
      } else {
        this.vm.form.controls.passportDate.reset();
        this.vm.form.controls.passportPlace.reset();
        this.vm.form.controls.passportNumber.reset();
        this.vm.form.controls.passportSeries.reset();
      }
      if (this.showIdCard) {
        this.setIdCardValidators();
      } else {
        this.vm.form.controls.identityCardDate.reset();
        this.vm.form.controls.identityCardNumber.reset();
        this.vm.form.controls.identityCardPlace.reset();
      }
      this.cdr.markForCheck();
    })

    this.vm.driver$.pipe(untilDestroyed(this)).subscribe(v => {
      this.vm.form.patchValue(v);
      this.patchPersonalDocTypes(v);
      this.setIinValidators();

    });

    this.vm.form.controls?.country.valueChanges.pipe(untilDestroyed(this)).subscribe(() => {
      this.setIinValidators();
      this.setLicenceSeriesValidators();
      this.setPersonalDocTypeValidators();
      this.vm.form.updateValueAndValidity();
      this.vm.form.controls?.personalDocTypes.reset();
      this.docTypesOptions = [
        {
          id: PERSONAL_DOC_TYPES.PASSPORT,
          label: 'Паспорт',
        },
        {
          id: PERSONAL_DOC_TYPES.IDENTITY_CARD,
          label: 'Удостоверение личности',
          hidden: !this.enableIdCard
        },
      ]
    });

    if (this.isAgentOrExpeditor) {
      this.agentCarrierInputSubject$
        .pipe(
          debounceTime(TIMEOUT.SHORT),
          tap(searchQuery => this.vm.getAgentCarriers(searchQuery ?? '')),
          untilDestroyed(this),
        )
        .subscribe();
    }

    this.setDocValidators();

    this.vm.form.controls?.agentCarrierId?.valueChanges.pipe().subscribe(res => {
      this.vm.hasAgentCarrier$.next(Boolean(res));
      if (Boolean(res)) {
        this.passportFormGroup.controls.mainPage.clearValidators();
        this.passportFormGroup.controls.mainPage.updateValueAndValidity();
        this.passportFormGroup.controls.registration.clearValidators();
        this.passportFormGroup.controls.registration.updateValueAndValidity();
      } else {
        this.setDocValidators();
      }
      this.vm.actionBtnTxt = this.vm.getActionBtnTxt();
    });
  }

  private setDocValidators() {
    if (!this.vm.isSubdomainPage) {
      this.passportFormGroup.controls.mainPage.setValidators([Validators.required]);
      this.passportFormGroup.controls.mainPage.updateValueAndValidity();
      this.passportFormGroup.controls.registration.setValidators([Validators.required]);
      this.passportFormGroup.controls.registration.updateValueAndValidity();
    }
  }

  private patchPersonalDocTypes(driver) {
    let value: any = [];
    if (driver.passportNumber) value.push(this.docTypesOptions.find(item => item.id === PERSONAL_DOC_TYPES.PASSPORT));
    if (driver.identityCardNumber) value.push(this.docTypesOptions.find(item => item.id === PERSONAL_DOC_TYPES.IDENTITY_CARD));
    this.vm.form.controls?.personalDocTypes.setValue(value);

  }

  public setPassportSeriesValidators() {
    if (this.isDriverCountryRussia) {
      this.vm.form.controls.passportSeries.setValidators([
        Validators.required,
        Validators.maxLength(DRIVER_FORM_CONSTANTS.MAX_PASSPORT_SERIES_LENGTH),
        trimmedStringValidator,
      ]);
    } else {
      this.vm.form.controls.passportSeries.clearValidators();
    }
  }
  public setPersonalDocTypeValidators() {
    this.vm.form.controls.personalDocTypes.clearValidators();
    if (!this.isDriverCountryKz) {
      this.vm.form.controls.personalDocTypes.setValidators([Validators.required]);
    }
  }
  public setLicenceSeriesValidators() {
    this.vm.form.controls.licenceSeries.clearValidators();
    if (this.isDriverCountryKz || this.isDriverCountryKg || this.isDriverCountryUz || this.isDriverCountryTj) {
      this.vm.form.controls.licenceSeries.setValidators([
        Validators.required,
        Validators.maxLength(4),
        Validators.minLength(2),
        trimmedStringValidator,
      ]);
    } else {
      this.vm.form.controls.licenceSeries.setValidators([
        Validators.required,
        Validators.maxLength(DRIVER_FORM_CONSTANTS.MAX_LICENSE_SERIES_LENGTH),
        trimmedStringValidator,
      ]);
    }
  }
  public setIdCardValidators() {
    if (this.isDriverCountryKz || this.isDriverCountryKg || this.isDriverCountryUz || this.isDriverCountryTj) {
      this.vm.form.controls.identityCardNumber.setValidators([
        Validators.required,
        Validators.maxLength(DRIVER_FORM_CONSTANTS.MAX_PASSPORT_NUMBER_LENGTH),
        trimmedStringValidator,
      ]);
      this.vm.form.controls.identityCardDate.setValidators([Validators.required, moreThanNowValidator()]);
      this.vm.form.controls.identityCardPlace.setValidators([Validators.required, trimmedStringValidator]);
    }
  }

  private clearPasportAndIdCardValidators() {
    this.vm.form.controls.passportDate.clearValidators();
    this.vm.form.controls.passportPlace.clearValidators();
    this.vm.form.controls.passportNumber.clearValidators();
    this.vm.form.controls.passportSeries.clearValidators();
    this.vm.form.controls.identityCardDate.clearValidators();
    this.vm.form.controls.identityCardNumber.clearValidators();
    this.vm.form.controls.identityCardPlace.clearValidators();
  }

  public setPassportValidators() {
    this.setPassportSeriesValidators();

    if (this.isDriverCountryKz || this.isDriverCountryKg || this.isDriverCountryUz || this.isDriverCountryTj) {
      this.vm.form.controls.passportNumber.setValidators([
        Validators.required,
        Validators.maxLength(DRIVER_FORM_CONSTANTS.MAX_PASSPORT_NUMBER_LENGTH),
        trimmedStringValidator,
      ]);
      this.vm.form.controls.passportDate.setValidators([Validators.required, moreThanNowValidator()]);
      this.vm.form.controls.passportPlace.setValidators([Validators.required, trimmedStringValidator]);
    } else {
      this.vm.form.controls.passportNumber.setValidators([
        Validators.required,
        Validators.maxLength(DRIVER_FORM_CONSTANTS.MAX_PASSPORT_NUMBER_LENGTH),
        trimmedStringValidator,
      ]);
      this.vm.form.controls.passportDate.setValidators([Validators.required, moreThanNowValidator()]);
      this.vm.form.controls.passportPlace.setValidators([Validators.required, trimmedStringValidator]);
    }
  }

  public setIinValidators() {
    if (this.isDriverCountryKz) {
      this.vm.form.controls.iin.setValidators([
        Validators.required,
        Validators.maxLength(DRIVER_FORM_CONSTANTS.MAX_IIN_LENGTH),
        trimmedStringValidator,
      ]);
    } else {
      this.vm.form.controls.iin.clearValidators();
    }
  }

  public getLicenceSeriesMask() {
    const country = this.vm.form?.controls.country.value?.id;
    return country
      ? this.regExpConf.ngxMask.driverLicence[country.toLowerCase()]?.series.mask
      : this.regExpConf.ngxMask.driverLicence.default?.series.mask;
  }

  public getLicenceSeriesPatterns() {
    const country = this.vm.form?.controls.country.value?.id;
    return country
      ? this.regExpConf.ngxMask.driverLicence[country.toLowerCase()]?.series.patterns
      : this.regExpConf.ngxMask.driverLicence.default?.series.patterns;
  }

  public getLicenceNumberMask() {
    const country = this.vm.form?.controls.country.value?.id;
    return country
      ? this.regExpConf.ngxMask.driverLicence[country.toLowerCase()]?.number.mask
      : this.regExpConf.ngxMask.driverLicence.default?.number.mask;
  }

  public getLicenceNumberPatterns() {
    const country = this.vm.form?.controls.country.value?.id;
    return country
      ? this.regExpConf.ngxMask.driverLicence[country.toLowerCase()]?.number.patterns
      : this.regExpConf.ngxMask.driverLicence.default?.number.patterns;
  }

  public validationErrors(e: ValidationErrors | null) {
    const country = this.vm.form?.controls.country.value?.id;
    if (e?.trimmedString) return this.vm.translate.instant('shared.errors.trimmedString');
    if (e?.required) return this.vm.translate.instant('shared.errors.required');
    if (e?.lessThanAdultAge) return this.vm.translate.instant('shared.errors.lessThanAdultAge');
    if (e?.moreThanNow) return this.vm.translate.instant('shared.errors.moreThanNow');
    if (e?.innError) return this.vm.translate.instant('shared.errors.innPhys');
    if (e?.innIncorrectError) return this.vm.translate.instant('shared.errors.innIncorrect');
    if (e?.maxlength) return this.vm.translate.instant('shared.errors.maxLength', { value: e?.maxlength?.requiredLength });
    if (e?.minlength) return this.vm.translate.instant('shared.errors.minLength', { value: e?.minlength?.requiredLength });

    if (e?.mask) {
      if (country === TnSupportedCountry.RU) {
        return this.vm.translate.instant('carrier.directory.marketplaceDriver.errors.mask', { value: 6 });
      }
      if ([TnSupportedCountry.KZ, TnSupportedCountry.BY].includes(country)) {
        return this.vm.translate.instant('carrier.directory.marketplaceDriver.errors.mask', { value: 6 });
      }

      return this.vm.translate.instant('carrier.directory.marketplaceDriver.errors.mask', {
        value: e.mask.requiredMask.replace(' ', '').length,
      });
    }

    return '';
  }

  public validationErrorsPhone(e: ValidationErrors | null) {
    if (e?.invalidPhoneNumber) {
      if ([TnSupportedCountry.BY, TnSupportedCountry.KG, TnSupportedCountry.TJ, TnSupportedCountry.UZ].includes(e?.invalidPhoneNumber)) {
        return 'Неверный формат номера';
      }
      return this.vm.translate.instant('shared.errors.phone');
    }
    if (e?.required) return this.vm.translate.instant('shared.errors.required');
    return '';
  }

  public validationErrorsIIN(e: ValidationErrors | null) {
    if (e?.trimmedString) return this.vm.translate.instant('shared.errors.trimmedString');
    if (e?.mask) {
      return this.vm.translate.instant('carrier.directory.marketplaceDriver.errors.mask', {
        value: DRIVER_FORM_CONSTANTS.MAX_IIN_LENGTH,
      });
    }
    if (e?.required) return this.vm.translate.instant('shared.errors.required');

    return '';
  }

  public searchAgentCarriers(search) {
    this.agentCarrierInputSubject$.next(search);
  }

  public ngOnDestroy() {
    this.vm.dispose();
  }
}

