import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatExpansionModule } from '@angular/material/expansion';
import { RouterModule } from '@angular/router';
import { TnCoreModule } from '@transport/core';
import { TnUiKitModule } from '@transport/ui-kit';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnUiTranslateModule } from '@transport/ui-translate';

import { TnCompanyLogoComponent } from './components/company-logo/company-logo.component';
import { TnHeaderComponent } from './components/header/header.component';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { TnUserInfoComponent } from './components/user-info/user-info.component';

@NgModule({
  imports: [CommonModule, RouterModule, TnUiMaterialModule, TnUiTranslateModule, TnCoreModule, TnUiKitModule, MatExpansionModule],
  declarations: [TnHeaderComponent, TnUserInfoComponent, TnCompanyLogoComponent, NotFoundComponent],
  exports: [TnHeaderComponent, NotFoundComponent, TnUserInfoComponent],
})
export class TnUiLayoutModule {}
