import { Directive, Host, Input, OnInit } from '@angular/core';
import { MatSelect } from '@angular/material/select';

import { TnCustomErrorStateMatcher } from './custom-error-state-matcher';

@Directive({
  selector: '[transportSelectErrorState]',
})
export class TnSelectErrorStateMatcherDirective implements OnInit {
  @Input() public transportSelectErrorState = false;

  constructor(@Host() private readonly host: MatSelect) {}

  public ngOnInit(): void {
    if (Boolean(this.transportSelectErrorState)) {
      this.host._defaultErrorStateMatcher = new TnCustomErrorStateMatcher();
    }
  }
}
