import { Overlay, ScrollStrategy } from '@angular/cdk/overlay';
import { InjectionToken } from '@angular/core';

import { transportTooltipScrollStrategyFactory } from './tooltip.directive';

export const SCROLL_THROTTLE_MS = 20;

export const DEFAULT_MARGIN_OFFSET = 64;

export const DEFAULT_Y_OFFSET = 12;

export const DEFAULT_X_OFFSET = 12;

export const TRANSPORT_TOOLTIP_SCROLL_STRATEGY = new InjectionToken<() => ScrollStrategy>('transport-tooltip-scroll-strategy');

export const TRANSPORT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER = {
  provide: TRANSPORT_TOOLTIP_SCROLL_STRATEGY,
  deps: [Overlay],
  useFactory: transportTooltipScrollStrategyFactory,
};
