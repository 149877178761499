import { DatePipe } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { transport } from '@transport/proto';
import { TnDriverInviteModal } from '@transport/ui-components';
import {
  BLACK_LIST_TYPE,
  createNewDriver,
  dirtyCheckFormHandle,
  DRIVER_FORM_CONSTANTS,
  DRIVER_GENDER,
  getEntityFlowActions,
  IDriver,
  IDriverForm,
  IEntityFlowAction,
  TDirectoryEntityPredefinedAction,
  TnDriverPageComponentData,
} from '@transport/ui-interfaces';
import {
  getDriverData,
  getDriverInfoFromServer,
  IDirtyCheckComponent,
  TnCarrierDirectoryApiService,
  TnCurrentUserFacade,
  TnDriverFacade,
  TnToasterFacade,
  TOAST_TYPE,
} from '@transport/ui-store';
import { getTranslateParams, regExpConfig } from '@transport/ui-utils';
import { BehaviorSubject, forkJoin, Observable, of } from 'rxjs';
import { concatMap, filter, startWith, switchMap } from 'rxjs/operators';
import { TnAnalyticsService } from 'libs/transport-ui-store/src/lib/current-user/services/analytics/analytics.service';

@UntilDestroy()
@Component({
  selector: 'transport-driver-page',
  templateUrl: './driver-page.component.html',
  styleUrls: ['./driver-page.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- TODO: OnPush change detection
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TnDriverPageComponent implements OnInit, IDirtyCheckComponent, OnDestroy {
  @HostBinding('class.content-container') public readonly contentContainer = true;

  public readonly regExpConf = regExpConfig;

  public pageData: TnDriverPageComponentData = new TnDriverPageComponentData();

  public mode: TDirectoryEntityPredefinedAction = 'view';

  public returnUrl?: string;

  public formData?: FormGroup<{ driver: IDriverForm; agentCarrier: string | null }>;

  private readonly isLoadingSubject = new BehaviorSubject(false);

  public isLoading$ = this.isLoadingSubject.asObservable();

  public limitations = getTranslateParams<typeof DRIVER_FORM_CONSTANTS>(DRIVER_FORM_CONSTANTS);

  public isDirty$ = of(false);

  private readonly ignoreDirtySubject = new BehaviorSubject<boolean>(false);

  public ignoreDirty$ = this.ignoreDirtySubject.asObservable();

  public agentCarriers$ = this.driverFacade.agentCarriers$;

  public blackListType = BLACK_LIST_TYPE;

  public actions: IEntityFlowAction[] = [];

  public get isReadOnlyMode(): boolean {
    return this.mode !== 'create' && this.mode !== 'edit' && this.mode !== 'copy';
  }

  public get backButtonTitle() {
    return Boolean(this.returnUrl) ? 'back' : 'backToTheList';
  }

  constructor(
    private readonly fb: FormBuilder,
    private readonly datePipe: DatePipe,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly driverFacade: TnDriverFacade,
    private readonly carrierDirectoryService: TnCarrierDirectoryApiService,
    public readonly userFacade: TnCurrentUserFacade,
    private readonly toastFacade: TnToasterFacade,
    private readonly dialog: MatDialog,
    private readonly analytics: TnAnalyticsService,
  ) {}

  public get isArchived(): boolean {
    return this.pageData.driver?.isArchived ?? false;
  }

  public get blackListInfo() {
    return this.pageData.driver?.blackListInfo ?? [];
  }

  public get isAgentWorkScheme() {
    return Boolean(this.userFacade.currentUser.isAgentOrExpeditor);
  }

  public setInputsUsingActivatedRouteData(): Observable<{
    driverData: TnDriverPageComponentData;
    agentCarriers: transport.IAgentCarrier[];
  }> {
    const id = this.route.snapshot.paramMap.get('id') ?? void 0;
    this.returnUrl = this.route.snapshot.queryParamMap.get('returnUrl') ?? void 0;
    const action = this.route.snapshot.data.action as TDirectoryEntityPredefinedAction;
    this.isLoadingSubject.next(true);
    return forkJoin({
      driver: typeof id !== 'undefined' ? this.driverFacade.getDriver(id) : of(createNewDriver()),
    }).pipe(
      switchMap(({ driver }) => {
        const res = new TnDriverPageComponentData();
        res.driver = { ...res.driver, ...driver };
        res.predefinedAction = action;
        res.select = {
          countries: this.carrierDirectoryService.getCountriesCodeList(),
        };
        return of(res);
      }),
      concatMap(data => {
        const companyName = data.driver.agentCarrier?.name ?? this.userFacade.currentUser.organization?.name ?? '';
        return forkJoin({
          driverData: of(data),
          agentCarriers: this.driverFacade.getAgentCarriersByName(data.predefinedAction === 'create' ? '' : companyName),
        });
      }),
    );
  }

  public searchAgentCarrier(event: { term: string }) {
    this.driverFacade.searchAgentCarrier(event.term);
  }

  public clearAgentCarrier() {
    this.searchAgentCarrier({ term: '' });
  }

  public navigateBack(id?: string) {
    if (typeof this.returnUrl !== 'undefined') {
      void this.router.navigateByUrl(Boolean(id) ? this.returnUrlWithId(id as string) : this.returnUrl);
    } else {
      void this.router.navigate(['/directory/carrier/drivers']);
    }
  }

  public save() {
    this.formData?.updateValueAndValidity();
    this.formData?.markAllAsTouched();
    if (Boolean(this.formData?.invalid)) {
      this.toastFacade.showMessage('shared.errors.formInvalid');

      this.analytics.trackValidationError(this.formData);
      return;
    }
    let saver: Observable<IDriver> = of({ gender: DRIVER_GENDER.MALE });
    const driverForm = this.formData?.controls?.driver as FormGroup<IDriverForm>;
    let driverData = getDriverData(driverForm, this.datePipe);
    if (Boolean(this.isAgentWorkScheme)) {
      const agentCarrierValue = this.formData?.controls?.agentCarrier.value;
      driverData = {
        ...driverData,
        agentCarrierId:
          Boolean(agentCarrierValue) && this.userFacade.currentUser.organization?.id !== agentCarrierValue ? agentCarrierValue : null,
      };
    }
    switch (this.mode) {
      case 'create':
      case 'copy':
        saver = this.driverFacade.addDriver(driverData);
        break;
      case 'edit':
        saver = this.driverFacade.editDriver({
          ...driverData,
          id: this.pageData.driver?.id ?? '',
        });
        break;
      default:
        break;
    }
    void saver.pipe(untilDestroyed(this)).subscribe(({ id }) => {
      this.toastFacade.showMessage('shared.directory.drivers.message.success', TOAST_TYPE.SUCCESS);
      this.ignoreDirtySubject.next(true);
      if (Boolean(this.returnUrl)) {
        this.driverFacade.setDriverId(id as string | null);
      }
      this.navigateBack(id as string);
    });
  }

  public copy() {
    void this.router.navigate([`/directory/carrier/drivers/copy/${this.pageData.driver?.id ?? ''}`]);
  }

  public edit() {
    void this.router.navigate([`/directory/carrier/drivers/edit/${this.pageData.driver?.id ?? ''}`]);
  }

  public remove() {
    this.driverFacade.removeDriver(this.pageData.driver?.id as string, this.pageData.driver?.name as string);
  }

  public ngOnInit(): void {
    void this.setInputsUsingActivatedRouteData()
      .pipe(untilDestroyed(this))
      .subscribe(({ driverData, agentCarriers }) => {
        this.driverFacade.setSelectedAgentCarrier(agentCarriers);
        this.isLoadingSubject.next(false);
        this.pageData = driverData;
        this.switchMode();
        this.modeInit();
        const agentCarrierId = this.pageData.driver.agentCarrier?.id ?? this.userFacade.currentUser.organization?.id ?? '';
        this.formData?.patchValue(
          {
            driver: getDriverInfoFromServer(this.pageData.driver),
            agentCarrier: this.mode === 'create' ? null : agentCarrierId,
          },
          { emitEvent: false },
        );
        if (this.mode === 'remove') {
          this.remove();
        }
      });

    void this.driverFacade.removeDriver$.pipe(untilDestroyed(this)).subscribe(() => this.navigateBack());

    void this.driverFacade.changedDriverStatus$
      .pipe(
        filter(driverInfo => Boolean(driverInfo)),
        untilDestroyed(this),
      )
      .subscribe(driverInfo => {
        if (Boolean(this.pageData.driver) && this.pageData.driver?.id === driverInfo?.id) {
          this.pageData.driver.verificationStatus = driverInfo?.verificationStatus;
        }
      });
  }

  public ngOnDestroy() {
    this.clearAgentCarrier();
  }

  public inviteDriver() {
    const { hasBeenInvitedToDriverMobile, id } = this.pageData.driver;
    this.dialog.open(TnDriverInviteModal, {
      width: '400px',
      data: { hasBeenInvitedToDriverMobile, driverId: id },
    });
  }

  private returnUrlWithId(id: string): string {
    return (this.returnUrl as string).includes('?') ? `${this.returnUrl}&driverId=${id}` : `${this.returnUrl}?driverId=${id}`;
  }

  private modeInit(): void {
    this.formData = this.createFormGroup();
    this.isReadOnlyMode ? this.formData.disable() : this.formData.enable();
    this.dirtyCheckHandle(this.formData);
    this.ignoreDirtySubject.next(false);
  }

  private switchMode() {
    this.mode = this.pageData.predefinedAction ?? 'view';
    this.actions = getEntityFlowActions(this.mode, this.isArchived);
  }

  private createFormGroup(): FormGroup {
    return this.fb.group({
      driver: new FormControl({} as IDriverForm),
      agentCarrier: new FormControl<string | null>({ value: null, disabled: this.isReadOnlyMode }),
    });
  }

  private dirtyCheckHandle(form: FormGroup) {
    this.isDirty$ = dirtyCheckFormHandle(
      form.valueChanges.pipe(
        startWith(false),
        switchMap(() => {
          return of(form.dirty);
        }),
      ),
      this.ignoreDirty$,
    );
    void this.isDirty$.pipe(untilDestroyed(this)).subscribe();
  }
}
