import { createAction, props } from '@ngrx/store';

import { IConstantsState } from './constants.state';

export const constantsNamespaceKey = 'Constants';

export const GET_CONSTANTS_CONFIG = `[${constantsNamespaceKey}] get constants config`;

export const getConfig = createAction(GET_CONSTANTS_CONFIG);

export const SET_CONSTANTS_CONFIG = `[${constantsNamespaceKey}] set constants config`;

export const setConfig = createAction(SET_CONSTANTS_CONFIG, props<{ payload: IConstantsState }>());
