<div class="flex justify-between content-center items-center w-full text-lg px-6 pt-4">
  <ng-content select="[title]"></ng-content>
  <mat-icon class="material-icons dialog-close" mat-dialog-close (click)="cancel.emit()">clear</mat-icon>
</div>

<div mat-dialog-content>
  <ng-content select="[content]"></ng-content>
</div>

<div mat-dialog-actions class="flex !justify-end content-center !px-6 gap-2">
  <button *ngIf="hasChanges" class="tw-button-outline-sm" data-test="clear-all" cdkFocusInitial (click)="clearAll.emit()">
    {{ 'shared.actions.clearAll' | translate }}
  </button>
  <button class="tw-button-solid-sm" data-test="apply" (click)="apply.emit()">
    {{ 'shared.actions.apply' | translate }}
  </button>
</div>
