import { Injectable } from '@angular/core';
import { CARRIER_RATING_REPORT_REQUEST_TYPE, ICarriersRatingDetailsReportData } from '@transport/ui-interfaces';
import moment from 'moment';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnGqlClientCarrierService } from '../gql-client-carrier/graphql-client-carrier.service';

@Injectable({
  providedIn: 'root',
})
export class TnCarrierRatingDetailsService {
  constructor(private readonly graphQL: TnGqlClientCarrierService) {}

  public getRatingData(
    carrierRatingId: number,
    offset: number,
    countPerPage: number,
  ): Observable<{ items: ICarriersRatingDetailsReportData[]; totalCount: number }> {
    return this.graphQL.queryCarriersRatingDetailsData(offset, countPerPage, carrierRatingId).pipe(
      map(resp => {
        return {
          totalCount: resp.carrierRatingReportDetailsCount ?? 0,
          items: resp.carrierRatingReportDetails.map(dtoItem => ({
            id: dtoItem.id,
            deliveryRequestNumber: dtoItem.deliveryRequestNumber,
            deliveryRequestDateUtc: moment(dtoItem.deliveryRequestDateUtc),
            deliveryContractNumber: dtoItem.deliveryContractNumber,
            deliveryContractDateUtc: moment(dtoItem.deliveryContractDateUtc),
            deliveryRequestType: dtoItem.deliveryRequestType as CARRIER_RATING_REPORT_REQUEST_TYPE,
            deliveryCompleted: dtoItem.deliveryCompleted,
            deliveryContractRefused: dtoItem.deliveryContractRefused,
            carrierFaultCanceledShipment: dtoItem.carrierFaultCanceledShipment,
            carrierFaultDelayedShipment: dtoItem.carrierFaultDelayedShipment,
          })),
        };
      }),
    );
  }
}
