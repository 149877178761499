import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TnUiComponentsModule } from '@transport/ui-components';
import { TnUiDirectivesModule } from '@transport/ui-directives';
import { TnUiKitModule } from '@transport/ui-kit';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnUiPipesModule } from '@transport/ui-pipes';
import { VehicleFormComponent } from './edit-vehicle/form/vehicle-form.component';
import { VehicleModalComponent } from './edit-vehicle/vehicle-modal/vehicle-modal.component';
import { DriverModalComponent } from './edit-driver/edit-modal/driver-modal.component';
import { DriverFormComponent } from './edit-driver/form/driver-form.component';
import { PublishVehicleModalModule } from './publish-vehicle/publish-vehicle-modal.module';
import { MpRejectAccreditationDialogComponent } from './reject-accreditation-dialog/reject-accreditation-dialog.component';
import { AccreditationBlockComponent } from './edit-driver/accreditation-block/accreditation-block.component';
import { CustomControlsModule } from '@transport/custom-controls';

@NgModule({
  declarations: [VehicleFormComponent, VehicleModalComponent, DriverFormComponent, DriverModalComponent, AccreditationBlockComponent, MpRejectAccreditationDialogComponent],
  exports: [VehicleFormComponent, VehicleModalComponent, PublishVehicleModalModule, MpRejectAccreditationDialogComponent],
  imports: [CommonModule, TnUiComponentsModule, TnUiMaterialModule, TnUiPipesModule, TnUiKitModule, TnUiDirectivesModule, CustomControlsModule],
})
export class MarketplaceSharedModule {
}
