import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { IClientTimeWarningData } from '../client-time.interface';

/**
 * Client time warning component.
 */
@Component({
  selector: 'transport-client-time-warning',
  templateUrl: './client-time-warning.component.html',
  styleUrls: ['./client-time-warning.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnClientTimeWarningComponent {
  constructor(
    private readonly dialogRef: MatDialogRef<TnClientTimeWarningComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) public data: IClientTimeWarningData,
  ) {}

  /**
   * Closes dialog.
   */
  public confirm(): void {
    this.dialogRef.close(true);
  }
}
