export const passportLocalization = {
  title: 'Паспорт',
  settings: 'Настройки профиля',
  tooltip: 'Профиль',
  index: {
    title: 'Индекс',
    instructions: 'Пользовательские инструкции к паспорту...',
  },
  bus: {
    inviteAccept: 'Приглашение принято!',
    inviteError: 'Вы не можете воспользоваться данным приглашением',
    inviteTypeError: 'Тип вашей организации не совпадает с типом организации в приглашении',
    inviteInvalidError: 'Регистрация невозможна, войдите в систему',
  },
  login: {
    title: 'Вход',
    action: 'Авторизоваться',
    continue: 'Продолжить',
    enter: 'Войти',
    sendMailAgain: 'Отправить письмо снова',
    letterSent: 'Письмо отправлено',
    form: {
      email: {
        title: 'Логин (email)',
        label: 'Логин',
        placeholder: 'E-mail',
        error: 'Неправильный адрес',
      },
      password: {
        title: 'Пароль',
        error: 'Минимальная длина 8 символов',
      },
    },
  },
  logout: {
    title: 'Выход',
  },
  signup: {
    title: 'Регистрация грузоперевозчика',
    action: 'Подтвердить регистрацию',
    entity: 'ЮРИДИЧЕСКОЕ ЛИЦО',
    individual: 'ИП',
    form: {
      inn: {
        title: 'Введите ИНН организации',
        innPhys: 'Допустимый формат 12 цифр',
        innLegal: 'Допустимый формат 10 цифр',
        ogrnIp: 'Допустимый формат 15 цифр',
        ogrnLegal: 'Допустимый формат 13 цифр',
      },
      firstName: {
        title: 'Имя',
        error: 'Максимальное кол-во 30 символов',
      },
      lastName: {
        title: 'Фамилия',
        error: 'Максимальное кол-во 30 символов',
      },
      middleName: {
        title: 'Отчество',
        error: 'Максимальное кол-во 30 символов',
      },
      email: {
        title: 'Эл. почта',
        error: 'Неправильный адрес',
      },
      phone: {
        title: 'Телефон',
      },
      companyName: {
        empty: 'Сначала введите ИНН',
        title: 'Название организации',
        error: 'Минимальная длина 8 символов',
      },
      password: {
        title: 'Пароль',
        error: 'Минимальная длина 8 символов',
      },
      carrier: {
        title: 'Грузоперевозчик',
      },
      owner: {
        title: 'Грузовладелец',
      },
      useTs: 'Есть собственный транспорт',
      useSignature: 'Есть электронная подпись',
      agreeWith: 'Соглашаюсь с',
      personalDataTitle: 'Политикой сбора и хранения персональных данных',
    },
    submitError: 'Не заполнены обязательные поля',
    submitSuccess: 'Вы успешно зарегистрировались!',
    organizationNotFound: 'Организация не найдена',
    notActive: 'Данная организация не является действующей. Для добавления допускаются только действующие организации',
  },
  submitCode: {
    back: 'Вернуться',
    enterCode: 'Теперь введите код',
    sentToMail: 'Код отправили на почту',
    sendAgain: 'Отправить код повторно',
  },
  confirmEmail: {
    positiveСontent: 'Почта подтверждена! Преейдите по ссылке приглашения.',
    negativeContent: 'Почта не подтверждена, обратитесь в службу поддержки.',
    alreadeExist:
      'Такая организация уже существует, пожалуйста, обратитесь в тех. поддержку по тел. +74999590911 или напишите на почту support@master-tms.ru',
    noNeededError: 'Подтверждение почты не требуется',
    invitationActivated: 'Приглашение активировано',
    invitationOrdersAccess: 'Доступ к заказам откроется после того, как партнер аккредитует вашу организацию.',
    tokenExpires: 'Срок действия ссылки для изменения e-mail истек. Вы можете изменить его в настройках пользователя.',
    success: 'Ваша почта изменена на ',
    prefix: 'Подтвердите изменение почты с ',
    suffix: ' на ',
    redirect: 'Перейти',
    confirm: 'Подтвердить',
    cancel: 'Отменить',
    ok: 'Хорошо',
  },
  legal: 'ООО',
  individual: 'ИП',
  selfEmployed: 'Самозанятые',
  registration: {
    title: 'Регистрация',
    expiredMessage: {
      prefix: 'Срок действия ссылки для создания нового пароля истек. Вы можете отправить ссылку на почту ',
      postfix: ' повторно',
    },
    confirmButton: 'Отправить',
    emailSentMessage: 'На адрес вашей электронной почты отправлено письмо, содержащее дальнейшие инструкции',
  },
  passwordReset: {
    title: 'Придумайте новый пароль для завершения восстановления',
    expiredMessage: {
      prefix: 'Срок действия ссылки для восстановления пароля истек. Вы можете отправить ссылку на почту ',
      postfix: ' повторно',
    },
    confirmButton: 'Отправить',
    emailSentMessage: 'На адрес вашей электронной почты отправлено письмо, содержащее дальнейшие инструкции',
  },
  passportCreateCompany: {
    form: {
      errors: {
        innPhysOrLegal: 'Допустимый формат 10 или 12 цифр',
      },
      success: {
        invitation: 'Приглашение принято',
      },
    },
  },
};
