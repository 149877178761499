import { ITab } from '@transport/ui-interfaces';

export interface IGqlDriversStatusCounters {
  [FILTERS_TABS.ALL]: number;
  [FILTERS_TABS.ACTIVE]: number;
  [FILTERS_TABS.ARCHIVED]: number;
}

export interface IFiltersToolbarTab {
  id: FILTERS_TABS;
  tab: ITab;
}

export interface IDriversFilterToolbarState {
  tabIndex: number;
  tabStatusesChanged: boolean;
}

export enum FILTERS_TABS {
  ALL = 'ALL',
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
}

export const TOOLBAR_LS_KEY = 'DriversFilterToolbar';
