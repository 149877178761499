import { animate, state, style, transition, trigger } from '@angular/animations';
import { Injectable } from '@angular/core';
import { regExpConfig } from '@transport/ui-utils';
import { BehaviorSubject } from 'rxjs';

const localStorageMenuFlag = 'menuState';

export const OFFSET_WIDTH_XL = 1280;

export enum MENU_STATE {
  IN = 'in',
  OUT = 'out',
}

export const MENU_ANIMATION = [
  trigger('menuBlockAnimation', [
    state(
      MENU_STATE.OUT,
      style({
        width: '56px'
      }),
    ),
    state(
      MENU_STATE.IN,
      style({
        width: '240px'
      }),
    ),
    transition(`${MENU_STATE.IN} => ${MENU_STATE.OUT}`, animate('400ms ease-in-out')),
    transition(`${MENU_STATE.OUT} => ${MENU_STATE.IN}`, animate('400ms ease-in-out')),
  ]),
  trigger('contentBlockAnimation', [
    state(
      MENU_STATE.IN,
      style({
        transform: 'translate3d(240px,0,0)',
        width: 'calc(100% - 240px)',
      }),
    ),
    state(
      MENU_STATE.OUT,
      style({
        transform: 'translate3d(56px, 0, 0)',
        width: 'calc(100% - 56px)',
      }),
    ),
    transition(`${MENU_STATE.IN} => ${MENU_STATE.OUT}`, animate('400ms ease-in-out')),
    transition(`${MENU_STATE.OUT} => ${MENU_STATE.IN}`, animate('400ms ease-in-out')),
  ]),
  trigger('menuBlockAnimationForMobile', [
    state(
      MENU_STATE.OUT,
      style({
        width: '0px'
      }),
    ),
    state(
      MENU_STATE.IN,
      style({
        width: '240px'
      }),
    ),
    transition(`${MENU_STATE.IN} => ${MENU_STATE.OUT}`, animate('400ms ease-in-out')),
    transition(`${MENU_STATE.OUT} => ${MENU_STATE.IN}`, animate('400ms ease-in-out')),
  ]),
  trigger('contentBlockAnimationForMobile', [
    state(
      MENU_STATE.IN,
      style({
        transform: 'translate3d(240px,0,0)',
        width: 'calc(100% - 240px)',
      }),
    ),
    state(
      MENU_STATE.OUT,
      style({
        transform: 'translate3d(0, 0, 0)',
        width: '100%',
      }),
    ),
    transition(`${MENU_STATE.IN} => ${MENU_STATE.OUT}`, animate('400ms ease-in-out')),
    transition(`${MENU_STATE.OUT} => ${MENU_STATE.IN}`, animate('400ms ease-in-out')),
  ]),
  trigger('transportContentBlockAnimation', [
    state(
      MENU_STATE.IN,
      style({
        transform: 'translate3d(240px,0,0)',
        width: 'calc(100% - 240px)',
      }),
    ),
    state(
      MENU_STATE.OUT,
      style({
        transform: 'translate3d(240px, 0, 0)',
        width: 'calc(100% - 56px)',
      }),
    ),
    transition(`${MENU_STATE.IN} => ${MENU_STATE.OUT}`, animate('400ms ease-in-out')),
    transition(`${MENU_STATE.OUT} => ${MENU_STATE.IN}`, animate('400ms ease-in-out')),
  ]),
];

@Injectable({
  providedIn: 'root',
})
export class BurgerMenuService {
  public menuState$ = new BehaviorSubject<MENU_STATE>(this.stateInitialValue);
  public contentState$ = new BehaviorSubject<string>(this.stateInitialValue);
  public isMobileDevice = regExpConfig.mobileUserAgent.regExp.test(navigator.userAgent);

  private get stateInitialValue(): MENU_STATE {
    return this.isOffsetWidthBelowBreakpoint
      ? this.stateInitialValueBelowBreakpoint
      : this.stateInitialValueAboveBreakpoint;
  }

  private get isOffsetWidthBelowBreakpoint() {
    return document.documentElement.offsetWidth < OFFSET_WIDTH_XL;
  }

  private get stateInitialValueBelowBreakpoint(): MENU_STATE {
    return MENU_STATE.OUT;
  }

  private get stateInitialValueAboveBreakpoint(): MENU_STATE {
    return (
      localStorage.getItem(localStorageMenuFlag) as MENU_STATE ||
      (document.documentElement.offsetWidth >= OFFSET_WIDTH_XL ? MENU_STATE.IN : MENU_STATE.OUT)
    );
  }

  public changeMenuViewFromSidebar(): void {
    if (this.isOffsetWidthBelowBreakpoint) {
      this.changeMenuView();
    }
  }

  public changeMenuView(): void {
    if (this.isOffsetWidthBelowBreakpoint) {
      if (this.menuState$.value === MENU_STATE.IN && this.contentState$.value === MENU_STATE.IN) {
        this.menuState$.next(MENU_STATE.OUT);
        this.contentState$.next(MENU_STATE.OUT);
        return;
      }
      this.menuState$.value === MENU_STATE.IN ? this.menuState$.next(MENU_STATE.OUT) : this.menuState$.next(MENU_STATE.IN);
      this.contentState$.value === MENU_STATE.IN ? this.contentState$.next(MENU_STATE.OUT) : this.contentState$.next(MENU_STATE.IN);
    } else {
      if (this.menuState$.value === MENU_STATE.IN && this.contentState$.value === MENU_STATE.OUT) {
        this.menuState$.next(MENU_STATE.OUT);
        localStorage.setItem(localStorageMenuFlag, this.menuState$.value);
        return;
      }
      this.menuState$.value === MENU_STATE.IN ? this.menuState$.next(MENU_STATE.OUT) : this.menuState$.next(MENU_STATE.IN);
      this.contentState$.value === MENU_STATE.IN ? this.contentState$.next(MENU_STATE.OUT) : this.contentState$.next(MENU_STATE.IN);
    }
  }
}
