import {TnCurrentUserFacade, TnGqlClientSharedService} from "@transport/ui-store";
import {Injectable} from "@angular/core";

@Injectable({
  providedIn: 'root'
})
export class TnNotificationsFunctionality {
  constructor(private readonly notificationsGraphQL: TnGqlClientSharedService, private readonly currenUser: TnCurrentUserFacade) { }

  public isOrderAvailable(orderId: string) {
    return this.notificationsGraphQL.queryObjectAvailability(this.currenUser.currentUserRole, orderId);
  }
}
