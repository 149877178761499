import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TUserPredefinedAction, USER_PERMISSION_CODE } from '@transport/ui-interfaces';

import { selectRouteParam } from '../../../ngrx-router/selectors/router.selectors';
import { IAdminState } from '../../admin.state';
import { IUserState } from '../user.interface';

const getUsersState = createFeatureSelector<IAdminState>('admin');
export const getMode = selectRouteParam<TUserPredefinedAction>('action');
export const getId = selectRouteParam('id');

const getAdminUser = createSelector(getUsersState, (state: IAdminState) => state.user);
export const getLoading = createSelector(getAdminUser, (state: IUserState) => state.loading);
export const getPageLoad = createSelector(getAdminUser, (state: IUserState) => state.isPageLoad);
export const getUser = createSelector(getAdminUser, (state: IUserState) => state.user);

export const getForm = createSelector(getAdminUser, (state: IUserState) => state.form);

export const getIsFormDisabled = createSelector(getAdminUser, (state: IUserState) => state.isFormDisabled);

export const getIsEmailEnabled = createSelector(getAdminUser, (state: IUserState) => state.isEmailEnabled);

export const getIsPermissionsDisabled = createSelector(getAdminUser, (state: IUserState) => state.isPermissionsDisabled);

export const getIsFormDirty = createSelector(getAdminUser, (state: IUserState) => state.isFormDirty);

export const isAdminCG = createSelector(getUser, user =>
  user?.permissions?.some(permission => permission.codename === USER_PERMISSION_CODE.ADMIN_CG),
);
