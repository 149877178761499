import { createFeatureSelector, createSelector } from '@ngrx/store';
import { DOCUMENT_TYPE, IOrder } from '@transport/ui-interfaces';

import { makeTableSelectors } from '../../table/table-selectors';
import { IOrdersState, ordersTableFeatureName } from '../orders-table.state';
import { TnCryptoSelectors } from './tn-crypto-document.selectors';

const getOrdersState = createFeatureSelector<IOrdersState>(ordersTableFeatureName);
const ordersOwnerStateSelector = createSelector(getOrdersState, (state: IOrdersState) => state.ordersTableOwner);

export const tableSelectors = makeTableSelectors(ordersOwnerStateSelector);

class TnCryptoOrderDetailsSelectors extends TnCryptoSelectors {
  public getOrder = createSelector(getOrdersState, (state: IOrdersState, props: { order: IOrder; docType: DOCUMENT_TYPE }) => {
    return props.order;
  });
}

export const tnCryptoOrder = new TnCryptoOrderDetailsSelectors().selectors();
