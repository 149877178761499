import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VerificationFlowService } from '../../../../../../../../apps/marketplace/src/app/verification/verification-flow.service';
import { VerificationStatuses } from '@transport/ui-kit';
import {
  initialNotAcceptedPopup,
  INotAcceptedPopup,
  INotAcceptedPopupContext,
  STATUS_ICON_COLORS,
} from '../popup-container/popup.interface';

@Component({
  selector: 'marketplace-not-accepted-popup',
  templateUrl: './not-accepted-popup.component.html',
  styleUrls: ['./not-accepted-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotAcceptedPopupComponent implements AfterViewInit {
  @Input() popupContext!: INotAcceptedPopupContext;

  public popupContent: INotAcceptedPopup = initialNotAcceptedPopup;

  public readonly verificationStatuses = VerificationStatuses;

  constructor(
    private readonly translateService: TranslateService,
    private readonly cd: ChangeDetectorRef,
    private readonly verificationFlowService: VerificationFlowService,
  ) {}

  ngAfterViewInit(): void {
    this.popupContent = {
      header: this.getHeader(),
      button: this.getButton(),
      message: this.getMessage(),
    };
    this.cd.detectChanges();
  }

  private getMessage() {
    switch (this.popupContext.riskProfileStatus) {
      case this.verificationStatuses?.notStarted: {
        return this.notStartedMessage;
      }
      case this.verificationStatuses?.checking: {
        return this.inCheckingMessage;
      }
      case this.verificationStatuses?.denied: {
        return this.deniedMessage;
      }
      default:
        return this.deniedMessage;
    }
  }

  private get notStartedMessage() {
    return {
      statusText: this.translateService.instant('shared.mp.cargo.table.popup.notAccepted.notInitiated.status'),
      messageText: this.translateService.instant('shared.mp.cargo.table.popup.notAccepted.notInitiated.message'),
      iconColor: STATUS_ICON_COLORS.BLUE,
    };
  }

  private get inCheckingMessage() {
    return {
      statusText: this.translateService.instant('shared.mp.cargo.table.popup.notAccepted.initiated.inProgressStatus'),
      messageText: this.translateService.instant('shared.mp.cargo.table.popup.notAccepted.initiated.inProgressMessage'),
      iconColor: STATUS_ICON_COLORS.ORANGE,
    };
  }

  private get deniedMessage() {
    return {
      statusText: this.translateService.instant('shared.mp.cargo.table.popup.notAccepted.initiated.deniedStatus'),
      messageText: this.translateService.instant('shared.mp.cargo.table.popup.notAccepted.initiated.deniedMessage'),
      iconColor: STATUS_ICON_COLORS.RED,
    };
  }

  private getHeader(): string {
    return this.popupContext.isAuction ? this.auctionHeader : this.acceptHeader;
  }

  private get auctionHeader() {
    return this.translateService.instant('shared.mp.cargo.table.popup.notAccepted.auctionHeader');
  }
  private get acceptHeader() {
    return this.translateService.instant('shared.mp.cargo.table.popup.notAccepted.takeHeader');
  }

  private getButton() {
    if (this.popupContext.riskProfileStatus === this.verificationStatuses.notStarted) {
      return this.notStartedButton;
    }
    return this.popupContext.isAuction ? this.auctionButton : this.takeButton;
  }

  private get notStartedButton() {
    return {
      text: this.translateService.instant('shared.mp.cargo.table.popup.notAccepted.notInitiated.button'),
      isDisabled: false,
      withIcon: true,
    };
  }
  private get auctionButton() {
    return {
      text: this.translateService.instant('shared.mp.cargo.table.popup.notAccepted.auctionButton'),
      isDisabled: true,
      withIcon: false,
    };
  }
  private get takeButton() {
    return {
      text: this.translateService.instant('shared.mp.cargo.table.popup.notAccepted.takeButton'),
      isDisabled: true,
      withIcon: false,
    };
  }

  public startVerification(): void {
    this.verificationFlowService.openModal();
  }
}
