export const marketplaceLocalization = {
  sideBar: {
    items: {
      title: {
        check: 'Проверки',
        cargo: 'Грузы',
        transport: 'Транспорт',
        transportMarket: 'Транспорт',
        my: 'Мои',
        orders: 'Заказы',
        tender: 'Тендеры',
        tenderTn: 'Тендеры ТН',
        vehicles: 'Транспортные средства',
        drivers: 'Водители',
        staff: 'Сотрудники',
        carriers: 'Внешние перевозчики',
        settings: 'Настройки',
        statistics: 'Статистика',
        serviceRegistry: 'Отчет об услугах',
        quotas: 'Отчет по квотам',
        pretensions: 'Претензии',
      },
    },
  },
  workspaces: {
    title: 'Выберите профиль для работы с заявками грузовладельца',
    openMarket: {
      title: 'Открытый рынок',
    },
  },
  counterOffer: {
    counterOffer: 'Встречное предложение',
    counterOfferHistory: 'История предложений',
    sendCounterOffer: 'Отправить',
    userCounterOffer: 'Ваше предложение',
  },
  counterOfferPopup: {
    sendCounterOffer: 'Отправить встречное',
    counterOfferSuccess: 'Встречное предложение отправлено',
    counterOfferError: 'Встречное предложение не отправлено',
  },
  ownerOffer: {
    title: 'Оферта грузовладельца',
    accept: 'Принять оферту',
    cancel: 'Отмена',
  },
  acknowledgeSuccess: 'Вы успешно приняли заявку',
  cargo: {
    container: {
      found: 'Найдено заказов',
      foundShort: 'Найдено',
      refresh: 'Обновить',
      toggler: {
        partners: 'Партнёры',
        open: 'Открытый рынок',
      },
      tradingParticipantsCount: 'Участников: {{count}}',
    },
    filter: {
      loadingPlace: {
        label: 'Откуда',
        placeholder: 'Нас. пункт',
      },
      unloadingPlace: {
        label: 'Куда',
        placeholder: 'Нас. пункт',
      },
      dateFrom: {
        label: 'Погрузка',
        placeholder: 'от',
      },
      dateTo: {
        label: '',
        placeholder: 'до',
      },
      bodySubtype: {
        label: 'Кузов ТС',
      },
      orderNumber: {
        placeholder: 'Номер заявки',
      },
      clear: 'Очистить',
    },
    tableParticipate: {
      title: 'Участвую',
    },
    cards: {
      header: 'Грузы',
      loadingUnloadingMethod: 'Погр/разгруз:',
      deliveryMethod: 'Способ:',
      deliveryMethods: {
        additionalCargo: 'Возможен догруз',
        individualCar: 'Отдельная машина',
      },
      loading: 'Погрузка',
      unloading: 'Разгрузка',
      weightPostfix: 'т',
      volumePostfix: 'м³',
      notSpecified: 'не указан',
      package: 'Упаковка',
      cargoPackagesCountPostfix: 'шт',
      dimensions: 'Габариты',
      dimensionsPostfix: 'м',
      auctionLeftTime: 'До завершения торгов осталось:',
      participate: {
        header: 'Участвую',
        more: 'Подробнее',
        vehicle: 'Машина:',
        additionalsPoints: 'Доп точки',
        cargo: 'Груз',
      },
    },
    table: {
      header: {
        orderType: 'тип заказа/заказчик',
        timer: 'таймер',
        loading: 'погрузка',
        additionalPoints: 'доп точки',
        unloading: 'разгрузка',
        vehicle: 'машина',
        cargo: 'груз',
        price: 'цена',
        actions: 'действия',
      },
      orderType: {
        auction: 'Аукцион',
        express: 'Срочный',
        bidding: 'Сбор предложений',
      },
      actions: {
        take: 'Забрать',
        participate: 'Участвовать',
        change: 'Изменить',
        win: 'Ставка выигрывает',
        offerPlaced: 'Предложение отправлено',
        biddingFinished: 'Торги завершены',
        fail: 'Ставка проигрывает',
        auctionFinished: 'Завершён',
        auctionCancelled: 'Отменён',
        winner: 'Вы победили',
        taken: 'Вы забрали заказ',
        loose: 'Вы проиграли',
        notParticipate: 'Не участвовал',
      },
      tooltip: {
        loading: 'Места погрузки:',
        unloading: 'Места разгрузки:',
      },
      popup: {
        take: {
          pickUpOrder: 'Забрать заказ',
          orderCancelled: 'Заказ отменен',
          priceWithVat: 'Цена с НДС',
          priceWithoutVat: 'Цена без НДС',
          orderWasTaken: 'Заказ забрали',
          take: 'Забрать заказ',
          orderWasMovedTitle: 'Заказ №{{value}} теперь Ваш!',
          orderWasMoved: 'Заказ перемещен в раздел "Заказы"',
          goToOrder: 'Перейти к оформлению',
          takenErrorTitle: 'Ошибка',
          takenError: 'Заказ уже забрали',
        },
        auction: {
          takePart: 'Участие в торгах',
          yourPrice: 'Ваша цена:',
          step: 'Шаг: ',
          withVat: 'с НДС',
          withoutVat: 'без НДС',
          auctionFinished: 'Аукцион завершён',
          auctionCancelled: 'Аукцион отменён',
          placeBet: 'Установить цену',
          betSuccess: 'Ставка принята',
          betError: 'Ошибка',
          betErrorTooLow: 'Ваша цены выше текущего минимума',
        },
        bidding: {
          takePart: 'Участие в торгах',
          yourPrice: 'Ваша цена:',
          step: 'Шаг: ',
          withVat: 'с НДС',
          withoutVat: 'без НДС',
          auctionFinished: 'Аукцион завершён',
          auctionCancelled: 'Аукцион отменён',
          placeBet: 'Установить цену',
          betSuccess: 'Ставка принята',
          betError: 'Ошибка',
          betErrorTooLow: 'Ваша цены выше текущего минимума',
        },
        notAccepted: {
          auctionHeader: 'Участие в торгах',
          takeHeader: 'Забрать заказ',
          auctionButton: 'Сделать ставку',
          takeButton: 'Забрать заказ',
          notInitiated: {
            status: 'Проверка не начата',
            message: 'Для участия в открытых торгах пройдите аккредитацию.',
            button: 'Пройти',
          },
          initiated: {
            inProgressStatus: 'Идет проверка',
            inProgressMessage: 'Доступ откроется после проверки.',
            deniedStatus: 'В доступе отказано',
            deniedMessage: 'Получить информацию можно в тех.подержке.',
          },
        },
        message: 'Для участия в открытых торгах дождитесь окончания процесса аккредитации у партнера.',
        partnersCheck: {
          header: {
            auctionHeader: 'Участие в торгах',
            takeHeader: 'Забрать заказ',
          },
          status: 'Проверка грузовладельца',
          tip: {
            notStarted: 'Проверка не начата',
            inProgress: 'Идёт проверка',
            denied: 'Проверка пройдена неуспешно',
            accessGranted: 'Проверка пройдена',
          },
          message: {
            notStarted: 'Для участия в торгах необходимо пройти аккредитацию {{value}}',
            inProgress: 'Доступ откроется после проверки',
            denied: 'Получить информацию можно в тех.подержке',
          },
          button: {
            take: 'Забрать заказ',
            auction: 'Сделать ставку',
            startVerification: 'Пройти проверку',
          },
          errors: {
            startSuccess: {
              title: 'Запрос на партнерство отправлен',
              message: 'Доступ к заказам откроется после того, как клиент аккредитует вашу организацию.',
            },
            startError: {
              title: 'Ошибка',
              message: 'Запрос на партнерство не был отправлен. Обратитесь в техподдержку или попробуйте позднее.',
            },
            refreshPartnersError: {
              title: 'Ошибка',
              message: 'Не удалось обновить список партнеров. Обратитесь в техподдержку или попробуйте позднее.',
            },
          },
        },
      },
      withoutCargo: 'Не указан',
    },
  },
  tender: {
    table: {
      header: {
        department: 'Отправитель',
        timer: 'Таймер',
        period: 'Период',
        unloadingPlace: 'Направление',
        vehicle: 'Машина',
        weightFromTo: 'Вес, от - до',
        ordersQuantity: 'Рейсов',
        price: 'Цена за рейс',
        actions: 'Действия',
      },
      actions: {
        participate: 'Участвовать',
      },
      popup: {
        header: 'Предложить цену за рейс',
        yourBet: 'Ваше предложение',
        maxBet: 'Максимальное предложение',
        bet: 'Отправить',
        alert: 'Предложение отправлено',
        errors: {
          betMoreThanMaxPrice: 'Предложение должно быть строго меньше максимальной цены - {{value}}',
          betLessThanZero: 'Предложение должно быть больше нуля',
          error: 'Произошла ошибка',
        },
      },
    },
    detail: {
      header: 'Тендер',
      course: 'Направление',
      params: 'Параметры тендера',
      period: 'Сроки',
      ordersQuantity: 'Кол-во рейсов',
      weight: 'Вес, от - до',
      requirements: 'Требования к ТС',
      liftingCapacity: 'Грузоподъемность',
      volume: 'Объем',
      mustHaveManipulator: 'Наличие манипулятора',
      needed: 'Требуется',
      notNeeded: 'Не требуется',
      bodySize: 'Габариты кузова (ДхВхШ)',
      bodyType: 'Тип кузова',
      bidsEndLeft: 'До конца торгов',
      startPrice: 'Стартовая цена за рейс',
      carrierOffer: 'Ваше предложение',
      bidsEndDate: 'Окончание торгов',
      from: 'от',
    },
    tenderStates: {
      invitationHasBeenSent: 'Предложение отправлено',
      summingUp: 'Подведение итогов',
    },
  },
  myOrders: {
    title: 'Заказы',
    ordersNotFound: 'Заказы не найдены',
    card: {
      point: 'точки',
      timeFrom: 'с',
      timeTo: 'до',
    },
    toolbar: {
      all: 'Все',
      onSign: 'Оформление',
      onWay: 'В рейсе',
      completed: 'Завершены',
      refusal: 'Отказы',
    },
    filter: {
      title: 'Фильтры',
      search: 'Поиск',
      status: 'Статус заказа',
      customer: 'Заказчик',
      customerPlaceholder: 'Начните ввод',
      loadingPlace: 'Откуда',
      unloadingPlace: 'Куда',
      loadingDate: {
        from: 'Погрузка от',
        to: 'Погрузка до',
      },
      bodySubtype: 'Кузов ТС',
      allocationType: 'Процедура получения заказа',
      commonDatePlaceholder: 'дд.мм.гггг',
      commonPlacePlaceholder: 'Нас. пункт',
      commonSelectPlaceholder: 'Выберите значение',
      buttons: {
        apply: 'Найти',
        clear: 'Очистить',
      },
    },
    allocationType: {
      all: 'Все',
      simple: 'Срочный заказ',
      preasigned: 'Назначенный заказ',
      auction: 'Аукцион',
      bidding: 'Сбор предложений',
      rating: 'Распределена по рейтингу',
      tender: 'Распределена по тендеру {{tenderNumber}}',
      quota: 'Назначена по квоте № {{id}}',
    },
    warning: {
      sla: 'Нарушение уровня обслуживания',
      proposal: 'Запрос изменения цены перевозки',
      acknowledge: 'Требуется подтвердить заказ',
    },
  },
  myCarriers: {
    title: 'Перевозчики',
    ordersNotFound: 'Перевозчики не найдены',
    card: {
      iin: 'ИИН',
      bin: 'БИН',
      inn: 'ИНН',
      kpp: 'КПП',
      status: 'Статус',
      empty: 'Отсутствует',
    },
    toolbar: {
      all: 'Все',
      active: 'Активные',
      archived: 'Архивные',
    },
    statusTypes: {
      active: 'Действующий',
      notActive: 'Недействующий',
    },
    confirmDelete: {
      title: 'Архивирование контракта',
      content: 'Вы действительно хотите отправить в архив контракт?',
      confirm: 'В архив',
      cancel: 'Отмена',
    },
    confirmRestore: {
      title: 'Восстановить перевозчика',
      content: 'Вы действительно хотите восстановить перевозчика?',
      confirm: 'Восстановить',
      cancel: 'Отмена',
    },
    form: {
      contacts: {
        fullName: 'ФИО',
        position: 'Должность',
        phone: 'Телефон',
        email: 'Email',
      },
      organization: {
        orgType: 'Тип организации',
        orgName: 'Название организации',
        carrierName: 'Наименование',
        carrierLegalNamePlaceholder: 'АО "Наименование"',
        carrierIndividualNamePlaceholder: 'ИП "Наименование"',
        orgNameOrInn: 'Название или ИНН организации',
        ipName: 'Название ИП',
        ipNameOrInn: 'Название или ИНН',
        selfName: 'Наименование перевозчика',
        legalAddress: 'Адрес',
        bin: 'БИН',
        iin: 'ИИН',
        kpp: 'КПП',
        inn: 'ИНН',
        ogrn: 'ОГРН',
        ogrnip: 'ОГРНИП',
        status: 'Статус',
        regAddress: 'Юридический адрес',
        actualAddress: 'Фактический адрес',
        isAddressEqual: 'Фактический адрес совпадает с юридическим',
        selfEmployed: 'Самозанятый',
        contactPersons: 'Контактные лица',
        vat: 'Ставка НДС',
      },
    },
    actions: {
      create: 'Создать',
      delete: 'Архивировать',
      edit: 'Редактировать',
      restore: 'Восстановить',
      details: 'Детали',
      save: 'Сохранить',
      cancel: 'Отменить',
      addContact: '+ Добавить контакт',
    },
    carrierModal: {
      titleCreate: 'Добавление перевозчика',
      titleEdit: 'Редактирование данных перевозчика',
    },
    filter: {
      title: 'Фильтры',
      search: 'Поиск',
      status: 'Статус',
      buttons: {
        apply: 'Найти',
        clear: 'Очистить',
      },
    },
    messages: {
      deleteSuccess: 'Перевозчик успешно удален',
      deleteError: 'Ошибка удаления перевозчика',
      createSuccess: 'Перевозчик успешно создан',
      createError: 'Ошибка создания перевозчика',
      editSuccess: 'Перевозчик успешно сохранен',
      editError: 'Ошибка сохранения перевозчика',
      fetchError: 'Ошибка получения профиля перевозчика',
      archiveSuccess: 'Перевозчик успешно отправлен в архив',
      archiveError: 'Не удалось отправлен перевозчика в архив',
      restoreSuccess: 'Перевозчик успешно восстановлен',
      restoreError: 'Не удалось восстановить перевозчика',
    },
  },
  drivers: {
    table: {
      actions: {
        delete: 'Архивировать',
        edit: 'Редактировать',
        invite: 'Пригласить в мобильное приложение',
        restore: 'Восстановить',
      },
      header: {
        driver: 'Водитель',
        agentCarrier: 'Организация',
        mobileApp: 'Мобильное приложение',
        accrStatus: 'Аккредитация',
      },
      mobileStatuses: {
        activated: 'Активировал',
        notActivated: 'Не активировал',
        notInvited: 'Не приглашен',
      },
    },
    toolbar: {
      all: 'Все',
      active: 'Активные',
      archived: 'Архивные',
    },
    confirmDelete: {
      title: 'Архивирование водителя',
      content: 'Вы действительно хотите отправить в архив водителя?',
      confirm: 'В архив',
      cancel: 'Отмена',
    },
    blackList: {
      inBlackList: 'В черном списке',
      hasWarning: 'Есть ограничение',
      reason: 'Причина',
      description: 'Описание',
    },
  },
  vehicles: {
    filters: {
      button: 'Фильтры',
      title: 'Фильтры',
      vehicleType: 'Тип автомобиля',
      ownershipType: 'Тип владения исполнителя перевозки',
      loadingType: 'Тип погрузки',
      regNumber: 'Гос. номер автомобиля',
      make: 'Марка автомобиля',
      trailerRegNumber: 'Гос. номер прицепа',
      trailerBodyType: 'Тип кузова',
      trailerBodySubtype: 'Подтип кузова',
      capacity: 'Грузоподъемность',
      volume: 'Объем',
      apply: 'Применить',
      clear: 'Очистить',
    },
    blackListTable: {
      type: 'Тип',
      country: 'Страна',
      regNo: 'Гос.номер',
      reason: 'Причина',
      description: 'Комментарий',
      blockType: 'Блокировки',
    },
    blackListModal: {
      reason: 'Причина',
      description: 'Комментарий',
      send: 'Отправить',
      title: 'Добавить ТС в черный список?',
      vehicleType: 'Тип автомобиля',
      country: 'Страна',
      regNumber: 'Гос.номер',
      block: 'Блокировка',
      full: 'Полная',
      partial: 'Частичная',
      addVehicle: 'Добавить ТС',
      vehicle: 'Транспортное средство',
    },
    table: {
      createButton: 'Создать',
      editButton: 'Редактировать',
      deleteButton: 'Архивировать',
      toBlackListButton: 'Добавить в черный список',
      fromBlackListButton: 'Убрать из черного списка',
      restoreButton: 'Восстановить',
      header: {
        vehicleType: 'ТИП ТС',
        licencePlate: 'ГОС НОМЕР',
        vehicleBrand: 'МАРКА',
        trailerRegNo: 'ГОС НОМЕР ПРИЦЕПА',
        bodySubType: 'ТИП КУЗОВА',
        capacity: 'ГРУЗОПОДЪЁМНОСТЬ, Т',
        volume: 'ОБЪЁМ, М3',
        drivers: 'ВОДИТЕЛЬ',
        ownershipType: 'ТИП ВЛАДЕНИЯ',
        agentCarrier: 'Организация',

        // For new table
        tractor: 'тягач',
        trailer: 'прицеп',
        body: 'кузов',
        accreditation: 'аккредитация',
      },
      vehicleTypes: {
        TRUCK: 'Одиночное ТС',
        COUPLER: 'Сцепка',
        TRAILER: 'Фура',
      },
      ownershipTypes: {
        LEASING: 'Лизинг',
        RENT: 'Аренда',
        OWN: 'Собственное ТС',
        ATTRACTED: 'Привлеченное ТС',
        NOTOWN: 'Привлеченное ТС',
      },
      accreditation: {
        notChecked: 'Требуется проверка',
        onWork: 'На проверке',
        approved: 'Одобрено',
        redjected: 'Отказано',
      },
      noVehicles: 'Транспортные средства не найдены',
    },
    blackList: {
      inBlackList: 'В черном списке',
      hasWarning: 'Есть ограничение',
      reason: 'Причина',
      description: 'Описание',
    },
    vehicle: {
      title: {
        create: 'Создать транспортное средство',
        edit: 'Изменить данные транспортного средства',
        driver: 'Водитель',
        semiTrailer: 'Полуприцеп',
        trailer: 'Прицеп',
      },
      buttons: {
        save: 'Сохранить',
        saveAndAccreditate: 'Сохранить и Аккредитовать',
        cancel: 'Отмена',
        create: 'Создать',
        createAndAccreditate: 'Создать и Аккредитовать',
      },
      form: {
        country: 'Страна',
        regNumber: 'Государственный номер',
        type: 'Тип транспортного средства',
        make: 'Марка',
        ownershipType: 'Тип владения исполнителя перевозки',
        driver: 'ФИО',
        driverInfo: 'Добавьте водителя, чтобы он по умолчанию указывался в документах с транспортным средством.',
        trailerBodyType: 'Тип кузова',
        loadingTypes: 'Типы разгрузки',
        back: 'Задняя',
        up: 'Верхняя',
        side: 'Боковая',
        vehicleTypes: {
          truck: 'Одиночное',
          trailer: 'Фура',
          coupler: 'Сцепка',
        },
        isThirdParty: 'Привлеченное ТС',
        ownershipTypes: {
          own: 'Собственное',
          rent: 'Аренда',
          leasing: 'Лизинг',
          free_use: 'Безвозмездное пользование',
          joint_property: 'Cовместная собственность супругов',
          third_party: 'Привлеченное',
        },
        capacity: {
          title: 'Грузоподъемность',
          addon: 'тонн',
        },
        volume: {
          title: 'Объем',
          addon: 'м3',
        },
        baseLocation: {
          label: 'Место базирования',
          placeholder: 'Нас. пункт',
        },
        organization: {
          label: 'Организация',
          placeholder: 'Выберите перевозчика',
        },
        errors: {
          loadingTypes: 'Укажите тип разгрузки',
        },
      },
      messages: {
        deleteSuccess: 'Транспортное средство успешно удалено',
        deleteError: 'Ошибка удаления транспортного средства',
        createSuccess: 'Транспортное средство успешно добавлено',
        createError: 'Ошибка создания транспортного средства',
        editSuccess: 'Транспортное средство успешно сохранено',
        editError: 'Ошибка сохранения транспортного средства',
        regNumberError: 'Транспортное средство с таким номером уже существует',
        fetchError: 'Ошибка получения транспортного средства',
        accrSendSuccess: 'Транспортное средство успешно сохранено и отправлено на проверку',
        createAndAccrSuccess: 'Транспортное средство успешно создано и отправлено на проверку',
        accrSendError: 'Транспортное средство  создано, но не отправлено на проверку',
        archiveSuccess: 'Транспортное средство успешно отправлено в архив',
        archiveError: 'Не удалось добавить транспортное средство в архив',
        toBlackListSuccess: 'Транспортное средство успешно добавлено в черный список',
        fromBlackListSuccess: 'Транспортное средство успешно убрано из черного списка',
        toBlackListError: 'Не удалось добавить транспортное средство в черный список',
        fromBlackListError: 'Не удалось убрать транспортное средство из черного списка',
        restoreSuccess: 'Транспортное средство успешно восстановлено',
        restoreError: 'Не удалось восстановить транспортное средство',
        restoreExistVehicleError: 'ТС с такими документами уже есть в списке',
      },
      confirmDelete: {
        title: 'Архивирование транспортного средства',
        content: 'Вы действительно хотите отправить в архив транспортное средство?',
        confirm: 'В архив',
        cancel: 'Отмена',
      },
      blackListDelete: {
        title: 'Добавление транспортного средства в черный список',
        content: 'Вы действительно хотите добавить транспортное средство в черный список?',
        confirm: 'В черный список',
        cancel: 'Отмена',
      },
    },
  },
  vehiclesMarket: {
    table: {
      myPublications: 'Мои публикации',
      vehiclesMarket: 'Рынок транспорта',
      createButton: 'Опубликовать свою машину',
    },
  },
  reports: {
    tabs: {
      rating: 'Рейтинги',
      tender: 'Тендеры',
      auctionControl: 'Контроль аукциона',
    },
    tender: {
      reportsNotFound: 'Вы пока не выиграли ни одного тендера',
      card: {
        id: 'Номер',
        department: 'Завод',
        loadingSettlement: 'Место погрузки',
        unloadingPlace: 'Направление',
        ordersQuantity: 'Перевозок',
        lotNumber: 'Лот №',
        displayedStatus: 'Статус',
        ordersAssignedQuantity: 'Назначено',
        ordersInWorkQuantity: 'В работе',
        ordersCompletedQuantity: 'Выполнено',
        ordersCanceledQuantity: 'Отказано',
        ordersCanceledPercentage: 'Процент отказов',

        detail: 'Заказы',

        empty: 'У данного тендера нет заказов',

        status: {
          CANCELED: 'Нарушение',
          IN_WORK: 'В работе',
          COMPLETED: 'Выполнен',
        },
      },
    },
    rating: {
      filter: {
        month: 'Период',
        department: 'Завод',
        short: 'Сокращенный вид',
      },
      reportsNotFound: 'Отчеты не найдены',
      card: {
        indicator: 'Показатель',
        department: 'Завод',
        deliveryArea: 'Направление',
        currentRating: 'Текущий рейтинг',
        calculatedRating: 'Расчетный рейтинг',
        assignedDeliveryRequests: 'Назначено перевозок',
        totalDeliveriesCompleted: 'Выполнено перевозок всего',
        assignedDeliveryRequestsCompleted: 'Выполнено назначенных перевозок',
        vacantDeliveryRequestsCompleted: 'Выполнено перевозок из свободных заявок',
        importantDeliveryRequestsRefused: 'Отказы от назначенных важных заявок',
        assignedDeliveryRequestsRefused: 'Отказы от назначенных перевозок',
        deliveryContractsRefused: 'Отказы от заявок-договоров',
        carrierFaultCanceledShipments: 'Срывы срока подачи ТС на загрузку',
        refusedRequestsPercentage: 'Общий процент отказа от заявок',
        carrierFaultDelayedShipmentsPercentage: 'Процент опозданий ТС на загрузку',
        importantDeliveryRequestsRefusePercentage: 'Процент отказов от важных заявок',
        vacantRequestsDeliveriesPercentage: 'Процент выполнения заявок из свободных',
        vehicleSearchAverageTime: 'Среднее время поиска автомобиля (чч:мм)',
      },
      detailCard: {
        request: 'Заявка',
        contract: 'Договор-заявка',
        incidents: 'Инциденты',
        result: 'Результат выполнения',
        isCompleted: 'Выполнена',
        yes: 'Да',
        no: 'Нет',
        delayedShipment: 'Опоздание ТС на погрузку',
        canceledShipment: 'Cрыв срока подачи ТС на погрузку',
        contractRefused: 'Отказ от заявки-договора',
        ordersNotFound: 'Заказы не найдены',
      },
    },
    auctionControl: {
      filter: {
        month: 'Период',
        short: 'Сокращенный вид',
      },
      reportsNotFound: 'Нет выигранных аукционов за период',
      card: {
        department: 'Завод',
        blockEndDate: 'Дата окончания блокировки',
        blockPerioad: 'Период действия расчета',
        wonAuction: 'Выигранных аукционов',
        cancelAuction: 'Отказы от перевозки',
        cancelPercentage: 'Процент отказов',
        routeName: 'Направление',
      },
      detailCard: {
        request: 'Заявка',
        assignedDate: 'Дата назначения',
        canceledDate: 'Дата отказа',
        ordersNotFound: 'Заказы не найдены',
      },
    },
  },
  serviceRegistry: {
    table: {
      filter: {
        title: 'Фильтры',
        status: 'Статус',
        reportNo: 'Номер акта',
        agentBillNo: 'Номер счета',
        reportDate: {
          from: 'Дата документов от',
          to: 'до',
        },
        clear: 'Очистить',
        apply: 'Найти',
      },
      actions: {
        download: 'Скачать',
      },
      header: {
        id: 'Номер',
        accountNumber: 'Номер счета',
        accountDate: 'Дата документов',
        comments: 'Замечания',
        status: 'Статус',
        paymentBill: 'Платежный документ',
        sum: 'Сумма отчета',
        documents: 'Документы',
      },
    },
    actions: {
      accept: 'Применить',
      save: 'Сохранить',
      send: 'Отправить',
      create: 'Создать отчет',
      view: 'Oтчет',
      edit: 'Редактировать отчет',
      errorSave: 'Ошибка сохранения отчета',
      successSave: 'Отчет успешно сохранен',
      successSend: 'Отчет успешно отправлен',
      errorSend: 'Ошибка отправки отчета',
    },
    form: {
      paramsTitle: 'Параметры отчета',
      propsTitle: 'Реквизиты отчета',
      documentDate: 'Дата документов',
      period: {
        from: 'Дата с',
        to: 'Дата по',
      },
      loadingPlaceBySettlement: 'Места погрузки',
      vehicleType: 'Тип владения ТС',
      carriers: 'Грузоперевозчики',
      contract: 'Договор',
      actNumber: 'Номер акта',
      accNumber: 'Номер счета',
      fioHead: 'ФИО руководителя',
      fioAccounter: 'ФИО бухгалтера',
      serviceName: 'Наименование услуги',
      serviceInvoice: 'Номер счет-фактуры перевыставленных услуг',
      expeditorInvoice: 'Номер счета экспедитора',
      agentInvoice: 'Номер счет-фактуры на агентское вознаграждение',
      agentInvoiceForOwnVehicle: 'Номер счет-фактуры',
    },
    statuses: {
      SENT: 'Отправлен',
      DRAFT: 'Сохранен',
      READY_TO_PAY: 'READY_TO_PAY',
      ACCEPTED: 'Принят',
      ACCEPTED_FOR_ACCOUNTING: 'Проведен',
      CORRECTING: 'На корректировке',
      PAID: 'Оплачен',
      CANCELED: 'Отменен',
    },
    confirmationFilter: {
      title: 'Применить новые параметры отчета?',
      content: 'В списке есть выбранные заявки, которые будут потеряны при изменении параметров.',
      confirm: 'Да, применить',
      cancel: 'Нет',
    },
    confirmationSave: {
      title: 'Сохранить отчет?',
      content: 'Новые параметры и реквизиты будут сохранены в отчете.',
      confirm: 'Да, сохранить',
      cancel: 'Нет',
    },
    confirmationSend: {
      title: 'Отправить отчет?',
      content: 'Отчет будет отправлен грузовладельцу.',
      confirm: 'Да, отправить',
      cancel: 'Нет',
    },
    confirmationApplyParams: {
      title: 'Параметры отчета',
      content: 'Новые параметры не были применены.',
      confirm: 'Применить',
      cancel: 'Нет',
    },
  },
  pretensions: {
    table: {
      filter: {
        title: 'Фильтры',
        status: 'Статус',
        direction: 'Направление',
        clear: 'Очистить',
        apply: 'Найти',
        outgoing: 'Исходящий',
        incoming: 'Входящий',
      },
      header: {
        id: 'Номер',
        date: 'Дата',
        sum: 'Сумма',
        status: 'Статус',
        description: 'Описание',
        updatedDate: 'Обновлено',
      },
      notFound: 'Претензий не найдено',
    },
    actions: {
      accept: 'Применить',
      save: 'Сохранить',
      send: 'Отправить',
      revoke: 'Отозвать',
      create: 'Создать',
      attach: 'Приложить',
      pay: 'Оплатить',
      cancel: 'Отклонить',
      reopen: 'Переоткрыть',
      apply: 'Согласиться',
      proposeSolution: 'Предложить решение',
      acceptProposedSolution: 'Принять предложение',
      declineProposedSolution: 'Отклонить предложение',
      waitForPretensionPayment: 'Ожидать оплаты',
      startPayment: 'Начать оплату',
      waitForPretensionOriginals: 'Ожидать оригиналы',
      close: 'Закрыть',
      addReason: 'Добавить причину',
      edit: 'Редактировать претензии',
      errorSave: 'Ошибка сохранения претензии',
      successSave: 'Претензия успешно сохранена',
      successSend: 'Претензия успешно отправлена',
      errorSend: 'Ошибка отправки претензии',
      successPropose: 'Предложение решения успешно отправлено',
      errorPropose: 'Ошибка предложение решения',
      successCancel: 'Претензия отклонена',
      errorCancel: 'Ошибка отмены претензии',
      successSatisfyPretension: 'Претензия успешно оплачена',
      errorSatisfyPretension: 'Ошибка оплаты претензии',
      successRevokePretension: 'Претензия успешно отозвана',
      errorRevokePretension: 'Ошибка отзыва претензии',
      successClosePretension: 'Претензия успешно закрыта',
      errorClosePretension: 'Ошибка при закрытии претензии',
      successReopenPretension: 'Претензия успешно переоткрыта',
      errorReopenPretension: 'Ошибка при переоткрытии претензии',
      successAcceptSolutionPretension: 'Предложенное решение принято',
      errorAcceptSolutionPretension: 'Ошибка при принятии предложения',
      successDeclineSolutionPretension: 'Предложенное решение отклонено',
      errorDeclineSolutionPretension: 'Ошибка при отклонении предложения',
      successWaitForPretensionPayment: 'Ожидается оплата по претензии',
      successWaitForPretensionOriginals: 'Ожидаются оригиналы документов по претензии',
      errorWaitForPretensionPayment: 'Ошибка при переводе в ожидание оплаты',
      errorWaitForPretensionOriginals: 'Ошибка при переводе в ожидание оригиналов документов по претензии',
      noOrdersSelectedError: 'Нужно выбрать одну заявку',
      noDocumentsAttachedError: 'Для отправки претензии нужно приложить файл',
    },
    form: {
      pretensionNumber: 'Номер',
      pretensionType: 'Тип претензиии',
      helpNeeded: 'Выезд технического специалиста',
      sum: 'Сумма',
      reason: 'Причина',
      description: 'Описание',
      proposedAmount: 'Встречное решение',
    },
    details: {
      isDepartureTechnicalRepresentative: 'Выезд специалиста',
      needed: 'Требуется',
      notNeeded: 'Не требуется',
      processingTime: 'Время на обработку',
      updatedAt: 'Дата последнего обновления',
      order: 'Заявка',
      deliveryContractNumber: 'Номер заявки-договора',
      reasons: 'Причины',
      type: 'Тип',
      statusHistory: 'История статусов',
      discusion: 'Обсуждение',
      documents: 'Вложения',
      documentsError: 'Для отправки претензии нужно приложить файл',
    },
    statuses: {
      SENT: 'Выставлена',
      DRAFT: 'Черновик',
      PROPOSED_SOLUTION: 'Предложено решение',
      AGREED: 'Согласны с решением',
      REVOKED: 'Отозвана',
      WAITING_FOR_ORIGINALS: 'Ожидание оригиналов по претензии',
      WAITING_OF_PAYMENT: 'Ожидание оплаты',
      CLOSED: 'Закрыта',
      PAID: 'Удовлетворена',
      CANCELLED: 'Отменена',
      RECEIVED: 'Выставлена',
    },
    confirmationProposed: {
      title: 'Отправить предложение?',
      content: '',
      confirm: 'Да, отправить',
      cancel: 'Нет',
    },
    confirmationSatisfy: {
      title: 'Подтвердить оплату претензии?',
      content: '',
      confirm: 'Да',
      cancel: 'Нет',
    },
    confirmationCancel: {
      title: 'Отклонить претензию?',
      content: '',
      confirm: 'Да',
      cancel: 'Нет',
    },
    confirmationRevoke: {
      title: 'Отозвать претензию?',
      content: '',
      confirm: 'Да',
      cancel: 'Нет',
    },
    confirmationClose: {
      title: 'Закрыть претензию?',
      content: '',
      confirm: 'Да',
      cancel: 'Нет',
    },
    confirmationReopen: {
      title: 'Переоткрыть претензию?',
      content: '',
      confirm: 'Да',
      cancel: 'Нет',
    },
    confirmationAcceptSolution: {
      title: 'Принять предложенное решение?',
      content: '',
      confirm: 'Да',
      cancel: 'Нет',
    },
    confirmationDeclineSolution: {
      title: 'Отклонить предложенное решение?',
      content: '',
      confirm: 'Да',
      cancel: 'Нет',
    },
    confirmationWaitForPretensionPayment: {
      title: 'Перевести в ожидание оплаты?',
      content: '',
      confirm: 'Да',
      cancel: 'Нет',
    },
    confirmationWaitForPretensionOriginals: {
      title: 'Перевести в ожидание оригиналов документов?',
      content: '',
      confirm: 'Да',
      cancel: 'Нет',
    },
  },
  accreditation: {
    tabs: {
      new: 'Новые',
      onAccred: 'На проверке',
      success: 'Одобрено',
      rejected: 'Отказано',
      completed: 'Проверенные',
    },
    filter: {
      title: 'Статус',
      verified: 'Проверенные',
    },
    alert: {
      success: 'Успешно',
      error: 'Ошибка',
    },
    table: {
      id: 'ID',
      role: 'Тип',
      name: 'Наименование',
      inn: 'ИНН/КПП',
      contacts: 'Контакты',
      signer: 'Контактное лицо',
      status: 'статус',
      actions: 'Действия',
      organization: 'организация',
      tractor: 'тягач',
      trailer: 'прицеп',
      body: 'кузов',
    },
    status: {
      new: 'Создана',
      rejected: 'Отказано',
      accepted: 'Одобрено',
      onVerification: 'На проверке',
      returnedOnVerification: 'Возвращено на проверку',
      dataRequested: 'Запрошены уточнения',
    },
    actions: {
      reject: 'Вернуть на аккредитацию',
      rejectToCheck: 'Вернуть на проверку',
      requestData: 'Требуется уточнение',
      accred: 'Принять решение',
      onAccred: 'Принять решение',
      cancel: 'Отмена',
      takeToWork: 'Взять на проверку',
    },
    modals: {
      accept: 'Одобрить',
      reject: 'Отказать',
      rejectTitle: 'Подтвердить отказ в аккредитации',
      returnAccred: 'Вернуть на аккредитацию?',
      rejectPlaceholder: 'Введите причину отказа*',
      comment: 'Комментарий',
      commentRequired: 'Комментарий*',
      commentIsTooLong: 'Текст комментария не может быть более 500 символов',
      commentIsTooShort: 'Текст комментария не может быть менее 2 символов',
      notStarted: 'Заказчик ещё не начинал аккредитацию',
      blocks: {
        tractor: 'Тягач',
        car: 'Машина',
        brand: 'Марка',
        type: 'Тип',
        ownership: 'Владение',
        leasingAgreement: 'Договор лизинга',
        rentContract: 'Договор аренды',
        download: 'Скачать',
        sts: 'СТС',
        stsFirstSide: 'СТС: первая сторона',
        stsSecondSide: 'СТС: вторая сторона',
        semitrailer: 'Полуприцеп',
        trailer: 'Прицеп',
        body: 'Кузов',
        volume: 'Объём',
        volumePostfix: 'м³',
        capacity: 'Грузоподъёмность',
        capacityPostfix: 'т',
      },
    },
    sidebarBlock: {
      title: 'Статус проверки',
      statuses: {
        notStarted: 'Полный функционал рынка откроется после аккредитации',
        checking: 'Идет проверка',
        denied: 'В доступе отказано',
        opened: 'Доступ открыт',
      },
    },
    cargoBlock: {
      title: 'Осталось совсем чуть-чуть',
      titleReject: 'Открытый рынок недоступен',
      bodyText: 'Доступ к открытому рынку заказов будет открыт после прохождения аккредитации',
      bodyTextReject: 'Аккредитация пройдена неуспешно. Свяжитесь с техподдержкой для уточнения информации',
      button: 'Начать',
    },
    decisionModal: {
      header: 'Регламент проверки',
      selectRegulation: {
        notSet: 'Не выбран',
        placeholder: 'Выбрать регламент',
        button: 'Подтвердить',
      },
      textInputPlaceholder: 'Результат',
    },
    errors: {
      takeToAccred: 'Не удалось взять на проверку',
    },
  },
  publications: {
    tabs: {
      review: 'На проверке',
      approved: 'Одобрено',
      denied: 'Отказано',
      all: 'Все',
    },
    table: {
      id: 'ID',
      name: 'Наименование',
      vehicle: 'Транспорт',
      timer: 'Таймер',
      from: 'Откуда',
      to: 'Куда',
      price: 'Цена',
      status: 'Статус',
      actions: 'Действия',
    },
    modal: {
      title: 'Публикация транспорта',
      help: 'Помощь:',
      submit: 'Опубликовать',
      success: 'Успешно',
      error: {
        publication: 'Ошибка публикации ТС',
        acreditation: 'Ошибка отправки ТС на аккредитацию',
        acreditationCode: 'Ошибка отправки ТС на аккредитацию. E2460',
      },
      form: {
        vehicle: {
          label: 'Машина',
          placeholder: 'Выберите машину',
        },
        placeFrom: {
          label: 'Готов погрузиться:',
          placeholder: 'Населенный пункт',
          date: 'Когда',
          timeFrom: 'Время от',
          timeTo: 'до',
        },
        placeTo: {
          label: 'Готов ехать в:',
          placeholder: 'В любом направлении',
        },
        price: {
          label: 'Цена',
          labelAddition: '- Необязательно',
          placeholder: 'Открыт к предложениям',
          withVat: ' с НДС ',
        },
        comment: {
          label: 'Комментарий',
          labelAddition: '- Необязательно',
          placeholder: 'Оставьте свой комментарий для заказчика',
          warning: 'Не пишите контактную информацию в комментарии!',
          info: 'Машина будет опубликована на 24 часа сразу после проверки платформой',
        },
      },
    },
  },
  driverAccreditation: {
    tabs: {
      review: 'На проверке',
      approved: 'Одобрено',
      denied: 'Отказано',
      all: 'Все',
    },
    table: {
      id: 'ID',
      organization: 'Организация',
      driver: 'Водитель',
      phone: 'Телефон',
      status: 'Статус',
      actions: 'Действия',
      noAccess: 'Нет доступа',
    },
    driverModal: {
      fullName: 'ФИО',
      notSpecified: 'Не указано',
      passport: {
        title: 'Паспорт',
        series: 'Серия',
        number: 'Номер',
        date: 'Дата выдачи',
        photo: 'Разворот с фото',
        registration: 'Разворот c пропиской',
        issueLocation: 'Кем выдан',
        download: 'Скачать',
      },
      licence: {
        title: 'Водительское удостоверение',
        series: 'Серия',
        number: 'Номер',
        date: 'Дата выдачи',
        location: 'Кем выдан',
      },
      connection: {
        title: 'Связь с водителем',
        firstPhoneNumber: 'Номер телефона',
        secondPhoneNumber: 'Номер доп. телефона',
      },
      actions: {
        rejectToCheck: 'Вернуть на проверку',
      },
    },
  },
  vehicleAccreditation: {
    tabs: {
      review: 'На проверке',
      approved: 'Одобрено',
      denied: 'Отказано',
      all: 'Все',
    },
    actions: {
      returnToAccred: 'Вернуть на аккредитацию?',
      reject: 'Вернуть на проверку',
      accred: 'Вернуть на проверку',
      onAccred: 'Принять решение',
      cancel: 'Отмена',
    },
  },
  check: {
    organizations: 'Организации',
    publications: 'Публикации ТС',
    vehicles: 'ТС',
    drivers: 'Водители',

    downloadFile: {
      success: 'Файл успешно загружен',
      failure: 'Ошибка загрузки файла',
    },
  },
  sidebar: {
    partnersList: {
      header: 'Рынки партнеров',
      hide: 'Скрыть',
      showMore: 'Показать ещё {{value}}...',
    },
  },
  verification: {
    filter: {
      title: 'Фильтр',
      status: {
        accepted: 'Одобрено',
        dataRequested: 'Запрошены уточнения',
        notSet: 'На проверке',
        rejected: 'Отказано',
        checkedOut: 'Проверено',
      },
    },
    infostep: {
      header: 'Аккредитация',
      mainText: {
        firstParagraph:
          'Для безопасности всех участников Рынка грузов и транспорта мы проводим обязательную проверку регистрирующихся организаций. После предоставления необходимой информации и успешной проверки, вам откроется доступ к участию в торгах.',
        secondParagraph:
          'Введите информацию и приложите сканы во всех дальнейших полях. Можно сохранить частично заполненную форму и продолжить позже. После заполнения всех полей станет активна кнопка отправки на проверку. При необходимости наши сотрудники свяжутся с вами для уточнения деталей.',
        thirdParagraph: {
          part1:
            'Загруженная информация надежно сохранена на серверах в центре обработки данных Яндекс. Более подробно с мерами защиты можно ознакомиться',
          personalYandexDataPolicy: 'здесь',
          personalYandexDataPolicyLink: 'https://cloud.yandex.ru/security/standards/152-fz',
          part2: 'и с нашей политикой защиты данных',
          personalDataPolicy: 'здесь.',
          personalDataPolicyLink: 'https://tn-dl.ru/legal/',
        },
      },
    },
    stepper: {
      step: 'шаг {{value}}',
      contacts: 'Контакты',
      organization: 'Организация',
      vehicle: 'Машины',
      sending: 'Отправка',
      help: 'Помощь',
      requiredError: 'Для отправки необходимо заполнить все отмеченные поля',
      apiError: 'Что-то пошло не так, обратитесь в поддержку',
    },
    form: {
      contacts: {
        fullName: 'ФИО',
        post: 'Должность',
        phone: 'Телефон',
        addPhone: 'Доп. телефон',
        email: 'Почта',
        attorney: 'Доверенность на право подписания',
        has: 'Если имеется',
      },
      organization: {
        role: {
          header: 'Тип организации',
          carrier: 'Грузоперевозчик',
          carrierShort: 'ГП',
          owner: 'Грузовладелец',
          ownerShort: 'ГВ',
        },
        legalAddress: 'Адрес',
        innKpp: 'ИНН / КПП',
        inn: 'ИНН',
        ogrn: 'ОГРН',
        regulation: 'Устав',
        registrationCertificate: 'Свидетельство о регистрации',
        genDir: 'Генеральный директор',
        director: 'Руководитель',
        selfEmployedPasport: 'Паспортные данные',
        directorFullName: 'ФИО',
        directorInn: 'ИНН',
        appointmentOrder: 'Решение о назначении',
        passportPhoto: 'Разворот паспорта с фотографией',
        passportRegistrationPhoto: 'Разворот паспорта с пропиской',
        selfEmployed: 'Самозанятый',
        selfEmployedShort: 'СЗ',
        selfEmployedReferrenceShort: 'Справка о постановке на учет',
        selfEmployedReferrence: 'Справка о постановке на учет по форме КНД 1122035',
        amoCRMButtonName: 'Открыть в AmoCRM',
      },
      vehicle: {
        title: 'Транспортные средства',
        secPageTitle: 'ТС в собственности',
        ownershipText: 'Имеются ТС в собственности',
        yes: 'Да',
        no: 'Нет',
        vehicleId: 'Гос. номер',
        photoFirst: 'Фото СТС: первая сторона',
        photoSecond: 'Фото СТС: вторая сторона',
        add: 'Указать еще',
        remove: 'Удалить',
      },
      sending: {
        contactsTitle: 'Контакты',
        vehicleTitle: 'ТС в собственности',
        notSpecified: 'не указано',
        required: 'обязательно к заполнению',
        director: 'Генеральный директор',
        checkHistory: 'История статусов',
      },
      from: 'от',
    },
    notification: {
      title: 'Информация отправлена на проверку',
      info: 'Операции на рынке будут доступны сразу после проверки документов. Вам придет уведомление на почту.',
    },
    buttons: {
      begin: 'Начать',
      next: 'Далее',
      back: 'Назад',
      send: 'Отправить',
      close: 'Закрыть',
      continueLater: 'Продолжить позже',
    },
    validationErrors: {
      maxLength: 'Максимальное количество символов - {{value}}',
      regNumberMask: 'Пример верного значения: А 123 АА 12 или А 123 АА 123',
      wrongFormat: 'Неверный формат',
    },
  },
  firstLogin: {
    title: {
      firstParagraph: 'Благодарим за регистрацию в логистической платформе MasterTMS!',
    },
    content: {
      firstParagraph:
        'Вы зарегистрированы как грузовладелец и имеете доступ к двум программным продуктам – вашей персональной обособленной от других пользователей системе управления перевозками MasterTMS, а также к открытому рынку грузов и транспорта для поиска новых перевозчиков.',
      secondParagraph:
        'Внутри вашей персональной, обособленной от других пользователей системе управления перевозками MasterTMS, вы можете под своим брендом приглашать ваших проверенных перевозчиков, размещать для них заявки на перевозку и эффективно организовать логистический процесс в цифровом виде по собственным правилам.',
      third:
        'В случае необходимости, вы можете размещать отдельные или все заявки на перевозку на открытом маркетплейсе грузов и транспорта, где они станут видны внешним перевозчикам, которые смогут поторговаться за ваш заказ и предложить свои условия.',
      fourth:
        'Предложения могут делать только те перевозчики, которые прошли проверку и допущены на платформу. Вы выбираете лучшее предложение.',
      fives: '',
      six: 'Узнать о том, как работать с платформой и размещать заказы для грузоперевозчиков, вы сможете в нашей ',
      base: 'базе знаний',
      last: ', виджет которой расположен внизу страницы справа.',
    },
    button: 'Ок',
  },
  settings: {
    header: 'Настройки',
    toolbar: {
      save: 'Сохранить',
      edit: 'Редактировать',
      cancel: 'Отмена',
    },
    form: {
      completeWhenRouteClosed: 'Подтверждать завершение заявки после выполнения через МП',
    },
    alerts: {
      success: 'Настройки успешно изменены',
      error: 'Ошибка при сохранении настроек. Попробуйте снова',
    },
  },
};
