import { Inject, Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { APP_ENV, TnAppEnvironment, USER_ROLE } from '@transport/ui-interfaces';
import { regExpConfig } from '@transport/ui-utils';
import { MobilePlaceholderModal } from './mobile-placeholder/mobile-placeholder.component';
import { MatDialog } from '@angular/material/dialog';
import { TnCurrentUserFacade } from '@transport/ui-store';

/**
 * Authenticated guard.
 */
@Injectable({
  providedIn: 'root',
})
export class CheckMobileGuard implements CanActivate {
  public authUrls = ['passport/login'];

  constructor(private readonly user: TnCurrentUserFacade, private readonly dialog: MatDialog, @Inject(APP_ENV) public readonly env: TnAppEnvironment) {}

  public canActivate(next: ActivatedRouteSnapshot) {
    if (this.user.currentUserRole === USER_ROLE.OWNER && regExpConfig.mobileUserAgent.regExp.test(navigator.userAgent)) {
      this.dialog.open(MobilePlaceholderModal, {
        height: '100vh',
        disableClose: true,
        position: { right: '0px', top: '0px' },
        width: `100%`,
        maxWidth: '100vw',
        data: {},
      });
      return false;
    }
    return true;
  }
}
