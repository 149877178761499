import { Directive, HostListener, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[transportTrim]',
})
export class TnTrimDirective {
  constructor(@Optional() private readonly formControl: NgControl) {}

  @HostListener('input', ['$event.target.value']) public onInput(event) {
    if (typeof event === 'string') {
      this.formControl.control?.patchValue(event.trim());
    } else {
      this.formControl.control?.patchValue(this.formControl.control.value);
    }
  }
}
