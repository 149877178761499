import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

export const defaultItemsAmoun = 5;

@Component({
  selector: 'transport-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnAccordionComponent implements OnInit {
  @Input() public list;

  @Input() public title = '';

  @Input() public itemsAmount = defaultItemsAmoun;

  @Output() public readonly action = new EventEmitter();

  public listSize!: number;

  public listOpen = false;

  constructor(private readonly cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.listSize = this.itemsAmount;
    this.cdr.markForCheck();
  }

  public openAllPartnersList(): void {
    if (this.listOpen) {
      this.listSize = this.list.length;
    } else {
      this.listSize = this.itemsAmount;
    }
  }
}
