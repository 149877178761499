// Yandex Maps types

export interface IYaMap {
  container: {
    getElement: () => { style: { height: number } };
    fitToViewport: () => void;
  };
  events: {
    add: (eventName: string, func?) => void;
  };
  geoObjects: {
    add: (func?) => void;
    removeAll: () => void;
    splice: (val?) => void;
  };
  panes: {
    get: (str: string) => { getElement: () => { style: { filter: string } } };
  };
  options: {
    set: (name: string, fl: boolean) => void;
  };

  destroy: () => void;
  setZoom: (val: number) => void;
  setCenter: (val) => void;
  setBounds: (val) => void;
}

export interface IYaMapLib {
  ready: (handleFunc?) => void;
  // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: yandex api
  Map: (id, obj, opts?) => void;
  // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: yandex api
  multiRouter: { MultiRoute: (p1, p2) => void };
  modules: {
    isDefined: (str: string) => boolean;
    define: (name: string, arr: string[], func?) => void;
    require: (arr: string[], func?) => void;
  };
}
