import { Component, Input } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'common-dimensions-input',
  templateUrl: './common-dimensions-input.component.html',
})
export class CommonDimensionsInputComponent {
  @Input() public size: 'xs' | 'sm' | 'md' | 'lg' = 'md';
  @Input() public errorsSize: 'xs' | 'sm' | 'md' | 'lg' = 'xs';
  @Input() public showErrors = true;
  @Input() public required = false;
  @Input() public disabled = false;
  @Input() public formGroup?: FormGroup;
  @Input() public maxlength = '5';
  @Input() public dataTest = 'cargo';
  @Input() public errorToMessage: (errors: ValidationErrors | null) => string = () => 'Error';

  get dimensionsTypeControl(): AbstractControl {
    return this.formGroup?.get('length')!;
  }

  get lengthControl(): AbstractControl {
    return this.formGroup?.get('length')!;
  }

  get widthControl(): AbstractControl {
    return this.formGroup?.get('width')!;
  }

  get heightControl(): AbstractControl {
    return this.formGroup?.get('height')!;
  }
}
