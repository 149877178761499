import { SidebarItem } from '@transport/sidebar';
import { USER_ROLE } from '@transport/ui-interfaces';
import { MODULES } from '@transport/ui-utils';

export const masterSidebarItems: SidebarItem[] = [
  {
    title: 'shared.masterTms.sidebar.items.title.orders',
    route: '/orders/owner',
    icon: 'sidebar-orders',
    classes: 'mt-3',
    allowed: [USER_ROLE.OWNER],
    permissions: ['orders.read'],
    dataTest: 'orders',
  },
  {
    title: 'shared.masterTms.sidebar.items.title.routing',
    route: '/routing',
    icon: 'sidebar-routing',
    allowed: [USER_ROLE.OWNER],
    module: MODULES.ROUTING,
    dataTest: 'routing',
  },
  {
    title: 'shared.masterTms.sidebar.items.title.monitoring',
    route: '/monitoring',
    icon: 'sidebar-monitoring',
    allowed: [USER_ROLE.OWNER],
    module: MODULES.MONITORING,
    dataTest: 'monitoring',
  },
  {
    title: 'shared.masterTms.sidebar.items.title.tenders',
    route: '/tenders',
    icon: 'sidebar-tenders',
    allowed: [USER_ROLE.OWNER],
    module: MODULES.COMMON_TENDERS,
    dataTest: 'common_tenders',
  },
  {
    title: 'shared.masterTms.sidebar.items.title.quotas',
    route: '/quotas',
    icon: 'sidebar-quotas',
    allowed: [USER_ROLE.OWNER],
    module: MODULES.COMMON_TENDERS,
    dataTest: 'common_tenders',
  },
  {
    title: 'shared.masterTms.sidebar.items.title.tsm',
    route: '/tsm',
    icon: 'sidebar-tsm',
    allowed: [USER_ROLE.OWNER],
    module: MODULES.TSM,
    dataTest: 'tsm',
  },
  {
    title: 'shared.masterTms.sidebar.items.title.trustedCarriers',
    icon: 'sidebar-owner-carriers',
    allowed: [USER_ROLE.OWNER],
    route: '/carriers',
    permissions: ['carriersPartners.manage'],
    hasDivider: true,
  },
  {
    title: 'shared.masterTms.sidebar.items.title.directory',
    allowed: [USER_ROLE.OWNER],
    hasDivider: true,
    children: [
      {
        title: 'shared.masterTms.sidebar.items.title.loadingUnloadingPlaces',
        route: '/directory/owner/addresses',
        allowed: [USER_ROLE.OWNER],
        icon: 'sidebar-addresses',
        dataTest: 'loading-unloading-places',
      },
      {
        title: 'shared.masterTms.sidebar.items.title.cargoTypes',
        route: '/directory/owner/cargo-types',
        allowed: [USER_ROLE.OWNER],
        icon: 'sidebar-cargo-types',
        dataTest: 'cargo-types',
      },
      {
        title: 'shared.mp.sideBar.items.title.vehicles',
        route: '/vehicles',
        icon: 'sidebar-vehicles',
        allowed: [USER_ROLE.OWNER],
        dataTest: 'vehicles',
      },
      {
        title: 'shared.mp.sideBar.items.title.drivers',
        route: '/drivers',
        icon: 'sidebar-drivers',
        allowed: [USER_ROLE.OWNER],
        dataTest: 'drivers',
      },
      {
        title: 'shared.masterTms.sidebar.items.title.vehicleTemplates',
        route: '/directory/owner/vehicle-templates',
        allowed: [USER_ROLE.OWNER],
        icon: 'sidebar-vehicle-templates',
        dataTest: 'vehicle-templates',
      },

      {
        title: 'shared.masterTms.sidebar.items.title.sla',
        route: '/sla',
        allowed: [USER_ROLE.OWNER],
        icon: 'sidebar-sla',
        dataTest: 'sla',
      },
    ],
  },
  {
    title: 'shared.masterTms.sidebar.items.title.reports',
    route: '/reports/statistics',
    icon: 'sidebar-report',
    allowed: [USER_ROLE.OWNER],
    dataTest: 'reports',
    hasDivider: true,
  },
  {
    title: 'shared.masterTms.sidebar.items.title.usersManagement',
    route: '/admin/owner/users',
    icon: 'sidebar-my-staff',
    allowed: [USER_ROLE.OWNER],
    permissions: ['users.manage'],
    dataTest: 'users-management',
  },
  {
    title: 'shared.mp.sideBar.items.title.pretensions',
    route: '/pretensions',
    icon: 'sidebar-pretensions',
    allowed: [USER_ROLE.OWNER],
    dataTest: 'pretensions',
    module: MODULES.PRETENSIONS,
  },
  {
    title: 'shared.masterTms.sidebar.items.title.blackList',
    route: '/black-list',
    icon: 'list',
    allowed: [USER_ROLE.OWNER],
    dataTest: 'black-list',
    module: MODULES.BLACKLIST,
  },
  {
    title: 'shared.masterTms.sidebar.items.title.organizationProfile',
    route: '/owner-profile',
    icon: 'sidebar-settings',
    allowed: [USER_ROLE.OWNER],
    dataTest: 'organization-profile',
  },
  {
    title: 'shared.masterTms.sidebar.items.title.integration',
    route: '/integration',
    icon: 'sidebar-integration',
    allowed: [USER_ROLE.OWNER],
    permissions: ['integrationToken.manage'],
    dataTest: 'integration',
  },
];
