<input
  matInput
  transportClearInput
  *ngIf="!isElasticInput"
  [disabled]="disabled"
  (blur)="onTouched()"
  (ngModelChange)="onChange($event)"
  [(ngModel)]="inputValue"
  autocomplete="off"
  class="price-input"
  [prefix]="prefix"
  [mask]="inputMask"
  [suffix]="suffix"
  [isClear]="isClear"
  [thousandSeparator]="thousandSeparator"
  [allowNegativeNumbers]="allowNegativeNumbers"
  [patterns]="patterns"
  [required]="inputRequired"
  [attr.data-test]="dataTest"
/>
<input
  matInput
  *ngIf="isElasticInput"
  transportElasticInput
  transportClearInput
  [maxWidth]="134"
  [disabled]="disabled"
  (blur)="onTouched()"
  (ngModelChange)="onChange($event)"
  [(ngModel)]="inputValue"
  autocomplete="off"
  class="price-input"
  [prefix]="prefix"
  [mask]="inputMask"
  [suffix]="suffix"
  [isClear]="isClear"
  [thousandSeparator]="thousandSeparator"
  [allowNegativeNumbers]="allowNegativeNumbers"
  [required]="inputRequired"
  [attr.data-test]="dataTest"
/>
