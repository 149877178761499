import { Inject, Injectable } from '@angular/core';
import { transport } from '@transport/proto';
import { APP_ENV, createNewUser, IUser, TnAppEnvironment, USER_ROLE } from '@transport/ui-interfaces';
import { APPLICATION, TnClSsoFrameService, TnGqlClientSharedService } from '@transport/ui-store';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';
import { Observable, of } from 'rxjs';

/**
 * User service - browser local storage wrapper.
 */
@Injectable({
  providedIn: 'root',
})
export class TnUserService {
  public set isOldOrderPageDesignSupported(value: boolean) {
    value
      ? JSON.stringify(window.localStorage.setItem('isOldOrderPageDesignSupported', 'true'))
      : window.localStorage.removeItem('isOldOrderPageDesignSupported');
  }

  constructor(
    @Inject(APP_ENV) public readonly env: TnAppEnvironment,
    private readonly gql: TnGqlClientSharedService,
    private readonly tnDomainService: TnDomainService,
    private readonly ssoService: TnClSsoFrameService,
  ) {}

  public getApplication() {
    const host = window.location.protocol + '//' + window.location.host;
    if (window.location.href.includes('localhost')) {
      const port = window.location.host.split(':')[1];
      if (port === '4200') {
        return APPLICATION.CLOUD_TMS;
      }
      return APPLICATION.CLOUD_EXCHANGE;
    }
    if (host === this.env.tonnaDomain) {
      return APPLICATION.CLOUD_EXCHANGE;
    }
    return APPLICATION.CLOUD_TMS;
  }

  public getCookieName() {
    const app = this.getApplication();
    const cookieName = 'userService';
    // const cookieName = app === APPLICATION.CLOUD_TMS ? 'tmsUserService' : 'tnUserService';
    return cookieName;
  }

  /**
   * Updates user service model with new values.
   * @param newValues new values object
   */
  public saveUser(newValues: Partial<IUser>): Observable<IUser> {
    if (newValues.token) {
      this.setCookie(this.getCookieName(), JSON.stringify(newValues));
      this.ssoService.login(newValues);
      return of(newValues as IUser);
    }
    return of({} as IUser);
  }

  /**
   * Restores user service model.
   */
  public restoreUser(): Observable<IUser> {
    const cookieName = this.getCookieName();
    let storedModel = window.localStorage.getItem(cookieName);
    const cookie = this.getCookie(cookieName);
    storedModel = cookie;
    let model: IUser = createNewUser({ role: USER_ROLE.NA });
    if (storedModel !== null) {
      model = JSON.parse(storedModel);
    }
    return of(model);
  }

  /**
   * Resets/initializes user service model.
   */
  public resetUser(): void {
    // const model = createNewUser();
    // window.localStorage.setItem('userService', JSON.stringify(model));
    // Обязательно удаляем userServce, иначе не сможем выйти из старых сессий
    this.setCookie('userService', '', {
      'max-age': -1,
    });
    this.setCookie('tmsUserService', '', {
      'max-age': -1,
    });
    this.setCookie('tnUserService', '', {
      'max-age': -1,
    });
    this.setCookie('application', '', {
      'max-age': -1,
    });
  }

  public getProfile(role: USER_ROLE, isWorkingWithTN: boolean): Observable<transport.IUserProfile> {
    return this.gql.getUserProfile(role, isWorkingWithTN);
  }

  public updateCookie(newValues: Partial<IUser>) {
    this.setCookie(this.getCookieName(), JSON.stringify(newValues));
  }

  /**
   * Save the invitation token received by mail
   */
  public saveInviteToken(inviteToken: string): string {
    window.localStorage.setItem('inviteToken', JSON.stringify(inviteToken));
    /**
     * Otherwise 'inviteToken' recorded multiple times
     */
    this.setCookie('inviteToken', '', {
      'max-age': -1,
    });
    this.setCookie('inviteToken', inviteToken);
    return inviteToken;
  }

  /**
   * Get the invitation token received by mail
   */
  public getInviteToken(): string {
    const token = window.localStorage.getItem('inviteToken');
    if (token !== null) {
      return JSON.parse(token);
    }
    return '';
  }

  /**
   * Restores the invitation token.
   */
  public restoreInviteToken(): string | null {
    const inviteTokenJSON = window.localStorage.getItem('inviteToken');
    let inviteToken = null;
    if (inviteTokenJSON !== null) {
      inviteToken = JSON.parse(inviteTokenJSON);
    }
    return inviteToken;
  }

  public removeInviteToken() {
    this.setCookie('inviteToken', '', {
      'max-age': -1,
    });
    window.localStorage.removeItem('inviteToken');
  }

  public getCookie(name: string) {
    const matches = document.cookie.match(new RegExp('(?:^|; )' + name.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'));
    // TMS-7989128 TMS-8220259 всегда берем последнюю сессию тк для тонны это тонна а ТМС видит только свои куки
    return matches ? decodeURIComponent(matches[matches.length - 1]) : null;
  }

  public setCookie(name, value, options = {}) {
    //Убраем поддомен Тонны, чтобы cookie были видны обоим приложениям
    const domain = this.tnDomainService.domain.includes('market') ? this.tnDomainService.domain.replace('market.', '') : this.tnDomainService.domain;

    options = {
      path: '/',
      domain: '.' + domain,
      samesite: 'strict',
      secure: true,
      ...options,
    };

    let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

    for (const optionKey in options) {
      updatedCookie += '; ' + optionKey;
      const optionValue = options[optionKey];
      if (optionValue !== true) {
        updatedCookie += '=' + optionValue;
      }
    }

    document.cookie = updatedCookie;
  }
}
