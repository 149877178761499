export const userProfileLocalization = {
  user: {
    infoTabTitle: 'Основное',
    notificationSettingsTabTitle: 'Настройка уведомлений',
    documentsSettingsTabTitle: 'Настройка документов',
    employeeManagementTabTitle: 'Управление пользователями',
    pageTitle: 'Профиль',
    organizationTitle: 'Моя организация',
    info: {
      pageTitle: 'Личные данные',
      nameField: 'Фамилия, имя и отчество',
      emailField: 'E-mail',
      phoneField: 'Телефон',
      countryField: 'Гражданство',
      passwordField: 'Пароль',
      passwordFieldButton: 'Изменить пароль',
      changeData: 'Изменить данные',
      changeEmail: 'Изменение почты',
      confirmEmail: 'Подтвердите почту',
      saveChanges: 'Сохранить',
      okay: 'Хорошо',
      confirmEmailMessage: {
        prefix: 'Отправили письмо на адрес ',
        postfix: ' Перейдите по ссылке в письме, чтобы подтвердить новый email.',
      },
      nameEdit: {
        firstName: 'Имя',
        lastName: 'Фамилия',
        patronymic: 'Отчество',
      },
      phoneEdit: {
        errors: {
          duplicatePhone: 'Пользователь с таким телефоном уже существует в системе',
        },
      },
      emailEdit: {
        newEmail: 'Новый email',
        currentPassword: 'Пароль',
        errors: {
          duplicateEmail: 'Пользователь с таким email уже существует в системе',
        },
      },
      emailNotConfirmed: 'Email не подтверждён.',
      reInvitation: 'Отправить письмо повторно',
      passwordEdit: {
        pageTitle: 'Изменить пароль',
        currentPassword: 'Текущий пароль',
        newPassword: 'Новый пароль',
        repeatPassword: 'Повторите пароль',
        save: 'Сохранить',
        editStatus: {
          success: 'Новый пароль сохранён',
        },
      },
    },
    notificationSettings: {
      header: {
        columnNames: {
          name: 'Наименование',
          web: 'Веб-пуши',
          email: 'E−mail',
        },
      },
      assignedOrder: {
        title: 'Назначенные заявки',
        description: 'Проинформируем о назначении, бронировании заявки на перевозчика',
      },
      tenderOrder: {
        title: 'Тендеры и квоты',
        description: 'Узнавайте о создании и ходе тендеров',
      },
      auctionAndBiddingOrders: {
        title: 'Торговые процедуры',
        description: 'Узнавайте о создании, ходе, завершении, отмене и продлении аукционов и сборов предложений',
      },
      documentProcessing: {
        title: 'Оформление заявок',
        description: 'Сообщим о статусах подписания заявки-договора',
      },
      reportCompletedOrders: {
        title: 'Отчет об оказанных услугах',
        description: 'Комментарии, замечания, файлы',
      },
      completeOrder: {
        title: 'Выполнение заявки',
        description: 'Сообщим о статусе выполнения заявки',
      },
      freeOrder: {
        title: 'Свободные заявки',
        description: 'Сообщим о создании новой заявки на перевозку в системе',
      },

      cancelOrder: {
        title: 'Заявка отменена',
        description: 'Сообщим об отмене заявки',
      },
    },
    organization: {
      completeRegistration: 'Полный функционал приложения откроется после заполнения профиля организации в разделе',
      settings: 'настройки',
      goTo: 'Перейти',
      legal: 'ОПФ',
      kpp: 'КПП',
      inn: 'ИНН',
      ogrn: 'ОГРН',
      vat: 'НДС',
      dialog: {
        title: 'Редактирование профиля организации',
        saveChanges: 'Сохранить изменения',
      },
      form: {
        legal: 'Организационно-правовая форма',
        name: 'Наименование компании',
        legalAddress: 'Юридический адрес',
        postalAddress: 'Почтовый адрес',
        vatType: 'НДС',
        signer: 'Подписывающее лицо',
        phoneNumber: 'Телефон',
        email: 'E-mail',
        organizationNotFound: 'Не смогли найти организацию, измените данные',
        sameAddress: 'Почтовый адрес совпадает с юридическим',
      },
      updateOrganizationMessage: 'Информация об организации успешно обновлена',
    },
    employeeManagement: {
      create: {
        pageTitle: 'Добавление сотрудника',
      },
      edit: {
        pageTitle: 'Редактирование профиля',
      },
      view: {
        pageTitle: 'Профиль',
        employeePermissions: 'Права',
      },
      headers: {
        employeeData: 'Данные сотрудника',
        employeePermissions: 'Настройка прав',
      },
      buttons: {
        add: 'Добавить',
        cancel: 'Отменить',
        close: 'Закрыть окно',
        saveChanges: 'Сохранить изменения',
        addEmployee: 'Добавить сотрудника',
      },
      permissions: {
        canManageCarriersPartners: 'Управление перевозчиками-партнерами',
        canManageCompanyProfile: 'Управление профилем компании',
        canManageGeneralReference: 'Управление общими справочниками',
        canManageOrders: 'Управление заявками на перевозку организации-грузоотправителя',
        canManageUsersAndTheirRoles: 'Управление пользователями и их ролями',
        canUseActsMatcher: 'Использование сервиса сравнения актов сверки',
        canViewOrganizationOrders: 'Просмотр всех заявок организации',
        isCompanyGroupAdmin: 'Администратор ГК',
        carrierCanManageDocuments: 'Управление документами',
        carrierCanManageGeneralReference: 'Управление справочниками',
        carrierCanManageOrganizationProfile: 'Управление профилем организации',
        carrierCanManageUsersAndPermissions: 'Управление пользователями ГП и правами',
        carrierCanViewOrganizationOrders: 'Просмотр всех заявок организации',
        carrierHasAccessOnTransportServices: 'Доступ к разделу "Реестр транспортных услуг"',
        carrierHasAccessToAgentCarriers: 'Управлением перевозчиками (организациями)',
      },
      email: {
        notConfirmed: 'Не подтвержден',
      },
    },
    documentsSettings: {
      facsimile: {
        editError: 'Факсимиле не сохранено',
        editSuccess: 'Факсимиле сохранено успешно',
      },
    },
  },
};
