import { Actions } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { USER_ROLE } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { TnCurrentUserFacade } from '../current-user/current-user.facade';

export class TnActionsByRoles {
  public actionsByRoles$: Observable<Action>;

  constructor(protected userRoles: USER_ROLE[], protected originActions$: Actions, protected userFacade: TnCurrentUserFacade) {
    this.actionsByRoles$ = this.originActions$.pipe(this.filterByRole<Action>());
  }

  public filterByRole<T>() {
    return filter<T>(() =>
      Boolean(
        this.userRoles.find(role => {
          return this.userFacade.isUserRole(role);
        }),
      ),
    );
  }
}
