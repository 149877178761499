import { transport } from '@transport/proto';
import {
  IBodyType,
  IDocumentType,
  IGuide,
  IStoragePointGuide,
  ITimezone,
} from 'libs/order/order-shared/src/lib/interfaces/guides.interfaces';
import { QC_GEO } from '@transport/ui-interfaces';

export function readGuide(guide?: any): IGuide {
  return {
    id: guide?.id,
    name: guide?.name || '',
  };
}

export function readDocumentType(doc?: any): IDocumentType {
  return {
    ...readGuide(doc),
    isVisible: doc?.isVisible,
    innerName: doc?.innerName,
  };
}

export function readBodyType(bodyType?: any): IBodyType {
  return {
    ...readGuide(bodyType),
    subtypes: bodyType?.subtypes?.map(readGuide) || [],
  };
}

export function readContact(contact?: any): transport.IContact {
  return {
    id: contact?.id,
    firstName: contact?.firstName,
    fullName: contact?.fullName,
    lastName: contact?.lastName,
    phoneNumber: contact?.phoneNumber,
    contactInfo: contact?.contactInfo,
  };
}

export function readStoragePoint(point?: any): IStoragePointGuide {
  return {
    ...readGuide(point),
    actions: point?.actions,
    settlement: point?.settlement,
    companyName: point?.companyName,
    pointName: point?.pointName,
    coordinateAccuracy: point.coordinateAccuracy,
  };
}

export function readTimezone(timezone?: any): ITimezone {
  return {
    id: timezone?.id,
    settlement: timezone?.settlement,
    timezone: timezone?.timezone,
  };
}
