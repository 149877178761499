/**
 * Return nested property.
 * exapmple:
 * const obj = { prop: { childProp: '100500' } };
 * const res = getObjectProperty('prop.childProp', obj); //'100500'
 */
export const getObjectProperty = (path: string | string[], obj: unknown & Record<string, unknown>, separator = '.') => {
  const properties = Array.isArray(path) ? path : path.split(separator);
  return properties.reduce((prev, curr) => (Boolean(prev) ? (prev[curr] as Record<string, unknown>) : prev), obj);
};
