import { Component, Input, OnInit } from '@angular/core';
import { FormGroup, ValidationErrors } from '@angular/forms';
import { TnCurrentUserService } from '@marketplace/auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, map } from 'rxjs';
import { LEGAL_FORMS } from '../../verification-modal/verification-modal.component';

@UntilDestroy()
@Component({
  selector: 'individual-businessman[form]',
  templateUrl: './individual-businessman.component.html',
  styleUrls: ['individual-businessman.component.scss'],
})
export class TnIndividualBusinessmanComponent implements OnInit {
  @Input() form!: FormGroup;

  public legalForm = this.currentUserService.profile$.pipe(
    untilDestroyed(this),
    map(profile => profile.organization?.legalForm),
  );

  constructor(public currentUserService: TnCurrentUserService, public translate: TranslateService) {}

  public ngOnInit() {
    combineLatest([this.currentUserService.user$, this.currentUserService.profile$])
      .pipe(untilDestroyed(this))
      .subscribe(([user, profile]) => {
        this.form.patchValue({
          fullName: `${user.firstName || ''} ${user.lastName || ''} ${user.patronymic || ''}`,
          phone: profile.phoneNumber,
          ogrnDate: this.ogrnDate,
        });
      });
  }

  get ogrnDate() {
    if (!this.currentUserService.profile.organization?.ogrnDate) return '';
    return this.currentUserService.profile.organization?.ogrnDate;
  }

  public validationErrors = (e: ValidationErrors | null) => {
    // if (e?.required) return this.translate.instant('shared.errors.required');
    if (e?.trimmedString) return this.translate.instant('shared.errors.trimmedString');
    if (e?.lessThanAdultAge) return this.translate.instant('shared.errors.lessThanAdultAge');
    if (e?.moreThanNow) return this.translate.instant('shared.errors.moreThanNow');
    if (e?.pattern) return this.translate.instant('shared.passport.passportCreateCompany.form.errors.innPhysOrLegal');
    return '';
  }

  public isIndividualInterpreneur(legalForm) {
    return legalForm?.id === LEGAL_FORMS.INDIVIDUAL_INTERPRENEUR;
  }
  public isSelfEmployed(legalForm) {
    return legalForm?.id === LEGAL_FORMS.SELFEMPLOYED;
  }
}
