import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAX_EMAIL_LENGTH, regExpConfig } from '@transport/ui-utils';

/**
 * Login form data.
 */
export interface ILoginByCodeFormData {
  email: string;
}

/**
 * Login form interface.
 */
export interface ILoginByCodeForm extends FormGroup {
  controls: {
    email: AbstractControl;
  };
}

/**
 * New login form constructor type.
 */
export type TLoginFormConstructor = (fb: FormBuilder, editableItem?: ILoginByCodeFormData, disabled?: boolean) => ILoginByCodeForm;

/**
 * Login by code form constructor
 * @param fb form builder
 * @param editableItem editable item
 * @param disabled indicates if for should be initialized with disabled controls
 */
export const newLoginByCodeForm: TLoginFormConstructor = (
  fb: FormBuilder,
  editableItem?: ILoginByCodeFormData,
  disabled = false,
): ILoginByCodeForm => {
  return fb.group({
    email: [
      editableItem?.email ?? '',
      {
        validators: Validators.compose([
          Validators.required,
          Validators.pattern(regExpConfig.email.regExp),
          Validators.maxLength(MAX_EMAIL_LENGTH),
        ]),
        disabled,
      },
    ],
  }) as ILoginByCodeForm;
};
