import { FILE_UPLOAD_STATUS } from './file.interface';
import { TnFileBase } from './file-base';

export class TnFileToUpload extends TnFileBase {
  public fileData: File;

  constructor(file: File) {
    super(file.name);
    this.fileData = file;
    this.status = FILE_UPLOAD_STATUS.NOT_UPLOADED;
  }
}
