import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { IPlace } from '@transport/ui-interfaces';

import { ITnState } from '../../../state';
import { TnTableStateFacade } from '../../../table/table-state-facade';
import { tableActions } from './actions/loading-places.actions';
import { removeLoadingPlace, removeLoadingPlaceSuccess } from './actions/loading-places-remove.actions';
import { tableSelectors } from './selectors/loading-places.selectors';

export interface ILoadingPlaceFilter {
  pointName?: string;
  organizationName?: string;
  inn?: string;
  settlement?: string;
  address?: string;
  typePoint?: string;
}

export enum POINT_TYPE {
  ALL = 'ALL',
  LOADING = 'LOADING',
  UNLOADING = 'UNLOADING',
}

@Injectable({
  providedIn: 'root',
})
export class TnDirectoryLoadingPlacesFacade extends TnTableStateFacade<ILoadingPlaceFilter> {
  public removeLoadingPlace$ = this.action.pipe(ofType(removeLoadingPlaceSuccess));

  constructor(public store: Store<ITnState>, public action: Actions) {
    super(store, tableActions, tableSelectors);
  }

  public remove(loadingPlace: IPlace) {
    this.store.dispatch(removeLoadingPlace({ loadingPlace }));
  }
}
