import { AfterViewInit, ChangeDetectorRef, Directive, ElementRef, Inject, Input, NgZone, Renderer2 } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';

import { getTableSettings, tableSettingsStorageKey } from './table.component';

const maxDeltaX = 64;
const resizeVelocity = 0.25;

@UntilDestroy()
@Directive({
  selector: '[transportTableColumnResizer]',
})
export class TnColumnResizerDirective implements AfterViewInit {
  @Input() public localStorageKey?: string;

  @Input()
  public deltas: { field: string; dx: number }[] = [];

  private readonly listeners: VoidFunction[] = [];

  private resizing = false;

  private startX = 0;

  private requestAnimationFrameID: number | null = null;

  private get tableElement() {
    return this.tableRef.nativeElement.parentElement;
  }

  private get headerElements() {
    return Array.from(this.tableElement?.querySelectorAll('th') ?? []);
  }

  private get resizerElements() {
    return Array.from(this.tableElement?.querySelectorAll('.table-column-resizer') ?? []);
  }

  constructor(
    private readonly renderer: Renderer2,
    private readonly tableRef: ElementRef<HTMLElement>,
    private readonly zone: NgZone,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  public ngAfterViewInit() {
    this.bindListeners();
    const observer = new MutationObserver(mutations => {
      mutations.forEach(mutation => {
        if (mutation.type === 'childList') {
          this.bindListeners();
        }
      });
    });
    const config = { childList: true };
    observer.observe(this.tableElement?.querySelector('thead') as Node, config);
  }

  public bindListeners() {
    this.listeners.forEach(listener => listener());
    this.resizerElements.forEach(element => {
      this.listeners.push(this.renderer.listen(element, 'mousedown', this.onResizeStart.bind(this)));
    });
  }

  private onResizeStart(event: Event & { pageX }) {
    this.resizing = true;
    this.startX = event.pageX as number;
    event.preventDefault();
    this.mouseMove(event.currentTarget as HTMLElement);
  }

  private mouseMove(column: HTMLElement) {
    const resizableMousemove = this.renderer.listen('document', 'mousemove', (event: Event & { pageX; buttons }) => {
      this.zone.runOutsideAngular(() => {
        if (this.resizing && Boolean(event.buttons)) {
          this.renderer.addClass(this.tableElement, 'resizing');
          this.renderer.addClass(column, 'resizing');
          let headerElement = column.parentElement ?? null;
          if (headerElement?.nodeName !== 'TH') {
            headerElement = column.parentElement?.parentElement?.parentElement ?? null;
          }
          const columnIndex = this.headerElements.findIndex(element => element === headerElement);
          const dx = (event.pageX - this.startX) * resizeVelocity;
          this.resize(dx, columnIndex);
        }
      });
    });
    const resizableMouseup = this.renderer.listen('document', 'mouseup', event => {
      if (this.resizing) {
        this.resizing = false;
        resizableMousemove();
        resizableMouseup();
        this.renderer.removeClass(this.tableElement, 'resizing');
        this.renderer.removeClass(column, 'resizing');
      }
    });
  }

  private resize(dx: number, columnIndex: number) {
    let newDx = this.deltas[columnIndex].dx + dx;
    if (newDx > maxDeltaX) {
      newDx = maxDeltaX;
    } else if (newDx < 0) {
      newDx = 0;
    }
    if (this.requestAnimationFrameID === null) {
      this.requestAnimationFrameID = window.requestAnimationFrame(() => {
        this.requestAnimationFrameID = null;
        this.deltas[columnIndex].dx = newDx;
        this.saveColumnSize(newDx, this.deltas[columnIndex].field);
        this.cdr.detectChanges();
      });
    }
  }

  private saveColumnSize(dx: number, key: string) {
    const sizes = {};
    sizes[key] = dx;
    const tablesSettings = getTableSettings();
    if (typeof this.localStorageKey !== 'undefined') {
      const currentSettings = tablesSettings[this.localStorageKey];
      tablesSettings[this.localStorageKey] = { ...currentSettings, ...sizes };
      window.localStorage.setItem(tableSettingsStorageKey, JSON.stringify(tablesSettings));
    }
  }
}
