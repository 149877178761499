import { gql } from 'apollo-angular';

import * as fragments from './graphql-fragments-shared';

/**
 * GraphQL carrier queries
 */
export const SHARED_MUTATION = {
  singleUpload: gql`
    mutation AttachContract($file: Upload!, $orderId: ID!) {
      attachContract(file: $file, orderId: $orderId) {
        id
        status
      }
    }
  `,

  singleUploadNew: gql`
    mutation uploadFile($input: UploadFileInput!) {
      uploadFile(input: $input) {
        ${fragments.UPLOADED_FILE_FRAGMENT}
      }
    }
  `,

  editOrderUploadedDocuments: gql`
    mutation editOrderUploadedDocuments($input:  EditOrderUploadedDocumentsInput!) {
      editOrderUploadedDocuments(input: $input) {
        ${fragments.ORDER_UPLOADED_DOCUMENT_FRAGMENT}
      }
    }
  `,

  sendDocumentToEdms: gql`
    mutation sendDocumentToEdms($docId: ID!) {
        sendDocumentToEdms(docId: $docId, edmsType: DIADOC) {
          result
          edmsMessageId
        }
     }
  `,

  multipleUpload: gql`
    mutation multipleUpload($text: String, $files: [Upload!]!) {
      multipleUpload(text: $text, files: $files) {
        id
        filename
        status
      }
    }
  `,

  /**
   * notification mutation: mark as read
   */
  markNotificationAsRead: gql`
    mutation markNotificationAsRead($notificationId: ID!) {
      markNotificationAsRead(notificationId: $notificationId)
    }
  `,

  /**
   *  notifications mutation: mark all as read
   */
  markAllNotificationsAsRead: gql`
    mutation {
      markAllNotificationsAsRead
    }
  `,

  editEmployee: gql`
    mutation editEmployee($input: EditEmployeeInput) {
      editEmployee(input: $input) {
        id
      }
    }
  `,

  addEmployee: gql`
    mutation addEmployee($input: AddEmployeeInput) {
      addEmployee(input: $input) {
        id
        isActive
        email
        firstName
        lastName
        phoneNumber
        companyName
        permissions {
          id
          codename
          description
        }
      }
    }
  `,

  resendInvitation: gql`
    mutation resendInvitation($employeeId: ID!) {
      dispatchInvitationToEmployee(employeeId: $employeeId) {
        id
        lastInvitationDatetime
      }
    }
  `,

  sendInvitationToEmployee: gql`
    mutation sendInvitationToEmployee($employeeId: ID!) {
      sendInvitationToEmployee(employeeId: $employeeId) {
        id
      }
    }
  `,

  /**
   * notification mutation: mark as read
   */
  removeEmployee: gql`
    mutation removeUser($employeeId: ID!) {
      sendEmployeeToArchive(employeeId: $employeeId) {
        id
      }
    }
  `,

  resetProfilePassword: gql`
    mutation resetEmployeePassword($employeeId: ID!) {
      resetEmployeePassword(employeeId: $employeeId) {
        id
        email
      }
    }
  `,

  editSelfProfile: gql`
    mutation editProfileSettings($input: EditProfileSettingsInput!) {
      editProfileSettings(input: $input) {
        uiSettings
      }
    }
  `,

  editUserName: gql`
    mutation editUserName($profileInput: EditProfileInput) {
      editProfile(profileInput: $profileInput) {
        id
      }
    }
  `,

  editOwnerSelfProfile: gql`
    mutation editProfileSettings($input: EditProfileSettingsInput!) {
      editProfileSettings(input: $input) {
        userSettings {
          notificationSettings {
            auctionAndBiddingOrders {
              web
              email
            }
            freeOrder {
              web
              email
            }
            assignedOrder {
              web
              email
            }
            completeOrder {
              web
              email
            }
            cancelOrder {
              web
              email
            }
            documentProcessing {
              web
              email
            }
          }
        }
      }
    }
  `,

  editUserPhone: gql`
    mutation editUserPhone($profileInput: EditProfileInput) {
      editProfile(profileInput: $profileInput) {
        id
      }
    }
  `,

  editUserCountry: gql`
    mutation editUserCountry($profileInput: EditProfileInput) {
      editProfile(profileInput: $profileInput) {
        id
      }
    }
  `,

  editCarrierSelfProfile: gql`
    mutation editProfileSettings($input: EditProfileSettingsInput!) {
      editProfileSettings(input: $input) {
        userSettings {
          notificationSettings {
            auctionAndBiddingOrders {
              web
              email
            }
            freeOrder {
              web
              email
            }
            assignedOrder {
              web
              email
            }
            completeOrder {
              web
              email
            }
            tenderOrder {
              web
              email
            }
            cancelOrder {
              web
              email
            }
            documentProcessing {
              web
              email
            }
          }
          isNewDesign
        }
      }
    }
  `,
};
