export const appColors = {
  bgDefault: '#f8f9fc',
  bgWarning: '#fff3e0',
  bgError: '#fffbee',
  infoError: '#d91921',
  infoWaiting: '#ffb74d',
  textSecondary: '#777c8f',
  textPrimary: '#1d1e22',
  textButton: '#37474f',
  textButtonSecondary: '#90a4ae',
  actionDefault: '#f4f6fa',
  orange700: '#f57c00',
  gray400: '#bdbdbd',
  gray500: '#9e9e9e',
  black: '#000000',
  black300: '#d9dde2',
  redA700: '#d50000',
  contrast: '#ffffff',
  gray700: '#2D3748',
  green500: '#38A169',
};
