<h2>{{ 'shared.mp.verification.form.sending.contactsTitle' | translate }}</h2>
<ng-container *ngFor="let key of contactsFormKeys;">
  <p *ngIf="!isSelfEmployed(legalForm | async) || !key.toLowerCase().includes('post')">
    <label>{{getContactsTranslate(key)}}</label>
    {{key.toLowerCase().includes('phone')? getMaskedNumber(contactsForm.value[key]) : contactsForm.value[key]}}
    <span *ngIf="!contactsForm.value[key]" class="text-gray-500">{{ 'shared.mp.verification.form.sending.notSpecified' | translate }}</span>
    &nbsp;
    <span
      *ngIf="!contactsForm.value[key] && key !== 'post' && key !== 'addPhone'"
      class="text-red-500"
      >{{ 'shared.mp.verification.form.sending.required' | translate }}</span
    >
    <span
      *ngIf='contactsForm.controls[key]?.errors?.pattern && key === "phone"'
      class="text-red-500"
      >{{'shared.mp.verification.validationErrors.wrongFormat' | translate}}</span
    >
  </p>
</ng-container>

<p *ngIf="!isSelfEmployed(legalForm | async)">
  <label>{{ 'shared.mp.verification.form.contacts.attorney' | translate }}</label>
  <a *ngIf="getFileName(contactsForm.controls.attorney)" class="cursor-pointer" (click)="downloadFile(contactsForm.controls.attorney)"
    >{{getFileName(contactsForm.controls.attorney)}}
  </a>
  <span *ngIf="!getFileName(contactsForm.controls.attorney)" class="text-gray-500">
    {{ 'shared.mp.verification.form.sending.notSpecified' | translate }}
  </span>
</p>

<div class="sending-form__company-name font-medium text-lg text-gray-700 mt-6">
  <span *ngIf="isSelfEmployed(legalForm | async)">{{ 'shared.mp.verification.form.organization.selfEmployedShort' | translate }}</span>
  {{currentUserService.profile.organization?.name}}
</div>
<div class="font-normal text-sm text-gray-500 mb-3">{{currentUserService.profile.organization?.legalAddress}}</div>
<div>
  <p>
    <ng-container *ngIf="!isIndividualInterpreneur(legalForm | async) && !isSelfEmployed(legalForm | async); else inn">
      <label>{{ 'shared.mp.verification.form.organization.innKpp' | translate }}</label>
      {{currentUserService.profile.organization?.inn || 'shared.mp.verification.form.sending.notSpecified' | translate}} /
      {{currentUserService.profile.organization?.kpp || 'shared.mp.verification.form.sending.notSpecified' | translate}}
    </ng-container>
    <ng-template #inn>
      <label>{{ 'shared.mp.verification.form.organization.inn' | translate }}</label>
      {{currentUserService.profile.organization?.inn || 'shared.mp.verification.form.sending.notSpecified' | translate}}
    </ng-template>
  </p>
  <p *ngIf="!isSelfEmployed(legalForm | async); else selfEmployedReferrence">
    <label>{{ 'shared.mp.verification.form.organization.ogrn' | translate }}</label>
    {{currentUserService.profile.organization?.registrationNumber || 'shared.mp.verification.form.sending.notSpecified' | translate}}
    {{ 'shared.mp.verification.form.from' | translate }}
    {{ogrnDate ? ogrnDate : 'shared.mp.verification.form.sending.notSpecified' | translate}}
  </p>
  <ng-template #selfEmployedReferrence>
    <p *ngIf="isSelfEmployed(legalForm | async)">
      <label>{{ 'shared.mp.verification.form.organization.selfEmployedReferrence' | translate }}</label>
      <a
        *ngIf="getFileName(organizationForm.controls.selfEmployedReferrence)"
        class="cursor-pointer"
        (click)="downloadFile(organizationForm.controls.selfEmployedReferrence)"
        >{{getFileName(organizationForm.controls.selfEmployedReferrence)}}
      </a>
      <span *ngIf="!getFileName(organizationForm.controls.selfEmployedReferrence)" class="text-gray-500">
        {{ 'shared.mp.verification.form.sending.notSpecified' | translate }}
      </span>
      &nbsp;
      <span *ngIf="!getFileName(organizationForm.controls.selfEmployedReferrence)" class="text-red-500">
        {{ 'shared.mp.verification.form.sending.required' | translate }}
      </span>
    </p></ng-template
  >
  <ng-container *ngIf="!isIndividualInterpreneur(legalForm | async) && !isSelfEmployed(legalForm | async); else passportPhoto">
    <p>
      <label>{{ 'shared.mp.verification.form.organization.regulation' | translate }}</label>
      <a
        *ngIf="getFileName(organizationForm.controls.regulation)"
        class="cursor-pointer"
        (click)="downloadFile(organizationForm.controls.regulation)"
        >{{getFileName(organizationForm.controls.regulation)}}
      </a>
      <span *ngIf="!getFileName(organizationForm.controls.regulation)" class="text-gray-500">
        {{ 'shared.mp.verification.form.sending.notSpecified' | translate }}
      </span>
      &nbsp;
      <span *ngIf="!getFileName(organizationForm.controls.regulation)" class="text-red-500">
        {{ 'shared.mp.verification.form.sending.required' | translate }}
      </span>
    </p>
  </ng-container>
  <ng-template #passportPhoto>
    <p>
      <label>{{ 'shared.mp.verification.form.organization.passportPhoto' | translate }}</label>
      <a
        *ngIf="getFileName(organizationForm.controls.passportPhoto)"
        class="cursor-pointer"
        (click)="downloadFile(organizationForm.controls.passportPhoto)"
        >{{getFileName(organizationForm.controls.passportPhoto)}}
      </a>
      <span *ngIf="!getFileName(organizationForm.controls.passportPhoto)" class="text-gray-500">
        {{ 'shared.mp.verification.form.sending.notSpecified' | translate }}
      </span>
      &nbsp;
      <span *ngIf="!getFileName(organizationForm.controls.passportPhoto)" class="text-red-500">
        {{ 'shared.mp.verification.form.sending.required' | translate }}
      </span>
    </p>
    <p>
      <label>{{ 'shared.mp.verification.form.organization.passportRegistrationPhoto' | translate }}</label>
      <a
        *ngIf="getFileName(organizationForm.controls.passportRegistrationPhoto)"
        class="cursor-pointer"
        (click)="downloadFile(organizationForm.controls.passportRegistrationPhoto)"
        >{{getFileName(organizationForm.controls.passportRegistrationPhoto)}}
      </a>
      <span *ngIf="!getFileName(organizationForm.controls.passportRegistrationPhoto)" class="text-gray-500">
        {{ 'shared.mp.verification.form.sending.notSpecified' | translate }}
      </span>
      &nbsp;
      <span *ngIf="!getFileName(organizationForm.controls.passportRegistrationPhoto)" class="text-red-500">
        {{ 'shared.mp.verification.form.sending.required' | translate }}
      </span>
    </p>
  </ng-template>

  <p *ngIf="!isSelfEmployed(legalForm | async)">
    <label>{{ 'shared.mp.verification.form.organization.registrationCertificate' | translate }}</label>
    <a
      *ngIf="getFileName(organizationForm.controls.registrationCertificate)"
      class="cursor-pointer"
      (click)="downloadFile(organizationForm.controls.registrationCertificate)"
      >{{getFileName(organizationForm.controls.registrationCertificate)}}
    </a>
    <span *ngIf="!getFileName(organizationForm.controls.registrationCertificate)" class="text-gray-500">
      {{ 'shared.mp.verification.form.sending.notSpecified' | translate }}
    </span>
    &nbsp;
    <span *ngIf="!getFileName(organizationForm.controls.registrationCertificate)" class="text-red-500">
      {{ 'shared.mp.verification.form.sending.required' | translate }}
    </span>
  </p>

  <ng-container *ngIf="!isIndividualInterpreneur(legalForm | async) && !isSelfEmployed(legalForm | async)">
    <h3>{{ 'shared.mp.verification.form.sending.director' | translate }}</h3>
    <p>
      <label>{{ 'shared.mp.verification.form.organization.directorFullName' | translate }}</label>
      <span
        [className]='!organizationForm.value.directorFullName ? "text-gray-500" : ""'
        >{{organizationForm.value.directorFullName || 'shared.mp.verification.form.sending.notSpecified' | translate}}</span
      >
    </p>
    <!--  <p>-->
    <!--    <label>{{ 'shared.mp.verification.form.organization.directorInn' | translate }}</label>-->
    <!--    <span-->
    <!--      [className]='!organizationForm.value.directorInn ? "text-gray-500" : ""'-->
    <!--      >{{organizationForm.value.directorInn || 'shared.mp.verification.form.sending.notSpecified' | translate}}</span-->
    <!--    >-->
    <!--  </p>-->
    <p>
      <label>{{ 'shared.mp.verification.form.organization.appointmentOrder' | translate }}</label>
      <a
        *ngIf="getFileName(organizationForm.controls.appointmentOrder)"
        class="cursor-pointer"
        (click)="downloadFile(organizationForm.controls.appointmentOrder)"
        >{{getFileName(organizationForm.controls.appointmentOrder)}}
      </a>
      <span *ngIf="!getFileName(organizationForm.controls.appointmentOrder)" class="text-gray-500">
        {{ 'shared.mp.verification.form.sending.notSpecified' | translate }}
      </span>
      &nbsp;
      <span *ngIf="!getFileName(organizationForm.controls.appointmentOrder)" class="text-red-500">
        {{ 'shared.mp.verification.form.sending.required' | translate }}
      </span>
    </p>
    <p>
      <label>{{ 'shared.mp.verification.form.organization.passportPhoto' | translate }}</label>
      <a
        *ngIf="getFileName(organizationForm.controls.passportPhoto)"
        class="cursor-pointer"
        (click)="downloadFile(organizationForm.controls.passportPhoto)"
        >{{getFileName(organizationForm.controls.passportPhoto)}}
      </a>
      <span *ngIf="!getFileName(organizationForm.controls.passportPhoto)" class="text-gray-500">
        {{ 'shared.mp.verification.form.sending.notSpecified' | translate }}
      </span>
      &nbsp;
      <span *ngIf="!getFileName(organizationForm.controls.passportPhoto)" class="text-red-500">
        {{ 'shared.mp.verification.form.sending.required' | translate }}
      </span>
    </p>
    <p>
      <label>{{ 'shared.mp.verification.form.organization.passportRegistrationPhoto' | translate }}</label>
      <a
        *ngIf="getFileName(organizationForm.controls.passportRegistrationPhoto)"
        class="cursor-pointer"
        (click)="downloadFile(organizationForm.controls.passportRegistrationPhoto)"
        >{{getFileName(organizationForm.controls.passportRegistrationPhoto)}}
      </a>
      <span *ngIf="!getFileName(organizationForm.controls.passportRegistrationPhoto)" class="text-gray-500">
        {{ 'shared.mp.verification.form.sending.notSpecified' | translate }}
      </span>
      &nbsp;
      <span *ngIf="!getFileName(organizationForm.controls.passportRegistrationPhoto)" class="text-red-500">
        {{ 'shared.mp.verification.form.sending.required' | translate }}
      </span>
    </p>
  </ng-container>
</div>

<ng-container *ngIf="vehicleForm.value.ownership === 'YES'">
  <div>
    <h2>{{ 'shared.mp.verification.form.sending.vehicleTitle' | translate }}</h2>
    <div *ngFor="let vehicle of vehicles;">
      <p *ngIf="vehicle.controls.photoFirst.value || vehicle.controls.regNumber.value || vehicle.controls.photoSecond.value">
        <label *ngIf="vehicle.controls.regNumber.value" class="!text-gray-700">
          {{setRegistrationNumberPattern(maskService, vehicle.controls.regNumber.value)}}
        </label>
        <label *ngIf="!vehicle.controls.regNumber.value" class="!text-red-500">
          {{ 'shared.mp.verification.form.sending.notSpecified' | translate }}
        </label>
        <ng-container *ngIf="getFileName(vehicle.controls.photoFirst) && getFileName(vehicle.controls.photoSecond); else notSpecified">
          <a class="cursor-pointer mr-3" (click)="downloadFile(vehicle.controls.photoFirst)"
            >{{getFileName(vehicle.controls.photoFirst)}}
          </a>
          <a class="cursor-pointer" (click)="downloadFile(vehicle.controls.photoSecond)">{{getFileName(vehicle.controls.photoSecond)}} </a>
        </ng-container>
        <ng-template #notSpecified>
          <span class="text-gray-500">
            {{ 'shared.mp.verification.form.sending.notSpecified' | translate }}
          </span>
          &nbsp;
          <span class="text-red-500">
            {{ 'shared.mp.verification.form.sending.required' | translate }}
          </span>
        </ng-template>
      </p>
    </div>
  </div>
</ng-container>
