import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { TnConfirmationService } from '../../../../confirmation/confirmation.service';
import { TnToasterFacade, TOAST_TYPE } from '../../../../toaster';
import { TnDriverBaseEffects } from '../../driver-page/effects/driver-base.effects';
import { TnCarrierDirectoryDriversApiService } from '../../drivers-table/carrier-directory-drivers-api.service';
import { removeDriver, removeDriverFailure, removeDriverSuccess } from '../actions/driver-remove.actions';

@Injectable({
  providedIn: 'root',
})
export class TnDriversEffects extends TnDriverBaseEffects {
  public operationFail = createEffect(() =>
    this.actions$.pipe(
      ofType(removeDriverFailure),
      tap(({ error }) => {
        //retrow error for handling by global error handler
        throw error;
      }),
    ),
  );

  public readonly listRemoveDriver = createEffect(() =>
    this.actions$.pipe(
      ofType(removeDriver),
      switchMap(({ id, name }) =>
        super.removeDriver(id).pipe(
          map(() => {
            this.toastFacade.showMessage({ code: 'shared.driver.toasts.remove.success', params: { name } }, TOAST_TYPE.SUCCESS);
            return removeDriverSuccess();
          }),
          catchError((error: Error) => {
            this.toastFacade.showMessage({ code: 'shared.driver.toasts.remove.error', params: { name } }, TOAST_TYPE.ERROR);
            return of(removeDriverFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    public readonly actions$: Actions,
    public readonly confirmation: TnConfirmationService,
    public readonly driverDirectoryService: TnCarrierDirectoryDriversApiService,
    private readonly toastFacade: TnToasterFacade,
  ) {
    super(actions$, confirmation, driverDirectoryService);
  }
}
