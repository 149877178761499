import { MOUSE_EVENT_BUTTON } from '@transport/ui-interfaces';
import { IRowExtender } from '../../../plain-table/table-row-extender/row-extender.interface';
import {getStandardNavigateFunction} from "./helpers/standart-navigate-function";
import {Router} from "@angular/router";


interface ITreatLikeALinkMouseExtenderConfig<T> {
  router: Router;
  linkBuilder: (rowData: T) => string;
}

/**
 * Redirects user implementing opening new tabs. Supports mouse click only. (No keyboard)
 * The extender subscribes on `mouseup` event of row element.
 * @param config - Config object
 * @param {Function} config.navigateFunction - function fires when event appears.
 */

export function redirectByLinkMouseExtender<T>(config: ITreatLikeALinkMouseExtenderConfig<T>) {
  const { router, linkBuilder } = config;
  const navigateFunction = getStandardNavigateFunction(router, linkBuilder);

  const extender: IRowExtender<T> = (elRef, rowData) => {
    const listener = event => {
      const mouseEvent = event as MouseEvent;
      if (
        mouseEvent.button === MOUSE_EVENT_BUTTON.MIDDLE_BUTTON ||
        (mouseEvent.button === MOUSE_EVENT_BUTTON.LEFT_BUTTON && mouseEvent.ctrlKey)
      ) {
        navigateFunction(event, rowData, true);
        return;
      }

      if (mouseEvent.button === MOUSE_EVENT_BUTTON.LEFT_BUTTON) {
        navigateFunction(event, rowData, false);
      }
    };

    const element = elRef.nativeElement;
    element.addEventListener('mouseup', listener);
    return () => {
      element.removeEventListener('mouseup', listener);
    };
  };

  return extender;
}
