import { Directive, EmbeddedViewRef, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[transportRecreateViewWhenKeyChanged]'
})
export class TnRecreateViewDirective implements OnChanges {
  @Input('transportRecreateViewWhenKeyChanged') public key: unknown;

  private viewRef: EmbeddedViewRef<unknown> | null = null;

  constructor(private templateRef: TemplateRef<unknown>, private viewContainer: ViewContainerRef) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (Boolean(changes.key)) {
      if (this.viewRef) {
        this.destroyView();
      }

      this.createView();
    }
  }

  private createView() {
    this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef);
  }

  private destroyView() {
    this.viewRef?.destroy();
    this.viewRef = null;
  }
}
