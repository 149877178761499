import { transport } from '@transport/proto';
import moment from 'moment';

import { IEntity } from '../common/entity-changes-list';
import { DOCUMENT_TYPE, ORDER_STATUS } from '../order';
import { IOrder } from '../order/order.interface';
import { USER_ROLE } from '../user/user-common.interface';
import { TnOrderAttachmentSign } from './order-attachment-sign';
import { IOrderDocumentsListItem, TnOrderAttachmentGroup } from './order-documents-list-item';
import { TnUploadedFile } from './uploaded-file';

export interface IOrderAttachment extends IEntity {
  uploadedFile: TnUploadedFile;
  documentType: transport.IOrderDocumentType;
  waybillInfo: IWayBillDocAttributes | null;
  deletable: boolean;
  signedFileId?: string;
  signedFile?: transport.IOrderUploadedDocument;
  organization?: transport.OrderUploadedDocument.IOrganization;
  editable?: boolean;
  contractInfo?: transport.IContractInfo | null;
}
export type TOrderAttachmentForSave = Pick<
  IOrderAttachment,
  'documentType' | 'uploadedFile' | 'signedFile' | 'signedFileId' | 'id' | 'waybillInfo' | 'contractInfo'
>;

/**
 * Атрибуты документа Транспортная Накладная
 */
export interface IWayBillDocAttributes {
  storagePoint: IWaybilStoragePoint | null;
  dateFrom: moment.Moment | null;
  timeFrom: string;
  dateTo: moment.Moment | null;
  timeTo: string;
}

export interface IWaybilStoragePoint {
  id: string | null;
  name: string | null;
  arriveDatetimeFrom: string | null;
  arriveDatetimeTo: string | null;
}

export function waybillInfoFromDto(dto?: transport.IWaybillInfoType | null): IWayBillDocAttributes | null {
  if (!Boolean(dto)) {
    return null;
  }
  const point = dto?.orderStoragePoint;
  return {
    storagePoint: point
      ? {
          id: point?.storagePoint?.id ?? null,
          name: point?.storagePoint?.name ?? null,
          arriveDatetimeFrom: point?.arriveDatetimeFrom ?? null,
          arriveDatetimeTo: point?.arriveDatetimeFrom ?? null,
        }
      : null,
    dateFrom: (Boolean(dto?.dateFrom) && moment(dto?.dateFrom)) || null,
    timeFrom: moment(dto?.dateFrom).format('HH:mm'),
    dateTo: (Boolean(dto?.dateTo) && moment(dto?.dateTo)) || null,
    timeTo: moment(dto?.dateTo).format('HH:mm'),
  };
}

export const orderAttachmentFromDto = (dto: transport.IOrderUploadedDocument): IOrderAttachment => {
  const res: IOrderAttachment = {
    id: dto.id ?? '',
    uploadedFile: new TnUploadedFile(dto.uploadedFile?.name ?? '', dto.uploadedFile?.url ?? '', dto.uploadedFile?.id ?? ''),
    documentType: {
      id: dto.documentType?.id ?? '',
      name: dto.documentType?.name ?? '',
      innerName: dto.documentType?.innerName,
      isVisible: dto.documentType?.isVisible,
    },
    signedFile: dto.signedFile ?? void 0,
    organization: dto.organization ?? void 0,
    deletable: dto.deletable ?? false,
    waybillInfo: waybillInfoFromDto(dto.waybillInfo),
    contractInfo: dto.contractInfo,
  };

  return res;
};

export const groupOrderAttachments = (documents: IOrderDocumentsListItem[]): TnOrderAttachmentGroup[] => {
  if (!Boolean(documents)) {
    return [];
  }
  const res = documents.reduce((acc: TnOrderAttachmentGroup[], current) => {
    let group = acc.find(item => item.documentType?.id === current.documentType?.id);
    if (typeof group === 'undefined') {
      group = { documentType: current.documentType, attachments: [] };
      acc.push(group);
    }
    group?.attachments.push(current);
    return acc;
  }, []);

  return res;
};

const isSignedByUser = (dtoId: string, signDocs: TnOrderAttachmentSign[], currentUserRole: USER_ROLE) => {
  const signedByUser = signDocs.filter(item => item.signedFileId === dtoId && item.signedUserRole === currentUserRole).length > 0;

  return signedByUser;
};

export const connectDocumentsWithSign = (
  order: IOrder | null,
  signFiles: TnOrderAttachmentSign[],
  docs: IOrderAttachment[],
  currentUserRole: USER_ROLE,
) => {
  const result: IOrderDocumentsListItem[] = docs.map(dto => {
    const signedByUser = isSignedByUser(dto.id ?? '', signFiles, currentUserRole);
    const idsGroupSignRequired = order?.settingsESignRequired?.map(item => item.id);
    const isSignRequired = (idsGroupSignRequired?.includes(dto.documentType?.id ?? '') ?? false) && !signedByUser;

    const res: IOrderDocumentsListItem = {
      id: dto.id,
      uploadedFile: dto.uploadedFile,
      signFiles: signFiles.filter(item => item.signedFileId === dto.id),
      documentType: dto.documentType,
      signRequired: isSignRequired,
      deletable: dto.deletable && (dto.documentType.innerName !== DOCUMENT_TYPE.WAYBILL || order?.status !== ORDER_STATUS.COMPLETED),
      waybillInfo: dto.waybillInfo,
      editable: dto.documentType.innerName === DOCUMENT_TYPE.WAYBILL && order?.status !== ORDER_STATUS.COMPLETED,
    };
    return res;
  });
  return result;
};
