import { Injectable } from '@angular/core';
import { IDriver, IDriverForm } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { prepareInputDriverData } from '../../../driver/driver.shared';
import { TnGqlClientCarrierService } from '../../../gql-client-carrier/graphql-client-carrier.service';

@Injectable({
  providedIn: 'root',
})
export class TnCarrierDirectoryDriversApiService {
  private lastDriver: IDriver | null = null;

  constructor(private readonly graphQL: TnGqlClientCarrierService) {}

  /**
   * Request for list of items
   */
  public getDrivers(listenX?: number, withSpinner?: boolean, options?: Record<string, unknown>) {
    return this.graphQL.queryDrivers(listenX, withSpinner, options).pipe(
      map(data => {
        data.drivers.map(el => {
          return (el.name = `${el.name} ${el.patronymic}`); //change driver name in table
        });
        return {
          list: data.drivers,
          totalCount: data.totalCount ?? 0,
        };
      }),
    );
  }

  /**
   * Get driver by id
   * @param id Driver id
   * @returns Observable with cargo type
   */
  public getDriver(id: string): Observable<IDriver> {
    const CLEAR_CACHE_TIME = 5000;
    return new Observable(obs => {
      if (this.lastDriver) {
        obs.next(this.lastDriver);
        obs.complete();
        setTimeout(() => {
          this.lastDriver = null;
        }, CLEAR_CACHE_TIME);
      } else {
        this.graphQL
          .queryDriver(id)
          .pipe(map(data => data.driver))
          .subscribe(
            res => {
              this.lastDriver = res;
              obs.next(res);
            },
            er => {
              obs.error(er);
            },
            () => {
              obs.complete();
            },
          );
      }
    });
  }

  /**
   * Remove driver request
   * @param id Id cargo type
   * @returns Observable with status
   */
  public removeDriver(id: string) {
    return this.graphQL.archiveDriver(id);
  }

  /**
   * Create new driver request
   * @param driverParam Driver data
   * @returns Observable with status
   */
  public addDriver(driverParam: IDriverForm) {
    return this.graphQL
      .addDriver({
        input: prepareInputDriverData(driverParam),
      })
      .pipe(map(result => result.addDriver));
  }

  /**
   * Edit driver request
   * @param driverParam Driver data
   * @returns Observable with status
   */
  public editDriver(driverParam: IDriverForm) {
    return this.graphQL
      .editDriver({
        input: prepareInputDriverData(driverParam),
      })
      .pipe(map(result => result.editDriver));
  }
}
