import { transport } from '@transport/proto';

type TModify<T, R> = Pick<T, Exclude<keyof T, keyof R>> & R;

export const createNewGroup = (input: Partial<transport.Carrier.ICarrierGroup> = {}) => {
  const defaultValue = transport.Carrier.CarrierGroup.toObject(new transport.Carrier.CarrierGroup(input), {
    defaults: true,
    longs: Number,
  }) as transport.Carrier.ICarrierGroup;

  return { ...defaultValue };
};

export type TCarrierGroupForm = TModify<
  Pick<Partial<transport.Carrier.ICarrierGroup>, 'name' | 'carriers'>,
  { carriers: transport.Carrier.IOrganization[] }
>;
