import { BehaviorSubject, catchError, map, of, Subject, switchMap, take, takeUntil } from 'rxjs';
import { IAgentCarrier, IGCarrier, USER_ROLE } from '@transport/ui-interfaces';
import { TnGqlClientSharedService } from '@transport/ui-store';
import { FormGroup } from '@angular/forms';
import { removeEmptyProperties } from '@transport/ui-utils';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { DRIVER_QUERIES } from '../driver-queries';
import { IMarketplaceDriver, IMarketplaceDriverInput } from '../driver.interface';
import { readMarketplaceDriver, driverToServer } from '../driver.mapper';
import { DriverFormVm } from '../driver.service';
import { getDriverForm } from './driver-form';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';
import { ALERT_STATE } from '@transport/ui-kit';
import { setAgentCarrierData } from '../../edit-vehicle/vehicle.mapper';
import { ICommonSelectData } from 'libs/custom-controls/src/lib/common.interface';

export class DriverEditVm implements DriverFormVm {
  public title = this.translate.instant('carrier.directory.marketplaceDriver.title.edit');
  public isSubdomainPage = Boolean(this.domainService.subDomain);
  public actionBtnTxt = this.getActionBtnTxt();
  public driver$ = new BehaviorSubject<IMarketplaceDriverInput>({} as IMarketplaceDriverInput);
  public loading$ = new BehaviorSubject<boolean>(false);
  public finish$ = new Subject<{ close: boolean; message: string; alertState: ALERT_STATE }>();
  public disposed$ = new Subject<void>();
  public organizations$ = new BehaviorSubject<ICommonSelectData[] | null>(null);
  public hasAgentCarrier$ = new BehaviorSubject<boolean>(false);

  public form: FormGroup = getDriverForm();

  constructor(
    public driverId: string,
    private readonly sharedGraphQlClient: TnGqlClientSharedService,
    public readonly translate: TranslateService,
    private readonly datePipe: DatePipe,
    private readonly domainService: TnDomainService,
    private role: USER_ROLE
  ) {
    this.sharedGraphQlClient
      .query<{ driver: IMarketplaceDriver }>(this.role, DRIVER_QUERIES.getDriver, 1, { id: this.driverId })
      .pipe(
        map(response => response.driver),
        takeUntil(this.disposed$),
        catchError(() => of(undefined)),
      )
      .subscribe(res => {
        if (!res) {
          this.finish$.next({
            close: false,
            message: this.translate.instant('carrier.directory.marketplaceDriver.messages.fetchError'),
            alertState: ALERT_STATE.ERROR,
          });
          return;
        }
        this.driver$.next(readMarketplaceDriver(res));
      });
  }

  action() {
    const fields = this.form.getRawValue();

    this.form.markAllAsTouched();
    if (this.form.invalid) return;

    this.loading$.next(true);
    if (this.isSubdomainPage || this.hasAgentCarrier$.value) {
      this.sharedGraphQlClient
        .mutate(this.role, DRIVER_QUERIES.editDriver, {
          input: { ...driverToServer(fields, this.datePipe), id: this.driverId },
        })
        .pipe(
          takeUntil(this.disposed$),
          catchError(() => of(undefined)),
        )
        .subscribe(res => {
          this.loading$.next(false);
          if (!res) {
            this.finish$.next({
              close: false,
              message: this.translate.instant('carrier.directory.marketplaceDriver.messages.editError'),
              alertState: ALERT_STATE.ERROR,
            });
            return;
          }
          this.finish$.next({
            close: true,
            message: this.translate.instant('carrier.directory.marketplaceDriver.messages.editSuccess'),
            alertState: ALERT_STATE.SUCCESS,
          });
        });
    } else {
      this.createAndSubmitRiskProfile(fields);
    }
  }

  getAgentCarriers(searchQuery) {
    this.sharedGraphQlClient.query<{ items: IAgentCarrier[] }>(this.role, DRIVER_QUERIES.getOrganizations, 1, {
      availability: IGCarrier.IAvailabilityEnum.Active,
      first: 10,
      searchQuery,
    })
    .pipe(
      take(1),
      map(response => response?.items?.map(item => setAgentCarrierData(item) as ICommonSelectData)),
      catchError(() => of(null)),
    )
    .subscribe(res => {
      const { id, label } = this.driver$.value?.agentCarrierId ?? {};
      let list = res ?? [];
      if (id && label) {
        const isAgentInList = res?.find(item => item.id === this.driver$.value.agentCarrierId?.id)
        if (!isAgentInList) list = [{ id, label }, ...(res ?? [])];
      }
      this.organizations$.next(list);
    });
  }

  public getActionBtnTxt(): string {
    return this.isSubdomainPage || this.hasAgentCarrier$?.value ?
      this.translate.instant('carrier.directory.marketplaceDriver.buttons.save') :
      this.translate.instant('carrier.directory.marketplaceDriver.buttons.saveAndAccredit')
  }

  private createAndSubmitRiskProfile(fields): void {
    let driverProfileId;

    this.sharedGraphQlClient
      .mutate(this.role, DRIVER_QUERIES.editDriver, {
        input: { ...driverToServer(fields, this.datePipe), id: this.driverId },
      })
      .pipe(
        switchMap((value: any) => {
          return this.sharedGraphQlClient.mutate(this.role, DRIVER_QUERIES.createDriverRiskProfile, {driverId: this.driverId,})
        }),
        switchMap((value: any) => {
          driverProfileId = value?.createDriverRiskProfile?.profileId;
          return this.sharedGraphQlClient
            .mutate(this.role, DRIVER_QUERIES.addDriverRiskProfileDossier, {
              docType: 'text',
              docVal: JSON.stringify(fields?.passportPhoto),
              profileId: value?.createDriverRiskProfile?.profileId,
            })
        }),
        switchMap((value: any) => {
          return this.sharedGraphQlClient
            .mutate(this.role, DRIVER_QUERIES.submitDriverRiskProfile, {profileId: driverProfileId, driverId: this.driverId})
        }),
        takeUntil(this.disposed$),
        catchError(() => of(undefined)),
      )
      .subscribe(res => {
        this.loading$.next(false);
        if (!res) {
          this.finish$.next({
            close: false,
            message: this.translate.instant('carrier.directory.marketplaceDriver.messages.editError'),
            alertState: ALERT_STATE.ERROR,
          });
          return;
        }
        this.finish$.next({
          close: true,
          message: this.translate.instant('carrier.directory.marketplaceDriver.messages.editAndSubmitSuccess'),
          alertState: ALERT_STATE.SUCCESS,
        });
      });
  }

  dispose() {
    this.disposed$.next();
    this.disposed$.complete();
    this.loading$.complete();
    this.finish$.complete();
  }
}
