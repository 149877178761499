import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TnMarketplaceAuthService } from '@marketplace/auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { TOAST_DURATION } from '@transport/ui-interfaces';
import { TnGqlClientCarrierService, TnUserService } from '@transport/ui-store';
import { ALERT_STATE } from 'libs/transport-ui-kit/src/lib/alert/alert.interfaces';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';
import { BehaviorSubject, switchMap, tap } from 'rxjs';

import { TnAlertService } from '../../../../../transport-ui-kit/src/lib/alert/alert.service';

@UntilDestroy()
@Component({
  selector: 'transport-marketplace-registration-by-invite',
  templateUrl: './registration-by-invite.component.html',
  styleUrls: ['./registration-by-invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnMarketplaceRegistrationByInviteComponent implements OnInit {
  public inviteToken: string | undefined;

  private readonly loadingSubject = new BehaviorSubject(false);

  public loaded$ = this.loadingSubject.asObservable();

  constructor(
    private readonly route: ActivatedRoute,
    private readonly router: Router,
    public readonly tnUserService: TnUserService,
    private readonly alertService: TnAlertService,
    private readonly translate: TranslateService,
    private readonly authService: TnMarketplaceAuthService,
    private readonly carrierGqlService: TnGqlClientCarrierService,
    private readonly domainSerice: TnDomainService,
  ) {}

  public ngOnInit(): void {
    void this.route.paramMap
      .pipe(
        untilDestroyed(this),
        switchMap(params => params.getAll('token')),
      )
      .subscribe((data: string) => {
        this.inviteToken = data || undefined;
        // TODO fix get coockies
        const authToken = JSON.parse(this.tnUserService.getCookie('userService') ?? '{"token":""}')?.token;
        if (Boolean(data) && authToken) {
          this.acceptСargoOwnerInvitation(data);
        } else {
          this.loadingSubject.next(true);
        }
      });
  }

  private acceptСargoOwnerInvitation(inviteToken: string): void {
    this.authService
      .invitationUserStatusWithHeaders(inviteToken)
      .pipe(
        untilDestroyed(this),
        switchMap(() => this.carrierGqlService.queryOrganizationAccrstatusBySubdomain(this.domainSerice.subDomain as string)),
        tap(({ carrierBusinessRelationshipState }) => {
          if (carrierBusinessRelationshipState !== 'ON_ACCREDITATION') return;
          const alertInput = {
            title: this.translate.instant('shared.passport.passportCreateCompany.form.success.invitation'),
            state: ALERT_STATE.SUCCESS,
            message: this.translate.instant('shared.passport.confirmEmail.invitationOrdersAccess'),
          };
          this.alertService.openAlert(alertInput, TOAST_DURATION.LONG);
        }),
      )
      .subscribe(() => this.router.navigate(['/my-cargo']));
  }
}
