import { AbstractControl, FormBuilder, UntypedFormBuilder, UntypedFormGroup, ValidatorFn, Validators } from '@angular/forms';

export interface IPasswordFormData {
  password: string;
  passwordConfirm?: string;
  oldPassword?: string;
}

export interface IPasswordForm extends UntypedFormGroup {
  controls: {
    password: AbstractControl;
    passwordConfirm: AbstractControl;
    oldPassword: AbstractControl;
  };
}

export type TPasswordFormConstructor = (fb: FormBuilder) => IPasswordForm;

const differentPasswordsValidator: ValidatorFn = (control: AbstractControl) => {
  const passwordValue: string = (control.parent as IPasswordForm)?.controls?.password.value;
  const controlValue: string = control.value;

  if (passwordValue === controlValue) {
    return null;
  }

  return { notSamePassword: true };
};

export const newPasswordForm: TPasswordFormConstructor = (fb: UntypedFormBuilder): IPasswordForm => {
  const MIN_PASSWORD_LENGTH = 8;
  return fb.group({
    password: [
      '',
      {
        validators: Validators.compose([Validators.minLength(MIN_PASSWORD_LENGTH)]),
      },
    ],
    passwordConfirm: [
      '',
      {
        validators: [differentPasswordsValidator],
      },
    ],
  }) as IPasswordForm;
};

export const newOldPasswordForm: TPasswordFormConstructor = (fb: FormBuilder): IPasswordForm => {
  const MIN_PASSWORD_LENGTH = 8;
  return fb.group({
    password: [
      '',
      {
        validators: Validators.compose([Validators.minLength(MIN_PASSWORD_LENGTH)]),
      },
    ],
    passwordConfirm: [
      '',
      {
        validators: [differentPasswordsValidator],
      },
    ],
    oldPassword: [
      '',
      {
        validators: [],
      },
    ],
  }) as IPasswordForm;
};
