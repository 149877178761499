import { IAgentCarrier } from '@transport/ui-interfaces';
import {
  IMarketplaceBodySubtypesList,
  IMarketplaceDriver,
  IMarketplaceDriversList,
  IMarketplaceLoadingTypes,
  IMarketplaceVehicleData,
  IMarketplaceVehicleMake,
  IVehicleFormFields
} from './vehicle.interface';
import { ICommonSelectData } from 'libs/custom-controls/src/lib/common.interface';

export function readMarketplaceVehicle(countries: ICommonSelectData[] = [], vehicle?: IMarketplaceVehicleData): IVehicleFormFields {
  return {
    country: vehicle?.country ? countries.find(c => c.id === vehicle?.country) : countries.length ? countries[0] : null,
    accrStatus: vehicle?.accrStatus,
    regNumber: vehicle?.vehicleId,
    type: vehicle?.vehicleType,
    make: vehicle?.vehicleMake ? {id: vehicle?.vehicleMake, label: vehicle?.vehicleMake} : null,
    ownershipType: vehicle?.ownershipType,
    isThirdParty: vehicle?.isThirdParty,
    driver: setDriverData(vehicle?.drivers[0]),
    agentCarrierId: setAgentCarrierData(vehicle?.agentCarrier ?? null),
    trailerRegNumber: vehicle?.vehicletrailer?.regNo,
    trailerMake: vehicle?.vehicletrailer?.vehicleMake ? {id: vehicle?.vehicletrailer?.vehicleMake, label: vehicle?.vehicletrailer?.vehicleMake} : null,
    trailerBodyType: vehicle?.vehicletrailer?.bodySubtype?.id ?? vehicle?.vehiclebody?.bodySubtype?.id,
    loadingTypes: setLoadingTypes(vehicle?.vehicletrailer?.loadingTypes ?? vehicle?.vehiclebody?.loadingTypes),
    capacity: vehicle?.vehicletrailer?.capacity ?? vehicle?.vehiclebody?.capacity ,
    volume: vehicle?.vehicletrailer?.volume ?? vehicle?.vehiclebody?.volume,
    accrComment: vehicle?.accrComment ?? '',
    baseLocation: vehicle?.baseLocation,
    blackListVehicle: {
      limitationType: !!vehicle?.blackListInfo?.find(item => item.workProhibited),
      message: vehicle?.blackListInfo || null,
    },
    blackListTrailer: {
      limitationType: !!vehicle?.vehicletrailer?.blackListInfo?.find(item => item.workProhibited),
      message: vehicle?.vehicletrailer?.blackListInfo || null,
    },
  } as IVehicleFormFields;
}

export function readMarketplaceVehicleMake(): IMarketplaceVehicleMake {
  return {
    vehicleMakes: [],
  };
}

export function readMarketplaceBodySubtypes(): IMarketplaceBodySubtypesList {
  return {
    bodySubtypes: [],
  };
}

export function readMarketplaceDrivers(): IMarketplaceDriversList {
  return {
    items: [],
  };
}

export function setLoadingTypes(type?: IMarketplaceLoadingTypes[]) {
    return {
      side: type?.some(v => v.id === "1"),
      back: type?.some(v => v.id === "2"),
      up: type?.some(v => v.id === "3"),
    }
}

export function setDriverData(driver: IMarketplaceDriver | undefined) {
  if(driver) {
    return {
      fullName: `${driver.surname} ${driver.name} ${driver.patronymic}`,
      id: driver.id,
    };
  }
  return null;
}
export function setAgentCarrierData(org: IAgentCarrier | null) {
  if(org) {
    return {
      label: `${org.carrierName} (${org.inn || org.bin || org.iin})`,
      id: org.id ?? '',
    };
  }
  return null;
}
export function setVehicleMakeData(vehicleMake: string) {
  if(vehicleMake) {
    return {
      label: vehicleMake,
      id: vehicleMake,
    };
  }
  return null;
}



