import { ChangeDetectionStrategy, ChangeDetectorRef, Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormBuilder,
  FormGroup,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { FormControl } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { TnFormControlDirective } from '@transport/ui-directives';
import { TAdminOrganizationForm } from '@transport/ui-interfaces';
import { CurrencySigns, TnSupportedCurrency } from '@transport/ui-pipes';
import { ReplaySubject, startWith } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'transport-lot-step-settings',
  templateUrl: './auction-lot-step-settings.component.html',
  styleUrls: ['./auction-lot-step-settings.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      // eslint-disable-next-line @angular-eslint/no-forward-ref -- TODO: tech debt
      useExisting: forwardRef(() => TnAuctionLotStepSettingsComponent),
      multi: true,
    },
  ],
})
export class TnAuctionLotStepSettingsComponent extends TnFormControlDirective implements OnInit, ControlValueAccessor {
  @Input() payment?: AbstractControl;
  public options = [
    {
      value: 'FIXED',
      title: CurrencySigns[TnSupportedCurrency.RUB],
    },
    {
      value: 'PERCENT',
      title: this.translate.instant('shared.auctionSettings.auctionStep.options.percent.label'),
      subtitle: this.translate.instant('shared.auctionSettings.auctionStep.options.percent.description'),
    },
  ];

  private readonly typeOptionSubject = new ReplaySubject(1);

  public typeOption$ = this.typeOptionSubject.asObservable();

  public errorToMessage: (errors: ValidationErrors | null) => string = (errors) => {
    if (errors === null) return '';

    const rangeError = (error: { type: string; value: any }) => {
      switch (error.type) {
        case 'ShouldBeMoreThan':
          return this.translate.instant('shared.auctionSettings.auctionStep.errors.shouldBeMoreThan', { value: String(error.value) });
        case 'ShouldBeLessThan':
          return this.translate.instant('shared.auctionSettings.auctionStep.errors.shouldBeLessThan', { value: String(error.value) });
      }
      return null;
    }

    if ('minStepPrice' in errors) {
      return rangeError(errors.minStepPrice) ?? '';
    }
    if ('minPriceStepPercent' in errors) {
      return rangeError(errors.minPriceStepPercent) ?? '';
    }

    return '';
  };

  constructor(protected readonly fb: FormBuilder, private readonly translate: TranslateService, private cdr: ChangeDetectorRef) {
    super(fb);
  }

  private get value(): TAdminOrganizationForm['auctionLotStepParameters'] {
    return this.form.getRawValue();
  }

  public handleTouched() {
    this.onTouched();
  }

  public writeValue(obj): void {
    this.typeOptionSubject.next(obj.typeOfPriceStep ?? 'FIXED');
    super.writeValue(obj);
  }

  public ngOnInit(): void {
    this.payment?.valueChanges.pipe(untilDestroyed(this), startWith(this.payment.value)).subscribe(res => {
      this.options = [
        {
          value: 'FIXED',
          title: CurrencySigns[res.currency ?? TnSupportedCurrency.RUB],
        },
        {
          value: 'PERCENT',
          title: this.translate.instant('shared.auctionSettings.auctionStep.options.percent.label'),
          subtitle: this.translate.instant('shared.auctionSettings.auctionStep.options.percent.description'),
        },
      ];
      this.cdr.markForCheck();
    })
    void this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((params: TAdminOrganizationForm['auctionLotStepParameters']) => {
      this.onChange(this.value);
      this.form.markAllAsTouched();
    });

    void this.form.controls.typeOfPriceStep.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe(type => void this.typeOptionSubject.next(type));
  }

  protected createFormGroup(): IAuctionLotStepSettingsForm {
    const formGroup = this.fb.group({
      minStepPrice: this.fb.control(''),
      minPriceStepPercent: this.fb.control(''),
      typeOfPriceStep: this.fb.control('FIXED'),
    }) as IAuctionLotStepSettingsForm;
    formGroup.setValidators(TnAuctionLotStepSettingsComponent.getValidator());
    return formGroup;
  }

  public static getValidator(): ValidatorFn {
    return control => {
      const errors: ValidationErrors = {};
      const value = control.value as { minStepPrice?: unknown; minPriceStepPercent?: unknown; typeOfPriceStep?: unknown };

      function numberIsValid(num): boolean {
        const res = parseFloat(num);
        if (typeof num === 'string' && num !== String(res)) {
          return false;
        }
        return !isNaN(res);
      }

      const MIN_STEP_PRICE_LOWER_BOUND = 50;
      const MIN_STEP_PRICE_UPPER_BOUND = 9999;

      const MIN_STEP_PRICE_PERCENT_LOWER_BOUND = 0.1;
      const MIN_STEP_PRICE_PERCENT_UPPER_BOUND = 99.9;

      const { minStepPrice, minPriceStepPercent, typeOfPriceStep } = {
        minStepPrice: null,
        minPriceStepPercent: null,
        typeOfPriceStep: null,
        ...value,
      };

      if (!numberIsValid(minStepPrice)) {
        errors.minStepPrice = 'minStepPrice is not valid number';
      } else {
        const v = parseFloat(minStepPrice as string);
        if (MIN_STEP_PRICE_LOWER_BOUND > v) {
          errors.minStepPrice = { type: 'ShouldBeMoreThan', value: MIN_STEP_PRICE_LOWER_BOUND };
        }
        if (v > MIN_STEP_PRICE_UPPER_BOUND) {
          errors.minStepPrice = { type: 'ShouldBeLessThan', value: MIN_STEP_PRICE_UPPER_BOUND };
        }
      }

      if (!numberIsValid(minPriceStepPercent)) {
        errors.minPriceStepPercent = 'minPriceStepPercent is not valid number';
      } else {
        const v = parseFloat(minPriceStepPercent as string);
        if (MIN_STEP_PRICE_PERCENT_LOWER_BOUND > v) {
          errors.minPriceStepPercent = { type: 'ShouldBeMoreThan', value: MIN_STEP_PRICE_PERCENT_LOWER_BOUND };
        }
        if (v > MIN_STEP_PRICE_PERCENT_UPPER_BOUND) {
          errors.minPriceStepPercent = { type: 'ShouldBeLessThan', value: MIN_STEP_PRICE_PERCENT_UPPER_BOUND };
        }
      }

      if (typeOfPriceStep !== 'FIXED' && typeOfPriceStep !== 'PERCENT') {
        errors.typeOfPriceStep = 'typeOfPriceStep is not valid';
      }
      return Object.keys(errors).length > 0 ? errors : null;
    };
  }
}

interface IAuctionLotStepSettingsForm extends FormGroup {
  controls: Record<keyof TAdminOrganizationForm['auctionLotStepParameters'], AbstractControl>;
}
