<!-- [class.invisible]="tipText && !focused && !value"  вырезано https://tndl.kaiten.ru/space/50858/card/5564125 -->
<input
  #inputElement 
  type="text"
  matInput
  [disabled]="disabled"
  [formControl]="searchInputControl"
  [matAutocomplete]="auto"
  [placeholder]="placeholder"
  autocomplete="nope"
/>
<div class="spinner-box" *ngIf="isLoading">
  <mat-spinner [diameter]="20" strokeWidth="2"> </mat-spinner>
</div>
<mat-autocomplete #auto="matAutocomplete" (optionSelected)="optionSelected($event)" [displayWith]="bindedValue">
  <ng-container *ngIf="filteredItems$ | async">
    <mat-option *ngFor="let item of $any(selectData$ | async)" [value]="item">
      {{ bindedValue(item) }}
    </mat-option>
  </ng-container>
</mat-autocomplete>

<span class="picker-placeholder mat-form-field-label" *ngIf="tipText && !focused && !value">
  {{ tipText }}
</span>
