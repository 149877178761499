import { gql } from 'apollo-angular';

import * as fragments from './graphql-fragments-shared';
import {
  STORAGE_POINTS_QUERY_BODY_FRAGMENT,
  STORAGE_POINTS_QUERY_RESPONSE_FRAGMENT,
  STORAGE_POINTS_QUERY_TYPE_FRAGMENT,
} from './graphql-fragments-shared';

/**
 * GraphQL carrier queries
 */
export const SHARED_QUERY = {
  /**
   * GraphQL query: notifications
   */
  getNotifications: gql`
    query notifications($isRead: Boolean, $first: Int, $offset: Int) {
      notifications(isRead: $isRead, first: $first, offset: $offset) {
        id
        eventDatetime
        title
        text
        topic
        action
        payload
        isRead
      }
    }
  `,

  /**
   * GraphQL query: notifications count
   */
  getNotificationsCount: gql`
    query notificationsCount($isRead: Boolean, $first: Int, $offset: Int) {
      count: notificationsCount(isRead: $isRead, first: $first, offset: $offset)
    }
  `,

  /**
   * GraphQL query: notifications count
   */
  getNotificationsWithCount: gql`
    query notificationsWithCount($isRead: Boolean, $first: Int, $offset: Int) {
      notifications(isRead: $isRead, first: $first, offset: $offset) {
        id
        eventDatetime
        title
        text
        topic
        action
        payload
        isRead
      }

      count: notificationsCount(isRead: $isRead)
    }
  `,

  /**
   * GraphQL query: notifications with count
   */
  getNotificationsWithCounts: gql`
    query notificationsWithCounts($isRead: Boolean, $first: Int, $offset: Int) {
      notifications(isRead: $isRead, first: $first, offset: $offset) {
        id
        eventDatetime
        title
        text
        topic
        action
        payload
        isRead
      }

      count: notificationsCount(isRead: $isRead)
      unreadCount: notificationsCount(isRead: false)
    }
  `,

  /**
   * GraphQL query: users with count
   */
  getEmployees: gql`
    query employeesWithCounts($first: Int, $offset: Int, $organizationId: ID, $withCount: Boolean = true) {
      employees: organizationEmployees(first: $first, offset: $offset, organizationId: $organizationId) {
        id
        isActive
        email
        firstName
        lastName
        patronymic
        phoneNumber
        companyName
        role
        permissions {
          id
          codename
          description
        }
        emailStatus
      }

      count: organizationEmployeesCount(organizationId: $organizationId) @include(if: $withCount)
    }
  `,

  /**
   * GraphQL query: users with count
   */
  getUser: gql`
    query user($employeeId: ID!) {
      user: organizationEmployee(employeeId: $employeeId) {
        id
        isActive
        email
        firstName
        lastName
        phoneNumber
        companyName
        permissions {
          id
          codename
          description
        }
      }
    }
  `,
  /**
   * GraphQL query: all user permissions
   */
  getAllPermissions: gql`
    query allPermissions {
      permissions: allPermissions {
        id
        codename
        description
      }
    }
  `,

  getOrderDocumentTypes: gql`
    query orderDocumentTypes {
      orderDocumentTypes {
        ${fragments.ORDER_DOCUMENT_TYPE_FRAGMENT}
      }
    }
  `,

  getLegalForms: gql`
    query legalForms {
      legalForms {
        ${fragments.LEGAL_FORM_FRAGMENT}
      }
    }
  `,

  getCarrierProfile: gql`
    query carrierProfile {
      profile {
        id
        uiSettings
        isPromoActive
        organization {
          ${fragments.CARRIER_ORGANIZATION_FRAGMENT}
        }
        isRegistrationCompleted
        bankDetails {
          name
          rcbic
          corrAcc
          acc
        }
        userSettings {
          notificationSettings {
            auctionAndBiddingOrders {
              web
              email
            }
            freeOrder {
              web
              email
            }
            assignedOrder {
              web
              email
            }
            completeOrder {
              web
              email
            }
            cancelOrder {
              web
              email
            }
            documentProcessing {
              web
              email
            }
            tenderOrder {
              web
              email
            }
            reportCompletedOrders {
              web
              email
            }
          }
          isNewDesign
        }
        supportEmail
        supportPhoneNumber,
        email,
        firstName,
        lastName,
        patronymic,
        phoneNumber,
        country
        userGuides {
          signGuide {
            url
          }
          carrierGuide {
            url
          }
        }
      }
    }
  `,

  getCarrierProfileWorkingWithTn: gql`
    query carrierProfileWorkingWithTn {
      profile {
        id
        uiSettings
        isPromoActive
        organization {
          ${fragments.CARRIER_ORGANIZATION_FRAGMENT}
        }
        userSettings {
          notificationSettings {
            auctionAndBiddingOrders {
              web
              email
            }
            freeOrder {
              web
              email
            }
            assignedOrder {
              web
              email
            }
            completeOrder {
              web
              email
            }
            tenderOrder {
              web
              email
            }
            cancelOrder {
              web
              email
            }
            documentProcessing {
              web
              email
            }
            reportCompletedOrders {
              web
              email
            }
          }
          isNewDesign
        }
        supportEmail
        supportPhoneNumber,
        email,
        firstName,
        lastName,
        patronymic,
        phoneNumber,
        country
        userGuides {
          signGuide {
            url
          }
          carrierGuide {
            url
          }
        }
      }
    }
  `,

  getOwnerProfile: gql`
    query ownerProfile {
      profile {
        id
        organization {
          ${fragments.OWNER_ORGANIZATION_FRAGMENT}
        }
        bankDetails {
          name
          rcbic
          corrAcc
          acc
        }
        isRegistrationCompleted
        userSettings {
          notificationSettings {
            auctionAndBiddingOrders {
              web
              email
            }
            freeOrder {
              web
              email
            }
            assignedOrder {
              web
              email
            }
            completeOrder {
              web
              email
            }
            cancelOrder {
              web
              email
            }
            documentProcessing {
              web
              email
            }
          }
        }
        supportEmail
        supportPhoneNumber,
        email,
        firstName,
        lastName,
        patronymic,
        phoneNumber,
        country
        facsimileImg
        userGuides {
          signGuide {
            url
          }
          cargoOwnerGuide {
            url
          }
        }
      }
    }
  `,
  getOwnerStatusAccreditation: gql`
    query getOwnerStatusAccreditation {
      profile {
        organization {
          accrStatus
        }
      }
    }
  `,

  getCounters: gql`
    query GetOrdersCounters {
    ordersCounters {
      free
      assigned
      bidding
      auction
  }
  }
  `,

  getVatTypes: gql`
    query GetVatTypes {
      vatTypes {
        id
        name
        rate
        innerName
    }
  }
  `,

  getCargoDocType: gql`
    query getCargoDocTypes {
      cargoDocTypes {
        id
        name
        code
      }
    }
  `,

  /**
   * GraphQL query: loading|unloading places
   */
  getStoragePoints: gql`
   query storagePoints(
     ${STORAGE_POINTS_QUERY_TYPE_FRAGMENT}
   ) {
     storagePoints(
       ${STORAGE_POINTS_QUERY_BODY_FRAGMENT}
     ) {
       ${STORAGE_POINTS_QUERY_RESPONSE_FRAGMENT.main}
     }
     totalCount: storagePointsCount(
       ${STORAGE_POINTS_QUERY_RESPONSE_FRAGMENT.totalCount}
   }
 `,

  getOwnerStoragePoints: gql`
   query storagePoints(
     ${STORAGE_POINTS_QUERY_TYPE_FRAGMENT}
     $settlementFias: String
   ) {
     storagePoints(
       ${STORAGE_POINTS_QUERY_BODY_FRAGMENT}
       settlementFias: $settlementFias
     ) {
       ${STORAGE_POINTS_QUERY_RESPONSE_FRAGMENT.main}
       longitude
       latitude
       coordinateAccuracy
     }
     totalCount: storagePointsCount(
       ${STORAGE_POINTS_QUERY_RESPONSE_FRAGMENT.totalCount}
   }
 `,

  getSystemBanner: gql`
    query bannerNotification {
      bannerNotification {
        text
        expireAt
        isActive
      }
    }`,

  getOrganization: gql`
    query getOrganization($organizationSubdomain: String) {
      organizationBySubdomain (organizationSubdomain: $organizationSubdomain) {
        id
        name
        subdomain
      }
    }`,

  /**
   * GraphQL query: queryName for company name and company logo in TMS
   */
  getProfileNameAndLogo: gql`
    query userProfileWithLogoAndName {
      profile {
        organization {
          name
          subdomain
          logo
        }
      }
    }
  `,

  getCompanyDomainNameAndLogo: gql`
  query getLogoAndOrganizationNameQuery($subdomain: String!){
    cargoOwnerOrganization(subdomain: $subdomain){
      name
      logo
      subdomain
    }
  }
  `,
  getOrderAvailability: gql`
    query getOrderAvailability($orderId: ID!){
      order(id: $orderId) {
        id
        allocationType
        status
      }
    }
  `,
};
