import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import moment from 'moment';
import { BehaviorSubject, Subject, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

import { ITimeLeftResult, timeLeft } from './countdown';

const TIMER_INTERVAL = 1000;

@Component({
  selector: 'countdown-counter',
  templateUrl: './countdown-counter.component.html',
  styleUrls: ['./countdown-counter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnCountdownCounterComponent implements OnInit, OnDestroy {
  @Output() private readonly expired = new EventEmitter<boolean>();

  // eslint-disable-next-line rxjs/no-exposed-subjects
  @Input() public expired$: BehaviorSubject<boolean> | undefined;

  @Input() public label = '';

  @Input() public adjustedTime = 0;

  @Input() public set endDatetime(date: string | undefined | null) {
    this.complete$.next(null);
    if (Boolean(date))
      timer(0, TIMER_INTERVAL)
        .pipe(takeUntil(this.complete$))
        .subscribe(() => {
          if (this.expired$?.value === true || moment().add(this.adjustedTime, 'milliseconds').isSameOrAfter(moment(date))) {
            this.timeLeftSource$.next({
              className: 'danger',
              time: '00:00',
            });
            this.expired$?.next(true);
            this.expired.emit(true);
            this.complete$.next(null);

            return;
          }
          const tLeft = timeLeft(date, this.adjustedTime);
          if (tLeft !== this.timeLeftSource$.value) {
            this.timeLeftSource$.next(timeLeft(date, this.adjustedTime));
          }
        });
  }

  private readonly complete$ = new Subject();

  private readonly timeLeftSource$ = new BehaviorSubject<ITimeLeftResult | undefined>({
    className: '',
    time: '--:--',
  });

  public time$ = this.timeLeftSource$.pipe(map(v => v?.time));

  public className$ = this.timeLeftSource$.pipe(map(v => v?.className));

  constructor(private readonly cd: ChangeDetectorRef) {}

  public ngOnInit() {
    this.time$.pipe(takeUntil(this.complete$)).subscribe(() => this.cd.markForCheck());
  }

  public ngOnDestroy() {
    this.complete$.next(null);
  }
}
