import { Injectable } from '@angular/core';
import { IReportStatisticsForm, IStatisticsReportInput, REPORT_TYPE } from '@transport/ui-interfaces';

import { TnHttpHandlersService } from '../feature-access/http-handlers.service';
import { TnFileDownloadService } from '../file-download/file-download.service';

@Injectable({
  providedIn: 'root',
})
export class TnStatisticReportService {
  constructor(private readonly handlers: TnHttpHandlersService, private readonly downloadService: TnFileDownloadService) {}

  public downloadStatisticReportV2(input: IStatisticsReportInput) {
    const params =
      input.reportType === REPORT_TYPE.ORDER_DETAILS ? this.mapOrderDetailsReportParams(input) : this.mapOrderActivityReportParams(input);
    switch (input.reportType) {
      case REPORT_TYPE.ORDER_DETAILS:
        this.mapOrderDetailsReportParams(input);
        break;

      case REPORT_TYPE.ORDER_ACTIVITY:
        this.mapOrderActivityReportParams(input);
        break;

      case REPORT_TYPE.ORDERS_TRAIDING:
        this.mapBiddingDetailsReportParams(input);
        break;

      default:
        break;
    }
    return this.downloadService.download({
      url: this.handlers.getEndpointUrl(`/p/reports/${input.reportType}?${params}`),
      fileName: '',
    });
  }

  public downloadStatisticReport(input: IReportStatisticsForm) {
    const params = this.getReportStatisticParams(input);
    return this.downloadService.download({
      url: this.handlers.getEndpointUrl(`/stats/cargo_owner_carrier_activity_report/?${params}`),
      fileName: '',
    });
  }

  private mapOrderDetailsReportParams(input: IStatisticsReportInput) {
    return this.inputParametersToQuery({
      'created_datetime_from': input.dateFrom,
      'created_datetime_to': input.dateTo,
      'carrier_organization_id': input.organizationId,
      'carrier_group_id': Boolean(input.organizationId) ? null : input.groupId,
    });
  }

  private mapOrderActivityReportParams(input: IStatisticsReportInput) {
    return this.inputParametersToQuery({
      'order_no': input.orderId,
      'carrier_organization_id': input.organizationId,
      'carrier_group_id': Boolean(input.organizationId) ? null : input.groupId,
      'created_datetime_from': input.dateFrom,
      'created_datetime_to': input.dateTo,
    });
  }

  private mapBiddingDetailsReportParams(input: IStatisticsReportInput) {
    return this.inputParametersToQuery({
      'created_datetime_from': input.dateFrom,
      'created_datetime_to': input.dateTo,
      'carrier_organization_id': input.organizationId,
      'carrier_group_id': Boolean(input.organizationId) ? null : input.groupId,
    });
  }

  private inputParametersToQuery(input: Record<string, unknown>) {
    const queryParamsObject = Object.entries(input).reduce((result, [k, v]) => {
      if (!Boolean(v) || k === undefined) return result;
      return { ...result, [k]: v };
    }, {});
    // eslint-disable-next-line compat/compat
    return new URLSearchParams(queryParamsObject as unknown as Record<string, string>).toString();
  }

  private getReportStatisticParams(input: IReportStatisticsForm): string {
    const {
      dates: { fromDate, toDate },
      numberOrder,
      carrier,
      carrierGroup,
    } = input;
    const id = Boolean(numberOrder) ? `order_id=${numberOrder}` : '';
    const organization = Boolean(carrier) ? `&carrier_organization=${carrier}` : '';
    const groups = Boolean(carrierGroup) ? carrierGroup.map(value => `&carrier_group=${value}`).join('') : '';
    const from = Boolean(fromDate) ? `&date_from=${fromDate}` : '';
    const to = Boolean(toDate) ? `&date_to=${toDate}` : '';

    return `${id}${organization}${groups}${from}${to}`;
  }
}
