import { Sort } from '@angular/material/sort';
import { createReducer, on } from '@ngrx/store';

import { makeTableActions } from './table-actions';
import { ITableState, REFRESH_TYPE } from './table-state.interface';

export enum PAGE_SIZE {
  FIVE = 5,
  TEN = 10,
  TWENTY = 20,
  FIFTY = 50,
  HUNDRED = 100,
}

export const initialParamsState: ITableState = {
  pagination: {
    pageSize: PAGE_SIZE.TWENTY,
    page: 0,
    pageSizeOptions: [PAGE_SIZE.TWENTY, PAGE_SIZE.FIFTY, PAGE_SIZE.HUNDRED],
  },
  sort: {} as Sort,
  selected: new Map(),
  refresh: REFRESH_TYPE.SLEEP,
};

function getTablePaginationReducer(actionCreator, initialState: ITableState) {
  const tableParamsReducerFunc = createReducer(
    initialState,
    on(actionCreator, (state, action) => {
      const newOptions = {
        ...state.pagination,
        pageSize: (action as { pageSize }).pageSize,
        page: (action as { page }).page,
      };
      return {
        ...state,
        pagination: newOptions,
      };
    }),
  );
  return tableParamsReducerFunc;
}

function getResetTablePaginationReducer(actionCreator, initialState: ITableState) {
  const tableParamsReducerFunc = createReducer(
    initialState,
    on(actionCreator, state => {
      const newOptions = {
        ...state.pagination,
        page: 0,
      };
      return {
        ...state,
        pagination: newOptions,
      };
    }),
  );
  return tableParamsReducerFunc;
}

function getTableFilterReducer(actionCreator, initialState: ITableState) {
  const tableParamsReducerFunc = createReducer(
    initialState,
    on(actionCreator, (state, action) => {
      return {
        ...state,
        filter: (action as { filterSettings }).filterSettings,
      };
    }),
  );
  return tableParamsReducerFunc;
}

function getTableSortReducer(actionCreator, initialState: ITableState) {
  const tableParamsReducerFunc = createReducer(
    initialState,
    on(actionCreator, (state, action) => {
      return {
        ...state,
        sort: (action as { sortSettings }).sortSettings,
      };
    }),
  );
  return tableParamsReducerFunc;
}

function getTableSelectedReducer(actionCreator, initialState: ITableState) {
  const tableParamsReducerFunc = createReducer(
    initialState,
    on(actionCreator, (state, action) => {
      return {
        ...state,
        selected: new Map((action as { selected: Map<string, boolean> }).selected),
      };
    }),
  );
  return tableParamsReducerFunc;
}

function getRefreshStateReducer(actionCreator, initialState: ITableState) {
  const tableParamsReducerFunc = createReducer(
    initialState,
    on(actionCreator, (state, action) => {
      return {
        ...state,
        refresh: (action as { refresh: REFRESH_TYPE }).refresh,
      };
    }),
  );
  return tableParamsReducerFunc;
}

export function getTableReducer(actions: ReturnType<typeof makeTableActions>, initialState?: Record<string, unknown>) {
  const initial = Boolean(initialState) ? { ...initialParamsState, ...initialState } : initialParamsState;
  const reducerFunc = createReducer(
    initial,
    on(actions.setTablePageAction, getTablePaginationReducer(actions.setTablePageAction, initial)),
    on(actions.resetPaginationAction, getResetTablePaginationReducer(actions.resetPaginationAction, initial)),
    on(actions.setTableFilterAction, getTableFilterReducer(actions.setTableFilterAction, initial)),
    on(actions.setTableSortingAction, getTableSortReducer(actions.setTableSortingAction, initial)),
    on(actions.saveSelectionItems, getTableSelectedReducer(actions.saveSelectionItems, initial)),
    on(actions.setRefreshState, getRefreshStateReducer(actions.setRefreshState, initial)),
  );
  return reducerFunc;
}
