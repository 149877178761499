/**
 * Remove null properties.
 * @param obj object
 */
export function removeEmptyProperties<T = Record<string, unknown>>(obj: T) {
  const result = {};
  const keys = Object.keys(obj as Object);
  keys.forEach(key => {
    if (Boolean(obj[key]) || obj[key] === 0 || obj[key] === '' || obj[key] === false) {
      result[key] = obj[key];
    }
  });
  return result as T;
}

/**
 * For translate service with parameterized translate pipe you need param { value: str}
 * Take object { name: str }, return { name: {value: str }}
 */
export function getTranslateParams<T = Record<string, unknown>>(obj: T) {
  const result = Object.getOwnPropertyNames(obj).reduce((accumulator: Record<string, { value: string }>, key) => {
    accumulator[key] = { value: obj[key] };
    return accumulator;
  }, {});
  return result as Record<keyof T, { value: string }>;
}

export function kbToMb(sizeKb: number) {
  const mbSize = 1048576;
  return sizeKb / mbSize;
}

export function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

export function replaceObjectProperty(obj, from: number, to: number) {
  const fields = Object.entries(obj);
  if (from < to) {
    fields.splice(to + 1, 0, fields[from]);
    fields.splice(from, 1);
  } else {
    const replacedField = fields.splice(from, 1);
    fields.splice(to, 0, replacedField[0]);
  }

  return fields.reduce((acc, current) => {
    acc[current[0]] = current[1];
    return acc;
  }, {});
}
