import { Component, Input } from '@angular/core';

@Component({
  selector: 'marketplace-place-with-datetime-column',
  templateUrl: './place-with-datetime-column.component.html',
  styleUrls: ['./place-with-datetime-column.component.scss'],
})
export class PlaceWithDatetimeColumnComponent {
  @Input() public date: Date | null = null;

  @Input() public settlement = '';

  @Input() public timeFrom: string | null = null;

  @Input() public timeTo: string | null = null;
  
  get ifTimeIsZero() {
    return this.timeFrom === '00:00' && this.timeTo === '00:00';
  }
}
