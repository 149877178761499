<h1 mat-dialog-title class="pt-4">
  {{ titleText | translate }}
</h1>
<div mat-dialog-content class="dialog-content" *ngIf="fileTypesOptions">
  <common-select
    [control]="fileTypeFormControl"
    [dataSource]="fileTypesOptions"
    [allowClear]="false"
    [disabled]="fileTypeFormControl.disabled"
    [errorToMessage]="validationErrors.bind(this)"
    label="{{ fileTypePlaceholderText | translate }}"
    [required]="true">
  </common-select>

  <transport-upload-area
    *ngIf="isUploadAreaVisible"
    (filesChange)="onFileChange($event)"
    (invalidFileSizeSelected)="onInvalidFileSizeSelected($event)"
    [multiple]="multipleFiles"
    [files]="$any(inputFiles)"
    [accept]="accept"
  ></transport-upload-area>

  <transport-files-list
    [files]="$any(selectedFiles$ | async)"
    (removeButtonClicked)="onRemoveButtonClick($event)"
    (refreshButtonClicked)="onRefreshButtonClick($event)"
  ></transport-files-list>
  <div class="accept-files">Допустимы файлы {{acceptFiles}}.</div>
  <transport-file-additional-attributes-container
    *ngIf="withAdditionalAttributes"
    [docAttributes]="{ docType: getDocTypeInnerName, waybillForm: waybillForm }"
  ></transport-file-additional-attributes-container>
</div>
<div mat-dialog-actions class="flex !justify-end content-center !px-6">
  <button mat-button cdkFocusInitial (click)="cancel()">
    {{ cancelButtonText | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="confirm()" [disabled]="isSaveDisabled$ | async" data-test="confirm-upload">
    {{ saveButtonText | translate }}
  </button>
</div>
