import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';

@Directive({
  selector: `
     mat-radio-button[transportMatInputDataTest],
     mat-select[transportMatInputDataTest],
      ng-select[transportMatInputDataTest],
       mat-checkbox[transportMatInputDataTest]`,
})
export class TnMatCheckboxControlDirective implements AfterViewInit {
  @Input('transportMatInputDataTest') value?: string | null = null;

  constructor(private readonly elemRef: ElementRef) {}

  public ngAfterViewInit() {
    const elem = this.elemRef.nativeElement;
    const elemInput = this.elemRef.nativeElement.querySelector('input');

    if (Boolean(elemInput)) {
      let id = elem.getAttribute('data-test');
      id = Boolean(id) ? id : this.value;
      if (Boolean(id)) {
        elemInput.setAttribute('data-test', String(id) + '-input');
      }
    }
  }

}
