/**
 * Place type.
 */
export type TPlace = 'LOADING' | 'UNLOADING';

/**
 * Loading checkBox.
 */
export interface ILoadingCheckbox {
  checked: boolean;
  id: string;
  name: string;
}
