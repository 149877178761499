import { USER_ROLE } from '@transport/ui-interfaces';
import { OrganizationPermissions } from 'apps/marketplace/src/shared/services/domain-organization.service';

export interface SidebarItem {
  title: string;
  route?: string;
  queryParams?: SidebarQueryParams;
  icon?: string;
  children?: SidebarItem[];
  allowed?: USER_ROLE[];
  allowedOnSubdomain?: USER_ROLE[];
  permissions?: string[];
  organizationPermissions?: OrganizationPermissions[];
  classes?: string;
  module?: string;
  dataTest?: string;
  isAgentOrExpeditor?: boolean;
  hasDivider?: boolean;
}

export interface SidebarQueryParams {
  tab?: number;
}
