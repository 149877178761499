import { transport } from '@transport/proto';
import { ILoginResponseData } from '@transport/ui-interfaces';
import { TnSupportedCurrency } from '@transport/ui-pipes';

export type TOrganizationStatus = string; // TODO: specific string values

export type TSubOrganizationRole = 'CARGO_OWNER' | 'CARRIER';

export interface ISubOrganizationsFilter {
  availability?: string;
}

export enum DOCUMENT_FLOW_SETTINGS {
  CARRIER_ONLY = 'CARRIER_ONLY',
  CARGO_OWNER_FIRST = 'CARGO_OWNER_FIRST',
  CARRIER_FIRST = 'CARRIER_FIRST',
  OPTIONAL_CARRIER_ONLY_SIGN = 'OPTIONAL_CARRIER_ONLY_SIGN',
}

export enum AUCTION_CRITERION_CHOICES {
  FIRST_LOADING_DATE = 'FIRST_LOADING_DATE',
  IS_VAT_INCLUDED = 'IS_VAT_INCLUDED',
}

export interface IDefaultProfileParams {
  vat: transport.IOrganizationVatType;
  showOrderExternalNo: boolean;
  isCompanyGroup: boolean;
  paymentType: transport.IPaymentType;
  paymentPeriod: string;
  id: string;
  name: string;
  currency: TnSupportedCurrency;
  auctionParameters: transport.IAuctionProlongationParameters & {
    minutesOfProlongation?: number;
    earlyFinish?: boolean;
    minStepPrice?: number;
    minPriceStepPercent?: number;
    typeOfPriceStep?: 'FIXED' | 'PERCENT';
    additionalAuctionCriteriaRequired?: boolean;
  };
  cargoRequired: boolean;
}

export interface IOrganizationInnStatuses {
  organizationName?: string;
  status: TInnStatus;
}

export type TInnStatus = 'ORGANIZATION_CAN_BE_CREATED' | 'ORGANIZATION_IS_NOT_ACTIVE' | string;

export interface ICreateCompanyStatus {
  organizationName: string;
  status: string;
  userLoginData: ILoginResponseData;
}

export interface IListOrganization {
  name: string;
  inn: string;
  address: string;
}
