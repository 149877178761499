import { ChangeDetectionStrategy, Component, Input, ViewChild } from '@angular/core';
import { TnDynamicComponentDirective } from '@transport/ui-directives';
import { DOCUMENT_TYPE, TFileAdditionalAttributes } from '@transport/ui-interfaces';

import { IFileAdditionalAttributesForm } from '../file-additional-attributes-form-abstract';
import { IWaybillForm, TnWaybillFormComponent } from '../waybill-form/waybill-form.component';

@Component({
  selector: 'transport-file-additional-attributes-container',
  templateUrl: './file-additional-attributes-container.component.html',
  styleUrls: ['./file-additional-attributes-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnFileAdditionalAttributesContainerComponent {
  @ViewChild(TnDynamicComponentDirective, { static: true })
  private readonly dynamicComponentDir?: TnDynamicComponentDirective;

  @Input() public set docAttributes({ docType, waybillForm }: { docType: DOCUMENT_TYPE; waybillForm?: IWaybillForm }) {
    this.loadComponent({ docType, waybillForm });
  }

  public get invalid(): boolean {
    return Boolean(this.form?.invalid);
  }

  public get value(): TFileAdditionalAttributes | undefined {
    return this.form?.getValue();
  }

  private form?: IFileAdditionalAttributesForm | IWaybillForm;

  public markAllAsTouched(): void {
    this.form?.markAllAsTouched();
  }

  public disabledFields(): void {
    this.form?.disable();
  }

  public writeValue(val: TFileAdditionalAttributes): void {
    this.form?.writeValue(val);
  }

  private loadComponent({ docType, waybillForm }: { docType: DOCUMENT_TYPE; waybillForm?: IWaybillForm }): void {
    if (docType === DOCUMENT_TYPE.WAYBILL) {
      this.form = this.dynamicComponentDir?.createComponent<IWaybillForm>(TnWaybillFormComponent);
      if (Boolean(waybillForm)) {
        const { orderUnloadingPlaces$, loadingPlaceTime$, isWaybillRequired$ } = waybillForm ?? ({} as IWaybillForm);
        (this.form as IWaybillForm).orderUnloadingPlaces$ = orderUnloadingPlaces$;
        (this.form as IWaybillForm).loadingPlaceTime$ = loadingPlaceTime$;
        (this.form as IWaybillForm).isWaybillRequired$ = isWaybillRequired$;
      }
    } else {
      this.dynamicComponentDir?.clear();
      delete this.form;
    }
  }
}
