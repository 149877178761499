<div
  [@fadeInOut]
  ngfDrop
  [multiple]="multiple ? '1' : ''"
  [selectable]="true"
  [(validDrag)]="!!validComboDrag"
  [files]="$any(files)"
  (filesChange)="onChangeFiles($event)"
  (lastInvalidsChange)="onInvalidChangeFiles($event)"
  [accept]="accept"
  [maxSize]="maxSize"
  [ngClass]="{
    'tn-drop-zone': useDefaultStyle,
    'unknown-drag': validComboDrag === null
  }"
  [class.invalid-drag]="validComboDrag === false"
  [class.valid-drag]="validComboDrag === true"
  (dragFilesChange)="onDragFilesChange($event)"
>
  <div [class]="isHorizontalCenter ? 'flex flex-col justify-center items-center' : 'flex flex-col justify-center'">
    <div class="tooltip w-full">
      <mat-icon *ngIf="useDefaultStyle" svgIcon="upload" class="upload"></mat-icon>
      <ng-content></ng-content>
    </div>
    <ng-content select="[bottom]"></ng-content>
  </div>
</div>
