import { RECONCILIATION_ACT_CREATION_METHOD } from '../reconciliation-act-creation-method';
import { RECONCILIATION_ACT_ERROR_TYPE } from '../reconciliation-act-error-type';
import { RECONCILIATION_ACT_STATUS } from '../reconciliation-act-status';

export interface IReconcilationActDto {
  id: string;
  guid: string;
  status: RECONCILIATION_ACT_STATUS;
  organization_1: number;
  organization_1_name: string | undefined;
  organization_2: number;
  organization_2_name: string | undefined;
  created_at: string | null | undefined;
  comment: string;
  creation_method: RECONCILIATION_ACT_CREATION_METHOD;
  act_date_from: string | null | undefined;
  act_date_to: string | null | undefined;
  has_diff: boolean | null;
  organization_1_rule_used: string;
  organization_2_rule_used: string;
  error_type: RECONCILIATION_ACT_ERROR_TYPE;
  full_results_filename: string;
  diff_results_filename: string;
  act_1_filename: string;
  act_2_filename: string;
}
