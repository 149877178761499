import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
  QueryList,
  SimpleChange,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatSort, Sort } from '@angular/material/sort';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { AbstractControlsOf } from '@ngneat/reactive-forms/lib/types';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { IUiSettings, IUiTableColumnDisplay, IUiTableColumnSetting } from '@transport/ui-interfaces';
import { TnCurrentUserFacade } from '@transport/ui-store';
import { TIMEOUT } from '@transport/ui-utils';
import { Subscription } from 'rxjs';
import { debounceTime, tap } from 'rxjs/operators';

import { ITableSelection } from './table.interfaces';
import { TABLE_CELL_PIPE_TYPE } from './table-cell.pipe';
import { TColumnType, TnTableColumnComponent } from './table-column/table-column.component';
import { TnTableSearchCellComponent } from './table-search-cell/table-search-cell.component';

export const tableSettingsStorageKey = 'tablesSettings';
const minColumnWidth = 64;

export const getTableSettings = () => {
  const localStorageValue = window.localStorage.getItem(tableSettingsStorageKey);
  const result = (localStorageValue !== null ? JSON.parse(localStorageValue) : {}) as Record<string, Record<string, number> | undefined>;
  return result;
};

@UntilDestroy()
@Component({
  selector: 'transport-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnTableComponent<T extends { id?: string | null }> implements AfterViewInit, OnChanges {
  public tableCellPipeType = TABLE_CELL_PIPE_TYPE;

  @ViewChild('defaultCellTemplate')
  private readonly defaultCellTemplate: TemplateRef<never> | null = null;

  @ViewChild('dateCellTemplate')
  private readonly dateCellTemplate: TemplateRef<never> | null = null;

  @ViewChild('defaultHeaderTemplate')
  private readonly defaultHeaderTemplate: TemplateRef<never> | null = null;

  @ViewChild('selectorsHeaderTemplate')
  private readonly selectorsHeaderTemplate: TemplateRef<never> | null = null;

  @ViewChild('settingsHeaderTemplate')
  private readonly settingsHeaderTemplate: TemplateRef<never> | null = null;

  @ViewChild('selectorsCellTemplate')
  private readonly selectorsCellTemplate: TemplateRef<never> | null = null;

  @ViewChild('textSearchTemplate')
  private readonly textSearchTemplate: TemplateRef<never> | null = null;

  @ViewChild('selectorSearchTemplate')
  private readonly selectorSearchTemplate: TemplateRef<never> | null = null;

  @ViewChild('defaultFooterCellTemplate')
  private readonly defaultFooterCellTemplate: TemplateRef<never> | null = null;

  @ViewChild(MatSort, { static: true }) public matSort: MatSort | null = null;

  @ContentChildren(TnTableColumnComponent)
  private readonly columns: QueryList<TnTableColumnComponent> | null = null;

  @ContentChildren(TnTableSearchCellComponent)
  private readonly searchCells: QueryList<TnTableSearchCellComponent> | null = null;

  private uiSettingsSubscription: Subscription | null = null;

  @Input()
  public tableName = '';

  @Input()
  public tableClass = 'mat-elevation-z8';

  @Input() public columnNames: IUiTableColumnDisplay[] = [];

  @Input() public translatePath = '';

  @Input() public dataSource: T[] = [];

  @Input() public footerValues: Record<string, string> = {};

  @Input() public hasActions = true;

  @Input() public hasSettings = false;

  @Input() public hasSelectors = false;

  @Input() public hasIndicators = false;

  @Input() public isSticky = true;

  @Input() public selection: Map<string, boolean> = new Map();

  @Input() public rowActions?: Record<string, (rowData: T) => boolean>;

  @Input() public currentSort: Sort = {} as Sort;

  @Input() public searchForm?: FormGroup;

  @Input() public hasShowFooter = false;

  @Input() public gotoDetailsLinkFn: ((row: T) => string) | null = null;

  @Input() public columnsDraggable = true;

  @Output() public readonly changeSorter = new EventEmitter<Sort>();

  @Output() public readonly isDragging = new EventEmitter();

  @Output() public readonly reordered = new EventEmitter();

  @Output() public readonly changeSettings = new EventEmitter();

  @Output() public readonly changeSelection = new EventEmitter<ITableSelection<T>>();

  @Output() public readonly rowClick = new EventEmitter();

  @Output() public readonly rowDblClick = new EventEmitter();

  @Output() public readonly search = new EventEmitter();

  public cellTemplates: Record<string, TemplateRef<never> | null> = {};

  public headerTemplates: Record<string, TemplateRef<never> | null> = {};

  public searchTemplates: Record<string, TemplateRef<never> | null> = {};

  public footerCellTemplates: Record<string, TemplateRef<never> | null> = {};

  public columnModels: {
    field: string;
    hasTooltip: boolean;
    hasSorting: boolean;
    label: string;
    width: number;
    type: TColumnType;
    selectorOptions: Record<string, string>[];
    hasFooterValue: boolean;
  }[] = [];

  public deltas: { field: string; dx: number }[] = [];

  public displayedColumns: string[] = [];

  public isAllSelected = false;

  public isDragStart = false;

  public settings: Record<
    string,
    { label: string; visible: boolean; width?: number; hasSorting?: boolean; hasSearch?: boolean; hasFooterValue: boolean }
  > = {};

  public hasSearch = false;

  public get localStorageKey() {
    return this.getLocalStorageKey();
  }

  public rowClasses = (rowData: T) => ({
    archived: Boolean(this.rowActions?.archived) ? this.rowActions?.archived(rowData) : false,
    warning: Boolean(this.rowActions?.warning) ? this.rowActions?.warning(rowData) : false,
    blocked: Boolean(this.rowActions?.blocked) ? this.rowActions?.blocked(rowData) : false,
    selected: Boolean(this.rowActions?.selectedRow) ? this.rowActions?.selectedRow(rowData) : false,
    highlighted: Boolean(this.rowActions?.highlighted) ? this.rowActions?.highlighted(rowData) : false,
  });

  constructor(
    public readonly userFacade: TnCurrentUserFacade,
    public readonly translate: TranslateService,
    private readonly cdRef: ChangeDetectorRef,
  ) {}

  public ngAfterViewInit() {
    const { active, direction } = this.currentSort;
    if (direction === 'asc' || direction === 'desc') {
      this.matSort?.sort({ id: active, start: direction, disableClear: false });
    }
    this.matSort?.sortChange.pipe(untilDestroyed(this)).subscribe((sort: Sort) => this.changeSorter.emit(sort));
    this.hasSearch = Boolean(this.searchCells?.length);
    this.initSearchForm();
  }

  public ngOnChanges(changes: SimpleChanges) {
    const columnNames = changes.columnNames;
    if (Boolean(columnNames.currentValue)) {
      this.cdRef.markForCheck();
      if (this.hasNewColumns(columnNames)) {
        setTimeout(() => this.updateTableSettings());
      }
    }
  }

  private hasNewColumns(columnNames: SimpleChange) {
    return (
      columnNames.isFirstChange() ||
      (columnNames.currentValue as IUiTableColumnDisplay[]).some(
        row => (columnNames.previousValue as IUiTableColumnDisplay[]).find(prevRow => prevRow.name === row.name)?.include !== row.include,
      )
    );
  }

  private getLocalStorageKey(postfix?: string) {
    if (Boolean(postfix)) {
      return `${this.tableName}_${postfix}`;
    }
    return this.tableName;
  }

  private updateTableSettings() {
    this.uiSettingsSubscription?.unsubscribe();
    this.uiSettingsSubscription = this.userFacade.uiSettings$.pipe(untilDestroyed(this)).subscribe(uiSettings => {
      const columnSettings = Boolean(uiSettings) && uiSettings[this.tableName] && (uiSettings[this.tableName] as { columns }).columns;
      this.applyColumnSettings(columnSettings);
      const visibleColumns = Object.keys(this.settings).filter(key => this.settings[key].visible);
      this.displayedColumns = [...visibleColumns];
      if (this.hasActions) {
        this.displayedColumns.push('actions');
      }
      if (this.hasIndicators) {
        this.displayedColumns.unshift(...['indicators']);
      }
      if (this.hasSelectors) {
        this.displayedColumns.unshift(...['selectors']);
      }
      this.columnModels = this.displayedColumns.map(key => {
        const { type, hasTooltip } = this.columns?.find(col => col.field === key) ?? ({} as TnTableColumnComponent);
        return {
          field: key,
          hasSorting: Boolean(this.settings[key]?.hasSorting),
          label: this.settings[key]?.label ?? '',
          width: this.settings[key]?.width ?? minColumnWidth,
          hasTooltip: Boolean(hasTooltip),
          hasSearch: Boolean(this.settings[key]?.hasSearch),
          selectorOptions: this.searchCells?.find(cell => cell.field === key)?.options ?? [],
          hasFooterValue: Boolean(this.settings[key]?.hasFooterValue),
          type,
        };
      });
      this.deltas = this.displayedColumns.map(key => {
        return {
          field: key,
          dx: (getTableSettings()[this.localStorageKey] ?? {})[key] ?? 0,
        };
      });
      this.updateTemplates();
      this.cdRef.markForCheck();
    });
  }

  private initSearchForm() {
    if (this.hasSearch) {
      if (!Boolean(this.searchForm)) {
        this.searchForm = this.getDefaultSearchForm();
      }
      void (this.searchForm as FormGroup).valueChanges
        .pipe(
          debounceTime(TIMEOUT.DEFAULT),
          untilDestroyed(this),
          tap(value => this.search.emit(value)),
        )
        .subscribe();
    }
  }

  private getDefaultSearchForm() {
    const controls: AbstractControlsOf<Record<string, FormControl>> = {} as AbstractControlsOf<Record<string, FormControl>>;
    this.searchCells?.forEach(({ field }) => {
      controls[field as string] = new FormControl('');
    });
    return new FormGroup(controls);
  }

  private applyColumnSettings(columnSettings: IUiTableColumnSetting[]) {
    this.settings = {};
    const getLabel = (column: IUiTableColumnDisplay) => {
      return Boolean(column.label)
        ? this.translate.instant(column.label ?? '')
        : this.translate.instant(`${this.translatePath}.${column.name}`);
    };

    let newColumnNames: IUiTableColumnDisplay[] = [];

    if (Boolean(columnSettings) && columnSettings.length) {
      columnSettings.forEach(columnSet => {
        const column: IUiTableColumnDisplay | undefined = this.columnNames.find(item => item?.name === columnSet?.name);
        if (Boolean(column) && Boolean(column?.include)) {
          this.settings[column?.name ?? ''] = {
            label: getLabel(column as IUiTableColumnDisplay),
            visible: columnSet.visible,
            width: column?.width,
            hasSorting: column?.hasSorting,
            hasSearch: column?.hasSearch,
            hasFooterValue: Boolean(column?.hasFooterValue),
          };
        }
      });
      newColumnNames = this.columnNames.filter(item => !columnSettings.some(x => x?.name === item?.name) && item?.include);

      if (Boolean(newColumnNames.length)) {
        const newColumnSettings = newColumnNames.map(column => ({ name: column.name, visible: Boolean(column.include) }));
        this.userFacade.setUiSettings(this.tableName, {
          columns: [...newColumnSettings, ...columnSettings],
        });
      }
    } else {
      newColumnNames = this.columnNames;
    }
    newColumnNames.forEach(column => {
      if (column.include) {
        this.settings[column.name] = {
          label: getLabel(column),
          visible: column.include,
          width: column.width,
          hasSorting: column?.hasSorting,
          hasFooterValue: Boolean(column?.hasFooterValue),
        };
      }
    });
  }

  private onSettingsChange(columns: IUiTableColumnSetting[]) {
    this.updateTableSettings();
    this.userFacade.setUiSettings(this.tableName, {
      columns,
    });
  }

  public reorderColumns($event: { from: number; to: number }) {
    if (!this.tableName) {
      throw new Error('You should set property name of table');
    } else {
      let currenSettings: IUiTableColumnSetting[] = [];
      const settings = <IUiSettings<IUiTableColumnSetting[] | undefined>>this.userFacade.currentUserUiSettings;
      const columns = (settings[this.tableName] as { columns: IUiTableColumnSetting[] | undefined })?.columns;
      if (typeof columns !== 'undefined') {
        currenSettings = [...columns];
      } else {
        currenSettings = Object.keys(this.settings).reduce((acc: IUiTableColumnSetting[], key) => {
          acc.push({ name: key, visible: this.settings[key].visible });
          return acc;
        }, []);
      }

      // some columns is not visible, so we need right indexes
      const mapVisibleIndexes = Object.keys(this.settings).reduce((acc: number[], key) => {
        if (this.settings[key].visible) {
          const position = currenSettings.findIndex(item => item.name === key);
          if (position >= 0) {
            acc.push(position);
          }
        }
        return acc;
      }, []);

      const temp = currenSettings[mapVisibleIndexes[$event.from]];
      if ($event.to > $event.from) {
        currenSettings.splice(mapVisibleIndexes[$event.to] + 1, 0, temp);
        currenSettings.splice(mapVisibleIndexes[$event.from], 1);
      } else {
        currenSettings.splice(mapVisibleIndexes[$event.from], 1);
        currenSettings.splice(mapVisibleIndexes[$event.to] + 1, 0, temp);
      }

      this.userFacade.setUiSettings(this.tableName, {
        columns: [...currenSettings],
      });
    }
  }

  public onCellClick(event: MouseEvent, field: string, rowData: T) {
    if (this.gotoDetailsLinkFn !== null) {
      event.preventDefault();
    }

    const { anchorOffset, focusOffset } = document.getSelection() as Selection;

    if (anchorOffset === focusOffset && field !== 'actions' && field !== 'selectors' && field !== 'contracts') {
      //TODO: временное решение TN/TMS-6661438 . исправить, когда таблицы в ТМС будут из markeplace.
      if (window.location.pathname.split('/')[1].includes('orders')) {
        this.rowClick.emit({ rowData, event });
        return;
      }
      this.rowClick.emit(rowData);
    }
  }

  public onColumnVisibilityChange(value: Record<string, boolean>) {
    const currentSettings: IUiTableColumnSetting[] = [];
    Object.keys(value).forEach(key => {
      currentSettings.push({ name: key, visible: value[key] });
    });
    this.onSettingsChange(currentSettings);
  }

  public get isSomeSelect() {
    return Boolean(this.selection.size) && !this.isAllSelected;
  }

  public isRowChecked(rowData: T) {
    return Boolean(this.selection.get(rowData.id as string));
  }

  public changeAllSelection(isAllSelected: boolean) {
    this.isAllSelected = isAllSelected;
    this.changeSelection.emit({ isSingleSelection: false, isSelected: isAllSelected } as ITableSelection<T>);
  }

  public changeRowSelection(isSelected: boolean, rowData: T) {
    this.isAllSelected = this.selection.size === this.dataSource.length;
    this.changeSelection.emit({ isSingleSelection: true, isSelected, currentRow: rowData });
  }

  public onDragColumnStart() {
    this.isDragging.emit(true);
    this.isDragStart = true;
  }

  public onDragColumnFinish() {
    this.isDragging.emit(false);
    this.isDragStart = false;
  }

  private updateTemplates() {
    this.columnModels.forEach(columnModel => {
      const { field, type, hasFooterValue } = columnModel;
      const column = this.columns?.find(col => col.field === field) ?? null;
      this.headerTemplates[field] = this.getHeaderTemplate(field);
      if (this.hasSelectors && field === 'selectors') {
        this.cellTemplates[field] = this.selectorsCellTemplate;
      } else {
        this.cellTemplates[field] = column?.template ?? this.getCellTemplate(type);
      }
      const searchCell = this.searchCells?.find(cell => cell.field === field) ?? null;
      if (searchCell !== null) {
        this.searchTemplates[field] = searchCell.template ?? this.getSearchTemplate(searchCell.type);
      }
      this.footerCellTemplates[field] = Boolean(hasFooterValue) ? this.defaultFooterCellTemplate : null;
    });
  }

  private getHeaderTemplate(field: string) {
    switch (field) {
      case 'actions':
        return this.settingsHeaderTemplate;
      case 'selectors':
        return this.selectorsHeaderTemplate;
      case 'indicators':
        return null;
      default:
        return this.defaultHeaderTemplate;
    }
  }

  private getCellTemplate(type: TColumnType) {
    switch (type) {
      case 'date':
        return this.dateCellTemplate;
      default:
        return this.defaultCellTemplate;
    }
  }

  private getSearchTemplate(type: string) {
    switch (type) {
      case 'selector':
        return this.selectorSearchTemplate;
      default:
        return this.textSearchTemplate;
    }
  }
}
