import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { TnMarketplaceAuthService } from '@marketplace/auth';
import { HTTP_ERROR_CODE, IRegistrationForbiddenError } from '@transport/ui-interfaces';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MpRegistrationEmployeeGuard implements CanActivate {
  constructor(
    private readonly authService: TnMarketplaceAuthService,
    private readonly router: Router
  ) {}

  public canActivate(next: ActivatedRouteSnapshot) {
    const token = next.queryParams.token;
    if (!Boolean(token)) {
      return this.router.createUrlTree([`/passport`]);
    }
    return this.authService.verifyUserKey(token).pipe(
      map(response => {
        return true;
      }),
      catchError((error: IRegistrationForbiddenError) => {
        if (error.status === HTTP_ERROR_CODE.FORBIDDEN) {
          return of(true);
        }
        return of(this.router.createUrlTree([`/passport`]));
      }),
    );
  }
}