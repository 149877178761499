<h2> {{ 'shared.auth.registration.form.activationTitle' | translate }} </h2>
<form [formGroup]="registrationForm">
      <common-select
      size="md"
      class="w-full"
      inputFieldClass="mb-2"
      errorsSize="xs"
      label="{{ 'shared.profile.user.info.countryField' | translate }}"
      [formGroup]="registrationForm"
      [dataSource]="countries"
      controlName="country"
      [allowClear]="false"
      [errorToMessage]="countryValidationErrors.bind(this)"
      dataTest="user-select-country"
    ></common-select>
    <common-input
      [formGroup]="registrationForm"
      size="md"
      class="w-full mb-2"
      errorsSize="xs"
      placeholder="{{ 'shared.auth.registration.form.fio.placeholder' | translate }}"
      label="{{ 'shared.auth.registration.form.fio.label' | translate }}"
      controlName="fullName"
      [errorToMessage]="fioValidationErrors.bind(this).bind(this)"
      data-test="fullName-input-marketplace"
    ></common-input>

    <common-phone-input
      [formGroup]="registrationForm"
      size="md"
      class="w-full"
      inputFieldClass="w-full"
      label="{{ 'shared.auth.registration.form.phone.label' | translate }}"
      controlName="phoneNumber"
      [errorToMessage]="phoneValidationErrors.bind(this).bind(this)"
      dataTest="registration-employe-phone"
    ></common-phone-input>

  <button data-test="registration-button" (click)="submit($event)" [disabled]="isLoading" class="tw-button-solid-md">
    {{'shared.auth.registration.form.continue' | translate}}
    <mat-spinner *ngIf="isLoading" diameter="18" ></mat-spinner>
  </button>
</form>

<p class="licenseText">
  {{ 'shared.auth.licenseText.text' | translate }}
  <a class="licenseText__link" target="_blank" [href]="licenseAgreementURL">{{ 'shared.auth.licenseText.link' | translate }}</a>
</p>
