import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { VehicleService } from '@marketplace/shared';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { ValidationErrors, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import moment from 'moment';
import { VEHICLE_TYPES } from 'libs/marketplace-shared/src/lib/edit-vehicle/form/vehicle-form';
import { regExpConfig, vehicleNumberPrepare } from '@transport/ui-utils';
import { COUNTRIES, TnSupportedCountry, TnSupportedCountryName } from '@transport/ui-pipes';
import { ICommonSelectData } from 'libs/custom-controls/src/lib/common.interface';
import { IVehicleBlacklistItem } from 'apps/transport/src/app/black-list/components/vehicle/vehicle-blacklist.interfaces';
import { map } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'vehicle-black-list-modal',
  templateUrl: 'vehicle-blacklist-modal.component.html',
  providers: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehicleBlacklistModalComponent implements OnInit {
  public readonly vehicleTypes = VEHICLE_TYPES.map(t => ({ id: t.type, label: this.translate.instant(t.name) }));
  public readonly countries = COUNTRIES.map(c => (c.id === TnSupportedCountry.NO ? { ...c, hidden: true } : c));
  public readonly regExpConf = regExpConfig;

  public blockTypes: ICommonSelectData<boolean>[] = [
    {
      id: true,
      label: this.translate.instant('shared.mp.vehicles.blackListModal.full'),
    },
    {
      id: false,
      label: this.translate.instant('shared.mp.vehicles.blackListModal.partial'),
    },
  ];

  public registrationNumberPlaceholderConfig = {
    [TnSupportedCountry.RU]: 'A 123 AA 123',
    [TnSupportedCountry.KZ]: '11 YYY 11',
    [TnSupportedCountry.BY]: 'XXXXXX - N',
    [TnSupportedCountry.UZ]: '01 123 ABC',
    [TnSupportedCountry.TJ]: '1234 AB 01',
    [TnSupportedCountry.KG]: '01 123 AB',
  };

  public form: FormGroup = new FormGroup({
    country: new FormControl(this.countries[0], [Validators.required]),
    // vehicleType: new FormControl(null),
    regNo: new FormControl(null, [Validators.required]),
    reason: new FormControl(null, Validators.required),
    description: new FormControl(null),
    workProhibited: new FormControl(this.blockTypes[0]),
  });

  public country = TnSupportedCountryName[this.data.vehicle?.country || TnSupportedCountry.NO];
  public regNo = vehicleNumberPrepare(this.data.vehicle?.regNo || '', this.data.vehicle?.country ?? TnSupportedCountry.RU);
  public vehicleType = this.vehicleTypes.find(v => v.id === this.data.vehicle?.vehicleType)?.label;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: { service: VehicleService; vehicle?: IVehicleBlacklistItem },
    private readonly dialogRef: MatDialogRef<VehicleBlacklistModalComponent>,

    public translate: TranslateService,
  ) {}

  ngOnInit() {
    if (!this.data.vehicle?.regNo)
      this.form.controls.country.valueChanges.pipe(untilDestroyed(this)).subscribe(({ id }) => {
        switch (id) {
          case TnSupportedCountry.RU:
            this.form.controls.regNo.clearValidators();
            this.form.controls.regNo.setValidators([
              Validators.required,
              Validators.pattern(/^[авекмнорстухАВЕКМНОРСТУХ][0-9]{3}[авекмнорстухАВЕКМНОРСТУХ]{2}[0-9]{2,3}/),
            ]);
            this.form.controls.regNo.updateValueAndValidity();
            break;
          case TnSupportedCountry.KZ:
            this.form.controls.regNo.clearValidators();
            this.form.controls.regNo.setValidators([Validators.required, Validators.pattern(/^[A-Za-z0-9 ]{4}[A-Za-z0-9 ]{0,4}/)]);
            this.form.controls.regNo.updateValueAndValidity();
            break;
          case TnSupportedCountry.UZ:
            this.form.controls.regNo.clearValidators();
            this.form.controls.regNo.setValidators([Validators.required, Validators.pattern(/^[A-Za-z0-9 ]{8}[A-Za-z0-9 ]{0,1}/)]);
            this.form.controls.regNo.updateValueAndValidity();
            break;
          case TnSupportedCountry.KG:
            this.form.controls.regNo.clearValidators();
            this.form.controls.regNo.setValidators([Validators.required, Validators.pattern(/^[A-Za-z0-9 ]{7}[A-Za-z0-9 ]{0,1}/)]);
            this.form.controls.regNo.updateValueAndValidity();
            break;
          case TnSupportedCountry.TJ:
            this.form.controls.regNo.clearValidators();
            this.form.controls.regNo.setValidators([Validators.required, Validators.pattern(/^[A-Za-z0-9 ]{7}[A-Za-z0-9 ]{0,1}/)]);
            this.form.controls.regNo.updateValueAndValidity();
            break;
          case TnSupportedCountry.BY:
            this.form.controls.regNo.clearValidators();
            this.form.controls.regNo.setValidators([Validators.required, Validators.pattern(/[A-Za-z0-9]{6}[1-7]/)]);
            this.form.controls.regNo.updateValueAndValidity();
            break;

          default:
            break;
        }
      });

    if (this.data.vehicle) {
      const value = {
        regNo: this.data.vehicle.regNo,
        reason: this.data.vehicle.reason,
        description: this.data.vehicle.description,
        country: this.countries.find(c => c.id === this.data.vehicle?.country) || this.countries[0],
        workProhibited: this.blockTypes.find(b => b.id === this.data.vehicle?.workProhibited) || this.blockTypes[0],
      };

      this.form.setValue(value);
    }
  }

  public get registrationNumberPlaceholder() {
    const country = this.form.controls.country.value?.id;
    return country ? this.registrationNumberPlaceholderConfig[country] : 'A 123 AA 123';
  }

  public get vehiclePassport() {
    const country = this.form.controls.country.value?.id;
    return country ? this.regExpConf.ngxMask.vehiclePassport[country.toLowerCase()] : this.regExpConf.ngxMask.vehiclePassport.ru;
  }

  public send() {
    if (this.form.valid) {
      const formData = this.form.getRawValue();
      const res = {
        reason: formData.reason,
        description: formData.description,
        workProhibited: formData.workProhibited.id,
        regNo: formData?.regNo,
        country: formData?.country.id,
        vehicleType: this.data.vehicle?.vehicleType || null,
        startDate: moment().toDate(),
      };

      this.dialogRef?.close(res);
    }
  }

  public cancel() {
    this.dialogRef?.close();
  }

  public validationErrors(e: ValidationErrors | null) {
    if (e?.required) return this.translate.instant('shared.errors.required');

    return '';
  }
}
