import { createReducer, on } from '@ngrx/store';
import { transport } from '@transport/proto';
import {
  FREIGHT_ERROR,
  ICargoPackagingType,
  IOrder,
  IOrderAttachment,
  ORDER_STATUS,
  orderAttachmentFromDto,
  VEHICLE_TYPE_PROPERTY,
} from '@transport/ui-interfaces';

import * as OrderDetailsCarrierActions from '../actions/order-details-carrier.actions';
import * as OrderDocumentsCarrierActions from '../actions/order-documents-carrier.actions';

export const orderDetailsCarrierFeatureName = 'orderDetailsCarrier';

export interface IOrderDetailsCarrierState {
  allDocumentTypes: transport.IOrderDocumentType[];
  signDocumentType: transport.IOrderDocumentType;
  contractDocumentType: transport.IOrderDocumentType;
  // TODO пока оставить этот комменарий, пиригодится при реализации документов в профиле
  // documentsChanges: TnEntityChangesList<IOrderAttachment>;
  displayedDocuments: IOrderAttachment[];
  order: IOrder | null;
  selectedDriver: transport.IDriver | null | undefined;
  selectedSecondDriver: transport.IDriver | null | undefined;
  selectedVehicle: transport.IVehicle | null | undefined;
  selectedAgentCarrier: transport.IAgentCarrier | null | undefined;
  attractedTruck: VEHICLE_TYPE_PROPERTY | null;
  freight: string | null | undefined;
  freightError: string | FREIGHT_ERROR;
  cargoBodyTypes: transport.Vehicle.Body.IType[];
  packagingTypes: ICargoPackagingType[];
  isLoading: boolean;
  isFirstLoading: boolean;
  isFormDirty: boolean;
  blackListDriver: transport.Driver.IBlackListDriver[] | null;
  blackListVehicle: transport.Vehicle.IBlackListVehicle[] | null;
  carrierContracts: transport.Order.ICarrierContract[];
}

const initialState: IOrderDetailsCarrierState = {
  allDocumentTypes: [],
  signDocumentType: new transport.OrderDocumentType(),
  contractDocumentType: new transport.OrderDocumentType(),
  // TODO пока оставить этот комменарий, пиригодится при реализации документов в профиле
  // documentsChanges: new TnEntityChangesList<IOrderAttachment>(),
  displayedDocuments: [],
  order: null, // createNewOrder(),
  selectedDriver: null,
  selectedSecondDriver: null,
  selectedVehicle: null,
  selectedAgentCarrier: null,
  attractedTruck: null,
  freight: null,
  freightError: '',
  cargoBodyTypes: [],
  packagingTypes: [],
  isLoading: false,
  isFirstLoading: false,
  isFormDirty: false,
  blackListDriver: null,
  blackListVehicle: null,
  carrierContracts: [],
};

const createOrderReducer = createReducer(
  initialState,

  on(OrderDocumentsCarrierActions.loadAllDocumentTypesSuccess, (state, { documentTypes }) => ({
    ...state,
    allDocumentTypes: documentTypes.otherTypes,
    signDocumentType: documentTypes.signType,
    contractDocumentType: documentTypes.contractType,
  })),

  on(OrderDocumentsCarrierActions.loadDocumentsSuccess, (state, { documents }) => ({
    ...state,
    displayedDocuments: documents,
  })),

  on(OrderDocumentsCarrierActions.addDocumentsSuccess, (state, { documents }) => ({
    ...state,
    displayedDocuments: [...state.displayedDocuments, ...documents],
  })),

  on(OrderDetailsCarrierActions.loadOrderDetailsStart, state => ({
    ...state,
    isLoading: true,
    isFirstLoading: true,
  })),

  on(
    OrderDetailsCarrierActions.refreshOrderStart,
    OrderDocumentsCarrierActions.uploadContractStart,
    OrderDocumentsCarrierActions.uploadSignStart,
    state => ({
      ...state,
      isLoading: true,
    }),
  ),

  on(
    OrderDetailsCarrierActions.loadOrderSuccess,
    OrderDetailsCarrierActions.updateOrderSuccess,
    OrderDetailsCarrierActions.unreserveTransportSuccess,
    (state, { order }) => {
      return {
        ...state,
        order: { ...state.order, ...order },
        displayedDocuments: order.uploadedDocuments?.map(orderAttachmentFromDto) ?? [],
        selectedDriver: order.driver,
        selectedSecondDriver: order.coDriver,
        selectedVehicle: order.vehicle,
        isLoading: false,
        isFirstLoading: false,
      };
    },
  ),

  on(OrderDetailsCarrierActions.loadOrderDetailsSuccess, (state, { order, cargoBodyTypes, packagingTypes }) => {
    return {
      ...state,
      order: { ...state.order, ...order },
      cargoBodyTypes,
      packagingTypes,
      displayedDocuments: order.uploadedDocuments?.map(orderAttachmentFromDto) ?? [],
      selectedDriver: order.driver,
      selectedSecondDriver: order.coDriver,
      selectedVehicle: order.vehicle,
      isLoading: false,
      isFirstLoading: false,
    };
  }),

  on(OrderDetailsCarrierActions.loadOrderFailure, state => {
    return {
      ...state,
      order: null,
      isLoading: false,
    };
  }),

  on(OrderDetailsCarrierActions.clearDetailState, state => {
    return {
      ...state,
      ...initialState,
    };
  }),

  on(OrderDetailsCarrierActions.markOrderAsCancelled, state => {
    return {
      ...state,
      order: {
        ...state.order,
        status: ORDER_STATUS.CANCELED,
        statusOnEvent: ORDER_STATUS.CANCELED,
      } as IOrder,
    };
  }),

  on(OrderDocumentsCarrierActions.removeDocumentSuccess, (state, { documentId }) => {
    const res = {
      ...state,
      displayedDocuments: [...state.displayedDocuments.filter(n => n.id !== documentId)],
      // TODO пока оставить этот комменарий, пиригодится при реализации документов в профиле
      // documentsChanges: state.documentsChanges.delete(document),
    };
    return res;
  }),

  on(OrderDocumentsCarrierActions.addSignToDocumentSuccess, (state, result) => {
    const res = {
      ...state,
      displayedDocuments: [...state.displayedDocuments, result.sign],
    };
    return res;
  }),

  on(OrderDetailsCarrierActions.selectDriver, (state, result) => {
    const res = {
      ...state,
      selectedDriver: result.driver,
      blackListDriver: null,
      blackListVehicle: null,
    };
    return res;
  }),

  on(OrderDetailsCarrierActions.selectSecondDriver, (state, result) => {
    const res = {
      ...state,
      selectedSecondDriver: result.driver,
      order: { ...state.order, coDriver: { ...result.driver } } as IOrder,
      isLoading: false,
    };
    return res;
  }),

  on(OrderDetailsCarrierActions.clearDriver, state => {
    const res = {
      ...state,
      selectedDriver: null,
      blackListVehicle: null,
    };
    return res;
  }),

  on(OrderDetailsCarrierActions.selectVehicle, (state, result) => {
    const res = {
      ...state,
      selectedVehicle: result.vehicle,
      blackListVehicle: null,
      blackListDriver: null,
    };
    return res;
  }),

  on(OrderDetailsCarrierActions.clearVehicle, state => {
    const res = {
      ...state,
      selectedVehicle: null,
      blackListDriver: null,
    };
    return res;
  }),

  on(OrderDetailsCarrierActions.selectAgentCarrier, (state, result) => {
    const res = {
      ...state,
      selectedAgentCarrier: result.agentCarrier,
    };
    return res;
  }),

  on(OrderDetailsCarrierActions.selectAttractedTruck, (state, result) => {
    const res = {
      ...state,
      attractedTruck: result.attracted,
    };
    return res;
  }),

  on(OrderDetailsCarrierActions.setFreight, (state, result) => {
    const res = {
      ...state,
      freight: result.freight,
    };
    return res;
  }),

  on(OrderDetailsCarrierActions.setFreightError, (state, result) => {
    const res = {
      ...state,
      freightError: result.error,
    };
    return res;
  }),

  on(OrderDetailsCarrierActions.setBlackListForDriver, (state, { list }) => {
    return {
      ...state,
      blackListDriver: [...(list ?? [])],
    };
  }),

  on(OrderDetailsCarrierActions.setBlackListForVehicle, (state, { list }) => {
    return {
      ...state,
      blackListVehicle: [...(list ?? [])],
    };
  }),

  on(
    OrderDetailsCarrierActions.acceptOrder,
    OrderDetailsCarrierActions.cancelOrder,
    OrderDetailsCarrierActions.completeOrder,
    OrderDetailsCarrierActions.letsGo,
    OrderDetailsCarrierActions.saveInfoForContractStart,
    OrderDetailsCarrierActions.unreserveTransportStart,
    OrderDetailsCarrierActions.selectSecondDriverStart,
    state => {
      const res = {
        ...state,
        isLoading: true,
      };
      return res;
    },
  ),

  on(OrderDetailsCarrierActions.setOrder, OrderDetailsCarrierActions.saveInfoForContractSuccess, (state, result) => {
    const res = {
      ...state,
      order: result.order,
      selectedDriver: result.order.driver,
      selectedSecondDriver: result.order.coDriver,
      selectedVehicle: result.order.vehicle,
      displayedDocuments: result.order.uploadedDocuments?.map(orderAttachmentFromDto) ?? [],
      isLoading: false,
    };
    return res;
  }),

  on(
    OrderDetailsCarrierActions.saveInfoForContractFailure,
    OrderDetailsCarrierActions.validateContractForm,
    OrderDocumentsCarrierActions.uploadSignSuccess,
    OrderDocumentsCarrierActions.addDocumentsSuccess,
    OrderDetailsCarrierActions.sameDrivers,
    state => ({
      ...state,
      isLoading: false,
    }),
  ),

  on(OrderDetailsCarrierActions.updateContractStatus, (state, result) => {
    const res = {
      ...state,
      order: { ...state.order, ...result.orderPart } as IOrder,
      displayedDocuments: state.order?.uploadedDocuments?.map(orderAttachmentFromDto) ?? [],
      isLoading: false,
    };

    return res;
  }),

  on(OrderDetailsCarrierActions.addInsuranceForOrderSuccess, (state, result) => {
    return {
      ...state,
      order: {
        ...state.order,
        insurance: result.insurance,
      } as IOrder,
      displayedDocuments: state.order?.uploadedDocuments?.map(orderAttachmentFromDto) ?? [],
    };
  }),

  on(OrderDetailsCarrierActions.setFormDirty, (state, { isDirty }) => ({
    ...state,
    isFormDirty: isDirty,
  })),

  on(OrderDetailsCarrierActions.changeOrderContract, (state, { contract }) => ({
    ...state,
    order: { ...state.order, carrierContract: contract } as IOrder,
  })),

  on(OrderDetailsCarrierActions.pageDestroy, state => {
    return {
      ...state,
      blackListDriver: null,
      blackListVehicle: null,
    };
  }),

  on(OrderDetailsCarrierActions.getCarrierContractsSuccess, (state, { contracts }) => {
    if (Boolean(state.order?.carrierContract) && !contracts.find(item => item.id === state.order?.carrierContract?.id)) {
      return {
        ...state,
        carrierContracts: [...contracts, { ...(state.order?.carrierContract as transport.Order.ICarrierContract) }],
      };
    }
    return {
      ...state,
      carrierContracts: [...contracts],
    };
  }),
);

export function orderDetailsCarrierReducer(state, action) {
  return createOrderReducer(state, action);
}
