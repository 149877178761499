import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectRouteData, selectRouteParams, selectUrl } from '../../../../ngrx-router/selectors/router.selectors';
import { IDirectoryState } from '../../../directory-state.interface';
import { ICarrierGroupState } from '../reducers/carriers-group.reducer';

const groupRouteFilter = /\/directory\/owner\/carriers\/groups\/([a-z]+\/[0-9]+){0,1}/;
/**
 * TODO if carrier groups will be in separate module delete this and just use selectRouteParam from ngrx router module!!!
 * Filters route params selector values using given URL regexp
 * @param param Parameter key
 * @param filter Regexp to filter route changes
 */
const selectRouteParamForRoute = <T = string>(param: string) =>
  createSelector(selectRouteParams, selectRouteData(param), selectUrl, (params, fromData, url) => {
    return groupRouteFilter.test(url) ? (Boolean(params) && (params[param] as T)) || fromData : false;
  });
const getGroupId = selectRouteParamForRoute('id');
const getGroupMode = selectRouteParamForRoute('action');
const getDirectoryState = createFeatureSelector<IDirectoryState>('directory');
const getGroupState = createSelector(getDirectoryState, state => state.carrierGroup);
const getGroup = createSelector(getGroupState, (state: ICarrierGroupState) => state.group);

const getLoading = createSelector(getGroupState, (state: ICarrierGroupState) => state.loading);

const getIsFormValid = createSelector(getGroupState, state => state.isFormValid);
const getIsFormDisabled = createSelector(getGroupState, state => state.isFormDisabled);
const getIsFormDirty = createSelector(getGroupState, state => state.isFormDirty);

const getFormValue = createSelector(getGroupState, state => state.formValue);

export const carrierGroupSelectors = {
  getGroupId,
  getGroupMode,
  getGroup,
  getLoading,
  getIsFormValid,
  getIsFormDisabled,
  getIsFormDirty,
  getFormValue,
};
