import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HTTP_ERROR_CODE } from '@transport/ui-interfaces';
import { snakeCaseToCamelCase } from '@transport/ui-utils';
import { GraphQLError } from 'graphql';

import { TnToasterService } from './toaster.service';

export enum GRAPH_QL_ERROR_CODES {
  INVALID_PARAM = 'INVALID_PARAM',
  OBJECT_NOT_FOUND = 'OBJECT_NOT_FOUND',
  INVALID_OBJECT_STATE_POSITION = 'INVALID_OBJECT_STATE_POSITION',
  OBJECT_ACCESS_FORBIDDEN = 'OBJECT_ACCESS_FORBIDDEN',
  INVALID_OBJECT_STATE_TRANSITION = 'INVALID_OBJECT_STATE_TRANSITION',
}

export enum GRAPH_QL_ERROR_CATEGORIES {
  DUPLICATE = 'DUPLICATE',
  EXPIRED = 'EXPIRED',
  USER_HAS_DIGITAL_PROFILE = 'USER_HAS_DIGITAL_PROFILE',
}

export enum GRAPH_QL_ERROR_PARAMS {
  EMAIL = 'email',
  PHONE_NUMBER = 'phoneNumber',
}

const TimeoutError = 'TimeoutError';

// TODO: tech deb after new registration flow
@Injectable({
  providedIn: 'root',
})
export class TnGlobalErrorHandlerService implements ErrorHandler {
  constructor(private readonly translate: TranslateService, private readonly toaster: TnToasterService) {}

  public handleError(error) {
    if (this.isGqlError(error) || this.isTimeoutError(error)) {
      const resultMessage = this.isGqlError(error)
        ? this.extractGqlErrorMessage(error)
        : this.translate.instant('shared.errors.timeoutError');
      this.toaster.showToaster(resultMessage, 'error');
    } else {
      const { networkError } = error ?? {};
      const errorMessage =
        (networkError as HttpErrorResponse)?.status === HTTP_ERROR_CODE.FORBIDDEN
          ? this.translate.instant('shared.errors.permissionOperation')
          : this.translate.instant('shared.errors.unknown');
      // TODO: tech deb after new registration flow (Проблема с 2 легаси приложениями, которые не используются)
      if (errorMessage !== this.translate.instant('shared.errors.unknown')) {
        this.toaster.showToaster(errorMessage, 'error');
      }
      // eslint-disable-next-line no-console -- need it here to log error to console
      console.error(error);
    }
  }

  public extractGqlErrorMessage(gqlErrorL: GraphQLError): string {
    const errorCode = gqlErrorL.extensions?.code;
    switch (errorCode) {
      case GRAPH_QL_ERROR_CODES.INVALID_PARAM:
      case GRAPH_QL_ERROR_CODES.OBJECT_ACCESS_FORBIDDEN:
      case GRAPH_QL_ERROR_CODES.OBJECT_NOT_FOUND:
      case GRAPH_QL_ERROR_CODES.INVALID_OBJECT_STATE_POSITION:
      case GRAPH_QL_ERROR_CODES.INVALID_OBJECT_STATE_TRANSITION:
        return this.translate.instant(`shared.request.error.${snakeCaseToCamelCase(errorCode)}`);
      default:
        return gqlErrorL.message;
    }
  }

  private isGqlError(error: GraphQLError): boolean {
    return Boolean(error?.extensions?.code);
  }

  private isTimeoutError(error: Error): boolean {
    return Boolean(error.name) && error.name === TimeoutError;
  }
}
