import { createAction, props } from '@ngrx/store';
import { transport } from '@transport/proto';
import { IAgentCarrierEditInput, IAgentCarrierInput, LEGAL_FORM_CODES } from '@transport/ui-interfaces';

import { IOrganizationData } from '../../../../profile/organization/organization.form';

const getAgentCarrier = createAction('Get AgentCarrier', props<{ id?: string }>());
const getAgentCarrierSuccess = createAction('Get AgentCarrier Success', props<{ agent: transport.IAgentCarrier | null }>());
const getAgentCarrierFailure = createAction('Get AgentCarrier Failure', props<{ error: Error }>());

const removeAgentCarrier = createAction('Remove AgentCarrier', props<{ id: string }>());
const removeAgentCarrierSuccess = createAction('Remove AgentCarrier Success');
const removeAgentCarrierFailure = createAction('Remove AgentCarrier Failure', props<{ error: Error }>());

const addAgentCarrier = createAction('Add AgentCarrier', props<{ agentCarrier: IAgentCarrierInput }>());
const addAgentCarrierSuccess = createAction('Add AgentCarrier Success');
const addAgentCarrierFailure = createAction('Add AgentCarrier Failure', props<{ error: Error }>());

const editAgentCarrier = createAction('Edit AgentCarrier', props<{ agentCarrier: IAgentCarrierEditInput }>());
const editAgentCarrierSuccess = createAction('Edit AgentCarrier Success');
const editAgentCarrierFailure = createAction('Edit AgentCarrier Failure', props<{ error: Error }>());

const getLegalForms = createAction('Get LegalForms');
const getLegalFormsSuccess = createAction('Get LegalForms Success', props<{ legalForms: transport.ILegalForm[] }>());

const getOrganizationInfo = createAction(
  'Get Organization Info',
  props<{ query: string; legalFormCode: LEGAL_FORM_CODES; carrierName: string }>(),
);
const getOrganizationInfoSuccess = createAction('Get Organization Info Success', props<{ company: IOrganizationData }>());
const getOrganizationInfoFailure = createAction('Get Organization Info Failure', props<{ organizationSearchError: string }>());

const clearOrganizationError = createAction('Clear Organization Error');

export const agentCarrierActions = {
  getAgentCarrier,
  getAgentCarrierSuccess,
  getAgentCarrierFailure,
  removeAgentCarrier,
  removeAgentCarrierSuccess,
  removeAgentCarrierFailure,
  addAgentCarrier,
  addAgentCarrierSuccess,
  addAgentCarrierFailure,
  editAgentCarrier,
  editAgentCarrierSuccess,
  editAgentCarrierFailure,
  getLegalForms,
  getLegalFormsSuccess,
  getOrganizationInfo,
  getOrganizationInfoSuccess,
  getOrganizationInfoFailure,
  clearOrganizationError,
};
