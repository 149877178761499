import { Inject, Injectable } from '@angular/core';
import { HammerGestureConfig } from '@angular/platform-browser';
import { touchDevice } from '@transport/ui-utils';

@Injectable({
  providedIn: 'root',
})
export class TnHammerGestureConfig extends HammerGestureConfig {
  public overrides: Record<string, Record<string, number | boolean>> = {
    pan: {
      enable: false,
      pointers: 0, // Required pointers. 0 for all pointers.
      threshold: 10, // Minimal pan distance required before recognizing.
    },
    pinch: {
      enable: false,
      pointers: 2, // Required pointers, with a minimal of 2.
      threshold: 0, // Minimal scale before recognizing.
    },
    press: {
      enable: false,
      pointers: 1, // Required pointers.
      threshold: 9, // Minimal movement that is allowed while pressing.
      time: 251, // Minimal press time in ms.
    },
    tap: {
      enable: false,
      pointers: 1, // Required pointers.
      taps: 1, // Amount of taps required.
      interval: 300, // Maximum time in ms between multiple taps.
      time: 250, // Maximum press time in ms.
      threshold: 2, // While doing a tap some small movement is allowed.
      posThreshold: 10, // The maximum position difference between multiple taps.
    },
    rotate: {
      enable: false,
      pointers: 2, // Required pointers, with a minimal of 2.
      threshold: 0, // Minimal rotation before recognizing.
    },
    swipe: {
      enable: true,
      pointers: 1, // Required pointers.
      velocity: 0.4, // Minimal velocity required before recognizing, unit is in px per ms.
      threshold: 20, // Minimal distance required before recognizing.
    },
  };

  constructor() {
    super();
    const touch = touchDevice();
    const keys = Object.keys(this.overrides);
    this.overrides = keys.reduce((accumulator: Record<string, Record<string, number | boolean>>, key) => {
      const enabledGestures = ['swipe'];
      const override = {
        ...this.overrides[key],
        enable: enabledGestures.includes(key) ? touch : false,
      };
      accumulator[key] = override;
      return accumulator;
    }, {});
  }
}
