<div class="relative">
  <mat-progress-bar *ngIf="driverTableService.loading$ | async" mode="indeterminate" class="!absolute"></mat-progress-bar>
</div>
<div class="overflow-auto">
  <marketplace-table
    class="ui-kit drivers-table"
    [dataSource]="driversDataSource$"
    [columnConfigs]="columnConfigs"
    (rowClick)="onRowClick($event)"
    [rowClasses]="rowClasses"
  >
    <marketplace-table-column columnName="driver">
      <ng-template let-row>
        <div class="flex items-center">
          <marketplace-text-column
            [title]='(row.surname ?? "") + " " + (row.name ?? "") + " " + (row.patronymic ?? "")'
            titleClass="text-gray-700 font-bold text-sm leading-5"
            [text]="row.phone | phoneFormat"
            textClass="text-gray-500 font-normal text-sm leading-5">
          </marketplace-text-column>
          <mat-icon *ngIf="row.blackListDriver?.message?.length"
                  transportTooltip
                  class="ml-3 !w-5 !h-5 desktop:!w-6 desktop:!h-6"
                  svgIcon="info-outline"
                  [ngClass]="{'!fill-orange-500': !row.blackListDriver.limitationType, '!fill-red-700': row.blackListDriver.limitationType}"
                  [containerClass]="'black-list-tooltip'"
                  [context]="{list: row.blackListDriver.message}"
                  [templateRef]="blackListMsg">
          </mat-icon>
        </div>
      </ng-template>
    </marketplace-table-column>

    <marketplace-table-column *ngIf="isAgentOrExpeditor" columnName="agentCarrier">
      <ng-template let-row>
        <div *ngIf="row.agentCarrier"
             class="text-gray-700 text-sm whitespace-nowrap overflow-hidden text-ellipsis max-w-[200px]"
             transportTooltip
             [context]="{ message: row.agentCarrier?.carrierName + ' (' + (row.agentCarrier?.inn || row.agentCarrier?.bin || row.agentCarrier?.iin) + ')'}">
             {{row.agentCarrier?.carrierName}}({{row.agentCarrier?.inn || row.agentCarrier?.bin || row.agentCarrier?.iin}})
        </div>
      </ng-template>
    </marketplace-table-column>

    <marketplace-table-column columnName="mobile_app">
      <ng-template let-row>
        <div class="whitespace-nowrap overflow-hidden text-ellipsis text-white">
          <span [ngClass]="[
          'text-xs font-bold leading-4 text-white rounded-sm py-px px-1 w-fit  text-ellipsis',
          row.usesDriverMobile ? 'bg-green-500' : 'bg-gray-500',
          ]"
           >{{((!row.hasBeenInvitedToDriverMobile && !row.usesDriverMobile) ? 'shared.mp.drivers.table.mobileStatuses.notInvited' : row.usesDriverMobile ? 'shared.mp.drivers.table.mobileStatuses.activated' : 'shared.mp.drivers.table.mobileStatuses.notActivated') | translate}}</span>
        </div>
      </ng-template>
    </marketplace-table-column>

    <marketplace-table-column columnName="accrStatus">
      <!-- <ng-template let-row>
        <marketplace-drivers-status-column [status]='row.accrStatus'></marketplace-drivers-status-column>
      </ng-template> -->
      <ng-template let-row>
        <div *ngIf="!row.isArchived">
          <div class="w-fit h-[18px] px-2 rounded-xl bg-gray-100 flex" *ngIf="row.accrStatus === 'new'">
            <span class="m-auto text-gray-700 font-medium text-xs leading-[14px] whitespace-nowrap">{{'shared.mp.vehicles.table.accreditation.notChecked' | translate}}</span>
          </div>

          <div class="w-fit h-[18px] px-2 rounded-xl bg-orange-100 flex" *ngIf="row.accrStatus === 'pending'">
            <span class="m-auto text-orange-700 font-medium text-xs leading-[14px] whitespace-nowrap">{{'shared.mp.vehicles.table.accreditation.onWork' | translate}}</span>
          </div>

          <div class="flex flex-col" *ngIf="row.accrStatus === 'passed'">
            <div class="w-fit h-[18px] px-2 rounded-xl bg-green-100 flex">
              <span class="m-auto text-green-700 font-medium text-xs leading-[14px] whitespace-nowrap">{{'shared.mp.vehicles.table.accreditation.approved' | translate}}</span>
            </div>
            <!-- <span class="text-gray-700 font-medium text-xs leading-4">Дата: 20.03.2020 13:30</span> -->
          </div>

          <div class="flex flex-col" *ngIf="row.accrStatus === 'not_passed'">
            <div class="w-fit h-[18px] px-2 rounded-xl bg-red-100 flex">
              <span class="m-auto text-red-700 font-medium text-xs leading-[14px] whitespace-nowrap">{{'shared.mp.vehicles.table.accreditation.redjected' | translate}}</span>
              <mat-icon transportTooltip
                        [context]="{ message: row.accrComment }"
                        class="text-red-700 !h-[14px] !w-[14px] !text-[14px] m-auto ml-1">
                        info
              </mat-icon>
            </div>
            <!-- <span class="text-gray-700 font-medium text-xs leading-4 whitespace-nowrap">Дата: 20.03.2020 13:30</span> -->
          </div>
        </div>
      </ng-template>
    </marketplace-table-column>

    <marketplace-table-column columnName="btns">
      <ng-template let-row>
        <div class="flex justify-end">
          <ng-container
            *ngIf="!row.isArchived"
            [ngTemplateOutlet]="rowActionsMenu"
            [ngTemplateOutletContext]="{ $implicit: row }"
          ></ng-container>
          <ng-container
            *ngIf="row.isArchived"
            [ngTemplateOutlet]="archivedRowActionsMenu"
            [ngTemplateOutletContext]="{ $implicit: row }"
          ></ng-container>
        </div>
      </ng-template>
    </marketplace-table-column>
  </marketplace-table>
</div>

<mat-paginator
  class="flex justify-center !border-t-0"
  [length]="(drivers$ | async)?.totalCount"
  [pageSize]="25"
  [pageIndex]="(driverTableService.store.pageInfo$ | async)?.pageIndex"
  [pageSizeOptions]="[25, 50, 100]"
  (page)="driverTableService.changePage($event)"
></mat-paginator>

<ng-template #rowActionsMenu let-driver>
  <button
    #menuTrigger="matMenuTrigger"
    class="!text-gray-500"
    style="width: unset; height: unset; line-height: unset;"
    mat-icon-button
    [matMenuTriggerFor]="menu"
    (mouseup)="$event.stopPropagation()"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu" [hasBackdrop]="true" class="drivers-table !shadow-base">
    <div class="flex flex-col">
      <button class="px-3 h-8 flex items-center gap-3 wb-white text-sm text-gray-700 hover:bg-gray-100 active:bg-gray-200"
              (click)="inviteDriver(driver)">
        <mat-icon class="mat-icon-md text-gray-500">phone_iphone</mat-icon>
        {{ 'shared.mp.drivers.table.actions.invite' | translate }}
      </button>
      <button class="px-3 h-8 flex items-center gap-3 wb-white text-sm text-gray-700 hover:bg-gray-100 active:bg-gray-200"
              (click)="onEdit($event, driver)">
        <mat-icon class="mat-icon-md text-gray-500">edit</mat-icon>
        {{ 'shared.mp.drivers.table.actions.edit' | translate }}
      </button>
      <button class="px-3 h-8 flex items-center gap-3 wb-white text-sm text-gray-700 hover:bg-gray-100 active:bg-gray-200"
              (click)="onDelete($event, driver)">
        <mat-icon class="mat-icon-md text-gray-500" svgIcon="delete-outlined"></mat-icon>
        {{ 'shared.mp.drivers.table.actions.delete' | translate }}
      </button>
    </div>
  </mat-menu>
</ng-template>
<ng-template #archivedRowActionsMenu let-driver>
  <button
    #archivedMenuTrigger="matMenuTrigger"
    class="!text-gray-500"
    style="width: unset; height: unset; line-height: unset;"
    mat-icon-button
    [matMenuTriggerFor]="archivedMenu"
    (mouseup)="$event.stopPropagation()"
  >
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #archivedMenu="matMenu" [hasBackdrop]="true" class="drivers-table !shadow-base">
    <div class="flex flex-col">
      <button
        class="px-3 h-8 flex items-center gap-3 wb-white text-sm text-gray-700 hover:bg-gray-100 active:bg-gray-200"
        (click)="restoreDriver(driver)"
      >
        <mat-icon class="mat-icon-md text-gray-500">restore</mat-icon>
        {{ 'shared.mp.drivers.table.actions.restore' | translate }}
      </button>
    </div>
  </mat-menu>
</ng-template>
<ng-template #blackListMsg let-list="list">
  <p *ngFor="let item of list; let i = index" class="text-xs">{{i+1}}. {{item?.cargoOwner?.name}}: {{(item?.workProhibited ? 'shared.mp.drivers.blackList.inBlackList' : 'shared.mp.drivers.blackList.hasWarning') | translate}}. {{item?.reason}}</p>
</ng-template>
