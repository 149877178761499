import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { first, map, switchMap, take } from 'rxjs/operators';

import { openConfirmation, SET_CONFIRMATION_CHOICE, setConfirmationChoice } from '../../confirmation/confirmation.actions';

@Injectable({
  providedIn: 'root',
})
export class TnDirtyFormCanDeactivateGuard implements CanDeactivate<IDirtyCheckComponent> {
  constructor(private readonly store: Store, private readonly actions$: Actions) {}

  public canDeactivate(component: IDirtyCheckComponent): Observable<boolean> {
    if (!Boolean(component) || !Boolean(component.isDirty$)) {
      return of(true);
    }
    return component.isDirty$.pipe(
      take(1),
      switchMap(dirty => {
        if (!dirty) {
          return of(true);
        }
        this.store.dispatch(openConfirmation({ payload: { openByPrefix: 'shared.dirtyFormConfirmationDialog' } }));
        return this.actions$.pipe(
          ofType<ReturnType<typeof setConfirmationChoice>>(SET_CONFIRMATION_CHOICE),
          first(),
          map(action => action.payload.confirmClosure),
        );
      }),
    );
  }
}

export interface IDirtyCheckComponent {
  isDirty$: Observable<boolean>;
}
