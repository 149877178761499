export interface IConstantsState {
  timestamp: number;
  support: {
    phone?: string;
    email?: string;
  };
}

export const constantsInitialState: IConstantsState = {
  timestamp: new Date().getTime(),
  support: {
    phone: '',
    email: '',
  },
};
