import { createReducer, on } from '@ngrx/store';
import { transport } from '@transport/proto';
import { IDriver } from '@transport/ui-interfaces';

import * as actions from '../actions/driver.actions';
import { setSelectedAgentCarrier } from '../actions/driver.actions';

export interface IDriverState {
  id: string | null;
  changedDriverStatus: Partial<IDriver> | null;
  agentCarriers: transport.IAgentCarrier[];
}

export const initialState: IDriverState = {
  id: null,
  changedDriverStatus: null,
  agentCarriers: [],
};

const driverReducerFunc = createReducer(
  initialState,
  on(actions.setDriverIdAction, (state, { id }) => {
    return {
      ...state,
      id,
    };
  }),
  on(actions.setChangedDriverStatus, (state, action) => {
    return {
      ...state,
      changedDriverStatus: action.changedDriverStatus,
    };
  }),
  on(actions.searchAgentCarrierSuccess, setSelectedAgentCarrier, (state, { agentCarriers }) => {
    return {
      ...state,
      agentCarriers: [...agentCarriers],
    };
  }),
);

export function driverReducer(state, action) {
  return driverReducerFunc(state, action);
}
