import { ValidatorFn, Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { regExpConfig } from '@transport/ui-utils';

import { conditionalValidator } from '../form-validators/forms';

export interface ISignupFormData {
  firstName: string;
  lastName: string;
  companyName?: string;
  email: string;
  role?: number;
  phone: string;
  inn: string;
  useTs: boolean;
  useSignature: boolean;
  personalData: boolean;
}

const maxFioSize = 30;

const isCorpRole = (formControl: FormControl) => {
  return !Boolean(formControl.parent?.get('role')?.value);
};

export function newSignupForm(): FormGroup<ISignupFormData> {
  return new FormGroup<ISignupFormData>({
    firstName: new FormControl<string>({ value: '', disabled: true }, [Validators.required, Validators.maxLength(maxFioSize)]),
    lastName: new FormControl<string>({ value: '', disabled: true }, [Validators.required, Validators.maxLength(maxFioSize)]),
    companyName: new FormControl<string>({ value: '', disabled: true }, Validators.required),
    inn: new FormControl<string>('', [
      Validators.required,
      conditionalValidator((formControl: FormControl) => {
        const res = isCorpRole(formControl);
        return typeof res === 'boolean' && res;
      }, Validators.pattern(regExpConfig.inn.RU)) as ValidatorFn,
      conditionalValidator((formControl: FormControl) => {
        const res = isCorpRole(formControl);
        return typeof res === 'boolean' && !res;
      }, Validators.pattern(regExpConfig.innPhysOrIp.RU)) as ValidatorFn,
    ]),
    email: new FormControl<string>({ value: '', disabled: true }, [Validators.required, Validators.email]),
    phone: new FormControl<string>({ value: '', disabled: true }, Validators.pattern(regExpConfig.phoneNumber.RU)),
    role: new FormControl<number>(0, [Validators.required]),
    useTs: new FormControl<boolean>(false, Validators.requiredTrue),
    useSignature: new FormControl<boolean>(false),
    personalData: new FormControl<boolean>(false, Validators.requiredTrue),
  });
}
