import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { transport } from '@transport/proto';
import { TIMEOUT } from '@transport/ui-utils';
import { of } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, switchMap, tap } from 'rxjs/operators';

import { TnConfirmationService } from '../../../../confirmation/confirmation.service';
import { TnToasterFacade, TOAST_TYPE } from '../../../../toaster/toaster.facade';
import { TnCarrierDirectoryApiService } from '../../carrier-directory-api.service';
import {
  removeTruck,
  removeTruckFailure,
  removeTruckSuccess,
  searchAgentCarrier,
  searchAgentCarrierSuccess,
  setChangedTruckStatus,
} from '../actions/truck.actions';
import { TnTruckFacade } from '../truck-state.facade';
import { TnTruckBaseEffects } from './truck-base.effects';

@Injectable({
  providedIn: 'root',
})
export class TnTruckEffects extends TnTruckBaseEffects {
  public operationFail = createEffect(() =>
    this.actions$.pipe(
      ofType(removeTruckFailure),
      tap(({ error }) => {
        //retrow error for handling by global error handler
        throw error;
      }),
    ),
  );

  public readonly detailRemoveTruck = createEffect(() =>
    this.actions$.pipe(
      ofType(removeTruck),
      switchMap(({ id }) =>
        super.removeTruck(id).pipe(
          map(() => {
            this.toastFacade.showMessage('shared.directory.trucks.message.remove.success', TOAST_TYPE.SUCCESS);
            return removeTruckSuccess();
          }),
          catchError((error: Error) => {
            return of(removeTruckFailure({ error }));
          }),
        ),
      ),
    ),
  );

  // public readonly changeVerificationStatus = createEffect(() =>
  //   this.actions$.pipe(
  //     ofWebsocketTopicAndAction('vehicle', ['verification_status_changed']),
  //     switchMap(storeAction => {
  //       // eslint-disable-next-line @typescript-eslint/naming-convention -- TODO: tech debt
  //       const { id, verification_status } = storeAction.eventData.payload;
  //       return of(
  //         setChangedTruckStatus({
  //           changedTruckStatus: {
  //             id,
  //             verificationStatus: verification_status,
  //           },
  //         }),
  //       );
  //     }),
  //   ),
  // );

  public readonly searchAgentCarrier = createEffect(() =>
    this.actions$.pipe(
      ofType(searchAgentCarrier),
      map(({ term }) => term),
      distinctUntilChanged(),
      debounceTime(TIMEOUT.SHORT),
      switchMap(term => {
        return this.truckFacade
          .getAgentCarriers(term)
          .pipe(map((agentCarriers: transport.IAgentCarrier[]) => searchAgentCarrierSuccess({ agentCarriers })));
      }),
    ),
  );

  constructor(
    public readonly actions$: Actions,
    public readonly confirmation: TnConfirmationService,
    public readonly carrierDirectoryService: TnCarrierDirectoryApiService,
    public readonly toastFacade: TnToasterFacade,
    private readonly truckFacade: TnTruckFacade,
  ) {
    super(actions$, confirmation, carrierDirectoryService);
  }
}
