import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TnConnectFormDirective } from './directives/connect/connect-form.directive';
import { TnFormEffects } from './form.effects';
import { formReducer } from './form.reducers';

const FORM_EFFECTS = [TnFormEffects];

@NgModule({
  declarations: [TnConnectFormDirective],
  imports: [StoreModule.forFeature('form', formReducer), EffectsModule.forFeature(FORM_EFFECTS)],
  exports: [StoreModule, EffectsModule, TnConnectFormDirective],
})
export class TnFormModule {}
