import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { ngrxThrow401, ngrxThrow404 } from '../actions';
import { INgrxErrorState } from '../reducer/ngrx-error.reducer';

/**
 * NgRx error facade.
 */
@Injectable({
  providedIn: 'root',
})
export class TnNgrxErrorFacade {
  constructor(private readonly store: Store<INgrxErrorState>) {}

  public throw401Error(error: HttpErrorResponse) {
    this.store.dispatch(ngrxThrow401({ payload: error }));
  }

  public throw404Error(error: HttpErrorResponse) {
    this.store.dispatch(ngrxThrow404({ payload: error }));
  }
}
