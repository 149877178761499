import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'transport-tw-menu-option',
  templateUrl: './tw-menu-option.component.html',
  styleUrls: ['./tw-menu-option.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnTwMenuOptionComponent implements OnChanges, OnInit {
  @Input() public variant: 'option' | 'active' | 'button' | 'title' | 'center' = 'option';

  @Input() public size: 'sm' | 'md' | 'lg' | null = null;

  @Input() public text = '';

  @Input() public additionalText = '';
  @Input() public additionalTextRight: string | undefined = undefined;

  @Output() public readonly action = new EventEmitter<void>();

  public class = '';

  ngOnInit() {
    this.class = this.calculateClass();
  }

  ngOnChanges() {
    this.class = this.calculateClass();
  }

  private calculateClass() {
    const { variant } = this;
    switch (variant) {
      case 'option':
        return 'tw-menu-item';
      case 'center':
        return 'tw-menu-center';
      case 'title':
        return 'tw-menu-title';
      default:
        return 'tw-menu-item';
    }
  }
}
