import { Component, EventEmitter, Input, NgZone, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'transport-add-selector-with-template-options',
  templateUrl: './add-selector-with-template-options.component.html',
  styleUrls: ['./add-selector-with-template-options.component.scss'],
})
export class AddSelectorWithTemplateOptionsComponent implements OnInit {
  @Input() public data = [];

  @Input() public placeholder = '';

  @Input() public bindLabel = '';

  @Input() public bindValue = '';

  @Input() public dataTest = '';

  @Input() public required = false;

  @Input() public labelInside = false;

  @Input()
  public control!: FormControl;

  @Output()
  public readonly addClicked = new EventEmitter();

  public visibleData = [];

  @ViewChild('searchRef', { static: false }) searchRef;

  @ViewChild(MatAutocompleteTrigger) trigger!: MatAutocompleteTrigger;

  constructor(private translate: TranslateService, private zone: NgZone) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.visibleData = this.data;
    }
  }

  public ngOnInit(): void {
    this.visibleData = this.data;
    this.control.setValidators([Validators.required]);
    this.control.valueChanges.subscribe(id => {
      if (id) {
        this.setValueInput(id);
      }
    });
  }

  public ngAfterViewInit() {
    if (Boolean(this.control.value)) {
      this.setValueInput(this.control.value);
    }
  }

  public inputFocused = false;

  public setValueInput(value) {
    const selectedObj = this.data.find(el => el[this.bindValue] === value);
    if (selectedObj) {
      this.searchRef.nativeElement.value = selectedObj[this.bindLabel];
      this.searchRef.nativeElement.blur();
    }
  }

  public displayFn(value?: number) {
    return Boolean(value) ? this.getLabel(this.data.find(elem => this.getValue(elem) === value)) : '';
  }

  public addItem() {
    this.trigger.closePanel();
    this.searchRef.nativeElement.blur();
    this.addClicked.emit();
  }

  public getLabel(item) {
    return item[this.bindLabel];
  }

  public getValue(item) {
    return item[this.bindValue];
  }

  private lastSelectedValue?: string;

  public onItemSelected(event) {
    this.lastSelectedValue = event.option.value;
    this.control.setValue(event.option.value);
  }

  public isSelected(item) {
    return this.getValue(item) === this.lastSelectedValue;
  }

  public onBlur() {
    this.control.markAsTouched();
    this.inputFocused = false;
    if (this.lastSelectedValue) {
      const option = this.data.find(d => d[this.bindValue] === this.lastSelectedValue);
      if (option) {
        this.searchRef.nativeElement.value = option[this.bindLabel];
      }
    }
  }

  public searching(event) {
    const value = event.target.value.toLowerCase();
    this.visibleData = this.data.filter(elem => this.getLabel(elem).toLowerCase().includes(value));
  }

  public clickAtInsideLabel() {
    this.searchRef.nativeElement.focus();
    // It doesn't want to open autocomplete panel at the same time with focus event.
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.trigger.openPanel();
      }, 0);
    });
  }

  public errorToMessage = (errors: ValidationErrors | null) => {
    if (errors?.required) {
      return this.translate.instant('shared.errors.required');
    }
    return '';
  };
}
