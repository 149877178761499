<form *ngIf="form" class="flex flex-col" [formGroup]="form">
  <transport-tw-input-field class="w-36" errorsSize="xs" [control]="form" [errorToMessage]="errorToMessage" label="{{'shared.auctionSettings.auctionStep.title' | translate}}">
    <transport-tw-input-with-select [selectControl]="$any(form.controls.typeOfPriceStep)" [formGroup]="form" [options]="options">
      <ng-container *ngIf="typeOption$ | async as typeOption">
        <ng-container [ngSwitch]="typeOption">
          <input class="tw-input-element text-right"
                 *ngSwitchCase="'FIXED'"
                 [formControl]="$any(form.controls.minStepPrice)"
                 transportNumberOnly
                 [onlyIntegers]="true"
          >
          <input class="tw-input-element text-right"
                 *ngSwitchCase="'PERCENT'"
                 [formControl]="$any(form.controls.minPriceStepPercent)"
                 transportNumberOnly
                 lengthAfterSeparator="1"
          >
        </ng-container>
      </ng-container>
    </transport-tw-input-with-select>
  </transport-tw-input-field>
</form>
