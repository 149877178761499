import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { createNewAdminOrganization } from '@transport/ui-interfaces';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { TnConfirmationService } from '../../confirmation/confirmation.service';
import { RouterActions } from '../../ngrx-router/actions/router.actions';
import { TnToasterFacade, TOAST_TYPE } from '../../toaster';
import { TnSubOrganizationsApiService } from '../admin/services/sub-organizations-api.service';
import { subOrganizationProfileActions } from './sub-organization-profile.actions';
import { TnSubOrganizationProfileFacade } from './sub-organization-profile.facade';

@Injectable({
  providedIn: 'root',
})
export class TnSubOrganizationProfileEffects {
  public loadSubOrganizationProfile = createEffect(() =>
    this.action$.pipe(
      ofType(subOrganizationProfileActions.loadSubOrganizationProfileStart),
      switchMap(() => combineLatest([this.facade.editableOrganizationProfileId$, this.facade.formMode$])),
      filter(([id, mode]) => Boolean(id) || mode === 'create'),
      switchMap(([id, mode]) => {
        if (mode === 'create') {
          return of(
            subOrganizationProfileActions.loadSubOrganizationProfileSuccess({
              profile: createNewAdminOrganization(),
            }),
          );
        }
        return this.subOrganizationsService.getSubOrganization(id).pipe(
          map(result => {
            return subOrganizationProfileActions.loadSubOrganizationProfileSuccess({
              profile: result,
            });
          }),
          catchError(error => of(subOrganizationProfileActions.loadSubOrganizationProfileFailure({ error }))),
        );
      }),
    ),
  );

  public saveSubOrganizationProfile = createEffect(() =>
    this.action$.pipe(
      ofType(subOrganizationProfileActions.saveSubOrganizationProfileStart),
      switchMap(({ formValue }) => of(formValue).pipe(withLatestFrom(this.facade.formMode$, this.facade.reactiveFormValue$))),
      switchMap(([formValue, formMode, form]) => {
        const saveFunction =
          formMode === 'edit'
            ? this.subOrganizationsService.editSubOrganization({ ...form, ...formValue })
            : this.subOrganizationsService.addSubOrganization(formValue);

        return saveFunction.pipe(
          map(result => {
            this.toastFacade.showMessage('owner.profile.message.saveProfileSuccess', TOAST_TYPE.SUCCESS);
            return subOrganizationProfileActions.saveSubOrganizationProfileSuccess({
              profile: result,
            });
          }),
          catchError(error => of(subOrganizationProfileActions.saveSubOrganizationProfileFailure({ error }))),
        );
      }),
    ),
  );

  public removeSubOrganizationProfile = createEffect(() =>
    this.action$.pipe(
      ofType(subOrganizationProfileActions.removeSubOrganizationProfileStart),
      switchMap(({ orgId }) => of(orgId).pipe(withLatestFrom(this.facade.editableOrganizationProfileId$))),
      switchMap(([orgId, id]) =>
        this.confirmation.openByPrefix('owner.subOrganization.modals.archive').pipe(
          filter(result => Boolean(result)),
          mapTo({ orgId, id }),
        ),
      ),
      switchMap(({ orgId, id }) =>
        this.subOrganizationsService.sendSubOrganizationToArchive(orgId ?? id).pipe(
          map(() => {
            this.toastFacade.showMessage('owner.profile.message.removeProfileSuccess', TOAST_TYPE.SUCCESS);
            return subOrganizationProfileActions.removeSubOrganizationProfileSuccess();
          }),
          catchError(error => {
            this.toastFacade.showMessage('owner.profile.message.removeProfileError');
            return of(subOrganizationProfileActions.removeSubOrganizationProfileFailure({ error }));
          }),
        ),
      ),
    ),
  );

  public backToListOnSuccess = createEffect(
    () =>
      this.action$.pipe(
        ofType(
          subOrganizationProfileActions.saveSubOrganizationProfileSuccess,
          subOrganizationProfileActions.removeSubOrganizationProfileSuccess,
        ),
        tap(() => {
          this.facade.navigateToTable();
        }),
      ),
    { dispatch: false },
  );

  public navigateTo = createEffect(() =>
    this.action$.pipe(
      ofType(subOrganizationProfileActions.navigateToAction),
      switchMap(action => of(action).pipe(withLatestFrom(this.facade.editableOrganizationProfileId$))),
      map(([{ action }, id]) => RouterActions.routerGo({ path: ['/profile/owner/sub-organizations', action, id] })),
    ),
  );

  constructor(
    private readonly subOrganizationsService: TnSubOrganizationsApiService,
    private readonly action$: Actions,
    private readonly facade: TnSubOrganizationProfileFacade,
    private readonly confirmation: TnConfirmationService,
    private readonly toastFacade: TnToasterFacade,
  ) {}
}
