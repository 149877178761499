import { createFeatureSelector, createSelector } from '@ngrx/store';

import { makeTableSelectors } from '../../../../table/table-selectors';
import { IDirectoryState } from '../../../directory-state.interface';

const getDirectoryState = createFeatureSelector<IDirectoryState>('directory');
const cargoTypesStateSelector = createSelector(getDirectoryState, (state: IDirectoryState) => state.cargoTypes);

export const tableSelectors = makeTableSelectors(cargoTypesStateSelector);

export const pageMode = createSelector(getDirectoryState, (state: IDirectoryState) => state.cargoType?.mode);
