import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import moment from 'moment';

export function dateComparisonValidator(controlName: string): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control.parent) return null;
    const date = moment(new Date());
    return date.isAfter(moment(`${control.value} ${control.parent.get(controlName)?.value}`, 'DD.MM.YYYY hh:mm'))
      ? {
          dateComparison: false,
        }
      : null;
  };
}

export function timeSequenceValidator(controlName: string, needBeMore: boolean): ValidatorFn {
  const getMin = time => {
    if (time.indexOf(':') === -1 || time.length !== 5) {
      return null;
    }
    try {
      const [hours, mins] = time.split(':').map(v => parseInt(v));
      return hours * 60 + mins;
    } catch (e) {
      return null;
    }
  };

  return (control: AbstractControl): ValidationErrors | null => {
    if (!control || !control.parent) {
      return null;
    } else {
      const anotherTime = getMin(control.parent.get(controlName)?.value);
      const time = getMin(control.value);
      if (anotherTime === null) {
        return null;
      }
      if (time === null) {
        return { timeToIsAfter: "Time isn't correct" };
      }

      return needBeMore
        ? time >= anotherTime
          ? null
          : { timeToIsAfter: 'That time is less than another' }
        : time <= anotherTime
        ? null
        : { timeToIsAfter: 'That time is more than another' };
    }
  };
}

export function getTimezoneForMoment(timezone: string = 'UTC+0') {
  let utc = '+0000';
  if (timezone) {
    utc = timezone.replace('UTC+', '');
    utc = utc.length === 1 ? `+0${utc}00` : `+${utc}00`;
  } else {
    console.error('нет таймзоны у мест погрузки разгрузки');
  }
  return utc;
}
