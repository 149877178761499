import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

export interface IAutoCompleteScrollEvent {
  autoComplete: MatAutocomplete;
  scrollEvent: Event;
}

@UntilDestroy()
@Directive({
  selector: 'mat-autocomplete[optionsScroll]'
})
export class OptionsScrollDirective {

  @Input() thresholdPercent = .95;
  @Output() optionsScroll = new EventEmitter<IAutoCompleteScrollEvent>();

  constructor(public autoComplete: MatAutocomplete) {
    this.autoComplete.opened.pipe(
      tap(() => {
        setTimeout(() => {
          this.removeScrollEventListener();
          this.autoComplete.panel.nativeElement.addEventListener('scroll', this.onScroll.bind(this))
        });
      }),
      untilDestroyed(this)
    ).subscribe();

    this.autoComplete.closed.pipe(
      tap(() => this.removeScrollEventListener()),
      untilDestroyed(this)
    ).subscribe();
  }

  private removeScrollEventListener() {
    this.autoComplete.panel.nativeElement.removeEventListener('scroll', this.onScroll);
  }

  onScroll(event: Event) {
    if (this.thresholdPercent === undefined) {
      this.optionsScroll.next({ autoComplete: this.autoComplete, scrollEvent: event });
    } else {
      const threshold = this.thresholdPercent * 100 * (<HTMLInputElement>event.target).scrollHeight / 100;
      const current = (<HTMLInputElement>event.target).scrollTop + (<HTMLInputElement>event.target).clientHeight;

      if (current > threshold) {
        this.optionsScroll.next({ autoComplete: this.autoComplete, scrollEvent: event });
      }
    }
  }
}
