import { Injectable } from '@angular/core';
import { transport } from '@transport/proto';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { TnGqlClientOwnerMutationsService } from '../../../gql-client-owner/graphql-client-owner-mutations.service';
import { TnGqlClientOwnerQueriesService } from '../../../gql-client-owner/graphql-client-owner-queries.service';

@Injectable({
  providedIn: 'root',
})
export class TnOwnerDirectoryCargoTypesApiService {
  constructor(private readonly gqlQuery: TnGqlClientOwnerQueriesService, private readonly gqlMutation: TnGqlClientOwnerMutationsService) {}

  /**
   * Request for list of items
   */
  public getCargoTypes(listenX?: number, withSpinner?: boolean, first?: number, offset?: number, options?: Record<string, unknown>) {
    return this.gqlQuery.queryCargoTypes(listenX, withSpinner, first, offset, options).pipe(
      map(data => {
        return {
          list: data.cargoTypes,
          totalCount: data.totalCount ?? 0,
        };
      }),
    );
  }

  /**
   * Get cargo type by id
   * @param cargoTypeId Id cargo type
   */
  public getOwnerCargoType(cargoTypeId: string) {
    return this.gqlQuery.queryCargoType(cargoTypeId).pipe(map(data => data.cargoType));
  }

  /**
   * Remove cargo type request
   * @param cargoTypeId Id cargo type
   */
  public removeCargoType(cargoTypeId: string) {
    return this.gqlMutation.archiveCargoType(cargoTypeId).pipe(map(result => result.sendCargoTypeToArchive));
  }

  /**
   * Create new cargo type request
   * @param cargoTypeParam Cargo type data
   */
  public addCargoType(cargoTypeParam: Record<string, unknown>) {
    return this.gqlMutation
      .addCargoType({
        input: cargoTypeParam,
      })
      .pipe(map(data => data.addCargoType));
  }

  /**
   * Edit cargo type request
   * @param cargoTypeParam Cargo type data
   */
  public editCargoType(cargoTypeParam: Record<string, unknown>) {
    return this.gqlMutation
      .editCargoType({
        input: cargoTypeParam,
      })
      .pipe(map(data => data.editCargoType));
  }

  /**
   * Get loading types list
   */
  public getOwnerLoadingTypesList(): Observable<transport.ILoadingType[]> {
    return this.gqlQuery.queryLoadingTypesList();
  }

  /**
   * Get packaging types list
   */
  public getOwnerPackagingTypesList() {
    return this.gqlQuery.queryPackagingTypes();
  }

  /**
   * Get cargo body types with sublasses
   */
  public getBodyTypes(): Observable<transport.Vehicle.Body.IType[]> {
    return this.gqlQuery.queryBodyTypes();
  }

  /**
   * Get options for some cargo type fields
   */
  public getCargoTypesFields() {
    return forkJoin({
      loadingTypes: this.getOwnerLoadingTypesList(),
      packagingTypes: this.getOwnerPackagingTypesList(),
      cargoBodyTypes: this.getBodyTypes(),
    }).pipe(
      switchMap(data => {
        const res = {
          loadingTypes: data.loadingTypes,
          packagingTypes: data.packagingTypes,
          cargoBodyTypes: data.cargoBodyTypes,
        };
        return of(res);
      }),
    );
  }
}
