import { Directive, Input, OnChanges, SimpleChange, SimpleChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs/operators';

import { ITnState } from '../../state/index';
import { getFlagValue, hideInProd } from '../feature-access.selectors';

interface IInputChanges extends SimpleChanges {
  transportFeatureFlag: SimpleChange;
}

@Directive({
  selector: '[transportFeatureFlag]',
})
export class TnFeatureFlagDirective implements OnChanges {
  @Input() public transportFeatureFlag?: string;

  constructor(
    private readonly viewContainer: ViewContainerRef,
    private readonly template: TemplateRef<unknown>,
    private readonly store: Store<ITnState>,
  ) {}

  public ngOnChanges(changes: IInputChanges) {
    if (!Boolean(changes.transportFeatureFlag.currentValue)) {
      void this.store
        .select(hideInProd)
        .pipe(
          tap(hide => {
            if (!hide) {
              this.viewContainer.createEmbeddedView(this.template);
            }
          }),
        )
        .subscribe();
    } else {
      void this.store
        .select(getFlagValue(changes.transportFeatureFlag.currentValue))
        .pipe(
          tap(flagValue => {
            if (Boolean(flagValue)) {
              this.viewContainer.createEmbeddedView(this.template);
            }
          }),
        )
        .subscribe();
    }
  }
}
