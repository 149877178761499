<h2 class="title">
  {{title}}
</h2>

<ng-container *ngFor="let item of list  | slice:0:listSize">
  <div class="item cursor-pointer hover:bg-gray-100" (click)="action.emit()">
    <mat-icon class="status-icon">lens</mat-icon>
    <span class="text-ellipsis overflow-hidden whitespace-nowrap" 
    tn-tip="true">
    {{item}}</span>
  </div>
</ng-container>
<div class="item cursor-pointer" (click)="listOpen = !listOpen; openAllPartnersList()" *ngIf="list.length > itemsAmount">
  <span class="indicator-close flex justify-center items-center w-6 h-6" [ngClass]="{'indicator-open': listOpen}">
    <span class="indicator"></span>
  </span>
  <span class="item">
  <ng-container *ngIf="!listOpen; else hide">
    {{ 'shared.accordion.showMore' | translate : { value: list.length - listSize } }}
  </ng-container>
  <ng-template #hide>
    {{ 'shared.accordion.hide' | translate }}
  </ng-template>
  </span>
</div>
