<transport-tw-input-field
  [class]="inputFieldClass"
  [control]="$any(formControl)"
  [size]="size"
  [errorsSize]="errorsSize"
  [label]="label"
  [labelAddition]="labelAddition"
  [labelClasses]="labelClasses"
  [caption]="caption"
  [captionClasses]="captionClasses"
  [showErrors]="showErrors"
  [required]="required"
  [errorToMessage]="errorToMessage">
  <transport-tw-input class="w-full" [control]="$any(formControl)" [size]="size">
    <mat-select
      #commonSelect
      multiple
      class="text-sm text-gray-700"
      [panelClass]="'tw-menu-block common-multi-select-panel w-full !overflow-x-hidden overflow-y-auto'"
      [placeholder]="placeholder"
      [ngClass]="{'arrow-up': isOpen}"
      [required]="required"
      [compareWith]="equals"
      [formControl]="$any(formControl)"
      [disableOptionCentering]="true"
      [attr.data-test]="dataTest"
      (openedChange)="openedChange($event)">
      <mat-select-search *ngIf="enableSearch && !disabled"
                         #search
                         [formControl]="filterCtrl"
                         [withContent]="true"
                         [isMultipleSelect]="true">
        <mat-checkbox [disabled]="!(options | async)?.length"
                      [indeterminate]="someComplete()"
                      [checked]="allComplete"
                      [attr.data-test]="'multiselect-checkbox'"
                      (change)="selectAll($event)">
        </mat-checkbox>
      </mat-select-search>
      <mat-select-trigger>
        <div class="flex items-center justify-between mr-1">
          <div class="flex items-center justify-between w-full multiselect-trigger-label">
            <span class="whitespace-nowrap overflow-hidden text-ellipsis mr-1">
                  {{finalLabel?.firstType}}
            </span>
            <span *ngIf="finalLabel?.additionalCount"
                  class="px-2 mr-1 bg-gray-100 rounded-md text-gray-700 text-xs leading-4 font-medium whitespace-nowrap">
                  +{{finalLabel?.additionalCount}}
            </span>
          </div>
          <div *ngIf="allowClear && !(formControl?.disabled || disabled) && !isEmpty"
               class="flex items-center justify-center cursor-pointer w-5 h-5"
               [ngClass]="{'z-[1010]': isOpen}">
            <mat-icon svgIcon="close" class="!w-[11px] !h-[11px] text-gray-400" (click)="clearSelect($event)"></mat-icon>
          </div>
        </div>
      </mat-select-trigger>
      <mat-option
        *ngFor="let option of options | async"
        class="!px-2"
        [ngClass]="{'!hidden': option.hidden}"
        [value]="option"
        [disabled]="disabled">
        <transport-tw-menu-option [size]="size" [text]="option.label"></transport-tw-menu-option>
      </mat-option>
    </mat-select>
  </transport-tw-input>
</transport-tw-input-field>
