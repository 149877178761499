<div class="relative flex flex-col gap-y-3">
  <div class="flex w-full justify-end">
    <button class="h-6" mat-dialog-close>
      <mat-icon class="close-icon rounded-full text-gray-400 hover:text-gray-700 hover:bg-gray-100 active:bg-gray-200">close</mat-icon>
    </button>
  </div>
  <div class="h-[600px] pr-2 overflow-y-auto">
    <ng-container *ngIf="data.prohibited.length > 0">
      <transport-black-list-card *ngFor="let item of data.prohibited" [info]="item" [type]="$any(item?.type || data.type)"></transport-black-list-card>
    </ng-container>
    <ng-container *ngIf="data.partProhibited.length > 0">
      <transport-black-list-card *ngFor="let item of data.partProhibited" [info]="item" [type]="$any(item?.type || data.type)"></transport-black-list-card>
    </ng-container>
  </div>
</div>
