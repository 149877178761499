import { createAction, props } from '@ngrx/store';
import { IOrderAttachment, TnFileToUpload, TnOrderDocumentTypes, TOrderAttachmentForSave } from '@transport/ui-interfaces';
import { GraphQLError } from 'graphql';

const actionPrefix = '[Order Documents Carrier]';

export const addDocumentsStart = createAction(
  `${actionPrefix} Add Documents Start`,
  props<{ documents: TOrderAttachmentForSave[]; orderId: string }>(),
  // TODO пока оставить этот комменарий, пиригодится при реализации документов в профиле
  // (doc: Partial<IOrderAttachment>) => ({ doc: { ...doc, id: generateId() } as IOrderAttachment }),
);

export const addWaybillStart = createAction(
  `${actionPrefix} Add Waybill Start`,
  props<{ documents: TOrderAttachmentForSave[]; orderId: string }>(),
);

export const editWaybillStart = createAction(
  `${actionPrefix} Edit Waybill Start`,
  props<{ wayBill: TOrderAttachmentForSave[]; orderId: string }>(),
);

export const editWaybillSuccess = createAction(
  `${actionPrefix} Edit Waybill Success`,
  props<{ wayBill: TOrderAttachmentForSave[]; orderId: string }>(),
);

export const addDocumentsSuccess = createAction(
  `${actionPrefix} Add Documents Success`,
  props<{ documents: IOrderAttachment[]; orderId: string }>(),
);

export const addDocumentsFailure = createAction(`${actionPrefix} Add Documents Failure`, props<{ error: GraphQLError }>());

export const removeDocumentStart = createAction(`${actionPrefix} Remove Document Start`, props<{ documentId: string; orderId: string }>());

export const removeDocumentSuccess = createAction(
  `${actionPrefix} Remove Document Success`,
  props<{ documentId: string; document: IOrderAttachment | undefined; orderId: string }>(),
);

export const removeDocumentFailure = createAction(`${actionPrefix} Remove Document Failure`, props<{ error: GraphQLError }>());

export const addSignToDocumentSuccess = createAction(`${actionPrefix} Add Sign To Document Success`, props<{ sign: IOrderAttachment }>());

export const addSignToDocumentFailure = createAction(`${actionPrefix} Add Sign To Document Failure`, props<{ error: GraphQLError }>());

export const uploadSignStart = createAction(`${actionPrefix} Upload Sign Start`, props<{ sign: TnFileToUpload; signedFileId: string }>());

export const uploadSignSuccess = createAction(`${actionPrefix} Upload Sign Success`, props<{ attach: TOrderAttachmentForSave }>());

export const uploadSignFailure = createAction(`${actionPrefix} Upload Sign Failure`, props<{ error: GraphQLError }>());

export const uploadContractStart = createAction(`${actionPrefix} Upload Contract Start`, props<{ contract: TnFileToUpload }>());

export const uploadContractFailure = createAction(`${actionPrefix} Upload Contract Failure`, props<{ error: GraphQLError }>());

export const loadAllDocumentTypesStart = createAction(`${actionPrefix} Load All Document Types Start`);

export const loadAllDocumentTypesSuccess = createAction(
  `${actionPrefix} Load All Document Types Success`,
  props<{ documentTypes: TnOrderDocumentTypes }>(),
);

export const loadAllDocumentTypesFailure = createAction(
  `${actionPrefix} Load All Document Types Failure`,
  props<{ error: GraphQLError }>(),
);

export const loadDocumentsSuccess = createAction(`${actionPrefix} Load  Documents Success`, props<{ documents: IOrderAttachment[] }>());

export const loadDocumentsFailure = createAction(`${actionPrefix} Load Documents Failure`, props<{ error: GraphQLError }>());
