// import { ORDER_CANCEL_REASON } from '@transport/ui-interfaces';

export enum ORDER_CANCEL_REASON_LABEL {
  CARRIER_NOT_FOUND = 'CARRIER_NOT_FOUND',
  CARRIER_REFUSED_ORDER = 'CARRIER_REFUSED_ORDER',
  CARGOOWNER_CANCELED_ORDER = 'CARGOOWNER_CANCELED_ORDER',
  TIME_TO_PROCESS_ORDER_EXPIRED = 'TIME_TO_PROCESS_ORDER_EXPIRED',
  CARRIER_NOT_SHOWING_FOR_LOADING = 'CARRIER_NOT_SHOWING_FOR_LOADING',
};

// осноной интерфейс лежит тут import { ORDER_CANCEL_REASON } from '@transport/ui-interfaces
// нельзя импортировать @transport/ui-interfaces так как это ломает сборку SSO, ошибки в сборке стилей
enum ORDER_CANCEL_REASON {
  // перевозчик не найден
  ORDER_EXPIRED = 'ORDER_EXPIRED',
  AUCTION_ENDED_WITHOUT_WINNER = 'AUCTION_ENDED_WITHOUT_WINNER',
  ORDER_ACCEPT_DEADLINE_EXPIRED = 'ORDER_ACCEPT_DEADLINE_EXPIRED',
  // перевозчик отказался
  CARRIER_REFUSED_ORDER = 'CARRIER_REFUSED_ORDER',
  // поиск перевозчика был отменен
  CARGOOWNER_CANCELED_ORDER = 'CARGOOWNER_CANCELED_ORDER',
  //Перевозчик не оформил заявку за выделенное время
  TIME_TO_PROCESS_ORDER_EXPIRED = 'TIME_TO_PROCESS_ORDER_EXPIRED',
  ORDER_RESERVATION_TIME_EXPIRED = 'ORDER_RESERVATION_TIME_EXPIRED',
  // Машина не прибыла на погрузку
  CARRIER_NOT_SHOWING_FOR_LOADING = 'CARRIER_NOT_SHOWING_FOR_LOADING',
}

export const cancelReasonTextPath = (reason): string | undefined => {
  if (
    reason === ORDER_CANCEL_REASON.ORDER_EXPIRED ||
    reason === ORDER_CANCEL_REASON.AUCTION_ENDED_WITHOUT_WINNER ||
    reason === ORDER_CANCEL_REASON.ORDER_ACCEPT_DEADLINE_EXPIRED
  )
    return ORDER_CANCEL_REASON_LABEL.CARRIER_NOT_FOUND;

  if (reason === ORDER_CANCEL_REASON.CARRIER_REFUSED_ORDER) return ORDER_CANCEL_REASON_LABEL.CARRIER_REFUSED_ORDER;
  if (reason === ORDER_CANCEL_REASON.CARGOOWNER_CANCELED_ORDER) return ORDER_CANCEL_REASON_LABEL.CARGOOWNER_CANCELED_ORDER;
  if (reason === ORDER_CANCEL_REASON.CARRIER_NOT_SHOWING_FOR_LOADING) return ORDER_CANCEL_REASON_LABEL.CARRIER_NOT_SHOWING_FOR_LOADING;

  if (
    reason === ORDER_CANCEL_REASON.TIME_TO_PROCESS_ORDER_EXPIRED ||
    reason === ORDER_CANCEL_REASON.ORDER_RESERVATION_TIME_EXPIRED
  )
    return ORDER_CANCEL_REASON_LABEL.TIME_TO_PROCESS_ORDER_EXPIRED;

  return;
};
