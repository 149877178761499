import { NgModule } from '@angular/core';

import { SafePipe } from './safe-pipe';
import { TnClSsoFrameComponent } from './tncl-sso-frame.component';

@NgModule({
  exports: [TnClSsoFrameComponent],
  declarations: [TnClSsoFrameComponent, SafePipe],
})
export class TnClSsoFrameModule {}
