export const orderLocalization = {
  label: {
    route: 'Маршрут',
    carrier: 'Исполнитель',
    freight: 'Фрахт',
    documents: 'Документы',
    sla: 'Требуемый уровень обслуживания',
    description: 'Описание',
    additionalInfo: 'Дополнительная информация',
    extraServices: 'Перечень услуг',
  },
  validation: {
    chooseCarrier: 'Выберите перевозчика',
    enterFreightAmount: 'Введите сумму фрахта',
  },
  header: {
    title: {
      order: 'Заказ',
      auction: 'Аукцион',
      bidding: 'Сбор предложений',
      tenderOrder: 'Заявка',
    },
    subtitle: {
      partnerOrder: '№{{id}}{{diyAttribute}}{{extId}}{{tenderLotNumber}} от',
      exchangeOrder: '№{{id}}{{diyAttribute}}{{extId}}{{tenderLotNumber}} от',
      tenderLotNumber: ', № лота {{value}}',
    },
  },
  warningMessage: {
    waybillRequired: 'Перед завершением заявки загрузите транспортные накладные в разделе “Документы”',
    waybillRequiredNew: 'Перед завершением заявки загрузите транспортные накладные в точках разгрузки в разделе “Маршрут”',
    isFactPointDatetimeRequired: 'Перед завершением заявки заполните фактическое время прибытия и убытия на точках',
    signError: 'Произошла ошибка проверки подписи, подпишите еще раз или свяжитесь со службой поддержки',
    creatingError: 'При формировании заявки-договора возникла ошибка на стороне 1С, обратитесь в службу поддержки',
    contractRequired:
      'Скачайте форму заявки в разделе “Документы”, подпишите и загрузите его нажав на кнопку “Загрузить подписанную заявку"',
    onAcreditationStatus: 'Организация еще не аккредитована. Вы получите оповещение при смене статуса.',
    notAcreditationStatus: 'Организация не прошла аккредитацию',
    notСontract: 'Ожидается загрузка договора со стороны ГВ',
    waitCargoOwnerSign: 'Ожидайте согласования договора-заявки со стороны грузовладельца',
    waitCargoOwnerSignCancel: 'Ожидайте подписания формы отказа со стороны грузовладельца',
    waitDocumentsFromTnSign: 'После подписания ожидайте синхронизацию с приложением',
    bothSigned: 'Договор-заявка сформирована и подписана двусторонне',
    cargoOwnerSigned: 'Договор-заявка сформирована и подписана со стороны грузовладельца',
    cargoOwnerSignedCancel: 'После подписания формы отказа ожидайте синхронизацию с приложением',
  },
  statusBarStages: {
    '1': {
      labelA: 'Поиск',
      labelB: 'исполнителя',
    },
    '2': {
      labelA: 'Оформление',
      labelB: 'заявки',
    },
    '3': {
      labelA: 'В исполнении',
      labelB: '',
    },
    '4': {
      labelA: 'Исполнена',
      labelB: '',
    },
    disabled: {
      labelA: 'Отменена',
      labelB: '',
    },
  },
  managementCard: {
    header: {
      title: {
        youWon: 'Вы победили',
        youLost: 'Вы проиграли',
        waitForBidsEnd: 'Ожидайте решения грузовладельца',
        noWinners: 'Победителей не выявлено',
        offerPlaced: 'Предложение отправлено',
      },
      countdownLabel: {
        timeForRegistration: 'Время на оформление',
        untilAuctionEnd: 'До конца аукциона',
        untilBiddingEnd: 'До конца торгов',
        acceptDeadline: 'Завершится через',
      },
    },
    description: {
      withVat: 'с НДС',
      withoutVat: 'без НДС',
      startPrice: 'Стартовая цена',
      currentBet: 'Текущая ставка',
      youBet: 'Ваша ставка',
      biddingEnd: 'Окончание торгов',
      winning: 'Вы выигрываете',
      loosing: 'Вы проигрываете',
      placedOffer: 'Предложение сделано',
      bidding: 'Сделайте предложение',
    },
    action: {
      accept: {
        title: 'Забрать заявку',
        confirmation: {
          accept: 'Согласовать',
          title: 'Забрать заявку',
          content: 'Вы уверены, что хотите забрать заявку?',
          confirm: 'Да, забрать',
          cancel: 'Нет',
        },
        reservationTime: {
          title: 'Внимание',
          content:
            'Вы собираетесь забронировать заявку с ограниченным временем на оформление ({{time}}). Вы уверены, что хотите ее забронировать?',
          confirm: 'Да, забронировать',
          cancel: 'Отменить',
        },
      },
      cancel: {
        title: 'Отказаться',
        confirmation: {
          title: 'Отказаться от заказа',
          content: 'Вы уверены, что хотите отказаться от заказа?',
          confirm: 'Да, отказаться',
          cancel: 'Нет',
        },
        confirmationTn: {
          title: 'Отказ от перевозки',
          content:
            'Для того, чтобы отказаться от перевозки на данном этапе, необходимо подписать Соглашение о расторжении заявки-договора.\n\nВы уверены, что хотите отказаться от данной перевозки?',
          confirm: 'Да, отказаться',
          cancel: 'Нет',
        },
      },
      change: {
        accepted: {
          title: 'Согласовать изменения',
          content: 'Вы уверены, что хотите согласовать изменения?',
          confirm: 'Да, согласовать',
          cancel: 'Закрыть',
        },
        rejected: {
          title: 'Отклонить изменения',
          content: 'Вы уверены, что хотите отклонить изменения?',
          confirm: 'Да, отклонить',
          cancel: 'Закрыть',
        },
        cancel: 'Отклонить изменение',
        create: {
          confirmation: {
            title: 'Отправить предложение',
            content: 'Вы уверены, что хотите отправить предложение об изменении?',
            confirm: 'Да, отправить',
            cancel: 'Нет',
          },
        },
        price: {
          confirmation: {
            title: 'Опубликовать',
            content: 'Вы уверены, что хотите опубликовать изменение цены перевозки?',
            confirm: 'Да',
            cancel: 'Нет',
          },
        },
        comment: {
          confirmation: {
            title: 'Отправить',
            content: 'Вы уверены, что хотите отправить изменение коментария перевозки?',
            confirm: 'Да',
            cancel: 'Нет',
          },
        },
      },
      complete: {
        title: 'Завершить заявку',
        confirmation: {
          title: 'Завершить заявку',
          content: 'Вы уверены, что хотите завершить выполнение заказа?',
          confirm: 'Да, завершить',
          cancel: 'Нет',
        },
      },
      acknowledge: {
        title: 'Принять',
        confirmation: {
          title: 'Принять заказ',
          content: 'Вы уверены, что хотите принять заказ?',
          confirm: 'Да, принять',
          cancel: 'Нет',
        },
      },
      execute: {
        title: 'Начать выполнение',
        confirmation: {
          title: 'Приступить к заказу',
          content: 'Вы уверены, что хотите приступить к заказу?',
          confirm: 'Да, приступить',
          cancel: 'Нет',
        },
      },
      createContract: {
        title: 'Подтвердить назначение',
        edmTitle: 'Подтвердить назначение',
      },
      uploadContract: {
        title: 'Загрузить подписанную заявку',
      },
      readAndSign: {
        title: 'Посмотреть и подписать',
      },
      signTn: {
        title: 'Подписать в TN Sign',
      },
      signContract: {
        title: 'Подписать Sign TN',
      },
      gotoOrder: {
        title: 'Перейти к заявке',
      },
      readOnly: {
        title: 'Невозможно совершать действия с заявкой, предназначенной только для просмотра',
        biddingOrderMessage: 'Ожидайте подтверждения распределения заявки грузовладельцем',
      },
      editFactDetatime: {
        success: 'Фактическое время прибытия и убытия сохранены',
        error: 'Ошибка сохранения фактического время прибытия и убытия',
        invalidDateRange: 'Время прибытия не может быть позднее время убытия',
      },
      successCancelLoading: 'Погрузки успешно отменена',
      errorCancelLoading: 'Ошибка отмены погрузки',
      successCancelUnloading: 'Разгрузка успешно отменена',
      errorCancelUnloading: 'Ошибка отмены разгрузки',
      confirmCancelLoading: {
        title: 'Подтвердить отмену погрузки?',
        content: '',
        confirm: 'Да',
        cancel: 'Нет',
      },
      confirmCancelUnloading: {
        title: 'Подтвердить отмену разгрузки?',
        content: '',
        confirm: 'Да',
        cancel: 'Нет',
      },
      confirmLoadingArrive: {
        title: 'Подтвердить время прибытия на погрузку?',
        content: '',
        confirm: 'Да',
        cancel: 'Нет',
      },
      confirmLoadingDepature: {
        title: 'Подтвердить время убытия с погрузки?',
        content: '',
        confirm: 'Да',
        cancel: 'Нет',
      },
      confirmUnloadingArrive: {
        title: 'Подтвердить время прибытия на разгрузку?',
        content: '',
        confirm: 'Да',
        cancel: 'Нет',
      },
      confirmUnloadingDepature: {
        title: 'Подтвердить время убытия с разгрузки?',
        content: '',
        confirm: 'Да',
        cancel: 'Нет',
      },
      confirmChangeFactDatetime: {
        title: 'Подтвердить изменения времени прибытия и убытия?',
        content: '',
        confirm: 'Да',
        cancel: 'Нет',
      },
    },
    bidForm: {
      bet: {
        label: 'Сделать предложение',
        error: {
          minimumBet: 'Достигнута минимальная цена',
          required: 'Поле обязательно к заполнению',
          shouldMoreZero: 'Ставка должна быть больше 0',
        },
        placeholder: 'Введите ставку',
      },
    },
  },
  freightCard: {
    carrierServices: 'Услуги перевозчика',
    valueWithVat: 'Сумма с НДС',
    vatRate: 'Ставка НДС',
    vatAmount: 'Сумма НДС',
    counterPartyVat: 'НДС за перевозчика',
    forwarderFee: 'Комиссия экспедитора',
    total: 'Цена заявки на транспорт',
    enterTheCostOfServices: 'Введите стоимость услуг',
    calculate: 'Рассчитать',
    errors: {
      isPriceTooSmall: 'Сумма вознаграждения экспедитора превышает стоимость услуг перевозчика',
      isFeeTooSmall: 'Комиссия экпедитора не может быть меньше {{feeMinValue}}.',
      isFeeTooSmallShort: 'Комиссия экпедитора не может быть меньше {{feeMinValue}}',
      isZeroPrice: 'Стоимость услуг перевозчика должна быть больше 0',
      isPriceTooBig: 'Стоимость услуг перевозчика не может превышать сумму по заявке',
    },
  },
  deliveryMethods: {
    additionalCargo: 'Возможен догруз',
    individualCar: 'Отдельная машина',
  },
  detailsCard: {
    type: 'Тип процедуры',
    auctionType: 'Аукцион',
    simpleType: 'Срочный заказ',
    preassignedType: 'Ручное распределение',
    biddingType: 'Сбор предложений',
    creationTime: 'Создан',
    startPrice: 'Начальная цена',
    price: 'Фактическая цена',
    allocationTime: 'Распределён',
    allocationByTender: 'Распределено по тендеру',
    bestPrice: 'Лучшая цена',
    comment: 'Комментарий',
    openBetHistory: 'Открыть детали торгов',
  },
  descriptionCard: {
    vehicleRequirements: 'Требования к ТС',
    cargoPlaceRequirements: 'Параметры груза',
    loadingTypes: 'Типы погрузки',
    capacity: {
      label: 'Грузоподъемность',
      value: '{{capacity}} т',
    },
    volume: {
      label: 'Объем',
      value: '{{volume}} м³',
    },
    bodyType: {
      label: 'Тип кузова',
    },
    specialRequirements: {
      label: 'Специальные требования',
    },
    bodySubtype: {
      label: 'Подтип кузова',
    },
    bodyDimensions: {
      label: 'Внутренний размер кузова (ДхВхШ), м',
      value: '{{length}}x{{height}}x{{width}}',
    },
    cargoType: {
      label: 'Тип груза',
    },
    weight: {
      label: 'Вес',
      value: '{{weight}} т',
    },
    temperatureMode: {
      label: 'Температурный режим',
      value: '{{temperatureMode}} °C',
      range: 'от {{temperatureModeFrom}} °C до {{temperatureModeTo}} °C',
    },
    containerType: {
      label: 'Тип контейнера',
    },
    packagingType: {
      label: 'Вид упаковки',
      value: {
        pallets: 'Паллеты',
        boxes: 'Коробки',
        bundles: 'Пачки',
        placer: 'Россыпь',
      },
    },
    cargoPackagesCount: {
      label: 'Грузовых мест',
    },
    cargoDimensions: {
      label: 'Грузовое место (ДхВхШ), м',
      value: '{{length}}x{{height}}x{{width}}',
    },
    cargoTotalPrice: {
      label: 'Стоимость груза',
      value: '{{ value }} руб.',
    },
  },
  additionalInfoCard: {
    container: 'Контейнер',
    containerNumber: 'Номер контейнера',
    containerSeal: 'Номер пломбы',
    customerOrganization: 'Заказчик перевозки',
    acceptingPlace: {
      title: 'Торговая площадка',
      market: 'Рынок',
    },
    tenderNumber: 'Распределено по тендеру',
    contactPerson: 'Контактное лицо',
    responsiablePerson: 'Ответственное лицо',
    insurance: 'Страхование',
    extraServices: 'Перечень услуг',
    comment: 'Комментарий',
    allowedExtraServices: {
      price: '{{price}} ₽',
      vatAmount: '{{vatAmount}} ₽ НДС',
      description: 'Указанный НДС включён в стоимость услуг',
    },
  },
  contractCard: {
    title: 'Выберите перевозчика, который будет исполнять заявку',
    agentCarrierName: '{{ value }} (ИНН {{ inn }} )',
    changeContractDialog: {
      title: 'Сменить договор',
      content: 'Вы уверены, что хотите сменить договор на {{ value }} ?',
      confirm: 'Сменить',
      cancel: 'Отмена',
    },
    forwarder: 'Экспедитор',
    contractor: {
      title: 'Организация исполнитель',
      placeholder: 'Введите наименование или ИНН',
    },
    primaryContractor: 'Исполнитель перевозки',
    editButton: 'Изменить',
    carrier: 'Перевозчик',
    enterCarrierName: 'Введите наименование перевозчика',
    contractNumber: 'Номер договора',
    chooseContractNumber: 'Выберите договор',
    whoseTransport: 'Чей транспорт',
    notActive: '• Неактивен',
    notActiveContractSelected: 'Выбран неактивный договор',
    typeOfProperty: {
      label: 'Привлеченное ТС',
      own: 'Собственное ТС',
      attracted: 'Привлеченное ТС',
      unknown: '---',
    },
    yes: 'Да',
    no: 'Нет',
  },
  transportCard: {
    title: 'Транспорт и водитель',
    vehicle: 'Транспорт',
    driver: 'Водитель',
    coDriver: 'Сменный водитель',
    fullName: 'ФИО',
    phone: 'Телефон',
    iin: 'ИИН',
    inn: 'ИНН',
    passport: 'Серия и номер паспорта',
    mobileApplication: 'Мобильное приложение',
    applicationInstalled: 'установлено',
    applicationNotInstalled: 'не установлено',
    vehicleType: {
      label: 'Тип ТС',
      value: {
        trailer: 'Фура',
        truck: 'Грузовик',
        coupler: 'Сцепка',
      },
    },
    vehicleMake: 'Марка',
    regNo: 'Госномер',
    ownershipTypeLabel: 'Тип владения',
    ownershipType: {
      own: 'Собственное',
      rent: 'Аренда',
      leasing: 'Лизинг',
      third_party: 'Привлеченное',
      free_use: 'Безвозмездное пользование',
      joint_property_of_spouses: 'Cовместная собственность супругов',
    },
    vehicleTrailerId: 'Госномер полуприцепа',
    vehicleCouplerId: 'Госномер прицепа',
    chooseVehicle: 'Выбрать транспорт',
    chooseDriver: 'Выбрать водителя',
    chooseCoDriver: 'Выбрать сменного водителя',
    addCoDriver: 'Добавить сменного водителя',
    forChangeRemain: 'На изменение осталось ',
    tnSignTermAgreementRemain: 'Время на подписание соглашения о расторжении ',
    change: 'Изменить',
    unfasten: 'Открепить',
    edit: 'Редактировать',
    inviteToApplication: 'Пригласить в приложение',
    warnings: {
      vehicleChange: {
        title: 'Смена ТС',
        content:
          'Данное ТС указано в подписанном договоре. Если вы хотите выбрать другое ТС, то потребуется повторное подписание договора. Вы уверены, что хотите сменить ТС?',
        confirm: 'Да, сменить',
        cancel: 'Отмена',
      },
      changeInReadyToGo: {
        title: 'Условия смены ТС/водителя',
        content: 'Сменить ТС/водителя необходимо в течение {{time}}. Вы уверены, что сможете выполнить всю процедуру за отведенное время?',
        confirm: 'Да, сменить',
        cancel: 'Отмена',
      },
      driverChange: {
        title: 'Смена Водителя',
        content:
          'Данный Водитель указан в подписанном договоре. Если вы хотите выбрать другого Водителя, то потребуется повторное подписание договора. Вы уверены, что хотите сменить Водителя?',
        confirm: 'Да, сменить',
        cancel: 'Отмена',
      },
      transportChange: {
        title: 'Смена ТС и водителя(ей)',
        content:
          'Данное ТС и водитель(и) указаны в подписанном договоре. Любое изменение данных потребует повторного подписания договора. Вы уверены, что хотите сменить?',
        confirm: 'Да, сменить',
        cancel: 'Отмена',
      },
      sameDriver: 'Основной и сменный водители не должны совпадать',
    },
    blackList: {
      vehicle: {
        new: 'ТС заблокировано этой компанией, выберите другое',
        reserved: 'На момент ввода, объект "{{type}}" находился в черном списке',
      },
      driver: {
        new: 'Водитель заблокирован компанией, выберите другого',
        reserved: 'На момент ввода водителя, объект находился в черном списке',
      },
    },
    riskProfile: {
      driverSubmitSuccess: 'Водитель отправлен на проверку',
      vehicleSubmitSuccess: 'ТС успешно отправлено на проверку',
    },
    errors: {
      driverRiskProfileErrorMessage: 'Не удалось отправить водителя на проверку',
      vehicleRiskProfileErrorMessage: 'Не удалось отправить ТС на проверку',
    },
  },
  documentsCard: {
    notification: {
      documentsReady: 'Для заявки №{{value}} сформированы документы',
      importFinished: '{{value}}',
      violationOfCriteria: 'По заявке №{{value}} возникли нарушения уровня обслуживания',
      orderLoadingPlaceTimeChanged: 'Внимание! Изменено время погрузки по заявке №{{value}}!',
      orderDataChanged: 'Внесены изменения в заявку №{{value}}! {{data}}',
      addSuccessfully: 'Документ успешно прикреплен',
      deleteSuccessfully: 'Документ успешно удален',
      redirect: 'Перейти',
    },
    name: {
      contract: 'Договор-заявка',
      request: 'Заявка',
      requestForm: 'Форма заявки',
      contractForm: 'Форма договора-заявки',
      termAgreement: 'Соглашение о расторжении',
      digitSign: 'Электронная подпись',
      waybill: 'Транспортная накладная',
      poaDriver: 'Доверенность водителю',
      goodsInfo: 'Подробная информация по заявке',
      processing: 'Формируются',
      loadingDocs: 'Приложенные документы на погрузке',
      unloadingDocs: 'Приложенные документы на разгрузке',
      invoiceServicesActualCarrier: 'Счет-фактура на оказанные услуги',
      actActualCarrier: 'Акт об оказанных услугах',
      tnSignContract: 'Договор-заявка в TN Sign',
      tnSignTermAgreement: 'Соглашение о расторжении в TN Sign',
    },
    action: {
      add: {
        title: 'Прикрепить документы',
      },
      remove: {
        confirmation: {
          title: 'Удаление документа',
          content: 'Вы уверены, что хотите удалить документ?',
          confirm: 'Да, удалить',
          cancel: 'Нет',
        },
      },
    },
    warning: {
      signTheContract: 'Подпишите договор электронной подписью, чтобы начать работу',
      signTheContractShort: 'Подпишите договор электронной подписью,',
      signTheTerminationAgreement: 'Подпишите соглашение о расторжении договора, чтобы продолжить работу',
      signTheTerminationAgreementShort: 'Подпишите соглашение о расторжении договора',
      signCheckingWait: 'Идет проверка подписи, подождите',
      cargoOwnerSigningOfTheContractWaiting: 'Ожидаем подписания договора со стороны грузоотправителя',
      cargoOwnerSigningOfTheTerminationAgreementWaiting:
        'Ожидаем подписания Соглашения о расторжении заявки-договора со стороны грузоотправителя',
      cargoOwnerSigningOfTheTerminationAgreementWaitingShort: 'Ждем подписания согл. о расторжении ГО',
      creatingError: 'При формировании заявки-договора возникла ошибка на стороне 1С, обратитесь в службу поддержки',
      creatingErrorWaiting: 'При формировании заявки-договора возникла ошибка на стороне 1С, обратитесь в службу поддержки',
      signError: 'Произошла ошибка проверки подписи, подпишите еще раз или свяжитесь со службой поддержки',
      signErrorShort: 'Ошибка проверки подписи',
      contractSignedBoth: 'Договор двусторонне подписан',
      terminationAgreementSignedBoth: 'Соглашение о расторжении договора двусторонне подписано',
    },
    status: {
      signedByCarrier: 'Подписан ГП',
      customersSigning: 'На подписании у ГВ',
      signedByCargoOwner: 'Подписан ГВ',
      signedBoth: 'Подписан двусторонне',
      checkingInProcess: 'идет проверка',
      signatureWaiting: 'ожидает подписи',
    },
  },
  statusCard: {
    processingTimeExpired: 'Время на оформление истекло',
    routeProblems: {
      description: 'Проблема на маршруте',
      reason: {
        problemDriverConfirmed: 'Водитель не подтвердил МЛ',
        problemDepartureOnRoute: 'Водитель не зафиксировал выезд на маршрут',
        problemCargoLoadingCompleted: 'Водитель не зафиксировал завершение погрузки',
        problemArrivedToDeliveryPlace: 'Водитель не зафиксировал приезд на разгрузку',
        problemEnteredDeliveryPlace: 'Водитель не зафиксировал въезд на разгрузку',
        problemCargoUnloadCompleted: 'Водитель не зафиксировал завершение разгрузки',
        problemDeliveryCompleted: 'Водитель не зафиксировал завершение доставки',
        timeLag: 'Задержка по времени',
      },
    },
    driverStatus: {
      description: {
        noConnection: 'Потеряна связь',
        connectionPlanned: 'Планируется отслеживание',
        online: 'Водитель на связи',
      },
      reason: {
        noConnection: 'Возможно у водителя пропал интернет в пути',
      },
    },
  },
  request: {
    load: {
      error: 'Заявка не найдена',
      expired: 'Время на обработку заявки истекло',
    },
    accept: {
      success: 'Заявка успешно назначена',
      takeCanceledAuctionError: 'Вы не можете взять в работу эту заявку, т.к. отказались от неё во время аукциона',
      error: 'При назначении заявки произошла ошибка',
    },
    cancel: {
      success: 'Вы успешно отказались от заявки',
      error: 'При отказе от заявки произошла ошибка',
    },
    complete: {
      success: 'Заявка успешно завершена',
      error: 'При завершении заявки произошла ошибка',
    },
    documentsAdd: {
      success: 'Документ успешно прикреплен',
      error: 'При добавлении документа произошла ошибка',
    },
    documentsEdit: {
      success: 'Документ успешно изменен',
      error: 'При редактировании документа произошла ошибка',
    },
    documentRemove: {
      success: 'Документ успешно удален',
      error: 'При удалении документа произошла ошибка',
    },
    letsGo: {
      success: 'Заявка принята к выполнению',
      error: 'При переходе к выполнению заявки произошла ошибка',
    },
    documentWaiting: {
      success: 'Ожидайте генерацию документов',
    },
  },
  routeCard: {
    time: 'с {{ from }} до {{ to }}',
    differentDatetime: 'с {{ from }} по {{ to }}',
    onlyTimeFrom: 'в {{ from }}',
    address: 'Адрес: ',
    loadingPlaces: 'Погрузка: ',
    unloadingPlaces: 'Разгрузка: ',
    direction: 'Схема проезда',
    viewOnTheMap: 'Смотреть на карте',
    timezone: 'Часовой пояс:',
    loadingType: 'Погрузка',
    unloadingType: 'Разгрузка',
    showCargos: 'Показать грузы на точке',
    hideCargos: 'Скрыть грузы на точке',
    showCargosDetail: 'Показать детальную информацию по грузам',
    hideCargosDetail: 'Скрыть детальную информацию по грузам',
    cargoName: 'Название',
    cargoSize: 'Габариты(ДхШхВ)',
    extCargoId: 'Идентификатор груза',
    volume: 'Объем',
    weight: 'Масса',
    packageType: 'Вид упаковки',
    quantity: 'Количество',
    value: {
      weight: '{{ value }} т',
      volume: '{{ value }} м³',
    },
    packagingType: {
      pallets: 'Паллеты',
      boxes: 'Коробки',
      bundles: 'Пачки',
      placer: 'Россыпь',
    },
  },
  electronicSignature: {
    title: 'Подписать ЭЦП',
    accept: 'Продолжить',
    selectLabel: 'Сертификат',
    fileSelectLabel: 'Файл для подписания',
    loading: 'Загрузка...',

    errors: {
      title: 'Ошибка',
      loadFile: 'Не получилось загрузить файл для подписи',
      maxFileSize: 'Файл для подписи не должен превышать {{ size }}',
      help: {
        text: 'Проверьте правильность работы плагина по',
        link: 'этой ссылке',
      },
    },
  },
  confirmation: {
    selectQuota: {
      title: 'Найдена квота',
      content: 'Подтвердить использование этой квоты: {{value}}?',
      confirm: 'Да, использовать',
      cancel: 'Нет',
    },
  },
};
