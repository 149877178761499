<ng-template #invite *ngIf="isSuccess$ | async; else inviteError">
  <transport-alert title="{{'shared.passport.confirmEmail.invitationActivated' | translate}}" titleVariant="top"
    state="success">
    {{'shared.passport.confirmEmail.invitationOrdersAccess' | translate}}.
  </transport-alert>
</ng-template>

<ng-template #inviteError>
  <div class="flex items-center flex-col mt-2">
    <p class="text-xl">Заказчик ещё не начинал аккредитацию</p>
  </div>
</ng-template>
