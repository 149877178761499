import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormControl, ValidatorFn, Validators } from '@angular/forms';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import moment, { Moment } from 'moment';

const MIN_DATE_LENGTH = 10;
@UntilDestroy()
@Component({
  selector: 'transport-tw-datepicker',
  templateUrl: './tw-datepicker.component.html',
  styleUrls: ['./tw-datepicker.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- Cuz no ways to detect [control] touched and validity status.
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TnTwDatepickerComponent implements AfterViewInit {
  @Input() public label = '';

  @Input() public placeholder = 'дд.мм.гггг';

  @Input() public size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';

  @Input() public required = false;

  @Input() public validationMoreThanNow = false;

  @Input() public showErrors = false;

  @Input() public control!: FormControl;

  @ViewChild('inputField') public inputField!: ElementRef;

  public dateControl = new FormControl(); //Необходим, чтобы маска работала корректно.

  private previousValue: string = ''; //необходим, чтобы снизить число перезагрузок в случаях валидации (в фильтрах)

  constructor() {}

  public ngAfterViewInit() {
    const validation = [this.isValidDate(this.required)];

    if (this.validationMoreThanNow) {
      validation.push(this.moreThanNowValidator());
    }

    if (this.control.validator) {
      validation.push(this.control.validator);
    }

    this.control.setValidators(Validators.compose(validation));
    this.control.valueChanges.pipe(untilDestroyed(this)).subscribe(res => {
      if (res === null) {
        (this.inputField.nativeElement as HTMLInputElement).value = '';
      }
    });
  }

  isValidDate(isRequired): ValidatorFn {
    return (formControl: AbstractControl) => {
      const value: string | moment.Moment = formControl?.value;
      if (Boolean(value) && moment(value, 'DD.MM.YYYY').isValid()) {
        return null;
      }
      if (!Boolean(value) && !Boolean(isRequired)) {
        return null;
      }
      return { isDateInvalid: true };
    };
  }

  moreThanNowValidator(): ValidatorFn {
    return (formControl: AbstractControl) => {
      const value: moment.Moment = typeof formControl.value === 'string' ? moment(formControl.value, 'DD.MM.YYYY') : formControl.value;
      if (Boolean(value)) {
        const now = moment();
        if (now.isAfter(value)) {
          return {
            moreThanNow: true,
          };
        }
      }
      return null;
    };
  }

  public onKeyUp(event: any) {
    if (
      event.target.value &&
      ((event.target.value as string).length < MIN_DATE_LENGTH || !moment(event.target.value, 'DD.MM.YYYY').isValid())
    ) {
      return;
    }
    if (this.previousValue != event.target.value) {
      this.control.markAsTouched();
      this.control.setValue(event.target.value);
      this.dateControl.markAsTouched();
      this.dateControl.setValue(moment(event.target.value, 'DD.MM.YYYY').format('DD.MM.YYYY') || null);
      this.previousValue = event.target.value;
    }
  }

  public onCalendarInput(event: MatDatepickerInputEvent<Moment>) {
    this.control.markAsTouched();
    this.control.setValue(event.value?.format('DD.MM.YYYY'));
    this.dateControl.markAsTouched();
    this.dateControl.setValue(event.value);
    this.previousValue = event.value?.format('DD.MM.YYYY') || '';
  }

  public get inputValue() {
    return this.control.value ? moment(this.control.value, 'DD.MM.YYYY').format('DD.MM.YYYY') : '';
  }
}
