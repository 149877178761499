import { IRegExpConfig } from './regexp.interface';

/**
 * Regular expression config.
 * Contains common regular expression patterns used within application.
 */
export const regExpConfig: IRegExpConfig = {
  ngxMask: {
    dimensions: {
      mask: 'AA       A       A||A       A       A',
      patterns: {
        A: {
          pattern: new RegExp(/[1-9]/),
          symbol: 'A',
        },
        D: {
          pattern: new RegExp(/[1-9]/),
          symbol: 'B',
        },
      },
    },
    // ИНН
    tin: {
      ru: {
        phys: {
          mask: '000000000000',
        },
        corp: {
          mask: '0000000000',
        },
      },
      // TODO: when project goes international
      any: {
        phys: {
          mask: '0*',
        },
      },
    },
    // Номер счета в банке
    bankAcc: {
      mask: '00000000000000000000',
    },
    // БИК банка
    bankBic: {
      mask: '000000000',
    },
    // Корр. счет
    corrAcc: {
      mask: '00000000000000000000',
    },
    // КПП
    iec: {
      ru: {
        mask: '000000000',
      },
      // TODO: when project goes international
      any: {
        mask: '0*',
      },
    },
    // ОГРН
    psrn: {
      ru: {
        corp: {
          mask: '0000000000000',
        },
        ip: {
          mask: '000000000000000',
        },
      },
      // TODO: when project goes international
      any: {
        mask: '0*',
      },
    },
    phone: {
      // ruInput is necessary because in phoneInput, we use prefix +7
      ruInput: {
        mask: '(DDD)DDD-DD-DD',
        patterns: {
          D: {
            pattern: new RegExp(/[0-9]/),
            symbol: 'D',
          },
        },
      },
      ru: {
        mask: '+P(DDD)DDD-DD-DD',
        patterns: {
          P: {
            pattern: new RegExp(/[7]/),
            symbol: 'P',
          },
          D: {
            pattern: new RegExp(/[0-9]/),
            symbol: 'D',
          },
        },
      },
      ruSpace: {
        mask: '+P DDD DDD DD DD',
        patterns: {
          P: {
            pattern: new RegExp(/[7]/),
            symbol: 'P',
          },
          D: {
            pattern: new RegExp(/[0-9]/),
            symbol: 'D',
          },
        },
      },
      byInput: {
        mask: '(DD)DDD-DD-DD',
        patterns: {
          D: {
            pattern: new RegExp(/[0-9]/),
            symbol: 'D',
          },
        },
      },
    },
    temperature: {
      mask: 'SNNN',
      patterns: {
        S: {
          pattern: new RegExp(/((^|, )([0-9]|-|))+$/),
          symbol: 'S',
        },
        N: {
          pattern: new RegExp(/[0-9]/),
          symbol: 'N',
        },
      },
    },
    iin: {
      kz: {
        mask: 'DDDDDDDDDDDD',
        patterns: {
          D: {
            pattern: new RegExp(/[0-9]/),
            symbol: 'D',
          },
        },
      },
    },
    citizenPassport: {
      ru: {
        series: {
          mask: 'DDDD',
          patterns: {
            D: {
              pattern: new RegExp(/[0-9]/),
              symbol: 'D',
            },
          },
        },
        number: {
          mask: 'DDDDDD',
          patterns: {
            D: {
              pattern: new RegExp(/[0-9]/),
              symbol: 'D',
            },
          },
        },
      },
      any: {
        mask: 'X*',
        patterns: {
          X: {
            pattern: new RegExp(/[0-9a-zA-Zа-яА-Я]/),
            symbol: 'X',
          },
        },
      },
    },
    driverLicence: {
      noRu: {
        series: {
          mask: 'KK KK',
          patterns: {
            K: {
              pattern: new RegExp(/[авекмнорстухАВЕКМНОРСТУХ0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz]/),
              symbol: 'K',
            },
          },
        },
      },
      ru: {
        series: {
          mask: 'DD KK',
          patterns: {
            D: {
              pattern: new RegExp(/\d/),
              symbol: 'D',
            },
            K: {
              pattern: new RegExp(/[авекмнорстухАВЕКМНОРСТУХ0-9]/),
              symbol: 'K',
            },
          },
        },
        number: {
          mask: 'DDDDDD',
          patterns: {
            D: {
              pattern: new RegExp(/\d/),
              symbol: 'D',
            },
          },
        },
      },
      kz: {
        series: {
          mask: 'DDKK',
          patterns: {
            D: {
              pattern: new RegExp(/[авекмнорстухАВЕКМНОРСТУХABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0-9]/),
              symbol: 'D',
            },
            K: {
              pattern: new RegExp(/[авекмнорстухАВЕКМНОРСТУХABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0-9]/),
              optional: true,
              symbol: 'K',
            },
          },
        },
        number: {
          mask: 'DDDDDD',
          patterns: {
            D: {
              pattern: new RegExp(/[0-9]/),
              symbol: 'D',
            },
            K: {
              pattern: new RegExp(/[0-9]/),
              optional: true,
              symbol: 'K',
            },
          },
        },
      },
      tj: {
        series: {
          mask: 'DDKK',
          patterns: {
            D: {
              pattern: new RegExp(/[авекмнорстухАВЕКМНОРСТУХABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0-9]/),
              symbol: 'D',
            },
            K: {
              pattern: new RegExp(/[авекмнорстухАВЕКМНОРСТУХABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0-9]/),
              optional: true,
              symbol: 'K',
            },
          },
        },
        number: {
          mask: 'DDDDDKK',
          patterns: {
            D: {
              pattern: new RegExp(/[0-9]/),
              symbol: 'D',
            },
            K: {
              pattern: new RegExp(/[0-9]/),
              optional: true,
              symbol: 'K',
            },
          },
        },
      },
      uz: {
        series: {
          mask: 'DDKK',
          patterns: {
            D: {
              pattern: new RegExp(/[авекмнорстухАВЕКМНОРСТУХABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0-9]/),
              symbol: 'D',
            },
            K: {
              pattern: new RegExp(/[авекмнорстухАВЕКМНОРСТУХABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0-9]/),
              optional: true,
              symbol: 'K',
            },
          },
        },
        number: {
          mask: 'DDDDDKK',
          patterns: {
            D: {
              pattern: new RegExp(/[0-9]/),
              symbol: 'D',
            },
            K: {
              pattern: new RegExp(/[0-9]/),
              optional: true,
              symbol: 'K',
            },
          },
        },
      },
      kg: {
        series: {
          mask: 'DDKK',
          patterns: {
            D: {
              pattern: new RegExp(/[авекмнорстухАВЕКМНОРСТУХABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0-9]/),
              symbol: 'D',
            },
            K: {
              pattern: new RegExp(/[авекмнорстухАВЕКМНОРСТУХABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0-9]/),
              optional: true,
              symbol: 'K',
            },
          },
        },
        number: {
          mask: 'DDDDDDKK',
          patterns: {
            D: {
              pattern: new RegExp(/[0-9]/),
              symbol: 'D',
            },
            K: {
              pattern: new RegExp(/[0-9]/),
              optional: true,
              symbol: 'K',
            },
          },
        },
      },
      by: {
        series: {
          mask: 'DDK',
          patterns: {
            D: {
              pattern: new RegExp(/[авекмнорстухАВЕКМНОРСТУХABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0-9]/),
              symbol: 'D',
            },
            K: {
              pattern: new RegExp(/[авекмнорстухАВЕКМНОРСТУХABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0-9]/),
              optional: true,
              symbol: 'K',
            },
          },
        },
        number: {
          mask: 'DDDDDDKK',
          patterns: {
            D: {
              pattern: new RegExp(/[0-9]/),
              symbol: 'D',
            },
            K: {
              pattern: new RegExp(/[0-9]/),
              optional: true,
              symbol: 'K',
            },
          },
        },
      },
      default: {
        series: {
          mask: 'K*',
          patterns: {
            K: {
              pattern: new RegExp(/[авекмнорстухАВЕКМНОРСТУХABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0-9]/),
              optional: true,
              symbol: 'K',
            },
          },
        },
        number: {
          mask: 'KKKKKKKK',
          patterns: {
            K: {
              pattern: new RegExp(/[0-9]/),
              optional: true,
              symbol: 'K',
            },
          },
        },
      },
    },
    vehiclePassport: {
      vin: {
        mask: 'SSSSSSSSSSSSSSSSS',
        patterns: {
          S: {
            pattern: new RegExp(/[0123456789ABCDEFGHJKLMNPRSTUVWXYZabcdefghjklmnprstuvwxyz]/),
            symbol: 'S',
          },
        },
      },
      ru: {
        registrationNumber: {
          mask: 'L DDD LL DDO', // последний символ может быть не цифрой. По этому в компоненте TnTruckFormComponent был придуман костыл  в this.form.controls.vehicleId.valueChanges
          patterns: {
            D: {
              pattern: new RegExp(/\d/),
              symbol: 'D',
            },
            O: {
              pattern: new RegExp(/\d/),
              symbol: 'O',
              optional: true, // из-за этого
            },
            L: {
              pattern: new RegExp(/[авекмнорстухАВЕКМНОРСТУХ]/),
              symbol: 'L',
            },
          },
        },
        registrationCertificate: {
          mask: 'DD KK DDDDDD',
          patterns: {
            D: {
              pattern: new RegExp(/[0-9]/),
              symbol: 'D',
            },
            K: {
              pattern: new RegExp(/[A-ZА-Я0-9]+/),
              symbol: 'K',
            },
          },
        },
        trailerNumber: {
          mask: 'LLDDDD DD',
          patterns: {
            D: {
              pattern: new RegExp(/\d/),
              symbol: 'D',
            },
            L: {
              pattern: new RegExp(/[авекмнорстухАВЕКМНОРСТУХ]/),
              symbol: 'L',
            },
          },
        },
      },
      kz: {
        registrationNumber: {
          mask: 'QQQQWWWW',
          patterns: {
            Q: {
              pattern: new RegExp(/[A-Za-z0-9 ]/),
              symbol: 'Q',
            },
            W: {
              pattern: new RegExp(/[A-Za-z0-9 ]/),
              symbol: 'W',
              optional: true,
            },
          },
        },
        trailerNumber: {
          mask: 'QQQQWWWW',
          patterns: {
            Q: {
              pattern: new RegExp(/[A-Za-z0-9 ]/),
              symbol: 'Q',
            },
            W: {
              pattern: new RegExp(/[A-Za-z0-9 ]/),
              symbol: 'W',
              optional: true,
            },
          },
        },
      },
      uz: {
        registrationNumber: {
          mask: 'QQQQQQQQW',
          patterns: {
            Q: {
              pattern: new RegExp(/[A-Za-z0-9 ]/),
              symbol: 'Q',
            },
            W: {
              pattern: new RegExp(/[A-Za-z0-9 ]/),
              symbol: 'W',
              optional: true,
            },
          },
        },
        trailerNumber: {
          mask: 'QQQQQQQQW',
          patterns: {
            Q: {
              pattern: new RegExp(/[A-Za-z0-9 ]/),
              symbol: 'Q',
            },
            W: {
              pattern: new RegExp(/[A-Za-z0-9 ]/),
              symbol: 'W',
              optional: true,
            },
          },
        },
      },
      kg: {
        registrationNumber: {
          mask: 'QQQQQQQW',
          patterns: {
            Q: {
              pattern: new RegExp(/[A-Za-z0-9 ]/),
              symbol: 'Q',
            },
            W: {
              pattern: new RegExp(/[A-Za-z0-9 ]/),
              symbol: 'W',
              optional: true,
            },
          },
        },
        trailerNumber: {
          mask: 'QQQQQQQW',
          patterns: {
            Q: {
              pattern: new RegExp(/[A-Za-z0-9 ]/),
              symbol: 'Q',
            },
            W: {
              pattern: new RegExp(/[A-Za-z0-9 ]/),
              symbol: 'W',
              optional: true,
            },
          },
        },
      },
      tj: {
        registrationNumber: {
          mask: 'QQQQQQQW',
          patterns: {
            Q: {
              pattern: new RegExp(/[A-Za-z0-9 ]/),
              symbol: 'Q',
            },
            W: {
              pattern: new RegExp(/[A-Za-z0-9 ]/),
              symbol: 'W',
              optional: true,
            },
          },
        },
        trailerNumber: {
          mask: 'QQQQQQQW',
          patterns: {
            Q: {
              pattern: new RegExp(/[A-Za-z0-9 ]/),
              symbol: 'Q',
            },
            W: {
              pattern: new RegExp(/[A-Za-z0-9 ]/),
              symbol: 'W',
              optional: true,
            },
          },
        },
      },
      by: {
        registrationNumber: {
          mask: 'QQQQQQ - W',
          patterns: {
            Q: {
              pattern: new RegExp(/[A-Za-z0-9]/),
              symbol: 'Q',
            },
            W: {
              pattern: new RegExp(/[1-7]/),
              symbol: 'W',
            },
          },
        },
        trailerNumber: {
          mask: 'QQQQQQ - W',
          patterns: {
            Q: {
              pattern: new RegExp(/[A-Za-z0-9]/),
              symbol: 'Q',
            },
            W: {
              pattern: new RegExp(/[1-7]/),
              symbol: 'W',
            },
          },
        },
      },
      // TODO: when project goes international
      any: {
        registrationNumber: {
          mask: 'X*',
          patterns: {
            X: {
              pattern: new RegExp(/[0-9a-zA-Zа-яА-Я]/),
              symbol: 'X',
            },
          },
        },
        registrationCertificate: {
          mask: 'X*',
          patterns: {
            X: {
              pattern: new RegExp(/[0-9a-zA-Z]/),
              symbol: 'X',
            },
          },
        },
        trailerNumber: {
          mask: 'X*',
          patterns: {
            X: {
              pattern: new RegExp(/[0-9a-zA-Zа-яА-Я]/),
              symbol: 'X',
            },
          },
        },
      },
    },
    currency: {
      rur: {
        mask: 'separator.2',
        patterns: {},
      },
    },
    email: {
      mask: 'X*@D*',
      patterns: {
        X: {
          pattern: new RegExp(/[.0-9a-zA-Z_-]/),
          symbol: 'X',
        },
        D: {
          pattern: new RegExp(/[a-zA-Zа-яА-Я0-9.-]/),
          symbol: 'D',
        },
      },
    },
    digest: {
      mask: '0{9}',
    },
    contractNumber: {
      mask: '0*',
    },
  },

  mobileUserAgent: {
    regExp: /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i,
  },

  clearEmail: {
    regExp: /[\s!?"'`#№$%:;,()<>~=+*^|§±[\]\/\\&]/g,
  },

  /**
   * Phone number regular expressions.
   */
  phoneNumber: {
    RU: /\+7[1-9]\d{9}/,
    LESS_RU: /\d{10}/,
    LESS_BY: /\d{9}/,
  },

  coordinates: { value: /^-?([0-9]{1,2}|1[0-7][0-9]|180)(\.[0-9]{1,10})$/ },

  /**
   * Zip code regular expressions.
   */
  zipCode: {
    RU: /\d{6}/,
  },

  inn: {
    RU: /^[0-9]{10}$/, // Идентификационный номер налогоплательщика(юр лицо) 10 цифр
  },

  innPhysOrIp: {
    RU: /^[0-9]{12}$/, // Идентификационный номер налогоплательщика(физ лицо или ИП) 12 цифр
  },

  innPhysOrLegal: {
    RU: /^[0-9]{10}$|^[0-9]{12}$/, // Идентификационный номер налогоплательщика (для юр лица / физ лица или ИП) 10 или 12 цифр
  },

  ogrn: {
    RU: /^[0-9]{13}$/, // ОГРН (юр лицо) 13 цифр
  },

  ogrnIp: {
    RU: /^[0-9]{15}$/, // ОГРН (физ лицо или ИП) 15 цифр
  },

  kpp: {
    RU: /^[0-9]{9}$/,
  },

  signer: {
    RU: /[.А-Яа-я ]{1,}/,
  },

  email: {
    regExp:
      /^(([0-9A-Za-z]+[-0-9A-z.]*[0-9A-Za-z]*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,})|(([а-яА-Я\-0-9]+\.)+[а-яА-ЯёЁ]{2,}))$/,
  },
  fio: {
    regExp: /^.+/,
  },
  domain: {
    regExp: /^[a-zA-Z0-9][a-zA-Z0-9-]{0,61}[a-zA-Z0-9]$/,
  },
};
