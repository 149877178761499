import { TColorScheme } from '@transport/ui-interfaces';

/**
 * Passport widget activated mode.
 */
export interface IPassportPathsRestriction {
  index: boolean;
  login: boolean;
  signup: boolean;
}

export enum PASSPORT_PATH {
  LOGIN = 'login',
  SIGNUP = 'signup',
  INDEX = 'index',
  REGISTRATION = 'registration',
  FORGOT_PASSWORD = 'forgot-password',
}

export interface IPassportAccessRecovery {
  token: string;
  email: string;
  isExpired: boolean;
}

export interface IPassportConfirmEmailData {
  token: string;
  email: string;
  newEmail: string;
  isExpired: boolean;
}

export interface IPassportNavigation {
  step: number;
  isForwardOnly: boolean;
}

export interface IPassportState {
  title: string;
  color: TColorScheme;
  currentPath: PASSPORT_PATH | string;
  pathsRestriction: IPassportPathsRestriction;
  displayConfig: boolean;
  redirectUrlAfterLogin: string;
  navigation: IPassportNavigation;
  accessRecovery?: IPassportAccessRecovery;
  confirmEmailData?: IPassportConfirmEmailData;
}

export const passportFeatureName = 'passport';
