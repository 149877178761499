import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TnCurrentUserService } from '@marketplace/auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { TOAST_DURATION, USER_ROLE } from '@transport/ui-interfaces';
import { TnAlertService } from '@transport/ui-kit';
import { TnGqlClientSharedService, TnHttpHandlersService } from '@transport/ui-store';
import { LOCAL_HOST_DOMAIN } from '@transport/ui-utils';
import { ALERT_STATE, IAlertInputs } from 'libs/transport-ui-kit/src/lib/alert/alert.interfaces';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';
import { BehaviorSubject, catchError, mergeMap, Observable, Subject, take, tap, throwError } from 'rxjs';
import { PARTNERS_QUERIES } from './partners-queries';

export interface CargoOwnerWithBusinessRelationshipWithCarrier {
  name: string;
  carrierBusinessRelationshipState: string; //TODO: Enum
  subdomain: string;
  logo: string;
}
export interface PartnersList {
  cargoOwnerWithBusinessRelationshipWithCarrier: CargoOwnerWithBusinessRelationshipWithCarrier[];
}

@UntilDestroy()
@Injectable()
export class PartnersService {
  private readonly startPartnerCheckUrl: string = './p/org/r/create_business_relationship';

  public readonly loadingFinish$ = new Subject();

  public readonly partnersList$ = new BehaviorSubject<CargoOwnerWithBusinessRelationshipWithCarrier[]>([]);

  constructor(
    private readonly sharedGraphQlClient: TnGqlClientSharedService,
    private readonly http: HttpClient,
    private readonly handlers: TnHttpHandlersService,
    private readonly alertService: TnAlertService,
    private readonly translateService: TranslateService,
    private readonly currentUserService: TnCurrentUserService,
    private readonly domainService: TnDomainService,
  ) {
    this.currentUserService.isLoggedIn$.pipe(untilDestroyed(this)).subscribe(token => {
      // Для обновления списка партнеров, если ГП авторизовался
      if (token) {
        /**
         * Добавил setTimeout так как при регистрации с автоматической аккредитацией
         * с бэка прилетает пустой массив партнеров, хотя это не так.
         * Если немного отложить отправку запроса, то партнеры подгружаются.
         */
        setTimeout(() => {
          this.getPartnersList().pipe(take(1)).subscribe();
        }, 150);
      } else {
        this.partnersList$.next([]);
      }
    });
  }

  public startPartnersCheck(owner_org_id: string): Observable<PartnersList> {
    return this.http
      .post(this.handlers.getEndpointUrl(this.startPartnerCheckUrl), { owner_org_id }, { headers: this.handlers.getAuthHeaders() })
      .pipe(
        mergeMap(() => {
          this.startPartnersCheckSuccess();
          return this.getPartnersList();
        }),
        catchError((err: Error) => {
          this.startPartnersCheckError(err);
          return throwError(() => err);
        }),
      );
  }

  public redirectToMarket(): void {
    const localHostPort = this.domainService.domain.includes(LOCAL_HOST_DOMAIN) ? ':4300' : '';
    const url = `${document.location.protocol}//${this.domainService.domain}${localHostPort}`;
    window.location.href = url;
  }

  public redirectToSubdomain(subdomain: string, pathname = ''): void {
    const localHostPort = this.domainService.domain.includes(LOCAL_HOST_DOMAIN) ? ':4300' : '';
    const url = `${document.location.protocol}//${subdomain}.${this.domainService.domain}${localHostPort}${pathname ? pathname : document.location.pathname}`;
    window.location.href = url;
  }

  public openNewTabWithSubdomain(subdomain: string, newTab = false): void {
    const localHostPort = this.domainService.domain.includes(LOCAL_HOST_DOMAIN) ? ':4300' : '';
    const path = document.location.pathname.includes('/orders') ? '/my-orders' : document.location.pathname;
    const url = `${document.location.protocol}//${subdomain}.${this.domainService.domain}${localHostPort}${path}`;
    newTab ? window.open(url, '_blank') : window.open(url);
  }

  private getPartnersList(): Observable<PartnersList> {
    return this.sharedGraphQlClient.query<PartnersList>(USER_ROLE.CARRIER, PARTNERS_QUERIES.list, 1).pipe(
      tap(res => {
        this.partnersList$.next(res.cargoOwnerWithBusinessRelationshipWithCarrier)
        this.loadingFinish$.next(true);
      }),
      catchError((err: Error) => {
        this.refreshPartnersError(err);
        return throwError(() => err);
      }),
    );
  }

  private startPartnersCheckSuccess(): void {
    this.alertService.openAlert(
      {
        title: this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.errors.startSuccess.title'),
        state: ALERT_STATE.SUCCESS,
        message: this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.errors.startSuccess.message'),
      } as IAlertInputs,
      TOAST_DURATION.SHORT,
    );
  }
  private startPartnersCheckError(err: Error): void {
    this.alertService.openAlert(
      {
        title: this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.errors.startError.title'),
        state: ALERT_STATE.ERROR,
        message: err.message,
      } as IAlertInputs,
      TOAST_DURATION.SHORT,
    );
  }

  private refreshPartnersError(err: Error) {
    this.alertService.openAlert(
      {
        title: this.translateService.instant('shared.mp.cargo.table.popup.partnersCheck.errors.refreshPartnersError.title'),
        state: ALERT_STATE.ERROR,
        message: err.message,
      } as IAlertInputs,
      TOAST_DURATION.SHORT,
    );
  }
}
