import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, switchMap } from 'rxjs/operators';

import { TnGqlClientOwnerQueriesService } from '../gql-client-owner/graphql-client-owner-queries.service';
import { getTimezones, getTimezonesSuccess } from './time-zone.actions';

@Injectable({
  providedIn: 'root',
})
export class TnTimeZoneEffects {
  constructor(private readonly action$: Actions, private readonly gqlOwner: TnGqlClientOwnerQueriesService) {}

  public loadTimeZones$ = createEffect(() => {
    return this.action$.pipe(
      ofType(getTimezones),
      switchMap(action => {
        return this.gqlOwner.getTimezones(false).pipe(map(response => getTimezonesSuccess({ timezones: response.timezones })));
      }),
    );
  });
}
