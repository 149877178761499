import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit, AfterViewInit } from '@angular/core';
import { AbstractControl, AsyncValidatorFn, FormBuilder, FormControl, ValidationErrors } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { APP_ENV, IRegByCodeForm, newRegByCodeForm, TnAppEnvironment, TOAST_DURATION } from '@transport/ui-interfaces';
import { TnToasterFacade, TOAST_TYPE } from '@transport/ui-store';
import { KEYDOWN_DELAY, LICENSE_AGREEMENT_URL, PRIVACY_POLICY_URL, regExpConfig, SECRET_FERNET } from '@transport/ui-utils';
import { TnAnalyticsService } from 'libs/transport-ui-store/src/lib/current-user/services/analytics/analytics.service';
import { catchError, debounceTime, first, map, Observable, Subject, switchMap, takeUntil, tap, throwError } from 'rxjs';

import { USER_ROLE } from '../../../../../transport-ui-interfaces/src/lib/user/user-common.interface';
import { TnMarketplaceAuthService } from '../../shared/services/marketplace-auth.service';
import fernet from 'fernet';
import { CountryNames } from '@transport/ui-pipes';
const secretFernet = new fernet.Secret(SECRET_FERNET);

@UntilDestroy()
@Component({
  selector: 'transport-registration-by-code',
  templateUrl: './registration-by-code.component.html',
  styleUrls: ['./registration-by-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnMarketplaceRegistrationByCodeComponent implements OnInit, OnDestroy, AfterViewInit {
  public registrationForm: IRegByCodeForm | null = newRegByCodeForm(
    this.fb,
    {
      role: '',
      inn: '',
      fullName: '',
      phoneNumber: '',
      email: '',
      companyName: '',
      country: null
    },
    false,
  );

  public confirm = false;

  public isLoading = false;

  public licenseAgreementURL = LICENSE_AGREEMENT_URL;

  public userAgreementURL = PRIVACY_POLICY_URL;

  private readonly destroySubject$ = new Subject();

  public selfEmployed = new FormControl(false);

  private roistatVisit!: string;

  private emailParam = '';

  countries = Object.keys(CountryNames).map(item => ({id: CountryNames[item], label: item}));


  @Input() public inviteToken: string | undefined;

  constructor(
    private readonly fb: FormBuilder,
    public readonly translate: TranslateService,
    private readonly authService: TnMarketplaceAuthService,
    private readonly cdr: ChangeDetectorRef,
    private readonly toastFacade: TnToasterFacade,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly analytics: TnAnalyticsService,
    @Inject(APP_ENV) public readonly env: TnAppEnvironment,
  ) {}

  public ngOnInit(): void {
    this.route.queryParams.pipe(untilDestroyed(this)).subscribe(params => {
      if (Boolean(params.roistat_visit)) {
        this.roistatVisit = params.roistat_visit;
      }
      if (Boolean(params.email)) {
        const email = new fernet.Token({
          secret: secretFernet,
          token: params.email,
          ttl: 0,
        });
        this.emailParam = email?.decode();
      }
    });

    this.analytics.track('user saw registration page');
    this.emailControl?.setAsyncValidators(this.validationEmail());
    this.emailControl?.valueChanges.pipe(takeUntil(this.destroySubject$)).subscribe((val: string) => {
      const newValue = val.replace(regExpConfig.clearEmail.regExp, '').trim();
      if (newValue.length !== val.length) {
        this.emailControl?.setValue(newValue);
      }
    });
  }

  public ngAfterViewInit() {
    if (this.emailParam) {
      this.emailControl?.setValue(this.emailParam);
      this.emailControl?.markAsTouched();
    }
  }

  public ngOnDestroy(): void {
    this.destroySubject$.next(null);
  }

  public get isRoleControl() {
    return this.registrationForm?.controls?.role;
  }

  public get emailControl() {
    return this.registrationForm?.controls?.email;
  }

  public get fullNameControl() {
    return this.registrationForm?.controls?.fullName;
  }

  public get phoneControl() {
    return this.registrationForm?.controls?.phoneNumber;
  }

  public get companyNameControl() {
    return this.registrationForm?.controls?.companyName;
  }

  public get countryControl() {
    return this.registrationForm?.controls?.country;
  }

  public submit(event?: Event) {
    event?.preventDefault();
    this.registrationForm?.markAllAsTouched();

    this.analytics.track('user clicked register', { registrationFormValid: this.registrationForm?.valid });

    if (Boolean(this.registrationForm?.valid)) {
      const data: any = {
        fullName: this.fullNameControl?.value,
        phoneNumber: this.phoneControl?.value,
        email: this.emailControl?.value,
        country: this.countryControl?.value.id,
        companyName: this.selfEmployed.value ? this.fullNameControl?.value : this.companyNameControl?.value,
        selfEmployed: this.isRoleControl?.value === 'CARRIER' ? this.selfEmployed.value : false,
      };
      if (Boolean(this.inviteToken)) {
        data.inviteToken = this.inviteToken;
      } else {
        data.role = this.isRoleControl?.value;
      }
      if (this.roistatVisit) {
        data.visit_id = this.roistatVisit;
      }

      this.authService
        .signUpByCode(data)
        .pipe(
          untilDestroyed(this),
          catchError(error => {
            this.toastFacade.showMessage(error, TOAST_TYPE.ERROR, TOAST_DURATION.SHORT);
            return throwError(() => error);
          }),
        )
        .subscribe(() => {
          // Для корректного редиректа ГВ на МастерТМС после подтверждения почты (см. TnMarketplaceSubmitCodeComponent authentication())
          const role = this.isRoleControl?.value === 'CARGO_OWNER' ? USER_ROLE.CARGO_OWNER : USER_ROLE.CARRIER;
          localStorage.setItem('isRoleRegistred', role);

          this.isLoading = false;
          this.authService.authenticationData = { ...this.registrationForm?.value, token: this.inviteToken };
          this.router.navigate(['passport', 'submit-code']);
        });
    }
  }

  public resendEmail() {
    this.isLoading = true;
    void this.authService
      .confirmEmailAgain()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.isLoading = false;
        this.cdr.markForCheck();
        this.toastFacade.showMessage('shared.passport.login.letterSent', TOAST_TYPE.SUCCESS, TOAST_DURATION.SHORT);
      });
  }

  private validationEmail(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<ValidationErrors | null> => {
      return control.valueChanges.pipe(
        untilDestroyed(this),
        debounceTime(KEYDOWN_DELAY),
        switchMap(value => {
          return this.authService.checkEmailExist(value);
        }),
        map(res => (Boolean(res.emailExist) ? { isEmailExist: true } : null)),
        first(),
        tap(() => {
          this.cdr.markForCheck();
        }),
      );
    };
  }

  public fioValidationErrors(e: ValidationErrors | null) {
    if (e?.required) return this.translate.instant('shared.auth.registration.form.errors.noFio');
    if (e?.trimmedString) return this.translate.instant('shared.errors.trimmedString');
    if (e?.maxlength)
      return `${this.translate.instant('shared.auth.registration.form.errors.fioMaxlength')} ${
        this.fullNameControl?.errors?.maxlength?.requiredLength
      }`;

    return '';
  }

  public phoneValidationErrors(e: ValidationErrors | null) {
    if (e?.required) return this.translate.instant('shared.auth.registration.form.errors.noPhone');
    if (e?.pattern) return this.translate.instant('shared.auth.registration.form.errors.incorrectPhone');

    return '';
  }

  public emailValidationErrors(e: ValidationErrors | null) {
    if (e?.isEmailExist) return this.translate.instant('shared.errors.emailDublicate');
    if (e?.pattern && !e?.maxlength) return this.translate.instant('shared.errors.email');
    if (e?.maxlength)
      return `${this.translate.instant('shared.auth.login.form.email.errors.maxlength')} ${
        this.emailControl?.errors?.maxlength?.requiredLength
      }`;
    if (e?.required) return this.translate.instant('shared.errors.nologin');

    return '';
  }

  public companyValidationErrors(e: ValidationErrors | null) {
    if (e?.required) return this.translate.instant('shared.auth.registration.form.errors.noCompany');
    if (e?.maxlength) return `${this.translate.instant('shared.auth.registration.form.errors.companyMaxlength')} ${this.companyNameControl?.errors?.maxlength?.requiredLength}`;

    return '';
  }

  public countryValidationErrors(e: ValidationErrors | null) {
    if (e?.required) return this.translate.instant('shared.auth.registration.form.errors.noCountry');

    return '';
  }

  public saveInviteToken(): void {
    this.authService.inviteToken = this.inviteToken ?? undefined;
  }
}
