<transport-tw-input-field
  class="!gap-0 w-full"
  [label]="label"
  [size]="size"
  [required]="required"
  [control]="$any(control)"
  [showErrors]="showErrors"
  [errorToMessage]="errorToMessage"
  [errorsSize]="errorsSize"
>
  <transport-tw-input [size]="size" [control]="$any(control)" class="mt-1 w-full">
    <mat-select
      [placeholder]="placeholder"
      [attr.data-test]="dataTest"
      disableOptionCentering
      panelClass="tw-menu-block w-full"
      [formControl]="control"
      (openedChange)="isOpen = $event"
      class="ml-1"
      [ngClass]="getMatSelectClasses()"
    >
      <mat-option *ngFor="let option of options" [value]="mainField ? option[mainField] : option">
        <transport-tw-menu-option [text]="option.name"></transport-tw-menu-option>
      </mat-option>
    </mat-select>
  </transport-tw-input>
</transport-tw-input-field>
