import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'transport-empty-list',
  templateUrl: './empty-list.component.html',
  styleUrls: ['./empty-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnEmptyListComponent {
  @Input() public message = 'shared.table.empty';
}
