/**
 * Склонение слов в зависимости от количества
 * @param number - количество чего-либо
 * @param localization - шаблон перевода
 * @returns - шаблон перевода в зависимости от переданного количества чего-либо
 */
export function wordDeclination(number: number, localization: string): string {
  if (number === 0) {
    return `${localization}.zero`;
  }
  const hundred = 100;
  const ten = 10;
  const one = 1;
  const twenty = 20;
  const five = 5;
  const twoDigitNumber = Math.abs(number) % hundred;
  const simpleNumber = number % ten;

  if (twoDigitNumber > ten && twoDigitNumber < twenty) {
    return `${localization}.other`;
  }

  if (simpleNumber > one && simpleNumber < five) {
    return `${localization}.fromTwoToFive`;
  }
  if (simpleNumber === one) {
    return `${localization}.one`;
  }

  return `${localization}.other`;
}
