import { Component, ChangeDetectionStrategy } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { trimmedStringValidator } from '@transport/ui-interfaces';
import { REJECT_MAX_SYMBOLS, REJECT_MIN_SYMBOLS } from '@transport/ui-store';

@Component({
  selector: 'marketplace-reject-accreditation-dialog',
  templateUrl: './reject-accreditation-dialog.component.html',
  styleUrls: ['./reject-accreditation-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MpRejectAccreditationDialogComponent{

  public input = new FormControl(
    '',
    Validators.compose([
      Validators.required,
      Validators.minLength(REJECT_MIN_SYMBOLS),
      Validators.maxLength(REJECT_MAX_SYMBOLS),
      trimmedStringValidator
    ])
  );

  constructor() {}
}
