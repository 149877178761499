import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TnCurrentUserEffects } from './current-user.effects';
import { currentUserReducer } from './current-user.reducer';

export const CURRENT_USER_EFFECTS = [TnCurrentUserEffects];

@NgModule({
  imports: [StoreModule.forFeature('currentUser', currentUserReducer), EffectsModule.forFeature(CURRENT_USER_EFFECTS)],
  exports: [StoreModule, EffectsModule],
})
export class TnCurrentUserStateModule {}
