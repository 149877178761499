import { gql } from 'apollo-angular';
import { ORDER_STATUS } from '@transport/ui-interfaces';

export const ACCEPT_ORDER_POPUP_QUERIES = {
  acceptOrder: gql`
    mutation acceptOrder($orderId: ID!, $acceptingPlace: OrderAcceptingPlaceEnum) {
      bookOrder(orderId: $orderId, acceptingPlace: $acceptingPlace) {
        id
        status
        driver {
          id
        }
      }
    }
  `,
};

export interface acceptDriverResponse {
  id: string;
  status: ORDER_STATUS;
  driver: {
    id: string | number;
  };
}
