import { IRowExtender } from '../row-extender.interface';

/**
 * Composes several extenders into the one.
 * @param {Function[]} extenders - List of `IRowExtenders`
 * @returns {IRowExtender} - Composed extender
 */
export function composeRowExtenders<T>(extenders: IRowExtender<T>[]): IRowExtender<T> {
  return (el, rowData?) => {
    const unsubs = extenders.map(extender => extender(el, rowData));
    return () => unsubs.forEach(unsub => unsub());
  };
}
