import { Injectable, Provider } from '@angular/core';
import { transport } from '@transport/proto';
import { fromDto, LEGAL_FORM_CODES, TAdminOrganizationForm, toDto, USER_ROLE } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnGqlClientOwnerMutationsService } from '../../../gql-client-owner/graphql-client-owner-mutations.service';
import { TnGqlClientOwnerQueriesService } from '../../../gql-client-owner/graphql-client-owner-queries.service';
import { TnGqlClientSharedService } from '../../../gql-client-shared';

@Injectable({
  providedIn: 'root',
})
export class TnAdminProfileApiService {
  constructor(
    private readonly gqlQuery: TnGqlClientOwnerQueriesService,
    private readonly gqlMutation: TnGqlClientOwnerMutationsService,
    private readonly graphQLShared: TnGqlClientSharedService,
  ) {}

  public getOrganization() {
    return this.gqlQuery.queryOrganization().pipe(map(dtoData => fromDto(dtoData.profile.organization)));
  }

  public getSubOrganizations() {
    return this.gqlQuery.querySubOrganizations().pipe(
      map(dtoData => {
        if (Boolean(dtoData) && Boolean(dtoData.subOrganizations)) {
          return dtoData.subOrganizations.map(fromDto);
        }
        return [];
      }),
    );
  }

  public saveOrganization(profile: TAdminOrganizationForm) {
    return this.gqlMutation.editOrganization(toDto(profile));
  }

  public getLegalForms() {
    const filter = ({ code }: transport.ILegalForm) =>
      [
        LEGAL_FORM_CODES.LIMITED_LIABILITY_COMPANY,
        LEGAL_FORM_CODES.INDIVIDUAL_ENTREPRENEUR,
        LEGAL_FORM_CODES.PUBLIC_JOINT_STOCK_COMPANY,
        LEGAL_FORM_CODES.NON_PUBLIC_JOINT_STOCK_COMPANY,
      ].some(legalFormCode => code === legalFormCode);
    return this.gqlQuery.queryLegalForms().pipe(map(dtoData => dtoData.legalForms.filter(filter)));
  }

  public getVatTypes(): Observable<transport.IOrganizationVatType[]> {
    return this.graphQLShared.queryOrganizationVatTypes(USER_ROLE.CARGO_OWNER);
  }

  public getPaymentTypes(): Observable<transport.IPaymentType[]> {
    return this.gqlQuery.queryPaymentTypes();
  }

  public getDefaultInsurance(): Observable<transport.Insurance.IContract> {
    return this.gqlQuery.getInsuranceContracts().pipe(map(data => data.myInsuranceContracts.find(c => c.isDefault) ?? {}));
  }
}
