<div class="flex justify-between" mat-dialog-title>
  <h2>{{ 'shared.chooseDriverDialog.title' | translate }}</h2>
  <mat-icon class="dialog-close icon_small" svgIcon="close" (click)="dialogRef.close(false)"></mat-icon>
</div>

<mat-progress-bar class="modal-sticky-progress-bar" *ngIf="$any(loading$ | async)" mode="indeterminate"></mat-progress-bar>

<div mat-dialog-content transportTooltip [isGlobalMode]="true" class="has-tall-footer table-container">
  <transport-table
    [tableName]="tableName"
    [columnNames]="columnNames"
    [dataSource]="dataSource"
    [rowActions]="rowActions"
    translatePath="shared.directory.drivers.table.column"
    [hasSettings]="$any(userFacade.permissions$ | async).generalReference?.manage"
    [hasIndicators]="true"
    (rowClick)="selectRow($event)"
    (rowDblClick)="selectDriver($event)"
    (search)="setFilter(transformValue($event))"
  >
    <transport-table-search-cell field="name"></transport-table-search-cell>
    <transport-table-search-cell field="surname"></transport-table-search-cell>
    <transport-table-search-cell field="phone"></transport-table-search-cell>
    <transport-table-column field="indicators">
      <ng-template let-driver="rowData">
        <transport-black-list-column
          [blackListInfo]="driver.blackListInfo | blackListViewData"
          [type]="blackListType.CHOOSE_DRIVER"
          [id]="driver.id"
          [returnUrl]="returnUrl"
        ></transport-black-list-column>
      </ng-template>
    </transport-table-column>
    <transport-table-column field="name" [hasTooltip]="true">
      <ng-template let-driver="rowData">
        {{ driver.name }}
      </ng-template>
    </transport-table-column>
    <transport-table-column field="surname" [hasTooltip]="true"></transport-table-column>
    <transport-table-column field="phone" [hasTooltip]="true">
      <ng-template let-driver="rowData">
        {{ driver.phone | phoneNumber }}
      </ng-template>
    </transport-table-column>
    <transport-table-column field="application">
      <ng-template let-driver="rowData">
        <mat-icon
          class="application-icon"
          transportTooltip
          [context]="{ message: (driverHasMobileAppTipMessage(driver.usesDriverMobile) | translate) }"
          [svgIcon]="driver.usesDriverMobile ? 'application-installed-outlined' : 'application-not-installed-outlined'"
        ></mat-icon>
      </ng-template>
    </transport-table-column>
  </transport-table>
  <div *ngIf="(isEmpty$ | async)" class="flex justify-center items-center">
    <div class="notice-message">
      {{ 'shared.chooseDriverDialog.emtyDriversList' | translate }}
    </div>
  </div>
  <mat-paginator
    #paginator
    showFirstLastButtons
    class="flex justify-center"
    [pageSizeOptions]="[5, 10, 20]"
    (page)="changePage($event)"
  >
  </mat-paginator>
</div>

<div mat-dialog-actions>
  <a class="link-create" matDialogClose [routerLink]="['/directory/carrier/drivers/create']" [queryParams]="{ returnUrl: returnUrl }">
    <mat-icon class="material-icons">add_circle</mat-icon>
    <span>{{ 'shared.chooseDriverDialog.createDriver' | translate }}</span>
  </a>

  <div class="action-buttons">
    <!--matDialogClose -->
    <button mat-raised-button color="primary" mat-button (click)="select()" [disabled]="!selectedRow">
      {{ 'shared.actions.select' | translate }}
    </button>
  </div>
</div>
