export const REJECT_MIN_SYMBOLS = 2;
export const REJECT_MAX_SYMBOLS = 500;
export const REJECT_MODAL_WIDTH = '590px';


export const COMPANY_IMAGE_EXTENSION = '.png, .jpeg, .svg, .ico, .jpg';
export const SUPPORT_TMS_PHONE = {
  text: '+ 7 (499) 959-09-11',
  href: 'tel:+74999590911',
};
export const SUPPORT_TMS_EMAIL = {
  text: 'support@master-tms.ru',
  href: 'mailto:support@master-tms.ru',
};
export const SUPPORT_TONNA_PHONE = {
  text: '+7 (499) 110-39-11',
  href: 'tel:+74991103911',
};
export const SUPPORT_TONNA_EMAIL = {
  text: 'support@market.master-tms.ru',
  href: 'mailto:support@market.master-tms.ru',
};
