import { NgModule } from '@angular/core';
import { TnUiKitModule } from '@transport/ui-kit';
import { TnUiPipesModule } from '@transport/ui-pipes';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnUiComponentsModule } from '@transport/ui-components';
import { TnUiDirectivesModule } from '@transport/ui-directives';
import { TableModule } from '@marketplace/shared/modules/table';
import { AppSharedModule } from '@marketplace/shared/modules/shared/app-shared.module';
import { DriversStatusColumnComponent } from './table/status-column/drivers-status-column.component';
import { FiltersToolbarComponent } from './filters/filters-toolbar/filters-toolbar.component';
import { DriversAmountService } from './filters/filters-toolbar/drivers-amount.service';
import { DriversTablePageComponent } from './table-page/drivers-table-page.component';
import { DriversTableComponent } from './table/drivers-table.component';
import { Route, RouterModule } from '@angular/router';
import { CustomControlsModule } from '@transport/custom-controls';


export const ROUTES: Route[] = [
  {
    path: '',
    component: DriversTablePageComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  declarations: [DriversTableComponent, DriversTablePageComponent, DriversStatusColumnComponent, FiltersToolbarComponent],
  imports: [
    TnUiKitModule,
    TnUiPipesModule,
    TnUiMaterialModule,
    TnUiDirectivesModule,
    TableModule,
    CustomControlsModule,
    TnUiComponentsModule,
    AppSharedModule,
    RouterModule.forChild(ROUTES)
  ],
  exports: [RouterModule],
  providers: [DriversAmountService],
})
export class DriversModule {}
