import { Injectable, OnDestroy } from '@angular/core';
import { TnGqlClientSharedService } from '@transport/ui-store';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, catchError, Observable, of, Subject, tap } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TOAST_DURATION, USER_ROLE } from '@transport/ui-interfaces';
import { TranslateService } from '@ngx-translate/core';
import { DatePipe } from '@angular/common';
import { DRIVER_QUERIES } from './driver-queries';
import { IMarketplaceDriverInput } from './driver.interface';
import { DriverModalComponent } from './edit-modal/driver-modal.component';
import { DriverCreateVm } from './form/driver-crate.vm';
import { DriverEditVm } from './form/driver-edit.vm';
import { TnModalAddTransportService } from 'libs/transport-notifications/src/lib/components/modal-add-transport/modal-add-transport.service';
import { TnDomainService } from '../../../../transport-ui-services/src/lib/domain/domain.service';
import { ALERT_STATE, ALERT_TITLE_VARIANT, IAlertInputs, TnAlertService } from '@transport/ui-kit';
import { ICommonSelectData } from 'libs/custom-controls/src/lib/common.interface';

export interface DriverFormVm {
  translate: TranslateService;
  title: string;
  actionBtnTxt: string;
  isSubdomainPage: boolean;
  driver$: BehaviorSubject<IMarketplaceDriverInput>;
  loading$: BehaviorSubject<boolean>;
  finish$: Subject<{ close: boolean; message: string; alertState: ALERT_STATE }>;
  organizations$: BehaviorSubject<ICommonSelectData[] | null>
  form: FormGroup;
  hasAgentCarrier$: BehaviorSubject<boolean>;
  getActionBtnTxt: () => string;
  action: () => void;
  dispose: () => void;
  getAgentCarriers: (searchQuery) => void;
}

@UntilDestroy()
@Injectable()
export class DriverService implements OnDestroy {
  public dialogRef?: MatDialogRef<any>;

  public vm: DriverFormVm | undefined;
  public role: USER_ROLE | undefined;

  constructor(
    private readonly sharedGraphQlClient: TnGqlClientSharedService,
    private readonly dialog: MatDialog,
    private readonly alertService: TnAlertService,
    public readonly translate: TranslateService,
    private readonly modalAddTransportService: TnModalAddTransportService,
    private readonly datePipe: DatePipe,
    private readonly domainService: TnDomainService,
  ) {}

  public sendDriverToArchive(role: USER_ROLE, driverId: string): Observable<any> {
    return this.sharedGraphQlClient.mutate(role as USER_ROLE, DRIVER_QUERIES.archiveDriver, { driverId }).pipe(
      catchError(() => {
        this.alertService.openBasicErrorAlert('carrier.directory.marketplaceDriver.messages.archiveError');
        return of(null);
      }),
      tap(res => {
        if(res) {
          this.alertService.openBasicSuccessAlert('carrier.directory.marketplaceDriver.messages.archiveSuccess');
        }
      }),
    );
  }

  public restoreDriver(role: USER_ROLE, driverId: string): Observable<any> {
    return this.sharedGraphQlClient.mutate(role as USER_ROLE, DRIVER_QUERIES.restoreDriver, { driverId }).pipe(
      catchError(err => {
        if (err.message) {
          this.alertService.openBasicErrorAlert(err.message);
        } else {
          this.alertService.openBasicErrorAlert('carrier.directory.marketplaceDriver.messages.restoreError');
        }

        return of(null);
      }),
      tap(res => {
        if(res) {
          this.alertService.openBasicSuccessAlert('carrier.directory.marketplaceDriver.messages.restoreSuccess');
        }
      }),
    );
  }

  // Switch case for 'copy' case
  public openModal(role: USER_ROLE, id?: string) {
    this.role = role;
    if (id) {
      this.vm = new DriverEditVm(id, this.sharedGraphQlClient, this.translate, this.datePipe, this.domainService, role);
    } else {
      this.vm = new DriverCreateVm(
        this.sharedGraphQlClient,
        this.translate,
        this.datePipe,
        this.modalAddTransportService,
        this.domainService,
        role
      );
    }

    this.vm.finish$.pipe(untilDestroyed(this)).subscribe(res => {
      res.close && this.close();
      this.alertService.openAlert(
        {
          titleVariant: ALERT_TITLE_VARIANT.NONE,
          message: res.message,
          state: res.alertState,
        } as IAlertInputs,
        TOAST_DURATION.SHORT,
      );
    });

    this.dialogRef = this.dialog.open<{ service: DriverService }>(DriverModalComponent, {
      maxWidth: '700px',
      disableClose: true,
      data: {
        service: this,
      },
      autoFocus: false,
    });
  }

  public close() {
    this.dialogRef?.close();
  }

  ngOnDestroy() {
    this.vm?.dispose();
  }
}
