import { transport } from '@transport/proto';

import { AUCTION_STATUS } from './lot-common.interface';

/**
 * Order lot model.
 */
export interface ILot extends transport.ILot {
  auctionStatus: AUCTION_STATUS;
}

export const createNewLastBetCarrier = (input?: transport.Lot.ILastBetCarrier) =>
  transport.Lot.LastBetCarrier.toObject(new transport.Lot.LastBetCarrier(input), {
    defaults: true,
  }) as transport.Lot.LastBetCarrier;

export const createNewLot = (input: Partial<ILot> = {}) => {
  input.lastBetCarrier = !Boolean(input.lastBetCarrier) ? createNewLastBetCarrier() : input.lastBetCarrier;
  const result = transport.Lot.toObject(new transport.Lot(input), {
    defaults: true,
  }) as ILot;
  result.startPrice = null;
  result.minStepPrice = null;
  result.minutesOfProlongation = typeof input.minutesOfProlongation !== 'undefined' ? input.minutesOfProlongation : null;
  result.minutesTillEndToProlong = typeof input.minutesTillEndToProlong !== 'undefined' ? input.minutesTillEndToProlong : null;
  result.quantityOfProlongations = typeof input.quantityOfProlongations !== 'undefined' ? input.quantityOfProlongations : null;
  result.timesOfProlongation = typeof input.timesOfProlongation !== 'undefined' ? input.timesOfProlongation : null;
  result.hasExtraConditionsForMarket = null;
  return result;
};
export const mapProlongationParamsToLotFields = (params: transport.IAuctionProlongationParameters, lot: transport.ILot): void => {
  lot.isProlongationAvailable = params?.isProlongationAvailable;
  lot.minutesOfProlongation = params?.prolongationTime;
  lot.timesOfProlongation = params?.prolongationCount;
  lot.minutesTillEndToProlong = params?.minutesTillEndToProlong;
};
