import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { STATUS } from '@transport/ui-interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'marketplace-drivers-status-column[status]',
  template: `
    <div class="whitespace-nowrap overflow-hidden text-ellipsis">
      <span [ngClass]="[
        'py-px px-2 rounded-md text-xs font-medium leading-4',
        status === DRIVER_STATUS.PENDING ? 'bg-orange-100 text-orange-700' : '',
        status === DRIVER_STATUS.PASSED ? 'bg-green-100 text-green-700' : '',
        status === DRIVER_STATUS.NEW ? 'bg-gray-100 text-gray-700' : '',
        status === DRIVER_STATUS.NOT_PASSED ? 'bg-red-100 text-red-700' : '',
        ]"
      >
        {{ getDriverStatusForView() }}</span>
    </div>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DriversStatusColumnComponent {
  @Input() status!: STATUS;

  DRIVER_STATUS = STATUS;

  constructor(private translate: TranslateService) {}

  public getDriverStatusForView() {
    switch (this.status) {
      case STATUS.NEW:
        return this.translate.instant('carrier.drivers.statuses.new');
      case STATUS.PASSED:
        return this.translate.instant('carrier.drivers.statuses.passed');
      case STATUS.PENDING:
        return this.translate.instant('carrier.drivers.statuses.pending');
      case STATUS.NOT_PASSED:
      return this.translate.instant('carrier.drivers.statuses.notPassed');
    }
  }
}
