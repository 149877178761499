import { UntilDestroy } from '@ngneat/until-destroy';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup } from '@angular/forms';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

@UntilDestroy()
@Component({
  selector: 'common-slide',
  templateUrl: './common-slide.component.html',
  styleUrls: ['./common-slide.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonSlideComponent {
  @Input() label?: string;
  @Input() checked?: boolean = false;
  @Input() labelClass: string = 'text-gray-700 not-italic font-normal text-sm leading-5';

  @Input() public formGroup?: FormGroup;
  @Input() public control?: FormControl;
  @Input() public controlName: string = '';
  @Input() public dataTest: string = '';

  @Output() public onSlide = new EventEmitter();

  get formControl(): AbstractControl | null {
    if (this.formGroup && this.controlName) return this.formGroup.get(this.controlName);

    return null;
  }

  onChange(e: MatSlideToggleChange) {
    this.onSlide.emit(e.checked);
  }
}
