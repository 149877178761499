import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { transport } from '@transport/proto';
import { MAX_EMAIL_LENGTH, regExpConfig } from '@transport/ui-utils';
import IEmployee = transport.IEmployee;

export interface IEmployeeForm extends FormGroup {
  controls: {
    lastName: AbstractControl;
    firstName: AbstractControl;
    patronymic: AbstractControl;
    phoneNumber: AbstractControl;
    email: AbstractControl;
    permissions: AbstractControl;
  };
}

export enum EMPLOYEE_EMAIL_STATUS {
  CONFIRMED = 'CONFIRMED',
  NOT_CONFIRMED = 'NOT_CONFIRMED',
  NEED_CONFIRM = 'NEED_CONFIRM',
}

export type TEmployeeFormConstructor = (fb: FormBuilder, editableItem: IEmployee) => IEmployeeForm;

export const newEmployeeForm: TEmployeeFormConstructor = (fb: FormBuilder, editableItem: IEmployee): IEmployeeForm => {
  const disabled = editableItem.hasDigitalProfile;
  const emailDisabled =
    Boolean(editableItem.hasDigitalProfile) ||
    (!Boolean(editableItem.hasDigitalProfile) && editableItem.emailStatus === EMPLOYEE_EMAIL_STATUS.CONFIRMED);
  return fb.group({
    permissions: fb.control([]),
    lastName: [{ value: editableItem.lastName, disabled }],
    firstName: [{ value: editableItem.firstName, disabled }],
    patronymic: [{ value: editableItem.patronymic, disabled }],
    phoneNumber: [{ value: editableItem.phoneNumber, disabled }],
    email: [
      {
        value: editableItem.email,
        disabled: emailDisabled,
      },
      {
        validators: Validators.compose([
          Validators.required,
          Validators.pattern(regExpConfig.email.regExp),
          Validators.maxLength(MAX_EMAIL_LENGTH),
        ]),
      },
    ],
  }) as IEmployeeForm;
};

export enum PERMISSION_TYPE {
  CAN_MANAGE_CARRIERS_PARTNERS = 'canManageCarriersPartners',
  CAN_MANAGE_COMPANY_PROFILE = 'canManageCompanyProfile',
  CAN_MANAGE_GENERAL_REFERENCE = 'canManageGeneralReference',
  CAN_MANAGE_ORDERS = 'canManageOrders',
  CAN_MANAGE_USERS_AND_THEIR_ROLES = 'canManageUsersAndTheirRoles',
  IS_COMPANY_GROUP_ADMIN = 'isCompanyGroupAdmin',
  CAN_USER_ACTS_MATCHER = 'canUseActsMatcher',
  CAN_VIEW_ORGANIZATION_ORDERS = 'canViewOrganizationOrders',
  CARRIER_CAN_MANAGE_DOCUMENTS = 'carrierCanManageDocuments',
  CARRIER_HAS_ACCESS_ON_TRANSPORT_SERVICES = 'carrierHasAccessOnTransportServices',
  CARRIER_CAN_MANAGE_USERS_AND_PERMISSIONS = 'carrierCanManageUsersAndPermissions',
  CARRIER_HAS_ACCESS_TO_AGENT_CARRIERS = 'carrierHasAccessToAgentCarriers',
  CARRIER_CAN_MANAGE_ORGANIZATION_PROFILE = 'carrierCanManageOrganizationProfile',
  CARRIER_CAN_MANAGE_GENERAL_REFERENCE = 'carrierCanManageGeneralReference',
  CARRIER_CAN_VIEW_ORGANIZATION_ORDERS = 'carrierCanViewOrganizationOrders',
}

export interface IEmployeePermissionsFormData {
  canManageCarriersPartners?: boolean;
  canManageCompanyProfile?: boolean;
  canManageGeneralReference?: boolean;
  canManageOrders?: boolean;
  canManageUsersAndTheirRoles?: boolean;
  isCompanyGroupAdmin?: boolean;
  canUseActsMatcher?: boolean;
  canViewOrganizationOrders?: boolean;
  carrierCanManageDocuments?: boolean;
  carrierHasAccessOnTransportServices?: boolean;
  carrierCanManageUsersAndPermissions?: boolean;
  carrierHasAccessToAgentCarriers?: boolean;
  carrierCanManageOrganizationProfile?: boolean;
  carrierCanManageGeneralReference?: boolean;
  carrierCanViewOrganizationOrders?: boolean;
}

export interface IEmployeePermissionsForm extends FormGroup {
  controls: {
    canManageCarriersPartners?: AbstractControl;
    canManageCompanyProfile?: AbstractControl;
    canManageGeneralReference?: AbstractControl;
    canManageOrders?: AbstractControl;
    canManageUsersAndTheirRoles?: AbstractControl;
    isCompanyGroupAdmin?: AbstractControl;
    canUseActsMatcher?: AbstractControl;
    canViewOrganizationOrders?: AbstractControl;
    carrierCanManageDocuments?: AbstractControl;
    carrierHasAccessOnTransportServices?: AbstractControl;
    carrierCanManageUsersAndPermissions?: AbstractControl;
    carrierHasAccessToAgentCarriers?: AbstractControl;
    carrierCanManageOrganizationProfile?: AbstractControl;
    carrierCanManageGeneralReference?: AbstractControl;
    carrierCanViewOrganizationOrders?: AbstractControl;
  };
}

export type TEmployeePermissionsFormConstructor = (
  fb: FormBuilder,
  allPermissions: transport.User.Permission[],
) => IEmployeePermissionsForm;

export const newEmployeePermissionsForm: TEmployeePermissionsFormConstructor = (
  fb: FormBuilder,
  allPermissions: transport.User.Permission[],
): IEmployeePermissionsForm => {
  const controlsConfig: {
    [key: string]: AbstractControl;
  } = {};

  allPermissions.forEach(permission => {
    controlsConfig[permission.codename] = fb.control(false);
  });

  return fb.group(controlsConfig);
};
