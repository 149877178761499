import { Injectable } from '@angular/core';
import { transport } from '@transport/proto';
import { fromDto, TAdminOrganizationForm, toDto, USER_ROLE } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnGqlClientOwnerMutationsService } from '../../../gql-client-owner/graphql-client-owner-mutations.service';
import { TnGqlClientOwnerQueriesService } from '../../../gql-client-owner/graphql-client-owner-queries.service';
import { TnGqlClientSharedService } from '../../../gql-client-shared/graphql-client-shared.service';

@Injectable({
  providedIn: 'root',
})
export class TnSubOrganizationsApiService {
  constructor(
    private readonly gqlQuery: TnGqlClientOwnerQueriesService,
    private readonly gqlMutation: TnGqlClientOwnerMutationsService,
    private readonly graphQLShared: TnGqlClientSharedService,
  ) {}

  /**
   * Request for list of sub organizations.
   */
  public getSubOrganizations(listenX?: number, withSpinner?: boolean, first?: number, offset?: number, options?: Record<string, unknown>) {
    return this.gqlQuery.querySubOrganizations(listenX, withSpinner, first, offset, options).pipe(
      map(data => {
        return {
          list: data.subOrganizations,
          totalCount: data.totalCount,
        };
      }),
    );
  }

  /**
   * Get sub org by id
   * @param sub org Id
   */
  public getSubOrganization(organizationId: string) {
    return this.gqlQuery.querySubOrganization(organizationId).pipe(map(data => fromDto(data.organization)));
  }

  /**
   * archive sub org request
   * @param subOrgParam type data
   */
  public sendSubOrganizationToArchive(organizationId: string) {
    return this.gqlMutation.sendSubOrganizationToArchive(organizationId);
  }

  /**
   * Create new sub org request
   * @param sub org data
   */
  public addSubOrganization(subOrganization: TAdminOrganizationForm) {
    const organizationDto = toDto(subOrganization);
    delete organizationDto.id;
    return this.gqlMutation.addSubOrganization(organizationDto);
  }

  /**
   * Edit new sub org request
   * @param sub org data
   */
  public editSubOrganization(subOrganization: TAdminOrganizationForm) {
    return this.gqlMutation.editOrganization(toDto(subOrganization));
  }

  public getLegalForms(): Observable<transport.ILegalForm[]> {
    return this.gqlQuery.queryLegalForms().pipe(map(dtoData => dtoData.legalForms));
  }

  public getVatTypes(): Observable<transport.IOrganizationVatType[]> {
    return this.graphQLShared.queryOrganizationVatTypes(USER_ROLE.CARGO_OWNER);
  }
}
