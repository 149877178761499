import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { ITnState } from '../../state';
import { TnTableStateFacade } from '../../table/table-state-facade';
import { actions } from '../actions/reconciliation-act-table.actions';
import { selectors } from '../selectors/reconciliation-act-table.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnReconcilationActTableFacade extends TnTableStateFacade {
  constructor(public store: Store<ITnState>) {
    super(store, actions, selectors);
  }
}
