import { Injectable } from '@angular/core';
import { ORDER_SIGNING_WAY } from '@transport/ui-interfaces';
import { map } from 'rxjs/operators';

import { TnGqlClientCarrierService } from '../../../gql-client-carrier/graphql-client-carrier.service';

@Injectable({
  providedIn: 'root',
})
export class TnSignDocumentService {
  constructor(private readonly graphQL: TnGqlClientCarrierService) {}

  public isTnCryptoTypeSigning(signingWay: ORDER_SIGNING_WAY) {
    return signingWay === ORDER_SIGNING_WAY.TN_CRYPTO;
  }

  public isScanTypeSigning(signingWay: ORDER_SIGNING_WAY) {
    return signingWay === ORDER_SIGNING_WAY.ORDER_SCAN;
  }

  public getOrderContractStatus(orderId: string) {
    return this.graphQL.queryOrderContractStatus(orderId).pipe(
      map(data => {
        return { ...data.order };
      }),
    );
  }
  //#region End TnDocument
}
