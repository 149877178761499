import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TnConfirmationComponent } from '@transport/ui-store';
import { TranslateService } from '@ngx-translate/core';
import { of } from 'rxjs';
import { TnDomainService } from '@transport/ui-services';

@Injectable({
  providedIn: 'root'
})
export class OwnerPublicOfferService {

  public isSubdomain = this.domainService.subDomain;

  constructor(
    private dialog: MatDialog,
    private translate: TranslateService,
    private domainService: TnDomainService,
  ) {}

  openOwnerPublicOfferDialog(publicOffer: string | null) {
    if (!publicOffer || this.isSubdomain) {
      return of(true);
    }
    const dialogRef = this.dialog.open<TnConfirmationComponent>(TnConfirmationComponent, {
      width: '50%',
      minWidth: '600px',
      data: {
        title: this.translate.instant('shared.mp.ownerOffer.title'),
        content: publicOffer,
        confirm: this.translate.instant('shared.mp.ownerOffer.accept'),
        cancel: this.translate.instant('shared.mp.ownerOffer.cancel'),
        markdown: true,
      },
    });
    return dialogRef.afterClosed();
  }

}
