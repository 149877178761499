import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { TnClientTimeWarningComponent } from './client-time-warning/client-time-warning.component';

/**
 * Client time service.
 */
@Injectable({
  providedIn: 'root',
})
export class TnClientTimeService {
  /**
   * Difference.
   */
  private diff = 0;

  /**
   * Indicates if warning is opened.
   */
  private isWarningOpened = false;

  /**
   * Dialog reference.
   */
  private readonly dialogRef?: MatDialogRef<TnClientTimeWarningComponent, boolean>;

  /**
   * Indicates if there is deviation.
   */
  private deviationPresent = false;

  /**
   * TnClientTimeService constructor.
   * @param dialog Material dialog opener
   */
  constructor(private readonly dialog: MatDialog) {}

  /**
   * Public deviation getter.
   */
  public get isThereDeviation() {
    return this.deviationPresent;
  }

  /**
   * Sets server time.
   * @param date date
   */
  public setServerTime(date: Date): void {
    const deviationThreshold = 10000;
    this.diff = Date.now() - date.getTime();
    this.deviationPresent = Math.abs(this.diff) > deviationThreshold;
  }

  /**
   * Starts tracking deviation.
   */
  public startTrackDeviation() {
    if (this.isThereDeviation && !this.isWarningOpened) {
      void this.dialog
        .open<TnClientTimeWarningComponent, unknown, boolean>(TnClientTimeWarningComponent, {
          width: '600px',
          data: this.diff,
          disableClose: true,
        })
        .afterClosed()
        .subscribe(() => (this.isWarningOpened = false));
      this.isWarningOpened = true;
    }
  }

  /**
   * Stops stracking deviation.
   */
  public stopTrackDeviation() {
    if (typeof this.dialogRef !== 'undefined') {
      this.dialogRef.close();
    }
  }
}
