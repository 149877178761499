import { AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, TemplateRef, ViewChild } from '@angular/core';
import { ORDER_STATUS, TOAST_DURATION } from '@transport/ui-interfaces';
import { BehaviorSubject, tap, delayWhen, timer, catchError, finalize, take, of, switchMap } from 'rxjs';
import { BiddingPopupService, BiddingPopupViewEnum } from './bidding-popup.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MAX_LENGTH_200, TIMEOUT } from '@transport/ui-utils';
import { TnAlertService } from '@transport/ui-kit';
import { IBiddingPopupContext } from '../popup-container/popup.interface';
import { TranslateService } from '@ngx-translate/core';
import { ALERT_STATE, IAlertInputs } from 'libs/transport-ui-kit/src/lib/alert/alert.interfaces';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { CurrencySigns, TnSupportedCurrency } from '@transport/ui-pipes';
import { OwnerPublicOfferService } from 'libs/marketplace-shared/src/lib/services/owner-public-offer.service';

@UntilDestroy(this)
@Component({
  selector: 'marketplace-bidding-popup',
  templateUrl: './bidding-popup.component.html',
  styleUrls: ['./bidding-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BiddingPopupComponent implements AfterViewInit, OnDestroy {
  @Input() popupContext!: IBiddingPopupContext;

  @ViewChild('placeBetSuccess') public orderSuccessRef!: TemplateRef<never>;
  @ViewChild('placeBetError') public orderErrorRef!: TemplateRef<never>;

  public currentPrice = 0;
  public lotStartPrice = 0;

  public showSendCounterOffer = true;

  public popupView = BiddingPopupViewEnum;

  public currentView$ = this.biddingPopupService.currentView$.asObservable();

  public counterOfferPriceControl = new FormControl(0, [Validators.required, Validators.min(1)]);
  public counterOfferVatControl = new FormControl(true);
  public counterOfferComment = new FormControl(null, Validators.maxLength(MAX_LENGTH_200));

  public loading = new BehaviorSubject(false);
  public loadingPrice = new BehaviorSubject(false);
  private spinnerTimeout: number = TIMEOUT.SHORTEST;

  get isCancelled() {
    return this.popupContext.status$.value === ORDER_STATUS.CANCELED;
  }

  constructor(
    public readonly biddingPopupService: BiddingPopupService,
    public alertService: TnAlertService,
    private translateService: TranslateService,
    private ownerPublicOfferService: OwnerPublicOfferService,
  ) {}

  ngAfterViewInit(): void {
    this.popupContext.price$
      .pipe(
        tap(() => this.loadingPrice.next(true)),
        delayWhen(() => timer(this.spinnerTimeout)),
        untilDestroyed(this),
      )
      .subscribe(price => {
        this.spinnerTimeout = TIMEOUT.DEFAULT;
        if (!price) {
          this.currentPrice = this.popupContext.startPrice;
          this.lotStartPrice = this.popupContext.startPrice;
        } else if (price - this.popupContext.lotStep < 0) {
          this.lotStartPrice = price;
          this.currentPrice = price;
        } else {
          this.currentPrice = price - this.popupContext.lotStep;
          this.lotStartPrice = price - this.popupContext.lotStep;
        }
        this.loadingPrice.next(false);
      });
  }

  ngOnDestroy() {
    this.biddingPopupService.currentView$.next(this.popupView.counterOffer);
  }

  public get hasCounterOffer(): boolean {
    return this.popupContext.hasMyCounterOffer;
  }

  public sendCounterOffer(): void {
    if (this.counterOfferPriceControl.valid) {
      this.ownerPublicOfferService
        .openOwnerPublicOfferDialog(this.popupContext.publicOffer)
        .pipe(
          take(1),
          switchMap(value => {
            if (!value) return of(false);

            this.loading.next(true);
            return this.biddingPopupService.addOrderCounterOffer(
              {
                amount: this.counterOfferPriceControl.value,
                currency: this.popupContext.currency ?? TnSupportedCurrency.RUB,
                vatIncluded: this.counterOfferVatControl.value,
                orderId: this.popupContext.lotId as unknown as number,
                comment: this.counterOfferComment.value,
              },
              this.popupContext.acceptingPlace,
            );
          }),
          tap(response => {
            if (response) {
              this.orderSuccessRef &&
                this.alertService.openAlertFromTemplate(this.orderSuccessRef, TOAST_DURATION.LONG, {
                  title: this.translateService.instant('shared.mp.counterOfferPopup.counterOfferSuccess'),
                });
            }
          }),
          catchError(err => {
            this.openAlertFailure(err);
            return of(null);
          }),
          finalize(() => {
            this.loading.next(false);
          }),
        )
        .subscribe();
    }
  }

  public priceControlErrorToMessage: (errors: ValidationErrors | null) => string = errors => {
    if (typeof errors?.required !== 'undefined') return this.translateService.instant('owner.order.errors.required');
    if (typeof errors?.min !== 'undefined')
      return this.translateService.instant('owner.order.errors.lessThanStep', { value: 1, currency: this.currencySign });
    return '';
  };

  private openAlertFailure(err: Error) {
    this.alertService.openAlert(
      {
        title: this.translateService.instant('shared.mp.cargo.table.popup.bidding.betError'),
        state: ALERT_STATE.ERROR,
        message: err.message,
      } as IAlertInputs,
      TOAST_DURATION.SHORT,
    );
  }

  public get currencySign() {
    return CurrencySigns[this.popupContext.currency ?? TnSupportedCurrency.RUB];
  }

  public validationErrors(e: ValidationErrors | null) {
    if (e?.maxlength) {
      return this.translateService.instant('shared.errors.maxCommentLength', { value: 200 });
    }
    return '';
  }
}
