import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAX_EMAIL_LENGTH, MIN_PASSWORD_LENGTH, regExpConfig } from '@transport/ui-utils';

/**
 * Reg form data.
 */
export interface IRegFormData {
  email: string;
  password: string;
  user: string;
  phone: string;
  inn: string;
}

/**
 * Reg form interface.
 */
export interface IRegForm extends FormGroup {
  controls: {
    email: AbstractControl;
    user: AbstractControl;
    phone: AbstractControl;
    password: AbstractControl;
    inn: AbstractControl;
  };
}

/**
 * New login form constructor type.
 */
export type TNewRegFormConstructor = (fb: FormBuilder, editableItem?: IRegFormData, disabled?: boolean) => IRegForm;

/**
 * Reg form constructor
 * @param fb form builder
 * @param editableItem editable item
 * @param disabled indicates if for should be initialized with disabled controls
 */
export const newRegForm: TNewRegFormConstructor = (fb: FormBuilder, editableItem?: IRegFormData, disabled = false): IRegForm => {
  return fb.group({
    email: [
      editableItem?.email ?? '',
      {
        validators: Validators.compose([
          Validators.required,
          Validators.pattern(regExpConfig.email.regExp),
          Validators.maxLength(MAX_EMAIL_LENGTH),
        ]),
        disabled,
      },
    ],
    user: [
      '',
      {
        validators: Validators.compose([Validators.required, Validators.pattern(regExpConfig.fio.regExp)]),
      },
    ],
    phone: [
      '',
      {
        validators: Validators.compose([
          Validators.required,
          Validators.pattern(regExpConfig.phoneNumber.RU),
        ]),
        disabled,
      },
    ],
    password: [
      '',
      {
        validators: Validators.compose([Validators.required, Validators.minLength(MIN_PASSWORD_LENGTH)]),
        disabled,
      },
    ],
    inn: [
      '',
      {
        validators: Validators.compose([Validators.required]),
      },
    ],
  }) as IRegForm;
};
