import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IDirectoryState } from '../../../directory-state.interface';
import { ITruckState } from '../reducers/truck.reducer';

const getDirectoryState = createFeatureSelector<IDirectoryState>('directory');
const truckStateSelector = createSelector(getDirectoryState, (state: IDirectoryState) => state.truck);

export const truckId = createSelector(truckStateSelector, (state: ITruckState) => state.id);

export const truck = createSelector(truckStateSelector, (state: ITruckState) => state.vehicle);

export const changedTruckStatus = createSelector(truckStateSelector, (state: ITruckState) => state.changedTruckStatus);

export const agentCarriers = createSelector(truckStateSelector, (state: ITruckState) => state.agentCarriers);
