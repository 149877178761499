import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';

import {
  HIDE_TOASTER_ACTION,
  hideToaster,
  SHOW_TOASTER_ACTION,
  SHOW_TOASTER_CANCEL_ACTION,
  SHOW_TOASTER_REDIRECT_ACTION,
  showToaster,
  showToasterWithCancel,
  showToasterWithRedirect,
} from './toaster.actions';
import { TnToasterService } from './toaster.service';

@Injectable({
  providedIn: 'root',
})
export class TnToasterEffects {
  constructor(private readonly action$: Actions, private readonly toaster: TnToasterService) {}

  public readonly showToaster$ = createEffect(
    () =>
      this.action$.pipe(
        ofType<ReturnType<typeof showToaster>>(SHOW_TOASTER_ACTION),
        tap(({ payload }) => {
          this.toaster.showToaster(payload.message, payload.type, payload.duration);
        }),
      ),
    { dispatch: false },
  );

  public readonly showToasterWithCancel$ = createEffect(
    () =>
      this.action$.pipe(
        ofType<ReturnType<typeof showToasterWithCancel>>(SHOW_TOASTER_CANCEL_ACTION),
        tap(({ payload }) => {
          this.toaster.showToasterWithCancel(payload.message, payload.type, payload.duration);
        }),
      ),
    { dispatch: false },
  );

  public readonly showToasterWithRedirect$ = createEffect(
    () =>
      this.action$.pipe(
        ofType<ReturnType<typeof showToasterWithRedirect>>(SHOW_TOASTER_REDIRECT_ACTION),
        tap(({ payload }) => {
          this.toaster.showToasterWithRedirect(payload.route, payload.message, payload.type, payload.duration, payload?.styles ?? [], payload?.action ?? '');
        }),
      ),
    { dispatch: false },
  );

  public readonly hideToaster$ = createEffect(
    () =>
      this.action$.pipe(
        ofType<ReturnType<typeof hideToaster>>(HIDE_TOASTER_ACTION),
        tap(({ payload }) => {
          this.toaster.hideToaster();
        }),
      ),
    { dispatch: false },
  );
}
