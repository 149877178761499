/**
 * Confirmation text data.
 */
export interface IConfirmationTextData {
  title: string;
  content: string;
  confirm: string;
  cancel: string;
  contentParams: string;
  markdown?: boolean;
}
