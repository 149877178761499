import { HttpClient, HttpResponse } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { APP_ENV, TnAppEnvironment } from '@transport/ui-interfaces';
import { TnAuthApiService, TnHttpHandlersService } from '@transport/ui-store';
import { graphqlEndpoints, navigationEndpoints } from 'libs/transport-ui-store/src/lib/current-user/services/analytics/tracked-endpoints';
import { tap } from 'rxjs/operators';

enum APP_NAME {
  TMS = 'tms',
  MARKET = 'market',
}

@UntilDestroy()
@Injectable({
  providedIn: 'root',
})
export class TnAnalyticsService {
  private readonly endpoints = {
    createSession: (): string => this.httpHandlerService.getEndpointUrl('/p/360/sessions/'),
    createEvent: (): string => this.httpHandlerService.getEndpointUrl('/p/360/events/'),
    stopSession: (id: string): string => this.httpHandlerService.getEndpointUrl(`/p/360/sessions/${id}`),
  };

  private readonly trackedEndpoints = new Map(Object.entries(graphqlEndpoints));

  private readonly trackedNavigationEndpoints = new Map(Object.entries(navigationEndpoints));

  public get trackingSessionId() {
    return sessionStorage.getItem('TrackingSessionId');
  }

  constructor(
    private readonly authService: TnAuthApiService,
    private readonly httpHandlerService: TnHttpHandlersService,
    private readonly router: Router,
    private readonly http: HttpClient,
    @Inject(APP_ENV) public readonly env: TnAppEnvironment,
  ) {}

  public init() {
    if (!Boolean(this.trackingSessionId)) {
      this.createSession().pipe(untilDestroyed(this)).subscribe();
    }
    this.fillRestEndpoints();
  }

  private fillRestEndpoints() {
    //Auth
    this.toTrackedRestReq(this.authService.endpoints.logout(), 'logout');
    this.toTrackedRestReq(this.authService.endpoints.login(), 'login');
    this.toTrackedRestReq(this.authService.endpoints.signup(), 'self registration');
    this.toTrackedRestReq(this.authService.endpoints.signupByLink(), 'registration by invitation');
    this.toTrackedRestReq(this.authService.endpoints.confirmEmailAgain(), 'registration (send confirm email again)');
    this.toTrackedRestReq(this.authService.endpoints.confirmUserRegistration(), 'registration (confirm user again)');
  }

  public toTrackedRestReq(endpoint: string, logName: string) {
    this.trackedEndpoints.set(endpoint, logName);
  }

  public trackNavigation(url: string, extra?: string, data?: unknown) {
    const urlParsed = url
      .split('/')
      .map(str => {
        if (/^\d+$/.test(str)) return ':id';
        return str;
      })
      .join('/');
    if (this.trackedNavigationEndpoints.has(urlParsed)) {
      const extraStr = extra ? ' ' + extra : '';
      this.track(this.trackedNavigationEndpoints.get(urlParsed) + extraStr ?? 'undefined endpoint', {
        urlParsed,
        data,
      });
    }
  }

  public trackHttpResponse(response: HttpResponse<unknown>, withErrors = false) {
    const { url } = response;
    const errorsExtra = withErrors ? '(error)' : '';
    if (typeof url === 'string') {
      if (this.trackedEndpoints.has(url)) {
        this.track<{ payload: unknown }>(`${this.trackedEndpoints.get(url)} ${errorsExtra}`, {
          payload: JSON.stringify(response.body),
        });
        return;
      }
      const urlObj = new URL(url);
      const operation = urlObj.searchParams.get('operation');
      if (typeof operation === 'string') {
        if (this.trackedEndpoints.has(operation))
          this.track<{ payload: unknown }>(`${this.trackedEndpoints.get(operation)} ${errorsExtra}`, {
            payload: JSON.stringify(response.body),
          });
      }
    }
  }

  public trackValidationError(form?: FormGroup, correctionMsg?: string) {
    if (form) {
      //TODO: maybe errors as object not string
      const errors = Object.entries(form.controls)
        .filter(([key, c]) => c.status === 'INVALID')
        .map(([key, c]) => {
          return `${key}: ${JSON.stringify(c.errors)}`;
        })
        .join(', ');

      const correction = correctionMsg ? correctionMsg + ' ' : '';
      this.trackNavigation(this.router.url, `(${correction}validation error)`, {
        errors,
      });
    }
  }

  public track<T>(name: string, event?: T) {
    if (Boolean(this.trackingSessionId)) {
      const endpoint: string = this.endpoints.createEvent();
      const app = Boolean(this.env.appName?.toLocaleLowerCase().includes(APP_NAME.TMS)) ? APP_NAME.TMS : APP_NAME.MARKET;
      const eventData = {
        session: this.trackingSessionId,
        app,
        name: name,
        attributes: event,
      };
      const observable = this.http.post(endpoint, eventData);
      this.httpHandlerService.pipeHttpRequest(observable).pipe(untilDestroyed(this)).subscribe();
    }
  }

  public createSession() {
    const endpoint: string = this.endpoints.createSession();
    const observable = this.http.post<{ id: string }>(endpoint, {});
    return this.httpHandlerService
      .pipeHttpRequest<{ id: string }>(observable)
      .pipe(tap(data => sessionStorage.setItem('TrackingSessionId', data.id)));
  }

  public stopSession() {
    if (Boolean(this.trackingSessionId)) {
      const id = this.trackingSessionId ?? '';
      const endpoint: string = this.endpoints.stopSession(id);
      sessionStorage.removeItem('TrackingSessionId');
      const observable = this.http.delete(endpoint, {});
      void this.httpHandlerService.pipeHttpRequest(observable).pipe(untilDestroyed(this)).subscribe();
    }
  }
}
