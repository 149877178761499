import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { VERIFICATION_STATUS } from '@transport/ui-interfaces';

import { STATUS_CHIP_CLASS } from '../status-chip/status-chip.component';

@Component({
  selector: 'transport-verification-status',
  templateUrl: './verification-status.component.html',
  styleUrls: ['./verification-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnVerificationStatusComponent {
  @Input() public status?: VERIFICATION_STATUS;

  public registrationStatus = VERIFICATION_STATUS;

  public get hasStatus() {
    return Boolean(this.status);
  }

  public get statusClass() {
    switch (this.status) {
      case VERIFICATION_STATUS.NOT_CHECKED:
      case VERIFICATION_STATUS.ON_WORK:
        return STATUS_CHIP_CLASS.GRAY;
      case VERIFICATION_STATUS.APPROVED:
        return STATUS_CHIP_CLASS.SUCCESS;
      case VERIFICATION_STATUS.REJECTED:
        return STATUS_CHIP_CLASS.ALERT;
      default:
        return STATUS_CHIP_CLASS.GRAY;
    }
  }
}
