<transport-tw-input-field
  class="!gap-0"
  [label]="label"
  [size]="labelSize"
  [errorsSize]="errorsSize"
  [required]="required"
  [control]="$any(control)"
  [showErrors]="showErrors"
>
  <transport-tw-input class="mt-1" [size]="size" [control]="$any(control)" matAutocompleteOrigin #origin="matAutocompleteOrigin">
    <input
      tw-input
      [value]="inputValue || control.value || ''"
      (keydown)="filter($event)"
      (keyup)="inputChange($event)"
      (blur)="onBlur($event)"
      [placeholder]="placeholder"
      [matAutocomplete]="auto"
      [matAutocompleteConnectedTo]="origin"
      #inputField
    />
  </transport-tw-input>
</transport-tw-input-field>

<mat-autocomplete
  [panelWidth]="panelWidth"
  class="tw-menu-block"
  [displayWith]="getOptionName"
  #auto="matAutocomplete"
  (optionSelected)="selected($event)"
>
  <mat-option *ngFor="let option of options" [value]="option">
    <transport-tw-menu-option [text]="option?.name" [title]="option?.name"></transport-tw-menu-option>
  </mat-option>
</mat-autocomplete>
