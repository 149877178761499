import { createAction, props } from '@ngrx/store';
import { ICargoType, PAGE_ACTIONS } from '@transport/ui-interfaces';

export const removeCargoType = createAction('Remove CargoType', props<{ cargoType: Partial<ICargoType> }>());

export const removeCargoTypeSuccess = createAction('Remove CargoType Success', props<{ cargoType: ICargoType }>());
export const removeCargoTypeFailure = createAction('Remove CargoType Failure', props<{ error: Error }>());

export const changePage = createAction('Change Page', props<{ action: PAGE_ACTIONS; id: string }>());
export const changePageMode = createAction('Change Page Mode', props<{ mode: PAGE_ACTIONS }>());
