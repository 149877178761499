import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ISubOrganizationsFilter } from '@transport/ui-interfaces';

import { ITnState } from '../../state/index';
import { TnTableStateFacade } from '../../table/table-state-facade';
import { subOrganizationsTableActions } from './sub-organizations.actions';
import { subOrganizationsTableSelectors } from './sub-organizations.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnSubOrganizationsFacade extends TnTableStateFacade<ISubOrganizationsFilter> {
  constructor(public store: Store<ITnState>) {
    super(store, subOrganizationsTableActions, subOrganizationsTableSelectors);
  }
}
