import { Injectable } from '@angular/core';
import { TnReconciliationActFilesUrlGeneratorAbstract } from '@transport/ui-interfaces';

import { TnHttpHandlersService } from '../../feature-access';

@Injectable({
  providedIn: 'root',
})
export class TnReconcilationActUrlService extends TnReconciliationActFilesUrlGeneratorAbstract {
  constructor(private readonly handlers: TnHttpHandlersService) {
    super();
  }

  private readonly apiUrl = this.handlers.getEndpointUrl('/acts_matcher/');

  public generateActsListUrl(pageNumber: number, countPerPage: number): string {
    return `${this.apiUrl}acts_comparisons/?page=${pageNumber}&page_size=${countPerPage}`;
  }

  public get organizationsListUrl(): string {
    return `${this.apiUrl}organizations/`;
  }

  public get myOrganizationUrl(): string {
    return `${this.apiUrl}my_organization/`;
  }

  public get rulesListUrl(): string {
    return `${this.apiUrl}act_xls_parse_rules/`;
  }

  public get saveActUrl(): string {
    return `${this.apiUrl}acts_comparisons/`;
  }

  public generateActDetailsUrl(guid: string): string {
    return `${this.apiUrl}acts_comparison/${guid}`;
  }

  public generateDownloadUrlForAct(guid: string, orgNumber: '1' | '2'): string {
    return this.handlers.getEndpointUrl(`acts_matcher/acts_comparison/${guid}/download_act/?org=${orgNumber}`);
  }

  public generateDownloadUrlForResults(guid: string, isFullResults: boolean): string {
    return this.handlers.getEndpointUrl(`acts_matcher/acts_comparison/${guid}/download_result/?full_results=${isFullResults}`);
  }
}
