import { transport } from '@transport/proto';

import { TOrganizationStatus } from './organization-common.interface';

/**
 * Carrier organization.
 */
export interface ICarrierOrganization extends transport.Carrier.IOrganization {
  status: TOrganizationStatus;
  businessRelationship?: {
    state: string;
    carrierFacsimileImg: string;
  };
}

export const createNewCarrierOrganization = (input?: Partial<transport.Carrier.IOrganization>) => {
  return transport.Carrier.Organization.toObject(new transport.Carrier.Organization(input), {
    defaults: true,
  }) as ICarrierOrganization;
};
