import { FILTERS_TABS, IFiltersToolbarTab } from '../filters.interface';

export const toolbarConfig: IFiltersToolbarTab[] = [
  {
    id: FILTERS_TABS.ALL,
    tab: {
      label: 'shared.mp.drivers.toolbar.all',
      count: 0,
    },
  },
  {
    id: FILTERS_TABS.ACTIVE,
    tab: {
      label: 'shared.mp.drivers.toolbar.active',
      count: 0,
    },
  },
  {
    id: FILTERS_TABS.ARCHIVED,
    tab: {
      label: 'shared.mp.drivers.toolbar.archived',
      count: 0,
    },
  },
];
