import { Action, createReducer, on } from '@ngrx/store';

import { allPermissionsApiActions } from '../actions';
import { IAllPermissionsState } from '../all-permissions.state';

export const allPermissionsInitialState: IAllPermissionsState = {
  loaded: false,
  permissions: [],
};

const adminAllPermissionsReducer = createReducer(
  allPermissionsInitialState,

  on(allPermissionsApiActions.loadAllPermissionsSuccess, (state, { permissions }) => {
    return {
      ...state,
      loaded: true,
      permissions,
    };
  }),
);

export function allPermissionReducer(state: IAllPermissionsState | undefined, action: Action) {
  return adminAllPermissionsReducer(state, action);
}
