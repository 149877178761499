import { Pipe, PipeTransform } from '@angular/core';
import { transport } from '@transport/proto';
import { IBlackListViewData } from '@transport/ui-interfaces';
import memo from 'memo-decorator';

/**
 * Pipe black list transform
 */
@Pipe({
  name: 'blackListViewData',
})
export class TnBlackListViewDataPipe implements PipeTransform {
  public blackList: transport.Vehicle.IBlackListVehicle[] | transport.Driver.IBlackListDriver[] = [];

  constructor() {}

  @memo()
  public transform(value?: transport.Vehicle.IBlackListVehicle[] | transport.Driver.IBlackListDriver[]): IBlackListViewData | null {
    if (!Boolean(value) || Boolean(value?.length === 0)) {
      return null;
    }
    this.blackList = value ?? [];

    const trailerList = value?.filter(item => this.isNomenclature(item));
    const otherList = value?.filter(item => !this.isNomenclature(item));

    const prohibited = this.isProhibited(otherList ?? []);
    const partProhibited = this.isPartProhibited(otherList ?? []);
    const prohibitedTrailer = this.isProhibited(trailerList ?? []);
    const partProhibitedTrailer = this.isPartProhibited(trailerList ?? []);

    return {
      companyCounts: this.cargoOwnerCount,
      prohibited: this.getProhibitedCount(prohibited),
      partProhibited: this.getProhibitedCount(partProhibited),
      trailer: {
        prohibited: this.getProhibitedCount(prohibitedTrailer),
        partProhibited: this.getProhibitedCount(partProhibitedTrailer),
        trailerList: trailerList ?? ([] as transport.Vehicle.IBlackListVehicle[]),
      },
      reasonList: (value ?? []).filter(item => Boolean(item?.reason)).map(item => item?.reason as string),
    };
  }

  private getProhibitedCount(list: transport.Vehicle.IBlackListVehicle[] | transport.Driver.IBlackListDriver[]) {
    return this.isSeveralCompanies ? this.uniqValuesById(list).length : list.length;
  }

  private get cargoOwnerCount() {
    const { id } = this.blackList[0]?.cargoOwner ?? {};
    const companies = this.blackList.filter(item => item?.cargoOwner?.id !== id);
    return companies.length + 1;
  }

  private get isSeveralCompanies() {
    return this.cargoOwnerCount > 1;
  }

  private isProhibited(list: transport.Vehicle.IBlackListVehicle[] | transport.Driver.IBlackListDriver[]) {
    return list.filter(item => Boolean(item?.workProhibited));
  }

  private isPartProhibited(list: transport.Vehicle.IBlackListVehicle[] | transport.Driver.IBlackListDriver[]) {
    return list.filter(item => !Boolean(item?.workProhibited));
  }

  private isNomenclature(item: transport.Vehicle.IBlackListVehicle) {
    return Boolean(item.nomenclatureType) || item.nomenclatureType === null;
  }

  private uniqValuesById(list: transport.Vehicle.IBlackListVehicle[] | transport.Driver.IBlackListDriver[]) {
    const blackListInfo = {};
    list.forEach(item => {
      if (!Boolean(blackListInfo[item.cargoOwner?.id as string])) {
        blackListInfo[item.cargoOwner?.id as string] = { ...item };
      }
    });
    return Object.values(blackListInfo);
  }
}
