import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ICargoRoutingsCard } from 'libs/transport-routings/src/lib/interfaces';

@Injectable()
export class ListSelectionService {
  public itemIds: string[] = [];

  public checked: Record<string, boolean> = {};

  public checkedListState$ = new BehaviorSubject(this.allChecked);

  public dragged$ = new BehaviorSubject<string[]>([]);
  public cursorAboveRoutes$ = new BehaviorSubject(false);

  constructor() {}

  public get allChecked(): boolean | 'indeterminate' {
    return this.checkedCount === this.itemsCount ? this.itemsCount > 0 : this.checkedCount === 0 ? false : 'indeterminate';
  }

  public setItems(itemIdsList) {
    this.itemIds = itemIdsList;
    this.checkedListState$.next(this.allChecked);
  }

  public isChecked(itemIds): boolean {
    if (typeof itemIds === 'string') {
      // eslint-disable-next-line no-prototype-builtins
      return this.checked.hasOwnProperty(itemIds);
    } else {
      return itemIds?.some(itemId => this.checked.hasOwnProperty(itemId));
    }
  }

  public isAllChecked(itemIds: string[]): boolean | string {
    const isAllChecked = itemIds?.every(itemId => this.checked.hasOwnProperty(itemId));
    const isSomeChecked = itemIds?.some(itemId => this.checked.hasOwnProperty(itemId));
    return this.checkedCount && isAllChecked ? true : isSomeChecked ? 'indeterminate' : false;
  }

  public toggleAll() {
    this.checked = this.checkedCount < this.itemsCount && this.itemIds?.length ? this.fromPairs(this.itemIds.map(id => [id, true])) : {};
    this.checkedListState$.next(this.allChecked);
  }

  public uncheckSeveral(itemIds: string[]) {
    itemIds.forEach(id => {
      if (this.checked.hasOwnProperty(id)) delete this.checked[id];
    });
    this.checkedListState$.next(this.allChecked);
  }

  public uncheckAll() {
    this.checked = this.fromPairs([]);
    this.checkedListState$.next(this.allChecked);
  }

  public toggleSingle(itemId) {
    if (itemId) {
      // eslint-disable-next-line no-prototype-builtins
      this.checked?.hasOwnProperty(itemId) ? delete this.checked[itemId] : (this.checked[itemId] = true);
      this.checkedListState$.next(this.allChecked);
    }
  }

  public toggleSeveral(items: string[] | undefined) {
    if (items?.length) {
      const isSomeChecked = items?.some(itemId => this.checked.hasOwnProperty(itemId));
      if (isSomeChecked) {
        items.forEach(itemId => {
          delete this.checked[itemId];
        });
      } else {
        items.forEach(itemId => {
          this.checked[itemId] = true;
        });
      }
      this.checkedListState$.next(this.allChecked);
    }
  }

  public toggleSeveralFromHeader(items: string[] | undefined) {
    if (items?.length) {
      if (this.isAllChecked(items) === true) {
        items.forEach(itemId => {
          delete this.checked[itemId];
        });
      } else {
        items.forEach(itemId => {
          this.checked[itemId] = true;
        });
      }
      this.checkedListState$.next(this.allChecked);
    }
  }

  public clearChecked() {
    this.checked = {};
    this.checkedListState$.next(this.allChecked);
  }

  private get checkedPairs(): Array<[string, boolean]> {
    return Array.from(Object.entries(this.checked)).filter(([id, checked]) => checked) as Array<[string, boolean]>;
  }

  private get checkedCount(): number {
    return this.checkedPairs.length;
  }

  private get itemsCount(): number {
    return this.itemIds?.length || 0;
  }

  private fromPairs(arr) {
    const obj = {};
    arr.forEach((pairs, i) => {
      obj[pairs[0]] = pairs[1];
    });
    return obj;
  }
}
