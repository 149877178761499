import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { VerificationStatuses } from './statuses.enum';

@Component({
  selector: 'user-accreditation-status',
  templateUrl: './user-accreditation-status.component.html',
  styleUrls: ['./user-accreditation-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnUserAccreditationStatusComponent {
  @Input() public collapsed = false;

  public readonly statuses = VerificationStatuses;

  @Input() public status: string | null | undefined = VerificationStatuses.notStarted;

  @Input() public verificationClosed: boolean | null | undefined = false;

  @Input() public onStartAccredetation = () => {};

  @Input() public onMarkStatusAsRead = () => {};
}
