import { HttpClient } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { TOAST_DURATION } from '@transport/ui-interfaces';
import { TnAlertService } from '@transport/ui-kit';
import { CLASS_PRINT_IFRAME } from '@transport/ui-utils';
import { ALERT_STATE, IAlertInputs } from 'libs/transport-ui-kit/src/lib/alert/alert.interfaces';
import { catchError, map, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DownloadFileService {
  constructor(
    private readonly http: HttpClient,
    private readonly alertService: TnAlertService,
    private readonly translate: TranslateService,
    protected sanitizer: DomSanitizer,
  ) {}

  private getFile(fileUrl: string, withAlert = true): Observable<Blob> {
    return this.http.get(fileUrl, { responseType: 'blob' }).pipe(
      map((response: Blob) => {
        withAlert && this.alertService.openAlert(
          {
            title: this.translate.instant('shared.mp.check.downloadFile.success'),
            state: ALERT_STATE.SUCCESS,
          } as IAlertInputs,
          TOAST_DURATION.SHORT,
        );
        return response;
      }),
      catchError((err: Error) => {
        this.alertService.openAlert(
          {
            title: this.translate.instant('shared.mp.check.downloadFile.failure'),
            state: ALERT_STATE.ERROR,
          } as IAlertInputs,
          TOAST_DURATION.SHORT,
        );
        return throwError(() => err);
      }),
    );
  }

  public downloadFile(fileUrl: string, fileName: string) {
    this.getFile(fileUrl).subscribe((response: Blob) => {
      const binaryData: Blob[] = [response];
      const dataType = response.type;
      const downloadLink = document.createElement('a');
      downloadLink.setAttribute('download', fileName);
      downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: dataType }));
      document.body.appendChild(downloadLink);
      downloadLink.click();
      downloadLink.parentNode?.removeChild(downloadLink);
    });
  }

  public printPdfFile(fileUrl: string, fileName: string) {
    this.getFile(fileUrl, false).subscribe((response: Blob) => {
      const binaryData: Blob[] = [response];
      const iframe = document.createElement('iframe');
      iframe.style.display = 'none';
      iframe.className = CLASS_PRINT_IFRAME;
      const blobUrl = window.URL.createObjectURL(new Blob(binaryData, {type: 'application/pdf'}));
      iframe.src = this.sanitizer.sanitize(SecurityContext.RESOURCE_URL, this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)) || '';
      document.body.appendChild(iframe);
      iframe?.contentWindow?.print();
    });
  }
}
