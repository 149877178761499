import { gql } from 'apollo-angular';

export const VEHICLES_QUERIES = {
  list: gql`
    query vehicles(
      $first: Int
      $offset: Int
      $availability: AvailabilityEnum
      $ownership: VehicleOwnershipEnum
      $isThirdParty: Boolean
      $vehicleType: VehicleTypeEnum
      $regNo: String
      $vehicleBrand: String
      $trailerRegNo: String
      $bodyType: ID
      $bodySubtype: ID
      $capacity: Float
      $loadingType: ID
      $volume: Float
      $agentCarrierId: ID
      $agentCarrierOwnership: AgentCarrierVehicleOwnershipEnum
      $sortBy: [String]
      $withActiveAgentCarrierOnly: Boolean
      $totalCount: Boolean = false
    ) {
      vehicles(
        first: $first
        offset: $offset
        sortBy: $sortBy
        availability: $availability
        ownership: $ownership
        isThirdParty: $isThirdParty
        vehicleType: $vehicleType
        regNo: $regNo
        vehicleBrand: $vehicleBrand
        trailerRegNo: $trailerRegNo
        bodyType: $bodyType
        bodySubtype: $bodySubtype
        capacity: $capacity
        loadingType: $loadingType
        volume: $volume
        agentCarrierId: $agentCarrierId
        agentCarrierOwnership: $agentCarrierOwnership
        withActiveAgentCarrierOnly: $withActiveAgentCarrierOnly
      ) {
        id
        country
        accrStatus
        accrComment
        isArchived
        vehicleType
        vehicleMake
        blackListInfo {
          cargoOwner {
            id
            name
          }
          workProhibited
          reason
        }
        regNo
        vehiclebody {
          id
          volume
          capacity
          bodySubtype {
            name
          }
        }
        vehicletrailer {
          id
          volume
          capacity
          regNo
          bodySubtype {
            name
          }
          blackListInfo {
            cargoOwner {
              id
              name
            }
            workProhibited
            reason
          }
        }
        ownershipType
        baseLocation {
          settlement
          fullAddress
          fiasCode
          timezone
          regionWithType
          latitude
          longitude
        }
        agentCarrier {
          id
          carrierName
          inn
          bin
          iin
        }
      }
      totalCount: vehiclesCount(
        sortBy: $sortBy
        availability: $availability
        ownership: $ownership
        isThirdParty: $isThirdParty
        vehicleType: $vehicleType
        regNo: $regNo
        vehicleBrand: $vehicleBrand
        trailerRegNo: $trailerRegNo
        bodyType: $bodyType
        bodySubtype: $bodySubtype
        capacity: $capacity
        loadingType: $loadingType
        volume: $volume
        agentCarrierId: $agentCarrierId
        agentCarrierOwnership: $agentCarrierOwnership
        withActiveAgentCarrierOnly: $withActiveAgentCarrierOnly
      ) @include(if: $totalCount)
    }
  `,
};
