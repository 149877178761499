import { ACCREDITATION_EVAL_STATE, ACCREDITATION_STATUS_IN } from "./response.mappers";

export enum CHECK_PAGE_TABS {
  ORGANIZATIONS,
  VEHICLES,
  DRIVERS,
}

export enum CHECK_PAGE_ROUTERS {
  DRIVERS = 'drivers',
  VEHICLES = 'vehicles',
  ORGANIZATIONS = 'organizations',
}

export enum ACCREDITATION_STATUS {
  ACCRED,
  ON_ACCRED,
  REJECT,
  NEW,
  DATA_REQUESTED,
  TAKE_TO_ACCRED,
}

export enum ACCREDITATION_TYPE_TABLE {
  ON_ACCRED,
  SUCCESS,
  REJECT,
  ALL,
}

export enum ACCREDITATION_RISK_TYPE_TABLE {
  NEW,
  ON_ACCRED,
  COMPLETED,
}

export enum ACCREDITATION_RISK_STATUS {
  NEW = 'new',
  SUBMITTED = 'submitted',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
}

export enum EVAL_STATE {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  DATA_REQUESTED = 'data_requested',
  NOT_SET = 'not_set',
  CHECKED_OUT = 'checked_out',
}

export interface IAccreditationStatuses {
  name: string;
  key: string;
  checked: boolean;
}

export enum ACCREDITATION_ORGANIZATION_TYPE_TABLE { // то же самое, что и ACCREDITATION_RISK_TYPE_TABLE
  NEW,
  SUBMITTED,
  COMPLETED,
}

export interface IPublicationsTableRowData {
  id: string;
  taskId: string;
  name: string;
  vehicle: string;
  timer: string | null;
  from: string;
  to: string;
  price: string | null;
  status: ACCREDITATION_STATUS;
  actions?: string;
  inn: string;
  vehicleCheck: string | null;
  fromTime: string;
  toTime: null;
  isVatIncluded: boolean;
  accredDate: string | null;
  reason: null | string;
  priceForDistance: boolean;
}

export enum TASKS_STATUS {
  PENDING = 'pending',      // не начата переводится после подтверждении реги
  SUBMITTED = 'submitted',  // на аккредитации
  DONE = 'done',            // проверка пройдена успешна
  ERRORS = 'errors',        // отказано
}

export type IManualTasks = {
  status?: TASKS_STATUS;
  result?: boolean;
  tags?: string[];
  tabIndex?: number;
  first: number;
  offset: number;
  searchFilter?: string;
}

export type IManualRisks = {
  objectType?: string;
  statusIn?: ACCREDITATION_RISK_STATUS[];
  tabIndex?: number;
  first: number;
  offset: number;
  evalStateIn?: string[];
  filters?: string[] | null;
  sortBy?: string[];
}

export type ResponseSingleTask = {
  result: string;
  tags?: string[];
  args: string;
};

export type ResponseManualTasks = {
  displayId: string;
  taskId: string;
  createdAt: string;
  completedAt: null | string;
  result: string;
  tags?: string[];
  args: string;
  status: TASKS_STATUS;

  evalState?: string;
  statusLog?: any[];
};

export interface ResponseTaskWithStatusLog {
  profileId: string;
  objectType: string;
  objectId: string;

  createdAt: string;
  completedAt: null | string;
  expiredAt: string;

  evaluation?: string;
  documents: IDossier[];
  status: string;
  evalState?: string;
  statusLog?: IStatusLog[];
}

export interface IDossier {
  dossierId: string;
  docType: string;
  docRef: string | null;
  docVal: string;
}

export interface IStatusLog {
  newStatus: ACCREDITATION_STATUS_IN;
  newEvalState: ACCREDITATION_EVAL_STATE;
  changedAt: string;
  changedByName: string;
  changedByEmail: string;
  comment: string;
}

export type ResponseManualRisks = {
  profileId: string;
  completedAt: null | string;
  updatedAt: string;
  createdAt: string;
  checkedOutAt: null | string;
  documents: {
    docRef: string;
    docType: string;
    docVal: string;
    dossierId: string;
  }[];
  objectType: string;
  objectId: string;
  expiredAt: string;
  status: string;
  evalState: string;
  evaluation: string;
  statusLog: {
    changedAt: string;
    changedByName: string;
    changedByEmail: string;
    comment: string;
    newEvalState: string;
    newStatus: string;
  }[];
}

export interface IAccreditationTableRowData {
  id: string;
  displayId: string;
  name: string;
  legalAddress: string;
  inn: string;
  kpp: string;
  signer: string | null;
  phoneNumber: string | null;
  accredDate: string | null;
  reason: string | null | undefined;
  orgId: string;
  profileId: string;
  registrationNumber: string | null;
  organizationDetail: IOrganizationData | null;
  taskId: string;
  role?: string;
  email?: string;
  legalForm?: string;

  status: ACCREDITATION_STATUS;
  statusLog?: any[];
  evalState?: string;
}

export interface IAccreditationDetailPage {
  id: string;
  displayId: string;
  name: string;
  legalAddress: string;
  inn: string;
  kpp: string;
  signer: string | null;
  phoneNumber: string | null;
  evaluation?: {
    value?: boolean;
    comment?: string | null;
  };
  accredDate: string | null;
  reason: string | null;
  orgId: string;
  profileId: string;
  registrationNumber: string | null;
  organizationDetail: IOrganizationData | null;
  taskId: string;
  role?: string;
  email?: string;
  legalForm?: string;

  status: string;
  statusLog?: IStatusLog[];
  evalState?: string;
  dossier?: IDossier;
  checkLists?: IDossier[]; //заполненные регламенты
  objectType?: string; //тип заявки
}

export interface IOrganizationData {
  contacts: IOrganizationContacts;
  organization: IOrganizationInfo;
  vehicle?: {
    ownership: string;
    vehicles: IOrganizationVehicle[];
  };
}

export interface IOrganizationVehicle {
  regNumber: string;
  photoFirst: IOrganizationFile;
  photoSecond: IOrganizationFile;
}

interface IOrganizationContacts {
  addPhone?: string;
  attorney?: IOrganizationFile;
  fullName?: string;
  phone?: string;
  post?: string;
}

interface IOrganizationInfo {
  appointmentOrder?: IOrganizationFile;
  directorFullName?: string;
  fullName?: string;
  passportPhoto?: IOrganizationFile;
  passportRegistrationPhoto?: IOrganizationFile;
  registrationCertificate?: IOrganizationFile;
  regulation?: IOrganizationFile;
  ogrnDate?: string;
  selfEmployedReferrence?: IOrganizationFile;
}

export interface IOrganizationFile {
  fileName: string | null;
  fileUrl: string | null;
}

export interface IRegulation {
  id?: string;
  name?: string;
  objectType?: string;
  rules?: IRegulationRule[],
}

export interface IRegulationRule {
  id?: string;
  name?: string;
  valType?: string;
  defValue?: string | null;
  value: string | boolean | number | null;
}
