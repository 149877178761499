import { TAdminOrganizationForm } from '@transport/ui-interfaces';

export const subOrganizationFeatureKey = 'subOrganization';

export interface ISubOrganizationProfileState {
  loading: boolean;
  reactiveFormValue: TAdminOrganizationForm;
  isArchived: boolean;
}

export const subOrganizationProfileInitialState: ISubOrganizationProfileState = {
  loading: false,
  reactiveFormValue: {} as TAdminOrganizationForm,
  isArchived: false,
};
