import { FormControl } from "@angular/forms";
import { ICommonSelectData } from "libs/custom-controls/src/lib/common.interface";

export interface DataGridColumn<TData>{
  columnDef: string;
  hasFilter: boolean;
  filterType?: filterType;
  filterOptions?: ICommonSelectData[];
  isVisible: boolean;
  hasSort: boolean;
  sortType?: sortType;
  header: string;
  styles?: string;
  stylesFn?: (element: TData) => string;
  filterControl?: FormControl<string | null>;
  isTemplate?: boolean;
  width?: number;
  widthControl?: FormControl<string | null |number>;
  cell: (element: TData) => string;
}

export interface RowActions<TData> {
  label: string;
  action: (element: TData) => void;
}

export type sortType = 'asc' | 'desc' | '';

export type filterType = 'date' | 'select' | 'number' | 'checkbox';
