import { ChangeDetectionStrategy, Component, EventEmitter, Inject, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { tap } from 'rxjs/operators';

import { TnYamapsBalloonsService } from '../../services/balloons/yamaps-balloons.service';
import { TnYamapsControlsService } from '../../services/controls/yamaps-controls.service';
import { TnYamapsGeocodeService } from '../../services/geocode/yamaps-geocode.service';
import { TnYamapsLoaderService } from '../../services/loader/yamaps-loader.service';
import { TnYamapsUtilsService } from '../../services/utils/yamaps-utils.service';
import { YAMAPS_DEFAULT_MAP_CENTER } from '../../yamaps.constants';
import { TnYamapBaseDirective } from './yamap-base';

@UntilDestroy()
@Component({
  selector: 'yamap-root',
  templateUrl: './yamap.component.html',
  styleUrls: ['./yamap.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- TODO: tech debt
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TnYamapComponent extends TnYamapBaseDirective implements OnInit, OnDestroy {
  /**  Declare start ponts of router */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: tech debt
  private readonly points: any = [];

  /**  Map center of map */
  @Input() public center: number[] | string = '';

  /**  Balloons config array */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: tech debt
  private readonly balloons: any[] = [];

  /**  Get coordinates by click mode */
  @Input() public getCoordinatesByClick = false;

  /**  Draggble point data callback */
  @Output() public readonly pointDragDataChange = new EventEmitter();

  /**
   * Constructor.
   * Gets map id when called.
   */
  constructor(
    public control: TnYamapsControlsService,
    protected readonly yamapsLoader: TnYamapsLoaderService,
    protected readonly utils: TnYamapsUtilsService,
    private readonly balloonsService: TnYamapsBalloonsService,
    private readonly geocode: TnYamapsGeocodeService,
  ) {
    super(yamapsLoader, utils);
  }

  /**
   * Update route points
   * @param referencePoints Array with point of route.
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: tech debt
  public update(referencePoints: any[]): void {
    if (this.utils.checkContainer(this.mapId)) {
      if (typeof referencePoints[referencePoints.length - 1] === 'string') {
        void this.geocode.addressToCoordinates(referencePoints[referencePoints.length - 1]).then(res => {
          this.map?.geoObjects.add(
            this.balloonsService.getBalloon({
              type: 'point',
              coordinates: res,
              hintContent: referencePoints[referencePoints.length - 1],
              baloonContent: referencePoints[referencePoints.length - 1],
              draggable: true,
              dragCallback: position => {
                this.pointDragDataChange.emit(position);
              },
            }),
          );
          this.map?.setCenter(res);
        });
      } else {
        void this.geocode
          .getMapCenter(this.center, this.points)
          .pipe(
            tap(mapCenter => {
              this.map?.geoObjects.add(
                this.balloonsService.getBalloon({
                  type: 'point',
                  coordinates: referencePoints,
                  hintContent: 'hintContent',
                  baloonContent: 'baloonContent',
                }),
              );
              this.map?.setCenter(referencePoints);
            }, untilDestroyed(this)),
          )
          .subscribe();
      }
    }
  }

  /**
   * Remove All GeoObjects
   */
  public removeAllGeoObjects() {
    this.map?.geoObjects.removeAll();
  }

  /**
   * mount map.
   */
  protected mount(): void {
    if (this.center === 'home') {
      this.center = this.utils.defaultCity;
    }
    void this.geocode
      .getMapCenter(this.center, this.points)
      .pipe(
        tap(mapCenter => {
          this.simpleMapInit(mapCenter);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  /**
   * Initialization simple map
   * @param mapCenter  Center of map.
   */
  public simpleMapInit(mapCenter?): void {
    this.map = new this.ymaps.Map(
      this.mapId,
      {
        center: Boolean(mapCenter) ? mapCenter : YAMAPS_DEFAULT_MAP_CENTER,
        zoom: this.zoom,
        controls: this.setControls(),
      },
      {},
    );
    this.balloonsInit();
    if (this.getCoordinatesByClick) {
      this.getCoordinatesByClickInit();
    }
    this.redraw();
  }

  /**
   * Initialization balloons
   */
  private balloonsInit(): void {
    if (this.utils.checkContainer(this.mapId)) {
      if (this.balloons.length > 0) {
        this.balloons.forEach(config => {
          this.map?.geoObjects.add(this.balloonsService.getBalloon(config));
        });
      }
    }
  }
}
