import { Injectable } from '@angular/core';
import {
  ApolloClient,
  ApolloClientOptions,
  ApolloQueryResult,
  FetchResult,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client/core';
import { TranslateService } from '@ngx-translate/core';
import { GRAPHQL_QUERY, SHARED_MUTATION } from '@transport/gql';
import { transport } from '@transport/proto';
import {
  ICargoPackagingType,
  IGqlEditOrderUploadedDocumentsResponse,
  IGqlGetOrderDocumentTypesResponse,
  IGqlUploadFileResponse,
  IOrder,
  IUiSettings,
  TCarrierOrdersFilterDto,
  TnFileToUpload,
  TOrderAttachmentForSave,
  USER_ROLE,
} from '@transport/ui-interfaces';
import { Apollo, ApolloBase } from 'apollo-angular';
import { DocumentNode } from 'graphql';
import moment from 'moment';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { TnHttpHandlersService } from '../feature-access';
import INotificationSettingsOverallType = transport.UserProfile.UserSettings.INotificationSettingsOverallType;
import IUserSettings = transport.UserProfile.IUserSettings;
import IUserProfile = transport.IUserProfile;
import ISystemBanner = transport.ISystemBanner;

/**
 * GraphQL client service.
 */
@Injectable({
  providedIn: 'root',
})
export class TnGqlClientSharedService {
  /**
   * Constructor.
   * @param apollo Apollo client
   * @param handlers Custom Http Handlers Service
   * @param translate
   */
  constructor(
    private readonly apollo: Apollo,
    private readonly handlers: TnHttpHandlersService,
    private readonly translate: TranslateService,
  ) {
    this.apollo.getClient();
  }

  /**
   * Creates apollo client for a specific user role.
   * @param userRole user role
   */
  public createApolloClientFor(userRole: USER_ROLE): void {
    const options: ApolloClientOptions<NormalizedCacheObject> = this.getApolloClientOptions(userRole);
    this.apollo.create(options, userRole);
  }

  /**
   * Resets apollo client for a specific user role.
   * @param userRole user role
   */
  public resetApolloClientFor(userRole: USER_ROLE): void {
    const options = this.getApolloClientOptions(userRole);
    const client = new ApolloClient(options);
    const base = this.apollo.use(userRole);
    this.clearClient(base);
    this.apollo.use(userRole).setClient(client);
  }

  /**
   * query - a read-only fetch.
   * @param userRole to select existing apollo client
   * @param query to execute
   * @param listenX
   * @param variables are arguments for fileds
   * @param [withSpinner] should request start spinner
   * @returns [Observable<any>] updated value
   */
  public query<T>(
    userRole: USER_ROLE,
    query: DocumentNode,
    listenX: number,
    variables?: { [key: string]: unknown },
    withSpinner?: boolean,
  ) {
    const vars = Boolean(variables) ? variables : {};
    // USER_ROLE.CARRIER для нотификаций из Маркетплейса. Роль не находит в фасаде сразу после логина
    const observable = this.apollo.use(userRole || USER_ROLE.CARRIER).watchQuery<T>({
      query,
      variables: vars,
      fetchPolicy: 'no-cache',
    }).valueChanges;
    return this.handlers.pipeGraphQLRequest<T, ApolloQueryResult<T>>(observable, listenX, withSpinner);
  }

  /**
   * mutation - a write followed by a fetch.
   * @param userRole to select existing apollo client
   * @param mutation to execute
   * @param variables are arguments for fileds
   * @param withSpinner should request start spinner
   * @returns [Observable<any>] updated value
   */
  public mutate<T>(
    userRole: USER_ROLE,
    mutation: DocumentNode,
    variables: { [key: string]: unknown } = {},
    withSpinner?: boolean,
    httpTimeout?: number,
    context?: any,
  ) {
    const observable = this.apollo.use(userRole).mutate<T>({
      mutation,
      variables,
      fetchPolicy: 'no-cache',
      context,
    });
    return this.handlers.pipeGraphQLRequest<T, FetchResult<T>>(observable, 1, withSpinner, httpTimeout);
  }

  /**
   * Upload files via  GraphQL mutations.
   * @param files
   * @param orderId
   */
  public attachContract(files: TnFileToUpload[], orderId: string): Observable<{ attachContract: IOrder }> {
    if (files.length > 1) {
      return new Observable<FetchResult>() as Observable<{ attachContract: IOrder }>;
    }
    return this.mutate<{ attachContract: IOrder }>(USER_ROLE.CARRIER, SHARED_MUTATION.singleUpload, {
      file: files[0].fileData,
      orderId,
    });
  }

  public uploadFile(userRole: USER_ROLE, file: TnFileToUpload, withSpinner: boolean): Observable<IGqlUploadFileResponse> {
    return this.mutate<IGqlUploadFileResponse>(
      userRole,
      SHARED_MUTATION.singleUploadNew,
      {
        input: {
          name: file.fullName,
          file: file.fileData,
        },
      },
      withSpinner,
    );
  }

  public getOrderDocumentTypes(userRole: USER_ROLE): Observable<IGqlGetOrderDocumentTypesResponse> {
    return this.query<IGqlGetOrderDocumentTypesResponse>(userRole, GRAPHQL_QUERY.shared.getOrderDocumentTypes, 1);
  }

  public getUserProfile(role: USER_ROLE, isWorkingWithTN: boolean): Observable<transport.IUserProfile> {
    if (role === USER_ROLE.TNDL_SECURITY_STAFF) {
      return of();
    }
    const queryString =
      role === USER_ROLE.OWNER
        ? GRAPHQL_QUERY.shared.getOwnerProfile
        : isWorkingWithTN
        ? GRAPHQL_QUERY.shared.getCarrierProfileWorkingWithTn
        : GRAPHQL_QUERY.shared.getCarrierProfile;
    return this.query<{ profile: transport.IUserProfile }>(role, queryString, 1).pipe(map(resp => resp.profile));
  }

  public getUserProfileLogoAndName(role: USER_ROLE): Observable<transport.OwnerOrganizationSubdomain> {
    const queryString = GRAPHQL_QUERY.shared.getProfileNameAndLogo;
    return this.query<{ profile: transport.OwnerOrganizationSubdomain }>(role, queryString, 1).pipe(map(resp => resp.profile));
  }

  public getDomainOwnerParams(role: USER_ROLE, subdomain: string | null): Observable<transport.OwnerOrganiationContractWithCarrier> {
    const queryString = GRAPHQL_QUERY.shared.getCompanyDomainNameAndLogo;
    return this.query<{ cargoOwnerOrganization: transport.OwnerOrganiationContractWithCarrier }>(role, queryString, 1, {
      subdomain: subdomain,
    }).pipe(map(resp => resp.cargoOwnerOrganization));
  }

  public queryOrganizationVatTypes(userRole: USER_ROLE): Observable<transport.IOrganizationVatType[]> {
    return this.query<{ vatTypes: transport.IOrganizationVatType[] }>(userRole, GRAPHQL_QUERY.shared.getVatTypes, 1).pipe(
      map(data => data.vatTypes),
    );
  }

  public queryCargoDocTypes(userRole: USER_ROLE): Observable<transport.ICargoDocType[]> {
    return this.query<{ cargoDocTypes: transport.ICargoDocType[] }>(userRole, GRAPHQL_QUERY.shared.getCargoDocType, 1).pipe(
      map(data => data.cargoDocTypes),
    );
  }

  public attachDocuments(
    documents: TOrderAttachmentForSave[],
    orderId: string,
    userRole: USER_ROLE,
  ): Observable<IGqlEditOrderUploadedDocumentsResponse> {
    const documentsToAdd = documents.map(
      item =>
        ({
          documentTypeId: item.documentType?.id,
          uploadedFileId: item.uploadedFile.id,
          signedFileId: item.signedFileId,
        } as transport.IAddOrderUploadedDocumentInput),
    );
    return this.mutate<IGqlEditOrderUploadedDocumentsResponse>(userRole, SHARED_MUTATION.editOrderUploadedDocuments, {
      input: {
        orderId,
        documentsToAdd,
      } as transport.IEditOrderUploadedDocumentsInput,
    });
  }

  public editDocuments(
    documentsToEdit: transport.IEditOrderUploadedDocumentInput[],
    orderId: string,
    userRole: USER_ROLE,
  ): Observable<IGqlEditOrderUploadedDocumentsResponse> {
    return this.mutate<IGqlEditOrderUploadedDocumentsResponse>(userRole, SHARED_MUTATION.editOrderUploadedDocuments, {
      input: {
        documentsToEdit,
        orderId,
      } as transport.IEditOrderUploadedDocumentsInput,
    });
  }

  public removeDocument(documentId: string, orderId: string, userRole: USER_ROLE): Observable<IGqlEditOrderUploadedDocumentsResponse> {
    return this.mutate<IGqlEditOrderUploadedDocumentsResponse>(userRole, SHARED_MUTATION.editOrderUploadedDocuments, {
      input: {
        documentsToDelete: [documentId],
        orderId: orderId,
      } as transport.IEditOrderUploadedDocumentsInput,
    });
  }

  /**
   * Returns apollo client options depending on user role.
   * @param userRole user role: carrier, owner
   */
  private getApolloClientOptions(userRole: USER_ROLE): ApolloClientOptions<NormalizedCacheObject> {
    const options: ApolloClientOptions<NormalizedCacheObject> = {
      link: this.handlers.createApolloLinkFor(userRole),
      cache: new InMemoryCache({ resultCaching: false }),
      defaultOptions: {
        query: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
        watchQuery: {
          fetchPolicy: 'no-cache',
          errorPolicy: 'all',
        },
        mutate: {
          errorPolicy: 'all',
        },
      },
    };
    return options;
  }

  /**
   * Clears apollo client.
   * @param base apollo client base
   */
  private clearClient(base: ApolloBase<NormalizedCacheObject> | any): void {
    if (Boolean(base.map)) {
      base.map.delete('USER_CLIENT');
    }
    if (Boolean(base._client)) {
      delete base._client;
    }
    const client = base.getClient();
    if (Boolean(client)) {
      void client.resetStore();
    }
  }

  public editUiSettings(userRole: USER_ROLE, uiSettings: IUiSettings): Observable<IUiSettings> {
    return this.mutate<{ editProfileSettings?: { uiSettings: string } }>(
      userRole,
      SHARED_MUTATION.editSelfProfile,
      { input: { uiSettings: JSON.stringify(uiSettings) } },
      false,
    ).pipe(
      switchMap(value => {
        const settings: IUiSettings = JSON.parse(value.editProfileSettings?.uiSettings ?? '{}');
        return of(settings);
      }),
    );
  }

  public editNotificationSettings(
    userRole: USER_ROLE,
    notificationSettings: INotificationSettingsOverallType,
  ): Observable<INotificationSettingsOverallType> {
    const mutationString = userRole === USER_ROLE.OWNER ? SHARED_MUTATION.editOwnerSelfProfile : SHARED_MUTATION.editCarrierSelfProfile;
    return this.mutate<{ editProfileSettings?: { userSettings: IUserSettings } }>(
      userRole,
      mutationString,
      { input: { notificationSettings } },
      false,
    ).pipe(switchMap(value => of(value.editProfileSettings?.userSettings?.notificationSettings as INotificationSettingsOverallType)));
  }

  public getPackagingTypesListMap(value: { listPackagingTypesForCargoType: string[] }): ICargoPackagingType[] {
    return value.listPackagingTypesForCargoType.map((item: string) => {
      return {
        id: item,
        name: this.translate.instant(`owner.cargoType.packagingType.${item.toLowerCase()}`),
      } as ICargoPackagingType;
    });
  }

  public getDateInterval(dateRangeList: string | undefined, type: string): TCarrierOrdersFilterDto {
    const filterValue = {};
    if (Boolean(dateRangeList)) {
      const [from, to] = dateRangeList?.split(',') as string[];
      const fromDate = Boolean(from) ? moment(from, 'YYYY-MM-DD').format('YYYY-MM-DD') : '';
      const toDate = Boolean(to) ? moment(to, 'YYYY-MM-DD').format('YYYY-MM-DD') : '';
      if (Boolean(fromDate)) {
        filterValue[`${type}From`] = fromDate;
      }
      if (Boolean(toDate)) {
        filterValue[`${type}To`] = toDate;
      }
    }

    return filterValue as TCarrierOrdersFilterDto;
  }

  public editUserName(userRole: USER_ROLE, profileInput: Partial<IUserProfile>): Observable<{ editProfile: { id: string } }> {
    return this.mutate(userRole, SHARED_MUTATION.editUserName, { profileInput });
  }

  public editUserPhone(userRole: USER_ROLE, profileInput: Partial<IUserProfile>): Observable<{ editProfile: { id: string } }> {
    return this.mutate(userRole, SHARED_MUTATION.editUserPhone, { profileInput });
  }

  public editUserCountry(userRole: USER_ROLE, profileInput: Partial<IUserProfile>): Observable<{ editProfile: { id: string } }> {
    return this.mutate(userRole, SHARED_MUTATION.editUserCountry, { profileInput });
  }

  public editIsNewDesign(value: boolean): Observable<boolean> {
    return this.mutate<{ editProfileSettings?: { userSettings: IUserSettings } }>(
      USER_ROLE.CARRIER,
      SHARED_MUTATION.editCarrierSelfProfile,
      { input: { isNewDesign: value } },
      false,
    ).pipe(switchMap(response => of(!Boolean(response.editProfileSettings?.userSettings?.isNewDesign))));
  }

  public queryNotificationsWithCounts(
    userRole: USER_ROLE,
    withSpinner = false,
    isRead: boolean | null = null,
    first?: number,
    offset?: number,
    listenX = 1,
  ): Observable<transport.INotificationsWithCounters> {
    return this.query<transport.INotificationsWithCounters>(
      userRole,
      GRAPHQL_QUERY.shared.getNotificationsWithCounts,
      listenX,
      {
        isRead,
        first,
        offset,
      },
      withSpinner,
    );
  }

  public queryNotificationsCount(
    userRole: USER_ROLE,
    withSpinner = false,
    isRead = false,
    first?: number,
    offset?: number,
    listenX = 1,
  ): Observable<{ count: number }> {
    return this.query<{ count: number }>(
      userRole,
      GRAPHQL_QUERY.shared.getNotificationsCount,
      listenX,
      {
        isRead,
        first,
        offset,
      },
      withSpinner,
    );
  }

  public markNotificationAsReadById(
    userRole: USER_ROLE,
    notificationId: string,
    withSpinner?: boolean,
  ): Observable<{ markNotificationAsRead: boolean }> {
    return this.mutate<{ markNotificationAsRead: boolean }>(
      userRole,
      SHARED_MUTATION.markNotificationAsRead,
      {
        notificationId,
      },
      withSpinner,
    );
  }

  public markAllNotificationsAsRead(userRole: USER_ROLE, withSpinner?: boolean): Observable<{ markAllNotificationsAsRead: boolean }> {
    return this.mutate<{ markAllNotificationsAsRead: boolean }>(userRole, SHARED_MUTATION.markAllNotificationsAsRead, {}, withSpinner);
  }

  public getSystemBanner(userRole: USER_ROLE): Observable<ISystemBanner> {
    return this.query<{ bannerNotification: ISystemBanner }>(userRole, GRAPHQL_QUERY.shared.getSystemBanner, 1).pipe(
      map(result => result.bannerNotification),
    );
  }

  public isDomainExist(organizationSubdomain: string): Observable<boolean> {
    const obs = this.apollo.use(USER_ROLE.CARGO_OWNER).watchQuery<{
      organizationBySubdomain: { subdomain: string };
    }>({
      query: GRAPHQL_QUERY.shared.getOrganization,
      variables: { organizationSubdomain },
      fetchPolicy: 'no-cache',
    }).valueChanges;
    return obs.pipe(
      switchMap(res => {
        let isDomainExit = false;
        if (Boolean(res.data.organizationBySubdomain)) {
          isDomainExit = true;
        }
        return of(isDomainExit);
      }),
    );
  }

  public queryObjectAvailability(userRole: USER_ROLE, orderId: string): Observable<Partial<IOrder> | null> {
    return this.query<{ order: Partial<IOrder> }>(userRole, GRAPHQL_QUERY.shared.getOrderAvailability, 1, { orderId }).pipe(
      map(res => res?.order),
      catchError(err => {
        if (err.extensions?.code === 'OBJECT_NOT_FOUND') {
          return of(null);
        }
        return throwError(err);
      }),
    );
  }
}
