import { Injectable } from '@angular/core';
import { TnUserService } from '@transport/ui-store';
import { BehaviorSubject } from 'rxjs';

export const enum APPLICATION {
  CLOUD_TMS,
  CLOUD_EXCHANGE,
}

export const EXCHANGE_ROUTER_URLS = ['marketplace', 'tenders', 'carrierAuction', 'auction']; // Routes on which the application switches to the exchange

export const EXCHANGE_ROUTER_ORDERS = 'kind=auction';

@Injectable({
  providedIn: 'root',
})
export class TnChangeApplicationService {
  constructor(private readonly userService: TnUserService) {}

  private readonly applicationChanged$ = new BehaviorSubject<APPLICATION>(this.applicationInCookie);

  public getApplicationChanged$() {
    return this.applicationChanged$;
  }

  private get applicationInCookie() {
    if (this.userService.getCookie('application') === '1') {
      return APPLICATION.CLOUD_EXCHANGE;
    }
    return APPLICATION.CLOUD_TMS;
  }
}
