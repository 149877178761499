import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { of, Subject } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { DriverInviteDialogService } from './driver-invite-dialog.service';
import { USER_ROLE } from '@transport/ui-interfaces';

@UntilDestroy()
@Component({
  selector: 'transport-driver-invite-dialog',
  templateUrl: './driver-invite-dialog.component.html',
  styleUrls: ['./driver-invite-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnDriverInviteModal implements OnInit {
  public resultInvite = true;

  public isLoading = false;

  private readonly inviteDriverSubject = new Subject<string>();

  public inviteDriver$ = this.inviteDriverSubject.asObservable();

  constructor(
    public dialogRef: MatDialogRef<TnDriverInviteModal>,
    @Inject(MAT_DIALOG_DATA)
    public data: { hasBeenInvitedToDriverMobile: boolean; driverId: string, role: USER_ROLE },
    private readonly modalService: DriverInviteDialogService,
    private readonly changeDetector: ChangeDetectorRef,
  ) {}

  public ngOnInit() {
    void this.inviteDriver$
      .pipe(
        switchMap((id: string) => this.modalService.inviteDriver(this.data.role, id)),
        untilDestroyed(this),
        catchError(() => of({ inviteDriverToMobile: false })),
      )
      .subscribe(result => {
        this.resultInvite = result.inviteDriverToMobile;
        this.isLoading = false;
        this.changeDetector.markForCheck();
      });
  }

  public close() {
    this.dialogRef.close();
  }

  public invite() {
    this.isLoading = true;
    this.inviteDriverSubject.next(this.data.driverId);
  }
}
