import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'marketplace-table-tag',
  template: `{{text}}`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'px-2 bg-gray-100 rounded-md text-gray-700 text-xs leading-4 font-medium whitespace-nowrap',
  },
})
export class TableTagComponent {
  @Input() text: string = '';
}
