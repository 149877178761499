import { FormArray, FormControl, FormGroup, Validators } from '@angular/forms';
import { transport } from '@transport/proto';
import { IGCarrier } from '@transport/ui-interfaces';
import { CurrencySigns, TnSupportedCurrency } from '@transport/ui-pipes';
import { CARGO_MAX_PRICE, CARGO_MIN_PRICE, MAX_LENGTH_150, PLACE_TYPE_POINT } from 'libs/transport-ui-utils/src/lib/constants';
import { Moment } from 'moment';

import { lessThanNowValidator, timeToMoreThanTimeFromValidator, VEHICLE_REQUIREMENTS } from '../form-validators';
import { nextBeforePrevValidator } from '../form-validators/order-date.validator';

export interface ICoordinates {
  longitude: string;
  latitude: string;
}

export enum ROUTE_POINT_STATUS {
  NOT_STARTED = 'NOT_STARTED',
  CANCELLED = 'CANCELLED',
  ON_POINT = 'ON_POINT',
  COMPLETED = 'COMPLETED',
}

export interface IOrderDirectionsPointFormInnerValue {
  placeId: string | null;
  date: Moment | null;
  dateTo: Moment | null;
  timeFrom: string | null;
  timeTo: string | null;
  timezone: string | null;
  address: string;
  comment: string | null;
  coordinates?: ICoordinates | null;
  contactPersons?: transport.IOrderStoragePointContactPerson[] | null;
  loadingType?: transport.ILoadingType[];
  drivingDirectionsImg: string | null;
  attachedDocuments?: transport.Order.AttachedDocuments[];
  placeType?: PLACE_TYPE_POINT | null;
  seqId?: number | null;
  cargos?: Partial<IGCarrier.ICargoType>[] | null;
  deliveryUnits?: Partial<IGCarrier.IDeliveryUnit[]> | null;

  settlement?: string | null;
  name?: string | null;
  org?: string | null;
  pointName?: string | null;
  operationId?: string;
  customFields?: {
        extId: string;
        label: string;
        value: string | null;
        options: {
          displayCargoOwner: boolean;
          displayCarrier: boolean;
          displayDriver: boolean;
          editableByCargoOwner: boolean;
        };
      }[]
    | null;
  factArriveDatetimeFrom?: string;
  factArriveDatetimeTo?: string;
  status?: ROUTE_POINT_STATUS | null;
}

export interface IOrderDirectionsFormInnerValue {
  loadingPlaces: IOrderDirectionsPointFormInnerValue[];
  unloadingPlaces: IOrderDirectionsPointFormInnerValue[];
}

export enum UNLOADING_TYPE {
  SETBYWAYBILL = 'setByWaybill',
  VOLUME = 'volume',
  WEIGHT = 'weight',
  QUANTITY = 'quantity',
}

// eslint-disable-next-line
export const createPlaceFormGroup = (
  formValue: IOrderDirectionsPointFormInnerValue,
  required = true,
  disabled = true,
  isNew = false,
) => {
  return new FormGroup(
    {
      placeId: new FormControl({ value: formValue.placeId, disabled }, Validators.required),
      coordinates: new FormControl(formValue.coordinates),
      address: new FormControl({ value: formValue.address, disabled }),
      comment: new FormControl({ value: formValue.comment, disabled }, Validators.maxLength(MAX_LENGTH_150)),
      date: new FormControl(
        { value: isNew ? '' : formValue.date, disabled },
        Validators.compose([Validators.required, lessThanNowValidator(''), nextBeforePrevValidator()]),
      ),
      dateTo: new FormControl(
        { value: isNew ? '' : formValue.dateTo, disabled },
        Validators.compose([lessThanNowValidator(''), nextBeforePrevValidator()]),
      ),
      timeFrom: new FormControl(
        { value: formValue.timeFrom, disabled },
        Validators.compose([Validators.required, Validators.minLength(5)]),
      ),
      timeTo: new FormControl({ value: formValue.timeTo, disabled }, Validators.compose([Validators.minLength(5)])),
      timezone: new FormControl({ value: formValue.timezone, disabled }),
      contactPersons: new FormArray(
        formValue.contactPersons?.length
          ? formValue.contactPersons.map(
              contact =>
                new FormControl({
                  value: {
                    fullName: contact.fullName,
                    phoneNumber: contact.phoneNumber,
                    contactInfo: contact.contactInfo,
                  },
                  disabled,
                }),
            )
          : [],
      ),
      loadingType: new FormControl({ value: formValue.loadingType, disabled }, required ? Validators.required : null),
      drivingDirectionsImg: new FormControl(formValue.drivingDirectionsImg),
      attachedDocuments: new FormControl(formValue.attachedDocuments),
      placeType: new FormControl(formValue.placeType),
      seqId: new FormControl(formValue.seqId),
      cargos: new FormArray([]),
      deliveryUnits: new FormArray([]),
      customFields: new FormArray(
        formValue?.customFields?.length
          ? formValue.customFields.map(
              field =>
                new FormGroup({
                  extId: new FormControl(field.extId),
                  label: new FormControl(field.label),
                  value: new FormControl(field.value, Validators.maxLength(100)),
                  options: new FormControl({
                    displayCargoOwner: field.options?.displayCargoOwner,
                    displayCarrier: field.options?.displayCarrier,
                    displayDriver: field.options?.displayDriver,
                    editableByCargoOwner: field.options?.editableByCargoOwner,
                  }),
                }),
            )
          : [],
      ),
      pointName: new FormControl(formValue?.pointName),
      factArriveDatetimeFrom: new FormControl(formValue?.factArriveDatetimeFrom),
      factArriveDatetimeTo: new FormControl(formValue?.factArriveDatetimeTo),
      status: new FormControl(formValue?.status),
    },
    {
      validators: [timeToMoreThanTimeFromValidator('timeFrom', 'timeTo', 'date', 'dateTo')],
      // updateOn: 'blur',
    },
  );
};

export const createCargoFormGroup = (
  placeIndex: number,
  cargo?,
  isCargoPriceRequired = false,
  mainFormCurrency: TnSupportedCurrency = TnSupportedCurrency.RUB,
): FormGroup => {
  const { size, name, cargoId, price, extCargoId, docType } = cargo ?? {};
  return new FormGroup({
    cargoId: new FormControl(cargoId ?? ''),
    extCargoId: new FormControl(extCargoId ?? '', Validators.maxLength(600)),
    docType: new FormControl(
      docType
        ? {
            ...docType,
            label: docType?.name,
          }
        : null,
    ),
    createdCargoId: new FormControl(''),
    id: new FormControl(cargoId ?? ''),
    loadingPlaceId: new FormControl(placeIndex),
    label: new FormControl(''),
    name: new FormControl(name ?? ''),
    size: new FormGroup({
      weight: new FormControl(size?.weight ?? null, Validators.max(VEHICLE_REQUIREMENTS.MAX_CAPACITY_VEHICLE)),
      volume: new FormControl(size?.volume ?? null, Validators.max(VEHICLE_REQUIREMENTS.MAX_VOLUME_VEHICLE)),
      packageType: new FormControl(size?.packageType ?? null),
      quantity: new FormControl(size?.quantity ?? null),
      dimensions: new FormGroup({
        dimensionsType: new FormControl('m'), //Значение единиц измерения пока нельзя получить с бэка
        length: new FormControl(size?.length ?? null),
        width: new FormControl(size?.width ?? null),
        height: new FormControl(size?.height ?? null),
      }),
      setByWaybill: new FormControl(size ? size.setByWaybill : true),
    }),
    price: new FormGroup({
      amount: new FormControl(
        // Удаляем нулевые копейки из стоимости груза.
        price?.amount ? Number(price.amount).toString() : null,
        isCargoPriceRequired
          ? [Validators.min(CARGO_MIN_PRICE), Validators.max(CARGO_MAX_PRICE), Validators.required]
          : [Validators.min(CARGO_MIN_PRICE), Validators.max(CARGO_MAX_PRICE)],
      ),
      currency: new FormControl({ id: price?.currency ?? mainFormCurrency, label: CurrencySigns[price?.currency ?? mainFormCurrency] }),
      // currency: new FormControl({id: price?.currency ?? TnSupportedCurrency.RUB, label: CurrencySigns[price?.currency ?? TnSupportedCurrency.RUB]}),
    }),
  });
};

export const getUnloadinType = (size): UNLOADING_TYPE => {
  if (size?.setByWaybill) return UNLOADING_TYPE.SETBYWAYBILL;
  if (size?.weight) return UNLOADING_TYPE.WEIGHT;
  if (size?.volume) return UNLOADING_TYPE.VOLUME;
  if (size?.quantity) return UNLOADING_TYPE.QUANTITY;
  return UNLOADING_TYPE.SETBYWAYBILL;
};

export const createDeliveryUnitFormGroup = (placeIndex: number, deliveryUnit?): FormGroup => {
  const { cargoId, size } = deliveryUnit ?? {};
  return new FormGroup({
    cargoId: new FormControl(cargoId ?? ''),
    deliveryPointId: new FormControl(placeIndex),
    size: new FormGroup({
      weight: new FormControl(size?.weight ?? null),
      volume: new FormControl(size?.volume ?? null),
      packageType: new FormControl(size?.packageType ?? null),
      quantity: new FormControl(size?.quantity ?? null),
      dimensions: new FormGroup({
        dimensionsType: new FormControl('m'),
        length: new FormControl(size?.length ?? null),
        width: new FormControl(size?.width ?? null),
        height: new FormControl(size?.height ?? null),
      }),
      // В виджете, для определения какой тип разгрузки активный
      unloadingType: new FormControl(getUnloadinType(size)),
      setByWaybill: new FormControl(size ? size.setByWaybill : true),
    }),
  });
};

export const emptyInputValue = () => {
  return {
    placeId: null,
    coordinates: null,
    date: null,
    timeFrom: null,
    timeTo: null,
    timezone: null,
    contactPersons: [],
    address: '',
    comment: '',
    contact: null,
    loadingType: [],
    drivingDirectionsImg: null,
    attachedDocuments: [],
    placeType: null,
  };
};
