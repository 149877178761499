/**
 * Fake points for maps
 */
export function mapPoints() {
  const points = {
    moscow: [
      ['55.748884524891295', '37.58427021262394'],
      ['55.758081420518685', '37.657397959205966'],
    ],
    russia: [
      ['59.96496533031128', '30.34035165470999'],
      ['56.87023179687424', '35.890532420765425'],
      ['55.771669472662644', '37.61304491265887'],
      ['54.62347062874368', '39.72709507191729'],
      ['55.80995822613999', '49.09446363143709'],
      ['54.305383967296976', '48.388339772616135'],
    ],
  };
  return points;
}
