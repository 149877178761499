import { NgModule } from '@angular/core';
import { TnUiComponentsModule } from '@transport/ui-components';
import { VehiclesTablePageComponent } from './table-page/vehicles-table-page.component';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnUiPipesModule } from '@transport/ui-pipes';
import { TnUiKitModule } from '@transport/ui-kit';
import { TnUiDirectivesModule } from '@transport/ui-directives';
import { VehiclesTableComponent } from './table/vehicles-table.component';
import { TableModule } from '@marketplace/shared/modules/table';
import { AppSharedModule } from '@marketplace/shared/modules/shared/app-shared.module';
import { CustomControlsModule } from '@transport/custom-controls';
import { FiltersToolbarComponent } from './filters/filters-toolbar/filters-toolbar.component';
import { VehiclesAmountService } from './filters/filters-toolbar/vehicle-amount.service';
import { VehiclesFilterModalComponent } from './filters/filter-modal/vehicles-filter-modal.component';
import { VehiclesTableService } from './table/vehicles-table.service';
import { Route, RouterModule } from '@angular/router';
import { VehicleBlacklistModalComponent } from 'libs/vehicles/src/lib/vehicle-blacklist-modal/vehicle-blacklist-modal.component';

export const ROUTES: Route[] = [
  {
    path: '',
    component: VehiclesTablePageComponent,
    pathMatch: 'full',
  },
];

@NgModule({
  declarations: [
    VehiclesTablePageComponent,
    VehiclesTableComponent,
    VehiclesFilterModalComponent,
    FiltersToolbarComponent,
    VehicleBlacklistModalComponent,
  ],
  imports: [
    TnUiMaterialModule,
    TnUiPipesModule,
    TnUiKitModule,
    TnUiDirectivesModule,
    TableModule,
    AppSharedModule,
    CustomControlsModule,
    TnUiComponentsModule,
    RouterModule.forChild(ROUTES),
  ],
  exports: [RouterModule],
  providers: [VehiclesTableService, VehiclesAmountService],
})
export class VehiclesModule {}
