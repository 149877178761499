import {Component, ContentChild, Input, TemplateRef} from "@angular/core";

@Component({
  selector: 'marketplace-table-column[columnName], marketplace-table-column[columnNames]',
  template: '',
})
export class MarketplaceTableColumnComponent {
  @Input() columnName: string = '';

  @Input() columnNames: string[] = [];

  @ContentChild(TemplateRef) public template: TemplateRef<unknown> | null = null;
}
