<div class="mb-3 text-sm font-medium text-gray-700" *ngIf="title">{{title}}</div>
<div class="grid grid-cols-1 sm:grid-cols-3 gap-3">
  <div>
    <div class="text-xs text-gray-500">{{placeholder}}</div>
    <div class="text-sm overflow-hidden whitespace-nowrap text-ellipsis" tn-tip="true">{{contactInfo?.fio}}</div>
  </div>

  <div>
    <div class="text-xs text-gray-500">{{ 'owner.order.field.phoneNumber' | translate }}</div>
    <div class="text-sm overflow-hidden whitespace-nowrap text-ellipsis" tn-tip="true">{{contactInfo?.phoneNumber | phoneFormat}}</div>
  </div>

  <div>
    <div class="text-xs text-gray-500">{{ 'owner.order.field.email' | translate }}</div>
    <div class="text-sm overflow-hidden whitespace-nowrap text-ellipsis" tn-tip="true" (dblclick)="copyMessage(contactInfo?.email)">{{contactInfo?.email}}</div>
  </div>
</div>
