import { ChangeDetectionStrategy, Component, ContentChild, Input, TemplateRef } from '@angular/core';

export type TColumnType = 'text' | 'number' | 'date' | 'icon';

@Component({
  selector: 'transport-table-column',
  template: '',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnTableColumnComponent {
  @ContentChild(TemplateRef)
  public template: TemplateRef<never> | null = null;

  @Input()
  public field?: string;

  @Input()
  public hasTooltip = false;

  @Input()
  public type: TColumnType = 'text';
}
