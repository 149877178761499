import { Injectable } from '@angular/core';
import { ILoadingPlaceForm } from '@transport/ui-interfaces';
import { map } from 'rxjs/operators';

import { TnGqlClientOwnerMutationsService } from '../../gql-client-owner/graphql-client-owner-mutations.service';

@Injectable({
  providedIn: 'root',
})
export class TnOwnerDirectoryPlaceTypesApiService {
  constructor(private readonly gqlMutation: TnGqlClientOwnerMutationsService) {}

  /**
   * Create new place type request
   * @param placeParam place type data
   * @returns Observable with status
   */
  public addLoadingUnloadingPlace(img: FileList, place: ILoadingPlaceForm) {
    return this.gqlMutation.addLoadingUnloadingPlace(img, place).pipe(map(data => data.addLoadingUnloadingPlace));
  }

  /**
   * Edit place type request
   * @param placeParam place type data
   * @returns Observable with status
   */
  public editLoadingUnloadingPlace(img: FileList, place: ILoadingPlaceForm) {
    return this.gqlMutation.editLoadingUnloadingPlace(img, place).pipe(map(data => data.editLoadingUnloadingPlace));
  }

  /**
   * archive place type request
   * @param placeParam place type data
   * @returns Observable with status
   */
  public sendLoadingUnloadingPlaceToArchive(placeId: number) {
    return this.gqlMutation.sendLoadingUnloadingPlaceToArchive(placeId);
  }
}
