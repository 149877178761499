<div>
  <div [ngClass]="labelClasses ? labelClasses : ['text-gray-700', 'text-'+size]">
    {{label}}
    <span *ngIf="required" class="text-red-500 ml-0.5">*</span>
    <span *ngIf="labelAddition" class="text-xs text-gray-400 font-medium">{{labelAddition}}</span>
  </div>

  <input type="file" [accept]="accept" #file class="file-input hidden" (change)="handleFileInput($event.target)" multiple />

  <div class="file-view-wrapper flex flex-col">
    <div [ngClass]="[ height ? height : '' , size ? 'text-'+size : '']">
      <div
        (click)="addFile()"
        [ngClass]="{ '!border-red-500': !control.valid && control.touched}"
        class="file-add-button h-full border border-gray-200 bg-gray-100 hover:bg-gray-200 rounded-l-md flex flex-col justify-center px-3 cursor-pointer float-left"
      >
        <span class="w-max">{{(reduceText ? 'shared.fileSelector.choose' : 'shared.fileSelector.chooseFile') | translate}}</span>
      </div>
      <div
        [ngClass]="{ '!border-red-500': !control.valid && control.touched}"
        class="file-add-text h-full border-y border-r border-gray-200 rounded-r-md flex flex-col justify-center px-3 bg-white"
      >
        <span class="text-blackAlpha-500" *ngIf="isLoading">{{'shared.fileSelector.loading' | translate}}</span>
        <span [ngClass]="{ 'leading-none' : reduceText}" class="text-blackAlpha-500" *ngIf="!fileName && !isLoading">
          {{'shared.fileSelector.fileNotSelected' | translate}}
        </span>
        <span
          class="relative text-blue-500 text-ellipsis whitespace-nowrap overflow-hidden flex justify-between items-center"
          *ngIf="fileName && !isLoading"
        >
          <span class="text-ellipsis whitespace-nowrap overflow-hidden pr-4">{{fileName}}</span>
          <mat-icon (click)="clear()" class="clear absolute right-0 text-gray-500">clear</mat-icon>
        </span>
        <a class="file-download-link text-gray-500" [href]="fileUploadService.fileUrl$ | async" download target="_blank">
          <mat-icon> insert_drive_file</mat-icon>
        </a>
      </div>
    </div>

    <div
      *ngIf="control?.errors?.invalidFormat && control.touched"
      [ngClass]="[errorSize ? 'text-'+errorSize : '']"
      class="mt-1 text-red-500"
    >
      {{'shared.fileSelector.errors.format' | translate}}
    </div>
    <div
      *ngIf="control?.errors?.invalidSize && control.touched"
      [ngClass]="[errorSize ? 'text-'+errorSize : '']"
      class="mt-1 text-red-500"
    >
      {{'shared.fileSelector.errors.maxSize' | translate}}
    </div>
    <div
      *ngIf="control && showErrors && control.invalid && control.touched"
      [ngClass]="[errorSize ? 'text-'+errorSize : '']"
      class="mt-1 text-red-500"
    >
      {{errorToMessage(control.errors)}}
    </div>
  </div>
</div>
