import { ComponentFactoryResolver, Directive, Type, ViewContainerRef } from '@angular/core';

@Directive({
  selector: '[transportDynamicComponentDirective]',
})
export class TnDynamicComponentDirective {
  constructor(private readonly viewContainerRef: ViewContainerRef, private readonly componentFactoryResolver: ComponentFactoryResolver) {}

  public clear(): void {
    this.viewContainerRef.clear();
  }

  public createComponent<TComponent>(componentClass: Type<TComponent>): TComponent {
    this.clear();
    return this.viewContainerRef?.createComponent<TComponent>(this.componentFactoryResolver.resolveComponentFactory(componentClass))
      .instance;
  }
}
