import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as actions from '../actions/carrier-filter-list.actions';
import { TnCarrierFilterListFacade } from '../carrier-filter-list.facade';

@Injectable({
  providedIn: 'root',
})
export class TnCarriersFilterListEffects {
  public showDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.loadCarrierFilterList),
      switchMap(() => {
        return this.facade.getCarrierOrganizations().pipe(
          map(result => actions.loadCarrierFilterListSuccess({ list: result })),
          catchError(error => of(actions.loadCarrierFilterListFailure({ error }))),
        );
      }),
    ),
  );

  constructor(private readonly facade: TnCarrierFilterListFacade, private readonly actions$: Actions) {}
}
