import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { VERIFICATION_STATUS } from '@transport/ui-interfaces';
import memo from 'memo-decorator';

@Pipe({
  name: 'verificationStatus',
})
export class TnVerificationStatusPipe implements PipeTransform {
  constructor(public translate: TranslateService) {}

  @memo({
    resolver: (...args: any[]): string => JSON.stringify(args),
  })
  public transform(value: VERIFICATION_STATUS): string {
    let key = '';
    switch (value) {
      case VERIFICATION_STATUS.APPROVED:
        key = 'approved';
        break;

      case VERIFICATION_STATUS.REJECTED:
        key = 'rejected';
        break;

      case VERIFICATION_STATUS.NOT_CHECKED:
        key = 'notChecked';
        break;

      case VERIFICATION_STATUS.ON_WORK:
        key = 'onWork';
        break;

      default:
        throw new Error('Неизвестный VERIFICATION_STATUS');
    }
    return Boolean(value) && this.translate.instant(`shared.verificationStatus.${key}`);
  }
}
