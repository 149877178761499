<input
  #timeInput
  matInput
  class="time-input-element"
  [ngClass]="{ 'placeholder-color': emptyValue }"
  type="text"
  [showMaskTyped]="true"
  [dropSpecialCharacters]="false"
  [leadZeroDateTime]="true"
  placeHolderCharacter="&ndash;"
  mask="Hh:m0"
  autocomplete="off"
  [disabled]="disabled"
  (ngModelChange)="onChange($event)"
  [(ngModel)]="inputValue"
  [attr.data-test]="dataTest"
/>
