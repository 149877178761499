import { transport } from '@transport/proto';

import { ICargoType } from '../cargo-type/cargo-type.interface';
import { ICargoPackagingType, TCargoPackagingTypeCode } from '../cargo-type/cargo-type-common.interface';
import { IPlace } from '../place/place.interface';

export interface IInsuranceDialogData {
  currentValue: {
    loadingPlace: IPlace;
    unloadingPlace: IPlace;
    loadingDate: string;
    cargoTypeId: string;
    weight: number;
    cargoPackagesCount: number;
    packagingType?: TCargoPackagingTypeCode | number;
    declaredPrice?: number;
    cargoKind?: string;
    cargoClass?: string;
  };
  selectValues: {
    cargoTypes: Partial<ICargoType>[];
    packagingTypes: ICargoPackagingType[];
  };
  isViewMode: boolean;
  contractId: string;
  ownerInsuranceContract?: transport.Order.IInsuranceContract | null;
  orderId?: string;
  type: string;
}

export interface IInsuranceData {
  cargoType?: ICargoType;
  userOrganization?: transport.IAdminOrganization;
  insurancePrice?: string | number;
  select: {
    cargoKinds: {
      id: string;
      name;
    }[];
    cargoClasses: {
      id: string;
      name;
    }[];
  };
}
