import { ChangeDetectionStrategy, Component, DoCheck, Input, OnInit, Optional, Self } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NgControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TnFormControlDirective } from '@transport/ui-directives';
import moment from 'moment';

@UntilDestroy()
@Component({
  selector: 'transport-date-range',
  templateUrl: './date-range-input.component.html',
  styleUrls: ['./date-range-input.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- TODO: tech debt
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TnDateRangeInputComponent extends TnFormControlDirective implements OnInit, DoCheck {
  @Input() public set toDateRequired(required: boolean) {
    if (required) {
      // this.form.controls.toDate.setValidators(Validators.required); // Validation of the "To" field is not required
    } else {
      this.form.controls.toDate.clearValidators();
    }
    this.toRequired = false; // Validation of the "To" field is not required (this.toRequired = required;)
  }

  public get toDateRequired(): boolean {
    return this.toRequired;
  }

  @Input() public set fromDateRequired(required: boolean) {
    if (required) {
      this.form.controls.fromDate.setValidators(Validators.required);
    } else {
      this.form.controls.fromDate.clearValidators();
    }
    this.fromRequired = required;
  }

  public get fromDateRequired(): boolean {
    return this.fromRequired;
  }

  @Input() public direction: 'row' | 'col' = 'col';

  @Input() public placeholder = 'shared.range';

  @Input() public dateFormat = '';

  private fromRequired = false;

  private toRequired = false;

  constructor(protected readonly fb: FormBuilder, @Optional() @Self() public controlDir: NgControl) {
    super(fb);
    if (Boolean(controlDir)) {
      controlDir.valueAccessor = this;
    }
  }

  public ngDoCheck(): void {
    if (Boolean(this.form.controls.fromDate.touched) && Boolean(this.form.controls.toDate.touched)) {
      return;
    }
    if (Boolean(this.controlDir.control?.touched)) {
      this.form.markAllAsTouched();
    }
  }

  public get addClassByDerection(): string {
    return this.direction === 'row' ? 'row-range-from' : '';
  }

  public get fromPlaceholder() {
    return `${this.placeholder}.from`;
  }

  public get toPlaceholder() {
    return `${this.placeholder}.to`;
  }

  public validate(control: AbstractControl): ValidationErrors | null {
    return this.form.invalid ? { invalidDateRange: true } : null;
  }

  public ngOnInit() {
    this.controlDir.control?.setValidators([this.validate.bind(this)]);
    this.controlDir.control?.updateValueAndValidity();
    void this.form.valueChanges.pipe(untilDestroyed(this)).subscribe((value: { fromDate: moment.Moment; toDate: moment.Moment }) => {
      const data = {
        fromDate: Boolean(value.fromDate) ? value.fromDate.format(this.dateFormat || 'YYYY-MM-DD') : '',
        toDate: Boolean(value.toDate) ? value.toDate.format(this.dateFormat || 'YYYY-MM-DD') : '',
      };
      this.onChange(data);
    });
  }

  //#region ControlValueAccessor implementing
  public writeValue(data: { fromDate?: string; toDate?: string }): void {
    if (Boolean(data)) {
      const datesData = {
        fromDate: Boolean(data.fromDate) ? moment(data.fromDate) : '',
        toDate: Boolean(data.toDate) ? moment(data.toDate) : '',
      };
      this.form.patchValue(datesData, { emitEvent: false });
    } else {
      this.form.patchValue({ fromDate: '', toDate: '' });
    }
  }
  //#endregion  End ControlValueAccessor implementing

  protected createFormGroup() {
    // eslint-disable-next-line complexity -- TODO: tech debt
    const rangeValidator: ValidatorFn = (control: AbstractControl) => {
      const fg = control as unknown as FormGroup;
      const fromDateControl = fg.controls.fromDate;
      const toDateControl = fg.controls.toDate;
      const start = fromDateControl.value;
      const end = toDateControl.value;
      const isInvalid = Boolean(Boolean(start) && end && start > end);
      if (isInvalid) {
        fromDateControl.setErrors({ outOfBounds: true });
        toDateControl.setErrors({ outOfBounds: true });
      } else {
        const fromDateErrors = fromDateControl.errors;
        const toDateErrors = toDateControl.errors;
        if (Boolean(fromDateErrors) && Boolean(fromDateErrors?.outOfBounds)) {
          delete fromDateErrors?.outOfBounds;
          if (fromDateErrors !== null && Object.keys(fromDateErrors).length === 0) {
            fromDateControl.setErrors(null);
          }
        }
        if (Boolean(toDateErrors) && Boolean(toDateErrors?.outOfBounds)) {
          delete toDateErrors?.outOfBounds;
          if (toDateErrors !== null && Object.keys(toDateErrors).length === 0) {
            toDateControl.setErrors(null);
          }
        }
      }
      return (isInvalid ? { outOfBounds: true } : null) as ValidationErrors;
    };

    return this.fb.group(
      {
        fromDate: [''],

        toDate: [''],
      },
      { validator: rangeValidator },
    );
  }
}
