import { TnFileToUpload, TnUploadedFile } from '@transport/ui-interfaces';
import { Observable, of } from 'rxjs';

import { TnFileUploadService } from '../file-upload/file-upload.service';

export const fileUploadProvidersMock = [
  {
    provide: TnFileUploadService,
    useValue: {
      upload: (file: TnFileToUpload): Observable<TnUploadedFile> => {
        const res = new TnUploadedFile(file.fullName, 'url', 'id');
        return of(res);
      },
    },
  },
];
