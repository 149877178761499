export const menuLocalization = {
  label: {
    orders: 'Заявки',
    auction: 'Аукцион',
    trucks: 'Транспортные средства',
    serviceRegistry: 'Реестр транспортных услуг',
    profile: 'Профиль',
    help: 'Помощь',
    reports: 'Отчеты',
    reconciliationActs: 'Акты сверки',
    tenders: 'Тендеры',
    carriers: 'Перевозчики',
    drivers: 'Водители',
    organizationProfile: 'Настройки',
    usersManagement: 'Сотрудники',
    cargoTypes: 'Типы грузов',
    loadingUnloadingPlaces: 'Адреса',
    trustedCarriers: 'Перевозчики',
    sla: 'Уровни обслуживания',
    integration: 'Интеграция',
    routing: 'Маршрутизация',
    tsm: 'Грузовые дворы',
  },
  adaptiveLabel: {
    trucks: 'ТC',
    serviceRegistry: 'Реестр ТУ',
    trustedCarriers: 'Перевозчики',
    loadingUnloadingPlaces: 'Места ПРР',
    organizationProfile: 'Организация',
    usersManagement: 'Пользователи',
  },
  exchange: {
    title: 'Переключиться на',
    profile: 'Профиль компании',
  },
};
