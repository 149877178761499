import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { ApolloLink, RequestHandler, split } from '@apollo/client/core';
import { onError } from '@apollo/client/link/error';
import { getMainDefinition } from '@apollo/client/utilities';
import { Store } from '@ngrx/store';
import { APP_ENV, HTTP_ERROR_CODE, IHttpRestErrorResponse, TnAppEnvironment, USER_ROLE } from '@transport/ui-interfaces';
import { TnClSsoFrameService, TnUserService } from '@transport/ui-store';
import { getAuthorizationHeader } from '@transport/ui-utils';
import { HttpLink } from 'apollo-angular/http';
import { createUploadLink } from 'apollo-upload-client';
import { Observable, throwError } from 'rxjs';
import { catchError, finalize, map, take, tap, timeout } from 'rxjs/operators';

import { TnCurrentUserFacade } from '../current-user/current-user.facade';
import { hideProgress, showProgress } from '../progress/progress.actions';
import { TnToasterFacade } from '../toaster/toaster.facade';
import { setApiBaseUrl } from './feature-access.actions';
/**
 * Http handers service.
 */
@Injectable({
  providedIn: 'root',
})
export class TnHttpHandlersService {
  /**
   * Default timeout interval for http-requests.
   */
  public readonly defaultHttpTimeout = 30000;

  constructor(
    private readonly userFacade: TnCurrentUserFacade,
    private readonly httpLink: HttpLink,
    private readonly store: Store,
    @Inject(APP_ENV) private readonly appEnv: TnAppEnvironment,
    private readonly toastFacade: TnToasterFacade,
    private readonly ssoService: TnClSsoFrameService,
  ) {
    const apiBaseUrl = this.apiBaseUrl();
    this.store.dispatch(setApiBaseUrl({ payload: { apiBaseUrl } }));
  }

  /**
   * Resolves server API base url, adds correct protocol.
   */
  private apiBaseUrl(): string {
    const apiBaseUrl = `${window.location.protocol}//${this.appEnv.apiDomain}`;
    return apiBaseUrl;
  }

  /**
   * Resolver graphQL base url, adds correct protocol.
   * @param role user role for which graphQL endpoint is intended, null stands for 'force mock mode'
   */
  public getGraphQlEndpoint(role: USER_ROLE): string {
    const protocol: string = window.location.protocol;
    let realServerPath = '';
    switch (role) {
      case USER_ROLE.CARRIER:
        realServerPath = '/carrier/graphql';
        break;
      case USER_ROLE.CARGO_OWNER:
      case USER_ROLE.NA:
      case USER_ROLE.OWNER:
      case USER_ROLE.TNDL_SECURITY_STAFF:
        realServerPath = '/cargo-owner/graphql';
        break;
      case USER_ROLE.TASKS:
        realServerPath = '/p/tasks/g';
        break;
      case USER_ROLE.RISKS:
        realServerPath = '/p/risks/g';
        break;
    }
    const url = `${protocol}//${this.appEnv.apiDomain}${realServerPath}`;
    return url;
  }

  /**
   * Returns new http headers for GraphQL.
   */
  public getAuthHeaders(): HttpHeaders {
    const authHeader = getAuthorizationHeader(this.userFacade.currentUser?.token ?? '');
    return new HttpHeaders(authHeader);
  }

  /**
   * Returns API base url concatenated with provided endpoint path.
   * Adds preceding slash before endpoint path if it is missing.
   * @param path endpoint path
   */
  public getEndpointUrl(path: string): string {
    const result = /^\/.*$/.test(path) ? path : `/${path}`;
    return this.apiBaseUrl() + result;
  }

  /**
   * Extracts GraphQL data.
   * Returns data only, excluding meta information located in response object root.
   * @param res Execution result
   */
  private extractGraphQLData(res: { errors?: unknown[]; data }) {
    // TODO: poor gql typing
    if (typeof res.errors?.length !== 'undefined') {
      throw res.errors[0];
    }
    return Boolean(res.data) ? res.data : res; // AttachContract response does not have 'data' object
  }

  /**
   * Check error status, and redirects to /login if status is 403.
   * Note on errors:
   * 401 - unauthorized token expired
   * 403 - forbidden, no access rights
   * @param status error status
   */
  public checkErrorStatusAndRedirect(status: HTTP_ERROR_CODE): void {
    if (status === HTTP_ERROR_CODE.UNAUTHORIZED) {
      // Reset token.
      if (!window.location.href.includes('/passport/login')) {
        this.userFacade.resetUser();
        this.ssoService.logOut();
        window.location.href = `${window.location.origin}/passport/login`;
      }
    }

    if (status === HTTP_ERROR_CODE.FORBIDDEN) {
      this.toastFacade.showMessage('shared.errors.permissionOperation');
    }
  }

  /**
   * Handles error.
   */
  public handleError(error: IHttpRestErrorResponse): Observable<never> {
    const errMsg: string = !Boolean(error)
      ? 'Server error'
      : Boolean(error.error) && Boolean(error.error.message)
      ? error.error.message
      : Boolean(error.message)
      ? error.message
      : Boolean(error.status)
      ? `${error.status}: ${error.statusText}`
      : 'Server error';
    return throwError(errMsg);
  }

  /**
   * Pipes request with object response.
   * @param observable request observable
   * @param listenX number of responses to catch
   */
  public pipeHttpRequest<T = unknown>(observable: Observable<T>, listenX = 1, withSpinner = false, shouldHandleErrors = true) {
    if (withSpinner) {
      this.store.dispatch(showProgress({ payload: {} }));
    }
    return observable.pipe(
      timeout(this.defaultHttpTimeout),
      take(listenX),
      finalize(() => {
        if (withSpinner) {
          this.store.dispatch(hideProgress({ payload: {} }));
        }
      }),
      catchError(error => (shouldHandleErrors ? this.handleError(error) : throwError(error))),
    );
  }

  /**
   * Pipes graphQL with object response.
   * @param observable request observable
   * @param listenX number of responses to catch
   * @param withSpinner should request start spinner
   * @param httpTimeout
   */
  public pipeGraphQLRequest<T, T1>(observable: Observable<T1>, listenX = 1, withSpinner = true, httpTimeout?: number) {
    let defhttpTimeout: number = this.defaultHttpTimeout;
    if (httpTimeout && Boolean(httpTimeout)) {
      defhttpTimeout = httpTimeout;
    }
    return observable.pipe<T1, T1, unknown, unknown, unknown, T, T>(
      tap(() => {
        if (withSpinner) {
          this.store.dispatch(showProgress({ payload: {} }));
        }
      }),
      timeout(defhttpTimeout),
      this.spinnerTap(withSpinner),
      take(listenX),
      tap(
        () => null,
        ({ networkError }) => {
          this.checkErrorStatusAndRedirect((networkError as HttpErrorResponse)?.status);
        },
      ),
      map(data => {
        const typedData = data as { errors?: unknown[]; data: T };
        return this.extractGraphQLData(typedData);
      }),
      finalize(() => {
        if (withSpinner) {
          this.store.dispatch(hideProgress({ payload: {} }));
        }
      }),
    );
  }

  /**
   * Creates apollo link with error handler.
   * @param userRole define server endpoint
   * @param errorLinkHandler custom error handler
   */
  public createApolloLinkFor(userRole: USER_ROLE, errorLinkHandler?: ApolloLink): ApolloLink {
    const uri = this.getGraphQlEndpoint(userRole);

    const prepareUri = ({ operationName }) => {
      // operation name need for E2E tests
      // return !this.appEnv.production ? `${uri}?operation=${operationName}` : uri;
      return `${uri}?operation=${operationName}`;
    };

    const httpLinkHandler = this.httpLink.create({
      uri: prepareUri,
    });

    const errorHandler =
      errorLinkHandler ??
      onError(error => {
        // eslint-disable-next-line no-console -- needed here
        console.error(error);
      });

    const uploadMutations = [
      'AttachContract',
      'editLoadingUnloadingPlace',
      'addLoadingUnloadingPlace',
      'uploadFile',
      'editOrganization',
      'addCargoContract',
      'EditCarrierContract',
      'AddCarrierContract',
      'EditBusinessRelationship',
    ];
    const uploadLink = createUploadLink({
      uri: prepareUri,
      headers: getAuthorizationHeader(this.userFacade.currentUser?.token ?? ''),
    }) as unknown as ApolloLink | RequestHandler | undefined;
    const networkLink = split(
      ({ query }) => {
        const { name } = getMainDefinition(query);
        return !Boolean(name) || !uploadMutations.includes(name?.value ?? '');
      },
      httpLinkHandler,
      uploadLink,
    );

    return errorHandler.concat(networkLink);
  }

  /**
   * Taps spinner.
   * @param applied indicates if spinner should be applied
   */
  private spinnerTap(applied: boolean) {
    const handler = () => {
      if (applied) {
        this.store.dispatch(hideProgress({ payload: {} }));
      }
    };
    return tap(handler, handler, handler);
  }
}
