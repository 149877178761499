import { Injectable } from '@angular/core';
import { USER_ROLE } from '@transport/ui-interfaces';
import { TnGqlClientSharedService } from '@transport/ui-store';
import { BehaviorSubject, take } from 'rxjs';
import { FILTERS_TOOLBAR_QUERIES } from './filters-toolbar-queries';
import { FILTERS_TABS, IGqlDriversStatusCounters } from '../filters.interface';
import { DriversService } from '../../drivers.service';

@Injectable()
export class DriversAmountService {

  public amount$ = new BehaviorSubject<IGqlDriversStatusCounters | null>(null);

  constructor(private sharedGraphQlClient: TnGqlClientSharedService, private driversPageService: DriversService) {}

  public updateDriversStatusCounters() {
    this.sharedGraphQlClient
    .query<{driverStatusCounters: { all: number; notArchived: number; archived: number; }}>(this.driversPageService.role$.value as USER_ROLE, FILTERS_TOOLBAR_QUERIES.counter, 1)
    .pipe(take(1))
    .subscribe(res => {
        this.amount$.next({
          [FILTERS_TABS.ALL]: res?.driverStatusCounters?.all,
          [FILTERS_TABS.ACTIVE]: res?.driverStatusCounters?.notArchived,
          [FILTERS_TABS.ARCHIVED]: res?.driverStatusCounters?.archived,
        })
      }
    );
  }
}
