import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { transport } from '@transport/proto';
import {
  DOCUMENT_TYPE,
  FILE_UPLOAD_STATUS,
  IFile,
  IFileType,
  IFileUploadDialogFormValue,
  IFileUploadDialogLabels,
  TFileAdditionalAttributes,
  TnFileToUpload,
  TnUploadedFile,
} from '@transport/ui-interfaces';
import { TnFileUploadService } from '@transport/ui-store';
import { DOC_FILE_ACCEPT_EXTENSIONS_SHORT, kbToMb } from '@transport/ui-utils';
import { BehaviorSubject, combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnFileAdditionalAttributesContainerComponent } from '../additional-attributes/file-additional-attributes-container/file-additional-attributes-container.component';
import { IWaybillForm } from '../additional-attributes/waybill-form/waybill-form.component';
import { TnAlertService } from '@transport/ui-kit';
import { ICommonSelectData } from 'libs/custom-controls/src/lib/common.interface';
import { ValidationErrors, Validators } from '@angular/forms';


const defaultLabels: IFileUploadDialogLabels = {
  title: 'shared.fileUploadDialog.title',
  cancelBtn: 'shared.actions.cancel',
  saveBtn: 'shared.actions.save',
  fileTypeSelect: 'shared.fileUploadDialog.fileTypeSelectDefault',
};

@UntilDestroy()
@Component({
  selector: 'transport-upload-dialog',
  templateUrl: './upload-dialog.component.html',
  styleUrls: ['./upload-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnUploadModal implements AfterViewInit, OnInit {
  constructor(
    private readonly dialogRef: MatDialogRef<TnUploadModal, IFileUploadDialogFormValue>,
    private readonly fileUploadService: TnFileUploadService,
    private readonly translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private alertService: TnAlertService,
  ) {}

  @ViewChild(TnFileAdditionalAttributesContainerComponent)
  private readonly additionalAttributesComponent?: TnFileAdditionalAttributesContainerComponent;

  @Input() public titleText = defaultLabels.title;

  @Input() public multipleFiles = true;

  @Input() public saveButtonText = defaultLabels.saveBtn;

  @Input() public cancelButtonText = defaultLabels.cancelBtn;

  @Input() public fileTypePlaceholderText = defaultLabels.fileTypeSelect;

  @Input() public isDocTypeSelectorDisabled = true;

  @Input() public fileTypes$: Observable<transport.IOrderDocumentType[]> = of([] as transport.IOrderDocumentType[]);

  @Input() public waybillForm?: IWaybillForm;

  @Input() public withAdditionalAttributes?: boolean = true;

  public inputFiles = [];
  public fileTypesOptions = [] as ICommonSelectData[];
  private fileTypes = [] as transport.IOrderDocumentType[];

  private readonly selectedFilesSubject = new BehaviorSubject<IFile[]>([]);

  public selectedFiles$ = this.selectedFilesSubject.asObservable();

  public get isUploadAreaVisible(): boolean {
    return this.multipleFiles || this.selectedFilesSubject.value.length === 0;
  }

  public fileTypeFormControl = new FormControl({id: ''}, Validators.required);

  private additionalAttributes?: TFileAdditionalAttributes | null = null;

  private readonly filesValid$ = this.selectedFiles$.pipe(
    map(files => {
      const res = files.length > 0 && !files.some(file => file.status !== FILE_UPLOAD_STATUS.UPLOADED);
      return res;
    }),
  );

  public isSaveDisabled$ = combineLatest([this.filesValid$, this.fileTypeFormControl.value$.pipe(map(value => Boolean(value)))]).pipe(
    map(([filesValid, formValid]) => {
      return !filesValid || !formValid;
    }),
  );

  public accept = `${DOC_FILE_ACCEPT_EXTENSIONS_SHORT}, .xlsx`;

  public get acceptFiles() {
    return this.accept.replace(/\./g, '').toUpperCase();
  }

  ngOnInit() {
    this.fileTypes$.pipe(untilDestroyed(this)).subscribe(res => {
      this.fileTypesOptions = res.map(item => ({id: item.innerName ?? '', label: item.name ?? ''}));
      this.fileTypes = res;
      this.cdr.markForCheck();
    });

  }

  public ngAfterViewInit(): void {
    if (Boolean(this.additionalAttributes)) {
      this.additionalAttributesComponent?.writeValue(this.additionalAttributes as TFileAdditionalAttributes);
    }
  }

  public fillForm(formData: IFileUploadDialogFormValue, withAttrs = true) {
    this.fileTypeFormControl.setValue({id: formData.fileType?.innerName ?? ''});
    if (withAttrs) {
      this.selectedFilesSubject.next(formData.files);
      this.additionalAttributes = formData.additionalAttributes;
    }
  }

  public applyEditFormMode(): void {
    this.fileTypeFormControl.disable();
  }

  public cancel(): void {
    this.dialogRef.close();
  }

  get getDocType(): transport.IOrderDocumentType {
    return this.fileTypes.find(item => item.innerName === this.fileTypeFormControl.value?.id) as transport.IOrderDocumentType;
  }
  get getDocTypeInnerName(): DOCUMENT_TYPE {
    return this.fileTypeFormControl.value?.id as DOCUMENT_TYPE;
  }

  public confirm(): void {
    const fileType: IFileType = this.getDocType;
    const res: IFileUploadDialogFormValue = {
      files: this.selectedFilesSubject.value as TnUploadedFile[],
      fileType,
    };

    if (Boolean(this.additionalAttributesComponent) && Boolean(this.additionalAttributesComponent?.invalid)) {
      this.additionalAttributesComponent?.markAllAsTouched();
      this.alertService.shortError(this.translate.instant('shared.errors.formInvalid'), '', 'center');
      return;
    }
    res.additionalAttributes = this.additionalAttributesComponent?.value;
    this.alertService.shortSuccess(this.translate.instant('shared.orderPage.documentsCard.notification.addSuccessfully'));

    this.dialogRef.close(res);
  }

  public onFileChange(files: TnFileToUpload[]): void {
    this.inputFiles = [];
    const newFiles = files.filter(this.isNewFile.bind(this));
    this.addFilesToList(newFiles);
  }

  public onInvalidFileSizeSelected(err: { maxSize: number; files: TnFileToUpload[] }): void {
    const errorText = this.translate.instant('shared.errors.maxFileSize', {
      value: kbToMb(err.maxSize),
    });
    this.inputFiles = [];
    const newFiles = err.files.filter(this.isNewFile.bind(this));
    for (const file of newFiles) {
      file.status = FILE_UPLOAD_STATUS.FILE_SIZE_ERROR;
      file.errorText = errorText;
    }
    this.addFilesToList(newFiles, false);
  }

  public onRemoveButtonClick(indx: number): void {
    this.selectedFilesSubject.value.splice(indx, 1);
    this.selectedFilesSubject.next(this.selectedFilesSubject.value);
  }

  public onRefreshButtonClick(indx: number): void {
    const file = this.selectedFilesSubject.value[indx] as TnFileToUpload;
    this.upload([file]);
  }

  private addFilesToList(files: TnFileToUpload[], startUpload = true): void {
    const oldList = this.selectedFilesSubject.value;
    this.selectedFilesSubject.next([...oldList, ...files]);
    if (startUpload) {
      this.upload(files);
    }
  }

  private isNewFile(file: TnFileToUpload): boolean {
    return !this.selectedFilesSubject.value.some(this.filesSearchComparator(file));
  }

  private readonly filesSearchComparator =
    (fileForSearch: IFile) =>
    (iterableFile: IFile): boolean =>
      fileForSearch.fullName === iterableFile.fullName;

  private upload(files: TnFileToUpload[]): void {
    for (const file of files) {
      file.status = FILE_UPLOAD_STATUS.UPLOADING;
      this.selectedFilesSubject.next([...this.selectedFilesSubject.value]);
      void this.fileUploadService
        .upload(file, false)
        .pipe(untilDestroyed(this))
        .subscribe(
          uploadedFile => {
            const oldList = this.selectedFilesSubject.value;
            const fileIndex = oldList.findIndex(this.filesSearchComparator(uploadedFile));
            oldList.splice(fileIndex, 1, uploadedFile);
            this.selectedFilesSubject.next([...oldList]);
          },
          err => {
            file.status = FILE_UPLOAD_STATUS.UPLOAD_ERROR;
            this.selectedFilesSubject.next([...this.selectedFilesSubject.value]);
          },
        );
    }
  }

  public validationErrors(e: ValidationErrors | null) {
    if (Boolean(e?.required)) return this.translate.instant('shared.errors.required');
    return '';
  }
}
