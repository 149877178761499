import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { transport } from '@transport/proto';
import { createNewGroup } from '@transport/ui-interfaces';
import { combineLatest, of } from 'rxjs';
import { catchError, filter, map, switchMap, withLatestFrom } from 'rxjs/operators';

import { TnConfirmationService } from '../../../../confirmation/confirmation.service';
import { TnToasterFacade, TOAST_TYPE } from '../../../../toaster/toaster.facade';
import { TnOwnerDirectoryCarrierGroupsApiService } from '../../carriers-groups-table/owner-directory-carrier-groups-api.service';
import { carrierGroupActions } from '../actions/carriers-group.actions';
import { TnCarrierGroupsFacade } from '../carrier-groups.facade';

@Injectable({
  providedIn: 'root',
})
export class TnCarriersGroupEffects {
  public showDetails = createEffect(() =>
    this.actions$.pipe(
      ofType(carrierGroupActions.showGroupDetails),
      switchMap(action => of(action).pipe(withLatestFrom(this.facade.groupId$))),
      map(([action, groupId]) => carrierGroupActions.navigateToGroups([action.mode, groupId])),
    ),
  );

  public setFormMode = createEffect(() => {
    return this.actions$.pipe(
      ofType(carrierGroupActions.loadGroupSuccess),
      switchMap(() => this.facade.mode$),
      map(mode => carrierGroupActions.setFormMode({ mode })),
    );
  });

  /**
   * @note TODO
   * - this seems not workign currently;
   * - do: find libs/ -type f -name "*.ts" | xargs grep "watchRouteParams" --color=always
   */
  public watchRouteParams = createEffect(() => {
    return combineLatest([this.facade.groupId$, this.facade.mode$]).pipe(
      filter(([id, mode]) => Boolean(mode)),
      map(([groupId]) => {
        return carrierGroupActions.loadGroup({ groupId });
      }),
    );
  });

  public loadGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(carrierGroupActions.loadGroup),
      switchMap(({ groupId }) => {
        if (!Boolean(groupId)) {
          return of(carrierGroupActions.loadGroupSuccess({ group: createNewGroup() }));
        }
        return this.groupService.getGroup(groupId).pipe(map(res => carrierGroupActions.loadGroupSuccess(res)));
      }),
      catchError((error: Error & { extensions: { code } }) => {
        this.toastFacade.showMessage(`shared.request.error.${error.extensions.code}`);
        return of(carrierGroupActions.loadGroupFailure({ error }));
      }),
    ),
  );

  public removeGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(carrierGroupActions.removeGroup),
      switchMap(({ id }) => of(id).pipe(withLatestFrom(this.facade.groupId$))),
      switchMap(([id, groupId]) =>
        this.confirmation.openByPrefix('shared.directory.carrierGroups.confirm.removeGroup').pipe(
          filter(result => Boolean(result)),
          switchMap(() =>
            this.groupService.removeGroup(id ?? groupId).pipe(
              map((group: { group: Partial<transport.Carrier.ICarrierGroup> }) => {
                this.toastFacade.showMessage('shared.directory.carrierGroups.remove.success', TOAST_TYPE.SUCCESS);
                return carrierGroupActions.removeGroupSuccess(group);
              }),
              catchError(error => of(carrierGroupActions.removeGroupFailure({ error }))),
            ),
          ),
        ),
      ),
    ),
  );

  public saveGroup = createEffect(() =>
    this.actions$.pipe(
      ofType(carrierGroupActions.saveGroup),
      switchMap(action => of(action).pipe(withLatestFrom(this.facade.groupId$, this.facade.mode$, this.facade.isFormValid$))),
      switchMap(([formValue, id, mode, isFormValid]) => {
        if (!isFormValid) {
          this.toastFacade.showMessage('shared.errors.formInvalid');
          return of(carrierGroupActions.validateGroupForm());
        }

        const result =
          mode === 'create' || mode === 'copy'
            ? this.groupService.createGroup({ id, ...formValue.group })
            : this.groupService.saveGroup({ id, ...formValue.group });
        return result.pipe(
          map(newGroup => {
            this.toastFacade.showMessage('shared.directory.carrierGroups.success', TOAST_TYPE.SUCCESS);
            return carrierGroupActions.saveGroupSuccess(newGroup);
          }),
          catchError((error: Error) => {
            this.toastFacade.showMessage({
              code: 'shared.directory.carrierGroups.errors.save',
              additionalText: `: ${error.message} `,
            });
            return of(carrierGroupActions.saveGroupFailure({ error }));
          }),
        );
      }),
    ),
  );

  public backToListOnSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(carrierGroupActions.saveGroupSuccess, carrierGroupActions.removeGroupSuccess),
      map(() => {
        return carrierGroupActions.navigateToGroups();
      }),
    ),
  );

  constructor(
    private readonly facade: TnCarrierGroupsFacade,
    private readonly groupService: TnOwnerDirectoryCarrierGroupsApiService,
    private readonly actions$: Actions,
    private readonly confirmation: TnConfirmationService,
    private readonly toastFacade: TnToasterFacade,
  ) {}
}
