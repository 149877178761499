import { createAction, props } from '@ngrx/store';

import { IConfirmationState } from './confirmation.state';

export const confirmationNamespaceKey = 'Confirmation';

export const OPEN_CONFIRMATION = `[${confirmationNamespaceKey}] open confirmation by prefix`;

export const openConfirmation = createAction(OPEN_CONFIRMATION, props<{ payload: Pick<IConfirmationState, 'openByPrefix'> }>());

export const SET_CONFIRMATION_CHOICE = `[${confirmationNamespaceKey}] set user choice`;

export const setConfirmationChoice = createAction(
  SET_CONFIRMATION_CHOICE,
  props<{ payload: Pick<IConfirmationState, 'confirmClosure'> }>(),
);
