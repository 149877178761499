import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { transport } from '@transport/proto';
import {
  DOCUMENT_TYPE,
  FREIGHT_ERROR,
  IOrder,
  ORDER_DETAILS_PAGE_KIND,
  TnFileToUpload,
  TOrderAttachmentForSave,
  VEHICLE_TYPE_PROPERTY,
} from '@transport/ui-interfaces';
import { map, Observable } from 'rxjs';

import { ITnState } from '../../state/index';
import * as orderDetailsActions from '../actions/order-details-carrier.actions';
import * as orderDocumentsactions from '../actions/order-documents-carrier.actions';
import * as selectors from '../selectors/order-details-carrier.selectors';
import * as documentsSelectors from '../selectors/oreder-documents-carrier.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnOrderDetailsCarrierFacade {
  constructor(private readonly store: Store<ITnState>, private readonly action$: Actions) {}

  public readonly allDocumentTypes$ = this.store.pipe(select(selectors.allDocumentTypesSelector));

  public readonly isReadonlyOrder$ = this.store.pipe(select(selectors.isReadonlyOrderSelector));

  public readonly validateContractForm$ = this.action$.pipe(ofType(orderDetailsActions.validateContractForm));

  public readonly unreserveTransportSuccess$ = this.action$.pipe(ofType(orderDetailsActions.unreserveTransportSuccess));

  public readonly isFormDirty$ = this.store.pipe(select(selectors.isFormDirtySelector));

  public readonly orderUnloadingPlaces$ = this.store.pipe(select(selectors.orderUnloadingPlacesSelector));

  public readonly orderLoadingPlaces$ = this.store.pipe(select(selectors.orderLoadingPlacesSelector));

  public readonly canEditDocuments$ = this.store.pipe(select(documentsSelectors.canEditDocumentSelector));

  public readonly selectedDriver$ = this.store.pipe(select(selectors.selectedDriverSelector));

  public readonly selectedSecondDriver$ = this.store.pipe(select(selectors.selectedSecondDriverSelector));

  public readonly isSameDrivers$ = this.action$.pipe(ofType(orderDetailsActions.sameDrivers));

  public readonly selectedVehicle$ = this.store.pipe(select(selectors.selectedVehicleSelector));

  public readonly selectedAgentCarrier$ = this.store.pipe(select(selectors.selectedAgentCarrierSelector));

  public readonly attractedTruck$ = this.store.pipe(select(selectors.attractedSelector));

  public readonly freightError$ = this.store.pipe(select(selectors.freightErrorSelector));

  public readonly order$ = this.store.pipe(select(selectors.orderSelector));

  public readonly orderStatus$ = this.store.pipe(select(selectors.orderStatus));

  public readonly orderNomenclature$ = this.store.pipe(select(selectors.orderNomenclature));

  public readonly orderContract$ = this.store.pipe(select(selectors.orderContractSelector));

  public readonly orderOwner$ = this.store.pipe(select(selectors.orderOwner));

  public readonly cargoBodyTypes$ = this.store.pipe(select(selectors.cargoBodyTypesSelector));

  public readonly carrierContracts$ = this.store.pipe(select(selectors.carrierContractsSelector));

  public readonly packagingTypes$ = this.store.pipe(select(selectors.packagingTypesSelector));

  public readonly isLoading$ = this.store.pipe(select(selectors.isLoadingSelector));

  public readonly isFirstLoading$ = this.store.pipe(select(selectors.isFirstLoadingSelector));

  public readonly isWaybillRequired$ = this.store.pipe(select(selectors.isWaybillRequired));

  public readonly documentsGroups$ = this.store.pipe(select(documentsSelectors.documentsGroupsSelector));

  public readonly hasDocumentsSelector$ = this.store.pipe(select(documentsSelectors.hasDocumentsSelector));

  public readonly contractBlank$ = this.store.pipe(select(selectors.contractBlankSelector));

  public readonly driverAndVehicleChangeDateTime$ = this.store.pipe(select(selectors.driverAndVehicleChangeDateTime));

  public readonly contractDocument$ = this.store.pipe(select(selectors.contractDocumentSelector));

  public readonly terminationAgreementDocument$ = this.store.pipe(select(selectors.terminationAgreementDocumentSelector));

  public readonly isDriverAndVehicleChangeTimeUp$ = this.store.pipe(select(selectors.isDriverAndVehicleChangeTimeUp));

  public readonly blackListDriver$ = this.store.pipe(select(selectors.blackListDriver));

  public readonly blackListVehicle$ = this.store.pipe(select(selectors.blackListVehicle));

  public readonly isDriverAndVehicleChanging$ = this.store.pipe(select(selectors.isDriverAndVehicleChanging));

  public readonly loadingPlaceTime$: Observable<string> = this.orderLoadingPlaces$.pipe(
    map(loadingPlaces => {
      const lastLoadingPlace = (loadingPlaces ?? [])[(loadingPlaces ?? []).length - 1];
      return lastLoadingPlace?.arriveDatetimeTo ?? '';
    }),
  );

  public loadAllDocumentTypes(): void {
    this.store.dispatch(orderDocumentsactions.loadAllDocumentTypesStart());
  }

  public loadOrderDetailsStart(orderId: string, orderKind: ORDER_DETAILS_PAGE_KIND): void {
    this.store.dispatch(orderDetailsActions.loadOrderDetailsStart({ orderId, orderKind }));
  }

  public getCarrierContracts(id: string) {
    this.store.dispatch(orderDetailsActions.getCarrierContracts({ id }));
  }

  public setIsDirtyState(isDirty: boolean): void {
    this.store.dispatch(orderDetailsActions.setFormDirty({ isDirty }));
  }

  public refreshOrderStart(orderId: string, orderKind: ORDER_DETAILS_PAGE_KIND): void {
    this.store.dispatch(orderDetailsActions.refreshOrderStart({ orderId, orderKind }));
  }

  public destroyPage(): void {
    this.store.dispatch(orderDetailsActions.pageDestroy());
  }

  public expiredOrder(): void {
    this.store.dispatch(orderDetailsActions.expiredOrder());
  }

  public loadOrderSuccess(order: IOrder): void {
    this.store.dispatch(orderDetailsActions.loadOrderSuccess({ order }));
  }

  public updateOrderSuccess(order: IOrder): void {
    this.store.dispatch(orderDetailsActions.updateOrderSuccess({ order }));
  }

  public addDocuments(documents: TOrderAttachmentForSave[], orderId: string): void {
    this.store.dispatch(orderDocumentsactions.addDocumentsStart({ documents, orderId }));
  }

  public editWaybill(wayBill: TOrderAttachmentForSave[], orderId: string): void {
    this.store.dispatch(orderDocumentsactions.editWaybillStart({ wayBill, orderId }));
  }

  public removeDocument(documentId: string, orderId: string): void {
    this.store.dispatch(orderDocumentsactions.removeDocumentStart({ documentId, orderId }));
  }

  public addSignToDocument(sign: TnFileToUpload, signedFileId: string): void {
    this.store.dispatch(orderDocumentsactions.uploadSignStart({ sign, signedFileId }));
  }

  public uploadContract(contract: TnFileToUpload): void {
    this.store.dispatch(orderDocumentsactions.uploadContractStart({ contract }));
  }

  public selectDriver(driver: transport.IDriver): void {
    this.store.dispatch(orderDetailsActions.selectDriverStart({ driver }));
  }

  public selectSecondDriver(driver: transport.IDriver): void {
    this.store.dispatch(orderDetailsActions.selectSecondDriverStart({ driver }));
  }

  public clearDriver(): void {
    this.store.dispatch(orderDetailsActions.clearDriver());
  }

  public selectVehicle(vehicle: transport.IVehicle): void {
    this.store.dispatch(orderDetailsActions.selectVehicle({ vehicle }));
  }

  public clearVehicle(): void {
    this.store.dispatch(orderDetailsActions.clearVehicle());
  }

  public selectAgentCarrier(agentCarrier?: transport.IAgentCarrier | null): void {
    this.store.dispatch(orderDetailsActions.selectAgentCarrier({ agentCarrier }));
  }

  public selectAttractedTruck(attracted: VEHICLE_TYPE_PROPERTY | null): void {
    this.store.dispatch(orderDetailsActions.selectAttractedTruck({ attracted }));
  }

  public setFreight(freight: string | null): void {
    this.store.dispatch(orderDetailsActions.setFreight({ freight }));
  }

  public setFreightError(error: string | FREIGHT_ERROR): void {
    this.store.dispatch(orderDetailsActions.setFreightError({ error }));
  }

  public setOrder(order: IOrder): void {
    this.store.dispatch(orderDetailsActions.setOrder({ order }));
  }

  public unreserveTransport(orderId: string): void {
    this.store.dispatch(orderDetailsActions.unreserveTransportStart({ orderId }));
  }

  public acceptOrder(orderId: string) {
    this.store.dispatch(orderDetailsActions.acceptOrder({ orderId }));
  }

  public cancelOrder(orderId: string, isTerminationAgreement: boolean) {
    this.store.dispatch(orderDetailsActions.cancelOrder({ orderId, isTerminationAgreement }));
  }

  public completeOrder(orderId: string) {
    this.store.dispatch(orderDetailsActions.completeOrder({ orderId }));
  }

  public letsGo(orderId: string) {
    this.store.dispatch(orderDetailsActions.letsGo({ orderId }));
  }

  public saveInfoForContract() {
    this.store.dispatch(orderDetailsActions.saveInfoForContractStart());
  }

  public addInsuranceForOrder(orderId: string, insurance: transport.IInsurance): void {
    this.store.dispatch(orderDetailsActions.addInsuranceForOrderStart({ orderId, insurance }));
  }

  public signTnCrypto() {
    this.store.dispatch(orderDetailsActions.signTnCrypto());
  }

  public changeOrderContract(contract: transport.Order.ICarrierContract) {
    this.store.dispatch(orderDetailsActions.changeOrderContract({ contract }));
  }

  public isDocumentSignAvailable$(docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(selectors.tnCryptoOrderDetails.isDocumentSignAvailable, { docType }));
  }

  public isTnCryptoDocumentSigningProcess$(docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(selectors.tnCryptoOrderDetails.isTnCryptoDocumentSigningProcess, { docType }));
  }

  public isTnCryptoDocumentSigned$(docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(selectors.tnCryptoOrderDetails.isTnCryptoDocumentSigned, { docType }));
  }

  public isTnCryptoDocumentOwnerSigned$(docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(selectors.tnCryptoOrderDetails.isTnCryptoDocumentOwnerSigned, { docType }));
  }

  public tnCryptoCarrierSignRequired$(docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(selectors.tnCryptoOrderDetails.tnCryptoCarrierSignRequired, { docType }));
  }

  public isTnCryptoDocumentSigningError$(docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(selectors.tnCryptoOrderDetails.isTnCryptoDocumentSigningError, { docType }));
  }

  public isTnCryptoDocumentOwnerSigning$(docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(selectors.tnCryptoOrderDetails.isTnCryptoDocumentOwnerSigning, { docType }));
  }

  public isTnCryptoDocumentCarrierChecking$(docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(selectors.tnCryptoOrderDetails.isTnCryptoDocumentCarrierChecking, { docType }));
  }

  public isTerminationArgeementInProgress$() {
    return this.store.pipe(
      select(selectors.tnCryptoOrderDetails.isTerminationArgeementInProgress, {
        docType: DOCUMENT_TYPE.TERMINATION_AGREEMENT,
      }),
    );
  }

  public isTnCryptoCarrierSigningError$(docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(selectors.tnCryptoOrderDetails.isTnCryptoCarrierSigningError, { docType }));
  }

  public isTnCryptoOwnerSigningError$(docType: DOCUMENT_TYPE) {
    return this.store.pipe(select(selectors.tnCryptoOrderDetails.isTnCryptoOwnerSigningError, { docType }));
  }

  public clearDetailState() {
    this.store.dispatch(orderDetailsActions.clearDetailState());
  }
}
