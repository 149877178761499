import { Directive, HostListener, Input, Optional } from '@angular/core';
import { NgControl } from '@angular/forms';
const defaultLen = '2';
const defaultAllowNegativeNumbers = false;
const defaultOnlyIntegers = false;
@Directive({
  selector: '[transportNumberOnly]',
})
export class TnNumberOnlyDirective {
  @Input() public lengthAfterSeparator = defaultLen;

  @Input() public allowNegativeNumbers = defaultAllowNegativeNumbers;

  @Input() public onlyIntegers = defaultOnlyIntegers;

  constructor(@Optional() private readonly formControl: NgControl) {}

  //
  // Allows you to enter fractional numbers using a period and a comma, but the comma is replaced by a dot
  //

  @HostListener('input', ['$event.target.value']) public onInput(event: unknown) {
    if (typeof event === 'string') {
      this.formControl.control?.patchValue(this.transformValue(event));
    } else {
      this.formControl.control?.patchValue(this.formControl.control?.value);
    }
  }

  private transformValue(startValue: string): string {
    let value = startValue;
    let isNegative: boolean = false;
    let intPart: string = '';
    let floatPart: string = '';

    {
      const firstDigitPosition = value.search(/\d/);

      if (firstDigitPosition === -1) return this.allowNegativeNumbers && value.search('-') !== -1 ? '-' : '';

      isNegative = (() => {
        const negativeSignPosition = value.search('-');
        return negativeSignPosition !== -1 && negativeSignPosition < firstDigitPosition;
      })();

      value = value.slice(firstDigitPosition);
    }

    const eraseNonDigits = (value: string) => value.replace(new RegExp(/[^\d]/g), '');


    if (this.onlyIntegers || value.search(/[,.]/) === -1) {
      intPart =  String(Number(eraseNonDigits(value)));
      floatPart = '';
    } else {
      const dotPosition = value.search(/[,.]/);

      intPart = String(Number(eraseNonDigits(value.slice(0, dotPosition))));
      floatPart = '.' + String(eraseNonDigits(value.slice(dotPosition + 1))).slice(0, Number(this.lengthAfterSeparator));
    }

    return (isNegative && this.allowNegativeNumbers ? '-' : '') + intPart + floatPart;
  }
}
