import { ChangeDetectionStrategy, Component, ContentChildren, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { NEVER, Observable } from 'rxjs';
import { MarketplaceTableColumnComponent } from './table-column.component';
import { IColumnConfig } from '../../interfaces/column-config.interface';
import { IRowExtender } from '../../table-row-extender';
import { composeRowExtenders, stopPropagationWhenSelectionExtender } from '../../table-row-extender';

@Component({
  selector: 'marketplace-table',
  templateUrl: './table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableComponent<T> implements OnChanges {
  @Input() public tableName: string = '';
  @Input() public dataSource: Observable<T[]> = NEVER;
  @Input() public columnConfigs: IColumnConfig[] = [];
  @Input() public rowClasses: ((row: T) => string[]) | null = null;
  @Input() public rowExtender: IRowExtender<T> | null = composeRowExtenders([
    stopPropagationWhenSelectionExtender({ event: 'mouseup' }),
    TableComponent.getTableRowClickExtender({ event: 'mouseup', context: this }),
  ]);

  // Fires when `rowExtender` isn't rewritten or rewritten with use of `TableComponent.getTableRowClickExtender` extender.
  @Output() public rowClick = new EventEmitter<{ rowData: T; event: Event }>();
  @ContentChildren(MarketplaceTableColumnComponent) public columns;

  public _hideTableHeader = false;
  @Input()
  public set hideTableHeader(v: boolean) {
    this._hideTableHeader = v;
  }

  public columnsToShow: string[] = [];
  public columnsDictionary: Record<string, IColumnConfig> = {};

  /**
   * Fires @Output() rowClick when `config.event` appeared on a table row.
   * @param config
   * @param {string} config.event - Common HTML event name
   * @param {Function} config.filter - Function gives Event object and filter that if that event isn't proper.
   * @param {Object} config.context - Instance of TableComponent.
   */
  public static getTableRowClickExtender<T>(config: {
    event: keyof HTMLElementEventMap;
    filter?: (event: Event) => boolean;
    context: TableComponent<T>;
  }) {
    const { event: eventName, filter, context } = config;
    const extender: IRowExtender<T> = (elRef, rowData) => {
      const listener = event => {
        if (filter && !filter(event)) return;
        context.rowClick.emit({ event, rowData });
      };

      const element = elRef.nativeElement;
      element.addEventListener(eventName, listener);
      return () => element.removeEventListener(eventName, listener);
    };

    return extender;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('columnConfigs' in changes) {
      const columns: IColumnConfig[] = changes.columnConfigs.currentValue;
      this.columnsToShow = columns.map((v: IColumnConfig) => v.name);
      this.columnsDictionary = columns.reduce((acc, cur) => {
        acc[cur.name] = cur;
        return acc;
      }, {});
    }
  }

  public getRowClasses(row: T): string[] {
    return this.rowClasses ? this.rowClasses(row) : [];
  }

  public getRowId(rowData): string {
    return rowData.item ? rowData.item.id : rowData?.id || '_';
  }
}
