import { Directive, OnDestroy } from '@angular/core';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';

@Directive({
  selector: '[transportAutocompletePosition]',
  exportAs: 'transportAutocompletePosition',
})
export class TnAutocompletePositionDirective implements OnDestroy {
  public constructor(private readonly matAutocompleteTrigger: MatAutocompleteTrigger) {
    window.addEventListener('scroll', this.scrollEvent, true);
  }

  public ngOnDestroy(): void {
    window.removeEventListener('scroll', this.scrollEvent, true);
  }

  private readonly scrollEvent = (): void => {
    if (!Boolean(this.matAutocompleteTrigger)) {
      return;
    }
    if (this.matAutocompleteTrigger.panelOpen) {
      this.matAutocompleteTrigger.updatePosition();
    }
  };
}
