import { InjectionToken } from '@angular/core';
import { touchDevice } from '@transport/ui-utils';

export const DEVTOOLS_EXTENSIONS_CHECKER = new InjectionToken<string>('ExtensionChecker');

export interface IDevtoolsExtensionsChecker {
  checkReduxDevtoolsExtension(): void;
}

export interface IDevtoolsExtensions {
  reduxDevToolsChecked?: true;
}

const t2DevStorageIdentifier = 't2Dev';

/**
 * Is used to prevent devtools check each time page is reloaded.
 */
export const t2DevSessionStorage = {
  get: () => {
    let result: IDevtoolsExtensions = {};
    const t2DevStorage = sessionStorage.getItem(t2DevStorageIdentifier);
    if (t2DevStorage !== null) {
      result = JSON.parse(t2DevStorage);
    }
    return result;
  },
  set: (options: { reduxDevToolsChecked?: true }) => {
    const t2DevStorageValue: IDevtoolsExtensions = {
      reduxDevToolsChecked: options.reduxDevToolsChecked,
    };
    sessionStorage.setItem(t2DevStorageIdentifier, JSON.stringify(t2DevStorageValue));
  },
};

export function devtoolsExtensionsChecker(): IDevtoolsExtensionsChecker {
  return {
    checkReduxDevtoolsExtension: () => {
      if (
        !Boolean(window['__REDUX_DEVTOOLS_EXTENSION__']) &&
        typeof t2DevSessionStorage.get().reduxDevToolsChecked === 'undefined' &&
        !touchDevice()
      ) {
//         const action = window.confirm(
//           `You browser is missing Redux DevTools Extension.
// You should use it to work effectively with NGRX.
// The extension is either not installed or disabled.
// Hit OK button to go to its GitHub page.`,
//         );
//         if (action) {
//           window.open('https://github.com/reduxjs/redux-devtools', '_blank');
//         }
      }
      t2DevSessionStorage.set({ reduxDevToolsChecked: true });
    },
  };
}

export function devtoolsExtensionsCheckerShim(): IDevtoolsExtensionsChecker {
  return {
    checkReduxDevtoolsExtension: () => null,
  };
}
