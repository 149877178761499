<ng-container *ngIf="(currentView$ | async) as view">
  <ng-container *ngIf="view === popupView.counterOffer" [ngTemplateOutlet]="counterOffer"></ng-container>
</ng-container>

<ng-template #counterOffer>
  <div class="popup px-3 max-w-[229px]">
    <h3 class="popup__header not-italic font-medium text-sm text-gray-700">
      Ваше предложение
    </h3>
    <div class="popup__main flex flex-col gap-2">
      <transport-tw-input-field
        errorsSize="xs"
        size="sm"
        [control]="counterOfferPriceControl"
        [errorToMessage]="priceControlErrorToMessage"
      >
        <div class="flex gap-1 items-center">
          <transport-tw-input size="sm" [rightAddon]="currencySign" [control]="counterOfferPriceControl">
            <input
              tw-input
              transportNumberOnly
              class="max-w-[140px] text-gray-700 text-end"
              thousandSeparator=" "
              autocomplete="off"
              [required]="true"
              [mask]="'separator.0'"
              [formControl]="counterOfferPriceControl"
            />
          </transport-tw-input>
          <mat-checkbox class="text-sm text-gray-700" [formControl]="counterOfferVatControl">
            {{'shared.mp.publications.modal.form.price.withVat' | translate}}
          </mat-checkbox>
        </div>
      </transport-tw-input-field>
      <common-textarea
        [control]="$any(counterOfferComment)!"
        [placeholder]="'shared.counterOfferComment' | translate"
        [errorToMessage]="validationErrors.bind(this)"
        [dataTest]="'counter-offer-modal-comment'"
        [rows]="3"
        size="sm"
      >
      </common-textarea>
    </div>
    <button
      class="tw-button-solid-sm w-full mt-2"
      [disabled]="!counterOfferPriceControl.valid || (loading | async)"
      (click)="sendCounterOffer()"
    >
      {{ 'shared.mp.verification.buttons.send' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #placeBetSuccess let-input>
  <transport-alert [title]="input.title" titleVariant="top" state="success"> </transport-alert>
</ng-template>
<ng-template #placeBetError>
  <transport-alert [title]="'shared.mp.cargo.table.popup.auction.betError' | translate" titleVariant="top" state="error">
    {{'shared.mp.cargo.table.popup.auction.betErrorTooLow' | translate}}
  </transport-alert>
</ng-template>
