import { Action, createReducer, on } from '@ngrx/store';
import { createNewOrder } from '@transport/ui-interfaces';

import { setCarrierOrderTrackingPageRouteData, setOwnerOrderTrackingPageRouteData } from '../actions/router-data.actions';
import { ITrackingState } from '../tracking.state';

/**
 * Initial state.
 */
export const initialTrackingRouterState: ITrackingState = {
  carrierOrderTrackingPage: {
    order: createNewOrder(),
    title: '',
  },
  ownerOrderTrackingPage: {
    order: createNewOrder(),
    title: '',
  },
};

const createTrackingRouterDataReducer = createReducer(
  initialTrackingRouterState,
  on(setCarrierOrderTrackingPageRouteData, (state, { payload }) => ({
    ...state,
    ...payload,
  })),
  on(setOwnerOrderTrackingPageRouteData, (state, { payload }) => ({
    ...state,
    ...payload,
  })),
);

export function trackingRouterDataReducer(state: ITrackingState, action: Action) {
  return createTrackingRouterDataReducer(state, action);
}
