<!-- <mat-form-field class="tw-off"> //Изменено для истории https://tndl.kaiten.ru/space/50858/card/5801299
  <mat-label>{{ title }}</mat-label>
  <input type="text"
         aria-label="Number"
         [required]="required"
         matInput
         placeholder="{{ 'shared.actions.startTyping' | translate }}"
         [formControl]="myControl"
         [matAutocomplete]="auto"
         (focus)="onFocus()"
         (blur)="onBlur()"
         [attr.data-test]="id"
         #trigger="matAutocompleteTrigger"
         transportAutocompletePosition="trigger" />
  <mat-autocomplete #auto
                    (optionSelected)="onOptionSelected($event)">
    <mat-option *ngFor="let option of visibleOptions$ | async"
                [value]="option.value">
      {{option.label || option.value}}
    </mat-option>
  </mat-autocomplete>
  <mat-error *ngIf="myControl.errors?.valueMustBeAnOption">
    {{'shared.errors.cargoTypeError' | translate}}
  </mat-error>
</mat-form-field> -->

  <transport-tw-input size="sm"
                      [control]="myControl"
                      class="autocomplite mt-1 !h-8"
                      matAutocompleteOrigin
                      #origin="matAutocompleteOrigin">
    <input
      [matAutocomplete]="auto"
      tw-input
      [formControl]="myControl"
      placeholder="{{ 'shared.actions.startTyping' | translate }}"
      class="pl-4"
      (focus)="onFocus()"
      (blur)="onBlur()"
      [attr.data-test]="id"
      #trigger="matAutocompleteTrigger"
      transportAutocompletePosition="trigger"
      [matAutocompleteConnectedTo]="origin"/>
  </transport-tw-input>
  <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onOptionSelected($event)" class="tw-menu-block">
    <mat-option *ngFor="let option of visibleOptions$ | async" [value]="option.value">
      <transport-tw-menu-option text="{{option.label || option.value}}" size="sm"></transport-tw-menu-option>
    </mat-option>
  </mat-autocomplete>
  <div *ngIf="myControl.errors?.valueMustBeAnOption && myControl.touched && showErrors" class="text-red-500 text-xs mt-1">
    {{'shared.errors.cargoTypeError' | translate}}
  </div>
