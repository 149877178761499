import { createAction, props } from '@ngrx/store';

export type TProgressPayload = Record<string, unknown>;

export const SHOW_PROGRESS_ACTION = '[Progress] show';

export const showProgress = createAction(SHOW_PROGRESS_ACTION, props<{ payload: TProgressPayload }>());

export const HIDE_PROGRESS_ACTION = '[Progress] hide';

export const hideProgress = createAction(HIDE_PROGRESS_ACTION, props<{ payload: TProgressPayload }>());
