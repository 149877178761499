import { COUNTER } from '../constants/constants.config';

export function generateEmptyArray(length: COUNTER | number) {
  return [...new Array(length)];
}

const length = 36;
const start = 2;
const end = 9;
export const generateId = (): string => Math.random().toString(length).substr(start, end);
