import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { IOrderContact, TOrderContact } from '@transport/ui-interfaces';
import { TnFioPipe } from '@transport/ui-pipes';

@UntilDestroy()
@Component({
  selector: 'transport-contacts-order',
  templateUrl: './contacts-order.component.html',
  styleUrls: ['./contacts-order.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnContactsOrderComponent {
  @Input() public placeholder = this.translate.instant('owner.order.field.fioShort');

  @Input() public title?: string | undefined;

  @Input() public set contacts(value: TOrderContact) {
    this.mapInputValueToInner(value);
  }

  public contactInfo: IOrderContact | null = null;

  constructor(private readonly translate: TranslateService, private readonly fioPipe: TnFioPipe) {}

  private mapInputValueToInner(contactsData?: TOrderContact) {
    this.contactInfo = {
      fio: Boolean(contactsData) ? this.fioPipe.transform(contactsData ?? {}) : '',
      email: contactsData?.email,
      phoneNumber: contactsData?.phoneNumber,
    };

    return this.contactInfo;
  }

  public copyMessage(value): void {
    const box = document.createElement('textarea');
    box.style.position = 'fixed';
    box.style.left = '0';
    box.style.top = '0';
    box.style.opacity = '0';
    box.value = value ?? '';
    document.body.appendChild(box);
    box.focus();
    box.select();
    document.execCommand('copy');
    document.body.removeChild(box);
  }
}
