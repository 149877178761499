import { RouterStateSnapshot } from '@angular/router';
import { RouterReducerState, RouterStateSerializer } from '@ngrx/router-store';
import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IRouterStateUrl, routerFeatureKey } from '../interfaces/router.interfaces';

/**
 * Custom route serializer.
 * Return an object including the URL, and router activated route root object.
 */
export class TnCustomRouteSerializer implements RouterStateSerializer<IRouterStateUrl> {
  /**
   * Custom route serializer serialize method.
   * @param routerState Router state
   */
  public serialize(routerState: RouterStateSnapshot): IRouterStateUrl {
    let route = routerState.root;

    while (route.firstChild !== null) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams },
    } = routerState;
    const { params, data } = route;

    return { url, params, queryParams, data, root: {} };
  }
}

export const selectRouterState = createFeatureSelector<RouterReducerState<IRouterStateUrl>>(routerFeatureKey);

export const selectIRouterStateUrl = createSelector(selectRouterState, routerReducerState => routerReducerState.state);
