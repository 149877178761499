import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { transport } from '@transport/proto';
import { TnBlackListModalComponent } from '@transport/ui-components';
import { BLACK_LIST_SECTION, BLACK_LIST_TYPE } from '@transport/ui-interfaces';
import { wordDeclination } from '@transport/ui-utils';

@Component({
  selector: 'transport-black-list-section',
  templateUrl: './black-list-section.component.html',
  styleUrls: ['./black-list-section.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnBlacklistSectionComponent {
  @Input() public list?: transport.Driver.IBlackListDriver[] | transport.Vehicle.IBlackListVehicle[];

  @Input() public type: BLACK_LIST_TYPE = BLACK_LIST_TYPE.TRUCK;

  constructor(private readonly dialog: MatDialog) {}

  public get prohibitedList() {
    return (this.list ?? []).filter(item => Boolean(item?.workProhibited));
  }

  public get partProhibitedList() {
    return (this.list ?? []).filter(item => !Boolean(item?.workProhibited));
  }

  public get prohibitedCount() {
    return this.prohibitedList.length;
  }

  public get partProhibitedCount() {
    return this.partProhibitedList.length;
  }

  public get prohibitedTitle() {
    return `shared.blackList.section.${wordDeclination(this.prohibitedCount, 'prohibited')}`;
  }

  public get partProhibitedTitle() {
    return `shared.blackList.section.${wordDeclination(this.partProhibitedCount, 'partProhibited')}`;
  }

  public get prohibitedCompanies() {
    return [...new Set((this.prohibitedList ?? []).map(item => item?.cargoOwner?.name))];
  }

  public get partProhibitedCompanies() {
    return [...new Set((this.partProhibitedList ?? []).map(item => item?.cargoOwner?.name))];
  }

  public get hasDifferentProhibitedTypes() {
    return this.prohibitedCount > 0 && this.partProhibitedCount > 0;
  }

  public openBlackListInfoDialog(isPartProhibited = false) {
    this.dialog.open(TnBlackListModalComponent, {
      width: '800px',
      data: {
        prohibited: this.prohibitedList,
        partProhibited: this.partProhibitedList,
        type: this.type,
        blackListSection: isPartProhibited ? BLACK_LIST_SECTION.PART_PROHIBITED : BLACK_LIST_SECTION.PROHIBITED,
      },
    });
  }
}
