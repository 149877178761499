import { ChangeDetectionStrategy, Component, Input, TemplateRef } from '@angular/core';

import { IManagementHeaderTitle } from './card-managment-header.model';

@Component({
  selector: 'transport-card-management-header',
  templateUrl: './card-management-header.component.html',
  styleUrls: ['./card-management-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnCardManagementHeaderComponent {
  @Input() public headerColor = 'contrast';

  @Input() public templateRef: TemplateRef<unknown> | null = null;

  @Input() public context: Record<string, unknown> | null = null;

  @Input() public title?: IManagementHeaderTitle | null;
}
