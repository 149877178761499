export interface IFreightFormValue {
  agentCarrierFreightIncludingVat: number | null;
}

export enum FREIGHT_ERROR {
  REQUIRED = 'required',
  AGENT_AMOUNT_LESS_100 = 'agentAmountLess100',
  AGENT_AMONT_MORE_FREIGHT = 'agentAmountMoreFreight',
}

export const MIN_AMOUNT = 100;
