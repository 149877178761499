import moment from 'moment';
import { TnFileToUpload } from '@transport/ui-interfaces';

export interface IUploadContractForm {
  contractId: string;
  dateStart: string | moment.Moment;
  dateEnd: string | moment.Moment;
  file: TnFileToUpload;
}

export interface IUploadContractInput {
  startDate: string;
  contractNumber: string;
  carrierOrganization: string;
  endDate?: string;
}
