import { transport } from '@transport/proto';

export const MAX_CONTRACT_NUMBER = 100;
export class TnCarrierOrganizationContractFormData {
  public contractNumber = '';

  public cargoQuantity = 0;

  public loadingPlaces: string[] = [];

  public unloadingPlaces: string[] = [];

  public cargoTypes: string[] = [];

  public dates: { fromDate?: string; toDate?: string | null } = {};

  /**
   * Constructor
   * @param carrierOrganization carrier contract data
   */
  constructor(contract: transport.Carrier.IContract) {
    this.contractNumber = contract.contractNumber ?? '';
    this.cargoQuantity = contract.cargoQuantity ?? 0;
    this.loadingPlaces = contract.loadingPlaces?.map(item => item.id ?? '') ?? [];
    this.unloadingPlaces = contract.unloadingPlaces?.map(item => item.id ?? '') ?? [];
    this.cargoTypes = contract.cargoTypes?.map(item => item.id ?? '') ?? [];

    this.dates = {
      fromDate: contract.startDate ?? '',
      toDate: contract.endDate ?? '',
    };
  }
}
