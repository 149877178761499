export const authLocalization = {
  login: {
    form: {
      title: 'Вход',
      submit: 'Войти',
      continue: 'Продолжить',
      registrationText: 'Зарегистрироваться',
      email: {
        label: 'Логин',
        placeholder: 'E-mail',
        errors: {
          maxlength: 'Максимальная длина значения равна:',
        },
      },
      password: {
        label: 'Пароль',
        placeholder: 'Минимум 8 символов',
        error: 'Минимальная длина 8 символов',
      },
    },
  },
  registration: {
    form: {
      title: 'Регистрация',
      activationTitle: 'Активация приглашения',
      submit: 'Зарегистрироваться',
      continue: 'Продолжить',
      activationSubmit: 'Продолжить',
      loginText: 'Уже зарегистрированы?',
      loginLinkText: 'Войти',
      inn: {
        label: 'Организация',
        placeholder: 'Название или ИНН организации',
      },
      companyName: {
        label: 'Организация',
        placeholder: 'Название организации',
      },
      fio: {
        label: 'ФИО',
        placeholder: 'Иванов Иван Иванович',
      },
      country: {
        label: 'Страна регистрации',
      },
      phone: {
        label: 'Телефон',
        placeholder: '+7 999 999 99 99',
      },
      email: {
        label: 'Логин',
        placeholder: 'E-mail',
      },
      password: {
        label: 'Пароль',
        placeholder: 'Минимум 8 символов',
        error: 'Минимальная длина 8 символов',
      },
      errors: {
        noFio: 'Введите ФИО',
        fioMaxlength: 'Максимальная длина значения равна:',
        noPhone: 'Введите номер телефона',
        incorrectPhone: 'Неправильный формат',
        noCompany: 'Введите наименование организации',
        noCountry: 'Укажите гражданство',
        companyMaxlength: 'Максимальная длина значения равна',
      },
    },
  },
  licenseText: {
    text: 'Продолжая, вы соглашаетесь ',
    link: 'со сбором, обработкой персональных данных и Пользовательским соглашением',
  },
  submitCode: {
    back: 'Вернуться',
    enterCode: 'Теперь введите код',
    sentToMail: 'Код отправили на почту',
    sendAgain: 'Отправить код повторно',
  },
};
