import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TnSupportedCurrency } from 'libs/transport-ui-pipes/src/lib/currency/multi-currency.pipe';

@Component({
  selector: 'marketplace-price-column',
  templateUrl: './price-column.component.html',
  styleUrls: ['./price-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PriceColumnComponent {
  @Input() public isVatIncluded: boolean = true;
  @Input() public price: number = 0;
  @Input() public currency: TnSupportedCurrency = TnSupportedCurrency.RUB;

  @Input() public hasProposal = false;

  constructor(private translate: TranslateService) {}
}
