import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MAX_PHONE_NUMBER_LENGTH } from '@transport/ui-interfaces';
import { MAX_EMAIL_LENGTH, regExpConfig } from '@transport/ui-utils';
export interface IProfileNameFormData {
  lastName: string;
  firstName: string;
  patronymic: string;
}

export interface IProfileEmailFormData {
  newEmail: string;
  currentPassword: string;
}

export interface IProfilePhoneFormData {
  phoneNumber: string;
}

export interface IProfileNameForm extends FormGroup {
  controls: {
    lastName: AbstractControl;
    firstName: AbstractControl;
    patronymic: AbstractControl;
  };
}

export interface IProfileEmailForm extends FormGroup {
  controls: {
    newEmail: AbstractControl;
    currentPassword: AbstractControl;
  };
}

export interface IProfilePhoneForm extends FormGroup {
  controls: {
    phoneNumber: AbstractControl;
  };
}

export type TNewProfileNameFormConstructor = (fb: FormBuilder, editableItem?: IProfileNameFormData) => IProfileNameForm;

export const newProfileNameForm: TNewProfileNameFormConstructor = (
  fb: FormBuilder,
  editableItem?: IProfileNameFormData,
): IProfileNameForm => {
  return fb.group({
    lastName: [editableItem?.lastName, {validators: Validators.required}],
    firstName: [editableItem?.firstName, {validators: Validators.required}],
    patronymic: [editableItem?.patronymic],
  }) as IProfileNameForm;
};

export type TNewProfileEmailFormConstructor = (fb: FormBuilder) => IProfileEmailForm;

export const newProfileEmailForm: TNewProfileEmailFormConstructor = (fb: FormBuilder): IProfileEmailForm => {
  return fb.group({
    newEmail: [
      '',
      {
        validators: Validators.compose([
          Validators.required,
          Validators.pattern(regExpConfig.email.regExp),
          Validators.maxLength(MAX_EMAIL_LENGTH),
        ]),
      },
    ],
    currentPassword: [''],
  }) as IProfileEmailForm;
};

export type TNewProfilePhoneFormConstructor = (fb: FormBuilder, editableItem?: IProfilePhoneFormData) => IProfilePhoneForm;

export const newProfilePhoneForm: TNewProfilePhoneFormConstructor = (
  fb: FormBuilder,
  editableItem?: IProfilePhoneFormData,
): IProfilePhoneForm => {
  return fb.group({
    phoneNumber: [editableItem?.phoneNumber,
      Validators.compose([
        Validators.required,
      ])
    ],
  }) as IProfilePhoneForm;
};
