import { createNewCarrierOrganization, ICarrierOrganization } from '../../organization/organization.interface';
import { TDirectoryEntityPredefinedAction } from '../directory.interface';

/**
 * Router carrier page component data model.
 */
export class TnCarrierPageComponentData {
  public title = 'directory: carrier page';

  public carrier: ICarrierOrganization | null = createNewCarrierOrganization();

  public predefinedAction: TDirectoryEntityPredefinedAction | null = null;

  public select: {
    loadingPlaces: {
      id: string | null | undefined;
      label: string;
    }[];
    unloadingPlaces: {
      id: string | null | undefined;
      label: string;
    }[];
    cargoTypes: {
      id: string | null | undefined;
      label: string | null | undefined;
    }[];
  } = {
    loadingPlaces: [
      {
        id: void 0,
        label: '',
      },
    ],
    unloadingPlaces: [
      {
        id: void 0,
        label: '',
      },
    ],
    cargoTypes: [
      {
        id: void 0,
        label: '',
      },
    ],
  };
}
