import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject } from 'rxjs';

import { ITnState } from '../state/index';
import { hideInProd } from './feature-access.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnFeatureAccessService {
  private readonly hideInProdSubject = new BehaviorSubject<boolean>(false);

  constructor(private readonly store: Store<ITnState>) {
    void this.hideInProd$.subscribe(value => {
      this.hideInProdSubject.next(Boolean(value));
    });
  }

  public get hideInProd() {
    return this.hideInProdSubject.value;
  }

  public readonly hideInProd$ = this.store.select(hideInProd);
}
