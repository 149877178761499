import { createAction, props } from '@ngrx/store';
import { TnAppEnvironment } from '@transport/ui-interfaces';

export const featureAccessNamespaceKey = 'Feature Access';

export const SET_ENV_ACTION = `[${featureAccessNamespaceKey}] set application environment`;

export const setEnv = createAction(SET_ENV_ACTION, props<{ payload: TnAppEnvironment }>());

export const SET_API_BASE_URL = `[${featureAccessNamespaceKey}] set api base url`;

export const setApiBaseUrl = createAction(SET_API_BASE_URL, props<{ payload: { apiBaseUrl: string } }>());

export const SET_FLAGS_ACTION = `[${featureAccessNamespaceKey}] set feature flags`;

export const setFlags = createAction(SET_FLAGS_ACTION, props<{ payload: Record<string, boolean> }>());
