import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS, MatFormFieldDefaultOptions } from '@angular/material/form-field';
import { MatRadioModule } from '@angular/material/radio';
import { TnCoreModule } from '@transport/core';
import { TnUiComponentsModule } from '@transport/ui-components';
import { TnUiDirectivesModule } from '@transport/ui-directives';
import { TnUiKitModule } from '@transport/ui-kit';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnUiPipesModule } from '@transport/ui-pipes';
import { TnFormModule } from '@transport/ui-store';
import { TnUiTranslateModule } from '@transport/ui-translate';
import { CodeInputModule } from 'angular-code-input';
import { TnMarketplaceRegistrationByInviteComponent } from 'libs/marketplace-auth/src/lib/components/registration-by-invite/registration-by-invite.component';

import { TnMarketplaceRootComponent } from './components';
import { TnConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { TnAuthHeaderComponent } from './components/header/header.component';
import { TnMarketplaceLoginByCodeComponent } from './components/login-by-code/login-by-code.component';
import { MpPassportRegistrationEmployeeComponent } from './components/passport-registration-employee/passport-registration-employee.component';
import { TnMarketplaceRegistrationByCodeComponent } from './components/registration-by-code/registration-by-code.component';
import { TnMarketplaceSubmitCodeComponent } from './components/submit-code/submit-code.component';
import { TnAuthRoutingModule } from './marketplace-auth-routing.module';
import { CustomControlsModule } from '@transport/custom-controls';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'fill',
};
/**
 * Transport Marketplace Auth module.
 * Encapsulates UI components, services, and related logic which purpose is to allow user
 * to register, and log in using registered user credentials.
 */
@NgModule({
  imports: [
    TnCoreModule,
    TnUiComponentsModule,
    TnUiDirectivesModule,
    TnUiMaterialModule,
    TnUiTranslateModule,
    TnUiPipesModule,
    TnFormModule,
    TnAuthRoutingModule,
    TnUiKitModule,
    MatRadioModule,
    CodeInputModule,
    CustomControlsModule,
  ],
  declarations: [
    TnMarketplaceRootComponent,
    TnAuthHeaderComponent,
    TnConfirmEmailComponent,
    TnMarketplaceLoginByCodeComponent,
    TnMarketplaceRegistrationByCodeComponent,
    TnMarketplaceSubmitCodeComponent,
    TnMarketplaceRegistrationByInviteComponent,
    MpPassportRegistrationEmployeeComponent,
  ],
  exports: [TnMarketplaceRootComponent],
  providers: [
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: appearance,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TnAuthModule {}
