import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TVehicle, VEHICLE_OWNERSHIPS_FILTERS } from '@transport/ui-interfaces';

import { ITnState } from '../../../state';
import { TnTableStateFacade } from '../../../table/table-state-facade';
import { removeTruck, removeTruckSuccess } from './actions/truck-remove.actions';
import { tableActions } from './actions/trucks.actions';
import { tableSelectors } from './selectors/trucks.selectors';

export interface ITrucksFilter {
  availability?: string;
  vehicleType?: TVehicle | null | string;
  regNo?: string;
  vehicleBrand?: string;
  trailerRegNo?: string;
  bodyType?: string;
  bodySubtype?: string;
  capacity?: number | string;
  loadingType?: string;
  volume?: number | string;
  agentCarrierOwnership?: VEHICLE_OWNERSHIPS_FILTERS;
}
@Injectable({
  providedIn: 'root',
})
export class TnDirectoryTrucksFacade extends TnTableStateFacade<ITrucksFilter> {
  public removeTruck$ = this.action.pipe(ofType(removeTruckSuccess));

  constructor(public store: Store<ITnState>, public action: Actions) {
    super(store, tableActions, tableSelectors);
  }

  public removeTruck(id: string) {
    this.store.dispatch(removeTruck({ id }));
  }
}
