import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

export enum STATUS_CHIP_CLASS {
  GRAY = 'gray',
  SUCCESS = 'success',
  ALERT = 'alert',
  WARNING = 'warning',
  BLUE = 'blue',
  ORANGE = 'orange',
  TRANSPARENT = 'transparent',
}

@Component({
  selector: 'transport-status-chip',
  templateUrl: './status-chip.component.html',
  styleUrls: ['./status-chip.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnStatusChipComponent {
  @Input() public text = '';

  @Input() public status: STATUS_CHIP_CLASS = STATUS_CHIP_CLASS.GRAY;

  public get hasStatus() {
    return Boolean(this.text);
  }
}
