import { Pipe, PipeTransform } from '@angular/core';
import { BLACK_LIST_TYPE, IVehicle } from '@transport/ui-interfaces';
import memo from 'memo-decorator';

/**
 * Pipe for to determine the black list type
 */
@Pipe({
  name: 'blackListType',
})
export class TnBlackListTypePipe implements PipeTransform {
  constructor() {}

  @memo()
  public transform(value: IVehicle): BLACK_LIST_TYPE {
    return Boolean((value.blackListInfo ?? [])[0]) ? BLACK_LIST_TYPE.TRUCK : BLACK_LIST_TYPE.TRAILER;
  }
}
