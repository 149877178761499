import { Action, createReducer, on } from '@ngrx/store';

import { setAddressFromFias, setFiasData } from './fias.actions';
import { fiasInitialState, IFiasState } from './fias.state';

const createFiasReducer = createReducer(
  fiasInitialState,
  on(setFiasData, (state, { payload, scope = 'default' }) => ({
    ...state,
    [scope]: { ...payload },
  })),

  on(setAddressFromFias, (state, { address, scope = 'address' }) => ({
    ...state,
    [scope]: { ...address },
  })),
);

export function fiasReducer(state: IFiasState, action: Action) {
  return createFiasReducer(state, action);
}
