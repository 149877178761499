import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TnRegistrationEffects } from './effects/registration-effects';
import { registrationReducer } from './reducers/registration.reducer';

@NgModule({
  declarations: [],
  imports: [StoreModule.forFeature('registration', registrationReducer), EffectsModule.forFeature([TnRegistrationEffects])],
})
export class TnRegistrationStateModule {}
