import { FILE_TYPE, FILE_UPLOAD_STATUS, IFile } from './file.interface';

const getFileTypeByExtension = (extension: string): FILE_TYPE => {
  let result = FILE_TYPE.UNKNOWN;
  switch (extension.toLowerCase()) {
    case 'doc':
    case 'docx':
      result = FILE_TYPE.DOC;
      break;

    case 'xls':
    case 'xlsx':
      result = FILE_TYPE.XLS;
      break;

    case 'pdf':
      result = FILE_TYPE.PDF;
      break;

    default:
      break;
  }
  return result;
};

const extractExtension = (fullFileName: string): string => fullFileName.split('.').pop() ?? '';
const extractNameWithoutExtension = (fullFileName: string): string => fullFileName.split('.').slice(0, -1).join('.');

export abstract class TnFileBase implements IFile {
  constructor(fullFileName: string) {
    this.fullName = fullFileName;
    this.extension = extractExtension(fullFileName);
    this.type = getFileTypeByExtension(this.extension);
    this.nameWithoutExtension = extractNameWithoutExtension(fullFileName);
  }

  public id?: string;

  public readonly extension: string;

  public readonly fullName: string;

  public readonly nameWithoutExtension: string;

  public errorText = '';

  public readonly type: FILE_TYPE;

  public status!: FILE_UPLOAD_STATUS;

  public downloadUrl?: string;

  public fileData?: File;

  public description?: string;
}
