import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';

import { TnConfirmationService } from '../../../../confirmation/confirmation.service';
import { TnToasterFacade, TOAST_TYPE } from '../../../../toaster/toaster.facade';
import { TnCarrierDirectoryApiService } from '../../carrier-directory-api.service';
import { TnTruckBaseEffects } from '../../truck-page/effects/truck-base.effects';
import { removeTruck, removeTruckFailure, removeTruckSuccess } from '../actions/truck-remove.actions';

@Injectable({
  providedIn: 'root',
})
export class TnTrucksEffects extends TnTruckBaseEffects {
  public operationFail = createEffect(() =>
    this.actions$.pipe(
      ofType(removeTruckFailure),
      tap(({ error }) => {
        //retrow error for handling by global error handler
        throw error;
      }),
    ),
  );

  public readonly listRemoveTruck = createEffect(() =>
    this.actions$.pipe(
      ofType(removeTruck),
      switchMap(({ id }) =>
        super.removeTruck(id).pipe(
          map(() => {
            this.toastFacade.showMessage('shared.directory.trucks.message.remove.success', TOAST_TYPE.SUCCESS);
            return removeTruckSuccess();
          }),
          catchError((error: Error) => {
            return of(removeTruckFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    public readonly actions$: Actions,
    public readonly confirmation: TnConfirmationService,
    public readonly carrierDirectoryService: TnCarrierDirectoryApiService,
    public readonly toastFacade: TnToasterFacade,
  ) {
    super(actions$, confirmation, carrierDirectoryService);
  }
}
