import { createFeatureSelector, createSelector } from '@ngrx/store';
import { IRegistrationState } from '@transport/ui-interfaces';

const getRegistratianState = createFeatureSelector<IRegistrationState>('registration');

export const getCurrentStep = createSelector(getRegistratianState, state => state.step);

export const getRegistrationStatuses = createSelector(getRegistratianState, state => ({
  driverCheck: state.driverCheck,
  vehicleCheck: state.vehicleCheck,
  organizationCheck: state.organizationCheck,
  esignCheck: state.esignCheck,
}));

export const documents = createSelector(getRegistratianState, state => state.documents);

export const signDocument = createSelector(getRegistratianState, state => state.signDocument);
