import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { IDriver, ORDER_STATUS } from '@transport/ui-interfaces';
import { TnConfirmationComponent, TnConfirmationService } from '@transport/ui-store';
import moment from 'moment';
import { of } from 'rxjs';
import { first, switchMap } from 'rxjs/operators';

import { TnDriverInviteModal } from '../driver/driver-invite-dialog/driver-invite-dialog.component';
import { TnChooseDriverModal } from './choose-driver-dialog/choose-driver-dialog.component';

@Component({
  selector: 'transport-card-pointer-driver',
  templateUrl: './card-pointer-driver.component.html',
  styleUrls: ['./card-pointer-driver.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnCardPointerDriverComponent {
  @Input() public driver?: IDriver | null;

  @Input() public title? = '';

  @Input() public label? = '';

  @Input() @HostBinding('class.disabled') public disabled = false;

  @Input() public orderStatus?: ORDER_STATUS;

  @Input() public changeTime?;

  @Input() public returnUrl?: string;

  @Input() public showAppInfo = true;

  @Input() public agentCarrierId: string | null = null;

  @Input() public onlyOwnership = false;

  @Input() public isAgent = false;

  @Input() public orderId?: string | null;

  @Input() public isChooseAny = false;

  @Input() public dataTest = '';

  // eslint-disable-next-line @angular-eslint/no-output-native -- TODO: tech debt
  @Output() public readonly select = new EventEmitter();

  @HostBinding('class.empty') public empty = !Boolean(this.driverSetted);

  public get driverSetted() {
    return this.driver?.id;
  }

  public get passport() {
    return Boolean(this.driver?.passportSeries) || Boolean(this.driver?.passportNumber) || Boolean(this.driver?.passportPlace);
  }

  public get license() {
    return Boolean(this.driver?.licenceSeries) || Boolean(this.driver?.licenceNumber) || Boolean(this.driver?.licencePlace);
  }

  public get fullname() {
    return [this.driver?.name, this.driver?.patronymic].filter(item => Boolean(item)).join(', ');
  }

  public get inviteDriverText() {
    return `carrier.directory.driver.form.${Boolean(this.driver?.hasBeenInvitedToDriverMobile) ? 'inviteAgain' : 'invite'}`;
  }

  constructor(
    public dialog: MatDialog,
    private readonly confirmation: TnConfirmationService,
    private readonly translate: TranslateService,
  ) {}

  private isWarningRequired() {
    return (
      Boolean(this.orderStatus) &&
      (this.orderStatus === ORDER_STATUS.CONTRACT_ATTACHED || this.orderStatus === ORDER_STATUS.TRANSPORT_RESERVED)
    );
  }

  private isReadyToGoWarningRequired() {
    return Boolean(this.orderStatus) && this.orderStatus === ORDER_STATUS.READY_TO_GO;
  }

  private warning() {
    if (this.isWarningRequired()) {
      return this.openWarning();
    } else if (this.isReadyToGoWarningRequired()) {
      return this.openReadyToGoWarning();
    }
    return of(true);
  }

  public changeDriver() {
    if (!this.disabled) {
      const operation = this.warning();
      void operation
        .pipe(
          first(),
          switchMap(result => {
            if (Boolean(result)) {
              return this.openSelectDialog();
            }
            return of(false);
          }),
        )
        .subscribe(result => {
          if (Boolean(result)) {
            if (this.isWarningRequired() || this.isReadyToGoWarningRequired()) {
              this.select.emit({ driver: result, unreserved: true });
            } else {
              this.select.emit({ driver: result });
            }
          }
        });
    }
  }

  public clearDriver() {
    if (!this.disabled) {
      const operation = this.warning();
      void operation.pipe(first()).subscribe(result => {
        if (Boolean(result)) {
          this.select.emit({ driver: null, unreserved: true });
        }
      });
    }
  }

  public openSelectDialog() {
    const dialogRef = this.dialog.open<TnChooseDriverModal, unknown, IDriver>(TnChooseDriverModal, {
      width: '1000px',
      height: '650px',
      data: {
        agentCarrierId: this.agentCarrierId,
        onlyOwnership: this.onlyOwnership,
        isAgent: this.isAgent,
        orderId: this.orderId,
        isChooseAny: this.isChooseAny,
        selectedDriverId: this.driver?.id,
      },
    });
    dialogRef.componentInstance.returnUrl = `${this.returnUrl}`;
    return dialogRef.afterClosed();
  }

  public openWarning() {
    return this.confirmation.openByPrefix('shared.warningChooseDriver', '375px');
  }

  public invite(event: Event) {
    event.stopPropagation();
    const { hasBeenInvitedToDriverMobile, id } = this.driver as IDriver;
    this.dialog.open(TnDriverInviteModal, {
      width: '400px',
      data: { hasBeenInvitedToDriverMobile, driverId: id },
    });
  }

  public openReadyToGoWarning() {
    const changeTime = moment.utc().startOf('day').add({ minutes: this.changeTime }).format('HH:mm');
    const dialogRef = this.dialog.open<TnConfirmationComponent>(TnConfirmationComponent, {
      width: '375px',
      data: {
        title: `shared.warningChooseReadyToGoTruck.title`,
        content: this.translate.instant('shared.warningChooseReadyToGoTruck.content', {
          time: `${changeTime} (ЧЧ:ММ)`,
        }),
        confirm: `shared.warningChooseReadyToGoTruck.confirm`,
        cancel: `shared.warningChooseReadyToGoTruck.cancel`,
      },
    });
    return dialogRef.afterClosed();
  }
}
