import { Component, Input } from '@angular/core';
import { TOOLTIP_POSITION } from "./tooltip.constants";

@Component({
  selector: 'transport-tooltip-content',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.scss'],
})
export class TnTooltipContentComponent {
  @Input() arrowPosition: TOOLTIP_POSITION | null = TOOLTIP_POSITION.LEFT;
  @Input() classTooltip = '';
  @Input() classArrow = '';

  constructor() {}
}
