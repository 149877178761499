import { FormControl, FormGroup, Validators } from '@angular/forms';
import { requireCheckboxesToBeCheckedValidator, VEHICLE_TYPE, VEHICLE_TYPE_OWNERSHIP } from '@transport/ui-interfaces';
import { TnSupportedCountry } from '@transport/ui-pipes';

export interface IVehicleForm {
  regNumber: FormControl;
  type: FormControl;
  make: FormControl;
  ownershipType: FormControl;
  isThirdParty: FormControl;
  driver: FormControl;
  trailerRegNumber: FormControl;
  trailerMake: FormControl;
  trailerBodyType: FormControl;
  loadingTypes: FormGroup;
  capacity: FormControl;
  volume: FormControl;
  agentCarrierId: FormControl;
  country: FormControl;

  // accreditation
  ownershipTractorType: FormControl;
  tracktorLeasingAgreement: FormControl;
  tracktorRentContract: FormControl,
  tractorSts: FormGroup,

  ownershipTrailerType: FormControl,
  trailerLeasingAgreement: FormControl;
  trailerRentContract: FormControl,
  trailerSts: FormGroup,
}

export function getVehicleForm() {
  const maxCapacityValue = 99;
  const maxVolumeValue = 120;
  const minCapacityValue = 0.001;
  const minVolumeValue = 0.01;

  return new FormGroup({
    country: new FormControl(null, [Validators.required]),
    regNumber: new FormControl(null, [Validators.required]),
    type: new FormControl(null, [Validators.required]),
    make: new FormControl(null, [Validators.required]),
    ownershipType: new FormControl(null, [Validators.required]),
    isThirdParty: new FormControl(null),
    driver: new FormControl(null, []),
    trailerRegNumber: new FormControl(null, [Validators.required]),
    trailerMake: new FormControl(null, [Validators.required]),
    trailerBodyType: new FormControl(null, [Validators.required]),
    baseLocation: new FormControl(null),
    loadingTypes: new FormGroup({
      back: new FormControl('', []),
      up: new FormControl('', []),
      side: new FormControl('', []),
    }, { validators: requireCheckboxesToBeCheckedValidator(['back', 'up', 'side']) }),
    capacity: new FormControl(null, [Validators.required, Validators.max(maxCapacityValue), Validators.min(minCapacityValue)]),
    volume: new FormControl(null, [Validators.required, Validators.max(maxVolumeValue), Validators.min(minVolumeValue)]),
    agentCarrierId: new FormControl(null),

    // accreditation
    ownershipTractorType: new FormControl(null, [Validators.required]),
    tracktorLeasingAgreement: new FormControl(null, [Validators.required]),
    tracktorRentContract: new FormControl(null, [Validators.required]),
    tractorSts: new FormGroup ({
      firstSide: new FormControl(null, [Validators.required]),
      secondSide: new FormControl(null, [Validators.required]),
    }),

    ownershipTrailerType: new FormControl(null, [Validators.required]),
    trailerLeasingAgreement: new FormControl(null, [Validators.required]),
    trailerRentContract: new FormControl(null, [Validators.required]),
    trailerSts: new FormGroup ({
      firstSide: new FormControl(null, [Validators.required]),
      secondSide: new FormControl(null, [Validators.required]),
    }),
  });
}

export const VEHICLE_TYPES = [
  {
    type: VEHICLE_TYPE.TRUCK,
    name: 'shared.mp.vehicles.vehicle.form.vehicleTypes.truck',
  },
  {
    type: VEHICLE_TYPE.TRAILER,
    name: 'shared.mp.vehicles.vehicle.form.vehicleTypes.trailer',
  },
  {
    type: VEHICLE_TYPE.COUPLER,
    name: 'shared.mp.vehicles.vehicle.form.vehicleTypes.coupler',
  },
];

export const OWNERSHIP_TYPES = [
  {
    type: VEHICLE_TYPE_OWNERSHIP.OWN,
    name: 'shared.mp.vehicles.vehicle.form.ownershipTypes.own',
  },
  {
    type: VEHICLE_TYPE_OWNERSHIP.RENT,
    name: 'shared.mp.vehicles.vehicle.form.ownershipTypes.rent',
  },
  {
    type: VEHICLE_TYPE_OWNERSHIP.LEASING,
    name: 'shared.mp.vehicles.vehicle.form.ownershipTypes.leasing',
  },
  {
    type: VEHICLE_TYPE_OWNERSHIP.FREE_USE,
    name: 'shared.mp.vehicles.vehicle.form.ownershipTypes.free_use',
  },
  {
    type: VEHICLE_TYPE_OWNERSHIP.JOINT_PROPERTY_OF_SPOUSES,
    name: 'shared.mp.vehicles.vehicle.form.ownershipTypes.joint_property',
  },
];

