import { createReducer, on } from '@ngrx/store';
import { transport } from '@transport/proto';
import { createNewUser, IUiSettings, IUser } from '@transport/ui-interfaces';

import * as currentUserAction from './current-user.actions';

export interface ICurrentUserState {
  // Additional entities state properties
  user: IUser & { uiSettings: IUiSettings };
  profile: transport.IUserProfile;
  loading: boolean;
  hasServerError: boolean;
  warningTextBanner: string | null;
}

export const initialState: ICurrentUserState = {
  user: createNewUser({ uiSettings: {} }) as IUser & {
    uiSettings: IUiSettings;
  },
  profile: { userSettings: { notificationSettings: null } } as transport.IUserProfile,
  loading: false,
  hasServerError: false,
  warningTextBanner: null,
};

const currentUserReducerFunc = createReducer(
  initialState,
  on(currentUserAction.restoreUserSuccessAction, (state, action) => {
    if (Boolean(action.user)) {
      return {
        ...state,
        user: action.user,
        loading: true,
      };
    }
    return state;
  }),

  on(currentUserAction.saveUserSuccessAction, (state, action) => {
    return {
      ...state,
      user: action.user,
      loading: true,
    };
  }),

  on(currentUserAction.getUserOrganizationSuccess, (state, { organization, id }) => {
    return {
      ...state,
      user: { ...state.user, organization, id },
    };
  }),

  on(currentUserAction.getCurrentUserProfileSuccess, (state, { profile }) => {
    return {
      ...state,
      profile: {
        ...profile,
        phoneNumber: profile.phoneNumber ?? '',
        firstName: profile.firstName ?? '',
        lastName: profile.lastName ?? '',
        patronymic: profile.patronymic ?? '',
      },
      user: {
        ...state.user,
      },
      loading: false,
    };
  }),

  on(currentUserAction.getCurrentUserUpdateVat, (state, { vat }) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        organization: {
          ...state.profile.organization,
          vat,
        },
      },
    };
  }),

  on(currentUserAction.updateUserOrganization, (state, { organization }) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        organization: { ...organization },
      },
      loading: false,
    };
  }),
  on(currentUserAction.updateUserProfileOrganization, (state, { profile }) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        ...profile,
      },
      loading: false,
    };
  }),

  on(currentUserAction.setNotificationSettings, state => {
    return {
      ...state,
      loading: true,
      hasServerError: false,
    };
  }),

  on(currentUserAction.setNotificationSettingsSuccess, (state, { newNotificationSettings }) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        userSettings: { notificationSettings: newNotificationSettings },
      },
      loading: false,
    };
  }),

  on(currentUserAction.setDocumentsSettingsSuccess, (state, { facsimileImg }) => {
    return {
      ...state,
      profile: {
        ...state.profile,
        facsimileImg,
      },
      loading: false,
    };
  }),

  on(currentUserAction.setNotificationSettingsFailure, state => {
    return {
      ...state,
      loading: false,
      hasServerError: true,
    };
  }),

  on(currentUserAction.editUserNameSuccess, (state, { user }) => {
    const { firstName, lastName, patronymic } = user;
    updateLocalStorage({ firstName, lastName, patronymic });

    return {
      ...state,
      user: {
        ...state.user,
        firstName,
        lastName,
        patronymic,
      },
      profile: {
        ...state.profile,
        firstName,
        lastName,
        patronymic,
      },
    };
  }),

  on(currentUserAction.editUserPhoneSuccess, (state, { user }) => {
    const { phoneNumber } = user;

    updateLocalStorage({ phoneNumber });
    return {
      ...state,
      user: {
        ...state.user,
        phoneNumber,
      },
      profile: {
        ...state.profile,
        phoneNumber,
      },
    };
  }),
  on(currentUserAction.editUserCountrySuccess, (state, { user }) => {
    const { country } = user;

    updateLocalStorage({ country });
    return {
      ...state,
      user: {
        ...state.user,
        country,
      },
      profile: {
        ...state.profile,
        country,
      },
    };
  }),
);


function updateLocalStorage(user) {
  const storedModel = localStorage.getItem('userService');
  if (storedModel !== null) {
    const model = {
      ...JSON.parse(storedModel),
      ...user,
    };
    localStorage.setItem('userService', JSON.stringify(model));
  }
}

export function currentUserReducer(state, action) {
  return currentUserReducerFunc(state, action);
}
