import { createAction, props } from '@ngrx/store';
import {
  ICheckOrganizationInput,
  ICheckTruckInput,
  IConfirmRegistrationStep,
  IDriverForm,
  IFileType,
  IOrganizationDocument,
  IRegistrationFileToUpload,
  IRegistrationStatus,
  REGISTRATION_ENTITY,
  TnFileToUpload,
} from '@transport/ui-interfaces';

export const REGISTRATION = '[REGISTRATION]';

export const changeStep = createAction(`${REGISTRATION} change step`, props<{ payload: REGISTRATION_ENTITY }>());

export const setRegistrationStatus = createAction(
  `${REGISTRATION} set registration status`,
  props<{
    payload: IRegistrationStatus;
  }>(),
);

export const editOrganization = createAction(
  `${REGISTRATION} edit organization`,
  props<{
    payload: IConfirmRegistrationStep<ICheckOrganizationInput>;
  }>(),
);

export const editOrganizationFailure = createAction(`${REGISTRATION} edit organization failure`, props<{ error: Error }>());

export const addDriver = createAction(`${REGISTRATION} add driver`, props<{ payload: IConfirmRegistrationStep<IDriverForm> }>());

export const addDriverFailure = createAction(`${REGISTRATION} add driver failure`, props<{ error: Error }>());

export const addSignForOrganization = createAction(`${REGISTRATION} add sign for organization`);

export const addSignForOrganizationFailure = createAction(`${REGISTRATION} add sign for organization failure`, props<{ error: Error }>());

export const loadDocumentsAndTypes = createAction(`${REGISTRATION} get documents and types`);

export const loadDocumentsAndTypesSuccess = createAction(
  `${REGISTRATION} load documents success`,
  props<{ documents: IRegistrationFileToUpload[] }>(),
);

export const loadDocumentsFailure = createAction(`${REGISTRATION} load documents failure`, props<{ error: Error }>());

export const uploadDocumentStart = createAction(
  `${REGISTRATION} upload document start`,
  props<{ file: TnFileToUpload; documentType: IFileType }>(),
);

export const uploadDocumentSuccess = createAction(`${REGISTRATION} upload document success`, props<{ file: IRegistrationFileToUpload }>());

export const uploadSignDocumentSucсess = createAction(
  `${REGISTRATION} upload sign document success`,
  props<{ file: IOrganizationDocument }>(),
);

export const uploadDocumentFailure = createAction(`${REGISTRATION} upload document failure`, props<{ error: Error }>());

export const deleteUploadedFile = createAction(`${REGISTRATION} delete uploaded sign file`);

export const deleteDocumentStart = createAction(`${REGISTRATION} delete document start`, props<{ docId: string; index: number }>());

export const deleteDocumentSuccess = createAction(`${REGISTRATION} delete document success`, props<{ index: number }>());

export const deleteDocumentFailure = createAction(`${REGISTRATION} delete document failure`, props<{ error: Error }>());

export const setAllDocumentTouched = createAction(`${REGISTRATION} set document touched`);
