import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RedirectAfterLoginService {
  private readonly localStorageFieldName: string = 'currentUrl';

  public readonly defaultRoute: string = 'my-cargo';

  public readonly profileRoute: string = 'my-settings';

  public readonly workspacesRoute: string = 'workspaces';

  public pushUrlToLocalStorage(url: string) {
    localStorage.setItem(this.localStorageFieldName, url);
  }

  public popUrlFromLocalStorage(): string | null {
    const currentUrl = localStorage.getItem(this.localStorageFieldName);
    localStorage.removeItem(this.localStorageFieldName);
    return currentUrl;
  }
}
