import { Component, Output, EventEmitter, Input } from '@angular/core';
import { SUPPORT_TONNA_PHONE } from '@transport/ui-store';

@Component({
  selector: 'verification-modal-footer',
  templateUrl: './verification-modal-footer.component.html',
  styleUrls: ['./verification-modal-footer.component.scss']
})
export class TnVerificationModalFooterComponent {
  @Output() close = new EventEmitter();

  @Input() showContinue = true;

  public helpPhone: { [key: string]: string } = SUPPORT_TONNA_PHONE;

  continueLater() {
    this.close.emit('');
  }
}
