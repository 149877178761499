<transport-tw-input-field size="sm"
                          label="{{ !labelInside ? placeholder : '' }}"
                          [control]="control"
                          [required]="!labelInside ? required : false "
                          [errorToMessage]="errorToMessage">
    <transport-tw-input size="sm"
                        matAutocompleteOrigin
                        #origin="matAutocompleteOrigin"
                        [control]="control">
        <div *ngIf="labelInside && !searchRef.value && !inputFocused"
             (click)="clickAtInsideLabel()">
            {{placeholder}}
            <span *ngIf="required"
                  class="text-red-500">*</span>
        </div>
        <input aria-label="Input"
               [required]="required"
               tw-input
               placeholder="{{ labelInside && !inputFocused ? '' : ('shared.actions.startTyping' | translate) }}"
               [matAutocomplete]="auto"
               (input)="searching($event)"
               [disabled]="control.disabled"
               [attr.data-test]="dataTest"
               (blur)="onBlur()"
               (focus)="inputFocused = true"
               #searchRef
               #trigger="matAutocompleteTrigger"
               [matAutocompleteConnectedTo]="origin"
               transportAutocompletePosition="trigger" />
        <mat-icon matSuffix
                  class="arrow">arrow_drop_down</mat-icon>
    </transport-tw-input>
</transport-tw-input-field>


<mat-autocomplete class="tw-menu-block"
                  #auto
                  [displayWith]="displayFn.bind(this)"
                  (optionSelected)="onItemSelected($event)">
    <mat-option *ngFor="let option of visibleData"
                [value]="getValue(option)">
        <ng-content></ng-content>
    </mat-option>
</mat-autocomplete>