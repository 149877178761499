<transport-tw-input-field
  class="!gap-0 data"
  [label]="label"
  [size]="size"
  [required]="required"
  [control]="$any(control)"
  [showErrors]="showErrors"
>
  <transport-tw-input class="mt-1 relative" [size]="size" [control]="$any(control)">
    <input [matDatepicker]="picker" class="opacity-0 w-0 h-0 absolute" tabindex="0"
    (dateInput)="onCalendarInput($event)" (dateChange)="onCalendarInput($event)"/>
    <input
      tw-input
      mask="d0.M0.0000"
      [validation]="false"
      [dropSpecialCharacters]="false"
      class="text-sm"
      [required]="required"
      [placeholder]="placeholder"
      autocomplete="off"
      [ngModel]="inputValue"
      (keyup)="onKeyUp($event)"
      (blur)="onKeyUp($event)"
      #inputField
    />
    <mat-icon class="cursor-pointer w-6 h-6 text-gray-400" svgIcon="calendar-icon" (click)="picker.open()"> </mat-icon>
    <mat-datepicker #picker></mat-datepicker>
  </transport-tw-input>
</transport-tw-input-field>
