import { FormControl, FormGroupDirective, NgForm } from '@angular/forms';

export class TnNgSelectErrorStateMatcher {
  constructor(private readonly ngSelect: { required: boolean; empty: boolean }) {}

  public isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    if (!Boolean(control)) {
      return this.ngSelect.required && this.ngSelect.empty;
    }
    return (control?.invalid ?? false) && ((control?.touched ?? false) || (form?.submitted ?? false));
  }
}
