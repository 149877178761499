import { Pipe, PipeTransform } from '@angular/core';
import { IPlace } from '@transport/ui-interfaces';
import memo from 'memo-decorator';

/**
 * Pipe for make string representation of loading/unloading place
 */
@Pipe({
  name: 'loadingPlaceSelect',
})
export class TnLoadingPlaceSelectPipe implements PipeTransform {
  @memo({
    resolver: (...args: any[]): string => JSON.stringify(args),
  })
  public transform(value: Partial<IPlace>): string {
    const pointName = Boolean(value.pointName) ? `${value.pointName}. ` : '';
    return `${pointName}${value.name}`;
  }
}
