import { gql } from 'apollo-angular';

export const DRIVERS_QUERIES = {
  list: gql`
    query drivers(
      $first: Int
      $offset: Int
      $availability: AvailabilityEnum
      $fullName: String
      $surname: String
      $namePatronymic: String
      $phone: String
      $agentCarrierId: ID
      $agentCarrierOwnership: AgentCarrierDriverOwnershipEnum
      $withActiveAgentCarrierOnly: Boolean
      $totalCount: Boolean = false
    ) {
      items: drivers(
        first: $first
        offset: $offset
        availability: $availability
        fullName: $fullName
        surname: $surname
        namePatronymic: $namePatronymic
        phone: $phone
        agentCarrierId: $agentCarrierId
        agentCarrierOwnership: $agentCarrierOwnership
        withActiveAgentCarrierOnly: $withActiveAgentCarrierOnly
      ) {
        id
        accrStatus
        accrComment
        phone
        surname
        name
        patronymic
        passportSeries
        passportNumber
        isArchived
        usesDriverMobile
        hasBeenInvitedToDriverMobile
        blackListInfo {
          cargoOwner {
            id
            name
          }
          workProhibited
          reason
        }
        agentCarrier {
          id
          carrierName
          inn
          bin
          iin
        }
      }

      totalCount: driversCount(
        availability: $availability
        phone: $phone
        fullName: $fullName
        surname: $surname
        namePatronymic: $namePatronymic
        agentCarrierId: $agentCarrierId
        agentCarrierOwnership: $agentCarrierOwnership
        withActiveAgentCarrierOnly: $withActiveAgentCarrierOnly
      ) @include(if: $totalCount)
    }
  `,
};

