import { createAction, props } from '@ngrx/store';
import { IUser, TUserPredefinedAction } from '@transport/ui-interfaces';

import { IUserForm } from '../user.interface';

const openUserDetails = createAction('[User page] Open User Details');
const setFormMode = createAction('[User page] Set Form Mode', props<{ mode: TUserPredefinedAction }>());
const loadUser = createAction('[User page] Load User');
const saveUser = createAction('[User page] Save User', props<{ user: IUserForm }>());
const inviteUser = createAction('[User page] Invite User', props<{ user: IUserForm }>());
const inviteUserAgain = createAction('[User page] Invite User again', props<{ id }>());

const removeUser = createAction('[User page] Remove User', props<{ user: IUser }>());
const resetPassword = createAction('[User page] Reset user password', props<{ id }>());

const setFormDirty = createAction('[User page] Set form isDirty', props<{ isDirty }>());

const resetState = createAction('[User page] Reset state');

export const userActions = {
  openUserDetails,
  setFormMode,
  loadUser,
  saveUser,
  inviteUser,
  inviteUserAgain,
  removeUser,
  resetPassword,
  setFormDirty,
  resetState,
};
