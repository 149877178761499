import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { transport } from '@transport/proto';
import { BLACK_LIST_SECTION, BLACK_LIST_TYPE } from '@transport/ui-interfaces';

const PART_PROHIBITED_INDEX = 1;
const PROHIBITED_INDEX = 0;
@Component({
  selector: 'transport-black-list-modal',
  templateUrl: './black-list-modal.component.html',
  styleUrls: ['./black-list-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnBlackListModalComponent {
  public get emptyTitle() {
    return this.translate.instant(`shared.blackList.section.${this.data.type === BLACK_LIST_TYPE.TRUCK ? 'emptyTruck' : 'emptyDriver'}`);
  }

  public get selectedIndex() {
    if (Boolean(this.data?.blackListSection)) {
      return this.data?.blackListSection === BLACK_LIST_SECTION.PART_PROHIBITED ? PART_PROHIBITED_INDEX : PROHIBITED_INDEX;
    }

    return this.data.prohibited.length > 0 ? PROHIBITED_INDEX : PART_PROHIBITED_INDEX;
  }

  constructor(
    public dialogRef: MatDialogRef<TnBlackListModalComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      prohibited: (transport.Driver.IBlackListDriver & {type?: BLACK_LIST_TYPE})[] | (transport.Vehicle.IBlackListVehicle & {type?: BLACK_LIST_TYPE})[];
      partProhibited: (transport.Driver.IBlackListDriver & {type?: BLACK_LIST_TYPE})[] | (transport.Vehicle.IBlackListVehicle & {type?: BLACK_LIST_TYPE})[];
      type: BLACK_LIST_TYPE;
      blackListSection?: BLACK_LIST_SECTION;
    },
    private readonly translate: TranslateService,
  ) {}
}
