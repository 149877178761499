import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LEGAL_FORM_CODES } from '@transport/ui-interfaces';
import { snakeToCamelCase } from '@transport/ui-utils';
import { GraphQLError } from 'graphql';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { TnConfirmationService } from '../../../../confirmation/confirmation.service';
import { IOrganizationData } from '../../../../profile/organization/organization.form';
import { TnGlobalErrorHandlerService } from '../../../../toaster/global-error-handler.service';
import { TnToasterFacade, TOAST_TYPE } from '../../../../toaster/toaster.facade';
import { agentCarrierActions } from '../actions/agent-carrier.actions';
import { TnAgentCarrierService } from '../agent-carrier.service';

export const CARRIER_ORGANIZATION_EQUALS_TO_OWN = 'CARRIER_ORGANIZATION_EQUALS_TO_OWN';

@Injectable({
  providedIn: 'root',
})
export class TnAgentCarrierEffects {
  public operationFail = createEffect(() =>
    this.actions$.pipe(
      ofType(
        agentCarrierActions.getAgentCarrierFailure,
        agentCarrierActions.addAgentCarrierFailure,
        agentCarrierActions.editAgentCarrierFailure,
        agentCarrierActions.removeAgentCarrierFailure,
      ),
      tap(({ error }) => {
        if ((error as GraphQLError).extensions?.category === CARRIER_ORGANIZATION_EQUALS_TO_OWN) {
          this.toastFacade.showMessage(
            `carrier.directory.agentCarriers.details.errors.${snakeToCamelCase(CARRIER_ORGANIZATION_EQUALS_TO_OWN.toLowerCase())}`,
          );
        }
        throw error;
      }),
    ),
  );

  public readonly getAgentCarrier = createEffect(() =>
    this.actions$.pipe(
      ofType(agentCarrierActions.getAgentCarrier),
      switchMap(({ id }) => {
        return Boolean(id) ? this.agentCarrierService.getAgentCarrier(id as string) : of(null);
      }),
      switchMap(agent => {
        return of(agentCarrierActions.getAgentCarrierSuccess({ agent }));
      }),
      catchError((error: GraphQLError) => {
        return of(agentCarrierActions.getAgentCarrierFailure({ error }));
      }),
    ),
  );

  public readonly addAgentCarrier = createEffect(() =>
    this.actions$.pipe(
      ofType(agentCarrierActions.addAgentCarrier),
      switchMap(({ agentCarrier }) => {
        return this.agentCarrierService.addAgentCarrier(agentCarrier).pipe(
          map(() => {
            this.toastFacade.showMessage('carrier.directory.agentCarriers.details.success.save', TOAST_TYPE.SUCCESS);
            return agentCarrierActions.addAgentCarrierSuccess();
          }),
          catchError((error: GraphQLError) => {
            return of(agentCarrierActions.addAgentCarrierFailure({ error }));
          }),
        );
      }),
    ),
  );

  public readonly editAgentCarrier = createEffect(() =>
    this.actions$.pipe(
      ofType(agentCarrierActions.editAgentCarrier),
      switchMap(({ agentCarrier }) => {
        return this.agentCarrierService.editAgentCarrier(agentCarrier).pipe(
          map(() => {
            this.toastFacade.showMessage('carrier.directory.agentCarriers.details.success.save', TOAST_TYPE.SUCCESS);
            return agentCarrierActions.editAgentCarrierSuccess();
          }),
          catchError((error: GraphQLError) => {
            return of(agentCarrierActions.editAgentCarrierFailure({ error }));
          }),
        );
      }),
    ),
  );

  public readonly removeAgentCarrier = createEffect(() =>
    this.actions$.pipe(
      ofType(agentCarrierActions.removeAgentCarrier),
      switchMap(({ id }) =>
        this.confirmation.openByPrefix('carrier.directory.agentCarriers.confirm.remove').pipe(
          filter(result => Boolean(result)),
          switchMap(() => {
            return this.agentCarrierService.sendAgentCarrierToArchive(id).pipe(
              map(() => {
                this.toastFacade.showMessage('carrier.directory.agentCarriers.details.success.remove', TOAST_TYPE.SUCCESS);
                return agentCarrierActions.removeAgentCarrierSuccess();
              }),
              catchError((error: Error) => {
                return of(agentCarrierActions.removeAgentCarrierFailure({ error }));
              }),
            );
          }),
        ),
      ),
    ),
  );

  public readonly getLegalForms = createEffect(() =>
    this.actions$.pipe(
      ofType(agentCarrierActions.getLegalForms),
      switchMap(() => this.agentCarrierService.getLegalForms()),
      switchMap(legalForms => {
        return of(agentCarrierActions.getLegalFormsSuccess({ legalForms }));
      }),
    ),
  );

  public readonly getOrganizationInfo = createEffect(() =>
    this.actions$.pipe(
      ofType(agentCarrierActions.getOrganizationInfo),
      switchMap(({ query, legalFormCode, carrierName }) => {
        return this.agentCarrierService.getOrganizationByInn(query, legalFormCode).pipe(
          map((organization: IOrganizationData | null) => {
            const company = organization ?? ({} as IOrganizationData);
            const hasCompanyName = Boolean(company.companyName);
            if (!hasCompanyName && legalFormCode === LEGAL_FORM_CODES.SELF_EMPLOYED) {
              company.companyName = carrierName;
            } else if (!hasCompanyName) {
              return agentCarrierActions.getOrganizationInfoFailure({
                organizationSearchError: 'shared.passport.signup.organizationNotFound',
              });
            }

            if (company?.status !== 'ACTIVE') {
              return agentCarrierActions.getOrganizationInfoFailure({ organizationSearchError: 'shared.passport.signup.notActive' });
            }

            return agentCarrierActions.getOrganizationInfoSuccess({ company });
          }),
          catchError((error: GraphQLError) => {
            const message = this.globalErrorHandler.extractGqlErrorMessage(error);
            return of(agentCarrierActions.getOrganizationInfoFailure({ organizationSearchError: message }));
          }),
        );
      }),
    ),
  );

  constructor(
    public readonly actions$: Actions,
    private readonly confirmation: TnConfirmationService,
    private readonly agentCarrierService: TnAgentCarrierService,
    private readonly toastFacade: TnToasterFacade,
    private readonly globalErrorHandler: TnGlobalErrorHandlerService,
  ) {}
}
