import { gql } from 'apollo-angular';

import * as sharedFragments from '../shared/graphql-fragments-shared';
import * as fragments from './graphql-fragments-carrier';
import { CARRIER_DIRECTORY_QUERY } from './graphql-queries-carrier-directory';

/**
 * GraphQL carrier queries
 */

export const CARRIER_QUERY = {
  ...CARRIER_DIRECTORY_QUERY,

  getOrders: gql`
    query ordersCarrier(
      $status: String
      $statuses: [String]
      $first: Int
      $offset: Int
      $totalCount: Boolean = false,
      $loadingPlaceByFiasCodes: [String],
      $unloadingPlaceByFiasCodes: [String]
      $allocationType: AllocationTypeEnum
      $actualProblemEvent: ActualProblemEventEnum
      $biddingType: BiddingTypeEnum
      $loadingDates: [Date]
      $unloadingDates: [Date]
      $loadingDateFrom: Date
      $loadingDateTo: Date
      $unloadingDateFrom: Date
      $unloadingDateTo: Date
      $externalNo: ID
      $id: ID
      $driverDataFullname: String
      $vehicleDataRegNo: String
      $sortBy: [String]
    ) {
      orders(
        status: $status,
        statuses: $statuses,
        first: $first,
        offset: $offset,
        loadingPlaceByFiasCodes: $loadingPlaceByFiasCodes,
        unloadingPlaceByFiasCodes: $unloadingPlaceByFiasCodes
        allocationType: $allocationType
        actualProblemEvent: $actualProblemEvent
        biddingType: $biddingType
        loadingDates: $loadingDates
        unloadingDates: $unloadingDates
        loadingDateFrom: $loadingDateFrom
        loadingDateTo: $loadingDateTo
        unloadingDateFrom: $unloadingDateFrom
        unloadingDateTo: $unloadingDateTo
        externalNo: $externalNo
        id: $id
        driverDataFullname: $driverDataFullname
        vehicleDataRegNo: $vehicleDataRegNo
        sortBy: $sortBy
      ) {
        ${fragments.ORDERS_FRAGMENT}
        lot {
          ${fragments.LOT_FRAGMENT_SHORT}
        }
        routeSheet {
          isDriverOnline
        }
      }

      totalCount: ordersCount(
        status: $status,
        statuses: $statuses,
        loadingPlaceByFiasCodes: $loadingPlaceByFiasCodes,
        unloadingPlaceByFiasCodes: $unloadingPlaceByFiasCodes
        actualProblemEvent: $actualProblemEvent
        allocationType: $allocationType
        biddingType: $biddingType
        loadingDates: $loadingDates
        unloadingDates: $unloadingDates
        loadingDateFrom: $loadingDateFrom
        loadingDateTo: $loadingDateTo
        unloadingDateFrom: $unloadingDateFrom
        unloadingDateTo: $unloadingDateTo
        externalNo: $externalNo
        id: $id
        driverDataFullname: $driverDataFullname
        vehicleDataRegNo: $vehicleDataRegNo
      ) @include(if: $totalCount)
    }
  `,

  getBiddingOrders: gql`
    query biddingOrders(
      $status: String
      $statuses: [String]
      $first: Int
      $offset: Int
      $totalCount: Boolean = false,
      $loadingPlaceByFiasCodes: [String],
      $unloadingPlaceByFiasCodes: [String]
      $allocationType: AllocationTypeEnum
      $biddingType: BiddingTypeEnum
      $loadingDates: [Date]
      $unloadingDates: [Date]
      $loadingDateFrom: Date
      $loadingDateTo: Date
      $unloadingDateFrom: Date
      $unloadingDateTo: Date
      $externalNo: ID
      $id: ID
      $onlyMy: Boolean
      $sortBy: [String]
    ) {
      biddingOrders(
        status: $status,
        statuses: $statuses,
        first: $first,
        offset: $offset,
        loadingPlaceByFiasCodes: $loadingPlaceByFiasCodes,
        unloadingPlaceByFiasCodes: $unloadingPlaceByFiasCodes
        allocationType: $allocationType
        biddingType: $biddingType
        loadingDates: $loadingDates
        unloadingDates: $unloadingDates
        loadingDateFrom: $loadingDateFrom
        loadingDateTo: $loadingDateTo
        unloadingDateFrom: $unloadingDateFrom
        unloadingDateTo: $unloadingDateTo
        externalNo: $externalNo
        id: $id
        onlyMy: $onlyMy
        sortBy: $sortBy
      ) {
        ${fragments.BIDDING_ORDERS_FRAGMENT}
        biddinglot {
          ${fragments.BIDDING_LOT_FRAGMENT_SHORT}
        }
      }
      totalCount: biddingOrdersCount(
        status: $status,
        statuses: $statuses,
        loadingPlaceByFiasCodes: $loadingPlaceByFiasCodes,
        unloadingPlaceByFiasCodes: $unloadingPlaceByFiasCodes
        allocationType: $allocationType
        biddingType: $biddingType
        loadingDates: $loadingDates
        unloadingDates: $unloadingDates
        loadingDateFrom: $loadingDateFrom
        loadingDateTo: $loadingDateTo
        unloadingDateFrom: $unloadingDateFrom
        unloadingDateTo: $unloadingDateTo
        externalNo: $externalNo
        id: $id
        onlyMy: $onlyMy
        sortBy: $sortBy
      ) @include(if: $totalCount)
    }
  `,

  getOrder: gql`
    query getOrder($orderId: ID!) {
      order(id: $orderId) {
        ${fragments.ORDER_FRAGMENT}
      }
    }
  `,

  getCompletedTnOrders: gql`
    query completedTnOrders(
      $first: Int,
      $offset: Int,
      $dateFrom: Date
      $dateTo: Date
      $loadingPlaces: [ID]
      $carrierContract: ID
      $actualCarrierOrganizations: [ID]
      $agentUsesOwnVehicle: Boolean
      $sortBy: [String]
      $ids: [ID]
      $totalCount: Boolean = false
    ) {
      completedTnOrders(
        first: $first
        offset: $offset
        dateFrom: $dateFrom
        dateTo: $dateTo
        loadingPlaces: $loadingPlaces
        carrierContract: $carrierContract
        actualCarrierOrganizations: $actualCarrierOrganizations
        agentUsesOwnVehicle: $agentUsesOwnVehicle
        sortBy: $sortBy
        ids: $ids
      ) {
        id
        externalNo
        uploadedDocuments {
          documentType {
            id
            innerName
            name
          }
        }
        loadingPlace {
          address
        }
        loadingDate
        unloadingPlace {
          address
        }
        vehicle {
          regNo
        }
        price
        driver {
          surname
          name
          patronymic
        }
        carrierContract {
          id
          contractNumber
          name
        }
        agentUsesOwnVehicle
        actualCarrierOrganization {
          id
          name
        }
        contractPrice
      }

      completedTnOrdersCount: completedTnOrdersCount(
        dateFrom: $dateFrom
        dateTo: $dateTo
        loadingPlaces: $loadingPlaces
        carrierContract: $carrierContract
        actualCarrierOrganizations: $actualCarrierOrganizations
        agentUsesOwnVehicle: $agentUsesOwnVehicle
        sortBy: $sortBy
        ids: $ids
      ) @include(if: $totalCount)
    }
  `,

  getSelectedOrders: gql`
    query allOrders(
      $id: ID
      $ids: [ID]
      $sortBy: [String]
    ) {
      allOrders(
        id: $id
        ids: $ids
        sortBy: $sortBy
      ) {
        ${fragments.ORDER_REPORT_FRAGMENT}
      }
    }
  `,

  getCarrierContractsWithTn: gql`
    query carrierContractsWithTn(
      $first: Int
      $offset: Int
      $id: ID
      $name: String
      $sortBy: [String]
      $totalCount: Boolean = false
    ) {
      carrierContractsWithTn(first: $first, offset: $offset, id: $id, name: $name, sortBy: $sortBy) {
        id
        contractNumber
        name
        contractType
      }

      carrierContractsWithTnCount: carrierContractsWithTnCount(id: $id, name: $name, sortBy: $sortBy) @include(if: $totalCount)
    }
  `,

  completedOrdersReport: gql`
    query completedOrdersReport($id : ID!) {
      completedOrdersReport(id: $id) {
        id
        status
        notes
        paymentDocuments
        reportSettings {
          reportNo
          reportDate
          agentBillNo
          agentInvoiceNo
          agentCarrierBillNo
          actualCarrierInvoiceNo
          headOfOrganization
          chiefAccountant
          service {
            id
            name
          }
        }
        orderRecords {
          invoiceNo
          invoiceDate
          invoiceFile {
            id
            name
            url
          }
          actNo
          actDate
          actFile {
            id
            name
            url
          }
          order {
            ${fragments.ORDER_REPORT_FRAGMENT}
          }
        }
        downloadPrintedFormsArchiveUrl
      }
    }
  `,

  getCompletedOrdersReportServices: gql`
    query completedOrdersReportServices($sortBy: [String]) {
      completedOrdersReportServices(sortBy: $sortBy) {
        id
        name
      }
    }
  `,

  getCarriersRatingReportData: gql`
    query carrierRatingReports($countRecords: Int, $offset: Int, $departmentIn: [String], $startDate: Date, $endDate: Date) {
      carrierRatingReports(first: $countRecords, offset: $offset, departmentIn: $departmentIn, startDate: $startDate, endDate: $endDate) {
        id
        indicator
        department
        deliveryArea
        startDate
        endDate
        currentRating
        calculatedRating
        totalDeliveriesCompleted
        assignedDeliveryRequests
        assignedDeliveryRequestsRefused
        assignedDeliveryRequestsCompleted
        deliveryContractsRefused
        details {
          id
        }
        vacantDeliveryRequestsCompleted
        vacantRequestsDeliveriesPercentage
        carrierFaultCanceledShipments
        carrierFaultDelayedShipmentsPercentage
        vehicleSearchAverageTime
        refusedRequestsPercentage
      }
      carrierRatingReportsCount(departmentIn: $departmentIn, startDate: $startDate, endDate: $endDate)
    }
  `,

  getCarriersRatingDetailsReportData: gql`
    query carrierRatingDetails($carrierRatingId: ID!, $countRecords: Int, $offset: Int) {
      carrierRatingReportDetails(carrierRatingId: $carrierRatingId, first: $countRecords, offset: $offset) {
        id
        deliveryRequestNumber
        deliveryRequestDateUtc
        deliveryContractNumber
        deliveryContractDateUtc
        deliveryRequestType
        deliveryCompleted
        deliveryContractRefused
        carrierFaultCanceledShipment
        carrierFaultDelayedShipment
      }
      carrierRatingReportDetailsCount(carrierRatingId: $carrierRatingId)
    }
  `,

  /**
   * GraphQL query: actual contract status
   */
  getOrderContractStatus: gql`
    query getOrderContractStatus($orderId: ID!) {
      order(id: $orderId) {
        status
        secondsToReservationTimeExpired
        viewProcessDeadline
        uploadedDocuments{
          ${sharedFragments.ORDER_UPLOADED_DOCUMENT_FRAGMENT}
        }
        documentActualStatus(documentType: ALL) {
          url
          status
          documentType {
            innerName
          }
        }
        documentStatusHistory(documentType: ALL) {
          status
          documentType {
            innerName
          }
        }
      }
    }
  `,

  /**
   * GraphQL query: order extra conditions
   */
  getExtraConditions: gql`
    query getExtraConditions($orderId: ID!) {
      order(id: $orderId) {
        id
        lot {
          extraConditionsForMarket
        }
      }
    }
  `,

  getOrderDriver: gql`
    query getOrderDriver($orderId: ID!) {
      order(id: $orderId) {
        loadingPlaces {
          storagePoint {
            id
            longitude
            latitude
          }
        }
        createdDatetime
        unloadingPlaces {
          storagePoint {
            id
            longitude
            latitude
          }
        }
        driver {
          id
        }
        customerOrganization {
          enableDeliveryTracking
        }
      }
    }
  `,

  /**
   * GraphQL query: auction order
   */
  getAuctionOrder: gql`
    query auctionOrder($orderId: ID!) {
      auctionOrder(id: $orderId) {
        acceptDeadline
        actualContractNumber
        carrierBusinessRelationshipToOwner
        hasMyCounterOffer
        allowCounterOffer
        ${fragments.ORDER_FRAGMENT}
        lot {
          ${fragments.LOT_FRAGMENT}
        }
        cantBetReasons
      }
    }
  `,

  getBiddingOrder: gql`
    query biddingOrder($orderId: ID!) {
      biddingOrder(id: $orderId) {
        carrierBusinessRelationshipToOwner
        actualContractNumber
        ${fragments.ORDER_FRAGMENT}
        myOrganizationBet
        assignedOrder {
          id
        }
        biddingStatusForCarrier
        biddinglot {
          ${fragments.BIDDING_LOT_FRAGMENT}
        }
      }
    }
  `,

  /**
   * GraphQL query: lot
   */
  getLot: gql`
    query lot($id: ID!) {
      lot(id: $id) {
        ${fragments.LOT_FRAGMENT}
      }
    }
  `,

  getDepartments: gql`
  query carrierRatingReports {
    carrierRatingDepartments(sortBy:"department") {
      department
    }
  }
  `,

  getRouteSheets: gql`
    query routeSheets($driverId: ID!) {
      routeSheets(driverId: $driverId) {
        id
        status
        order {
          ${fragments.ORDER_FRAGMENT_ROUTE_SHEET}
        }
      }
    }
  `,

  /**
   * GraphQL query: routeSheetEvents
   */
  getRouteSheetEvents: gql`
    query routeSheetEvents($routeSheetId: ID!, $event: RouteSheetEventEvent) {
      routeSheetEvents(routeSheetId: $routeSheetId, event: $event) {
        ${sharedFragments.ROUTE_SHEET_EVENT}
      }
    }
  `,

  getActiveRouteSheet: gql`
    query routeSheets($driverId: ID!) {
      routeSheets(driverId: $driverId) {
        id
        status
        order {
        ${fragments.ORDER_FRAGMENT}
        }
        documents {
          filename
          url
          datetime
        }
      }
    }
  `,

  getPackagingTypes: gql`
    query listPackagingTypesForCargoType {
      listPackagingTypesForCargoType
    }
  `,

  getProfile: gql`
    query getProfile {
      profile {
        organization {
          ${fragments.ORGANIZATION_FRAGMENT}
        }
        companyName
      }
    }
  `,

  getInsuranceCost: gql`
    query insuranceCost($orderId: ID!, $declaredPrice: Int!) {
      insuranceCost(orderId: $orderId, declaredPrice: $declaredPrice)
    }
  `,

  getInsuranceInfo: gql`
    query insuranceInitialInfo($orderId: ID!) {
      insuranceInitialInfo(orderId: $orderId) {
        schema
        fieldValues
        defaultValues
      }
    }
  `,

  getCarrierUser: gql`
    query carrierUser($id: ID!) {
      carrierUser(id: $id) {
        createdAt
        tnId
        registrationSource
        email
        phoneNumber
        firstName
        lastName
        selfRegistrationInProcess
        organization {
          id
          inn
          kpp
          signer
          legalAddress
          registrationNumber
          name
          phoneNumber
          email
          legalForm {
            ${sharedFragments.LEGAL_FORM_FRAGMENT}
          }
          marketAttributes {
            organizationCheck
            driverCheck
            vehicleCheck
            esignCheck
            accessRequested
          }
          hasMarketAccess
          hasTmsAccess
        }
      }
    }
  `,

  getCarrierUserMarketAttributes: gql`
    query carrierUser($id: ID!) {
      carrierUser(id: $id) {
        selfRegistrationInProcess
        organization {
          id
          hasTmsAccess
          hasMarketAccess
          accrAtDt
          accrValue
          accrExpAt
          accrProfile
          accrStatus
          marketAttributes {
            organizationCheck
            driverCheck
            vehicleCheck
            esignCheck
            accessRequested
          }
          vat {
            name
            rate
          }
          name
        }
      }
    }
  `,

  getOrganizationDataByInn: gql`
    query organizationDataByInn($inn: String!, $legalFormCode: String!) {
      organizationDataByInn(inn: $inn, legalFormCode: $legalFormCode) {
        companyName
        legalFormCode
        kpp
        address
        ogrn
        status
      }
    }
  `,

  getOrganizationDocumentsType: gql`
    query organizationDocumentTypes {
      organizationDocumentTypes {
        id
        name
        innerName
      }
    }
  `,

  getOrganizationDocuments: gql`
    query organizationDocuments {
      organizationDocuments {
        id
        documentType {
          id
          name
          innerName
        }
        uploadedFile {
          id
          name
          url
          createdAt
        }
      }
    }
  `,
  getAgentCarriers: gql`
  query getAgentCarriers($first: Int
    $offset: Int
    $availability: AvailabilityEnum
    $status: AgentCarrierStatusEnum
    $searchQuery: String
    $totalCount: Boolean = false
    ) {
    agentCarriers(
      availability: $availability
      status: $status
      searchQuery: $searchQuery
      first: $first
      offset: $offset
    ) {
      id
      carrierName
      country
      iin
      bin
      inn
      kpp
      lastExistenceCheck {
        createdAt
        status
      }
      isArchived
      vat{
          id
          name
      }
      legalForm {
        code
       }
    }
    totalCount: agentCarriersCount(
      availability: $availability
      searchQuery: $searchQuery
    ) @include(if: $totalCount)
  }
  `,

  getAgentCarrier: gql`
    query agentCarrier($id: ID!) {
      agentCarrier(id: $id) {
        id
        carrierName
        vat {
          id
          name
        }
        postalAddress
        name
        inn
        kpp
        legalAddress
        isArchived
        lastExistenceCheck {
          createdAt
          status
        }
        legalForm {
          code
        }
      }
    }
  `,

  getAgentFeeAndFreightDetails: gql`
    query agentFeeAndFreightDetails($orderId: ID!, $agentCarrierId: ID!, $freightAmount: Float, $carrierContractId: ID!) {
      agentFeeAndFreightDetails(
        orderId: $orderId,
        agentCarrierId: $agentCarrierId,
        freightAmount: $freightAmount,
        carrierContractId: $carrierContractId
      ) {
        agentCarrierFreightIncludingVat
        agentCarrierVatRate
        agentCarrierFreightVatAmount
        agentVatAmountPerAgentCarrier
        agentFeeIncludingVat
        agentFeeVatRate
        agentFeeVatAmount
        totalPrice
      }
    }
  `,

  completedOrdersReportStatuses: gql`
    query completedOrdersReportStatuses {
      completedOrdersReportStatuses
    }
  `,

  completedOrdersReports: gql`
    query completedOrdersReports(
      $id: ID
      $first: Int,
      $offset: Int,
      $yearAndMonthDate: Date,
      $carrierContractId: ID
      $agentUsesOwnVehicle: Boolean
      $statuses: [String],
      $hasNotes: Boolean,
      $agentBillNo: String,
      $sortBy: [String],
      $reportNo: String,
      $totalCount: Boolean = false
    ) {
      completedOrdersReports(
        first: $first,
        offset: $offset,
        yearAndMonthDate: $yearAndMonthDate,
        carrierContractId: $carrierContractId,
        agentUsesOwnVehicle: $agentUsesOwnVehicle
        statuses: $statuses,
        hasNotes: $hasNotes,
        agentBillNo: $agentBillNo,
        sortBy: $sortBy
        id: $id
        reportNo: $reportNo
      ) {
        id
        paymentDocuments,
        status,
        notes,
        totalSum,
        reportSettings {
          reportNo
          reportDate
          agentBillNo
        }
        downloadPrintedFormsArchiveUrl
      }
      completedOrdersReportsCount: completedOrdersReportsCount(
        yearAndMonthDate: $yearAndMonthDate,
        carrierContractId: $carrierContractId
        agentUsesOwnVehicle: $agentUsesOwnVehicle
        statuses: $statuses,
        hasNotes: $hasNotes,
        agentBillNo: $agentBillNo
        id: $id
        reportNo: $reportNo
      ) @include(if: $totalCount)
    }
  `,

  getTenders: gql`
    query tenders(
      $first: Int
      $offset: Int
      $onlyWithMyBids: Boolean
      $department: String
      $loadingSettlement: String
      $ordersQuantityFrom: Int
      $ordersQuantityTo: Int
      $startDateFrom: Date
      $startDateTo: Date
      $sortBy: [String]
    ) {
      tenders(
        first: $first,
        offset: $offset,
        onlyWithMyBids: $onlyWithMyBids,
        department: $department,
        loadingSettlement: $loadingSettlement,
        ordersQuantityFrom: $ordersQuantityFrom,
        ordersQuantityTo: $ordersQuantityTo,
        startDateFrom: $startDateFrom,
        startDateTo: $startDateTo,
        sortBy: $sortBy,
      ) {
        id
        lotNumber
        bidsEndDatetime
        loadingSettlement
        department
        course
        unloadingPlace
        activeDateFrom
        activeDateTo
        cargoWeightFrom
        cargoWeightTo
        ordersQuantity
        maxOrderPrice {
          amount
          currency
        }
        winnerPrice {
          amount
          currency
        }
        displayedStatus
        lastBidPrice
        vehicleRequirements {
          bodyType
          liftingCapacity
          volume
          length
          width
          height
          mustHaveManipulator
        }
      }
      tendersCount: tendersCount(
        onlyWithMyBids: $onlyWithMyBids
        department: $department
        loadingSettlement: $loadingSettlement
        ordersQuantityFrom: $ordersQuantityFrom
        ordersQuantityTo: $ordersQuantityTo
        startDateFrom: $startDateFrom,
        startDateTo: $startDateTo,
        sortBy: $sortBy
      )
    }
  `,

  getTendersDepartments: gql`
    query tenderDepartments {
      tenderDepartments
    }
  `,

  getTenderLoadingSettlements: gql`
    query tenderLoadingSettlements {
      tenderLoadingSettlements
    }
  `,

  getTender: gql`
    query tender($id: ID!) {
      tender(id: $id) {
        id
        isVatIncluded
        lotNumber
        bidsEndDatetime
        loadingSettlement
        department
        course
        unloadingPlace
        activeDateFrom
        activeDateTo
        cargoWeightFrom
        cargoWeightTo
        ordersQuantity
        maxOrderPrice
        winnerPrice
        displayedStatus
        lastBidPrice
        vehicleRequirements {
          bodyType
          liftingCapacity
          volume
          length
          width
          height
          mustHaveManipulator
        }
        limitRate
        isTn
        stepPrice
        lastBid {
          id
          price
        }
      }
    }
  `,

  getTenderReports: gql`
    query tenderReports(
      $first: Int
      $offset: Int
      $loadingSettlement: String
      $startDateFrom: Date
      $startDateTo: Date
      $sortBy: [String]
      $totalCount: Boolean = false
    ) {
      tenderReports(
        first: $first,
        offset: $offset,
        loadingSettlement: $loadingSettlement,
        startDateFrom: $startDateFrom,
        startDateTo: $startDateTo,
        sortBy: $sortBy,
      ) {
        id
        lotNumber
        loadingSettlement
        department
        course
        ordersQuantity
        displayedStatus
        ordersAssignedQuantity
        ordersInWorkQuantity
        ordersCompletedQuantity
        ordersCanceledQuantity
        ordersCanceledPercentage
      }
      tenderReportsCount: tenderReportsCount(
        loadingSettlement: $loadingSettlement,
        startDateFrom: $startDateFrom,
        startDateTo: $startDateTo,
        sortBy: $sortBy,
      ) @include(if: $totalCount)
    }
  `,

  getTenderReport: gql`
    query tenderReport(
      $id: ID!
    ) {
      tenderReport(
        id: $id
      ) {
        isTn
        orders {
          id
          externalNo
          status
          deliveryRequestDateUtc
          createdDatetime
          routeSheet {
            isDriverOnline
          }
          signingWay
          statusOnEvent
        }
      }
    }
  `,
  getCarrierContractsWithCargoOwner: gql`
    query carrierContractsWithCargoOwner(
      $first: Int
      $offset: Int
      $id: ID
      $name: String
      $cargoOwnerUserId: ID!
      $sortBy: [String]
    ) {
      carrierContractsWithCargoOwner(
        first: $first
        offset: $offset
        id: $id
        name: $name
        cargoOwnerUserId: $cargoOwnerUserId
        sortBy: $sortBy
      ) {
        id
        cargoOwner {
          name
        }
        name
        contractNumber
        contractType
        agentType
        isActive
      }
    }
  `,

  getReports: gql`
    query auctionControlReports ($first: Int, $offset: Int){
      auctionControlReports (first: $first, offset: $offset)
      {
        id
        queueName
        routeName
        productionDivisionName
        endDate
        auctionWonCount
        auctionCanceledCount
        cancelPercent
      }
      auctionControlReportsCount
    }
  `,

  getReportDetails: gql`
    query auctionControlReportDetails ($auctionControlReportId: ID!, $first: Int, $offset: Int) {
      auctionControlReportDetails (auctionControlReportId: $auctionControlReportId, first: $first, offset: $offset)
      {
        externalNo
        orderId
        createdDate
        assignedDate
        canceledDate
      }
      auctionControlReportDetailsCount (auctionControlReportId: $auctionControlReportId)
    }
  `,

  getCargoOwnerWithBusinessRelationshipWithCarrier: gql`
    query cargoOwnerWithBusinessRelationshipWithCarrier{
      cargoOwnerWithBusinessRelationshipWithCarrier {
          id
          name
          subdomain
          logo
      }
    }
  `,

  getOwnersDataWithBusinessRelationship: gql`
    query cargoOwnersDataWithBusinessRelationship{
      cargoOwnersDataWithBusinessRelationship {
          id
          name
          subdomain
          logo
      }
    }
  `,

  getAcreditationStatus: gql`
    query stateBusinessRelationship ($inn: String!){
      stateBusinessRelationship (inn: $inn)
    }
  `,

  getOwnerCompanyINN: gql`
  query getOwnerCompanyINN($subdomain: String!){
    cargoOwnerOrganization(subdomain: $subdomain){
      inn
    }
  }
  `,

  getOwnerCompanyBusinessRelationship: gql`
  query getOwnerCompanyBusinessRelationship($subdomain: String!){
    cargoOwnerOrganization(subdomain: $subdomain){
      carrierBusinessRelationshipState
    }
  }
  `,
};
