export class VehicleTypeColumnVm {
  public loadingTypes: string[] = [];

  public vehicleType = '';

  public setLoadingTypes(types: string | string[]) {
    types = types instanceof Array ? types : types.split(', ');
    this.loadingTypes = setLoadingTypesViewReady(types);
  }

  public setVehicleType(type: string) {
    this.vehicleType = VehicleTypeDict[type] ?? type;
  }
}

const VehicleTypeDict = {
  ['TRUCK']: 'Одиночное',
  ['TRAILER']: 'Фура',
  ['COUPLER']: 'Сцепка',
};

function setLoadingTypesViewReady(types: string[]) {
  const result: string[] = [];
  types.forEach(type => {
    let short = type;
    switch (type) {
      case 'Боковая':
        short = 'Бок';
        break;
      case 'Задняя':
        short = 'Задн';
        break;
      case 'Верхняя':
        short = 'Верх';
        break;
    }
    result.push(short);
  });
  return result;
}
