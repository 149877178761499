import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ITrackingMapObject } from '@transport/ui-interfaces';
import { TnToasterFacade } from '@transport/ui-store';
import { COUNTER } from '@transport/ui-utils';
import { tap } from 'rxjs/operators';

import { IRouteDataModel, IValueTextType } from '../../interfaces/yamaps.interfaces';
import { TnYamapsBalloonsService } from '../../services/balloons/yamaps-balloons.service';
import { TnYamapsControlsService } from '../../services/controls/yamaps-controls.service';
import { TnYamapsGeocodeService } from '../../services/geocode/yamaps-geocode.service';
import { TnYamapsLoaderService } from '../../services/loader/yamaps-loader.service';
import { TnYamapsUtilsService } from '../../services/utils/yamaps-utils.service';
import { YAMAPS_DEFAULT_MAP_CENTER, YAMAPS_TRACKING } from '../../yamaps.constants';
import { TnYamapBaseDirective } from './yamap-base';

@UntilDestroy()
@Component({
  selector: 'yamap-tracking-root',
  templateUrl: './yamap-tracking.component.html',
  styleUrls: ['./yamap-tracking.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- TODO: tech debt
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TnYamapTrackingComponent extends TnYamapBaseDirective implements OnInit, OnDestroy, OnChanges {
  /**  Declare start ponts of router */
  @Input() public points: ITrackingMapObject[] = [];

  @Input() public status = '';

  /**  Map zoom */
  @Input() public zoom: number = COUNTER.EIGHT;

  /**  Map center of map */
  @Input() public center: number[] | string = '';

  /**  Get coordinates by click mode */
  @Input() public getCoordinatesByClick = false;

  /** Set layer filter */
  private layerFilter = '';

  /**  Route data callback */
  @Output() public readonly routerDataChange = new EventEmitter();

  /**  Get coordinates by click mode callback */
  // eslint-disable-next-line @angular-eslint/no-output-native -- TODO: tech debt
  @Output() public readonly click: EventEmitter<MouseEvent> = new EventEmitter<MouseEvent>();

  /**  tracking hover */
  @Output() public readonly trackingHover = new EventEmitter();

  /**  tracking click */
  @Output() public readonly trackingClick = new EventEmitter();

  /**  Declare routeDataModel */
  private routeDataModel: IRouteDataModel[] = [];

  /**
   * Constructor.
   * Gets map id when called.
   */
  constructor(
    public control: TnYamapsControlsService,
    protected readonly yamapsLoader: TnYamapsLoaderService,
    protected readonly utils: TnYamapsUtilsService,
    private readonly balloonsService: TnYamapsBalloonsService,
    private readonly geocode: TnYamapsGeocodeService,
    private readonly toastFacade: TnToasterFacade,
  ) {
    super(yamapsLoader, utils);
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (Boolean(changes.points)) {
      this.update();
    }
  }

  /**
   * public search method for yandex map.
   * @param val  Search string request.
   * @param callback  Callback function
   */
  public geoSearch(val: string, callback) {
    this.geocode.geoSearch(val, callback);
  }

  /**
   * Automatic map zoom so that the whole route is visible
   * @param data bounded points
   */
  private boundedBy(data) {
    if (!this.control.routeEditMode && this.utils.checkContainer(this.mapId)) {
      this.map?.setBounds(data);
    }
  }

  public geoObjectSplice(...args) {
    this.map?.geoObjects.splice(...args);
  }

  public buildTrackingPoints() {
    this.points.forEach(point => {
      switch (point.type) {
        case YAMAPS_TRACKING.placeType.driver:
        case YAMAPS_TRACKING.placeType.past:
        case YAMAPS_TRACKING.placeType.future:
        case YAMAPS_TRACKING.placeType.delay:
          this.buidPointForTracking(point);
          break;
        default:
          break;
      }
    });
  }

  public buidPointForTracking(point: ITrackingMapObject) {
    const obj: { events } = this.balloonsService.getBalloon({
      type: 'simple',
      coordinates: point.coordinates,
      preset: point.icon,
      hintContent: point.hintContent,
      iconContent: point.iconContent,
    });
    this.map?.geoObjects.add(obj);
    obj.events
      .add('mouseenter', e => {
        e.get('target').options.set('preset', point.iconHover);
        this.trackingHover.emit({
          event: 'mouseenter',
          data: {
            id: point.id,
          },
        });
      })
      .add('mouseleave', e => {
        e.get('target').options.set('preset', point.icon);
        this.trackingHover.emit({
          event: 'mouseleave',
          data: {
            id: point.id,
          },
        });
      })
      .add('click', () => {
        this.trackingClick.emit({
          event: 'click',
          data: {
            id: point.id,
          },
        });
      });
  }

  /**
   * mount map.
   */
  protected mount(): void {
    if (this.center === 'home') {
      this.center = this.utils.defaultCity;
    }
    void this.geocode
      .getMapCenter(this.center, this.points as unknown as number[][])
      .pipe(
        tap(mapCenter => {
          this.choiceMode(mapCenter);
        }),
        untilDestroyed(this),
      )
      .subscribe();
  }

  /**
   * choice Router or Simple mode.
   */
  private choiceMode(mapCenter) {
    this.yamapsColorizedInit();
    this.trackingMapInit(mapCenter);
  }

  private update() {
    if (this.points.length > 0) {
      this.buildTracking();
      this.buildTrackingPoints();
      this.redraw();
    }
  }

  private trackingMapInit(mapCenter?): void {
    this.map = new this.ymaps.Map(this.mapId, {
      center: Boolean(mapCenter) ? mapCenter : YAMAPS_DEFAULT_MAP_CENTER,
      zoom: this.zoom,
      controls: this.setControls(),
    });
    this.update();
  }

  private getMultiRoute(points) {
    return new this.ymaps.multiRouter.MultiRoute(
      {
        referencePoints: points,
        params: {
          results: 1,
        },
      },
      {
        wayPointVisible: false,
        viaPointVisible: false,
        pinVisible: false,
      },
    );
  }

  private buildTracking() {
    let counter = 0;
    let breaker = 0;
    const points = this.points
      .filter(p => p.type !== 'delay')
      .map(e => {
        if (this.status === YAMAPS_TRACKING.mapStatus.drive) {
          if (e.type === YAMAPS_TRACKING.placeType.driver) {
            breaker = counter;
            return e.coordinates;
          }
          counter += 1;
        }
        if (e.type !== YAMAPS_TRACKING.placeType.driver) {
          return e.coordinates;
        }
        return null;
      })
      .filter(item => item !== null);
    if (this.status === YAMAPS_TRACKING.mapStatus.finish) {
      const q = 999;
      breaker = q;
    }
    localStorage.setItem('breaker', breaker.toString());
    const multiRoute = this.getMultiRoute(points);
    this.ymaps.modules.require(['MultiRouteColorizer'], multiRouteColorizer => {
      const colorized = new multiRouteColorizer(multiRoute);
      /**
       * @note
       * The variable is returned so that @typescript-eslint/no-unused-vars is not triggered.
       * Returning it is not really needed for workability.
       */
      return colorized;
    });

    multiRoute.model.events
      .add('requestsuccess', this.routerRequestSuccess.bind(this))
      .add('requestfail', this.routerRequestFail.bind(this));
    if (this.status === YAMAPS_TRACKING.mapStatus.start) {
      this.layerFilter = 'grayscale';
    }
    if (typeof this.map !== 'undefined') {
      if (['grayscale', 'invert', 'sepia'].includes(this.layerFilter)) {
        this.map.panes.get('ground').getElement().style.filter = `${this.layerFilter}(100%)`;
      }
      this.map.geoObjects.add(multiRoute);
    }
  }

  /**
   * Success method of routing request.
   * @param event retuned values of router.
   */
  private routerRequestSuccess(event): void {
    const target: unknown[] = [];
    this.routeDataModel = [];
    const points = event.get('target').getJson();
    const routes = event.get('target').getRoutes();
    if (Boolean(routes[0]) && this.utils.checkContainer(this.mapId)) {
      if (this.restrict) {
        this.map?.options.set('restrictMapArea', true);
        document.getElementById(String(this.mapId))?.previousElementSibling?.classList.add('on');
      }
      this.boundedBy(routes[0].properties.get('boundedBy'));
    }
    routes.forEach(item => {
      this.routeDataModel.push({
        type: item.properties.get('rawProperties').RouteMetaData.type as string,
        boundedBy: item.properties.get('rawProperties').boundedBy as any[],
        distance: item.properties.get('rawProperties').RouteMetaData.Distance as IValueTextType,
        duration: item.properties.get('rawProperties').RouteMetaData.Duration as IValueTextType,
        durationInTraffic: item.properties.get('rawProperties').RouteMetaData.DurationInTraffic as IValueTextType,
      });
    });
    points.properties.waypoints.forEach(items => {
      target.push(items);
    });
    this.routerDataChange.emit([this.routeDataModel, target]);
  }

  /**
   * Fail method of routing request.
   * @param event error.
   */
  private routerRequestFail(event): void {
    this.routerDataChange.emit([[]]);
    this.toastFacade.showMessage('shared.errors.yamapCommonError');
  }

  /**
   * TODO:
   * - this method should be refactored and broken down into smaller methods.
   */
  // eslint-disable-next-line max-lines-per-function -- TODO: tech debt
  private yamapsColorizedInit() {
    if (!this.ymaps.modules.isDefined('MultiRouteColorizer')) {
      this.ymaps.modules.define(
        'MultiRouteColorizer',
        ['util.defineClass'],
        // eslint-disable-next-line max-lines-per-function -- TODO: tech debt
        function (provide, defineClass) {
          function Colorizer(this: any, multiRoute) {
            this.multiRoute = multiRoute;
            multiRoute.events
              .add('update', this.onMultiRouteUpdate, this)
              .add('activeroutechange', this.onActiveRouteChange, this);

            this.activeRoute = multiRoute.getActiveRoute();
            this.colorize();
          }
          Colorizer.busPreset = {
            strokeWidth: 3,
            strokeColor: '#FF0000',
            strokeStyle: 'solid',
          };

          Colorizer.walkPreset = {
            strokeWidth: 3,
            strokeColor: '#008000',
            strokeStyle: 'solid',
          };

          defineClass(Colorizer, {
            onActiveRouteChange: function () {
              this.uncolorize();
              this.activeRoute = this.multiRoute.getActiveRoute();
              this.colorize();

              if (Boolean(this.activeRoute)) {
                this.multiRoute.getMap()?.setBounds(this.activeRoute.getBounds());
              }
            },

            onMultiRouteUpdate: function () {
              this.colorize();
            },

            colorize: function () {
              if (Boolean(this.activeRoute)) {
                let counter = 0;
                this.activeRoute.getPaths().each(path => {
                  path.getSegments().each(segment => {
                    if (counter < Number(localStorage.getItem('breaker'))) {
                      segment.options.set({ preset: Colorizer.walkPreset });
                    } else {
                      segment.options.set({ preset: Colorizer.busPreset });
                    }
                  }, this);
                  counter += 1;
                }, this);
              }
            },

            uncolorize: function () {
              if (Boolean(this.activeRoute)) {
                this.activeRoute.getPaths().each(path => {
                  path.getSegments().each(segment => {
                    segment.options.unset('preset');
                  }, this);
                }, this);
              }
            },

            destroy: function () {
              this.uncolorize();
              this.multiRoute.events
                .remove('update', this.onMultiRouteUpdate, this)
                .remove('activeroutechange', this.onActiveRouteChange, this);
            },
          });

          provide(Colorizer);
        },
      );
    }
  }
}
