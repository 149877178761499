import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { TnSettingsService } from '@transport/ui-services';
import { map, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TnModuleGuard implements CanActivate {
  constructor(private readonly settingsService: TnSettingsService, private readonly router: Router) {}

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    return this.settingsService.getModules().pipe(
      map(() => {
        if (this.settingsService.availableModules?.some(module => state.url.includes(module))) {
          return true;
        }
        void this.router.navigate([`/orders/owner`]);
        return false;
      }),
    );
  }
}
