<transport-tw-input-field
  [class]="inputFieldClass"
  [labelClasses]="labelClasses"
  [control]="$any(control)"
  [size]="size"
  [errorsSize]="errorsSize"
  [label]="label"
  [labelAddition]="labelAddition"
  [caption]="caption"
  [captionClasses]="captionClasses"
  [showErrors]="showErrors"
  [required]="required"
  [errorToMessage]="errorToMessage"
>
  <transport-tw-input class="w-full" [control]="$any(control)" [size]="size">
		<input matInput type="text" [placeholder]="placeholder" #text aria-label="Number" [formControl]="control" matInput [matAutocomplete]="auto">
		<mat-autocomplete
			#auto="matAutocomplete"
			[displayWith]="displayWith"
			[class]="'common-autocomplete-panel ' + size"
			showPanel="true"
			(optionsScroll)="onScroll()"
		>
			<mat-option
				*ngFor="let option of filteredLookups$ | async"
				[value]="option"
				[ngClass]="hideOptionCondition(option) ? '!hidden ' + optionClasses : optionClasses"
			>
				<ng-template
					*ngIf="customOptionTemplate; else defaultOptionTemplate"
					[ngTemplateOutlet]="template"
					[ngTemplateOutletContext]="{option: option}"
				></ng-template>
				<ng-template #defaultOptionTemplate>
					<div class="w-full">
						<span>{{option.name}}</span>
					</div>
				</ng-template>
			</mat-option>
		</mat-autocomplete>
	</transport-tw-input>
</transport-tw-input-field>