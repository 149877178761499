import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

export type TTransportButtonType =
  | ''
  | 'confirm'
  | 'action-confirm-flat'
  | 'action'
  | 'action-flat'
  | 'action-secondary-flat'
  | 'action-confirm'
  | 'cancel'
  | 'outline'
  | 'action-input'
  | 'filter';

// TODO: https://technonikol.atlassian.net/browse/T20S-5971
@Component({
  selector: 'transport-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnButtonComponent {
  @Input() public type: TTransportButtonType = '';

  @Input() public label = '';

  @Input() public icon = '';

  @Input() public matIcon = '';

  @Input() public iconPosition: 'left' | 'right' = 'right';

  @Input() public size: 'xs' | 'sm' | 'md' | 'lg' | string = '';

  @Input() public backColor: 'blue-500' | string = '';

  @Input() public textColor: 'blue-500' | string = '';

  @Input() public counter: number | null = 0;

  @Input() public disabled = false;

  @Input() public isSmallScreenEnabled = true;

  @Input() public hasFullWidth = false;

  @Input() public dataTest = '';

  @Output() public readonly action = new EventEmitter<Event>();

  public onClick(event: Event) {
    this.action.emit(event);
  }

  public getClass() {
    const classes = [
      this.type,
      this.size,
      Boolean(this.backColor) ? 'bg-' + this.backColor : '',
      Boolean(this.textColor) ? 'text-' + this.textColor : '',
    ];
    return classes.filter(el => Boolean(el)).join(' ');
  }
}
