import { TFileAdditionalAttributes } from './file-additional-attributes';
import { IFileType, TnUploadedFile } from './uploaded-file';

export interface IFileUploadDialogFormValue {
  files: TnUploadedFile[];
  fileType?: IFileType;
  additionalAttributes?: TFileAdditionalAttributes | null;
}

export interface IFileUploadDialogLabels {
  title: string;
  cancelBtn: string;
  saveBtn: string;
  fileTypeSelect: string;
}
