import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HTTP_ERROR_CODE } from '@transport/ui-interfaces';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { TnNgrxErrorFacade } from './facade/ngrx-error.facade';

/**
 * NgRx error interceptor service.
 */
@Injectable({
  providedIn: 'root',
})
export class TnNgrxErrorInterceptorService implements HttpInterceptor {
  /**
   * Constructor.
   * @param facade NgRx error facade.
   */
  constructor(private readonly facade: TnNgrxErrorFacade) {}

  /**
   * Interceptor main `intercept` method.
   * @param request http request
   * @param next http handler
   */
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          switch (error.status) {
            // Обработка ошибки вырезана из-за tn-8361812 по общему решению техлидов
            // case HTTP_ERROR_CODE.UNAUTHORIZED:
            //   this.facade.throw401Error(error);
            //   break;
            case HTTP_ERROR_CODE.NOT_FOUND:
              this.facade.throw404Error(error);
              break;
            default:
              break;
          }
        }
        return throwError(error);
      }),
    );
  }
}
