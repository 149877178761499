<mat-form-field class="tw-off">
  <mat-select [(ngModel)]="selectedValue" (selectionChange)="onChangeSelect($event)" panelClass="mat-group-panel">
    <mat-option value="carrierAllAccredited">
      {{ 'owner.order.publishTypeNew.carrierAllAccredited' | translate }}
    </mat-option>
    <mat-optgroup label="Группы" transportTooltip [isGlobalMode]="true">
      <mat-option *ngFor="let item of data" [value]="item.id">
        <div [attr.tn-tip]="true" class="overflow-hidden overflow-ellipsis">
          {{item.name}}
        </div>
      </mat-option>
      <mat-option class="empty" disabled *ngIf="data?.length === 0">
        {{ 'owner.order.publishTypeNew.noOptions' | translate }}
      </mat-option>
    </mat-optgroup>
  </mat-select>
</mat-form-field>
