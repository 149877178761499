import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { adminProfileActions } from '../admin/admin-profile.actions';
import { TnAdminProfileApiService } from '../admin/services/admin-profile-api.service';
import { subOrganizationProfileActions } from '../sub-organization/sub-organization-profile.actions';
import { adminProfileSharedActions } from './admin-profile-shared.actions';

@Injectable({
  providedIn: 'root',
})
export class TnAdminProfileSharedEffects {
  constructor(private readonly adminProfileService: TnAdminProfileApiService, private readonly action$: Actions) {}

  public loadProfileDefaultInsuranceContract = createEffect(() =>
    this.action$.pipe(
      ofType(adminProfileActions.loadAdminProfileStart, subOrganizationProfileActions.loadSubOrganizationProfileStart),
      switchMap(() =>
        this.adminProfileService.getDefaultInsurance().pipe(
          map(contract => {
            return adminProfileSharedActions.defaultInsuranceSuccess({
              contract,
            });
          }),
          catchError(error => of(adminProfileSharedActions.defaultInsuranceFailure({ error }))),
        ),
      ),
    ),
  );

  public loadProfileLegalForms = createEffect(() =>
    this.action$.pipe(
      ofType(adminProfileActions.loadAdminProfileStart, subOrganizationProfileActions.loadSubOrganizationProfileStart),
      switchMap(() =>
        this.adminProfileService.getLegalForms().pipe(
          map(result => {
            return adminProfileSharedActions.loadLegalFormsSuccess({
              legalForms: result,
            });
          }),
          catchError(error => of(adminProfileSharedActions.loadLegalFormsFailure({ error }))),
        ),
      ),
    ),
  );

  public loadProfileVatTypes = createEffect(() =>
    this.action$.pipe(
      ofType(adminProfileActions.loadAdminProfileStart, subOrganizationProfileActions.loadSubOrganizationProfileStart),
      switchMap(() =>
        this.adminProfileService.getVatTypes().pipe(
          map(result =>
            adminProfileSharedActions.loadVatTypesSuccess({
              vatTypes: result,
            }),
          ),
          catchError(error => of(adminProfileSharedActions.loadVatTypesFailure({ error }))),
        ),
      ),
    ),
  );

  public loadProfilePaymentTypes = createEffect(() =>
    this.action$.pipe(
      ofType(adminProfileActions.loadAdminProfileStart, subOrganizationProfileActions.loadSubOrganizationProfileStart),
      switchMap(() =>
        this.adminProfileService.getPaymentTypes().pipe(
          map(result => {
            return adminProfileSharedActions.loadPaymentTypesSuccess({
              paymentTypes: result,
            });
          }),
          catchError(error => of(adminProfileSharedActions.loadPaymentTypesFailure({ error }))),
        ),
      ),
    ),
  );
}
