import { gql } from 'apollo-angular';
export namespace IGOwner {
  export type Maybe<T> = T | null | undefined;
  export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
  export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
  export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
  /** All built-in and custom scalars, mapped to their actual values */
  export type Scalars = {
    ID: string;
    String: string;
    Boolean: boolean;
    Int: number;
    Float: number;
    /**
     * The `Date` scalar type represents a Date
     * value as specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    Date: string;
    /**
     * The `DateTime` scalar type represents a DateTime
     * value as specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    DateTime: string;
    /** The `Decimal` scalar type represents a python Decimal. */
    Decimal: string;
    /**
     * Allows use of a JSON String for input / output from the GraphQL schema.
     *
     * Use of this type is *not recommended* as you lose the benefits of having a defined, static
     * schema (one of the key benefits of GraphQL).
     */
    JSONString: string;
    ModelID: string;
    /**
     * The `Time` scalar type represents a Time value as
     * specified by
     * [iso8601](https://en.wikipedia.org/wiki/ISO_8601).
     */
    Time: string;
    /**
     * Create scalar that ignores normal serialization/deserialization, since
     * that will be handled by the multipart request spec
     */
    Upload: string;
  };

  export enum IAccreditationStatusEnum {
    New = 'new',
    Pending = 'pending',
    Passed = 'passed',
    NotPassed = 'not_passed',
  }

  export interface IAddAuctionOrderInput {
    order?: Maybe<IAddOrderInput>;
    lot?: Maybe<ILotInput>;
    /** Признак рыночной заявки */
    isMarket?: Maybe<Scalars['Boolean']>;
  }

  export interface IAddCargoOwnerRiskProfileDossier {
    /** Идентификатор созданного документа досье */
    dossierId?: Maybe<Scalars['String']>;
  }

  export interface IAddCarrierGroupInput {
    /** Название группы организаций-перевозчиков. Длина (max): 150 */
    name: Scalars['String'];
    /** Список ID организаций-перевозчиков в группе */
    carriersIds: Array<Maybe<Scalars['ID']>>;
  }

  /** Информация о новом сотруднике организации */
  export interface IAddEmployeeInput {
    /** Имя. Длина (max): 30 */
    firstName: Scalars['String'];
    /** Фамилия. Длина (max): 150 */
    lastName: Scalars['String'];
    /** Отчество. Длина (max): 150 */
    patronymic?: Maybe<Scalars['String']>;
    /** Email. Длина (max): 254 */
    email: Scalars['String'];
    /** Номер телефона. Длина (max): 25 */
    phoneNumber?: Maybe<Scalars['String']>;
    /** Список из ID прав доступа, назначаемых сотруднику */
    permissions: Array<Maybe<Scalars['ID']>>;
    /** Настройки UI */
    uiSettings?: Maybe<Scalars['JSONString']>;
  }

  export interface IAddInsuranceContractInput {
    /** Код страховой компании */
    insuranceCompanyCodename?: Maybe<IInsurance_Company_Choices>;
    /** Номер генерального договора */
    generalNumber: Scalars['String'];
    /** Тариф */
    tariff: Scalars['Float'];
    /** Признак того, что договор является договором по умолчанию */
    isDefault: Scalars['Boolean'];
    /** Дата начала действия договора */
    startDatetime: Scalars['DateTime'];
    /** Дата окончания действия договора */
    endDatetime?: Maybe<Scalars['DateTime']>;
    /** Данные для страхования */
    parameters: Scalars['JSONString'];
  }

  export interface IAddInsuranceInput {
    /** ID договора с СК */
    insuranceContract: Scalars['ID'];
    /** Данные для страхования */
    insuranceData: Scalars['JSONString'];
  }

  export interface IAddOrderInput {
    /** ID уровня обслуживания */
    sla?: Maybe<Scalars['ID']>;
    /** ID места погрузки */
    loadingPlace?: Maybe<Scalars['ID']>;
    /** ID места доставки */
    unloadingPlace?: Maybe<Scalars['ID']>;
    /** Дата погрузки */
    loadingDate?: Maybe<Scalars['Date']>;
    /** Планируемая дата доставки */
    unloadingDate?: Maybe<Scalars['Date']>;
    /** ID типа груза */
    cargoType?: Maybe<Scalars['ID']>;
    /** Вес груза */
    weight?: Maybe<Scalars['Float']>;
    /** Объем груза */
    volume?: Maybe<Scalars['Float']>;
    /** Комментарий к заявке. Длина (max): 500 */
    comment?: Maybe<Scalars['String']>;
    /** Количество грузовых мест */
    cargoPackagesCount?: Maybe<Scalars['Int']>;
    /** Стоимость груза */
    price?: Maybe<IPriceInput>;
    /** Информация о грузоперевозчике */
    carrier?: Maybe<Scalars['ID']>;
    /** Информация о грузоперевозчике */
    carrierOrganization?: Maybe<Scalars['ID']>;
    /** Список мест погрузки с указанием времени и контактного лица */
    loadingPlaces?: Maybe<Array<Maybe<IOrderStoragePointInput>>>;
    /** Список мест доставки с указанием планируемого времени и контактного лица */
    unloadingPlaces?: Maybe<Array<Maybe<IOrderStoragePointInput>>>;
    /** Цена включает НДС */
    isVatIncluded?: Maybe<Scalars['Boolean']>;
    /** Требования к ТС, указанные в заявке */
    vehicleRequirements?: Maybe<IOrderVehicleRequirementsInput>;
    /** Требования к грузовому месту, указанные в заявке */
    cargoPlaceRequirements?: Maybe<ICargoPlaceRequirementsInput>;
    /** Данные для страхования */
    insurance?: Maybe<IAddInsuranceInput>;
    /** ID круга перевозчика, для которого будет доступна заявка */
    carrierGroupId?: Maybe<Scalars['ID']>;
    /** Все аккредитованные перевозчики */
    carrierAllAccredited?: Maybe<Scalars['Boolean']>;
    /** ID черновика, на базе которого создаётся заявке */
    draftId?: Maybe<Scalars['ID']>;
    /** Номер заявки во внешней системе. Длина (max): 36 */
    externalNo?: Maybe<Scalars['String']>;
    /** ID типа оплаты, который привязан к заявке */
    paymentTypeId?: Maybe<Scalars['ID']>;
    /** Срок оплаты. Длина (max): 5000 */
    paymentPeriod?: Maybe<Scalars['String']>;
    /** Список ID дополнительных услуг */
    extraServices?: Maybe<Array<Maybe<Scalars['ModelID']>>>;
    /** Копия заявки */
    copyOfOrderId?: Maybe<Scalars['Int']>;
    /** Организация, указанная как заказчик перевозки */
    customerOrganization?: Maybe<Scalars['ID']>;
    /** Температурный режим (градусы Цельсия) */
    temperatureMode?: Maybe<Scalars['Int']>;
    /** ID тендера, к которому относится заявка */
    tenderId?: Maybe<Scalars['ID']>;
    /** Дедлайн для того чтобы забрать свободную заявку или сделать ставку для аукционной заявки. */
    acceptDeadline?: Maybe<Scalars['DateTime']>;
    /** Признак размещения заявки на рынке */
    isMarket?: Maybe<Scalars['Boolean']>;
    /** Данные по грузам */
    cargos?: Maybe<Array<Maybe<ICargoInput>>>;
    /** Данные по местам разгрузки */
    deliveryUnits?: Maybe<Array<Maybe<IDeliveryUnitInput>>>;
  }

  /** Ответ на добавление заявки */
  export interface IAddOrderOutputType {
    /** Информация о заявке */
    order?: Maybe<IOrderType>;
  }

  export interface IAddOrderUploadedDocumentInput {
    /** Загруженные файлы */
    uploadedFileIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Тип документа */
    documentTypeId: Scalars['ModelID'];
    /** Загруженный файл */
    uploadedFileId?: Maybe<Scalars['ModelID']>;
    /** Подписываемый файл */
    signedFileId?: Maybe<Scalars['ModelID']>;
  }

  export interface IAddOrganizationInput {
    /** Название организации. Длина (max): 300 */
    name: Scalars['String'];
    /** Подписывающее лицо (Фамилия И. О.). Длина (max): 150 */
    signer?: Maybe<Scalars['String']>;
    /** Описание организации. Длина (max): 300 */
    about?: Maybe<Scalars['String']>;
    /** Телефон организации. Длина (max): 150 */
    phoneNumber?: Maybe<Scalars['String']>;
    /** Электронная почта. Длина (max): 254 */
    email?: Maybe<Scalars['String']>;
    /** КПП. Длина (max): 9 */
    kpp?: Maybe<Scalars['String']>;
    /** Юридический адрес. Длина (max): 300 */
    legalAddress?: Maybe<Scalars['String']>;
    /** Почтовый адрес. Длина (max): 300 */
    postalAddress?: Maybe<Scalars['String']>;
    /** ОГРН/ОГРНИП. Длина (max): 15 */
    registrationNumber?: Maybe<Scalars['String']>;
    /** Идентификатор типа НДС */
    vat?: Maybe<Scalars['ID']>;
    /** Тип оплаты */
    paymentTypeId?: Maybe<Scalars['ID']>;
    /** Сроки оплаты. Длина (max): 5000 */
    paymentPeriod?: Maybe<Scalars['String']>;
    /** Общие параметры аукционов */
    auctionParameters?: Maybe<IAuctionParametersInput>;
    /** Параметр, указывающий на необходимость страховать грузы по всем заявкам организации */
    insuranceParameters?: Maybe<IInsuranceParametersInput>;
    showOrderExternalNo?: Maybe<Scalars['Boolean']>;
    extraConditionsForMarket?: Maybe<Scalars['String']>;
    /** Включить возможность отслеживания доставки по заявке */
    enableDeliveryTracking?: Maybe<Scalars['Boolean']>;
    /** Параметр, отвечающий за поддомен организации */
    subdomain?: Maybe<Scalars['String']>;
    /** Параметр, отвечающий за URL логотипа организации */
    logo?: Maybe<Scalars['Upload']>;
    /** Настройки документооборота */
    documentFlowSettings?: Maybe<Scalars['String']>;
    legalForm?: Maybe<Scalars['ID']>;
    /** ИНН */
    inn?: Maybe<Scalars['String']>;
  }

  export interface IAddPriceQuotationInput {
    /** Требования к ТС, указанные в запросе цены */
    vehicleRequirements: IPriceQuotationVehicleRequirementsInput;
    /** Откуда. Длина (max): 500 */
    loadingPlace: Scalars['String'];
    /** Код населенного пункта отправления (ФИАС). Длина (max): 100 */
    loadingPlaceFiasCode: Scalars['String'];
    /** Куда. Длина (max): 500 */
    unloadingPlace: Scalars['String'];
    /** Код населенного пункта назначения (ФИАС). Длина (max): 100 */
    unloadingPlaceFiasCode: Scalars['String'];
    /** Дата и время окончания внесения предложений */
    endDateTime: Scalars['DateTime'];
    /** Круг перевозчиков, для которого доступен запрос цены */
    carrierGroupId?: Maybe<Scalars['ModelID']>;
    /** Шаг цены (кратность) */
    priceStep?: Maybe<Scalars['Int']>;
    /** Комментарий к запросу цены. Длина (max): не ограничена */
    comment?: Maybe<Scalars['String']>;
  }

  export interface IAddTenderInput {
    /** Требования к ТС для заявок тендера */
    vehicleRequirements?: Maybe<ITenderVehicleRequirementsInput>;
    /** Город погрузки. Длина (max): не ограничена */
    loadingSettlement: Scalars['String'];
    /** Город погрузки - ФИАС (до нас. пункта). Длина (max): 11 */
    loadingSettlementFias: Scalars['String'];
    /** Направление. Длина (max): не ограничена */
    course: Scalars['String'];
    /** Направление - ФИАС (до нас. пункта). Длина (max): 11 */
    courseFias: Scalars['String'];
    /** Количество перевозок */
    ordersQuantity: Scalars['Int'];
    /** Начало периода действия */
    activeDateFrom: Scalars['Date'];
    /** Конец периода действия */
    activeDateTo: Scalars['Date'];
    /** Максимальная цена за перевозку */
    maxOrderPrice: Scalars['Int'];
    /** Цена включает НДС */
    isVatIncluded: Scalars['Boolean'];
    /** Шаг цены */
    stepPrice: Scalars['Int'];
    /** Дата и время окончания принятия ставок */
    bidsEndDatetime: Scalars['DateTime'];
    /** Группа перевозчиков */
    carrierGroupId?: Maybe<Scalars['ModelID']>;
    /** Комментарий к тендеру. Длина (max): 500 */
    comment?: Maybe<Scalars['String']>;
  }

  export interface IAgentCarrierInfoType {
    inn?: Maybe<Scalars['String']>;
    kpp?: Maybe<Scalars['String']>;
    name?: Maybe<Scalars['String']>;
    phone?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
  }

  /** Класс для выдачи сумм распределения фрахта и агентского вознаграждения из суммы заявки */
  export interface IAgentFeeAndFreightDetails {
    /** Сумма ГП ТЭКа: величина фрахта, включая НДС */
    agentCarrierFreightIncludingVat: Scalars['Decimal'];
    /** Сумма ГП ТЭКа: ставка НДС */
    agentCarrierVatRate: Scalars['String'];
    /** Сумма ГП ТЭКа: величина НДС для фрахта */
    agentCarrierFreightVatAmount: Scalars['Decimal'];
    /** Сумма ТЭКа: НДС за контрагента (за ГП) */
    agentVatAmountPerAgentCarrier: Scalars['Decimal'];
    /** Сумма ТЭКа: агентское вознаграждение, включая НДС */
    agentFeeIncludingVat: Scalars['Decimal'];
    /** Сумма ТЭКа: ставка НДС */
    agentFeeVatRate: Scalars['String'];
    /** Сумма ТЭКа: величина НДС для агентского вознаграждения */
    agentFeeVatAmount: Scalars['Decimal'];
    /** Итого */
    totalPrice: Scalars['Decimal'];
  }

  /** Перечислитель типов размещения заявок */
  export enum IAllocationTypeEnum {
    /** Все заявки */
    All = 'ALL',
    /** Простые заявки */
    Simple = 'SIMPLE',
    /** Аукционные заявки */
    Auction = 'AUCTION',
    /** Торги на повышение */
    Bidding = 'BIDDING',
  }

  export interface IAuctionParametersInput {
    /** Длительность аукциона, указанная для текущей организации, в часах */
    duration?: Maybe<Scalars['Int']>;
    /** Тип шага цены */
    typeOfPriceStep?: Maybe<ITypeOfPriceStepEnum>;
    /** Дострочное завершение торгов */
    earlyFinish: Maybe<Scalars['Boolean']>;
    /** Минимальный шаг торгов указанный для текущей организации */
    minStepPrice?: Maybe<Scalars['Int']>;
    /** Процент от начальной цены для расчета минимального шага торгов, % */
    minPriceStepPercent?: Maybe<Scalars['Float']>;
    /** Переторжка для аукционов включена */
    isProlongationAvailable?: Maybe<Scalars['Boolean']>;
    /** Время в минутах, на которое продлевается аукцион */
    minutesOfProlongation?: Maybe<Scalars['Int']>;
    /** Сколько раз можно продлевать аукцион */
    timesOfProlongation?: Maybe<Scalars['Int']>;
    /** Время в минутах до конца аукциона, в которое может быть начата переторжка */
    minutesTillEndToProlong?: Maybe<Scalars['Int']>;
  }

  export interface IAuctionParametersType {
    /** Длительность аукциона, указанная для текущей организации, в минутах */
    duration: Scalars['Int'];
    /** Дострочное завершение торгов */
    earlyFinish: Scalars['Boolean'];
    /** Тип шага цены */
    typeOfPriceStep: IAuctionParametersTypeOfPriceStep;
    /** Минимальный шаг торгов указанный для текущей организации, используется в том случае, если выбран тип фиксированного шага */
    minStepPrice: Scalars['Int'];
    /** Процент от начальной цены для расчета минимального шага торгов, %, используется в том случае, если выбран тип процента от стоимости */
    minPriceStepPercent: Scalars['Float'];
    /** Переторжка для аукционов включена */
    isProlongationAvailable: Scalars['Boolean'];
    /** Время в минутах, на которое продлевается аукцион */
    minutesOfProlongation: Scalars['Int'];
    /** Сколько раз можно продлевать аукцион */
    timesOfProlongation: Scalars['Int'];
    /** Время в минутах до конца аукциона, в которое может быть начата переторжка */
    minutesTillEndToProlong: Scalars['Int'];
  }

  /** An enumeration. */
  export enum IAuctionParametersTypeOfPriceStep {
    /** Фиксированный шаг */
    Fixed = 'FIXED',
    /** Процент от начальной стоимости для расчета шага */
    Percent = 'PERCENT',
  }

  /** Enum доступных записей */
  export enum IAvailabilityEnum {
    /** Все записи */
    All = 'ALL',
    /** Активные записи */
    Active = 'ACTIVE',
    /** Архивные записи */
    Archived = 'ARCHIVED',
  }

  /** Баннер-уведомление */
  export interface IBannerNotificationType {
    /** Текст уведомления */
    text: Scalars['String'];
    /** Дата и время окончания отображения уведомления */
    expireAt?: Maybe<Scalars['DateTime']>;
    /** Уведомление активно */
    isActive: Scalars['Boolean'];
  }

  export interface IBidOnTenderType {
    id: Scalars['ID'];
    /** Организация-перевозчик */
    carrierOrganization: ICargoOwnerOrganizationType;
    /** Предложенная цена */
    price?: Maybe<Scalars['Int']>;
    /** Комментарий к ставке */
    comment?: Maybe<Scalars['String']>;
  }

  /** Перечислитель признака рыночной/партнерской заявки */
  export enum IBiddingTypeEnum {
    /** И рыночные и партнерские заявки */
    All = 'ALL',
    /** Только рыночные заявки */
    Market = 'MARKET',
    /** Только партнерские заявки */
    Partner = 'PARTNER',
  }

  export interface IBlackListDriverType {
    id: Scalars['ID'];
    /** Грузоотправитель, к которому относится ЧС */
    cargoOwner: ICargoOwnerOrganizationType;
    /** Дата и время вступление в силу ограничений */
    startDate: Scalars['DateTime'];
    /** Режим работы с объектом: False - предупреждение, True - блокировка */
    workProhibited: Scalars['Boolean'];
    /** Описание проблемы с объектом */
    description: Scalars['String'];
    /** Причина внесения объекта в чёрный список */
    reason: Scalars['String'];
  }

  export interface IBlackListVehicleTrailerType {
    id: Scalars['ID'];
    /** Грузоотправитель, к которому относится ЧС */
    cargoOwner: ICargoOwnerOrganizationType;
    /** Дата и время вступление в силу ограничений */
    startDate: Scalars['DateTime'];
    /** Режим работы с объектом: False - предупреждение, True - блокировка */
    workProhibited: Scalars['Boolean'];
    /** Описание проблемы с объектом */
    description: Scalars['String'];
    /** Причина внесения объекта в чёрный список */
    reason: Scalars['String'];
    /** Виды номенклатуры */
    nomenclatureType?: Maybe<Scalars['String']>;
  }

  export interface IBlackListVehicleType {
    id: Scalars['ID'];
    /** Грузоотправитель, к которому относится ЧС */
    cargoOwner: ICargoOwnerOrganizationType;
    /** Дата и время вступление в силу ограничений */
    startDate: Scalars['DateTime'];
    /** Режим работы с объектом: False - предупреждение, True - блокировка */
    workProhibited: Scalars['Boolean'];
    /** Описание проблемы с объектом */
    description: Scalars['String'];
    /** Причина внесения объекта в чёрный список */
    reason: Scalars['String'];
  }

  /** Информация о подтипе кузова транспортного средства */
  export interface IBodySubtypeType {
    id: Scalars['ID'];
    /** Наименование подтипа кузова ТС */
    name: Scalars['String'];
  }

  /** Тип кузова транспортного средства */
  export interface IBodyTypeType {
    id: Scalars['ID'];
    /** Наименование типа кузова ТС */
    name: Scalars['String'];
    /** Получение списка всех доступных подтипов кузова */
    subtypes?: Maybe<Array<Maybe<IBodySubtypeType>>>;
  }

  /** An enumeration. */
  export enum IBusinessRelationshipState {
    /** На аккредитации */
    OnAccreditation = 'ON_ACCREDITATION',
    /** Аккредитован */
    Accredited = 'ACCREDITED',
    /** Отказано в аккредитации */
    NotAccredited = 'NOT_ACCREDITED',
    /** Контракт подписан */
    ContractIsSigned = 'CONTRACT_IS_SIGNED',
  }

  export interface IBusinessRelationshipType {
    /** Состояние регистрации отношений */
    state: IBusinessRelationshipState;
    /** Дата последнего действия с деловым отношением */
    lastChangeDate?: Maybe<Scalars['String']>;
    /** Пользователь, который совершил последнее действие с деловым отношением */
    lastChangeUser?: Maybe<Scalars['String']>;
    /** Причина последнего изменения состояния деловых отношений */
    lastChangeReason?: Maybe<Scalars['String']>;
  }

  export interface ICargoInput {
    /** Уникальный номер груза в рамках заявки */
    id?: Maybe<Scalars['ID']>;
    /** Название груза */
    name?: Maybe<Scalars['String']>;
    /** Количественные характеристики груза */
    size?: Maybe<IDeliverySizeInput>;
    /** Порядковый номер погрузки, ссылка на seq_id в loading_places */
    loadingPlaceId?: Maybe<Scalars['ID']>;
  }

  /** GraphQL-мутаторы для грузоотправителя */
  export interface ICargoOwnerMutations {
    _debug?: Maybe<IDjangoDebug>;
    /** Добавление нового сотрудника организации */
    addEmployee?: Maybe<IEmployeeType>;
    /** Изменение данных сотрудника организации */
    editEmployee?: Maybe<IEmployeeType>;
    /** Отправка сотрудника организации в архив */
    sendEmployeeToArchive?: Maybe<IEmployeeType>;
    /** Сброс пароля сотрудника организации */
    resetEmployeePassword?: Maybe<IEmployeeType>;
    /** Добавить учредительный документ */
    addConstituentDocument?: Maybe<IConstituentDocumentType>;
    /** Удалить учредительный документ */
    removeConstituentDocument?: Maybe<IConstituentDocumentType>;
    /** Редактирование профиля пользователя */
    editProfile?: Maybe<IProfileType>;
    /** Изменение настроек профиля пользователя */
    editProfileSettings?: Maybe<IProfileType>;
    /**
     * Загрузить файл.
     * Мин. размер 1 б
     * Макс. размер 20 Мб
     * Поддерживаемые типы:
     * * application/msword
     * * application/pdf
     * * application/vnd.ms-excel
     * * application/vnd.oasis.opendocument.spreadsheet
     * * application/vnd.oasis.opendocument.text
     * * application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
     * * application/vnd.openxmlformats-officedocument.wordprocessingml.document
     * * image/jpeg
     * * image/png
     * * image/tiff
     * * image/x-tiff
     */
    uploadFile?: Maybe<IUploadedFileType>;
    /** Пометить уведомление как прочитанное */
    markNotificationAsRead?: Maybe<Scalars['Boolean']>;
    /** Пометить все уведомления пользователя как прочитанные */
    markAllNotificationsAsRead?: Maybe<Scalars['Boolean']>;
    /** Изменение деловых отношений */
    editBusinessRelationship?: Maybe<IBusinessRelationshipType>;
    /** Добавление нового типа груза */
    addCargoType?: Maybe<ICargoTypeType>;
    /** Изменение информации о типе груза */
    editCargoType?: Maybe<ICargoTypeType>;
    /** Перенос имеющегося типа груза в архив */
    sendCargoTypeToArchive?: Maybe<ICargoTypeType>;
    /** Добавление новой группы перевозчиков */
    addCarrierGroup?: Maybe<ICarrierGroupType>;
    /** Редактирование группы перевозчиков */
    editCarrierGroup?: Maybe<ICarrierGroupType>;
    /** Отправить в архив группу перевозчиков */
    sendToArchiveCarrierGroup?: Maybe<ICarrierGroupType>;
    /** Добавление договора с организацией-перевозчиком */
    addCarrierContract?: Maybe<ICarrierContractType>;
    /** Редактирование договора с организацией-перевозчиком */
    editCarrierContract?: Maybe<ICarrierContractType>;
    /** Перенос имеющегося договора с организацией-перевозчиком в архив */
    sendCarrierContractToArchive?: Maybe<ICarrierContractType>;
    /** Удаление черновика */
    deleteDraft?: Maybe<IDraftType>;
    /** Добавление нового договора страхования */
    addInsuranceContract?: Maybe<IInsuranceContractType>;
    /** Изменение информации в договоре страхования */
    editInsuranceContract?: Maybe<IInsuranceContractType>;
    /** Перенос имеющегося договора страхования в архив */
    sendInsuranceContractToArchive?: Maybe<IInsuranceContractType>;
    /** Добавление новой заявки */
    addOrder?: Maybe<IAddOrderOutputType>;
    /** Отмена уже заведённой заявки */
    cancelOrder?: Maybe<IOrderType>;
    /** Добавление новой аукционной заявки */
    addAuctionOrder?: Maybe<IOrderType>;
    /** Назначение организации-перевозчика на заявку */
    assignCarrier?: Maybe<IOrderType>;
    /** Выставить значение признака по отслеживанию заявки */
    setOrderTrackingRequired?: Maybe<IOrderType>;
    /** Отменяет возможность переторжки на аукционе. Действие необратимо. */
    disableAuctionProlongation?: Maybe<ILotType>;
    /** Редактирование загруженных документов в заявке */
    editOrderUploadedDocuments?: Maybe<Array<Maybe<IOrderUploadedDocumentType>>>;
    /** Отправить документ в ЭДО */
    sendDocumentToEdms?: Maybe<ISendDocumentToEdms>;
    /** Добавление новой организации */
    addOrganization?: Maybe<ICargoOwnerOrganizationType>;
    /** Редактирование организации */
    editOrganization?: Maybe<ICargoOwnerOrganizationType>;
    /** Архивация организации */
    sendOrganizationToArchive?: Maybe<ICargoOwnerOrganizationType>;
    /** Создать профиль аккредитации ГВ */
    createCargoOwnerRiskProfile?: Maybe<ICreateCargoOwnerRiskProfile>;
    /** Добавить досье для аккредитации ГВ */
    addCargoOwnerRiskProfileDossier?: Maybe<IAddCargoOwnerRiskProfileDossier>;
    /** Отправить профиль риска ГВ на проверку */
    submitCargoOwnerRiskProfile?: Maybe<ISubmitCargoOwnerRiskProfile>;
    /** Создание запроса цены */
    addPriceQuotation?: Maybe<IPriceQuotationType>;
    /** Отмена созданного запроса цены */
    sendPriceQuotationToArchive?: Maybe<IPriceQuotationType>;
    /** Добавление нового SLA */
    addSla?: Maybe<ISlaType>;
    /** Изменение SLA */
    editSla?: Maybe<ISlaType>;
    /** Удаление SLA */
    deleteSla?: Maybe<IDeleteSla>;
    /** Добавление нового места погрузки/разгрузки */
    addLoadingUnloadingPlace?: Maybe<IStoragePointType>;
    /** Изменение информации о месте погрузки/разгрузки */
    editLoadingUnloadingPlace?: Maybe<IStoragePointType>;
    /** Перенос имеющегося места погрузки/разгрузки в архив */
    sendLoadingUnloadingPlaceToArchive?: Maybe<IStoragePointType>;
    /** Отмена тендера */
    cancelTender?: Maybe<ITenderType>;
    /** Выбор выигравшей ставки (установка победителя тендера) */
    setTenderWinner?: Maybe<ITenderType>;
    /** Добавление нового тендера */
    addTender?: Maybe<ITenderType>;
    /** Добавление встречного предложения */
    addVehicleRequest?: Maybe<IVehicleRequestType>;
  }

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsAddEmployeeArgs = {
    input?: Maybe<IAddEmployeeInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsEditEmployeeArgs = {
    input?: Maybe<IEditEmployeeInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsSendEmployeeToArchiveArgs = {
    employeeId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsResetEmployeePasswordArgs = {
    employeeId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsAddConstituentDocumentArgs = {
    file: Scalars['Upload'];
    name: Scalars['String'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsRemoveConstituentDocumentArgs = {
    docId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsEditProfileArgs = {
    emailInput?: Maybe<IEditProfileEmailInput>;
    passwordInput?: Maybe<IEditProfilePasswordInput>;
    profileInput?: Maybe<IEditProfileInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsEditProfileSettingsArgs = {
    input?: Maybe<IEditProfileSettingsInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsUploadFileArgs = {
    input: IUploadFileInput;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsMarkNotificationAsReadArgs = {
    notificationId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsEditBusinessRelationshipArgs = {
    carrierINN: Scalars['String'];
    lastChangeReason?: Maybe<Scalars['String']>;
    state: Scalars['String'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsAddCargoTypeArgs = {
    input?: Maybe<ICargoTypeInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsEditCargoTypeArgs = {
    input?: Maybe<IEditCargoTypeInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsSendCargoTypeToArchiveArgs = {
    cargoTypeId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsAddCarrierGroupArgs = {
    input?: Maybe<IAddCarrierGroupInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsEditCarrierGroupArgs = {
    input?: Maybe<IEditCarrierGroupInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsSendToArchiveCarrierGroupArgs = {
    groupId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsAddCarrierContractArgs = {
    file?: Maybe<Scalars['Upload']>;
    input?: Maybe<ICarrierContractInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsEditCarrierContractArgs = {
    file?: Maybe<Scalars['Upload']>;
    input?: Maybe<IEditCarrierContractInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsSendCarrierContractToArchiveArgs = {
    carrierContractId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsDeleteDraftArgs = {
    draftId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsAddInsuranceContractArgs = {
    input?: Maybe<IAddInsuranceContractInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsEditInsuranceContractArgs = {
    input?: Maybe<IEditInsuranceContractInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsSendInsuranceContractToArchiveArgs = {
    contractId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsAddOrderArgs = {
    input?: Maybe<IAddOrderInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsCancelOrderArgs = {
    orderId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsAddAuctionOrderArgs = {
    input?: Maybe<IAddAuctionOrderInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsAssignCarrierArgs = {
    carrierId: Scalars['ID'];
    orderId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsSetOrderTrackingRequiredArgs = {
    orderId: Scalars['ID'];
    trackingRequired: Scalars['Boolean'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsDisableAuctionProlongationArgs = {
    auctionOrderId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsEditOrderUploadedDocumentsArgs = {
    input: IEditOrderUploadedDocumentsInput;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsSendDocumentToEdmsArgs = {
    docId: Scalars['ID'];
    edmsType: IEdmsType;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsAddOrganizationArgs = {
    input?: Maybe<IAddOrganizationInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsEditOrganizationArgs = {
    input?: Maybe<IEditOrganizationInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsSendOrganizationToArchiveArgs = {
    organizationId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsAddCargoOwnerRiskProfileDossierArgs = {
    docType: Scalars['String'];
    docVal: Scalars['String'];
    profileId: Scalars['String'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsSubmitCargoOwnerRiskProfileArgs = {
    profileId: Scalars['String'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsAddPriceQuotationArgs = {
    input?: Maybe<IAddPriceQuotationInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsSendPriceQuotationToArchiveArgs = {
    priceQuotationId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsAddSlaArgs = {
    inputData: ISlaInput;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsEditSlaArgs = {
    inputData: IEditSlaInput;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsDeleteSlaArgs = {
    slaId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsAddLoadingUnloadingPlaceArgs = {
    drivingDirectionsImg?: Maybe<Scalars['Upload']>;
    input?: Maybe<ILoadingUnloadingPlaceInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsEditLoadingUnloadingPlaceArgs = {
    drivingDirectionsImg?: Maybe<Scalars['Upload']>;
    input?: Maybe<IEditLoadingUnloadingPlaceInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsSendLoadingUnloadingPlaceToArchiveArgs = {
    storagePointId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsCancelTenderArgs = {
    tenderId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsSetTenderWinnerArgs = {
    bidId: Scalars['ID'];
    tenderId: Scalars['ID'];
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsAddTenderArgs = {
    input?: Maybe<IAddTenderInput>;
  };

  /** GraphQL-мутаторы для грузоотправителя */
  export type ICargoOwnerMutationsAddVehicleRequestArgs = {
    input: IVehicleRequestInput;
  };

  export interface ICargoOwnerOrganizationType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    /** Дата и время аккредитации */
    accrAtDt?: Maybe<Scalars['DateTime']>;
    /** Аккредитован - ДА или НЕТ */
    accrValue: Scalars['Boolean'];
    /** Дата и время окончания аккредитации */
    accrExpAt?: Maybe<Scalars['DateTime']>;
    /** Ссылка на объект risk profile */
    accrProfile?: Maybe<Scalars['String']>;
    role: IOrganizationRole;
    /** Тип НДС по умолчанию. */
    vat?: Maybe<IVatType>;
    /** Название организации */
    name: Scalars['String'];
    /** Подписывающее лицо (Фамилия И. О.) */
    signer: Scalars['String'];
    /** Описание организации */
    about?: Maybe<Scalars['String']>;
    /** Телефон организации */
    phoneNumber?: Maybe<Scalars['String']>;
    /** Электронная почта */
    email?: Maybe<Scalars['String']>;
    /** Является группой компаний */
    isCompanyGroup: Scalars['Boolean'];
    /** Головная компания */
    parent?: Maybe<ICargoOwnerOrganizationType>;
    /** Организационно-правовая форма организации */
    legalForm?: Maybe<ILegalFormType>;
    /** ИНН */
    inn?: Maybe<Scalars['String']>;
    /** КПП */
    kpp?: Maybe<Scalars['String']>;
    /** Юридический адрес */
    legalAddress?: Maybe<Scalars['String']>;
    /** Почтовый адрес */
    postalAddress?: Maybe<Scalars['String']>;
    /** ОГРН/ОГРНИП */
    registrationNumber?: Maybe<Scalars['String']>;
    /** Тип оплаты */
    paymentType?: Maybe<IPaymentTypeType>;
    /** Сроки оплаты */
    paymentPeriod?: Maybe<Scalars['String']>;
    /** Показывать номер заявки из внешней системы */
    showOrderExternalNo: Scalars['Boolean'];
    /** Настройки документооборота */
    documentFlowSettings?: Maybe<IOrganizationDocumentFlowSettings>;
    /** Дополнительные условия при добавлении заявки на рынке */
    extraConditionsForMarket?: Maybe<Scalars['String']>;
    /** Организация TN */
    isTn: Scalars['Boolean'];
    /** Включить возможность отслеживания заявок */
    enableDeliveryTracking: Scalars['Boolean'];
    /** Необходима дополнительная аккредитация ГП от ГВ */
    requiresCarrierAccrOnMarket: Scalars['Boolean'];
    /** Параметр, обозначающий pk настроек персонализации */
    personalizationSettings?: Maybe<Scalars['ID']>;
    /** Параметр, отвечающий за поддомен организации */
    subdomain?: Maybe<Scalars['String']>;
    /** Параметр, отвечающий за URL логотипа организации */
    logo?: Maybe<Scalars['String']>;
    /** Общие параметры аукционов организации */
    auctionParameters?: Maybe<IAuctionParametersType>;
    /** Настройки страхования для организации */
    insuranceParameters?: Maybe<IInsuranceParametersType>;
    /** Дополнительные услуги, доступные организации */
    allowedExtraServices?: Maybe<Array<Maybe<IExtraServicesType>>>;
    /** Статус задачи */
    accrStatus?: Maybe<IAccreditationStatusEnum>;
  }

  /** GraphQL-запросы для грузоотправителя */
  export interface ICargoOwnerQuery {
    _debug?: Maybe<IDjangoDebug>;
    /** Получение информации о типе кузова ТС и соответствующих подтипов */
    bodyType?: Maybe<IBodyTypeType>;
    /** Получение справочника типов кузова транспортных средств и соответствующих подтипов */
    bodyTypes?: Maybe<Array<Maybe<IBodyTypeType>>>;
    /** Получение справочника подтипов кузова транспортных средств */
    bodySubtypes?: Maybe<Array<Maybe<IBodySubtypeType>>>;
    /** Список всех сотрудников организации */
    organizationEmployees?: Maybe<Array<Maybe<IEmployeeType>>>;
    /** Информация о сотруднике своей организации */
    organizationEmployee?: Maybe<IEmployeeType>;
    /** Общее количество объектов */
    organizationEmployeesCount?: Maybe<Scalars['Int']>;
    /** Получение списка организационно-правовых форм организаций */
    legalForms?: Maybe<Array<Maybe<ILegalFormType>>>;
    /** Получение информации о типе погрузки/разгрузки */
    loadingType?: Maybe<ILoadingTypeType>;
    /** Получение списка всех типов погрузки/разгрузки */
    loadingTypes?: Maybe<Array<Maybe<ILoadingTypeType>>>;
    /** Типы документов для организации */
    organizationDocumentTypes?: Maybe<Array<Maybe<IDocumentTypeType>>>;
    /** Получение информации о типе оплаты */
    paymentType?: Maybe<IPaymentTypeType>;
    /** Получение списка всех типов оплаты */
    paymentTypes?: Maybe<Array<Maybe<IPaymentTypeType>>>;
    /** Список прав пользователя в системе */
    allPermissions?: Maybe<Array<Maybe<IPermissionType>>>;
    /** Профиль пользователя */
    profile?: Maybe<IProfileType>;
    /** Получение информации о всех НДС */
    vatTypes?: Maybe<Array<Maybe<IVatType>>>;
    /** Получение информации о всех часовых поясах */
    timezones?: Maybe<Array<Maybe<ITimeZoneType>>>;
    /** Получение учредительного документа */
    constituentDocument?: Maybe<IConstituentDocumentType>;
    /** Учредительные документы */
    myConstituentDocuments?: Maybe<Array<Maybe<IConstituentDocumentType>>>;
    /** Получение уведомления по ID */
    notification?: Maybe<INotificationType>;
    /** Получение списка уведомлений */
    notifications?: Maybe<Array<Maybe<INotificationType>>>;
    /** Общее количество объектов */
    notificationsCount?: Maybe<Scalars['Int']>;
    /** Получение баннер-уведомления */
    bannerNotification?: Maybe<IBannerNotificationType>;
    /** Получение информации о типе груза */
    cargoType?: Maybe<ICargoTypeType>;
    /** Получение списка всех типов грузов, доступных для грузоотправителя */
    cargoTypes?: Maybe<Array<Maybe<ICargoTypeType>>>;
    listPackagingTypesForCargoType?: Maybe<Array<Maybe<Scalars['String']>>>;
    /** Общее количество объектов */
    cargoTypesCount?: Maybe<Scalars['Int']>;
    /**
     * Получение информации о грузоперевозчике
     * @deprecated Стоит использовать carrier_organization
     */
    carrier?: Maybe<ICarrierType>;
    /**
     * Получение списка всех грузоперевозчиков
     * @deprecated Стоит использовать carrier_organizations
     */
    carriers?: Maybe<Array<Maybe<ICarrierType>>>;
    /** Получение информации об организации-грузоперевозчике */
    carrierOrganization?: Maybe<ICarrierOrganizationType>;
    /** Получение списка всех организаций-грузоперевозчиков */
    carrierOrganizations?: Maybe<Array<Maybe<ICarrierOrganizationType>>>;
    /** Получение списка доверенных организаций-грузоперевозчиков */
    trustedCarrierOrganizations?: Maybe<Array<Maybe<ICarrierOrganizationType>>>;
    /** Получение списка организаций-грузоперевозчиков с которыми у ГО были заявки на перевозку */
    orderCarrierOrganizations?: Maybe<Array<Maybe<ICarrierOrganizationType>>>;
    /** Круги перевозчиков */
    myCarrierGroups?: Maybe<Array<Maybe<ICarrierGroupType>>>;
    /** Детальный просмотр круга перевозчиков */
    carrierGroup?: Maybe<ICarrierGroupType>;
    /** Общее количество объектов */
    myCarrierGroupsCount?: Maybe<Scalars['Int']>;
    /** Общее количество объектов */
    trustedCarrierOrganizationsCount?: Maybe<Scalars['Int']>;
    /** Получение контактных данных людей на месте погрузки */
    contactPersonsAtLoadingPlace?: Maybe<Array<Maybe<IContactPersonAtStoragePointType>>>;
    /** Получение контактных данных людей на месте разгрузки */
    contactPersonsAtUnloadingPlace?: Maybe<Array<Maybe<IContactPersonAtStoragePointType>>>;
    /** Получение черновика по ID */
    draft?: Maybe<IDraftType>;
    /** Получение списка черновиков организации */
    drafts?: Maybe<Array<Maybe<IDraftType>>>;
    /** Общее количество объектов */
    draftsCount?: Maybe<Scalars['Int']>;
    /** Список договоров с СК */
    myInsuranceContracts?: Maybe<Array<Maybe<IInsuranceContractType>>>;
    /** Получение информации о договоре со страховой компанией */
    insuranceContract?: Maybe<IInsuranceContractType>;
    /** Информация о данных, необходимых для страхования */
    insuranceInitialInfo?: Maybe<IInsuranceInitialInfoType>;
    /** Предварительный подсчёт стоимости страхования */
    insuranceCost?: Maybe<Scalars['Float']>;
    /** Отмена страхования заявки */
    cancelInsurance?: Maybe<Scalars['Boolean']>;
    /** Список страховых компаний */
    insuranceCompanies?: Maybe<Array<Maybe<IInsuranceCompanyType>>>;
    /** Список доступных классов грузов */
    insuranceCargoClasses?: Maybe<Array<Maybe<IInsuranceReferenceType>>>;
    /** Список доступных типов грузов */
    insuranceCargoKinds?: Maybe<Array<Maybe<IInsuranceReferenceType>>>;
    /** Общее количество объектов */
    myInsuranceContractsCount?: Maybe<Scalars['Int']>;
    /** Получение информации о заявке */
    order?: Maybe<IOrderType>;
    /** Получение списка заявок грузоотправителя */
    orders?: Maybe<Array<Maybe<IOrderType>>>;
    /** Получение информации об аукционной заявке грузоотправителя */
    auctionOrder?: Maybe<IOrderType>;
    /** Получение списка аукционных заявок грузоотправителя */
    auctionOrders?: Maybe<Array<Maybe<IOrderType>>>;
    /** Получение информации по лоту (лот является частью аукционной заявки) */
    lot?: Maybe<ILotType>;
    /**
     * Получение списка всех лотов
     * @deprecated Не используется, будет убран
     */
    lots?: Maybe<Array<Maybe<ILotType>>>;
    /** Получение списка всех собственных заявок (и обычных и аукционных) */
    myOrders?: Maybe<Array<Maybe<IOrderType>>>;
    /** Общее количество объектов */
    ordersCount?: Maybe<Scalars['Int']>;
    /** Общее количество объектов */
    myOrdersCount?: Maybe<Scalars['Int']>;
    /** Общее количество объектов */
    auctionOrdersCount?: Maybe<Scalars['Int']>;
    /** Типы документов для заявки */
    orderDocumentTypes?: Maybe<Array<Maybe<IOrderDocumentTypeType>>>;
    /** Получение информации по профилю организации */
    organization?: Maybe<ICargoOwnerOrganizationType>;
    /** Получение информации об организации по поддомену */
    organizationBySubdomain?: Maybe<ICargoOwnerOrganizationType>;
    /** Список всех дочерних организации, принадлежащих к ГК организации пользователя */
    subOrganizations?: Maybe<Array<Maybe<ICargoOwnerOrganizationType>>>;
    /** Общее количество объектов */
    subOrganizationsCount?: Maybe<Scalars['Int']>;
    /** Получение информации о запросе цены */
    priceQuotation?: Maybe<IPriceQuotationType>;
    /** Получение списка запросов цены грузоотправителя */
    priceQuotations?: Maybe<Array<Maybe<IPriceQuotationType>>>;
    /** Общее количество объектов */
    priceQuotationsCount?: Maybe<Scalars['Int']>;
    /** Получение информации о выполняемом маршрутном листе по заявке */
    orderRouteSheet?: Maybe<IRouteSheetType>;
    /** Получение информации о событиях на маршрутном листе */
    routeSheetEvents?: Maybe<Array<Maybe<IRouteSheetEventType>>>;
    /** SLA критерии */
    slaCriteria?: Maybe<Array<Maybe<ISlaCriterionType>>>;
    /** SLA организации */
    sla?: Maybe<ISlaType>;
    /** Все SLA организации */
    allOrganizationSla?: Maybe<Array<Maybe<ISlaType>>>;
    /**
     * Получение информации о месте погрузки
     * @deprecated Устаревшее поле, необходимо использовать поле storagePoint
     */
    loadingPlace?: Maybe<IStoragePointType>;
    /**
     * Получение списка всех мест погрузки, доступных для грузоотправителя
     * @deprecated Устаревшее поле, необходимо использовать поле storagePoints
     */
    loadingPlaces?: Maybe<Array<Maybe<IStoragePointType>>>;
    /**
     * Получение информации о месте доставки
     * @deprecated Устаревшее поле, необходимо использовать поле storagePoint
     */
    unloadingPlace?: Maybe<IStoragePointType>;
    /**
     * Получение списка всех мест доставки, доступных для грузоотправителя
     * @deprecated Устаревшее поле, необходимо использовать поле storagePoints
     */
    unloadingPlaces?: Maybe<Array<Maybe<IStoragePointType>>>;
    /** Получение информации о месте погрузки/доставки */
    storagePoint?: Maybe<IStoragePointType>;
    /** Получение списка всех мест погрузки/доставки, доступных для грузоотправителя */
    storagePoints?: Maybe<Array<Maybe<IStoragePointType>>>;
    /** Общее количество объектов */
    storagePointsCount?: Maybe<Scalars['Int']>;
    /** Тендер */
    tender?: Maybe<ITenderType>;
    /** Список тендеров */
    tenders?: Maybe<Array<Maybe<ITenderType>>>;
    /** Отчет по тендеру */
    tenderReport?: Maybe<ITenderReportType>;
    /** Список отчетов по тендерам */
    tenderReports?: Maybe<Array<Maybe<ITenderReportType>>>;
    /** Общее количество объектов */
    tendersCount?: Maybe<Scalars['Int']>;
    /** Общее количество объектов */
    tenderReportsCount?: Maybe<Scalars['Int']>;
    /** Получение информации о типе кузова транспортных средств */
    vehicleType?: Maybe<IVehicleTypeType>;
    /** Получение списка всех типов кузова транспортных средств */
    vehicleTypes?: Maybe<Array<Maybe<IVehicleTypeType>>>;
    /** Получение списка предложений ТС */
    vehiclesAvailability?: Maybe<Array<Maybe<IVehicleAvailabilityType>>>;
    /** Количество предложений ТС */
    vehiclesAvailabilityCount?: Maybe<Scalars['Int']>;
  }

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryBodyTypeArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryOrganizationEmployeesArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    organizationId?: Maybe<Scalars['ID']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryOrganizationEmployeeArgs = {
    employeeId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryOrganizationEmployeesCountArgs = {
    organizationId?: Maybe<Scalars['ID']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryLoadingTypeArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryPaymentTypeArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryConstituentDocumentArgs = {
    docId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryNotificationArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryNotificationsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    isRead?: Maybe<Scalars['Boolean']>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryNotificationsCountArgs = {
    isRead?: Maybe<Scalars['Boolean']>;
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryCargoTypeArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryCargoTypesArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    name?: Maybe<Scalars['String']>;
    withArchived?: Maybe<Scalars['Boolean']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryCargoTypesCountArgs = {
    name?: Maybe<Scalars['String']>;
    withArchived?: Maybe<Scalars['Boolean']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryCarrierArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryCarriersArgs = {
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryCarrierOrganizationArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryCarrierOrganizationsArgs = {
    state?: Maybe<Scalars['String']>;
    anyContract?: Maybe<Scalars['Boolean']>;
    withContracts?: Maybe<Scalars['Boolean']>;
    haveOrdersWith?: Maybe<Scalars['Boolean']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryTrustedCarrierOrganizationsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    state?: Maybe<Scalars['String']>;
    anyContract?: Maybe<Scalars['Boolean']>;
    withContracts?: Maybe<Scalars['Boolean']>;
    haveOrdersWith?: Maybe<Scalars['Boolean']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryOrderCarrierOrganizationsArgs = {
    state?: Maybe<Scalars['String']>;
    anyContract?: Maybe<Scalars['Boolean']>;
    withContracts?: Maybe<Scalars['Boolean']>;
    haveOrdersWith?: Maybe<Scalars['Boolean']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryMyCarrierGroupsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    availability?: Maybe<IAvailabilityEnum>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryCarrierGroupArgs = {
    carrierGroupId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryMyCarrierGroupsCountArgs = {
    availability?: Maybe<IAvailabilityEnum>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryTrustedCarrierOrganizationsCountArgs = {
    state?: Maybe<Scalars['String']>;
    anyContract?: Maybe<Scalars['Boolean']>;
    withContracts?: Maybe<Scalars['Boolean']>;
    haveOrdersWith?: Maybe<Scalars['Boolean']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryContactPersonsAtLoadingPlaceArgs = {
    loadingPlaceId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryContactPersonsAtUnloadingPlaceArgs = {
    unloadingPlaceId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryDraftArgs = {
    draftId?: Maybe<Scalars['ID']>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryDraftsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryDraftsCountArgs = {
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryMyInsuranceContractsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    availability?: Maybe<IAvailabilityEnum>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryInsuranceContractArgs = {
    insuranceContractId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryInsuranceInitialInfoArgs = {
    insuranceContractId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryInsuranceCostArgs = {
    insuranceContractId: Scalars['ID'];
    declaredPrice: Scalars['Int'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryCancelInsuranceArgs = {
    orderId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryMyInsuranceContractsCountArgs = {
    availability?: Maybe<IAvailabilityEnum>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryOrderArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryOrdersArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceName?: Maybe<Scalars['String']>;
    unloadingPlaceName?: Maybe<Scalars['String']>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceName_Contains?: Maybe<Scalars['String']>;
    unloadingPlaceName_Contains?: Maybe<Scalars['String']>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    carrierOrganizationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryAuctionOrderArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryAuctionOrdersArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceName?: Maybe<Scalars['String']>;
    unloadingPlaceName?: Maybe<Scalars['String']>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceName_Contains?: Maybe<Scalars['String']>;
    unloadingPlaceName_Contains?: Maybe<Scalars['String']>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    carrierOrganizationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryLotArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryLotsArgs = {
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryMyOrdersArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceName?: Maybe<Scalars['String']>;
    unloadingPlaceName?: Maybe<Scalars['String']>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceName_Contains?: Maybe<Scalars['String']>;
    unloadingPlaceName_Contains?: Maybe<Scalars['String']>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    carrierOrganizationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryOrdersCountArgs = {
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceName?: Maybe<Scalars['String']>;
    unloadingPlaceName?: Maybe<Scalars['String']>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceName_Contains?: Maybe<Scalars['String']>;
    unloadingPlaceName_Contains?: Maybe<Scalars['String']>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    carrierOrganizationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryMyOrdersCountArgs = {
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceName?: Maybe<Scalars['String']>;
    unloadingPlaceName?: Maybe<Scalars['String']>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceName_Contains?: Maybe<Scalars['String']>;
    unloadingPlaceName_Contains?: Maybe<Scalars['String']>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    carrierOrganizationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryAuctionOrdersCountArgs = {
    status?: Maybe<Scalars['String']>;
    statuses?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceName?: Maybe<Scalars['String']>;
    unloadingPlaceName?: Maybe<Scalars['String']>;
    status_In?: Maybe<Array<Maybe<Scalars['String']>>>;
    loadingPlaceName_Contains?: Maybe<Scalars['String']>;
    unloadingPlaceName_Contains?: Maybe<Scalars['String']>;
    onlyMy?: Maybe<Scalars['Boolean']>;
    allocationType?: Maybe<IAllocationTypeEnum>;
    biddingType?: Maybe<IBiddingTypeEnum>;
    id?: Maybe<Scalars['ID']>;
    externalNo?: Maybe<Scalars['ID']>;
    loadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    unloadingDates?: Maybe<Array<Maybe<Scalars['Date']>>>;
    loadingDateFrom?: Maybe<Scalars['Date']>;
    loadingDateTo?: Maybe<Scalars['Date']>;
    unloadingDateFrom?: Maybe<Scalars['Date']>;
    unloadingDateTo?: Maybe<Scalars['Date']>;
    carrierOrganizationIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryOrderDocumentTypesArgs = {
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryOrganizationArgs = {
    organizationId?: Maybe<Scalars['ID']>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryOrganizationBySubdomainArgs = {
    organizationSubdomain?: Maybe<Scalars['String']>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQuerySubOrganizationsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    availability?: Maybe<IAvailabilityEnum>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQuerySubOrganizationsCountArgs = {
    availability?: Maybe<IAvailabilityEnum>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryPriceQuotationArgs = {
    priceQuotationId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryPriceQuotationsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    availability?: Maybe<IPriceQuotationCargoOwnerEnum>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryPriceQuotationsCountArgs = {
    availability?: Maybe<IPriceQuotationCargoOwnerEnum>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryOrderRouteSheetArgs = {
    orderId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryRouteSheetEventsArgs = {
    routeSheetId: Scalars['ID'];
    event?: Maybe<IRouteSheetEventEvent>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQuerySlaArgs = {
    slaId: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryLoadingPlaceArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryLoadingPlacesArgs = {
    withArchived?: Maybe<Scalars['Boolean']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryUnloadingPlaceArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryUnloadingPlacesArgs = {
    withArchived?: Maybe<Scalars['Boolean']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryStoragePointArgs = {
    id: Scalars['ID'];
    typePoint?: Maybe<IStoragePointTypePoint>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryStoragePointsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    typePoint?: Maybe<IStoragePointTypePoint>;
    withArchived?: Maybe<Scalars['Boolean']>;
    withInvisible?: Maybe<Scalars['Boolean']>;
    pointName?: Maybe<Scalars['String']>;
    organizationName?: Maybe<Scalars['String']>;
    inn?: Maybe<Scalars['ID']>;
    settlement?: Maybe<Scalars['String']>;
    settlementFias?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryStoragePointsCountArgs = {
    typePoint?: Maybe<IStoragePointTypePoint>;
    withArchived?: Maybe<Scalars['Boolean']>;
    withInvisible?: Maybe<Scalars['Boolean']>;
    pointName?: Maybe<Scalars['String']>;
    organizationName?: Maybe<Scalars['String']>;
    inn?: Maybe<Scalars['ID']>;
    settlement?: Maybe<Scalars['String']>;
    settlementFias?: Maybe<Scalars['String']>;
    address?: Maybe<Scalars['String']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryTenderArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryTendersArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryTenderReportArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryTenderReportsArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryTendersCountArgs = {
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryTenderReportsCountArgs = {
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryVehicleTypeArgs = {
    id: Scalars['ID'];
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryVehicleTypesArgs = {
    sortBy?: Maybe<Array<Maybe<Scalars['String']>>>;
  };

  /** GraphQL-запросы для грузоотправителя */
  export type ICargoOwnerQueryVehiclesAvailabilityArgs = {
    first?: Maybe<Scalars['Int']>;
    offset?: Maybe<Scalars['Int']>;
    loadingPlaceBySettlement?: Maybe<Scalars['String']>;
  };

  /** Информация о Грузоотправителе */
  export interface ICargoOwnerType {
    id: Scalars['ID'];
    /** Адрес электронной почты пользователя */
    email: Scalars['String'];
    /** Имя пользователя */
    firstName: Scalars['String'];
    /** Фамилия пользователя */
    lastName: Scalars['String'];
    phoneNumber?: Maybe<Scalars['String']>;
    /** Название организации, к которой относится пользователь */
    companyName?: Maybe<Scalars['String']>;
  }

  export interface ICargoPlaceRequirementsInput {
    /** Требования к грузовому месту: Вид упаковки */
    packagingType?: Maybe<ICargoPlaceRequirementsPackagingType>;
    /** Требования к грузовому месту: Длина */
    length?: Maybe<Scalars['Float']>;
    /** Требования к грузовому месту: Ширина */
    width?: Maybe<Scalars['Float']>;
    /** Требования к грузовому месту: Высота */
    height?: Maybe<Scalars['Float']>;
  }

  /** An enumeration. */
  export enum ICargoPlaceRequirementsPackagingType {
    /** Паллеты */
    Pallets = 'PALLETS',
    /** Коробки */
    Boxes = 'BOXES',
    /** Пачки */
    Bundles = 'BUNDLES',
    /** Россыпь */
    Placer = 'PLACER',
  }

  export interface ICargoPlaceRequirementsType {
    /** Вид упаковки */
    packagingType?: Maybe<ICargoPlaceRequirementsPackagingType>;
    /** Длина грузового места */
    length?: Maybe<Scalars['Float']>;
    /** Ширина грузового места */
    width?: Maybe<Scalars['Float']>;
    /** Высота грузового места */
    height?: Maybe<Scalars['Float']>;
  }

  export enum ICargoStatus {
    /** Не выполнялся */
    NotStarted = 'not_started',
    /** Загружен */
    Loaded = 'loaded',
    /** Доставлен */
    Delivered = 'delivered',
    /** Не выполнен */
    Cancelled = 'cancelled',
    /** Частично доставлен */
    PartlyDelivered = 'partly_delivered',
  }

  export interface ICargoType {
    /** Уникальный идентификатор груза */
    cargoId?: Maybe<Scalars['String']>;
    /** Статус груза */
    status: ICargoStatus;
    /** Наименование груза */
    name?: Maybe<Scalars['String']>;
    /** Ссылка на точку загрузки */
    loadingPointId?: Maybe<Scalars['Int']>;
    /** Размеры/количество груза */
    size?: Maybe<IDeliverySizeType>;
    /** Единицы доставки */
    deliveryUnits?: Maybe<Array<Maybe<IDeliveryUnit>>>;
  }

  /** Заносимая информация о типе груза */
  export interface ICargoTypeInput {
    /** ID типа кузова транспортного средства */
    typeVehicle?: Maybe<Scalars['ID']>;
    /** Наименование типа груза. Длина (max): 200 */
    name: Scalars['String'];
    /** Вес груза по умолчанию */
    defaultWeight?: Maybe<Scalars['Float']>;
    /** Объём груза по умолчанию */
    defaultVolume?: Maybe<Scalars['Float']>;
    /** Упаковка */
    packagingType?: Maybe<ICargoTypePackagingType>;
    /** Количество грузовых мест по умолчанию */
    cargoPackagesCount?: Maybe<Scalars['Int']>;
    /** Длина грузового места */
    lengthCargoPackage?: Maybe<Scalars['Float']>;
    /** Ширина грузового места */
    widthCargoPackage?: Maybe<Scalars['Float']>;
    /** Высота грузового места */
    heightCargoPackage?: Maybe<Scalars['Float']>;
    /** Список из ID разрешённых типов погрузки */
    permittedLoadingTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Требования к ТС: Грузоподъемность в тоннах */
    vehicleCapacity?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Объем кузова в м3 */
    vehicleVolume?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Высота кузова */
    vehicleHeight?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Ширина кузова */
    vehicleWidth?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Длина кузова */
    vehicleLength?: Maybe<Scalars['Float']>;
    /** Температурный режим (градусы Цельсия) */
    temperatureMode?: Maybe<Scalars['Int']>;
  }

  /** An enumeration. */
  export enum ICargoTypePackagingType {
    /** Паллеты */
    Pallets = 'PALLETS',
    /** Коробки */
    Boxes = 'BOXES',
    /** Пачки */
    Bundles = 'BUNDLES',
    /** Россыпь */
    Placer = 'PLACER',
  }

  /** Информация о типе груза */
  export interface ICargoTypeType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    /** Грузоотправитель, на которого зарегистрирован тип груза */
    cargoOwner: ICargoOwnerOrganizationType;
    /** Требования к ТС: Тип кузова транспортного средства */
    typeVehicle?: Maybe<IBodySubtypeType>;
    /** Разрешённые типы погрузки/разгрузки */
    permittedLoadingTypes: Array<ILoadingTypeType>;
    /** Название типа груза */
    name: Scalars['String'];
    /** Вес груза по умолчанию */
    defaultWeight?: Maybe<Scalars['Float']>;
    /** Объём груза по умолчанию */
    defaultVolume?: Maybe<Scalars['Float']>;
    /** Упаковка */
    packagingType?: Maybe<ICargoTypePackagingType>;
    /** Количество грузовых мест по умолчанию */
    cargoPackagesCount?: Maybe<Scalars['Int']>;
    /** Длина грузового места */
    lengthCargoPackage?: Maybe<Scalars['Float']>;
    /** Ширина грузового места */
    widthCargoPackage?: Maybe<Scalars['Float']>;
    /** Высота грузового места */
    heightCargoPackage?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Грузоподъемность в тоннах */
    vehicleCapacity?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Объём кузова в м3 */
    vehicleVolume?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Длина кузова */
    vehicleLength?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Ширина кузова */
    vehicleWidth?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Высота кузова */
    vehicleHeight?: Maybe<Scalars['Float']>;
    /** Температурный режим (градусы Цельсия) */
    temperatureMode?: Maybe<Scalars['Int']>;
  }

  /** An enumeration. */
  export enum ICarrierContractAgentType {
    /** Агент */
    Agent = 'AGENT',
    /** Экспедитор */
    Expeditor = 'EXPEDITOR',
    /** Собственник ТС */
    Owner = 'OWNER',
  }

  export interface ICarrierContractInput {
    /** ID организации грузоперевозчика */
    carrierOrganization: Scalars['ID'];
    /** Номер договора. Длина (max): 100 */
    contractNumber: Scalars['String'];
    /** Дата начала действия договора */
    startDate: Scalars['Date'];
    /** Дата завершения действия договора */
    endDate?: Maybe<Scalars['Date']>;
    /** Количество перевозок согласно договору */
    cargoQuantity?: Maybe<Scalars['Int']>;
    /** Список ID допустимых мест погрузки */
    loadingPlaces?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Список ID допустимых мест доставки */
    unloadingPlaces?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Список ID допустимых типов груза */
    cargoTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
  }

  export interface ICarrierContractType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    /** Номер договора */
    contractNumber: Scalars['String'];
    /** Дата начала действия договора */
    startDate: Scalars['Date'];
    /** Дата завершения действия договора */
    endDate?: Maybe<Scalars['Date']>;
    /** Количество перевозок согласно договору */
    cargoQuantity?: Maybe<Scalars['Int']>;
    /** Типы грузов */
    cargoTypes: Array<ICargoTypeType>;
    /** Наименование */
    name?: Maybe<Scalars['String']>;
    /** Тип агента в договоре (если он указан) */
    agentType: ICarrierContractAgentType;
    /** Список мест погрузки */
    loadingPlaces?: Maybe<Array<Maybe<IStoragePointType>>>;
    /** Список мест доставки */
    unloadingPlaces?: Maybe<Array<Maybe<IStoragePointType>>>;
    /** Файл договора */
    file?: Maybe<Scalars['String']>;
  }

  export interface ICarrierGroupType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    /** Дата и время создания */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Название круга перевозчиков */
    name: Scalars['String'];
    /** Менеджер круга перевозчиков */
    user: ICarrierUserType;
    /** Количество перевозчиков в круге */
    carriersQuantity?: Maybe<Scalars['Int']>;
    /** Список организаций-грузоперевозчиков */
    carriers?: Maybe<Array<Maybe<ICarrierOrganizationType>>>;
  }

  export interface ICarrierOrganizationType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    /** Название организации */
    name: Scalars['String'];
    /** Подписывающее лицо (Фамилия И. О.) */
    signer: Scalars['String'];
    /** Описание организации */
    about?: Maybe<Scalars['String']>;
    /** Телефон организации */
    phoneNumber?: Maybe<Scalars['String']>;
    /** Электронная почта */
    email?: Maybe<Scalars['String']>;
    /** Является группой компаний */
    isCompanyGroup: Scalars['Boolean'];
    /** Головная компания */
    parent?: Maybe<ICargoOwnerOrganizationType>;
    /** ИНН */
    inn?: Maybe<Scalars['String']>;
    /** КПП */
    kpp?: Maybe<Scalars['String']>;
    /** Юридический адрес */
    legalAddress?: Maybe<Scalars['String']>;
    /** Почтовый адрес */
    postalAddress?: Maybe<Scalars['String']>;
    /** ОГРН/ОГРНИП */
    registrationNumber?: Maybe<Scalars['String']>;
    /** Дата выдачи ОГРН */
    ogrnDate?: Maybe<Scalars['Date']>;
    /** Статус организации */
    status?: Maybe<Scalars['String']>;
    /** Получение списка всех договоров с грузоперевозчиком */
    contracts?: Maybe<Array<Maybe<ICarrierContractType>>>;
    /** Деловые отношения */
    businessRelationship?: Maybe<IBusinessRelationshipType>;
    /** Руководитель организации */
    headOfOrganization?: Maybe<IHeadOfOrganizationType>;
  }

  export interface ICarrierType {
    id: Scalars['ID'];
    companyName?: Maybe<Scalars['String']>;
    /** Имя менеджера ГП */
    firstName?: Maybe<Scalars['String']>;
    /** Отчество менеджера ГП */
    patronymic?: Maybe<Scalars['String']>;
    /** Фамилия менеджера ГП */
    lastName?: Maybe<Scalars['String']>;
    /** Email менеджера ГП */
    email?: Maybe<Scalars['String']>;
    /** Телефон менеджера ГП */
    phoneNumber?: Maybe<Scalars['String']>;
  }

  export interface ICarrierUserType {
    id: Scalars['ID'];
    /** Название организации */
    companyName?: Maybe<Scalars['String']>;
    /** Адрес электронной почты ГП */
    email?: Maybe<Scalars['String']>;
    /** ID организации */
    organizationId?: Maybe<Scalars['ID']>;
    /** Имя */
    firstName?: Maybe<Scalars['String']>;
    /** Фамилия */
    lastName?: Maybe<Scalars['String']>;
    /** Отчество */
    patronymic?: Maybe<Scalars['String']>;
  }

  export interface IConstituentDocumentType {
    id: Scalars['ID'];
    /** Название документа */
    name: Scalars['String'];
    /** ID организации */
    organizationId?: Maybe<Scalars['ID']>;
    /** URL для скачивания */
    url?: Maybe<Scalars['String']>;
  }

  /** Контактное лицо на месте погрузки/разгрузки */
  export interface IContactPersonAtStoragePointType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    firstName: Scalars['String'];
    lastName: Scalars['String'];
    fullName: Scalars['String'];
    phoneNumber: Scalars['String'];
    loadingPlace?: Maybe<IStoragePointType>;
  }

  /** Заносимая информация о контактном лице на месте погрузки/разгрузки */
  export interface IContactPersonInput {
    /** Имя. Длина (max): 500 */
    firstName?: Maybe<Scalars['String']>;
    /** Фамилия. Длина (max): 500 */
    lastName?: Maybe<Scalars['String']>;
    /** Телефонный номер. Длина (max): 150 */
    phoneNumber: Scalars['String'];
  }

  export interface IContractInfoType {
    /** Время создания заявки-договора, согласно 1С */
    createdAt1c?: Maybe<Scalars['DateTime']>;
    /** Номер документа в системе 1С */
    contractNumber1c?: Maybe<Scalars['String']>;
  }

  export interface ICreateCargoOwnerRiskProfile {
    /** Идентификатор созданного профиля риска */
    profileId?: Maybe<Scalars['String']>;
  }

  /** An enumeration. */
  export enum ICustomUserEmailStatus {
    /** Подтверждён */
    Confirmed = 'CONFIRMED',
    /** Не подтверждён */
    NotConfirmed = 'NOT_CONFIRMED',
    /** Требуется подтверждение */
    NeedConfirm = 'NEED_CONFIRM',
  }

  export interface IDeleteSla {
    deleted?: Maybe<Scalars['Boolean']>;
  }

  export enum IDeliveryMethodEnum {
    /** Доставка отдельной машиной */
    Ftl = 'FTL',
    /** Возможен догруз */
    Ltl = 'LTL',
  }

  export interface IDeliverySizeInput {
    /** Длина груза */
    length?: Maybe<Scalars['Decimal']>;
    /** Ширина груза */
    width?: Maybe<Scalars['Decimal']>;
    /** Высота груза */
    height?: Maybe<Scalars['Decimal']>;
    /** Вес груза */
    weight?: Maybe<Scalars['Decimal']>;
    /** Объем груза */
    volume?: Maybe<Scalars['Decimal']>;
    /** Тип упаковки */
    packageType?: Maybe<Scalars['String']>;
    /** Количество упаковок */
    quantity?: Maybe<Scalars['Int']>;
    /** Объем груза на разгрузку указан в ТТН */
    setByWaybill?: Maybe<Scalars['Boolean']>;
  }

  export interface IDeliverySizeType {
    /** Длина груза */
    length?: Maybe<Scalars['Decimal']>;
    /** Ширина груза */
    width?: Maybe<Scalars['Decimal']>;
    /** Высота груза */
    height?: Maybe<Scalars['Decimal']>;
    /** Объём груза */
    volume?: Maybe<Scalars['Decimal']>;
    /** Масса груза */
    weight?: Maybe<Scalars['Decimal']>;
    /** Тип упаковки */
    packageType?: Maybe<Scalars['String']>;
    /** Количество упаковок */
    quantity?: Maybe<Scalars['Int']>;
    /** Количество или объем задаются ТТН */
    setByWaybill?: Maybe<Scalars['Boolean']>;
  }

  export interface IDeliveryUnit {
    /** Уникальный идентификатор единицы разгрузки */
    unitId?: Maybe<Scalars['String']>;
    /** Ссылка на груз */
    cargoId?: Maybe<Scalars['String']>;
    /** Ссылка на точку доставки */
    deliveryPointId?: Maybe<Scalars['Int']>;
    /** Статус выполнения на единице доставки груза */
    status?: Maybe<IDeliveryUnitStatus>;
    /** Размеры/количество разгружаемого груза */
    size?: Maybe<IDeliverySizeType>;
  }

  export interface IDeliveryUnitInput {
    /** Уникальный номер груза в рамках заявки, ссылка на id объекта в cargos */
    cargoId?: Maybe<Scalars['ID']>;
    /** Порядковый номер места разгрузки в рамках заявки, ссылка на seq_id объекта в unloading_places */
    deliveryPointId?: Maybe<Scalars['ID']>;
    /** Количественные характеристики груза на разгрузку в данной точке */
    size?: Maybe<IDeliverySizeInput>;
  }

  export enum IDeliveryUnitStatus {
    /** Не выполнялся */
    NotStarted = 'not_started',
    /** Отменена */
    Cancelled = 'cancelled',
    /** Загружен */
    Loaded = 'loaded',
    /** Доставлен */
    Delivered = 'delivered',
  }

  /** Debugging information for the current query. */
  export interface IDjangoDebug {
    /** Executed SQL queries for this API query. */
    sql?: Maybe<Array<Maybe<IDjangoDebugSql>>>;
  }

  /** Represents a single database query made to a Django managed DB. */
  export interface IDjangoDebugSql {
    /** The type of database being used (e.g. postrgesql, mysql, sqlite). */
    vendor: Scalars['String'];
    /** The Django database alias (e.g. 'default'). */
    alias: Scalars['String'];
    /** The actual SQL sent to this database. */
    sql?: Maybe<Scalars['String']>;
    /** Duration of this database query in seconds. */
    duration: Scalars['Float'];
    /** The raw SQL of this query, without params. */
    rawSql: Scalars['String'];
    /** JSON encoded database query parameters. */
    params: Scalars['String'];
    /** Start time of this database query. */
    startTime: Scalars['Float'];
    /** Stop time of this database query. */
    stopTime: Scalars['Float'];
    /** Whether this database query took more than 10 seconds. */
    isSlow: Scalars['Boolean'];
    /** Whether this database query was a SELECT. */
    isSelect: Scalars['Boolean'];
    /** Postgres transaction ID if available. */
    transId?: Maybe<Scalars['String']>;
    /** Postgres transaction status if available. */
    transStatus?: Maybe<Scalars['String']>;
    /** Postgres isolation level if available. */
    isoLevel?: Maybe<Scalars['String']>;
    /** Postgres connection encoding if available. */
    encoding?: Maybe<Scalars['String']>;
  }

  export enum IDocumentFlowSettingsEnum {
    /** Грузовладелец первым подписывает договор */
    CargoOwnerFirst = 'CARGO_OWNER_FIRST',
    /** Договор подписывает только грузоперевозчик */
    CarrierOnly = 'CARRIER_ONLY',
  }

  export interface IDocumentStatusType {
    /** ID документа */
    id?: Maybe<Scalars['ID']>;
    /** Документ */
    document?: Maybe<IOrderUploadedDocumentType>;
    /** Статус */
    status?: Maybe<Scalars['String']>;
    /** Дата создания */
    createdAt?: Maybe<Scalars['String']>;
    /** Тип документа */
    documentType?: Maybe<IOrderDocumentTypeType>;
    /** Внешний ID */
    extDocId?: Maybe<Scalars['Int']>;
  }

  /** Перечислитель по типу документа для получения статуса */
  export enum IDocumentTypeInnerNameEnum {
    /** Все типы */
    All = 'ALL',
    /** Доверенность водителю */
    PoaDriver = 'POA_DRIVER',
    /** Заявка-договор */
    Contract = 'CONTRACT',
    /** Транспортная накладная */
    Waybill = 'WAYBILL',
    /** Электронная подпись */
    DigitSign = 'DIGIT_SIGN',
    /** Форма заявки-договора */
    ContractForm = 'CONTRACT_FORM',
    /** ТОРГ-12 */
    Torg12 = 'TORG12',
    /** Соглашение о расторжении */
    TerminationAgreement = 'TERMINATION_AGREEMENT',
  }

  export interface IDocumentTypeType {
    id: Scalars['ID'];
    /** Название типа документа */
    name: Scalars['String'];
    /** Служебное название документа */
    innerName?: Maybe<IOrganizationDocumentTypeInnerName>;
  }

  /** Указанное в заявке место погрузки/доставки */
  export interface IDraftOrderStoragePointType {
    /** Указанные в заявке дата и предполагаемое время начала периода прибытия транспорта */
    arriveDatetimeFrom?: Maybe<Scalars['DateTime']>;
    /** Указанные в заявке дата и предполагаемое время окончания периода прибытия транспорта */
    arriveDatetimeTo?: Maybe<Scalars['DateTime']>;
    /** Фактическое время прибытия на точку доставки */
    factArriveDatetimeFrom?: Maybe<Scalars['DateTime']>;
    /** Фактическое время оканчания работы на точке доставки */
    factArriveDatetimeTo?: Maybe<Scalars['DateTime']>;
    /** Комментарий к месту погрузки/разгрузки */
    comment?: Maybe<Scalars['String']>;
    /** Порядковый номер погрузки/разгрузки в рамках заявки */
    seqId?: Maybe<Scalars['Int']>;
    storagePoint?: Maybe<IStoragePointType>;
    /** Deprecated! Стоит использовать arrive_datetime_from */
    workDatetime?: Maybe<Scalars['DateTime']>;
    /** Контактное лицо на точке погрузки/доставки */
    contactPersonAtStoragePoint?: Maybe<Scalars['String']>;
    /** Контактный телефон на точке погрузки/доставки */
    contactPhoneAtStoragePoint?: Maybe<Scalars['String']>;
    /** Контактное лицо на точке погрузки/доставки */
    contactPersons?: Maybe<Array<Maybe<IOrderStoragePointContactPerson>>>
    /** Тип погрузки/разгрузки */
    loadingType?: Maybe<ILoadingTypeType>;
    /** Список прикрепленных файлов */
    attachedDocuments?: Maybe<Array<Maybe<IOrderStoragePointAttachedDocumentType>>>;
  }

  export interface IDraftOrderType {
    id: Scalars['ID'];
    /** Дата и время создания заявки */
    createdDatetime: Scalars['DateTime'];
    /** Дата и время последней модификации заявки */
    lastModifiedDatetime: Scalars['DateTime'];
    cargoType?: Maybe<ICargoTypeType>;
    /** Вес груза */
    weight?: Maybe<Scalars['Float']>;
    /** Объём груза */
    volume?: Maybe<Scalars['Float']>;
    /** Цена */
    price?: Maybe<IPriceType>;
    /** Комментарий к заявке */
    comment: Scalars['String'];
    /** Статус заявки */
    status: IOrderStatus;
    /** Информация о грузоперевозчике */
    carrier?: Maybe<ICarrierType>;
    /** Информация о водителе */
    driverInfo: Scalars['String'];
    /** Информация об автомобиле */
    truckInfo: Scalars['String'];
    /** Информация о прицепе */
    trailerInfo: Scalars['String'];
    /** Получение информации о сменном водителе */
    coDriver?: Maybe<IDriverType>;
    /** Дата и время назначения перевозчика на заявку */
    carrierAssignedDatetime?: Maybe<Scalars['DateTime']>;
    /** Дата и время указания информации о водителе, автомобиле и прицепе */
    transportReservedDatetime?: Maybe<Scalars['DateTime']>;
    /** Дата и время загрузки скана заявки-договора */
    contractAttachedDatetime?: Maybe<Scalars['DateTime']>;
    /** Дата и время завершения оформления заявки */
    readyToGoDatetime?: Maybe<Scalars['DateTime']>;
    /** Тип размещения заявки */
    allocationType: IOrderAllocationType;
    cargoPackagesCount?: Maybe<Scalars['Int']>;
    /** Цена включает НДС */
    isVatIncluded: Scalars['Boolean'];
    /** Признак необходимости отслеживать заявку */
    trackingRequired: Scalars['Boolean'];
    /** Признак рыночной заявки */
    isMarket?: Maybe<Scalars['Boolean']>;
    /** Номер заявки во внешней системе */
    externalNo?: Maybe<Scalars['String']>;
    /** Сроки оплаты */
    paymentPeriod?: Maybe<Scalars['String']>;
    /** Организация, указанная в качестве заказчика перевозки */
    customerOrganization?: Maybe<ICargoOwnerOrganizationType>;
    /** Признак того, каким образом происходит подписание заявок */
    signingWay: IOrderSigningWay;
    /** Температурный режим (градусы Цельсия) */
    temperatureMode?: Maybe<Scalars['Int']>;
    /** Заявка только на просмотр, используется в интеграции с 1С (витрина) */
    isReadonly: Scalars['Boolean'];
    /**
     * Время завершения в заявке
     * @deprecated Для web и мобильных клиентов этот параметр заменен на viewEndDatetime.
     */
    endDatetime?: Maybe<Scalars['DateTime']>;
    /** Дедлайн для обработки назначенной заявки */
    processDeadline?: Maybe<Scalars['DateTime']>;
    /** Время на бронирование свободной заявки, указанная для текущей заявки, в минутах */
    timeOrderReservation?: Maybe<Scalars['Int']>;
    /** Количество участников торговой процедуры */
    tradingParticipantsCount?: Maybe<Scalars['Int']>;
    /** Дополнительное время на обработку назначенной заявки, в секундах */
    gracePeriod?: Maybe<Scalars['Int']>;
    /** Отгрузка обязательна в согласованную с покупателем дату */
    isShipmentOnAgreedDate: Scalars['Boolean'];
    /** Часть перевозок помечены таким флагом. Не означает, что все перевозки для заказчиков DIY помечены */
    diy: Scalars['Boolean'];
    /** Признак того, что в заявке не нарушен ни один критерий SLA */
    slaObserved: Scalars['Boolean'];
    lot?: Maybe<ILotType>;
    /** Страховка */
    insurance?: Maybe<IOrderInsuranceType>;
    /** Фактическая организация-перевозчик (назначается ТЭКом), осуществляющая перевозку */
    actualCarrierOrganization?: Maybe<Scalars['String']>;
    /** Список мест погрузки указанной заявки */
    loadingPlaces?: Maybe<Array<Maybe<IDraftOrderStoragePointType>>>;
    /** Список мест доставки указанной заявки */
    unloadingPlaces?: Maybe<Array<Maybe<IDraftOrderStoragePointType>>>;
    /** Получение информации о первом месте погрузки в заявке */
    loadingPlace?: Maybe<IStoragePointType>;
    /** Дата погрузки */
    loadingDate?: Maybe<Scalars['Date']>;
    /** Контактное лицо на первом месте погрузки в заявке */
    contactPersonAtLoadingPlace?: Maybe<Scalars['String']>;
    /** Получение информации о первом месте доставки в заявке */
    unloadingPlace?: Maybe<IStoragePointType>;
    /** Дата доставки */
    unloadingDate?: Maybe<Scalars['Date']>;
    /** Контактное лицо на первом месте доставки в заявке */
    contactPersonAtUnloadingPlace?: Maybe<Scalars['String']>;
    /** Получение информации о требованиях к транспортному средству */
    vehicleRequirements?: Maybe<IVehicleRequirementsType>;
    /** Получение информации о требованиях к грузовому месту */
    cargoPlaceRequirements?: Maybe<ICargoPlaceRequirementsType>;
    /** Цена с НДС */
    priceWithVat?: Maybe<IPriceType>;
    /** Цена без НДС */
    priceWithoutVat?: Maybe<IPriceType>;
    /** Круг перевозчиков */
    carrierGroup?: Maybe<ICarrierGroupType>;
    /** Тип оплаты */
    paymentType?: Maybe<IPaymentTypeType>;
    /** Статус заявки по событиям из МЛ */
    statusOnEvent?: Maybe<Scalars['String']>;
    /** Время завершения, которое рассчитывается для web-клиента, в зависимости от таймера в заявке */
    viewEndDatetime?: Maybe<Scalars['DateTime']>;
    /** Дедлайн для обработки назначенной заявки,который рассчитывается для web-клиента, в зависимости от таймера в заявке */
    viewProcessDeadline?: Maybe<Scalars['DateTime']>;
    /** Дедлайн для того чтобы забрать свободную заявку или сделать ставку для аукционной заявки,который рассчитывается для web-клиента, в зависимости от таймера в заявке */
    acceptDeadline?: Maybe<Scalars['DateTime']>;
    /**
     * Время до завершения заявки по времени(в секундах)
     * @deprecated Этот параметр в следующих версиях будет заменен на secondsToLifeTimeExpired.
     */
    secondsToCancel?: Maybe<Scalars['Int']>;
    /** Время до завершения заявки по времени жизни(в секундах) */
    secondsToLifeTimeExpired?: Maybe<Scalars['Int']>;
    /** Время до завершения заявки по времени бронирования(в секундах) */
    secondsToReservationTimeExpired?: Maybe<Scalars['Int']>;
    /** Может ли пользователь редактировать список загруженных документов */
    canModifyUploadedDocuments?: Maybe<Scalars['Boolean']>;
    /** Загруженные документы */
    uploadedDocuments?: Maybe<Array<Maybe<IOrderUploadedDocumentType>>>;
    /** Загруженные документы */
    uploadedDocumentsV2?: Maybe<Array<Maybe<IOrderUploadedDocumentType>>>;
    /** Дополнительные услуги по заявке */
    extraServices?: Maybe<Array<Maybe<IExtraServicesType>>>;
    /** Список типов файлов, которые необходимо подписывать */
    settingsESignRequired?: Maybe<Array<Maybe<IOrderDocumentTypeType>>>;
    /** Маршрутный лист по заявке */
    routeSheet?: Maybe<IRouteSheetType>;
    /** Текущий статус документа */
    documentActualStatus?: Maybe<Array<Maybe<IDocumentStatusType>>>;
    /** История статус по документу */
    documentStatusHistory?: Maybe<Array<Maybe<IDocumentStatusType>>>;
    /** Грузовладелец, создавший заявку (пользователь) */
    owner?: Maybe<ICargoOwnerType>;
    /** Дополнительные настройки заявки */
    orderAttributes?: Maybe<IOrderAttributesType>;
    /** Водитель */
    driver?: Maybe<IDriverDataType>;
    /** Транспортное средство */
    vehicle?: Maybe<IVehicleDataType>;
    /** Информация о прицепе/полуприцепе */
    vehicleTrailer?: Maybe<IVehicleTrailerDataType>;
    /** Для завершения заявки необходимо указать информацию по транспортным накладнымдля всех мест доставки */
    waybillRequired?: Maybe<Scalars['Boolean']>;
    /** Информация для всех транспортных накладных указана */
    hasWaybillInfo?: Maybe<Scalars['Boolean']>;
    /** Перевозка осуществляется собственным транспортным средством ТЭКа (или экспедитора) */
    agentUsesOwnVehicle?: Maybe<Scalars['Boolean']>;
    /** Расшифровка фрахта и агентского вознаграждения в заявке с НДС и без НДС */
    agentFeeAndFreightDetails?: Maybe<IAgentFeeAndFreightDetails>;
    /** Генеральный договор с перевозчиком */
    carrierContract?: Maybe<ICarrierContractType>;
    /** Типы погрузки/разгрузки */
    loadingTypes?: Maybe<Scalars['String']>;
    /** Внешний ID заявки */
    extId?: Maybe<Scalars['String']>;
    /** Связанный тендер */
    tender?: Maybe<ITenderType>;
    /** Уровень обслуживания для заявки */
    sla?: Maybe<ISlaType>;
    /** Информация о фактической организации-перевозчике */
    agentCarrierInfo?: Maybe<IAgentCarrierInfoType>;
    /** Количество ставок, сделанных для данного аукциона */
    betsCount?: Maybe<Scalars['Int']>;
    /** Информация о фактической организации-перевозчике */
    cargos?: Maybe<Array<Maybe<ICargoType>>>;
    /** Информация о грузах */
    cargoInfo?: Maybe<Scalars['JSONString']>;
    /** Настройки документооборота */
    documentFlowSettings?: Maybe<IDocumentFlowSettingsEnum>;
    /** Статус документооборота */
    edmStatus?: Maybe<Scalars['String']>;
  }

  export type IDraftOrderTypeLoadingPlaceArgs = {
    id?: Maybe<Scalars['ID']>;
  };

  export type IDraftOrderTypeUnloadingPlaceArgs = {
    id?: Maybe<Scalars['ID']>;
  };

  export type IDraftOrderTypeStatusOnEventArgs = {
    value?: Maybe<IOrderStatusOnEvent>;
  };

  export type IDraftOrderTypeDocumentActualStatusArgs = {
    documentType?: Maybe<IDocumentTypeInnerNameEnum>;
  };

  export type IDraftOrderTypeDocumentStatusHistoryArgs = {
    documentType?: Maybe<IDocumentTypeInnerNameEnum>;
  };

  /** Черновик */
  export interface IDraftType {
    id: Scalars['ID'];
    /** Дата и время создания */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Информация о заявке */
    order?: Maybe<IDraftOrderType>;
    /** Получение информации о требованиях к грузовому месту */
    cargoPlaceRequirements?: Maybe<ICargoPlaceRequirementsType>;
    /** Список мест погрузки указанной заявки */
    loadingPlaces?: Maybe<Array<Maybe<IDraftOrderStoragePointType>>>;
    /** Список мест доставки указанной заявки */
    unloadingPlaces?: Maybe<Array<Maybe<IDraftOrderStoragePointType>>>;
    /** Получение информации о требованиях к транспортному средству */
    vehicleRequirements?: Maybe<IVehicleRequirementsType>;
    /** Получение информации о первом месте погрузки в заявке */
    loadingPlace?: Maybe<IStoragePointType>;
    /** Получение информации о первом месте доставки в заявке */
    unloadingPlace?: Maybe<IStoragePointType>;
    /** Дата погрузки */
    loadingDate?: Maybe<Scalars['Date']>;
    /** Дата доставки */
    unloadingDate?: Maybe<Scalars['Date']>;
    /** Дополнительные услуги по заявке */
    extraServices?: Maybe<Array<Maybe<IExtraServicesType>>>;
    /** Информация по грузам */
    cargos?: Maybe<Array<Maybe<ICargoType>>>;
  }

  /** Данные водителя для заявки */
  export interface IDriverDataType {
    /** Мобильный телефон водителя */
    phone?: Maybe<Scalars['String']>;
    /** Доп. мобильный телефон водителя */
    addPhone?: Maybe<Scalars['String']>;
    /** Серия водительских прав */
    licenceSeries?: Maybe<Scalars['String']>;
    /** Номер водительских прав */
    licenceNumber?: Maybe<Scalars['String']>;
    /** Фамилия */
    surname?: Maybe<Scalars['String']>;
    /** Имя */
    name?: Maybe<Scalars['String']>;
    /** Отчество */
    patronymic?: Maybe<Scalars['String']>;
    /** Серия паспорта */
    passportSeries?: Maybe<Scalars['String']>;
    /** Номер паспорта */
    passportNumber?: Maybe<Scalars['String']>;
    /** Кем выдан паспорт */
    passportPlace?: Maybe<Scalars['String']>;
    /** Дата выдачи паспорта */
    passportDate?: Maybe<Scalars['Date']>;
    /** ID водителя */
    id?: Maybe<Scalars['ID']>;
    /** Устанавливал ли водитель мобильное приложение */
    usesDriverMobile?: Maybe<Scalars['Boolean']>;
    /** Информация о ЧС */
    blackListInfo?: Maybe<Array<Maybe<IBlackListDriverType>>>;
  }

  /** Данные водителя для заявки */
  export type IDriverDataTypeBlackListInfoArgs = {
    orderId?: Maybe<Scalars['ID']>;
  };

  /** An enumeration. */
  export enum IDriverGender {
    /** Женский */
    Female = 'FEMALE',
    /** Мужской */
    Male = 'MALE',
  }

  /** Информация о водителе */
  export interface IDriverType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    /** Статус проверки */
    verificationStatus: IDriverVerificationStatus;
    /** День рожденья */
    birthday?: Maybe<Scalars['Date']>;
    /** Пол */
    gender?: Maybe<IDriverGender>;
    /** ИНН */
    inn?: Maybe<Scalars['String']>;
    /** Мобильный телефон водителя */
    phone?: Maybe<Scalars['String']>;
    /** Доп. мобильный телефон водителя */
    addPhone?: Maybe<Scalars['String']>;
    /** Серия водительских прав */
    licenceSeries?: Maybe<Scalars['String']>;
    /** Номер водительских прав */
    licenceNumber?: Maybe<Scalars['String']>;
    /** Кем выданы водитеские права */
    licencePlace?: Maybe<Scalars['String']>;
    /** Дата выдачи водительских прав */
    licenceDate?: Maybe<Scalars['Date']>;
    /** Фамилия */
    surname?: Maybe<Scalars['String']>;
    /** Имя */
    name?: Maybe<Scalars['String']>;
    /** Отчество */
    patronymic?: Maybe<Scalars['String']>;
    /** Серия паспорта */
    passportSeries?: Maybe<Scalars['String']>;
    /** Номер паспорта */
    passportNumber?: Maybe<Scalars['String']>;
    /** Кем выдан паспорт */
    passportPlace?: Maybe<Scalars['String']>;
    /** Дата выдачи паспорта */
    passportDate?: Maybe<Scalars['Date']>;
    /** Страна (RU - Россия, любой другой - не Россия) */
    country: Scalars['String'];
    /** Грузоперевозчик */
    carrierOrganization: ICargoOwnerOrganizationType;
    /** Устанавливал ли водитель мобильное приложение */
    usesDriverMobile?: Maybe<Scalars['Boolean']>;
    /** Информация о ЧС */
    blackListInfo?: Maybe<Array<Maybe<IBlackListDriverType>>>;
  }

  /** Информация о водителе */
  export type IDriverTypeBlackListInfoArgs = {
    orderId?: Maybe<Scalars['ID']>;
  };

  /** An enumeration. */
  export enum IDriverVerificationStatus {
    /** Проверка не проводилась */
    NotChecked = 'NOT_CHECKED',
    /** Принято в работу */
    OnWork = 'ON_WORK',
    /** Одобрено */
    Approved = 'APPROVED',
    /** Отклонено */
    Rejected = 'REJECTED',
  }

  export interface IEditCargoTypeInput {
    /** ID типа кузова транспортного средства */
    typeVehicle?: Maybe<Scalars['ID']>;
    /** Наименование типа груза. Длина (max): 200 */
    name?: Maybe<Scalars['String']>;
    /** Вес груза по умолчанию */
    defaultWeight?: Maybe<Scalars['Float']>;
    /** Объём груза по умолчанию */
    defaultVolume?: Maybe<Scalars['Float']>;
    /** Упаковка */
    packagingType?: Maybe<ICargoTypePackagingType>;
    /** Количество грузовых мест по умолчанию */
    cargoPackagesCount?: Maybe<Scalars['Int']>;
    /** Длина грузового места */
    lengthCargoPackage?: Maybe<Scalars['Float']>;
    /** Ширина грузового места */
    widthCargoPackage?: Maybe<Scalars['Float']>;
    /** Высота грузового места */
    heightCargoPackage?: Maybe<Scalars['Float']>;
    /** Список из ID разрешённых типов погрузки */
    permittedLoadingTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Требования к ТС: Грузоподъемность в тоннах */
    vehicleCapacity?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Объем кузова в м3 */
    vehicleVolume?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Высота кузова */
    vehicleHeight?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Ширина кузова */
    vehicleWidth?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Длина кузова */
    vehicleLength?: Maybe<Scalars['Float']>;
    /** Температурный режим (градусы Цельсия) */
    temperatureMode?: Maybe<Scalars['Int']>;
    id: Scalars['ID'];
  }

  export interface IEditCarrierContractInput {
    /** ID организации грузоперевозчика */
    carrierOrganization?: Maybe<Scalars['ID']>;
    /** Номер договора. Длина (max): 100 */
    contractNumber?: Maybe<Scalars['String']>;
    /** Дата начала действия договора */
    startDate?: Maybe<Scalars['Date']>;
    /** Дата завершения действия договора */
    endDate?: Maybe<Scalars['Date']>;
    /** Количество перевозок согласно договору */
    cargoQuantity?: Maybe<Scalars['Int']>;
    /** Список ID допустимых мест погрузки */
    loadingPlaces?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Список ID допустимых мест доставки */
    unloadingPlaces?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Список ID допустимых типов груза */
    cargoTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** ID договора с грузоперевозчиком */
    id: Scalars['ID'];
  }

  export interface IEditCarrierGroupInput {
    /** Название группы организаций-перевозчиков. Длина (max): 150 */
    name?: Maybe<Scalars['String']>;
    /** Список ID организаций-перевозчиков в группе */
    carriersIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** ID круга организаций-перевозчиков */
    groupId: Scalars['ID'];
  }

  export interface IEditContactPersonInput {
    /** Имя. Длина (max): 500 */
    firstName?: Maybe<Scalars['String']>;
    /** Фамилия. Длина (max): 500 */
    lastName?: Maybe<Scalars['String']>;
    /** Телефонный номер. Длина (max): 150 */
    phoneNumber?: Maybe<Scalars['String']>;
    /** Идентификатор контактных данных человека на месте погрузки/разгрузки */
    id?: Maybe<Scalars['ID']>;
  }

  /** Редактирование информации о сотруднике организации */
  export interface IEditEmployeeInput {
    /** Имя. Длина (max): 30 */
    firstName?: Maybe<Scalars['String']>;
    /** Фамилия. Длина (max): 150 */
    lastName?: Maybe<Scalars['String']>;
    /** Отчество. Длина (max): 150 */
    patronymic?: Maybe<Scalars['String']>;
    /** Email. Длина (max): 254 */
    email?: Maybe<Scalars['String']>;
    /** Номер телефона. Длина (max): 25 */
    phoneNumber?: Maybe<Scalars['String']>;
    /** Список из ID прав доступа, назначаемых сотруднику */
    permissions?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Настройки UI */
    uiSettings?: Maybe<Scalars['JSONString']>;
    /** ID редактируемого сотрудника */
    id: Scalars['ID'];
  }

  export interface IEditInsuranceContractInput {
    /** Код страховой компании */
    insuranceCompanyCodename?: Maybe<IInsurance_Company_Choices>;
    /** Номер генерального договора */
    generalNumber?: Maybe<Scalars['String']>;
    /** Тариф */
    tariff?: Maybe<Scalars['Float']>;
    /** Признак того, что договор является договором по умолчанию */
    isDefault?: Maybe<Scalars['Boolean']>;
    /** Дата начала действия договора */
    startDatetime?: Maybe<Scalars['DateTime']>;
    /** Дата окончания действия договора */
    endDatetime?: Maybe<Scalars['DateTime']>;
    /** Данные для страхования */
    parameters?: Maybe<Scalars['JSONString']>;
    /** Идентификатор редактируемого договора страхования */
    id: Scalars['ID'];
  }

  export interface IEditLoadingUnloadingPlaceInput {
    /** Название места погрузки/разгрузки. Длина (max): 200 */
    pointName?: Maybe<Scalars['String']>;
    /** Населённый пункт. Длина (max): 150 */
    settlement?: Maybe<Scalars['String']>;
    /** Название компании, к которой относится место погрузки/разгрузки. Длина (max): 150 */
    companyName?: Maybe<Scalars['String']>;
    /** Адрес места погрузки/разгрузки. Длина (max): 500 */
    address?: Maybe<Scalars['String']>;
    /** Время начала работы */
    workingHoursFrom?: Maybe<Scalars['Time']>;
    /** Время окончания работы */
    workingHoursTo?: Maybe<Scalars['Time']>;
    /** Возможные типы погрузки/разгрузки */
    loadingTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Тип точки погрузки/доставки. Длина (max): 32 */
    typePoint?: Maybe<Scalars['String']>;
    /** Долгота места погрузки/разгрузки. Длина (max): 20 */
    longitude?: Maybe<Scalars['String']>;
    /** Широта места погрузки/разгрузки. Длина (max): 20 */
    latitude?: Maybe<Scalars['String']>;
    /** Код в системе ФИАС. Длина (max): 100 */
    fiasCode?: Maybe<Scalars['String']>;
    /** Список редактируемых контактных данных */
    contacts?: Maybe<Array<Maybe<IEditContactPersonInput>>>;
    /** ID часового пояса */
    timezoneId?: Maybe<Scalars['ID']>;
    /** Идентификатор редактируемого места погрузки/разгрузки */
    id: Scalars['ID'];
  }

  export interface IEditOrderUploadedDocumentInput {
    /** Загруженные файлы */
    uploadedFileIds?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** ID */
    id: Scalars['ModelID'];
    /** Тип документа */
    documentTypeId?: Maybe<Scalars['ModelID']>;
    /** Загруженный файл */
    uploadedFileId?: Maybe<Scalars['ModelID']>;
  }

  export interface IEditOrderUploadedDocumentsInput {
    /** Документы на добавление */
    documentsToAdd?: Maybe<Array<Maybe<IAddOrderUploadedDocumentInput>>>;
    /** Документы на редактирование */
    documentsToEdit?: Maybe<Array<Maybe<IEditOrderUploadedDocumentInput>>>;
    /** Документы на удаление */
    documentsToDelete?: Maybe<Array<Maybe<Scalars['ModelID']>>>;
    /** Заявка */
    orderId: Scalars['ModelID'];
  }

  export interface IEditOrganizationInput {
    /** Название организации. Длина (max): 300 */
    name?: Maybe<Scalars['String']>;
    /** Подписывающее лицо (Фамилия И. О.). Длина (max): 150 */
    signer?: Maybe<Scalars['String']>;
    /** Описание организации. Длина (max): 300 */
    about?: Maybe<Scalars['String']>;
    /** Телефон организации. Длина (max): 150 */
    phoneNumber?: Maybe<Scalars['String']>;
    /** Электронная почта. Длина (max): 254 */
    email?: Maybe<Scalars['String']>;
    /** КПП. Длина (max): 9 */
    kpp?: Maybe<Scalars['String']>;
    /** Юридический адрес. Длина (max): 300 */
    legalAddress?: Maybe<Scalars['String']>;
    /** Почтовый адрес. Длина (max): 300 */
    postalAddress?: Maybe<Scalars['String']>;
    /** ОГРН/ОГРНИП. Длина (max): 15 */
    registrationNumber?: Maybe<Scalars['String']>;
    /** Идентификатор типа НДС */
    vat?: Maybe<Scalars['ID']>;
    /** Тип оплаты */
    paymentTypeId?: Maybe<Scalars['ID']>;
    /** Сроки оплаты. Длина (max): 5000 */
    paymentPeriod?: Maybe<Scalars['String']>;
    /** Общие параметры аукционов */
    auctionParameters?: Maybe<IAuctionParametersInput>;
    /** Параметр, указывающий на необходимость страховать грузы по всем заявкам организации */
    insuranceParameters?: Maybe<IInsuranceParametersInput>;
    showOrderExternalNo?: Maybe<Scalars['Boolean']>;
    extraConditionsForMarket?: Maybe<Scalars['String']>;
    /** Включить возможность отслеживания доставки по заявке */
    enableDeliveryTracking?: Maybe<Scalars['Boolean']>;
    /** Параметр, отвечающий за поддомен организации */
    subdomain?: Maybe<Scalars['String']>;
    /** Параметр, отвечающий за URL логотипа организации */
    logo?: Maybe<Scalars['Upload']>;
    /** Настройки документооборота */
    documentFlowSettings?: Maybe<Scalars['String']>;
    /** ID компании */
    id: Scalars['ID'];
    /** Является группой компаний */
    isCompanyGroup?: Maybe<Scalars['Boolean']>;
  }

  /** Смена пароля профиля */
  export interface IEditProfileEmailInput {
    /** Текущий пароль */
    currentPassword?: Maybe<Scalars['String']>;
    /** Новый email */
    newEmail?: Maybe<Scalars['String']>;
  }

  /** Редактирование личной информации пользователя */
  export interface IEditProfileInput {
    /** Имя */
    firstName?: Maybe<Scalars['String']>;
    /** Фамилия */
    lastName?: Maybe<Scalars['String']>;
    /** Отчество */
    patronymic?: Maybe<Scalars['String']>;
    /** Номер телефона */
    phoneNumber?: Maybe<Scalars['String']>;
  }

  /** Смена пароля профиля */
  export interface IEditProfilePasswordInput {
    /** Текущий пароль */
    currentPassword?: Maybe<Scalars['String']>;
    /** Новый пароль */
    newPassword?: Maybe<Scalars['String']>;
  }

  /** Настройки профиля пользователя */
  export interface IEditProfileSettingsInput {
    /** Настройки UI */
    uiSettings?: Maybe<Scalars['JSONString']>;
    /** Настройки уведомлений */
    notificationSettings?: Maybe<INotificationSettingsOverallInput>;
    /** Включить/выключить новый дизайн */
    isNewDesign?: Maybe<Scalars['Boolean']>;
  }

  export interface IEditSlaInput {
    /** Идентификатор редактируемого места погрузки/разгрузки */
    id: Scalars['ID'];
    /** Название SLA */
    name?: Maybe<Scalars['String']>;
    /** Описание */
    description?: Maybe<Scalars['String']>;
    /** Пользовательский критерий в SLA */
    nonTrackableCriteria?: Maybe<Array<Maybe<INonTrackableSlaCriterionInput>>>;
    /** Критерий в SLA */
    trackableCriteria?: Maybe<Array<Maybe<IEditTrackableSlaCriterionInput>>>;
  }

  export interface IEditTrackableSlaCriterionInput {
    /** Описание */
    description?: Maybe<Scalars['String']>;
    /** Значение порога отслеживания */
    thresholdValue?: Maybe<Scalars['String']>;
    /** ID системного отслеживаемого критерия */
    criterionId?: Maybe<Scalars['ID']>;
    /** Признак того что критерий в SLA является активным */
    isActive?: Maybe<Scalars['Boolean']>;
    /** ID отслеживаемого критерия в SLA */
    id?: Maybe<Scalars['ID']>;
  }

  export enum IEdmsType {
    Diadoc = 'DIADOC',
  }

  /** Сотрудник организации */
  export interface IEmployeeType {
    id: Scalars['ID'];
    /** Адрес электронной почты пользователя */
    email: Scalars['String'];
    /** Имя пользователя */
    firstName: Scalars['String'];
    /** Фамилия пользователя */
    lastName: Scalars['String'];
    phoneNumber?: Maybe<Scalars['String']>;
    lastLogin?: Maybe<Scalars['DateTime']>;
    /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
    isActive: Scalars['Boolean'];
    dateJoined: Scalars['DateTime'];
    /** Организация, к которой относится пользователь */
    organization?: Maybe<ICargoOwnerOrganizationType>;
    /** Отчество пользователя */
    patronymic?: Maybe<Scalars['String']>;
    /** Статус электронной почты */
    emailStatus: ICustomUserEmailStatus;
    /** Роль */
    role?: Maybe<Scalars['String']>;
    /** Название организации, к которой относится пользователь */
    companyName?: Maybe<Scalars['String']>;
    /** Список прав пользователя в системе */
    permissions?: Maybe<Array<Maybe<IPermissionType>>>;
  }

  export interface IEventAttachedDoc {
    /** Наименование прикрепленного документа */
    filename?: Maybe<Scalars['String']>;
    /** URL прикрепленного документа */
    url?: Maybe<Scalars['String']>;
    /** Дата и время, когда был загружен документ */
    datetime?: Maybe<Scalars['String']>;
  }

  export interface IExtraServicesType {
    id: Scalars['ID'];
    /** Название дополнительной услуги */
    name: Scalars['String'];
    /** Стоимость доп. услуги */
    price?: Maybe<Scalars['Float']>;
    /** Сумма НДС */
    vatAmount?: Maybe<Scalars['Float']>;
  }

  export interface IGrapheneLoadingType {
    /** Id типа погрузки */
    id?: Maybe<Scalars['ID']>;
    /** Название типа погрузки */
    name?: Maybe<Scalars['String']>;
  }

  export interface IGrapheneVehicleBodyType {
    /** Тип кузова */
    bodyType?: Maybe<Scalars['String']>;
    /** Подтип кузова */
    bodySubtype?: Maybe<Scalars['String']>;
    /** Грузоподъемность */
    capacity?: Maybe<Scalars['Float']>;
    /** Объем */
    volume?: Maybe<Scalars['Float']>;
    /** Типы погрузки */
    loadingTypes?: Maybe<Array<Maybe<IGrapheneLoadingType>>>;
  }

  export interface IGrapheneVehicleTrailerType {
    /** Тип прицепа/полуприцепа */
    bodyType?: Maybe<Scalars['String']>;
    /** Подтип прицепа/полуприцепа */
    bodySubtype?: Maybe<Scalars['String']>;
    /** Регистрационный номер прицепа/полуприцепа */
    regNo?: Maybe<Scalars['String']>;
    /** Грузоподъемность */
    capacity?: Maybe<Scalars['Float']>;
    /** Объем */
    volume?: Maybe<Scalars['Float']>;
    /** Типы погрузки */
    loadingTypes?: Maybe<Array<Maybe<IGrapheneLoadingType>>>;
  }

  /** Информация о транспортном средстве */
  export interface IGrapheneVehicleType {
    /** ID ТС */
    id?: Maybe<Scalars['ID']>;
    /** Тип ТС */
    vehicleType?: Maybe<Scalars['String']>;
    /** Марка ТС */
    vehicleMake?: Maybe<Scalars['String']>;
    /** Номер свидетельства о регистрации ТС */
    vehicleCertificate?: Maybe<Scalars['String']>;
    /** Номер государственной регистрации ТС */
    regNo?: Maybe<Scalars['String']>;
    /** VIN */
    vehicleVin?: Maybe<Scalars['String']>;
    /** Кузов ТС */
    vehiclebody?: Maybe<IGrapheneVehicleBodyType>;
    /** Прицеп ТС */
    vehicletrailer?: Maybe<IGrapheneVehicleTrailerType>;
    /** Статус верификации ТС */
    verificationStatus?: Maybe<Scalars['String']>;
  }

  export interface IHeadOfOrganizationType {
    id: Scalars['ID'];
    /** Фамилия */
    surname?: Maybe<Scalars['String']>;
    /** Имя */
    name?: Maybe<Scalars['String']>;
    /** Отчество */
    patronymic?: Maybe<Scalars['String']>;
    /** ИНН */
    inn?: Maybe<Scalars['String']>;
    /** Серия паспорта */
    passportSeries?: Maybe<Scalars['String']>;
    /** Номер паспорта */
    passportNumber?: Maybe<Scalars['String']>;
    /** Дата выдачи паспорта */
    passportDate?: Maybe<Scalars['Date']>;
  }

  export interface IInsuranceCompanyType {
    /** Код страховой компании */
    codename?: Maybe<Scalars['String']>;
    /** Название страховой компании */
    name?: Maybe<Scalars['String']>;
  }

  export interface IInsuranceContractType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    parameters?: Maybe<Scalars['JSONString']>;
    /** Номер генерального договора */
    generalNumber?: Maybe<Scalars['String']>;
    /** Тариф */
    tariff: Scalars['Float'];
    /** Дата и время начала действия договора */
    startDatetime: Scalars['DateTime'];
    /** Дата и время завершения действия договора */
    endDatetime?: Maybe<Scalars['DateTime']>;
    /** Договор по умолчанию */
    isDefault: Scalars['Boolean'];
    /** Страховая компания */
    insuranceCompany?: Maybe<IInsuranceCompanyType>;
  }

  export interface IInsuranceInitialInfoType {
    /** JSON schema для входных данных страхования */
    schema?: Maybe<Scalars['JSONString']>;
    /** Значения (key-value) для полей формы */
    fieldValues?: Maybe<Scalars['JSONString']>;
    /** Значения по умолчанию для полей формы */
    defaultValues?: Maybe<Scalars['JSONString']>;
  }

  export interface IInsuranceParametersInput {
    /** Параметр, указывающий на необходимость страховать грузы по всем заявкам организации */
    insureByDefault?: Maybe<Scalars['Boolean']>;
  }

  export interface IInsuranceParametersType {
    /** Страховать груз по всем заявкам */
    insureByDefault: Scalars['Boolean'];
  }

  export interface IInsuranceReferenceType {
    /** Код записи справочника */
    code?: Maybe<Scalars['String']>;
    /** Наименование записи справочника */
    value?: Maybe<Scalars['String']>;
  }

  /** An enumeration. */
  export enum ILegalFormCode {
    /** ООО */
    A_12300 = 'A_12300',
    /** ПАО */
    A_12247 = 'A_12247',
    /** НАО */
    A_12267 = 'A_12267',
    /** ИП */
    A_50102 = 'A_50102',
    /** Самозанятый */
    A_00000 = 'A_00000',
  }

  /** Организационно-правовая форма */
  export interface ILegalFormType {
    id: Scalars['ID'];
    /** Сокращённое название организационно-правовой формы */
    shortName: Scalars['String'];
    /** Название организационно-правовой формы */
    name: Scalars['String'];
    /** Код общеросийского классификатора организационно-правовой формы */
    code: ILegalFormCode;
  }

  export interface ILoadingTypeType {
    id: Scalars['ID'];
    /** Наименование типа погрузки/разгрузки */
    name: Scalars['String'];
  }

  /** Заносимая информация о месте погрузки/разгрузки */
  export interface ILoadingUnloadingPlaceInput {
    /** Название места погрузки/разгрузки. Длина (max): 200 */
    pointName?: Maybe<Scalars['String']>;
    /** Населённый пункт. Длина (max): 150 */
    settlement?: Maybe<Scalars['String']>;
    /** Название компании, к которой относится место погрузки/разгрузки. Длина (max): 150 */
    companyName?: Maybe<Scalars['String']>;
    /** Адрес места погрузки/разгрузки. Длина (max): 500 */
    address?: Maybe<Scalars['String']>;
    /** Время начала работы */
    workingHoursFrom: Scalars['Time'];
    /** Время окончания работы */
    workingHoursTo: Scalars['Time'];
    /** Возможные типы погрузки/разгрузки */
    loadingTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Тип точки погрузки/доставки. Длина (max): 32 */
    typePoint: Scalars['String'];
    /** Долгота места погрузки/разгрузки. Длина (max): 20 */
    longitude?: Maybe<Scalars['String']>;
    /** Широта места погрузки/разгрузки. Длина (max): 20 */
    latitude?: Maybe<Scalars['String']>;
    /** Код в системе ФИАС. Длина (max): 100 */
    fiasCode?: Maybe<Scalars['String']>;
    /** Заносимая информация о контактном лице на месте погрузки/разгрузки */
    contacts?: Maybe<Array<Maybe<IContactPersonInput>>>;
    /** ID часового пояса */
    timezoneId?: Maybe<Scalars['ID']>;
  }

  export interface ILotHistoryType {
    /** Ставка */
    bet: Scalars['Int'];
    /** Перевозчик, сделавший ставку */
    carrier: ICarrierUserType;
    /** Дата и время добавления ставки */
    added: Scalars['DateTime'];
  }

  export interface ILotInput {
    /** Дата и время начала аукциона */
    startDatetime: Scalars['DateTime'];
    /** Дата и время окончания аукциона */
    endDatetime: Scalars['DateTime'];
    /** Начальная цена */
    startPrice: Scalars['Int'];
    /** Уведомления о ходе торгов включены */
    enableNotifications?: Maybe<Scalars['Boolean']>;
    /** Минимальный шаг торгов */
    minStepPrice?: Maybe<Scalars['Int']>;
    /** Включить возможность переторжки */
    isProlongationAvailable?: Maybe<Scalars['Boolean']>;
    /** Время в минутах, на которое продлевается аукцион */
    minutesOfProlongation?: Maybe<Scalars['Int']>;
    /** Сколько раз можно продлевать аукцион */
    timesOfProlongation?: Maybe<Scalars['Int']>;
    /** Время в минутах до конца аукциона, в которое может быть начата переторжка */
    minutesTillEndToProlong?: Maybe<Scalars['Int']>;
  }

  /** An enumeration. */
  export enum ILotStepPriceType {
    /** Простой */
    Simple = 'SIMPLE',
    /** Расчётный */
    Calculated = 'CALCULATED',
  }

  export interface ILotType {
    order: IDraftOrderType;
    /** Дата и время начала аукциона */
    startDatetime: Scalars['DateTime'];
    /** Дата и время завершения аукциона */
    endDatetime: Scalars['DateTime'];
    /** Исходная цена */
    startPrice: Scalars['Int'];
    /** Последняя ставка */
    lastBet?: Maybe<Scalars['Int']>;
    /** Перевозчик, сделавший последнюю ставку */
    lastBetCarrier?: Maybe<ICarrierUserType>;
    /** Дата и время когда была сделана последняя ставка */
    lastBetDatetime?: Maybe<Scalars['DateTime']>;
    /** Отслеживать ход торгов */
    enableNotifications: Scalars['Boolean'];
    /** Сколько раз была переторжка */
    quantityOfProlongations: Scalars['Int'];
    /** Тип шага цены: простой или расчётный */
    stepPriceType: ILotStepPriceType;
    /** Минимальный шаг торгов */
    minStepPrice: Scalars['Int'];
    /** Переторжка для аукционов включена */
    isProlongationAvailable: Scalars['Boolean'];
    /** Время в минутах, на которое продлевается аукцион */
    minutesOfProlongation: Scalars['Int'];
    /** Сколько раз можно продлевать аукцион */
    timesOfProlongation: Scalars['Int'];
    /** Время в минутах до конца аукциона, в которое может быть начата переторжка */
    minutesTillEndToProlong: Scalars['Int'];
    /** Дополнительные условия по рыночной заявке */
    extraConditionsForMarket?: Maybe<Scalars['String']>;
    /** Заявка имеет дополнительные условия по рынку для перевозки */
    hasExtraConditionsForMarket: Scalars['Boolean'];
    id?: Maybe<Scalars['ID']>;
    /** Тип аукциона */
    type?: Maybe<Scalars['String']>;
    auctionStatus?: Maybe<Scalars['String']>;
    /** Можно ли сейчас продлить аукцион */
    mayBeProlonged?: Maybe<Scalars['Boolean']>;
    /** Время до завершения аукциона */
    viewEndDatetime?: Maybe<Scalars['DateTime']>;
    /** История для аукциона */
    history?: Maybe<Array<Maybe<ILotHistoryType>>>;
    /** Количество участвующих в аукционе организаций */
    participantOrganizationsCount?: Maybe<Scalars['Int']>;
  }

  export interface INonTrackableSlaCriterionInput {
    /** Название критерия */
    name: Scalars['String'];
    /** Описание */
    description: Scalars['String'];
  }

  export interface INonTrackableSlaCriterionType {
    /** Название критерия */
    name?: Maybe<Scalars['String']>;
    /** Описание */
    description?: Maybe<Scalars['String']>;
  }

  /** Настройки уведомлений */
  export interface INotificationSettingsInput {
    /** Уведомление через веб-сокеты */
    web?: Maybe<Scalars['Boolean']>;
    /** Уведомление по электронной почте */
    email?: Maybe<Scalars['Boolean']>;
    /** Уведомление по SMS */
    sms?: Maybe<Scalars['Boolean']>;
    /** Уведомление на мобильное приложение */
    mobilePush?: Maybe<Scalars['Boolean']>;
  }

  /** Настройки уведомлений пользователя */
  export interface INotificationSettingsOverallInput {
    /** Аукционы */
    auctionOrder?: Maybe<INotificationSettingsInput>;
    /** Свободные заявки */
    freeOrder?: Maybe<INotificationSettingsInput>;
    /** Назначенные заявки */
    assignedOrder?: Maybe<INotificationSettingsInput>;
    /** Выполнение заявки */
    completeOrder?: Maybe<INotificationSettingsInput>;
    /** Торги на повышение */
    biddingOrder?: Maybe<INotificationSettingsInput>;
    /** Тендеры */
    tenderOrder?: Maybe<INotificationSettingsInput>;
    /** Отмена заявок */
    cancelOrder?: Maybe<INotificationSettingsInput>;
    /** Запрос цены */
    priceQuotation?: Maybe<INotificationSettingsInput>;
    /** Оформление документов */
    documentProcessing?: Maybe<INotificationSettingsInput>;
  }

  /** Настройки уведомлений пользователя */
  export interface INotificationSettingsOverallType {
    /** Аукционы */
    auctionOrder?: Maybe<INotificationSettingsType>;
    /** Свободные заявки */
    freeOrder?: Maybe<INotificationSettingsType>;
    /** Назначенные заявки */
    assignedOrder?: Maybe<INotificationSettingsType>;
    /** Выполнение заявки */
    completeOrder?: Maybe<INotificationSettingsType>;
    /** Торги на повышение */
    biddingOrder?: Maybe<INotificationSettingsType>;
    /** Тендеры */
    tenderOrder?: Maybe<INotificationSettingsType>;
    /** Отмена заявок */
    cancelOrder?: Maybe<INotificationSettingsType>;
    /** Запрос цены */
    priceQuotation?: Maybe<INotificationSettingsType>;
    /** Оформление документов */
    documentProcessing?: Maybe<INotificationSettingsType>;
    /** Критерии обслуживания */
    slaCriteria?: Maybe<INotificationSettingsType>;
  }

  /** Настройки уведомлений */
  export interface INotificationSettingsType {
    /** Уведомление через веб-сокеты */
    web?: Maybe<Scalars['Boolean']>;
    /** Уведомление по электронной почте */
    email?: Maybe<Scalars['Boolean']>;
    /** Уведомление по SMS */
    sms?: Maybe<Scalars['Boolean']>;
    /** Уведомление на мобильное приложение */
    mobilePush?: Maybe<Scalars['Boolean']>;
  }

  export interface INotificationType {
    id: Scalars['ID'];
    eventDatetime: Scalars['DateTime'];
    title: Scalars['String'];
    text: Scalars['String'];
    topic?: Maybe<Scalars['String']>;
    action?: Maybe<Scalars['String']>;
    payload?: Maybe<Scalars['JSONString']>;
    isRead: Scalars['Boolean'];
  }

  /** An enumeration. */
  export enum IOrderAllocationType {
    /** Простая заявка */
    SimpleAllocation = 'SIMPLE_ALLOCATION',
    /** Аукционная заявка */
    AuctionAllocation = 'AUCTION_ALLOCATION',
    /** Назначенная */
    PreassignedAllocation = 'PREASSIGNED_ALLOCATION',
    /** Торги на повышение */
    BiddingAllocation = 'BIDDING_ALLOCATION',
  }

  export interface IOrderAttributesType {
    /** Необходимо наличие успешной проверки грузоперевозчика для его работы с заявкой */
    isScored: Scalars['Boolean'];
    /** Необходимо наличие ЭЦП (электронно-цифровой подписи) грузоперевозчика для его работы с заявкой */
    hasDigitalSignature: Scalars['Boolean'];
    /** Необходимо наличие собственных ТС у грузоперевозчика для его работы с заявкой */
    hasOwnVehicle: Scalars['Boolean'];
    /** По заявке начат процесс смены водителя и ТС */
    isDriverAndVehicleChanging: Scalars['Boolean'];
    /** Таймер смены водителя и ТС остановлен */
    isDriverAndVehicleChangeTimerOnPause?: Maybe<Scalars['Boolean']>;
    /** Оставшееся время для смены водителя и ТС(в минутах) */
    driverAndVehicleChangeTimeLeft?: Maybe<Scalars['Int']>;
  }

  export interface IOrderDocumentTypeType {
    id: Scalars['ID'];
    /** Название типа документа */
    name: Scalars['String'];
    innerName?: Maybe<Scalars['String']>;
    /** Тип виден для graphql */
    isVisible: Scalars['Boolean'];
    /** Документ этого типа может состоять из нескольких файлов */
    canHaveSeveralFiles: Scalars['Boolean'];
  }

  /** An enumeration. */
  export enum IOrderInsuranceStatus {
    /** Ожидание назначения ТС */
    WaitingTransport = 'WAITING_TRANSPORT',
    /** Приготовлено для страхования */
    Prepared = 'PREPARED',
    /** Выполняется */
    InProgress = 'IN_PROGRESS',
    /** Застраховано */
    Insured = 'INSURED',
    /** Отклонено */
    Rejected = 'REJECTED',
    /** Отменён */
    Canceled = 'CANCELED',
    /** Ошибка */
    Error = 'ERROR',
  }

  export interface IOrderInsuranceType {
    /** Договор с СК */
    insuranceContract?: Maybe<IInsuranceContractType>;
    /** Статус */
    status: IOrderInsuranceStatus;
    /** URL страхового сертификата */
    certificateUrl?: Maybe<Scalars['String']>;
    /** URL счета на оплату страхового сертификата */
    invoiceUrl?: Maybe<Scalars['String']>;
    /** Данные для страхования */
    data?: Maybe<Scalars['JSONString']>;
    /** Застраховал ли ГО заявку */
    isOwnerInsurance?: Maybe<Scalars['Boolean']>;
  }

  /** An enumeration. */
  export enum IOrderSigningWay {
    /** Приложение скана заявки */
    OrderScan = 'ORDER_SCAN',
    /** Приложение файла ЭЦП */
    DigitalSignature = 'DIGITAL_SIGNATURE',
    /** Подписание через Tn-crypto организациями */
    TnCrypto = 'TN_CRYPTO',
  }

  /** An enumeration. */
  export enum IOrderStatus {
    /** Доступно */
    Free = 'FREE',
    /** Назначен перевозчик */
    Assigned = 'ASSIGNED',
    /** Завершено */
    Completed = 'COMPLETED',
    /** Отменено */
    Canceled = 'CANCELED',
    /** Транспорт зарезервирован */
    TransportReserved = 'TRANSPORT_RESERVED',
    /** Заявка-договор подписана и загружена */
    ContractAttached = 'CONTRACT_ATTACHED',
    /** Оформление заявки завершено, заказ готов к перевозке */
    ReadyToGo = 'READY_TO_GO',
  }

  /**
   * READY_TO_GO - в исполнении/без МЛ
   * NEW - в исполнении
   * DRIVER_CONFIRMED - водитель подтвержден
   * ON_ROUTE - транспорт выехал на маршрут (по статусу МЛ)
   * DEPARTURE_ON_ROUTE - транспорт выехал на маршрут (по событию из МЛ)
   * DELIVERY_COMPLETED - доставка завершена
   * CANCELED - заявка отменена
   * EMPTY - None
   */
  export enum IOrderStatusOnEvent {
    ReadyToGo = 'READY_TO_GO',
    New = 'NEW',
    DriverConfirmed = 'DRIVER_CONFIRMED',
    OnRoute = 'ON_ROUTE',
    DepartureOnRoute = 'DEPARTURE_ON_ROUTE',
    DeliveryCompleted = 'DELIVERY_COMPLETED',
    Canceled = 'CANCELED',
    Empty = 'EMPTY',
  }

  export interface IOrderStoragePointAttachedDocumentType {
    /** Наименование прикрепленного документа */
    filename?: Maybe<Scalars['String']>;
    /** URL прикрепленного документа */
    url?: Maybe<Scalars['String']>;
  }

  export interface IOrderStoragePointContactPerson {
    fullName?: Maybe<Scalars['String']>;
    phoneNumber?: Maybe<Scalars['String']>;
  }

  /** Заносимая информация о местах погрузки/доставки в заявке */
  export interface IOrderStoragePointInput {
    /** ID места погрузки/доставки */
    storagePoint: Scalars['ID'];
    /** Ожидаемые дата и начало периода времени прибытия транспорта в указанном в заявке месте погрузки/доставки */
    arriveDatetimeFrom?: Maybe<Scalars['DateTime']>;
    /** Ожидаемые дата и окончание периода времени прибытия транспорта в указанном в заявке месте погрузки/доставки */
    arriveDatetimeTo?: Maybe<Scalars['DateTime']>;
    /** Тип погрузки/разгрузки */
    loadingType?: Maybe<Scalars['ID']>;
    /** Порядковый номер погрузки/разгрузки */
    seqId?: Maybe<Scalars['ID']>;
    /** Устаревший. Используйте arriveDatetimeFrom */
    workDatetime?: Maybe<Scalars['DateTime']>;
    /** Комментарий к точке маршрута */
    comment?: Maybe<Scalars['String']>;
    /** Контактное лицо на точке погрузки/доставки */
    contactPersonAtStoragePoint?: Maybe<Scalars['String']>;
    /** Контактный телефон на точке погрузки/доставки */
    contactPhoneAtStoragePoint?: Maybe<Scalars['String']>;
    /** Контактное лицо на точке погрузки/доставки */
    contactPersons?: Maybe<Array<Maybe<IOrderStoragePointContactPerson>>>
  }

  /** Указанное в заявке место погрузки/доставки */
  export interface IOrderStoragePointType {
    id: Scalars['ID'];
    /** Указанные в заявке дата и предполагаемое время начала периода прибытия транспорта */
    arriveDatetimeFrom?: Maybe<Scalars['DateTime']>;
    /** Указанные в заявке дата и предполагаемое время окончания периода прибытия транспорта */
    arriveDatetimeTo?: Maybe<Scalars['DateTime']>;
    /** Фактическое время прибытия на точку доставки */
    factArriveDatetimeFrom?: Maybe<Scalars['DateTime']>;
    /** Фактическое время оканчания работы на точке доставки */
    factArriveDatetimeTo?: Maybe<Scalars['DateTime']>;
    /** Комментарий к месту погрузки/разгрузки */
    comment?: Maybe<Scalars['String']>;
    /** Порядковый номер погрузки/разгрузки в рамках заявки */
    seqId?: Maybe<Scalars['Int']>;
    storagePoint?: Maybe<IStoragePointDataType>;
    /** Deprecated! Стоит использовать arrive_datetime_from */
    workDatetime?: Maybe<Scalars['DateTime']>;
    /** Контактное лицо на точке погрузки/доставки */
    contactPersonAtStoragePoint?: Maybe<Scalars['String']>;
    /** Контактный телефон на точке погрузки/доставки */
    contactPhoneAtStoragePoint?: Maybe<Scalars['String']>;
    /** Контактное лицо на точке погрузки/доставки */
    contactPersons?: Maybe<Array<Maybe<IOrderStoragePointContactPerson>>>
    /** Тип погрузки/разгрузки */
    loadingType?: Maybe<ILoadingTypeType>;
    /** Список прикрепленных файлов */
    attachedDocuments?: Maybe<Array<Maybe<IOrderStoragePointAttachedDocumentType>>>;
  }

  export interface IOrderType {
    id: Scalars['ID'];
    /** Дата и время создания заявки */
    createdDatetime: Scalars['DateTime'];
    /** Дата и время последней модификации заявки */
    lastModifiedDatetime: Scalars['DateTime'];
    cargoType?: Maybe<ICargoTypeType>;
    /** Вес груза */
    weight?: Maybe<Scalars['Float']>;
    /** Объём груза */
    volume?: Maybe<Scalars['Float']>;
    /** Цена */
    price?: Maybe<IPriceType>;
    /** Комментарий к заявке */
    comment: Scalars['String'];
    /** Статус заявки */
    status: IOrderStatus;
    /** Информация о грузоперевозчике */
    carrier?: Maybe<ICarrierType>;
    /** Информация о водителе */
    driverInfo: Scalars['String'];
    /** Информация об автомобиле */
    truckInfo: Scalars['String'];
    /** Информация о прицепе */
    trailerInfo: Scalars['String'];
    /** Получение информации о сменном водителе */
    coDriver?: Maybe<IDriverType>;
    /** Дата и время назначения перевозчика на заявку */
    carrierAssignedDatetime?: Maybe<Scalars['DateTime']>;
    /** Дата и время указания информации о водителе, автомобиле и прицепе */
    transportReservedDatetime?: Maybe<Scalars['DateTime']>;
    /** Дата и время загрузки скана заявки-договора */
    contractAttachedDatetime?: Maybe<Scalars['DateTime']>;
    /** Дата и время завершения оформления заявки */
    readyToGoDatetime?: Maybe<Scalars['DateTime']>;
    /** Тип размещения заявки */
    allocationType: IOrderAllocationType;
    cargoPackagesCount?: Maybe<Scalars['Int']>;
    /** Виды номенклатуры по всем грузам заявки */
    cargoNomenclatureTypes: Scalars['String'];
    /** Цена включает НДС */
    isVatIncluded: Scalars['Boolean'];
    /** Признак необходимости отслеживать заявку */
    trackingRequired: Scalars['Boolean'];
    /** Признак рыночной заявки */
    isMarket?: Maybe<Scalars['Boolean']>;
    /** Номер заявки во внешней системе */
    externalNo?: Maybe<Scalars['String']>;
    /** Сроки оплаты */
    paymentPeriod?: Maybe<Scalars['String']>;
    /** Организация, указанная в качестве заказчика перевозки */
    customerOrganization?: Maybe<ICargoOwnerOrganizationType>;
    /** Признак того, каким образом происходит подписание заявок */
    signingWay: IOrderSigningWay;
    /** Температурный режим (градусы Цельсия) */
    temperatureMode?: Maybe<Scalars['Int']>;
    /** Заявка только на просмотр, используется в интеграции с 1С (витрина) */
    isReadonly: Scalars['Boolean'];
    /**
     * Время завершения в заявке
     * @deprecated Для web и мобильных клиентов этот параметр заменен на viewEndDatetime.
     */
    endDatetime?: Maybe<Scalars['DateTime']>;
    /** Дедлайн для обработки назначенной заявки */
    processDeadline?: Maybe<Scalars['DateTime']>;
    /** Время на бронирование свободной заявки, указанная для текущей заявки, в минутах */
    timeOrderReservation?: Maybe<Scalars['Int']>;
    /** Количество участников торговой процедуры */
    tradingParticipantsCount?: Maybe<Scalars['Int']>;
    /** Дополнительное время на обработку назначенной заявки, в секундах */
    gracePeriod?: Maybe<Scalars['Int']>;
    /** Отгрузка обязательна в согласованную с покупателем дату */
    isShipmentOnAgreedDate: Scalars['Boolean'];
    /** Часть перевозок помечены таким флагом. Не означает, что все перевозки для заказчиков DIY помечены */
    diy: Scalars['Boolean'];
    /** Признак того, что в заявке не нарушен ни один критерий SLA */
    slaObserved: Scalars['Boolean'];
    lot?: Maybe<ILotType>;
    /** Страховка */
    insurance?: Maybe<IOrderInsuranceType>;
    /** Фактическая организация-перевозчик (назначается ТЭКом), осуществляющая перевозку */
    actualCarrierOrganization?: Maybe<Scalars['String']>;
    /** Список мест погрузки указанной заявки */
    loadingPlaces?: Maybe<Array<Maybe<IOrderStoragePointType>>>;
    /** Список мест доставки указанной заявки */
    unloadingPlaces?: Maybe<Array<Maybe<IOrderStoragePointType>>>;
    /** Получение информации о первом месте погрузки в заявке */
    loadingPlace?: Maybe<IStoragePointDataType>;
    /** Дата погрузки */
    loadingDate?: Maybe<Scalars['Date']>;
    /** Контактное лицо на первом месте погрузки в заявке */
    contactPersonAtLoadingPlace?: Maybe<Scalars['String']>;
    /** Получение информации о первом месте доставки в заявке */
    unloadingPlace?: Maybe<IStoragePointDataType>;
    /** Дата доставки */
    unloadingDate?: Maybe<Scalars['Date']>;
    /** Контактное лицо на первом месте доставки в заявке */
    contactPersonAtUnloadingPlace?: Maybe<Scalars['String']>;
    /** Получение информации о требованиях к транспортному средству */
    vehicleRequirements?: Maybe<IVehicleRequirementsType>;
    /** Получение информации о требованиях к грузовому месту */
    cargoPlaceRequirements?: Maybe<ICargoPlaceRequirementsType>;
    /** Цена с НДС */
    priceWithVat?: Maybe<IPriceType>;
    /** Цена без НДС */
    priceWithoutVat?: Maybe<IPriceType>;
    /** Круг перевозчиков */
    carrierGroup?: Maybe<ICarrierGroupType>;
    /** Тип оплаты */
    paymentType?: Maybe<IPaymentTypeType>;
    /** Статус заявки по событиям из МЛ */
    statusOnEvent?: Maybe<Scalars['String']>;
    /** Время завершения, которое рассчитывается для web-клиента, в зависимости от таймера в заявке */
    viewEndDatetime?: Maybe<Scalars['DateTime']>;
    /** Дедлайн для обработки назначенной заявки,который рассчитывается для web-клиента, в зависимости от таймера в заявке */
    viewProcessDeadline?: Maybe<Scalars['DateTime']>;
    /** Дедлайн для того чтобы забрать свободную заявку или сделать ставку для аукционной заявки,который рассчитывается для web-клиента, в зависимости от таймера в заявке */
    acceptDeadline?: Maybe<Scalars['DateTime']>;
    /**
     * Время до завершения заявки по времени(в секундах)
     * @deprecated Этот параметр в следующих версиях будет заменен на secondsToLifeTimeExpired.
     */
    secondsToCancel?: Maybe<Scalars['Int']>;
    /** Время до завершения заявки по времени жизни(в секундах) */
    secondsToLifeTimeExpired?: Maybe<Scalars['Int']>;
    /** Время до завершения заявки по времени бронирования(в секундах) */
    secondsToReservationTimeExpired?: Maybe<Scalars['Int']>;
    /** Может ли пользователь редактировать список загруженных документов */
    canModifyUploadedDocuments?: Maybe<Scalars['Boolean']>;
    /** Загруженные документы */
    uploadedDocuments?: Maybe<Array<Maybe<IOrderUploadedDocumentType>>>;
    /** Загруженные документы */
    uploadedDocumentsV2?: Maybe<Array<Maybe<IOrderUploadedDocumentType>>>;
    /** Дополнительные услуги по заявке */
    extraServices?: Maybe<Array<Maybe<IExtraServicesType>>>;
    /** Список типов файлов, которые необходимо подписывать */
    settingsESignRequired?: Maybe<Array<Maybe<IOrderDocumentTypeType>>>;
    /** Маршрутный лист по заявке */
    routeSheet?: Maybe<IRouteSheetType>;
    /** Текущий статус документа */
    documentActualStatus?: Maybe<Array<Maybe<IDocumentStatusType>>>;
    /** История статус по документу */
    documentStatusHistory?: Maybe<Array<Maybe<IDocumentStatusType>>>;
    /** Грузовладелец, создавший заявку (пользователь) */
    owner?: Maybe<ICargoOwnerType>;
    /** Дополнительные настройки заявки */
    orderAttributes?: Maybe<IOrderAttributesType>;
    /** Водитель */
    driver?: Maybe<IDriverDataType>;
    /** Транспортное средство */
    vehicle?: Maybe<IVehicleDataType>;
    /** Информация о прицепе/полуприцепе */
    vehicleTrailer?: Maybe<IVehicleTrailerDataType>;
    /** Для завершения заявки необходимо указать информацию по транспортным накладнымдля всех мест доставки */
    waybillRequired?: Maybe<Scalars['Boolean']>;
    /** Информация для всех транспортных накладных указана */
    hasWaybillInfo?: Maybe<Scalars['Boolean']>;
    /** Перевозка осуществляется собственным транспортным средством ТЭКа (или экспедитора) */
    agentUsesOwnVehicle?: Maybe<Scalars['Boolean']>;
    /** Расшифровка фрахта и агентского вознаграждения в заявке с НДС и без НДС */
    agentFeeAndFreightDetails?: Maybe<IAgentFeeAndFreightDetails>;
    /** Генеральный договор с перевозчиком */
    carrierContract?: Maybe<ICarrierContractType>;
    /** Типы погрузки/разгрузки */
    loadingTypes?: Maybe<Scalars['String']>;
    /** Внешний ID заявки */
    extId?: Maybe<Scalars['String']>;
    /** Связанный тендер */
    tender?: Maybe<ITenderType>;
    /** Уровень обслуживания для заявки */
    sla?: Maybe<ISlaType>;
    /** Информация о фактической организации-перевозчике */
    agentCarrierInfo?: Maybe<IAgentCarrierInfoType>;
    /** Количество ставок, сделанных для данного аукциона */
    betsCount?: Maybe<Scalars['Int']>;
    /** Информация о фактической организации-перевозчике */
    cargos?: Maybe<Array<Maybe<ICargoType>>>;
    /** Информация о грузах */
    cargoInfo?: Maybe<Scalars['JSONString']>;
    /** Настройки документооборота */
    documentFlowSettings?: Maybe<IDocumentFlowSettingsEnum>;
    /** Статус документооборота */
    edmStatus?: Maybe<Scalars['String']>;
  }

  export type IOrderTypeLoadingPlaceArgs = {
    id?: Maybe<Scalars['ID']>;
  };

  export type IOrderTypeUnloadingPlaceArgs = {
    id?: Maybe<Scalars['ID']>;
  };

  export type IOrderTypeStatusOnEventArgs = {
    value?: Maybe<IOrderStatusOnEvent>;
  };

  export type IOrderTypeDocumentActualStatusArgs = {
    documentType?: Maybe<IDocumentTypeInnerNameEnum>;
  };

  export type IOrderTypeDocumentStatusHistoryArgs = {
    documentType?: Maybe<IDocumentTypeInnerNameEnum>;
  };

  export interface IOrderUploadedDocumentFileType {
    /** id загруженного файла */
    id?: Maybe<Scalars['ID']>;
    /** Документ */
    document: IOrderUploadedDocumentType;
    /** Загруженный файл */
    uploadedFile: IUploadedFileType;
    /** Дата загрузки файла пользователем */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Название файла */
    name?: Maybe<Scalars['String']>;
    /** Ссылка для скачивания файла */
    url?: Maybe<Scalars['String']>;
  }

  export interface IOrderUploadedDocumentType {
    id: Scalars['ID'];
    /** Организация-владелец */
    organization: ICargoOwnerOrganizationType;
    /** Тип документа */
    documentType: IOrderDocumentTypeType;
    /** Загруженный файл */
    uploadedFile?: Maybe<IUploadedFileType>;
    /** Подписываемый файл */
    signedFile?: Maybe<IOrderUploadedDocumentType>;
    /** Может ли пользователь удалить документ */
    deletable?: Maybe<Scalars['Boolean']>;
    /** Информация по транспортной накладной */
    waybillInfo?: Maybe<IWaybillInfoType>;
    /** Информация по заявке-договору */
    contractInfo?: Maybe<IContractInfoType>;
    /** Загруженные файлы */
    uploadedFiles?: Maybe<Array<Maybe<IOrderUploadedDocumentFileType>>>;
  }

  export interface IOrderVehicleRequirementsInput {
    /** Требования к ТС: грузоподъемность в тоннах */
    capacity?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Объём кузова в м3 */
    volume?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Длина кузова */
    length?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Ширина кузова */
    width?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Высота кузова */
    height?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Список из ID разрешённых типов погрузки */
    loadingTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Метод доставки: FTL/LTL */
    deliveryMethod?: Maybe<IDeliveryMethodEnum>;
    /** Температурный режим (нижняя граница) */
    temperatureModeFrom?: Maybe<Scalars['Int']>;
    /** Температурный режим (верхняя граница) */
    temperatureModeTo?: Maybe<Scalars['Int']>;
    /** Требования к ТС: Тип кузова транспортного средства */
    bodySubtype?: Maybe<Scalars['ID']>;
  }

  /** An enumeration. */
  export enum IOrganizationDocumentFlowSettings {
    /** Грузовладелец первым подписывает договор */
    CargoOwnerFirst = 'CARGO_OWNER_FIRST',
    /** Договор подписывает только грузоперевозчик */
    CarrierOnly = 'CARRIER_ONLY',
  }

  /** An enumeration. */
  export enum IOrganizationDocumentTypeInnerName {
    /** Документ о собственности/аренде офисного помещения */
    OwnershipDoc = 'OWNERSHIP_DOC',
    /** Открытая часть электронной подписи */
    OpenDigitSign = 'OPEN_DIGIT_SIGN',
  }

  /** An enumeration. */
  export enum IOrganizationRole {
    /** Грузовладелец */
    CargoOwner = 'CARGO_OWNER',
    /** Грузоперевозчик */
    Carrier = 'CARRIER',
    /** Сотрудник службы безопасности */
    TndlSecurityStaff = 'TNDL_SECURITY_STAFF',
  }

  export interface IPaymentTypeType {
    id: Scalars['ID'];
    /** Название типа оплаты */
    name: Scalars['String'];
  }

  /** Право доступа пользователя */
  export interface IPermissionType {
    id: Scalars['ID'];
    codename: Scalars['String'];
    /** Описание права доступа */
    description?: Maybe<Scalars['String']>;
  }

  export interface IPriceInput {
    /** Цена */
    amount?: Maybe<Scalars['Decimal']>;
    /** Валюта */
    currency?: Maybe<Scalars['String']>;
  }

  /** Фильтр по запросам цен для организации ГО */
  export enum IPriceQuotationCargoOwnerEnum {
    Active = 'ACTIVE',
    Finished = 'FINISHED',
    Default = 'DEFAULT',
  }

  export interface IPriceQuotationResponseType {
    id: Scalars['ID'];
    /** Дата и время создания */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Грузоперевозчик, ответивший на запрос цены */
    carrier: ICarrierUserType;
    /** Организация, ответившая на запрос цены */
    organization: ICargoOwnerOrganizationType;
    /** Цена */
    price: Scalars['Int'];
    /** Цена включает НДС */
    isVatIncluded: Scalars['Boolean'];
    /** Комментарий ответа к запросу цены */
    comment: Scalars['String'];
  }

  export interface IPriceQuotationType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    /** Дата и время создания */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Организация, от которой был сделан запрос цены */
    organization: ICargoOwnerOrganizationType;
    /** Откуда */
    loadingPlace: Scalars['String'];
    /** Код населенного пункта отправления (ФИАС) */
    loadingPlaceFiasCode: Scalars['String'];
    /** Куда */
    unloadingPlace: Scalars['String'];
    /** Код населенного пункта назначения (ФИАС) */
    unloadingPlaceFiasCode: Scalars['String'];
    /** Получение информации о требованиях к транспортному средству */
    vehicleRequirements?: Maybe<IVehicleRequirementsType>;
    /** Дата и время окончания внесения предложений */
    endDateTime: Scalars['DateTime'];
    /** Круг перевозчиков, для которого доступен запрос цены */
    carrierGroup?: Maybe<ICarrierGroupType>;
    /** Шаг цены (кратность) */
    priceStep?: Maybe<Scalars['Int']>;
    /** Комментарий к запросу цены */
    comment?: Maybe<Scalars['String']>;
    /** Завершенный? */
    isFinished: Scalars['Boolean'];
    /** Грузовладелец, создавший запрос цены (пользователь) */
    cargoOwner?: Maybe<ICargoOwnerType>;
    /** Ответ на добавление запроса цены */
    responses?: Maybe<Array<Maybe<IPriceQuotationResponseType>>>;
  }

  export interface IPriceQuotationVehicleRequirementsInput {
    /** Требования к ТС: грузоподъемность в тоннах */
    capacity?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Объём кузова в м3 */
    volume?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Длина кузова */
    length?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Ширина кузова */
    width?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Высота кузова */
    height?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Список разрешенных типов погрузки/разгрузки */
    loadingTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Метод доставки: FTL/LTL */
    deliveryMethod?: Maybe<IDeliveryMethodEnum>;
    /** Температурный режим (нижняя граница) */
    temperatureModeFrom?: Maybe<Scalars['Int']>;
    /** Температурный режим (верхняя граница) */
    temperatureModeTo?: Maybe<Scalars['Int']>;
    /** Требования к ТС: Тип кузова транспортного средства */
    bodySubtype?: Maybe<Scalars['ID']>;
  }

  export interface IPriceType {
    /** Цена */
    amount?: Maybe<Scalars['Decimal']>;
    /** Валюта */
    currency?: Maybe<Scalars['String']>;
  }

  export enum IPriceTypeEnum {
    PerKm = 'PER_KM',
    Total = 'TOTAL',
  }

  /** Профиль пользователя */
  export interface IProfileType {
    id: Scalars['ID'];
    /** Адрес электронной почты пользователя */
    email: Scalars['String'];
    /** Имя пользователя */
    firstName: Scalars['String'];
    /** Фамилия пользователя */
    lastName: Scalars['String'];
    phoneNumber?: Maybe<Scalars['String']>;
    lastLogin?: Maybe<Scalars['DateTime']>;
    /** Designates whether this user should be treated as active. Unselect this instead of deleting accounts. */
    isActive: Scalars['Boolean'];
    dateJoined: Scalars['DateTime'];
    /** Организация, к которой относится пользователь */
    organization?: Maybe<ICargoOwnerOrganizationType>;
    /** Отчество пользователя */
    patronymic?: Maybe<Scalars['String']>;
    uiSettings?: Maybe<Scalars['JSONString']>;
    /** Роль */
    role?: Maybe<Scalars['String']>;
    /** Название организации, к которой относится пользователь */
    companyName?: Maybe<Scalars['String']>;
    /** Список прав пользователя в системе */
    permissions?: Maybe<Array<Maybe<IPermissionType>>>;
    /** Адрес электронной почты техподдержки */
    supportEmail?: Maybe<Scalars['String']>;
    /** Номер телефона техподдержки */
    supportPhoneNumber?: Maybe<Scalars['String']>;
    /** Руководства пользователя */
    userGuides?: Maybe<IUserGuideType>;
    /** Максимальное количество мест погрузки в заявке */
    maxCountLoadingPlace?: Maybe<Scalars['Int']>;
    /** Настройки пользователя */
    userSettings?: Maybe<IUserSettingsType>;
    /** Часовой пояс сервера */
    defaultTimezone?: Maybe<ITimeZoneType>;
  }

  /** An enumeration. */
  export enum IRouteSheetEventEvent {
    /** Текущее местоположение */
    CurrentLocation = 'CURRENT_LOCATION',
    /** Выезд на маршрут */
    DepartureOnRoute = 'DEPARTURE_ON_ROUTE',
    /** Погрузка завершена */
    CargoLoadingCompleted = 'CARGO_LOADING_COMPLETED',
    /** Приезд на место доставки */
    ArrivedToDeliveryPlace = 'ARRIVED_TO_DELIVERY_PLACE',
    /** Въезд на территорию места доставки */
    EnteredDeliveryPlace = 'ENTERED_DELIVERY_PLACE',
    /** Разгрузка завершена */
    CargoUnloadCompleted = 'CARGO_UNLOAD_COMPLETED',
    /** Задержка по времени */
    TimeLag = 'TIME_LAG',
    /** Водитель не подтвердил МЛ */
    ProblemDriverConfirmed = 'PROBLEM_DRIVER_CONFIRMED',
    /** Водитель не зафиксировал выезд на маршрут */
    ProblemDepartureOnRoute = 'PROBLEM_DEPARTURE_ON_ROUTE',
    /** Водитель не зафиксировал завершение погрузки */
    ProblemCargoLoadingCompleted = 'PROBLEM_CARGO_LOADING_COMPLETED',
    /** Водитель не зафиксировал приезд на разгрузку */
    ProblemArrivedToDeliveryPlace = 'PROBLEM_ARRIVED_TO_DELIVERY_PLACE',
    /** Водитель не зафиксировал въезд на разгрузку */
    ProblemEnteredDeliveryPlace = 'PROBLEM_ENTERED_DELIVERY_PLACE',
    /** Водитель не зафиксировал завершение разгрузки */
    ProblemCargoUnloadCompleted = 'PROBLEM_CARGO_UNLOAD_COMPLETED',
    /** Водитель не зафиксировал завершение доставки */
    ProblemDeliveryCompleted = 'PROBLEM_DELIVERY_COMPLETED',
    /** Приезд на место погрузки */
    ArrivedToLoadingPlace = 'ARRIVED_TO_LOADING_PLACE',
    /** Въезд на территорию места погрузки */
    EnteredLoadingPlace = 'ENTERED_LOADING_PLACE',
    /** Выезд с территории места погрузки */
    DepartureFromLoadingPlace = 'DEPARTURE_FROM_LOADING_PLACE',
  }

  /** An enumeration. */
  export enum IRouteSheetEventIssueTopic {
    /** Задержка по времени */
    TimeLag = 'TIME_LAG',
    /** Координаты точки места погрузки/разгрузки не совпадают с фактическими координатами */
    IncorrectCoordinates = 'INCORRECT_COORDINATES',
  }

  /** An enumeration. */
  export enum IRouteSheetEventSourceInfo {
    /** Интеграция с ТН */
    TnIntegration = 'TN_INTEGRATION',
    /** Мобильное приложение водителя */
    DriverMobile = 'DRIVER_MOBILE',
  }

  /** Информация о событии, произошедшем на маршрутном листе */
  export interface IRouteSheetEventType {
    id: Scalars['ID'];
    /** Статус маршрутного листа */
    event: IRouteSheetEventEvent;
    dateTime?: Maybe<Scalars['DateTime']>;
    /** Долгота места события на маршруте */
    longitude?: Maybe<Scalars['String']>;
    /** Широта места события на маршруте */
    latitude?: Maybe<Scalars['String']>;
    /** Тема возникшей проблемы */
    issueTopic?: Maybe<IRouteSheetEventIssueTopic>;
    /** Описание причины возникновения проблемы */
    issueText?: Maybe<Scalars['String']>;
    /** Источник информации о событии */
    sourceInfo: IRouteSheetEventSourceInfo;
    /**
     * Маршрутный лист, на котором произошло событие
     * @deprecated Меняется направление графа - есть опасность циклического запроса RouteSheet-RouteSheetEvent. Данное поле будет удалено.Для получения событий по МЛ используйте поле events в RouteSheetType.
     */
    routeSheet?: Maybe<IRouteSheetType>;
    /** Точка погрузки/доставки, на которой произошло событие */
    storagePoint?: Maybe<IStoragePointType>;
    /** Документы, прикрепленные водителем на событие */
    documents?: Maybe<Array<Maybe<IEventAttachedDoc>>>;
  }

  /** An enumeration. */
  export enum IRouteSheetStatus {
    /** Новый */
    New = 'NEW',
    /** Водитель подтвержден */
    DriverConfirmed = 'DRIVER_CONFIRMED',
    /** На маршруте */
    OnRoute = 'ON_ROUTE',
    /** Доставка завершена */
    DeliveryCompleted = 'DELIVERY_COMPLETED',
    /** Отменен */
    Cancelled = 'CANCELLED',
  }

  /** Информация о маршрутном листе */
  export interface IRouteSheetType {
    id: Scalars['ID'];
    /** Статус маршрутного листа */
    status: IRouteSheetStatus;
    /**
     * Информация о заявке, по которой составлен маршрутный лист
     * @deprecated Меняется направление графа - есть опасность циклического запроса Order-RouteSheet. Данное поле будет удалено.Для получения МЛ используйте поле routeSheet в OrderType.
     */
    order?: Maybe<IOrderType>;
    /** События маршрутного листа */
    events?: Maybe<Array<Maybe<IRouteSheetEventType>>>;
    /** Документы, прикрепленные к событиям маршрутного листа */
    documents?: Maybe<Array<Maybe<IEventAttachedDoc>>>;
    /** Актуальные проблемные события по заявке */
    actualProblemEvents?: Maybe<Array<Maybe<IRouteSheetEventType>>>;
    /** Есть ли связь у водителя */
    isDriverOnline?: Maybe<Scalars['Boolean']>;
  }

  export interface ISlaCriterionType {
    /** ID критерия */
    id?: Maybe<Scalars['ID']>;
    /** Название критерия */
    name?: Maybe<Scalars['String']>;
    /** Тип критерия */
    criterionType?: Maybe<Scalars['String']>;
    /** Описание */
    description?: Maybe<Scalars['String']>;
    /** Название порога отслеживания */
    thresholdName?: Maybe<Scalars['String']>;
    /** Признак того что SLA критерий является архивным */
    isArchived?: Maybe<Scalars['Boolean']>;
  }

  export interface ISlaInput {
    /** Название SLA */
    name: Scalars['String'];
    /** Описание */
    description: Scalars['String'];
    /** Пользовательский критерий в SLA */
    nonTrackableCriteria?: Maybe<Array<Maybe<INonTrackableSlaCriterionInput>>>;
    /** Критерий в SLA */
    trackableCriteria?: Maybe<Array<Maybe<ITrackableSlaCriterionInput>>>;
  }

  export interface ISlaType {
    /** SLA ID */
    id?: Maybe<Scalars['ID']>;
    /** ID организации */
    organizationId?: Maybe<Scalars['Int']>;
    /** Название SLA */
    name?: Maybe<Scalars['String']>;
    /** Описание */
    description?: Maybe<Scalars['String']>;
    /** Пользовательский критерий в SLA */
    nonTrackableCriteria?: Maybe<Array<Maybe<INonTrackableSlaCriterionType>>>;
    /** Критерий в SLA */
    trackableCriteria?: Maybe<Array<Maybe<ITrackableSlaCriterionType>>>;
    /** Признак того что SLA является активным */
    isActive?: Maybe<Scalars['Boolean']>;
    /** Признак того что SLA является архивным */
    isArchived?: Maybe<Scalars['Boolean']>;
    /** Дата и время создания */
    createdAt?: Maybe<Scalars['DateTime']>;
  }

  export interface ISendDocumentToEdms {
    result?: Maybe<Scalars['Boolean']>;
    edmsMessageId?: Maybe<Scalars['String']>;
  }

  /** Информация о месте погрузки/доставки */
  export interface IStoragePointDataType {
    /** Грузоотправитель, на которого зарегистрировано место погрузки/доставки */
    cargoOwner: ICargoOwnerOrganizationType;
    /** Название места погрузки/доставки */
    pointName?: Maybe<Scalars['String']>;
    /** Населённый пункт */
    settlement: Scalars['String'];
    /** Название компании, к которой относится место погрузки/доставки */
    companyName: Scalars['String'];
    /** Адрес места погрузки/доставки */
    address: Scalars['String'];
    /** Время начала работы */
    workingHoursFrom: Scalars['Time'];
    /** Время окончания работы */
    workingHoursTo: Scalars['Time'];
    /** Возможные типы погрузки/доставки */
    loadingTypes: Array<ILoadingTypeType>;
    /** Изображение со схемой проезда к месту погрузки/доставки */
    drivingDirectionsImg?: Maybe<Scalars['String']>;
    /** Тип точки */
    typePoint: IStoragePointDataTypePoint;
    /** Широта места погрузки/разгрузки */
    latitude?: Maybe<Scalars['String']>;
    /** Долгота места погрузки/разгрузки */
    longitude?: Maybe<Scalars['String']>;
    /** Код в системе ФИАС, по которому можно получить подробную информацию об адресе */
    fiasCode?: Maybe<Scalars['String']>;
    /** Часовой пояс места погрузки/доставки */
    timezone?: Maybe<ITimeZoneType>;
    /** id места погрузки/доставки (StoragePoint) */
    id?: Maybe<Scalars['ID']>;
    /** Место архивное */
    isArchived?: Maybe<Scalars['Boolean']>;
    /** Название места погрузки/доставки */
    name?: Maybe<Scalars['String']>;
    /** Список контактов */
    contacts?: Maybe<Array<Maybe<IContactPersonAtStoragePointType>>>;
  }

  /** An enumeration. */
  export enum IStoragePointDataTypePoint {
    /** Погрузка */
    Loading = 'LOADING',
    /** Разгрузка */
    Unloading = 'UNLOADING',
  }

  /** Место погрузки/доставки */
  export interface IStoragePointType {
    id: Scalars['ID'];
    /** Архивный? */
    isArchived: Scalars['Boolean'];
    /** Грузоотправитель, на которого зарегистрировано место погрузки/доставки */
    cargoOwner: ICargoOwnerOrganizationType;
    /** Название места погрузки/доставки */
    pointName?: Maybe<Scalars['String']>;
    /** Населённый пункт */
    settlement: Scalars['String'];
    /** Название компании, к которой относится место погрузки/доставки */
    companyName: Scalars['String'];
    /** Адрес места погрузки/доставки */
    address: Scalars['String'];
    /** Время начала работы */
    workingHoursFrom: Scalars['Time'];
    /** Время окончания работы */
    workingHoursTo: Scalars['Time'];
    /** Возможные типы погрузки/доставки */
    loadingTypes: Array<ILoadingTypeType>;
    /** Изображение со схемой проезда к месту погрузки/доставки */
    drivingDirectionsImg?: Maybe<Scalars['String']>;
    /** Тип точки */
    typePoint: IStoragePointTypePoint;
    /** Широта места погрузки/разгрузки */
    latitude?: Maybe<Scalars['String']>;
    /** Долгота места погрузки/разгрузки */
    longitude?: Maybe<Scalars['String']>;
    /** Код в системе ФИАС, по которому можно получить подробную информацию об адресе */
    fiasCode?: Maybe<Scalars['String']>;
    /** Часовой пояс места погрузки/доставки */
    timezone?: Maybe<ITimeZoneType>;
    /** Название места погрузки/доставки */
    name?: Maybe<Scalars['String']>;
    /** Список контактов */
    contacts?: Maybe<Array<Maybe<IContactPersonAtStoragePointType>>>;
  }

  /** An enumeration. */
  export enum IStoragePointTypePoint {
    /** Погрузка */
    Loading = 'LOADING',
    /** Разгрузка */
    Unloading = 'UNLOADING',
  }

  export interface ISubmitCargoOwnerRiskProfile {
    /** Успешно отправлен на проверку - ДА или НЕТ */
    ok?: Maybe<Scalars['Boolean']>;
  }

  /** Тип тендера */
  export interface ITenderReportType {
    id: Scalars['ID'];
    /** Номер лота */
    lotNumber?: Maybe<Scalars['String']>;
    /** Организация-перевозчик */
    carrierOrganization?: Maybe<ICargoOwnerOrganizationType>;
    /** Город погрузки */
    loadingSettlement?: Maybe<Scalars['String']>;
    /** Завод */
    department?: Maybe<Scalars['String']>;
    /** Направление */
    course?: Maybe<Scalars['String']>;
    /** Количество перевозок */
    ordersQuantity?: Maybe<Scalars['Int']>;
    /** Цена победителя */
    winnerPrice?: Maybe<Scalars['Int']>;
    /** Статус тендера для отображения */
    displayedStatus?: Maybe<Scalars['String']>;
    /** Количество назначенных заявок */
    ordersAssignedQuantity?: Maybe<Scalars['Int']>;
    /** Количество заявок в работе */
    ordersInWorkQuantity?: Maybe<Scalars['Int']>;
    /** Количество выполненных заявок */
    ordersCompletedQuantity?: Maybe<Scalars['Int']>;
    /** Количество отмененных заявок */
    ordersCanceledQuantity?: Maybe<Scalars['Int']>;
    /** Процент отказа по заявкам */
    ordersCanceledPercentage?: Maybe<Scalars['Int']>;
    /** Заявки по тендеру */
    orders?: Maybe<Array<Maybe<IOrderType>>>;
  }

  /** Тип тендера */
  export interface ITenderType {
    id: Scalars['ID'];
    /** Номер лота */
    lotNumber?: Maybe<Scalars['String']>;
    /** Организация-перевозчик */
    carrierOrganization?: Maybe<ICargoOwnerOrganizationType>;
    /** Дата и время окончания принятия ставок */
    bidsEndDatetime?: Maybe<Scalars['DateTime']>;
    /** Город погрузки */
    loadingSettlement?: Maybe<Scalars['String']>;
    /** Город погрузки - ФИАС (до нас. пункта) */
    loadingSettlementFias?: Maybe<Scalars['String']>;
    /** Завод */
    department?: Maybe<Scalars['String']>;
    /** Направление */
    course?: Maybe<Scalars['String']>;
    /** Направление - ФИАС (до нас. пункта) */
    courseFias?: Maybe<Scalars['String']>;
    /** Адрес доставки */
    unloadingPlace?: Maybe<Scalars['String']>;
    /** Начало периода действия */
    activeDateFrom?: Maybe<Scalars['Date']>;
    /** Конец периода действия */
    activeDateTo?: Maybe<Scalars['Date']>;
    /** Количество перевозок */
    ordersQuantity?: Maybe<Scalars['Int']>;
    /** Максимальная цена за перевозку */
    maxOrderPrice?: Maybe<Scalars['Int']>;
    /** Шаг цены */
    stepPrice?: Maybe<Scalars['Int']>;
    /** Цена победителя */
    winnerPrice?: Maybe<Scalars['Int']>;
    /** Группа перевозчиков */
    carrierGroup?: Maybe<ICarrierGroupType>;
    /** Комментарий к тендеру */
    comment?: Maybe<Scalars['String']>;
    /** Цена включает НДС */
    isVatIncluded: Scalars['Boolean'];
    /** Статус тендера для отображения */
    displayedStatus?: Maybe<Scalars['String']>;
    /** Количество ставок по тендеру */
    bidsQuantity?: Maybe<Scalars['Int']>;
    /** Требования к ТС */
    vehicleRequirements?: Maybe<Array<Maybe<ITenderVehicleRequirementsType>>>;
    /** Ставки по тендеру */
    bids?: Maybe<Array<Maybe<IBidOnTenderType>>>;
  }

  export interface ITenderVehicleRequirementsInput {
    /** Требования к ТС: грузоподъемность в тоннах */
    capacity?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Объём кузова в м3 */
    volume?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Длина кузова */
    length?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Ширина кузова */
    width?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Высота кузова */
    height?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Список из ID разрешённых типов погрузки */
    loadingTypes?: Maybe<Array<Maybe<Scalars['ID']>>>;
    /** Метод доставки: FTL/LTL */
    deliveryMethod?: Maybe<IDeliveryMethodEnum>;
    /** Температурный режим (нижняя граница) */
    temperatureModeFrom?: Maybe<Scalars['Int']>;
    /** Температурный режим (верхняя граница) */
    temperatureModeTo?: Maybe<Scalars['Int']>;
    /** Требования к ТС: Тип кузова транспортного средства */
    bodySubtype?: Maybe<Scalars['ID']>;
  }

  /** Требования к ТС для тендера */
  export interface ITenderVehicleRequirementsType {
    /** Тип кузова ТС */
    bodyType?: Maybe<Scalars['String']>;
    /** Подтип кузова */
    bodySubtype?: Maybe<IBodySubtypeType>;
    /** Грузоподъемность ТС, т. */
    liftingCapacity?: Maybe<Scalars['Float']>;
    /** Объём кузова ТС в м3 */
    volume?: Maybe<Scalars['Float']>;
    /** Длина кузова ТС */
    length?: Maybe<Scalars['Float']>;
    /** Ширина кузова ТС */
    width?: Maybe<Scalars['Float']>;
    /** Высота кузова ТС */
    height?: Maybe<Scalars['Float']>;
    /** Необходимость наличия манипулятора у ТС */
    mustHaveManipulator: Scalars['Boolean'];
  }

  export interface ITimeZoneType {
    id: Scalars['ID'];
    /** Название города часового пояса */
    settlement: Scalars['String'];
    /** Часовой пояс(в формате UTC) */
    timezone: Scalars['String'];
    /** Название часового пояса */
    name: Scalars['String'];
  }

  export interface ITrackableSlaCriterionInput {
    /** Описание */
    description?: Maybe<Scalars['String']>;
    /** Значение порога отслеживания */
    thresholdValue?: Maybe<Scalars['String']>;
    /** ID системного отслеживаемого критерия */
    criterionId: Scalars['ID'];
    /** Признак того что критерий в SLA является активным */
    isActive: Scalars['Boolean'];
  }

  export interface ITrackableSlaCriterionType {
    /** ID критерия в SLA */
    id?: Maybe<Scalars['ID']>;
    /** Пользовательское описание */
    description?: Maybe<Scalars['String']>;
    /** Признак того что критерий в SLA является активным */
    isActive?: Maybe<Scalars['Boolean']>;
    /** Системный критерий */
    criterion?: Maybe<ISlaCriterionType>;
    /** Значение порога отслеживания */
    thresholdValue?: Maybe<Scalars['String']>;
    /** Дата и время создания */
    createdAt?: Maybe<Scalars['DateTime']>;
  }

  export enum ITypeOfPriceStepEnum {
    /** Фиксированный шаг */
    Fixed = 'FIXED',
    /** Процент от начальной стоимости для расчета шага */
    Percent = 'PERCENT',
  }

  export interface IUploadFileInput {
    /** Файл */
    file: Scalars['Upload'];
    /** Название файла. Длина (max): 255 */
    name: Scalars['String'];
  }

  export interface IUploadedFileType {
    id: Scalars['ID'];
    /** Дата и время создания */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Название файла */
    name: Scalars['String'];
    /** Ссылка для скачивания файла */
    url?: Maybe<Scalars['String']>;
    /** Содержимое файла в base64 */
    content?: Maybe<Scalars['String']>;
  }

  /** Руководства пользователя */
  export interface IUserGuideType {
    /** Руководство по работе с ЭЦП */
    signGuide?: Maybe<IUploadedFileType>;
    /** Руководство для грузоперевозчика */
    carrierGuide?: Maybe<IUploadedFileType>;
    /** Руководство для грузовладельца */
    cargoOwnerGuide?: Maybe<IUploadedFileType>;
  }

  /** Настройки пользователя */
  export interface IUserSettingsType {
    /** Настройки уведомлений пользователя */
    notificationSettings?: Maybe<INotificationSettingsOverallType>;
    /** Включен новый дизайн */
    isNewDesign: Scalars['Boolean'];
  }

  export interface IVatType {
    id: Scalars['ID'];
    /** Название ставки НДС */
    name: Scalars['String'];
    /** Значение ставки НДС, % */
    rate: Scalars['Float'];
  }

  export enum IVehicleAvailabilityStatusEnum {
    Created = 'CREATED',
    Published = 'PUBLISHED',
    NotAccredited = 'NOT_ACCREDITED',
    Canceled = 'CANCELED',
    Completed = 'COMPLETED',
  }

  /** Информация о предложении ТС */
  export interface IVehicleAvailabilityType {
    /** ID предложения транспорта */
    id?: Maybe<Scalars['ID']>;
    /** Информация о ТС */
    vehicle?: Maybe<IGrapheneVehicleType>;
    /** Организационно-правовая форма */
    legalForm?: Maybe<Scalars['String']>;
    /** Стартовая точка погрузки */
    loadingPlaceDescription?: Maybe<Scalars['String']>;
    /** Дата и время начала возможной погрузки */
    loadingTimeFrom?: Maybe<Scalars['DateTime']>;
    /** Дата и время завершения возможной погрузки */
    loadingTimeTo?: Maybe<Scalars['DateTime']>;
    /** Конечная точка разгрузки */
    unloadingPlaceDescription?: Maybe<Scalars['String']>;
    /** Код ФИАС места погрузки */
    loadingPlaceFiasCode?: Maybe<Scalars['String']>;
    /** Часовой пояс(в формате UTC) */
    loadingPlaceTimezone?: Maybe<Scalars['String']>;
    /** Код ФИАС места разгрузки */
    unloadingPlaceFiasCode?: Maybe<Scalars['String']>;
    /** Населенный пункт места погрузки */
    loadingPlaceSettlement?: Maybe<Scalars['String']>;
    /** Fias-код населенного пункта места погрузки */
    loadingPlaceSettlementFiasCode?: Maybe<Scalars['String']>;
    /** Включен ли в цену НДС */
    isVatIncluded?: Maybe<Scalars['Boolean']>;
    /** Стоимость перевозки */
    price?: Maybe<IPriceType>;
    /** Тип назначенной цены */
    priceType?: Maybe<IPriceTypeEnum>;
    /** Комментарий к публикации */
    comment?: Maybe<Scalars['String']>;
    /** Дата и время публикации на платформе */
    publishDate?: Maybe<Scalars['DateTime']>;
    /** Дата и время завершения публикации ТС */
    deadline?: Maybe<Scalars['DateTime']>;
    /** Статус публикации */
    status?: Maybe<IVehicleAvailabilityStatusEnum>;
    /** Дата и время создания */
    createdAt?: Maybe<Scalars['DateTime']>;
    /** Дата и время аккредитации */
    accrAtDt?: Maybe<Scalars['DateTime']>;
    /** Аккредитован - ДА или НЕТ */
    accrValue?: Maybe<Scalars['Boolean']>;
    /** Дата и время окончания аккредитации */
    accrExpAt?: Maybe<Scalars['DateTime']>;
    /** Ссылка на объект risk profile */
    accrProfile?: Maybe<Scalars['String']>;
    /** Коментарий к аккредитации */
    accrComment?: Maybe<Scalars['String']>;
    /** Количество завершённых заявок ГП */
    completedOrderCount?: Maybe<Scalars['Int']>;
    /** Сделал ли ГВ запрос на предложение ТС */
    vehicleRequestDone?: Maybe<Scalars['Boolean']>;
  }

  /** An enumeration. */
  export enum IVehicleDataOwnershipType {
    /** Лизинг */
    Leasing = 'LEASING',
    /** Аренда */
    Rent = 'RENT',
    /** В собственности */
    Own = 'OWN',
  }

  /** Информация о транспортном средстве */
  export interface IVehicleDataType {
    /** Типы ТС */
    vehicleType: IVehicleDataVehicleType;
    /** Марка ТС */
    vehicleMake: Scalars['String'];
    /** Номер государственной регистрации ТС (латиница) */
    regNo: Scalars['String'];
    /** Типы владения ТС */
    ownershipType: IVehicleDataOwnershipType;
    /** ID транспортного средства */
    id?: Maybe<Scalars['ID']>;
    /** Информация о ЧС */
    blackListInfo?: Maybe<Array<Maybe<IBlackListVehicleType>>>;
  }

  /** Информация о транспортном средстве */
  export type IVehicleDataTypeBlackListInfoArgs = {
    orderId?: Maybe<Scalars['ID']>;
  };

  /** An enumeration. */
  export enum IVehicleDataVehicleType {
    /** Грузовик */
    Truck = 'TRUCK',
    /** Фура */
    Trailer = 'TRAILER',
    /** Автосцепка */
    Coupler = 'COUPLER',
  }

  /** Заносимая информация о встречной заявке */
  export interface IVehicleRequestInput {
    /** ID публикации ТС */
    vehicleAvailabilityId: Scalars['Int'];
    /** ID Заявки */
    orderId: Scalars['Int'];
  }

  export enum IVehicleRequestStatus {
    Proposed = 'proposed',
    Accepted = 'accepted',
    Refused = 'refused',
    Canceled = 'canceled',
  }

  /** Встречное рпедложение бронирования ТС */
  export interface IVehicleRequestType {
    /** ID встречного предложения на бронирование ТС */
    id?: Maybe<Scalars['ID']>;
    /** ID предложения ТС */
    vehicleAvailabilityId?: Maybe<Scalars['ID']>;
    /** ID заявки */
    orderId?: Maybe<Scalars['ID']>;
    /** Статус встречного предложения */
    status?: Maybe<IVehicleRequestStatus>;
  }

  /** An enumeration. */
  export enum IVehicleRequirementsDeliveryMethod {
    /** Отдельной машиной */
    Ftl = 'FTL',
    /** Возможен догруз */
    Ltl = 'LTL',
  }

  export interface IVehicleRequirementsType {
    /** Требования к ТС: Тип кузова транспортного средства */
    bodySubtype?: Maybe<IBodySubtypeType>;
    /** Требования к ТС: Грузоподъемность в тоннах */
    capacity?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Объём кузова в м3 */
    volume?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Длина кузова */
    length?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Ширина кузова */
    width?: Maybe<Scalars['Float']>;
    /** Требования к ТС: Высота кузова */
    height?: Maybe<Scalars['Float']>;
    /** Способ, которым ТС доставляет груз(FTL/LTL) */
    deliveryMethod: IVehicleRequirementsDeliveryMethod;
    /** Температурный режим (градусы Цельсия) */
    temperatureModeFrom?: Maybe<Scalars['Int']>;
    /** Температурный режим (градусы Цельсия) */
    temperatureModeTo?: Maybe<Scalars['Int']>;
    /** Получение списка всех типов погрузки/разгрузки */
    loadingTypes?: Maybe<Array<Maybe<ILoadingTypeType>>>;
  }

  /** An enumeration. */
  export enum IVehicleTrailerDataTrailerType {
    /** Прицеп */
    Full = 'FULL',
    /** Полуприцеп */
    Half = 'HALF',
  }

  /** Информация о прицепе/полуприцепе транспортного средства */
  export interface IVehicleTrailerDataType {
    /** Типы прицепа ТС */
    trailerType: IVehicleTrailerDataTrailerType;
    /** Номер государственной регистрации прицепа/полуприцепа (латиница) */
    regNo: Scalars['String'];
    /** ID прицепа/полуприцепа */
    id?: Maybe<Scalars['ID']>;
    /** Информация о ЧС */
    blackListInfo?: Maybe<Array<Maybe<IBlackListVehicleTrailerType>>>;
  }

  /** Информация о прицепе/полуприцепе транспортного средства */
  export type IVehicleTrailerDataTypeBlackListInfoArgs = {
    orderId?: Maybe<Scalars['ID']>;
  };

  export interface IVehicleTypeType {
    id: Scalars['ID'];
    /** Наименование подтипа кузова ТС */
    name: Scalars['String'];
  }

  export interface IWaybillInfoType {
    /** Место погрузки/разгрузки из заявки */
    orderStoragePoint?: Maybe<IDraftOrderStoragePointType>;
    /** Время доставки с */
    dateFrom?: Maybe<Scalars['DateTime']>;
    /** Время доставки по */
    dateTo?: Maybe<Scalars['DateTime']>;
  }

  /** An enumeration. */
  export enum IInsurance_Company_Choices {
    Spass = 'spass',
  }
}
