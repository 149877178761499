import { ISvgIconInterface } from './svg-icon.interface';

export const iconsCollection: ISvgIconInterface[] = [
  {
    name: 'sidebar-cargo',
    path: 'assets/icons/sidebar/sidebar-cargo.svg',
  },
  {
    name: 'sidebar-orders',
    path: 'assets/icons/sidebar/sidebar-orders.svg',
  },
  {
    name: 'sidebar-my-orders',
    path: 'assets/icons/sidebar/sidebar-my-orders.svg',
  },
  {
    name: 'sidebar-routing',
    path: 'assets/icons/sidebar/sidebar-routing.svg',
  },
  {
    name: 'sidebar-monitoring',
    path: 'assets/icons/sidebar/sidebar-monitoring.svg',
  },
  {
    name: 'sidebar-quotas',
    path: 'assets/icons/sidebar/sidebar-quotas.svg',
  },
  {
    name: 'sidebar-tsm',
    path: 'assets/icons/sidebar/sidebar-tsm.svg',
  },
  {
    name: 'sidebar-vehicle-templates',
    path: 'assets/icons/sidebar/sidebar-vehicle-templates.svg',
  },
  {
    name: 'sidebar-integration',
    path: 'assets/icons/sidebar/sidebar-integration.svg',
  },
  {
    name: 'sidebar-tenders-tn',
    path: 'assets/icons/sidebar/sidebar-tenders-tn.svg',
  },
  {
    name: 'sidebar-tenders',
    path: 'assets/icons/sidebar/sidebar-tenders.svg',
  },
  {
    name: 'sidebar-owner-carriers',
    path: 'assets/icons/sidebar/sidebar-owner-carriers.svg',
  },
  {
    name: 'sidebar-my-staff',
    path: 'assets/icons/sidebar/sidebar-my-staff.svg',
  },
  {
    name: 'sidebar-vehicles',
    path: 'assets/icons/sidebar/sidebar-vehicles.svg',
  },
  {
    name: 'sidebar-drivers',
    path: 'assets/icons/sidebar/sidebar-drivers.svg',
  },
  {
    name: 'sidebar-statistics',
    path: 'assets/icons/sidebar/sidebar-statistics.svg',
  },
  {
    name: 'sidebar-carriers',
    path: 'assets/icons/sidebar/sidebar-carriers.svg',
  },
  {
    name: 'sidebar-pretensions',
    path: 'assets/icons/sidebar/sidebar-pretensions.svg',
  },
  {
    name: 'sidebar-user',
    path: 'assets/icons/sidebar/sidebar-user.svg',
  },
  {
    name: 'sidebar-partner',
    path: 'assets/icons/sidebar/sidebar-partner.svg',
  },
  {
    name: 'sidebar-quotas-report',
    path: 'assets/icons/sidebar/sidebar-quotas-report.svg',
  },
  {
    name: 'sidebar-report',
    path: 'assets/icons/sidebar/sidebar-report.svg',
  },
  {
    name: 'sidebar-cargo-types',
    path: 'assets/icons/sidebar/sidebar-cargo-types.svg',
  },
  {
    name: 'sidebar-addresses',
    path: 'assets/icons/sidebar/sidebar-addresses.svg',
  },
  {
    name: 'sidebar-sla',
    path: 'assets/icons/sidebar/sidebar-sla.svg',
  },
  {
    name: 'sidebar-notifications',
    path: 'assets/icons/sidebar/sidebar-notifications.svg',
  },
  {
    name: 'sidebar-settings',
    path: 'assets/icons/sidebar/sidebar-settings.svg',
  },
  {
    name: 'new-plus',
    path: 'assets/icons/new-icon-pack/new-plus.svg',
  },
  {
    name: 'new-delete',
    path: 'assets/icons/new-icon-pack/new-delete.svg',
  },
  {
    name: 'new-edit',
    path: 'assets/icons/new-icon-pack/new-edit.svg',
  },
  {
    name: 'new-done',
    path: 'assets/icons/new-icon-pack/new-done.svg',
  },
  {
    name: 'warehouse',
    path: 'assets/icons/warehouse.svg',
  },
  {
    name: 'a-letter',
    path: 'assets/icons/A_letter.svg',
  },
  {
    name: 'burger',
    path: 'assets/icons/burger.svg',
  },
  {
    name: 'c-letter',
    path: 'assets/icons/C_letter.svg',
  },
  {
    name: 'h-letter',
    path: 'assets/icons/H_letter.svg',
  },
  {
    name: 'k-letter',
    path: 'assets/icons/K_letter.svg',
  },
  {
    name: 't-letter',
    path: 'assets/icons/T_letter.svg',
  },
  {
    name: '3-letter',
    path: 'assets/icons/3_letter.svg',
  },
  {
    name: 'r-letter',
    path: 'assets/icons/R_letter.svg',
  },
  {
    name: 'p-letter',
    path: 'assets/icons/P_letter.svg',
  },
  {
    name: 'phone-online',
    path: 'assets/icons/phone_online.svg',
  },
  {
    name: 'phone-offline',
    path: 'assets/icons/phone_offline.svg',
  },
  {
    name: 'info-outline',
    path: 'assets/icons/info_outline.svg',
  },
  {
    name: 'info-outline-gray',
    path: 'assets/icons/info_outline_gray.svg',
  },
  {
    name: 'info-outline-orange',
    path: 'assets/icons/info_outline_orange.svg',
  },
  {
    name: 'accept-outline',
    path: 'assets/icons/accept_outline.svg',
  },
  {
    name: 'arrow-right',
    path: 'assets/icons/arrow_right.svg',
  },
  {
    name: 'arrow-left',
    path: 'assets/icons/arrow_left.svg',
  },
  {
    name: 'tonna-copy',
    path: 'assets/icons/copy-outline.svg',
  },
  {
    name: 'tonna-accept',
    path: 'assets/icons/tonna_accept.svg',
  },
  {
    name: 'tonna-cancel',
    path: 'assets/icons/tonna_cancel.svg',
  },
  {
    name: 'ui-arrow-down-fill',
    path: 'assets/icons/UI-arrow-down-fill.svg',
  },
  {
    name: 'ui-user',
    path: 'assets/icons/UI_user.svg',
  },
  {
    name: 'ui-delete',
    path: 'assets/icons/UI_delete.svg',
  },
  {
    name: 'tonna-sidebar-cargo',
    path: 'assets/icons/tonna-sidebar-cargo.svg',
  },
  {
    name: 'tonna-sidebar-transport',
    path: 'assets/icons/tonna-sidebar-transport.svg',
  },
  {
    name: 'success-icon-40',
    path: 'assets/icons/illustration.svg',
  },
  {
    name: 'info-icon-40',
    path: 'assets/icons/big-info-icon.svg',
  },
  {
    name: 'calendar-icon',
    path: 'assets/icons/calendar-outline.svg',
  },
  {
    name: 'swap-icon',
    path: 'assets/icons/swap.svg',
  },
  {
    name: 'user-outline',
    path: 'assets/icons/user-outline.svg',
  },
  {
    name: 'settings-outline',
    path: 'assets/icons/settings-outline.svg',
  },
  {
    name: 'auction-outline',
    path: 'assets/icons/auction.svg',
  },
  {
    name: 'money-outline',
    path: 'assets/icons/money.svg',
  },
  {
    name: 'gavel-outline',
    path: 'assets/icons/gavel_outline.svg',
  },
  {
    name: 'persone-outline',
    path: 'assets/icons/persone-outline.svg',
  },
  {
    name: 'place-outline',
    path: 'assets/icons/place-out.svg',
  },
  {
    name: 'bar-chart-outline',
    path: 'assets/icons/bar-chart-outline.svg',
  },
  {
    name: 'import-export-outline',
    path: 'assets/icons/import_export_outline.svg',
  },
  {
    name: 'folder-outline',
    path: 'assets/icons/folder-outline.svg',
  },
  {
    name: 'employ-outline',
    path: 'assets/icons/employ-outline.svg',
  },
  {
    name: 'account',
    path: 'assets/icons/account_outline.svg',
  },
  {
    name: 'account-transparent',
    path: 'assets/icons/account.svg',
  },
  {
    name: 'notification-outline',
    path: 'assets/icons/notification_outline.svg',
  },
  {
    name: 'help-icon-outline',
    path: 'assets/icons/help_icon_outline.svg',
  },
  {
    name: 'local-shipping',
    path: 'assets/icons/local-shipping-outline.svg',
  },
  {
    name: 'orders-outline',
    path: 'assets/icons/orders-icon.svg',
  },
  {
    name: 'location-searching',
    path: 'assets/icons/location-searching.svg',
  },
  {
    name: 'help-outline',
    path: 'assets/icons/help_outline.png',
  },
  {
    name: 'truck',
    path: 'assets/icons/publication-truck.svg',
  },
  {
    name: 'box',
    path: 'assets/icons/font/box.svg',
  },
  {
    name: 'location',
    path: 'assets/icons/font/location.svg',
  },
  {
    name: 'poluchatel',
    path: 'assets/icons/font/poluchatel.svg',
  },
  {
    name: 'users-2',
    path: 'assets/icons/font/users-2.svg',
  },
  {
    name: 'bank',
    path: 'assets/icons/bank.svg',
  },
  {
    name: 'transport2',
    path: 'assets/icons/transport2.svg',
  },
  {
    name: 'abort',
    path: 'assets/icons/abort.svg',
  },
  {
    name: 'arrived',
    path: 'assets/icons/arrived.svg',
  },
  {
    name: 'complete',
    path: 'assets/icons/complete.svg',
  },
  {
    name: 'complete2',
    path: 'assets/icons/complete2.svg',
  },
  {
    name: 'progress',
    path: 'assets/icons/progress.svg',
  },
  {
    name: 'price-quotation-empty-table',
    path: 'assets/icons/price-quotation-empty-table.svg',
  },
  {
    name: 'cloud',
    path: 'assets/icons/cloud-with-arrow.svg',
  },
  {
    name: 'delete-outlined',
    path: 'assets/icons/delete-outlined.svg',
  },
  {
    name: 'warning',
    path: 'assets/icons/warning.svg',
  },
  {
    name: 'location-outlined',
    path: 'assets/icons/location-outlined.svg',
  },
  {
    name: 'cube-outlined',
    path: 'assets/icons/cube-outlined.svg',
  },
  {
    name: 'account-card-details',
    path: 'assets/icons/account-card-details.svg',
  },
  {
    name: 'contract',
    path: 'assets/icons/contract.svg',
  },
  {
    name: 'gavel',
    path: 'assets/icons/gavel.svg',
  },
  {
    name: 'download',
    path: 'assets/icons/download.svg',
  },
  {
    name: 'download-gray',
    path: 'assets/icons/download_gray.svg',
  },
  {
    name: 'upload',
    path: 'assets/icons/upload.svg',
  },
  {
    name: 'file-outlined',
    path: 'assets/icons/file-outlined.svg',
  },
  {
    name: 'directions',
    path: 'assets/icons/directions.svg',
  },
  {
    name: 'prize',
    path: 'assets/icons/prize.svg',
  },
  {
    name: 'answer',
    path: 'assets/icons/answer.svg',
  },
  {
    name: 'service',
    path: 'assets/icons/service.svg',
  },
  {
    name: 'call',
    path: 'assets/icons/call.svg',
  },
  {
    name: 'chip-clear',
    path: 'assets/icons/chip-clear.svg',
  },
  {
    name: 'refresh',
    path: 'assets/icons/refresh.svg',
  },
  {
    name: 'shevron_bottom',
    path: 'assets/icons/shevron_bottom.svg',
  },
  {
    name: 'refresh_black',
    path: 'assets/icons/refresh_black.svg',
  },
  {
    name: 'signature',
    path: 'assets/icons/signature.svg',
  },
  {
    name: 'download-outlined',
    path: 'assets/icons/download_outlined.svg',
  },
  {
    name: 'owner-person',
    path: 'assets/icons/owner_person.svg',
  },
  {
    name: 'tracking-stage',
    path: 'assets/icons/tracking_stage.svg',
  },
  {
    name: 'truck-front',
    path: 'assets/icons/truck-front.svg',
  },
  {
    name: 'current-tracking-stage',
    path: 'assets/icons/current_tracking_stage.svg',
  },
  {
    name: 'attach-file',
    path: 'assets/icons/attach_file.svg',
  },
  {
    name: 'back',
    path: 'assets/icons/back.svg',
  },
  {
    name: 'word',
    path: 'assets/icons/word.svg',
  },
  {
    name: 'pdf',
    path: 'assets/icons/pdf.svg',
  },
  {
    name: 'umbrella',
    path: 'assets/icons/umbrella.svg',
  },
  {
    name: 'compare_arrows',
    path: 'assets/icons/compare_arrows.svg',
  },
  {
    name: 'calendar',
    path: 'assets/icons/calendar.svg',
  },
  {
    name: 'organization',
    path: 'assets/icons/organization.svg',
  },
  {
    name: 'add_car',
    path: 'assets/icons/add-car.svg',
  },
  {
    name: 'car',
    path: 'assets/icons/car.svg',
  },
  {
    name: 'doc',
    path: 'assets/icons/doc.svg',
  },
  {
    name: 'doc-half-green',
    path: 'assets/icons/doc-half-green.svg',
  },
  {
    name: 'doc-green',
    path: 'assets/icons/doc-green.svg',
  },
  {
    name: 'doc-red',
    path: 'assets/icons/doc-red.svg',
  },
  {
    name: 'doc-in-progress',
    path: 'assets/icons/doc-in-progress.svg',
  },
  {
    name: 'warning-status',
    path: 'assets/icons/warning_status.svg',
  },
  {
    name: 'error-status',
    path: 'assets/icons/error_status.svg',
  },
  {
    name: 'planned_connection',
    path: 'assets/icons/planned_connection.svg',
  },
  {
    name: 'driver_connection',
    path: 'assets/icons/driver_connection.svg',
  },
  {
    name: 'lost_connection',
    path: 'assets/icons/lost_connection.svg',
  },
  {
    name: 'message',
    path: 'assets/icons/message.svg',
  },
  {
    name: 'excel',
    path: 'assets/icons/excel.svg',
  },
  {
    name: 'passport',
    path: 'assets/icons/passport.svg',
  },
  {
    name: 'text-snippet',
    path: 'assets/icons/text-snippet.svg',
  },
  {
    name: 'chart',
    path: 'assets/icons/chart.svg',
  },
  {
    name: 'info',
    path: 'assets/icons/info.svg',
  },
  {
    name: 'send',
    path: 'assets/icons/send.svg',
  },
  {
    name: 'manual',
    path: 'assets/icons/record_voice_over_24px.svg',
  },
  {
    name: 'auto',
    path: 'assets/icons/auto.svg',
  },
  {
    name: 'sign',
    path: 'assets/icons/sign.svg',
  },
  {
    name: 'check-steps',
    path: 'assets/icons/check-steps.svg',
  },
  {
    name: 'check-steps-success',
    path: 'assets/icons/check-steps-success.svg',
  },
  {
    name: 'application-installed',
    path: 'assets/icons/application-installed.svg',
  },
  {
    name: 'application-not-installed',
    path: 'assets/icons/application-not-installed.svg',
  },
  {
    name: 'menu',
    path: 'assets/icons/menu_24px.svg',
  },
  {
    name: 'application-installed-outlined',
    path: 'assets/icons/application-installed-outlined.svg',
  },
  {
    name: 'application-not-installed-outlined',
    path: 'assets/icons/application-not-installed-outlined.svg',
  },
  {
    name: 'invite-send',
    path: 'assets/icons/invite_send.svg',
  },
  {
    name: 'invite-warning',
    path: 'assets/icons/invite_warning.svg',
  },
  {
    name: 'supplier',
    path: 'assets/icons/supplier.svg',
  },
  {
    name: 'requirements-list',
    path: 'assets/icons/requirements-list.svg',
  },
  {
    name: 'monetization-outlined',
    path: 'assets/icons/monetization-outlined.svg',
  },
  {
    name: 'empty-list',
    path: 'assets/icons/empty-list.svg',
  },
  {
    name: 'rating-warning',
    path: 'assets/icons/rating-warning.svg',
  },
  {
    name: 'checkbox-marked-circle',
    path: 'assets/icons/checkbox-blue-outline.svg',
  },
  {
    name: 'warning_amber',
    path: 'assets/icons/warning_amber.svg',
  },
  {
    name: 'warning_icon',
    path: 'assets/icons/warning_icon.svg',
  },
  {
    name: 'block',
    path: 'assets/icons/block.svg',
  },
  {
    name: 'more',
    path: 'assets/icons/more.svg',
  },
  {
    name: 'services-reports',
    path: 'assets/icons/services-report.svg',
  },
  {
    name: 'table-chart',
    path: 'assets/icons/table-chart.svg',
  },
  {
    name: 'new-folder',
    path: 'assets/icons/new-folder.svg',
  },
  {
    name: 'certificate',
    path: 'assets/icons/certificate.svg',
  },
  {
    name: 'archived',
    path: 'assets/icons/archived.svg',
  },
  {
    name: 'info-italic',
    path: 'assets/icons/info-italic.svg',
  },
  {
    name: 'edit',
    path: 'assets/icons/edit.svg',
  },
  {
    name: 'plus-outlined',
    path: 'assets/icons/plus-outlined.svg',
  },
  {
    name: 'alert-box',
    path: 'assets/icons/alert-box.svg',
  },
  {
    name: 'free',
    path: 'assets/icons/free.svg',
  },
  {
    name: 'rating',
    path: 'assets/icons/rating.svg',
  },
  {
    name: 'bargain',
    path: 'assets/icons/bargain.svg',
  },
  {
    name: 'auction',
    path: 'assets/icons/auction.svg',
  },
  {
    name: 'check',
    path: 'assets/icons/check.svg',
  },
  {
    name: 'pdf2',
    path: 'assets/icons/pdf2.svg',
  },
  {
    name: 'search',
    path: 'assets/icons/search.svg',
  },
  {
    name: 'key',
    path: 'assets/icons/key.svg',
  },
  {
    name: 'blue-arrow-up',
    path: 'assets/icons/blue_arrow_up.svg',
  },
  {
    name: 'arrow-down',
    path: 'assets/icons/arrow-down.svg',
  },
  {
    name: 'ui-arrow-down-narrow',
    path: 'assets/icons/arrow-down-narrow.svg',
  },
  {
    name: 'ui-arrow-up-narrow',
    path: 'assets/icons/arrow-up-narrow.svg',
  },
  {
    name: 'filters',
    path: 'assets/icons/filters.svg',
  },
  {
    name: 'help',
    path: 'assets/icons/help-circle-outline.svg',
  },
  {
    name: 'termination-agreement',
    path: 'assets/icons/termination-agreement.svg',
  },
  {
    name: 'termination-agreement-half',
    path: 'assets/icons/termination-agreement-half.svg',
  },
  {
    name: 'termination-agreement-full',
    path: 'assets/icons/termination-agreement-full.svg',
  },
  {
    name: 'tender',
    path: 'assets/icons/tender.svg',
  },
  {
    name: 'sts-example',
    path: 'assets/icons/sts-example.svg',
  },
  {
    name: 'vin-example',
    path: 'assets/icons/vin-example.svg',
  },
  {
    name: 'gosNumber-example',
    path: 'assets/icons/gosNumber-example.svg',
  },
  {
    name: 'gosTrailerNumber-example',
    path: 'assets/icons/gosTrailerNumber-example.svg',
  },
  {
    name: 'settings',
    path: 'assets/icons/settings.svg',
  },
  {
    name: 'info-bg-blue',
    path: 'assets/icons/info-bg-blue.svg',
  },
  {
    name: 'check-bg-green',
    path: 'assets/icons/check-bg-green.svg',
  },
  {
    name: 'info-green',
    path: 'assets/icons/info-green.svg',
  },
  {
    name: 'logout',
    path: 'assets/icons/logout.svg',
  },
  {
    name: 'plus',
    path: 'assets/icons/plus.svg',
  },
  {
    name: 'chevron_left',
    path: 'assets/icons/chevron_left.svg',
  },
  {
    name: 'copy',
    path: 'assets/icons/copy.svg',
  },
  {
    name: 'list',
    path: 'assets/icons/list.svg',
  },
  {
    name: 'message-question-ask',
    path: 'assets/icons/message-question-ask.svg',
  },
  {
    name: 'equalizer',
    path: 'assets/icons/equalizer.svg',
  },
  {
    name: 'eye-outline',
    path: 'assets/icons/eye-outline.svg',
  },
  {
    name: 'business-center',
    path: 'assets/icons/business-center.svg',
  },
  {
    name: 'support-email',
    path: 'assets/icons/support-email.svg',
  },
  {
    name: 'support-phone',
    path: 'assets/icons/support-phone.svg',
  },
  {
    name: 'email',
    path: 'assets/icons/email.svg',
  },
  {
    name: 'phone',
    path: 'assets/icons/phone.svg',
  },
  {
    name: 'light-check',
    path: 'assets/icons/light-check.svg',
  },
  {
    name: 'chevron-up',
    path: 'assets/icons/chevron-up.svg',
  },
  {
    name: 'chevron-down',
    path: 'assets/icons/chevron-down.svg',
  },
  {
    name: 'location',
    path: 'assets/icons/location.svg',
  },
  {
    name: 'list-2',
    path: 'assets/icons/list-2.svg',
  },
  {
    name: 'truck-2',
    path: 'assets/icons/truck-2.svg',
  },
  {
    name: 'document',
    path: 'assets/icons/document.svg',
  },
  {
    name: 'document-2',
    path: 'assets/icons/document-2.svg',
  },
  {
    name: 'dollar',
    path: 'assets/icons/dollar.svg',
  },
  {
    name: 'explanation-neutral',
    path: 'assets/icons/explanation-neutral.svg',
  },
  {
    name: 'explanation-error',
    path: 'assets/icons/explanation-error.svg',
  },
  {
    name: 'explanation-warning',
    path: 'assets/icons/explanation-warning.svg',
  },
  {
    name: 'minus',
    path: 'assets/icons/minus.svg',
  },
  {
    name: 'pdf-file',
    path: 'assets/icons/pdf-file.svg',
  },
  {
    name: 'doc-file',
    path: 'assets/icons/doc-file.svg',
  },
  {
    name: 'docx-file',
    path: 'assets/icons/docx-file.svg',
  },
  {
    name: 'jpg-file',
    path: 'assets/icons/jpg-file.svg',
  },
  {
    name: 'gif-file',
    path: 'assets/icons/gif-file.svg',
  },
  {
    name: 'png-file',
    path: 'assets/icons/png-file.svg',
  },
  {
    name: 'marker-outline-from',
    path: 'assets/icons/marker-outline-from.svg',
  },
  {
    name: 'marker-outline-to',
    path: 'assets/icons/marker-outline-to.svg',
  },
  {
    name: 'marker-outline-point',
    path: 'assets/icons/marker-outline-point.svg',
  },
  {
    name: 'person',
    path: 'assets/icons/person.svg',
  },
  {
    name: 'order-directions',
    path: 'assets/icons/order-directions.svg',
  },
  {
    name: 'close',
    path: 'assets/icons/close.svg',
  },
  {
    name: 'blue-fulled-checkbox',
    path: 'assets/icons/blue-fulled-checkbox.svg',
  },
  {
    name: 'gray-empty-checkbox',
    path: 'assets/icons/gray-empty-checkbox.svg',
  },
  {
    name: 'more-horiz',
    path: 'assets/icons/more-horiz.svg',
  },
  {
    name: 'application',
    path: 'assets/icons/application.svg',
  },
  {
    name: 'delete',
    path: 'assets/icons/delete.svg',
  },
  {
    name: 'warning-outline',
    path: 'assets/icons/warning-outline.svg',
  },
  {
    name: 'diy',
    path: 'assets/icons/diy.svg',
  },
  {
    name: 'red-outline-circle',
    path: 'assets/icons/red-outline-circle.svg',
  },
  {
    name: 'red-circle',
    path: 'assets/icons/red-circle.svg',
  },
  {
    name: 'marker-from',
    path: 'assets/icons/marker-from.svg',
  },
  {
    name: 'marker-to',
    path: 'assets/icons/marker-to.svg',
  },
  {
    name: 'doubt',
    path: 'assets/icons/doubt.svg',
  },
  {
    name: 'download-file',
    path: 'assets/icons/download_file.svg',
  },
  {
    name: 'done',
    path: 'assets/icons/done.svg',
  },
  {
    name: 'clear',
    path: 'assets/icons/clear.svg',
  },
  {
    name: 'post-add',
    path: 'assets/icons/post-add.svg',
  },
  {
    name: 'calendar-today',
    path: 'assets/icons/calendar-today.svg',
  },
  {
    name: 'filter-list',
    path: 'assets/icons/filter_list.svg',
  },
  {
    name: 'highlight-off',
    path: 'assets/icons/highlight-off.svg',
  },
  {
    name: 'ballot',
    path: 'assets/icons/ballot.svg',
  },
  {
    name: 'build',
    path: 'assets/icons/build.svg',
  },
  {
    name: 'mobile-friendly',
    path: 'assets/icons/mobile-friendly.svg',
  },
  {
    name: 'mobile-off',
    path: 'assets/icons/mobile-off.svg',
  },
  {
    name: 'paper-plane',
    path: 'assets/icons/paper-plane.svg',
  },
  {
    name: 'open-window',
    path: 'assets/icons/open-window.svg',
  },
  {
    name: 'api-connector',
    path: 'assets/icons/api-connector.svg',
  },
  {
    name: 'pin-drop-outlined',
    path: 'assets/icons/pin-drop-outlined.svg',
  },
  {
    name: 'file-copy',
    path: 'assets/icons/file_copy.svg',
  },
  {
    name: 'delete-forever',
    path: 'assets/icons/delete_forever.svg',
  },
  {
    name: 'route-distance',
    path: 'assets/icons/route-distance.svg',
  },
  {
    name: 'route-expansion',
    path: 'assets/icons/route-expansion.svg',
  },
  {
    name: 'route-loading',
    path: 'assets/icons/route-loading.svg',
  },
  {
    name: 'route-duration',
    path: 'assets/icons/route-duration.svg',
  },
  {
    name: 'double-chevron-right',
    path: 'assets/icons/double-chevron-right.svg',
  },
  {
    name: 'double-chevron-left',
    path: 'assets/icons/double-chevron-left.svg',
  },
  {
    name: 'gear',
    path: 'assets/icons/gear.svg',
  },
  {
    name: 'close_big',
    path: 'assets/icons/close_big.svg',
  },
  {
    name: 'close_xs',
    path: 'assets/icons/close_xs.svg',
  },
  {
    name: 'photo',
    path: 'assets/icons/photo.svg',
  },
  {
    name: 'publication-lock',
    path: 'assets/icons/publication-lock.svg',
  },
  {
    name: 'publication-truck',
    path: 'assets/icons/publication-truck.svg',
  },
  {
    name: 'question-mark-red',
    path: 'assets/icons/question-mark-red.svg',
  },
];
