<form [formGroup]="form">
  <h2 class="form__header hidden mb-3 font-semibold text-sm text-gray-700">{{ 'shared.mp.verification.stepper.contacts' | translate }}</h2>
  <div class="row flex gap-x-2">
    <transport-tw-input-field
      class="w-full"
      [errorToMessage]="validationErrors.bind(this)"
      label="{{ 'shared.mp.verification.form.contacts.fullName' | translate }}"
      size="sm"
      [control]="$any(form.controls.fullName)"
    >
      <transport-tw-input size="md" [control]="form.controls.fullName">
        <input tw-input class="text-sm" [required]="true" [formControl]="$any(form.controls.fullName)" />
      </transport-tw-input>
    </transport-tw-input-field>

    <transport-tw-input-field
      class="w-full"
      [errorToMessage]="validationErrors.bind(this)"
      label="{{ 'shared.mp.verification.form.contacts.post' | translate }}"
      size="sm"
      [control]="$any(form.controls.post)"
      *ngIf="!isSelfEmployed(legalForm | async)"
    >
      <transport-tw-input size="md" [control]="form.controls.post">
        <input tw-input class="text-sm" [formControl]="$any(form.controls.post)" />
      </transport-tw-input>
    </transport-tw-input-field>
  </div>

  <div class="row flex gap-x-2">
    <transport-tw-input-field
      class="w-full"
      [errorToMessage]="validationErrors.bind(this)"
      label="{{ 'shared.mp.verification.form.contacts.phone' | translate }}"
      size="sm"
      [control]="$any(form.controls.phone)"
    >
      <transport-tw-input size="md" [control]="form.controls.phone">
        <transport-phone-input [required]="true" formControlName="phone" [dataTest]="'driver-form-phone'"></transport-phone-input>
      </transport-tw-input>
    </transport-tw-input-field>

    <transport-tw-input-field
      class="w-full"
      [errorToMessage]="validationErrors.bind(this)"
      label="{{ 'shared.mp.verification.form.contacts.addPhone' | translate }}"
      size="sm"
      [control]="$any(form.controls.addPhone)"
    >
      <transport-tw-input size="md" [control]="form.controls.addPhone">
        <transport-phone-input formControlName="addPhone" [dataTest]="'driver-form-phone'"></transport-phone-input>
      </transport-tw-input>
    </transport-tw-input-field>
  </div>

  <div class="attorney row flex gap-x-2"  *ngIf="!isSelfEmployed(legalForm | async)">
    <div class="label flex justify-center flex-col">
      <span
        class="not-italic font-normal text-sm leading-4 text-gray-700"
        >{{ 'shared.mp.verification.form.contacts.attorney' | translate }}</span
      >
      <span
        class="not-italic font-normal text-xs leading-3 text-gray-500"
        >{{ 'shared.mp.verification.form.contacts.has' | translate }}</span
      >
    </div>
    <div class="file-selector">
      <transport-file-selector [errorToMessage]="validationErrors.bind(this)" [control]="form.controls.attorney"></transport-file-selector>
    </div>
  </div>
</form>
