export enum PROBLEM_EVENTS {
  ALL = 'ALL',
  EXISTS = 'EXISTS',
  NOT_EXISTS = 'NOT_EXISTS',
}

export interface IProblemEventsFilter {
  name: string;
  code: PROBLEM_EVENTS;
}

export const problemEvents: IProblemEventsFilter[] = [
  {
    name: 'carrier.order.problemsFilter.list.all',
    code: PROBLEM_EVENTS.ALL,
  },
  {
    name: 'carrier.order.problemsFilter.list.existProblems',
    code: PROBLEM_EVENTS.EXISTS,
  },
  {
    name: 'carrier.order.problemsFilter.list.notProblems',
    code: PROBLEM_EVENTS.NOT_EXISTS,
  },
];
