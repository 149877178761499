<h2>{{ 'shared.auth.login.form.title' | translate }}</h2>
<form
  *ngIf="loginForm"
  [formGroup]="loginForm"
  (ngSubmit)="submit($event)"
>
  <common-input
    [formGroup]="loginForm"
    size="md"
    class="w-full"
    [trim]="true"
    autocomplete="email"
    inputFieldClass="mb-2"
    errorsSize="xs"
    placeholder="{{ 'shared.auth.login.form.email.placeholder' | translate }}"
    label="{{ 'shared.auth.login.form.email.label' | translate }}"
    controlName="email"
    [errorToMessage]="emailValidationErrors.bind(this).bind(this)"
    dataTest="login-email"
  ><mat-icon>mail</mat-icon></common-input>

  <button data-test="login-button" (click)="submit($event)" class="tw-button-solid-md mb-2 w-full" [disabled]="!loginForm.valid">
    {{'shared.auth.login.form.continue' | translate}}
    <mat-spinner *ngIf="isLoading" diameter="18" ></mat-spinner>
  </button>

  <p class="registration">
    <a class="registration-link text-blue-500" [routerLink]="['/passport/registration']"> {{ 'shared.auth.login.form.registrationText' | translate }} </a>
  </p>
</form>
