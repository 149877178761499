import { Injectable } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class TnMatPaginatorIntl extends MatPaginatorIntl {
  constructor(private readonly translate: TranslateService) {
    super();
    this.itemsPerPageLabel = this.translate.instant('shared.pager.itemsPerPage');
    this.nextPageLabel = this.translate.instant('shared.pager.nextPageLabel');
    this.previousPageLabel = this.translate.instant('shared.pager.previousPageLabel');
    this.firstPageLabel = this.translate.instant('shared.pager.firstPageLabel');
    this.lastPageLabel = this.translate.instant('shared.pager.lastPageLabel');
  }

  public getRangeLabel = (page: number, pageSize: number, lengthValue: number): string => {
    const ofString = this.translate.instant('shared.pager.ofString');

    if (lengthValue === 0 || pageSize === 0) {
      return `0 ${ofString} ${lengthValue}`;
    }
    const length = Math.max(lengthValue, 0);
    const startIndex = page * pageSize;
    const endIndex = startIndex < length ? Math.min(startIndex + pageSize, length) : startIndex + pageSize;
    const maxSimpleDigit = 9;
    return `${startIndex < maxSimpleDigit ? `0${startIndex + 1}` : `${startIndex + 1}`} - ${endIndex} ${ofString} ${length}`;
  };
}
