import { AfterViewInit, Component, EventEmitter, Input, NgZone, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'transport-accreditation-autocomplete',
  templateUrl: './accreditation-autocomplete.component.html',
  styleUrls: ['./accreditation-autocomplete.component.scss'],
})
export class AccreditationAutocompleteComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() public data: any[] = [];

  @Input() public placeholder = '';

  @Input() public size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';

  @Input() public errorsSize: 'xs' | 'sm' | 'md' | 'lg' = 'xs';

  @Input() public bindLabel = '';

  @Input() public bindValue = '';

  @Input() public dataTest = '';

  @Input() public required = false;

  @Input() public labelInside = false;

  @Input() public showErrors = true;

  @Input() public showEmptyMessage = false;

  @Input() public loading$;

  @Input() public allowClear: boolean = false;

  @Input() public control!: FormControl;

  @Input() public showAddBtn = true;

  @Output() public readonly addClicked = new EventEmitter();

  @Output() public readonly sendOnCheck = new EventEmitter();

  public visibleData: any[] = [];

  public separator!: number;

  @ViewChild('searchRef', { static: false }) searchRef;

  @ViewChild(MatAutocompleteTrigger) trigger!: MatAutocompleteTrigger;

  constructor(private readonly translate: TranslateService, private readonly zone: NgZone) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.data) {
      this.visibleData = this.sortData(this.data);
      this.separator = this.setSeparator(this.visibleData);
      if (Boolean(this.control.value)) {
        this.setValueInput(this.control.value);
      }
    }
  }

  public ngOnInit(): void {
    this.visibleData = this.sortData(this.data);
    this.control.valueChanges.subscribe(id => {
      if (id) {
        this.setValueInput(id);
      }
      this.separator = this.setSeparator(this.visibleData);
    });
  }

  public ngAfterViewInit() {
    if (Boolean(this.control.value)) {
      this.setValueInput(this.control.value);
    }
  }

  public disabledField(accStatus): boolean {
    return accStatus !== 'passed';
  }

  public inputFocused = false;

  public setValueInput(value) {
    const bindValue = Boolean(value[this.bindValue]) ? value[this.bindValue] : value;
    const selectedObj = this.data?.find(el => el[this.bindValue] === bindValue);
    if (Boolean(selectedObj)) {
      this.searchRef.nativeElement.value = selectedObj[this.bindLabel];
      this.searchRef.nativeElement.blur();
    }
  }

  public displayFn(value?: number) {
    return Boolean(value) ? this.getLabel(this.data.find(elem => this.getValue(elem) === value)) : '';
  }

  public addItem() {
    this.trigger.closePanel();
    this.searchRef.nativeElement.blur();
    this.addClicked.emit();
  }

  public getLabel(item) {
    return item[this.bindLabel];
  }

  public getValue(item) {
    return item[this.bindValue];
  }

  public onCheck(item): void {
    this.sendOnCheck.emit(item.id);
    this.trigger.closePanel();
  }

  private lastSelectedValue?: string;

  public onItemSelected(event) {
    this.lastSelectedValue = event.option.value;
    this.control.setValue(event.option.value);
  }

  public isSelected(item) {
    return this.getValue(item) === this.lastSelectedValue;
  }

  public onBlur() {
    this.control.markAsTouched();
    this.inputFocused = false;
    if (this.lastSelectedValue) {
      const option = this.data.find(d => d[this.bindValue] === this.lastSelectedValue);
      if (option) {
        this.searchRef.nativeElement.value = option[this.bindLabel];
      }
    }
  }

  public clearSelect(e): void {
    e.stopPropagation();
    this.control.setValue(null);
    this.searchRef.nativeElement.value = null;
  }

  public searching(event) {
    const value = event.target.value.toLowerCase();
    this.visibleData = this.sortData(this.data.filter(elem => this.getLabel(elem).toLowerCase().includes(value)));
    this.separator = this.setSeparator(this.visibleData);
  }

  public onClickArrow() {
    this.inputFocused ? this.searchRef.nativeElement.blur() : this.searchRef.nativeElement.focus();
  }

  public clickAtInsideLabel() {
    this.searchRef.nativeElement.focus();
    // It doesn't want to open autocomplete panel at the same time with focus event.
    this.zone.runOutsideAngular(() => {
      setTimeout(() => {
        this.trigger.openPanel();
      }, 0);
    });
  }

  public errorToMessage = (errors: ValidationErrors | null) => {
    if (errors?.required) {
      return this.translate.instant('shared.errors.required');
    }
    return '';
  };

  private setSeparator(data): number {
    return this.sortData(data)?.findIndex(el => el?.accrStatus !== 'passed');
  }

  private sortData(data): any[] {
    const dataPassed = data?.filter(el => el.accrStatus === 'passed');
    const dataPending = data?.filter(el => el.accrStatus !== 'passed' && el.accrStatus !== 'not_passed');
    const dataNotPassed = data?.filter(el => el.accrStatus === 'not_passed');
    return dataPassed?.concat(dataPending)?.concat(dataNotPassed);
  }
}
