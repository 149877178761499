<table cdk-table [dataSource]="dataSource">

  <colgroup>
    <ng-container *ngFor="let col of columnsToShow">
      <ng-container *ngIf="columnsDictionary[col].width || columnsDictionary[col].minWidth; then colWithWidthOrMinWidth; else colWithoutWidth"></ng-container>
      <ng-template #colWithWidthOrMinWidth>
        <ng-container *ngIf="columnsDictionary[col].width; then colWithWidth; else colWithMinWidth"></ng-container>
      </ng-template>
      <ng-template #colWithWidth><col [style.width.px]="columnsDictionary[col].width"/></ng-template>
      <ng-template #colWithMinWidth><col [ngStyle]="{'min-width.px': columnsDictionary[col].minWidth}"/></ng-template>
      <ng-template #colWithoutWidth><col/></ng-template>
    </ng-container>
  </colgroup>

  <ng-container *ngFor="let column of columns">
    <ng-container *ngIf="!column.columnNames.length">
      <ng-container [cdkColumnDef]="column.columnName">
        <th cdk-header-cell *cdkHeaderCellDef> {{ columnsDictionary[column.columnName]?.header ?? ''}} </th>
        <td cdk-cell *cdkCellDef="let row">
          <div class="table_cell_content_wrapper">
            <ng-container [ngTemplateOutlet]="column.template" [ngTemplateOutletContext]="{$implicit: row, columnName: column.columnName}"></ng-container>
          </div>
        </td>
      </ng-container>
    </ng-container>

    <ng-container *ngIf="column.columnNames.length">
      <ng-container *ngFor="let colName of column.columnNames">
        <ng-container [cdkColumnDef]="colName">
          <th cdk-header-cell *cdkHeaderCellDef> {{ columnsDictionary[colName]?.header ?? ''}} </th>
          <td cdk-cell *cdkCellDef="let row">
            <div class="table_cell_content_wrapper">
              <ng-container [ngTemplateOutlet]="column.template" [ngTemplateOutletContext]="{$implicit: row, columnName: colName}"></ng-container>
            </div>
          </td>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="!_hideTableHeader">
    <tr cdk-header-row *cdkHeaderRowDef="columnsToShow"></tr>
  </ng-container>
  <tr
    cdk-row
    *cdkRowDef="let row; columns: columnsToShow"
    [ngClass]="getRowClasses(row)"
    marketplaceRowExtender
    [rowData]="row"
    [rowExtender]="rowExtender"
    [attr.data-test]="(tableName ? tableName + '_' : '') + 'mp-table-row_' + getRowId(row)"
  ></tr>
</table>
