import { USER_ROLE } from '../user';
import { IOrderAttachment } from './order-attachment';
import { TnUploadedFile } from './uploaded-file';

export class TnOrderAttachmentSign {
  public signedUserRole: USER_ROLE;

  public id?: string;

  public uploadedFile: TnUploadedFile;

  public deletable: boolean;

  public signedFileId?: string | null;

  public constructor(doc: IOrderAttachment) {
    this.signedUserRole = USER_ROLE[doc.organization?.role ?? ''];
    this.deletable = doc.deletable;
    this.id = doc.id;
    this.uploadedFile = doc.uploadedFile;
    this.signedFileId = doc.signedFile?.id;
  }
}
