import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { transport } from '@transport/proto';
import { MAX_EMAIL_LENGTH, regExpConfig } from '@transport/ui-utils';

import { trimmedStringValidator } from '../form-validators';

export interface IOrganizationFormValue {
  legalForm: transport.ILegalForm | null;
  ogrn?: string;
  companyName?: string;
  inn: string;
  kpp?: string;
  legalAddress?: string;
  postalAddress: string;
}

export interface IOrganizationForm extends FormGroup {
  controls: {
    legalForm: AbstractControl;
    ogrn: AbstractControl;
    companyName: AbstractControl;
    inn: AbstractControl;
    kpp: AbstractControl;
    legalAddress: AbstractControl;
    postalAddress: AbstractControl;
  };
}

export interface IProfileOrganizationFormValue {
  organization: IOrganizationFormValue;
  phoneNumber: string;
  email: string;
  signer: string;
  vatType: string;
}

export interface IProfileOrganizationForm extends FormGroup {
  controls: {
    organization: AbstractControl;
    phoneNumber: AbstractControl;
    email: AbstractControl;
    signer: AbstractControl;
    vatType: AbstractControl;
  };
}

export type TProfileOrganizationFormConstructor = (
  fb: FormBuilder,
  editableItem?: IProfileOrganizationFormValue,
) => IProfileOrganizationForm;

export const newProfileOrganizationForm: TProfileOrganizationFormConstructor = (
  fb: FormBuilder,
  editableItem?: IProfileOrganizationFormValue,
): IProfileOrganizationForm => {
  return fb.group({
    organization: [editableItem?.organization],
    vatType: [editableItem?.vatType],
    phoneNumber: [editableItem?.phoneNumber, [Validators.pattern(regExpConfig.phoneNumber.RU)]],
    email: [editableItem?.email, [Validators.pattern(regExpConfig.email.regExp), Validators.maxLength(MAX_EMAIL_LENGTH)]],
    signer: [editableItem?.signer, [trimmedStringValidator]],
  }) as IProfileOrganizationForm;
};
