import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { transport } from '@transport/proto';

import { ITnState } from '../../../state';
import { TnTableStateFacade } from '../../../table/table-state-facade';
import { tableActions } from './actions/agent-carriers.actions';
import { removeAgent, removeAgentSuccess } from './actions/agent-carriers-remove.actions';
import { TnAgentCarriersService } from './agent-carriers.service';
import { tableSelectors } from './selectors/agent-carriers.selectors';

export interface IAgentCarriersFilter {
  availability?: string;
  carrierName?: string;
  name?: string;
  inn?: string;
  kpp?: string;
}

@Injectable({
  providedIn: 'root',
})
export class TnDirectoryAgentCarriersFacade extends TnTableStateFacade<IAgentCarriersFilter> {
  public removeAgent$ = this.action.pipe(ofType(removeAgentSuccess));

  constructor(
    public store: Store<ITnState>,
    public action: Actions,
    public agentCarrierService: TnAgentCarriersService,
    private readonly translate: TranslateService,
  ) {
    super(store, tableActions, tableSelectors);
  }

  public getAgentCarriers(listenX?: number, withSpinner?: boolean, options?: Record<string, unknown>) {
    return this.agentCarrierService.getAgentCarriers(listenX, withSpinner, options);
  }

  public removeAgent(id: string) {
    this.store.dispatch(removeAgent({ id }));
  }

  public changeAgentCarrierNameMapper = (agentCarrier: transport.IAgentCarrier) => ({
    ...agentCarrier,
    name: this.translate.instant('carrier.directory.agentCarriers.details.agentCarrierName', {
      value: agentCarrier.name as string,
      inn: agentCarrier.inn as string,
    }),
  });
}
