import { Injectable } from '@angular/core';
import { transport } from '@transport/proto';
import { TCarrierGroupForm } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnGqlClientOwnerMutationsService } from '../../../gql-client-owner/graphql-client-owner-mutations.service';
import { TnGqlClientOwnerQueriesService } from '../../../gql-client-owner/graphql-client-owner-queries.service';

@Injectable({
  providedIn: 'root',
})
export class TnOwnerDirectoryCarrierGroupsApiService {
  constructor(private readonly gqlQuery: TnGqlClientOwnerQueriesService, private readonly gqlMutation: TnGqlClientOwnerMutationsService) {}

  /**
   * Request for list of items
   */
  public getCarrierGroupsList(
    listenX?: number,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
    options?: Record<string, unknown>,
  ): Observable<{ list: transport.Carrier.ICarrierGroup[]; totalCount: number }> {
    return this.gqlQuery.getCarrierGroups(listenX, withSpinner, first, offset, options).pipe(
      map(data => {
        return {
          list: data.myCarrierGroups,
          totalCount: data.totalCount ?? 0,
        };
      }),
    );
  }

  public saveGroup(
    group: TCarrierGroupForm & { id: string },
    withSpinner = true,
  ): Observable<{ group: Partial<transport.Carrier.ICarrierGroup> }> {
    return this.gqlMutation.saveGroup(group, withSpinner);
  }

  public createGroup(
    group: TCarrierGroupForm & { id: string },
    withSpinner = true,
  ): Observable<{ group: Partial<transport.Carrier.ICarrierGroup> }> {
    return this.gqlMutation.createGroup(group, withSpinner);
  }

  public getGroup(groupId: string, withSpinner = true): Observable<{ group: transport.Carrier.ICarrierGroup }> {
    return this.gqlQuery.queryGroup(groupId, withSpinner);
  }

  public removeGroup(groupId: string, withSpinner = true): Observable<{ group: Partial<transport.Carrier.ICarrierGroup> }> {
    return this.gqlMutation.removeGroup(groupId, withSpinner);
  }
}
