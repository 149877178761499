import {redirectByLinkMouseExtender} from './redirect-by-link-mouse.extender';
import {redirectByLinkKeyboardExtender} from './redirect-by-link-keyboard.extender';
import {composeRowExtenders} from "@marketplace/shared/modules/table/plain-table/table-row-extender";
import {Router} from "@angular/router";

interface ITreatLikeALinkExtenderConfig<T> {
  router: Router;
  linkBuilder: (rowData: T) => string;
}

/**
 * Redirects user implementing opening new tabs. Supports keyboard navigation and mouse clicks.
 * The extender subscribes on `mouseup` event of row element.
 * The extender subscribes on `keydown` event of row element, and sets `tabindex` attribute on the row element.
 * @param config - Config object
 * @param {Function} config.navigateFunction - function fires when event appears.
 */
export function redirectByLinkExtender<T>(config: ITreatLikeALinkExtenderConfig<T>) {
  return composeRowExtenders([redirectByLinkMouseExtender(config), redirectByLinkKeyboardExtender(config)]);
}
