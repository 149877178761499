import { createFeatureSelector, createSelector } from '@ngrx/store';

import { makeTableSelectors } from '../../../../table/table-selectors';
import { IDirectoryState } from '../../../directory-state.interface';

const getDirectoryState = createFeatureSelector<IDirectoryState>('directory');
const trucksStateSelector = createSelector(getDirectoryState, (state: IDirectoryState) => state.trucks);

const chooseTrucksStateSelector = createSelector(getDirectoryState, (state: IDirectoryState) => state.chooseTrucks);

export const tableSelectors = makeTableSelectors(trucksStateSelector);

export const chooseTrucksTableSelectors = makeTableSelectors(chooseTrucksStateSelector);
