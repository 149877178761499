import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { transport } from '@transport/proto';

export enum VEHICLE_REQUIREMENTS {
  MAX_VOLUME_VEHICLE = 120,
  MIN_VOLUME_VEHICLE = 0,

  MAX_WIDTH_VEHICLE = 99.99,
  MIN_WIDTH_VEHICLE = 0.01,

  MAX_LENGTH_VEHICLE = 99.99,
  MIN_LENGTH_VEHICLE = 0.01,

  MAX_HEIGHT_VEHICLE = 99.99,
  MIN_HEIGHT_VEHICLE = 0.01,

  MAX_CAPACITY_VEHICLE = 99.99,
  MIN_CAPACITY_VEHICLE = 0,

  MIN_TEMPERATURE = -99,
  MAX_TEMPERATURE = 99,
  MAX_WEIGHT = 99.99,

  SPECIAL_REQUIREMENTS_MAX_LENGTH = 100,
}

const validateRange = (value: number, max: VEHICLE_REQUIREMENTS, min = 0) => {
  return value >= min && value <= max;
};

const getErrors = (value: transport.Order.IVehicleRequirements | null) => {
  if (value === null) return null;
  const capacityValid = validateRange(value.capacity ?? 0, VEHICLE_REQUIREMENTS.MAX_CAPACITY_VEHICLE, VEHICLE_REQUIREMENTS.MIN_CAPACITY_VEHICLE);
  const volumeValid = validateRange(value.volume ?? 0, VEHICLE_REQUIREMENTS.MAX_VOLUME_VEHICLE, VEHICLE_REQUIREMENTS.MIN_VOLUME_VEHICLE);
  const heightValid = !value.height || validateRange(value.height, VEHICLE_REQUIREMENTS.MAX_HEIGHT_VEHICLE, VEHICLE_REQUIREMENTS.MIN_HEIGHT_VEHICLE);
  const lengthValid = !value.length || validateRange(value.length, VEHICLE_REQUIREMENTS.MAX_LENGTH_VEHICLE, VEHICLE_REQUIREMENTS.MIN_LENGTH_VEHICLE);
  const widthValid = !value.width || validateRange(value.width, VEHICLE_REQUIREMENTS.MAX_WIDTH_VEHICLE, VEHICLE_REQUIREMENTS.MIN_WIDTH_VEHICLE);

  const condition = !volumeValid || !capacityValid || !heightValid || !lengthValid || !widthValid;

  return condition ? { vehicleRequirements: true } : null;
};

export const vehicleRequirementsValidator: ValidatorFn = (control: AbstractControl) => getErrors(control.value) as ValidationErrors;
