import { ActivatedRouteSnapshot, Data, Params } from '@angular/router';
import * as fromRouter from '@ngrx/router-store';

/**
 * Router state url interface.
 */
export interface IRouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
  root: Partial<ActivatedRouteSnapshot>;
  data: Data;
}

/**
 * Router state.
 */
export interface IRouterState {
  router: fromRouter.RouterReducerState<IRouterStateUrl>;
}

export const routerFeatureKey = 'router';

export const initialRouterState: fromRouter.RouterReducerState<IRouterStateUrl> = {
  state: { url: '/', params: {}, data: {}, queryParams: {}, root: {} },
  navigationId: -1,
};
