<div class="app-container">
  <transport-tncl-sso-frame (ssoLogout)="logoutBySSO()"></transport-tncl-sso-frame>
  <div class="content-container" [ngClass]="{'full_screen': isPassportPage}" transportTooltip [isGlobalMode]="true">
    <div class="flex flex-col justify-between z-10 shadow-sm bg-white" *ngIf="!firstLoad && !isPassportPage" [@menuBlockAnimation]="burgerService.menuState$.value">
      <sidebar [items]="(sidebarGuard.allowedItems$ | async) || []" class="flex-auto h-screen">
        <transport-header
          class="flex items-center mx-4 mt-1"
          [ngClass]="{'!m-1': burgerService.menuState$.value === menuState.OUT}"
          [homePath]="homePath$ | async"
          [menuState$]="burgerService.menuState$"
          logo
        > </transport-header>
      </sidebar>      
      <div
        *ngIf="trialExpDate && !isExpired"
        class="bg-gray-100 w-100 text-gray-500 flex flex-col items-center align-bottom text-center text-xs p-1 border-gray-300 border-r"
      >
        <span
          class="whitespace-nowrap"
          *ngIf="burgerService.menuState$.value === menuState.IN; else infoItalic"
        >
          {{ 'shared.trial.expAt' | translate }}
          <span>{{ trialExpDate | date: 'dd.MM.yyyy'}}</span>
        </span>
        <ng-template #infoItalic>
          <mat-icon
            class="w-6 hover:cursor-pointer limit-svg"
            svgIcon="info-italic"
            [matTooltip]="('shared.trial.expAt' | translate) + ' ' + (trialExpDate | date: 'dd.MM.yyyy')"
            matTooltipShowDelay="0"
            matTooltipHideDelay="0"
          ></mat-icon>
        </ng-template>
      </div>
    </div>

    <div
      class="content-wrap"
      cdkScrollable
      [@transportContentBlockAnimation]="!isPassportPage && burgerService.contentState$.value"
      (click)="fadeOutClick()"
    >
      <ng-container *ngIf="trialExpDate && isExpired">
        <div class="flex items-center justify-center text-xs text-black bg-orange-100 opacity-50">
          {{ 'shared.trial.ends' | translate }} {{ trialExpDate | date: 'dd.MM.yyyy'}}
        </div>
      </ng-container>
      <transport-system-banner *ngIf="hasToken" banner (activated)="onShowBanner($event)"></transport-system-banner>
      <div class="content" [ngClass]="{'max-content-height': trialExpDate && isExpired}">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>

  <button *ngIf="widgetOpened"
          class="widget-closer flex fixed bottom-14 right-4 w-4 h-4 z-[999] cursor-pointer rounded-full hover:bg-gray-200 bg-gray-100"
          (click)="closeWidget()">
          <mat-icon class="!w-4 !h-4 !text-base !leading-4">close</mat-icon>
  </button>
</div>
