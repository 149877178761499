import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'transport-table-column-text',
  templateUrl: './table-column-text.component.html',
  styleUrls: ['./table-column-text.component.scss'],
  animations: [
    trigger('hightlight', [
      state('true', style({ color: '#38A169' })),
      state('false', style({ color: '*' })),
      transition('* <=> *', animate('1s')),
    ]),
  ],
})
export class TnTableColumnTextComponent {
  @Input() public textTop: string | null | undefined = '';

  @Input() public textBottom: string | null | undefined = '';

  @Input() public showTextBottom = true;

  @Input() public contracts: { contractNumber: string; startDate: string; file: string }[] = [];

  @Input() public hightlight = false;
}
