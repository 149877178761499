import { regExpConfig } from '@transport/ui-utils';
import { NgxMaskService } from 'ngx-mask';

// We can't import it from @transport/ui-pipes, because it breaks the build
enum TnSupportedCountry {
  RU = 'RU',
  KZ = 'KZ',
}

export function setRegistrationNumberPattern(
  maskService: NgxMaskService,
  vehicleId: string,
  country?: TnSupportedCountry | string | null,
) {
  const vId = vehicleNumberPrepare(vehicleId, country);

  return maskService.applyMaskWithPattern(vId.toLocaleUpperCase(), [
    regExpConfig.ngxMask.vehiclePassport[country ? country.toLowerCase() : 'ru']?.registrationNumber.mask,
    regExpConfig.ngxMask.vehiclePassport[country ? country.toLowerCase() : 'ru']?.registrationNumber.patterns ?? {},
  ]);
}

export function vehicleNumberPrepare(vehicleId: string, country?: TnSupportedCountry | string | null): string {
  let number;
  switch (country) {
    case TnSupportedCountry.RU:
      number = `${vehicleId.slice(0, 1)} ${vehicleId.slice(1, 4)} ${vehicleId.slice(4, 6)} ${vehicleId.slice(6)}`;
      break;

    case TnSupportedCountry.KZ:
      // Because of KZ mask and pattern, without it if put "123 AA 12" then the mask converts it to "123 AA"
      number = `${vehicleId.slice(0, 3)} ${vehicleId.slice(3, -2)} ${vehicleId.slice(-2)}`;
      break;

    default:
      number = vehicleId;
      break;
  }

  return number;
}

export function trailerNumberPrepare(trailerId: string, country?: TnSupportedCountry | string | null): string {
  let number;
  switch (country) {
    case TnSupportedCountry.RU:
      number = `${trailerId.slice(0, 2)} ${trailerId.slice(2, 6)} ${trailerId.slice(6)}`;
      break;

    case TnSupportedCountry.KZ:
      number = `${trailerId.slice(0, 3)} ${trailerId.slice(3, -2)} ${trailerId.slice(-2)}`;
      break;

    default:
      number = trailerId;
      break;
  }

  return number;
}
