import { createAction, props } from '@ngrx/store';
import { transport } from '@transport/proto';
import { TCarrierGroupForm, TUserPredefinedAction } from '@transport/ui-interfaces';

import { RouterActions } from '../../../../ngrx-router/actions/router.actions';

const setFormMode = createAction('[CarrierGroup] Set Form Mode', props<{ mode: TUserPredefinedAction }>());

const navigateToGroups = (params: string[] = []) => RouterActions.routerGo({ path: ['directory/owner/carriers/trusted/groups', ...params] });

const showGroupDetails = createAction('[CarrierGroup] Show Group Details', props<{ mode: TUserPredefinedAction }>());

// LOAD
const loadGroup = createAction('[CarrierGroup] Load Group', props<{ groupId: string }>());
const loadGroupSuccess = createAction('[CarrierGroup] Load Group Success', props<{ group: transport.Carrier.ICarrierGroup }>());
const loadGroupFailure = createAction('[CarrierGroup] Load Group Failure', props<{ error: Error }>());

// SAVE
const saveGroup = createAction('[CarrierGroup] Save Group', props<{ group: TCarrierGroupForm }>());
const saveGroupSuccess = createAction('[CarrierGroup] Save Group Success', props<{ group: transport.Carrier.ICarrierGroup }>());
const saveGroupFailure = createAction('[CarrierGroup] Save Group Failure', props<{ error: Error }>());
const validateGroupForm = createAction('[CarrierGroup] Validate Group Form');

// REMOVE
const removeGroup = createAction('[CarrierGroup] Remove Group', props<{ id?: string }>());

const removeGroupSuccess = createAction('[CarrierGroup] Remove Group Success', props<{ group: transport.Carrier.ICarrierGroup }>());
const removeGroupFailure = createAction('[CarrierGroup] Remove Group Failure', props<{ error: Error }>());

//FORM STATUS
const formValidStatusChanged = createAction('[CarrierGroup] Form Valid Status Changed', props<{ isValid: boolean }>());

const formDirtyStatusChanged = createAction('[CarrierGroup] Form Dirty Status Changed', props<{ isDirty: boolean }>());

export const carrierGroupActions = {
  setFormMode,
  navigateToGroups,
  showGroupDetails,
  loadGroup,
  loadGroupSuccess,
  loadGroupFailure,
  saveGroup,
  saveGroupSuccess,
  saveGroupFailure,
  validateGroupForm,
  removeGroup,
  removeGroupSuccess,
  removeGroupFailure,
  formValidStatusChanged,
  formDirtyStatusChanged,
};
