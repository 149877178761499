<div class="vehicles-page m-4 rounded-md shadow-sm bg-white relative">
  <div class="vehicles-page__button-container flex justify-between items-center px-4">
    <div class="flex gap-4 items-center">
      <button (click)="onFilterModalOpen()" class="tw-button-outline-sm">
        <mat-icon svgIcon="filter-list"></mat-icon>
        {{ 'shared.mp.vehicles.filters.button' | translate }}
        <span
          *ngIf="filtersCount$ | async as filtersCount"
          class="flex justify-center items-center rounded-md text-sm leading-none py-0.5 px-1.5 bg-blue-500 text-white font-normal"
        >
          {{ filtersCount }}
        </span>
      </button>
      <marketplace-filters-toolbar data-test="filters-toolbar"></marketplace-filters-toolbar>
    </div>
    <button class="tw-button-solid-sm" *ngIf="canManage" (click)="createVehicles()">
      <mat-icon svgIcon="plus"></mat-icon>
      {{ 'shared.mp.vehicles.table.createButton' | translate }}
    </button>
  </div>
  <div class="overflow-auto">
    <marketplace-vehicles-table [canManage]="!!canManage"></marketplace-vehicles-table>
  </div>
</div>

<ng-template #noVehicles>
  <div class="flex justify-center items-center absolute w-full h-[calc(100vh-120px)]">
    <h2 class="text-gray-300 text-xl">{{ 'shared.mp.vehicles.table.noVehicles' | translate }}</h2>
  </div>
</ng-template>

