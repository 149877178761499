import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { RouterActions } from '../../../ngrx-router/actions/router.actions';
import { ITnState } from '../../../state';
import { TnAuthApiService } from './auth-api.service';

@Injectable({
  providedIn: 'root',
})
export class TnAuthFacade {
  constructor(protected store: Store<ITnState>, private readonly authApiService: TnAuthApiService) {}

  public openExternalUrl(url: string, howOpen = '_self') {
    this.store.dispatch(RouterActions.routerToExternal({ url, howOpen }));
  }

  public getDigitalProfileUrl() {
    return this.authApiService.getDigitalProfileUrl();
  }
}
