import { AUCTION_CRITERION_CHOICES, ORDER_ALLOCATION_TYPE, ORDER_STATUS } from '@transport/ui-interfaces';
import { transport } from '@transport/proto';
import { ListStore } from '@marketplace/shared/modules/table';
import { IGCarrier } from '@transport/ui-interfaces';
import { TnSupportedCurrency } from 'libs/transport-ui-pipes/src/lib/currency/multi-currency.pipe';

export type CargoListStore = ListStore<ICargoOrderItem, keyof ICargoOrderItem, any>;

export interface ICargoOrderItem {
  id: string;
  bookingAttemptsLeft: number | null;
  externalNo: string | null;
  isMarket: boolean;
  acceptDeadline: string | null;
  hasMyCounterOffer: boolean;
  isPreviouslyAssigned: boolean | null;
  status: ORDER_STATUS;
  allocationType: ORDER_ALLOCATION_TYPE;
  customerOrganization: IGCarrier.ICargoOwnerOrganizationType | undefined;
  loadingPlaces: transport.Order.IStoragePoint[];
  loadingDate: string;
  loadingDateTo?: string;
  loadingTimeTo: string | null;
  loadingTimeFrom: string | null;
  unloadingTimeTo: string | null;
  unloadingTimeFrom: string | null;
  loadingDateSmall?: string | undefined;
  unloadingPlaces: transport.Order.IStoragePoint[];
  unloadingDate: string;
  unloadingDateTo?: string;
  unloadingDateSmall?: string | undefined;
  lot: transport.ILot & {
    additionalAuctionCriteria: {
    type: AUCTION_CRITERION_CHOICES;
    weight: number;
    }[] | null,
    myOrgAdditionalCriteriaData: {
      type: AUCTION_CRITERION_CHOICES;
      value: number;
    }[] | null,
    lastBetEquivalent: number | null;
  } | null;
  biddinglot?: transport.IBiddingLot | null;
  price: number | undefined | null;
  priceCurrency: TnSupportedCurrency | null;
  priceWithVat: number | undefined | null;
  priceWithoutVat: number | undefined | null;
  volume: number;
  weight: number;
  cargoTypeName?: string | null;
  allowCounterOffer?: boolean | null;
  cargoPackagesCount: number | null;
  cargoPlaceRequirements: {
    packagingType: string | undefined;
    height: number | null;
    length: number | null;
    width: number | null;
  };
  cargos: {
    name: string | null;
  }[];
  loadingTypes: string[];
  endDatetime: string | null;
  vehicleRequirements: {
    bodySubtypes: { name: string, id: string }[] | null;
    loadingTypes?: {
      id: string | null;
      name: string | null;
    }[];
    capacity?: number | null;
    volume?: number | null;
    deliveryMethod?: DELIVERY_METHODS | null;
  };
  isVatIncluded: boolean;
  carrierId: string | undefined;
  carrierBusinessRelationshipToOwner: BUSINESS_RELATIONSHIP | null;
  secondsToLifeTimeExpired?: string | null;
  timeOrderReservation?: string | null;
  tradingParticipantsCount?: number | null;
  unsignedTermAgreement: {orderId: string, orderExtId: string}[] | null;
}

export interface ICargoAuctionTableStoragePointResponse {
  arriveDatetimeFrom: string;
  arriveDatetimeTo: string;
  storagePoint: {
  id: string;
  name: string;
  address: string;
    timezone: {
  timezone: string;
    };
  };
}

export interface ICargoTableStoragePointResponse {
  arriveDatetimeFrom: string;
  arriveDatetimeTo: string;
  id: string;
  name: string;
  address: string;
  settlement: string;
  timezone: string;
}

export interface ICargoResponse {
  orderId: number;
  bookingAttemptsLeft: number | null;
  externalNo: string | null;
  isMarket: boolean;
  allowCounterOffer: boolean;
  hasMyCounterOffer: boolean;
  isPreviouslyAssigned: boolean | null;
  unsignedTermAgreement: {orderId: string, orderExtId: string}[] | null;
  acceptDeadline: string | null;
  status: ORDER_STATUS;
  allocationType: ORDER_ALLOCATION_TYPE;
  customerOrganization: IGCarrier.ICargoOwnerOrganizationType | null;
  loadingDate: string | null;
  loadingDateTo: string | null;
  loadingPlaces: ICargoTableStoragePointResponse[] | null;
  unloadingPlaces: ICargoTableStoragePointResponse[];
  unloadingDate: string | null;
  unloadingDateTo: string | null;
  lot: transport.ILot & {
    additionalAuctionCriteria: {
    type: AUCTION_CRITERION_CHOICES;
    weight: number;
    }[] | null,
    myOrgAdditionalCriteriaData: {
      type: AUCTION_CRITERION_CHOICES;
      value: number;
    }[] | null,
    lastBetEquivalent: number | null;
  } | null;
  biddinglot: transport.IBiddingLot | null;
  price: number | null;
  priceCurrency: TnSupportedCurrency | null;
  priceWithVat: number | null;
  priceWithoutVat: number | null;
  volume: number;
  weight: number;
  cargoTypeName?: string;
  cargoPackagesCount: number | null;
  cargoPlaceRequirements: {
    packagingType: string | null;
    height: number | null;
    length: number | null;
    width: number | null;
  };
  cargos: {
    name: string | null;
  }[];
  loadingTypes: string;
  vehicleRequirements: {
    bodySubtypes: { name: string, id: string }[] | null;
    loadingTypes?: {
      id: string | null;
      name: string | null;
    }[];
    capacity?: number | null;
    volume?: number | null;
    deliveryMethod?: DELIVERY_METHODS | null;
  };
  isVatIncluded: boolean;
  carrierId: undefined;
  carrierBusinessRelationshipToOwner: BUSINESS_RELATIONSHIP | null;
  secondsToLifeTimeExpired: string | null;
  timeOrderReservation: string | null;
  tradingParticipantsCount: number | null;
}

export interface IMarketplaceBodySubtypes {
  id: string | null;
  name: string | null;
}

export interface IMarketplaceCargoFilter {
  loadingPlaceBySettlement?: string | null;
  unloadingPlaceBySettlement?: string | null;
  loadingDateFrom?: string | null;
  loadingDateTo?: string | null;
  bodySubtype?: string | null;
  isMarket?: boolean;
  orderNumber?: string | null;
}

export enum CARGO_TOGGLER_POSITION {
  MARKET = 'market',
  PARTNERS = 'partners',
}

export enum DELIVERY_METHODS {
  FTL = 'FTL',
  LTL = 'LTL',
}

export enum BUSINESS_RELATIONSHIP {
  ON_ACCREDITATION = 'ON_ACCREDITATION',
  ACCREDITED = 'ACCREDITED',
  NOT_ACCREDITED = 'NOT_ACCREDITED',
  CONTRACT_IS_SIGNED = 'CONTRACT_IS_SIGNED',
}
