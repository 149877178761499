export const getAuthorizationHeader = (token: string) => ({
  Authorization: `Token ${token}`,
});

export const getContentTypeHeader = (value: string) => ({
  'Content-Type': value,
});

export const getOrganizationDomain = (value: string) => ({
  'X-TMS-Org': value,
});
