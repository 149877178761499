import { Injectable } from '@angular/core';
import { USER_ROLE } from '@transport/ui-interfaces';
import { TnGqlClientSharedService } from '@transport/ui-store';
import { BehaviorSubject, take } from 'rxjs';
import { FILTERS_TOOLBAR_QUERIES } from './filters-toolbar-queries';
import { FILTERS_TABS, IGqlVehiclesStatusCounters } from '../filters.interface';
import { VehiclesService } from '../../vehicles.service';

@Injectable()
export class VehiclesAmountService {

  public amount$ = new BehaviorSubject<IGqlVehiclesStatusCounters | null>(null);

  constructor(private sharedGraphQlClient: TnGqlClientSharedService, private vehiclesPageService: VehiclesService,) {}

  public updateVehiclesStatusCounters() {
    this.sharedGraphQlClient
      .query<{vehicleStatusCounters: { all: number; notArchived: number; archived: number; }}>(this.vehiclesPageService.role$.value as USER_ROLE, FILTERS_TOOLBAR_QUERIES.counter, 1)
      .pipe(take(1))
      .subscribe(res => {
          this.amount$.next({
            [FILTERS_TABS.ALL]: res?.vehicleStatusCounters?.all,
            [FILTERS_TABS.ACTIVE]: res?.vehicleStatusCounters?.notArchived,
            [FILTERS_TABS.ARCHIVED]: res?.vehicleStatusCounters?.archived,
          })
        }
      );
  }
}
