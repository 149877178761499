import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { switchMap } from 'rxjs/operators';

import { OPEN_CONFIRMATION, openConfirmation } from './confirmation.actions';
import { TnConfirmationService } from './confirmation.service';

@Injectable({
  providedIn: 'root',
})
export class TnConfirmationEffects {
  constructor(private readonly action$: Actions, private readonly api: TnConfirmationService) {}

  public readonly openConfirmationByPrefix$ = createEffect(
    () =>
      this.action$.pipe(
        ofType<ReturnType<typeof openConfirmation>>(OPEN_CONFIRMATION),
        switchMap(action => this.api.openByPrefix(action.payload.openByPrefix)),
      ),
    { dispatch: false },
  );
}
