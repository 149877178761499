import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'transport-tw-select',
  templateUrl: './tw-select.component.html',
  styleUrls: ['./tw-select.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- Cuz no ways to detect [control] touched and validity status.
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TnTwSelectComponent {
  @Input() public label = '';

  @Input() public dataTest = '';

  @Input() public placeholder = '';

  @Input() public matSelectClass = '';

  @Input() public size: 'sm' | 'md' | 'lg' = 'sm';

  @Input() public errorsSize: 'xs' | 'sm' | 'md' | 'lg' = 'xs';

  @Input() public required = false;

  @Input() public showErrors = true;

  @Input() public options!: any[]; //{name}

  @Input() public control = new FormControl();

  @Input() public mainField = '';

  @Input() public errorToMessage: (errors: ValidationErrors | null) => string = () => 'Error';

  public isOpen!: boolean;

  constructor() {}

  public getMatSelectClasses() {
    const classes: string[] = [];
    this.isOpen && classes.push('arrow-up');
    this.matSelectClass && classes.push(this.matSelectClass);
    return classes.join(' ');
  }
}
