import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IAcceptPopupContext, IAuctionPopupContext, IBiddingPopupContext, INotAcceptedPopupContext, IPartnersCheckPopupContext } from './popup.interface';

type INotAcceptedPopupContextInput = { notAcceptedPopup: INotAcceptedPopupContext };
type IPartnersCheckPopupContextInput = { partnerCheckPopup: IPartnersCheckPopupContext };
type IAuctionPopupContextInput = { auctionPopup: IAuctionPopupContext };
type IAcceptPopupContextInput = { acceptPopup: IAcceptPopupContext };
type IBiddingPopupContextInput = { biddingPopup: IBiddingPopupContext };

export type ITakeOrderPopupContextInput =
  | INotAcceptedPopupContextInput
  | IPartnersCheckPopupContextInput
  | IAuctionPopupContextInput
  | IBiddingPopupContextInput
  | IAcceptPopupContextInput;

export type ITakeOrderPopupContextFullInput = INotAcceptedPopupContextInput &
  IPartnersCheckPopupContextInput &
  IAuctionPopupContextInput &
  IBiddingPopupContextInput &
  IAcceptPopupContextInput;

@Component({
  selector: 'marketplace-popup-container[contexts][showPopup]',
  templateUrl: './popup-container.component.html',
  styleUrls: ['./popup-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PopupContainerComponent {
  @Input() contexts!: ITakeOrderPopupContextInput;
  @Input() showPopup!: keyof ITakeOrderPopupContextFullInput;

  public decideWhichPopup() {
    return this.showPopup;
  }

  get notAcceptedContext(): INotAcceptedPopupContext {
    if ('notAcceptedPopup' in this.contexts) {
      return this.contexts.notAcceptedPopup;
    }
    throw new Error('No notAccepted context provided');
  }

  get partnerCheckContext(): IPartnersCheckPopupContext {
    if ('partnerCheckPopup' in this.contexts) {
      return this.contexts.partnerCheckPopup;
    }

    throw new Error('No partnerCheck context provided');
  }

  get auctionContext(): IAuctionPopupContext {
    if ('auctionPopup' in this.contexts) {
      return this.contexts.auctionPopup;
    }
    throw new Error('No auction context provided');
  }

  get biddingContext(): IBiddingPopupContext {
    if ('biddingPopup' in this.contexts) {
      return this.contexts.biddingPopup;
    }
    throw new Error('No bidding context provided');
  }

  get acceptContext(): IAcceptPopupContext {
    if ('acceptPopup' in this.contexts) {
      return this.contexts.acceptPopup;
    }
    throw new Error('No accept context provided');
  }
}
