<button mat-menu-item (click)="action('view')">
  <mat-icon class="material-icons">description</mat-icon>
  {{ 'shared.actions.details' | translate }}
</button>
<ng-container *ngIf="isModifyEnabled">
  <button *ngIf="isCopyEnabled" mat-menu-item (click)="action('copy')">
    <mat-icon class="material-icons">add_circle_outline</mat-icon>
    {{ 'shared.actions.copy' | translate }}
  </button>
  <button *ngIf="isEditEnabled" mat-menu-item (click)="action('edit')">
    <mat-icon class="material-icons">edit</mat-icon>
    {{ 'shared.actions.edit' | translate }}
  </button>
  <button *ngIf="isRemoveEnabled" mat-menu-item (click)="action('remove')">
    <mat-icon class="material-icons">remove</mat-icon>
    {{ 'shared.actions.archive' | translate }}
  </button>

  <button
    *ngFor="let btn of customButtons"
    mat-menu-item
    (click)="customAction(btn.name)"
    [matTooltip]="btn.label"
    matTooltipShowDelay="0"
    matTooltipHideDelay="0"
  >
    <mat-icon class="material-icons">{{ btn.icon }}</mat-icon>
    {{ btn.label }}
  </button>
</ng-container>
