import { transport } from '@transport/proto';

import { createNewCargoType, ICargoType } from '../../cargo-type';
import { ICargoPackagingType } from '../../cargo-type/cargo-type-common.interface';
import { TDirectoryEntityPredefinedAction } from '../directory.interface';

/**
 * Router cargo type page component data model.
 */
export class TnCargoTypePageComponentData {
  public title = 'directory: cargo type page';

  public cargoType: ICargoType | null = createNewCargoType();

  public predefinedAction: TDirectoryEntityPredefinedAction = 'view';

  public select: {
    vehicleTypes: transport.IVehicleType[];
    loadingTypes: transport.ILoadingType[];
    packagingTypes: ICargoPackagingType[];
    cargoBodyTypes: transport.Vehicle.Body.IType[];
  } = {
    vehicleTypes: [],
    loadingTypes: [],
    packagingTypes: [],
    cargoBodyTypes: [],
  };
}
