import { ICommonSelectData } from "libs/custom-controls/src/lib/common.interface";
import { transport } from '@transport/proto';
import IBlackListDriver = transport.Driver.IBlackListDriver;

export interface IMarketplaceDriver {
  id?: string | null;
  accrStatus?: string | null;
  name: string | null;
  surname?: string | null;
  patronymic?: string | null;
  birthday: string | null;
  phone?: string | null;
  addPhone?: string | null;
  country?: string | null;
  iin?: string | null;
  inn?: string | null;
  passportSeries?: string | null;
  passportNumber?: string | null;
  passportPlace?: string | null;
  passportDate?: string | null;
  identityCardNumber?: string | null;
  identityCardPlace?: string | null;
  identityCardDate?: string | null;
  licenceSeries?: string | null;
  licenceNumber?: string | null;
  licencePlace?: string | null;
  licenceDate?: string | null;
  agentCarrierId?: string | number | null;
  agentCarrier?: {
    id: string;
    carrierName: string;
    inn: string
  };
  blackListInfo?: IBlackListDriver[] | null;
}
export interface IMarketplaceDriverInput {
  id?: string | null;
  accrStatus?: string | null;
  accrComment?: string | null;
  name: string | null;
  surname?: string | null;
  patronymic?: string | null;
  birthday: string | null;
  phone?: string | null;
  addPhone?: string | null;
  country?: ICommonSelectData | null;
  iin?: string | null;
  inn?: string | null;
  passportSeries?: string | null;
  passportNumber?: string | null;
  passportPlace?: string | null;
  passportDate?: string | null;
  identityCardNumber?: string | null;
  identityCardPlace?: string | null;
  identityCardDate?: string | null;
  licenceSeries?: string | null;
  licenceNumber?: string | null;
  licencePlace?: string | null;
  licenceDate?: string | null;
  agentCarrierId?: ICommonSelectData | null;
  blackListDriver?: {
    limitationType: boolean,
    message: IBlackListDriver[] | null,
  },
}

export enum PERSONAL_DOC_TYPES {
  PASSPORT = 'PASSPORT',
  IDENTITY_CARD = 'IDENTITY_CARD',
  LICENSE = 'LICENSE',
}
