<ng-container [ngSwitch]="decideWhichPopup()">
  <ng-template ngSwitchCase="notAcceptedPopup"
    ><marketplace-not-accepted-popup
      [popupContext] = "notAcceptedContext"
    ></marketplace-not-accepted-popup
  ></ng-template>
  <ng-template ngSwitchCase="partnerCheckPopup">
    <marketplace-partner-check-popup
      [popupContext] = "partnerCheckContext"
    ></marketplace-partner-check-popup
  ></ng-template>
  <ng-template ngSwitchCase="auctionPopup">
    <marketplace-auction-popup
      [popupContext] = "auctionContext"
      (click)="$event.stopPropagation();"
    ></marketplace-auction-popup
  ></ng-template>
  <ng-template ngSwitchCase="biddingPopup">
    <marketplace-bidding-popup
      [popupContext] = "biddingContext"
      (click)="$event.stopPropagation();"
    ></marketplace-bidding-popup
  ></ng-template>
  <ng-template ngSwitchCase="acceptPopup">
    <marketplace-accept-order-popup
      [popupContext]="acceptContext"
      (click)="$event.stopPropagation();"
    ></marketplace-accept-order-popup
  ></ng-template>
</ng-container>
