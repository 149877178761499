import { createAction, props } from '@ngrx/store';
import {
  IReconcilationActDetailsRouteParams,
  TnReconcilationActWebsocketMessage,
  TnReconciliationActDetailsFormValue,
  TUserPredefinedAction,
} from '@transport/ui-interfaces';

export const reconciliationActSetFormMode = createAction(
  '[Reconciliation Act Details] Set Form Mode',
  props<{ formMode: TUserPredefinedAction }>(),
);

export const reconciliationActDetailsPageDestroyed = createAction('[Reconciliation Act Details] Page Destroyed');

export const reconciliationActDetailsPageScrolledToResults = createAction('[Reconciliation Act Details] Page Scrolled To Results');

export const reconciliationActDetailsRouteParamsChanged = createAction(
  '[Reconciliation Act Details] Route Params Changed',
  props<{ params: IReconcilationActDetailsRouteParams }>(),
);

export const reconciliationActDetailsFormValidStatusChanged = createAction(
  '[Reconciliation Act Details] Form Valid Status Changed',
  props<{ isValid: boolean }>(),
);

export const reconciliationActDetailsFormDirtyStatusChanged = createAction(
  '[Reconciliation Act Details] Form Dirty Status Changed',
  props<{ isDirty: boolean }>(),
);

export const saveActBtnClick = createAction(
  '[Reconciliation Act Details] Save Act Btn Click',
  props<{ act: TnReconciliationActDetailsFormValue }>(),
);

export const invalidFormSubmitted = createAction('[Reconciliation Act Details] Invalid Form Submitted');

export const actDetailsFormCreated = createAction('[Reconciliation Act Details] Form Created');

export const addCompletedActsAndUpdateSelectedAct = createAction(
  '[Reconciliation Act Details] Add Completed Acts And Update Selected Act',
  props<{ message: TnReconcilationActWebsocketMessage }>(),
);
