import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TnCoreModule } from '@transport/core';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnUiTranslateModule } from '@transport/ui-translate';

import { TnAccordionComponent } from './accordion/accordion.component';
import { AccreditationAutocompleteComponent } from './accreditation-autocomplete/accreditation-autocomplete.component';
import { TnAlertComponent } from './alert/alert.component';
import { TnButtonComponent } from './button/button.component';
import { TnCardComponent } from './card/card.component';
import { TnCardHeaderIndicatorComponent } from './card-header-indicator/card-header-indicator.component';
import { TnCardManagementHeaderComponent } from './card-management-header/card-management-header.component';
import { TnCardSectionComponent } from './card-section/card-section.component';
import { TnCardSectionRowComponent } from './card-section/card-section-row/card-section-row.component';
import { TnCountdownCounterComponent } from './countdown-counter/countdown-counter.component';
import { TnFileSelectorComponent } from './file-selector/file-selector.component';
import { TnTwAutocompleteComponent } from './kit/tw-autocomplete/tw-autocomplete.component';
import { TnTwDatepickerComponent } from './kit/tw-datepicker/tw-datepicker.component';
import { TnTwInputComponent } from './kit/tw-input/tw-input.component';
import { TnTwInputDirective } from './kit/tw-input/tw-input.directive';
import { TnTwInputFieldComponent } from './kit/tw-input-field/tw-input-field.component';
import { TnTwInputWithSelectComponent } from './kit/tw-input-with-select/tw-input-with-select.component';
import { TnTwMenuOptionComponent } from './kit/tw-menu-option/tw-menu-option.component';
import { TnTwSelectComponent } from './kit/tw-select/tw-select.component';
import { TnRoutingMapComponent } from './routing-map/routing-map.component';
import { TnStringInfoComponent } from './string-info/string-info.component';
import { TnTooltipComponent } from './tooltip/tooltip.component';
import { TRANSPORT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER } from './tooltip/tooltip.constants';
import { TnTooltipDirective } from './tooltip/tooltip.directive';
import { TnTooltipContentComponent } from './tooltip-content/tooltip.component';
import { TnUserAccreditationStatusComponent } from './user-accreditation-status/user-accreditation-status.component';

@NgModule({
  imports: [CommonModule, TnUiTranslateModule, TnUiMaterialModule, TnCoreModule],
  declarations: [
    TnCountdownCounterComponent,
    TnCardSectionRowComponent,
    TnCardSectionComponent,
    TnCardHeaderIndicatorComponent,
    TnCardComponent,
    TnTooltipComponent,
    TnTooltipDirective,
    TnButtonComponent,
    TnStringInfoComponent,
    TnCardManagementHeaderComponent,
    TnTwInputComponent,
    TnTwInputDirective,
    TnTwInputFieldComponent,
    TnAccordionComponent,
    TnAlertComponent,
    TnTwMenuOptionComponent,
    TnFileSelectorComponent,
    TnTwAutocompleteComponent,
    TnTwDatepickerComponent,
    TnTwSelectComponent,
    TnTwInputWithSelectComponent,
    AccreditationAutocompleteComponent,
    TnTooltipContentComponent,
    TnUserAccreditationStatusComponent,
    TnRoutingMapComponent,
  ],
  exports: [
    TnCountdownCounterComponent,
    TnCardSectionRowComponent,
    TnCardSectionComponent,
    TnCardHeaderIndicatorComponent,
    TnCardComponent,
    TnTooltipComponent,
    TnTooltipDirective,
    TnButtonComponent,
    TnStringInfoComponent,
    TnCardManagementHeaderComponent,
    TnTwInputComponent,
    TnTwInputDirective,
    TnTwInputFieldComponent,
    TnAccordionComponent,
    TnAlertComponent,
    TnTwMenuOptionComponent,
    TnTwAutocompleteComponent,
    TnTwDatepickerComponent,
    TnTwSelectComponent,
    TnTwInputWithSelectComponent,
    TnFileSelectorComponent,
    AccreditationAutocompleteComponent,
    TnTooltipContentComponent,
    TnUserAccreditationStatusComponent,
    TnRoutingMapComponent,
  ],
  providers: [TRANSPORT_TOOLTIP_SCROLL_STRATEGY_FACTORY_PROVIDER],
})
export class TnUiKitModule {}
