import { Injectable } from '@angular/core';
import { transport } from '@transport/proto';
import { LEGAL_FORM_CODES } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnGqlClientCarrierService } from '../../gql-client-carrier/graphql-client-carrier.service';
import { TnToasterFacade } from '../../toaster/toaster.facade';
import { IOrganizationResultInfo, ORGANIZATION_SEARCHING_STATUS } from './organization.form';

@Injectable({
  providedIn: 'root',
})
export class TnOrganizationService {
  constructor(private readonly graphQL: TnGqlClientCarrierService, private readonly toastFacade: TnToasterFacade) {}

  public getOrganizationByInn(inn: string, legalFormCode: LEGAL_FORM_CODES): Observable<IOrganizationResultInfo> {
    return this.graphQL.getOrganizationByInn(inn, legalFormCode).pipe(
      map(({ organizationDataByInn: organization }) => {
        if (!Boolean(organization.companyName) && legalFormCode !== LEGAL_FORM_CODES.SELF_EMPLOYED) {
          this.toastFacade.showMessage(`shared.passport.signup.${ORGANIZATION_SEARCHING_STATUS.NOT_FOUND}`);
          return { status: ORGANIZATION_SEARCHING_STATUS.NOT_FOUND, organization };
        }

        if (organization?.status !== 'ACTIVE') {
          this.toastFacade.showMessage(`shared.passport.signup.${ORGANIZATION_SEARCHING_STATUS.NOT_ACTIVE}`);
          return { status: ORGANIZATION_SEARCHING_STATUS.NOT_ACTIVE, organization: null };
        }

        return { status: ORGANIZATION_SEARCHING_STATUS.OK, organization };
      }),
    );
  }

  public getLegalForms() {
    const filter = ({ code }: transport.ILegalForm) =>
      [LEGAL_FORM_CODES.LIMITED_LIABILITY_COMPANY, LEGAL_FORM_CODES.INDIVIDUAL_ENTREPRENEUR, LEGAL_FORM_CODES.SELF_EMPLOYED].some(
        legalFormCode => code === legalFormCode,
      );
    return this.graphQL.queryLegalForms().pipe(map(dtoData => dtoData.legalForms.filter(filter)));
  }
}
