import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TnAnalyticsService } from 'libs/transport-ui-store/src/lib/current-user/services/analytics/analytics.service';
import { Observable, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

enum HTTP_STATUS {
  SUCCESS = 200,
  UNAUTHORIZED = 401,
}

@Injectable({
  providedIn: 'root',
})
export class TnAnalyticsInterceptorService implements HttpInterceptor {
  constructor(private readonly analytics: TnAnalyticsService) {}

  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(req).pipe(
      catchError(err => {
        if (err.status === HTTP_STATUS.UNAUTHORIZED) {
          this.analytics.trackHttpResponse(err, true);
        }
        return throwError(err);
      }),
      tap(response => {
        if (response instanceof HttpResponse && response.status === HTTP_STATUS.SUCCESS) {
          const withErrors = typeof response.body === 'object' && response.body !== null && 'errors' in response.body;
          this.analytics.trackHttpResponse(response, withErrors);
        }
      }),
    );
  }
}
