import { AbstractControl, Validators } from '@angular/forms';
import { FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';

import { IDriver } from '../driver/driver.interface';
import { conditionalValidator } from '../form-validators/forms';
import { IVehicle } from '../vehicle/vehicle.interface';
import { VEHICLE_TYPE_OWNERSHIP, VEHICLE_TYPE_PROPERTY } from '../vehicle/vehicle-common.interface';
import { IVehicleData } from '../vehicle/vehicle-data.interface';

export interface ITruckForm {
  id: string;
  vehicleMake: string;
  vehicleCountry: string;
  vehicleId: string | null;
  vehicleCertificate: string | null;
  vehicleVin: string;
  isArchived: boolean;
}

export interface IVehicleTrailerForm {
  trailerId: string;
  trailerType: string;
  trailerSubtype: string;
  trailerVolume: number;
  trailerCapacity: number;
  trailerLoadingTypes: string[];
}

export interface IBodyTrailerForm {
  bodyType: string;
  bodySubtype: string;
  bodyVolume: number;
  bodyCapacity: number;
  bodyLoadingTypes: string[];
}

export enum TRUCK_IDENTIFICATOR_TYPE {
  STS = 'sts',
  VIN = 'vin',
  GOS_NUMBER = 'gosNumber',
  GOS_TRAILER_NUMBER = 'gosTrailerNumber',
}

export enum VEHICLE_FORM_CONSTANTS {
  MAX_GOSNUM_LENGTH = 20,
  MAX_STSNUM_LENGTH = 20,
}
export class TnVehicleFormData {
  public vehicleType = '';

  public truckInfo = {} as ITruckForm;

  public vehicleDriver: IDriver | null = null;

  public vehicleTrailer = {} as IVehicleTrailerForm;

  public bodyTrailer = {} as IBodyTrailerForm;

  public typeProperty = '';

  public ownershipType = '';

  public agentCarrier: string | null = '';

  // eslint-disable-next-line complexity -- TODO: tech debt
  constructor(vehicle: IVehicle, driver: IDriver) {
    this.vehicleType = vehicle.vehicleType ?? 'TRUCK';
    this.agentCarrier = vehicle.agentCarrier?.id ?? null;
    this.typeProperty = Boolean(this.agentCarrier) ? VEHICLE_TYPE_PROPERTY.ATTRACTED : VEHICLE_TYPE_PROPERTY.OWN;
    this.ownershipType = vehicle.ownershipType ?? VEHICLE_TYPE_OWNERSHIP.OWN;

    this.truckInfo.id = vehicle.id ?? '';
    this.truckInfo.vehicleMake = vehicle.vehicleMake ?? '';
    this.truckInfo.vehicleCountry = vehicle.country ?? '';
    this.truckInfo.vehicleId = vehicle.vehicleId ?? '';
    this.truckInfo.vehicleCertificate = vehicle.vehicleCertificate ?? '';
    this.truckInfo.vehicleVin = vehicle.vehicleVin ?? '';
    this.truckInfo.isArchived = vehicle.isArchived ?? false;

    this.bodyTrailer.bodyType = vehicle.vehiclebody?.bodyType?.id ?? '';
    this.bodyTrailer.bodySubtype = vehicle.vehiclebody?.bodySubtype?.id ?? '';
    this.bodyTrailer.bodyLoadingTypes = vehicle.vehiclebody?.loadingTypes?.map(item => item.id ?? '') ?? [];
    this.bodyTrailer.bodyVolume = vehicle.vehiclebody?.volume ?? 0;
    this.bodyTrailer.bodyCapacity = vehicle.vehiclebody?.capacity ?? 0;

    this.vehicleDriver = driver;

    this.vehicleTrailer.trailerId = vehicle.vehicletrailer?.trailerId ?? '';
    this.vehicleTrailer.trailerType = vehicle.vehicletrailer?.bodyType?.id ?? '';
    this.vehicleTrailer.trailerSubtype = vehicle.vehicletrailer?.bodySubtype?.id ?? '';
    this.vehicleTrailer.trailerVolume = vehicle.vehicletrailer?.volume ?? 0;
    this.vehicleTrailer.trailerCapacity = vehicle.vehicletrailer?.capacity ?? 0;
    this.vehicleTrailer.trailerLoadingTypes = vehicle.vehicletrailer?.loadingTypes?.map(item => item.id ?? '') ?? [];
  }
}

export interface IVehicleForm {
  vehicleType: FormControl<string>;
  truck: FormControl<ITruckForm>;
  vehicleBody: FormControl<IBodyTrailerForm>;
  vehicleDriver: FormControl<IDriver>;
  vehicleTrailer: FormControl<IVehicleTrailerForm>;
  isOrganizationOwner: FormControl<boolean>;
  typeProperty: FormControl<VEHICLE_TYPE_PROPERTY | string>;
  ownershipType: FormControl<VEHICLE_TYPE_OWNERSHIP | string>;
  agentCarrier: FormControl<string | null>;
}

const getVechicleTrailerInfo = (item: TnVehicleFormData) => {
  return Boolean(item)
    ? {
        trailerId: item.vehicleTrailer.trailerId,
        trailerType: item.vehicleTrailer.trailerType,
        trailerSubtype: item.vehicleTrailer.trailerSubtype,
        trailerVolume: item.vehicleTrailer.trailerVolume,
        trailerCapacity: item.vehicleTrailer.trailerCapacity,
        trailerLoadingTypes: item.vehicleTrailer.trailerLoadingTypes,
      }
    : {};
};

const getVehicleBodyInfo = (item: TnVehicleFormData) => {
  return Boolean(item)
    ? {
        bodyType: item.bodyTrailer.bodyType,
        bodySubtype: item.bodyTrailer.bodySubtype,
        bodyVolume: item.bodyTrailer.bodyVolume,
        bodyCapacity: item.bodyTrailer.bodyCapacity,
        bodyLoadingTypes: item.bodyTrailer.bodyLoadingTypes,
      }
    : {};
};

const getTruckInfo = (item: TnVehicleFormData) => {
  return Boolean(item)
    ? {
        id: item.truckInfo.id,
        vehicleMake: item.truckInfo.vehicleMake,
        vehicleCountry: item.truckInfo.vehicleCountry,
        vehicleId: item.truckInfo.vehicleId,
        vehicleCertificate: item.truckInfo.vehicleCertificate,
        vehicleVin: item.truckInfo.vehicleVin,
        isArchived: item.truckInfo.isArchived,
      }
    : {};
};

const isTypePropertyAttrached = (formControl: AbstractControl) => {
  return formControl?.get('typeProperty')?.value === VEHICLE_TYPE_PROPERTY.ATTRACTED;
};

/**
 * AddLoadingPlace form constructor. while use clientside-mocks
 * TODO: establish a pattern that allows breaking down such methods into smaller methods.
 */
export const newVehicleForm = (
  fb: FormBuilder,
  disabled: boolean,
  isAgent = false,
  editableItem?: TnVehicleFormData,
): FormGroup<IVehicleForm> => {
  const vehicleTrailerInfo = getVechicleTrailerInfo(editableItem as TnVehicleFormData);

  const vehicleBodyInfo = getVehicleBodyInfo(editableItem as TnVehicleFormData);

  const truckInfo = getTruckInfo(editableItem as TnVehicleFormData);

  const form = fb.group({
    vehicleType: [{ value: editableItem?.vehicleType, disabled }, Validators.required],
    truck: [truckInfo, Validators.required],
    vehicleBody: [vehicleBodyInfo],
    vehicleDriver: [{ value: editableItem?.vehicleDriver, disabled }, Validators.compose([])],
    vehicleTrailer: [vehicleTrailerInfo],

    isOrganizationOwner: [{ value: false, disabled }],
    typeProperty: [
      { value: editableItem?.typeProperty, disabled },
      conditionalValidator((formControl: AbstractControl) => {
        return isAgent;
      }, Validators.compose([Validators.required])),
    ],
    agentCarrier: [
      { value: editableItem?.agentCarrier, disabled },
      conditionalValidator((formControl: AbstractControl) => {
        return isTypePropertyAttrached(formControl);
      }, Validators.compose([Validators.required])),
    ],
    ownershipType: [{ value: editableItem?.ownershipType, disabled }, Validators.required],
  });

  return form as FormGroup<IVehicleForm>;
};

export const getVehicleData = (
  vehicleForm: FormGroup<IVehicleForm>,
  isBodyInfoVisible: boolean,
  isTrailerInfoVisible: boolean,
): IVehicleData => {
  const formValue = Boolean(vehicleForm?.getRawValue) ? vehicleForm?.getRawValue() : vehicleForm.value;
  const trailerData = {
    vehicleTrailerRegNo: formValue.vehicleTrailer.trailerId,
    vehicleTrailerType: formValue.vehicleTrailer.trailerType,
    vehicleTrailerSubtype: formValue.vehicleTrailer.trailerSubtype,
    vehicleTrailerVolume: formValue.vehicleTrailer.trailerVolume,
    vehicleTrailerCapacity: formValue.vehicleTrailer.trailerCapacity,
    vehicleTrailerLoadingTypes: formValue.vehicleTrailer.trailerLoadingTypes,
  };

  const bodyData = {
    vehicleBodyType: formValue.vehicleBody.bodyType,
    vehicleBodySubtype: formValue.vehicleBody.bodySubtype,
    vehicleBodyVolume: formValue.vehicleBody.bodyVolume,
    vehicleBodyCapacity: formValue.vehicleBody.bodyCapacity,
    vehicleBodyLoadingTypes: formValue.vehicleBody.bodyLoadingTypes,
  };

  const truckInfo = {
    vehicleMake: formValue.truck.vehicleMake,
    country: formValue.truck.vehicleCountry,
    vehicleCertificate: Boolean(formValue.truck.vehicleCertificate)
      ? (formValue.truck.vehicleCertificate as string).replace(/\s/g, '')
      : '',
    vehicleRegNo: (formValue.truck.vehicleId as string).replace(/_/g, ''),
    vehicleVin: formValue.truck.vehicleVin,
  };

  return {
    vehicleType: formValue.vehicleType,
    ownershipType: formValue.ownershipType,
    vehicleDrivers:
      Boolean(formValue.vehicleDriver) && Boolean((formValue.vehicleDriver as { id: string }).id)
        ? [(formValue.vehicleDriver as { id: string }).id]
        : [],
    ...truckInfo,
    ...(Boolean(isBodyInfoVisible) ? bodyData : {}),
    ...(Boolean(isTrailerInfoVisible) ? trailerData : {}),
  };
};
