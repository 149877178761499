import { transport } from '@transport/proto';

import { IFileType, TnUploadedFile } from '../files/uploaded-file';
import { IVehicleData } from '../vehicle/vehicle-data.interface';

export interface IRegistrationStatus {
  driverCheck?: REGISTRATION_STATUS | string | null;
  organizationCheck?: REGISTRATION_STATUS | string | null;
  vehicleCheck?: REGISTRATION_STATUS | string | null;
  esignCheck?: REGISTRATION_STATUS | string | null;
}
export interface IRegistrationState extends IRegistrationStatus {
  step: REGISTRATION_ENTITY | null;
  documents: IRegistrationFileToUpload[];
  signDocument: IOrganizationDocument | null;
}

export enum REGISTRATION_ENTITY {
  ORGANIZATION = 0,
  TRUCK = 1,
  DRIVER = 2,
  SIGN = 3,
}

export enum REGISTRATION_STATUS {
  NOT_CHECKED = 'NOT_CHECKED',
  ON_WORK = 'ON_WORK',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export interface IConfirmRegistrationStep<T> {
  entity: REGISTRATION_ENTITY;
  value: T;
}

export interface ICheckOrganizationInput {
  legalFormCode: string;
  name: string;
  phoneNumber: string;
  email: string;
  inn: string;
  kpp: string;
  legalAddress: string;
  registrationNumber: string;
  postalAddress: string;
  signer: string;
  headOfOrganization: Partial<transport.IHeadOfOrganizationType>;
}

export interface ICheckTruckInput extends IVehicleData {
  ownedByCarrier?: boolean;
}

export interface IOrganizationDocumentInput {
  documentTypeId: string;
  uploadedFileId: string;
}

export interface IOrganiationUploadedFile extends TnUploadedFile {
  id?: string;
  name?: string;
  createdAt: string;
  url?: string;
  innerName?: string;
}

export interface IOrganizationDocument {
  documentType: IFileType;
  uploadedFile: IOrganiationUploadedFile | null;
  id?: string;
}
export interface IRegistrationFileToUpload extends IOrganizationDocument {
  isTouched?: boolean;
}

export interface IRegistrationResultAction {
  title: string;
  color?: string;
  url: string;
}
