import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { ITab, ITabsView } from '@transport/ui-interfaces';

@UntilDestroy()
@Component({
  selector: 'transport-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnToolbarComponent {
  @Input() public tabs: Array<string | ITab> = [];

  @Input() public compactCounter = true;

  @Input() public selectedTabIndex = 0;

  @Input() public isNewDesign = false;

  @Input() public isInGroup = false;

  @Input() public isTonnaDesign = false;

  @Output() public readonly tabsSelectionChanged = new EventEmitter<number>();

  public get tabsView(): ITabsView {
    const isTab = (value: string | ITab): value is ITab => {
      return Boolean((value as ITab).label);
    };

    const isString = (value: string | ITab): value is string => {
      return typeof value === 'string';
    };

    return {
      labels: this.tabs.filter(isString),
      tabs: this.tabs.filter(isTab),
    };
  }

  public get showTabGroup() {
    const { labels, tabs } = this.tabsView;
    return Number(labels?.length) > 0 || Number(tabs?.length) > 0;
  }

  public isShowCount(tab) {
    if (tab) {
      return Boolean(tab.count) && tab.count !== '' && tab.count !== '0';
    }
    return false;
  }

  public changeTab(selectedIndex: number) {
    if (this.selectedTabIndex !== selectedIndex) {
      this.tabsSelectionChanged.emit(selectedIndex);
    }
  }
}
