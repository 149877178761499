export interface IEntity {
  id?: string;
}

export class TnEntityChangesList<T extends IEntity> {
  private readonly deletedItems = new Map<string, T>();

  private readonly newItems = new Map<string, T>();

  private readonly updatedItems = new Map<string, T>();

  public delete(item: T): TnEntityChangesList<T> {
    if (!this.newItems.has(item.id ?? '')) {
      this.deletedItems.set(item.id ?? '', item);
    }
    this.newItems.delete(item.id ?? '');
    this.updatedItems.delete(item.id ?? '');
    return this;
  }

  public add(item: T): TnEntityChangesList<T> {
    this.newItems.set(item.id ?? '', item);
    return this;
  }

  public update(item: T): TnEntityChangesList<T> {
    if (!this.newItems.has(item.id ?? '')) {
      this.updatedItems.set(item.id ?? '', item);
    } else {
      this.newItems.set(item.id ?? '', item);
    }
    return this;
  }

  public reset(): TnEntityChangesList<T> {
    this.deletedItems.clear();
    this.newItems.clear();
    this.updatedItems.clear();
    return this;
  }

  public get value() {
    return {
      deletedItems: [...this.deletedItems.values()],
      newItems: [...this.newItems.values()],
      updatedItems: [...this.updatedItems.values()],
    };
  }

  public get hasChanges() {
    return this.deletedItems.size > 0 || this.newItems.size > 0 || this.updatedItems.size > 0;
  }
}
