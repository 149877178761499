import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { transport } from '@transport/proto';
import { ISla, ISlaCriterion, ISlaFilter } from '@transport/ui-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnGqlClientOwnerMutationsService, TnGqlClientOwnerQueriesService } from '../../gql-client-owner';

@Injectable({
  providedIn: 'root',
})
export class TnSlaProvider {
  constructor(private readonly gqlQuery: TnGqlClientOwnerQueriesService, private readonly gqlMutate: TnGqlClientOwnerMutationsService) {}

  public queryAllSla(
    pageSize?: number,
    offset?: number,
    filterSetting?: ISlaFilter,
    sortSetting?: Sort,
  ): Observable<{ list: ISla[]; totalCount: number }> {
    return this.gqlQuery.queryAllSla().pipe(
      map(data => data.allOrganizationSla),
      map(data => ({ list: data, totalCount: data.length })),
    );
  }

  public querySlaCriteria(): Observable<ISlaCriterion[]> {
    return this.gqlQuery.querySlaCriteria().pipe(map(data => data.slaCriteria));
  }

  public querySla(slaId: string): Observable<ISla> {
    return this.gqlQuery.querySla(slaId).pipe(map(data => data.sla));
  }

  public addSla(sla: transport.ISlaInput): Observable<unknown> {
    return this.gqlMutate.addSla(sla);
  }

  public editSla(sla: transport.IEditSlaInput): Observable<unknown> {
    return this.gqlMutate.editSla(sla);
  }

  public deleteSla(slaId: string): Observable<unknown> {
    return this.gqlMutate.deleteSla(slaId);
  }
}
