import { InjectionToken } from '@angular/core';

export interface ISupportContacts {
  phone: string;
  email: string;
}

/**
 * Application environment constructor input interface.
 */
export interface IAppEnvironmentInput {
  production?: boolean;
  testing?: boolean;
  debugging?: boolean;
  mockBackend?: boolean;
  apiDomain?: string;
  appName?: string;
  support?: ISupportContacts;
  amplitudeApiKey?: string;
  ssoUrl?: string;
  tmsDomain?: string;
  ws?: string;
}

/**
 * Applicantion environment api domains type;
 */
export type TTransportApiDomains = 'api.transport2.xyz' | 't12.tntest.xyz';

/**
 * Application environment.
 * By default generates dev environment.
 */
export class TnAppEnvironment {
  /**
   * Disables:
   * - redux dev tools plugin dev tools plugin;
   * - dev meta reducers: store logger;
   * Enables:
   * - service worker;
   */
  public production?: boolean = false;

  /**
   * Disables:
   * - redux dev tools plugin dev tools plugin;
   * - dev meta reducers: store logger;
   */
  public testing?: boolean = false;

  /**
   * Disables:
   * - redux dev tools plugin dev tools plugin;
   * Enables:
   * - dev meta reducers: store logger;
   */
  public debugging?: boolean = false;

  public apiDomain?: TTransportApiDomains | string = 't12.tntest.xyz';

  public appName?: 'Master-TMS' | string = 'Master-TMS';

  public support?: ISupportContacts = {
    phone: '+79155925073',
    email: 'support@transport2.ru',
  };

  public appVersion?: string;

  public amplitudeApiKey?: string;

  public ssoUrl?: string;

  public tmsDomain?: string;

  public tonnaDomain?: string;

  public ws?: string;

  /**
   * AppEnvironment constructor.
   * @param input app env constructor input
   */
  constructor(input: IAppEnvironmentInput = {}) {
    if (typeof input.production !== 'undefined') {
      this.production = input.production;
      this.apiDomain = input.production ? 'api.transport2.xyz' : 't12.tntest.xyz';
    }
    if (typeof input.testing !== 'undefined') {
      this.testing = input.testing;
    }
    if (typeof input.debugging !== 'undefined') {
      this.debugging = input.debugging;
    }
    if (typeof input.apiDomain !== 'undefined') {
      this.apiDomain = input.apiDomain;
    }
    if (typeof input.appName !== 'undefined') {
      this.appName = input.appName;
    }
    if (typeof input.support !== 'undefined') {
      this.support = { ...input.support };
    }

    // Функционал вырезан в соответствии с TMS-6844403
    // if (typeof input.amplitudeApiKey !== 'undefined') {
    //   this.amplitudeApiKey = input.amplitudeApiKey;
    // }

    if (typeof input.ssoUrl !== 'undefined') {
      this.ssoUrl = input.ssoUrl;
    }

    if (typeof input.tmsDomain !== 'undefined') {
      this.tmsDomain = input.tmsDomain;
    }

    if (typeof input.ws !== 'undefined') {
      this.ws = input.ws;
    }
  }
}

export type TAppEnvToken = InjectionToken<TnAppEnvironment>;

/**
 * Application environment injection token.
 */
export const APP_ENV: TAppEnvToken = new InjectionToken<TnAppEnvironment>('APP_ENV');
