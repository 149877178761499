import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TnTimeZoneEffects } from './time-zone.effects';
import { timezoneReducer } from './time-zone.reducers';

const TIMEZONE_EFFECTS = [TnTimeZoneEffects];

@NgModule({
  imports: [StoreModule.forFeature('timezone', timezoneReducer), EffectsModule.forFeature(TIMEZONE_EFFECTS)],
  exports: [StoreModule, EffectsModule],
})
export class TnTimeZoneModule {}
