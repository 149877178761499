import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Inject, OnInit } from '@angular/core';
import { FormBuilder, ValidationErrors } from '@angular/forms';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
  APP_ENV,
  ILoginByCodeForm,
  ILoginByCodeFormData,
  newLoginByCodeForm,
  TnAppEnvironment,
  TOAST_DURATION,
} from '@transport/ui-interfaces';
import { TnClSsoFrameService } from '@transport/ui-store';
import { TnMarketplaceAuthService } from '../../shared/services/marketplace-auth.service';
import { TnCurrentUserService } from '../../shared/services/user/current-user.service';
import { RedirectAfterLoginService } from '../../shared/services/redirect-after-login.service';
import { switchMap, map, tap, takeWhile, catchError, of, throttleTime } from 'rxjs';
import { TnAnalyticsService } from 'libs/transport-ui-store/src/lib/current-user/services/analytics/analytics.service';
import { ALERT_STATE, IAlertInputs, TnAlertService } from '@transport/ui-kit';

@UntilDestroy()
@Component({
  selector: 'transport-login-by-code',
  templateUrl: './login-by-code.component.html',
  styleUrls: ['./login-by-code.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnMarketplaceLoginByCodeComponent implements OnInit {
  /**
   * DOM host binding.
   */
  @HostBinding('class.mat-body-1') public readonly matBody1 = true;

  public isFalseCredentionals = false;

  /**
   * Login form.
   */
  public loginForm: ILoginByCodeForm | null = newLoginByCodeForm(
    this.fb,
    {
      email: '',
    },
    false,
  );

  public isLoading = false;

  constructor(
    private readonly fb: FormBuilder,
    private readonly tnCurrentUserService: TnCurrentUserService,
    public readonly translate: TranslateService,
    private readonly authService: TnMarketplaceAuthService,
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly ssoService: TnClSsoFrameService,
    private readonly redirectAfterLogin: RedirectAfterLoginService,
    private readonly analytics: TnAnalyticsService,
    private readonly alertService: TnAlertService,
    @Inject(APP_ENV) public readonly env: TnAppEnvironment,
  ) {}

  public ngOnInit(): void {
    localStorage.removeItem('isRoleRegistred'); //Для предотвращения редиректа на мастер ТМС, если пользователь не завершил регистрацию
    //Для веб-аналитики, прежде чем отправлять эвент, необходимо создать сессию
    if (Boolean(this.analytics.trackingSessionId)) {
      this.analytics.track('user saw login page');
    } else {
      this.analytics
        .createSession()
        .pipe(untilDestroyed(this))
        .subscribe(() => this.analytics.track('user saw login page'));
    }
    this.ssoService.tokenStream$.pipe(throttleTime(5000)).subscribe(data => {
      if (typeof data !== 'string' && data?.token && data?.token !== '') {
        this.tnCurrentUserService.tnUserService.setCookie(this.tnCurrentUserService.tnUserService.getCookieName(), '', {'max-age': -1,});
        this.tnCurrentUserService.saveUser(data);
        const url = this.redirectAfterLogin.popUrlFromLocalStorage();
        if (Boolean(url)) {
          window.location.href = String(url);
        } else {
          window.location.reload();
        }
      } else {
        this.ssoService.logOut();
      }
    });
  }

  public get emailControl() {
    return this.loginForm?.controls?.email;
  }

  public submit(event: Event) {
    event.preventDefault();
    this.loginForm?.markAllAsTouched();

    if (Boolean(this.loginForm?.valid)) {
      this.isLoading = true;
      const formData: ILoginByCodeFormData = this.loginForm?.value;

      this.authService
        .checkEmailExist(formData.email)
        .pipe(
          untilDestroyed(this),
          map(res => res.emailExist),
          tap(exist => {
            if (!exist) {
              this.loginForm?.controls.email.setErrors({ isEmailExist: exist });
              this.isLoading = false;
            }
            this.cdr.markForCheck();
          }),
          takeWhile(exist => exist),
          switchMap(() => {
            return this.authService.sendAuthenticationCode(formData);
          }),
          catchError(error => {
            this.alertService.openAlert(
              {
                state: ALERT_STATE.ERROR,
                message: error,
              } as IAlertInputs,
              TOAST_DURATION.LONG,
            );
            return of();
          }),
        )
        .subscribe(() => {
          this.isLoading = false;
          this.authService.authenticationData = this.loginForm?.value;
          this.router.navigate(['passport', 'submit-code']);
        });
    }
  }

  public emailValidationErrors(e: ValidationErrors | null) {
    if (e?.required) return this.translate.instant('shared.errors.nologin');
    if (e?.pattern && !e?.maxlength) return this.translate.instant('shared.errors.email');
    if (!e?.isEmailExist) return this.translate.instant('shared.errors.emailNotRegistered');
    if (e?.maxlength)
      return `${this.translate.instant('shared.auth.login.form.email.errors.maxlength')} ${
        this.emailControl?.errors?.maxlength?.requiredLength
      }`;

    return '';
  }
}
