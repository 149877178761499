import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { TnHttpHandlersService } from '@transport/ui-store';

@Component({
  selector: 'transport-pdf-image-viewer',
  templateUrl: './pdf-image-viewer.component.html',
  styleUrls: ['./pdf-image-viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnMpPdfImageViewerComponent {
  public displayValue: Blob | string | null | undefined;

  @Input() public set src(value: Blob | string | undefined) {
    if (!value) return;
    if (typeof value === 'string') {
      this.onUrlSrc(value);
      return;
    }
    if (value.type !== 'application/pdf') {
      this.readBlobToString(value);
    }
    if (value.type === 'application/pdf') {
      this.displayValue = value;
    }
  }

  @Input() public fileName: string | null = null;

  constructor(
    private readonly http: HttpClient,
    private readonly cdr: ChangeDetectorRef,
    private readonly sanitizer: DomSanitizer,
    private readonly handlers: TnHttpHandlersService,
  ) {}

  private onUrlSrc(url: string) {
    let headers;
    if (!url.includes('storage.yandex')) headers = this.handlers.getAuthHeaders();

    this.http.get(url, { responseType: 'blob', headers }).subscribe((res: Blob) => {
      if (this.isPDF) {
        this.displayValue = res;
        this.cdr.detectChanges();
      } else {
        this.readBlobToString(res);
      }
    });
  }

  private readBlobToString(blob: Blob) {
    const reader = new FileReader();
    reader.onloadend = () => {
      this.displayValue = reader.result as string;
      this.cdr.detectChanges();
    };
    if (typeof blob === 'string') {
      blob = new Blob([blob], { type: 'application/octet-stream' });
    }
    reader.readAsDataURL(blob);
  }

  public get isPDF(): boolean {
    if (this.fileName) {
      return this.fileName.toLowerCase().includes('pdf');
    }
    return false;
  }

  public bypassSecurityTrustResourceUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }
}
