export const graphqlEndpoints = {
  //Owner
  AddOrder: '(owner) order created',
  AddAuctionOrder: '(owner) add auction order',
  AssignCarrier: '(owner) assign carrier',
  addTender: '(owner) add tender',
  cancelTender: '(owner) cancel tender',
  AddCargoType: '(owner) add cargo type',
  cargoType: '(owner) select cargo type',
  addLoadingUnloadingPlace: '(owner) add uploading place',
  storagePoint: '(owner) add storage point',

  acceptOrder: '(carrier) accept order',
  reserveTransportV2: '(carrier) reserve transport',
  addVehicleOnTms: 'add vehicle on tms',
  addDriver: '(carrier) add driver',
  letsGo: '(carrier) lets go',
  completeOrder: '(carrier) complete order',
  addBidOnTender: '(carrier) place a bet on tender',

  cancelOrder: 'delete order',

  editOrderUploadedDocuments: 'edit order uploaded documents',
  routeSheets: 'get route sheets',
};

export const navigationEndpoints = {
  '/passport/registration-invite': 'registration by invitation view',
  '/passport/forgot-password': 'forgot password view',
  '/passport/login': 'login view',

  '/profile/owner/edit': 'owner company edit view',

  '/order/create': '(owner) order creation view',
  '/orders/auction/create': '(owner) auction creation view',
  '/orders/owner/order/view/:id': '(owner) order view',
  '/directory/owner/cargo-types/create': '(owner) cargo types creation view',
  '/directory/owner/loading-places/create': '(owner) loading places creation view',
  '/tenders/tender/create': '(owner) tender creation view',

  '/orders/:id': '(carrier) order accept view',
  '/tenders/tender/:id': '(carrier) tender view',
  '/directory/carrier/trucks/create': '(carrier) truck creation view',
  '/directory/carrier/drivers/create': '(carrier) add driver view',
  '/marketplace/carrier/view/:id': '(carrier) marketplace view',

  '/tracking/carrier/order/:id': 'routes on map view',
};
