import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { Store } from '@ngrx/store';

import { ITnState } from '../../../state';
import { TnTableStateFacade } from '../../../table/table-state-facade';
import { tableActions } from './actions/choose-drivers.actions';
import { IDriversFilter } from './drivers-state.facade';
import { chooseDriversTableSelectors } from './selectors/drivers.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnChooseDriversFacade extends TnTableStateFacade<IDriversFilter> {
  constructor(public store: Store<ITnState>, public action: Actions) {
    super(store, tableActions, chooseDriversTableSelectors);
  }
}
