import { Sort } from '@angular/material/sort';
import { IPager } from '@transport/ui-interfaces';
import { TIMEOUT } from '@transport/ui-utils';
import { BehaviorSubject, combineLatest, debounceTime, map, mergeMap, Observable, of, Subject, take, takeUntil, tap } from 'rxjs';

export interface PaginatedArray<T> {
  items: T[];
  totalCount: number;
}

export interface Disposable {
  dispose?: (() => void) | undefined;
}

export type FetchFn<Filter, Item> = (
  filter: Filter,
  sort: Sort,
  pageInfo: IPager,
) => Observable<{
  items: Item[];
  totalCount: number;
}>;

export const LIST_STORE_LS_KEY = 'ListStore';

export class ListStore<Item, Column extends string, Filter> {
  private localStorageKey: string | undefined;

  private complete$ = new Subject<void>();
  private fetch$ = new BehaviorSubject<boolean>(false);

  public filter$ = new BehaviorSubject<Filter>(this.filter);

  public sort$ = new BehaviorSubject<Sort>({
    active: 'id',
    direction: 'desc',
  });

  public pageInfo$ = new BehaviorSubject<IPager>({
    pageIndex: 0,
    pageSize: 25,
  });

  public append$ = new BehaviorSubject(0);

  public loading$ = new BehaviorSubject<boolean>(false);
  public list$ = new BehaviorSubject<PaginatedArray<Item>>({
    items: [],
    totalCount: 0,
  });

  public get store() {
    return {
      total$: this.list$.pipe(map(data => data.totalCount)),
      items$: this.list$.pipe(map(data => data.items)),
      loading$: this.loading$.asObservable(),
      pageInfo$: this.pageInfo$,
      list$: this.list$,
      filter$: this.filter$,
      fetch: this.fetch.bind(this),
      triggerList: this.triggerList.bind(this),
      appendItems: this.appendItems.bind(this),
      changeFilter: this.changeFilter.bind(this),
      changePage: this.changePage.bind(this),
      storeToLocalStorage: this.setLocalStorageKey.bind(this),
    };
  }

  constructor(private readonly filter: Filter, private fetchFn: FetchFn<Filter, Item>) {
    // get filter preset from local storage
    setTimeout(() => {
      if (this.localStorageKey) {
        const stored = localStorage.getItem(LIST_STORE_LS_KEY);
        const parsed = stored ? JSON.parse(stored) : undefined;
        if (parsed && parsed[this.localStorageKey]) {
          this.filter$.next(parsed[this.localStorageKey].filter);
          this.pageInfo$.next(parsed[this.localStorageKey].pageInfo);
          this.sort$.next(parsed[this.localStorageKey].sort);
        }
      }
    }, 0);

    combineLatest([this.filter$, this.sort$, this.pageInfo$, this.fetch$])
      .pipe(tap(() => this.loading$.next(true)))
      .pipe(
        takeUntil(this.complete$),
        debounceTime(TIMEOUT.SHORT),
        mergeMap(res => of(res)),
      )
      .subscribe(([filter, sort, pageInfo, forceUpdate]) => {
        const list = this.list$.value;
        const append = this.append$.value;

        //if endless scroll finished
        if (list.items.length && list.items.length === list.totalCount && !forceUpdate && append) {
          this.loading$.next(false);
          this.append$.next(0);
          return;
        }

        this.fetchFn(filter, sort, pageInfo)
          .pipe(take(1))
          .subscribe({
            next: result => {
              //add items to main list if its infinite scroll
              if (append) {
                const full = [...list.items, ...result.items];
                this.list$.next({
                  items: full,
                  totalCount: result.totalCount || list.totalCount,
                });
              } else {
                this.disposeItems();
                this.list$.next(result);
              }

              //put filter to local storage if localStorageKey provided
              if (this.localStorageKey) {
                const storages = localStorage.getItem(LIST_STORE_LS_KEY);
                const parsed = storages || storages?.length ? JSON.parse(storages) : {};

                localStorage.setItem(
                  LIST_STORE_LS_KEY,
                  JSON.stringify({
                    ...parsed,
                    [this.localStorageKey]: {
                      filter: this.filter$.value,
                      pageInfo: append
                        ? {
                            pageSize: 25,
                            pageIndex: 0,
                          }
                        : this.pageInfo$.value,
                      sort: this.sort$.value,
                    },
                  }),
                );
              }
              this.loading$.next(false);
            },
            error: e => {
              console.error(e);
              this.loading$.next(false);
            },
          });
      });
  }

  private setLocalStorageKey(key: string): ListStore<Item, Column, Filter> {
    this.localStorageKey = key;
    return this;
  }

  public triggerList() {
    this.list$.next(this.list$.value);
  }

  public fetch(clearList = false, forceUpdate = true) {
    if (clearList) {
      this.list$.next({
        items: [],
        totalCount: 0,
      });
    }
    if (forceUpdate) {
      this.pageInfo$.next({ ...this.pageInfo$.value, pageIndex: 0 });
    }
    this.fetch$.next(forceUpdate);
  }

  public changePage(page: IPager, clearList = false) {
    if (clearList) {
      this.list$.next({
        items: [],
        totalCount: this.list$.value.totalCount,
      });
    }
    const { pageIndex, pageSize } = page;
    this.pageInfo$.next({
      pageIndex,
      pageSize,
    });
  }

  public appendItems() {
    if (this.list$.value.items.length === this.list$.value.totalCount) return;
    if (this.loading$.value) return;

    this.pageInfo$.next({
      pageIndex: this.pageInfo$.value.pageIndex + 1,
      pageSize: this.pageInfo$.value.pageSize,
    });
    this.append$.next(this.pageInfo$.value.pageSize);
  }

  public changeFilter(filterParameters: Filter, savePrev = true) {
    this.list$.next({
      items: [],
      totalCount: 0,
    });
    savePrev ? this.filter$.next({ ...this.filter$.value, ...filterParameters }) : this.filter$.next({ ...filterParameters });
    this.pageInfo$.next({
      pageSize: this.pageInfo$.value.pageSize,
      pageIndex: 0,
    });
  }

  public dispose() {
    this.fetch$.complete();
    this.loading$.complete();

    this.list$.complete();
    this.filter$.complete();
    this.sort$.complete();
    this.pageInfo$.complete();

    this.complete$.next();
    this.complete$.complete();

    this.disposeItems();
  }

  private disposeItems() {
    // @ts-ignore
    if (this.list$.value.items.some((item: Disposable) => item.dispose))
      // @ts-ignore
      this.list$.value.items.map(vm => vm.dispose());
  }
}
