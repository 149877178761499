import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'marketplace-text-column',
  templateUrl: './text-column.component.html',
  styleUrls: ['./text-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextColumnComponent {
  @Input() public title: string | null = null;
  @Input() public titleClass = '';
  @Input() public text: string | null = null;
  @Input() public textClass = '';
}
