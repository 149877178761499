import { Injectable } from '@angular/core';
import { FormGroup } from '@ngneat/reactive-forms';
import { select, Store } from '@ngrx/store';
import { transport } from '@transport/proto';
import {
  ICheckOrganizationInput,
  ICheckTruckInput,
  IConfirmRegistrationStep,
  IDriverForm,
  IFileType,
  IRegistrationStatus,
  IVehicleForm,
  REGISTRATION_ENTITY,
  TAdminOrganizationForm,
  TnFileToUpload,
} from '@transport/ui-interfaces';
import { Observable } from 'rxjs';

import { TnPassportService } from '../../passport';
import { ITnState } from '../../state';
import * as actions from '../actions/registration.actions';
import { TnRegistrationService } from '../registration.service';
import * as selectors from '../selectors/registration.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnRegistrationFacade {
  constructor(
    public store: Store<ITnState>,
    private readonly passportService: TnPassportService,
    private readonly registrationService: TnRegistrationService,
  ) {}

  public step$ = this.store.pipe(select(selectors.getCurrentStep));

  public statuses$ = this.store.pipe(select(selectors.getRegistrationStatuses));

  public documents$ = this.store.pipe(select(selectors.documents));

  public signDocument$ = this.store.pipe(select(selectors.signDocument));

  public changeStep(step: REGISTRATION_ENTITY): void {
    this.store.dispatch(actions.changeStep({ payload: step }));
  }

  public setRegistrationStatus(statuses: IRegistrationStatus) {
    this.store.dispatch(actions.setRegistrationStatus({ payload: statuses }));
  }

  public getRegistrationStatus(id: string): Observable<transport.ICarrierUser | null> {
    return this.passportService.getMarketAttributes(id);
  }

  public getDocumentsAndTypes() {
    this.store.dispatch(actions.loadDocumentsAndTypes());
  }

  public startUploadFile(file: TnFileToUpload, documentType: IFileType) {
    this.store.dispatch(actions.uploadDocumentStart({ file, documentType }));
  }

  public deleteUploadedFile() {
    this.store.dispatch(actions.deleteUploadedFile());
  }

  public startDeletedFile(docId: string, index: number) {
    this.store.dispatch(actions.deleteDocumentStart({ docId, index }));
  }

  public setAllDocumentTouched() {
    this.store.dispatch(actions.setAllDocumentTouched());
  }

  public getOrganizationDocuments() {
    return this.registrationService.getOrganizationDocuments();
  }

  public getOrganizationDocumentsType() {
    return this.registrationService.getOrganizationDocumentsType();
  }

  public addDocumentForOrganization(fileId: string, documentType: string) {
    return this.registrationService.addDocumentForOrganization(fileId, documentType);
  }

  public deleteOrganizationDocument(docId: string) {
    return this.registrationService.deleteOrganizationDocument(docId);
  }

  public addDriverOnRegistration(driver: IDriverForm) {
    return this.registrationService.addDriverOnRegistration(driver);
  }

  public checkOrganization(input: IConfirmRegistrationStep<ICheckOrganizationInput>) {
    this.store.dispatch(actions.editOrganization({ payload: input }));
  }

  public checkDriver(input: IConfirmRegistrationStep<IDriverForm>) {
    this.store.dispatch(actions.addDriver({ payload: input }));
  }

  public checkSign() {
    this.store.dispatch(actions.addSignForOrganization());
  }

  public setOrganizationInputFields(organization: TAdminOrganizationForm): ICheckOrganizationInput {
    return this.registrationService.setOrganizationInputFields(organization);
  }

  public setTruckInputFields(truck): ICheckTruckInput {
    return this.registrationService.setTruckInputFields(truck as FormGroup<IVehicleForm>);
  }
}
