import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TnYamapsUtilsService {
  /**  Center om map defaul point - Moscow */
  public readonly defaultLatitude = 55.76;

  public readonly defaultLongitude = 37.64;

  public defaultCity: number[] = [this.defaultLatitude, this.defaultLongitude];

  /**
   * Generate id for map
   * @returns String mapId.
   */
  public getId(): string {
    return `id${Date.now() + Math.random() * (Date.now() - 0) + 0}`;
  }

  /**
   * Check container with map
   * @param mapId Id of map.
   * @returns boolean Coordinate.
   */
  public checkContainer(mapId: string): boolean {
    return document.getElementById(String(mapId)) !== null;
  }

  /**
   * @note TODO: coordinates validator
   */
  public validateCoordinates(data: unknown[] = []): unknown[] {
    return data;
  }
}
