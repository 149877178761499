import { BehaviorSubject, catchError, map, of, Subject, takeUntil } from 'rxjs';
import { TnGqlClientSharedService } from '@transport/ui-store';
import { USER_ROLE, VEHICLE_TYPE_OWNERSHIP } from '@transport/ui-interfaces';
import { FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { VehicleFormVm } from '../vehicle.service';
import { IMarketplaceVehicleData, IVehicleFormFields } from '../vehicle.interface';
import { getVehicleForm } from './vehicle-form';
import { VEHICLE_QUERIES } from '../vehicle-queries';
import { readMarketplaceVehicle } from '../vehicle.mapper';
import { VehicleBaseVm } from './vehicle-base-vm';
import { ALERT_STATE, ALERT_TITLE_VARIANT, IAlertInputs } from 'libs/transport-ui-kit/src/lib/alert/alert.interfaces';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';

export class VehicleEditVm implements VehicleFormVm {
  public title = this.translate.instant('shared.mp.vehicles.vehicle.title.edit');
  public actionBtnTxt = this.translate.instant('shared.mp.vehicles.vehicle.buttons.saveAndAccreditate');
  public vehicle$ = new BehaviorSubject<IVehicleFormFields>(readMarketplaceVehicle());
  public loading$ = new BehaviorSubject<boolean>(false);
  public finish$ = new Subject<{ close: boolean; alert: IAlertInputs }>();
  public disposed$ = new Subject<void>();

  public id: string;

  public form: FormGroup = getVehicleForm();

  public baseVm!: VehicleBaseVm;

  constructor(
    private vehicleId: string,
    private type: string,
    private readonly sharedGraphQlClient: TnGqlClientSharedService,
    public readonly translate: TranslateService,
    private readonly domainService: TnDomainService,
    public role: USER_ROLE,
  ) {
    this.vehicle$.next({ type: this.type } as IVehicleFormFields);
    this.id = vehicleId;
    if (this.domainService?.subDomain) {
      this.actionBtnTxt = this.translate.instant('shared.mp.vehicles.vehicle.buttons.save');
    }

    this.sharedGraphQlClient
      .query<{ vehicle: IMarketplaceVehicleData }>(this.role, VEHICLE_QUERIES.getVehicle, 1, {
        id: this.vehicleId,
      })
      .pipe(map(response => response.vehicle))
      .pipe(
        takeUntil(this.disposed$),
        catchError(() => of(undefined)),
      )
      .subscribe(res => {
        if (!res) {
          this.finish$.next({
            close: false,
            alert: {
              message: this.translate.instant('shared.mp.vehicles.vehicle.messages.fetchError'),
              state: ALERT_STATE.ERROR,
            },
          });
          return;
        }
        this.vehicle$.next(readMarketplaceVehicle(this.baseVm.countries, res));
      });

    this.baseVm = new VehicleBaseVm(this.sharedGraphQlClient, this.translate, this.domainService, this.role);
  }

  action() {
    this.form.markAllAsTouched();
    this.updateValueAndValidityFileSelectors();
    if (this.form.invalid) return;

    const fields = this.form.getRawValue();
    fields.agentCarrierId = fields.isThirdParty ? fields.agentCarrierId : null;
    fields.country = fields.country?.id;
    fields.id = this.vehicleId;
    fields.trailerBodyType = fields.trailerBodyType.id;
    fields.type = fields.type.id;
    fields.ownershipType = fields.ownershipType.id;

    this.loading$.next(true);

    this.baseVm
      .editVehicle(fields)
      .pipe(takeUntil(this.disposed$))
      .subscribe(res => {
        if ((res as { error: string }).error) {
          this.finish$.next({
            close: false,
            alert: {
              message: (res as { error: string }).error,
              state: ALERT_STATE.ERROR,
            },
          });
        } else {
          const message = (res as { submitVehicleRiskProfile: { ok: boolean } }).submitVehicleRiskProfile
            ? this.translate.instant('shared.mp.vehicles.vehicle.messages.accrSendSuccess')
            : this.translate.instant('shared.mp.vehicles.vehicle.messages.editSuccess');
          this.finish$.next({
            close: true,
            alert: {
              titleVariant: ALERT_TITLE_VARIANT.NONE,
              message: message,
              state: ALERT_STATE.SUCCESS,
            },
          });
        }
        this.loading$.next(false);
      });
  }

  private updateValueAndValidityFileSelectors() {
    this.form.controls.ownershipTractorType.updateValueAndValidity();
    this.form.controls.tracktorLeasingAgreement.updateValueAndValidity();
    this.form.controls.tracktorRentContract.updateValueAndValidity();
    (this.form.controls.tractorSts as FormGroup).controls.firstSide.updateValueAndValidity();
    (this.form.controls.tractorSts as FormGroup).controls.secondSide.updateValueAndValidity();
    this.form.controls.ownershipTrailerType.updateValueAndValidity();
    this.form.controls.trailerLeasingAgreement.updateValueAndValidity();
    this.form.controls.trailerRentContract.updateValueAndValidity();
    (this.form.controls.trailerSts as FormGroup).controls.firstSide.updateValueAndValidity();
    (this.form.controls.trailerSts as FormGroup).controls.secondSide.updateValueAndValidity();
  }

  dispose() {
    this.disposed$.next();
    this.disposed$.complete();
    this.vehicle$.complete();
    this.loading$.complete();
    this.finish$.complete();
  }
}
