import { Component, Input } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { TnCurrentUserService } from '@marketplace/auth';
import { TranslateService } from '@ngx-translate/core';
import { regExpConfig, setRegistrationNumberPattern } from '@transport/ui-utils';
import { map } from 'rxjs';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { DownloadFileService } from 'apps/marketplace/src/shared/services/download-file.service';
import { LEGAL_FORMS } from '../../verification-modal/verification-modal.component';
import { NgxMaskService } from 'ngx-mask';

@UntilDestroy()
@Component({
  selector: 'verification-sending-form',
  templateUrl: './sending-form.component.html',
  styleUrls: ['./sending-form.component.scss'],
})
export class TnSendingFormComponent {
  @Input() form!: FormGroup;

  public regExpConf = regExpConfig;
  
  public setRegistrationNumberPattern = setRegistrationNumberPattern;

  public legalForm = this.currentUserService.profile$.pipe(
    untilDestroyed(this),
    map(profile => profile.organization?.legalForm),
  );

  constructor(
    public readonly maskService: NgxMaskService,
    private translate: TranslateService,
    public currentUserService: TnCurrentUserService,
    private downloadService: DownloadFileService,
  ) {}

  get contactsForm() {
    return this.form.controls['contactsFG'] as FormGroup;
  }

  get contactsFormKeys() {
    return Object.keys(this.contactsForm.value).filter(key => key !== 'attorney');
  }

  get organizationForm() {
    return this.form.controls['organizationFG'] as FormGroup;
  }

  get vehicleForm() {
    return this.form.controls['vehicleFG'] as FormGroup;
  }

  get vehicles() {
    return (this.vehicleForm.controls['vehicles'] as FormArray).controls as FormGroup[];
  }

  get ogrnDate() {
    if (!this.currentUserService.profile.organization?.ogrnDate) return undefined;
    return this.currentUserService.profile.organization?.ogrnDate;
  }

  public getContactsTranslate(key: string) {
    return this.translate.instant(`shared.mp.verification.form.contacts.${key}`);
  }

  public getFileName(control) {
    return control?.value?.fileName;
  }

  public getFileUrl(control) {
    return control?.value?.fileUrl;
  }

  public downloadFile(control) {
    const fileurl = this.getFileUrl(control);
    const fileName = this.getFileName(control);

    this.downloadService.downloadFile(fileurl, fileName);
  }

  getMaskedNumber(phone: string) {
    return phone?.replace(/(.{2})(\d{3})(\d{3})(\d{2})(\d{2})$/, '$1 ($2) $3-$4-$5');
  }

  public isIndividualInterpreneur(legalForm) {
    return legalForm?.id === LEGAL_FORMS.INDIVIDUAL_INTERPRENEUR;
  }
  public isSelfEmployed(legalForm) {
    return legalForm?.id === LEGAL_FORMS.SELFEMPLOYED;
  }
}
