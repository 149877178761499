import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'transport-form-block-new',
  templateUrl: './form-block.component.html',
  styleUrls: ['./form-block.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnFormBlockNewComponent {
  @Input() public title?: string;

  @Input() public subtitle?: string;

  @Input() public svgIconName?: string | null;

  @Input() public matIconName?: string | null;

  @Input() public isActiveTitle?: boolean | null = false;

  @Input() public isBorder = true;

  @Input() public iconStyles: string[] = [];

  @Output() public readonly clickTitle = new EventEmitter();

  public get hasDescription() {
    return this.hasIcon || Boolean(this.title);
  }

  public get hasIcon() {
    return Boolean(this.svgIconName) || Boolean(this.matIconName);
  }
}
