import { DatePipe } from '@angular/common';
import { Validators } from '@angular/forms';
import { FormBuilder, FormControl, FormGroup } from '@ngneat/reactive-forms';
import {
  DRIVER_FORM_CONSTANTS,
  IDriver,
  IDriverForm,
  lessThanAdultAgeValidator,
  moreThanNowValidator,
  sameFieldValueValidator,
  trimmedStringValidator,
} from '@transport/ui-interfaces';
import { regExpConfig, removeEmptyProperties } from '@transport/ui-utils';

export function createDriverFormGroup(fb: FormBuilder) {
  const form: FormGroup<IDriverForm> = fb.group({
    surname: new FormControl<string>(
      '',
      Validators.compose([Validators.required, trimmedStringValidator]),
    ),
    name: new FormControl<string>(
      '',
      Validators.compose([Validators.required, trimmedStringValidator]),
    ),
    patronymic: new FormControl<string>(
      '',
      Validators.compose([trimmedStringValidator, sameFieldValueValidator('licencePatronymic', false)]),
    ),
    ...getLicenceFioFormControls(),
    ...getLicencePassportFormControls(),
    ...getCommonFormControls(),
    ...getPassportFormControls(),
  });

  return form;
}

export function getLicenceFioFormControls() {
  return {
    licenceSurname: new FormControl<string>(
      '',
      Validators.compose([Validators.required, trimmedStringValidator, sameFieldValueValidator('surname', false)]),
    ),
    licenceName: new FormControl<string>(
      '',
      Validators.compose([Validators.required, trimmedStringValidator, sameFieldValueValidator('name', false)]),
    ),
    licencePatronymic: new FormControl<string>(
      '',
      Validators.compose([trimmedStringValidator, sameFieldValueValidator('patronymic', false)]),
    ),
  };
}

export function getLicencePassportFormControls() {
  return {
    licenceSeries: new FormControl<string>(
      '',
      Validators.compose([
        Validators.required,
        Validators.maxLength(DRIVER_FORM_CONSTANTS.MAX_LICENSE_SERIES_LENGTH),
        trimmedStringValidator,
      ]),
    ),
    licenceNumber: new FormControl<string>(
      '',
      Validators.compose([
        Validators.required,
        Validators.maxLength(DRIVER_FORM_CONSTANTS.MAX_LICENSE_NUMBER_LENGTH),
        trimmedStringValidator,
      ]),
    ),
    licencePlace: new FormControl<string>('', Validators.compose([Validators.required, trimmedStringValidator])),
    licenceDate: new FormControl<string>('', Validators.compose([Validators.required, moreThanNowValidator()])),
  };
}

export function getCommonFormControls() {
  return {
    birthday: new FormControl<string>('', Validators.compose([Validators.required, moreThanNowValidator(), lessThanAdultAgeValidator()])),
    gender: new FormControl<string>('', Validators.required),
    inn: new FormControl<string>('', Validators.compose([Validators.pattern(regExpConfig.innPhysOrIp.RU)])),
    phone: new FormControl<string>('', Validators.compose([Validators.required, Validators.pattern(regExpConfig.phoneNumber.RU)])),
    addPhone: new FormControl<string>('', Validators.compose([Validators.pattern(regExpConfig.phoneNumber.RU)])),
    country: new FormControl<string>(''),
  };
}

export function getPassportFormControls() {
  return {
    passportSeries: new FormControl<string>(
      '',
      Validators.compose([
        Validators.required,
        Validators.maxLength(DRIVER_FORM_CONSTANTS.MAX_PASSPORT_SERIES_LENGTH),
        trimmedStringValidator,
      ]),
    ),
    passportNumber: new FormControl<string>(
      '',
      Validators.compose([
        Validators.required,
        Validators.maxLength(DRIVER_FORM_CONSTANTS.MAX_PASSPORT_NUMBER_LENGTH),
        trimmedStringValidator,
      ]),
    ),
    passportPlace: new FormControl<string>('', Validators.compose([Validators.required, trimmedStringValidator])),
    passportDate: new FormControl<string>('', Validators.compose([Validators.required, moreThanNowValidator()])),
  };
}

export function getDriverData(formData: FormGroup<IDriverForm> | undefined, datePipe: DatePipe): IDriverForm {
  const formValue = formData?.value;
  return {
    birthday: datePipe.transform(formValue?.birthday, 'yyyy-MM-dd'),
    gender: formValue?.gender,
    country: formValue?.country,
    inn: formValue?.inn,
    phone: formValue?.phone,
    addPhone: formValue?.addPhone,
    licenceSeries: formValue?.licenceSeries,
    licenceNumber: formValue?.licenceNumber,
    licencePlace: formValue?.licencePlace,
    licenceDate: datePipe.transform(formValue?.licenceDate, 'yyyy-MM-dd'),
    licenceSurname: formValue?.licenceSurname,
    licenceName: formValue?.licenceName,
    licencePatronymic: formValue?.licencePatronymic,
    surname: formValue?.surname,
    name: formValue?.name,
    patronymic: formValue?.patronymic,
    passportSeries: formValue?.passportSeries,
    passportNumber: formValue?.passportNumber,
    passportPlace: formValue?.passportPlace,
    passportDate: datePipe.transform(formValue?.passportDate, 'yyyy-MM-dd'),
  };
}

export function getDriverInfoFromServer(driver?: IDriver): IDriverForm {
  return {
    birthday: driver?.birthday,
    gender: driver?.gender,
    inn: driver?.inn,
    phone: driver?.phone,
    addPhone: driver?.addPhone,
    licenceSeries: driver?.licenceSeries,
    licenceNumber: driver?.licenceNumber,
    licencePlace: driver?.licencePlace,
    licenceDate: driver?.licenceDate,
    licenceSurname: driver?.surname,
    licenceName: driver?.name,
    licencePatronymic: driver?.patronymic,
    surname: driver?.surname,
    name: driver?.name,
    patronymic: driver?.patronymic,
    passportSeries: driver?.passportSeries,
    passportNumber: driver?.passportNumber,
    passportPlace: driver?.passportPlace,
    passportDate: driver?.passportDate,
    country: driver?.country,
  };
}

export function prepareInputDriverData(driverParam: IDriverForm) {
  const { agentCarrierId } = driverParam;
  const input: { addPhone; agentCarrierId? } = removeEmptyProperties({ ...driverParam });
  if (!Boolean(input.addPhone)) {
    delete input.addPhone;
  }
  input.agentCarrierId = agentCarrierId;
  return input as IDriverForm;
}
