import { ChangeDetectionStrategy, Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CLOUD_TMS_LOGO_URL } from '@transport/ui-utils';

@Component({
  selector: 'mobile-placeholder-modal',
  templateUrl: './mobile-placeholder.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobilePlaceholderModal {

  CLOUD_TMS_LOGO_URL = CLOUD_TMS_LOGO_URL;

  constructor(public readonly dialogRef: MatDialogRef<MobilePlaceholderModal, unknown>) {}
}
