export function isSnakeCase(text: string): boolean {
  return text.includes('_');
}

export function snakeCaseToCamelCase(text: string) {
  const words = text.toLowerCase().split('_');
  return words.map((item, index) => (index ? capitalize(item) : item)).join('');
}

export function capitalize(word: string) {
  return word.charAt(0).toUpperCase() + word.slice(1);
}

export function stringToArray(stringOrArray?: string | string[] | null, delimiter = ','): string[] | null {
  if (Array.isArray(stringOrArray)) {
    return stringOrArray;
  }
  return Boolean(stringOrArray) ? (stringOrArray as string).split(delimiter) : null;
}

export function abbreviation(text: string): string {
  return Boolean(text) ? `${text[0].toUpperCase()}.` : '';
}

export function getCommaSeparatedText(array: string[]): string {
  const arrayToString = String(array);
  if (arrayToString.slice(-1) === ',') {
    return arrayToString.slice(0, -1).replace(/,/g, ', ');
  }
  return arrayToString.replace(/,/g, ', ');
}
