import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { TnToasterFacade, TnUserService } from '@transport/ui-store';

import { RedirectAfterLoginService } from '../../shared/services/redirect-after-login.service';
import { delay, of } from 'rxjs';

/**
 * Marketplace guard.
 */
@Injectable({
  providedIn: 'root',
})
export class TnMarketplaceGuard implements CanActivate {
  public authUrls = ['/passport/login'];

  constructor(
    public readonly toastFacade: TnToasterFacade,
    public readonly userService: TnUserService,
    private readonly router: Router,
    private readonly redirectAfterLoginService: RedirectAfterLoginService,
  ) {}

  /**
   * Redirects user to passport/login path if user is not logged in.
   */
  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const path = next.url.toString();
    const token = JSON.parse(this.userService.getCookie(this.userService.getCookieName()) ?? '{"token":""}')?.token;

    if (Boolean(token)) {
      this.redirectAfterLoginService.pushUrlToLocalStorage(path);
      return true;
    }

    return this.router.navigate(this.authUrls);
  }
}
