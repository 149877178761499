import { createFeatureSelector, createSelector } from '@ngrx/store';

import { selectRouteData, selectRouteParam } from '../../ngrx-router/selectors/router.selectors';
import { IProfileState, profileFeatureKey } from '../transport-profile.state';

const getProfileState = createFeatureSelector<IProfileState>(profileFeatureKey);
const getSubOrganizationProfileState = createSelector(getProfileState, state => state.subOrganization);
const getProfileSharedState = createSelector(getProfileState, state => state.shared);

const getVatTypes = createSelector(getProfileSharedState, state => state.vatTypesDict);
const getFormMode = selectRouteData('action');
const getEditableProfileId = selectRouteParam('id');
const getReactiveFormValue = createSelector(getSubOrganizationProfileState, state => state.reactiveFormValue);
const getLoading = createSelector(getSubOrganizationProfileState, state => state.loading);
const getLegalForms = createSelector(getProfileSharedState, state => state.legalFormsDict);
const getDefaultInsurance = createSelector(getProfileSharedState, state => state.defaultContract);
const getPaymentTypes = createSelector(getProfileSharedState, state => state.paymentTypesDict);
const getIsArchivedOrganization = createSelector(getSubOrganizationProfileState, state => state.isArchived);

export const subOrganizationProfileSelectors = {
  getVatTypes,
  getFormMode,
  getEditableProfileId,
  getLoading,
  getReactiveFormValue,
  getLegalForms,
  getDefaultInsurance,
  getPaymentTypes,
  getIsArchivedOrganization,
};
