import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TnFileDownloadService {
  constructor(private readonly http: HttpClient) {}

  public download(input: { url: string; fileName?: string }): Observable<boolean> {
    const url = input.url;

    return this.http.get(url, { observe: 'response', responseType: 'arraybuffer' }).pipe(
      tap(data => {
        this.uploadFile(data, input?.fileName);
      }),
      map(() => true),
    );
  }

  public downloadWithBody(input: { url: string; fileName?: string }, body: unknown): Observable<boolean> {
    const url = input.url;

    return this.http.post(url, body, { observe: 'response', responseType: 'arraybuffer' }).pipe(
      tap(data => this.uploadFile(data, input?.fileName)),
      map(() => true),
    );
  }

  public uploadFile(data: HttpResponse<ArrayBuffer>, name?: string) {
    if (data.body !== null) {
      const fileName = Boolean(name) ? (name as string) : this.getFileNameFromContentDisposition(data.headers.get('content-disposition'));
      const file = new File([data.body], fileName);
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(file);
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  // TODO возможно стоит передавать как параметр и заголовок и параметр split
  private getFileNameFromContentDisposition(headerValue: string | null): string {
    if (Boolean(headerValue)) {
      const fileName = (headerValue as string).split("filename*=utf-8''")[1];
      return Boolean(fileName) ? decodeURI(fileName) : '';
    }
    return '';
  }
}
