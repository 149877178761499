import moment from 'moment';

import { TnUploadedFile } from '../files/uploaded-file';
import { USER_ROLE } from '../user/user-common.interface';
import { IReconcilationActDto } from './dto/reconciliation-act.dto';
import { RECONCILIATION_ACT_CREATION_METHOD } from './reconciliation-act-creation-method';
import { RECONCILIATION_ACT_DIFF_TYPE, reconciliationAсtDiffTypeFromDto } from './reconciliation-act-diff-type';
import { RECONCILIATION_ACT_ERROR_TYPE } from './reconciliation-act-error-type';
import { TnReconciliationActFilesUrlGeneratorAbstract } from './reconciliation-act-files-url-generator';
import { TnReconcilationActOrganization } from './reconciliation-act-organization';
import { TnReconcilationActRule } from './reconciliation-act-rule';
import { RECONCILIATION_ACT_STATUS } from './reconciliation-act-status';

export class TnReconcilationActModel {
  public id = '';

  public guid = '';

  public createDateTime: moment.Moment | null = null;

  public actDateFrom: moment.Moment | null = null;

  public actDateTo: moment.Moment | null = null;

  public firstOrganization: TnReconcilationActOrganization | null = null;

  public secondOrganization: TnReconcilationActOrganization | null = null;

  public firstRule: TnReconcilationActRule | null = null;

  public secondRule: TnReconcilationActRule | null = null;

  public diff: RECONCILIATION_ACT_DIFF_TYPE | null = null;

  public creationMethod: RECONCILIATION_ACT_CREATION_METHOD | null = null;

  public comment = '';

  public status: RECONCILIATION_ACT_STATUS | null = null;

  public errorType: RECONCILIATION_ACT_ERROR_TYPE | null = null;

  public firstAct: TnUploadedFile | null = null;

  public secondAct: TnUploadedFile | null = null;

  public resultsFileFull: TnUploadedFile | null = null;

  public resultsFileDiffOnly: TnUploadedFile | null = null;

  // eslint-disable-next-line complexity, max-lines-per-function -- TODO: tech debt
  public static fromDto(
    dtoItem: IReconcilationActDto,
    urlGenerator?: TnReconciliationActFilesUrlGeneratorAbstract,
  ): TnReconcilationActModel {
    const createDateTime = moment(dtoItem.created_at);
    const actDateFrom = moment(dtoItem.act_date_from);
    const actDateTo = moment(dtoItem.act_date_to);
    const result = new TnReconcilationActModel();

    result.id = dtoItem.id;
    result.guid = dtoItem.guid;
    result.createDateTime = (createDateTime.isValid() && createDateTime) || null;
    result.actDateFrom = (actDateFrom.isValid() && actDateFrom) || null;
    result.actDateTo = (actDateTo.isValid() && actDateTo) || null;
    result.firstOrganization = new TnReconcilationActOrganization(
      dtoItem.organization_1,
      dtoItem.organization_1_name ?? '',
      USER_ROLE.CARRIER,
    );
    result.secondOrganization = new TnReconcilationActOrganization(
      dtoItem.organization_2,
      dtoItem.organization_2_name ?? '',
      USER_ROLE.OWNER,
    );

    result.creationMethod = dtoItem.creation_method;
    result.comment = dtoItem.comment ?? '';
    result.diff = reconciliationAсtDiffTypeFromDto(dtoItem.has_diff);
    result.status = dtoItem.status;
    result.errorType = dtoItem.error_type;
    // с бэка не приходит идентификатор правила (пока просто -1, он нигде не используется),
    // если он понадобится на фронте, то надо будет доработать бэк
    result.firstRule = new TnReconcilationActRule(-1, dtoItem.organization_1_rule_used);
    result.secondRule = new TnReconcilationActRule(-1, dtoItem.organization_2_rule_used);

    if (typeof urlGenerator !== 'undefined') {
      result.firstAct = new TnUploadedFile(dtoItem.act_1_filename, urlGenerator.generateDownloadUrlForAct(result.guid, '1'));
      result.secondAct = new TnUploadedFile(dtoItem.act_2_filename, urlGenerator.generateDownloadUrlForAct(result.guid, '2'));

      if (result.status === RECONCILIATION_ACT_STATUS.COMPLETED) {
        result.resultsFileFull = new TnUploadedFile(
          dtoItem.full_results_filename,
          urlGenerator.generateDownloadUrlForResults(result.guid, true),
        );
        if (result.diff === RECONCILIATION_ACT_DIFF_TYPE.YES) {
          result.resultsFileDiffOnly = new TnUploadedFile(
            dtoItem.diff_results_filename,
            urlGenerator.generateDownloadUrlForResults(result.guid, false),
          );
        }
      }
    }

    return result;
  }
}
