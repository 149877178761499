<mat-progress-bar *ngIf="isLoading" class="progress-line" mode="indeterminate"></mat-progress-bar>
<h1 mat-dialog-title class="flex justify-between content-start">
  <mat-icon
    class="invite-icon"
    *ngIf="(inviteDriver$ | async) && !isLoading"
    [svgIcon]="resultInvite ? 'invite-send' : 'invite-warning'"
  ></mat-icon>
  <ng-container *ngIf="!(inviteDriver$ | async) || isLoading; else inviteDriverTitle">
    {{ 'shared.driverInvite.inviteTitle' | translate }}
  </ng-container>
  <button mat-icon-button matTooltip="{{ 'shared.actions.close' | translate }}" class="close-button" mat-dialog-close (click)="close()">
    <mat-icon class="material-icons dialog-close">close</mat-icon>
  </button>
</h1>
<div mat-dialog-content class="invite-content flex flex-col">
  <ng-container *ngIf="!(inviteDriver$ | async) || isLoading; else inviteDriverDescription">
    {{ 'shared.driverInvite.inviteDescription' | translate }}
  </ng-container>
</div>
<div mat-dialog-actions class="invite-actions flex justify-end">
  <ng-container *ngIf="inviteDriver$ | async; else inviteDriverClose">
    <button mat-raised-button color="primary" mat-button (click)="close()">
      {{ 'shared.actions.close' | translate }}
    </button>
  </ng-container>
</div>

<ng-template #inviteDriverTitle>
  <ng-container *ngIf="!isLoading">
    <span *ngIf="resultInvite">{{ 'shared.driverInvite.inviteTitleSuccess' | translate }}</span>
    <span *ngIf="!resultInvite">{{ 'shared.driverInvite.inviteTitleError' | translate }}</span>
  </ng-container>
</ng-template>

<ng-template #inviteDriverDescription>
  <ng-container *ngIf="!resultInvite">{{
    'shared.driverInvite.inviteDescriptionError' | translate
  }}</ng-container>
</ng-template>

<ng-template #inviteDriverClose>
  <button mat-button matDialogClose cdkFocusInitial (click)="close()">
    {{ 'shared.actions.cancel' | translate }}
  </button>
  <button mat-raised-button color="primary" mat-button (click)="invite()">
    {{ 'shared.actions.invite' | translate }}
  </button>
</ng-template>
