import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnChanges, OnInit, SimpleChanges, SkipSelf } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { transport } from '@transport/proto';
import { MENU_STATE } from '@transport/sidebar';
import { USER_ROLE } from '@transport/ui-interfaces';
import { APPLICATION, TnChangeApplicationService, TnCurrentUserFacade, TnGqlClientSharedService } from '@transport/ui-store';
import { CLOUD_EXCHANGE_LOGO_URL, CLOUD_TMS_LOGO_URL, CLOUD_TMS_LOGO_URL_MINI } from '@transport/ui-utils';
import { TnServicePassportComponent } from 'libs/transport-passport/src/lib/services/transport-passport.service';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';
import { BehaviorSubject, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'transport-company-logo',
  templateUrl: './company-logo.component.html',
  styleUrls: ['./company-logo.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnCompanyLogoComponent implements OnInit, OnChanges {
  @Input() public isTonna = false;

  @Input() public application: APPLICATION = APPLICATION.CLOUD_TMS;

  // eslint-disable-next-line
  @Input() public menuState$!: BehaviorSubject<MENU_STATE>;

  public collapsed = false;

  public defaultLogo = CLOUD_TMS_LOGO_URL;

  public companyLogo = '';

  public logoSubClass = '';

  private personalLogo = '';

  private isLoaded = true;

  constructor(
    private readonly gql: TnGqlClientSharedService,
    private readonly cdr: ChangeDetectorRef,
    private readonly user: TnCurrentUserFacade,
    private readonly domainService: TnDomainService,
    private readonly passportService: TnServicePassportComponent,
    private readonly applicationChangeService: TnChangeApplicationService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes.application) {
      this.setActualLogo();
    }
  }

  public ngOnInit(): void {
    void this.user.isLoggedIn$.pipe(untilDestroyed(this)).subscribe(token => {
      if (token && Boolean(this.domainService.subDomain)) {
        this.isUserRole();
      }
    });

    if (!this.isTonna) {
      this.applicationChangeService
        .getApplicationChanged$()
        .pipe(untilDestroyed(this))
        .subscribe(res => {
          this.application = res;
          this.setActualLogo();
          this.cdr.markForCheck();
        });
    } else {
      this.isLoaded = false;
      this.setActualLogo();
    }

    this.menuState$.pipe(untilDestroyed(this)).subscribe(state => {
      this.collapsed = state === 'out';
      this.setActualLogo();
    });

    this.cdr.markForCheck();
  }

  private setActualLogo(): void {
    switch (this.application) {
      case APPLICATION.CLOUD_TMS:
        this.companyLogo = this.getCompanyLogo();
        this.logoSubClass = '';
        break;
      case APPLICATION.CLOUD_EXCHANGE:
        this.companyLogo = this.getExchangeLogo();
        this.logoSubClass = 'exchange';
    }
    this.cdr.markForCheck();
  }

  private isUserRole(): void {
    if (this.user.currentUserRole === USER_ROLE.CARGO_OWNER) {
      this.getOwnerLogoAndName();
    } else if (this.user.currentUserRole === USER_ROLE.CARRIER) {
      this.getOwnerLogoAndNameByCarrier();
    }
  }

  private getCompanyLogo(): string {
    let logo = '';
    if (this.isLoaded) {
      return logo;
    }
    if (this.personalLogo) {
      logo = this.collapsed ? '' : this.personalLogo;
    } else {
      logo = this.collapsed ? CLOUD_TMS_LOGO_URL_MINI : CLOUD_TMS_LOGO_URL;
    }

    return logo;
  }

  private getExchangeLogo(): string {
    if (this.isLoaded) {
      return '';
    }
    return this.personalLogo ? this.personalLogo : CLOUD_EXCHANGE_LOGO_URL;
  }

  private getOwnerLogoAndName(): void {
    void this.gql
      .getUserProfileLogoAndName(USER_ROLE.CARGO_OWNER)
      .pipe(untilDestroyed(this))
      .subscribe((organization: transport.OwnerOrganizationSubdomain) => {
        this.isLoaded = false;
        this.personalLogo = organization.organization.logo ?? '';
        this.setActualLogo();
      });
  }

  private getOwnerLogoAndNameByCarrier(): void {
    this.passportService
      .getOrganizationByDomain()
      .pipe(
        untilDestroyed(this),
        catchError(err => {
          this.isLoaded = false;
          this.personalLogo = '';
          return throwError(() => err);
        }),
      )
      .subscribe((organization: transport.InvitedOwnerOrganization) => {
        this.isLoaded = false;
        this.personalLogo = organization?.logo ?? '';
        this.setActualLogo();
      });
  }
}
