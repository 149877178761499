import { Injectable } from "@angular/core";
import { CARRIER_MUTATION } from "@transport/gql";
import { USER_ROLE } from "@transport/ui-interfaces";
import { TnGqlClientSharedService } from "@transport/ui-store";
import { Observable } from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class DriverInviteDialogService {

  constructor(private readonly sharedGraphQlClient: TnGqlClientSharedService) {}

  public inviteDriver(role: USER_ROLE, driverId: string, withSpinner?: boolean): Observable<{ inviteDriverToMobile: boolean }> {
    return this.sharedGraphQlClient.mutate<{ inviteDriverToMobile: boolean }>(role, CARRIER_MUTATION.inviteDriver, { driverId }, withSpinner);
  }
}