import { Injectable } from '@angular/core';
import { transport } from '@transport/proto';
import { ICarrierOrganization, IPlace, STATE_RELATIONSHIP, TStateRelationship } from '@transport/ui-interfaces';
import { TnLoadingPlaceSelectPipe } from '@transport/ui-pipes';
import { removeEmptyProperties } from '@transport/ui-utils';
import { forkJoin, Observable } from 'rxjs';
import { map, take } from 'rxjs/operators';

import { TnGqlClientOwnerMutationsService, TnGqlClientOwnerQueriesService } from '../../../gql-client-owner';
import { IUploadContractInput } from '@transport/ui-components';
import { OWNER_MUTATIONS } from '@transport/gql';

@Injectable({
  providedIn: 'root',
})
export class TnOwnerDirectoryCarriersApiService {
  constructor(
    private readonly gqlQuery: TnGqlClientOwnerQueriesService,
    private readonly gqlMutation: TnGqlClientOwnerMutationsService,
    private readonly loadingPlaceSelectPipe: TnLoadingPlaceSelectPipe,
  ) {}

  public editBusinessRelationshipFacsimileImg(
    carrierOrgId: string,
    carrierFacsimileImg: File | null,
  ): Observable<{ state: TStateRelationship }> {
    return this.gqlMutation.editBusinessRelationshipFacsimileImg(carrierOrgId, carrierFacsimileImg);
  }

  /**
   * Request for list of items
   */
  public getCarrierOrganizationsList(
    withSpinner = false,
    withContracts = true,
    state?: STATE_RELATIONSHIP[],
  ): Observable<{ list: transport.Carrier.IOrganization[]; totalCount: number | undefined }> {
    return this.gqlQuery.queryCarrierOrganizationsList(1, withSpinner, withContracts, state).pipe(
      map(data => {
        return {
          list: data,
          totalCount: void 0,
        };
      }),
    );
  }

  public getMyCarrierContractingRequired(
    listenX?: number,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
    options?: Record<string, unknown>,
  ): Observable<{ list: any[]; totalCount: number }> {
    return this.gqlQuery.queryMyCarrierContractingRequired(1, withSpinner).pipe(
      map(data => {
        return {
          list: data.carrierOrganizations,
          totalCount: data.carrierOrganizations.length,
        };
      }),
    );
  }

  public getTrustedCarrierOrganizationList(
    listenX?: number,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
    options?: Record<string, unknown>,
  ): Observable<{ list: ICarrierOrganization[]; totalCount: number }> {
    return this.gqlQuery.queryTrustedCarrierOrganizationsList(listenX, withSpinner, first, offset, options).pipe(
      map(data => {
        return {
          list: data.trustedCarrierOrganizations,
          totalCount: data.totalCount ?? 0,
        };
      }),
    );
  }

  public getCarrierOrganizationsFilterList(withSpinner = false, withContracts = true): Observable<Partial<ICarrierOrganization>[]> {
    return this.gqlQuery.queryCarrierOrganizationsFilterList(1, withSpinner, withContracts);
  }

  /**
   * Get carrier organization by id
   * @param carrierOrganizationId Id cargo type
   * @returns Observable with cargo type
   */
  public getOwnerCarrierOrganization(carrierOrganizationId: string) {
    return this.gqlQuery.queryCarrierOrganization(carrierOrganizationId).pipe(map(data => data.carrierOrganization));
  }

  /**
   * Edit carrier organization request
   * @param contractData Carrier data
   * @returns Observable with status
   */
  public editContract(contractData: Record<string, unknown>, file?: File | null) {
    const contractDataInput: Record<string, unknown> = {
      input: contractData,
    };
    if (file) {
      contractDataInput.file = file;
    }
    return this.gqlMutation.editCarrierOrganizationContract(contractDataInput);
  }

  /**
   * Create new carrier request
   * @param contractData Carrier data
   * @returns Observable with status
   */
  public addContract(contractData: Record<string, unknown>, file?: File | null) {
    const contractDataInput: Record<string, unknown> = {
      input: removeEmptyProperties(contractData),
    };
    if (file) {
      contractDataInput.file = file;
    }
    return this.gqlMutation.addCarrierOrganizationContract(contractDataInput);
  }

  /**
   * Remove carrier request
   * @param cargoTypeId Id carrier
   * @returns Observable with status
   */
  public removeContract(contractId: string) {
    return this.gqlMutation.archiveContract(contractId);
  }

  /**
   * Get options for some contract fields
   */
  public getDialogData() {
    return forkJoin({
      loadingPoints: this.gqlQuery.queryStoragePoints(1, true, 0, 0, {
        typePoint: 'LOADING',
        withArchived: false,
        selectMode: true,
      }),
      unloadingPoints: this.gqlQuery.queryStoragePoints(1, true, 0, 0, {
        typePoint: 'UNLOADING',
        withArchived: false,
        selectMode: true,
      }),
      cargoTypes: this.gqlQuery.queryCargoTypes(1, true, 0, 0, { selectMode: true }),
    }).pipe(
      take(1),
      map(data => {
        return {
          loadingPlaces: data.loadingPoints.storagePoints.map(item => {
            return {
              id: item.id,
              label: this.loadingPlaceSelectPipe.transform(item as Partial<IPlace>),
            };
          }),
          unloadingPlaces: data.unloadingPoints.storagePoints.map(item => {
            return {
              id: item.id,
              label: this.loadingPlaceSelectPipe.transform(item as Partial<IPlace>),
            };
          }),
          cargoTypes: data.cargoTypes.cargoTypes.map(item => {
            return {
              id: item.id,
              label: item.name,
            };
          }),
        };
      }),
    );
  }

  public addCargoContract(input: IUploadContractInput, file: File) {
    return this.gqlMutation.addCargoContract(input, file);
  }
}
