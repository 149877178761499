import { CUSTOM_ELEMENTS_SCHEMA, ModuleWithProviders, NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TnAdminStoreModule } from './admin/admin.module';
import { TnConfirmationModule } from './confirmation/confirmation.module';
import { TnConstantsModule } from './constants/constants.module';
import { TnCurrentUserStateModule } from './current-user/current-user.module';
import { TnDadataModule } from './dadata/dadata.module';
import { DEVTOOLS_EXTENSIONS_CHECKER, devtoolsExtensionsChecker, devtoolsExtensionsCheckerShim } from './dev-utils/extensions-checker.util';
import { TnDirectoryStateModule } from './directory/directory-state.module';
import { TnFeatureAccessModule } from './feature-access/feature-access.module';
import { TnFiasModule } from './fias/fias.module';
import { TnFormModule } from './form';
import { TnNgrxErrorModule } from './ngrx-error/ngrx-error.module';
import { TnNgrxRouterModule } from './ngrx-router/ngrx-router.module';
import { TnOrdersTableStateModule } from './orders-table/orders-table-state.module';
import { TnPassportStateModule } from './passport/passport-store.module';
import { TnProgressModule } from './progress/progress.module';
import { TnReconciliationActStateModule } from './reconciliation-act/reconciliation-act-state.module';
import { TnRegistrationStateModule } from './registration';
import { TnTimeZoneModule } from './time-zone';
import { TnToasterModule } from './toaster/toaster.module';
import { TnTrackingStoreModule } from './tracking/tracking.module';
import { ApolloModule } from 'apollo-angular';

@NgModule({
  imports: [
    StoreModule,
    EffectsModule,
    TnCurrentUserStateModule,
    TnNgrxRouterModule,
    TnNgrxErrorModule,
    TnToasterModule,
    TnProgressModule,
    TnFeatureAccessModule,
    TnPassportStateModule,
    TnRegistrationStateModule,
    TnConstantsModule,
    TnFiasModule,
    TnDadataModule,
    TnTimeZoneModule,
    TnDirectoryStateModule,
    TnConfirmationModule,
    TnOrdersTableStateModule,
    TnTrackingStoreModule,
    TnAdminStoreModule,
    TnFormModule,
    TnReconciliationActStateModule,
    ApolloModule
  ],
  exports: [StoreModule, EffectsModule, TnCurrentUserStateModule, TnNgrxRouterModule, TnNgrxErrorModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TnUiStoreModule {
  public static forRoot(): ModuleWithProviders<TnUiStoreModule> {
    return {
      ngModule: TnUiStoreModule,
      providers: [
        {
          provide: DEVTOOLS_EXTENSIONS_CHECKER,
          useFactory: devtoolsExtensionsCheckerShim,
        },
      ],
    };
  }
}
