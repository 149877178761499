import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { transport } from '@transport/proto';
import { ICreateCompanyStatus, IListOrganization, IOrganizationInnStatuses } from '@transport/ui-interfaces';
import { TnHttpHandlersService, TnUserService } from '@transport/ui-store';
import { StringValueNode } from 'graphql';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TnServicePassportComponent {
  private readonly inviteToken = this.tnUserService.restoreInviteToken();

  private readonly endpoints = {
    getOrganizationNameByInn: (): string => this.handlers.getEndpointUrl('/auth/get_organization_by_inn'),
    getInvitedOrganization: (): string => this.handlers.getEndpointUrl('/auth/invite_creator_organization'),
    createOrganizationByInn: (): string => this.handlers.getEndpointUrl('/auth/create_organization'),
    getOrganizationByDomain: (): string => this.handlers.getEndpointUrl('/auth/organization_by_domain'),
    getOrganizations: (): string => this.handlers.getEndpointUrl('/auth/get_list_organizations_by_hint'),
  };

  constructor(
    private readonly http: HttpClient,
    private readonly handlers: TnHttpHandlersService,
    private readonly tnUserService: TnUserService,
  ) {}

  public getOrganizationNameByInn(inn: StringValueNode | string, selfEmployed = false): Observable<IOrganizationInnStatuses> {
    return this.http.post<IOrganizationInnStatuses>(this.endpoints.getOrganizationNameByInn(), {
      inn: inn,
      token: this.inviteToken,
      selfEmployed
    });
  }

  public getInvitedOrganization(inviteToken: string | null): Observable<transport.InvitedOwnerOrganization> {
    return this.http.post<transport.InvitedOwnerOrganization>(this.endpoints.getInvitedOrganization(), {
      token: inviteToken,
    });
  }

  public createOrganizationByInn(inn: string): Observable<ICreateCompanyStatus> {
    return this.http.post<ICreateCompanyStatus>(this.endpoints.createOrganizationByInn(), {
      inn: inn,
      token: this.inviteToken,
    });
  }

  public getOrganizationByDomain(): Observable<transport.InvitedOwnerOrganization> {
    return this.http.get<transport.InvitedOwnerOrganization>(this.endpoints.getOrganizationByDomain());
  }

  public getOrganizations(query: string, legalType?:string | null): Observable<IListOrganization[]> {
    return this.http.post<IListOrganization[]>(this.endpoints.getOrganizations(), {
      dataOrganization: query,
      legalType: legalType || undefined,
    });
  }
}
