import { AbstractControl, ValidatorFn } from '@angular/forms';
import { FormGroup } from '@ngneat/reactive-forms';
import { mergeDateAndTime } from '@transport/ui-utils';
import moment from 'moment';

import { IWayBillDocAttributes } from '../files/order-attachment';

export function waybillFormValidator(loadingPlaceStringTime?: string | null): ValidatorFn {
  return (form: AbstractControl) => {
    const formValue = (form as FormGroup<IWayBillDocAttributes>).value;
    const selectedArriveDateFrom = formValue.dateFrom;
    if (selectedArriveDateFrom) {
      const selectedArriveTimeFrom = Boolean(formValue.timeFrom) ? formValue.timeFrom : '00:00';
      const fg = form as FormGroup;
      const fromDateControl = fg.controls.dateFrom;
      const toDateControl = fg.controls.dateTo;
      const loadingPlaceTime = moment(loadingPlaceStringTime);
      const selectedArriveDateTo = formValue.dateTo;
      const selectedArriveTimeTo = formValue.timeTo;
      const selectedArriveDateTimeFrom = mergeDateAndTime(selectedArriveDateFrom, selectedArriveTimeFrom);
      if (selectedArriveDateTo) {
        const selectedArriveDateTimeTo = mergeDateAndTime(selectedArriveDateTo, selectedArriveTimeTo ?? selectedArriveTimeFrom);
        if (selectedArriveDateTimeTo <= selectedArriveDateTimeFrom) {
          toDateControl.setErrors({ dateToLessDateFrom: true });
        } else {
          fromDateControl.setErrors(null);
          toDateControl.setErrors(null);
        }
      } else {
        fromDateControl.setErrors(null);
      }
      if (selectedArriveDateTimeFrom <= loadingPlaceTime) {
        fromDateControl.setErrors({ dateFromLessLoadingDate: true });
      }
    }
    return null;
  };
}
