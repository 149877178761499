import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { transport } from '@transport/proto';
import { IDriver, IOrder, IVehicle, ORDER_DETAILS_PAGE_KIND } from '@transport/ui-interfaces';
import { TnBlockedTrailerByNomenclaturePipe } from '@transport/ui-pipes';
import { GraphQLError } from 'graphql';
import { forkJoin, of, timer } from 'rxjs';
import { catchError, filter, first, map, switchMap, takeUntil, takeWhile, tap, withLatestFrom, zip } from 'rxjs/operators';

import { RouterActions } from '../../ngrx-router/actions/router.actions';
import { ITnState } from '../../state/index';
import { GRAPH_QL_ERROR_CATEGORIES, GRAPH_QL_ERROR_CODES } from '../../toaster';
import { TnToasterFacade, TOAST_TYPE } from '../../toaster/toaster.facade';
import * as OrderDetailsCarrierActions from '../actions/order-details-carrier.actions';
import { IOrderDetailsCarrierState } from '../reducers/order-details-carrier.reducer';
import * as selectors from '../selectors/order-details-carrier.selectors';
import { TnCarrierOrdersService } from '../services/carrier/carrier-order.service';
import { TnCommonOrdersService } from '../services/common/common-order.service';
import { TnSignDocumentService } from '../services/common/sign-document.service';

enum CONSTANTS {
  INTERVAL = 1000,
  INTERVAL_MULTIPLIER = 10,
}

@Injectable({
  providedIn: 'root',
})
export class TnOrderDetailsCarrierEffects {
  public operationFail = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.loadOrderFailure, OrderDetailsCarrierActions.addInsuranceForOrderFailure),
      tap(({ error }) => {
        //retrow error for handling by global error handler
        throw error;
      }),
    ),
  );

  public unreserveTransport = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.unreserveTransportStart),
      switchMap(value => {
        return this.carrierOrdersService.unreserveTransport(value.orderId);
      }),
      map((order: IOrder) => {
        return OrderDetailsCarrierActions.unreserveTransportSuccess({ order });
      }),
    ),
  );

  public selectDriverStart = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.selectDriverStart),
      withLatestFrom(this.store.pipe(select(selectors.orderDetailsCarrierStateSelector))),
      map(([{ driver }, state]: [{ driver: transport.IDriver }, IOrderDetailsCarrierState]) => {
        const driverProhibitedBlackList = (driver.blackListInfo ?? []).filter(item => item?.workProhibited);
        if (Boolean(driverProhibitedBlackList.length > 0)) {
          return OrderDetailsCarrierActions.setBlackListForDriver({ list: driver.blackListInfo ?? [] });
        }

        if (this.isNotSameDriver(state.selectedSecondDriver, driver)) {
          return OrderDetailsCarrierActions.selectDriver({ driver });
        }
        return OrderDetailsCarrierActions.sameDrivers();
      }),
    ),
  );

  public selectVehicle = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.selectVehicle),
      withLatestFrom(this.store.pipe(select(selectors.selectedDriverSelector)), this.store.pipe(select(selectors.orderSelector))),
      filter(([, driver]) => !Boolean(driver)),
      switchMap(([action, , order]) => {
        return this.carrierOrdersService.getVehicleDriver(action.vehicle.id ?? '', order?.id as string, true);
      }),
      filter(result => Boolean(result[0])),
      map((value: IDriver[]) => {
        return OrderDetailsCarrierActions.selectDriverStart({ driver: value[0] });
      }),
    ),
  );

  public selectDriver = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.selectDriver),
      withLatestFrom(this.store.pipe(select(selectors.selectedVehicleSelector)), this.store.pipe(select(selectors.orderSelector))),
      filter(([, vehcile]) => !Boolean(vehcile)),
      switchMap(([action, , order]) => {
        return this.carrierOrdersService.getVehicleByDriver(action.driver.id ?? '', order?.id as string, true).pipe(
          filter(result => Boolean(result[0])),
          map((value: IVehicle[]) => {
            const truck = value[0];
            const truckBlackListInfoList = (truck.blackListInfo ?? []).filter(item => item?.workProhibited);
            const trailerBlackListInfoList = this.blockedTrailerPipe.transform(
              truck.vehicletrailer?.blackListInfo,
              order?.cargoNomenclatureTypes,
            );
            const isExistTruckBlackList = Boolean(truckBlackListInfoList.length);
            if (isExistTruckBlackList || Boolean(trailerBlackListInfoList?.length)) {
              return OrderDetailsCarrierActions.setBlackListForVehicle({
                list: (truck.blackListInfo ?? [] ?? []).concat(trailerBlackListInfoList ?? []),
              });
            }
            return OrderDetailsCarrierActions.selectVehicle({ vehicle: truck });
          }),
        );
      }),
    ),
  );

  public pereodicalUpdateOrder = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.pereodicalUpdateOrder),
      switchMap(action => {
        return timer(CONSTANTS.INTERVAL * CONSTANTS.INTERVAL_MULTIPLIER, CONSTANTS.INTERVAL * CONSTANTS.INTERVAL_MULTIPLIER).pipe(
          withLatestFrom(this.store.pipe(select(selectors.orderSelector))),
          takeWhile(([, order]) => this.carrierOrdersService.isAuctionActive(order as IOrder)),
          map(() => {
            return OrderDetailsCarrierActions.refreshOrderStart({
              orderId: action.orderId,
              orderKind: ORDER_DETAILS_PAGE_KIND.AUCTION,
            });
          }),
          takeUntil(this.action$.pipe(ofType(OrderDetailsCarrierActions.pageDestroy))),
        );
      }),
    ),
  );

  public firstLoadOrder = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.loadOrderSuccess),
      withLatestFrom(this.store.pipe(select(selectors.orderDetailsCarrierStateSelector))),
      first(),
      // eslint-disable-next-line @typescript-eslint/no-explicit-any -- TODO: tech debt
      zip(([res, state]: (any | IOrderDetailsCarrierState)[]) => {
        if (
          this.carrierOrdersService.isAuctionAllocation(state.order) &&
          this.carrierOrdersService.isAuctionActive(state.order)
        ) {
          return OrderDetailsCarrierActions.pereodicalUpdateOrder({ orderId: res.order.id });
        }
        return OrderDetailsCarrierActions.loadOrderSuccess({ order: res.order });
      }),
    ),
  );

  public refreshOrder = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.refreshOrderStart),
      switchMap(({ orderId, orderKind }) => {
        let observable$ = this.carrierOrdersService.getOrder(orderId);
        switch (orderKind) {
          case ORDER_DETAILS_PAGE_KIND.AUCTION:
            observable$ = this.carrierOrdersService.getAuctionOrder(orderId);
            break;
          case ORDER_DETAILS_PAGE_KIND.BID:
            observable$ = this.carrierOrdersService.getBiddingOrder(orderId);
            break;
        }
        return observable$.pipe(
          switchMap((order: IOrder) => {
            if (this.carrierOrdersService.isExpired(order)) {
              return [OrderDetailsCarrierActions.loadOrderSuccess({ order }), OrderDetailsCarrierActions.expiredOrder()];
            }
            return [OrderDetailsCarrierActions.loadOrderSuccess({ order })];
          }),
          catchError((error: GraphQLError) => this.onOrderLoadFailure(error, orderKind)),
        );
      }),
    ),
  );

  public getOrderDetails = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.loadOrderDetailsStart),
      switchMap(({ orderId, orderKind }) => {
        let order$ = this.carrierOrdersService.getOrder(orderId);
        switch (orderKind) {
          case ORDER_DETAILS_PAGE_KIND.AUCTION:
            order$ = this.carrierOrdersService.getAuctionOrder(orderId);
            break;
          case ORDER_DETAILS_PAGE_KIND.BID:
            order$ = this.carrierOrdersService.getBiddingOrder(orderId);
            break;
        }
        const cargoBodyTypes$ = this.carrierOrdersService.getBodyTypes();
        const packagingTypes$ = this.carrierOrdersService.getPackagingTypesList();

        return forkJoin([order$, cargoBodyTypes$, packagingTypes$]).pipe(
          switchMap(([order, cargoBodyTypes, packagingTypes]) => {
            if (this.carrierOrdersService.isExpired(order)) {
              return [
                OrderDetailsCarrierActions.loadOrderDetailsSuccess({ order, cargoBodyTypes, packagingTypes }),
                OrderDetailsCarrierActions.expiredOrder(),
              ];
            }
            return [OrderDetailsCarrierActions.loadOrderDetailsSuccess({ order, cargoBodyTypes, packagingTypes })];
          }),
          catchError((error: GraphQLError) => this.onOrderLoadFailure(error, orderKind)),
        );
      }),
    ),
  );

  public addInsuranceForOrder = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.addInsuranceForOrderStart),
      switchMap(({ orderId, insurance }) => {
        return this.carrierOrdersService.addInsuranceContract(orderId, insurance).pipe(
          map(({ addInsuranceOrder }) => {
            this.toastFacade.showMessage('carrier.order.field.insurance.dataPreparedMessage', TOAST_TYPE.SUCCESS);
            return OrderDetailsCarrierActions.addInsuranceForOrderSuccess({
              orderId: addInsuranceOrder?.id as string,
              insurance: addInsuranceOrder.insurance as transport.IInsurance,
            });
          }),
          catchError(error => {
            return of(OrderDetailsCarrierActions.addInsuranceForOrderFailure({ error }));
          }),
        );
      }),
    ),
  );

  public selectSecondDriverStart = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.selectSecondDriverStart),
      withLatestFrom(this.store.pipe(select(selectors.orderDetailsCarrierStateSelector))),
      switchMap(([{ driver }, state]: [{ driver: transport.IDriver }, IOrderDetailsCarrierState]) => {
        if (this.isNotSameDriver(state.selectedDriver, driver)) {
          // in status Assigned we should not set changes to server
          if (!(state.order && this.commonOrderService.isAssigned(state.order))) {
            return this.carrierOrdersService.setSecondDriver(driver?.id ?? '', state.order?.id ?? '');
          }
          return of(driver);
        }
        return of(false);
      }),
      map(coDriver => {
        if (coDriver === false) {
          return OrderDetailsCarrierActions.sameDrivers();
        }
        return OrderDetailsCarrierActions.selectSecondDriver({ driver: coDriver as IDriver });
      }),
    ),
  );

  public getCarrierContracts = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDetailsCarrierActions.getCarrierContracts),
      switchMap(({ id }) => {
        return this.carrierOrdersService.getCarrierContractsWithCargoOwner(id).pipe(
          map(contracts => OrderDetailsCarrierActions.getCarrierContractsSuccess({ contracts })),
          catchError(error => of(OrderDetailsCarrierActions.getCarrierContractsFailure({ error }))),
        );
      }),
    ),
  );

  public updateOrderContractStatus;

  private isNotSameDriver(selectedDriver?: transport.IDriver | null, newDriver?: transport.IDriver): boolean {
    return (
      !Boolean(selectedDriver?.id) ||
      !Boolean(newDriver?.id) ||
      (Boolean(selectedDriver?.id) && Boolean(newDriver?.id) && newDriver?.id !== selectedDriver?.id)
    );
  }

  private onOrderLoadFailure(error: GraphQLError, orderKind: ORDER_DETAILS_PAGE_KIND = ORDER_DETAILS_PAGE_KIND.MAIN) {
    if (orderKind === ORDER_DETAILS_PAGE_KIND.BID) {
      return this.onBiddingOrderLoadFailure(error);
    }
    return of(OrderDetailsCarrierActions.loadOrderFailure({ error }), RouterActions.routerGo({ path: ['/orders/carrier/'] }));
  }

  private onBiddingOrderLoadFailure(error: GraphQLError) {
    if (
      error?.extensions?.code === GRAPH_QL_ERROR_CODES.OBJECT_ACCESS_FORBIDDEN &&
      error?.extensions?.category === GRAPH_QL_ERROR_CATEGORIES.EXPIRED
    ) {
      this.toastFacade.showMessage('carrier.order.section.bid.expiredToastMessage', TOAST_TYPE.ERROR);
      return of(RouterActions.routerGo({ path: ['/orders/carrier/'] }));
    }
    return of(OrderDetailsCarrierActions.loadOrderFailure({ error }), RouterActions.routerGo({ path: ['/orders/carrier/'] }));
  }

  constructor(
    private readonly carrierOrdersService: TnCarrierOrdersService,
    private readonly commonOrderService: TnCommonOrdersService,
    private readonly signDocumentService: TnSignDocumentService,
    private readonly action$: Actions,
    private readonly store: Store<ITnState>,
    private readonly toastFacade: TnToasterFacade,
    private readonly blockedTrailerPipe: TnBlockedTrailerByNomenclaturePipe,
    // websocket: TnWebsocketService,
  ) {
    // const websocketTnActions$ = websocket.get$(WEBSOCKET_KEY.EVENTS).pipe(
    //   switchMap(ws =>
    //     merge(ws.onTopic('order'), ws.onTopic('termination_agreement')).pipe(
    //       filter(message => {
    //         return message?.action === 'signing';
    //       }),
    //     ),
    //   ),
    // );

    // this.updateOrderContractStatus = createEffect(() =>
    //   websocketTnActions$.pipe(
    //     withLatestFrom(this.store.pipe(select(selectors.orderDetailsCarrierStateSelector))),
    //     filter(([message, state]) => {
    //       const payloadId = message.payload?.id;
    //       const orderId = state.order?.id;
    //       return Boolean(payloadId) && Boolean(orderId) && payloadId === orderId;
    //     }),
    //     switchMap(([message, state]) => {
    //       return this.signDocumentService.getOrderContractStatus(message.payload?.id);
    //     }),
    //     map(info => {
    //       return OrderDetailsCarrierActions.updateContractStatus({ orderPart: info });
    //     }),
    //   ),
    // );
  }
}
