import { ValidatorFn, Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { transport } from '@transport/proto';
import {
  conditionalValidator,
  hasPhysicalPersonInn,
  IAgentCarrierEditInput,
  IAgentCarrierInput,
  isJuridicalPerson,
} from '@transport/ui-interfaces';
import { regExpConfig } from '@transport/ui-utils';
import ILegalForm = transport.ILegalForm;

const isJuridicalPersonValidator = (formControl: FormControl) => {
  const legalFormCode = formControl.parent?.get('legalForm')?.value;
  return isJuridicalPerson(legalFormCode);
};

const hasPhysicalPersonInnValidator = (formControl: FormControl) => {
  const legalFormCode = formControl.parent?.get('legalForm')?.value;
  return hasPhysicalPersonInn(legalFormCode);
};

export const MAX_CARRIER_NAME_LENGTH = 150;
export const MAX_POSTAL_ADDRESS_LENGTH = 300;

export function createAgentCarrierForm() {
  return new FormGroup({
    carrierName: new FormControl<string>('', [Validators.required, Validators.maxLength(MAX_CARRIER_NAME_LENGTH)]),
    legalForm: new FormControl<transport.ILegalForm | null>(null, Validators.required),
    inn: new FormControl<string | null>(null, [
      Validators.required,
      conditionalValidator(isJuridicalPersonValidator, Validators.pattern(regExpConfig.inn.RU)) as ValidatorFn,
      conditionalValidator(hasPhysicalPersonInnValidator, Validators.pattern(regExpConfig.innPhysOrIp.RU)) as ValidatorFn,
    ]),
    kpp: new FormControl<string | null>('', [
      Validators.pattern(regExpConfig.kpp.RU),
      conditionalValidator(isJuridicalPersonValidator, Validators.required) as ValidatorFn,
    ]),
    companyName: new FormControl<string>({ value: '', disabled: true }, Validators.required),
    legalAddress: new FormControl<string>({ value: '', disabled: true }, Validators.required),
    postalAddress: new FormControl<string>('', Validators.maxLength(MAX_POSTAL_ADDRESS_LENGTH)),
    vat: new FormControl<string>(),
    sameAddress: new FormControl<boolean>(false),
  });
}

export function getAgentCarrierInput({ legalForm, carrierName, inn, kpp, vat, postalAddress }): IAgentCarrierInput {
  return { legalForm: (legalForm as ILegalForm)?.id as string, carrierName, inn, kpp, vat, postalAddress };
}

export function getAgentCarrierEditInput({ postalAddress, id, vat, carrierName }): IAgentCarrierEditInput {
  return { postalAddress, id, vat: vat, carrierName };
}

export function setAgentCarrierFieldFormServer(agent: transport.IAgentCarrier) {
  const { carrierName, vat, postalAddress, inn, kpp, legalAddress, name } = agent;
  return {
    carrierName,
    vat: vat?.id,
    inn,
    kpp,
    legalAddress,
    postalAddress,
    companyName: name ?? '',
  };
}
