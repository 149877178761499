import { FocusMonitor } from '@angular/cdk/a11y';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Self,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';

import { TnBaseMatInputDirective } from '../base-mat-input';

type TInputEmptyValue = 'undefined' | 'null' | 'value';

/**
 * Owner user details page component.
 */
@Component({
  selector: 'transport-number-input',
  templateUrl: './number-input.component.html',
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TnNumberInputComponent,
    },
  ],
  styleUrls: ['./number-input.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- TODO: tech debt
  changeDetection: ChangeDetectionStrategy.Default,
})
/* eslint-disable prettier/prettier -- prettier conflicts with eslint (brace style), eslint takes precedence here */
export class TnNumberInputComponent
  extends TnBaseMatInputDirective<number | string>
  implements ControlValueAccessor, OnDestroy, DoCheck, MatFormFieldControl<unknown> {
    /* eslint-enable prettier/prettier -- prettier conflicts with eslint in this case (brace style), eslint takes precedence here */
  public static nextId = 0;

  @HostBinding('attr.id')
  public id = `number-number-input-${(TnNumberInputComponent.nextId += 1)}`;

  @Input() public dataTest = '';

  @Input() public inputMask = 'separator.2';

  @Input() public patterns = {};

  @Input() public prefix = '';

  @Input() public suffix = ' \u20BD';

  @Input() public set isCurrencyInput(val: boolean) {
    if (val) {
      this.thousandSeparator = ' ';
    }
  }
  @Input() public thousandSeparator = '';

  @Input() public emptyValue: TInputEmptyValue = 'value';

  @Input() public isElasticInput = false;

  @Input() public allowNegativeNumbers = false;

  @Input() public isClear = false;

  public controlType = 'number-number-input';

  constructor(
    public fm: FocusMonitor,
    public elRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl,
    private readonly cdRef: ChangeDetectorRef,
  ) {
    super(fm, elRef, ngControl);
  }

  public get empty() {
    return this.value !== 0 && !Boolean(this.value);
  }

  public writeValue(value: number | string | undefined): void {
    this.inputValue = value;
    this.cdRef.markForCheck();
  }

  public onChange(event) {
    this.onTouched();
    this.value = event;
    let result = this.inputValue;
    if (typeof result === 'string') {
      result = result.replace(/[ |,|\u20BD]/g, '');
    }
    this.propagateChange(result === '' ? this.getEmptyValue(result) : Number(result));
  }

  private getEmptyValue(value: string) {
    switch (this.emptyValue) {
      case 'null':
        return null;
      case 'undefined':
        return void 0;
      default:
        return value;
    }
  }
}
