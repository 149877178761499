<div class="info-step__header flex justify-between px-6 pb-3 border-b-2 border-b-gray-200 ">
  <h2 class="not-italic font-medium text-lg text-gray-700">{{ 'shared.mp.verification.infostep.header' | translate }}</h2>
  <ng-content></ng-content>
</div>
<div class="info-step flex justify-between gap-4 px-6 py-3">
  <mat-icon class="info-step__icon !w-10 !h-10" svgIcon="info-icon-40"></mat-icon>
  <div class="not-italic font-normal text-sm text-gray-700">
    <p>{{ 'shared.mp.verification.infostep.mainText.firstParagraph' | translate }}</p>
    <br />
    <p>{{ 'shared.mp.verification.infostep.mainText.secondParagraph' | translate }}</p>
    <br />
    <p>
      {{ 'shared.mp.verification.infostep.mainText.thirdParagraph.part1' | translate }}
      <a
        class="underline"
        [href]="'shared.mp.verification.infostep.mainText.thirdParagraph.personalYandexDataPolicyLink' | translate"
        target="_blank"
      >
        {{ 'shared.mp.verification.infostep.mainText.thirdParagraph.personalYandexDataPolicy' | translate }}</a
      >
      {{ 'shared.mp.verification.infostep.mainText.thirdParagraph.part2' | translate }}
      <a
        class="underline"
        [href]="'shared.mp.verification.infostep.mainText.thirdParagraph.personalDataPolicyLink' | translate"
        target="_blank"
      >
        {{ 'shared.mp.verification.infostep.mainText.thirdParagraph.personalDataPolicy' | translate }}</a
      >
    </p>
  </div>
</div>
