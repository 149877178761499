import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { BLACK_LIST_COLUMN_TYPE, IBlackListViewData } from '@transport/ui-interfaces';
import { TnBlockedTrailerByNomenclaturePipe } from '@transport/ui-pipes';
import { wordDeclination } from '@transport/ui-utils';

const SHOW_REASON_COUNT = 3;

@Component({
  selector: 'transport-black-list-column',
  templateUrl: './black-list-column.component.html',
  styleUrls: ['./black-list-column.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnBlacklistColumnComponent {
  @Input() public blackListInfo?: IBlackListViewData | null;

  @Input() public type: BLACK_LIST_COLUMN_TYPE = BLACK_LIST_COLUMN_TYPE.TRUCKS;

  @Input() public currentOrganizationId?: string;

  @Input() public orderNomenclature?: string | null;

  @Input() public id?: string;

  @Input() public returnUrl?: string;

  public columnType = BLACK_LIST_COLUMN_TYPE;

  public showReasonCount = SHOW_REASON_COUNT;

  constructor(private readonly translate: TranslateService, private readonly blockedTrailerPipe: TnBlockedTrailerByNomenclaturePipe) {}

  public get icon() {
    return this.isTransportChoosingColumnType && this.isProhibitedItem ? 'block' : 'warning_amber';
  }

  public get isChooseDriver() {
    return this.type === BLACK_LIST_COLUMN_TYPE.CHOOSE_DRIVER;
  }

  public get isTransportChoosingColumnType() {
    return this.isChooseDriver || this.type === BLACK_LIST_COLUMN_TYPE.CHOOSE_TRUCK;
  }

  public get isShowBlackListInfo() {
    const { trailer } = this.blackListInfo ?? {};
    if (this.isTransportChoosingColumnType) {
      return (
        this.isExistBlackListRecord ||
        (Boolean(trailer) && this.blockedTrailerPipe.transform(trailer?.trailerList ?? [], this.orderNomenclature)?.length)
      );
    }
    return Boolean(this.blackListInfo);
  }

  public get isExistBlackListRecord() {
    const { prohibited, partProhibited } = this.blackListInfo ?? {};
    return Number(prohibited) > 0 || Number(partProhibited) > 0;
  }

  public get isProhibitedItem() {
    const { trailer, prohibited } = this.blackListInfo ?? {};

    return (
      Number(prohibited) > 0 ||
      (Boolean(trailer?.prohibited) &&
        Boolean(this.blockedTrailerPipe.transform(trailer?.trailerList ?? [], this.orderNomenclature)?.[0]?.workProhibited))
    );
  }

  public companyLocalization(companyCounts?: number, count?: number) {
    return Number(companyCounts) > 1 ? wordDeclination(Number(count), 'severalCompanies') : 'company';
  }

  public get mainTitle() {
    const { companyCounts, prohibited } = this.blackListInfo ?? {};
    if (this.isTransportChoosingColumnType) {
      const localization = `shared.blackList.chooseTitleShared.title.${
        Boolean(this.isProhibitedItem) ? 'workProhibited' : 'partWorkProhibited'
      }`;
      return this.translate.instant(localization);
    }
    return Number(prohibited) > 0
      ? this.translate.instant(`shared.blackList.${this.type}.prohibited.${this.companyLocalization(companyCounts, prohibited)}`, {
          count: prohibited,
        })
      : '';
  }

  public get partProhibitedTitle() {
    const { companyCounts, partProhibited } = this.blackListInfo ?? {};
    return Number(partProhibited) > 0
      ? this.translate.instant(`shared.blackList.${this.type}.partProhibited.${this.companyLocalization(companyCounts, partProhibited)}`, {
          count: partProhibited,
        })
      : '';
  }

  public get reasonList() {
    return (this.blackListInfo?.reasonList ?? []).slice(0, SHOW_REASON_COUNT);
  }

  public get reasonCount() {
    return (this.blackListInfo?.reasonList ?? []).length - SHOW_REASON_COUNT;
  }

  public get prohibitedTrailerTitle() {
    return this.trailerTitle('prohibited');
  }

  public get partProhibitedTrailerTitle() {
    return this.trailerTitle('partProhibited');
  }

  public get reasonCountTitle() {
    return this.translate.instant(`shared.blackList.reason.tooltip.${wordDeclination(this.reasonCount, 'reasonCount')}`, {
      count: this.reasonCount,
    });
  }

  public get moreDetailsTitle() {
    return this.translate.instant(`shared.blackList.reason.tooltip.${this.isChooseDriver ? 'cardDriver' : 'cardTruck'}`);
  }

  public get linkPage() {
    return `directory/carrier/${this.isChooseDriver ? 'drivers' : 'trucks'}/view/${this.id}`;
  }

  private trailerTitle(lockType: 'prohibited' | 'partProhibited') {
    const { companyCounts, trailer } = this.blackListInfo ?? {};
    return Boolean(trailer?.[lockType])
      ? this.translate.instant(`shared.blackList.trailers.${lockType}.${this.companyLocalization(companyCounts, trailer?.[lockType])}`, {
          count: trailer?.[lockType],
        })
      : '';
  }
}
