import { Injectable, NgZone } from '@angular/core';
import { MatSnackBar, MatSnackBarRef, SimpleSnackBar } from '@angular/material/snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { TOAST_DURATION, toasterExtraClasses, TToast } from '@transport/ui-interfaces';

import { TnToasterActionService } from './toaster-action.service';
import { Router } from '@angular/router';

/**
 * Custom toaster service for user feedback.
 * Should be used to notify user about server responses in human readable format.
 * Usage example:
 * this.toaster.showToaster(error, 'error', 5000);
 */
@Injectable({
  providedIn: 'root',
})
export class TnToasterService {
  /**
   * Snackbar reference.
   */
  private snackBarRef?: MatSnackBarRef<SimpleSnackBar>;

  /**
   * Constructor.
   * @param snackBar Reusable snackbar
   * @param zone
   * @param toasterAction
   * @param router
   */
  constructor(
    private readonly snackBar: MatSnackBar,
    private readonly zone: NgZone,
    private readonly toasterAction: TnToasterActionService,
    private readonly router: Router,
  ) {}

  /**
   * Shows snackbar.
   * @param message text message to be displayed
   * @param type event type, colorizes snackbar
   * @param [duration] snackbar visibility duration in milliseconds
   */
  public showToaster(message: string, type: TToast, duration?: number): void {
    const ec: string[] = toasterExtraClasses(type);
    // Zone need to show toaster in right position https://github.com/angular/components/issues/13181
    this.zone.run(() => {
      this.snackBarRef = this.snackBar.open(message, void 0, {
        panelClass: ec,
        verticalPosition: 'bottom',
        duration: Boolean(duration) ? duration : TOAST_DURATION.DEFAULT,
      });
    });
  }

  /**
   * Shows snackbar.
   * @param message text message to be displayed
   * @param type event type, colorizes snackbar
   * @param [duration] snackbar visibility duration in milliseconds
   */
  public showToasterWithCancel(message: string, type: TToast, duration?: number): void {
    const ec: string[] = toasterExtraClasses(type);
    // Zone need to show toaster in right position https://github.com/angular/components/issues/13181
    this.zone.run(() => {
      this.snackBarRef = this.snackBar.open(message, 'Отмена', {
        panelClass: ec,
        verticalPosition: 'bottom',
        duration: Boolean(duration) ? duration : TOAST_DURATION.DEFAULT,
      });
      this.snackBarRef.onAction().subscribe(() => {
        this.toasterAction.getToasterAction$().next(true);
      });
    });
  }

  /**
   * Shows snackbar.
   * @param route route to redirect on click
   * @param message text message to be displayed
   * @param type event type, colorizes snackbar
   * @param [duration] snackbar visibility duration in milliseconds
   */
  public showToasterWithRedirect(route: string, message: string, type: TToast, duration?: number, customStyles: string[] = [], action?: string): void {
    const ec: string[] = toasterExtraClasses(type);
    // Zone need to show toaster in right position https://github.com/angular/components/issues/13181
    this.zone.run(() => {
      this.snackBarRef = this.snackBar.open(message, action, {
        panelClass: [...ec, ...customStyles],
        duration: Boolean(duration) ? duration : TOAST_DURATION.DEFAULT,
        horizontalPosition: 'end',
        verticalPosition: 'top',
      });
      this.snackBarRef.onAction().subscribe(() => {
        if (route !== this.router.url) this.router.navigateByUrl(route);
      });
    });
  }

  /**
   * Dismisses snackbar.
   */
  public hideToaster(): void {
    if (typeof this.snackBarRef !== 'undefined') {
      this.snackBarRef.dismiss();
    }
  }
}
