<h1 mat-dialog-title>
  {{ titleText }}
</h1>
<div mat-dialog-content class="dialog-content" *ngIf="fileData">
  <transport-file-additional-attributes-container
    [docAttributes]="{ docType: docType, waybillForm: waybillForm }"
  ></transport-file-additional-attributes-container>
</div>
<div mat-dialog-actions class="flex justify-end content-center">
  <button mat-raised-button color="primary" (click)="close()">
    {{ closeButtonText | translate }}
  </button>
</div>
