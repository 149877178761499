import { getTableReducer, PAGE_SIZE } from '../../../../table/table-params.reducer';
import { tableActions } from '../actions/choose-trucks.actions';

const initialState = {
  filter: {
    availability: 'ACTIVE',
  },
  pagination: {
    pageSize: PAGE_SIZE.FIVE,
    page: 0,
    pageSizeOptions: [PAGE_SIZE.FIVE, PAGE_SIZE.TEN, PAGE_SIZE.TWENTY],
  },
};

export function chooseTrucksReducer(state, action) {
  return getTableReducer(tableActions, initialState)(state, action);
}
