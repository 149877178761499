import { gql } from 'apollo-angular';

export const FILTERS_TOOLBAR_QUERIES = {
  counter: gql`
    query DriverStatusCounter {
      driverStatusCounters {
        all
        notArchived
        archived
      }
    }
  `,
};
