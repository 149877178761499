import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, Provider } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TnNgrxErrorEffects } from './effects/ngrx-error.effects';
import { TnNgrxErrorInterceptorService } from './ngrx-error-interceptor.service';
import { ngrxErrorReducer } from './reducer/ngrx-error.reducer';

export const ngrxErrorInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: TnNgrxErrorInterceptorService,
  multi: true,
};

@NgModule({
  imports: [StoreModule.forFeature('ngrxError', ngrxErrorReducer), EffectsModule.forFeature([TnNgrxErrorEffects])],
})
export class TnNgrxErrorModule {}
