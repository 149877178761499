import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';


@Component({
  selector: 'warning-termination-agreement-sign',
  templateUrl: './warning-termination-agreement-sign.component.html',
  styleUrls: ['./warning-termination-agreement-sign.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WarningTerminationAgreementSignComponent {

  constructor(public dialog: MatDialogRef<WarningTerminationAgreementSignComponent>, @Inject(MAT_DIALOG_DATA) public data: {orderId: string, orderExtId: string}[]) {}
}
