import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TnToasterEffects } from './toaster.effects';
import { toasterReducer } from './toaster.reducers';

export const TOASTER_EFFECTS = [TnToasterEffects];

@NgModule({
  imports: [StoreModule.forFeature('toaster', toasterReducer), EffectsModule.forFeature(TOASTER_EFFECTS)],
  exports: [StoreModule, EffectsModule],
})
export class TnToasterModule {}
