export const helpLocalization = {
  info: {
    menuTitle: 'Помощь',
    userManual: {
      title: 'Как пользоваться электронно-транспортной платформой',
      subtitle: 'В руководстве пользователя мы за несколько минут познакомим вас с элементами раздела и расскажем о ключевых функциях',
      fileName: 'Руководство пользователя',
    },
    signManual: {
      title: 'Как работать с электронно–цифровой подписью',
      fileName: 'Руководство по работе с электронно–цифровой подписью',
    },
    footer: {
      question: 'Не нашли ответ на свой вопрос?',
      writeUs: 'Напишите нам',
      callUs: 'или позвоните',
    },
  },
  actions: {
    open: 'Открыть',
  },
};
