<transport-tw-input-field
  [class]="inputFieldClass"
  [control]="formControl"
  class="min-w-[134px]"
  [size]="size"
  [errorsSize]="errorsSize"
  [label]="label"
  [labelAddition]="labelAddition"
  [caption]="caption"
  [captionClasses]="captionClasses"
  [showErrors]="showErrors"
  [required]="label ? required : false"
  [errorToMessage]="errorToMessage"
>
  <transport-tw-input class="relative" [size]="size" [control]="formControl">
    <div class="input-container flex whitespace-nowrap overflow-hidden text-ellipsis">
      <input
        [formControl]="$any(formControl)"
        tw-input
        autocomplete="off"
        [required]="required"
        [placeholder]="placeholder"
        [matDatepicker]="picker"
        [attr.data-test]="dataTest"
        maxlength="10"
        [min]="minDate"
        (input)="onKeyUp($event)"
      />
    </div>
    <div class="absolute right-8 flex items-center justify-center w-5 h-5">
      <mat-icon
        *ngIf="allowClear && !isDisabled && !isEmpty"
        class="!w-[11px] !h-[11px] text-gray-400 cursor-pointer"
        svgIcon="close"
        (click)="clearSelect($event)"
      ></mat-icon>
    </div>
    <mat-icon
      [ngClass]="{'!cursor-default': isDisabled}"
      class="absolute right-2 !h-5 !w-5 cursor-pointer text-gray-400"
      svgIcon="calendar-today"
      (click)="picker.open()"
    ></mat-icon>
    <mat-datepicker #picker></mat-datepicker>
  </transport-tw-input>
</transport-tw-input-field>
