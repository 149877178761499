export interface INgxMaskObject {
  mask: string;
  patterns?: {
    [key: string]: {
      pattern: RegExp;
      symbol: string;
      optional?: boolean;
    };
  };
}

export interface INgxMasks {
  bankAcc: INgxMaskObject;
  corrAcc: INgxMaskObject;
  bankBic: INgxMaskObject;
  tin: {
    ru: {
      phys: INgxMaskObject;
      corp: INgxMaskObject;
    };
    any: {
      phys: INgxMaskObject;
    };
  };
  iec: {
    ru: INgxMaskObject;
    any: INgxMaskObject;
  };
  psrn: {
    ru: {
      ip: INgxMaskObject;
      corp: INgxMaskObject;
    };
    any: INgxMaskObject;
  };
  phone: {
    ruInput: INgxMaskObject;
    ru: INgxMaskObject;
    ruSpace: INgxMaskObject;
    byInput: INgxMaskObject;
  };
  iin: {
    kz: INgxMaskObject;
  };
  citizenPassport: {
    ru: {
      series: INgxMaskObject;
      number: INgxMaskObject;
    };
    any: INgxMaskObject;
  };
  driverLicence: {
    noRu: {
      series: INgxMaskObject;
    };
    default: {
      series: INgxMaskObject;
      number: INgxMaskObject;
    };
    ru: {
      series: INgxMaskObject;
      number: INgxMaskObject;
    };
    kz: {
      series: INgxMaskObject;
      number: INgxMaskObject;
    };
    kg: {
      series: INgxMaskObject;
      number: INgxMaskObject;
    };
    uz: {
      series: INgxMaskObject;
      number: INgxMaskObject;
    };
    tj: {
      series: INgxMaskObject;
      number: INgxMaskObject;
    };
    by: {
      series: INgxMaskObject;
      number: INgxMaskObject;
    };
  };
  vehiclePassport: {
    vin: INgxMaskObject;
    ru: {
      registrationNumber: INgxMaskObject;
      registrationCertificate: INgxMaskObject;
      trailerNumber: INgxMaskObject;
    };
    kz: {
      registrationNumber: INgxMaskObject;
      trailerNumber: INgxMaskObject;
    };
    uz: {
      registrationNumber: INgxMaskObject;
      trailerNumber: INgxMaskObject;
    };
    kg: {
      registrationNumber: INgxMaskObject;
      trailerNumber: INgxMaskObject;
    };
    tj: {
      registrationNumber: INgxMaskObject;
      trailerNumber: INgxMaskObject;
    };
    by: {
      registrationNumber: INgxMaskObject;
      trailerNumber: INgxMaskObject;
    };
    any: {
      registrationNumber: INgxMaskObject;
      registrationCertificate: INgxMaskObject;
      trailerNumber: INgxMaskObject;
    };
  };
  currency: {
    rur: INgxMaskObject;
  };
  email: INgxMaskObject;
  digest: INgxMaskObject;
  contractNumber: INgxMaskObject;
  temperature: INgxMaskObject;
  dimensions: INgxMaskObject;
}

export interface IRegExpObj {
  [key: string]: RegExp;
}

export interface IRegExpConfig {
  ngxMask: INgxMasks;
  phoneNumber: IRegExpObj;
  zipCode: IRegExpObj;
  inn: IRegExpObj;
  innPhysOrIp: IRegExpObj;
  innPhysOrLegal: IRegExpObj;
  ogrn: IRegExpObj;
  ogrnIp: IRegExpObj;
  kpp: IRegExpObj;
  email: IRegExpObj;
  fio: IRegExpObj;
  signer: IRegExpObj;
  domain: IRegExpObj;
  clearEmail: IRegExpObj;
  mobileUserAgent: IRegExpObj;
  coordinates: IRegExpObj;
}
