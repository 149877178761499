import { IUser } from '@transport/ui-interfaces';

export interface IUserForm {
  firstName: string | null;
  lastName: string | null;
  email: string | null;
  phoneNumber: string | null;
  permissions: string[] | null;
}

export interface IUserState {
  loading: boolean;
  isPageLoad: boolean;
  user: IUser | null;
  form: IUserForm | null;
  isFormDirty: boolean;
  isFormDisabled: boolean;
  isEmailEnabled: boolean;
  isPermissionsDisabled: boolean;
}
