<ng-container *ngIf="(currentView$ | async) as view">
  <ng-container *ngIf="view === popupView.bet" [ngTemplateOutlet]="bet"></ng-container>
  <ng-container *ngIf="view === popupView.counterOffer" [ngTemplateOutlet]="counterOffer"></ng-container>
</ng-container>

<ng-template #counterOffer>
  <div class="popup px-3">
    <div class="flex flex-row w-full mb-3 gap-[18px] items-center" (click)="auctionPopupService.currentView$.next(popupView.bet)">
      <img src="assets/icons/chevron_left.svg" class="w-2 h-4 object-cover cursor-pointer" />
      <h3 class="popup__header not-italic font-medium text-sm text-gray-700">
        {{ 'shared.mp.counterOffer.counterOffer' | translate }}
      </h3>
    </div>
    <div class="popup__main flex flex-col gap-2">
      <transport-tw-input-field
        errorsSize="xs"
        size="sm"
        [control]="counterOfferPriceControl"
        [errorToMessage]="priceControlErrorToMessage"
      >
        <div class="flex gap-1 items-center">
          <transport-tw-input size="sm" [rightAddon]="currencySign" [control]="counterOfferPriceControl">
            <input
              tw-input
              transportNumberOnly
              class="max-w-[125px] text-gray-700 text-end"
              thousandSeparator=" "
              autocomplete="off"
              [required]="true"
              [mask]="'separator.0'"
              [formControl]="counterOfferPriceControl"
            />
          </transport-tw-input>
          <mat-checkbox
            class="text-sm text-gray-700"
            *ngIf="!vatCriterionExists"
            [formControl]="counterOfferVatControl"
          >
            {{'shared.mp.publications.modal.form.price.withVat' | translate}}
          </mat-checkbox>
        </div>
      </transport-tw-input-field>
      <div *ngIf="popupContext.additionalAuctionCriteria?.length" class="flex flex-col justify-center">
        <additional-auction-criteria
          class="max-w-[220px]"
          [currency]="popupContext.currency"
          [arriveDatetimeTo]="popupContext.firstLoadingPlace.loadingDateTo ?? ''"
          [arriveDatetimeFrom]="popupContext.firstLoadingPlace.loadingDate ?? ''"
          [dateAdditionalCriteriaControl]="dateAdditionalCriteriaControl"
          [vatAdditionalCriteriaControl]="counterOfferVatControl"
          [additionalAuctionCriteria]="popupContext.additionalAuctionCriteria || []"
          [myOrgAdditionalCriteriaData]="popupContext.myOrgAdditionalCriteriaData"
        ></additional-auction-criteria>
        <div *ngIf="getOffer as offer" class="place-step-bet__row" [attr.data-test]="'place-step-bet-equivalent'">
          Эквивалент {{offer | currencyMulti: popupContext.currency: false}}
        </div>
        <div *ngIf="(popupContext.lastBetEquivalent || popupContext.startPrice) as equivalent " class="place-step-bet__row text-sm text-gray-500" [attr.data-test]="'place-step-bet-current-equivalent'">
          Текущий эквивалент {{equivalent | currencyMulti: popupContext.currency: false}}
        </div>
      </div>
    </div>
    <common-textarea
      [control]="$any(counterOfferComment)!"
      [placeholder]="'shared.counterOfferComment' | translate"
      [errorToMessage]="validationErrors.bind(this)"
      [dataTest]="'counter-offer-modal-comment'"
      [rows]="3"
      size="sm"
    >
    </common-textarea>
    <button class="tw-button-solid-sm w-full mt-2" [disabled]="!(counterOfferPriceControl.valid && counterOfferComment.valid) || (loading | async)" (click)="sendCounterOffer()">
      {{ 'shared.mp.verification.buttons.send' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #bet>
  <ng-container *ngIf="!(popupContext.expired$ | async); else auctionInactive">
    <transport-place-step-bet
      [currency]="popupContext.currency"
      [tradingParticipantsCount]="0"
      [isSmallView]="true"
      [minStepPrice]="popupContext.lotStep || 0"
      [isVatIncluded]="popupContext.isVat"
      [isLastBetByMyOrganization]="popupContext.isLastBetMine"
      [startPrice]="popupContext.startPrice || 0"
      [lastBet]="lastBet"
      [betPrice]="currentPrice"
      [additionalAuctionCriteria]="popupContext.additionalAuctionCriteria || []"
      [myOrgAdditionalCriteriaData]="popupContext.myOrgAdditionalCriteriaData"
      [arriveDatetimeTo]="popupContext.firstLoadingPlace.loadingDateTo ?? ''"
      [arriveDatetimeFrom]="popupContext.firstLoadingPlace.loadingDate ?? ''"
      [lastBetEquivalent]="popupContext.lastBetEquivalent"
      [commentControl]="placeStepBetComment"
      (placeBet)="placeBet($event)"
    >
    </transport-place-step-bet>
  </ng-container>
  <ng-template #auctionInactive>
    <p class="bet__price w-full mb-2 not-italic text-center font-medium text-md text-gray-700">
      {{isCancelled
        ? ('shared.mp.cargo.table.popup.auction.auctionCancelled' | translate)
        : ('shared.mp.cargo.table.popup.auction.auctionFinished' | translate)}}
    </p>
  </ng-template>
  <div *ngIf="popupContext.allowCounterOffer && (popupContext.startPrice === currentPrice)" class="h-[1px] w-full bg-gray-300 mt-3">&nbsp;</div>

  <button
    *ngIf="popupContext.allowCounterOffer && (popupContext.startPrice === currentPrice)"
    (click)="auctionPopupService.currentView$.next(popupView.counterOffer)"
    class="tw-button-outline-sm mt-3 mx-2"
  >
    {{ 'shared.mp.counterOfferPopup.sendCounterOffer' | translate }}
  </button>
</ng-template>

<ng-template #placeBetSuccess let-input>
  <transport-alert [title]="input.title" titleVariant="top" state="success"> </transport-alert>
</ng-template>
<ng-template #placeBetError>
  <transport-alert [title]="'shared.mp.cargo.table.popup.auction.betError' | translate" titleVariant="top" state="error">
    {{'shared.mp.cargo.table.popup.auction.betErrorTooLow' | translate}}
  </transport-alert>
</ng-template>
