import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { routerReducer } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { TnRouterEffects } from './effects/router.effects';
import { initialRouterState, routerFeatureKey } from './interfaces/router.interfaces';

@NgModule({
  imports: [
    StoreModule.forFeature(routerFeatureKey, routerReducer, { initialState: initialRouterState }),
    EffectsModule.forFeature([TnRouterEffects]),
  ],
})
export class TnNgrxRouterModule {}
