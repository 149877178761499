import { gql } from 'apollo-angular';

import * as sharedFragments from '../shared/graphql-fragments-shared';
import { ORDER_FRAGMENT, ORGANIZATION_CARRIER_PROFILE_FRAGMENT } from './graphql-fragments-carrier';
import { CARRIER_DIRECTORY_MUTATION } from './graphql-mutations-carrier-directory';

/**
 * GraphQL carrier queries
 */
export const CARRIER_MUTATION = {
  ...CARRIER_DIRECTORY_MUTATION,

  acceptOrder: gql`
    mutation acceptOrder($orderId: ID!) {
      bookOrder(orderId: $orderId) {
        ${ORDER_FRAGMENT}
      }
    }
  `,

  letsGo: gql`
    mutation letsGo($orderId: ID!) {
      letsGo(orderId: $orderId) {
        ${ORDER_FRAGMENT}
      }
    }
  `,

  addOrderCounterOffer: gql`
    mutation addOrderCounterOffer($input: OrderCounterOfferInput!, $acceptingPlace: OrderAcceptingPlaceEnum) {
      addOrderCounterOffer(input: $input, acceptingPlace: $acceptingPlace) {
        counterOfferId
        orderId
        cargoOwnerInfo
        price{
          amount
          currency
          vatIncluded
          vat
        }
        additionalCriteriaData{
          type
          value
        }
        counterOfferEquivalent
      }
    }
  `,

  completeOrder: gql`
    mutation completeOrder($orderId: ID!) {
      completeOrder(orderId: $orderId) {
        ${ORDER_FRAGMENT}
      }
    }
  `,

  cancelOrder: gql`
    mutation cancelOrder($orderId: ID!) {
      cancelOrder(orderId: $orderId) {
        ${ORDER_FRAGMENT}
      }
    }
  `,

  cancelOrderTn: gql`
    mutation signTerminationAgreementTnCrypto($orderId: ID!) {
      signTerminationAgreementTnCrypto(orderId: $orderId) {
        ${ORDER_FRAGMENT.replace('documentType: CONTRACT', 'documentType: TERMINATION_AGREEMENT')}
      }
    }
  `,

  /**
   * unreserve transport
   */
  unreserveTransport: gql`
    mutation unreserveTransport($orderId: ID!) {
      unreserveTransport(orderId: $orderId) {
        ${ORDER_FRAGMENT}
      }
    }
  `,

  reserveTransportV2WithAgent: gql`
    mutation reserveTransportV2($driverId: ID!, $coDriverId: ID!, $orderId: ID!, $vehicleId: ID!, $carrierContractId: ID!, $agentCarrierId: ID!, $freightAmount: Float) {
      reserveTransportV2(driverId: $driverId, coDriverId: $coDriverId, orderId: $orderId, vehicleId: $vehicleId, carrierContractId: $carrierContractId, agentCarrierId: $agentCarrierId, freightAmount: $freightAmount) {
        ${ORDER_FRAGMENT}
      }
    }
  `,

  reserveTransportV2: gql`
    mutation reserveTransportV2($driverId: ID!, $coDriverId: ID!, $orderId: ID!, $vehicleId: ID!, $carrierContractId: ID, $agentCarrierInfo: AgentCarrierInfoInput) {
      reserveTransportV2(driverId: $driverId, coDriverId: $coDriverId, orderId: $orderId, vehicleId: $vehicleId, carrierContractId: $carrierContractId, agentCarrierInfo: $agentCarrierInfo) {
        ${ORDER_FRAGMENT}
      }
    }
  `,

  /**
   * lot mutation.
   */
  placeBet: gql`
    mutation placeBet($bet: Int!, $orderId: ID!) {
      placeBet(bet: $bet, orderId: $orderId) {
        isLastBetMine
        order {
          id
          status
        }
        startPrice
        minStepPrice
        lastBet
      }
    }
  `,

  placeBiddingBet: gql`
    mutation placeBiddingBet($bet: Int!, $orderId: ID!) {
      placeBiddingBet(bet: $bet, orderId: $orderId) {
        order {
          myOrganizationBet
        }
        startPrice
        minStepPrice
        viewEndDatetime
        biddingStatus
      }
    }
  `,

  addInsurance: gql`
    mutation addInsuranceOrder($input: AddInsuranceOrderInput!) {
      addInsuranceOrder(input: $input) {
        id
        insurance {
          data
        }
      }
    }
  `,

  addDocumentForOrganization: gql`
    mutation addOrganizationUploadedDocument($documentTypeId: ID!, $uploadedFileId: ID!) {
      addOrganizationUploadedDocument(documentTypeId: $documentTypeId, uploadedFileId: $uploadedFileId) {
        id
        documentType {
          id
          name
          innerName
        }
        uploadedFile {
          id
          name
          createdAt
        }
      }
    }
  `,

  removeOrganizationDocument: gql`
    mutation removeOrganizationUploadedDocument($docId: ID!) {
      removeOrganizationUploadedDocument(docId: $docId) {
        id
        uploadedFile {
          id
        }
      }
    }
  `,

  inviteDriver: gql`
    mutation inviteDriverToMobile($driverId: ID!) {
      inviteDriverToMobile(driverId: $driverId)
    }
  `,

  addAgentCarrier: gql`
    mutation addAgentCarrier($input: AddAgentCarrierInput) {
      addAgentCarrier(input: $input) {
        id
      }
    }
  `,

  editAgentCarrier: gql`
    mutation editAgentCarrier($input: EditAgentCarrierInput) {
      editAgentCarrier(input: $input) {
        id
      }
    }
  `,

  sendAgentCarrierToArchive: gql`
    mutation sendAgentCarrierToArchive($id: ID!) {
      sendAgentCarrierToArchive(id: $id) {
        id
      }
    }
  `,

  setCoDriver: gql`
    mutation setCoDriver($coDriverId: ID, $orderId: ID!) {
      setCoDriver(coDriverId: $coDriverId, orderId: $orderId) {
        id
        coDriver {
          ${sharedFragments.ORDER_FRAGMENT_DRIVER}
          blackListInfo(orderId: $orderId) {
            id
            reason
          }
        }
      }
    }
  `,

  addCompletedOrdersReport: gql`
    mutation addCompletedOrdersReport($input: AddCompletedOrdersReportInput) {
      addCompletedOrdersReport(input: $input) {
        id
        status
        notes
      }
    }
  `,

  editCompletedOrdersReport: gql`
    mutation editCompletedOrdersReport($input: EditCompletedOrdersReportInput) {
      editCompletedOrdersReport(input: $input) {
        id
        status
        notes
      }
    }
  `,

  addBidOnTender: gql`
    mutation addBidOnTender($input: AddBidOnTenderInput) {
      addBidOnTender(input: $input) {
        id
        lotNumber
        bidsEndDatetime
        loadingSettlement
        department
        course
        unloadingPlace
        activeDateFrom
        activeDateTo
        cargoWeightFrom
        cargoWeightTo
        ordersQuantity
        maxOrderPrice {
          amount
          currency
        }
        winnerPriceWithVat {
          amount
          currency
        }
        maxOrderPriceWithVat {
          amount
          currency
        }
        winnerPrice {
          amount
          currency
        }
        displayedStatus
        lastBidPrice {
          amount
          currency
        }
        vehicleRequirements {
          bodyType
          liftingCapacity
          volume
          length
          width
          height
          mustHaveManipulator
        }
        limitRate
        stepPrice
        isTn
        lastBid {
          id
          comment
          price {
            amount
            currency
          }
          priceWithVat {
            amount
            currency
          }
        }
      }
    }
  `,

  saveOrganizationProfile: gql`
    mutation editOrganization($input: EditOrganizationInput) {
      editOrganization(input: $input) {
        ${ORGANIZATION_CARRIER_PROFILE_FRAGMENT}
      }
    }
  `,
};
