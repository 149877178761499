import { Injectable } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { ITnState } from '../state/index';
import * as actions from './dadata.actions';
import * as selectors from './dadata.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnDadataFacade {
  public readonly dadataSuggestions$ = this.store.pipe(select(selectors.dadataSuggestions));

  public readonly dadataCitySuggestions$ = this.store.pipe(select(selectors.dadataCitySuggestions));

  public readonly dadataData$ = this.store.pipe(select(selectors.dadataData));

  public readonly getMapPointByAddressSuccess$ = this.action$.pipe(ofType(actions.getMapPointByAddressSuccess));

  constructor(private readonly store: Store<ITnState>, private readonly action$: Actions) {}

  public getMapPointsByAddress(payload: actions.IMapPointsByAddressPayload) {
    this.store.dispatch(actions.getMapPointsByAddress({ payload }));
  }

  public getCityByAddress(payload: actions.IMapPointsByAddressPayload) {
    this.store.dispatch(actions.getCityByAddress({ payload }));
  }

  public getMapPointByAddress(payload: { address: string; country?: string }) {
    this.store.dispatch(actions.getMapPointByAddress(payload));
  }

  public getMapPointsByCoordinates(payload: actions.IMapPointsByCoordinatesPayload) {
    this.store.dispatch(actions.getMapPointsByCoordinates({ payload }));
  }

  public clearSuggestions() {
    this.store.dispatch(actions.setDadataData({ payload: { suggestions: [] } }));
  }
}
