<h2>{{ 'shared.auth.registration.form.' + (inviteToken ? 'activationTitle' : 'title') | translate }}</h2>
<form *ngIf="registrationForm" [formGroup]="registrationForm">
  <ng-container *ngIf="!confirm">
    <mat-radio-group *ngIf="!inviteToken" class="radio-switch" aria-label="Select an option" [formControl]="$any(isRoleControl)">
      <mat-radio-button class="text-sm font-normal" value="CARRIER">Грузоперевозчик</mat-radio-button>
      <mat-radio-button class="text-sm font-normal" value="CARGO_OWNER">Грузовладелец</mat-radio-button>
    </mat-radio-group>

    <common-input
      [formGroup]="registrationForm"
      size="md"
      class="w-full"
      inputFieldClass="mb-2"
      errorsSize="xs"
      placeholder="{{ 'shared.auth.registration.form.companyName.placeholder' | translate }}"
      label="{{ 'shared.auth.registration.form.companyName.label' | translate }}"
      controlName="companyName"
      [errorToMessage]="companyValidationErrors.bind(this).bind(this)"
      dataTest="registration-org"
    ><mat-icon>work</mat-icon></common-input>

    <common-select
      size="md"
      class="w-full"
      inputFieldClass="mb-2"
      errorsSize="xs"
      label="{{ 'shared.profile.user.info.countryField' | translate }}"
      [formGroup]="registrationForm"
      [dataSource]="countries"
      controlName="country"
      [allowClear]="false"
      [errorToMessage]="countryValidationErrors.bind(this)"
      dataTest="user-select-country"
    ></common-select>

    <common-input
      [formGroup]="registrationForm"
      size="md"
      class="w-full"
      inputFieldClass="mb-2"
      errorsSize="xs"
      placeholder="{{ 'shared.auth.registration.form.fio.placeholder' | translate }}"
      label="{{ 'shared.auth.registration.form.fio.label' | translate }}"
      controlName="fullName"
      [errorToMessage]="fioValidationErrors.bind(this).bind(this)"
      dataTest="registration-name"
    ><mat-icon>person</mat-icon></common-input>

    <common-phone-input
      [formGroup]="registrationForm"
      size="md"
      class="w-full"
      inputFieldClass="w-full"
      label="{{ 'shared.auth.registration.form.phone.label' | translate }}"
      controlName="phoneNumber"
      [required]="true"
      [errorToMessage]="phoneValidationErrors.bind(this).bind(this)"
      dataTest="registration-phone"
    ></common-phone-input>

    <common-input
      [formGroup]="registrationForm"
      size="md"
      class="w-full"
      inputFieldClass="mb-2"
      errorsSize="xs"
      autocomplete="email"
      label="{{ 'shared.auth.registration.form.email.label' | translate }}"
      placeholder="{{ 'shared.auth.registration.form.email.placeholder' | translate }}"
      controlName="email"
      [errorToMessage]="emailValidationErrors.bind(this).bind(this)"
      dataTest="registration-email"
    ><mat-icon>mail</mat-icon></common-input>

    <button data-test="registration-button" (click)="submit($event)" class="tw-button-solid-md mb-2 mr-0" [disabled]="!registrationForm.valid">
      {{'shared.auth.registration.form.continue' | translate}}
      <mat-spinner *ngIf="isLoading" diameter="18"></mat-spinner>
    </button>

    <p class="sign-in">
      {{ 'shared.auth.registration.form.loginText' | translate }}&nbsp;
      <a class="sign-in__link" (click)="saveInviteToken()" [routerLink]="['/passport/login']"> {{ 'shared.auth.registration.form.loginLinkText' | translate }}</a>
    </p>
  </ng-container>

  <ng-container *ngIf="confirm">
    <div class="text-gray-500 text-md font-bold mb-3 text-center">{{ 'shared.reg.confirmEmail' | translate }}</div>
    <div class="text-gray-700 text-sm">{{ 'shared.reg.sendEmailSuccess' | translate : {value: emailControl?.value} }}</div>
    <button (click)="resendEmail()" class="tw-button-solid-md w-full mt-3">
      {{'shared.passport.login.sendMailAgain'  | translate}}
      <mat-spinner *ngIf="isLoading" diameter="18"></mat-spinner>
    </button>
  </ng-container>
</form>

<p *ngIf="!confirm" class="licenseText">
  {{ 'shared.auth.licenseText.text' | translate }}
  <a class="licenseText__link" target="_blank" [href]="licenseAgreementURL">{{ 'shared.auth.licenseText.link' | translate }}</a>
</p>
