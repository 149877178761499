import { createReducer, on } from '@ngrx/store';

import * as PassportActions from './passport.actions';
import { IPassportAccessRecovery, IPassportState, PASSPORT_PATH } from './passport.state';

export const passportInitialState: IPassportState = {
  title: '',
  color: 'primary',
  pathsRestriction: {
    index: true,
    login: false,
    signup: false,
  },
  displayConfig: false,
  currentPath: '',
  redirectUrlAfterLogin: '',
  navigation: {
    step: 1,
    isForwardOnly: true,
  },
};

const getInitialNavigation = (state: IPassportState) => {
  const step = 1;
  switch (state.currentPath) {
    case PASSPORT_PATH.LOGIN:
    case PASSPORT_PATH.SIGNUP:
    case PASSPORT_PATH.FORGOT_PASSWORD:
      return { step, isForwardOnly: false };
    default:
      return { step, isForwardOnly: true };
  }
};

const reducer = createReducer(
  passportInitialState,
  on(PassportActions.setPassportUiState, (state, { payload }) => {
    const newState = payload;
    return newState;
  }),

  on(PassportActions.setPassportRoute, (state, { route }) => {
    return {
      ...state,
      currentPath: route,
      navigation: getInitialNavigation(state),
    };
  }),

  on(PassportActions.setAccessRecovery, (state, { token, isExpired, email }) => {
    const titleTranslationToken = state.currentPath === PASSPORT_PATH.REGISTRATION ? 'registration' : 'passwordReset';
    return {
      ...state,
      accessRecovery: {
        token,
        isExpired,
        email,
      },
      title: isExpired ? '' : `shared.passport.${titleTranslationToken}.title`,
    };
  }),

  on(PassportActions.setConfirmEmailData, (state, { token, email, newEmail, isExpired }) => {
    return {
      ...state,
      confirmEmailData: {
        token,
        email,
        newEmail,
        isExpired,
      },
      title: isExpired ? 'shared.passport.confirmEmail.tokenExpires' : '',
    };
  }),

  on(PassportActions.goForward, state => {
    return {
      ...state,
      navigation: {
        ...state.navigation,
        step: state.navigation.step + 1,
      },
    };
  }),

  on(PassportActions.goBack, state => {
    return {
      ...state,
      navigation: {
        ...state.navigation,
        step: state.navigation.step > 1 ? state.navigation.step - 1 : 1,
      },
    };
  }),

  on(PassportActions.setTitle, (state, { title }) => {
    return {
      ...state,
      title,
    };
  }),

  on(PassportActions.setRegistrationTokenExpired, state => {
    return {
      ...state,
      accessRecovery: {
        ...(state?.accessRecovery as IPassportAccessRecovery),
        isExpired: true,
      },
    };
  }),

  on(PassportActions.setPasswordForgot, state => {
    return {
      ...state,
      isPasswordForgot: true,
      title: 'shared.passport.passwordReset.passwordForgot.title',
      navigation: {
        ...state.navigation,
        step: 2,
      },
    };
  }),

  on(PassportActions.resetPasswordForgot, state => {
    return {
      ...state,
      isPasswordForgot: false,
      title: 'shared.passport.login.title',
    };
  }),
);

export function passportReducers(state, action) {
  return reducer(state, action);
}
