import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { IFiasAddressDto } from '@transport/ui-interfaces';

import { ITnState } from '../state';
import { getFiasByCode, getFiasData, setAddressFromFias, setFiasData } from './fias.actions';
import { fiasData } from './fias.selectors';
import { IFiasData } from './fias.state';

@Injectable({
  providedIn: 'root',
})
export class TnFiasFacade {
  public fiasData$ = this.store.select(fiasData);

  constructor(private readonly store: Store<ITnState>) {}

  public dispatchFiasAddressByCode(codes: string[], scope: string): void {
    this.store.dispatch(getFiasByCode({ codes, scope }));
  }

  public setFiasData(data: IFiasData, scope: string) {
    this.store.dispatch(setFiasData({ payload: data, scope }));
  }

  public getFiasData(input: string, minInputLength: number, scope: string) {
    this.store.dispatch(getFiasData({ payload: { input, minInputLength, scope } }));
  }

  public clearFiasFromState(scope: string, addresses?: IFiasAddressDto[]) {
    const address = {
      addresses: addresses ?? [],
    } as IFiasData;
    this.store.dispatch(setAddressFromFias({ address, scope }));
  }
}
