import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { OWNERSHIP_TYPES, VEHICLE_TYPES } from 'libs/marketplace-shared/src/lib/edit-vehicle/form/vehicle-form';
import { map } from 'rxjs/operators';
import { regExpConfig } from '@transport/ui-utils';
import { VEHICLE_TYPE_OWNERSHIP } from '@transport/ui-interfaces';
import { VehicleListFilter, VehiclesTableService } from '../../table/vehicles-table.service';

@UntilDestroy()
@Component({
  templateUrl: 'vehicles-filter-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehiclesFilterModalComponent implements OnInit {
  public form = new FormGroup({
    vehicleType: new FormControl(),
    ownership: new FormControl(),
    isThirdParty: new FormControl(),
    regNo: new FormControl(),
    vehicleBrand: new FormControl(),
    trailerRegNo: new FormControl(),
    bodyType: new FormControl(),
    capacity: new FormControl(),
    volume: new FormControl(),
    loadingType: new FormControl(),
  });

  public vehicleTypes = VEHICLE_TYPES.map(type => ({
    id: type.type,
    label: this.translate.instant(type.name),
  }));

  public ownershipTypes = OWNERSHIP_TYPES.map(type => ({
    id: type.type,
    label: this.translate.instant(type.name),
  })).map(type => {
    if (type.id === VEHICLE_TYPE_OWNERSHIP.OWN) {
      return {
        id: 'OWNED',
        label: type.label,
      };
    }
    return type;
  });

  public loadingTypes = [
    { id: '1', label: this.translate.instant('shared.mp.vehicles.vehicle.form.side') },
    { id: '2', label: this.translate.instant('shared.mp.vehicles.vehicle.form.back') },
    { id: '3', label: this.translate.instant('shared.mp.vehicles.vehicle.form.up') },
  ];

  public bodySubtypes$ = this.vehicleTableService.bodySubtypes$.pipe(
    map(types => types.map(type => ({ id: type.id!, label: type.name! }))),
  );

  public vehicleMakes$ = this.vehicleTableService.vehicleMakes$.pipe(map(types => types.map(type => ({ id: type, label: type }))));

  public regExpConf = regExpConfig;

  constructor(
    public dialog: MatDialogRef<VehiclesFilterModalComponent>,
    public translate: TranslateService,
    public vehicleTableService: VehiclesTableService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit() {
    this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      Object.values(value).some(v => !!v) && this.form.markAsDirty();
    });
    this.vehicleTableService.store.filter$.pipe(untilDestroyed(this)).subscribe((value: VehicleListFilter) => {
      this.form.patchValue(value);
      this.cdr.markForCheck();
    });

    this.dialog
      .afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(() => this.onApply());
  }

  public validationErrors(e: ValidationErrors | null) {
    if (e?.mask) return this.translate.instant('shared.errors.carNumber');
    return '';
  }

  onApply() {
    const value = this.form.getRawValue();
    this.dialog.close(value);
  }

  onClear() {
    this.form.reset();
    this.dialog.close(this.form.getRawValue());
  }
}
