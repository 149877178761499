import { AfterViewInit, Directive, ElementRef, HostListener, Input, OnDestroy, OnInit, Renderer2 } from '@angular/core';

/**
 * Directive set width of input equeal as string length
 */
@Directive({
  selector: '[transportElasticInput]',
})
export class TnElasticInputDirective implements OnInit, AfterViewInit, OnDestroy {
  constructor(private readonly renderer: Renderer2, private readonly elementRef: ElementRef) {}

  public textDiv?: HTMLDivElement;

  public currentWidth;

  @Input() public maxWidth = 0;

  @HostListener('input', ['$event']) public onInput(event) {
    this.resizeInput();
  }

  @HostListener('paste', ['$event']) public onPaste(event) {
    this.resizeInput();
  }

  @HostListener('cut', ['$event']) public onCut(event) {
    this.resizeInput();
  }

  public ngOnInit() {
    this.currentWidth = (this.elementRef.nativeElement as HTMLElement).offsetWidth;
    this.textDiv = this.renderer.createElement('div');
    const text = this.renderer.createText('two');

    this.renderer.setStyle(this.textDiv, 'visibility', 'hidden');
    this.renderer.setStyle(this.textDiv, 'white-space', 'pre');
    this.renderer.setStyle(this.textDiv, 'position', 'absolute');

    this.renderer.appendChild(this.textDiv, text);
    this.renderer.appendChild(this.elementRef.nativeElement, this.textDiv);
    this.renderer.appendChild((this.elementRef.nativeElement as HTMLElement).parentNode, this.textDiv);
  }

  public ngAfterViewInit() {
    setTimeout(() => {
      this.resizeInput();
    });
  }

  public ngOnDestroy() {
    this.renderer.removeChild((this.elementRef.nativeElement as HTMLElement).parentNode, this.textDiv);
  }

  public resizeInput() {
    this.renderer.setProperty(this.textDiv, 'innerHTML', (this.elementRef.nativeElement as HTMLElement & { value }).value);
    if (typeof this.textDiv !== 'undefined' && this.textDiv.offsetWidth > this.currentWidth) {
      this.renderer.setStyle(
        this.elementRef.nativeElement,
        'width',
        `${this.textDiv.offsetWidth < this.maxWidth ? this.textDiv.offsetWidth : this.maxWidth}px`,
      );
    }
  }
}
