import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { DOCUMENT_TYPE, orderAttachmentFromDto } from '@transport/ui-interfaces';
import { of } from 'rxjs';
import { catchError, concatMap, filter, map, mapTo, switchMap, tap, withLatestFrom } from 'rxjs/operators';

import { TnConfirmationService } from '../../confirmation/confirmation.service';
import { TnFileUploadService } from '../../file-upload/file-upload.service';
import { ITnState } from '../../state/index';
import { TnToasterFacade, TOAST_TYPE } from '../../toaster/toaster.facade';
import * as OrderDetailsCarrierActions from '../actions/order-details-carrier.actions';
import * as OrderDocumentsCarrierActions from '../actions/order-documents-carrier.actions';
import * as selectors from '../selectors/order-details-carrier.selectors';
import { TnCarrierOrdersService } from '../services/carrier/carrier-order.service';

@Injectable({
  providedIn: 'root',
})
export class TnOrderDetailsCarrierDocsEffects {
  public operationFail = createEffect(() =>
    this.action$.pipe(
      ofType(
        OrderDocumentsCarrierActions.loadAllDocumentTypesFailure,
        OrderDocumentsCarrierActions.removeDocumentFailure,
        OrderDocumentsCarrierActions.addDocumentsFailure,
        OrderDocumentsCarrierActions.addSignToDocumentFailure,
        OrderDocumentsCarrierActions.uploadContractFailure,
        OrderDocumentsCarrierActions.loadDocumentsFailure,
      ),
      tap(({ error }) => {
        //retrow error for handling by global error handler
        throw error;
      }),
    ),
  );

  public loadAllDocumentTypes = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDocumentsCarrierActions.loadAllDocumentTypesStart),
      switchMap(() =>
        this.carrierOrdersService.getOrderDocumentTypes().pipe(
          map(result =>
            OrderDocumentsCarrierActions.loadAllDocumentTypesSuccess({
              documentTypes: result,
            }),
          ),
          catchError(error => {
            return of(OrderDocumentsCarrierActions.loadAllDocumentTypesFailure({ error }));
          }),
        ),
      ),
    ),
  );

  public removeDocument = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDocumentsCarrierActions.removeDocumentStart),
      withLatestFrom(this.store.pipe(select(selectors.orderDetailsCarrierStateSelector))),
      concatMap(([{ documentId, orderId }, state]) => {
        const document = state.displayedDocuments.find(item => item.id === documentId);
        return this.confirmation.openByPrefix('carrier.order.confirm.removeDocument').pipe(
          filter(result => Boolean(result)),
          mapTo({ documentId, orderId, document }),
        );
      }),
      concatMap(({ documentId, orderId, document }) =>
        this.carrierOrdersService.removeDocument(documentId, orderId).pipe(
          map(() => OrderDocumentsCarrierActions.removeDocumentSuccess({ documentId, document, orderId })),
          catchError(error => {
            return of(OrderDocumentsCarrierActions.removeDocumentFailure({ error }));
          }),
        ),
      ),
    ),
  );

  public addDocuments = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDocumentsCarrierActions.addDocumentsStart),
      concatMap(({ documents, orderId }) => {
        if (documents[0].documentType.innerName === DOCUMENT_TYPE.WAYBILL) {
          return of(OrderDocumentsCarrierActions.addWaybillStart({ documents, orderId }));
        }

        return this.carrierOrdersService.attachDocuments(documents, orderId ?? '').pipe(
          map(result =>
            OrderDocumentsCarrierActions.addDocumentsSuccess({
              documents: result.map(orderAttachmentFromDto),
              orderId: orderId ?? '',
            }),
          ),
          catchError(error => {
            return of(OrderDocumentsCarrierActions.addDocumentsFailure({ error }));
          }),
        );
      }),
    ),
  );

  public addWaybill = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDocumentsCarrierActions.addWaybillStart),
      concatMap(({ documents, orderId }) => {
        return this.carrierOrdersService.addWaybill(documents, orderId ?? '').pipe(
          map(result => {
            this.toastFacade.showMessage('carrier.order.action.addWaybill.success', TOAST_TYPE.SUCCESS);
            return OrderDetailsCarrierActions.refreshOrderStart({ orderId });
          }),
          catchError(error => {
            return of(OrderDocumentsCarrierActions.addDocumentsFailure({ error }));
          }),
        );
      }),
    ),
  );

  public editWaybill = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDocumentsCarrierActions.editWaybillStart),
      concatMap(({ wayBill, orderId }) => {
        return this.carrierOrdersService.editWaybill(wayBill, orderId ?? '').pipe(
          this.toastFacade.notificationHandler('carrier.order.action.editWaybill.error', 'carrier.order.action.editWaybill.success'),
          map(result => OrderDetailsCarrierActions.refreshOrderStart({ orderId })),
          catchError(error => {
            return of(OrderDocumentsCarrierActions.addDocumentsFailure({ error }));
          }),
        );
      }),
    ),
  );

  // Если добавили контракт, то обновляем данные по заявке
  public addContract = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDocumentsCarrierActions.addDocumentsSuccess),
      filter(({ documents }) => {
        const isContractAttached = documents.filter(item => item.documentType?.innerName === DOCUMENT_TYPE.CONTRACT).length > 0;
        return isContractAttached;
      }),
      map(({ orderId }) => {
        return OrderDetailsCarrierActions.refreshOrderStart({ orderId });
      }),
    ),
  );

  // Если удалили контракт или Траснпортную накладную, то обновляем данные по заявке
  public refreshOrderAfterDocumentsRemovement = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDocumentsCarrierActions.removeDocumentSuccess),
      filter(({ document }) => {
        const isRefreshNeed =
          document?.documentType?.innerName === DOCUMENT_TYPE.CONTRACT || document?.documentType?.innerName === DOCUMENT_TYPE.WAYBILL;
        return isRefreshNeed;
      }),
      map(({ orderId }) => {
        return OrderDetailsCarrierActions.refreshOrderStart({ orderId });
      }),
    ),
  );

  public uploadSign = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDocumentsCarrierActions.uploadSignStart),
      switchMap(({ sign, signedFileId }) => {
        return this.fileUploadService.upload(sign, true).pipe(
          switchMap(result => {
            return of({ sign: result, signedFileId });
          }),
        );
      }),
      withLatestFrom(this.store.pipe(select(selectors.orderDetailsCarrierStateSelector))),
      switchMap(([{ sign, signedFileId }, state]) => {
        if (!Boolean(state.signDocumentType?.id)) {
          return this.carrierOrdersService.getOrderDocumentTypes().pipe(
            switchMap(result => [
              OrderDocumentsCarrierActions.loadAllDocumentTypesSuccess({
                documentTypes: result,
              }),
              OrderDocumentsCarrierActions.uploadSignSuccess({
                attach: {
                  uploadedFile: sign,
                  documentType: result.signType,
                  signedFileId,
                  waybillInfo: null,
                },
              }),
            ]),
          );
        }
        return [
          OrderDocumentsCarrierActions.uploadSignSuccess({
            attach: {
              uploadedFile: sign,
              documentType: state.signDocumentType,
              waybillInfo: null,
              signedFileId,
            },
          }),
        ];
      }),
    ),
  );

  public addSignToDocument = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDocumentsCarrierActions.uploadSignSuccess),
      withLatestFrom(this.store.pipe(select(selectors.orderDetailsCarrierStateSelector))),
      switchMap(([{ attach }, state]) => {
        return this.carrierOrdersService.attachDocuments([attach], state.order?.id ?? '').pipe(
          map(result =>
            OrderDocumentsCarrierActions.addSignToDocumentSuccess({
              sign: orderAttachmentFromDto(result[0]),
            }),
          ),
          catchError(error => {
            return of(OrderDocumentsCarrierActions.addSignToDocumentFailure({ error }));
          }),
        );
      }),
    ),
  );

  public uploadContract = createEffect(() =>
    this.action$.pipe(
      ofType(OrderDocumentsCarrierActions.uploadContractStart),
      switchMap(({ contract }) => this.fileUploadService.upload(contract, true)),
      withLatestFrom(this.store.pipe(select(selectors.orderDetailsCarrierStateSelector))),
      switchMap(([res, state]) => {
        if (!Boolean(state.contractDocumentType?.id)) {
          return this.carrierOrdersService.getOrderDocumentTypes().pipe(
            switchMap(result => [
              OrderDocumentsCarrierActions.loadAllDocumentTypesSuccess({
                documentTypes: result,
              }),
              OrderDocumentsCarrierActions.addDocumentsStart({
                documents: [{ documentType: result.contractType, uploadedFile: res, waybillInfo: null }],
                orderId: state.order?.id ?? '',
              }),
            ]),
          );
        }
        return [
          OrderDocumentsCarrierActions.addDocumentsStart({
            documents: [{ documentType: state.contractDocumentType, uploadedFile: res, waybillInfo: null }],
            orderId: state.order?.id ?? '',
          }),
        ];
      }),
      catchError(error => {
        return of(OrderDocumentsCarrierActions.uploadContractFailure({ error }));
      }),
    ),
  );

  public documentsLoad = createEffect(() =>
    this.action$.pipe(
      ofType(
        OrderDetailsCarrierActions.loadOrderSuccess,
        OrderDetailsCarrierActions.updateOrderSuccess,
        OrderDetailsCarrierActions.saveInfoForContractSuccess,
        OrderDetailsCarrierActions.setOrder,
      ),
      map(({ order }) => {
        const documents =
          order.uploadedDocuments?.map(item => {
            return orderAttachmentFromDto(item);
          }) ?? [];
        return OrderDocumentsCarrierActions.loadDocumentsSuccess({ documents });
      }),
      catchError(error => {
        return of(OrderDocumentsCarrierActions.loadDocumentsFailure({ error }));
      }),
    ),
  );

  constructor(
    private readonly carrierOrdersService: TnCarrierOrdersService,
    private readonly action$: Actions,
    private readonly confirmation: TnConfirmationService,
    private readonly store: Store<ITnState>,
    private readonly fileUploadService: TnFileUploadService,
    private readonly toastFacade: TnToasterFacade,
  ) {}
}
