import { Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { GRAPHQL_QUERY } from '@transport/gql';
import { transport } from '@transport/proto';
import {
  BIDDING_TYPE,
  ICargoPackagingType,
  ICargoType,
  ICargoTypesFilter,
  ICarrier,
  ICarrierOrganization,
  IDefaultProfileParams,
  IGqlAllOwnerSlaResponse,
  IGqlAuctionOrderResponse,
  IGqlContactsAtPlacesResponse,
  IGqlDraftResponse,
  IGqlDraftsResponse,
  IGqlGetTimezonesResponse,
  IGqlInsuranceContractResponse,
  IGqlMyCarrierAccreditationResponse,
  IGqlMyCarrierGroupsResponse,
  IGqlOrderResponse,
  IGqlOrderRouteSheetResponse,
  IGqlOrdersResponse,
  IGqlOwnerOrdersAmount,
  IGqlRouteSheetEventsResponse,
  IGqlSlaCriteriaResponse,
  IGqlSlaResponse,
  IInsuranceReference,
  IMyOrdersResponse,
  IOrdersFilter,
  IOrdersFilterDto,
  IPlace,
  IUser,
  ordersSorterDto,
  ORDERS_FILTER_MODE,
  ORDER_ALLOCATION_TYPE_GROUP,
  ORDER_STATUS,
  TPlace,
  USER_ROLE,
  STATUS,
  IOrder,
  STATE_RELATIONSHIP,
} from '@transport/ui-interfaces';
import { DocumentNode } from 'graphql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnCurrentUserFacade } from '../current-user';
import { TnFeatureAccessService } from '../feature-access';
import { TnGqlClientSharedService } from '../gql-client-shared';

/**
 * GraphQL client service for owner.
 */
@Injectable({
  providedIn: 'root',
})
export class TnGqlClientOwnerQueriesService {
  /**
   * Service user role.
   */
  protected serviceUserRole: USER_ROLE = USER_ROLE.OWNER;

  /**
   * Default variables.
   */
  private readonly defaultVariables = {};

  /**
   * Constructor.
   */
  constructor(
    private readonly sharedGraphQlClient: TnGqlClientSharedService,
    private readonly userFacade: TnCurrentUserFacade,
    private readonly featureAccess: TnFeatureAccessService,
  ) {
    this.sharedGraphQlClient.createApolloClientFor(this.serviceUserRole);
    this.sharedGraphQlClient.createApolloClientFor(USER_ROLE.TNDL_SECURITY_STAFF);
    this.isLoggedInSubscription();
  }

  /**
   * query - a read-only fetch.
   * @param query to execute
   * @param listenX
   * @param variables are arguments for fileds
   * @param [withSpinner] should request start spinner
   * @returns [Observable<any>] updated value
   */
  public query<T>(query: DocumentNode, listenX: number, variables: Record<string, unknown> = {}, withSpinner?: boolean): Observable<T> {
    return this.sharedGraphQlClient.query(this.serviceUserRole, query, listenX, variables, withSpinner);
  }

  public queryOrders(
    listenX = 1,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
    filter?: IOrdersFilter,
    sorter?: Sort,
  ): Observable<IGqlOrdersResponse> {
    const variables: IOrdersFilterDto = this.ordersFilterToDto(filter ?? {});
    variables.first = first;
    variables.offset = offset;
    const sortBy = this.ordersSorterToDto(sorter ?? ({} as Sort));
    return this.query<IGqlOrdersResponse>(
      (() => {
        switch (filter?.type) {
          case ORDERS_FILTER_MODE.FREE:
          case ORDERS_FILTER_MODE.TAKEN:
          case ORDERS_FILTER_MODE.READY_TO_GO:
          case ORDERS_FILTER_MODE.COMPLETED:
          case ORDERS_FILTER_MODE.CANCELED:
            return GRAPHQL_QUERY.owner.getOrdersShortSchema;
          case ORDERS_FILTER_MODE.AUCTION:
            return GRAPHQL_QUERY.owner.getOrdersAuctionSchema;
        }
        return GRAPHQL_QUERY.owner.getOrdersDefaultSchema;
      })(),
      listenX,
      {
        ...variables,
        sortBy,
      },
      withSpinner,
    );
  }

  public queryMyOrdersForDashboard(listenX = 1): Observable<IMyOrdersResponse> {
    return this.query(GRAPHQL_QUERY.owner.myOrders, listenX);
  }

  public queryOrdersAmountForDashboard(listenX = 1): Observable<IGqlOwnerOrdersAmount> {
    return this.query(GRAPHQL_QUERY.owner.getOrdersAmountForDashboard, listenX, {
      allocationType: ORDER_ALLOCATION_TYPE_GROUP.ALL,
      biddingType: BIDDING_TYPE.ALL,
    });
  }

  public queryDrafts(
    listenX = 1,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
    options?: Record<string, unknown>,
    sorter?: Sort,
  ): Observable<IGqlDraftsResponse> {
    const variables = { ...(options ?? { first: 0, offset: 0 }) };
    variables.first = first;
    variables.offset = offset;
    const sortBy = this.ordersSorterToDto(sorter ?? ({} as Sort));
    return this.query(
      GRAPHQL_QUERY.owner.getDrafts,
      listenX,
      {
        ...variables,
        sortBy,
      },
      withSpinner,
    );
  }

  public queryDraft(draftId: string): Observable<IGqlDraftResponse> {
    return this.query(GRAPHQL_QUERY.owner.getDraft, 1, { draftId }, false);
  }

  public queryAllSla(): Observable<IGqlAllOwnerSlaResponse> {
    return this.query(GRAPHQL_QUERY.owner.allSla, 1, {}, false);
  }

  public querySlaCriteria(): Observable<IGqlSlaCriteriaResponse> {
    return this.query(GRAPHQL_QUERY.owner.getSlaCriteria, 1, {}, false);
  }

  public querySla(slaId: string): Observable<IGqlSlaResponse> {
    return this.query(GRAPHQL_QUERY.owner.getSla, 1, { slaId }, false);
  }

  /**
   * Queries GraphQL endpont for order by id.
   * @param orderId order id
   * @param isCopyMode
   */
  public queryOrder(orderId: string, isCopyMode = false): Observable<IGqlOrderResponse> {
    return this.query(GRAPHQL_QUERY.owner.getOrder, 1, { orderId, isCopyMode }, false);
  }

  public queryAuctionBetHistory(orderId: string): Observable<{ order: Partial<IOrder> }> {
    return this.query(GRAPHQL_QUERY.owner.getAuctionBetHistory, 1, { orderId }, false);
  }

  public queryOrderWithTradeInfo(orderId: string): Observable<{ order }> {
    return this.query(GRAPHQL_QUERY.owner.getOrderWithTradeInfo, 1, { orderId }, false);
  }

  public queryOrderInfoShort(orderId: string): Observable<{ order: Partial<IOrder> }> {
    return this.query(GRAPHQL_QUERY.owner.getOrderShortInfo, 1, { orderId }, false);
  }

  public contactPersonsAtLoadingPlace(orderId: string, listenX = 1, withSpinner?: boolean): Observable<IGqlContactsAtPlacesResponse> {
    return this.query(GRAPHQL_QUERY.owner.getContactPersonsAtLoadingPlace, listenX, { loadingPlaceId: orderId }, withSpinner);
  }

  public contactPersonsAtUnloadingPlace(orderId: string, listenX = 1, withSpinner?: boolean): Observable<IGqlContactsAtPlacesResponse> {
    return this.query(GRAPHQL_QUERY.owner.getContactPersonsAtUnloadingPlace, listenX, { unloadingPlaceId: orderId }, withSpinner);
  }

  /**
   * Queries GraphQL endpont for carriers.
   * @param listenX number of responses to catch
   * @param withSpinner should request start spinner
   * @param first
   * @param offset
   * @param options
   */
  public queryCarriers(
    listenX = 1,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
    options?: Record<string, unknown>,
  ): Observable<{ carrierOrganizations: ICarrier[] }> {
    const variables = { ...(options ?? { first: 0, offset: 0 }) };
    if ((first ?? 0) > 0 && (offset ?? 0) >= 0) {
      variables.first = first;
      variables.offset = offset;
    }
    return this.query<{ carrierOrganizations: ICarrier[] }>(
      GRAPHQL_QUERY.owner.getCarrierOrganizationList,
      listenX,
      variables,
      withSpinner,
    );
  }

  /**
   * Queries GraphQL endpont for cargo types.
   * @param listenX number of responses to catch
   * @param [withSpinner] should request start spinner
   * @param [first] paging filter, indicates quantity of elements that should be returned after offset.
   * @param [offset] paging filter, indicates quantity of elements that should be passed before returning elements equal to 'first' value.
   * @param options
   */
  public queryCargoTypes(
    listenX = 1,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
    options?: Record<string, unknown>,
  ): Observable<{ cargoTypes: ICargoType[]; totalCount: number | undefined }> {
    const variables: ICargoTypesFilter = {
      ...this.defaultVariables,
      ...options,
    };
    if ((first ?? 0) > 0 && (offset ?? 0) >= 0) {
      variables.first = first;
      variables.offset = offset;
    }
    return this.query<{ cargoTypes: ICargoType[]; totalCount: number | undefined }>(
      GRAPHQL_QUERY.owner.getCargoTypes,
      listenX,
      variables as Record<string, unknown>,
      withSpinner,
    );
  }

  /**
   * Queries GraphQL endpont for cargo type by id.
   * @param cargoTypeId cargo type id
   * @param listenX number of responses to catch
   * @param [withSpinner] should request start spinner
   */
  public queryCargoType(cargoTypeId: string, listenX = 1, withSpinner?: boolean): Observable<{ cargoType: ICargoType }> {
    return this.query<{ cargoType: ICargoType }>(GRAPHQL_QUERY.owner.getCargoType, listenX, { cargoTypeId }, withSpinner);
  }

  /**
   * Queries GraphQL endpont for list of vehicle types.
   * @param listenX number of responses to catch
   * @param [withSpinner] should request start spinner
   * @returns Observable with list of vehicle types
   */
  public queryVehicleTypeList(listenX = 1, withSpinner?: boolean): Observable<transport.IVehicleType[]> {
    return this.query<{ vehicleTypes: transport.IVehicleType[] }>(GRAPHQL_QUERY.owner.getVehicleTypeList, listenX, {}, withSpinner).pipe(
      map(data => data.vehicleTypes),
    );
  }

  /**
   * Queries GraphQL endpont for list of carrier organizations.
   * @param listenX number of responses to catch
   * @param [withSpinner] should request start spinner
   * @param [anyContract] get organizations wich have contract
   * @returns Observable with list of carrier organizations
   */
  public queryCarrierOrganizationsList(
    listenX = 1,
    withSpinner?: boolean,
    anyContract?: boolean,
    state?: STATE_RELATIONSHIP[],
  ): Observable<ICarrierOrganization[]> {
    return this.query<{ carrierOrganizations: ICarrierOrganization[] }>(
      GRAPHQL_QUERY.owner.getCarrierOrganizationList,
      listenX,
      { anyContract, state },
      withSpinner,
    ).pipe(map(data => data.carrierOrganizations));
  }

  public queryTrustedCarrierOrganizationsList(
    listenX = 1,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
    options?: Record<string, unknown>,
  ): Observable<{ trustedCarrierOrganizations: ICarrierOrganization[]; totalCount: number }> {
    const variables = { ...(options ?? { first: 0, offset: 0 }) };
    if ((first ?? 0) > 0 && (offset ?? 0) >= 0) {
      variables.first = first;
      variables.offset = offset;
    }
    return this.query<{ trustedCarrierOrganizations: ICarrierOrganization[]; totalCount: number }>(
      GRAPHQL_QUERY.owner.getTrustedCarrierOrganizationList,
      listenX,
      variables,
      withSpinner,
    );
  }

  public queryMyCarrierContractingRequired(
    listenX = 1,
    withSpinner?: boolean,
  ): Observable<{ carrierOrganizations: ICarrierOrganization[] }> {
    return this.query(GRAPHQL_QUERY.owner.getMyCarrierContractingRequired(), listenX, {}, withSpinner);
  }

  /**
   * Querie GraphQL endpont for carrier organization by id.
   * @param carrierOrganizationId Carrier organization id
   * @param listenX number of responses to catch
   * @param [withSpinner] should request start spinner
   * @returns Observable with carrier organization
   */
  public queryCarrierOrganization(
    carrierOrganizationId: string,
    listenX = 1,
    withSpinner?: boolean,
  ): Observable<{ carrierOrganization: ICarrierOrganization }> {
    return this.query<{ carrierOrganization: ICarrierOrganization }>(
      GRAPHQL_QUERY.owner.getCarrierOrganization,
      listenX,
      { id: carrierOrganizationId },
      withSpinner,
    );
  }

  /**
   * Queries GraphQL endpont for list of loading types.
   * @param listenX number of responses to catch
   * @param [withSpinner] should request start spinner
   * @returns Observable with list of loading types
   */
  public queryLoadingTypesList(listenX = 1, withSpinner?: boolean): Observable<transport.ILoadingType[]> {
    return this.query<{ loadingTypes: transport.ILoadingType[] }>(GRAPHQL_QUERY.owner.getLoadingTypeList, listenX, {}, withSpinner).pipe(
      map(data => data.loadingTypes),
    );
  }

  /**
   * Queries GraphQL endpont for list of cargo body types.
   * @param listenX number of responses to catch
   * @param [withSpinner] should request start spinner
   * @returns Observable with list of cargo body types
   */
  public queryBodyTypes(listenX = 1, withSpinner?: boolean): Observable<transport.Vehicle.Body.IType[]> {
    return this.query<{ bodyTypes: transport.Vehicle.Body.IType[] }>(GRAPHQL_QUERY.owner.getBodyTypes, listenX, {}, withSpinner).pipe(
      map(data => data.bodyTypes),
    );
  }

  /**
   * Queries GraphQL endpont for auction order by id.
   * @param orderId order id
   * @param isCopyMode
   */
  public queryAuctionOrder(orderId: string, isCopyMode = false): Observable<IGqlAuctionOrderResponse> {
    return this.query<IGqlAuctionOrderResponse>(GRAPHQL_QUERY.owner.getAuctionOrder, 1, { orderId, isCopyMode }, false);
  }

  /**
   * Queries notification with orders-filters and paging.
   * @param withSpinner
   * @param isRead
   * @param first
   * @param offset
   * @param listenX number of responses to catch
   */
  public queryNotificationsWithCount(
    withSpinner = false,
    isRead: boolean | null = null,
    first?: number,
    offset?: number,
    listenX = 1,
  ): Observable<transport.INotificationsWithCounters> {
    return this.query<transport.INotificationsWithCounters>(
      GRAPHQL_QUERY.shared.getNotificationsWithCount,
      listenX,
      {
        isRead,
        first,
        offset,
      },
      withSpinner,
    );
  }

  /**
   * Queries GraphQL endpoint for loading places.
   */
  public queryStoragePoints(
    listenX = 1,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
    options?: Record<string, unknown>,
  ): Observable<{
    storagePoints: IPlace[];
    totalCount: number | undefined;
  }> {
    const variables = { ...(options ?? { first: 0, offset: 0 }) };
    if ((first ?? 0) > 0 && (offset ?? 0) >= 0) {
      variables.first = first;
      variables.offset = offset;
    }
    return this.query<{
      storagePoints: IPlace[];
      totalCount: number | undefined;
    }>(GRAPHQL_QUERY.shared.getOwnerStoragePoints, listenX, variables, withSpinner);
  }

  /**
   * Queries GraphQL endpoint for place.
   * @param id point id
   * @param typePoint point type
   * @param listenX number of responses to catch
   * @param withSpinner should request start spinner
   */
  public queryStoragePoint(id: string, typePoint: TPlace, listenX = 1, withSpinner?: boolean): Observable<{ storagePoint: IPlace }> {
    return this.query<{ storagePoint: IPlace }>(
      GRAPHQL_QUERY.owner.getStoragePoint,
      listenX,
      {
        id,
        typePoint,
      },
      withSpinner,
    );
  }

  /**
   * Queries users with orders-filters and paging.
   */
  public queryUsers(
    listenX = 1,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
    options?: Record<string, unknown>,
  ): Observable<{ employees: transport.IUser[]; count: number | undefined }> {
    const variables: ICargoTypesFilter = {
      ...options,
    };
    if ((first ?? 0) > 0 && (offset ?? 0) >= 0) {
      variables.first = first;
      variables.offset = offset;
    }
    return this.query<{ employees: transport.IUser[]; count: number | undefined }>(
      GRAPHQL_QUERY.shared.getEmployees,
      listenX,
      variables as Record<string, unknown>,
      withSpinner,
    );
  }

  /**
   * Queries user.
   */
  public queryUser(employeeId: string, withSpinner = false, listenX = 1): Observable<{ user: IUser }> {
    return this.query(
      GRAPHQL_QUERY.shared.getUser,
      listenX,
      {
        employeeId,
      },
      withSpinner,
    );
  }

  public getAllPermissions(withSpinner = true, listenX = 1): Observable<{ permissions: transport.User.Permission[] }> {
    return this.query<{ permissions: transport.User.Permission[] }>(GRAPHQL_QUERY.shared.getAllPermissions, listenX, {}, withSpinner);
  }

  public getAllDiction(withSpinner = true, listenX = 1): Observable<{ permissions: transport.User.Permission[] }> {
    return this.query<{ permissions: transport.User.Permission[] }>(GRAPHQL_QUERY.shared.getAllPermissions, listenX, {}, withSpinner);
  }

  /**
   * Queries organization.
   */
  public queryOrganization(withSpinner?: boolean): Observable<{ profile: { organization: transport.IAdminOrganization, bankDetails: any, isRegistrationCompleted: boolean } }> {
    return this.query<{ profile: { organization: transport.IAdminOrganization, bankDetails: any, isRegistrationCompleted: boolean  } }>(
      GRAPHQL_QUERY.owner.getCurrentUserOrganizationProfile,
      1,
      {},
      withSpinner,
    );
  }

  /**
   * Queries default profile params.
   */
  public queryOrganizationDefaultParams(withSpinner?: boolean): Observable<{ profile: { organization: IDefaultProfileParams } }> {
    return this.query<{ profile: { organization: IDefaultProfileParams } }>(
      GRAPHQL_QUERY.owner.getCurrentUserDefaultParamsProfile,
      1,
      {},
      withSpinner,
    );
  }

  /**
   * Queries default auction parameters.
   */
  public queryOrganizationDefaultAuctionParameters(withSpinner?: boolean) {
    return this.query<{
      profile?: {
        organization?: { auctionParameters: transport.IAuctionProlongationParameters };
      };
    }>(GRAPHQL_QUERY.owner.getCurrentUserDefaultActionParameters, 1, {}, withSpinner);
  }

  public queryLegalForms(): Observable<{ legalForms: transport.ILegalForm[] }> {
    return this.query<{ legalForms: transport.ILegalForm[] }>(GRAPHQL_QUERY.shared.getLegalForms, 1);
  }

  public getRouteSheetEvents(routeSheetId: string): Observable<IGqlRouteSheetEventsResponse> {
    return this.query<IGqlRouteSheetEventsResponse>(GRAPHQL_QUERY.owner.getRouteSheetEvents, 1, { routeSheetId }, false);
  }

  public getOrderRouteSheet(orderId: string): Observable<IGqlOrderRouteSheetResponse> {
    return this.query<IGqlOrderRouteSheetResponse>(GRAPHQL_QUERY.owner.getOrderRouteSheet, 1, { orderId }, false);
  }

  public getCarrierAccreditation(): Observable<IGqlMyCarrierAccreditationResponse> {
    return this.query<IGqlMyCarrierAccreditationResponse>(GRAPHQL_QUERY.owner.getMyCarrierAccreditation, 1, {}, true);
  }

  public getCarrierReject(): Observable<IGqlMyCarrierAccreditationResponse> {
    return this.query<IGqlMyCarrierAccreditationResponse>(GRAPHQL_QUERY.owner.getMyCarrierReject(), 1, {}, true);
  }

  /**
   * Queries carrier groups.
   */
  public getCarrierGroups(
    listenX = 1,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
    options?: Record<string, unknown>,
  ): Observable<IGqlMyCarrierGroupsResponse> {
    const variables = { ...(options ?? { first: 0, offset: 0 }) };
    if ((first ?? 0) > 0 && (offset ?? 0) >= 0) {
      variables.first = first;
      variables.offset = offset;
    }
    return this.query<IGqlMyCarrierGroupsResponse>(GRAPHQL_QUERY.owner.getMyCarrierGroups(), listenX, variables, withSpinner);
  }

  /**
   * Queries carrier groups without additional data.
   */
  public getCarrierGroupsShort(listenX = 1, withSpinner?: boolean): Observable<IGqlMyCarrierGroupsResponse> {
    return this.query<IGqlMyCarrierGroupsResponse>(GRAPHQL_QUERY.owner.getMyCarrierGroups(true), listenX, {}, withSpinner);
  }

  /**
   * Query list of available packaging types
   * Queries GraphQL endpoint for sub organizations.
   */
  public querySubOrganizations(
    listenX = 1,
    withSpinner?: boolean,
    first = 0,
    offset = 0,
    options?: Record<string, unknown>,
  ): Observable<{ subOrganizations: transport.IAdminOrganization[]; totalCount: number }> {
    const variables = Boolean(options) ? { ...options } : {};
    if (first > 0 && offset >= 0) {
      variables.first = first;
      variables.offset = offset;
    }
    return this.query<{ subOrganizations: transport.IAdminOrganization[]; totalCount: number }>(
      GRAPHQL_QUERY.owner.getCurrentUserSuborganizations,
      listenX,
      variables,
      withSpinner,
    );
  }

  /**
   * Queries GraphQL endpont for sub org by id.
   * @param sub org id
   * @param listenX number of responses to catch
   * @param [withSpinner] should request start spinner
   */
  public queryPackagingTypes(): Observable<ICargoPackagingType[]> {
    return this.query<{ listPackagingTypesForCargoType: string[] }>(GRAPHQL_QUERY.owner.getPackagingTypes, 1).pipe(
      map((value: { listPackagingTypesForCargoType: string[] }) => this.sharedGraphQlClient.getPackagingTypesListMap(value)),
    );
  }

  public queryInsuranceContracts(withSpinner?: boolean): Observable<{ myInsuranceContracts: unknown }> {
    return this.query<{ myInsuranceContracts: unknown }>(GRAPHQL_QUERY.owner.getInsuranceContracts, 1, {}, withSpinner);
  }

  public queryInsuranceInfo(insuranceContractId, withSpinner?: boolean): Observable<unknown> {
    return this.query(
      GRAPHQL_QUERY.owner.getInsuranceInfo,
      1,
      {
        insuranceContractId,
      },
      withSpinner,
    );
  }

  public queryInsuranceCost(insuranceContractId, declaredPrice, withSpinner?: boolean): Observable<{ insuranceCost: number }> {
    return this.query<{ insuranceCost: number }>(
      GRAPHQL_QUERY.owner.getInsuranceCost,
      1,
      { insuranceContractId, declaredPrice },
      withSpinner,
    );
  }

  public querySubOrganization(
    organizationId: string,
    listenX = 1,
    withSpinner?: boolean,
  ): Observable<{ organization: transport.IAdminOrganization }> {
    return this.query<{ organization: transport.IAdminOrganization }>(
      GRAPHQL_QUERY.owner.getSubOrganizationById,
      listenX,
      { organizationId },
      withSpinner,
    );
  }

  public queryCarrierOrganizationsFilterList(
    listenX = 1,
    withSpinner?: boolean,
    anyContract?: boolean,
  ): Observable<Partial<ICarrierOrganization>[]> {
    return this.query<{ carrierOrganizations: Partial<ICarrierOrganization>[] }>(
      GRAPHQL_QUERY.owner.getCarrierOrganizationFilterList,
      listenX,
      { anyContract },
      withSpinner,
    ).pipe(map(data => data.carrierOrganizations));
  }

  public queryPaymentTypes(): Observable<transport.IPaymentType[]> {
    return this.query<{ paymentTypes: transport.IPaymentType[] }>(GRAPHQL_QUERY.owner.getPaymentTypes, 1).pipe(
      map(data => data.paymentTypes),
    );
  }

  /**
   * Queries a carrier group.
   */
  public queryGroup(groupId: string, withSpinner = false, listenX = 1): Observable<{ group: transport.Carrier.ICarrierGroup }> {
    return this.query<{ group: transport.Carrier.ICarrierGroup }>(GRAPHQL_QUERY.owner.getCarrierGroup, listenX, { groupId }, withSpinner);
  }

  private ordersFilterToDto(filter: IOrdersFilter): IOrdersFilterDto {
    let res: IOrdersFilterDto = {
      onlyMy: filter.onlyMy ?? false,
      totalCount: true,
      loadingPlaceName: filter.loadingPlaceName,
      unloadingPlaceName: filter.unloadingPlaceName,
      isMarket: filter?.isMarket,
      // TODO: 2142 при запуске "Рынка" убрать проверку hideInProd
      biddingType: this.featureAccess.hideInProd ? BIDDING_TYPE.ALL : filter.publishType?.biddingType ?? BIDDING_TYPE.ALL,
    };
    res = { ...res, ...this.getOptionalFilters(filter) };
    switch (filter.type) {
      case ORDERS_FILTER_MODE.ALL:
        res.allocationType = ORDER_ALLOCATION_TYPE_GROUP.ALL;
        break;

      case ORDERS_FILTER_MODE.AUCTION:
        res.allocationType = ORDER_ALLOCATION_TYPE_GROUP.AUCTION;
        break;

      case ORDERS_FILTER_MODE.TAKEN:
        res.statuses = [ORDER_STATUS.ASSIGNED, ORDER_STATUS.TRANSPORT_RESERVED, ORDER_STATUS.CONTRACT_ATTACHED];
        res.allocationType = ORDER_ALLOCATION_TYPE_GROUP.ALL;
        break;

      case ORDERS_FILTER_MODE.FREE:
        res.status = ORDER_STATUS.FREE;
        res.allocationType = ORDER_ALLOCATION_TYPE_GROUP.ALL;
        break;

      case ORDERS_FILTER_MODE.READY_TO_GO:
        res.status = ORDER_STATUS.READY_TO_GO;
        res.allocationType = ORDER_ALLOCATION_TYPE_GROUP.ALL;
        break;

      case ORDERS_FILTER_MODE.COMPLETED:
        res.status = ORDER_STATUS.COMPLETED;
        res.allocationType = ORDER_ALLOCATION_TYPE_GROUP.ALL;
        break;

      case ORDERS_FILTER_MODE.CANCELED:
        res.status = ORDER_STATUS.CANCELED;
        res.allocationType = ORDER_ALLOCATION_TYPE_GROUP.ALL;
        break;
      default:
        throw new Error('Неизвестный filter.type');
    }

    return res;
  }

  private ordersSorterToDto(sorter: Sort): string[] {
    if (Boolean(sorter.direction) && Boolean(sorter.active)) {
      return [`${sorter.direction === 'desc' ? '-' : ''}${ordersSorterDto[sorter.active]}`];
    }
    return ['-id']; //Изменение для сортировки черновиков по ID , раньше был [] и сортировка работала корректно везде кроме черновиков.
  }

  private getOptionalFilters(filter: IOrdersFilter): Partial<IOrdersFilterDto> {
    let filterValues = {} as Partial<IOrdersFilterDto>;
    if (Boolean(filter.id)) {
      filterValues.id = filter.id;
    }
    if (Boolean(filter.externalNo)) {
      filterValues.externalNo = filter.externalNo;
    }
    if (Boolean(filter.loadingDates)) {
      filterValues = {
        ...filterValues,
        ...this.sharedGraphQlClient.getDateInterval(filter.loadingDates, 'loadingDate'),
      };
    }
    if (Boolean(filter.unloadingDates)) {
      filterValues = {
        ...filterValues,
        ...this.sharedGraphQlClient.getDateInterval(filter.unloadingDates, 'unloadingDate'),
      };
    }

    if (Boolean(filter.carriers)) {
      filterValues.carrierOrganizationIds = filter.carriers?.split(',');
    }

    if (Boolean(filter.loadingDateFrom)) {
      filterValues.loadingDateFrom = filter.loadingDateFrom;
    }

    return filterValues;
  }

  public getInsuranceContracts(
    listenX = 1,
    withSpinner?: boolean,
    first?: number,
    offset?: number,
    options?: Record<string, unknown>,
  ): Observable<IGqlInsuranceContractResponse> {
    const variables = { ...(options ?? { availability: 'ACTIVE' }) };
    if ((first ?? 0) > 0 && (offset ?? 0) >= 0) {
      variables.first = first;
      variables.offset = offset;
    }
    return this.query<IGqlInsuranceContractResponse>(GRAPHQL_QUERY.owner.getInsuranceContracts, listenX, variables, withSpinner);
  }

  public queryInsuranceContract(
    contractId: string,
    withSpinner = false,
    listenX = 1,
  ): Observable<{ contract: Partial<transport.Insurance.Contract> }> {
    return this.query<{ contract: Partial<transport.Insurance.Contract> }>(
      GRAPHQL_QUERY.owner.getInsuranceContract,
      listenX,
      { contractId },
      withSpinner,
    );
  }

  public getSpassClasses(withSpinner = false): Observable<{ classes: IInsuranceReference[] }> {
    return this.query<{ classes: IInsuranceReference[] }>(GRAPHQL_QUERY.owner.getSpasClasses, 1, {}, withSpinner);
  }

  public getSpassKinds(withSpinner = false): Observable<{ kinds: IInsuranceReference[] }> {
    return this.query<{ kinds: IInsuranceReference[] }>(GRAPHQL_QUERY.owner.getSpasKinds, 1, {}, withSpinner);
  }

  public getTimezones(withSpinner = false): Observable<IGqlGetTimezonesResponse> {
    return this.query<IGqlGetTimezonesResponse>(GRAPHQL_QUERY.owner.getTimezones, 1, {}, withSpinner);
  }

  public getInsuranceCompanies(withSpinner = false): Observable<{ companies: transport.Insurance.ICompany[] }> {
    return this.query<{ companies: transport.Insurance.ICompany[] }>(GRAPHQL_QUERY.owner.getInsuranceCompanies, 1, {}, withSpinner);
  }

  public getOrganizationsServices(withSpinner = false): Observable<transport.IAdminOrganization[]> {
    return this.query<{ organization: Partial<transport.IAdminOrganization> }>(
      GRAPHQL_QUERY.owner.getOrganizationServices,
      1,
      {},
      withSpinner,
    ).pipe(map(data => data?.organization?.allowedExtraServices ?? []));
  }

  public getAllOrganizationsSla(withSpinner = false): Observable<transport.IAllOrganizationSlaData> {
    return this.query<transport.IAllOrganizationSlaData>(GRAPHQL_QUERY.owner.getAllOrganizationSla, 1, {}, withSpinner);
  }

  /*
   * Is logged in subscription.
   */
  private isLoggedInSubscription(): void {
    void this.userFacade.isLoggedIn$.subscribe((token: string) => {
      if (Boolean(token)) {
        const role: USER_ROLE = this.userFacade.currentUserRole;
        if (role === this.serviceUserRole) {
          this.sharedGraphQlClient.resetApolloClientFor(this.serviceUserRole);
        }
      }
    });
  }

  public getOwnerStatusAccreditation(): Observable<STATUS | null | undefined> {
    const queryString = GRAPHQL_QUERY.shared.getOwnerStatusAccreditation;
    return this.query<{ profile: transport.IUserProfile }>(queryString, 1).pipe(map(resp => resp.profile.organization?.accrStatus));
  }
}
