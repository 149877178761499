/**
 * Custom toaster interface.
 */
export type TToast = 'error' | 'success' | 'warning' | 'accent' | 'primary';

/**
 * Toast duration values enumerator.
 */
export enum TOAST_DURATION {
  LESSER = 2000,
  SHORT = 5000,
  DEFAULT = 7000,
  LONG = 9000,
  MINUTE = 60000,
}

/**
 * Returns extra classes for toaster depending on provided toast type.
 * @param toastType toast type
 */
export const toasterExtraClasses = (toastType: TToast): string[] => {
  const extraClasses: {
    error: string[];
    success: string[];
    warning: string[];
    accent: string[];
    primary: string[];
  } = {
    error: ['error-bg'],
    success: ['success-bg'],
    warning: ['warn-bg'],
    accent: ['accent-bg'],
    primary: ['primary-bg'],
  };
  return Boolean(extraClasses[toastType]) ? extraClasses[toastType] : [];
};
