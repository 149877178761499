import { createSelector } from '@ngrx/store';

import { ITnState } from '../state';
import { IFeatureAccessState } from './feature-access.state';

export const getFeatureAccessValue = (state: ITnState) => state.featureAccess;

export const hideInProd = createSelector(getFeatureAccessValue, (state: IFeatureAccessState) => state.environment.production);

export const getFlagValue = (flag: string) =>
  createSelector(getFeatureAccessValue, (state: IFeatureAccessState) => state.featureFlags[flag]);

export const apiBaseUrl = createSelector(getFeatureAccessValue, (state: IFeatureAccessState) => state.apiBaseUrl);
