import { gql } from 'apollo-angular';

export const PARTNERS_QUERIES = {
  list: gql`
    query {
      cargoOwnerWithBusinessRelationshipWithCarrier {
        name
        carrierBusinessRelationshipState
        subdomain
        logo
      }
    }
  `,
};
