export const blackListInfoLocalization = {
  trucks: {
    prohibited: {
      company: 'Блокировка ТС: {{ count }}',
      severalCompanies: {
        one: '{{ count }} компания заблокировала это ТС',
        fromTwoToFive: '{{ count }} компании заблокировали это ТС',
        other: '{{ count }} компаний частично заблокировали это ТС',
      },
    },
    partProhibited: {
      company: 'Частичная блокировка ТС: {{ count }}',
      severalCompanies: {
        one: '{{ count }} компания частично заблокировала это ТС',
        fromTwoToFive: '{{ count }} компании частично заблокировали это ТС',
        other: '{{ count }} компаний частично частично заблокировали это ТС',
      },
    },
  },
  drivers: {
    prohibited: {
      company: 'Блокировка водителя: {{ count }}',
      severalCompanies: {
        one: '{{ count }} компания заблокировала этого водителя',
        fromTwoToFive: '{{ count }} компании заблокировали этого водителя',
        other: '{{ count }} компаний заблокировали этого водителя',
      },
    },
    partProhibited: {
      company: 'Частичная блокировка водителя: {{ count }}',
      severalCompanies: {
        one: '{{ count }} компания частично заблокировала этого водителя',
        fromTwoToFive: '{{ count }} компании частично заблокировали этого водителя',
        other: '{{ count }} компаний частично заблокировали этого водителя',
      },
    },
    truckPage: {
      title: {
        name: 'У водителя ',
        chunk: {
          prohibited: {
            one: '{{ count }} блокировка',
            fromTwoToFive: '{{ count }} блокировки',
            other: '{{ count }} блокировок',
          },
          partProhibited: {
            one: '{{ count }} ограничение',
            fromTwoToFive: '{{ count }} ограничения',
            other: '{{ count }} ограничений',
          },
        },
        and: ' и ',
      },
    },
  },
  trailers: {
    prohibited: {
      company: 'Блокировка прицепа: {{ count }}',
      severalCompanies: {
        one: '{{ count }} компания заблокировала этот прицеп',
        fromTwoToFive: '{{ count }} компании заблокировали этот прицеп',
        other: '{{ count }} компаний частично заблокировали этого прицеп',
      },
    },
    partProhibited: {
      company: 'Частичная блокировка прицепа: {{ count }}',
      severalCompanies: {
        one: '{{ count }} компания частично заблокировала этот прицеп',
        fromTwoToFive: '{{ count }} компании частично заблокировали этот прицеп',
        other: '{{ count }} компаний частично частично заблокировали этого прицеп',
      },
    },
  },
  chooseTitleShared: {
    title: {
      workProhibited: 'Заблокирован',
      partWorkProhibited: 'Частично заблокирован',
    },
  },
  section: {
    prohibited: {
      one: '{{ count }} блокировка',
      fromTwoToFive: '{{ count }} блокировки',
      other: '{{ count }} блокировок',
    },
    partProhibited: {
      one: '{{ count }} ограничение',
      fromTwoToFive: '{{ count }} ограничения',
      other: '{{ count }} ограничений',
    },
    more: 'Подробнее',
    tabs: {
      prohibited: 'Блокировки ({{ count }})',
      partProhibited: 'Ограничения ({{ count }})',
    },
    emptyTruck: 'У данного ТС нет никаких ограничений',
    emptyDriver: 'У данного Водителя нет никаких ограничений',
  },
  card: {
    title: {
      full: 'Заблокировано {{ company }}',
      partial: 'Частично заблокировано {{ company }}',
    },
    description: {
      full: 'Запрещено назначать {{type}} на все виды перевозок {{ company }}',
      partial: 'Запрещено назначать {{type}} на перевозки в части номенклатур {{ company }}',
    },
    labels: {
      startDate: 'Дата начала',
      reason: 'Причина',
      description: 'Описание',
      lockObject: 'Объект блокировки',
      nomenclature: 'Заблокированные номенклатуры',
    },
  },
  reason: {
    tooltip: {
      reasonCount: {
        one: 'и еще {{ count }} причина. ',
        fromTwoToFive: 'и еще {{ count }} причины. ',
        other: 'и еще {{ count }} причин. ',
      },
      cardTruck: 'Подробнее в карточке ТС',
      cardDriver: 'Подробнее в карточке водителя',
    },
  },
};
