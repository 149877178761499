import { createFeatureSelector, createSelector } from '@ngrx/store';

import { makeTableSelectors } from '../../../../table/table-selectors';
import { IDirectoryState } from '../../../directory-state.interface';

const getDirectoryState = createFeatureSelector<IDirectoryState>('directory');
const driversStateSelector = createSelector(getDirectoryState, (state: IDirectoryState) => state.drivers);

const chooseDriversStateSelector = createSelector(getDirectoryState, (state: IDirectoryState) => state.chooseDrivers);

export const tableSelectors = makeTableSelectors(driversStateSelector);

export const chooseDriversTableSelectors = makeTableSelectors(chooseDriversStateSelector);
