<mat-toolbar [ngClass]="{'new-design': isNewDesign, 'in-group': isInGroup, 'tonna-design': isTonnaDesign}">
  <mat-toolbar-row class="flex basis-full items-center justify-between">
    <mat-tab-group
      *ngIf="showTabGroup"
      scrollToCenter
      animationDuration="0ms"
      [disablePagination]="false"
      [selectedIndex]="selectedTabIndex"
      (selectedIndexChange)="changeTab($event)"
    >
      <ng-container
        [ngTemplateOutlet]="$any(tabsView.tabs?.length) > 0 ? tabs : labels"
        [ngTemplateOutletContext]="{view: tabsView}"
      ></ng-container>

      <ng-template let-view="view" #tabs>
        <mat-tab *ngFor="let tab of view.tabs; let i = index">
          <ng-template mat-tab-label>
            <div class="items-center flex gap-[6px]">
              <span [attr.data-test]="'transport-toolbar-tab-' + i">{{ tab?.label }}</span>
              <span *ngIf="isShowCount(tab)" class="counter">
                <ng-container *ngIf="compactCounter">{{ tab?.count | toolbarCounter }}</ng-container>
                <ng-container *ngIf="!compactCounter">{{ tab?.count }}</ng-container>
              </span>
            </div>
          </ng-template>
        </mat-tab>
      </ng-template>

      <ng-template let-view="view" #labels>
        <mat-tab *ngFor="let label of view.labels;let i = index">
          <ng-template mat-tab-label>
            <span [attr.data-test]="'transport-toolbar-tab-' + i">{{ label }}</span>
          </ng-template>
        </mat-tab>
      </ng-template>
    </mat-tab-group>
    <ng-content></ng-content>
  </mat-toolbar-row>
  <div *ngIf="isNewDesign && isInGroup" class="new-design-underline"></div>
</mat-toolbar>
