import { Injectable } from '@angular/core';
import { FormGroup } from '@ngneat/reactive-forms';
import { ControlsValue } from '@ngneat/reactive-forms/lib/types';
import { transport } from '@transport/proto';
import {
  getVehicleData,
  ICheckOrganizationInput,
  ICheckTruckInput,
  IDriverForm,
  IFileType,
  IOrganiationUploadedFile,
  IOrganizationDocument,
  IVehicleForm,
  LEGAL_FORM_CODE_BACKEND_ENUM_PREFIX,
  TAdminOrganizationForm,
  TnUploadedFile,
} from '@transport/ui-interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnGqlClientCarrierService } from '../gql-client-carrier';
import { TOrganizationForm } from '../profile';

@Injectable({
  providedIn: 'root',
})
export class TnRegistrationService {
  constructor(private readonly graphQL: TnGqlClientCarrierService) {}

  public addDriverOnRegistration(driver: IDriverForm): Observable<transport.IDriver> {
    return this.graphQL.addDriverOnRegistration(driver);
  }

  public getOrganizationDocumentsType(): Observable<IFileType[]> {
    return this.graphQL.getOrganizationDocumentsType().pipe(map(result => result.organizationDocumentTypes));
  }

  public getOrganizationDocuments() {
    return this.graphQL.getOrganizationDocuments().pipe(
      map(result => {
        return result.organizationDocuments.map(document => {
          const { name, id, url, createdAt } = document.uploadedFile as IOrganiationUploadedFile;
          return {
            ...document,
            uploadedFile: {
              ...new TnUploadedFile(name as string, url as string, id),
              createdAt,
            },
          };
        });
      }),
    );
  }

  public addDocumentForOrganization(uploadedFileId: string, documentTypeId: string): Observable<IOrganizationDocument> {
    return this.graphQL
      .addDocumentForOrganization({
        documentTypeId,
        uploadedFileId,
      })
      .pipe(map(result => result.addOrganizationUploadedDocument));
  }

  public deleteOrganizationDocument(docId: string): Observable<IOrganizationDocument> {
    return this.graphQL.deleteOrganizationDocument(docId).pipe(map(result => result.removeOrganizationUploadedDocument));
  }

  public setOrganizationInputFields(organization: TAdminOrganizationForm): ICheckOrganizationInput {
    const organizationValue: ControlsValue<TOrganizationForm> = organization ?? {};
    const { legalForm } = organizationValue ?? {};

    return {
      legalFormCode: ((legalForm as transport.ILegalForm).code as string).slice(LEGAL_FORM_CODE_BACKEND_ENUM_PREFIX.length),
      name: organizationValue.companyName as string,
      phoneNumber: organizationValue.phoneNumber as string,
      email: organizationValue.email as string,
      inn: organizationValue.inn as string,
      kpp: organizationValue.kpp as string,
      legalAddress: organizationValue.legalAddress as string,
      registrationNumber: organizationValue.ogrn as string,
      postalAddress: organizationValue.postalAddress as string,
      signer: organizationValue.signer as string,
      headOfOrganization: {},
    };
  }

  public setTruckInputFields(vehicle: FormGroup<IVehicleForm>): ICheckTruckInput {
    const { vehicleBody, vehicleTrailer } = vehicle.value;
    const vehicleData = getVehicleData(vehicle, Boolean(Object.keys(vehicleBody).length), Boolean(Object.keys(vehicleTrailer).length));
    delete vehicleData.vehicleDrivers;

    return vehicleData as ICheckTruckInput;
  }
}
