import { Action, createReducer, on } from '@ngrx/store';

import { showToaster, showToasterWithCancel } from './toaster.actions';
import { IToasterState, toasterInitialState } from './toaster.state';

const createToasterReducer = createReducer(
  toasterInitialState,
  on(showToaster, (state, { payload }) => ({ ...state, ...payload })),
  on(showToasterWithCancel, (state, { payload }) => ({ ...state, ...payload })),
);

export function toasterReducer(state: IToasterState, action: Action) {
  return createToasterReducer(state, action);
}
