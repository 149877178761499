import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'common-textarea',
  templateUrl: './common-textarea.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonTextareaComponent {
  @Input() public size: 'xs' | 'sm' | 'md' | 'lg' = 'md';
  @Input() public errorsSize: 'xs' | 'sm' | 'md' | 'lg' = 'xs';
  @Input() public showErrors = true;
  @Input() public required = false;
  @Input() public rows = 2 ;
  @Input() public disabled = false;
  @Input() public formGroup?: FormGroup;
  @Input() public control?: FormControl;
  @Input() public controlName = '';
  @Input() public placeholder = '';
  @Input() public label = '';
  @Input() public dataTest = '';
  @Input() public errorToMessage: (errors: ValidationErrors | null) => string = () => 'Error';

  get formControl(): AbstractControl {
    return (this.formGroup?.get(this.controlName) ?? this.control)!;
  }

  public inputValue(event) {
    this.formControl?.setValue(event.target.value);
  }
}
