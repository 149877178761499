import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { TnMarketplaceRegistrationByInviteComponent } from 'libs/marketplace-auth/src/lib/components/registration-by-invite/registration-by-invite.component';

import { TnMarketplaceRootComponent } from './components';
import { TnConfirmEmailComponent } from './components/confirm-email/confirm-email.component';
import { TnMarketplaceLoginByCodeComponent } from './components/login-by-code/login-by-code.component';
import { MpPassportRegistrationEmployeeComponent } from './components/passport-registration-employee/passport-registration-employee.component';
import { TnMarketplaceRegistrationByCodeComponent } from './components/registration-by-code/registration-by-code.component';
import { TnMarketplaceSubmitCodeComponent } from './components/submit-code/submit-code.component';
import { MpRegistrationEmployeeGuard } from './guards/authenticated/registration-employee.guard';

/**
 * Transport Auth Routes.
 */
export const AUTH_ROUTES: Route[] = [
  {
    path: '',
    component: TnMarketplaceRootComponent,
    children: [
      {
        path: 'login',
        component: TnMarketplaceLoginByCodeComponent,
        data: { animation: 'SlideAndFadeInOut' },
      },
      {
        path: 'registration',
        component: TnMarketplaceRegistrationByCodeComponent,
        data: { animation: 'SlideAndFadeInOut' },
      },
      {
        path: 'registration-employee',
        component: MpPassportRegistrationEmployeeComponent,
        canActivate: [MpRegistrationEmployeeGuard],
        data: { animation: 'SlideAndFadeInOut' },
      },
      {
        path: 'confirm-email/:token',
        component: TnConfirmEmailComponent,
        data: { animation: 'SlideAndFadeInOut' },
      },
      {
        path: 'submit-code',
        component: TnMarketplaceSubmitCodeComponent,
        data: { animation: 'SlideAndFadeInOut' },
      },
      {
        path: 'confirm-registration/:token',
        component: TnConfirmEmailComponent,
        data: { animation: 'SlideAndFadeInOut' },
      },
      {
        path: 'reg/:token',
        component: TnMarketplaceRegistrationByInviteComponent,
        data: { animation: 'SlideAndFadeInOut' },
      },
    ],
  },
];

/**
 * Transport Auth Routing Module.
 */
@NgModule({
  imports: [RouterModule.forChild(AUTH_ROUTES)],
  exports: [RouterModule],
})
export class TnAuthRoutingModule {}
