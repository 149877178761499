import { IRowExtender } from '../row-extender.interface';

interface IAvoidFocusExtenderConfig {
  event: keyof HTMLElementEventMap;
}
/**
 * Immediate stops event propagation when user made a selection.
 * @param {Object} config - The configuration object.
 * @param {string} config.event - Common HTML event name. (e.g. 'click', 'mousedown')
 * */
export function stopPropagationWhenSelectionExtender<T>(config: IAvoidFocusExtenderConfig) {
  const { event: eventName } = config;
  const extender: IRowExtender<T> = el => {
    const listener = (event: MouseEvent) => {
      const { anchorOffset, anchorNode, focusOffset, focusNode } = window.getSelection() as Selection;
      if (anchorOffset !== focusOffset || anchorNode !== focusNode) {
        event.stopImmediatePropagation();
      }
    };

    const element = el.nativeElement;
    element.addEventListener(eventName, listener);
    return () => element.removeEventListener(eventName, listener);
  };
  return extender;
}
