import { HttpErrorResponse } from '@angular/common/http';
import { createAction, props } from '@ngrx/store';

export const NGRX_THROW_401 = '[ngrx-error] THROW 401';

export const ngrxThrow401 = createAction(NGRX_THROW_401, props<{ payload: HttpErrorResponse }>());

export const NGRX_THROW_404 = '[ngrx-error] THROW 404';

export const ngrxThrow404 = createAction(NGRX_THROW_404, props<{ payload: HttpErrorResponse }>());
