<div class="flex basis-auto justify-between" class="mat-h2">
  <ng-content></ng-content>
  <button mat-raised-button class="delete-trailer-btn" color="primary" *ngIf="isDeleteTrailerBtnVisible" (click)="setVehicleTypeTruck()">
    {{ 'shared.actions.remove' | translate }}
  </button>
</div>
<form class="flex flex-col basis-auto" [formGroup]="form" *ngIf="form" transportConnectForm="trailer" [clearFormStateOnDestroy]="true">
  <mat-form-field>
    <mat-label class="required-asteriks">{{
      'carrier.directory.vehicle.details.formLabels.trailerId' | translate
    }}</mat-label>
    <input
      matInput
      autocomplete="off"
      [mask]="
        isVehicleCountryRussia
          ? regExpConf.ngxMask.vehiclePassport.ru.trailerNumber.mask
          : regExpConf.ngxMask.vehiclePassport.any.trailerNumber.mask
      "
      [patterns]="
        isVehicleCountryRussia
          ? $any(regExpConf.ngxMask.vehiclePassport.ru.trailerNumber.patterns)
          : $any(regExpConf.ngxMask.vehiclePassport.any.trailerNumber.patterns)
      "
      formControlName="trailerId"
      transportUppercase
      [validation]="true"
      [transportInputErrorState]="isUseMatStep"
      data-test="trailer-info-id"
    />
    <transport-truck-help-icon
      [showIcon]="showTruckHelpIcon"
      [type]="truckIdentificatorType.GOS_TRAILER_NUMBER"
    ></transport-truck-help-icon>
    <mat-error *ngIf="form?.controls?.trailerId?.errors?.required">{{
      'shared.errors.required' | translate
    }}</mat-error>
    <mat-error *ngIf="form?.controls?.trailerId?.errors?.mask">{{
      'carrier.directory.vehicle.details.validation.wrongPatternTrailerNum' | translate
    }}</mat-error>
    <mat-error *ngIf="form?.controls?.trailerId?.errors?.maxlength">
      {{ 'shared.errors.maxLength' | translate: limitations.MAX_GOSNUM_LENGTH }}
    </mat-error>
    <mat-error *ngIf="form?.controls?.trailerId?.errors?.trimmedString">
      {{ 'shared.errors.trimmedString' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label class="required-asteriks">{{
      'carrier.directory.vehicle.details.formLabels.trailerType' | translate
    }}</mat-label>
    <mat-select
      formControlName="trailerType"
      [transportSelectErrorState]="isUseMatStep"
      data-test="trailer-info-type"
    >
      <mat-option *ngFor="let option of bodyTypes" [value]="option.id">
        {{ option.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form?.controls?.trailerType?.errors?.required">{{
      'shared.errors.required' | translate
    }}</mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="form?.controls?.trailerType?.value">
    <mat-label class="required-asteriks">{{
      'carrier.directory.vehicle.details.formLabels.trailerSubtype' | translate
    }}</mat-label>
    <mat-select
      formControlName="trailerSubtype"
      [transportSelectErrorState]="isUseMatStep"
      data-test="trailer-info-subtype"
    >
      <mat-option *ngFor="let option of bodySubtypes[form?.controls?.trailerType?.value]" [value]="option.id">
        {{ option.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form?.controls?.trailerSubtype?.errors?.required">{{
      'shared.errors.required' | translate
    }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label class="required-asteriks">{{
      'carrier.directory.vehicle.details.formLabels.trailerLoadingTypes' | translate
    }}</mat-label>
    <mat-select
      formControlName="trailerLoadingTypes"
      multiple
      [transportSelectErrorState]="isUseMatStep"
      data-test="trailer-info-loading-types"
    >
      <mat-option *ngFor="let option of loadingTypes" [value]="option.id">
        {{ option.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form?.controls?.trailerLoadingTypes?.errors?.required">{{
      'shared.errors.required' | translate
    }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label class="required-asteriks">{{
      'carrier.directory.vehicle.details.formLabels.trailerVolume' | translate
    }}</mat-label>
    <input
      transportNumberOnly
      transportTrim
      matInput
      type="text"
      formControlName="trailerVolume"
      data-test="trailer-info-volume"
    >
    <mat-error *ngIf="form?.controls?.trailerVolume?.errors?.required">{{
      'carrier.directory.vehicle.details.validation.notEmptyVolumeTrailer' | translate
    }}</mat-error>
    <mat-error *ngIf="form?.controls?.trailerVolume?.errors?.max">{{
      'carrier.directory.vehicle.details.validation.max99' | translate
    }}</mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label class="required-asteriks">{{
      'carrier.directory.vehicle.details.formLabels.trailerCapacity' | translate
    }}</mat-label>
    <input
      transportNumberOnly
      transportTrim
      matInput
      type="text"
      formControlName="trailerCapacity"
      data-test="trailer-info-capacity"
    >
    <mat-error *ngIf="form?.controls?.trailerCapacity?.errors?.required">{{
      'shared.errors.required' | translate
    }}</mat-error>
    <mat-error *ngIf="form?.controls?.trailerCapacity?.errors?.max">{{
      'carrier.directory.vehicle.details.validation.max99' | translate
    }}</mat-error>
    <mat-error *ngIf="form?.controls?.trailerCapacity?.errors?.min">{{
      'carrier.directory.vehicle.details.validation.min1' | translate
    }}</mat-error>
  </mat-form-field>
</form>
