import { InjectionToken } from '@angular/core';

/**
 * Documentation application environment constructor options interface.
 */
export interface IDocAppEnvironmentInput {
  production?: boolean;
  appName?: string;
  mdFilePaths?: string[];
}
/**
 * Documentation application environment.
 * By default generates dev environment.
 */
export class TnDocAppEnvironment {
  public production = false;

  public appName: 'Master-TMS•DOCUMENTATION' | string = 'Master-TMS•DOCUMENTATION';

  public mdFilePaths: string[] = [];

  /**
   * Constructor.
   * @param options documentation app env constructor options
   */
  constructor(input: IDocAppEnvironmentInput = {}) {
    const keys = Boolean(input) ? Object.keys(input) : [];
    for (const key of keys) {
      this[key] = Boolean(input[key]) ? input[key] : this[key];
    }
  }
}

export type TDocAppEnvToken = InjectionToken<TnDocAppEnvironment>;

/**
 * Documentation application environment injection token.
 */
export const DOC_APP_ENV: TDocAppEnvToken = new InjectionToken<TnDocAppEnvironment>('DOC_APP_ENV');
