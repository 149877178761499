import { transport } from '@transport/proto';

import { IOrderAttachment } from './order-attachment';
import { TnOrderAttachmentSign } from './order-attachment-sign';

export interface IOrderDocumentsListItem extends IOrderAttachment {
  signRequired: boolean;
  signFiles?: TnOrderAttachmentSign[];
}

export class TnOrderAttachmentGroup {
  public documentType?: transport.IOrderDocumentType;

  public attachments: IOrderDocumentsListItem[] = [];
}
