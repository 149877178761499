import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { ITnState } from '../../../state/index';
import { TnTableStateFacade } from '../../../table/table-state-facade';
import { tableActions } from './actions/carriers.actions';
import { tableSelectors } from './selectors/carriers.selectors';

@Injectable({
  providedIn: 'root',
})
export class TnDirectoryCarriersFacade extends TnTableStateFacade {
  constructor(public store: Store<ITnState>) {
    super(store, tableActions, tableSelectors);
  }
}
