import { AfterContentInit, AfterViewInit, Component, ContentChild, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { TnTwInputDirective } from '../tw-input/tw-input.directive';

export interface ITwInputWithSelectOption {
  value: string;
  title: string;
  subtitle?: string;
}

@UntilDestroy()
@Component({
  selector: 'transport-tw-input-with-select[selectControl][formGroup]',
  templateUrl: './tw-input-with-select.component.html',
  styleUrls: ['./tw-input-with-select.component.scss'],
})
export class TnTwInputWithSelectComponent implements AfterContentInit, AfterViewInit, OnInit {
  @Input() public options: ITwInputWithSelectOption[] = [];

  @Input() public size: 'sm' | 'md' | 'lg' = 'md';

  @Input() public selectorClass = '';

  @Input() public styleInput = true;

  @Input() public selectControl!: AbstractControl;

  @Input() public formGroup!: AbstractControl;

  @Input() public rightAddon!: string;

  @ContentChild(TnTwInputDirective) public input?: TnTwInputDirective;

  public currentSelect: string | null = null;

  public currentSelectTitle = '';

  @ViewChild('selectorDiv') private readonly selectorDiv?: ElementRef;

  @ViewChild('selectorShadow') private readonly selectorShadow?: ElementRef;

  public get classSizeAddon(): string {
    return this.size ? '-' + this.size : '';
  }

  constructor() {}

  public getBlockClass() {
    const result: string[] = [];
    result.push(`tw-input-block-${this.size}`);
    if (this.formGroup.invalid && this.formGroup.touched) {
      result.push('tw-input-block-error');
    }
    if (this.formGroup.disabled) {
      result.push('tw-input-block-disabled');
    }
    return result.join(' ');
  }

  public ngOnInit() {
    this.selectControl.valueChanges.pipe(untilDestroyed(this)).subscribe(v => {
      this.calcCurrent(v);
    });
    this.calcCurrent(this.selectControl.value);
  }

  public ngAfterContentInit() {
    if (this.styleInput) {
      this.input?.el.nativeElement.classList.add(`tw-input-element-${this.size}`);
    }
  }

  public ngAfterViewInit() {
    // eslint-disable-next-line compat/compat -- We dont support IE
    const observer = new ResizeObserver(entries => {
      const width = entries[0].contentRect.width;
      if (this.selectorShadow) {
        this.selectorShadow.nativeElement.style.width = `${width}px`;
      }
    });
    this.selectorDiv && observer.observe(this.selectorDiv.nativeElement);
  }

  public selectOption(value: string) {
    this.selectControl.setValue(value);
  }

  private calcCurrent(value: string) {
    this.currentSelect = value;
    this.currentSelectTitle = this.options.find(o => o.value === value)?.title ?? '';
  }
}
