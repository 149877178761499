<form class="flex flex-col" [formGroup]="form" *ngIf="form" transportConnectForm="body" [clearFormStateOnDestroy]="true">
  <ng-content></ng-content>
  <mat-form-field>
    <mat-label class="required-asteriks">
      {{ 'carrier.directory.vehicle.details.formLabels.bodyType' | translate }}
    </mat-label>
    <mat-select
      formControlName="bodyType"
      [transportSelectErrorState]="isUseMatStep"
      data-test="body-info-type"
    >
      <mat-option *ngFor="let option of bodyTypes" [value]="option.id">
        {{ option.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.controls.bodyType.errors?.required">
      {{ 'shared.errors.required' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field *ngIf="form.controls.bodyType.value">
    <mat-label class="required-asteriks">
      {{ 'carrier.directory.vehicle.details.formLabels.bodySubtype' | translate }}
    </mat-label>
    <mat-select
      formControlName="bodySubtype"
      [transportSelectErrorState]="isUseMatStep"
      data-test="body-info-subtype"
    >
      <mat-option *ngFor="let option of bodySubtypes[bodyTypeValue]" [value]="option.id">
        {{ option.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.controls.bodySubtype.errors?.required">
      {{ 'shared.errors.required' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label class="required-asteriks">
      {{ 'carrier.directory.vehicle.details.formLabels.bodyLoadingTypes' | translate }}
    </mat-label>
    <mat-select
      formControlName="bodyLoadingTypes"
      multiple
      [transportSelectErrorState]="isUseMatStep"
      data-test="body-info-loading-types"
    >
      <mat-option *ngFor="let option of loadingTypes" [value]="option.id">
        {{ option.name }}
      </mat-option>
    </mat-select>
    <mat-error *ngIf="form.controls.bodyLoadingTypes.errors?.required">
      {{ 'shared.errors.required' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label class="required-asteriks">
      {{ 'carrier.directory.vehicle.details.formLabels.bodyVolume' | translate }}
    </mat-label>
    <input
      transportNumberOnly
      transportTrim
      matInput
      type="text"
      formControlName="bodyVolume"
      data-test="body-info-volume"
    >
    <mat-error *ngIf="form.controls.bodyVolume.errors?.required">
      {{ 'carrier.directory.vehicle.details.validation.notEmptyVolumeBody' | translate }}
    </mat-error>
    <mat-error *ngIf="form.controls.bodyVolume.errors?.max">
      {{ 'carrier.directory.vehicle.details.validation.max99' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <mat-label class="required-asteriks">
      {{ 'carrier.directory.vehicle.details.formLabels.bodyCapacity' | translate }}
    </mat-label>
    <input
      transportNumberOnly
      transportTrim
      matInput
      type="text"
      formControlName="bodyCapacity"
      data-test="body-info-capacity"
    >
    <mat-error *ngIf="form.controls.bodyCapacity.errors?.required">
      {{ 'shared.errors.required' | translate }}
    </mat-error>
    <mat-error *ngIf="form.controls.bodyCapacity.errors?.max">
      {{ 'carrier.directory.vehicle.details.validation.max99' | translate }}
    </mat-error>
    <mat-error *ngIf="form.controls.bodyCapacity.errors?.min">
      {{ 'carrier.directory.vehicle.details.validation.min1' | translate }}
    </mat-error>
  </mat-form-field>
</form>
