import { FormArray, FormControl, FormGroup } from '@angular/forms';

import { ISla, ISlaTrackableCriterion } from './sla.interface';

export interface ISlaForm extends FormGroup {
  controls: {
    name: FormControl;
    description: FormControl;
    nonTrackableCriteria: INonTrackableCriteriaFromArray;
    trackableCriteria: ITrackableCriteriaFormGroup;
  };
}

export interface ITrackableCriteriaFormGroup extends FormGroup {
  controls: Record<string, ITrackableCriteriaForm>;
}

export interface ITrackableCriteriaForm extends FormGroup {
  controls: {
    criteriaId: FormControl;
    description: FormControl;
    isActive: FormControl;
    thresholdValue: FormControl;
  };
}

interface INonTrackableCriteriaFromArray extends FormArray {
  controls: INonTrackableCriteriaFrom[];
}

export interface INonTrackableCriteriaFrom extends FormGroup {
  controls: {
    name: FormControl;
    description: FormControl;
  };
}

export type ITrackableCriterionFormValue = Pick<Required<ISlaTrackableCriterion>, 'description' | 'isActive' | 'thresholdValue'> & {
  id: string | null;
  criterionId: string;
};

export type ISlaFormValue = Pick<Required<ISla>, 'name' | 'description' | 'nonTrackableCriteria'> & {
  trackableCriteria: ITrackableCriterionFormValue[] | null;
};

export function slaToFormValue(sla: ISla): ISlaFormValue {
  const { name, description, nonTrackableCriteria, trackableCriteria } = sla;
  return {
    name: typeof name === 'undefined' ? null : name,
    description: typeof description === 'undefined' ? null : description,
    nonTrackableCriteria: typeof nonTrackableCriteria === 'undefined' ? null : nonTrackableCriteria,
    trackableCriteria:
      typeof trackableCriteria === 'undefined' || trackableCriteria === null
        ? null
        : trackableCriteria.map(v => slaTrackableCriterionToFormValue(v, v.criterion.id)),
  };
}

export function slaTrackableCriterionToFormValue(criterion: ISlaTrackableCriterion, criterionId: string): ITrackableCriterionFormValue {
  const { id, description, isActive, thresholdValue } = criterion;
  return {
    id: typeof id === 'undefined' ? null : id,
    criterionId,
    description: typeof description === 'undefined' ? null : description,
    isActive: typeof isActive === 'undefined' ? null : isActive,
    thresholdValue: typeof thresholdValue === 'undefined' ? null : thresholdValue,
  };
}
