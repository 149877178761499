<div *ngIf='leftAddon' [ngClass]="
  ['addon', 'bg-gray-100 rounded-l-md border-r border-solid border-gray-100 text-gray-700 flex items-center ',
  'tw-input-spacing'+classSizeAddon, size ? 'text-'+size : '']"
>{{ leftAddon }}</div>

<div [ngClass]="['tw-input-container tw-input-container' + classSizeAddon, !leftAddon ? 'rounded-l-md' : '', !rightAddon ? 'rounded-r-md' : '']" >
  <ng-content></ng-content>
</div>

<div *ngIf='rightAddon && !isPasswordType()' [ngClass]="
  ['addon', 'bg-gray-100 rounded-r-md border-l border-solid border-gray-100 text-gray-700 flex items-center ',
  'tw-input-spacing'+classSizeAddon, size ? 'text-'+size : '']"
>{{ rightAddon }}</div>

<div *ngIf='isPasswordType()' [ngClass]="
  ['addon', 'bg-gray-100 rounded-r-md border-l border-solid border-gray-100 text-gray-700 flex items-center ',
  'tw-input-spacing'+classSizeAddon, size ? 'text-'+size : '', 'cursor-pointer']"
  (click)="clickShowPassword($event)"
>
  <mat-icon>{{showPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
</div>
