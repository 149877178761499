import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { TnYamapsCoreModule } from '@transport/client-yamaps-core';
import { TnCoreModule } from '@transport/core';
import { CustomControlsModule } from '@transport/custom-controls';
import { TnUiComponentsModule } from '@transport/ui-components';
import { TnUiDirectivesModule } from '@transport/ui-directives';
import { TnAlertService, TnUiKitModule } from '@transport/ui-kit';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnUiPipesModule } from '@transport/ui-pipes';
import { TnUiStoreModule } from '@transport/ui-store';
import { TnUiTranslateModule } from '@transport/ui-translate';
import { NgxMaskDirective, NgxMaskPipe, NgxMaskService, provideEnvironmentNgxMask } from 'ngx-mask';

import { TnBlacklistSectionComponent } from './components/carrier/black-list-section/black-list-section.component';
import { TnDriverPageComponent } from './components/carrier/driver-page/driver-page.component';
import { TnDriversComponent } from './components/carrier/drivers/drivers.component';
import { TnDriversFilterModal } from './components/carrier/drivers/drivers-filter/drivers-filter.component';
import { TnDriversToolbarComponent } from './components/carrier/drivers/drivers-toolbar/drivers-toolbar.component';
import { TnDirectoryRoutingModule } from './transport-directory-routing.module';

@NgModule({
  imports: [
    TnCoreModule,
    TnUiComponentsModule,
    TnUiDirectivesModule,
    TnUiMaterialModule,
    TnUiTranslateModule,
    TnUiPipesModule,
    TnUiStoreModule,
    TnDirectoryRoutingModule,
    TnYamapsCoreModule,
    TnUiKitModule,

    CustomControlsModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  declarations: [
    TnDriversComponent,
    TnDriverPageComponent,
    TnDriversFilterModal,
    TnDriversToolbarComponent,
    TnBlacklistSectionComponent,
  ],
  exports: [TnDriversComponent, TnDriverPageComponent],
  providers: [
    TnAlertService,
    NgxMaskService,
    provideEnvironmentNgxMask(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TnDirectoryModule {}
