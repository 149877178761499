import { createFeatureSelector, createSelector } from '@ngrx/store';
import { transport } from '@transport/proto';
import { IUiSettings, USER_ROLE } from '@transport/ui-interfaces';

import { ICurrentUserState } from './current-user.reducer';
import { TnCarrierUserPermissionsMap } from './user-permission-carrier.interface';
import { TnOwnerUserPermissionsMap } from './user-permission-owner.interface';

const getCurrentUserSelector = createFeatureSelector<ICurrentUserState>('currentUser');

export const getCurrentUser = createSelector(getCurrentUserSelector, (state: ICurrentUserState) => {
  return state.user;
});

export const getCurrentUserProfile = createSelector(getCurrentUserSelector, (state: ICurrentUserState) => {
  return state.profile;
});

export const getCurrentUserAuctionParameters = createSelector(getCurrentUserSelector, (state: ICurrentUserState) => {
  return state.profile.organization?.auctionParameters;
});

export const getCurrentUserRole = createSelector(getCurrentUser, user => {
  return user.role;
});

export const getCurrentUserNotificationSettings = createSelector(getCurrentUserSelector, (state: ICurrentUserState) => {
  return state.profile?.userSettings?.notificationSettings as transport.UserProfile.UserSettings.INotificationSettingsOverallType;
});

export const getLoadingState = createSelector(getCurrentUserSelector, (state: ICurrentUserState) => {
  return state.loading;
});

export const getServerErrorState = createSelector(getCurrentUserSelector, (state: ICurrentUserState) => {
  return state.hasServerError;
});

function getOwnerPermissions(permissionsMap: Record<string, unknown> = {}): TnOwnerUserPermissionsMap {
  return {
    users: {
      read: Boolean(permissionsMap.can_manage_users_and_their_roles),
      manage: Boolean(permissionsMap.can_manage_users_and_their_roles),
    },
    orders: {
      read: true,
      manage: Boolean(permissionsMap.can_manage_general_reference_and_orders),
      filterOrganization: Boolean(permissionsMap.can_view_organization_orders),
    },
    generalReference: {
      read: false,
      manage: Boolean(permissionsMap.can_manage_general_reference_and_orders),
    },
    companyProfile: {
      read: false,
      manage: Boolean(permissionsMap.can_manage_company_profile),
    },
    carriersPartners: {
      read: false,
      manage: Boolean(permissionsMap.can_manage_carriers_partners),
    },
    subOrganizationProfile: {
      read: true,
      manage: Boolean(permissionsMap.is_company_group_admin),
    },
    reconciliationAct: {
      read: Boolean(permissionsMap.can_use_acts_matcher),
      manage: Boolean(permissionsMap.can_use_acts_matcher),
    },
    integrationToken: Boolean(permissionsMap.can_manage_integration_token)
      ? {
          read: Boolean(permissionsMap.can_manage_integration_token),
          manage: Boolean(permissionsMap.can_manage_integration_token),
        }
      : null,
    source: permissionsMap,
  };
}

function getCarrierPermissions(permissionsMap: Record<string, unknown> = {}): TnCarrierUserPermissionsMap {
  return {
    documents: {
      read: true,
      manage: Boolean(permissionsMap.carrier_can_manage_documents),
    },
    generalReference: {
      read: true,
      manage: Boolean(permissionsMap.carrier_can_manage_general_reference),
    },
    reconciliationAct: {
      read: Boolean(permissionsMap.can_use_acts_matcher),
      manage: Boolean(permissionsMap.can_use_acts_matcher),
    },
    carriersRatingReport: {
      read: Boolean(permissionsMap.isWorkingWithTN),
      manage: false,
    },
    serviceRegistry: {
      read: Boolean(permissionsMap.carrier_has_access_on_transport_services),
    },
    carriersBiddingOrders: {
      read: Boolean(permissionsMap.isWorkingWithTN),
      manage: false,
    },
    users: {
      read: Boolean(permissionsMap.carrier_can_manage_users_and_permissions),
      manage: Boolean(permissionsMap.carrier_can_manage_users_and_permissions),
    },
    organizationProfile: {
      read: true,
      manage: Boolean(permissionsMap.carrier_can_manage_organization_profile),
    },
  };
}

export const getPermissions = createSelector(getCurrentUserSelector, (state: ICurrentUserState) => {
  const permissionsMap: Record<string, unknown> = {};
  if (Boolean(state.user.permissions)) {
    state.user.permissions?.reduce((acc, cur) => {
      acc[cur.codename ?? ''] = true;
      return acc;
    }, permissionsMap);
  }

  let result: TnOwnerUserPermissionsMap & TnCarrierUserPermissionsMap;
  if (state.user.role === USER_ROLE.CARGO_OWNER) {
    result = getOwnerPermissions(permissionsMap);
  } else {
    permissionsMap.isWorkingWithTN = state.user.isWorkingWithTN;
    result = getCarrierPermissions(permissionsMap);
  }

  return result;
});

export const isLoggedIn = createSelector(getCurrentUserSelector, (state: ICurrentUserState) => {
  return state.user.token;
});

export const uiSettings = createSelector(getCurrentUserSelector, (state: ICurrentUserState) => {
  return state.user.uiSettings as IUiSettings | null | undefined;
});

export const warningTextBanner = createSelector(getCurrentUserSelector, (state: ICurrentUserState) => {
  return state.warningTextBanner;
});
