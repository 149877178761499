import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { TCargoPackagingTypeCode } from '@transport/ui-interfaces';
import memo from 'memo-decorator';

/**
 * Pipe for converting cargoTypePackaging to human readable value
 */
@Pipe({
  name: 'cargoTypePackagingType',
})
export class TnCargoPackagingTypePipe implements PipeTransform {
  constructor(public translate: TranslateService) {}

  /**
   * @param value cargo packaging type
   */
  @memo()
  public transform(value: TCargoPackagingTypeCode): string {
    return Boolean(value) ? this.translate.instant(`owner.cargoType.packagingType.${value.toLowerCase()}`) : '';
  }
}
