import { TnUserPermissionsMap } from './user-permission.interface';

/**
 * Owner user permissions.
 */
export class TnOwnerUserPermissionsMap extends TnUserPermissionsMap {
  public users? = {
    read: false,
    manage: false,
  };

  public orders? = {
    read: false,
    manage: false,
    filterOrganization: false,
  };

  public generalReference? = {
    read: false,
    manage: false,
  };

  public companyProfile? = {
    read: false,
    manage: false,
  };

  public carriersPartners? = {
    read: false,
    manage: false,
  };

  public subOrganizationProfile? = {
    read: false,
    manage: false,
  };
}
