export enum SSO_ACTION_TYPE {
  SET = 'set',
  GET = 'get',
  RETURN = 'return',
  LOGOUT = 'logout',
}

export interface ICommandInterface {
  action?: SSO_ACTION_TYPE;
  data?: unknown;
}
