import { Directive, EventEmitter, Output, TemplateRef, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { UntilDestroy } from '@ngneat/until-destroy';

@UntilDestroy()
@Directive()
export abstract class TnFilterBaseDirective<T, N> {
  @Output() public readonly filterChange = new EventEmitter<T>();

  @ViewChild('dialogRef') public dialogTemplateRef?: TemplateRef<N>;

  public abstract filterForm: FormGroup;

  public abstract defaultValues: T;

  public abstract filterData: T;

  public abstract apply(): void;

  public abstract openDialog(): void;

  public dialogRef?: MatDialogRef<N>;

  constructor() {}

  public cancel(): void {
    this.filterForm.reset();
    this.dialogRef?.close();
  }

  public clearAll() {
    this.filterForm.reset();
  }

  public get activatedFiltersCount() {
    let result = 0;
    Object.keys(this.filterData as Object).forEach(key => {
      const isActiveValue = this.isActiveValue(this.filterData[key]);
      result += isActiveValue ? 1 : 0;
    });
    return result;
  }

  public get hasChanges() {
    return (
      Object.keys(this.filterForm.controls).filter(key => {
        const formValue = this.filterForm.controls[key].value;
        return Boolean(formValue) && JSON.stringify(formValue) !== JSON.stringify(this.defaultValues[key]);
      }).length > 0
    );
  }

  protected isActiveValue(value) {
    return typeof value === 'boolean' || (Boolean(value) && (!Array.isArray(value) || [...value].length > 0));
  }
}
