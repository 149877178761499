<div class="flex items-start gap-1">
  <common-select
    *ngIf="withCitizenNumber"
    [class]="size === 'sm' ? 'w-[80px]' : 'w-[120px]'"
    [size]="$any(size)"
    [label]="label"
    [labelClasses]="['whitespace-nowrap', 'text-gray-700', 'text-' + size]"
    [allowClear]="false"
    [value]="citizenNumberFormControl.value"
    [control]="$any(citizenNumberFormControl)"
    [required]="required"
    [dataSource]="$any(citizenNumbers)"
    [attr.data-test]="dataTest + '-citizen-number'"
  ></common-select>

  <transport-tw-input-field
    [class]="inputFieldClass"
    [ngClass]="getMarginTop()"
    [control]="formControl"
    [size]="size"
    [label]="withCitizenNumber ? '' : label"
    [errorsSize]="errorsSize"
    [showErrors]="showErrors"
    [required]="withCitizenNumber ? false : required"
    [errorToMessage]="errorToMessage"
  >
    <transport-tw-input
      class="relative"
      [size]="size"
      [control]="formControl"
    >
      <ng-content></ng-content>
      <input
        tw-input
        [prefix]="prefix"
        [formControl]="$any(phoneControl)"
        [disabled]="disabled"
        [mask]="!!phoneControl.value || !disabled ? regExpPhone.mask : ''"
        [patterns]="$any(regExpPhone.patterns)"
        [showMaskTyped]="true"
        [attr.data-test]="dataTest"
        (blur)="onBlur($event)"
        (input)="onInput($event)"
    />
    </transport-tw-input>
  </transport-tw-input-field>
</div>
