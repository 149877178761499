import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { APP_ENV, TnAppEnvironment } from '@transport/ui-interfaces';
import { BehaviorSubject, firstValueFrom, of, tap } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthTokenService {
  public isTokenValid$ = new BehaviorSubject<boolean>(true);

  constructor(
    @Inject(APP_ENV) private readonly appEnv: TnAppEnvironment,
    private readonly http: HttpClient,
    private readonly router: Router,
  ) {}

  public init(): Promise<unknown> {
    const matches = document.cookie.match(new RegExp('(?:^|; )' + 'userService'.replace(/([.$?*|{}()[\]\\/+^])/g, '\\$1') + '=([^;]*)'));
    // TMS-7989128 TMS-8220259 всегда берем последнюю сессию тк для тонны это тонна а ТМС видит только свои куки
    const coockie = matches ? decodeURIComponent(matches[matches.length - 1]) : null;
    const parsedCockie = JSON.parse(coockie ?? '{}');
    const apiBaseUrl = `${window.location.protocol}//${this.appEnv.apiDomain}/auth/check_token`;
    if (parsedCockie?.token && !window.location.href.includes('/passport/')) {
      return firstValueFrom(this.http.get(apiBaseUrl).pipe(tap((res: any) => this.isTokenValid$.next(res.is_valid))));
    }
    this.isTokenValid$.next(true);
    return firstValueFrom(of(true));
  }
}
