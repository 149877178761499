import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { getOrganizationDomain } from '@transport/ui-utils';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';
import { Router } from '@angular/router';

/**
 * Authentication token interceptor.
 * Adds authentication token to http requests.
 */
@Injectable({
  providedIn: 'root',
})
export class TnTMSSubdomainInterceptorService implements HttpInterceptor {
  /**
   * Constructor.
   */
  constructor(private readonly domainService: TnDomainService, private router: Router) {}

  /**
   * Intercepts http request, adds X-TMS-Org header to all requests.
   * @param req Http request
   * @param next Http request handler
   */
  public intercept(req: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    let request = req;
    const checkDomain = request.url.includes('auth/organization_by_domain');
    if (this.domainService.subDomain) {
      request = request.clone({
        setHeaders: getOrganizationDomain(this.domainService.subDomain),
      });
    }

    return next.handle(request).pipe(
      tap({
        error: (err: HttpErrorResponse) => {
          if (err.status === 404 && checkDomain) {
            const localHostPort = this.domainService.domain.includes('lv4.lv3.lv2.localhost') ? ':4200' : '';
            window.location.href = `${window.location.protocol}//${this.domainService.domain}${localHostPort}`;
          }
          console.error(err);
        },
      }),
    );
  }
}
