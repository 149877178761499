import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { transport } from '@transport/proto';
import { USER_ROLE } from '@transport/ui-interfaces';
import { RedirectAfterLoginService } from 'libs/marketplace-auth/src/lib/shared/services/redirect-after-login.service';
import { combineLatest, Observable, throwError } from 'rxjs';
import { catchError, first, map } from 'rxjs/operators';

import { TnDomainService } from '../../../../../transport-ui-services/src/lib/domain/domain.service';
import { isLoggedIn, TnCurrentUserFacade, TnUserService } from '../../current-user';
import { TnGqlClientCarrierService } from '../../gql-client-carrier';
import { TnGqlClientSharedService } from '../../gql-client-shared';
import { TnPassportFacade } from '../../passport';
import { TnToasterFacade } from '../../toaster';
import { SERVER_MARKETPLACE_URL } from '@transport/ui-utils';

/**
 * Authenticated guard.
 */
@Injectable({
  providedIn: 'root',
})
export class TnAuthenticatedGuard implements CanActivate {
  public authUrls = ['passport/login'];

  private cargoOwnerDomain: string | null | undefined;

  private carrierDomains: (string | null | undefined)[] | null = null;

  constructor(
    private readonly store: Store,
    private readonly passportFacade: TnPassportFacade,
    private readonly router: Router,
    public readonly tnUserService: TnUserService,
    public readonly toastFacade: TnToasterFacade,
    private readonly user: TnCurrentUserFacade,
    private readonly gql: TnGqlClientSharedService,
    private readonly gqlClientCarrierService: TnGqlClientCarrierService,
    private readonly domainService: TnDomainService,
    private readonly redirectAfterLogin: RedirectAfterLoginService,
  ) {}

  /**
   * Redirects user to passport/login path if user is not logged in.
   */
  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const path = next.url.toString();
    const hasMarketSubdomain = this.domainService.domain.includes(SERVER_MARKETPLACE_URL);

    if(!path
        && !this.domainService.subDomain
        && !hasMarketSubdomain
        && !Boolean(this.tnUserService.getCookie(this.tnUserService.getCookieName()))
      ) {
      window.location.href = `${window.location.protocol}//${this.domainService.domain}/lp`;
      return false;
    }
    if (Boolean(this.tnUserService.getCookie(this.tnUserService.getCookieName()))) {
      const obs =
        this.user.currentUserRole === USER_ROLE.CARRIER ? this.getDomainsCarrierFromServer() : this.getDomainCargoOwnerFromServer();
      return combineLatest([this.store.select(isLoggedIn), obs]).pipe(
        // TODO: technical debt (need refactoring)
        // eslint-disable-next-line complexity
        map(([token, data]) => {
          if (this.user.currentUserRole === USER_ROLE.CARGO_OWNER) {
            // data = domain
            if (Boolean(data) && data !== this.domainService.subDomain) {
              const localHostPort = this.domainService.domain.includes('lv4.lv3.lv2.localhost') ? ':4200' : '';
              const pathname = window.location.pathname;
              window.location.href = `${window.location.protocol}//${data}.${this.domainService.domain}${localHostPort}${
                pathname !== '/' ? pathname : '/orders'
              }`;
              return false;
            }
          } else if (data?.length !== 0 && data !== undefined && Array.isArray(data)) {
            // поверяем имеет ли доступ к субдомену ГП
            // const isFoundSubdomain = Boolean(data.find(sb => sb === this.domainService.subDomain));
            // if (!isFoundSubdomain && !Boolean(this.domainService.subDomain) && !window.location.href.includes('/profile/subdomain')) {
            //   const localHostPort = this.domainService.domain.includes('lv4.lv3.lv2.localhost') ? ':4200' : '';
            //   window.location.href = `${window.location.protocol}//${this.domainService.domain}${localHostPort}/profile/subdomain`;
            //   return false;
            // }
          }
          if (Boolean(token) || Boolean(this.authUrls.find(url => url === path))) {
            return true;
          }
          void this.passportFacade.setRedirectUrl(state.url).pipe(first()).subscribe();
          this.redirectAfterLogin.pushUrlToLocalStorage(state.url);
          return this.router.createUrlTree(['/passport/login']);
        }),
        catchError(err => {
          return throwError(err);
        }),
      );
    }
    void this.passportFacade.setRedirectUrl(state.url).pipe(first()).subscribe();
    this.redirectAfterLogin.pushUrlToLocalStorage(state.url);
    return this.router.createUrlTree(['/passport/login']);
  }

  private getDomainCargoOwnerFromServer(): Observable<string | null | undefined> {
    return new Observable<string | null | undefined>(obs => {
      if (Boolean(this.cargoOwnerDomain)) {
        obs.next(this.cargoOwnerDomain);
        obs.complete();
      } else {
        void this.gql
          .getUserProfileLogoAndName(USER_ROLE.CARGO_OWNER)
          .pipe(
            map((res: transport.OwnerOrganizationSubdomain) => {
              return res.organization.subdomain;
            }),
            catchError(err => {
              obs.next('');
              obs.complete();
              return throwError(() => err);
            }),
          )
          .subscribe(subdomain => {
            this.cargoOwnerDomain = subdomain;
            obs.next(subdomain);
            obs.complete();
          });
      }
    });
  }

  private getDomainsCarrierFromServer(): Observable<(string | null | undefined)[]> {
    return new Observable<(string | null | undefined)[]>(obs => {
      if (this.carrierDomains) {
        obs.next(this.carrierDomains);
        obs.complete();
      } else {
        void this.gqlClientCarrierService
          .getCargoOwnversWithBusinessRelationshipData()
          .pipe(
            map((res: transport.CargoOwnersDataWithBusinessRelationship) => {
              if (Boolean(res.cargoOwnersDataWithBusinessRelationship) && res.cargoOwnersDataWithBusinessRelationship !== null) {
                return res.cargoOwnersDataWithBusinessRelationship.map(el => el.subdomain);
              }
              return [];
            }),
            catchError(err => {
              obs.next([]);
              obs.complete();
              return throwError(() => err);
            }),
          )
          .subscribe(res => {
            this.carrierDomains = res;
            obs.next(res);
          });
      }
    });
  }
}
