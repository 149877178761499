import { Directive, HostListener, Input, OnChanges, SimpleChange, SimpleChanges } from '@angular/core';

interface IInputChanges extends SimpleChanges {
  printSectionId: SimpleChange;
  printTitle: SimpleChange;
  useExistingCss: SimpleChange;
  printDelay: SimpleChange;
  printStyleRefs: SimpleChange;
  styleSheetFile: SimpleChange;
}

@Directive({
  selector: '[transportPrint], button[transportPrint]',
})
export class TnPrintDirective implements OnChanges {
  private styleSheetFileRef = '';

  @Input() public printSectionClass = 'transport-print-section';

  @Input() public printTitle = 'Print title';

  @Input() public printDelay = 0;

  @Input() public printStyleRefs: string[] = [];

  @Input() public styleSheetFile = '';

  /**
   * Should be called in on changes hook.
   */
  private newStyleSheetFileRef(cssList: string) {
    let result = this.styleSheetFileRef;
    const linkTagFn = (cssFileName: string) => `<link rel="stylesheet" type="text/css" href="${cssFileName}">`;
    if (cssList.includes(',')) {
      const valueArr = cssList.split(',');
      for (const val of valueArr) {
        result = this.styleSheetFileRef + linkTagFn(val);
      }
    } else {
      result = linkTagFn(cssList);
    }
    return result;
  }

  /**
   * @returns the string that create the stylesheet which will be injected
   * later within <style></style> tag.
   */
  private getStyleValues() {
    return `<style> ${this.printStyleRefs.join(' ').replace(/,/g, ';')} </style>`;
  }

  private getElementTag(tag: keyof HTMLElementTagNameMap): string {
    const html: string[] = [];
    const elements = document.getElementsByTagName(tag);
    for (let index = 0; index < elements.length; index += 1) {
      html.push(elements[index].outerHTML);
    }
    return html.join('\r\n');
  }

  /**
   * Prepares html for the print view.
   * @note to debug print view comment line
   * window.addEventListener('load', triggerPrint, false);
   */
  private getHtml(styles: string, links: string, printContents: string, scripts: string): string {
    return `
        <html class="mat-typography">
            <head>
            <title>${this.printTitle}</title>
            ${styles}
            ${links}
            ${this.getStyleValues()}
            ${this.styleSheetFileRef}
            </head>
            <body>
            ${printContents}
            ${scripts}
            <script defer>
                function triggerPrint(event) {
                window.removeEventListener('load', triggerPrint, false);
                setTimeout(function() {
                    window.print();
                    setTimeout(function() { window.close(); }, 0);
                }, ${this.printDelay});
                }
                window.addEventListener('load', triggerPrint, false);
            </script>
            </body>
        </html>`;
  }

  @HostListener('click')
  public print(): void {
    let styles = '';
    let links = '';
    const scripts = '';

    styles = this.getElementTag('style');
    links = this.getElementTag('link');

    const popupWin = window.open('', '_blank', 'top=0,left=0,height=auto,width=auto');
    const printContents = document.getElementsByClassName(this.printSectionClass).item(0)?.innerHTML;

    if (popupWin !== null) {
      void popupWin.document.open();
      const html = this.getHtml(styles, links, printContents ?? '', scripts);
      void popupWin.document.write(html);
      void popupWin.document.close();
    }
  }

  public ngOnChanges(changes: IInputChanges): void {
    const styleSheetFileChange: string | undefined = changes.styleSheetFile.currentValue;
    if (typeof styleSheetFileChange !== 'undefined') {
      this.styleSheetFileRef = this.newStyleSheetFileRef(changes.styleSheetFile.currentValue);
    }
  }
}
