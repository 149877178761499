import { USER_ROLE } from '../user/user-common.interface';
import { IReconcilationActOrganizationDto } from './dto/reconciliation-act-organization.dto';
import { TnReconcilationActRule } from './reconciliation-act-rule';

export class TnReconcilationActOrganization {
  public id: number;

  public name: string;

  public defaultRule?: TnReconcilationActRule;

  /**
   * CARRIER или OWNER. CARGO_OWNER не используется!
   */
  public role: USER_ROLE.OWNER | USER_ROLE.CARRIER;

  constructor(id: number, name: string, role: USER_ROLE.OWNER | USER_ROLE.CARRIER) {
    this.id = id;
    this.name = name;
    this.role = role;
  }

  public static fromDto(dto: IReconcilationActOrganizationDto): TnReconcilationActOrganization {
    const role = dto.role === 'CARRIER' ? USER_ROLE.CARRIER : USER_ROLE.OWNER;
    const result = new TnReconcilationActOrganization(dto.id, dto.name, role);
    if (Boolean(dto.rule_id) && Boolean(dto.rule_name)) {
      result.defaultRule = new TnReconcilationActRule(dto.rule_id, dto.rule_name);
    }
    return result;
  }
}
