<div mat-dialog-title class="!flex !mt-4">
  <h2>{{ 'shared.directory.loadingPlacesDetails.modals.daDataModal.title' | translate }}</h2>
</div>
<mat-dialog-content class="mat-typography">
  <mat-progress-bar class="modal-sticky-progress-bar" *ngIf="$any(isLoading$ | async)" mode="indeterminate"></mat-progress-bar>

  <div class="flex">
    <yamap-root
      class="flex flex-col min-w-[70%] max-w-[70%]"
      height="400px"
      (yamapReady)="mapReady()"
      [getCoordinatesByClick]="true"
      (getCoordinatesByClickChange)="getCoordinatesByClickChange($event)"
    ></yamap-root>

    <section *ngIf="!$any(isLoading$ | async)" class="flex flex-col gap-[10px] right-column min-w-[30%] max-w-[30%]">
      <div *ngIf="addressSuggestions$ | async as addressSuggestions">
        <ng-container *ngIf="addressSuggestions.length > 0">
          <mat-card
            appearance="outlined"
            *ngFor="let item of addressSuggestions; let i = index"
            class="flex p-2"
            (click)="selectAddress(item, i)"
            [attr.data-address-index]="i"
          >
            {{ item.fullAddress }}
          </mat-card>
        </ng-container>

        <ng-container *ngIf="addressSuggestions.length === 0">
          <div class="empty-list flex justify-center items-center">
            {{ 'shared.directory.loadingPlacesDetails.modals.daDataModal.emtpy' | translate }}
          </div>
        </ng-container>
      </div>
    </section>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ 'shared.directory.loadingPlacesDetails.modals.daDataModal.cancel' | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="select()" [disabled]="isSelectButtonDisabled">
    {{ 'shared.directory.loadingPlacesDetails.modals.daDataModal.confirm' | translate }}
  </button>
</mat-dialog-actions>
