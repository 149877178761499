import { createFeatureSelector, createSelector } from '@ngrx/store';

import { IDirectoryState } from '../../../directory-state.interface';
import { IDriverState } from '../reducers/driver.reducer';

const getDirectoryState = createFeatureSelector<IDirectoryState>('directory');
const driverStateSelector = createSelector(getDirectoryState, (state: IDirectoryState) => state.driver);

export const driverId = createSelector(driverStateSelector, (state: IDriverState) => state.id);

export const changedDriverStatus = createSelector(driverStateSelector, (state: IDriverState) => state.changedDriverStatus);

export const agentCarriers = createSelector(driverStateSelector, (state: IDriverState) => state.agentCarriers);
