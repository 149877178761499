<h1 mat-dialog-title>
  <strong>{{ 'shared.clientTimeWarning.title' | translate }}</strong>
</h1>

<div mat-dialog-content>
  <div>
    {{ 'shared.clientTimeWarning.main' | translate }}
  </div>
  <div class="recommendations">
    <div>
      {{ 'shared.clientTimeWarning.recommendations' | translate }}
    </div>
    <div>
      <a href="https://yandex.ru/support/common/support/date-time.html" target="_blank">
        https://yandex.ru/support/common/support/date-time.html
      </a>
    </div>
  </div>
</div>
<div mat-dialog-actions class="flex justify-end items-center">
  <button mat-button (click)="confirm()" cdkFocusInitial>
    {{ 'shared.clientTimeWarning.confirm' | translate }}
  </button>
</div>
