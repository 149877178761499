import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'transport-filter-dialog',
  templateUrl: './filter-dialog.component.html',
  styleUrls: ['./filter-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnFilterDialogComponent {
  @Input() public hasChanges = true;

  @Output() public readonly clearAll = new EventEmitter();

  @Output() public readonly apply = new EventEmitter();

  @Output() public readonly cancel = new EventEmitter();

  constructor() {}
}
