import { Router } from '@angular/router';
import { INavigateFunction } from '../../interfaces/navigate-function.interface';

/**
 * Redirects user implementing opening new tabs. Function doesn't subscribe any events on its own.
 * @param {Router} router - An instance of a Router from `@angular/router`.
 * @param {Function} linkBuilder - Function gets `rowData` and returns a subdirectory part of url path where user is supposed to be redirected.
 */
export function getStandardNavigateFunction<T>(router: Router, linkBuilder: (rowData: T) => string) {
  const standardNavigateFunction: INavigateFunction<T> = (event: Event, rowData: T, openInNewTab: boolean) => {
    const subdirectory = linkBuilder(rowData);

    if (openInNewTab) {
      const url = `${window.location.protocol}//${window.location.host}/${subdirectory}`;
      window.open(url, '_blank');
      return;
    }

    const basicPath = subdirectory.split('?')[0];
    const queryParams: Record<string, string> = {};
    if (subdirectory.indexOf('?') !== -1) {
      const params = subdirectory.split('?')[1];
      new URLSearchParams(params).forEach((value, key) => {
        queryParams[key] = value;
      });
    }
    router.navigate([basicPath], { queryParams }).then();
  };
  return standardNavigateFunction;
}
