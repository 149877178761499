import { Validators } from '@angular/forms';
import { FormControl, FormGroup } from '@ngneat/reactive-forms';
import { IBodyTrailerForm } from '@transport/ui-interfaces';

const MAX_VOLUME = 99;
const MAX_CAPACITY = 99;

export function createBodyInfoFormGroup() {
  return new FormGroup<IBodyTrailerForm>({
    bodyType: new FormControl<string>('', Validators.required),
    bodySubtype: new FormControl<string>('', Validators.required),
    bodyLoadingTypes: new FormControl<string[]>([], Validators.required),
    bodyVolume: new FormControl<number>(0, Validators.compose([Validators.required, Validators.max(MAX_VOLUME)])),
    bodyCapacity: new FormControl<number>(0, Validators.compose([Validators.required, Validators.min(1), Validators.max(MAX_CAPACITY)])),
  });
}

export function mapBodyTruckInputValueToInner<T extends IBodyTrailerForm>(value: T) {
  return {
    bodyType: value.bodyType ?? '',
    bodySubtype: value.bodySubtype ?? '',
    bodyVolume: value.bodyVolume ?? 0,
    bodyCapacity: value.bodyCapacity ?? 0,
    bodyLoadingTypes: value.bodyLoadingTypes?.map(item => item ?? '') ?? [],
  };
}
