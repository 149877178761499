import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { VehicleService } from '@marketplace/shared';
import { USER_ROLE, VEHICLE_TYPE } from '@transport/ui-interfaces';
import { IColumnConfig, PaginatedArray } from '@marketplace/shared/modules/table';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';
import { BehaviorSubject, filter, map, switchMap, take, takeUntil } from 'rxjs';
import { IVehicleTable } from './vehicles-table.interface';
import { VehiclesTableService } from './vehicles-table.service';
import { TnCurrentUserService } from '@marketplace/auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TnConfirmationService } from '@transport/ui-store';
import { VehiclesAmountService } from '../filters/filters-toolbar/vehicle-amount.service';
import { VehiclesService } from '../vehicles.service';
import { MatDialog } from '@angular/material/dialog';

const VEHICLE_TYPES = [
  {
    name: 'Одиночное ТС',
    type: VEHICLE_TYPE.TRUCK,
  },
  {
    name: 'Фура',
    type: VEHICLE_TYPE.TRAILER,
  },
  {
    name: 'Сцепка',
    type: VEHICLE_TYPE.COUPLER,
  },
];

@UntilDestroy()
@Component({
  selector: 'marketplace-vehicles-table',
  templateUrl: './vehicles-table.component.html',
  styleUrls: ['./vehicles-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VehiclesTableComponent implements OnInit {
  public columnConfigs: IColumnConfig[] = [
    { name: 'tractor', header: this.translate.instant('shared.mp.vehicles.table.header.tractor'), minWidth: 50 },
    { name: 'agentCarrier', header: this.translate.instant('shared.mp.vehicles.table.header.agentCarrier'), minWidth: 200, width: 200 },
    { name: 'trailer', header: this.translate.instant('shared.mp.vehicles.table.header.trailer'), minWidth: 50 },
    { name: 'body', header: this.translate.instant('shared.mp.vehicles.table.header.body'), minWidth: 330 },
    { name: 'accreditation', header: this.translate.instant('shared.mp.vehicles.table.header.accreditation'), minWidth: 155 },
    { name: 'actions' },
  ];

  public isAgentOrExpeditor = Boolean(this.currentUserService.currentUser.isAgentOrExpeditor);
  public _canManage = true;

  @Input() set canManage(canManage: boolean) {
    this._canManage = canManage;
    if (!canManage) {
      this.columnConfigs = this.columnConfigs.filter(column => column.name !== 'actions');
    }
  }

  public vehicles$: BehaviorSubject<PaginatedArray<IVehicleTable>> = this.vehiclesService.list;
  public vehiclesTableData$ = this.vehicles$.pipe(map(data => data.items));

  public rowClasses = (vehicle: IVehicleTable) => {
    return vehicle.isArchived ? ['disabled'] : [];
  };

  constructor(
    public vehiclesService: VehiclesTableService,
    public translate: TranslateService,
    private vehiclesPageService: VehiclesService,
    private readonly vehicleService: VehicleService,
    private readonly vehiclesAmountService: VehiclesAmountService,
    private readonly domainService: TnDomainService,
    public readonly currentUserService: TnCurrentUserService,
    private readonly confirmation: TnConfirmationService,
    private dialog: MatDialog,
  ) {
    if (this.domainService.subDomain) {
      this.columnConfigs = this.columnConfigs.filter(column => column.name !== 'accreditation');
    }
  }

  ngOnInit(): void {
    this.columnConfigs = this.columnConfigs.filter(item => {
      if (!this.isAgentOrExpeditor) {
        return item.name !== 'agentCarrier';
      } else return true;
    });
  }

  public get isOwner() {
    return this.vehiclesPageService.role$.value === USER_ROLE.OWNER;
  }

  public onRowClick(row: { event: Event; rowData: IVehicleTable }) {
    if (row.rowData.isArchived) return;
    if (!this._canManage) return;
    this.onEdit(row.rowData);
  }

  public onPageClick(page: PageEvent) {
    this.vehiclesService.changePage(page);
  }

  public onEdit(vehicle: IVehicleTable): void {
    const type = VEHICLE_TYPES.find(el => el.name === vehicle.vehicleType)?.type;
    this.vehicleService.openModal(this.vehiclesPageService.role$.value as USER_ROLE, vehicle.id, type);
    this.fetchOnModalDestroy();
  }

  public onRestore(vehicle: IVehicleTable): void {
    this.vehicleService
      .restoreVehicle(this.vehiclesPageService.role$.value as USER_ROLE, vehicle.id)
      .pipe(take(1))
      .subscribe(() => {
        this.vehiclesService.fetch();
        this.vehiclesAmountService.updateVehiclesStatusCounters();
      });
  }

  // public isNotBlacklisted(vehicle: IVehicleTable) {
  //   return !vehicle.blackListVehicle.message?.length;
  // }

  // public vehicleToBlackList(vehicle: IVehicleTable) {
  //   this.dialog
  //     .open(VehicleBlacklistModalComponent, {
  //       height: '100vh',
  //       position: { right: '0px', top: '0px' },
  //       width: '415px',
  //       maxWidth: '100vw',
  //       disableClose: true,
  //       data: {
  //         service: this.vehicleService,
  //         vehicle,
  //       },
  //     })
  //     .afterClosed()
  //     .pipe(
  //       untilDestroyed(this),
  //       filter(result => Boolean(result)),
  //       switchMap(data => this.vehicleService.vehicleToBlackList(data, this.vehiclesPageService.role$.value!)),
  //     )
  //     .subscribe(() => {
  //       this.vehiclesService.fetch();
  //       this.vehiclesAmountService.updateVehiclesStatusCounters();
  //     });
  // }

  public sendVechicleToArchive(vehicle: IVehicleTable) {
    this.confirmation
      .openByPrefix('shared.mp.vehicles.vehicle.confirmDelete')
      .pipe(
        filter(result => Boolean(result)),
        switchMap(() => this.vehicleService.sendVechicleToArchive(this.vehiclesPageService.role$.value as USER_ROLE, vehicle.id)),
        untilDestroyed(this),
      )
      .subscribe(() => {
        this.vehiclesService.fetch();
        this.vehiclesAmountService.updateVehiclesStatusCounters();
      });
  }

  public restoreVehicle(vehicle: IVehicleTable) {
    this.vehicleService
      .restoreVehicle(this.vehiclesPageService.role$.value as USER_ROLE, vehicle.id)
      .pipe(take(1))
      .subscribe(() => {
        this.vehiclesService.fetch();
        this.vehiclesAmountService.updateVehiclesStatusCounters();
      });
  }

  private fetchOnModalDestroy(): void {
    if (this.vehicleService.dialogRef)
      this.vehicleService.vm?.finish$.pipe(takeUntil(this.vehicleService.dialogRef.afterClosed())).subscribe(finished => {
        if (finished) {
          this.vehiclesService.fetch();
          this.vehiclesAmountService.updateVehiclesStatusCounters();
        }
      });
  }
}
