import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FILE_TYPE } from '@transport/ui-interfaces';

@Component({
  selector: 'transport-file-icon',
  templateUrl: './file-icon.component.html',
  styleUrls: ['./file-icon.component.scss'],
  // eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- TODO: tech debt
  changeDetection: ChangeDetectionStrategy.Default,
})
export class TnFileIconComponent {
  @Input() public fileType?: FILE_TYPE;

  @Input() public fileExtension?: string;

  public getIconText(): string {
    let result = '';
    switch (this.fileType) {
      case FILE_TYPE.DOC:
        result = 'doc';
        break;

      case FILE_TYPE.PDF:
        result = 'pdf';
        break;

      case FILE_TYPE.XLS:
        result = 'xls';
        break;

      default:
        result = this.fileExtension ?? '';
        break;
    }
    return result;
  }
}
