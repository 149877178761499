import moment from 'moment';

/**
 * Добавляет к указанной дате часы и минуты
 * @param time время в формате HH:mm
 */
export const mergeDateAndTime = (date: moment.Moment, time: string): moment.Moment => {
  const hours = time?.split(':')[0];
  const minutes = time?.split(':')[1];
  const result = moment(date).add(hours, 'hours').add(minutes, 'minutes');
  return result;
};

export const getDateByFormat = (date: string, format = 'DD.MM.YYYY') => {
  return moment(date).format(format);
};
