import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FormGroup, FormControl, ValidationErrors } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, startWith } from 'rxjs';
import { ICommonSelectData } from '../../../common.interface';


@UntilDestroy()
@Component({
  selector: 'common-multiple-select',
  templateUrl: './common-multiple-select.component.html',
  styleUrls: ['./common-multiple-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonMultipleSelectComponent implements OnInit, AfterViewInit {
  @ViewChild('search') searchTextBox?: ElementRef;
  @ViewChild('commonSelect') commonSelect!: MatSelect;

  _dataSource: ICommonSelectData[] | null = [];
  @Input() public set dataSource(data: ICommonSelectData[] | null) {
    this._dataSource = data;
    this.options.next(this.sortDisabledOptions(this.filterOptions(this.filterCtrl.value)));
  }
  public get dataSource() {
    return this._dataSource;
  }

  @Input() public size: 'sm' | 'md' | 'lg' = 'sm';
  @Input() public errorsSize: 'xs' | 'sm' | 'md' | 'lg' = 'xs';
  @Input() public inputFieldClass = '';
  @Input() public label?: string = '';
  @Input() public labelClasses?: string[];
  @Input() public labelAddition?: string;
  @Input() public caption?: string;
  @Input() public captionClasses?: string[];
  @Input() public showErrors = true;
  @Input() public required = false;
  @Input() public disabled = false;
  @Input() public alwaysEnabled = false;
  @Input() public errorToMessage: (errors: ValidationErrors | null) => string = () => 'Error';
  @Input() public allowClear = true;
  @Input() public enableSearch = false;
  @Input() public hasBackdrop = true; // Если селект в модалке

  @Input() public formGroup?: FormGroup;
  @Input() public control?: FormControl;
  @Input() public controlName: string = '';
  @Input() public placeholder: string = '';
  @Input() public dataTest: string = '';

  public options = new BehaviorSubject<ICommonSelectData[] | null>([]);
  public isOpen = false;
  public finalLabel:{ additionalCount: number, firstType: string } | null = null;

  public filterCtrl: FormControl = new FormControl();

  public allComplete: boolean = false;

  constructor(private cdr: ChangeDetectorRef) {}

  public get formControl(): FormControl | null {
    let control: FormControl | null = null
    if (this.formGroup && this.controlName) control = this.formGroup.get(this.controlName) as FormControl;
    if (this.control) control = this.control ?? null;
    if (this.alwaysEnabled) control?.enable();
    return control;
  }

  public get isEmpty(): boolean {
    return !Boolean(this.formControl?.value);
  }

  public ngOnInit() {
    this.options.next(this.sortDisabledOptions(this.filterOptions(this.filterCtrl.value)));
    this.formControl?.valueChanges
      .pipe(untilDestroyed(this), startWith(this.formControl?.value))
      .subscribe(value => {
        this.finalLabel = this.shortenLabel(value);
        this.allComplete = value?.length === this.options?.value?.length;
        this.cdr.markForCheck();
      })
    this.filterCtrl.valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((val) => this.options.next(this.sortDisabledOptions(this.filterOptions(val))));
  }

  public ngAfterViewInit(): void {
    if (!this.hasBackdrop) {
      // MatSelect doesn't provide a way to customize the backdrop. Accessing MatSelect internals as a workaround.
      const select = this.commonSelect as any;
      select._overlayDir.backdropClass = 'hidden';
      select._overlayDir.overlayOutsideClick.pipe(untilDestroyed(this)).subscribe(() => this.commonSelect.close());
    }
  }

  public openedChange(e) {
    this.isOpen = e;
    // Set search textbox value as empty while opening selectbox
    this.filterCtrl.patchValue('');
    // Focus to search textbox while clicking on selectbox
    // if (e == true) {
    //   this.searchTextBox?.nativeElement?.focus();
    // }
  }

  public equals(objOne, objTwo) {
    if (typeof objOne !== 'undefined' && typeof objTwo !== 'undefined') {
      return objOne?.id === objTwo?.id;
    }
    return false;
  }

  public selectAll(e) {
    if (e?.checked) {
      this.formControl?.setValue(this.options.value);
    } else {
      this.formControl?.setValue(null);
      this.allComplete = false;
    }
  }

  public shortenLabel(value): { firstType: string, additionalCount: number } | null {
    if (!value?.length) return null;
    const additionalCount = value?.length - 1;
    const firstType = (value[0]?.label || value[0]?.name) ?? '';
    return {additionalCount, firstType};
  }

  private filterOptions(val) {
    if (!this.dataSource) return [];
    if (!val) {
      return this.dataSource;
    } else {
      const filteredOptions = this.dataSource.filter(option => option.label.toLowerCase().includes(val.toLowerCase()));
      return filteredOptions;
    }
  }

  private sortDisabledOptions(value) {
    if (this.formControl?.disabled || this.disabled) {
      const checkedIds = this.formControl?.value?.map(item => item.id);
      const checkedoptions = this.formControl?.value?.map(item => ({ id: item.id, label: item.name || item.label })) ?? [];
      const unChecked = value?.filter(item => !checkedIds?.includes(item.id)) ?? [];
      return [...checkedoptions, ...unChecked];
    } else {
      return value;
    }
  }

  public clearSelect(e): void {
    e.stopPropagation();
    this.formControl?.setValue(null);
  }

  public someComplete() {
    if (!this.formControl?.value?.length) return false;
    return this.formControl?.value?.length !== this.options?.value?.length && !this.allComplete;
  }
}
