import { ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { AUCTION_CRITERION_CHOICES } from '@transport/ui-interfaces';
import { TnSupportedCurrency } from 'libs/transport-ui-pipes/src/lib/currency/multi-currency.pipe';

@UntilDestroy()
@Component({
  selector: 'transport-place-step-bet',
  templateUrl: './place-step-bet.component.html',
  styleUrls: ['./place-step-bet.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnPlaceStepBetComponent implements OnInit {
  @Input() public currency = TnSupportedCurrency.RUB;

  @Input() public isVatIncluded = false;

  @Input() public isSmallView = false;

  @Input() public betPrice = 1;

  @Input() public minStepPrice = 1;

  @Input() public startPrice = 0;

  @Input() public lastBet = 0;

  @Input() public lastBetEquivalent: number | null = null;

  @Input() public additionalAuctionCriteria: { type: AUCTION_CRITERION_CHOICES; weight: number }[] = [];

  @Input() public myOrgAdditionalCriteriaData: { type: AUCTION_CRITERION_CHOICES; value: number }[] | null = null;

  @Input() public isLastBetByMyOrganization;

  @Input() public arriveDatetimeTo = '';

  @Input() public arriveDatetimeFrom = '';

  @Input() public tradingParticipantsCount;

  @Input() public disabled = false; //Для случая, если ГП не аккредитован ТНЦЛ

  @Input() public commentControl!: FormControl;

  @Output() public readonly placeBet = new EventEmitter<{
    bet: number;
    additionalCriteriaData?: { type: AUCTION_CRITERION_CHOICES; value: number }[];
  }>();

  public increment = 0;
  
  public dateAdditionalCriteriaControl = new UntypedFormControl(null);
  
  public vatAdditionalCriteriaControl = new UntypedFormControl(null);

  public get currentBet(): number {
    return this.betPrice + this.increment * this.minStepPrice;
  }

  constructor(private readonly translate: TranslateService) {}

  public ngOnInit(): void {
    this.vatAdditionalCriteriaControl.setValue(this.isVatIncluded);
    this.vatAdditionalCriteriaControl.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.isVatIncluded = value;
      this.recalculateIncrement();
    })
  }

  public recalculateIncrement() {
    if (!(this.betPrice || this.startPrice)) {
      return;
    }
    const bet = this.betPrice || this.startPrice;
    const vatWeight = this.additionalAuctionCriteria[0]?.weight || 0;
    const price = bet / ((vatWeight + 100) / 100)

    this.increment = this.isVatIncluded ? 0 : Math.floor((price - bet) / this.minStepPrice);
  }

  public setIncrement(event: Event, value: number) {
    event.preventDefault();
    this.increment = value;
  }

  public get maxBet() {
    return this.additionalAuctionCriteria?.length ? this.startPrice : this.lastBet || this.startPrice;
  }

  public validationErrors(e: ValidationErrors | null) {
    if (e?.maxlength) {
      return this.translate.instant('shared.errors.maxCommentLength', { value: 200 });
    }
    return '';
  }

  public get getOffer() {
    const type = this.additionalAuctionCriteria[0]?.type;
    const weight = this.additionalAuctionCriteria[0]?.weight || 0;

    if (type === AUCTION_CRITERION_CHOICES.FIRST_LOADING_DATE) {
      return this.currentBet + this.dateAdditionalCriteriaControl.value?.id * Number(weight);
    }
    if (type === AUCTION_CRITERION_CHOICES.IS_VAT_INCLUDED) {
      return this.isVatIncluded ? this.currentBet : this.currentBet * weight / 100 + this.currentBet;
    }

    return this.currentBet;
  }

  public get isValidAdditionalCriteria() {
    return this.isValidMaxEquivalent && this.isValidMinEquivalent;
  }

  public onPlaceBet() {
    if (this.additionalAuctionCriteria?.length && this.isValidAdditionalCriteria) {
      const type = this.additionalAuctionCriteria[0]?.type;
      const value = this.getPlaceBetValue(type);

      this.placeBet.emit({
        bet: this.currentBet,
        additionalCriteriaData: [
          { type, value },
        ],
      });
    } else {
      this.placeBet.emit({ bet: this.currentBet });
    }
  }

  private getPlaceBetValue(type) {
    if (type === AUCTION_CRITERION_CHOICES.FIRST_LOADING_DATE) {
      return this.dateAdditionalCriteriaControl.value?.id ?? 0;
    }
    if (type === AUCTION_CRITERION_CHOICES.IS_VAT_INCLUDED && this.vatAdditionalCriteriaControl.value === false) {
      return this.getOffer - this.currentBet;
    }

    return 0;
  }

  public get isValidMaxEquivalent(): boolean {
    return this.lastBetEquivalent ? this.getOffer < this.lastBetEquivalent : this.getOffer <= this.startPrice;
  }

  public get isValidMinEquivalent(): boolean {
    return this.getOffer >= this.minStepPrice;
  }
}
