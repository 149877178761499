import { Component, Input } from '@angular/core';
import { STATUS } from '@transport/ui-interfaces';

@Component({
  selector: 'transport-accreditation-block',
  templateUrl: './accreditation-block.component.html',
})
export class AccreditationBlockComponent {
  @Input() public driverStatus: STATUS = STATUS.NEW;

  public DRIVER_STATUS = STATUS;

  public readonly STATUS_TEXT = {
    [STATUS.NEW]: 'carrier.drivers.statuses.new',
    [STATUS.PASSED]: 'carrier.drivers.statuses.passed',
    [STATUS.PENDING]: 'carrier.drivers.statuses.pending',
    [STATUS.NOT_PASSED]: 'carrier.drivers.statuses.notPassed',
  };
}
