import { Injectable } from '@angular/core';
import { transport } from '@transport/proto';
import { IProfileOrganizationFormValue, USER_ROLE } from '@transport/ui-interfaces';
import { TnGqlClientCarrierService, TnGqlClientSharedService } from '@transport/ui-store';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class TnProfileOrganizationService {
  private readonly isSubdomainPage$ = new Subject<boolean>();

  constructor(private readonly graphQL: TnGqlClientCarrierService, private readonly graphQLShared: TnGqlClientSharedService) {}

  public getIsSubdomainPage(): Observable<boolean> {
    return this.isSubdomainPage$;
  }

  public setIsSubdomainPage(isSubdomainPageUrl: boolean): void {
    this.isSubdomainPage$.next(isSubdomainPageUrl);
  }

  public getVatTypes(): Observable<transport.IOrganizationVatType[]> {
    return this.graphQLShared.queryOrganizationVatTypes(USER_ROLE.CARRIER);
  }

  public saveOrganization(value: IProfileOrganizationFormValue & { id: string }) {
    return this.graphQL.editOrganization(this.getEditOrganizationInput(value)).pipe(map(result => result.editOrganization));
  }

  private getEditOrganizationInput(value: IProfileOrganizationFormValue & { id: string }): transport.IEditOrganizationInput {
    const {
      organization: { legalForm, ogrn, companyName, inn, kpp, legalAddress, postalAddress },
      email,
      id,
      signer,
      phoneNumber,
      vatType,
    } = value;
    const organizationName = Boolean(companyName) ? { name: companyName } : {};
    return {
      registrationNumber: ogrn,
      legalForm: legalForm?.id ?? '',
      email: email ?? '',
      postalAddress: postalAddress ?? '',
      id: id,
      inn,
      kpp: kpp ?? '',
      phoneNumber: phoneNumber ?? '',
      legalAddress: legalAddress ?? '',
      signer: signer ?? '',
      vat: vatType ?? '',
      ...organizationName,
    };
  }
}
