import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonSelectComponent } from './components/common-select/common-select.component';
import { TnUiKitModule } from '@transport/ui-kit';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { CommonDatePickerComponent } from 'libs/custom-controls/src/lib/components/common-date-picker/common-date-picker.component';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnCoreModule } from '@transport/core';
import { TnUiTranslateModule } from '@transport/ui-translate';
import { CommonDateRangeComponent } from 'libs/custom-controls/src/lib/components/common-date-range/common-date-range.component';
import { CommonCheckboxComponent } from 'libs/custom-controls/src/lib/components/common-checkbox/common-checkbox.component';
import { CommonInputComponent } from 'libs/custom-controls/src/lib/components/common-input/common-input.component';
import { TnUiDirectivesModule } from '@transport/ui-directives';
import { CommonTextareaComponent } from './components/common-textarea/common-textarea.component';
import { CommonPhoneInputComponent } from './components/common-phone-input/common-phone-input.component';
import { CommonDimensionsInputComponent } from './components/common-dimensions-input/common-dimensions-input.component';
import { CommonNumberInputComponent } from './components/common-input-number/common-number-input.component';
import { CommonTimeInputComponent } from './components/common-input-time/common-time-input.component';
import { MatSelectSearchComponent } from 'libs/custom-controls/src/lib/components/common-select/mat-select-search/mat-select-search.component';
import { CommonMultipleSelectComponent } from './components/common-select/common-multiple-select/common-multiple-select.component';
import { CommonSlideComponent } from 'libs/custom-controls/src/lib/components/common-slide/common-slide.component';
import { CommonImageInputComponent } from 'libs/custom-controls/src/lib/components/common-image-input/common-image-input.component';
import { CommonUploadAreaComponent } from 'libs/custom-controls/src/lib/components/common-upload-area/upload-area.component';
import { CommonAutocompleteComponent } from './components/common-autocomplete/common-autocomplete';
import { OptionsScrollDirective } from './components/common-autocomplete/directives/options-scroll.directive';

@NgModule({
  imports: [
    CommonModule,
    MatIconModule,
    FormsModule,
    TnUiKitModule,
    MatSelectModule,
    TnUiMaterialModule,
    TnCoreModule,
    TnUiDirectivesModule,
    TnUiTranslateModule,
  ],
  declarations: [
    CommonSelectComponent,
    CommonMultipleSelectComponent,
    CommonDatePickerComponent,
    CommonDateRangeComponent,
    CommonCheckboxComponent,
    CommonInputComponent,
    CommonImageInputComponent,
    CommonNumberInputComponent,
    CommonTimeInputComponent,
    CommonTextareaComponent,
    CommonPhoneInputComponent,
    CommonDimensionsInputComponent,
    MatSelectSearchComponent,
    CommonSlideComponent,
    CommonUploadAreaComponent,
    CommonAutocompleteComponent,
    OptionsScrollDirective,
  ],
  exports: [
    CommonSelectComponent,
    CommonMultipleSelectComponent,
    CommonDatePickerComponent,
    CommonDateRangeComponent,
    CommonCheckboxComponent,
    CommonInputComponent,
    CommonImageInputComponent,
    CommonNumberInputComponent,
    CommonTimeInputComponent,
    CommonTextareaComponent,
    CommonPhoneInputComponent,
    CommonDimensionsInputComponent,
    CommonSlideComponent,
    CommonUploadAreaComponent,
    CommonAutocompleteComponent
  ],
})
export class CustomControlsModule {}
