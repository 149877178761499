import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { trackingRouterDataReducer } from './reducers/router-data.reducer';

export const TRACKING_EFFECTS = [];

@NgModule({
  imports: [StoreModule.forFeature('tracking', trackingRouterDataReducer), EffectsModule.forFeature(TRACKING_EFFECTS)],
  exports: [StoreModule, EffectsModule],
})
export class TnTrackingStoreModule {}
