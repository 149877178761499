import { createReducer, on } from '@ngrx/store';

import { adminProfileSharedActions } from './admin-profile-shared.actions';
import { adminProfileSharedInitialState } from './admin-profile-shared.state';

const adminProfileSharedReducerCreator = createReducer(
  adminProfileSharedInitialState,

  on(adminProfileSharedActions.loadPaymentTypesSuccess, (state, { paymentTypes }) => ({
    ...state,
    paymentTypesDict: paymentTypes,
  })),

  on(adminProfileSharedActions.loadLegalFormsSuccess, (state, { legalForms }) => ({
    ...state,
    legalFormsDict: legalForms,
  })),

  on(adminProfileSharedActions.loadVatTypesSuccess, (state, { vatTypes }) => ({
    ...state,
    vatTypesDict: vatTypes,
  })),

  on(adminProfileSharedActions.defaultInsuranceSuccess, (state, { contract: defaultContract }) => ({
    ...state,
    defaultContract,
  })),
);

export function adminProfileSharedReducer(state, action) {
  return adminProfileSharedReducerCreator(state, action);
}
