import { CARRIER_QUERY } from './carrier/graphql-queries-carrier';
import { OWNER_QUERY } from './owner/graphql-queries-owner';
import { SHARED_QUERY } from './shared/graphql-queries-shared';

/**
 * GraphQL queries.
 */
export const GRAPHQL_QUERY = {
  shared: SHARED_QUERY,
  carrier: CARRIER_QUERY,
  owner: OWNER_QUERY,
};

export * from './carrier/graphql-mutations-carrier';
export * from './owner/graphql-mutations-owner';
export * from './shared/graphql-mutations-shared';
