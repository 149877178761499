import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, filter, map, switchMap, tap } from 'rxjs/operators';

import { TnConfirmationService } from '../../../../confirmation/confirmation.service';
import { TnToasterFacade, TOAST_TYPE } from '../../../../toaster/toaster.facade';
import { TnAgentCarrierService } from '../../agent-carrier-page/agent-carrier.service';
import { removeAgent, removeAgentFailure, removeAgentSuccess } from '../actions/agent-carriers-remove.actions';

@Injectable({
  providedIn: 'root',
})
export class TnAgentCarriersEffects {
  public operationFail = createEffect(() =>
    this.actions$.pipe(
      ofType(removeAgentFailure),
      tap(({ error }) => {
        //retrow error for handling by global error handler
        throw error;
      }),
    ),
  );

  public readonly removeAgentCarrier = createEffect(() =>
    this.actions$.pipe(
      ofType(removeAgent),
      switchMap(({ id }) =>
        this.confirmation.openByPrefix('carrier.directory.agentCarriers.details.modals.archive').pipe(
          filter(result => Boolean(result)),
          switchMap(() => {
            return this.agentCarrierService.sendAgentCarrierToArchive(id).pipe(
              map(() => {
                this.toastFacade.showMessage('carrier.directory.agentCarriers.details.success.remove', TOAST_TYPE.SUCCESS);
                return removeAgentSuccess();
              }),
              catchError((error: Error) => {
                return of(removeAgentFailure({ error }));
              }),
            );
          }),
        ),
      ),
    ),
  );

  constructor(
    public readonly actions$: Actions,
    private readonly confirmation: TnConfirmationService,
    private readonly agentCarrierService: TnAgentCarrierService,
    private readonly toastFacade: TnToasterFacade,
  ) {}
}
