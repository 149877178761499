import { Component, Input } from '@angular/core';
import { TnSupportedCurrency } from 'libs/transport-ui-pipes/src/lib/currency/multi-currency.pipe';

@Component({
  selector: 'marketplace-vehicle-publication-price-column',
  templateUrl: './vehicle-publication-price.component.html',
  styleUrls: ['./vehicle-publication-price.component.scss'],
})
export class VehiclePublicationPriceComponent {
  @Input() price: string | number | null | undefined = null;
  @Input() currency: TnSupportedCurrency = TnSupportedCurrency.RUB;

  @Input() isVatIncluded = true;

  // true - Цена за дистанцию, false - цена за весь заказ
  @Input() priceForDistance = false;
}
